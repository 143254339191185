import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import {modifyCurrency} from "../../Actions/ModifyCurrency";
import currencyInfo from "../../Constants/currencyInfo"

const getDropdownText = (currencyValue) => {
    return currencyInfo[currencyValue].text + " (" + currencyInfo[currencyValue].symbol + ")"
}

const currencyOptions = ["usd", "inr", "aud", "brl", "gbp", "eur", "php"].map((x, idx) => { return {key: idx, value: x, text: getDropdownText(x)} })

class CurrencyDropdown extends Component {
    constructor(props) {
        super(props);

        this.handleCurrencyChange = this.handleCurrencyChange.bind(this)
    }

    handleCurrencyChange(event, {value}){
        this.props.modifyCurrency(value)
    }

    render() {
        return (
            <Dropdown
                disabled={this.props.userHasCurrency}
                placeholder="Select Currency"
                name="CurrencyDropdown"
                value={this.props.currency}
                options={currencyOptions}
                onChange={this.handleCurrencyChange}
                // fluid
                selection
            />
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency,
    userHasCurrency: state.mainState.userHasCurrency
})

const mapActionsToProps = {
    modifyCurrency: modifyCurrency
}

export default connect(mapStateToProps, mapActionsToProps)(CurrencyDropdown)