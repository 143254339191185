import React, {Component} from 'react'
import { connect } from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import { push } from 'react-router-redux'

class CTAButtonLanding extends Component {
    constructor(props){
        super(props);

        this.goToSignUpPage = this.goToSignUpPage.bind(this);
    }

    goToSignUpPage(e){
        if ('mobile' in this.props){
            this.props.navigateTo("/signup")
        }
        else{
            this.props.toggleModal("needAuthModal")
        }

    }

    render() {
        return (
            <div className="landingCTASignUp">
                <button id="landingCTASignUpButton" onClick={this.goToSignUpPage}>
                    <span>Sign Up Free</span>
                </button>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal,
    navigateTo: push
}

export default connect(null, mapActionsToProps)(CTAButtonLanding)