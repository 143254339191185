import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import AppearanceToggle from "./AppearanceToggle"
import { Label, Button } from 'semantic-ui-react'
import AppearanceInput from "./AppearanceInput"
import AppearanceFileExtensions from "./AppearanceFileExtensions"
import {areArraysTheSame} from "../../../../Constants/areArraysTheSame";
import SaveAppearanceBtn from "./SaveAppearanceBtn"
import DisplayHeader from "../../../../FileDnD/FilesID/DisplayHeader";
import TierLabel from "../../../../SharedComponents/TierLabel"

class AppearanceIndex extends Component {
    hasInfoChanged = () => {
        let hasChanged = false;

        for (let key in this.props.customImportAppearanceEdit){
            const newValue = this.props.customImportAppearanceEdit[key];
            const originalValue = this.props.customImportAppearance[key];

            if (newValue.constructor === Array){
                if (!areArraysTheSame(originalValue, newValue)){
                    hasChanged = true;
                    break
                }
            } else if (newValue.constructor === String){
                if (originalValue.trim() !== newValue.trim()){
                    hasChanged = true;
                    break
                }
            } else {
                if (originalValue !== newValue){
                    hasChanged = true;
                    break
                }
            }
        }

        return hasChanged
    }

    undo = () => {
        const appearanceEdit = JSON.parse(JSON.stringify(this.props.customImportAppearanceEdit));

        for (let key in this.props.customImportAppearance){
            appearanceEdit[key] = this.props.customImportAppearance[key]
        }

        this.props.modifyCustomImportSettings("appearanceEdit", appearanceEdit)
    }

    render() {
        const checkboxStyle = {"marginBottom": "16px"}
        const subheadingStyle = {fontFamily: "Lato", fontWeight: "500", fontSize: "14px", color: "#161E16"};

        return (
            <div>
                <div style={{marginBottom: "16px"}}>
                    <DisplayHeader header="Appearance"/>
                </div>
                <div className="row">
                    <div className="col-sm-9">
                        <div style={checkboxStyle} className="displayBox">
                            <AppearanceInput
                                name="organizationName"
                                labelText="Your Name"
                                helpText="This is the name the user will see that they are uploading files for"
                            />
                        </div>
                        {/*<div style={{display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "16px"}}>*/}
                            <div className="displayBox" style={{marginBottom: '16px'}}>
                                <AppearanceToggle
                                    name="multipleFiles"
                                    labelText="Multiple Files"
                                />
                            </div>
                            <div className="displayBox" style={{marginBottom: "16px"}}>
                                <AppearanceToggle
                                    name="showFileUploads"
                                    labelText="Show file information after uploading"
                                />
                            </div>
                            <div className="displayBox" style={{marginBottom: "16px"}}>
                                <AppearanceToggle
                                    name="readAllSheets"
                                    labelText="Read all sheets of Excel files"
                                    helpText="If column matching is turned on, then this will NOT work even if you have turned it on"
                                />
                            </div>
                            <div className="displayBox" style={{marginBottom: "16px"}}>
                                <AppearanceToggle
                                    name="removeBranding"
                                    tierLabel={<TierLabel tier="pro" feature="branding" importer/>}
                                    labelText="Remove API Spreadsheets branding"
                                />
                                {/*<AppearanceToggle*/}
                                    {/*name="showFileUploads"*/}
                                    {/*labelText="Show file information after uploading"*/}
                                {/*/>*/}
                            </div>
                        {/*</div>*/}
                        {/*<div style={checkboxStyle}>*/}
                            {/*<AppearanceToggle*/}
                                {/*name="editDialog"*/}
                                {/*labelText="Allow user to edit file"*/}
                            {/*/>*/}
                        {/*</div>*/}
                        <div className="displayBox" style={{marginBottom: "16px"}}>
                            <div style={checkboxStyle}>
                                <AppearanceInput
                                    name="successMessage"
                                    labelText="Success Message"
                                    helpText="Message to show when File Upload is Successful"
                                />
                            </div>
                            <div style={checkboxStyle}>
                                <AppearanceInput
                                    name="errorMessage"
                                    labelText="Error Message"
                                    helpText="Message to show when File Upload has an Error"
                                />
                            </div>
                        </div>
                        <div className="displayBox" style={{marginBottom: "16px"}}>
                            <AppearanceFileExtensions subheadingStyle={subheadingStyle} />
                        </div>
                        <div className="displayBox" style={{marginBottom: "16px"}}>
                            <AppearanceInput
                                name="backgroundColor"
                                labelText="Background Color"
                                helpText="Color of the Upload box"
                            />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <Button content="Undo"
                                basic
                                icon="undo"
                                color="orange"
                                onClick={this.undo}
                                disabled={!this.hasInfoChanged()}
                                fluid />
                        <br/>
                        <SaveAppearanceBtn hasInfoChanged={this.hasInfoChanged()} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    customImportAppearance: state.mainState.customImportAppearance,
    customImportAppearanceEdit: state.mainState.customImportAppearanceEdit
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(AppearanceIndex)