import React, {Component} from 'react'
import {connect} from 'react-redux'
import AccountRowDisplay from "../../../../SharedComponents/GoogleAccounts/AccountRowDisplay"
import { Checkbox, Icon } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import capitalizeHyphenated from "../../../../Constants/capitalizeHyphenated";
import AddNewGoogleAccount from "./AddNewGoogleAccount"
import AddNewDropboxAccount from "./AddNewDropboxAccount"
import isObjectEmpty from "../../../../Constants/isObjectEmpty";
import S3BucketsSelection from "./S3BucketsSelection"
import AddNewS3Account from "./AddNewS3Account"
import GoogleDriveFolderSelection from "./GoogleDriveFolderSelection";
import DropboxFolderSelection from "./DropboxFolderSelection";
import DatabaseTablesSelection from "./DatabaseTablesSelection";
import AddNewDatabase from "./AddNewDatabase"

const displayNames = {
    "google": "Google Drive",
    "dropbox": "Dropbox",
    "s3": "AWS S3",
    "postgres": "Postgres"
}

class DestinationsSelection extends Component {
    toggleAccountSelection = (email) => {
        const destinationsEdit = JSON.parse(JSON.stringify(this.props.destinationsEdit))
        const key = this.props.connectType + "AccountSelected"
        const detailsKey = this.props.connectType + "AccountDetails"

        const accountSelected = destinationsEdit[key]
        const accountDetails = destinationsEdit[detailsKey]

        if (accountSelected.includes(email)){
            const idx = accountSelected.indexOf(email)
            accountSelected.splice(idx, 1)
        } else {
            accountSelected.push(email)
        }

        if (email in accountDetails){
            delete accountDetails[email]
        } else {
            accountDetails[email] = {}
        }

        destinationsEdit[key] = accountSelected
        destinationsEdit[detailsKey] = accountDetails

        this.props.modifyCustomImportSettings("destinationsEdit", destinationsEdit)
    }

    render() {
        let accountRows = [];

        const connectType = this.props.connectType;
        let add = null;
        const isDatabase = connectType === "postgres" || connectType === "mysql";

        if (this.props.destinationsEdit[connectType]){
            for (let email in this.props.destinations[connectType + "AccountInfo"]){
                const info = this.props.destinations[connectType + "AccountInfo"][email];
                if (info["has" + capitalizeHyphenated(connectType) + "Permissions"] || connectType === "s3" || isDatabase){
                    accountRows.push(
                        <div className="row">
                            <div className="col-sm-1">
                                <div style={{marginTop: "3px", marginLeft: "10px" }}>
                                    <Checkbox
                                        checked={this.props.destinationsEdit[connectType + "AccountSelected"].includes(email)}
                                        onChange={e => this.toggleAccountSelection(email)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-11">
                                <AccountRowDisplay
                                    imgStyle={{width: '25px', height: '25px'}}
                                    key={email}
                                    email={email}
                                    info={info}
                                    name={connectType === "s3" || isDatabase ? "" : info.name}
                                    profilePic={connectType === "s3" || isDatabase ? "" : info.profilePic}
                                    accountType={connectType}
                                    isDatabase={isDatabase}
                                />
                                {
                                    connectType === "postgres" && this.props.destinationsEdit[connectType + "AccountSelected"].includes(email) ?
                                        <DatabaseTablesSelection databasePK={email} databaseType={connectType} />: null
                                }
                                {
                                    connectType === "mysql" && this.props.destinationsEdit[connectType + "AccountSelected"].includes(email) ?
                                        <DatabaseTablesSelection databasePK={email} databaseType={connectType} />: null
                                }
                                {
                                    connectType === "s3" && this.props.destinationsEdit[connectType + "AccountSelected"].includes(email) ?
                                        <S3BucketsSelection accountName={email} />: null
                                }
                                {
                                    connectType === "google" && this.props.destinationsEdit[connectType + "AccountSelected"].includes(email) ?
                                        <GoogleDriveFolderSelection accountName={email} /> : null
                                }
                                {
                                    connectType === "dropbox" && this.props.destinationsEdit[connectType + "AccountSelected"].includes(email) ?
                                        <DropboxFolderSelection accountName={email} /> : null
                                }
                            </div>
                        </div>
                    )
                }
            }

            if (this.props.connectType === "google"){
                add = <AddNewGoogleAccount />
            } else if (this.props.connectType === "dropbox"){
                if (isObjectEmpty(this.props.destinations[connectType + "AccountInfo"])){
                    add = <AddNewDropboxAccount changeMenuItem={this.props.changeMenuItem} />
                } else {
                    if (accountRows.length === 0){
                        add = <p>There was a problem connecting your Dropbox Account. Please see your <b><Icon name="dropbox"/> Dropbox</b> accounts in your <a href="/profile">Profile</a> for steps on how to correct this situation</p>
                    }
                }
            } else if (this.props.connectType === "s3"){
                add = <AddNewS3Account />
            } else if (this.props.connectType === "postgres" || this.props.connectType === "mysql"){
                add = <div style={{marginBottom: "10px"}}>
                    <AddNewDatabase type={this.props.connectType}/>
                </div>
            }
        }

        let accountSelectionHeader = null;

        if (accountRows.length > 0){
            if (isDatabase){
                accountSelectionHeader = <p style={{marginTop: "10px"}}>Select Database(s) to Upload File Data</p>
            } else {
                accountSelectionHeader = <p style={{marginTop: "10px"}}>Select {displayNames[connectType]} Accounts to Upload File</p>
            }
        }

        return (
            <div>
                {accountSelectionHeader}
                {accountRows}
                <br/>
                {add}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    destinations: state.mainState.customImportDestinations,
    destinationsEdit: state.mainState.customImportDestinationsEdit
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(DestinationsSelection)