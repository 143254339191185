import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'
class DetailMetadata extends Component {
    render() {
        return (
            <span>
                <Label color="green"><span className="detailMetadataTag">Rows</span> <span className="detailMetadataValue">{this.props.data.dataset_info['instances']}</span></Label>
                <Label color="green"><span className="detailMetadataTag">Columns</span> <span className="detailMetadataValue">{this.props.data.dataset_info['attributes']}</span></Label>
                <Label color="blue"><span className="detailMetadataTag">Tasks</span> <span className="detailMetadataValue">{this.props.data.dataset_info['tasks']}</span></Label>
                <Label color="olive"><span className="detailMetadataTag">Downloads</span> <span className="detailMetadataValue">{this.props.data.downloads}</span></Label>
                <Label><span className="detailMetadataTag">Date Published</span> <span className="detailMetadataValue">{this.props.data.dataset_info['datePublished']}</span></Label>
                <Label color="orange"><span className="detailMetadataTag">Missing Values</span> <span className="detailMetadataValue">{this.props.data.dataset_info['missingValues'] ? 'Yes': 'No'}</span></Label>
            </span>
        )
    }
}

export default DetailMetadata