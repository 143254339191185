import React, {Component} from 'react'
import {connect} from 'react-redux'
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import { Icon } from 'semantic-ui-react'
import SSLCertificateStatus from "./SSLCertificateStatus";
import SSLPendingValidationStatus from "./SSLPendingValidationStatus";
import InfoPopup from "../../../../SharedComponents/InfoPopup"
import DeleteCertificateBtn from "./DeleteCertificateBtn"

class SSLInfoModalBody extends Component {
    getDisplayRow = (label, value, info=null) => (<div style={{display: "grid", gridTemplateColumns: "30% 70%", marginBottom: "16px"}}>
        <div style={{fontSize: "14px", fontWeight: "700", color: gunaldiStyles.subText}}>
            {label} {info !== null ? <InfoPopup info={info} /> : null}
        </div>
        <div>
            {value}
        </div>
    </div>)

    render() {
        const certInfo = this.props.modalInfo;
        return (
            <div>
                <div style={{fontSize: "16px", color: gunaldiStyles.mainText, fontWeight: "700", marginBottom: "16px"}}><Icon name="certificate"/> SSL Certificate</div>
                {this.getDisplayRow("Domain Name", certInfo.DomainName)}
                {this.getDisplayRow("Type", certInfo.Type === "IMPORTED" ? "Imported" : "Requested")}
                {this.getDisplayRow("Status", <SSLCertificateStatus status={certInfo.Status}/>)}
                {"NotAfter" in certInfo ? this.getDisplayRow("Expires", certInfo.NotAfter) : null}
                {
                    certInfo.Status === "PENDING_VALIDATION"
                        ? <SSLPendingValidationStatus
                            certificateArn={certInfo['CertificateArn']}
                            getDisplayRow={this.getDisplayRow}
                            domainValidationOptions={"DomainValidationOptions" in certInfo ? certInfo.DomainValidationOptions : []}
                        /> : null
                }
                <div style={{marginBottom: "16px"}}>
                    <DeleteCertificateBtn
                        idx={certInfo.idx}
                        deleteSSLInfo={this.props.deleteSSLInfo}
                        certificateArn={certInfo['CertificateArn']}
                    />
                </div>
                {/*<div style={{fontSize: "18px", fontWeight: "700"}}></div>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(SSLInfoModalBody)