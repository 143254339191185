import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from '../SharedComponents/Modal'
import { Icon, Popup, Input, Button, Message } from 'semantic-ui-react'
import API_Root from "../Constants/API_Root";

class ShareModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            loading: false,
            error: null
        }

        this.copyURL = this.copyURL.bind(this);
        this.copyKeys = this.copyKeys.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.submitEmail = this.submitEmail.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
        this.getOutputType = this.getOutputType.bind(this);
        this.getInfo = this.getInfo.bind(this);
    }

    copyURL(e){
        e.target.focus();
        this.urlRef.select();
        document.execCommand("copy")
    }

    copyKeys(e){
        e.target.focus();
        this.keyRef.select();
        document.execCommand("copy")
    }

    changeEmail(e){
        this.setState({
            email: e.target.value
        })
    }

    dismissMessage(e){
        this.setState({
            error: null
        })
    }

    getOutputType(outputFormat){
        let outputType = "";

        if (outputFormat === "matrix"){
            outputType = "matrix/"
        }
        else if (outputFormat === "jsonColumn"){
            outputType = "jsonColumn/"
        }

        return outputType
    }

    getInfo(){
        const shareModalProps = this.props.modalName.split("_");

        // const outputFormat = shareModalProps[2];
        const pk = shareModalProps[1];

        const API = API_Root === "https://api-woyera.com/" ? "https://api.apispreadsheets.com/" : API_Root;
        // const API = "https://api.apispreadsheets.com/";
        const url = API + "data/" + pk + "/";

        // Get pkID
        let pkID;

        for (let i=0; i<this.props.filePK.length; i++){
            const filePK = this.props.filePK[i];

            if (filePK.pk.toString() === pk.toString()){
                pkID = filePK.id;
                break
            }
        }

        // Get secret and access key if they exist
        let accessKey = null;
        let secretKey = null;

        const fileInfo = this.props.fileInformation[pkID];

        if ("accessKey" in fileInfo && fileInfo['private']){
            accessKey = fileInfo.accessKey;
            secretKey = fileInfo.secretKey
        }

        return {
            url: url,
            accessKey: accessKey,
            secretKey: secretKey
        }
    }

    submitEmail(e){
        const info = this.getInfo()

        this.setState({
            loading: true
        });

        const errorMessage = <Message error
                                      onDismiss={this.dismissMessage}
                                      header={null}
                                      content="There was a problem sharing your link. Please try again!" />;

        const setErrorState = () => this.setState({ loading: false, error: errorMessage});

        fetch(API_Root + "api/share-api-url/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                apiURL: info.url,
                accessKey: info.accessKey,
                secretKey: info.secretKey,
                shareEmail: this.state.email
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data)=> {
                    let message;
                    if (!data.error){
                        message = <Message positive
                                           onDismiss={this.dismissMessage}
                                           header={null}
                                           content="Your link was shared successfully" />
                    }
                    else{
                        message = errorMessage
                    }

                    this.setState({
                        error: message,
                        loading: false
                    })
                }).catch(err => setErrorState())
            }
            else{
                setErrorState()
            }
        }).catch(err => setErrorState())
    }

    render() {
        if (this.props.modalName !== null){
            if (this.props.modalName.includes("shareModal")) {

                const info = this.getInfo();

                const url = info.url;
                const accessKey = info.accessKey;
                const secretKey = info.secretKey;

                let keyCopy = null;

                let emailTitle = "Email API Link";
                let shareTitle = "Share API Link";

                if (accessKey !== null){
                    const keyText = '{"accessKey": "' + accessKey + '", \n"secretKey": "' + secretKey + '"}';

                    emailTitle += " and Keys";
                    shareTitle += " and Keys";

                    keyCopy =
                    <div>
                        <textarea readOnly={true} style={{width: '400px', height: '50px', border: 'none'}} value={keyText}
                                  ref={(textarea) => {
                                      this.keyRef = textarea
                                  }}/>
                        <span style={{marginTop: '-30px'}}>
                            <Popup content="Copy Keys"
                               trigger={<Icon name="copy" onClick={this.copyKeys} style={{cursor: 'pointer', color: 'green'}}/>}/>
                        </span>
                        <br/><br/>
                    </div>
                }

                const modalBody =
                    <div>
                        <br/>
                        <input readOnly={true} style={{width: '350px', border: 'none'}} value={url} type="text"
                               ref={(input) => {
                                   this.urlRef = input
                               }}/>
                        <Popup content="Copy URL"
                               trigger={<Icon name="copy" onClick={this.copyURL} style={{cursor: 'pointer', color: 'green'}}/>}/>
                        <br/><br/>
                        {keyCopy}
                        <h5 className="thinHeading">{emailTitle}</h5>
                        <div style={{width: '400px'}}>
                            <Input value={this.state.email} onChange={this.changeEmail} icon="at" iconPosition='left'
                                   fluid/>
                        </div>
                        <br/>
                        <Button disabled={this.state.email.trim() === ""} loading={this.state.loading} icon="share"
                                content="Share" color="blue" onClick={this.submitEmail}/>
                        {this.state.error}
                        <br/><br/>
                    </div>

                return (
                    <ModalApp name="shareModal"
                              header={<span><Icon name="share"/> {shareTitle}</span>}
                              size="medium"
                              body={modalBody}
                    />
                )
            }
            else{
                return(
                        null
                    )
                }
        }
        else{
            return(
                null
            )
        }

    }
}

const mapStateToProps = (state) => ({
    fileURL: state.mainState.fileURL,
    modalName: state.mainState.modalName,
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ShareModal)