import React, {Component} from 'react'
import TierRestrictionBanner from "./TierRestrictionBanner";
import RequestLabel from "../API/RequestLabel";
import DocsBaseURL from "../../../InfoPages/DocsPage/DocsBaseURL.js"
import PostHeaderReq from "../../../InfoPages/DocsPage/20200311/Create/PostHeaderReq"
import DocsImage from "../DocsImage";

class ActionsAPIPrivate extends Component {
    render() {
        return (
            <div>
                <div style={{marginTop: "-8px", marginBottom: "16px"}}>
                    <TierRestrictionBanner plan="pro" payment="private"/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Making API Private</p>
                    <p>You can make an API Private by clicking on the API Private toggle</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/APIPrivate-Toggle.png" alt='Toggle Private' />
                    <p>This will provide you a 32 character long Access & Secret Key. On a Private API, you must use these keys when making API requests</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/APIPrivate-Keys.png" alt='API Keys' width="70%"/>
                    <p>You can also request new keys at any time</p>
                    <p>But beware that after you receive new keys, your old keys would immediately stop working</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">API Requests with Keys</p>
                    <p>When making <RequestLabel type="get"/> requests, you need to add your keys in URL parameters</p>
                    <DocsBaseURL url="data/aBcDe123xy0z/?accessKey=12345678901234567890123456789012&secretKey=12345678901234567890123456789012/" />
                    <p>Read more in these guides: <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-read")}>reading rows</span>, <span  className="linkStyle" onClick={e => this.props.setBothMenuItems("api-delete")}>deleting rows</span></p>
                    <p>When making <RequestLabel type="post"/> requests, you need to add your keys in the Request header</p>
                    <PostHeaderReq/>
                    <br/>
                    <p>Read more in these guides: <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-create")}>creating rows</span>, <span  className="linkStyle" onClick={e => this.props.setBothMenuItems("api-update")}>updating rows</span></p>
                </div>
            </div>
        )
    }
}

export default ActionsAPIPrivate