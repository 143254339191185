import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../../../../Actions/ToggleModal";
import {modifyModalInfo} from "../../../../Actions/ModifyModalInfo";
import { Icon, Popup } from 'semantic-ui-react'
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import SSLInfoModal from "./SSLInfoModal"

class OpenSSLModalInfoBtn extends Component {
    openModal = (e) => {
        this.props.modifyModalInfo({...this.props.sslInfo, idx: this.props.idx})
        this.props.toggleModal("sslInfoModal")
    }

    render() {
        return (
            <div>
                <Popup content='View Certificate Details'
                       trigger={<Icon name="eye"
                                        style={{color: gunaldiStyles.blue, cursor: "pointer"}}
                                        onClick={this.openModal}
                       />} />
                <SSLInfoModal deleteSSLInfo={this.props.deleteSSLInfo}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(OpenSSLModalInfoBtn)