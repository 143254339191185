import React, {Component} from 'react'
import {connect} from 'react-redux'
import constants from "../../../Constants/constants";
import RereadFile from "./RereadFile"

class UploadDropbox extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const imgStyle = {border:"1px solid #F0F0F0", display: "block", marginBottom: "16px"}
        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">Connect Dropbox Account</p>
                    <p>Before selecting a file, you need to connect your Dropbox account</p>
                    <p>You can only connect 1 Dropbox account at a time. This is a limit imposed by Dropbox</p>
                    <p>1. Start by clicking on the <b>Select Your Dropbox Spreadsheets</b> button</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-Dropbox/SelectDropbox1-4.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="select dropbox"
                    />
                    <p>2. <b>Sign In</b> to your Dropbox account</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-Dropbox/SignInWithYourAccount2.png"
                         style={{width: "50%", ...imgStyle}}
                         alt="sign in to dropbox account"
                     />
                    <p>3. <b>Accept</b> the API Spreadsheets permissions</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-Dropbox/AcceptAPIConnection3.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="accept dropbox permissions"
                    />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Selecting a File</p>
                    <p>If your account was successfully connected then you will be brought back to the Upload screen</p>
                    <p>You can start selecting a file by clicking on the <b>Select Your Dropbox Spreadsheets</b> button</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-Dropbox/SelectDropbox1-4.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="select dropbox"
                    />
                    <p>Now you can select any of the following file types from your Dropbox account: {constants.extensions.join(",")}</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-Dropbox/SelectFile5.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="select dropbox from account"
                    />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Dropbox File Read</p>
                    <RereadFile setBothMenuItems={this.props.setBothMenuItems} hasCsv={true} fileNames={<b>excel files</b>}/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Managing your Dropbox Accounts</p>
                    <p>You can <span className="linkStyle" onClick={e => this.props.setBothMenuItems("integrations-dropbox")}>read all about how to manage your Dropbox Accounts here</span></p>
                </div>
                {/*Prompts to select Dropbox account*/}
                {/*Only 1 dropbox account*/}
                {/*copy and paste intro article from here*/}
                {/*mention where to manage these accounts*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UploadDropbox)