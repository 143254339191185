import React, {Component} from 'react'
import LoginForm from './LoginForm'
import { Image } from 'semantic-ui-react'
import images from '../../../Constants/Images'
import AppSumoAPILogo from "../AppSumoAPILogo.svg";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.initEmail(),
            password: '',
            error: null,
            loading: false,
            isAppSumo: this.initAppSumo()
        }

    }
    componentDidMount(){
        document.title = "Signup - API Spreadsheets"
    }
    initEmail = () => {
        const url = window.location.href;

        if (url.includes("&email=")){
            return url.substring(url.indexOf("&email=") + "&email=".length, url.length).trim()
        } else { return '' }
    }
    initAppSumo = () => { return (window.location.href).includes("?deal=appsumo") }

    isImporter = () => ((window.location.href).includes("?importer"))

    render() {
        return (
            <div className="row" style={{backgroundColor: '#DCDCDC', minHeight: '100vh'}}>
                <div className="col-sm-3" />
                <div className="col-sm-6">
                    <br/><br/><br/>
                    {this.state.isAppSumo ?
                        <div style={{marginLeft:'1rem'}}>
                            <Image style={{marginTop:'5px'}} src={AppSumoAPILogo} href="/" size="large"/>
                        </div>:
                        <div style={{marginLeft: '1rem'}}>
                            <Image style={{marginTop: '5px'}} src={this.isImporter() ? images.logoImporterNav : images.logoNav} href={this.isImporter() ? "/importer" : "/"} size="medium"/>
                        </div> }
                    {/*<div style={{marginLeft: '1rem'}}>*/}
                    {/*    <Image style={{marginTop: '5px'}} src={images.logoNav} href="/" size="medium"/>*/}
                    {/*</div>*/}
                    <LoginForm importer={this.isImporter()}/>
                    <br/>
                    <p style={{textAlign: 'center'}}>Don't Have An Account? <a href={"/signup" + (this.isImporter() ? "?importer" : "")}>Create One</a></p>
                </div>
                <div className="col-sm-3" />
            </div>
        )
    }
}

export default LoginPage