import React, {Component} from 'react'
import UploadFilesIndex from '../UploadFiles/UploadFilesIndex'
import { connect } from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import AuthComponent from "../SharedComponents/AuthComponent";
import { Message, Button } from 'semantic-ui-react'
import GoogleSheetsConnect from '../GoogleSheets/GoogleSheetsConnect';
import DropboxAuth from '../Dropbox/DropboxAuth';
import DropboxNonAuth from '../Dropbox/DropboxNonAuth';
import API_Root from "../Constants/API_Root";
import {modifyIntegrationAuth} from "../Actions/ModifyIntegrationAuth";
import GoogleSheetsNonAuth from '../GoogleSheets/GoogleSheetsNonAuth'
import UploadFilesNonAuth from "../UploadFiles/UploadFilesNonAuth";
import HowDoesThisWork from "../SharedComponents/LandingUseCases/HowDoesThisWork";
import HeroSignUp from "./HeroSignUp.svg"
import CTAButtonLanding from "../SharedComponents/CTAButtonLanding";
import { MobileView, TabletView } from 'react-device-detect'
import APICount from "../SharedComponents/APICount";
import FeaturesButton from "../SharedComponents/LandingUseCases/FeaturesButton";
import PromoBar from "../SharedComponents/PromoBar/PromoBar"
import EmailVerificationMessage from "../SharedComponents/EmailVerificationMessage";
import FailedPaymentMessage from "../SharedComponents/FailedPaymentMessage";
import AWSAuth from "../AWS/AWSAuth";
import QuickstartUploadAuth from "../QuickstartUpload/QuickstartUploadAuth"
import UploadMenuHeader from "./UploadMenuHeader";
import NewDatabaseConnection from "../Database/NewDatabaseConnection";
import AWSModalBodyIndex from "../AWS/AWSModalBodyIndex";
import QuickstartUploadModalBody from "../QuickstartUpload/QuickstartUploadModalBody"
import {toggleShowDemo} from "../Actions/ToggleShowDemo";

class UpdatedAppIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            menuItem: "uploadFiles"
        }

        this.contactUs = this.contactUs.bind(this);
    }

    componentDidMount(){
        if (localStorage.getItem("email") !== null && localStorage.getItem("token") !== null){
            if (window.location.href.includes("?signup")){
                if (localStorage.getItem("showAPIDemo") === null) {
                    this.props.toggleModal("signupDemoModal")
                    localStorage.setItem("showAPIDemo", false)
                }
            }

            fetch(API_Root + 'api/api-check-integration-auth/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                })
            }).then((res) =>
            {
                if (res.status === 200){
                    res.json().then((data) =>
                        {
                            if (!data.error){
                                const storedIntegrations = data.integrationAuth;

                                const integrationAuth = {
                                    dropbox: false,
                                    googleSheets: false
                                }

                                // Check if it's actually authenticated using the access token
                                //
                                if ('dropbox' in storedIntegrations){
                                    integrationAuth.dropbox = true
                                }

                                this.props.modifyIntegrationAuth(integrationAuth);
                            }
                        }
                    ).catch((err) => console.log(err))
                }
            }).catch(err => console.log(err))
        }
        else{
            // (function(){
            //     const s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            //     s1.async=true;
            //     s1.src='https://embed.tawk.to/5e56ff65a89cda5a188820c9/default';
            //     s1.charset='UTF-8';
            //     s1.setAttribute('crossorigin','*');
            //     s1.id = "tawk-to-chat-box"
            //     s0.parentNode.insertBefore(s1,s0);
            // })();
        }
    }


    contactUs(e){
        this.contactLink.click();
    }

    openModal = () => {
        this.props.toggleModal("howitworksModal")
    }

    scrollUp=()=> {
        window.scrollTo({
            bottom: 0,
            behavior: 'smooth',
        })
    }

    changeMenuItem = (menuItem) => this.setState({ menuItem: menuItem })

    getDisplayComponent = (menuItem) => {
        const displayMap = {
            "uploadFiles": <UploadFilesIndex/>,
            "googleSheets": <GoogleSheetsConnect/>,
            "dropbox": <DropboxAuth/>,
            "s3": <AWSModalBodyIndex/>,
            "quickstart": <QuickstartUploadModalBody/>,
            "database": <NewDatabaseConnection/>
        }

        if (menuItem in displayMap){
            return displayMap[menuItem]
        } else {
            return null
        }
    }

    render() {
        const contactFiles = this.props.customContactFiles;
        let message = null;

        if (contactFiles.length > 0){
            const fileNames = contactFiles.join(", ");

            message =
                <div className="row">
                    <div className="col-sm-1" />
                    <div className="col-sm-10">
                        <a style={{display: 'none'}}
                           ref={contactLink => this.contactLink = contactLink}
                           href="mailto:info@apispreadsheets.com?Subject=Enterprise%20Pricing%20Spreadsheets"
                        >Contact Us Email</a>
                        <Message   negative={true}
                                   icon="file alternate outline"
                                   header={"The following files: " + fileNames + " are too big. The max is 50,000 records"}
                                   content={<p><span className="linkStyle" onClick={this.contactUs}>Contact Us to Upgrade to the Enterprise Plan</span></p>}
                        />
                        <br/><br/>
                    </div>
                    <div className="col-sm-1" />
                </div>
        }

        return (
            <div className="app" id="app">
                <UploadMenuHeader menuItem={this.state.menuItem} changeMenuItem={this.changeMenuItem}/>
                <div style={{padding: "65px 200px 480px 200px"}}>
                    {this.getDisplayComponent(this.state.menuItem)}
                </div>
                {/*<div className="uploadGrid">*/}
                    {/*<div>*/}
                        {/*<AuthComponent authComponent={<GoogleSheetsConnect/>} nonAuthComponent={<GoogleSheetsNonAuth />}/>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                        {/*<AuthComponent*/}
                            {/*authComponent={<UploadFilesIndex/>}*/}
                            {/*nonAuthComponent={<UploadFilesNonAuth/>}*/}
                        {/*/>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                        {/*<AuthComponent authComponent={<DropboxAuth/>} nonAuthComponent={<DropboxNonAuth />}/>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                        {/*<AuthComponent authComponent={<AWSAuth/>} nonAuthComponent={<AWSAuth/>}/>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                        {/*<AuthComponent authComponent={<QuickstartUploadAuth/>} nonAuthComponent={<QuickstartUploadAuth/>}/>*/}
                    {/*</div>*/}
                {/*</div>*/}
                {/*<AuthComponent authComponent={null} nonAuthComponent={<img src={HeroSignUp} style={{width: '100%', height: 'auto', marginTop: '-1%'}} alt="flowImage"/>}/>*/}
                {/*<AuthComponent authComponent={null} nonAuthComponent={<CTAButtonLanding/>}/>*/}
                {/*<AuthComponent authComponent={null} nonAuthComponent={<APICount/>}/>*/}
                {/*<AuthComponent authComponent={null} nonAuthComponent={<HowDoesThisWork landing={true} />}/>*/}
                {message}
                {/*<div className="row">*/}
                    {/*<div className="col-sm-12">*/}
                        {/*<br/><br/>*/}
                        {/*<FileInformationTable tableType="upload"/>*/}
                    {/*</div>*/}
                {/*</div>*/}
                {/*<br/><br/><br/>*/}
                {/*<AuthComponent authComponent={null} nonAuthComponent={<FeaturesButton/>}/>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    customContactFiles: state.mainState.customContactFiles,
    integrationAuth: state.mainState.integrationAuth,
    showDemo: state.mainState.showDemo
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyIntegrationAuth: modifyIntegrationAuth,
    toggleShowDemo: toggleShowDemo
}

export default connect(mapStateToProps, mapActionsToProps)(UpdatedAppIndex)