let calculatorPricing = [
    {
        rowMax: 1500,
        requests: 250,
        requestsSec: 1,
        files: 3,
        team: 0,
        secure: false,
        price: 0,
        name: 'free',
        desc: 'hobbyists',
        support: 'FAQ',
        blockRequests: false,
        alerts: false,
        multipleKeys: false,
        domainWhitelist: false,
        autoEmail: false,
        webhooks: false,
        database: false,
        customizeImporter: false,
        cachingLayer: false,
        connectDomains: false,
        privateServer: false,
        addlProducts: true,
        requestsFrequency:"monthly",
        sqlQuery: true,
        ai: false
    },
    {
        rowMax: 5000,
        requests: 5000,
        requestsSec: 1,
        files: 15,
        team: 0,
        secure: true,
        price: 49,
        name: 'pro',
        desc: 'professionals',
        support: 'Email (2 days)',
        blockRequests: false,
        alerts: false,
        multipleKeys: false,
        domainWhitelist: false,
        autoEmail: false,
        webhooks: true,
        database: false,
        customizeImporter: true,
        cachingLayer: false,
        connectDomains: false,
        privateServer: false,
        addlProducts: true,
        requestsFrequency:"monthly",
        sqlQuery: true,
        ai: false
    },
    {
        rowMax: 50000,
        requests: 12500,
        requestsSec: 1,
        files: 30,
        team: 3,
        secure: true,
        price: 99,
        name: 'team',
        desc: 'teams',
        support: 'Email (2 days)',
        blockRequests: true,
        alerts: true,
        multipleKeys: false,
        domainWhitelist: false,
        autoEmail: true,
        webhooks: true,
        database: false,
        customizeImporter: true,
        cachingLayer: false,
        connectDomains: false,
        privateServer: false,
        addlProducts: true,
        requestsFrequency:"monthly",
        sqlQuery: true,
        ai: true
    },
    {
        rowMax: 100000,
        requests: 50000,
        requestsSec: 3,
        files: 75,
        team: 5,
        secure: true,
        price: 199,
        name: 'business',
        desc: 'agency-&-business',
        support: 'Priority (same day)',
        blockRequests: true,
        alerts: true,
        multipleKeys: true,
        domainWhitelist: true,
        autoEmail: true,
        webhooks: true,
        database: true,
        customizeImporter: true,
        cachingLayer: true,
        connectDomains: true,
        privateServer: false,
        addlProducts: true,
        requestsFrequency:"monthly",
        sqlQuery: true,
        ai: true
    },
    {
        rowMax: 100000000,
        requests: 100000000,
        files: 100000000,
        team: 100000000,
        secure: true,
        price: 550,
        name: 'enterprise',
        desc: 'agency-&-business',
        support: 'Dedicated',
        blockRequests: true,
        alerts: true,
        multipleKeys: true,
        domainWhitelist: true,
        autoEmail: true,
        webhooks: true,
        database: true,
        customizeImporter: true,
        cachingLayer: true,
        connectDomains: true,
        privateServer: true,
        addlProducts: true,
        requestsFrequency:"monthly",
        sqlQuery: true,
        ai: true
    }
]

export default calculatorPricing;