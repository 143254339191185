import React, {Component} from 'react'
import {connect} from 'react-redux'
import {gunaldiStyles} from "../GunaldiDesignStyles";
import SupportTierMessage from "./SupportTierMessage";

class SupportConfirmationModalBody extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let success;
        try{
            success = this.props.modalInfo.supportInfo.success;
        } catch (e) {
            success = false
        }

        return (
            <div style={{paddingBottom: "32px"}}>
                <div style={{color: success ? gunaldiStyles.mainText : gunaldiStyles.red, fontSize: "18px", fontWeight: "700"}}>
                    {
                        success ? "Thank you for submitting your support request 😀" :
                            "There was an error submitting your support request 😢"
                    }
                </div>
                <div style={{marginTop: "16px"}}>
                    {
                        success ? <SupportTierMessage tier={this.props.tier} page="confirmation"/> : <div style={{color: gunaldiStyles.mainText, fontSize: "14px"}}>Please email us at support@apispreadsheets.com</div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo,
    tier: state.mainState.tier
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(SupportConfirmationModalBody)