import React, {Component} from 'react'
import { Divider, Popup } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {toggleModal} from "../../../../Actions/ToggleModal";

class DocsGetURLParameters extends Component {
    constructor(props){
        super(props)

        this.openSQLModal = this.openSQLModal.bind(this);
    }

    openSQLModal(){
        this.props.toggleModal("queryModal")
    }

    render() {
        return (
            <div>
                <h5 id="parameterHeading">URL Query Parameters</h5>
                <Divider/>
                <span className="parameterName">fileID</span> <span className="parameterType">integer</span>
                <span className="parameterSecondLine"><span className="parameterRequired">Required</span> </span>
                <span className="parameterDesc">This is the id of the file you are making requests to. It can be found on your file page
                    <Popup content={<img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/API-Introduction/FileID.png" alt="File ID"/>} position="left" trigger={<span className="linkStyle"> here</span>} />
                </span>
                <Divider/>
                <span className="parameterName">accessKey</span> <span className="parameterType">string, 32 characters</span>
                <span className="parameterSecondLine"><span className="parameterRequired">Required, if private</span> </span>
                <span className="parameterDesc">The accessKey of the file, if the API is private. If file is not private, then there is no need for the accessKey parameter</span>
                <Divider/>
                <span className="parameterName">secretKey</span> <span className="parameterType">string, 32 characters</span>
                <span className="parameterSecondLine"><span className="parameterRequired">Required, if private</span> </span>
                <span className="parameterDesc">The secretKey of the file, if the API is private. If file is not private, then there is no need for the secretKey parameter</span>
                <Divider/>
                <span className="parameterName">dataFormat</span> <span className="parameterType">string</span>
                <span className="parameterSecondLine"><span className="parameterOptional">Optional</span> <span className="parameterDefault">default=row</span></span>
                <span className="parameterDesc">The format of the output data you want, can be one of the following <code>row, matrix, column</code>. If this parameter is not specified or is not in the aforementioned list, then the <code>row</code> format is returned.</span>
                <Divider/>
                <span className="parameterName">limit</span> <span className="parameterType">integer</span>
                <span className="parameterSecondLine"><span className="parameterOptional">Optional</span></span>
                <span className="parameterDesc">The first <code>n</code> amount of rows to be returned</span>
                <Divider/>
                <span className="parameterName">query</span> <span className="parameterType">string</span>
                <span className="parameterSecondLine"><span className="parameterOptional">Optional</span></span>
                <span className="parameterDesc">SQL style query to get a subset of rows. <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-query")}>Read a full overview on how to write a query</span></span>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps,mapActionsToProps)(DocsGetURLParameters)