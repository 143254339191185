import React, {Component} from 'react'

class DocsDisplay extends Component {
    render() {
        return (
            <div style={{padding: "32px 100px 100px 100px", width: '100%'}}>
                <div style={{paddingBottom: "16px"}}>
                    {this.props.header}
                </div>
                {this.props.display}
            </div>
        )
    }
}

export default DocsDisplay