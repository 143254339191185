import API_Root from "../../Constants/API_Root";

const importURL = API_Root.includes("api-woyera") ? "https://www.apispreadsheets.com" : "http://localhost:5000"

const sampleImportCode = (importHash, shouldMatch=false) => {
    let code = "<!DOCTYPE html>\n" +
        "<html>\n" +
        "<head>\n" +
        "<script type=\"text/javascript\" src=\"" + importURL + "/import.js\"></script>\n" +
        "<script type=\"text/javascript\">\n" +
        "   function importComplete(success, data) {\n" +
        "      console.log(success)\n" +
        "      if (success){\n" +
        "          console.log(data);\n" +
        (shouldMatch ? "" : "          // close the importer if you wish\n          // importer.closeImporter();\n") +
        "      }\n" +
        "   }\n" +
        "\n"

    if (shouldMatch){
        code += "   function matchComplete(matchRequired, matchSuccess){\n" +
            "       if (matchRequired){\n" +
            "           // if first uploaded file has finished matching, do something\n" +
            "           if(matchSuccess[0]){\n" +
            "               // close the importer if you wish \n" +
            "               // importer.closeImporter()\n" +
            "           }\n" +
            "       }\n" +
            "   }\n\n"
    }

    code += "   let importer = new APISpreadsheetsImporter(\"" + importHash + "\", importComplete"

    if (shouldMatch){
        code += ", matchComplete"
    }

    code += ");\n\n"

    code += "</script>\n</head>\n<body>\n" +
    "   <button class=\"api-spreadsheets-import-button\" onclick=\"importer.importFiles();\">Import Excel and CSV Files</button>\n</body>\n</html>"

    return code
}



export default sampleImportCode;