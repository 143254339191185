import React, {Component} from 'react'
import {connect} from "react-redux";
import FileList from "../LandingPage/Illustrations/FileList.svg"
import {toggleModal} from "../Actions/ToggleModal";
import {modifyPaymentModalOrigin} from "../Actions/ModifyPaymentModalOrigin";

class NoDatabaseCreated extends Component {
    goToNewDatabase = () => {
        this.props.toggleModal("addNewDatabaseModal")
    }

    openUpgradeModal = () => {
        this.props.modifyPaymentModalOrigin("database");
        this.props.toggleModal("paymentModal")
    }

    render(){
        const borderStyle = "24px solid #F0F0F0"
        const isUpgrade = 'isUpgrade' in this.props;

        return (
            <div style={'style' in this.props ? this.props.style : null}>
                <img src={FileList} alt="No importers created" style={{borderTop: borderStyle, borderLeft: borderStyle, borderRight: borderStyle, display: "block", margin: "0 auto" }}/>
                <div style={{textAlign: "center", marginTop: "16px"}}>
                    <span style={{fontSize: "20px", fontWeight: "700", fontFamily: "Lato", color: "#161E16"}}>{isUpgrade ? "You cannot connect to a database 😭" : "No databases have been connected 😭"}</span>
                </div>
                {/*<div style={{textAlign: "center", marginTop: "16px"}}>*/}
                    {/*<span style={{color: "#5E645E", fontSize: "14px", fontWeight: "400", fontFamily: "Lato"}}>Create an importer and get an already hosted URL for users to upload files.<br/> Or a pre-written code snippet to place in your website.</span>*/}
                {/*</div>*/}
                <div style={{textAlign: "center", marginTop: "16px"}}>
                    <div style={{textAlign: "center", marginTop: "16px"}}>
                        <button className="callToActionBtn darkCTABtn" style={{width: "400px"}} onClick={isUpgrade ? this.openUpgradeModal : this.goToNewDatabase}>
                            <div>
                                <div>{isUpgrade ? "Upgrade to connect to a database" : "Connect a new database"}</div>
                                <div><i className={isUpgrade ? 'bx bx-credit-card' : 'bx bx-plus'}/></div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

}

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyPaymentModalOrigin: modifyPaymentModalOrigin
}

export default connect(null, mapActionsToProps)(NoDatabaseCreated)