import React, {Component} from 'react'
import capitalizeHyphenated from "../../../Constants/capitalizeHyphenated";

class CTAColGrid extends Component {
    render() {
        const position = capitalizeHyphenated(this.props.position);
        const idNames = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
        const gridDivs = idNames.map((x, idx) => <div key={idx.toString()} id={"cta" + position + x.toString()} style={{gridArea: "cta" + position + x.toString()}}/>)

        return (
            <div id={"cta" + position + "Grid"}>
                {gridDivs}
            </div>
        )
    }
}

export default CTAColGrid