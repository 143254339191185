import React, {Component} from 'react'
import ReactJson from 'react-json-view'
import {capitalize} from "../../../Constants/capitalize";

class WebhookResponseBody extends Component {
    render() {
        const responses = {
            "create": {"request_type": "POST", "status_code": 201, "response_object": {}, "crud_action": "create", "request_body": {"data": {"Name": ["Abe Lincoln"], "UniqueID": ["9780321643728"], "Occupation": ["President"], "Age": [61]}, "dataFormat": "jsonColumn"}, "access_key": null, "secret_key": null, "file_id": 1392, "file_name": "FamousPeople.xlsx", "file_type": "file", "sheet_read": "1800s-1900s", "uploaded_dt": "2021-10-25 18:03:09", "private": false},
            "read": {"request_type": "GET", "status_code": 200, "response_object": {"data": [{"Name": "Abe Lincoln", "UniqueID": "9780321643728", "Occupation": "President", "Age": 61}, {"Name": "Thomas Edison", "UniqueID": "9700000000728", "Occupation": "Inventor", "Age": 75}]}, "crud_action": "read", "request_body": {"dataFormat": "jsonRow", "rows": null, "query": null}, "access_key": null, "secret_key": null, "file_id": 1392, "file_name": "FamousPeople.xlsx", "file_type": "file", "sheet_read": "1800s-1900s", "uploaded_dt": "2021-10-25 18:03:09", "private": false},
            "update": {"request_type": "POST", "status_code": 201, "response_object": {}, "crud_action": "update", "request_body": {"query": "select*from1392whereUniqueID='9780321643728'", "data": {"Name": ["Grover Cleveland"]}, "dataFormat": "jsonColumn"}, "access_key": null, "secret_key": null, "file_id": 1392, "file_name": "FamousPeople.xlsx", "file_type": "file", "sheet_read": "1800s-1900s", "uploaded_dt": "2021-10-25 18:03:09", "private": false},
            "delete": {"request_type": "GET", "status_code": 200, "response_object": {"message": "1 row was deleted"}, "crud_action": "delete", "request_body": {"dataFormat": "jsonRow", "rows": null, "query": "deletefrom1392whereUniqueID='9780321643728'"}, "access_key": null, "secret_key": null, "file_id": 1392, "file_name": "FamousPeople.xlsx", "file_type": "file", "sheet_read": "1800s-1900s", "uploaded_dt": "2021-10-25 18:03:09", "private": false}
        }


        return (
            <div className="getResponseBox">
                <div className="getResponseHeading">
                    <span style={{float: 'left'}}><h6 className="thinHeading">Request Body for <b>{capitalize(this.props.eventType)}</b></h6></span>
                </div>
                <ReactJson src={responses[this.props.eventType]} name={null} theme="ocean" displayObjectSize={false} displayDataTypes={false}/>
            </div>
        )
    }
}

export default WebhookResponseBody