import {getValueFromInfoObject} from "./getValueFromInfoObject";

export const getShouldMatchColumns = (infoProps) => {
    let shouldMatchColumnsBool = getValueFromInfoObject("matchColumns", infoProps, "importOptions")
    if (shouldMatchColumnsBool === null){
        shouldMatchColumnsBool = false
    }

    let shouldMatchColumns = false;
    if (shouldMatchColumnsBool){
        const columnsMatchInfo = getValueFromInfoObject("columns", infoProps, "columns")

        if (columnsMatchInfo !== null){
            if (columnsMatchInfo.constructor === Array){
                if (columnsMatchInfo.length > 0){
                    shouldMatchColumns = true
                }
            }
        }
    }

    return shouldMatchColumns
}