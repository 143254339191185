export const MODIFY_EDIT_FILE_RESPONSE = 'MODIFY_EDIT_FILE_RESPONSE';

export function modifyEditFileResponse(finalColumnNames, finalColumnPK, fileInformation){
    return{
        type: MODIFY_EDIT_FILE_RESPONSE,
        mainState: {
            finalColumnNames: finalColumnNames,
            finalColumnPK: finalColumnPK,
            fileInformation: fileInformation
        }
    }
}