import React, {Component} from 'react'
import { Checkbox } from 'semantic-ui-react'

class ColumnRequired extends Component {
    toggle = () => {
        this.props.changeState("required", !this.props.required)
    }

    render() {
        return (
            <div style={this.props.checkBoxStyle}>
                <Checkbox checked={this.props.required} onChange={this.toggle}/>
            </div>
        )
    }
}

export default ColumnRequired