import React, {Component} from 'react'
import { Segment, Icon } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import images from "../../Constants/Images";

class MobileEmailSample extends Component {
    render() {
        return (
            <Segment>
                <div className="row">
                    <div className="col-sm-12">
                        <div style={{textAlign: "left", marginLeft: "10px"}}>
                            <small><Icon name="paperclip" /> Oct 26, 2021, 11:48 AM <Icon name="star outline" /> <Icon name="reply" /></small>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-10">
                        <span style={{marginLeft: "10px"}}>
                            <span style={{fontWeight: "100", fontSize: "20px", marginTop: "10px"}}>Daily Report</span>
                            <span style={{marginLeft: "10px"}}>
                                <small style={{backgroundColor: "rgba(245, 179, 62)", fontWeight: "bold", borderRadius: "24px", padding: "5px 10px 5px 10px"}}>External</small>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <span style={{marginLeft: "10px"}}><b>Rosa Diaz</b> info@apispreadsheets.com</span>
                    </div>
                </div>
                <div style={{marginLeft: "10px"}}>
                    <span className="text-muted">to Your Email Account <b><Icon name="dropdown" /></b></span>
                </div>
                <div style={{marginLeft: "10px", marginTop: "10px"}}>
                    <p>Hi Team,</p>
                    <p>See the daily spreadsheet of Orders attached. Let me know if you have any questions.</p>
                    <p>Thanks,</p>
                    <p>Rosa Diaz</p>
                    <Icon name="file excel outline" style={{color: "green", fontSize: "30px"}} />
                    <b>Orders.xlsx</b>
                </div>
            </Segment>
        )
    }
}

export default MobileEmailSample