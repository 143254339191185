import React, {Component} from 'react'
import { Button } from 'semantic-ui-react'

class UpdateCreditCardDetailsLinkBtn extends Component {
    render() {
        return (
            <Button size={this.props.size} style={{backgroundColor: this.props.bgColor}}><a href="/update-payment-info" style={{textDecoration: "none", color: this.props.textColor}}>{this.props.text}</a></Button>
        )
    }
}

export default UpdateCreditCardDetailsLinkBtn