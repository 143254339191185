import React, {Component} from 'react'
import RoleTag from "./RoleTag";

class RolesModalBody extends Component {
    render() {
        return (
            <div style={{marginTop: "30px"}}>
                <div style={{border: "1px solid lightgrey", borderRadius: "8px", padding: "5px 0 10px 5px", boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)", backgroundColor: "rgba(0,74,110,0.1)"}}>
                    <div style={{border: "1px solid lightgrey", borderRadius: "8px", width: "75%", padding: "5px 0 10px 5px", boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)", backgroundColor: "rgba(0,101,53,0.1)"}}>
                        <div style={{border: "1px solid lightgrey", borderRadius: "8px", width: "75%", padding: "5px 0 10px 5px", boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)", backgroundColor: "rgba(45,51,138,0.1)"}}>
                            <div style={{display: "grid", gridTemplateColumns: "150px auto"}}>
                                <div>
                                    <RoleTag userRole="developer"/>
                                </div>
                                <div>
                                    <ul>
                                        <li>Access files through all API requests</li>
                                        <li>Have team specific Access & Secret keys</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div style={{padding: "10px 0 0 5px"}}>
                            <div style={{display: "grid", gridTemplateColumns: "150px auto"}}>
                                <div>
                                    <RoleTag userRole="manager"/>
                                </div>
                                <div>
                                    <b>Everything in Developer and...</b>
                                    <ul>
                                        <li>Share file to team members</li>
                                        <li>Access ALL File Actions like re-reading, deleting etc.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{padding: "10px 0 0 10px"}}>
                        <div style={{display: "grid", gridTemplateColumns: "150px auto"}}>
                            <div>
                                <RoleTag userRole="admin"/>
                            </div>
                            <div>
                                <b>Everything in Developer and Manager and...</b>
                                <ul>
                                    <li>Create, delete, and update Teams</li>
                                    <li>Invite or delete members</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RolesModalBody