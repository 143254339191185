import React, {Component} from 'react'
import DocsMenu from "./DocsMenu";
import DocsDisplayIndex from './DocsDisplayIndex'

class DocsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            apiVersion : '2020-03-11'
        }

        this.changeAPIVersion = this.changeAPIVersion.bind(this);
    }

    changeAPIVersion(apiVersion){
        this.setState({
            apiVersion: apiVersion
        })
    }

    render() {
        return (
            <div className="docsPageIndex">
                <DocsMenu changeAPIVersion={this.changeAPIVersion}/>
                <DocsDisplayIndex apiVersion={this.state.apiVersion}/>
            </div>
        )
    }
}

export default DocsPage