import React, {Component} from 'react'

class RequestLabel extends Component {
    render() {
        const backgroundColor = {"get": "#9b870c", "post": "orange"}

        return (
            <span style={{
                backgroundColor: backgroundColor[this.props.type],
                color: "white",
                fontSize: "85%",
                padding: "0 4px 0 4px",
                fontWeight: "700",
                marginLeft: "6px"}}>
                {this.props.type.toUpperCase()}
            </span>
        )
    }
}

export default RequestLabel