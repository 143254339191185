import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react';
import {getUploadFileForMessage} from "../../Import/ImportUpload/getUploadFileForMessage";

class UploadIndicator extends Component {
    render() {
        let heading = "Upload Files";
        let firstIconName = "file excel outline";

        if (this.props.status === "merged"){
            heading = "Upload More Files";
            firstIconName = "plus square outline"
        }

        if ('importSegment' in this.props){
            heading += getUploadFileForMessage("", this.props.info)
        }

        return (
            <div>
                <span className="uploadIcons">
                    <Icon name={firstIconName} />
                    {/*<Icon name="file alternate outline" />*/}
                </span>
                <h4 className="thinHeading">{heading}</h4>
            </div>
        )
    }
}

export default UploadIndicator