import React, {Component} from 'react'
import {connect} from 'react-redux'
import {gunaldiStyles} from "../../../SharedComponents/GunaldiDesignStyles";
import FileURL from "../../../FileDnD/FileURL"
import CreateOptionsToolbar from "../../FileSummaryCode/ActionTypeOptionsToolbar/CreateOptionsToolbar";
import JSONOutputPreview from "../../JSONOutputPreview";
import CustomAccordion from "../../../SharedComponents/CustomAccordion";
import ReactJson from "react-json-view";
import AceEditor from "react-ace";
import 'brace/theme/monokai';
import 'brace/mode/powershell';
import QuickstartParameters from "../../FileSummaryCode/QuickstartParameters";
import API_Backend_Root from "../../../Constants/API_Backend_Root";

class QuickstartLazyMode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requestType: "all", // could also be "read" or "create",
            fileURL: ""
        }
    }

    componentDidMount(){
        fetch(API_Backend_Root + "store-analytics-from-ui/", {
            method: "POST",
            body: JSON.stringify({"data": {"Email":localStorage.getItem("email"),
                    "Notes":"User viewed Google Sheets non-strict","Use Case":"Google Sheets Non-Strict","Date Saved":(new Date()).toLocaleDateString(),"Usage Metrics":""},
                "headers": {"accessKey": "2863a49b9ea04e9847cb90f0e0aa93d5", "secretKey": "9eca419e34ff1e929f05d00598dfb679"},
                "apiUrl": "https://api.apispreadsheets.com/data/Zh4Ob0LXhmZN3goL/", "sendEmailAlert": false}),
        }).then(res =>{
            if (res.status === 201){
                console.log("lead saved")
            }
            else{
                console.log("lead not saved")
            }
        }).catch(err => console.log(err))
    }

    handleRequestTypeChange = (event) => {
        this.setState({ requestType: event.target.value });
    };

    updateFileURL = (fileURL) => this.setState({ fileURL: fileURL })

    getOutputJson = () => {
        let standardJson = { "input_cells": {"Data": {"B1": 50, "B2": 250000}}, "output_cells": {"Calculations": ["B1"]}}
        const pkFileInfo = this.props.fileInformation[this.props.pkID];

        let sampleSheetName1 = pkFileInfo['sheetNames'][0]
        let sampleSheetName2 = pkFileInfo['sheetNames'][0]

        let sampleData = pkFileInfo["jsonColumnSample"];
        let a2Val = 50
        let a3Val = "HIGH"

        try{
            let keys1 = Object.keys(sampleData)
            let a2ValRaw = sampleData[keys1[0]][0]

            if (a2ValRaw !== undefined && a2ValRaw !== null) {
                a2Val = a2ValRaw
            }
        } catch (e) {}

        try{
            let keys2 = Object.keys(sampleData)
            let a3ValRaw = sampleData[keys2[0]][1]

            if (a3ValRaw !== undefined && a3ValRaw !== null) {
                a3Val = a3ValRaw
            }
        } catch (e) {}

        try{
            sampleSheetName2 = pkFileInfo['sheetNames'][1]

            if (sampleSheetName2 === undefined || sampleSheetName2 === null){
                sampleSheetName2 = sampleSheetName1
            }
        } catch (e) {}

        let finalJson = { "input_cells": {[sampleSheetName1]: {"A2": a2Val, "A3": a3Val}}, "output_cells": {[sampleSheetName2]: ["B1"]}}

        if (this.state.requestType === "read"){
            delete finalJson["input_cells"]
        } else if (this.state.requestType === "create"){
            delete finalJson["output_cells"]
        }

        return finalJson
    }

    getRespJSON = () => {
        let finalJSON = this.getOutputJson()

        let reqOutput = finalJSON["output_cells"]

        let finalResp = {}

        for (let sheetName in reqOutput){
            let sheetOutput = {}

            for (let i=0; i<reqOutput[sheetName].length; i++){
                sheetOutput[reqOutput[sheetName][i]] = "value"
            }

            finalResp[sheetName] = sheetOutput;
        }

        return finalResp
    }

    getHeaders = () => {
        let fileInfo = this.props.fileInformation[this.props.pkID]

        return {accessKey: fileInfo.accessKey, secretKey: fileInfo.secretKey}
    }

    getIsFilePrivate = () => (this.props.fileInformation[this.props.pkID].private)

    getCurlCode = () => {
        let fileURL = this.state.fileURL
        let requestBody = this.getOutputJson()

        let requestHeaders = null
        if (this.getIsFilePrivate()){
            requestHeaders = this.getHeaders()
        }

        // Base cURL command
        let curlCommand = `curl -X POST "${fileURL}"`;

        // Adding headers if they exist
        if (requestHeaders) {
            for (const [key, value] of Object.entries(requestHeaders)) {
                curlCommand += ` \\\n -H "${key}: ${value}"`;
            }
        }

        // Add request body
        curlCommand += ` \\\n -d '${JSON.stringify(requestBody, null, 2)}'`;

        return curlCommand;
    }

    render() {
        const labelStyle = {backgroundColor: gunaldiStyles.blue,
            padding: "2px 6px 2px 6px",
            borderRadius: "2px",
            fontSize: "12px",
            color: "white", fontWeight: "700", fontFamily: "Lato"};

        let isFilePrivate = this.getIsFilePrivate()

        return (
            <div>
                <div>
                    <span style={labelStyle}>
                        NON-STRICT MODE
                    </span>
                </div>
                <div style={{ fontSize: "12px"}}>
                    Non-strict mode lets you interact with your Google Sheet using the sheet names and cell names. No database rules apply
                </div>
                <div style={{ marginTop: "16px"}}>
                    <FileURL apiType="create"
                             textPadding="10"
                             pk={this.props.pk}
                             pkID={this.props.pkID}
                             lazyMode
                             displayFileHash={this.props.displayFileHash}
                             updateFileURL={this.updateFileURL}
                         />
                </div>
                <div style={{ fontFamily: 'Lato', fontSize: "14px", marginTop: "16px"}}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div style={{"border": "1px solid #F0F0F0", "borderRadius": "4px", "padding": "16px"}}>
                                <div>
                                    <span style={{
                                        verticalAlign: "middle",
                                        fontSize: "16px",
                                        color: "#161E16",
                                        fontWeight: "700",
                                        fontFamily: "Lato"
                                    }}>How to use this API</span>
                                </div>
                                <div style={{paddingTop: "12px"}}>
                                    <p>Make a POST request to the URL above with a JSON request body with</p>
                                    <div>
                                        <input
                                            type="radio"
                                            id="requestType1"
                                            name="requestType"
                                            value="all"
                                            checked={this.state.requestType === 'all'}
                                            onChange={this.handleRequestTypeChange}
                                            style={{cursor: "pointer"}}
                                        />
                                        <label htmlFor="requestType1" style={{cursor: "pointer", paddingLeft: "8px"}}>both
                                            the <code>input_cells</code> and <code>output_cells</code> if you want to
                                            insert and receive data</label>
                                    </div>
                                    <div style={{cursor: "pointer"}}>
                                        <input
                                            type="radio"
                                            id="requestType2"
                                            name="requestType"
                                            value="create"
                                            checked={this.state.requestType === 'create'}
                                            onChange={this.handleRequestTypeChange}
                                            style={{cursor: "pointer"}}
                                        />
                                        <label htmlFor="requestType2" style={{cursor: "pointer", paddingLeft: "8px"}}>only
                                            the <code>input_cells</code> if you just want to insert data</label>
                                    </div>
                                    <div style={{cursor: "pointer"}}>
                                        <input
                                            type="radio"
                                            id="requestType3"
                                            name="requestType"
                                            value="read"
                                            checked={this.state.requestType === 'read'}
                                            onChange={this.handleRequestTypeChange}
                                            style={{cursor: "pointer"}}
                                        />
                                        <label htmlFor="requestType3" style={{cursor: "pointer", paddingLeft: "8px"}}>only
                                            the <code>output_cells</code> if you just want to receive data</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">

                        </div>
                    </div>
                </div>
                <div style={{fontFamily: 'Lato', fontSize: "14px", marginTop: "16px"}}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div id="fileSummaryCodeToolbar">
                                <CustomAccordion
                                    body={<JSONOutputPreview
                                        pkID={this.props.pkID}
                                        outputFormat="lazyMode"
                                        outputJson={this.getOutputJson()}
                                        pk={this.props.pk}
                                    />}
                                    header={"Sample Request Body"}
                                    accordionStyle={{
                                        borderRadius: '4px',
                                        border: "1px solid #F0F0F0",
                                        backgroundColor: 'white'
                                        // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                                    }}
                                    headerDivStyle={{
                                        borderRadius: '4px',
                                        backgroundColor: 'white',
                                        fontSize: '16px',
                                        fontWeight: '700',
                                        fontFamily: "Lato",
                                        padding: "16px"
                                    }}
                                    open
                                />
                                <div style={{ paddingTop: "16px"}}>
                                    {
                                        isFilePrivate ?
                                            <div style={{
                                                "border": "1px solid #F0F0F0",
                                                "borderRadius": "4px",
                                                "padding": "16px"
                                            }}>
                                                <b>Your file is private so you need to include keys in your Request Header</b>
                                                <div style={{paddingTop: '8px'}}>
                                                    <h6 style={{color: "white"}}>Include Keys</h6>
                                                    <ReactJson src={this.getHeaders()} name={null} theme="ocean" displayObjectSize={false}/>
                                                </div>
                                            </div> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div id="fileSummaryCodeToolbar">
                                {
                                    this.state.requestType === "all" || this.state.requestType === "read" ?
                                    <div>
                                        <CustomAccordion
                                            body={<JSONOutputPreview
                                                pkID={this.props.pkID}
                                                outputFormat="lazyMode"
                                                outputJson={this.getRespJSON()}
                                                pk={this.props.pk}
                                            />}
                                            header={"Sample Response Body"}
                                            accordionStyle={{
                                                borderRadius: '4px',
                                                border: "1px solid #F0F0F0",
                                                backgroundColor: 'white'
                                                // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                                            }}
                                            headerDivStyle={{
                                                borderRadius: '4px',
                                                backgroundColor: 'white',
                                                fontSize: '16px',
                                                fontWeight: '700',
                                                fontFamily: "Lato",
                                                padding: "16px"
                                            }}
                                            open
                                        />
                                    </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{fontFamily: 'Lato', fontSize: "14px", marginTop: "16px"}}>
                    <div style={{
                        "border": "1px solid #F0F0F0",
                        "borderRadius": "4px",
                        "padding": "16px"
                    }}>
                        <div style={{marginBottom: "16px"}}>
                            <div style={{
                                verticalAlign: "middle",
                                fontSize: "16px",
                                color: "#161E16",
                                fontWeight: "700",
                                fontFamily: "Lato"
                            }}>Sample cURL code
                            </div>
                            <div style={{
                                verticalAlign: "middle",
                                fontSize: "12px",
                                color: gunaldiStyles.secondaryText,
                                fontFamily: "Lato"
                            }}>Use ChatGPT to convert this for your language or API Platform
                            </div>
                        </div>
                        <AceEditor
                            mode="powershell"
                            theme="monokai"
                            value={this.getCurlCode()}
                            name="UNIQUE_ID_OF_DIV"
                            editorProps={{$blockScrolling: true}}
                            height="300px"
                            width="auto"
                        />
                    </div>
                </div>
                <div style={{fontFamily: 'Lato', fontSize: "14px", marginTop: "16px"}}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div style={{
                                "border": "1px solid #F0F0F0",
                                "borderRadius": "4px",
                                "padding": "16px"
                            }}>
                                <div style={{
                                    verticalAlign: "middle",
                                    fontSize: "16px",
                                    color: "#161E16",
                                    fontWeight: "700",
                                    fontFamily: "Lato",
                                    marginBottom: "16px"
                                }}>Request Body Parameters
                                </div>
                                <QuickstartParameters/>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div style={{
                                "border": "1px solid #F0F0F0",
                                "borderRadius": "4px",
                                "padding": "16px"
                            }}>
                                <div style={{
                                    verticalAlign: "middle",
                                    fontSize: "16px",
                                    color: "#161E16",
                                    fontWeight: "700",
                                    fontFamily: "Lato",
                                    marginBottom: "16px"
                                }}>Response Body
                                </div>
                                <span className="parameterDesc">If <code>output_cells</code> are included in the request body then a JSON response body will be returned. The keys will be the sheet_names provided in the <code>output_cells</code> request body, and the values will be JSON Objects containing the cell reference and their values. Like as follows</span>
                                <div style={{paddingTop: "8px"}}>
                                    <ReactJson src={{
                                        "sheet_name_1": {"A4": "value", "B80": "value"},
                                        "sheet_name_2": {"A9": "value"}
                                    }} name={null} theme="ocean" displayObjectSize={false}/>
                                </div>
                            </div>
                            <div style={{ marginTop: "16px"}}>
                                <div style={{
                                    "border": "1px solid #F0F0F0",
                                    "borderRadius": "4px",
                                    "padding": "16px"
                                }}>
                                    <div style={{
                                        verticalAlign: "middle",
                                        fontSize: "16px",
                                        color: "#161E16",
                                        fontWeight: "700",
                                        fontFamily: "Lato",
                                        marginBottom: "16px"
                                    }}>Response Status Codes
                                    </div>
                                    <div className="docsSection">
                                        <div className="responseCodeLine">
                                            <code className="responseCodeStatus">201</code> <span
                                            className="responseCodeParam codeSuccess">CREATED</span>
                                            <span className="responseCodeDesc">Data was successfully inserted and/or returned</span>
                                        </div>
                                        <hr/>
                                        <div className="responseCodeLine">
                                            <code className="responseCodeStatus">400</code> <span
                                            className="responseCodeParam codeFail">Bad request</span>
                                            <span className="responseCodeDesc">Request body or URLs were not in the proper format</span>
                                        </div>
                                        <hr/>
                                        <div className="responseCodeLine">
                                            <code className="responseCodeStatus">401</code> <span
                                            className="responseCodeParam codeFail">Unauthorized</span>
                                            <span className="responseCodeDesc">The data is private and incorrect access and secret keys were provided</span>
                                        </div>
                                        <hr/>
                                        <div className="responseCodeLine">
                                            <code className="responseCodeStatus">402</code> <span
                                            className="responseCodeParam codeFail">Payment Required</span>
                                            <span className="responseCodeDesc">You are over the row limit per file or request limit for your plan</span>
                                        </div>
                                        <hr/>
                                        <div className="responseCodeLine">
                                            <code className="responseCodeStatus">404</code> <span
                                            className="responseCodeParam codeFail">Not Found</span>
                                            <span className="responseCodeDesc">The fileID does not exist</span>
                                        </div>
                                        <hr/>
                                        <div className="responseCodeLine">
                                            <code className="responseCodeStatus">405</code> <span
                                            className="responseCodeParam codeFail">Method not allowed</span>
                                            <span className="responseCodeDesc">You have blocked this method</span>
                                        </div>
                                        <hr/>
                                        <div className="responseCodeLine">
                                            <code className="responseCodeStatus">500</code> <span
                                            className="responseCodeParam codeFail">Server Error</span>
                                            <span className="responseCodeDesc">There was something wrong with our server OR the server of the connected platform (like Google Sheets)</span>
                                        </div>
                                        <hr/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(QuickstartLazyMode)