import React, {Component} from 'react';
import withAuthorization from '../Authentication/withAuthorization';
import FilesIndex from "./FilesIndex";

class FilesAuthIndex extends Component {
    render() {
        return(
            <FilesIndex/>
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(FilesAuthIndex);
