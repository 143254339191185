import React, {Component} from 'react'
import { Segment, Icon } from 'semantic-ui-react'

class LandingSecuritySegment extends Component {
    render() {
        return (
            <Segment>
                <h5 style={{textAlign: "center"}}>{this.props.header}</h5>
                <div style={{textAlign: "center"}}>
                    <Icon name={this.props.icon} style={{color: this.props.color, fontSize: "30px"}} />
                </div>
            </Segment>
        )
    }
}

export default LandingSecuritySegment