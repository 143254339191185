import React, {Component} from 'react'
import images from "../Constants/Images";
import { Image } from 'semantic-ui-react'

class DatabaseTypeTag extends Component {
    render() {
        return (
            <span style={'style' in this.props ? this.props.style : null}>
                <Image src={images[this.props.dbType.toLowerCase() + "Logo"]} avatar/>
            </span>
        )
    }
}

export default DatabaseTypeTag