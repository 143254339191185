import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Checkbox, Button, Popup, Icon } from 'semantic-ui-react'
import {capitalize} from "../../Constants/capitalize";
import UpgradeToTeam from "../UpgradeToTeam"
import BlockMessage from "./BlockMessage";
import API_Root from "../../Constants/API_Root";
import {mapPkAndPkid} from "../../Constants/mapPkAndPkid";
import FetchLoader from "../FetchLoader";

const requestTypes = ["create", "read", "update", "delete"]

class BlockRequestsModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            blockCreate: false,
            blockRead: false,
            blockUpdate: false,
            blockDelete: false,
            blockHistory: {
                blockCreate: false,
                blockRead: false,
                blockUpdate: false,
                blockDelete: false
            },
            blocking: false,
            error: null,
            isPopupOpen: false,
            fetching: false
        }
    }

    componentDidMount(){
        const isTeam = this.props.tier === "team" || this.props.tier === "business" || this.props.tier === "enterprise";
        const stopFetch = () => this.setState({ fetching: false })

        if (isTeam){
            this.setState({ fetching: true })

            fetch(API_Root + "api/block-request-types-api-files/", {
                method: "POST",
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    filePK: mapPkAndPkid(this.props.filePK, "id", this.props.editID),
                    get: true
                })
            }).then((res) => {
                if (res.status === 200){
                    res.json().then((data) => {
                        this.setState({
                            blockCreate: data.blockCreate,
                            blockRead: data.blockRead,
                            blockUpdate: data.blockUpdate,
                            blockDelete: data.blockDelete,
                            blockHistory: {
                                blockCreate: data.blockCreate,
                                blockRead: data.blockRead,
                                blockUpdate: data.blockUpdate,
                                blockDelete: data.blockDelete
                            }
                        })
                        stopFetch()
                    }).catch(err => stopFetch())
                }
                else{
                    stopFetch()
                }
            }).catch(err => stopFetch())
        }
    }

    blockRequestType = (e, requestType) => {
        const blockState = this.state["block" + capitalize(requestType)]

        this.setState({
            ["block" + capitalize(requestType)]: !blockState
        })
    }

    getDivStyle = (isTeam) => {
        let style = {marginTop: "16px"}

        if (!isTeam){
            style['pointerEvents'] = "none"
            style['opacity'] = "0.5"
            // style['cursor'] = "not-allowed"
        }

        return style
    }

    isBlockButtonDisabled = () => {
        let btnDisabled = true;

        for (let i=0; i<requestTypes.length; i++){
            if (this.state["block" + capitalize(requestTypes[i])] !== this.state.blockHistory["block" + capitalize(requestTypes[i])]){
                btnDisabled = false
                break;
            }
        }

        return btnDisabled
    }

    getBtnText = () => {
        // let btnText = "Block"
        let blockTypes = [];
        let unblockTypes = [];

        for (let i=0; i<requestTypes.length; i++){
            if (this.state["block" + capitalize(requestTypes[i])] !== this.state.blockHistory["block" + capitalize(requestTypes[i])]){
                if (this.state["block" + capitalize(requestTypes[i])] === true){
                    blockTypes.push(capitalize(requestTypes[i]))
                } else {
                    unblockTypes.push(capitalize(requestTypes[i]))
                }
            }
        }

        let btnText = "";
        if (blockTypes.length === 0 && unblockTypes.length === 0){
            btnText = "Toggle to Block or Unblock Request Types"
        } else{
            if (blockTypes.length > 0){
                btnText += "Block " + blockTypes.join(" and ")
            }

            if (unblockTypes.length > 0){
                let unblock = unblockTypes.join(" and ")
                if (btnText !== ""){
                    btnText += ". "
                }

                btnText += "Unblock " + unblock
            }

            btnText += " Request Types"
        }

        return btnText
    }

    handleClose = () => {
        this.setState({ isPopupOpen: false })
    }

    handleOpen = () => {
        this.setState({ isPopupOpen: true })
    }

    blockRequests = e => {
        this.setState({
            isPopupOpen: false,
            blocking: true
        })

        const stopFetch = (error, header) => this.setState({ isPopupOpen: false, blocking: false, error: <BlockMessage error={error} header={header}/> })

        fetch(API_Root + "api/block-request-types-api-files/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                blockCreate: this.state.blockCreate,
                blockRead: this.state.blockRead,
                blockUpdate: this.state.blockUpdate,
                blockDelete: this.state.blockDelete,
                filePK: mapPkAndPkid(this.props.filePK, "id", this.props.editID)
            })
        }).then((res) => {
            if (res.status === 201){
                stopFetch(false, this.getBtnText() + " was successful!")
                this.setState({
                    blockHistory: {blockCreate: this.state.blockCreate, blockRead: this.state.blockRead, blockUpdate: this.state.blockUpdate, blockDelete: this.state.blockDelete}
                })
            }
            else{
                stopFetch(true, "There was an error to " + this.getBtnText() + ". Please try again or contact us at info@apispreadsheets.com if the error persists")
            }
        }).catch(err => stopFetch(true, "There was an error to " + this.getBtnText() + ". Please try again or contact us at info@apispreadsheets.com if the error persists"))

    }

    reset = () => {
        const history = JSON.parse(JSON.stringify(this.state.blockHistory));

        this.setState({
            blockCreate: history.blockCreate,
            blockRead: history.blockRead,
            blockDelete: history.blockDelete,
            blockUpdate: history.blockUpdate
        })
    }

    render() {
        const isTeam = this.props.tier === "team" || this.props.tier === "business" || this.props.tier === "enterprise";
        return (
            <div>
                {isTeam ? null : <div style={{marginTop: "8px"}}><UpgradeToTeam higherTier="blockRequests" header="You can only Block Request Types on a Team or Higher Tier Plan"/></div>}
                <div style={isTeam ? null : {"cursor": "not-allowed"}}>
                    <div style={this.getDivStyle(isTeam)}>
                        <div className="blockRequestCheckbox">
                            <FetchLoader fetching={this.state.fetching}/><Checkbox toggle label="Create" onChange={e => this.blockRequestType(e, "create")} checked={this.state.blockCreate}/>
                        </div>
                        <div className="blockRequestCheckbox">
                            <FetchLoader fetching={this.state.fetching}/><Checkbox toggle label="Read" onChange={e => this.blockRequestType(e, "read")} checked={this.state.blockRead}/>
                        </div>
                        <div className="blockRequestCheckbox">
                            <FetchLoader fetching={this.state.fetching}/><Checkbox toggle label="Update" onChange={e => this.blockRequestType(e, "update")} checked={this.state.blockUpdate}/>
                        </div>
                        <div className="blockRequestCheckbox">
                            <FetchLoader fetching={this.state.fetching}/><Checkbox toggle label="Delete" onChange={e => this.blockRequestType(e, "delete")} checked={this.state.blockDelete}/>
                        </div>
                        <br/>
                        <Popup
                            trigger={<Button style={{backgroundColor: "#EE685F", color: "white"}} loading={this.state.blocking} icon="shield" content={this.getBtnText()} disabled={this.isBlockButtonDisabled()} color="red"/>}
                            on="click"
                            open={this.state.isPopupOpen}
                            onClose={this.handleClose}
                            onOpen={this.handleOpen}
                            flowing
                            content={<div>
                                <h6>Are you sure you want to {this.getBtnText()}?</h6>
                                <Button size="large" color="red" content="Yes" onClick={this.blockRequests} />
                                <Button size="large" color="green" content="No" onClick={this.handleClose} />
                            </div>}
                        />
                        {this.state.error}
                        <br/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier,
    editID: state.mainState.editID,
    filePK: state.mainState.filePK
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(BlockRequestsModalBody)