import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleToast} from "../../../../Actions/ToggleToast";
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import { Icon, Popup, Button } from 'semantic-ui-react';
import API_Root from "../../../../Constants/API_Root";
import {modifyConnectedDomains} from "../../../../Actions/ModifyConnectedDomains";

class DeleteDomainConnectionBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupOpen: false,
            deleting: false
        }
    }

    togglePopup = (e, toggle) => {
        this.setState({
            popupOpen: toggle
        })
    }

    deleteDomainConnection = (e) => {
        const isError = () => this.setState({ deleting: false, popupOpen: false}, () => this.props.toggleToast({show: true, message: "There was an error deleting your Domain Connection. Try again or contact our support if this error persists", type: "error"}));

        fetch(API_Root + "api/delete-cname-domain/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                idx: this.props.idx,
                domainId: this.props.domainId,
                domainName: this.props.domainName
            })
        }).then(res => {
            if (res.status === 200){
                this.setState({ deleting: false, popupOpen: false }, () => this.props.deleteDomainConnection(this.props.idx))
                this.props.toggleToast({show: true, message: "Domain connection successfully deleted!", type: "success"});

                res.json().then(data => {
                    this.props.modifyConnectedDomains(data.connectedDomains)
                }).catch(err => console.log(err))
            } else {
                isError()
            }
        }).catch(err => isError())
    }

    render() {
        return (
            <div>
                <Popup
                    trigger={
                        <Icon
                            style={{color: gunaldiStyles.red, cursor: "pointer"}}
                            loading={this.state.deleting}
                            name="trash alternate outline"
                            onClick={(e) => this.togglePopup(e, true)}/>}
                    on="click"
                    size="small"
                    position="bottom"
                    // onOpen={(e) => this.togglePopup(e, true)}
                    onClose={(e) => this.togglePopup(e, false)}
                    open={this.state.popupOpen}>
                    <div style={{padding: '16px'}}>
                        <div style={{color: gunaldiStyles.red, fontSize: "14px", marginBottom: "8px"}}>
                            Are you sure you want to delete this domain connection? <br/><br/>It will immediately stop working within the next 5-10 minutes.
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <Button color="green" content="No" onClick={(e) => this.togglePopup(e, false)} fluid />
                            </div>
                            <div className="col-sm-6">
                                <Button color="red" loading={this.state.deleting} content="Yes" onClick={this.deleteDomainConnection} fluid />
                            </div>
                        </div>
                    </div>
                </Popup>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleToast: toggleToast,
    modifyConnectedDomains: modifyConnectedDomains
}

export default connect(mapStateToProps, mapActionsToProps)(DeleteDomainConnectionBtn)