import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Menu, Segment } from 'semantic-ui-react'
import Icons from "../../Constants/Icons";
import CodeSnippet from "../CodeSnippet"
import {getCodeSnippet} from "../../Constants/getCodeSnippet";
import {getFileURL} from "../../Constants/getFileURL";
import FileURL from "../FileURL"
import CodeAccordionUpdated from "../CodeAccordionUpdated"
import FileAccordion from "../FileAccordion"
import QuickstartResponse from "./QuickstartResponse"

class QuickstartCodeIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const divHeader = <div className="row">
            <div className="col-sm-12">
                <h4>Quickstart Code</h4>
            </div>
        </div>;

        const fileURL = getFileURL(this.props.fileInformation, this.props.displayFileHash, this.props.pkID, "", "readAction", 1, 1, false, false);
        const apiType = "readAction"

        const code = {
            cURL: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, apiType, this.props.createFormat, fileURL, "cURL"),
            python: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, apiType, this.props.createFormat, fileURL, "python"),
            javascript: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, apiType, this.props.createFormat, fileURL, "javascript"),
            ruby: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, apiType, this.props.createFormat, fileURL, "ruby"),
            PHP: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, apiType, this.props.createFormat, fileURL, "php")
        }

        return (
            <div>
                {this.props.tableType === "all" ? null : divHeader}
                <Menu pointing secondary>
                    <Menu.Item
                        icon={Icons.readAction}
                        name="read"
                        active={true}
                        style={{color: "#6FBB98", fontSize: "14px"}}
                    />
                </Menu>
                <Segment attached="bottom" style={{backgroundColor: 'whitesmoke'}}>
                    <b><i>You can only read values from a Quickstart API</i></b>
                    <QuickstartResponse tableType={this.props.tableType} pkID={this.props.pkID}/>
                    <FileURL quickstart apiType='readAction' url={fileURL}/>
                    <CodeAccordionUpdated apiType='readAction' code={code} quickstart/>
                </Segment>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(QuickstartCodeIndex)