import React, {Component} from 'react'
import {connect} from 'react-redux'
import {isURLValid} from "../../Constants/isURLValid";
import isObjectEmpty from "../../Constants/isObjectEmpty";
import { Message, Button, Dropdown } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import ReactJson from 'react-json-view'
import BannerAlert from "../../SharedComponents/BannerAlert";

class FooterMessage extends Component {
    constructor(props){
        super(props);

        this.state = {
            testing: false,
            testType: 'create',
            testResults: null
        }
    }

    canTestWebhook = () => {
        const webhookURL = this.props.fileInformation[this.props.editID].webhookURL;
        const eventsChecked = this.props.fileInformation[this.props.editID].webhookInfo;

        let canTest = false;
        if (isURLValid(webhookURL) && !isObjectEmpty(eventsChecked)){
            const eventTypes = ["create", "read", "update", "delete"]

            for (let i=0; i<eventTypes.length; i++){
                const eventKey = eventTypes[i] + "Checked"

                if (eventKey in eventsChecked){
                    if (eventsChecked[eventKey] === true){
                        canTest = true
                        break
                    }
                }
            }
        }

        return canTest
    }

    sendTestRequest = () => {
        const webhookURL = this.props.fileInformation[this.props.editID].webhookURL;

        const isError = () => this.setState({ testing: false, testResults: <p style={{color: "red", fontWeight: "bold"}}>There was an error testing your webhook. Please try again</p> })

        fetch(API_Root + "api/api-webhook-test/", {
            method: "POST",
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                webhookURL: webhookURL,
                testType: this.state.testType
            })
        }).then(res => {
            if (res.status === 200){
                res.json().then(data => {
                    this.setState({
                        testing: false,
                        testResults: <div style={{marginTop: "10px"}}>
                            <div style={{borderRadius: "8px 8px 0 0", border: "1px solid lightgrey", padding: "6px 16px 6px 16px", backgroundColor: "whitesmoke"}}>
                                <div className="fileIDHeading" style={{fontWeight: "600"}}>Response from the Webhook</div>
                            </div>
                            <ReactJson src={data} name={null} theme="ocean" displayObjectSize={false}/>
                        </div>
                    })
                }).catch(err => isError())
            } else {
                isError()
            }
        }).catch(err => isError())
    }

    handleChange = (event, {value}) => {
        this.setState({ testType: value })
    }

    render() {
        if (this.canTestWebhook()){
            return (
                <div style={{marginTop: "16px"}}>
                    <BannerAlert
                        type="display"
                        header="Send a test request to this webhook"
                        content={<span>Choose request {' '}
                            <Dropdown   inline
                                        options={[{key: 0, text: 'Read', value: 'read'}, {key: 1, text: 'Create', value: 'create'}]}
                                        value={this.state.testType}
                                        onChange={this.handleChange}
                            />
                        </span>}
                        action={<Button color="blue" icon="fork" onClick={this.sendTestRequest} loading={this.state.testing} content="Test Webhook"/>}
                    />
                    {this.state.testResults}
                </div>
            )
        } else {
            return (
                <div style={{marginTop: "16px"}}>
                    <BannerAlert
                        type="warning"
                        header="This Webhook will not work with its current settings"
                        content="Please save a valid URL and/or save at least one event to be called" />
                </div>
            )
        }

    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    editID: state.mainState.editID
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FooterMessage)