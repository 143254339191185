import React, {Component} from 'react'
import {getSupportMessage} from "./getSupportMessage";
import BannerAlert from "../BannerAlert";
import UpgradeButton from "../../InfoPages/Profile/UpgradeButton"
import {gunaldiStyles} from "../GunaldiDesignStyles";

class SupportTierMessage extends Component {
    showUpgrade = () => ["free", "pro", "team", "business"].includes(this.props.tier)

    render() {
        const header = "You are on the " + this.props.tier.toUpperCase() + " plan"
        const supportMessageObject = getSupportMessage(this.props.tier)

        if (this.props.page === "support"){
            return (
                <div>
                    <BannerAlert
                        type="display"
                        icon="bx bx-support"
                        header={header}
                        content={<div>
                            <div>{supportMessageObject.timeResponse}</div>
                            <div>{supportMessageObject.supportHours}</div>
                            {this.showUpgrade() ? <div>Upgrade if you want a faster response time</div> : null}
                        </div>}
                        action={this.showUpgrade() ? <UpgradeButton /> : null}
                    />
                </div>
            )
        } else {
            return (
                <div>
                    <p style={{fontSize: "16px", color: gunaldiStyles.subText}}>{header}</p>
                    <ul>
                        <li>{supportMessageObject.timeResponse}</li>
                        <li>{supportMessageObject.supportHours}</li>
                    </ul>
                    {
                        this.showUpgrade() ?
                        <div>
                            <div style={{fontSize: "16px", color: gunaldiStyles.subText, marginTop: "16px", marginBottom: "16px"}}>Upgrade if you want a faster response time</div>
                            <div>
                                <UpgradeButton/>
                            </div>
                        </div>:
                            null
                    }
                </div>
            )
        }
    }
}

export default SupportTierMessage