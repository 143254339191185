import React, {Component} from 'react'
import { Button } from 'semantic-ui-react'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import { push } from 'react-router-redux'
import { connect } from 'react-redux'

class NewUserActionButtonsRow extends Component {
    goToLink = (link) => this.props.navigateTo(link)

    render() {
        return (
            <div className="row">
                <div className="col-sm-4">
                    <Button
                            onClick={e => this.goToLink("/api?menuItem=upload")}
                            content="Use a spreadsheet as an API"
                            icon="code"
                            style={{height:'48px', backgroundColor: gunaldiStyles.mainGreen, color: "white"}}
                    />
                </div>
                <div className="col-sm-4">
                    <Button
                            onClick={e => this.goToLink("/import")}
                            content="Create a spreadsheet importer"
                            icon="table"
                            style={{height:'48px', backgroundColor: gunaldiStyles.orange, color: "white"}}
                    />
                </div>
                <div className="col-sm-4">
                    <Button
                            onClick={e => this.goToLink("/teams")}
                            content="Invite your team members"
                            icon="group"
                            style={{height:'48px', backgroundColor: gunaldiStyles.subText, color: "white"}}
                    />
                </div>
            </div>
        )
    }
}

const mapActionsToProps = {
    navigateTo: push
}

export default connect(null, mapActionsToProps)(NewUserActionButtonsRow)