import React, {Component} from 'react'

class DatabaseDisplayInfo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>

            </div>
        )
    }
}

export default DatabaseDisplayInfo