import React, {Component} from 'react'
import {isMobile} from "react-device-detect";
import IconLabel from "../SharedComponents/IconLabel";
import RulesRow from "./RulesRow";

class SQLRulesModalBody extends Component {
    render() {
        return (
            <div>
                <IconLabel
                    icon="bx bx-list-check"
                    label="Query Writing Rules"
                    style={{ fontWeight: "700" }}
                />
                <div style={{ marginTop: "24px" }}>
                    <RulesRow
                        rule={<span>1. Query must begin with <code>select</code></span>}
                        example={<span><b>select</b> <code>*</code> <b>from</b> <code>file1</code></span>}
                    />
                    <RulesRow
                        rule={<span>2. Column names are <b>CASE SENSITIVE</b></span>}
                        example={<span>Since you can have columns such <b>Order_Dt</b> and <b>order_dt</b> in your file</span>}
                    />
                    <RulesRow
                        rule={<span>3. Refer to your files by the name indicated after you upload them<br/>Depending on the order of the import, the name will be either  <code>file1</code>, <code>file2</code>, or <code>file3</code><br/>You will see this mapping of Files name to Table name after you have uploaded your files</span>}
                        example={<span><b>select</b> <code>file1.Name, file2.Age</code> <b>from</b> <code>file1, file2</code> <b>where</b> <code>file1.Name=file2.Name</code></span>}
                    />
                    <RulesRow
                        rule={<span>4. Columns with spaces need double quotes around them</span>}
                        example={<span><b>select</b> <code>file1."F Name" </code> <b>from</b> <code>file1, file2</code> <b>where</b> <code>file1."F Name"=file2.Name</code></span>}
                    />
                    <RulesRow
                        rule={<span>5. Use <code>NULL</code> when referring to any blank values</span>}
                        example={<span><b>select</b> <code>first_name</code> <b>from</b> <code>file1</code> <b>where</b> <code>first_name IS NULL</code></span>}
                    />
                    <RulesRow
                        rule={<span>6. If an obvious filter is not working, the data type (such as numbers) may not be read properly. For e.g., if the below query fails</span>}
                        example={<span><b>select</b> <code>age</code> <b>from</b> <code>file1</code> <b>where</b> <code>age > 32</code></span>}
                    />
                    <div style={{ marginBottom: "64px", border: "2px solid #F0F0F0", borderRadius: "4px", padding: "16px"}}>
                        If you have any more questions, you can email us at <b>info@apispreadsheets.com</b>
                    </div>
                </div>
            </div>
        )
    }
}

export default SQLRulesModalBody