import React, {Component} from 'react'
import TierRestrictionBanner from "./TierRestrictionBanner";
import DocsImage from "../DocsImage";

class ActionsTeamInfo extends Component {
    render() {
        const readMore = <span className="linkStyle" onClick={e => this.props.setBothMenuItems("teams")}>read more about teams and roles here</span>
        return (
            <div>
                <div style={{marginTop: "-8px", marginBottom: "16px"}}>
                    <TierRestrictionBanner plan="team" payment="teams"/>
                </div>
                <div className="docsSection">
                    <p>Click the <b>Team Info</b> action in the menu bar to share your File or API with a Team</p>
                    <p>You can {readMore}</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Sharing File/API when you haven't created a team</p>
                    <p>You can share any File/API with your team. Before sharing the File/API, you need to create a team</p>
                    <p>You can create a team directly on your Files page</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/TeamInfo-Create.png" alt='Create team' width="70%" />
                    <p>After you click create the team, the File will automatically be shared with everyone on that team</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/TeamInfo-CreateSuccess.png" alt='Create team success' width="70%" />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Sharing File/API with an already created team</p>
                    <p>If you have already created a Team, then you can simply pick that team on the <b>Team Info</b> screen</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/TeamInfo-Selection.png" alt='Create team' width="70%" />
                    <p>The File will then be shared with everyone on that team</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/TeamInfo-SelectionSuccess.png" alt='Create team' width="70%" />
                </div>
            </div>
        )
    }
}

export default ActionsTeamInfo