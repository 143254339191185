import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../../../../SharedComponents/Modal"
import SSLInfoModalBody from "./SSLInfoModalBody"
import isObjectEmpty from "../../../../Constants/isObjectEmpty";

class SSLInfoModal extends Component {
    render() {
        let body = null

        if (this.props.modalInfo !== null){
            if (!isObjectEmpty(this.props.modalInfo)){
                body = <SSLInfoModalBody deleteSSLInfo={this.props.deleteSSLInfo}/>
            }
        }

        return (
            <ModalApp name="sslInfoModal"
                      header={null}
                      body={body}
                      size="medium"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(SSLInfoModal)