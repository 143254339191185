import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import {modifyEditID} from "../Actions/ModifyEditID";
import { Button } from 'semantic-ui-react'
import MenuSegment from "./FilesID/FilesIDDisplayMenu/MenuSegment"

class ImportSheets extends Component {
    constructor(props) {
        super(props);

        this.importSheetsModal = this.importSheetsModal.bind(this);
    }

    importSheetsModal(){
        this.props.modifyEditID(this.props.pkID);
        this.props.toggleModal("importSheets");
    }

    render() {
        return (
            <MenuSegment content="Change Sheet" basic color='purple' icon='file alternate outline' onClick={this.importSheetsModal} fluid/>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyEditID: modifyEditID
}

export default connect(mapStateToProps, mapActionsToProps)(ImportSheets)