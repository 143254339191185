import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import API_Root from "../../../../Constants/API_Root";
import {modifyEditOptionsForBackend} from "./modifyEditOptionsForBackend";
import {modifyImportSheetRead} from "../../../../Actions/ModifyImportSheetRead";

class SheetNameDelimiterDropdown extends Component {
    constructor(props){
        super(props);

        this.state = {
            reading: false
        }
    }

    updateDataType = (event, { value }) => {
        this.setState({ reading: true })

        const isExcel = this.props.editOptions.sheetRead !== null;

        const currEditOptions = JSON.parse(JSON.stringify(this.props.editOptions))

        let currValue;
        if (isExcel){
            currValue = currEditOptions.sheetRead
        } else {
            currValue = currEditOptions.delimiter
        }

        if (currValue !== value){
            if (isExcel){
                currEditOptions.sheetRead = value
            } else {
                currEditOptions.delimiter = value
            }

            // Update the rows and columns
            const sheetRead = currEditOptions.sheetRead

            const backendOptions = modifyEditOptionsForBackend(currEditOptions)

            const error = () => this.setState({ reading: false }, () => alert("There was an error changing your setting. Try again"))
            fetch(API_Root + "api/read-file-with-edit-options/", {
                method: "POST",
                body: JSON.stringify({
                    pk: this.props.pk,
                    sheetRead: sheetRead,
                    editOptions: backendOptions
                })
            }).then((res) => {
                if (res.status === 200) {
                    res.json().then(data => {
                        currEditOptions.rows = data.rows
                        currEditOptions.columns = data.columns

                        const currImportSheetRead = JSON.parse(JSON.stringify(this.props.importSheetRead))
                        currImportSheetRead[this.props.pkID] = sheetRead

                        this.props.modifyImportSheetRead(currImportSheetRead)
                        this.setState({ reading: false }, () => this.props.modifyCustomImportSettings("editOptions", currEditOptions))
                    }).catch(err => error())
                }
                else {
                    error()
                }
            }).catch(err => error())
        }
    }

    render() {
        if (this.props.editOptions.columns.length > 0){
            const isExcel = this.props.editOptions.sheetRead !== null;
            let dropdownOptions;
            let value;

            if (isExcel){
                dropdownOptions = this.props.editOptions.sheetNames.map((x, idx) => { return { key: idx, text: x, value: x }})
                value = this.props.editOptions.sheetRead
            } else {
                dropdownOptions = [
                    {key: 1, text: 'Comma (,)', value: ','},
                    {key: 2, text: 'Semi-Colon (;)', value: ';'},
                    {key: 3, text: 'Pipe (|)', value: '|'},
                    {key: 4, text: 'Tab', value: 'tab'},
                    {key: 5, text: 'Space ( )', value: 'space'},
                ];
                value = this.props.editOptions.delimiter
            }

            return (
                <div style={{textAlign: "right"}}>
                    <h5 className="text-muted" style={{ fontWeight: "bold", marginBottom: "15px"}}>
                        {isExcel ? "Change Excel Sheet" : "Change Delimiter"}
                    </h5>
                    <Dropdown options={dropdownOptions}
                              onChange={this.updateDataType}
                              selection
                              loading={this.state.reading}
                              value={value}
                              fluid />
                </div>
            )
        } else {
            return null
        }

    }
}

const mapStateToProps = (state) => ({
    editOptions: state.mainState.customImportEditOptions,
    importSheetRead: state.mainState.importSheetRead
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings,
    modifyImportSheetRead: modifyImportSheetRead
}

export default connect(mapStateToProps, mapActionsToProps)(SheetNameDelimiterDropdown)