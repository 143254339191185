import React from 'react'
import { Route, Switch } from "react-router";
import LandingPageIndex from './LandingPage/LandingPageIndex'
import NotFound from './InfoPages/NotFound'
import PrivacyPolicy from './InfoPages/PrivacyPolicy'
import TermsAndConditions from './InfoPages/TermsAndConditions'
import ResetPasswordIndex from './Authentication/UserPages/Password/ResetPasswordIndex'
import PricingPage from './InfoPages/Pricing/PricingPage'
import HelpIndex from "./InfoPages/Help/HelpIndex";
import FilesAuthIndex from './FileDnD/FilesAuthIndex'
import DocsPage from './InfoPages/DocsPage/DocsPage'
import GuidesIndex from './Guides/GuidesIndex'
import LoginIndex from './Authentication/UserPages/LogIn/LoginIndex'
import SignUpIndex from './Authentication/UserPages/SignUp/SignUpIndex'
import ContactUsIndex from './InfoPages/ContactUs/ContactUsIndex'
import CompletePaymentIndex from './SharedComponents/Payment/CompletePaymentIndex'
import TutorialsIndex from "./InfoPages/Tutorials/TutorialsIndex";
import FeaturesIndex from "./InfoPages/Features/FeaturesIndex"
import UpgradeIndex from "./InfoPages/UpgradeIndex"
import CaseStudyIndex from "./InfoPages/CaseStudy/CaseStudyIndex"
import DatasetsIndex from "./InfoPages/Datasets/DatasetsIndex"
import DatasetSpecificIndex from "./InfoPages/Datasets/DatasetSpecificIndex"
import PromoIndex from "./InfoPages/Promo/PromoIndex"
import TutorialsDisplayIndex from "./InfoPages/Tutorials/TutorialsDisplayIndex"
import EmailUnsubscribeIndex from "./SharedComponents/EmailUnsubscribeIndex"
import UploadFilesVizSpreadsheetsIndex from "./UploadVizSpreadsheets/UploadFilesVizSpreadsheetsIndex";
import UpdatePaymentIndex from "./SharedComponents/UpdatePayment/UpdatePaymentIndex"
import AuthDropboxFolderConnectIndex from "./UploadFiles/DropboxFolderConnect/AuthDropboxFolderConnectIndex"
import UpdatedPricingPage from "./InfoPages/Pricing/UpdatedPricingPage";
// import QuickstartAPI from "./UploadFiles/QuickstartAPI/QuickstartAPI";
import AuthVisualizationIndex from "./FileDnD/Visualization/AuthVisualizationIndex";
import TeamsIndex from "./Teams/TeamsIndex"
import FilesIDAuthIndex from "./FileDnD/FilesID/FilesIDAuthIndex"
import TeamsIDIndex from "./Teams/TeamsIDIndex"
import FirebaseUserActions from "./Authentication/FirebaseUserActions"
import ProfileAuthIndex from "./InfoPages/Profile/ProfileAuthIndex"
import ImportAuthIndex from "./Import/ImportAuthIndex";
import ImportIDIndex from "./Import/ImportID/ImportIDIndex";
import ImportUploadIndex from "./Import/ImportUpload/ImportUploadIndex";
import ImportUploadDisplayEmbed from "./Import/ImportUpload/ImportUploadDisplayEmbed";
import AuthAddDropboxAccountToDestinations from "./Import/ImportID/CustomImport/Index/AuthAddDropboxAccountToDestinations";
import AppsumoDuplicateAccount from "./AppsumoDuplicateAccount";
import DatabaseIndex from "./Database/DatabaseIndex";
import AuthDatabaseDisplay from "./Database/AuthDatabaseDisplay";
import AuthDatabaseEditAPIIndex from "./Database/Query/AuthDatabaseEditAPIIndex"
import AuthVerifyEmailIndex from "./Authentication/AuthVerifyEmailIndex";
import TestAPIWhitelistIndex from "./SharedComponents/TestAPIWhitelistIndex"
import TableIndex from "./InfoPages/Table/TableIndex";
import CacheTableIndex from "./FileDnD/CachingLayer/CacheTableIndex";
import APIAuthIndex from "./App/API/APIAuthIndex"
import APIDocsIndex from "./App/Docs/APIDocsIndex"
import ImporterDocsIndex from "./App/Docs/ImporterDocsIndex";
import ImporterLandingPageIndex from "./LandingPage/ImporterLandingPage/ImporterLandingPageIndex";
import ImporterPricingPage from "./InfoPages/Pricing/ImporterPricingPage";
import ImportEditIndex from "./Import/ImportEdit/ImportEditIndex";
import SQLIndex from "./SQL/SQLIndex"
import UtilitiesAuthIndex from "./Utilities/UtilitiesAuthIndex";
import TestFetchBtn from "./SharedComponents/TestFetchBtn";
import DisplayCalculatorIndex from "./MakeCalculator/DisplayCalculatorIndex";

export default ({ childProps }) =>
    <Switch>
        <Route exact path="/calculator/:id/" component={DisplayCalculatorIndex} />
        <Route exact path='/test-fetch-btn' component={TestFetchBtn} />
        <Route exact path='/' component={LandingPageIndex} />
        <Route exact path='/table/:id' component={TableIndex} />
        <Route exact path='/cache-table/:id' component={CacheTableIndex} />
        <Route exact path="/upload" component={LandingPageIndex} />
        <Route exact path="/dashboard/:id/" component={AuthVisualizationIndex} />
        {/*<Route exact path="/quickstart-api" component={QuickstartAPI} />*/}
        <Route exact path="/upload-files-for-viz-spreadsheets" component={UploadFilesVizSpreadsheetsIndex} />
        <Route exact path='/files' component={FilesAuthIndex} />
        <Route exact path='/files/:id' component={FilesIDAuthIndex} />
        <Route exact path='/contact-us' component={ContactUsIndex} />
        <Route exact path='/reset-password/:id' component={ResetPasswordIndex} />
        <Route exact path='/promo' component={PromoIndex} />
        <Route exact path='/promo/amaf/' component={PromoIndex} />
        <Route exact path="/login" component={LoginIndex} />
        <Route exact path="/signup" component={SignUpIndex} />
        <Route exact path='/complete-payment' component={CompletePaymentIndex} />
        <Route exact path='/update-payment-info' component={UpdatePaymentIndex} />
        {/*<Route exact path="/verify-email/:id" componet={AuthVerifyEmailIndex} />*/}
        {/*<Route exact path='/verify-email/:id' component={ContactUsIndex} />*/}
        <Route exact path='/verify-email' component={AuthVerifyEmailIndex} />
        {/*Dumb Components aka Display Only and No Interaction with external data sources*/}
        <Route exact path='/FAQs' component={HelpIndex} />
        <Route exact path='/terms' component={TermsAndConditions} />
        <Route exact path='/features' component={FeaturesIndex} />
        <Route exact path='/privacy' component={PrivacyPolicy} />
        <Route exact path='/pricing' component={PricingPage} />
        <Route exact path="/profile" component={ProfileAuthIndex} />
        <Route exact path='/new-pricing' component={UpdatedPricingPage} />
        <Route exact path="/docs" component={DocsPage} />
        <Route exact path="/api-docs" component={APIDocsIndex} />
        <Route exact path="/import-docs" component={ImporterDocsIndex} />
        <Route exact path="/guides" component={GuidesIndex} />
        <Route exact path="/guides/:id" component={GuidesIndex} />
        <Route exact path="/tutorials" component={TutorialsIndex} />
        <Route exact path="/tutorials/:id" component={TutorialsDisplayIndex} />
        <Route exact path="/upgrade" component={UpgradeIndex} />
        <Route exact path="/case-study/:id" component={CaseStudyIndex} />
        <Route exact path="/datasets" component={DatasetsIndex} />
        <Route exact path="/datasets/:id" component={DatasetSpecificIndex} />
        <Route exact path="/teams" component={TeamsIndex} />
        <Route exact path="/database" component={DatabaseIndex} />
        <Route exact path="/teams/:id" component={TeamsIDIndex} />
        <Route exact path="/importer" component={ImporterLandingPageIndex} />
        <Route exact path="/importer/pricing" component={ImporterPricingPage} />
        <Route exact path="/import" component={ImportAuthIndex} />
        <Route exact path="/import/:id" component={ImportIDIndex} />
        <Route exact path="/import/embed/:id" component={ImportUploadDisplayEmbed} />
        <Route exact path="/import/embed/:id/edit/:id" component={ImportEditIndex} />
        <Route exact path="/import/upload/:id" component={ImportUploadIndex} />
        <Route exact path="/email/unsubscribe/:id" component={EmailUnsubscribeIndex} />
        <Route exact path="/dropbox-folder-connect/" component={AuthDropboxFolderConnectIndex} />
        <Route exact path="/useractions/:id" component={FirebaseUserActions} />
        <Route exact path="/add-dropbox-account-to-destinations" component={AuthAddDropboxAccountToDestinations} />
        <Route exact path="/appsumoduplicate/:id" component={AppsumoDuplicateAccount}/>
        <Route exact path="/database/:id" component={AuthDatabaseDisplay} />
        <Route exact path="/database-edit-api/" component={AuthDatabaseEditAPIIndex} />
        <Route exact path="/test-api-whitelist" component={TestAPIWhitelistIndex} />
        <Route exact path="/api" component={APIAuthIndex} />
        <Route exact path="/sql" component={SQLIndex} />
        <Route exact path="/utilities" component={UtilitiesAuthIndex} />
        <Route component={NotFound}/>
    </Switch>;