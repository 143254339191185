const currencyInfo = {
    usd: {
        symbol: "$",
        text: "US Dollar",
        multiplier: 1
    },
    inr: {
        symbol: "₹",
        text: "Indian Rupee",
        multiplier: 30
    },
    aud: {
        symbol: "AUD$",
        text: "Australian Dollar",
        multiplier: 1.5
    },
    brl: {
        symbol: 'R$',
        text: "Brazilian Real",
        multiplier: 3
    },
    gbp: {
        symbol: "£",
        text: "British Pound",
        multiplier: 1
    },
    sgd: {
        symbol: 'SGD$',
        text: "Singapore Dollar",
        multiplier: 1.3
    },
    eur: {
        symbol: '€',
        text: "Euro",
        multiplier: 1
    },
    php: {
        symbol: '₱',
        text: "Philippine Piso",
        multiplier: 20
    }
}

export default currencyInfo