import React, {Component} from 'react'
import {connect} from 'react-redux'
import ColumnInfoSaveBullet from "./ColumnInfoSaveBullet";
import ColumnInfoSaveButton from './ColumnInfoSaveButton';
import { Button } from 'semantic-ui-react'

class ColumnInfoSaveBox extends Component {
    constructor(props) {
        super(props);

        this.hasInfoChanged = this.hasInfoChanged.bind(this);
        this.discardChanges = this.discardChanges.bind(this);
    }

    discardChanges(e){
        this.props.discardChanges();
    }

    hasInfoChanged(){
        const prevInfo = this.props.fileInformation[this.props.editID].columnInformation;
        const currInfo = this.props.currInfo;

        const prevNameInfo = prevInfo.info;
        const currNameInfo = currInfo.info;
        const prevNames = prevInfo.names;
        const currNames = currInfo.names;

        let allChangeInfo = [];

        let changedRows = 0;

        for (let i=0; i<prevNames.length; i++){
            let changeInfo = [];

            const prevName = prevNames[i];
            const currName = currNames[i];

            if (currName !== prevName){
                changeInfo.push({type: "name", prevValue: prevName, currValue: currName})
            }

            const currType = currNameInfo[currName].type;
            const prevType = prevNameInfo[prevName].type;

            const currDesc = currNameInfo[currName].desc;
            const prevDesc = prevNameInfo[prevName].desc;

            if (currType !== prevType){
                changeInfo.push({type: "type", prevValue: prevType, currValue: currType})
            }

            if (currDesc !== prevDesc){
                changeInfo.push({type: "desc", prevValue: prevDesc, currValue: currDesc})
            }

            allChangeInfo.push(changeInfo)

            if (changeInfo.length > 0){
                changedRows += 1
            }

        }

        return {"changedRows": changedRows, "allChangeInfo": allChangeInfo}
    }

    render() {
        let bullets = [];

        const changedObject = this.hasInfoChanged();
        const changedRows = changedObject.changedRows;
        const allChangeInfo = changedObject.allChangeInfo;

        if (changedRows > 0){
            for (let i=0; i<allChangeInfo.length; i++){
                if (allChangeInfo[i].length > 0){
                    for (let j=0; j<allChangeInfo[i].length; j++){
                        bullets.push(<li key={i.toString() + j.toString()}><ColumnInfoSaveBullet row={i} changeInfo={allChangeInfo[i][j]}/></li>)
                    }
                }
            }

            return (
                <div id="columnInfoSaveBox">
                    {/*<h4 className="thinHeading">Changes made to column information</h4>*/}
                    {/*<ul>*/}
                        {/*{bullets}*/}
                    {/*</ul>*/}
                    {/*<div className="row">*/}
                        {/*<div className="col-sm-6">*/}
                            <ColumnInfoSaveButton currInfo={this.props.currInfo} prevInfo={this.props.prevInfo}/>
                        {/*</div>*/}
                        {/*<div className="col-sm-6">*/}
                            <Button
                                // size="mini"
                                style={{backgroundColor: "#9696A0", color: "white"}}
                                icon="undo" content="Undo" onClick={this.discardChanges} />
                        {/*</div>*/}
                    {/*</div>*/}
                </div>
            )
        }
        else{
            return(
                null
            )
        }



    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    editID: state.mainState.editID
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ColumnInfoSaveBox)