import React, { Component } from 'react';
import NavbarIndex from './SharedComponents/Navbar/NavbarIndex';
import Routes from './Routes';
import withAuthentication from './Authentication/withAuthentication';
import FooterIndex from './SharedComponents/Footer/FooterIndex'
import FeedbackModal from './SharedComponents/Feedback/FeedbackModal'
import LoadingModal from './SharedComponents/LoadingModal'
import EditModal from './EditFile/EditModal'
import ImportSheetsModal from './ImportSheets/ImportSheetsModal'
import ForgetPasswordModal from "./Authentication/UserPages/Password/ForgetPasswordModal";
import ShareModal from './FileDnD/ShareModal'
import PaymentModal from "./SharedComponents/Payment/PaymentModal";
import ContactUsModal from "./InfoPages/ContactUs/ContactUsModal";
import SupportModal from "./SharedComponents/Support/SupportModal";
import HowItWorksModal from "./InfoPages/HowItWorks/HowItWorksModal";
import NeedAuthModal from './Authentication/UserPages/NeedAuthModal';
import AnalyticsModal from "./Analytics/AnalyticsModal";
import ColumnNamesModal from "./FileDnD/ColumnNames/ColumnNamesModal";
import FileDescriptionModal from "./FileDnD/FileDescription/FileDescriptionModal";
import UpgradeModal from './SharedComponents/UpgradeModal'
import QueryModal from "./InfoPages/Query/FullTutorial/QueryModal";
import QueryQuickstartModal from "./InfoPages/Query/Quickstart/QueryQuickstartModal";
import KeysModal from "./FileDnD/KeysModal/KeysModal";
import ImageModal from "./SharedComponents/ImageModal/ImageModal"
import CustomToastContainer from "./SharedComponents/CustomToastContainer"
import CRUDModal from "./InfoPages/Features/FeaturesBody/CRUDModal";
import QuickstartModal from "./InfoPages/QuickstartModal/QuickstartModal";
import GoogleSheetsTokenErrorModal from "./InfoPages/GoogleSheetsTokenErrorModal";
import FixColumnNamesModal from "./FileDnD/FixColumnNames/FixColumnNamesModal"
import LoginModal from "./SharedComponents/LoginModal";
import GoogleSheetsErrorModal from "./InfoPages/GoogleSheetsErrorModal";
import ShowTawkToComponent from "./ShowTawkToComponent"
import BlockRequestsModal from "./FileDnD/BlockRequests/BlockRequestsModal";
import AlertsModal from "./FileDnD/Alerts/AlertsModal";
import WhitelistDomainsModal from "./FileDnD/WhitelistDomains/WhitelistDomainsModal";
import UpdatedPricingModal from "./InfoPages/Pricing/UpdatedPricingModal";
import CreateTeamModal from "./Teams/CreateTeam/CreateTeamModal"
import RolesModal from "./Teams/Roles/RolesModal";
import TeamInfoModal from "./Teams/TeamInfo/TeamInfoModal";
import TeamFileModal from "./FileDnD/TeamFile/TeamFileModal";
import DemoModal from "./SharedComponents/DemoVideo/DemoModal";
import AccountRemoveStepsModal from "./SharedComponents/AccountRemoveStepsModal";
import ReportingModal from "./FileDnD/Reporting/ReportingModal";
import ImportConfigDeleteModal from "./Import/ImportConfigDelete/ImportConfigDeleteModal"
import FileIDReadMore from "./FileDnD/FileIDReadMore";
import AWSModal from "./AWS/AWSModal";
import QuickstartUploadModal from "./QuickstartUpload/QuickstartUploadModal"
import DatabaseModal from "./Database/DatabaseModal";
import DatabaseConnectionErrorModal from "./Database/DatabaseConnectionErrorModal";
import EditDatabaseModal from "./Database/Modals/EditDatabaseModal";
import DeleteDatabaseModal from "./Database/Modals/DeleteDatabaseModal";
import DBTablesAndColumnInfoModal from "./Database/Modals/DBTablesAndColumnInfoModal"
import QueryHelpModal from "./Database/Modals/QueryHelpModal";
import AddNewS3Modal from "./AWS/AddNewS3Modal";
import EditS3Modal from "./InfoPages/Profile/EditS3Modal";
import AddNewDatabaseModal from "./Database/AddNewDatabaseModal";
import PendingInvitesModal from "./Teams/PendingInvitesModal"
import SignUpDemoModal from "./SharedComponents/DemoVideo/SignUpDemoModal";
// import SSLInfoModal from "./InfoPages/Profile/CNAME/SSLCertificate/SSLInfoModal"
import CompletedCelebration from "./SharedComponents/Payment/CompletedCelebration"
import SupportConfirmationModal from "./SharedComponents/Support/SupportConfirmationModal"
// import LovePromoBanner from "./SharedComponents/LovePromoBanner";
import RunMacrosReceiveInformationModal from "./FileDnD/RunMacros/RunMacrosReceiveInformationModal"

class AppAuthentication extends Component {
    componentDidMount(){
        if (localStorage.getItem("source") === null){
            let source = "";
            let url = window.location.href;

            if (url.includes("source=")){
                let srcSubstr = url.substring(url.indexOf("source="), url.length)

                let endIdx = srcSubstr.length;

                if (srcSubstr.includes("&")){
                    endIdx = srcSubstr.indexOf("&")
                }

                source = srcSubstr.substring(srcSubstr.indexOf("=") + 1, endIdx)
            } else {
                source = document.referrer
            }

            localStorage.setItem("source", source)
        }

    }

    render() {
        return (
            <div>
                <RunMacrosReceiveInformationModal/>
                <SignUpDemoModal/>
                <PendingInvitesModal />
                <AddNewDatabaseModal />
                <AWSModal/>
                <FileIDReadMore/>
                <ReportingModal/>
                <CompletedCelebration />
                <TeamFileModal/>
                <RolesModal/>
                <AccountRemoveStepsModal/>
                <CreateTeamModal />
                <UpdatedPricingModal/>
                <WhitelistDomainsModal/>
                <AlertsModal/>
                <BlockRequestsModal/>
                <GoogleSheetsErrorModal/>
                <KeysModal/>
                <HowItWorksModal/>
                <FeedbackModal/>
                <ImportSheetsModal/>
                <EditModal />
                <ShareModal />
                <FileDescriptionModal/>
                <QueryModal/>
                <QueryQuickstartModal/>
                <ImageModal />
                {/*<SSLInfoModal />*/}
                <CRUDModal/>
                <GoogleSheetsTokenErrorModal/>
                <LoadingModal name="loadingModalDeletingFile" header="Deleting your file..." message="Deleting your file..."/>
                <LoadingModal name="loadingModalDropboxRefresh" header="Refreshing your Dropbox file..." message="Refreshing your Dropbox file..."/>
                <LoadingModal name="loadingModalGoogleSheetsRefresh" header="Refreshing your Google Sheets file..." message="Refreshing your Google Sheets file..."/>
                <LoadingModal name="loadingModalDropbox" header="Syncing your Dropbox file..." message="Syncing your Dropbox file..."/>
                <LoadingModal name="loadingModalGoogleSheets" header="Syncing your Google Sheets file..." message="Syncing your Google Sheets file..."/>
                <LoadingModal name="loadingModalFile" header="Preparing your API from your file..." message="Preparing your API from your file..." noClose/>
                <LoadingModal name="loadingModalImportFile" header="Uploading your files..." message="Uploading your files..." noClose/>
                <LoadingModal name="loadingModalImportSheets" header="Reading..." message="Reading Your Additional Sheets" noClose/>
                <LoadingModal name="loadingModalEditFile" header="Re-reading your files..." message="Re-Reading Files with Options" noClose/>
                <LoadingModal name="loadingModalCachingLayer" header="Creating your Caching Layer..." message="Creating your Caching Layer..." noClose/>
                <UpgradeModal />
                <LoginModal/>
                <ForgetPasswordModal/>
                <NeedAuthModal />
                <PaymentModal/>
                <ContactUsModal/>
                <QuickstartModal/>
                <SupportModal/>
                <ImportConfigDeleteModal />
                <DemoModal/>
                <AnalyticsModal/>
                <ColumnNamesModal/>
                <QuickstartUploadModal/>
                <TeamInfoModal/>
                <DatabaseModal/>
                <SupportConfirmationModal />
                <DBTablesAndColumnInfoModal />
                <EditDatabaseModal/>
                <DeleteDatabaseModal/>
                <AddNewS3Modal/>
                <QueryHelpModal/>
                <FixColumnNamesModal />
                <DatabaseConnectionErrorModal/>
                <EditS3Modal />
                    <div>
                        <ShowTawkToComponent/>
                        <CustomToastContainer />
                        {/*<LovePromoBanner/>*/}
                        <NavbarIndex/>
                        <Routes/>
                        <FooterIndex/>
                    </div>
                {/*<NonModalApp />*/}
            </div>
        );
    }
}


export default withAuthentication(AppAuthentication)
