import React, {Component} from 'react'
import { Segment } from 'semantic-ui-react'
import ConnectDomainIllustration from "../../Illustrations/ConnectDomainIllustration.svg"

class ConnectDomain extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Connect Your Own Domain</div>
                <div style={this.props.subheadingStyle}>You can connect your own domain to use to make API requests</div>
                <Segment>
                    <div style={{margin: "0 auto", textAlign: "center"}}>
                        <img src={ConnectDomainIllustration} alt="multiple connect sources to API" style={{width: "50%"}}/>
                    </div>
                </Segment>
            </div>

        )
    }
}

export default ConnectDomain