import React, {Component} from 'react'
import {connect} from 'react-redux'
import NewDatabaseConnection from "./NewDatabaseConnection"
import ModalApp from "../SharedComponents/Modal"
import { Icon } from 'semantic-ui-react'
import isObjectEmpty from "../Constants/isObjectEmpty";

class AddNewDatabaseModal extends Component {
    constructor(props) {
        super(props);
    }

    getDatabaseType = () => {
        let dbType = "MYSQL"
        let isFromImport = false;

        if (this.props.modalInfo !== null){
            if (!isObjectEmpty(this.props.modalInfo)){
                if ("type" in this.props.modalInfo){
                    dbType = this.props.modalInfo.type.toUpperCase()
                }

                if ("isFromImport" in this.props.modalInfo){
                    isFromImport = this.props.modalInfo.isFromImport
                }
            }
        }

        return dbType
    }

    render() {
        return (
            <ModalApp name="addNewDatabaseModal"
                      header={<span style={{fontFamily: "Lato", fontWeight: "700", fontSize: "20px", color: "#161E16"}}>Connect a New Database</span>}
                      body={<div style={{marginTop: "16px"}}><NewDatabaseConnection addFromImport type={this.getDatabaseType()}/></div>}
                      size="large"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(AddNewDatabaseModal)