import React, {Component} from 'react'
import {connect} from 'react-redux'
import UpgradeToTeam from "../UpgradeToTeam"
import WhitelistDomainsRow from "./WhitelistDomainsRow";
import { Button, Icon } from 'semantic-ui-react'
import BlockMessage from "../BlockRequests/BlockMessage"
import API_Root from "../../Constants/API_Root";
import {mapPkAndPkid} from "../../Constants/mapPkAndPkid";
import Loader from 'react-loader-spinner'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

class WhitelistDomainsModalBody extends Component {
    constructor(props){
        super(props);

        this.state = {
            domains: [""],
            history: {
                domains: [""]
            },
            savingDomains: false,
            error: null,
            fetching: false
        }
    }

    componentDidMount(){
        const isBusiness = this.props.tier === "business" || this.props.tier === "enterprise";
        const stopFetch = () => this.setState({ fetching: false })

        if (isBusiness){
            this.setState({ fetching: true })

            fetch(API_Root + "api/set-get-whitelist-domains-api-files/", {
                method: "POST",
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    filePK: mapPkAndPkid(this.props.filePK, "id", this.props.editID),
                    get: true
                })
            }).then((res) => {
                if (res.status === 200){
                    res.json().then((data) => {
                        this.setState({
                            domains: data.whitelistDomains,
                            history: {
                                domains: data.whitelistDomains
                            },
                            fetching: false
                        })
                    }).catch(err => stopFetch())
                }
                else{
                    stopFetch()
                }
            }).catch(err => stopFetch())
        }
    }

    storeWhitelistDomains = () => {
        this.setState({ savingDomains: true })

        const stopFetch = (error, header, list=null) => this.setState({ savingDomains: false, error: <BlockMessage error={error} header={header} list={list}/> })

        fetch(API_Root + "api/set-get-whitelist-domains-api-files/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                whitelistDomains: this.state.domains.join("|"),
                filePK: mapPkAndPkid(this.props.filePK, "id", this.props.editID)
            })
        }).then((res) => {
            if (res.status === 201){
                stopFetch(false, "Following domains were successfully whitelisted!", this.state.domains)
                this.setState({
                    history: {domains: this.state.domains}
                })
            }
            else{
                stopFetch(true, "There was an error whitelisting your domains. Please try again or contact us at info@apispreadsheets.com if the error persists")
            }
        }).catch(err => stopFetch(true, "There was an error to whitelisting your domains. Please try again or contact us at info@apispreadsheets.com if the error persists"))
    }

    handleInputChange = (value, idx) => {
        const currDomains = JSON.parse(JSON.stringify(this.state.domains))
        currDomains[idx] = value;

        this.setState({ domains: currDomains })
    }

    removeDomain = (idx) => {
        const currDomains = JSON.parse(JSON.stringify(this.state.domains))
        currDomains.splice(idx, 1)

        this.setState({ domains: currDomains })
    }

    addDomain = () => {
        const currDomains = JSON.parse(JSON.stringify(this.state.domains))
        currDomains.push("")

        this.setState({ domains: currDomains })
    }

    getDivStyle = (isBusiness) => {
        let style = {marginTop: "16px"}

        if (!isBusiness){
            style['pointerEvents'] = "none"
            style['opacity'] = "0.5"
            // style['cursor'] = "not-allowed"
        }

        return style
    }

    haveDomainsChanged = () => {
        let haveChanged = false;
        if (this.state.domains.length !== this.state.history.domains.length){
            haveChanged = true;
        } else{
            for (let i=0; i<this.state.domains.length; i++){
                if (this.state.domains[i].trim() !== this.state.history.domains[i].trim()){
                    haveChanged = true;
                    break
                }
            }
        }

        return haveChanged
    }

    reset = () => {
        const histDomains = JSON.parse(JSON.stringify(this.state.history.domains))

        this.setState({
            domains: histDomains
        })
    }

    getBlankDomains = () => {
        let blanks = []
        for (let i=0; i<this.state.domains.length; i++){
            if (this.state.domains[i].trim() === ""){
                blanks.push(this.state.domains[i])
            }
        }

        return blanks
    }

    render() {
        const isBusiness = this.props.tier === "business" || this.props.tier === "enterprise";

        let domainRows;

        if (!this.state.fetching){
            domainRows = [];

            for (let i=0; i<this.state.domains.length; i++){
                domainRows.push(<div key={i} style={{marginBottom: "16px"}}><WhitelistDomainsRow domainValue={this.state.domains[i]}
                                                     idx={i}
                                                     handleInputChange={this.handleInputChange}
                                                     removeDomain={this.removeDomain}/></div>)
            }
        } else {
            domainRows = <Loader
                            type="TailSpin"
                            color="black"
                            height="20"
                            width="20"
                        />
        }

        return (
            <div>
                {isBusiness ? null : <div style={{marginTop: "8px"}}><UpgradeToTeam higherTier="whitelistDomains" header="You can only Whitelist Domains on the Business or Higher Tier"/></div>}
                <div style={isBusiness ? null : {"cursor": "not-allowed"}}>
                    <div style={this.getDivStyle(isBusiness)}>
                        <div className="row" style={{marginTop: "16px", marginBottom: "16px"}}>
                            <div className="col-sm-10">
                                <div style={{fontWeight: "500", fontSize: "14px", fontFamily: 'Lato', color: "#161E16"}}>Requests will ONLY be allowed from these Domains</div>
                            </div>
                            <div className="col-sm-2">
                                {this.haveDomainsChanged() ? <span style={{color: 'red', cursor: "pointer", fontSize: "10px", textAlign: 'right'}} onClick={this.reset}><Icon name="undo"/> Reset</span> : null}
                            </div>
                        </div>
                        {domainRows}
                        <span style={{backgroundColor: "#5499F8", padding: "6px 2px 6px 6px", borderRadius: "4px", cursor: 'pointer', textAlign: "center"}} onClick={this.addDomain}>
                            <Icon name="plus" style={{color: 'white', fontSize: "14px", textAlign: "center"}} />
                        </span>
                        <div style={{marginTop: "32px"}}>
                            <Button onClick={this.storeWhitelistDomains}
                                    loading={this.state.savingDomains}
                                    disabled={this.getBlankDomains().length === this.state.domains.length}
                                    style={gunaldiStyles.btnGreen}
                                    content="Whitelist URLs" />
                        </div>
                        {this.state.error}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier,
    editID: state.mainState.editID,
    filePK: state.mainState.filePK
})

export default connect(mapStateToProps, null)(WhitelistDomainsModalBody)