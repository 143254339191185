import React, {Component} from 'react'
import {connect} from "react-redux";
import ShowTawkTo from "./ShowTawkTo";

class ShowTawkToComponent extends Component {
    render() {
        return (
            <ShowTawkTo tier={this.props.tier}/>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(ShowTawkToComponent)