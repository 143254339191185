export const isUserAMacroUser = (userPricing) => {
    let isUserMacro = false;

    try{
        if ("enterpriseInfo" in userPricing){
            if ("macros" in userPricing["enterpriseInfo"]) {
                isUserMacro = userPricing["enterpriseInfo"]['macros']

                if (isUserMacro === undefined || isUserMacro === null){
                    isUserMacro = false
                }
            }
        }
    } catch (e) {

    }

    return isUserMacro

}