import React, {Component} from 'react'
import {connect} from 'react-redux'
import CachingLayerTimer from "./CachingLayerTimer";
import isObjectEmpty from "../../Constants/isObjectEmpty";
import API_Root from "../../Constants/API_Root";
import CopyURLSegment from "../../SharedComponents/CopyURLSegment";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import DeleteCachingLayerBtn from "./DeleteCachingLayerBtn";

class CachingLayerDisplay extends Component {
    constructor(props){
        super(props);
    }

    getCachingLayerURL = () => {
        const fileInfo = this.props.fileInformation[this.props.pkID]

        let baseURL = API_Root.includes("8000") ? "http://localhost:8080/" : "https://cache.apispreadsheets.com/"

        baseURL += "cache/" + fileInfo.fileHash + "/" + this.props.info.cacheId

        if (!isObjectEmpty(this.props.info.authInfo)){
            if ('access_key_list' in this.props.info.authInfo && 'secret_key_list' in this.props.info.authInfo){
                // augment the url with access and secret keys
                baseURL += "/?accessKey=" + fileInfo.accessKey + "&secretKey=" + fileInfo.secretKey
            }
        }

        return baseURL
    }

    render() {
        const cacheLayerURL = this.getCachingLayerURL();
        let securityFeatures = null;
        let domains = [];
        if ('whitelist_domains' in this.props.info.authInfo ){
            domains = this.props.info.authInfo['whitelist_domains'].split("|")
        }

        if (domains.length > 0){
            const descStyle = {fontSize: "14px", color: gunaldiStyles.subText}

            securityFeatures = <div style={{marginTop: "24px"}}>
                <div style={{fontFamily: "Lato", fontSize: '16px', fontWeight: "700", color: gunaldiStyles.mainText}}>Security Features Enabled</div>
                    <div style={descStyle}>You can only request the data from these domains: {domains.join(", ")}</div>
                </div>
        }

        return (
            <div>
                <div style={{marginBottom: "24px"}}>
                    <CopyURLSegment url={cacheLayerURL} header="API URL for Caching Layer"/>
                </div>
                <div style={{marginBottom: "24px"}}>
                    <CachingLayerTimer
                        expiring={this.props.info.expiring}
                    />
                </div>
                <div>
                    <div style={{fontFamily: "Lato", fontSize: '16px', fontWeight: "700", color: gunaldiStyles.mainText}}>View data in caching Layer</div>
                    <div style={{fontSize: "14px", fontWeight: "700"}}><a href={"/cache-table/cache?url=" + cacheLayerURL} target="_blank" rel="noopener noreferrer">Click here to view data</a></div>
                </div>
                {securityFeatures}
                <div style={{marginTop: "60px"}}>
                    <DeleteCachingLayerBtn
                        cacheId={this.props.info.cacheId}
                        resetState={this.props.resetState}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CachingLayerDisplay)