import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../../SharedComponents/Modal"
import DBTablesAndColumnInfoModalBody from "./DBTablesAndColumnInfoModalBody"

class DBTablesAndColumnInfoModal extends Component {
    render() {
        return (
            <ModalApp name="dbTablesAndColumnInfoModal"
                      header={null}
                      size="large"
                      body={<DBTablesAndColumnInfoModalBody/>}
            />
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DBTablesAndColumnInfoModal)