import React, {Component} from 'react'
import {connect} from 'react-redux'
import getURLParam from "../../Constants/getURLParam";
import API_Root from "../../Constants/API_Root";
import Loader from 'react-loader-spinner'
import { Message } from 'semantic-ui-react';
import ImportUploadDisplay from "./ImportUploadDisplay";
import ImportUploadNavbar from "./ImportUploadNavbar";
import ImportUploadFilesDisplay from "./ImportUploadFilesDisplay";
import ImportUploadName from "./ImportUploadName";
import IntroUploadName from "./IntroUploadName";
import {modifyCustomImportSettings} from "../../Actions/ModifyCustomImportSettings";
import {getValueFromInfoObject} from "./getValueFromInfoObject";
import {toggleModal} from "../../Actions/ToggleModal";
import ImportUploadLoginModal from "./ImportUploadLoginModal"
import PageHeader from "../../SharedComponents/PageHeader";
import {getUploadFileForMessage} from "./getUploadFileForMessage";

class ImportUploadIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: true,
            info: null,
            importName: localStorage.getItem("importName"),
            errorHeader: "",
            errorMsg: <span> </span>
        }

    }

    componentDidMount(){
        const error = (header="There was an error setting up this import page! Please try again", msg=<span>Please refresh and try again. Contact us at <a href="mailto:support@apispreadsheets.com">support@apispreadsheets.com</a> if this error persists.</span>) => {
            this.setState({ fetching: false, info: null, errorHeader: header, errorMsg: msg })
        }

        const importKey = getURLParam(window.location.href);

        fetch(API_Root + "api/get-import-sheet-info/", {
            method: "POST",
            headers: {Accept: 'application/json, text/plain, */*',},
            body: JSON.stringify({
                importKey: importKey
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then(data => {
                    this.props.modifyCustomImportSettings("info", data)

                    this.setState({
                        fetching: false,
                        info: data
                    })

                    const isPrivate = getValueFromInfoObject("private", data, "importOptions")

                    if (isPrivate){
                        const rememberImporterLogin = localStorage.getItem("rememberImporterLogin")

                        if (rememberImporterLogin)
                        this.props.toggleModal("importUploadLoginModal")
                    }
                }).catch(err => error())
            } else if (res.status === 404){
                this.setState({ fetching: false }, () => this.import404Config.click())
            } else {
                error()
            }
        }).catch(err => error())
    }

    changeImportName = (importName) => this.setState({ importName: importName })

    render() {
        let body = null;
        let removeBranding = false

        if (this.state.fetching){
            body =
                <div style={{padding: '100px 0', textAlign: 'center'}}>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4>Setting up your import page...</h4>
                </div>
        } else{
            if (this.state.info === null){
                body =
                    <div style={{padding: '100px 0', textAlign: 'center'}}>
                        <Message negative header={this.state.errorHeader}
                                 content={this.state.errorMsg}
                        />
                    </div>
            } else {
                const importKey = getURLParam(window.location.href)
                removeBranding = getValueFromInfoObject("removeBranding", this.state.info, "appearance")

                if (removeBranding === null){
                    removeBranding = false
                }

                body =
                    <div>
                        {
                            this.state.importName === null ?
                                <div style={{padding: "50px 0"}}>
                                    <IntroUploadName
                                        info={this.state.info}
                                        changeImportName={this.changeImportName}
                                    />
                                </div> :
                                <div>
                                    <ImportUploadLoginModal importHash={importKey}/>
                                    <div style={{marginBottom: "24px"}}>
                                        <PageHeader
                                            importer
                                            header="Upload Your Files"
                                            desc={<span>Uploader published by <span style={{color: "cornflowerblue"}}>{getUploadFileForMessage("", this.state.info)}</span></span>}
                                        />
                                    </div>
                                    {
                                        this.props.matchColumns ? null : <ImportUploadName info={this.state.info} />
                                    }
                                    <ImportUploadDisplay info={this.state.info}
                                                         importKey={importKey}
                                                         embed={false}
                                    />
                                    <div style={{marginTop: "24px", marginBottom: "240px"}}>
                                        <ImportUploadFilesDisplay info={this.state.info} importKey={importKey} />
                                    </div>
                                </div>
                        }
                    </div>
            }
        }

        return (
            <div style={{minHeight: '100vh'}}>
                <a href="/cannot-find-import-configuration" style={{display: "none"}} ref={import404Config => this.import404Config = import404Config}>Read more</a>
                {removeBranding ? null : <ImportUploadNavbar info={this.state.info}/>}
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    matchColumns: state.mainState.customImportMatchColumns
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ImportUploadIndex)