import React, {Component} from 'react'
import Loader from 'react-loader-spinner'
import { Icon } from 'semantic-ui-react'
import API_Root from "../Constants/API_Root";

class EmailUnsubscribeIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            unsubscribing: true,
            error: false
        }
    }

    componentDidMount(){
        const { id } = this.props.match.params;

        fetch(API_Root + "api/unsubscribe-from-product-emails/", {
            method: "POST",
            body: JSON.stringify({
                email: id,
            })
        }).then((res) => {
            if (res.status === 200){
                this.setState({ unsubscribing: false, error: false })
            }
            else{
                this.setState({ unsubscribing: false, error: true })
            }
        }).catch(err => this.setState({ unsubscribing: false, error: true }))
    }

    render() {
        const { id } = this.props.match.params;

        if (this.state.unsubscribing){
            return (
                <div className="emailUnsubscribeIndex">
                    <h4>Unsubscribing { id } from API Spreadsheets emails...</h4>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                </div>
            )
        } else {
            let emojiStyle = null;
            if (this.state.error){
                return (
                    <div className="emailUnsubscribeIndex">
                        <h4><Icon name="warning" style={{color: 'red'}}/> {id} could not be unsubscribed. Please email us at support@apispreadsheets.com to be unsubscribed.</h4>
                    </div>
                )
            } else {
                return (
                    <div className="emailUnsubscribeIndex">
                        <h4>{id} successfully unsubscribed!
                            <span role="img" aria-label="smile" style={emojiStyle}>😀</span>
                            <span role="img" aria-label="confetti" style={emojiStyle}>🎉</span>
                            <span role="img" aria-label="heart" style={emojiStyle}>❤️</span>
                            <span role="img" aria-label="beer" style={emojiStyle}>🍻</span>
                            <span role="img" aria-label="poppers" style={emojiStyle}>🎊</span>
                        </h4>
                    </div>
                )
            }
        }
    }
}

export default EmailUnsubscribeIndex