import React, {Component} from 'react'
import {Button, Icon, Message} from "semantic-ui-react";
import API_Root from "../Constants/API_Root";
import isObjectEmpty from "../Constants/isObjectEmpty";
import UpdateCreditCardDetailsLinkBtn from "./UpdateCreditCardDetailsLinkBtn";
import Verify3DSecurePaymentBtnIndex from "./Verify3DSecurePaymentBtnIndex";
import { connect } from 'react-redux'
import {toggleToast} from "../Actions/ToggleToast";

class FailedPaymentMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message:false,
            failDt:'',
            stripe3DSecureInfo: {}
        }
    }


    componentDidMount() {
        // if failDt and not 3d secure -> need payment update
        // if failDt and 3d secure -> need to do 3d secure
        // if not failDt and 3d secure -> need to do 3d secure

        const email = localStorage.getItem("email")
        const token = localStorage.getItem("token")

        if (email !== null && token !== null){
            const isError = () => this.setState({message:false, failDt:''})

            fetch(API_Root + "api/get-user-fail-date/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                })
            }).then(res => res.json())
                .then((data)=> {
                    let failDt = data.failDt
                    let stripe3DSecureInfo = data.stripe3DSecureInfo;

                    if (failDt !== "1970-01-01" || !isObjectEmpty(stripe3DSecureInfo)){
                        this.setState({message:true,failDt:failDt,stripe3DSecureInfo:stripe3DSecureInfo})
                    }
                }).catch(err => isError()).catch(err => isError())
        }
    }

    render() {
        if (this.state.message){
            let failDt = this.state.failDt
            let stripe3DSecureInfo = this.state.stripe3DSecureInfo;

            let msgHeader = ""
            let msgDesc = ""
            let secureHeader = "Your default payment method needs additional verification"
            let failHeader = "The payment for your Monthly Subscription did not go through :("
            let btnRow = null;
            let verifyBtn = <Verify3DSecurePaymentBtnIndex stripe3DSecureInfo={stripe3DSecureInfo} toggleToast={this.props.toggleToast}/>;

            if (failDt === "1970-01-01"){
                if (!isObjectEmpty(stripe3DSecureInfo)){
                    let plan = ""
                    if ("pricingPlan" in stripe3DSecureInfo){
                        plan = stripe3DSecureInfo['pricingPlan']
                    }

                    msgHeader = secureHeader + " to complete your " + plan.toUpperCase() + " subscription"

                    btnRow = verifyBtn
                }
            } else {
                msgHeader = failHeader
                const updateBtn = <UpdateCreditCardDetailsLinkBtn size="large" bgColor="navy" textColor="white" text={<span><Icon style={{color:'white'}} name="payment" /> Update Payment Info</span>}/>

                if (!isObjectEmpty(stripe3DSecureInfo)){
                    msgDesc = secureHeader + ". Or you can update your payment method. "
                    btnRow = <span>{verifyBtn} {updateBtn}</span>
                } else {
                    msgDesc = "Your payment method needs to be updated. "
                    btnRow = <span>{updateBtn}</span>
                }

                let failDtDate = new Date(failDt + " 23:00:00+0000")
                let currDt = new Date()

                let difference = failDtDate.getTime() - currDt.getTime();
                let days = Math.ceil(difference/ (1000 * 3600 * 24));

                let s = days === 1 ? "" : "s"

                msgDesc += "You have " + days.toString() + " day" + s + " to take further action. Your account benefits will expire on " + failDtDate.toLocaleDateString() + " at " + failDtDate.toLocaleTimeString().replace(":00","")
            }

            return (
                <div>
                    <Message negative>
                        <Message.Header>{msgHeader}</Message.Header>
                        <p>{msgDesc}</p>
                        {btnRow}
                    </Message>
                </div>
            )
        }else{
            return null
        }


    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(FailedPaymentMessage)