import React, {Component} from 'react'
import DocsImage from "../../DocsImage"

class Destinations extends Component {
    render(){
        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">Introduction</p>
                    <p>You can select external accounts where you want the imported spreadsheet data to be stored to</p>
                    <DocsImage
                        src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/SpreadsheetImporter/DestinationsTab.png"
                        width="100%"
                        alt="View Destinations API Spreadsheets"
                    />
                    <p>After making any changes, don't forget to <b>Save Destinations</b></p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Destinations-Save.png"
                        width="100%"
                        alt="Save Destinations API Spreadsheets"
                    />
                    <p>Currently, imported spreadsheet data can be saved to the following</p>
                    <ol>
                        <li><a href="#googleDrive">Google Drive</a></li>
                        <li><a href="#dropbox">Dropbox</a></li>
                        <li><a href="#awsS3">AWS S3</a></li>
                        <li><a href="#databases">Databases (MySQL or Postgres)</a></li>
                    </ol>
                </div>
                <section id="googleDrive">
                    <div className="docsSection">
                        <p className="docsHeader">Google Drive</p>
                        <p>If you don't have any Google Accounts connected, you can connect one on this screen. <span className="linkStyle" onClick={e => this.props.setBothMenuItems("integrations-googleDrive")}>Read more about connecting & managing Google Accounts here</span></p>
                        <p>You can select 1 or multiple Google Accounts to save the imported file</p>
                        <p>For each of your Google Account, you can select 1 folder to save the imported file</p>
                        <DocsImage
                            src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Destinations-GoogleSheetsFolder.png"
                            width="100%"
                            alt="View Destinations API Spreadsheets"
                        />
                        <DocsImage
                            src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Destinations-GoogleSheetsSelectFolder.png"
                            width="100%"
                            alt="View Destinations API Spreadsheets"
                        />
                        <p>Don't forget to save after finishing</p>
                    </div>
                </section>
                <section id="dropbox">
                    <div className="docsSection">
                        <p className="docsHeader">Dropbox</p>
                        <p>If you don't have any Dropbox Accounts connected, you can connect one on this screen. <span className="linkStyle" onClick={e => this.props.setBothMenuItems("integrations-dropbox")}>Read more about connecting & managing Dropbox Accounts here</span></p>
                        <p>Since you can only connect 1 Dropbox Account to API Spreadsheets, you can only select 1 Dropbox Accounts to save the imported file</p>
                        <DocsImage
                            src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Destinations-SelectFolderDropbox.png"
                            width="100%"
                            alt="View Destinations API Spreadsheets"
                        />
                        <p>For your Dropbox account, you can select only 1 folder to save the imported file</p>
                        <DocsImage
                            src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Destinations-DropboxFolderSelection.png"
                            width="100%"
                            alt="View Destinations API Spreadsheets"
                        />
                        <p>Don't forget to save after finishing</p>
                    </div>
                </section>
                <section id="awsS3">
                    <div className="docsSection">
                        <p className="docsHeader">AWS S3</p>
                        <p>If you don't have any AWS S3 Accounts connected, you can connect one on this screen. <span className="linkStyle" onClick={e => this.props.setBothMenuItems("integrations-awsS3")}>Read more about connecting & managing AWS S3 Accounts here</span></p>
                        <p>You can select 1 or multiple AWS S3 accounts to save the imported file to</p>
                        <p>For each of your AWS S3 account, you can select as many buckets but only 1 folder in each of your bucket</p>
                        <DocsImage
                            src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Destinations-AWSS3.png"
                            width="100%"
                            alt="View Destinations API Spreadsheets"
                        />
                        <p>Don't forget to save after finishing</p>
                    </div>
                </section>
                <section id="databases">
                    <div className="docsSection">
                        <p className="docsHeader">Databases</p>
                        <p>You can select tables in your <b>Postgres</b> or <b>MySQL</b> databases where data from the imported spreadsheets will be stored</p>
                        <span  className="linkStyle" onClick={e => this.props.setBothMenuItems("database-importer")}>Read all about databases here</span>
                    </div>
                </section>
            </div>
        )
    }
}

export default Destinations