import React, {Component} from 'react'
import DocsGetResponse from './DocsGetResponse'
import DocsGetURLParameters from "./DocsGetURLParameters";
import DocsBaseURL from "./DocsBaseURL";

class DocsGetIndex extends Component {
    render() {
        let baseGetURL = 'data/[fileID]/?accessKey=[accessKey]&secretKey=[secretKey]&dataFormat=[dataFormat]/';

        return (
            <div>
                <p>To get data from your file make a GET request to the following URL.</p>
                <DocsBaseURL url={baseGetURL} />
                <i>Example URL</i>
                <DocsBaseURL url="data/1/?accessKey=12345678901234567890123456789012&secretKey=12345678901234567890123456789012&dataFormat=matrix/" />
                <div className="row">
                    <div className="col-sm-6 getParams">
                        <DocsGetURLParameters />
                    </div>
                    <div className="col-sm-6 getResponse">
                        <DocsGetResponse/>
                    </div>
                </div>
            </div>
        )
    }
}

export default DocsGetIndex