export const canUserTakeAction = (fileInformation, teamInfoProps) => {
    let canTakeAction = true;
    const isTeam = "isTeam" in fileInformation ? fileInformation.isTeam : false;

    try {
        if (isTeam) {
            if ("teams_pk_list" in fileInformation) {
                if (fileInformation.teams_pk_list.length > 0) {
                    const allTeamPKs = teamInfoProps.map(x => x.id)
                    let allFileTakeAction = [];

                    for (let i = 0; i < fileInformation.teams_pk_list.length; i++) {
                        const idx = allTeamPKs.indexOf(fileInformation.teams_pk_list[i])
                        allFileTakeAction.push(["admin", "manager"].includes(teamInfoProps[idx].all_users[localStorage.getItem("email")].role))
                    }

                    if (allFileTakeAction.indexOf(true) === -1){
                        canTakeAction = false
                    }
                }
            }
        }
    } catch (error) {
        canTakeAction = true
    }

    return canTakeAction
}