import React, {Component} from 'react'
import FeatureTable from "./FeatureTable";
import { Icon } from 'semantic-ui-react'
import APIQuickstartCode from "./APIQuickstartCode";
import Icons from "../../../Constants/Icons";

class API extends Component {
    render() {
        return (
            <div className="featureAPI">
                <h1 className="featureModalHeader">Simple & Robust API Design</h1>
                <div className="featureModalSimpleAPIExampleTable">
                    <div className="featureModalSimpleAPIExampleTableValues">
                        <span className="featureModalSubHeader"><Icon name={Icons.readAction} /> To Read Data</span>
                        <FeatureTable
                            headers={["Name", "Age"]}
                            data={[[{value: "Queen Liz", style: {backgroundColor: 'green', color: 'white'}}, "94"],[{value: "Don Draper", style: {backgroundColor: 'green', color: 'white'}}, "103"], ["Emma Watson", "30"]]}
                        />
                    </div>
                    <div className="featureModalSimpleAPIExampleTableRequest">
                        <span className="featureModalSubHeader"><Icon name="code" /> Make a GET Request with a SQL-style Query</span>
                        <br/><br/>
                        <pre id="apiFeatureModalURLBox">
                            {/*https://api.apispreadsheets.com/data/100/?query=<br/><span id="flashingQuery">SELECTnameFROM100WHEREage>90</span>*/}
                            https://api.apispreadsheets.com/data/100/?query=<br/><span style={{fontWeight: '800', color: 'lightgreen'}}>SELECT name FROM 100 WHERE age>90</span>
                        </pre>
                    </div>
                </div>
                <br/>
                <div id="featureModalCodeOuterDiv">
                    <span className="featureModalSubHeader">Or Quick Start with your Favorite Language</span>
                    <APIQuickstartCode/>
                </div>
                {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#00cba9" fill-opacity="1" d="M0,192L24,208C48,224,96,256,144,234.7C192,213,240,139,288,128C336,117,384,171,432,186.7C480,203,528,181,576,149.3C624,117,672,75,720,96C768,117,816,203,864,213.3C912,224,960,160,1008,154.7C1056,149,1104,203,1152,213.3C1200,224,1248,192,1296,176C1344,160,1392,160,1416,160L1440,160L1440,320L1416,320C1392,320,1344,320,1296,320C1248,320,1200,320,1152,320C1104,320,1056,320,1008,320C960,320,912,320,864,320C816,320,768,320,720,320C672,320,624,320,576,320C528,320,480,320,432,320C384,320,336,320,288,320C240,320,192,320,144,320C96,320,48,320,24,320L0,320Z"></path></svg>*/}
            </div>
        )
    }
}

export default API