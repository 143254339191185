import React, {Component} from 'react'
import UploadMenuItem from "./UploadMenuItem";

class UploadMenuHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{backgroundColor: "#F4FBF3", width: "100%", fontFamily: "Lato, sans-serif"}}>
                {/*<div style={{fontWeight: "700", fontSize: "20px", color: "#161E16", textAlign: "center", paddingTop: "24px", paddingBottom: "24px"}}>*/}
                    {/*Upload or Connect Data*/}
                {/*</div>*/}
                <div style={{display: "grid", gridTemplateColumns: "1fr auto auto auto auto auto 1fr", paddingTop: "24px"}}>
                    <div> </div>
                    <UploadMenuItem changeMenuItem={this.props.changeMenuItem}
                                    menuItem={this.props.menuItem}
                                    name="uploadFiles"
                                    label="Upload Files"
                                    icon="bx bx-folder-open"
                    />
                    <UploadMenuItem changeMenuItem={this.props.changeMenuItem}
                                    menuItem={this.props.menuItem}
                                    name="googleSheets"
                                    label="Google Sheets"
                                    icon="bx bxl-google"
                    />
                    <UploadMenuItem changeMenuItem={this.props.changeMenuItem}
                                    menuItem={this.props.menuItem}
                                    name="dropbox"
                                    label="Dropbox"
                                    icon="bx bxl-dropbox"
                    />
                    <UploadMenuItem changeMenuItem={this.props.changeMenuItem}
                                    menuItem={this.props.menuItem}
                                    name="s3"
                                    label="AWS S3"
                                    icon="bx bxl-aws"
                    />
                    <UploadMenuItem changeMenuItem={this.props.changeMenuItem}
                                    menuItem={this.props.menuItem}
                                    name="quickstart"
                                    label="Quickstart"
                                    icon="bx bx-code-block"
                    />
                    {/*<UploadMenuItem changeMenuItem={this.props.changeMenuItem}*/}
                                    {/*menuItem={this.props.menuItem}*/}
                                    {/*name="database"*/}
                                    {/*label="Database"*/}
                                    {/*icon="bx bxs-data"*/}
                    {/*/>*/}
                    <div> </div>
                </div>
            </div>
        )
    }
}

export default UploadMenuHeader