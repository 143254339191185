export const getConnectedDomainLink = (connectedDomains) => {
    if (connectedDomains !== null){
        if (connectedDomains.trim() !== ""){
            return "http://" + connectedDomains.split(",")[0]
        } else {
            return null
        }
    } else {
        return null
    }
}