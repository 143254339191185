import React, {Component} from 'react'
import {connect} from 'react-redux'
import DocsMenuItem from "./DocsMenuItem"

class DocsMenu extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div style={{backgroundColor: this.props.page === "api" ? "#6FBB98" : "#E27837", padding: "24px", minHeight: "100vh"}}>
                {this.props.info.docsMenuItems.map((x, idx) => <DocsMenuItem info={this.props.info.itemsInfo[x]}
                                                                             name={x}
                                                                             menuItem={this.props.menuItem}
                                                                             subMenuItem={this.props.subMenuItem}
                                                                             modifySubMenuItem={this.props.modifySubMenuItem}
                                                                             modifyMenuItem={this.props.modifyMenuItem}/>)}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DocsMenu)