import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import Loader from 'react-loader-spinner'
import API_Root from "../../Constants/API_Root";
import {toggleToast} from "../../Actions/ToggleToast";

class DeleteKeys extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleting: false
        }
    }

    deleteKeys = () => {
        this.setState({ deleting: true })

        const error = () => {
            this.setState({ deleting: false }, () => {
                this.props.toggleToast({ show: true, type: "error", message: "There was an error deleting your keys. Please try again. Contact support if error persists."})
            })
        }

        fetch(API_Root + "api/crud-api-utilities-info/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                mode: "deleteKeys",
                pk: this.props.pk,
                keys: this.props.keys
            })
        }).then(res => {
            if (res.status === 200){
                this.setState({
                    deleting: false
                }, () => {
                    this.props.deleteKeysFromState(this.props.idx)
                })
            } else {
                error()
            }
        }).catch(err => error())
    }

//     keys={x}
//     idx={x}
//     changeState={this.props.changeState}
// pk={this.props.pk}

    render() {
        if (this.state.deleting){
            return <Loader
                type="TailSpin"
                color="black"
                height="20"
                width="20"
            />
        }

        return (
            <small style={{ color: gunaldiStyles.red, cursor: "pointer"}} onClick={this.deleteKeys}><Icon name="trash alternate outline"/> Delete Key Pair</small>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(DeleteKeys)