import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyEditID} from "../../Actions/ModifyEditID";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";

class WebhookButton extends Component {
    openModal = () => {
        this.props.modifyModalInfo({pk: this.props.pk})
        this.props.modifyEditID(this.props.pkID);
        this.props.toggleModal("webhookModal")
    }

    render() {
        return (
            <div>
                <Button content="Webhook"
                        basic
                        color='blue'
                        icon='fork'
                        onClick={this.openModal} fluid/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo,
    modifyEditID: modifyEditID
}

export default connect(mapStateToProps, mapActionsToProps)(WebhookButton)