import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Popup, Button } from 'semantic-ui-react'
import getURLParam from "../Constants/getURLParam";
import API_Root from "../Constants/API_Root";
import {toggleToast} from "../Actions/ToggleToast";
import {modifyAllDBInfo} from "../Actions/ModifyAllDBInfo";

class DatabasePageHeaderDescription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            originalEditDesc: JSON.parse(JSON.stringify(this.props.desc)),
            editDesc: JSON.parse(JSON.stringify(this.props.desc)),
            saving: false
        }
    }

    toggleEdit = () => {
        const toggledEdit = !JSON.parse(JSON.stringify(this.state.editMode))

        this.setState({ editMode: toggledEdit })
    }

    handleDescriptionChange = (e) => this.setState({ editDesc: e.target.value })

    undoDesc = () => this.setState({ editDesc: this.state.originalEditDesc, editMode: false, saving: false })

    saveDesc = (e) => {
        this.setState({ saving: true });

        const databaseHash = getURLParam(window.location.href);
        const urlEnd = "api/edit-api-db-info/" + databaseHash.toString() + "/"

        const error = () => this.setState({ editMode: false, editDesc: "", saving: false }, () => this.props.toggleToast({message: "There was an error saving. Please try again!", type: "error", show: true}))

        fetch(API_Root + urlEnd, {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                editType: "description",
                value: { description: this.state.editDesc }
            })
        }).then((res) => {
            if (res.status === 200){
                const allDBInfo = JSON.parse(JSON.stringify(this.props.allDBInfo))
                allDBInfo['dbInfo']['description'] = this.state.editDesc
                this.props.modifyAllDBInfo(allDBInfo)
                this.setState({ editMode: false, originalEditDesc: this.state.editDesc, saving: false })
            } else {
                error()
            }
        }).catch(err => error())
    }

    render() {
        if (this.state.editMode){
            let alert = null;
            let saveBtnDisabled = false;

            if (this.state.originalEditDesc.trim() !== this.state.editDesc.trim()){
                alert = <span style={{color: 'red', paddingLeft: '5px', marginBottom: '5px'}}>Description not saved<br/></span>
                saveBtnDisabled = false
            }

            if (this.state.editDesc.trim() === ""){
                alert = <span style={{color: 'red', paddingLeft: '5px', marginBottom: '5px'}}>Description cannot be blank<br/></span>
                saveBtnDisabled = true
            }

            if (this.state.originalEditDesc.trim() === this.state.editDesc.trim()){
                saveBtnDisabled = true
            }

            return (
                <div>
                    <div className="fileDescDiv">
                        <div className="fileDescTextArea">
                            <input
                                className="inputGray"
                                style={{width: "100%"}}
                                value={this.state.editDesc}
                                onChange={this.handleDescriptionChange}
                                placeholder="Enter description for Database"
                            />
                        </div>
                        <div style={{display: "grid", gridTemplateColumns: "50% 50%", marginTop: "4px"}}>
                            <div>
                                {alert}
                            </div>
                            <div>
                                <div className="fileDescButtons">
                                    <Button content="Undo"
                                            icon="undo"
                                            onClick={this.undoDesc}
                                            size="mini"
                                            style={{backgroundColor: "#9696A0", color: "white"}}
                                    />
                                    <Button content="Save"
                                            style={{backgroundColor: "#6FBB98", color: "white"}}
                                            icon="save outline"
                                            disabled={saveBtnDisabled}
                                            onClick={this.saveDesc}
                                            loading={this.state.saving}
                                            size="mini"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="ellipses" style={{paddingRight: "32px", fontFamily: "Lato", fontWeight: "500", fontSize: "14px", color: "#161E16"}}>
                        {this.state.originalEditDesc}
                        <Popup
                            trigger={<button onClick={this.toggleEdit} style={{marginLeft: "8px"}} className="iconBtn">
                                {/*<span><Icon name="edit outline" /></span>*/}
                                <span><i className="bx bxs-edit-alt" /> </span>
                            </button>}
                            content="Edit Description"
                        />
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    allDBInfo: state.mainState.allDBInfo
})

const mapActionsToProps = {
    toggleToast: toggleToast,
    modifyAllDBInfo: modifyAllDBInfo
}

export default connect(mapStateToProps, mapActionsToProps)(DatabasePageHeaderDescription)