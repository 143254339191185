import React, {Component} from 'react'
import {connect} from 'react-redux'
import DropboxAuth from "../../Dropbox/DropboxAuth"

class DropboxFolderConnectIndex extends Component {
    render() {
        return (
            <div style={{minHeight: '100vh'}}>
                <div style={{paddingTop: "50px"}}>
                    <h2 className="thinHeading" style={{textAlign: "center"}}>Connect to your Folder on Dropbox</h2>
                    <DropboxAuth />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DropboxFolderConnectIndex)