import React, {Component} from 'react'
import PageHeader from "../SharedComponents/PageHeader";
import { Button } from 'semantic-ui-react'
import PageHeaderHelp from "../SharedComponents/PageHeaderHelp";
import {connect} from "react-redux";
import {toggleModal} from "../Actions/ToggleModal";

class DatabasePageHeader extends Component {
    addNewDatabase = () => {
        this.props.toggleModal("addNewDatabaseModal")
    }

    render() {
        return (
            <PageHeader
                common
                tiny
                header="Database"
                desc={<div style={{ display: "grid", gridTemplateColumns: "50% 50%"}}>
                    <div>
                        <Button
                            disabled={'noCreate' in this.props}
                            icon="plus"
                            content="Connect a New Database"
                            style={{backgroundColor: "#FFFFFF", border: "1px solid #6FBB98", color: "#6FBB98"}}
                            onClick={this.addNewDatabase} />
                    </div>
                    <div>
                        <PageHeaderHelp
                            header="Need help connecting to a database"
                            url="/api-docs/?docsMenuItem=database-connect"
                            desc="Read about how to connect to a database"
                        />
                    </div>
                </div>}
            />
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(DatabasePageHeader)