import React, {Component} from 'react'
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import InfoPopup from "../../../../SharedComponents/InfoPopup"

class ColumnRowHeader extends Component {
    render() {
        const headerStyle = {fontSize: "14px", color: gunaldiStyles.mainText, fontWeight: "400", marginBottom: "8px"};

        if ("center" in this.props){
            headerStyle['textAlign'] = 'center'
        }

        return (
            <div style={headerStyle}>
                {this.props.header} {'info' in this.props ? <InfoPopup info={this.props.info}/> : null}
            </div>
        )
    }
}

export default ColumnRowHeader