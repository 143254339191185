import React, {Component} from 'react'
import {connect} from 'react-redux'
import {convertServerDateToDisplayDate} from "../Constants/convertServerDateToDisplayDate";
import RoleTag from "./Roles/RoleTag"
import { Button, Icon } from "semantic-ui-react"
import TeamInfoRemoveButton from "../Teams/TeamInfo/TeamInfoRemoveButton"
import { push } from 'react-router-redux'

class TeamSegmentRow extends Component {
    constructor(props){
        super(props);

        this.state = {
            url: "/teams"
        }
    }

    findRoleOfUser = (idx) => {
        const userEmail = localStorage.getItem("email")

        const memberInfo = this.props.teamInfo[idx].all_users

        if (userEmail in memberInfo){
            return <RoleTag userRole={memberInfo[userEmail].role}/>
        } else { return null }
    }

    seeTeamDetails = (teamHash, fileHash) => {
        this.setState({
            url: this.getFileURL(teamHash, fileHash)
        }, () => this.goToTeamsPageLink.click())
    }

    getFileURL = (teamHash, fileHash) => {
        const baseURL = window.location.href.includes("localhost") ? "http://localhost:5000/" : "https://www.apispreadsheets.com/"
        return baseURL + "teams/" + teamHash.toString() + (fileHash === null ? "" : "?file=" + fileHash.toString())
    }

    goToTeamPage = (e) => {
        let classNames;
        try {
            classNames = e.target.className
        } catch(err) {
            classNames = ""
        }

        if (classNames === null){
            classNames = ""
        }

        if (classNames.indexOf("teamTabRemoveFileBtn") === -1){
            this.seeTeamDetails(this.props.info.team_hash, this.props.fileHash)
        }
    }

    getCountofActiveMembers = (allUsersObject) => {
        let count = 0;

        for (let userEmail in allUsersObject){
            const userInfo = allUsersObject[userEmail]

            if ("joined" in userInfo){
                if (userInfo["joined"].toString().length > 0){
                    count += 1
                }
            }
        }

        return count
    }

    render() {
        const isOnTeamPage = this.props.teamPage;

        return (
            <div>
                <a href={this.state.url}  ref={goToTeamsPageLink => this.goToTeamsPageLink = goToTeamsPageLink} style={{display: "none"}}> Live Dashboard</a>
                <div className={isOnTeamPage ? "teamPageSegmentRow filesTabFileSummaryRow" : "teamSegmentRow"} onClick={this.goToTeamPage} style={{cursor: "pointer"}}>
                    <div>
                        <div style={{borderRadius: "40px", padding: "10px", background: "#F0F0F0", width: "40px", height: "40px", textAlign: "center"}}>
                            <i className="bx bx-group" style={{color: "#5499F8", fontSize: "130%"}}/>
                        </div>
                    </div>
                    <div className="ellipses" style={{paddingTop: "5px"}}>
                        <span style={{fontWeight: "700", fontSize: "14px", fontFamily: "Lato", color: "#161E16"}}>{this.props.info.name}</span>
                        {/*<p>{this.props.info.description}</p>*/}
                    </div>
                    <div style={{paddingTop: "5px"}}>
                        <span style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#5E645E"}}>{convertServerDateToDisplayDate(this.props.info.created_dt).substring(0, 10)}</span>
                    </div>
                    {
                        isOnTeamPage ?
                        <div style={{paddingTop: "5px"}} className="ellipses">
                            {this.props.info.admin_email}
                        </div> : null
                    }
                    <div style={{paddingTop: "5px"}}>
                        {this.findRoleOfUser(this.props.idx)}
                    </div>
                    {
                        isOnTeamPage ?
                            <div style={{paddingTop: "5px"}}>
                                {this.getCountofActiveMembers(this.props.info.all_users)}
                            </div> : null
                    }
                    <div style={{textAlign: "right"}}>
                        <button onClick={e => this.seeTeamDetails(this.props.info.team_hash, this.props.fileHash)} style={{width:'84px',height:'36px',backgroundColor:'#FFFFFF',border:'1px solid #6FBB98',borderRadius:'4px', color:"#6FBB98", cursor: "pointer"}}> Details</button>
                    </div>
                    {
                        isOnTeamPage ?
                            <div>
                                <TeamInfoRemoveButton displayTeamIndex teamIdx={this.props.idx}/>
                            </div> : null
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    teamInfo: state.mainState.teamInfo
})

const mapActionsToProps = {
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(TeamSegmentRow)