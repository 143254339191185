import React, {Component} from 'react'
import multiplekeys from "../../Illustrations/multiplekeys.svg"
import adminLogo from "../../../Teams/Roles/adminLogo.svg"
import managerLogo from "../../../Teams/Roles/managerLogo.svg"
import developerLogo from "../../../Teams/Roles/developerLogo.svg"
import { Image, Segment } from 'semantic-ui-react'

class MultipleKeysDesc extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Assign Roles to Team Members</div>
                <div style={this.props.subheadingStyle}>You can assign each team member different roles to manage their access</div>
                {/*Role graphic*/}
                <div className="row">
                    <div className="col-sm-4">
                        <Segment>
                            <h4 style={{textAlign: "center"}}>Admin</h4>
                            <Image src={adminLogo} alt="logo" size="medium" circular/>
                        </Segment>
                    </div>
                    <div className="col-sm-4">
                        <Segment>
                            <h4 style={{textAlign: "center"}}>Manager</h4>
                            <Image src={managerLogo} alt="logo" size="medium" circular/>
                        </Segment>
                    </div>
                    <div className="col-sm-4">
                        <Segment>
                            <h4 style={{textAlign: "center"}}>Developer</h4>
                            <Image src={developerLogo} alt="logo" size="medium" circular/>
                        </Segment>
                    </div>
                </div>
            </div>

        )
    }
}

export default MultipleKeysDesc