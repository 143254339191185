import React, {Component} from 'react'
import logoPictureURLs from "../logoPictureURLs";
import CRUD from "../../Illustrations/CRUD.svg";

class CrudActionsDesc extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Execute all CRUD Actions</div>
                <div style={this.props.subheadingStyle}>Create, Read, Delete, and Update data in your spreadsheets with your API </div>
                <div style={{textAlign: "center"}}>
                    <img src={CRUD} alt="CRUD Illustration" style={{width: "75%"}}/>
                </div>
            </div>

        )
    }
}

export default CrudActionsDesc