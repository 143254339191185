import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../../SharedComponents/Modal"
import { Icon, Input, Button } from 'semantic-ui-react'
import {toggleModal} from "../../Actions/ToggleModal";
import API_Root from "../../Constants/API_Root";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

class ImportUploadLoginModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            error: null,
            checking: false
        }
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    login = () => {
        this.setState({ checking: true })

        const error = () => this.setState({ checking: false, error: <small style={{color: "red"}}>Your username or password (or both) are wrong. Try again</small> })

        fetch(API_Root + "api/check-import-login/", {
            method: "POST",
            body: JSON.stringify({
                username: this.state.username,
                password: this.state.password,
                importHash: this.props.importHash
            })
        }).then((res) => {
            if (res.status === 200) {
                this.setState({ checking: false, error: null }, () => this.props.toggleModal(null))
            }
            else {
                error()
            }
        }).catch(err => error())
    }

    render() {
        return (
            <ModalApp name="importUploadLoginModal"
                      header={<span style={{fontSize: "20px", fontWeight: "700", color: "#161E16"}}><Icon name="lock" /> Sign in to upload</span>}
                      body={<div style={{marginTop: "16px"}}>
                          <div>
                              <input style={{border: "1px solid #F0F0F0", padding: "8px 8px 8px 12px", width: "100%"}}
                                     name="username"
                                     // icon="user"
                                     placeholder="Username" onChange={this.handleInputChange} value={this.state.username}/>
                          </div>
                          <div>
                              <input
                                  style={{border: "1px solid #F0F0F0", padding: "8px 8px 8px 12px", width: "100%"}}
                                  name="password"
                                  placeholder="Password" type="password" onChange={this.handleInputChange} value={this.state.password}/>
                          </div>
                          <div style={{marginTop: "16px"}}>
                            <Button disabled={!(this.state.username.trim() !== "" && this.state.password.trim() !== "")} content="Sign In" style={{...gunaldiStyles.btnGreen, width: '100%'}} onClick={this.login} loading={this.state.checking} />
                          </div>
                          {this.state.error}
                      </div>}
                      size="small"
                      noClose
            />
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ImportUploadLoginModal)