import React, {Component} from 'react'
import Gist from 'react-gist'
import SpreadsheetImportCodeJSON from "./SpreadsheetImportCodeJSON";

class SpreadsheetImportCode extends Component {
    render() {
        const codeColor = "crimson"
        return (
            <div>
                <p>This guide will explain the details about the code required to create an importer</p>
                <p style={{marginBottom: "5px"}}>Once you have <a href="/guides/spreadsheet-importer" target="_blank" rel="noopener noreferrer">customized your importer</a>, you can copy and paste our pre-written code to insert in your website or app that will let users upload spreadsheets</p>
                <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ImporterCode.png" style={{width: "110%"}} alt="Import Code"/>
                <p> </p>
                <h2>Full Code</h2>
                <Gist id="d0ee0c33e92d6695b9c9ddac39316a26" />
                <p> </p>
                <p>Let's walk through the code in more detail</p>
                <h2>Get library <i>line 3</i></h2>
                <pre>
                    <code style={{color: codeColor}}>&lt;script type="text/javascript" src="https://www.apispreadsheets.com/import.js">&lt;/script></code>
                </pre>
                <p>The script tag on <i><b>line 3</b></i> gets our API Spreadsheets library that lets you build the importer with such little code</p>
                <h2>Configure import callback <i>lines 5-8</i></h2>
                <pre>
                    <code style={{color: codeColor}}>
                        {
                            "function importComplete(success, data) {\n" +
                                "\tconsole.log(success)\n" +
                                "\tif (success){ console.log(data) }\n" +
                            "}\n"
                        }
                    </code>
                </pre>
                <p>This function is called AFTER the user is finished importing. The function MUST ALWAYS have 2 parameters: <code>success</code> and <code>data</code> in this order.</p>
                <p><b><code>success</code></b> will either be <code>true</code> or <code>false</code> depending on if the import was successful or not. If import was successful, then the <b><code>data</code></b> will be an array where each element is a JSON object</p>
                <p>The <b><code>data</code></b> array will be the same length as the files uploaded. For example, if a user uploads 2 files it will look like this <code>{"[{file_1_data},{file_2_data}]"}</code></p>
                <p>The JSON objects in the arrays will correspond to the order of file that was uploaded. Each JSON object will have the following format</p>
                <div style={{border: "1px solid grey", padding: "10px"}}>
                    <SpreadsheetImportCodeJSON/>
                </div>
                <p> </p>
                <p> You can do whatever you wish in this function using the <code>success</code> and <code>data</code> variables</p>
                <p> For example: you can display success/error alerts, you can use the apiUrl to get data from the file, you can display information to the user about the file(s) they imported</p>
                <p> The world is your oyster here</p>
                <h2>Configure importer variable <i>line 10</i></h2>
                <pre>
                    <code style={{color: codeColor}}>let importer = new APISpreadsheetsImporter("d8832db655301ed2573139943ef04748", importComplete);</code>
                </pre>
                <p>Here we will configure the <code>importer</code> variable. This is possible due to the script we imported in <b>line 3</b></p>
                <p>The <code>APISpreadsheetsImporter</code> takes 2 parameters:
                    <ol>
                        <li>Importer key</li>
                        <li>Callback function</li>
                    </ol>
                </p>
                <p>We already went over the callback function in the section above. The <b>Importer Key</b> is found from your importer page</p>
                <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ImporterKey.png" style={{width: "110%"}} alt="Importer Key"/>
                <p> </p>
                <p>Now that your <code>importer</code> variable is configured, we can use to start the import process from anywhere in your HTML code</p>
                <h2>Call importer function from HTML body <i>line 14</i></h2>
                <pre>
                    <code style={{color: codeColor}}>&lt;button class="api-spreadsheets-import-button" onclick="importer.importFiles();">Import Excel and CSV Files&lt;/button></code>
                </pre>
                <p>In this code, we are in the <code>&lt;body></code> of our HTML page. Here we made a button that when clicked calls this function <code>importer.importFiles()</code>. This function opens the popup that begins the importing process for the user</p>
                <p>This is just ONE of the many many ways to configure the import process. As long as <code>onclick="importer.importFiles();"</code> is in any HTML tag, you can add it to ANY element</p>
                <p style={{marginBottom: "5px"}}>For example, the following would work to open the popup as well</p>
                <code style={{color: codeColor}}>&lt;p onclick="importer.importFiles();">Import Please&lt;/p></code>
                <p> </p>
                <h2>Still have questions?</h2>
                <p>
                    <a href="/guides/spreadsheet-importer" target="_blank" rel="noopener noreferrer">Details on Importer Customization</a>
                    <br/><br/>
                    <a href="tutorials/import-spreadsheets-on-your-apps.html" target="_blank" rel="noopener noreferrer">End-to-end tutorial on setting up an importer</a>
                    <br/><br/>
                    <a href="mailto:support@apispreadsheets.com">Email us your questions!</a>
                </p>
            </div>
        )
    }
}

export default SpreadsheetImportCode