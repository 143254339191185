import React, {Component} from 'react'

const reformatData = (sampleValues) => {
    let data = [];

    for (let i=0; i<sampleValues.length; i++){
        let row = sampleValues[i];
        let newRow = [];

        for (let j=0; j<14; j++){
            newRow.push(row[j])
        }

        newRow.push(row[row.length-1])

        data.push(newRow)
    }

    return data
}


class DatasetSampleValues extends Component {
    render() {
        let asterisk = null;
        let data = this.props.sampleValues;

        if (this.props.sampleValues[0].length > 15){
            data = reformatData(data)
            asterisk = <span>{". Showing 15 out of " + this.props.sampleValues[0].length.toString() + " columns."}</span>
        }

        let headerRow = [];
        let dataRows = [];

        for (let i=0; i<data.length; i++){
            let dataRow = []

            for (let j=0; j<data[i].length; j++){
                if (i === 0){
                    headerRow.push(<th key={i.toString() + j.toString()} className={j===data[i].length - 1 ? "predictorHeaderCell" : null}>{data[i][j]}</th>)
                }
                else{
                    dataRow.push(<td key={i.toString() + j.toString()} className={j===data[i].length - 1 ? "predictorCell" : null}>{data[i][j]}</td>)
                }
            }

            if (i !== 0){
                dataRows.push(<tr>{dataRow}</tr>)
            }
        }


        return (
            <div style={{marginTop: '50px'}}>
                <h2>Sample Values</h2>
                <span className="text-muted">{"Showing " + (this.props.sampleValues.length - 1).toString() + " out of " + this.props.rows.toString() + " rows"}{asterisk}</span>
                <div style={{width: '100%', overflowX: "scroll"}}>
                    <table className="table table-bordered table-striped table-hover table-sm">
                        <thead>
                            {headerRow}
                        </thead>
                        <tbody>
                            {dataRows}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default DatasetSampleValues