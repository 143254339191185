import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Checkbox, Popup } from 'semantic-ui-react'

class SelectSSLCertificate extends Component {
    isDisabled = () => {
        if (this.props.status === 'ISSUED'){
            if (this.props.domainNameError){
                return {
                    popupMsg: null,
                    isDisabled: true
                }
            } else {
                if (this.props.certDomainName !== this.props.domainName){
                    return {
                        popupMsg: "Certificate domain name (" + this.props.certDomainName + ") does not match entered domain name (" + this.props.domainName + ")",
                        isDisabled: true
                    }
                } else {
                    return {
                        popupMsg: null,
                        isDisabled: false
                    }
                }
            }
        } else {
            return {
                popupMsg: "Certificate is not active",
                isDisabled: true
            }
        }
    }

    toggleSelect = () => {
        console.log(this.props.idx)
        if (this.props.sslSelected !== this.props.idx){
            this.props.changeState({ sslSelected: this.props.idx })
        } else {
            this.props.changeState({ sslSelected: null })
        }
    }

    render() {
        const isDisabledObject = this.isDisabled()
        const checkbox = <Checkbox checked={this.props.sslSelected === this.props.idx}
                                   onChange={this.toggleSelect}
                                   disabled={isDisabledObject.isDisabled}
        />

        let body = null;
        if (isDisabledObject.popupMsg === null){
            body = checkbox
        } else {
            body = <Popup trigger={checkbox} content={isDisabledObject.popupMsg} />
        }

        return (
            <div style={{paddingLeft:"8px"}}>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(SelectSSLCertificate)