import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup, Icon } from 'semantic-ui-react'
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import API_Root from "../../Constants/API_Root";
import {modifyImportSheetsFileInformation} from "../../Actions/ModifyImportSheetsFileInformation";

class FileDescriptionModalBody extends Component {
    constructor(props) {
        super(props);

        if ('importSegment' in this.props){
            this.state = {
                originalFileDesc: JSON.parse(JSON.stringify(this.props.importSheetsFileInformation[this.props.pkID].description)),
                fileDesc: JSON.parse(JSON.stringify(this.props.importSheetsFileInformation[this.props.pkID].description)),
                saving: false,
                editMode: false
            }
        } else {
            this.state = {
                originalFileDesc: JSON.parse(JSON.stringify(this.props.fileInformation[this.props.pkID].description)),
                fileDesc: JSON.parse(JSON.stringify(this.props.fileInformation[this.props.pkID].description)),
                saving: false,
                editMode: false
            }
        }

        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.saveDescription = this.saveDescription.bind(this);
        this.undoDesc = this.undoDesc.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
    }

    // initEditMode(e){
    //     let fileDesc;
    //
    //     if ('importSegment' in this.props){
    //         fileDesc = JSON.parse(JSON.stringify(this.props.importSheetsFileInformation[this.props.pkID].description));
    //     } else {
    //         fileDesc = JSON.parse(JSON.stringify(this.props.fileInformation[this.props.pkID].description));
    //     }
    //
    //     return fileDesc.trim() === ""
    // }

    undoDesc(e){
        if (this.state.originalFileDesc === ""){
            this.setState({
                fileDesc: this.state.originalFileDesc,
                editMode: true
            })
        }
        else{
            this.setState({
                fileDesc: this.state.originalFileDesc,
                editMode: false
            })
        }
    }

    handleDescriptionChange(e){
        this.setState({
            fileDesc: e.target.value
        })
    }

    toggleEdit(e){
        const currEdit = this.state.editMode;

        this.setState({
            editMode: !currEdit
        })
    }

    saveDescription(e){
        this.setState({saving: true});

        let copiedFileInfo;

        if ('importSegment' in this.props){
            copiedFileInfo = JSON.parse(JSON.stringify(this.props.importSheetsFileInformation))
        } else {
            copiedFileInfo = JSON.parse(JSON.stringify(this.props.fileInformation))
        }


        copiedFileInfo[this.props.pkID].description = this.state.fileDesc;

        if ('importSegment' in this.props){
            this.props.modifyImportSheetsFileInformation(copiedFileInfo)
        } else {
            this.props.modifyFileInformation(copiedFileInfo);
        }


        let pk = this.props.pk;

        fetch(API_Root + 'api/change-api-file-description/', {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                pk: pk,
                description: this.state.fileDesc,
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then(
            (res) => {
                if (res.status === 200){
                    this.setState({originalFileDesc: this.state.fileDesc, saving: false, editMode: false});
                }
                else{
                    this.setState({originalFileDesc: this.state.fileDesc, saving: false, editMode: false});
                }
            }).catch(err => this.setState({originalFileDesc: this.state.fileDesc, saving: false, editMode: false}))

    }

    startEditMode = () => {
        this.setState({ editMode: true })
    }

    render() {
        const fileDesc = this.state.fileDesc;

        let alert = null;
        let saveBtnDisabled = true;

        if (this.state.originalFileDesc.trim() !== fileDesc.trim()){
            alert = <span style={{color: 'red', paddingLeft: '5px', marginBottom: '5px'}}>Description not saved<br/></span>
            saveBtnDisabled = false
        }

        if (this.state.editMode){
            return (
                <div>
                    <div className="fileDescDiv">
                        <div className="fileDescTextArea">
                            <textarea
                                rows={3}
                                style={{width: '100%',
                                    padding: "8px 8px 8px 12px",
                                    border: "1px solid #F0F0F0",
                                    borderRadius: "4px",
                                    backgroundColor: "#F0F0F0",
                                    display: 'block',
                                    height: '100%'}}
                                value={fileDesc}
                                onChange={this.handleDescriptionChange}
                                placeholder={'importSegment' in this.props ?
                                    "Enter file details for " + this.props.importEmail + " to see" :
                                    "Enter a description for the file"}
                            />
                        </div>
                        <div style={{display: "grid", gridTemplateColumns: "50% 50%", marginTop: "16px"}}>
                            <div>
                                {alert}
                            </div>
                            <div>
                                <div className="fileDescButtons">
                                    <Button content="Undo"
                                            icon="undo"
                                            onClick={this.undoDesc}
                                            size="mini"
                                            style={{backgroundColor: "#9696A0", color: "white"}}
                                    />
                                    <Button content="Save"
                                            style={{backgroundColor: "#6FBB98", color: "white"}}
                                            icon="save outline"
                                            disabled={saveBtnDisabled}
                                            onClick={this.saveDescription}
                                            loading={this.state.saving}
                                            size="mini"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            if (this.state.originalFileDesc.trim() === ""){
                return (
                    <p style={{color: "#5499F8", fontFamily: "Lato", fontSize: "14px", cursor: "pointer"}} onClick={this.startEditMode}>Add Description</p>
                )
            } else {
                return (
                    // <div className="fileDescDiv">
                       // <div className="fileDescTextArea">
                        <div>
                            <p>{fileDesc} <Popup
                                trigger={<button onClick={this.toggleEdit} style={{marginLeft: "8px"}} className="iconBtn">
                                    {/*<span><Icon name="edit outline" /></span>*/}
                                    <span><i className="bx bxs-edit-alt" /> </span>
                                </button>}
                                content="Edit Description"
                            /></p>
                        </div>
                        // <div className="fileDescButtons">
                        //</div>
                    // </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    importSheetsFileInformation: state.mainState.importSheetsFileInformation
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation,
    modifyImportSheetsFileInformation: modifyImportSheetsFileInformation
}

export default connect(mapStateToProps, mapActionsToProps)(FileDescriptionModalBody)