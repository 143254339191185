import React, {Component} from 'react'
import {connect} from 'react-redux'
import Icons from "../../../Constants/Icons";
import { Icon } from 'semantic-ui-react'
import ReadRows from "./CRUD/ReadRows";
import CreateRows from "./CRUD/CreateRows";
import UpdateRows from "./CRUD/UpdateRows";
import DeleteRows from "./CRUD/DeleteRows";

class CRUDModalBody extends Component {
    render() {
        if (this.props.modalInfo === null){
            return(
                null
            )
        }
        else{
            if ('actionType' in this.props.modalInfo){
                const bodies = {
                    "create": {"body": <CreateRows/>, "header": "A New Row"},
                    "read": {"body": <ReadRows readType="all"/>, "header": "Your Rows"},
                    "update": {"body": <UpdateRows updateType="all"/>, "header": "Your Rows"},
                    "delete": {"body": <DeleteRows/>, "header": "Rows"}
                }

                return (
                    <div>
                        <h2 className='thinHeading'> <Icon name={Icons[this.props.modalInfo.actionType + "Action"]} /> {this.props.modalInfo.actionType.toUpperCase()} {bodies[this.props.modalInfo.actionType]['header']}</h2>
                        {bodies[this.props.modalInfo.actionType]['body']}
                    </div>
                )
            }
            else{
                return(
                    null
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CRUDModalBody)