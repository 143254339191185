import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Backend_Root from "../../../Constants/API_Backend_Root";
import Loader from 'react-loader-spinner'
import { Table } from 'semantic-ui-react'

class DownloadFilesPostCallIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allFiles: [],
            gettingFiles: true
        }
    }

    componentDidMount(){
        const stopFetch = this.setState({ gettingFiles: false })

        fetch(API_Backend_Root + "api/get-post-call-files-for-download/", {
            method: 'POST',
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                filename: this.props.fileName
            })
        }).then(res => {
            res.json().then(data => {
                this.setState({
                    allFiles: data.allFiles
                })
            }).catch(err => stopFetch())
        }).catch(err => stopFetch())
    }

    render() {
        if (this.state.gettingFiles){
            return (
                <div>
                    <Loader/>
                </div>
            )
        } else {
            if (this.state.allFiles.length === 0){
                return (
                    <div>
                        No post call files found
                    </div>
                )
            } else {
                return (
                    <div>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>File Name</Table.HeaderCell>
                                    <Table.HeaderCell>Create Date</Table.HeaderCell>
                                    <Table.HeaderCell>Download File</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    this.state.allFiles.map((x, idx) =>
                                        (
                                            <Table.Row key={idx}>
                                                <Table.Cell>{x.file_name}</Table.Cell>
                                                <Table.Cell>{x.date_created}</Table.Cell>
                                                <Table.Cell><a href={x.download_link} target="_blank">Download File</a></Table.Cell>
                                            </Table.Row>
                                        ))
                                }
                            </Table.Body>
                        </Table>
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DownloadFilesPostCallIndex)