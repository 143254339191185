import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyEditID} from "../../Actions/ModifyEditID";
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";

class TeamFileButton extends Component {
    openModal = () => {
        this.props.modifyModalInfo({ pk: this.props.pk, tableType: this.props.tableType, fileHash: this.props.fileHash })
        this.props.modifyEditID(this.props.pkID)
        this.props.toggleModal("teamFileModal")
    }

    render() {
        return (
            <div>
                <Button content="Team Info" basic color="blue" icon="users" fluid onClick={this.openModal}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    modifyModalInfo: modifyModalInfo,
    modifyEditID: modifyEditID,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(TeamFileButton)