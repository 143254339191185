import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import Carousel from "../../SharedComponents/Carousel/Carousel";
import CRUD from "./Features/CRUD";
import API from "./Features/API";
import CTA from "./Features/CTA";

class HowItWorksModal extends Component {
    render() {
        return (
            <ModalApp
                name="howitworksModal"
                header={null}
                body={<Carousel
                    content={[
                        <CRUD/>,
                        <API/>,
                        <CTA/>
                    ]}
                />}
                size="large"/>
        )
    }
}

export default HowItWorksModal