import React, {Component} from 'react'
import API_Root from "../../Constants/API_Root";
import DisplayHeader from "../FilesID/DisplayHeader";
import { connect } from 'react-redux'
import {toggleToast} from "../../Actions/ToggleToast";

class ReportingTestEmail extends Component {
    sendTestEmail = () => {
        const reportInfo = this.props.reportInfo;
        reportInfo['emails'] = [localStorage.getItem("email")]

        fetch(API_Root + "api/send-api-test-auto-reporting/", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                pk: this.props.pk,
                reportInfo: reportInfo
            })
        }).then((res) => {
            if (res.status === 200){
                this.props.toggleToast({show: true, message: "Test email sent! Please check your inbox", type: "success"})
            } else {
                this.props.toggleToast({show: true, message: "Test email could not be sent for some reason! Sorry about that", type: "error"})
            }
        }).catch((err) => {
            this.props.toggleToast({show: true, message: "Test email could not be sent for some reason! Sorry about that", type: "error"})
        })
    }

    render() {
        return (
            <div>
                <DisplayHeader header="Send Test Email"/>
                <small style={{fontFamily: 'Lato',
                    color: "#9696A0",
                    fontWeight: "400",
                    fontSize: "14px"}}>Test email will only go to {localStorage.getItem("email")}</small>
                <p className="linkStyle" onClick={this.sendTestEmail} style={{marginBottom: "0"}}>Send a Test Email</p>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(null, mapActionsToProps)(ReportingTestEmail)