import React, {Component} from 'react'
import { Dropdown } from 'semantic-ui-react'

class SampleRequestDataType extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, {value}){
        this.props.changeState({"dataFormat": value});
    }

    render() {
        const idx = this.props.idx.toString();
        const name = "createFormat" + idx;
        const createFormat = this.props.dataFormat

        const dataTypeOptions = [
            {key: 1, text: 'Row', value: 'row'},
            {key: 2, text: 'Column', value: 'column'},
            {key: 3, text: 'Matrix', value: 'matrix'},

        ];

        return (
            <span> {' '}
                <Dropdown
                    placeholder="Select Data Format"
                    name={name}
                    value={createFormat}
                    options={dataTypeOptions}
                    onChange={this.handleChange}
                    inline
                />
            </span>
        )
    }
}

export default SampleRequestDataType