import React, {Component} from 'react'
import {connect} from "react-redux";
import FileList from "../LandingPage/Illustrations/FileList.svg"
import ImportCreateButton from "./ImportCreateButton"

class NoFilesUploaded extends Component {

    render(){
        const borderStyle = "24px solid #F0F0F0"

        return (
            <div style={'style' in this.props ? this.props.style : null}>
                <img src={FileList} alt="No importers created" style={{borderTop: borderStyle, borderLeft: borderStyle, borderRight: borderStyle, display: "block", margin: "0 auto" }}/>
                <div style={{textAlign: "center", marginTop: "16px"}}>
                    <span style={{fontSize: "20px", fontWeight: "700", fontFamily: "Lato", color: "#161E16"}}>Create a Custom Importer</span>
                </div>
                <div style={{textAlign: "center", marginTop: "16px"}}>
                    <span style={{color: "#5E645E", fontSize: "14px", fontWeight: "400", fontFamily: "Lato"}}>Create an importer and get an already hosted URL for users to upload files.<br/> Or a pre-written code snippet to place in your website.</span>
                </div>
                <div style={{textAlign: "center", marginTop: "16px"}}>
                    <ImportCreateButton noImporterCreated type="custom" />
                </div>
            </div>
        )
    }

}

export default NoFilesUploaded