import React, {Component} from 'react'
import DocsImage from "../DocsImage";
import RereadFile from "../Upload/RereadFile";

class ActionsFileOptions extends Component {
    render() {
        return (
            <div>
                <div className="docsSection">
                    <RereadFile rereadIndex
                                setBothMenuItems={this.props.setBothMenuItems}
                                hasCsv={true} fileNames={<span><b>excel files</b> and Google Sheets</span>}/>
                    <p>You can change the following to re-read your file</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Read your .csv file with another delimiter</p>
                    <p>Go to the <b>File Options</b> action in your File and you can change the delimiter from there</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-ReadSettings/CSVDelimiter.png"
                         width="75%"  alt="read csv file with another delimiter" />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Read your file from different row</p>
                    <p>Go to the <b>File Options</b> action in your File and you can change 2 things</p>
                    <div className="docsList">
                        <p>1. Which row to start reading the file from</p>
                        <p>2. If your file has column names or not</p>
                    </div>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-ReadSettings/StartRowFileHeaderOptions.png"
                         width="75%" alt="read file with different options" />
                    <p>If you select that your file does <b>not</b> have column names, then the API will use generic column names such as: "0", "1", "2"</p>
                    <p>If you select that your file <b>does</b> have column names, then the row that you start reading the file will become your column names</p>
                </div>
            </div>
        )
    }
}

export default ActionsFileOptions