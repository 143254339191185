import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../../../../Constants/API_Root";
import Loader from 'react-loader-spinner'
import EditTable from "./EditTable"
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";

class EditSettingsIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetchingEditOptions: true,
            editingYourFile: false,
        }

    }

    componentDidMount(){
        const fileHash = this.props.fileInfo.fileHash;
        const accessKey = this.props.fileInfo.accessKey;
        const secretKey = this.props.fileInfo.secretKey;

        const rows = 'data' in this.props.fileInfo['matrixSample'] ? this.props.fileInfo['matrixSample']['data'] : [[]];
        const columns = this.props.fileInfo['columnInformation']['names']

        const error = () => this.setState({ fetchingEditOptions: false}, () => {
            const currEditOptions = JSON.parse(JSON.stringify(this.props.editOptions))
            currEditOptions.rows = rows
            currEditOptions.columns = columns
            this.props.modifyCustomImportSettings("editOptions", currEditOptions)
        })

        fetch(API_Root + "api/get-edit-information-for-file/" + fileHash + "/" + accessKey + "/" + secretKey + "/")
            .then(res => {
                if (res.status === 200){
                    res.json().then(data => {
                        const editOptions = data.editOptions;

                        editOptions.fetchingEditOptions = false
                        editOptions.editingYourFile = false

                        if (editOptions.skipRows === ""){
                            editOptions.skipRows = 0
                        } else {
                            editOptions.skipRows = parseInt(data.editOptions) - 1
                        }

                        if (editOptions.startRow === ""){
                            editOptions.startRow = 0
                        } else {
                            editOptions.startRow = parseInt(data.editOptions) - 1
                        }

                        editOptions.sheetNames = data.sheetNames;
                        editOptions.sheetRead = data.sheetRead;
                        editOptions.rows = data.rows;
                        editOptions.columns = data.columns;

                        this.props.modifyCustomImportSettings("editOptions", editOptions)
                        this.setState({ fetchingEditOptions: false, editingYourFile: false })
                    }).catch(err => error())
                } else{
                    error()
                }
            }).catch(err => error())
    }

    changeState = (key, value) => {
        const currEditOptions = JSON.parse(JSON.stringify(this.props.editOptions))
        currEditOptions[key] = value

        this.props.modifyCustomImportSettings("editOptions", currEditOptions)
    }

    render() {
        if (this.state.fetchingEditOptions || this.state.editingYourFile){
            return (
                <div style={{padding: '100px 0', textAlign: 'center'}}>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    {
                        'embed' in this.props ?
                        <h4>Setting up your edit page...</h4>
                        : <h4>Re-reading your file settings...</h4>
                    }
                </div>
            )
        } else {
            return (
                <div>
                    <EditTable changeState={this.changeState} />
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    editOptions: state.mainState.customImportEditOptions
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(EditSettingsIndex)