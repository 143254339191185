import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../Constants/API_Root";
import {Button} from "semantic-ui-react";
import {toggleToast} from "../Actions/ToggleToast";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";

class UpdateFileHashButton extends Component {
    constructor(props) {
        super(props);
        this.state={
            loading:false
        }
    }


    toggleUseFileHash =() =>{
        this.setState({loading:true})
        let error =()=>{
            this.setState({loading:false})
            this.props.toggleToast({
                show: true,
                message: "There was an error updating your File ID. Please try again. Contact us if this error persists.",
                type: "error"
            })
        }

        fetch(API_Root + "api/toggle-use-file-hash/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                pk: this.props.pk,
                useFileHash:true,
            })
        }).then((res) => {
            if (res.status === 200){
                this.setState({loading: false})

                let fileInformation = JSON.parse(JSON.stringify(this.props.fileInformation))
                let pkIDFileInfo = fileInformation[this.props.pkID]
                pkIDFileInfo.useFileHash = true
                fileInformation[this.props.pkID] = pkIDFileInfo

                this.props.modifyFileInformation(fileInformation)
                this.props.toggleToast({
                    show: true,
                    message: "Congrats your File IDs have been updated!",
                    type: "success"
                })


            }
            else{
                error()
            }
        }).catch(err => error())
    }

    render() {
        return (
            <Button style={{marginLeft:'5px'}} color="green" onClick={this.toggleUseFileHash} loading={this.state.loading}> Upgrade Now </Button>

        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,

})

const mapActionsToProps = {
    toggleToast:toggleToast,
    modifyFileInformation:modifyFileInformation

}

export default connect(mapStateToProps, mapActionsToProps)(UpdateFileHashButton)