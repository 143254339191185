import React, {Component} from 'react'
import {Button, Message} from "semantic-ui-react";
// import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Link from "react-router-dom/Link";
import NewUserActionButtonsRow from "./NewUserActionButtonsRow";

class AppSumoWelcomeBanner extends Component {
    render() {
        return (
            <Message style={{marginBottom: "10px"}}>
                    <div style={{textAlign:'center',padding:'10px'}}>
                        <h3> Hey Sumo-ling <span role="img" aria-label="waving">👋</span> </h3>
                        <h4> Welcome to API Spreadsheets! Get started right away <span role="img" aria-label="smile">😀</span> </h4>
                        <br/>
                        <NewUserActionButtonsRow/>
                    </div>
                </Message>



        )
    }
}

export default  AppSumoWelcomeBanner