import React, {Component} from 'react'
import { Input } from 'semantic-ui-react'
import {connect} from 'react-redux'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import {modifyColumnInfoInRedux} from "./modifyColumnInfoInRedux";
import {getNameErrorMsg} from "./getNameErrorMsg";

class ColumnName extends Component {
    componentDidMount(){
        if (getNameErrorMsg(this.props.name, this.props.idx, this.props.columnNames).trim() !== ""){
            this.props.changeState("error", true)
        }
    }

    handleChange = (e) => {
        const errorMsg = getNameErrorMsg(JSON.parse(JSON.stringify(e.target.value)), this.props.idx, this.props.columnNames)

        let error;
        if (errorMsg.trim() !== ""){
            error = true
        } else{
            error = false
        }

        const currInfo = {error: error, name: e.target.value, dupeCheckName: e.target.value}
        if (this.props.internalNameEqual){
            currInfo.internalName = e.target.value
            currInfo.dupeCheckInternalName = e.target.value
        }

        modifyColumnInfoInRedux(currInfo, this.props, this.props.idx)
    }

    render() {
        const errorMsg = getNameErrorMsg(this.props.name, this.props.idx, this.props.columnNames)
        return (
            <div>
                <input style={{width: "100%"}}
                       placeholder="Enter column name"
                       value={this.props.name}
                       className="inputGray"
                       onChange={this.handleChange}/>
                {errorMsg === "" ? null : <small style={{color: "red"}}>{errorMsg}</small>}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    customImportColumnsAddlInfo: state.mainState.customImportColumnsAddlInfo,
    customImportColumns: state.mainState.customImportColumns
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnName)