import React, {Component} from 'react'
import logoPictureURLs from "../logoPictureURLs";
import APIQuickstartCode from "../../../InfoPages/HowItWorks/Features/APIQuickstartCode";
import { Label } from 'semantic-ui-react'

class SimpleDesign extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Use API Easily With Our Quickstart Code</div>
                <div style={this.props.subheadingStyle}>Test out the code below to <Label color="yellow">GET</Label> data</div>
                <APIQuickstartCode/>
                <div style={{marginTop: "16px"}}>
                    <div style={this.props.subheadingStyle}>Or open this URL in your browser <a href="https://api.apispreadsheets.com/data/412/" target="_blank">https://api.apispreadsheets.com/data/412/</a> </div>
                </div>
            </div>


        )
    }
}

export default SimpleDesign