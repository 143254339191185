import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Menu, Icon } from 'semantic-ui-react'
import GuideDetails from "./GuideDetails";

class GuidesMenu extends Component {
    constructor(props) {
        super(props);

        this.changeMenuItem = this.changeMenuItem.bind(this);
    }

    changeMenuItem(e, {name}){
        this.props.changeHelpMenu(name)
    }

    render() {
        let menuItems1 = [];
        let menuItems2 = [];

        for (let i=0; i<GuideDetails.length; i++){
            let x = GuideDetails[i];

            let icon = null;

            if (x.iconType === "custom"){
                icon = <span className={x.icon} />
            }
            else{
                icon = <Icon name={x.icon} />
            }
            let menuItem = <Menu.Item
                name={i}
                active={this.props.topic === i}
                onClick={this.changeMenuItem}
                key={i}
            >
                {icon} {x.header}
            </Menu.Item>

            if (x.menuType === "tutorial"){
                menuItems2.push(menuItem)
            }
            else if (x.menuType === "quickStart"){
                menuItems1.push(menuItem)
            }
        }

        return (
            <div style={{paddingLeft: '50px'}}>
                <div className="guidesMenu">
                    <div style={{textAlign: 'center'}}>
                        <h4>Quick Start</h4>
                        <p className="guidesMenuDesc"><i>Guides to quickly get you started</i></p>
                    </div>
                    <Menu vertical secondary pointing fluid>
                        {menuItems1}
                    </Menu>
                </div>
                <br/><br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(GuidesMenu)