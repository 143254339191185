import React, {Component} from 'react'
import {connect} from 'react-redux'
import isObjectEmpty from "../../Constants/isObjectEmpty";
import ColumnOnlyNamesBody from "./ColumnOnlyNamesBody";
import ColumnNamesInfoBody from "./ColumnNamesInfoBody";

class ColumnNamesBody extends Component {
    render() {
        const fileInfo = this.props.fileInformation[this.props.editID];

        if (isObjectEmpty(fileInfo.columnInformation)){
            return(
                <ColumnOnlyNamesBody fileInfo={fileInfo}/>
            )
        }
        else{
            return(
                <ColumnNamesInfoBody />
            )
        }
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ColumnNamesBody)