const APIDemoVideoLinks = [
    {
        topic: "Full API Demo",
        desc: "Quickstart videos on how to upload a file and use the API",
        videos: [{
            title: "Get started using the API",
            id: "xw-v0cNJNfw",
            time: "0:47"
        }]
    },
    {
        topic: "Upload",
        desc: "In-depth videos showing how to connect files from various sources",
        videos: [{
            title: "Google Sheets",
            id: "A7m7_rQDtNo",
            time: "1:22"
        }, {
            title: "Error Connecting to Google Sheets",
            id: "2LeoMGMz_O8",
            time: "1:22"
        }, {
            title: "Local Files",
            id: "kFK5wb3MU6w",
            time: "1:33"
        }, {
            title: "Dropbox",
            id: "AaDKiJOp9bc",
            time: "1:39"
        }, {
            title: "AWS S3",
            id: "ZB7WRsKm4Ow",
            time: "2:23"
        }, {
            title: "Quickstart JSON Object",
            id: "vKNRBFCJGAI",
            time: "2:31"
        }]
    },
    {
        topic: "CRUD Actions",
        desc: "Learn how to make create, read, update, and delete requests to your files using the API",
        videos: [{
            title: "Create Rows",
            id: "xLbxEu-Gc_o",
            time: "5:46"
        }, {
            title: "Read Rows",
            id: "KrVycYu-Um8",
            time: "4:43"
        }, {
            title: "Update Rows",
            id: "ctY8j04xug8",
            time: "4:27"
        }, {
            title: "Delete Rows",
            id: "DJshWn3hXAc",
            time: "3:36"
        }]
    }
]

export default APIDemoVideoLinks