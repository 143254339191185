import React, {Component} from 'react'
import DocsImage from "../DocsImage";

class ActionsColumnNames extends Component {
    render() {
        return (
            <div>
                <div className="docsSection">
                    <p>Column Names can be viewed by clicking on their action in the menu below</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/ColumnNames-Select.png" alt='Select Column Names' />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Column Names are not live</p>
                    <p>The column names that you see on your Files page are determined at the time of upload</p>
                    <p>They are then updated whenever an API request is made or the file is refreshed or re-uploaded</p>
                    <p>Which means there can be instances when your File's or Google Sheet's Column Names do not match up with the Column Names on your Files page</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/ColumnNames-Display.png" alt='View Column Names' width="70%"/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Column Names are helpful for Queries and Data Governance</p>
                    <p>Column Names are useful when you are <span onClick={e => this.props.setBothMenuItems("api-query")}>using SQL queries</span> when using the API. They let you know the <b>EXACT</b> name of the column you should use when making requests</p>
                    <p>Column Names are also useful to keep your data organized. Especially if the File is shared with your Team</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/ColumnNames-Desc.png" alt='Write Column Description' width="70%"/>
                </div>
            </div>
        )
    }
}

export default ActionsColumnNames