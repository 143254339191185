import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class StartRead extends Component {
    render() {
        const fileID = this.props.fileID;

        return (
            <span>
                <div style={this.props.headerStyle}>1. Query will be in a similar format to SQL with some exceptions</div>
                <p><Icon name="caret right" /> Only <b>SELECT</b>, <b>FROM</b> and <b>WHERE</b> identifiers are allowed. Other SQL identifiers like <b>ORDER BY</b> and <b>GROUP BY</b> are not allowed</p>
                <p><Icon name="caret right" /> Only <code>*</code> or column names like <code>col_1,col_2,...</code> are allowed after the <b>SELECT</b> and before the <b>FROM</b> identifiers. No functions are allowed</p>
                <div className="codeTheoryBox">
                    <span>SELECT <code>*</code> FROM <code>fileID</code> WHERE <code>filterClause</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <b>Valid</b>
                    <span>SELECT <code>*</code> FROM <code>{fileID}</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <b>Valid</b>
                    <span>SELECT <code>country, population</code> FROM <code>{fileID}</code> WHERE <code>{"country LIKE 'United%' OR country='Australia' OR country='Indonesia'"}</code></span>
                </div>
                <br/>
                <div className="codeInvalidBox">
                    <b>Invalid</b>
                    <span>SELECT <code>*</code> FROM <code>{fileID}</code> WHERE <code>id>5</code> GROUP BY <code>date desc</code></span>
                </div>
                <br/>
                <div className="codeInvalidBox">
                    <b>Invalid</b>
                    <span>SELECT <code>substring(country, 1, 2) AS country_code</code> FROM <code>{fileID}</code></span>
                </div>
            </span>
        )
    }
}

export default StartRead