import React, {Component} from 'react'
import { Divider } from 'semantic-ui-react'
import {capitalize} from "../../../Constants/capitalize";

class WebhookBodyParameters extends Component {
    render() {
        const defaultThings = {
            "create": {respObject: "an empty object", reqBody: <span>your <b>data</b> and your <b>dataFormat</b></span>},
            "read": {respObject: "your data", reqBody: <span>your <b>query</b>, the number of <b>rows</b>, and your <b>dataFormat</b>. The <b>query</b> and <b>rows</b> will be <code>null</code> if you didn't include them in your request</span>},
            "update": {respObject: "an empty object", reqBody: <span>your <b>data</b>, your <b>query</b> and your <b>dataFormat</b></span>},
            "delete": {respObject: "an object containing the number of rows deleted", reqBody: <span>your <b>query</b>, the number of <b>rows</b>, and your <b>dataFormat</b>. The <b>rows</b> will be <code>null</code> if you didn't include them in your request</span>}
        }

        const eventInfo = defaultThings[this.props.eventType]

        return (
            <div>
                <h5 id="parameterHeading">Request Body Parameters for <b>{capitalize(this.props.eventType)}</b></h5>
                <Divider/>
                <span className="parameterName">request_type</span> <span className="parameterType">string</span>
                <span className="parameterSecondLine"><span className="parameterDefault">default={this.props.eventType === "create" || this.props.eventType === "update" ? '"POST"' : '"GET"'}</span></span>
                <span className="parameterDesc">The request type that was made to API Spreadsheets before the Webhook was called. Always {this.props.eventType === "create" || this.props.eventType === "update" ? "POST" : "GET"} for {this.props.eventType} requests</span>
                <Divider/>
                <span className="parameterName">status_code</span> <span className="parameterType">integer</span>
                <span className="parameterSecondLine"></span>
                <span className="parameterDesc">The status code that API Spreadsheets returned for the request that was made before the Webhook was called. Always {this.props.eventType === "create" || this.props.eventType === "update" ? 201 : 200} for {this.props.eventType} requests if they were successful. You can check out all the response codes <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-" + this.props.eventType)}>in the docs.</span></span>
                <Divider/>
                <span className="parameterName">response_object</span> <span className="parameterType">JSON Object</span>
                <span className="parameterSecondLine"></span>
                <span className="parameterDesc">The response that was returned from API Spreadsheets. For {this.props.eventType} requests, it will be {eventInfo.respObject}, unless there is an error. You can find all the response objects <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-" + this.props.eventType)}>in the docs.</span></span>
                <Divider/>
                <span className="parameterName">crud_action</span> <span className="parameterType">JSON Object</span>
                <span className="parameterSecondLine"><span className="parameterDefault">default="{this.props.eventType}"</span></span>
                <span className="parameterDesc">The action of the request made to API Spreadsheets. Always "{this.props.eventType}" for {this.props.eventType} requests</span>
                <Divider/>
                <span className="parameterName">request_body</span> <span className="parameterType">JSON Object</span>
                <span className="parameterSecondLine"></span>
                <span className="parameterDesc">The request body that was sent to API Spreadsheets before the webhook was called. For {this.props.eventType} requests, the request body object will contain {eventInfo.reqBody}.</span>
                <Divider/>
                <span className="parameterName">access_key</span> <span className="parameterType">string</span>
                <span className="parameterSecondLine"><span className="parameterDefault">default=null</span></span>
                <span className="parameterDesc">If file is private, this will be the access key used to make a request. Otherwise it will be <code>null</code></span>
                <Divider/>
                <span className="parameterName">secret_key</span> <span className="parameterType">string</span>
                <span className="parameterSecondLine"><span className="parameterDefault">default=null</span></span>
                <span className="parameterDesc">If file is private, this will be the secret key used to make a request. Otherwise it will be <code>null</code></span>
                <Divider/>
                <span className="parameterName">file_id</span> <span className="parameterType">integer</span>
                <span className="parameterSecondLine"></span>
                <span className="parameterDesc">The ID of the file as seen on your dashboard</span>
                <Divider/>
                <span className="parameterName">file_name</span> <span className="parameterType">string</span>
                <span className="parameterSecondLine"></span>
                <span className="parameterDesc">Name of the file as seen on your dashboard</span>
                <Divider/>
                <span className="parameterName">file_type</span> <span className="parameterType">string</span>
                <span className="parameterSecondLine"></span>
                <span className="parameterDesc">Type of the file, it could be one 3: <b>file</b>, <b>googleSheets</b> or <b>dropbox</b> depending on your source</span>
                <Divider/>
                <span className="parameterName">sheet_read</span> <span className="parameterType">string</span>
                <span className="parameterSecondLine"><span className="parameterDefault">default=null</span></span>
                <span className="parameterDesc">For <b>excel</b> files or <b>Google Sheets</b> this will be the name of the sheet that was read. Such as <b>Sheet1</b></span>
                <Divider/>
                <span className="parameterName">uploaded_dt</span> <span className="parameterType">string "YYYY-MM-DD HH:MM:SS"</span>
                <span className="parameterSecondLine"></span>
                <span className="parameterDesc">The date time the file was uploaded to API Spreadsheets. It will always be in UTC timezone in the format <b>YYYY-MM-DD HH:MM:SS</b></span>
                <Divider/>
                <span className="parameterName">private</span> <span className="parameterType">boolean</span>
                <span className="parameterSecondLine"></span>
                <span className="parameterDesc"><code>true</code> or <code>false</code> depending on if the file is private or not</span>
            </div>
        )
    }
}

export default WebhookBodyParameters