import React, {Component} from 'react'
import {connect} from 'react-redux'
import isObjectEmpty from "../../Constants/isObjectEmpty";
import TableAccordion from "./TableAccordion";
import { Message, Icon } from 'semantic-ui-react'

class DBTablesAndColumnInfoModalBody extends Component {
    render() {
        if (this.props.modalInfo === null){
            return (
                null
            )
        } else {
            let tableAccordion = [];

            if(!isObjectEmpty(this.props.modalInfo)){
                for (let tableName in this.props.modalInfo['tablesAndColumnInfo']){
                    tableAccordion.push(<TableAccordion tableName={tableName} columnInfo={this.props.modalInfo['tablesAndColumnInfo'][tableName]}/>)
                }
            } else{
                tableAccordion = <div>
                    <Message
                        header="There weren't any tables in your database"
                        content="If you think this is in error, please contact support@dbspreadsheets.com"
                        icon="database"
                        negative
                    />
                </div>
            }

            return (
                <div>
                    <h2 className="thinHeading"><Icon name="columns" /> All Tables and Columns in Your Database</h2>
                    <br/>
                    {tableAccordion}
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DBTablesAndColumnInfoModalBody)