import React, {Component} from 'react'
import BannerAlert from "../../SharedComponents/BannerAlert";

class AccountsErrorBanner extends Component {
    render() {
        return (
            <BannerAlert type="error"
                         header={'There was an error getting your ' + this.props.accountType + ' accounts'}
                         icon="bx bx-error-alt"
                         content="Please refresh and try again. Contact our Support if this error persists"
            />
        )
    }
}

export default AccountsErrorBanner