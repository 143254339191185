import React, {Component} from 'react'
import Upload from "./Upload";
import API from "../HowItWorks/Features/API";
// import APIFeatures from "./APIFeatures";
import CTAButtonLanding from "../../SharedComponents/CTAButtonLanding"
import CRUD from "../HowItWorks/Features/CRUD";

class FeaturesIndex extends Component {
    render() {
        return (
            <div className="featuresIndex">
                <Upload/>
                <CTAButtonLanding/>
                <CRUD/>
                <CTAButtonLanding/>
                <API />
                <CTAButtonLanding/>
                {/*<APIFeatures align="left"/>*/}
                {/*<CTAButtonLanding/>*/}
                <div style={{marginBottom: '300px'}} />
            </div>
        )
    }
}

export default FeaturesIndex