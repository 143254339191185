import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class MenuItem extends Component {
    selectMenuItem = (e) => {
        this.props.onClick(e, this.props.name, this.props.idx)
    }

    render() {
        let id = ""
        if ("menuItem" in this.props){
            if (this.props.menuItem === this.props.name){
                id = "menuSegmentSelected"
            }
        }

        return (
            <div className="menuItem" id={id} onClick={this.selectMenuItem}>
                <div>
                    {'icon' in this.props ? this.props.icon !== null ? <span style={{paddingRight: "12px"}}><Icon name={this.props.icon}  /></span> : null : null }
                    <span>{this.props.label}</span>
                </div>
                <div>
                </div>
                <div>
                    <i className="bx bx-chevron-right 1" style={{fontSize: "20px", textAlign: "right", verticalAlign: "middle"}} />
                </div>
            </div>
        )
    }
}

export default MenuItem