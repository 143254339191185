import React, {Component} from 'react'
import {connect} from 'react-redux'
import { TextArea, Input, Form } from 'semantic-ui-react'
import DisplayHeader from "../FilesID/DisplayHeader"

class ReportingEmailDetails extends Component {
    handleInputChange = (e, name) => {
        this.props.changeState(name, e.target.value)
    }

    render() {
        return (
            <div>
                <div style={{marginBottom: "8px"}}>
                    <DisplayHeader header="Email Message Details"/>
                </div>
                <div style={{marginBottom: "16px"}}>
                    <span style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#5E645E"}}>Email Subject</span>
                    <input className="inputGray" value={this.props.subject} onChange={e => this.handleInputChange(e, "subject")} style={{width: "100%"}}/>
                </div>
                <div style={{marginBottom: "16px"}}>
                    <span style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#5E645E"}}>From Name</span>
                    <input className="inputGray" value={this.props.from} onChange={e => this.handleInputChange(e, "from")} style={{width: "100%"}} />
                </div>
                <div style={{marginBottom: "16px"}}>
                    <span style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#5E645E"}}>Email Message</span>
                    <Form>
                        <TextArea value={this.props.msg} onChange={e => this.handleInputChange(e, "msg")} rows={5} className="inputGray" fluid/>
                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ReportingEmailDetails)