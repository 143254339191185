import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Segment, Button, Icon } from 'semantic-ui-react'
import RemoveS3Account from "./RemoveS3Account";
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";

class S3AccountRow extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    editAccount = () => {
        this.props.modifyModalInfo({
            s3AccountInfo: this.props.info,
            s3AccountName: this.props.email,
            allNames: this.props.allNames
        })

        this.props.toggleModal("editS3Modal")
    }

    render() {
        return (
            <Segment style={{padding: "10px", marginBottom: "10px"}}>
                <div className="row">
                    <div className="col-sm-8">
                        <h5><Icon name="aws" /> {this.props.email}</h5>
                    </div>
                    <div className="col-sm-4">
                        <div style={{textAlign: "right"}}>
                            <Button circular basic color="blue" icon="edit alternate outline" onClick={this.editAccount}/>
                            <RemoveS3Account email={this.props.email} removeAccountInfo={this.props.removeAccountInfo}/>
                        </div>
                    </div>
                </div>
            </Segment>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(S3AccountRow)