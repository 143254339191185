import React, {Component} from 'react'
import PreWrittenCodeIcon from "../../Illustrations/PreWrittenCodeIcon.svg"
import ImportCodeSnippet from "../../../Import/ImportSheetsSegment/ImportCodeSnippet";

class PrewrittenCode extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Pre-written Code for Your Applications</div>
                <div style={this.props.subheadingStyle}>Copy and paste this code to create a importer on your own websites or applications</div>
                <ImportCodeSnippet importHash="3d7612f8aa463f8c15279beb5c46ae65" shouldMatch={false}/>
                <br/>
            </div>
        )
    }
}

export default PrewrittenCode