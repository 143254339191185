import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Accordion, Icon } from 'semantic-ui-react'
import ReactJson from 'react-json-view'

class QuickstartResponse extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: this.props.tableType === "all" ? -1 : 0,
        }

        this.handleAccordionClick = this.handleAccordionClick.bind(this);
    }

    handleAccordionClick(e, idxClicked){
        const { index } = idxClicked;
        const activeIndex = this.state.activeIndex;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex })
    }

    render() {
        const activeIndex = this.state.activeIndex;
        const title = "JSON Response";

        return (
            <Accordion styled fluid>
                <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleAccordionClick}>
                    <Icon name='dropdown' />
                    <b>{title}</b>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                    <ReactJson src={this.props.fileInformation[this.props.pkID].jsonRowSample} name={null} theme="ocean" displayObjectSize={false}/>
                </Accordion.Content>
            </Accordion>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(QuickstartResponse)