import React, {Component} from 'react'
import FAQ from './FAQ'

class FAQBlock extends Component {
    render() {
        const FAQs = this.props.FAQs.map((x, idx) =>
            <FAQ key={idx} question={x.question} answer={x.answer} image={'image' in x ? x.image : null}/>
        )
        return (
            <div>
                {FAQs}
            </div>
        )
    }
}

export default FAQBlock