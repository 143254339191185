import React, {Component} from 'react'
import capitalizeHyphenated from "../Constants/capitalizeHyphenated";

class FeatureCard extends Component {
    render() {
        const isWhite = 'white' in this.props;

        return (
            <div style={{textAlign: 'center', fontFamily: 'Lato'}}>
                <img src={this.props.img} style={{ height: '110px'}} alt={this.props.header}/>
                <div style={{marginTop: "16px", fontWeight: '700', fontSize: "20px", color: isWhite ? "white" : "#161E16"}}>{capitalizeHyphenated(this.props.header)}</div>
                <div style={{marginTop: "16px", fontWeight: '400', fontSize: "14px", color: isWhite ?" white" : "#9696A0"}}>{this.props.desc}</div>
            </div>
        )
    }
}

export default FeatureCard