import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Message, Button, Input } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {toggleModal} from "../../Actions/ToggleModal";
import {toggleToast} from "../../Actions/ToggleToast";

class EditS3ModalBody extends Component {
    constructor(props) {
        super(props);

        const accountInfo = this.props.modalInfo.s3AccountInfo;
        const allNames = JSON.parse(JSON.stringify(this.props.modalInfo.allNames));
        allNames.splice(allNames.indexOf(this.props.modalInfo.s3AccountName), 1)

        this.state = {
            error: null,
            accessKey: accountInfo.accessKey,
            secretKey: accountInfo.secretKey,
            name: this.props.modalInfo.s3AccountName,
            description: accountInfo.description,
            loading: false,
            allNames: allNames
        }
    }

    changeKeysInput = (e) => this.setState({ [e.target.name]: e.target.value })

    dismissMessage = () => this.setState({ error: null })

    setErrorMsg = (header="There was an error connecting to your account", msg="Try again") => {
        this.setState({
            error: <div style={{marginTop: "10px"}}>
                <Message negative={true}
                         icon="warning sign"
                         header={header}
                         content={msg}
                         onDismiss={this.dismissMessage}
                />
            </div>,
            loading: false
        })
    }

    editAccountInfo = () => {
        this.setState({ loading: true })

        fetch(API_Root + "api/crud-s3-accounts-for-user/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                actionType: "update",
                updateInfo: {accessKey:this.state.accessKey, secretKey:this.state.secretKey, name: this.state.name, description: this.state.description},
                name: this.props.modalInfo.s3AccountName
            })
        }).then((res) => {
            if (res.status === 200){
                this.setState({
                    error: null,
                    loading: false
                }, () => {
                    this.props.toggleModal(null)
                    window.location.reload()
                    this.props.toggleToast({ show: true, type: "success", message: "AWS S3 Account edited successfully!" })
                })
            } else if (res.status === 400){
                this.setErrorMsg("This account does not exist in the database", "Please try again or contact us if there is an error")
            } else{
                this.setErrorMsg("There was an error connecting to your account", "Check your keys and try again")
            }
        }).catch(err => this.setErrorMsg("There was an error connecting to your account", "Please try again"))
    }

    render() {
        const newAccountError = this.state.name.trim() === "" || this.state.accessKey.trim() === "" || this.state.secretKey.trim() === "" || this.state.allNames.includes(this.state.name.trim())
        return (
            <div>
                <div style={{border: "1px solid lightgrey", padding: "10px", borderRadius: "8px"}}>
                    <h4>Connect to a new AWS S3 Account</h4>
                    {/*TODO: Enter help link on how to get access and secret key for S3*/}
                    <div className="inputLabel thinHeading"><span className="thinHeading"> Account Name</span></div>
                    <Input placeholder="My business account"
                           name="name"
                           onChange={this.changeKeysInput}
                           value={this.state.name}
                           fluid
                    />
                    {this.state.allNames.includes(this.state.name.trim()) ?
                        <small style={{color: "red"}}>An account with this name already exists</small> : null}
                    <div className="inputLabel thinHeading"><span className="thinHeading"> Access Key</span></div>
                    <Input placeholder="ABCDEFGHIJKLMNOPQRSTUV"
                           name="accessKey"
                           onChange={this.changeKeysInput}
                           value={this.state.accessKey}
                           fluid
                    />
                    <div className="inputLabel thinHeading"><span className="thinHeading"> Secret Key</span></div>
                    <Input placeholder="d9c24ff43fbcedf44e3335b69bf5e161ada79683"
                           name="secretKey"
                           onChange={this.changeKeysInput}
                           value={this.state.secretKey}
                           fluid
                    />
                    <br/>
                    <Button content="Connect to AWS S3"
                            disabled={newAccountError}
                            icon="plug"
                            color="green"
                            loading={this.state.loading}
                            onClick={this.editAccountInfo}/>
                    {newAccountError ? <small style={{color: "red"}}>Please enter all values to connect</small> : null}
                    {this.state.error}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(EditS3ModalBody)