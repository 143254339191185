import React, {Component} from 'react'
import {Input} from "semantic-ui-react";
import API_Root from "../Constants/API_Root";
import { Message, Button } from 'semantic-ui-react'
import isObjectEmpty from "../Constants/isObjectEmpty";
import DisplayHeader from "../FileDnD/FilesID/DisplayHeader";
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

class ConnectAWSAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            accessKey: "",
            secretKey: "",
            name: "",
            description: "",
            loading: false
        }
    }

    changeKeysInput = (e) => this.setState({ [e.target.name]: e.target.value })

    dismissMessage = () => this.setState({ error: null })

    setErrorMsg = (header="There was an error connecting to your account", msg="Try again") => {
        this.setState({
            error: <div style={{marginTop: "10px"}}>
                <Message negative={true}
                            icon="warning sign"
                            header={header}
                            content={msg}
                            onDismiss={this.dismissMessage}
                />
            </div>,
            loading: false
        })
    }

    connectToAccount = () => {
        this.setState({ loading: true })

        fetch(API_Root + "api/crud-s3-accounts-for-user/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                actionType: "create",
                accessKey:this.state.accessKey,
                secretKey:this.state.secretKey,
                name: this.state.name.trim(),
                description: this.state.description
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.setState({
                        error: null,
                        loading: false
                    }, () => {
                        let changeStateObject;

                        if ('addS3FromImport' in this.props){
                            changeStateObject = {
                                bucketNames: data.bucketNames,
                                awsS3Accounts: this.addNewAccountToCurrentAccounts(this.state.name.trim(), data.accountInfo),
                            }
                        } else{
                            changeStateObject = {
                                bucketNames: data.bucketNames,
                                awsS3Accounts: this.addNewAccountToCurrentAccounts(this.state.name.trim(), data.accountInfo),
                                selectedAccountInfo: data.accountInfo,
                                selectedAccountName: this.state.name.trim()
                            }

                            if (isObjectEmpty(this.props.awsS3Accounts)){
                                changeStateObject['currentDisplay'] = 'buckets'
                            }
                        }

                        this.props.changeState(changeStateObject)
                    })
                }).catch(err => this.setErrorMsg("There was an error connecting to your account", "Please try again"))
            } else if (res.status === 400){
                this.setErrorMsg("This account already exists", "Please enter a new account")
            } else{
                this.setErrorMsg("There was an error connecting to your account", "Check your keys and try again")
            }
        }).catch(err => this.setErrorMsg("There was an error connecting to your account", "Please try again"))

    }

    addNewAccountToCurrentAccounts = (newAccountName, newAccountInfo) => {
        const copiedCurrentAccounts = JSON.parse(JSON.stringify(this.props.awsS3Accounts))
        copiedCurrentAccounts[newAccountName] = newAccountInfo

        return copiedCurrentAccounts
    }

    render() {
        const newAccountError = this.state.name.trim() === "" || this.state.accessKey.trim() === "" || this.state.secretKey.trim() === "" || this.state.name.trim() in this.props.awsS3Accounts
        return (
            <div>
                <div style={{marginBottom: "16px"}}>
                    <DisplayHeader header="Connect to a new AWS S3 Account"/>
                </div>
                {/*TODO: Enter help link on how to get access and secret key for S3*/}
                <div style={{marginBottom: "16px"}}>
                    <div style={{fontFamily: "Lato", fontSize: '14px', color: "#5E645E", margin: "0 0 6px 0"}}>Account Name</div>
                        <input placeholder="My business account"
                               name="name"
                               onChange={this.changeKeysInput}
                               value={this.state.name}
                               style={{width: "100%"}}
                               className="inputGray"
                        />
                        {this.state.name.trim() in this.props.awsS3Accounts ?
                            <small style={{color: "red"}}>An account with this name already exists</small> : null}
                </div>
                <div style={{marginBottom: "16px"}}>
                    <div style={{fontFamily: "Lato", fontSize: '14px', color: "#5E645E", margin: "0 0 6px 0"}}>Access Key</div>
                    <input placeholder="ABCDEFGHIJKLMNOPQRSTUV"
                           name="accessKey"
                           onChange={this.changeKeysInput}
                           value={this.state.accessKey}
                           style={{width: "100%"}}
                           className="inputGray"
                    />
                </div>
                <div style={{marginBottom: "24px"}}>
                    <div style={{fontFamily: "Lato", fontSize: '14px', color: "#5E645E", margin: "0 0 6px 0"}}>Secret Key</div>
                    <input placeholder="d9c24ff43fbcedf44e3335b69bf5e161ada79683"
                           name="secretKey"
                           onChange={this.changeKeysInput}
                           value={this.state.secretKey}
                           style={{width: "100%"}}
                           className="inputGray"
                    />
                </div>
                <Button content="Connect to AWS S3"
                        disabled={newAccountError}
                        icon="plug"
                        style={gunaldiStyles.btnGreen}
                        loading={this.state.loading}
                        onClick={this.connectToAccount}/>
                {newAccountError ? <small style={{color: "red", display: "block"}}>Please enter all values to connect</small> : null}
                {this.state.error}
            </div>
        )
    }
}

export default ConnectAWSAccount