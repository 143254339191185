// API Backend Route for testing or Development

const browserDomain = window.location.origin;

let API_Backend_Root;

if (browserDomain.includes("localhost")){
    API_Backend_Root = "http://127.0.0.1:8080/";
}
else{
    API_Backend_Root = "https://api.apispreadsheets.com/";
}

export default API_Backend_Root