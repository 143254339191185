import React, {Component} from 'react'
import DocsBaseURL from '../../DocsBaseURL'
import DocsGetURLParameters from "./DocsGetURLParameters";
import DocsGetResponse from "./DocsGetResponse";
import DocsGetResponseCode from "../../DocsGetResponseCode";

class ReadIndex extends Component {
    render() {
        let baseGetURL = 'data/[fileID]/?query=[query]&dataFormat=[dataFormat]&limit=[limit]&accessKey=[accessKey]&secretKey=[secretKey]/';

        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader" style={{marginBottom: "0", paddingBottom: "0"}}>Request</p>
                    <hr/>
                    <p className="docsHeader">Make a <b>GET</b> Request to the following URL</p>
                    <DocsBaseURL url={baseGetURL} />
                    <i>Example URL</i>
                    <DocsBaseURL url="data/aBcDe123xy0z/?query=select * from aBcDe123xy0z where col_name='abc'&dataFormat=matrix&limit=10&accessKey=12345678901234567890123456789012&secretKey=12345678901234567890123456789012/" />
                    <DocsGetURLParameters setBothMenuItems={this.props.setBothMenuItems}/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader" style={{marginBottom: "0", paddingBottom: "0"}}>Response</p>
                    <hr/>
                    <p>The response contains your <code>data</code> depending on your <code>dataFormat</code>. Remember, the default dataFormat is <b>row</b></p>
                    <div className="row">
                        <div className="col-sm-6">
                            <DocsGetResponse/>
                        </div>
                        <div className="col-sm-6">
                            <div style={{fontSize: '115%'}}>
                                <DocsGetResponseCode/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReadIndex