import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleToast} from "../Actions/ToggleToast";
import { Icon, Popup } from 'semantic-ui-react';

class FileIDCopy extends Component {
    constructor(props) {
        super(props);
    }

    copyID = (id) => {
        navigator.clipboard.writeText(id)
        this.props.toggleToast({show: true, message: "File ID Copied", type: "success"})
    }

    render() {
        let useFileHash = this.props.fileInformation["useFileHash"]
        let fileHash = this.props.fileInformation["fileHash"]

        let displayFileHash = useFileHash ? fileHash : this.props.previewFileHash ? fileHash : this.props.pk.toString();

        return (
            <span>
                <span style={{color:'#5E645E'}}> File ID </span>
                <b>{displayFileHash}</b>
                <Icon name="copy outline"
                      className="fileIDLabelIcon"
                      color="green"
                      style={{cursor: "pointer", marginLeft: "5px"}}
                      onClick={() => this.copyID(displayFileHash)}
                />
            </span>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(FileIDCopy)