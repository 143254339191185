import React, {Component} from 'react'
import {connect} from 'react-redux'
import DocsImage from "../DocsImage";
import SilentFail from "../Importer/SilentFail";

class Dropbox extends Component {
    render() {
        const isImporter = 'importer' in this.props;
        const imgStyle = {border:"1px solid #F0F0F0", display: "block", marginBottom: "16px"}

        return (
            <div>
                {
                    isImporter ? <div className="docsSection">
                        <p className="docsHeader">Connect Dropbox Accounts</p>
                        <p>1. <b>Sign In</b> to your Dropbox account</p>
                        <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-Dropbox/SignInWithYourAccount2.png"
                             style={{width: "50%", ...imgStyle}}
                             alt="sign in to dropbox account"
                        />
                        <p>2. <b>Accept</b> the API Spreadsheets permissions</p>
                        <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-Dropbox/AcceptAPIConnection3.png"
                             style={{width: "75%", ...imgStyle}}
                             alt="accept dropbox permissions"
                        />
                    </div>
                    : null
                }
                <div className="docsSection">
                    <p className="docsHeader">View your Dropbox Accounts</p>
                    <p>You can view your connected Dropbox accounts from your Profile</p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Integrations/Profile-DropboxAccounts.png"
                        alt="Dropbox accounts"
                        width="100%"
                    />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Remove your Dropbox Account from API Spreadsheets</p>
                    <p>To remove our connection from your Dropbox Account, click on that account's <b>Remove Account from API Spreadsheets</b> button </p>
                    <p>This will show you a series of prompts to follow, and once you are done API Spreadsheets will not be able to connect to that Dropbox Account. All APIs from that Dropbox Account will stop working</p>
                    <p>Once again, taking the steps above will promptly cut off API Spreadsheets' access to your Dropbox Account</p>
                    <p>But if you open your Dropbox Account, you will see that API Spreadsheets still has permissions to your Dropbox Account. Don't worry, the connection is fully removed from our end</p>
                    <p>You can go ahead and remove API Spreadsheets from your Dropbox Account</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Remove API Spreadsheets from your Dropbox Account</p>
                    <p>1. Go to your Dropbox account</p>
                    <p>2. Click on the Settings page of your account</p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Integrations/Profile-DropboxAccountsSettings.png"
                        alt="Dropbox accounts"
                        width="100%"
                    />
                    <p>3. Click on <b>Connect Apps</b>. Find API Spreadsheets and click disconnect</p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Integrations/Profile-DropboxAccountsConnectedAppsDisconnect.png"
                        alt="Dropbox accounts"
                        width="100%"
                    />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Not being able to connect to Dropbox</p>
                    <p>Dropbox accounts can encounter {isImporter ? "1" : "2"} problem{isImporter ? "" : "s"}</p>
                    <ol>
                        <li>You are trying to connect more than 1 Dropbox account on API Spreadsheets</li>
                        {isImporter ? null : <li>You connected your account before July 2022 and now can't access the API</li>}
                    </ol>
                    <p>The {isImporter ? null : "first"} problem is imposed by Dropbox. There is currently no way for us to connect to multiple Dropbox accounts at the same time</p>
                    {isImporter ? null : <p>The second problem was caused by Dropbox updating their integration process. If you run into this problem, you can simply remove your Dropbox account following the steps above, and then reupload <i>any</i> file from that account to get things working again</p>}
                </div>
                <div className="docsSection">
                    {
                        isImporter ? <div>
                            <p className="docsHeader">Imported file failed to save to Dropbox Account</p>
                            <p>If your connection was not working when the file was imported, then it will not be saved to your Dropbox Account</p>
                            <SilentFail destination="Dropbox"/>
                        </div> : <div>
                            <p className="docsHeader">Your File Deleted on Dropbox</p>
                            <p>If you delete your File from Dropbox and NOT from API Spreadsheets, you will get an error when you try to do anything with that file</p>
                            <p>Your API URL will be valid but since your file does not exist in Dropbox, you will receive a <code>500</code> or <code>502</code> error code when making any API requests</p>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(Dropbox)