import React, {Component} from 'react'
import ModalApp from '../SharedComponents/Modal'
import ImportSheetsBody from './ImportSheetsBody'
import {connect} from 'react-redux';
import { Icon } from 'semantic-ui-react'

class ImportSheetsModal extends Component {
    render() {
        let filePK;

        // Get the PK of the file
        for (let i=0; i<this.props.filePK.length; i++){
            const filePKObj = this.props.filePK[i];
            const fileID = filePKObj['id'];

            if (fileID === this.props.editID){
                filePK = filePKObj['pk'];
                break
            }
        }

        return (
            <ModalApp name="importSheets"
                      header={null}
                      body={<ImportSheetsBody pk={filePK}/>}
                      size="small"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    filePK: state.mainState.filePK
})

export default connect(mapStateToProps,)(ImportSheetsModal)