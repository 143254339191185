import React, {Component} from 'react'
import ReactJson from 'react-json-view'
import {generateRandomKey} from "../../../../Constants/generateRandomKey";

class PostHeaderReq extends Component {
    render() {
        const boxClassName = "getResponseBox";
        return (
            <div className={boxClassName}>
                <div className="getResponseHeading">
                    <span style={{float: 'left'}}><h6 className="thinHeading">Request Header</h6></span>
                </div>
                <ReactJson src={{accessKey: generateRandomKey(32), secretKey: generateRandomKey(32)}} name={null} theme="ocean" displayObjectSize={false} displayDataTypes={false}/>
            </div>
        )
    }
}

export default PostHeaderReq