import React, {Component} from 'react';
import withAuthorization from '../../Authentication/withAuthorization';
import ProfileIndex from "./ProfileIndex";

class ProfileAuthIndex extends Component {
    render() {
        return(
            <ProfileIndex />
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(ProfileAuthIndex);
