import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'
import SpreadsheetImportWebhook from "./SpreadsheetImportWebhook";
import DocsImage from "../../App/Docs/DocsImage"
import Gist from "react-gist";

class SpreadsheetImport extends Component {
    render() {
        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">What is a Spreadsheet Importer?</p>
                    <p>It is a button or a website that lets users upload spreadsheets easily to you</p>
                    <p>For example, <a href="https://apispreadsheets.com/import/upload/7ac29ce56fa18e550261aa590be13280" target="_blank" rel="noopener noreferrer">try out this live importer</a></p>
                    <p>You can create something like this and when your users upload the spreadsheets, they can be saved in your Google Drive, Dropbox, AWS S3 etc</p>
                    <p>In this guide, we will walk through how to create a button on your website that will let users upload Spreadsheets or CSV files</p>
                    <p>You will then be able to view these files on your Google Drive & Dropbox accounts</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Create an Importer on Your API Spreadsheets Account</p>
                    <p><b>1. Log in to your API Spreadsheets Account and Click Import</b></p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/ImportSpreadsheetsOnYourApps/NavBar.png" style={{"width": "100%"}} alt="go to import tab on API Spreadsheets" />
                    <br/><br/><br/><br/>
                    <p><b>2. Click on the Create an Importer</b></p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/ImportSpreadsheetsOnYourApps/CreateImport.png" style={{"width": "40%"}} alt="Click on Create Your Own Import Configuration" />
                    <br/>
                    <br/>
                    <p><b>3. Enter a unique name for your importer and continue</b></p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Importer-Name.png" style={{ border: '1px solid lightgrey'}} alt="Click on Create Your Own Import Configuration" />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Customize Various Settings and Features for your Importer</p>
                    <br/>
                    <p><i>If you don't customize any of these settings, no worries!. All files uploaded can always be accessed through your API Spreadsheets dashboard</i></p>
                    <p><b>1. Create a list of Columns</b></p>
                    <p>You can create a list of Columns (data model) for your importer </p>
                    <p>The column names that you specify under <b>Internal Name</b> will be matched with the column names from the uploaded spreadsheets</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/ImportSpreadsheetsOnYourApps/CreateListColumns.png" style={{"width": "100%"}} alt="Create a list of Columns" />
                    <br/><br/>
                    <p style={{"marginBottom": "0"}}>You can also specify whether these columns should be required to match or not. And if they should be converted to a particular data type</p>
                    <small><i>NOTE: if the conversion to a data type fails, like for example, converting "ABC" to numerical, this will result in a NULL value in the data </i></small>
                    <br/><br/><br/><br/>
                    <p><b>2. Select destinations for the uploaded files</b></p>
                    <p style={{"marginBottom": "0"}}>Connect and choose any external accounts you want the uploaded files to be saved to</p>
                    <small><i>NOTE: no matter what, all files can be accessed through your API Spreadsheets dashboard</i></small>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/ImportSpreadsheetsOnYourApps/DestinationsTab.png" style={{width: '100%', border: '1px solid lightgrey'}} alt="Select destinations for the uploaded files" />
                    <br/><br/><br/><br/>
                    <p><b>3. Customize how the importer should look and behave</b></p>
                    <p>You can select things like color of the importer square, messages to show up when upload is successful, the file extensions allowed to be uploaded, etc.</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/ImportSpreadsheetsOnYourApps/ApperanceTab.png" style={{width: '100%', border: '1px solid lightgrey'}} alt="Customize how the importer should look and behave" />
                    <br/><br/><br/><br/>
                    <p><b>4. Set up any actions to be taken after file upload</b></p>
                    <p>You can set up things like a Webhook, or Email Alerts. These will take place after the file has been uploaded</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/ImportSpreadsheetsOnYourApps/ActionsTab.png" style={{width: '100%', border: '1px solid lightgrey'}} alt="Set up any actions to be taken after file upload" />
                    <br/><br/><br/><br/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Insert Pre-Written Code on your Website</p>
                    <br/>
                    <p><b>1. Copy pre-written code from the importer page</b></p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/ImportSpreadsheetsOnYourApps/ImporterCode.png" style={{width: '100%', border: '1px solid lightgrey'}} alt="Copy pre-written code from the importer page" />
                    <br/><br/><br/><br/>
                    <p><b>2. Paste code on your website</b></p>
                    <p>The first three lines should go in your <code>&lt;head></code> tags. The <code>&lt;button></code> should go where you want the user to click to upload the files</p>
                    <Gist id='d0ee0c33e92d6695b9c9ddac39316a26'/>
                    <br/>
                    <p><b>3. Test Import Button</b></p>
                    <p style={{"marginBottom": "0"}}>Click on the button and test it out. Clicking on the button will open a modal that will walk the user through the uploading process</p>
                    <small><i>NOTE: The importer does not have to be a button. It can be anything as long as it invokes the function <code>importer.importFiles();</code> </i></small>
                    <br/><br/>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/ImportSpreadsheetsOnYourApps/TestCode.png" style={{width: '100%', border: '1px solid lightgrey'}} alt="Test Import Button" />
                    <br/><br/>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/MapColumnss.png" style={{width: '100%', border: '1px solid lightgrey'}} alt="Test Import Map Columns Button" />
                    <br/><br/><br/><br/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">View Uploaded Spreadsheets</p>
                    <br/>
                    <p><b>1. Go to your API Spreadsheets Files Dashboard</b></p>
                    <p>You can use the uploaded file as an API and do many other things with it </p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/ImportSpreadsheetsOnYourApps/FilesDashboard.png" style={{width: '100%', border: '1px solid lightgrey'}} alt="Imported File on Dashboard" />
                    <br/><br/><br/><br/>
                    <p><b>2. Go to your external accounts that you set up in destinations</b></p>
                    <p>The uploaded file will be saved here</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Actions-WebhooksDataToggle.png" style={{ border: '1px solid lightgrey'}} alt="Imported File on Dashboard" />
                </div>
            </div>
        )
    }
}

export default SpreadsheetImport