import React, {Component} from 'react'
import {isMobile} from "react-device-detect";
import {getPricingText} from "../../Constants/getPricingText";
import {connect} from "react-redux";
import { Icon } from 'semantic-ui-react'
import StartPlanButton from "./StartPlanButton"
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import AddCommaToNumbers from "../../Constants/AddCommaToNumbers";

class EnterprisePricingBox extends Component {
    render() {
        const featuresText = {fontSize: "14px", color: "white", marginBottom: "4px"}
        return (
            <div style={{ backgroundColor: "#161E16", borderRadius: "10px", padding: isMobile ? "24px" : "50px"}}>
                <div className="row">
                    <div className="col-sm-3">
                        <div style={{fontWeight: "700", color: "white", fontSize: "20px", marginBottom: "8px"}}>ENTERPRISE</div>
                        <div style={{marginBottom: "8px"}}>
                            <span style={{fontWeight: "700", color: "white", fontSize: "32px"}}>{AddCommaToNumbers(getPricingText(this.props.currency, 4))}</span>
                            <span style={{color: "#9696A0", fontWeight: "400", fontSize: "14px"}}>/month</span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="row" style={{marginBottom: "8px"}}>
                            <div className="col-sm-12" style={{color: "white"}}>
                                Everything in the <b>BUSINESS</b> plan plus...
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-7">
                                <div style={featuresText}><Icon name="th" /><b>Unlimited</b> Rows per File</div>
                                <div style={featuresText}><Icon name="file alternate outline" /><b>Unlimited</b> Files</div>
                                <div style={featuresText}><Icon name="code" /><b>2.1 million</b> Requests per Month</div>
                            </div>
                            <div className="col-sm-5">
                                <div style={featuresText}><Icon name="group" /><b>Unlimited</b> Teams</div>
                                <div style={featuresText}><Icon name="server" /><b>Private</b> Server</div>
                                <div style={featuresText}><Icon name="user circle outline" /><b>Personal</b> Consultant</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div style={{textAlign: isMobile ? "left": "right", paddingTop: "24px"}}>
                            <StartPlanButton
                                url="mailto:sales@apispreadsheets.com?subject=Enterprise Plan"
                                backgroundColor={gunaldiStyles.orange}
                                color="white"
                                text="Contact Us"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency,
    yearlyPricing: state.mainState.yearlyPricing
})

export default connect(mapStateToProps, null)(EnterprisePricingBox)