import React, {Component} from 'react'
import {connect} from 'react-redux'
import RoleTag from "../Roles/RoleTag";
import { Label, Icon, Popup, Button } from 'semantic-ui-react';
import API_Root from "../../Constants/API_Root";
import {convertServerDateToDisplayDate} from "../../Constants/convertServerDateToDisplayDate";
import {modifyTeamInfo} from "../../Actions/ModifyTeamInfo";

const getInviteURL = (teamHash, email) => {
    let baseURL = "https://www.apispreadsheets.com"
    if (window.location.href.includes("localhost")){
        baseURL = "http://localhost:5000"
    }

    return baseURL + "/signup/?team=" + teamHash.toString() + "&email=" + email
}

class InvitesSentRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCanceling: false
        }

        this.copyInviteURL = this.copyInviteURL.bind(this);
        this.cancelInvite = this.cancelInvite.bind(this);
        this.updateNewTeamInfo = this.updateNewTeamInfo.bind(this);
        this.getInfo = this.getInfo.bind(this);
    }

    getInfo(){
        return this.props.teamInfo[this.props.teamIdx]
    }

    copyInviteURL(e){
        e.target.focus();
        this.inviteURL.select();
        document.execCommand("copy")
    }

    updateNewTeamInfo(newTeamInfo){
        const currTeamInfo = JSON.parse(JSON.stringify(this.props.teamInfo))
        currTeamInfo[this.props.teamIdx] = newTeamInfo
        this.props.modifyTeamInfo(currTeamInfo)
    }

    cancelInvite(e){
        this.setState({ isCanceling: true })

        const hitError = () => {
            this.setState({ isCanceling: false });
            alert("There was an error canceling the invite. Contact our support if this persists!")
        }

        fetch(API_Root + "api/remove-add-or-edit-api-team-member/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                inviteEmail: this.props.email,
                teamPK: this.props.teamInfo[this.props.teamIdx].id,
                role: this.props.role,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                adminAction: true,
                operation: "decline"
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.setState({isCanceling: false}, () => this.updateNewTeamInfo(data.teamInfo))
                }).catch(err => hitError())
            } else {
                hitError()
            }
        }).catch(err => hitError())

    }

    render() {
        const sentOnDisplayDate = convertServerDateToDisplayDate(this.props.sentOn);
        const info = this.getInfo();

        return (
            <div style={{borderBottom: '1px solid lightgrey', padding: "10px 0 10px 0"}}>
                <div className="invitesSentRow">
                    <div style={{paddingTop: "2px"}}>
                        {this.props.email}
                    </div>
                    <div>
                        <RoleTag userRole={this.props.role}/>
                    </div>
                    <div style={{paddingTop: "2px"}}>
                        {sentOnDisplayDate}
                    </div>
                    <div style={{textAlign: "right"}}>
                        <Popup content="Cancel Invite"
                               trigger={<Button icon="user cancel"
                                                loading={this.state.isCanceling}
                                                circular
                                                color="red"
                                                basic
                                                onClick={this.cancelInvite}/>}
                        />
                    </div>
                </div>
                <div>
                    <small style={{display: 'block'}}>
                        <b>Invite URL</b>
                        <input readOnly={true} style={{width: '350px', border: 'none'}} value={getInviteURL(info['team_hash'], this.props.email)} type="text"
                               ref={(input) => {
                                   this.inviteURL = input
                               }}
                        />
                        <Popup content="Copy Invite URL"
                               trigger={<Icon name="copy" onClick={this.copyInviteURL} style={{cursor: 'pointer', color: 'green'}}/>}/>
                    </small>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    teamInfo: state.mainState.teamInfo
})

const mapActionsToProps = {
    modifyTeamInfo: modifyTeamInfo
}

export default connect(mapStateToProps, mapActionsToProps)(InvitesSentRow)