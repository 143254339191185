import React, {Component} from 'react'
import {connect} from 'react-redux'
import GoogleSheetsVisual from "./GoogleSheetsVisual";
import API_Root from "../Constants/API_Root";
import {modifyIntegrationAuth} from "../Actions/ModifyIntegrationAuth";
import loadScript from 'load-script';
import {toggleModal} from "../Actions/ToggleModal";
import copyArray from "../Constants/copyArray";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import {modifyFilePKProBlock} from "../Actions/ModifyFilePKProBlock";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import {modifyStatus} from "../Actions/ModifyStatus";
import { push } from 'react-router-redux'
import { toggleToast } from "../Actions/ToggleToast";
import {initUploadedPK} from "../Actions/InitUploadedPK";
import {modifyUpgradeText} from "../Actions/ModifyUpgradeText";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import GoogleSheetsPermissionsModal from "./GoogleSheetsPermissionsModal";
import {readFiles} from "../Constants/readFile";
import { Button, Icon } from 'semantic-ui-react'
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import {modifyAPIMenuItem} from "../Actions/ModifyAPIMenuItem";

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

let scriptLoadingStarted = false;

class GoogleSheetsConnect extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onApiLoad = this.onApiLoad.bind(this);
        this.onChoose = this.onChoose.bind(this);

        this.storeSheetsInfo = this.storeSheetsInfo.bind(this);

        this.state = {
            clientId: '340136574451-49jb4g9qahbn3l2bsd40s2og3nuiodi1.apps.googleusercontent.com',
            developerKey:'AIzaSyB_mctRTSmKsVCAQRx1bKYtKWm1f8APl-Q',
            // scope:['https://www.googleapis.com/auth/drive'],
            scope:['https://www.googleapis.com/auth/drive.file', 'https://www.googleapis.com/auth/spreadsheets'],
            onChange: data => this.onChange(data),
            onAuthFailed: data => console.log('on auth failed:', data),
            multiselect: true,
            navHidden: false,
            authImmediate: false,
            mimeTypes: ['application/vnd.google-apps.spreadsheet'],
            query: '',
            viewId:'DOCS',
            origin: API_Root.includes("api") ? 'https://apispreadsheets.com' : 'http://localhost:5000',
            email: ''
        }

    }

    isGoogleReady() {
        return !!window.gapi;
    }

    componentWillMount() {
        if(this.isGoogleReady()) {
            // google api is already exists
            // init immediately
            this.onApiLoad();
        } else if (!scriptLoadingStarted) {
            // load google api and the init
            scriptLoadingStarted = true;
            loadScript(GOOGLE_SDK_URL, this.onApiLoad)
        } else {
            // is loading
        }
    }

    isGoogleAuthReady() {
        return !!window.gapi.auth;
    }

    isGooglePickerReady() {
        return !!window.google.picker;
    }

    onApiLoad() {
        window.gapi.load('auth');
        window.gapi.load('picker');
    }

    onChoose(){
        if (this.props.showingTutorial){
            this.props.toggleModal("googleSheetsPermissionsModal")
        } else {
            this.choose()
        }
    }

    choose = () => {
        this.props.toggleModal(null)

        if (!this.isGoogleReady() || !this.isGoogleAuthReady() || !this.isGooglePickerReady() || this.state.disabled) {
            return null;
        }

        const token = window.gapi.auth.getToken();
        const oauthToken = token && token.access_token;

        if (oauthToken) {
            if (this.state.email.trim() === ""){
                fetch("https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=" + token.access_token)
                    .then((res) => res.json())
                        .then((data) => {
                            this.setState({
                                email: data.email
                            }, () => this.createPicker(token.access_token))
                        })
                    .catch(err => console.log(err)).catch(err => console.log(err))
            } else {
                this.createPicker(token.access_token);
            }
        } else {
            const scope = this.state.scope;
            const syncError = (errorMessage="There was an error syncing your file! Read and try some of our tips!") => {
                // this.props.toggleToast({show: true, message: errorMessage, type: "error"});
                this.props.toggleModal(null)
                this.props.toggleModal("googleSheetsErrorModal");
            }

            window.gapi.auth2.authorize({client_id: this.state.clientId, scope: scope.join(" ") + " profile email", response_type:"code permission"},
               (authresult) => {
                   if (authresult.code){
                        const code = authresult.code;
                        fetch(API_Root + "api/api-store-google-sheets-auth/",
                           {
                               method: 'POST',
                               headers: {
                                   Accept: 'application/json, text/plain, */*',
                               },
                               body: JSON.stringify({
                                   email: localStorage.getItem("email"),
                                   token: localStorage.getItem("token"),
                                   code: code
                               })
                           }).then((res) =>
                               {
                                   if (res.status === 200){
                                       res.json().then((data) => {
                                            this.setState({ email: data.email }, () => {
                                                const currIntegration = JSON.parse(JSON.stringify(this.props.integrationAuth));

                                                currIntegration.googleSheets = true;

                                                this.props.modifyIntegrationAuth(currIntegration);
                                                // try{
                                                this.createPicker(authresult.access_token)
                                                // } catch (error) {
                                                //     syncError()
                                                // }

                                            })
                                        }).catch(err => syncError())
                                   }
                                   else if (res.status === 406) {
                                       res.json().then((data) => {
                                           this.props.modifyModalInfo({gmailError: true, gmailEmail: data.email});
                                           this.props.toggleModal("googleSheetsTokenErrorModal");
                                       }).catch(err => {
                                           this.props.modifyModalInfo({gmailError: true, gmailEmail: ""});
                                           this.props.toggleModal("googleSheetsTokenErrorModal");
                                       })
                                   }
                                   else{
                                       syncError()
                                       // this.props.navigateTo("/");
                                       // this.props.toggleToast({show: true, message: "Click on the Google Sheets Box and Try Again!", type: "warn"});
                                   }
                               }).catch(err => syncError())
                           }
                           else{
                                let error = true;

                                if ('error' in authresult){
                                    if (authresult.error === 'popup_closed_by_user'){
                                        error = false
                                    }
                                }

                                if (error){
                                    syncError()
                                    // this.props.navigateTo("/");
                                    // this.props.toggleToast({show: true, message: "Click on the Google Sheets Box and Try Again!", type: "warn"});
                                }
                           }
                        })

        }
    }

    createPicker(accessToken) {
        // if(this.state.createPicker){
        //     return this.state.createPicker(window.google, accessToken)
        // }

        // const googleViewId = window.google.picker.ViewId[this.state.viewId];
        // const view = new window.google.picker.View(googleViewId);

        const view = new window.google.picker.DocsView();

        if (this.state.mimeTypes) {
            view.setMimeTypes(this.state.mimeTypes.join(','))
        }
        if (this.state.query) {
            view.setQuery(this.state.query)
        }

        if (!view) {
            throw new Error('Can\'t find view by viewId');
        }

        // if (this.state.origin) {
        //     picker.setOrigin(this.state.origin);
        // }

        const picker = new window.google.picker.PickerBuilder()
            .addView(view)
            .setOAuthToken(accessToken)
            .setDeveloperKey(this.state.developerKey)
            .setCallback(this.state.onChange);

        if (this.state.navHidden) {
            picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN)
        }

        if (this.state.multiselect) {
            picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
        }


        // try {
        picker.build().setVisible(true);
        // } catch (error) {
        //     this.props.toggleModal(null)
        //     this.props.toggleModal("googleSheetsErrorModal");
        // }
    }

    onChange(data){
        const syncError = (errorMessage="There was an error syncing your file! Read and try some of our tips!") => {
            this.props.toggleModal(null)
            this.props.toggleToast({show: true, message: errorMessage, type: "error"});
            this.props.toggleModal("googleSheetsErrorModal");
        }

        if (data.action === "picked"){
            if (data.docs){
                if (data.docs.length > 0){
                    this.props.toggleModal("loadingModalGoogleSheets");
                    fetch(API_Root + 'api/api-save-sheets-dropbox/', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                        },
                        body: JSON.stringify({
                            documentType: "googleSheets",
                            documents: data.docs,
                            googleEmail: this.state.email,
                            email: localStorage.getItem("email")
                        })
                    }).then(res => res.json())
                        .then((data) => {
                            if (!data.error){
                                // Modify the File Primary Keys
                                let filePK = JSON.parse(JSON.stringify(data.filePK));
                                let uploadedPK = JSON.parse(JSON.stringify(data.filePK));

                                if (this.props.filePK.length > 0){
                                    filePK = copyArray(this.props.filePK, data.filePK);
                                }

                                if (this.props.uploadedPK.length > 0){
                                    uploadedPK = data.filePK.concat(this.props.uploadedPK)
                                }

                                this.props.initUploadedPK(uploadedPK)
                                this.props.modifyFilePK(filePK);
                                // Open up WebSocket when files are saved
                                this.storeSheetsInfo(data.filePK);

                            }
                            else{
                                if ('message' in data){
                                    this.props.toggleModal(null)
                                    this.props.modifyUpgradeText(data.message)
                                    this.props.toggleModal("upgradeModal")
                                }
                                else{
                                    syncError()
                                }
                            }

                        }).catch((err) => { syncError() })
                        .catch(err => syncError()).catch(err => syncError())
                }
            }
            // else{
            //     syncError()
            // }
        }
        // else{
        //     syncError()
        // }
    }

    storeSheetsInfo(filePK){
        const socketRequest = {
            utcOffset: new Date().getTimezoneOffset(),
            filePK: filePK,
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
            sheetRead: this.props.sheetRead
        }

        const route = "read-api-sheets-dropbox"

        readFiles(socketRequest, filePK, this.props, route, true, true)
    }

    render() {
        const textStyle = {fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#5E645E"}

        return (
            <div>
                {
                    this.props.showingTutorial ? <div>
                        <div style={{ marginBottom: "16px", textAlign: "center"}}>
                            <div style={textStyle}>After you click <b style={{color: "black"}}>PROCEED</b>, you will be prompted to Sign In to your Google Account</div>
                            <div style={textStyle}>Make sure to give <b style={{color: "black"}}><u>ALL</u></b> requested Permissions (as seen in the pic below) to API Spreadsheets</div>
                        </div>
                        <div>
                            <img
                                style={{border: "1px solid lightgrey", display: "block", marginLeft: "auto", marginRight: "auto", width: "50%"}}
                                src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/GoogleSheetsPermissionCheck.png"
                                alt="GoogleSheetsPermissions"/>
                        </div>
                        <div style={{textAlign: "center", marginTop: "16px"}}>
                            <Button color="green" style={gunaldiStyles.btnGreen} onClick={this.choose}>
                                PROCEED <Icon name="arrow right" />
                            </Button>
                        </div>
                    </div> :
                        <div style={{textAlign: "center"}} className="uploadOuterSection">
                            <div style={{paddingTop: "25px", textAlign: "center"}}>
                                <i className="bx bxl-google" style={{color: "#D1E4CE", fontSize: "80px"}} />
                                <br/><br/>
                                <span onClick={this.choose} className="uploadLabel" style={{color: "#FFFFFF", backgroundColor: gunaldiStyles.mainGreen}}>
                                    Select Your Google Sheets
                                </span>
                            </div>
                        </div>
                }
                {/*<GoogleSheetsPermissionsModal startSelecting={this.choose}/>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    integrationAuth: state.mainState.integrationAuth,
    filePK: state.mainState.filePK,
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock,
    fileInformation: state.mainState.fileInformation,
    uploadedPK: state.mainState.uploadedPK,
    showingTutorial: state.mainState.showingTutorial,
    tutorialStep: state.mainState.tutorialStep
})

const mapActionsToProps = {
    modifyIntegrationAuth: modifyIntegrationAuth,
    toggleModal: toggleModal,
    modifyFilePK: modifyFilePK,
    modifyFilePKProBlock: modifyFilePKProBlock,
    modifyFileInformation: modifyFileInformation,
    modifySheetRead: modifySheetRead,
    modifyStatus: modifyStatus,
    navigateTo: push,
    toggleToast: toggleToast,
    initUploadedPK: initUploadedPK,
    modifyUpgradeText: modifyUpgradeText,
    modifyModalInfo: modifyModalInfo,
    modifyAPIMenuItem: modifyAPIMenuItem
}

export default connect(mapStateToProps, mapActionsToProps)(GoogleSheetsConnect)