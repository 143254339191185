import React, {Component} from 'react'
import ProfileDisplayHeader from "./ProfileDisplayHeader";
import {Segment} from "semantic-ui-react";
import DownloadReceiptsAccordion from "./DownloadReceiptsAccordion";
import CancelAccount from "./CancelAccount";
import PopupPlanBody from "../../SharedComponents/Payment/PopupPlanBody";
import UpgradeButton from "./UpgradeButton";
import AppSumoWelcomeBanner from "./AppSumoWelcomeBanner";
import AppsumoPopupPlanBody from "./AppsumoPopupPlanBody";

const plans = {
    "free": 0,
    "pro": 1,
    "team": 2,
    "business": 3,
    "enterprise": 4,
    "": -1
}

class AppsumoPricingPlan extends Component {
    render() {

        return (
            <div>
                {window.location.href.includes("profile?deal=appsumo")? <AppSumoWelcomeBanner/>:null }
                <div className="row">
                    <div className="col-sm-1" />
                    <div className="col-sm-7">
                        <ProfileDisplayHeader name={this.props.name} icon={this.props.icon}/>
                        <br/>
                        <Segment>
                            <div className="row">
                                <div className="col-sm-4">
                                    <h5 className="thinHeading text-muted">Account Type</h5>
                                </div>
                                <div className="col-sm-4">
                                    <h4>BUSINESS</h4>
                                    {/*{this.props.appsumoPlanName==='apispreadsheets_tier1'? <h4> AppSumo Lifetime Tier 1</h4>:null}*/}
                                    {/*{this.props.appsumoPlanName==='apispreadsheets_tier2'? <h4> AppSumo Lifetime Tier 2</h4>:null}*/}
                                    {/*{this.props.appsumoPlanName==='apispreadsheets_tier3'? <h4> AppSumo Lifetime Tier 3</h4>:null}*/}
                                    {/*{this.props.appsumoPlanName==='apispreadsheets_tier4'? <h4> AppSumo Lifetime Tier 4</h4>:null}*/}
                                </div>
                                <div className="col-sm-4"/>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <br/>
                                    <h5 className="thinHeading text-muted" style={{color: 'red'}}>Questions?</h5>
                                    <h5 className="thinHeading">
                                        Contact us through our support if you have any questions about your account
                                    </h5>
                                </div>
                            </div>

                        </Segment>
                    </div>
                    <div className="col-sm-3">
                        {
                            this.props.appsumoPlanName !== "" ?
                                <div style={{border: "1px solid lightgrey", borderRadius: "4px", padding: "10px", marginTop: "-10px", boxShadow: "9px 7px 21px -10px rgba(0,0,0,0.75)"}}>
                                    {
                                        plans[this.props.pricingPlan] !== 4 ?
                                            <AppsumoPopupPlanBody appsumoPlanName={this.props.appsumoPlanName}/> :
                                            <p>This is a custom account. Please contact us at info@apispreadsheets.com if you want your plan details</p>
                                    }
                                </div> :
                                null
                        }
                    </div>
                    <div className="col-sm-1" />
                </div>

            </div>
        )
    }
}

export default AppsumoPricingPlan
