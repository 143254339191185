import React, {Component} from 'react'
import { Menu } from 'semantic-ui-react'
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import ImportSSLCertificate from "./ImportSSLCertificate"
import RequestSSLCertificate from "./RequestSSLCertificate"
import YourSSLCertificates from "./YourSSLCertificates"
import CNAMEDomainError from "../CNAMEDomainError"

class SSLIndex extends Component {
    handleItemClick = (e, { name }) => this.props.changeState({ sslMenuItem: name })

    getDisplay = () => {
        return {
            importCertificate: <ImportSSLCertificate
                fileCertificate={this.props.fileCertificate}
                fileKey={this.props.fileKey}
                fileCertificateChain={this.props.fileCertificateChain}
                changeState={this.props.changeState}
                importSSLMsg={this.props.importSSLMsg}
                importSSLError={this.props.importSSLError}
                currSSLInfo={this.props.currSSLInfo}
            />,
            requestCertificate: <RequestSSLCertificate
                domainName={this.props.domainName}
                domainNameError={this.props.domainNameError}
                domainNameErrorMsg={this.props.domainNameErrorMsg}
                domainValidationOption={this.props.domainValidationOption}
                changeState={this.props.changeState}
                requestSSLMsg={this.props.requestSSLMsg}
                requestSSLError={this.props.requestSSLError}
                currSSLInfo={this.props.currSSLInfo}
            />,
            yourCertificates: <YourSSLCertificates
                sslSelected={this.props.sslSelected}
                domainNameError={this.props.domainNameError}
                domainName={this.props.domainName}
                changeState={this.props.changeState}
                currSSLInfo={this.props.currSSLInfo} />
        }[this.props.menuItem]
    }

    getHeaderAndDesc = () => {
        return {
            importCertificate: {header: "Import SSL Certificate", desc: "Use this option if you already have an SSL certificate for your domain"},
            requestCertificate: {header: "Request SSL Certificate", desc: "Use this option if you want us to request and manage SSL for your domain"},
            yourCertificates: {header: "Your SSL Certificates", desc: <div>
                    Link SSL Certificate to domain, or manage your SSL certificates
                    <CNAMEDomainError
                        error={this.props.domainNameError}
                        errorMsg={this.props.domainNameErrorMsg}
                        errorOnly
                        selectSSL
                    />
                </div>}
        }[this.props.menuItem]
    }

    render() {
        const headerAndDesc = this.getHeaderAndDesc()

        return (
            <div>
                <Menu pointing secondary>
                    {
                        this.props.currSSLInfo.length > 0 ?
                            <Menu.Item
                                name='yourCertificates'
                                active={this.props.menuItem === 'yourCertificates'}
                                onClick={this.handleItemClick}
                                icon="certificate"
                            /> : null
                    }
                    <Menu.Item
                        name='requestCertificate'
                        active={this.props.menuItem === 'requestCertificate'}
                        onClick={this.handleItemClick}
                        icon="pencil square"
                    />
                    <Menu.Item
                        name='importCertificate'
                        active={this.props.menuItem === 'importCertificate'}
                        onClick={this.handleItemClick}
                        icon="upload"
                    />
                </Menu>
                <div>
                    <div style={{marginBottom: "16px"}}>
                        <div style={{fontFamily: "Lato", fontSize: '14px', color: gunaldiStyles.mainText}}>
                            {headerAndDesc.desc}
                        </div>
                        {/*<div style={{textAlign: "right"}}>*/}
                            {/*<small>We use AWS to manage SSL. <a href="https://docs.aws.amazon.com/acm/latest/userguide/import-certificate.html" target="_blank" rel="noopener noreferrer">Read more details on certificate format</a></small>*/}
                        {/*</div>*/}
                    </div>
                    {this.getDisplay()}
                </div>
            </div>
        )
    }
}

export default SSLIndex