import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Checkbox, Input, Icon} from "semantic-ui-react";
import {modifyCustomImportSettings} from "../../../../../Actions/ModifyCustomImportSettings";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import FindReplaceInputs from "./FindReplaceInputs";
import DatetimeCleaningFunctions from "./DatetimeCleaningFunctions";
import API_Root from "../../../../../Constants/API_Root";
import InfoPopup from "../../../../../SharedComponents/InfoPopup";
import TestingCleanFunctions from "../TestingCleanFunctions";
import {gunaldiStyles} from "../../../../../SharedComponents/GunaldiDesignStyles";

class TextCleaningFunctions extends Component {
    constructor(props) {
        super(props);
    }

    getColumnInfo = () => {
        return this.props.customImportColumnsAddlInfo[this.props.columnIdx]
    }

    changeChangeCase = () => {
        const allColumnInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))
        const columnInfo = allColumnInfo[this.props.columnIdx]
        const cleaningFunctions = columnInfo['cleaningFunctions'];

        if ("changeCase" in cleaningFunctions){
            delete cleaningFunctions['changeCase']
        } else {
            cleaningFunctions['changeCase'] = "lower"
        }

        columnInfo['cleaningFunctions'] = cleaningFunctions
        allColumnInfo[this.props.columnIdx] = columnInfo

        this.props.modifyCustomImportSettings("columnsAddlInfo", allColumnInfo)
    }

    changeCaseType = (e) => {
        const allColumnInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))
        const columnInfo = allColumnInfo[this.props.columnIdx]
        const cleaningFunctions = columnInfo['cleaningFunctions'];

        if ("changeCase" in cleaningFunctions){
            cleaningFunctions['changeCase'] = e.target.value;
        }

        columnInfo['cleaningFunctions'] = cleaningFunctions
        allColumnInfo[this.props.columnIdx] = columnInfo

        this.props.modifyCustomImportSettings("columnsAddlInfo", allColumnInfo)
    }
    changeTrimWhitespace=()=>{
        const allColumnInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))
        const columnInfo = allColumnInfo[this.props.columnIdx]
        const cleaningFunctions = columnInfo['cleaningFunctions'];

        if ("trimWhitespace" in cleaningFunctions){
            delete cleaningFunctions['trimWhitespace']
        } else {
            cleaningFunctions['trimWhitespace'] = true
        }

        columnInfo['cleaningFunctions'] = cleaningFunctions
        allColumnInfo[this.props.columnIdx] = columnInfo

        this.props.modifyCustomImportSettings("columnsAddlInfo", allColumnInfo)
    }
    changeFindReplace=()=>{
        const allColumnInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))
        const columnInfo = allColumnInfo[this.props.columnIdx]
        const cleaningFunctions = columnInfo['cleaningFunctions'];
        let findReplaceArray = [{find:"",replace:""}]

        if ("findReplace" in cleaningFunctions){
            delete cleaningFunctions['findReplace']
        } else {
            cleaningFunctions['findReplace'] = findReplaceArray
        }

        columnInfo['cleaningFunctions'] = cleaningFunctions
        allColumnInfo[this.props.columnIdx] = columnInfo

        this.props.modifyCustomImportSettings("columnsAddlInfo", allColumnInfo);


    }
    render() {
        let info = this.getColumnInfo()
        let cleaningFunctions = info.cleaningFunctions;
        let labelStyle = {fontSize: "14px", color: gunaldiStyles.mainText, fontWeight: "400"};

        return (
            <div>
                <Checkbox
                    name="trimWhitespace"
                    label={<label style={labelStyle}>Trim Whitespace</label>}
                    onChange={this.changeTrimWhitespace}
                />
                <br/>
                <Checkbox
                    name="changeCase"
                    label={<label style={labelStyle}>Change Case</label>}
                    onChange={this.changeChangeCase}
                    checked={'changeCase' in cleaningFunctions}
                />
                {
                    'changeCase' in cleaningFunctions ?
                        <div style={{paddingLeft:'35px'}}>
                            <div>
                                <input type="radio"
                                       id={"lower_" + this.props.columnIdx.toString()}
                                       name={"changeCaseType_" + this.props.columnIdx.toString()}
                                       onChange={this.changeCaseType}
                                       checked={cleaningFunctions['changeCase'] === "lower"}
                                       value="lower" />
                                <label style={{...labelStyle, paddingLeft:'10px'}} htmlFor={"lower_" + this.props.columnIdx.toString()}> Lower Case</label>
                                <br/>
                                <input type="radio"
                                       id={"upper_" + this.props.columnIdx.toString()}
                                       name={"changeCaseType_" + this.props.columnIdx.toString()}
                                       onChange={this.changeCaseType}
                                       checked={cleaningFunctions['changeCase'] === "upper"}
                                       value="upper" />
                                <label style={{...labelStyle, paddingLeft:'10px'}} htmlFor={"upper_" + this.props.columnIdx.toString()}> Upper Case</label>
                                <br/>
                                <input type="radio"
                                       id={"title_" + this.props.columnIdx.toString()}
                                       name={"changeCaseType_" + this.props.columnIdx.toString()}
                                       onChange={this.changeCaseType}
                                       checked={cleaningFunctions['changeCase'] === "title"}
                                       value="title" />
                                <label style={{...labelStyle, paddingLeft:'10px'}} htmlFor={"title_" + this.props.columnIdx.toString()}> Title Case</label>
                            </div>
                        </div> : null
                }
                <div>
                    <Checkbox
                        name="findReplace"
                        label={<label style={labelStyle}>Find and Replace <InfoPopup style={{cursor: "pointer" }} info={<div> Find & Replace will occur AFTER Change Case <br/><br/> <u>Example value:</u> <b>How are you?</b> <br/> <u>Find:</u> <b>ARE</b> <u>Replace:</u> <b>could</b> <br/><br/> <i>Scenario 1 <Icon name="check" style={{color: "green"}}/> </i><br/><u>Case:</u> uppercase <br/><u>Cleaned value</u> <b>HOW could YOU?</b> <br/><br/> <i>Scenario 2 <Icon name="cancel" style={{color: "red"}}/></i><br/> <u>Case:</u> lowercase <br/> <u>Cleaned value</u> <b>how are you?</b>  </div>} /> </label> }
                        onChange={this.changeFindReplace}
                        checked={'findReplace' in cleaningFunctions}
                    />
                </div>
                {
                    'findReplace' in cleaningFunctions ?
                        <div>
                            {cleaningFunctions['findReplace'].map((x, idx) =>
                                <FindReplaceInputs idx={idx} columnIdx={this.props.columnIdx}/> )}
                        </div> : null

                }
                {
                    'changeCase' in cleaningFunctions || 'findReplace' in cleaningFunctions || 'trimWhitespace' in cleaningFunctions ? <div> <TestingCleanFunctions cleaningFunctions={cleaningFunctions} columnType={"text"} /> </div>:null
                }
            </div>


        )
    }
}


const mapStateToProps = (state) => ({
    customImportColumnsAddlInfo: state.mainState.customImportColumnsAddlInfo,
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(TextCleaningFunctions)