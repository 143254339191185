import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyStatus} from "../../Actions/ModifyStatus";
import {toggleModal} from "../../Actions/ToggleModal";
import API_Root from "../../Constants/API_Root";
import constants from "../../Constants/constants";
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import {modifySheetRead} from "../../Actions/ModifySheetRead";
import {toggleToast} from "../../Actions/ToggleToast";
import RefreshFile from "../RefreshFile"
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
import {readFiles} from "../../Constants/readFile";
import {modifyFilePKProBlock} from "../../Actions/ModifyFilePKProBlock";
import getURLParam from "../../Constants/getURLParam";
import { push } from 'react-router-redux'
import {modifyEditQueryInfo} from "../../Actions/ModifyEditQueryInfo";
import PageHeaderButton from "../../SharedComponents/PageHeaderButton";
import {modifyAPIMenuItem} from "../../Actions/ModifyAPIMenuItem";

class ReuploadLink extends Component {
    constructor(props) {
        super(props);

        this.reuploadFile = this.reuploadFile.bind(this);
        this.getStatusAndResults = this.getStatusAndResults.bind(this);
    }


    reuploadFile(e){
        this.props.toggleModal("loadingModalEditFile");

        this.props.modifyStatus("reading");

        const pkArray = [{'id': this.props.pkID, "pk": this.props.replaceFilePK}]
        this.props.modifyStatus("saved");
        this.getStatusAndResults(pkArray);
    }

    getStatusAndResults(filePK){
        let socketInfo = {
            filePK: filePK,
            sheetRead: this.props.sheetRead,
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
            utcOffset: new Date().getTimezoneOffset(),
            reupload: true,
            originalFilePK: [{'id': this.props.pkID, 'pk': this.props.pk}]
        };

        if (!('fixColumnNames' in this.props)){
            socketInfo['checkColumnNames'] = true
        }

        readFiles(socketInfo, filePK, this.props, "read-api-files", true, false)
    }

    render() {
        return(
            <span className="linkStyle" onClick={this.reuploadFile}>Replace current file with this one</span>
        )
    }
}

const mapStateToProps = (state) => ({
    sheetRead: state.mainState.sheetRead,
    editOptions: state.mainState.editOptions,
    fileInformation: state.mainState.fileInformation,
    filePKProBlock: state.mainState.filePKProBlock
})

const mapActionsToProps = {
    modifyStatus: modifyStatus,
    toggleModal: toggleModal,
    modifyFileInformation: modifyFileInformation,
    modifySheetRead: modifySheetRead,
    toggleToast: toggleToast,
    modifyModalInfo: modifyModalInfo,
    modifyFilePKProBlock: modifyFilePKProBlock,
    navigateTo: push,
    modifyEditQueryInfo: modifyEditQueryInfo,
    modifyAPIMenuItem: modifyAPIMenuItem
}

export default connect(mapStateToProps, mapActionsToProps)(ReuploadLink)