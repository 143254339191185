import API_Root from "./API_Root";
import {getQuery} from "./getQuery";
import { store } from '../index.js'
import {getConnectedDomainLink} from "./getConnectedDomainLink";

export const getFileURL = (fileInformation, pk, pkID, outputFormat, apiType, rowLen, rows, readQuery, count=false) => {
    const outputType = getOutputType(outputFormat);

    let API = API_Root === "https://api-woyera.com/" ? "https://api.apispreadsheets.com/" : API_Root.replace("8000", "8080");
    try{
        if (API_Root === "https://api-woyera.com/"){
            const connectedDomainLink = getConnectedDomainLink(store.getState().mainState.connectedDomains)
            if (connectedDomainLink !== null){
                API = connectedDomainLink + "/"
            }
        }
    } catch (e){
        console.log(e)
    }

    // const API = API_Root === "https://api-woyera.com/" ? "https://api.apispreadsheets.com/" : API_Root;
    // const API = "https://api.apispreadsheets.com/";
    let url = API + "data/" + pk + "/";

    if (apiType === "readAction"){
        if (isPrivate(fileInformation, pkID)){
            const accessKey = fileInformation[pkID]['accessKey'];
            const secretKey = fileInformation[pkID]['secretKey'];

            let dataFormat = outputType !== "" ? "&dataFormat=" + outputType : "";
            url += "?accessKey=" + accessKey + "&secretKey=" + secretKey + dataFormat

        }
        else{
            let dataFormat = outputType !== "" ? "?dataFormat=" + outputType : "";
            url += dataFormat
        }

        if (readQuery){
            const query = getQuery(fileInformation[pkID] , pk, "read")

            if (url.includes("?")){
                url += "&query=" + query
            }
            else{
                url += "?query=" + query
            }
        }

        if (rowLen.toString() !== rows.toString()){
            if (url.includes("?")){
                url += "&limit=" + rows.toString()
            }
            else{
                url += "?limit=" + rows.toString()
            }

        }

        if (count){
            url += url.includes("?") ? "&" : "?"
            url += "count"
        }

    }
    else if (apiType === "deleteAction"){
        if (isPrivate(fileInformation, pkID)){
            const accessKey = fileInformation[pkID]['accessKey'];
            const secretKey = fileInformation[pkID]['secretKey'];

            url += "?accessKey=" + accessKey + "&secretKey=" + secretKey + "&query=" + getQuery(fileInformation[pkID] , pk, "delete")

        }
        else{
            url += "?query=" + getQuery(fileInformation[pkID] , pk, "delete")
        }
    }

    return url
}

const getOutputType = (outputFormat) => {
    let outputType = "";

    if (outputFormat === "matrix"){
        outputType = "matrix"
    }
    else if (outputFormat === "jsonColumn"){
        outputType = "column"
    }

    return outputType
}

const isPrivate = (fileInformation, pkID) => {
    return fileInformation[pkID]['private']
}

