export const RESET_ALL_STATES = 'RESET_ALL_STATES';

export function resetAllStates(){
    return{
        type: RESET_ALL_STATES,
        mainState: {
            filePK: [],
            fileInformation: {},
            editOptions: {},
            modalName: null,
            proBlock: false,
            status: "upload",
            editID: null,
            sheetRead: {},
            addFileName: false,
            fileURL: '',
            keysPK: -1,
            customContactFiles: [],
            filePKProBlock: {}
        }
    }
}