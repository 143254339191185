import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyImportSheetsInfo} from "../../Actions/ModifyImportSheetsInfo";
import { Button, Input } from 'semantic-ui-react'
import ImportConfigNameSaveBtn from "./ImportConfigNameSaveBtn"

class ImportConfigName extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: false,
            name: JSON.parse(JSON.stringify(this.getOriginalName()))
        }
    }

    getOriginalName = () => this.props.custom ? this.props.customImportInfo.name : this.props.importSheetsInfo[this.props.idx].name

    editName = (e) => this.setState({ isEditing: true })

    handleNameChange = (e) => this.setState({ name: e.target.value })

    undoName = (e) => this.setState({ isEditing: false, name: JSON.parse(JSON.stringify(this.getOriginalName())) })

    stopEditing = () => this.setState({ isEditing: false })

    render() {
        if (this.state.isEditing){
            return (
                <span>
                    <input
                        size="40"
                        style={{
                            padding: "8px 8px 8px 12px",
                            border: "1px solid #F0F0F0",
                            borderRadius: "4px",
                            fontSize: "14px",
                            backgroundColor: "#F0F0F0"}}
                           value={this.state.name}
                           onChange={this.handleNameChange} />
                    <Button content="Undo"
                            icon="undo"
                            onClick={this.undoName}
                            size="mini"
                            style={{backgroundColor: "#9696A0", color: "white"}}
                    />
                    <ImportConfigNameSaveBtn
                        custom={this.props.custom}
                        idx={this.props.idx}
                        importHash={this.props.importHash}
                        stopEditing={this.stopEditing}
                        originalName={this.getOriginalName()}
                        configName={this.state.name}/>
                </span>
            )
        } else {
            return (
                <span style={{fontWeight: "700", fontSize: "14px", fontFamily: "Lato", color: "#161E16"}}>
                    {this.getOriginalName()}
                    {
                        this.props.custom ?
                            <button onClick={this.editName} style={{marginLeft: "8px"}} className="iconBtn">
                                {/*<span><Icon name="edit outline" /></span>*/}
                                <span><i className="bx bxs-edit-alt" /> </span>
                            </button>
                            : null
                    }
                </span>
            )
        }

    }
}

const mapStateToProps = (state) => ({
    importSheetsInfo: state.mainState.importSheetsInfo,
    customImportInfo: state.mainState.customImportInfo
})

const mapActionsToProps = {
    modifyImportSheetsInfo: modifyImportSheetsInfo
}

export default connect(mapStateToProps, mapActionsToProps)(ImportConfigName)