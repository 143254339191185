import React, {Component} from 'react'
import { Popup, Button } from 'semantic-ui-react'

class DeleteS3Account extends Component {
    constructor(props) {
        super(props);
    }

    deleteAccount = () => {

    }

    render() {
        return (
            <Button circular
                    onClick={this.deleteAccount}
                    icon="trash alternate outline"
                    color="red"
                    basic />
        )
    }
}

export default DeleteS3Account