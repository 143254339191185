import React, {Component} from 'react'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import {connect} from 'react-redux'
import {toggleToast} from "../../Actions/ToggleToast";
import {Icon } from 'semantic-ui-react'
import DeleteKeys from "./DeleteKeys"

class KeyBox extends Component {
    constructor(props) {
        super(props);
    }

    copyKey = (keyType, keyValue) => {
        // e.target.focus();
        navigator.clipboard.writeText(keyValue)
        document.execCommand("copy")
        window.getSelection().removeAllRanges();

        const label = {'access_key': "Access Key", "secret_key": "Secret Key"}[keyType]

        this.props.toggleToast({show: true, message: label + " Copied!", type: "success"})
    }

    render() {
        const keyRowStyle = {
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            columnGap: "16px",
            marginBottom: "4px",
            fontSize: "16px",
            fontFamily: "Lato",
            fontWeight: "400",
            color: gunaldiStyles.subText
        }

        const accessKey = this.props.keys.access_key;
        const secretKey = this.props.keys.secret_key;

        return (
            <div style={{ borderTop: "1px solid #F0F0F0", borderBottom: "1px solid #F0F0F0", padding: "8px 0 8px 0"}}>
                <div style={keyRowStyle}>
                    <div style={{  }}>
                        Access Key
                    </div>
                    <div style={{ textAlign: "right"}}>
                        <span style={{ marginRight: "4px"}}>
                            {accessKey}
                        </span>
                        <Icon name="copy outline"
                              className="fileIDLabelIcon"
                              color="green"
                              style={{cursor: "pointer"}}
                              onClick={e => this.copyKey("access_key", accessKey)}
                        />
                    </div>
                </div>
                <div style={keyRowStyle}>
                    <div style={{ fontSize: "16px", fontFamily: "Lato", fontWeight: "400", color: gunaldiStyles.subText }}>
                        Secret Key
                    </div>
                    <div style={{ textAlign: "right"}}>
                        <span style={{ marginRight: "4px"}}>
                            {secretKey}
                        </span>
                        <Icon name="copy outline"
                              className="fileIDLabelIcon"
                              color="green"
                              style={{cursor: "pointer"}}
                              onClick={e => this.copyKey("secret_key", secretKey)}
                        />
                    </div>
                </div>
                {
                    this.props.canDelete ?
                        <div style={{ marginTop: "8px"}}>
                            <DeleteKeys pk={this.props.pk}
                                        keys={this.props.keys}
                                        idx={this.props.idx}
                                        deleteKeysFromState={this.props.deleteKeysFromState}
                            />
                        </div>: null
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(KeyBox)