import React, {Component} from 'react'
import {connect} from 'react-redux'
import getURLParam from "../../Constants/getURLParam";
import API_Root from "../../Constants/API_Root";
import {initOriginalFilePK} from "../../Actions/InitOriginalFilePK";
import {modifyFilePK} from "../../Actions/ModifyFilePK";
import {modifyTeamInfo} from "../../Actions/ModifyTeamInfo";
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import {modifyFilePKProBlock} from "../../Actions/ModifyFilePKProBlock";
import {modifyFilesFetchedTime} from "../../Actions/ModifyFilesFetchedTime";
import {resetAllStates} from "../../Actions/ResetAllStates";
import {modifySheetRead} from "../../Actions/ModifySheetRead";
import {push} from "react-router-redux";
import Loader from 'react-loader-spinner'
import {isBrowser} from "react-device-detect";
import FileSummary from "../FileSummary"
import { Message, Button, Icon } from 'semantic-ui-react'
import PageHeader from "../../SharedComponents/PageHeader";
import BackHeaderIcon from "../../SharedComponents/BackHeaderIcon";
import FilesIDHeaderToolbar from "./FilesIDHeaderToolbar"
import FilesTabFileSource from "../FilesTabFileSource";
import {canUserTakeAction} from "../../SharedComponents/canUserTakeAction";
import FilesIDDisplay from "./FilesIDDisplay"
import EmailVerificationMessage from "../../SharedComponents/EmailVerificationMessage";
import {modifyAPIMenuItem} from "../../Actions/ModifyAPIMenuItem";
import {isUserAMacroUser} from "../../Constants/isUserAMacroUser";

const prevPages = ["teams", "database", "import"]

class FilesIDIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: true,
            error: false,
            previewFileHash: false
        };
    }

    componentDidMount(){
        let pk = getURLParam(window.location.href);

        if (pk.includes("?")){
            pk = pk.substring(0, pk.indexOf("?"))
        }
        // for (let i=0; i<prevPages.length++; i++){
        //     const prevPageTitle = prevPages[i];
        //     const prevPageParam = "?" + prevPageTitle + "=";
        //
        //     if (window.location.href.includes(prevPageParam)){
        //         pk = pk.substring(0, pk.indexOf(prevPageParam))
        //         break;
        //     }
        // }

        fetch(API_Root + "api/get-one-api-file/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                utcOffset: new Date().getTimezoneOffset(),
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                filePK: pk
            })
        }).then(res => res.json())
            .then((data)=> {
                const dataCopy = JSON.parse(JSON.stringify(data));
                this.props.modifyFilePK(dataCopy.filePK);
                this.props.modifyFileInformation(data.fileInformation);
                this.props.modifySheetRead(data.sheetRead);
                this.props.modifyFilePKProBlock(data.filePKProBlock);
                this.props.modifyTeamInfo(data.teamInfo)
                this.props.modifyFilesFetchedTime();
                this.setState({
                    error: 'error' in dataCopy ? dataCopy.error : false
                })
                this.props.initOriginalFilePK(dataCopy.filePK)
                this.setState({fetching: false});
            }).catch(err => this.setState({fetching: false})).catch(err => this.setState({fetching: false}));
        // alert(pk)
    }

    goToFiles = () => {
        this.fileLink.click()
    }

    togglePreviewFileHash = () => {
        const shouldPreviewFileHash = JSON.parse(JSON.stringify( this.state.previewFileHash ))

        this.setState({ previewFileHash: !shouldPreviewFileHash })
    }

    getBackURL = () => {
        const url = window.location.href
        let backURL;

        if (url.includes("?importerFilePage")){
            backURL = "/import?menuItem=files&refresh"
        } else {
            backURL = "/api?menuItem=files&refresh"

            for (let i=0; i<prevPages.length; i++){
                const prevPageTitle = prevPages[i];
                const prevPageParam = "?" + prevPageTitle + "=";

                if (url.includes(prevPageParam)){
                    backURL = "/" + prevPageTitle + "/" + this.getBackURLID(url, prevPageParam)
                    break
                }
            }
        }

        return backURL
    }

    getBackURLID = (url, prevPageParam) => {
        return url.substring(url.indexOf(prevPageParam) + prevPageParam.length, url.length).replace("/", "")
    }

    render() {
        let body;

        if (this.state.fetching){
            body =
                <div style={{padding: '100px 0', textAlign: 'center'}}>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4 className="thinHeading" style={{color: 'black'}}>Getting your file...</h4>
                </div>
        } else {
            if (this.props.originalFilePK.length > 0){
                const colLengths = ["6","3", "1", "2"];

                const url = window.location.href
                const isSourceTeam = url.includes("?team=")
                const isFilesTab = url.includes("/files")

                const fileInformation =  this.props.fileInformation[this.props.filePK[0].id];
                const fileName = fileInformation.name;
                const isExcel = fileName.split('.').pop() !== "csv" && fileName.split('.').pop() !== "txt";
                const sheetName = isExcel ? <span className="text-muted"><small>{this.props.sheetRead[this.props.filePK[0].id]}</small></span> : null;
                const canTakeAction = canUserTakeAction(fileInformation, this.props.teamInfo)

                let isFileImported;
                try{
                    isFileImported = fileInformation.importSheetsFK !== -1;
                } catch (e){
                    isFileImported = false;
                }

                let proBlock = this.props.filePKProBlock[this.props.filePK[0].id];
                let fixColNames = false;

                if (!proBlock){
                    if (fileInformation.fixColumnNames){
                        if (!isUserAMacroUser(this.props.fullPricingInfo)) {
                            proBlock = true
                            fixColNames = true
                        }
                    }
                }

                body =
                    <div>
                        <PageHeader
                            header={
                                <div className="filesIDIndexHeaderContainer">
                                    <h3><span style={{marginTop: "3px"}}> <BackHeaderIcon link={this.getBackURL()} /> <FilesTabFileSource fileInformation={fileInformation} filesIndex/> {fileName} {sheetName} </span> </h3>
                                </div>
                            }
                            desc={<FilesIDHeaderToolbar fileInformation={fileInformation}
                                                        idx={0}
                                                        fixColNames={fixColNames}
                                                        proBlock={proBlock}
                                                        isExcel={isExcel}
                                                        canTakeAction={canTakeAction}
                                                        pk={this.props.filePK[0].pk}
                                                        pkID={this.props.filePK[0].id}
                                                        previewFileHash={this.state.previewFileHash}
                                                        fileHash={fileInformation['fileHash']} />}
                            isImporter={isFileImported}
                        />
                        <FilesIDDisplay idx={0} pkID={this.props.filePK[0].id}
                                        pk={this.props.filePK[0].pk}
                                        key={0}
                                        colLengths={colLengths}
                                        textPadding="10"
                                        tableType="all"
                                        togglePreviewFileHash={this.togglePreviewFileHash}
                        />
                </div>
            } else {
                body =
                    <div style={{paddingTop: '50px'}}>
                        <div className="row">
                            <div className="col-sm-2" />
                            <div className="col-sm-8">
                                <Message error header={this.state.error ? "You are not authorized to view this file" : "This file does not exist"} />
                            </div>
                            <div className="col-sm-2" />
                        </div>
                    </div>
            }
        }

        return (
            <div style={{minHeight: '100vh', paddingBottom: '350px'}}>
                <EmailVerificationMessage/>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    originalFilePK: state.mainState.originalFilePK,
    filesFetchedTime: state.mainState.filesFetchedTime,
    sheetRead: state.mainState.sheetRead,
    teamInfo: state.mainState.teamInfo,
    filePKProBlock: state.mainState.filePKProBlock,
    fullPricingInfo: state.mainState.fullPricingInfo
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation,
    modifyFilePK: modifyFilePK,
    modifySheetRead: modifySheetRead,
    modifyFilePKProBlock: modifyFilePKProBlock,
    initOriginalFilePK: initOriginalFilePK,
    modifyFilesFetchedTime: modifyFilesFetchedTime,
    navigateTo: push,
    resetAllStates: resetAllStates,
    modifyTeamInfo: modifyTeamInfo
}

export default connect(mapStateToProps, mapActionsToProps)(FilesIDIndex)