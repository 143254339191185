export const areArraysTheSame = (originalArray, newArray) => {
    let areSame = true;

    if (originalArray.length === newArray.length){
        originalArray.sort()
        newArray.sort()

        for (let i=0; i<originalArray.length; i++){
            if (originalArray[i] !== newArray[i]){
                areSame = false;
                break
            }
        }
    } else {
        areSame = false;
    }

    return areSame
}