import React, {Component} from 'react'
import {connect} from 'react-redux'
import constants from "../../Constants/constants";
import FileDrop from 'react-file-drop';
import UploadIndicator from "../../UploadFiles/Indicators/UploadIndicator";
import ReadingIndicator from '../../UploadFiles/Indicators/ReadingIndicator'
import MergeIndicator from '../../UploadFiles/Indicators/MergeIndicator'
import bytesToMB from "../../Constants/bytesToMB";
import API_Root from "../../Constants/API_Root";
import copyArray from "../../Constants/copyArray";
import {modifyStatus} from "../../Actions/ModifyStatus";
import {modifyImportSheetsUploadPK} from "../../Actions/ModifyImportSheetsUploadPK";
import copyObject from "../../Constants/copyObject";
import {removePKFromArray} from "../../Constants/removePKFromArray";
import {modifyImportSheetsFileInformation} from "../../Actions/ModifyImportSheetsFileInformation";
import {toggleToast} from "../../Actions/ToggleToast";
import {modifyImportSheetRead} from "../../Actions/ModifyImportSheetRead";
import {hexToRgbA} from "../../Constants/hexToRgbA";
import {getValueFromInfoObject} from "./getValueFromInfoObject";
import {modifyCustomImportSettings} from "../../Actions/ModifyCustomImportSettings";
import {getShouldMatchColumns} from "./getShouldMatchColumns";
import { Message } from 'semantic-ui-react'
import {getFileURL} from "../../Constants/getFileURL";
import {toggleModal} from "../../Actions/ToggleModal";
import convertHexToRGB from "../../Constants/convertHexToRGB";
import {modifyFileInfoForCallback} from "./modifyFileInfoForCallback";
import isObjectEmpty from "../../Constants/isObjectEmpty";

class ImportUploadDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileInfo: [],
            error: null,
            sheetMap: {},
            updatedPK: []
        }
    }

    dismissMessage = () => this.setState({ error: null })

    handleFileUpload = (droppedFiles, e) => {
        const isError = (err) => {
            this.props.toggleModal(null)
            this.props.toggleToast({show: true, message: "There was an error uploading your file(s)! Please try again!", type: "error"});
            this.props.modifyStatus("upload");
        }

        const files = droppedFiles === null ? e.target.files : droppedFiles;

        const formData = new FormData();

        let allFileInfo = [];

        for (let i=0; i<files.length; i++){
            const fileObj = files[i];
            formData.append("file" + i.toString(), fileObj);
            allFileInfo.push({
                name: fileObj.name,
                size: bytesToMB(fileObj.size)
            })
        }

        formData.append("importKey", this.props.importKey);
        formData.append("readAllSheets", (this.getFileAppearanceSettings().readAllSheets && !getShouldMatchColumns(this.props.info)).toString())

        this.props.modifyStatus("reading");
        this.props.toggleModal("loadingModalImportFile")

        this.setState({ sheetMap: {}, updatedPK: [] })

        fetch(API_Root + 'api/save-api-files/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: formData
        }).then(res => res.json())
            .then(data => {
                if (!data.error){
                    // Modify the File Primary Keys
                    let filePK = JSON.parse(JSON.stringify(data.filePK));
                    let emptyFiles = JSON.parse(JSON.stringify(data.emptyFiles));

                    if (emptyFiles.length > 0){
                        this.setState({
                            error: <Message header="Empty files named below can't be uploaded"
                                            content={emptyFiles.join(",")}
                                            onDismiss={this.dismissMessage}
                                            negative
                                            icon="warning sign"
                                            style={{marginBottom: "10px"}}
                            />
                        })
                    }

                    if (filePK.length > 0){
                        if (this.props.importSheetsUploadPK.length > 0){
                            filePK = copyArray(this.props.importSheetsUploadPK, data.filePK);
                        }

                        if ("sheetMap" in data){
                            if (!isObjectEmpty(data.sheetMap)){
                                this.setState({ sheetMap: data.sheetMap })
                            }
                        }
                        // for excel files in the backend, get their sheetNames
                        // read file n number of times for sheetNames
                        // create {pk_id: sheetread, pk_id2: sheetread} mapping
                        // another redux object with first file's [{pk: {sheetName1: pk1, sheetName2: pk2, ...}}, ...]

                        this.props.modifyStatus("saved");
                        this.props.modifyImportSheetsUploadPK(filePK)

                        // Open up WebSocket when files are saved
                        this.getStatusAndResults(data.filePK);
                    } else {
                        this.props.modifyStatus("upload")
                    }
                }
                else{
                    isError(data.error)
                }
            }).catch(err => isError(err)).catch(err => isError(err));
    }

    getStatusAndResults = (filePK) => {
        // Create sheetRead for files with multiple sheets
        let newSheetRead = {};
        for (let i=0; i < filePK.length; i++){
            const pkInfo = filePK[i];
            if ("sheetName" in pkInfo){
                newSheetRead[pkInfo.id] = pkInfo['sheetName']
            }
        }

        const copiedSheetRead = {...newSheetRead, ...this.props.importSheetRead}
        // Offset to get created dt
        const current = new Date();
        const utcOffset = current.getTimezoneOffset();

        const shouldMatchColumns = getShouldMatchColumns(this.props.info)
        const columnsMap = shouldMatchColumns ? [] : null;

        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/read-api-files/");

        // Send the PKs and if any column names
        socket.onopen = (event) => {
            socket.send(JSON.stringify({
                utcOffset: utcOffset,
                filePK: filePK,
                sheetRead: copiedSheetRead,
                // sheetRead: this.props.importSheetRead,
                importKey: this.props.importKey,
                email: this.props.info.userEmail,
                importName: localStorage.getItem("importName"),
                columnsMap: columnsMap
            }));
        };

        socket.onmessage = (event) => {
            const reply = JSON.parse(event.data);
            const status = reply['status'];

            if (status === "read") {
                this.props.modifyStatus(status);
            }

            if (status === "api") {
                this.setState({
                    updatedPK: filePK
                })

                let fileInformation = JSON.parse(JSON.stringify(reply['fileInformation']));
                let sheetRead = reply['sheetRead'];

                this.props.modifyStatus(status);

                try {
                    if (!isObjectEmpty(this.state.sheetMap)){
                        let pkIndicesToDelete = [];

                        for (let sheetFileIdx in this.state.sheetMap){
                            sheetFileIdx = parseInt(sheetFileIdx)
                            pkIndicesToDelete.push(sheetFileIdx)

                            const parentFileIdx = this.state.sheetMap[sheetFileIdx];

                            const parentFilePKInfo = filePK[parentFileIdx];
                            const sheetFilePKInfo = filePK[sheetFileIdx]

                            const parentFileInfo = fileInformation[parentFilePKInfo.id]
                            const sheetFileInfo = fileInformation[sheetFilePKInfo.id]

                            const key = sheetRead[sheetFilePKInfo.id]
                            const sheetFileInfoKey = {[key]: {...sheetFileInfo, ...{id: sheetFilePKInfo.id, pk: sheetFilePKInfo.pk}}}

                            let parentSheetInfo;
                            try{
                                parentSheetInfo = parentFileInfo['sheetInfo']
                            } catch (e) {
                                parentSheetInfo = {}
                            }

                            parentFileInfo['sheetInfo'] = {...sheetFileInfoKey, ...parentSheetInfo}
                            fileInformation[parentFilePKInfo.id] = parentFileInfo

                            delete fileInformation[sheetFilePKInfo.id]
                            delete sheetRead[sheetFilePKInfo.id]
                        }

                        let updatedPK = [];
                        for (let i=0; i<this.props.importSheetsUploadPK.length; i++){
                            if (!pkIndicesToDelete.includes(i)){
                                updatedPK.push(this.props.importSheetsUploadPK[i])
                            }
                        }

                        this.props.modifyImportSheetsUploadPK(updatedPK)
                        this.setState({
                            updatedPK: updatedPK
                        })

                    }
                } catch (e) {
                    console.log(e)
                }

                if (typeof this.props.importSheetsFileInformation !== 'undefined') {
                    if (Object.entries(this.props.importSheetsFileInformation).length !== 0 && this.props.importSheetsFileInformation.constructor === Object) {
                        fileInformation = copyObject(this.props.importSheetsFileInformation, fileInformation);
                        sheetRead = copyObject(this.props.importSheetRead, sheetRead);
                    }
                }

                if (this.props.embed){
                    const updatedFileInformation = this.modifyFileInfo(fileInformation, this.state.updatedPK, sheetRead)
                    this.sendMessage(true, updatedFileInformation, shouldMatchColumns, filePK.map(x => false))
                }

                // for (let key in fileInformation){
                //     const fileInfo = fileInformation[key]
                //     fileInfo['columnsMap'] = columnsMap
                //     fileInformation[key] = fileInfo
                // }

                this.props.modifyImportSheetsFileInformation(fileInformation);
                this.props.modifyImportSheetRead(sheetRead);

                if (this.props.importSheetsUploadPK.length > 1){
                    const allPKID = this.props.importSheetsUploadPK.map(x => x.id)
                    const newPKID = filePK.map(x => x.id)

                    this.props.modifyCustomImportSettings("fileIdx", allPKID.indexOf(newPKID[0]))
                }

                this.props.modifyCustomImportSettings("shouldEdit", true)
                this.props.modifyCustomImportSettings("matchColumns", shouldMatchColumns)

                // if (!this.props.embed){
                    this.props.toggleModal(null)
                // }

                if (!shouldMatchColumns){
                    let successMessage = getValueFromInfoObject("successMessage", this.props.info, "appearance")

                    if (successMessage !== null){
                        if (successMessage.trim() === ""){
                            successMessage = "Files successfully uploaded!"
                        }
                    } else {
                        successMessage = "Files successfully uploaded!"
                    }

                    this.props.toggleToast({
                        show: true,
                        message: successMessage.trim(),
                        type: "success"
                    })

                }

                socket.close()
            }

            if (status === "error") {
                this.sendMessage(false, null, shouldMatchColumns, filePK.map(x => false))
                this.props.modifyStatus("upload")
                this.props.modifyImportSheetsUploadPK(removePKFromArray(this.props.importSheetsUploadPK, filePK));

                // if (!this.props.embed){
                    this.props.toggleModal(null)
                // }

                const erorrMessage = 'errorMessage' in reply ? reply['errorMessage'] : "There was an error reading your file :( Please try again"
                this.props.toggleToast({show: true, message: erorrMessage, type: "error"})

                socket.close()
            }
        };
    }

    sendMessage = (success, fileInfo, shouldMatchColumns, matchCompleted) => {
        if (this.props.embed){
            const parentWindow = window.parent;

            parentWindow.postMessage({success: success, fileInfo: fileInfo, match: {required: shouldMatchColumns, completed: matchCompleted}}, "*")
        }
    }

    modifyFileInfo = (allFileInfo, filePK, sheetRead) => {
        let updatedFileInfo = [];

        if (filePK.length > 0){
            const copiedAllFileInfo = JSON.parse(JSON.stringify(allFileInfo));

            for (let i=0; i<filePK.length; i++){
                const id = filePK[i].id;
                const pk = filePK[i].pk;

                const fileInfo = modifyFileInfoForCallback(copiedAllFileInfo, id, pk, sheetRead);

                if (fileInfo !== null){
                    if ("sheetInfo" in fileInfo){
                        const allSheetInfo = JSON.parse(JSON.stringify(fileInfo.sheetInfo))
                        // console.log(allSheetInfo)
                        for (let sheetName in allSheetInfo){
                            const sheetInfo = allSheetInfo[sheetName]
                            const sheetPk = sheetInfo.pk;
                            const sheetID = sheetInfo.id;
                            const sheetReadObj = {[sheetID]: sheetName}
                            const copiedAllSheetInfo = {[sheetID]: sheetInfo}

                            const modifiedSheetInfo = modifyFileInfoForCallback(copiedAllSheetInfo, sheetID, sheetPk, sheetReadObj)

                            allSheetInfo[sheetName] = modifiedSheetInfo
                        }

                        fileInfo.sheetInfo = allSheetInfo
                    }

                    updatedFileInfo.push(fileInfo)
                }
            }
        }

        return updatedFileInfo
    }

    getFileAppearanceSettings = () => {
        const defaultAppearance = {
            "multipleFiles": true,
            "removeBranding": false,
            "fileTypes": [".xls", ".xlsx", ".csv", ".txt"],
            "backgroundColor": "#E27837",
            "successMessage": "",
            "errorMessage": "",
            "showFileUploads": true,
            "readAllSheets": false
        }

        if ("appearance" in this.props.info) {
            for (let appearanceKey in defaultAppearance){
                if (appearanceKey in this.props.info["appearance"]){
                    defaultAppearance[appearanceKey] = this.props.info["appearance"][appearanceKey]
                }
            }
        }

        return defaultAppearance
    }

    render() {
        if (this.props.matchColumns){
            return null
        } else {
            // Hides the Input Button
            const inputFileStyle = {
                width: '0.1px',
                height: '0.1px',
                opacity: '0',
                overflow: 'hidden',
                position: 'absolute',
                zIndex: '-1'
            };

            // Components for what status the job is in
            const indicators = {
                upload: <UploadIndicator status="upload" importSegment info={this.props.info}/>,
                reading: <ReadingIndicator percentage={0} importSegment/>,
                saved: <ReadingIndicator percentage={45} importSegment/>,
                read: <MergeIndicator percentage={90} importSegment/>,
                api: <UploadIndicator status="merged" importSegment info={this.props.info}/>
            };

            const appearanceSettings = this.getFileAppearanceSettings()

            let buttonColor = "#FFFFFF"
            const rgbComponent = convertHexToRGB(appearanceSettings.backgroundColor);
            if (rgbComponent !== null){
                if ((rgbComponent.r * 0.299) + (rgbComponent.g * 0.587) + (rgbComponent.b * 0.114) > 186){
                    buttonColor = "#000000"
                }
            }

            return (
                <div style={{margin: "0 60px 0 60px"}}>
                    <input
                        type="file"
                        name="inputFileUpload"
                        id="inputFileUpload"
                        className="form-control-file"
                        onChange={(e) => this.handleFileUpload(null, e)}
                        style={inputFileStyle}
                        multiple={appearanceSettings.multipleFiles}
                        accept={appearanceSettings.fileTypes.join(",")}
                    />
                        <div className="uploadOuterSection" style={{border: "2px dashed " + hexToRgbA(appearanceSettings.backgroundColor, 0.5), marginBottom: "0"}}>
                            <label htmlFor="inputFileUpload" style={{width: "100%"}}>
                            <FileDrop draggingOverTargetClassName="lightgray" onDrop={this.handleFileUpload}>
                                <div style={{paddingTop: "25px", textAlign: "center"}}>
                                    <i className="bx bxs-folder-open" style={{color: hexToRgbA(appearanceSettings.backgroundColor), fontSize: "80px"}}/>
                                    <div style={{fontFamily: "Lato", fontWeight: "400", color: "#9696A0", marginTop: "8px", marginBottom: "16px", fontSize: "14px"}}>
                                        Drag & Drop Files here or
                                    </div>
                                    <label htmlFor="inputFileUpload" style={{backgroundColor: hexToRgbA(appearanceSettings.backgroundColor)}}>
                                        <div className="uploadLabel" style={{color: buttonColor, backgroundColor: hexToRgbA(appearanceSettings.backgroundColor)}}>
                                            Browse Files
                                        </div>
                                    </label>
                                    <div style={{color: "#9696A0", textAlign: "center"}}>
                                        <small>You can upload: {appearanceSettings.fileTypes.join(",")}</small>
                                    </div>
                                </div>
                            </FileDrop>
                            </label>
                        </div>
                        {this.state.error}
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    status: state.mainState.status,
    importSheetRead: state.mainState.importSheetRead,
    importSheetsUploadPK: state.mainState.importSheetsUploadPK,
    importSheetsFileInformation: state.mainState.importSheetsFileInformation,
    matchColumns: state.mainState.customImportMatchColumns
})

const mapActionsToProps = {
    modifyStatus: modifyStatus,
    modifyImportSheetsUploadPK: modifyImportSheetsUploadPK,
    modifyImportSheetsFileInformation: modifyImportSheetsFileInformation,
    toggleToast: toggleToast,
    modifyImportSheetRead: modifyImportSheetRead,
    modifyCustomImportSettings: modifyCustomImportSettings,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ImportUploadDisplay)