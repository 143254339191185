import React, {Component} from 'react'
import {connect} from 'react-redux'
import getURLParam from "../../Constants/getURLParam";
import API_Root from "../../Constants/API_Root";
import { Message } from 'semantic-ui-react';
import Loader from 'react-loader-spinner'
import {initAllCustomImportSettings} from "../../Actions/InitAllCustomImportSettings";
import CustomImportIndex from "./CustomImport/Index/CustomImportIndex"
import isObjectEmpty from "../../Constants/isObjectEmpty";
import {getColumnAddlInfo, getColumnInfo} from "./CustomImport/Columns/getColumnInfo";
import BannerAlert from "../../SharedComponents/BannerAlert"
import PageHeader from "../../SharedComponents/PageHeader"
import BackHeaderIcon from "../../SharedComponents/BackHeaderIcon"
import ImportConfigName from "../ImportSheetsSegment/ImportConfigName"
import ImportPageHeaderToolbar from "./ImportPageHeaderToolbar"

class ImportIDDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: true,
            info: null,
            idx: -1,
            activeItem: "columns"
        }
    }

    componentDidMount(){
        const error = (msg="There was an error setting up this import page! Please try again") => {
            this.setState({ fetching: false, info: null })
        }

        const splitParam = getURLParam(window.location.href).split("_")
        const importKey = splitParam[0];

        let idx = -1;
        if (splitParam.length > 1){
            idx = parseInt(splitParam[1])
        }

        let activeItem = "columns";
        if (splitParam.length > 2){
            activeItem = splitParam[2]
        }

        fetch(API_Root + "api/get-import-sheet-info/", {
            method: "POST",
            headers: {Accept: 'application/json, text/plain, */*',},
            body: JSON.stringify({
                importKey: importKey,
                dashboard: true,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token")
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then(data => {
                    const deleteKeys = ["columns", "destinations", "appearance", "importOptions"]

                    // Create another dict with all the rest of the info
                    let info = {}
                    for (let key in data){
                        if (!deleteKeys.includes(key)){
                            info[key] = data[key]
                            delete data[key]
                        }
                    }
                    data['info'] = info

                    // Create another key with the additional info of columns
                    let columnsAddlInfo = [];
                    let columnsInfo = [];

                    if (!isObjectEmpty(data['columns'])){
                        if ("columns" in data['columns']){
                            columnsInfo = JSON.parse(JSON.stringify(data['columns']['columns']))

                            for (let i=0; i<columnsInfo.length; i++){
                                const info = JSON.parse(JSON.stringify(columnsInfo[i]));

                                info['saved'] = true
                                info['error'] = false
                                info['dupeCheckName'] = info['name']
                                info['dupeCheckInternalName'] = info['internalName']
                                info['internalNameEqual'] = info['name'] === info['internalName']

                                columnsAddlInfo.push(info)
                            }
                        } else {
                            columnsInfo = [getColumnInfo()]
                            columnsAddlInfo = [getColumnAddlInfo()]
                        }
                    } else {
                        columnsInfo = [getColumnInfo()]
                        columnsAddlInfo = [getColumnAddlInfo()]
                    }

                    data['columns'] = columnsInfo
                    data['customImportColumnsAddlInfo'] = columnsAddlInfo

                    this.props.initAllCustomImportSettings(data)
                    this.setState({ fetching: false, info: {}, idx: idx, activeItem: activeItem})
                }).catch(err => error())
            } else if (res.status === 404){
                this.setState({ fetching: false })
            } else {
                error()
            }
        }).catch(err => error())
    }

    render() {
        let body = null;

        if (this.state.fetching){
            body =
                <div style={{padding: '100px 0', textAlign: 'center'}}>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4>Setting up your import page...</h4>
                </div>
        } else{
            if (this.state.info === null){
                body =
                    <div>
                        <BannerAlert type="error"
                                     header='There was an error setting up your import configuration settings'
                                     icon="bx bx-error-alt"
                                     content={<span>Please refresh and try again. Contact us at <a href="mailto:support@apispreadsheets.com">support@apispreadsheets.com</a> if this error persists.</span>}
                        />
                        <PageHeader
                            header={
                                <div className="filesIDIndexHeaderContainer">
                                    <h3>
                                    <span style={{marginTop: "3px"}}>
                                        <BackHeaderIcon link="/import"/>
                                        <span style={{marginTop: "3px", color: "red"}}>IMPORT CONFIGURATION ERROR</span>
                                    </span>
                                    </h3>
                                </div>
                            }
                            desc={null}
                        />
                    </div>
            } else {
                body =
                    <div>
                        <PageHeader
                            importer
                            header={
                                <div className="filesIDIndexHeaderContainer">
                                    <h3>
                                        <span style={{marginTop: "3px"}}>
                                            <BackHeaderIcon link="/import"/>
                                            <ImportConfigName idx={this.state.idx}
                                                              importHash={this.props.customImportInfo.importHash}
                                                              custom={true}
                                            />
                                        </span>
                                    </h3>
                                </div>
                            }
                            desc={<ImportPageHeaderToolbar idx={this.state.idx}
                                                           importName={this.props.customImportInfo.name}
                                                           custom={true}
                                                           importHash={this.props.customImportInfo.importHash} />}
                        />
                        <div style={{margin: "24px 60px 250px 60px"}}>
                            <CustomImportIndex idx={this.state.idx} activeItem={this.state.activeItem}/>
                        </div>
                    </div>
            }
        }

        return (
            <div style={{minHeight: '100vh'}}>
                {/*<a href="/cannot-find-import-configuration" style={{display: "none"}} ref={import404Config => this.import404Config = import404Config}>Read more</a>*/}
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    customImportInfo: state.mainState.customImportInfo
})

const mapActionsToProps = {
    initAllCustomImportSettings: initAllCustomImportSettings,
}

export default connect(mapStateToProps, mapActionsToProps)(ImportIDDisplay)