import React, {Component} from 'react'
import HeroImg from "../Illustrations/HeroImg.svg";
import ImporterTable from "../FeaturesTables/ImporterTableContent/ImporterTable";
// import SilcaLogo from "../Constants/SilcaLogo.png";
import { Icon } from 'semantic-ui-react'
import UpdatedCTA from "../UpdatedCTA";
import SignUp2Wave from "../Illustrations/SignUp2Wave.svg";
import SignUp3Wave from "../Illustrations/SignUp3Wave.svg";
import SignUp4Wave from "../Illustrations/SignUp4Wave.svg";
import SignUp5Wave from "../Illustrations/SignUp5Wave.svg";
import FeatureCard from "../FeatureCard";
import SaveTime from "../Illustrations/SaveTime.svg"
import Editing from "../Illustrations/Editing.svg"
import MinimalCoding from "../Illustrations/MinimalCoding.svg"
import APIQuickstartCode from "../../InfoPages/HowItWorks/Features/APIQuickstartCode.js"
import CustomerCarousel from "../../InfoPages/CustomerCarousel";
import APIDatabaseTable from "../FeaturesTables/APIDatabaseTableContent/APIDatabaseTable";
import CollaborateTeamTable from "../FeaturesTables/CollaborateTeamTableContent/CollaborateTeamTable";
import ImportStep1 from "../Illustrations/ImporterStep1.svg"
import ImportStep2 from "../Illustrations/ImporterStep2.svg"
import ImportStep3 from "../Illustrations/ImporterStep3.svg"
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import CollaborateIllustration from "../Illustrations/CollaborateIllustration.svg";
import ImportIllustration from "../Illustrations/ImportIllustration.svg"
import IntegrateIllustration from "../Illustrations/IntegrateIllustration.svg"
import {getHeroText} from "../getHeroText";
import PreURL from "../FeaturesTables/ImporterTableContent/PreURL"
import ColMatching from "../FeaturesTables/ImporterTableContent/ColMatching";
import SheetsDestination from "../FeaturesTables/ImporterTableContent/SheetsDestination";
import CustomAppearance from "../FeaturesTables/ImporterTableContent/CustomAppearance";
import GetAnAPI from "../FeaturesTables/ImporterTableContent/GetAnAPI";
import PrewrittenCode from "../FeaturesTables/ImporterTableContent/PrewrittenCode";
import HowItWorksRow from "../FeaturesTables/HowItWorksRow";
import MultipleSources from "../FeaturesTables/APIDatabaseTableContent/MultipleSources";
import CrudActionsDesc from "../FeaturesTables/APIDatabaseTableContent/CrudActionsDesc";
import SimpleDesign from "../FeaturesTables/APIDatabaseTableContent/SimpleDesign";
import QueriesDesc from "../FeaturesTables/APIDatabaseTableContent/QueriesDesc";
import LandingSecurity from "../FeaturesTables/APIDatabaseTableContent/LandingSecurity";
import SecurityDesc from "../FeaturesTables/APIDatabaseTableContent/SecurityDesc";
import APIStep1 from "../Illustrations/APIStep1.svg"
import APIStep2 from "../Illustrations/APIStep2.svg"
import APIStep3 from "../Illustrations/APIStep3.svg"
import Number1 from "../Illustrations/Number1.svg"
import Number2 from "../Illustrations/Number2.svg"
import Number3 from "../Illustrations/Number3.svg"
import HowItWorksSegment from "../FeaturesTables/HowItWorksSegment"
import AutoReportingDesc from "../FeaturesTables/CollaborateTeamTableContent/AutoReportingDesc";
import TeamsDesc from "../FeaturesTables/CollaborateTeamTableContent/TeamsDesc";
import MultipleKeysDesc from "../FeaturesTables/CollaborateTeamTableContent/MultipleKeysDesc";
import MonitoringAccessDesc from "../FeaturesTables/CollaborateTeamTableContent/MonitoringAccessDesc";
import Encrypt from "../Illustrations/Encrypt.svg"
import gdpr from "../Illustrations/gdpr.svg";
import Prem from "../Illustrations/Prem.svg"
import DataModel from "../FeaturesTables/ImporterTableContent/DataModel";
import ImportWebhooks from "../FeaturesTables/ImporterTableContent/ImportWebhooks";
import APISideBanner from "../Illustrations/APISideBanner.svg"
import {isEven} from "../../Constants/isEven";
import GunaldiHeroImage from "../Illustrations/GunaldiHeroImage.svg"
import GunaldiHeroFinal from "../Illustrations/GunaldiHeroFinal.svg"
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import GunaldiCTA from "../GunaldiCTA";
import ConvertSpreadsheetsToAPI from "../Illustrations/ConvertSpreadsheetsToAPI.svg"
import GunaldiCreateImporterSegment from "../Illustrations/GunaldiCreateImporterSegment.svg";
import RequestDemoSegment from "../RequestDemoSegment";
import LandingConvertSpreadsheetsToAPI from "../Illustrations/LandingConvertSpreadsheetsToAPI.svg"
import LandingApps from "../Illustrations/LandingApps.svg"
import LandingDashboard from "../Illustrations/LandingDashboard.svg"
import LandingForms from "../Illustrations/LandingForms.svg"
import LandingImport from "../Illustrations/LandingImport.svg"
import LandingReport from "../Illustrations/LandingReport.svg"
import LandingIntegrate from "../Illustrations/LandingIntegrate.svg"
import HeroText from "../HeroText";
import ImporterHero from "../Illustrations/ImporterHero.svg"
import LandingPageFeatureSegment from "../LandingPageFeatureSegment";
import ImporterFeature1 from "../Illustrations/ImporterFeatures/ImporterFeature1.svg"
import ImporterFeatureIcon1 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon1.svg"
import ImporterFeature2 from "../Illustrations/ImporterFeatures/ImporterFeature2.svg"
import ImporterFeatureIcon2 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon2.svg"
import ImporterFeature3 from "../Illustrations/ImporterFeatures/ImporterFeature3.svg"
import ImporterFeatureIcon3 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon3.svg"
import ImporterFeature4 from "../Illustrations/ImporterFeatures/ImporterFeature4.svg"
import ImporterFeatureIcon4 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon4.svg"
import ImporterFeature5 from "../Illustrations/ImporterFeatures/ImporterFeature5.svg"
import ImporterFeatureIcon5 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon5.svg"
import ImporterFeature6 from "../Illustrations/ImporterFeatures/ImporterFeature6.svg"
import ImporterFeatureIcon6 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon6.svg"
import ImporterFeature7 from "../Illustrations/ImporterFeatures/ImporterFeature7.svg"
import ImporterFeatureIcon7 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon7.svg"
import ImporterFeature8 from "../Illustrations/ImporterFeatures/ImporterFeature8.svg"
import ImporterFeatureIcon8 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon8.svg"
import Apps from "../Illustrations/ImporterFeatures/Apps.svg"
import ImporterUseCase from "../Illustrations/ImporterFeatures/ImporterUseCase.svg"
import integrate from "../Illustrations/ImporterFeatures/integrate.svg"

class ImporterBrowserLandingPageIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            buttonMargin: this.getResponsiveStyle("buttonMargin"),
            heroClassName: this.getResponsiveStyle("heroClassName"),
            headerFontSize: this.getResponsiveStyle("headerFontSize"),
            showHero: true,
            apiCount: "200+",
            isEven: isEven()
        }

        this.updateDimensions = this.updateDimensions.bind(this);
        this.getResponsiveStyle = this.getResponsiveStyle.bind(this);
    }

    getResponsiveStyle(style, updateState=false){
        let styleAttr;

        if (window.innerWidth >= 1280){
            if (style === "buttonMargin"){
                styleAttr = Math.floor((window.innerWidth - 1280) * 0.2) + 50
            } else if (style === "heroClassName"){
                styleAttr = "updatedLandingHero"
            } else if (style === "headerFontSize"){
                styleAttr = Math.floor((window.innerWidth - 1280) * 0.2) + 300
            } else if (style === "showHero"){
                styleAttr = true
            }
        } else {
            if (window.innerWidth < 1100){
                if (style === "showHero"){
                    styleAttr = false
                }
            } else {
                if (style === "showHero"){
                    styleAttr = true
                }
            }

            if (style === "buttonMargin"){
                styleAttr = 50
            } else if (style === "heroClassName"){
                styleAttr = "updatedLandingHero"
            } else if (style === "headerFontSize"){
                styleAttr = 300
            }
        }

        if (updateState){
            this.setState({ [style]: styleAttr })
        } else {
            return styleAttr
        }
    }

    updateDimensions = () => {
        this.getResponsiveStyle("showHero", true)
        this.getResponsiveStyle("buttonMargin", true)
        this.getResponsiveStyle("heroClassName", true)
        this.getResponsiveStyle("headerFontSize", true)
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render(){
        const heroText = getHeroText(this.state.isEven);
        const trustedText = <span style={{fontSize: "18px", fontWeight: "400", fontFamily: "Lato", color: "#161E16"}}>Trusted by <span style={{color: gunaldiStyles.mainGreen}}>200+</span> Businesses Worldwide</span>
        const featureMenuHeaderStyle = {fontFamily: "Lato", fontWeight: "700", color: "#161E16", fontSize: "24px", textAlign: "center", marginBottom: "12px"}
        const featureMenuSubheadingStyle = {fontFamily: "Lato", fontWeight: "400", color: "#5E645E", fontSize: "14px", textAlign: "center", marginBottom: "24px"}

        const sectionHeaderStyle = {textAlign: "center", paddingBottom: "80px", color: "#161E16", fontWeight: "700", fontFamily: "Lato", fontSize: "32px"};

        const featureDivStyle = {margin: "160px 200px 160px 200px"};

        return (
            <div style={{minHeight: '100vh'}}>
                {
                    this.state.showHero ?
                        <div className={this.state.heroClassName}>
                            <div>
                                <div style={{margin: '60px 0 0 100px'}}>
                                    <HeroText headerFontSize={this.state.headerFontSize} importer/>
                                </div>
                                {/*<UpdatedCTA style={{marginTop: this.state.buttonMargin.toString() + "px", marginLeft: '10%'}}/>*/}
                            </div>
                            <div>
                                <img src={ImporterHero} style={{width: '100%', height: 'auto', paddingRight: '5%'}} alt="Hero Spreadsheet Flow"/>
                            </div>
                        </div> :
                        <div style={{margin: '60px', textAlign: 'center'}}>
                            <HeroText headerFontSize={this.state.headerFontSize} importer/>
                        </div>
                }
                <div className="updatedLandingSocialProof">
                    <div style={{display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "100px"}}>
                        <div style={{fontFamily: "Lato", fontWeight: "700", fontSize: "20px", color: "#161E16", paddingTop: "20px"}}>
                            Trusted By
                        </div>
                        <div>
                            <CustomerCarousel/>
                        </div>
                    </div>
                </div>
                <div style={{ padding: "160px 120px 120px 100px"}}>
                    <div style={sectionHeaderStyle}>
                        Create a Spreadsheet <span style={{color: gunaldiStyles.orange}}>Importer</span> in Minutes
                    </div>
                    <div>
                        {
                            this.state.showHero ?
                                <img src={GunaldiCreateImporterSegment} style={{width: '100%'}} alt="Create Importer Full"/> :
                                <HowItWorksRow
                                    info={[
                                        {header: "Customize your importer", body: <img src={ImportStep1} alt="Customize Importer Illustrator" width="100%"/>},
                                        {header: "Users upload spreadsheets", body: <img src={ImportStep2} alt="User uploading spreadsheets" width="100%"/>},
                                        {header: "Use imported data", body: <img src={ImportStep3} alt="User imported data" width="100%"/>},
                                    ]}
                                />
                        }
                    </div>
                </div>
                <div style={{textAlign: 'center', paddingBottom: "60px"}}>
                    <GunaldiCTA importer/>
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon1}
                        img={ImporterFeature1}
                        header="Create a Data Model"
                        category="customize your importer"
                        desc="Your data model will standardize the column names, and clean the values of each imported file"
                        textLocation="left"
                    />
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon2}
                        img={ImporterFeature2}
                        header="Customize Importer Appearance"
                        category="customize your importer"
                        desc="Configure importer color, success & error messages, allowed file extensions and much more with no-code"
                        textLocation="right"
                    />
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon3}
                        img={ImporterFeature3}
                        header="Save in Multiple Destinations"
                        category="customize your importer"
                        desc="Select all destinations an imported file and its data should be saved. No code needed"
                        textLocation="left"
                    />
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon5}
                        img={ImporterFeature5}
                        header="Copy & Paste Pre-Written Code"
                        category="users upload spreadsheets"
                        desc="Insert the importer on your website by copying and pasting our already built libraries"
                        textLocation="right"
                    />
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon4}
                        img={ImporterFeature4}
                        header="Share Importer URL"
                        category="users upload spreadsheets"
                        desc="You can share the importer hosted on our website with your customers and users. No code needed"
                        textLocation="left"
                    />
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon6}
                        img={ImporterFeature6}
                        header="Map Columns to Data Model"
                        category="users upload spreadsheets"
                        desc="Our algorithm automatically matches columns in an imported file to the data model"
                        textLocation="right"
                    />
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon7}
                        img={ImporterFeature7}
                        header="Connect Your Webhook"
                        category="users upload spreadsheets"
                        desc="A Webhook (your own REST API URL) can get called after each import. The world is your oyster after connecting a Webhook"
                        textLocation="left"
                    />
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon8}
                        img={ImporterFeature8}
                        header="Access Data with API"
                        category="users upload spreadsheets"
                        desc={<span>Interact with data imported from spreadsheets via our industry leading REST API. <a href="/">Read all about our API here</a></span>}
                        textLocation="right"
                    />
                </div>
                <div style={{textAlign: 'center', paddingBottom: "120px"}}>
                    <GunaldiCTA importer/>
                </div>
                <div>
                    <div style={{textAlign: "center", paddingBottom: "16px", color: "#161E16", fontWeight: "700", fontFamily: "Lato", fontSize: "32px"}}>
                        Use Cases
                    </div>
                    <div style={{fontFamily: "Lato", fontWeight: "400", color: "#5E645E", fontSize: "14px", textAlign: "center", marginBottom: "32px"}}>
                        Some of our most popular use cases. You can view more use cases <a href="/tutorials" target="_blank">here</a>
                    </div>
                    <div className="row" style={{marginBottom: "32px"}}>
                        <div className="col-sm-4">
                            <a href="/tutorials/collect-client-spreadsheets-no-code.html" target="_blank" className="nostyle">
                                <FeatureCard
                                    img={Apps}
                                    header="no-code import"
                                    desc="Collect spreadsheets from your clients with absolutely no code"
                                />
                            </a>
                        </div>
                        <div className="col-sm-4">
                            <a href="/tutorials/import-spreadsheets-on-your-apps.html" target="_blank" className="nostyle">
                                <FeatureCard
                                    img={ImporterUseCase}
                                    header="install importer"
                                    desc="Add a spreadsheet importer on your website and apps in minutes"
                                />
                            </a>
                        </div>
                        <div className="col-sm-4">
                            <a href="/tutorials/insert-data-from-spreadsheets-to-postgres.html" target="_blank" className="nostyle">
                                <FeatureCard
                                    img={integrate}
                                    header="integrate importer"
                                    desc="Save data from imported spreadsheets to your database and more"
                                />
                            </a>
                        </div>
                    </div>
                    <div style={{fontFamily: "Lato", fontWeight: "400", color: "#5E645E", fontSize: "14px", textAlign: "center", marginTop: "48px"}}>
                        If your use case is not listed and you want to find out if we can support it, email us at <a href="mailto:sales@apispreadsheets.com">sales@apispreadsheets.com</a>
                    </div>
                </div>
                <div style={{marginTop: "160px", padding: "80px 100px 80px 100px", backgroundColor:gunaldiStyles.subOrange, color: gunaldiStyles.mainText}}>
                    <div style={{textAlign: "center", paddingBottom: "32px", fontWeight: "700", fontFamily: "Lato", fontSize: "32px"}}>
                        Security
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <FeatureCard
                                img={Encrypt}
                                header="data-encryption"
                                desc="Your data is encrypted in-transit using HTTPS/SSL and at rest with AWS"
                            />
                        </div>
                        <div className="col-sm-4">
                            <FeatureCard
                                img={gdpr}
                                header="GDPR-compliant"
                                desc="We will never use your data maliciously as spelled out in our Terms of Service"
                            />
                        </div>
                        <div className="col-sm-4">
                            <FeatureCard
                                img={Prem}
                                header="on-prem-install"
                                desc="We offer on-prem install and can easily execute a Data Processor agreement"
                            />
                        </div>
                    </div>
                    {/*<div style={{textAlign: "center", padding: "40px 0 40px 0"}}>*/}
                        {/*<a href="/guides/api-data-security" target="_blank" rel="noopener noreferrer" style={{color: "white", textDecoration: "none", fontSize: "12px", fontFamily: "Lato"}}>Read our full guide on API security <Icon name="arrow right" /></a>*/}
                    {/*</div>*/}
                </div>
                <div style={{textAlign: 'center', margin: "80px 0 80px 0"}}>
                    <GunaldiCTA importer/>
                </div>
                <div style={{margin: "80px 120px 80px 100px"}}>
                    <RequestDemoSegment importer/>
                </div>
            </div>
        )
    }
}

export default ImporterBrowserLandingPageIndex