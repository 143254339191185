import React, {Component} from 'react'
import { Icon, Segment } from 'semantic-ui-react'

class SimpleInterfaceBox extends Component {
    render() {
        return (
            <Segment raised className="simpleInterfaceBox">
                <h4 style={{textAlign: 'center', fontWeight: 'bold'}}>{this.props.header}</h4>
                <h1 style={{textAlign: 'center'}}><Icon name={this.props.icon} style={{color: this.props.color}} className="landingIcon"/></h1>
                <hr/>
                <div className="simpleInterfaceBoxBody">
                    {this.props.body}
                </div>
            </Segment>
        )
    }
}

export default SimpleInterfaceBox