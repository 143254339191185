import React, {Component} from 'react'
import FAQMenu from "./FAQMenu";
import FAQHeader from "./FAQHeader";
import FAQBlock from "./FAQBlock";
import {reshapeFAQs} from "./reshapeFAQs";
import {removePunctuation} from "../removePunctuation";

class FAQDisplayGrid extends Component {
    constructor(props){
        super(props);

        const reshapedFAQsObject = reshapeFAQs(this.props.helpInfo)

        this.state = {
            menuItem: this.props.helpInfo[0].info.name,
            idx: 0,
            searchText: "",
            faqTexts: reshapedFAQsObject.texts,
            faqLocations: reshapedFAQsObject.locations,
            searchFAQs: []
        }

        this.changeMenuItem = this.changeMenuItem.bind(this);
    }

    changeMenuItem(menuItem, idx){
        this.setState({
            menuItem: menuItem,
            idx: idx
        })
    }

    isSearching = (searchText) => { return searchText.trim() !== ""}

    modifySearchText = (searchText) =>
    {
        this.setState({ searchText: searchText })

        if (this.isSearching(searchText)){
            this.searchFAQs(searchText)
        }
    }

    searchFAQs = (searchText) => {
        const searchArray = removePunctuation(searchText.toString().trim().toLowerCase()).split(' ')
        let FAQs = [];
        let FAQsAlreadyInList = [];

        for (let i=0; i<this.state.faqTexts.length; i++){
            let faqText = this.state.faqTexts[i];

            for (let j=0; j<searchArray.length; j++){
                if (faqText.includes(searchArray[j])){
                    let faqLocation = this.state.faqLocations[i];
                    let faqLocationString = faqLocation.join("")

                    if (!FAQsAlreadyInList.includes(faqLocationString)){
                        FAQsAlreadyInList.push(faqLocationString)
                        FAQs.push(this.props.helpInfo[faqLocation[0]].faq[faqLocation[1]])
                    }
                }
            }
        }

        this.setState({
            searchFAQs: FAQs
        })
    }

    render() {
        return (
            <div className="menuDisplayGrid">
                <div>
                    <FAQMenu
                        menuItem={this.state.menuItem}
                        helpInfo={this.props.helpInfo}
                        changeMenuItem={this.changeMenuItem}
                        searchText={this.state.searchText}
                        modifySearchText={this.modifySearchText}
                    />
                </div>
                <div>
                    {
                        this.isSearching(this.state.searchText) ? <div id="faqDisplayHeader"> </div> :
                            <FAQHeader helpInfoObj={this.props.helpInfo[this.state.idx].info}/>
                    }
                    <FAQBlock
                            FAQs={this.isSearching(this.state.searchText) ? this.state.searchFAQs : this.props.helpInfo[this.state.idx].faq}
                    />
                </div>
            </div>
        )
    }
}

export default FAQDisplayGrid