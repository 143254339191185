import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import { Icon } from 'semantic-ui-react'
import WhitelistDomainsModalBody from "./WhitelistDomainsModalBody"

class WhitelistDomainsModal extends Component {
    render() {
        return (
            <ModalApp name="whitelistDomainsModal"
                      header={<h2><Icon name="unlock alternate"/> Whitelist Domains</h2>}
                      size="medium"
                      body={<WhitelistDomainsModalBody/>}
            />
        )
    }
}

export default WhitelistDomainsModal