import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'

class ChangeRowLength extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.undoRowSelection = this.undoRowSelection.bind(this);
    }

    handleInputChange(e){
        if (e.target.value <= this.props.rowLen
            && this.props.rowLen >= 0 && this.props.rowLen % 1 === 0){
            this.props.changeState("rows", e.target.value)
        }
    }

    undoRowSelection(e){
        this.props.changeState("rows", this.props.rowLen)
    }

    render() {
        if (this.props.apiType === "readAction"){
            return (
                <span>
                    <label style={{marginRight: '5px'}}><b>Rows </b></label>
                    <input type="number"
                           value={this.props.rows}
                           onChange={this.handleInputChange}
                           style={{marginTop: '-12px', marginRight: '10px', width: '50%'}}
                           max={this.props.rowLen}
                           min={0}
                           step={1}
                    />
                    <Popup
                        trigger={<Button circular
                                         icon="undo"
                                         color="red"
                                         onClick={this.undoRowSelection}
                                         size="mini"
                                         style={{float: "right"}}
                                         basic
                        />}
                        content="Reset to Full"
                    />
                </span>
            )
        }
        else{
            return (
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ChangeRowLength)