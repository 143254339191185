import React, {Component} from 'react'
import WebhookBodyParameters from "./WebhookBodyParmeters";
import WebhookResponseBody from "./WebhookResponseBody";

class WebhookBodyIndex extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-6">
                    <WebhookBodyParameters setBothMenuItems={this.props.setBothMenuItems} eventType={this.props.eventType}/>
                </div>
                <div className="col-sm-6">
                    <WebhookResponseBody setBothMenuItems={this.props.setBothMenuItems} eventType={this.props.eventType}/>
                </div>
            </div>
        )
    }
}

export default WebhookBodyIndex