import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react';
import pricing from "../../Constants/Pricing/Pricing";
import FilesTabFileSource from "../../FileDnD/FilesTabFileSource";
import FilesTabFileSourceNames from "../../FileDnD/FilesTabFileSourceNames";
import FileRowErrorContent from "../../FileDnD/FileRowErrorContent"
import API_Root from "../../Constants/API_Root";
import {getFileLimitMessage} from "../../Constants/getFileLimitMessage";
import {isUserAMacroUser} from "../../Constants/isUserAMacroUser";
import {isFileInLazyMode} from "../../Constants/isFileInLazyMode";

class TeamFilesRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accordionShow: false
        }

    }

    getCreatedDt = (dt) => {
        // if (dt.toString().length >= 10){
        //     return dt.toString().substring(0, 10)
        // } else {
        //     return dt.toString()
        // }
        return dt
    }

    goToFile = (e, disable) => {
        let classNames;
        try {
            classNames = e.target.className
        } catch(err) {
            classNames = ""
        }

        if (classNames === null){
            classNames = ""
        }

        if (disable === null){
            if (classNames.indexOf("fileIDLabelIcon") === -1 && classNames.indexOf("fileTabRemoveFileBtn") === -1){
                this.filesTabFileSummaryLink.click()
            }
        }
    }

    toggleAccordion = () => {
        const currShow = this.state.accordionShow;

        this.setState({ accordionShow: !currShow })
    }

    getURL = (fileHash) => {
        let url = (API_Root.includes("api-woyera") ? "https://www.apispreadsheets.com" : "http://localhost:5000") + "/files/" + fileHash

        if ("hash" in this.props && "prevPage" in this.props){
           url +=  "?" + this.props.prevPage + "=" + this.props.hash.toString()
        }

        return url
    }

    render() {
        const fileInformation =  this.props.fileInformation;
        let fileName = fileInformation.name;
        const isExcel = fileName.split('.').pop() !== "csv" && fileName.split('.').pop() !== "txt";

        const proBlock = fileInformation.proBlock

        const fileDivStyle = {
            borderTop:'1px solid #F0F0F0',
            borderBottom:'1px solid #F0F0F0',
            margin: '0px 0px',
            // height:'70px',
            // marginTop: '20px',
            cursor:'pointer',
            padding: "10px"
        };
        let disable = null;
        let allBlocks = [];

        if (proBlock){
            disable = true
            const message = <span style={{color: "red"}}><Icon name="warning sign" /> <span style={{paddingLeft: "2px"}}>{getFileLimitMessage([], -1, this.props.tier, this.props.appsumoPlanName, this.props.isGrandfather, fileInformation.proBlockTooManyFiles)}</span></span>
            allBlocks.push({type: "recordsUpgrade", message: message, content: null, action: null})
        }
        else{
            if (fileInformation.fixColumnNames && !isFileInLazyMode(fileInformation) && !isUserAMacroUser(this.props.fullPricingInfo)){
                disable = true

                allBlocks.push({
                    type: "fixColumnNames",
                    message: <span style={{color: "red"}}><Icon name="columns" /> <span style={{paddingLeft: "2px"}}>Column Names in your file were not valid</span></span>,
                    action: null,
                    content: null
                })
            }
        }
        const sheetName = isExcel ? <div style={{color: allBlocks.length > 0 ? "red" : "gray"}}><small>{fileInformation.sheetRead}</small></div> : null;

        let rowClassName;
        if (allBlocks.length > 0){
            delete fileDivStyle['cursor']
            rowClassName = "fileErrorRow"
        } else {
            rowClassName = "teamFilesRowHeader filesTabFileSummaryRow"
        }

        if (disable){
            let disableMessage = null;
            let actionsButton = "";
            let content = null;

            if (allBlocks.length === 1){
                disableMessage = allBlocks[0].message
                actionsButton = allBlocks[0].action
                content = allBlocks[0].content
            } else if (allBlocks.length > 1){
                disableMessage = <span style={{color: "red"}}><Icon name="warning circle" /> <span style={{paddingLeft: "2px"}}>{allBlocks.length.toString()} file issues found</span></span>
                actionsButton = null
                content = <FileRowErrorContent allBlocks={allBlocks} />
            }

            if (actionsButton === null){
                actionsButton = <button style={{padding: "6px 12px 6px 16px", border: "1px solid #9696A0", cursor: "pointer", fontFamily: "Lato", fontWeight: "400", fontSize: "14px", borderRadius: "4px", backgroundColor: "white", color: "#9696A0"}} onClick={this.toggleAccordion}><span style={{paddingRight: "6px"}}>{this.state.accordionShow ? "Hide" : "View"} Issue{allBlocks.length > 1 ? "s" : ""}</span> <Icon name={this.state.accordionShow ? "chevron up" : "chevron down"} /> </button>
            } else if (actionsButton === ""){
                actionsButton = null
            }

            let accordionBody = null
            if (content !== null){
                if (this.state.accordionShow){
                    // content
                    accordionBody =
                        <div style={{backgroundColor: "#F9F9F9", padding: "24px 0px 24px 24px"}}>
                            {content}
                        </div>
                }
            }

            return (
                <div style={fileDivStyle}>
                    <div className={rowClassName}>
                        <div>
                            <div className="fileRowFileSource">
                                <div>
                                    <FilesTabFileSource fileInformation={fileInformation} />
                                </div>
                                <div className="ellipses">
                                    <span style={{color: "red", fontFamily:'Lato',fontWeight:'600',fontSize:'16px'}}>{fileName}</span>
                                    {sheetName}
                                </div>
                            </div>
                        </div>
                        <div style={{position:'static',height:'44px',float:'left',fontStyle:'normal',fontFamily:'Lato',fontWeight:'400',fontSize:'14px',color:'#5E645E'}}>
                            {this.getCreatedDt(fileInformation['createdDt'])}
                        </div>
                        <div>
                            <div style={{display: "grid", gridTemplateColumns: "60% 40%"}}>
                                <div>
                                    {disableMessage}
                                </div>
                                <div style={{textAlign: "right"}}>
                                    <div style={{position:'static',height:'44px',display:'inline-block', marginRight: "15px"}}>
                                        {actionsButton}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {accordionBody}
                </div>
            )
        } else{
            return (
                <div style={fileDivStyle} className={rowClassName} onClick={e => this.goToFile(e, disable)}>
                    <a style={{display: "none"}} href={this.getURL(fileInformation.fileHash)} ref={filesTabFileSummaryLink => this.filesTabFileSummaryLink = filesTabFileSummaryLink}> </a>
                    <div>
                        <div className="fileRowFileSource">
                            <div>
                                <FilesTabFileSource fileInformation={fileInformation} />
                            </div>
                            {/*<div className="wordwrap wordwrap2">*/}
                            <div className="ellipses">
                                <span style={{color: "#161E16;", fontFamily:'Lato',fontWeight:'600',fontSize:'16px', paddingRight: "4px"}}>{fileName}</span>
                                {sheetName}
                            </div>
                        </div>
                    </div>
                    <div style={{position:'static',height:'44px',float:'left',fontStyle:'normal',fontFamily:'Lato',fontWeight:'400',fontSize:'14px',color:'#5E645E'}}>
                        {this.getCreatedDt(fileInformation['createdDt'])}
                    </div>
                    <div style={{fontStyle:'normal',textAlign:'left',fontFamily:'Lato',fontWeight:'700',fontSize:'14px', paddingLeft: "2px"}}>
                        <FilesTabFileSourceNames fileInformation={fileInformation}/>
                    </div>
                    <div style={{textAlign: "right"}}>
                        <div style={{position:'static',height:'44px',display:'inline-block', marginRight: "15px"}}>
                            <button style={{width:'84px',height:'36px',backgroundColor:'#FFFFFF',border:'1px solid #6FBB98',borderRadius:'4px'}}> <a style={{textDecoration:'none',color:'#6FBB98'}} href={this.getURL(fileInformation.fileHash)}> Details </a> </button>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier,
    appsumoPlanName: state.mainState.appsumoPlanName,
    isGrandfather: state.mainState.isGrandfather,
    fullPricingInfo: state.mainState.fullPricingInfo
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(TeamFilesRow)