import React, {Component} from 'react'
import {connect} from 'react-redux'
import ImportInfoRow from "./ImportInfoRow";
import {convertServerDateToDisplayDate} from "../../../../Constants/convertServerDateToDisplayDate";
import { Icon } from 'semantic-ui-react'
import {toggleToast} from "../../../../Actions/ToggleToast";
import DisplayHeader from "../../../../FileDnD/FilesID/DisplayHeader";

class ImportInfoIndex extends Component {
    copyKey = (e) => {
        // e.target.focus();
        this.customImportHashRef.select();
        document.execCommand("copy")
        window.getSelection().removeAllRanges();

        this.props.toggleToast({show: true, message: "Import Key Copied!", type: "success"})
    }

    copy = (copyText) => {
        navigator.clipboard.writeText(copyText)
        this.props.toggleToast({show: true, message: "Importer Key Copied", type: "success"})
    }

    render() {
        const labelStyle = {fontFamily: "Lato", fontWeight: '500', fontSize: "14px", color: "#5E645E", marginTop: "16px"}
        const resultStyle = {fontFamily: "Lato", fontWeight: '500', fontSize: "14px", marginTop: "16.5px"}

        return (
            <div className="filesIDDisplayBox">
                <div style={{marginBottom: "16px"}}>
                    <DisplayHeader header="Importer Info"/>
                </div>
                <div style={{display: "grid", gridTemplateColumns: "274px auto", columnGap: "24px"}}>
                    <div>
                        <div style={labelStyle}>
                            Importer Key
                        </div>
                        <div style={labelStyle}>
                            Created On
                        </div>
                    </div>
                    <div>
                        <div style={resultStyle}>
                            <Icon name="copy outline"
                                  className="fileIDLabelIcon"
                                  color="green"
                                  onClick={() => this.copy(this.props.importHash)}
                            />
                            {this.props.importHash}
                        </div>
                        <div style={resultStyle}>
                            {convertServerDateToDisplayDate(this.props.info.createdDt)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    info: state.mainState.customImportInfo
})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(ImportInfoIndex)