import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Checkbox, Dropdown} from "semantic-ui-react";
import {modifyCustomImportSettings} from "../../../../../Actions/ModifyCustomImportSettings";
import TestingCleanFunctions from "../TestingCleanFunctions";
import {gunaldiStyles} from "../../../../../SharedComponents/GunaldiDesignStyles";

class DatetimeCleaningFunctions extends Component {
    constructor(props) {
        super(props);
        this.state={
            setValue:''

        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event, {value}){
        this.props.handleInputChange(this.props.idx, value)
    }
    getColumnInfo = () => {
        return this.props.customImportColumnsAddlInfo[this.props.columnIdx]
    }


    changeDatetime = () => {
        const allColumnInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))
        const columnInfo = allColumnInfo[this.props.columnIdx]
        const cleaningFunctions = columnInfo['cleaningFunctions'];

        if ("convertDatetime" in cleaningFunctions){
            delete cleaningFunctions['convertDatetime']
        } else {
            cleaningFunctions['convertDatetime'] = "%Y-%m-%d"
        }

        columnInfo['cleaningFunctions'] = cleaningFunctions
        allColumnInfo[this.props.columnIdx] = columnInfo

        this.props.modifyCustomImportSettings("columnsAddlInfo", allColumnInfo)
    }
    changeDatetimeType = (e,{value}) => {
        const allColumnInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))
        const columnInfo = allColumnInfo[this.props.columnIdx]
        const cleaningFunctions = columnInfo['cleaningFunctions'];

        if ("convertDatetime" in cleaningFunctions){
            cleaningFunctions['convertDatetime'] = value;
        }else{
            cleaningFunctions['covertDatetime'] = "%Y-%m-%d"
        }

        columnInfo['cleaningFunctions'] = cleaningFunctions
        allColumnInfo[this.props.columnIdx] = columnInfo

        this.props.modifyCustomImportSettings("columnsAddlInfo", allColumnInfo)

    }

    render() {
        const options = [
            {key:0,text:"YYYY-MM-DD",value:"%Y-%m-%d"},
            {key:1,text:"YYYY-MM-DD HH:MM:SS",value:"%Y-%m-%d %H:%M:%S"},
            {key:2,text:"MM/DD/YYYY",value:"%m/%d/%Y"},
            {key:3,text:"DD/MM/YYYY",value:"%d/%m/%Y"},
            {key:4,text:"DD Month YYYY",value:"%d %B %Y"},
            {key:5,text:"Mon DD, YYYY",value:"%b %d, %Y"},
            {key:6,text:"DD-Mon-YYYY",value:"%d-%b-%Y"},
            {key:7,text:"Mon-DD-YYYY",value:"%b-%d-%Y"},
            {key:8,text:"HH:MM",value:"%H:%M"},
            {key:9,text:"H:MM AM/PM",value:"%I:%M %p"},
            {key:10,text:"Mon DD, YYYY H:MM AM/PM",value:"%b %d, %Y %I:%M %p"},

        ]

        let info = this.getColumnInfo()
        let cleaningFunctions = info.cleaningFunctions;
        let labelStyle = {fontSize: "14px", color: gunaldiStyles.mainText, fontWeight: "400"};
        // if checked convertDatetime: "YYYY-MM-DD"
        // otherwise no key called convertDatetime
        return (
            <div>
                <Checkbox
                    onChange={this.changeDatetime}
                    name="convertDatetime"
                    label={<label style={labelStyle}>Convert Datetime </label>}
                />
                {   'convertDatetime' in cleaningFunctions ?
                    <div>
                        <Dropdown
                            onChange={this.changeDatetimeType}
                            options={options}
                            placeholder="Choose an option"
                            value={cleaningFunctions['convertDatetime']}
                            selection
                        />
                        <TestingCleanFunctions cleaningFunctions={cleaningFunctions} columnType={"datetime"}/>
                    </div>:null

                }


            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    customImportColumnsAddlInfo: state.mainState.customImportColumnsAddlInfo,
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(DatetimeCleaningFunctions)