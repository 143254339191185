import React, {Component} from 'react'
import {connect} from 'react-redux'
import TierLabel from "../../../SharedComponents/TierLabel";
import pricing from "../../../Constants/Pricing/Pricing";

class MenuSegment extends Component {
    constructor(props) {
        super(props);
    }

    selectMenuSegment = (e) => {
        this.props.onClick(e, this.props.name)
    }

    shouldDisplayTier = () => {
        let allTiers = [];
        try{
            allTiers = pricing.map(x => x.name)
        } catch (e) {
            allTiers = []
        }

        let display = true;
        if (this.props.reduxTier === "enterprise"){
            display = false
        } else {
            if (allTiers.includes(this.props.reduxTier) && allTiers.includes(this.props.tier)){
                const userTierIdx = allTiers.indexOf(this.props.reduxTier)
                const labelTierIdx = allTiers.indexOf(this.props.tier)

                display = userTierIdx < labelTierIdx
            }
        }

        return display
    }

    render() {
        let tierLabel = null;

        if ("isNew" in this.props){
            tierLabel = <span style={{paddingRight: "17.29px"}}><TierLabel tier="new"/></span>
        } else if ("isBeta" in this.props){
            console.log("is beta")
            tierLabel = <span style={{paddingRight: "17.29px"}}><TierLabel tier="beta"/></span>
        } else {
            if ("tier" in this.props){
                if (this.shouldDisplayTier()){
                    tierLabel = <span style={{paddingRight: "17.29px"}}><TierLabel tier={this.props.tier}/></span>
                }
            }
        }

        let id = ""
        if ("menuItem" in this.props){
            if (this.props.menuItem === this.props.name){
                if ('importer' in this.props){
                    id = "importerMenuSegmentSelected"
                } else {
                    id = "menuSegmentSelected"
                }
            }
        }

        return (
            <div className={'importer' in this.props ? "importerMenuSegment" : "menuSegment"} id={id} onClick={this.selectMenuSegment}>
                <div>
                    <i className={"bx bx-" + this.props.icon} style={{fontSize: "20px", verticalAlign: "middle"}} />
                    <span style={{paddingLeft: "12px"}}>{this.props.label}</span>
                </div>
                <div>
                </div>
                <div>
                    {tierLabel}
                    <i className="bx bx-chevron-right 1" style={{fontSize: "20px", textAlign: "right", verticalAlign: "middle"}} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reduxTier: state.mainState.tier
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(MenuSegment)