import React, {Component} from 'react'
import {connect} from 'react-redux'
import Hero from "../SharedComponents/Hero";
import GuidesMenu from './GuidesMenu'
import GuideDetails from "./GuideDetails";
import capitalizeHyphenated from "../Constants/capitalizeHyphenated";
import GuidesDisplayIndex from "./GuidesText/GuidesDisplayIndex";

class GuidesIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            topic: this.getTopicIndex(),
            link: this.getLink(),
        }

        this.getTopicIndex = this.getTopicIndex.bind(this);
        this.getLink = this.getLink.bind(this);
        this.changeHelpMenu = this.changeHelpMenu.bind(this);
    }

    getTopicIndex(){
        const url =  window.location.href
        let param = url.substring(url.indexOf("guides") + 7, url.length);

        for (let i=0; i<GuideDetails.length; i++){
            if (GuideDetails[i].title === param){
                return i
            }
        }

        return 0
    }

    getLink(){
        const topicIndex = this.getTopicIndex();

        return GuideDetails[topicIndex].title
    }


    componentDidMount(){
        document.title = "API Spreadsheets - " + capitalizeHyphenated(GuideDetails[this.state.topic].title);

        document.getElementsByTagName('meta')["description"].content = GuideDetails[this.state.topic].desc;

    }

    changeHelpMenu(topic){
        this.setState({
            link: GuideDetails[topic].title
        }, () => this.downloadLink.click())
    }

    render() {
        const url = "/guides/" + this.state.link

        return (
            <div id="guidesIndex">
                <a
                    style={{display: 'none'}}
                    ref={downloadLink => this.downloadLink = downloadLink}
                    href={url}
                >Download hidden</a>
                <Hero
                    header="Guides"
                    desc="Get started right away"
                    textColor="white"
                    color1="rgb(124,252,0)"
                    color2="rgb(0,128,0)"
                />
                <br/><br/>
                <div className="row">
                    <div className="col-sm-3">
                        <GuidesMenu changeHelpMenu={this.changeHelpMenu} topic={this.state.topic} />
                    </div>
                    <div className="col-sm-9">
                        <div className="row">
                            <div className="col-sm-1" />
                            <div className="col-sm-10">
                                <div className="guidesDisplay">
                                    <GuidesDisplayIndex topic={this.state.topic}/>
                                </div>
                            </div>
                            <div className="col-sm-1" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(GuidesIndex)