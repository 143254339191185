import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react';
import isObjectEmpty from "../../Constants/isObjectEmpty";
import ImportUploadFilesSegment from "./ImportUploadFilesSegment";
import ColumnMatchingIndex from "./ColumnMatching/ColumnMatchingIndex"
import {getValueFromInfoObject} from "./getValueFromInfoObject";
import DisplayHeader from "../../FileDnD/FilesID/DisplayHeader";
import { isMobile } from 'react-device-detect'
import getColumnMatchedValues from "../getColumnMatchedValues";

class ImportUploadFilesDisplay extends Component {
    needToMatchColumns = () => {
        const matchColumns = this.props.info.importOptions.matchColumns

        let columnNames;
        try{
            columnNames = this.props.info.columns.columns
        } catch (e){
            columnNames = []
        }

        return matchColumns && columnNames.length > 0
    }

    showFileUploads = () => {
        let show;
        try{
            let importSheetsUploadPK = this.props.importSheetsUploadPK;
            let importSheetsFileInformation = this.props.importSheetsFileInformation;

            const matchedArray = getColumnMatchedValues(importSheetsUploadPK, importSheetsFileInformation)
            if (this.needToMatchColumns() && matchedArray.includes(false)){
                show = true
            } else {
                try{
                    show = this.props.info.appearance.showFileUploads;

                    if (this.props.matchColumns){
                        show = true
                    }
                } catch (e){
                    show = true
                }
            }
        } catch (e){
            show = true
        }

        return show
    }

    render() {
        if (this.showFileUploads()){
            if (!isObjectEmpty(this.props.importSheetsFileInformation) && this.props.importSheetsUploadPK.length > 0){
                let uploadedFiles = null;
                let header = null;

                if (!this.props.matchColumns){
                    // let shouldShow = getValueFromInfoObject("showFileUploads", this.props.info, "appearance")
                    // if (shouldShow === null){
                    let shouldShow = true
                    // }

                    if (shouldShow){
                        uploadedFiles = []
                        const colLengths = ["6","3", "1", "2"];

                        for (let i=0; i<this.props.importSheetsUploadPK.length; i++){
                            const pkID = this.props.importSheetsUploadPK[i]['id'];
                            const pk = this.props.importSheetsUploadPK[i]['pk'];

                            if (pk in this.props.importSheetsFileInformation || pkID in this.props.importSheetsFileInformation){
                                uploadedFiles.push(
                                    <div key={pk.toString() + i.toString()}>
                                        <ImportUploadFilesSegment
                                            idx={i}
                                            pk={pk}
                                            pkID={pkID}
                                            colLengths={colLengths}
                                            textPadding="10"
                                            importInfo={this.props.info}
                                            importKey={this.props.importKey}
                                        />
                                    </div>
                                )
                            }
                        }

                        const headerStyle = {fontFamily: 'Lato', fontWeight: '700', fontSize: '14px', color: '#161E16'}
                        header =
                            <div style={{margin: "32px 60px 0 60px"}}>
                                <div style={{marginBottom: "16px"}}>
                                    <DisplayHeader header="Uploaded Files" icon="file alternate outline"/>
                                </div>
                                <div className={isMobile ? "" : "importUploadFilesSegmentDiv"} style={{borderBottom: "1px solid #F0F0F0"}}>
                                    <div style={headerStyle}>File Name</div>
                                    <div style={headerStyle}>File ID</div>
                                    <div style={headerStyle}>Description</div>
                                    <div> </div>
                                    <div> </div>
                                </div>
                            </div>
                    }
                } else {
                    header = null
                    uploadedFiles = <ColumnMatchingIndex userEmail={this.props.info.userEmail} importKey={this.props.importKey}/>
                }

                return (
                    <div>
                        {header}
                        {uploadedFiles}
                    </div>
                )
            } else {
                return null
            }
        } else {
            return null
        }

    }
}

const mapStateToProps = (state) => ({
    importSheetsFileInformation: state.mainState.importSheetsFileInformation,
    importSheetsUploadPK: state.mainState.importSheetsUploadPK,
    matchColumns: state.mainState.customImportMatchColumns,
    shouldEdit: state.mainState.customImportShouldEdit
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(ImportUploadFilesDisplay)