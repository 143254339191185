import React, {Component} from 'react'
import PageHeader from "../../SharedComponents/PageHeader";
import DemoVideosSection from "./DemoVideosSection";
import VideoModal from "./VideoModal"

class DemoVideoIndex extends Component {
    render() {
        return (
            <div>
                <VideoModal />
                <PageHeader
                    header="Demo Videos"
                    desc="Learn how to use API Spreadsheets' features by watching these videos"
                    isImporter={'importer' in this.props}
                    tiny
                />
                <div style={{padding: "32px 32px 120px 32px"}}>
                    {
                        this.props.videos.map((x, idx) => <DemoVideosSection header={x.topic} desc={x.desc} videos={x.videos} key={idx}/>)
                    }
                </div>
            </div>
        )
    }
}

export default DemoVideoIndex