import React, {Component} from 'react'
import {connect} from 'react-redux'
import currencyInfo from "../../Constants/currencyInfo";
import {getPricingText} from "../../Constants/getPricingText";
import {gunaldiStyles} from "../GunaldiDesignStyles";

class YearlySavedAmount extends Component {
    getPriceSaved = () => {
        const monthlyPrice = getPricingText(this.props.currency, this.props.planIdx, true, null, false)
        const yearlyPrice = getPricingText(this.props.currency, this.props.planIdx, true, null, true)

        return currencyInfo[this.props.currency].symbol + ((monthlyPrice - yearlyPrice) * 12).toString()
    }

    render() {
        return (
            <div style={{backgroundColor: gunaldiStyles.mainText, color: "white", padding: "16px", fontSize: "16px", fontWeight: "700"}}>
                Saving <b>{this.getPriceSaved()}</b> per year!
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(YearlySavedAmount)