import React, {Component} from 'react'
// import PricingPopupBody from "../Pricing/PricingPopupBody"
import UploadSources from "../HowItWorks/Features/UploadSources.svg"
// const pricingPopup = <Popup flowing hoverable content={<PricingPopupBody/>} trigger={<span className="linkStyle">plan</span> }/>

class Upload extends Component {
    render() {
        return (
            <div className="logoGrid">
                <h1>Upload Spreadsheets from Multiple Sources</h1>
                <div>
                    <span>Google Sheets</span>
                    <span>Local Files</span>
                    <span>Dropbox</span>
                </div>
                <img src={UploadSources} style={{width: '100%', height: 'auto'}} alt="Different Upload Sources" />
                {/*<span>*/}
                    {/*Power Your Apps*/}
                {/*</span>*/}
            </div>
        )
    }
}

export default Upload