import React, {Component} from 'react'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import API_Backend_Root from "../../Constants/API_Backend_Root";

class DownloadResults extends Component {
    render() {
        return (
            <div style={{ marginTop: "8px"}}>
                <div style={{ border: "1px solid #F0F0F0", borderRadius: "8px", padding: "12px"}}>
                    <div style={{ fontSize: "16px", fontWeight: "700", color: gunaldiStyles.subText }}>Get Download Link for .XLSX file</div>
                    <div style={{ fontSize: "12px", color: gunaldiStyles.subText }}>Click on link to download your file</div>
                    <div style={{ marginTop: "12px"}}>
                        <pre id="apiFeatureModalURLBox">
                            <div style={{ fontWeight: "700"}}>downloadUrl</div>
                            <div>https://download-link.com/?security=true</div>
                        </pre>
                    </div>
                </div>
            </div>
        )
    }
}

export default DownloadResults