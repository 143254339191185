import React, {Component} from 'react'
import ContactUsForm from './ContactUsForm'
import Hero from "../../SharedComponents/Hero"

class ContactUsIndex extends Component {
    render() {
        return (
            <div style={{minHeight: '100vh'}}>
                <Hero
                    header="Contact Us"
                    desc="Tell us what's on your mind"
                    textColor="white"
                    color1="rgb(124,252,0)"
                    color2="rgb(0,128,0)"
                />
                <div className="row">
                    <div className="col-sm-3" />
                    <div className="col-sm-6">
                        <br/><br/>
                        <ContactUsForm/>
                    </div>
                    <div className="col-sm-3" />
                </div>
            </div>
        )
    }
}

export default ContactUsIndex