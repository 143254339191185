import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import ColumnNamesBody from './ColumnNamesBody'

class ColumnNamesModal extends Component {
    render() {
        return (
            <ModalApp name="columnNamesModal"
                      header={null}
                      size="large"
                      body={<ColumnNamesBody/>}
            />
        )
    }
}

export default ColumnNamesModal