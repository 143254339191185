import React, {Component} from 'react'
import {connect} from 'react-redux'
import InfoPopup from "../../../../SharedComponents/InfoPopup"
import { Input } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import {Dropdown} from "semantic-ui-react";
import ColumnAdditionalInfoModalBodyDropdown from "./ColumnAdditionalInfoModalBodyCleaningFunctionsIndex";
import ColumnAdditionalInfoModalBodyCleaningFunctionsIndex from "./ColumnAdditionalInfoModalBodyCleaningFunctionsIndex";
import TestingImportButton from "./TestingCleanFunctions";
import CustomAccordion from "../../../../SharedComponents/CustomAccordion"
import ColumnRowHeader from "./ColumnRowHeader";
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";

class ColumnAdditionalInfoModalBody extends Component {
    getColumnIdx = () => {
        // let columnIdx = -1;
        //
        // if (this.props.modalInfo !== null){
        //     if ("columnIdx" in this.props.modalInfo){
        //         columnIdx = this.props.modalInfo.columnIdx;
        //     }
        // }

        return this.props.idx
    }

    handleInputChange = (e) => {
        const columnIdx = this.getColumnIdx()
        const allColumnAddlInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))

        const columnAddlInfo = allColumnAddlInfo[columnIdx]
        columnAddlInfo[e.target.name] = e.target.value

        allColumnAddlInfo[columnIdx] = columnAddlInfo

        this.props.modifyCustomImportSettings("columnsAddlInfo", allColumnAddlInfo)
    }

    render() {
        const columnIdx = this.getColumnIdx()

        if (columnIdx !== -1){
            const columnAddlInfo = this.props.customImportColumnsAddlInfo[columnIdx]
            const dataType = columnAddlInfo.dataType;

            return (
                <div>
                    {
                        dataType === "text" || dataType === "numerical" || dataType === "datetime" ?
                            <CustomAccordion
                                body={<ColumnAdditionalInfoModalBodyCleaningFunctionsIndex columnIdx={columnIdx} dataType={dataType}/>}
                                header="Cleaning Functions"
                                accordionStyle={{
                                    borderRadius: '4px',
                                    border: "1px solid #F0F0F0",
                                    backgroundColor: 'white'
                                    // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                                }}
                                headerDivStyle={{
                                    fontSize: "14px",
                                    color: gunaldiStyles.mainText,
                                    borderRadius: '4px',
                                    backgroundColor: 'white',
                                    fontWeight: '700',
                                    fontFamily: "Lato",
                                    padding: "8px"
                                }}
                            /> : null
                    }
                    <div style={{marginTop: "8px", marginBottom: "8px"}}>
                        <CustomAccordion
                            body={<div>
                                <ColumnRowHeader header="Description" info="Description of the column. The user will be able to see this while matching"/>
                                <div style={{marginBottom: "8px"}}>
                                    <input onChange={this.handleInputChange} value={columnAddlInfo.description} name="description" className="inputGray" style={{width: "100%"}} placeholder="First name column should only have first names"/>
                                </div>
                                <ColumnRowHeader header="Tags" info="Other names that will help our matching algorithm. Enter them separated by commas in this format: tag1, tag2,..."/>
                                <div>
                                    <input onChange={this.handleInputChange} value={columnAddlInfo.tags} name="tags" className="inputGray" style={{width: "100%"}} placeholder="f_name, first name, FIRST NAME" />
                                </div>
                            </div>}
                            header="Additional Information"
                            accordionStyle={{
                                borderRadius: '4px',
                                border: "1px solid #F0F0F0",
                                backgroundColor: 'white'
                                // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                            }}
                            headerDivStyle={{
                                fontSize: "14px",
                                color: gunaldiStyles.mainText,
                                borderRadius: '4px',
                                backgroundColor: 'white',
                                fontWeight: '700',
                                fontFamily: "Lato",
                                padding: "8px"
                            }}
                        />
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({
    customImportColumnsAddlInfo: state.mainState.customImportColumnsAddlInfo,
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnAdditionalInfoModalBody)