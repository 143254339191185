import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Input, Button } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";

class ActionsUsernamePassword extends Component {
    handleInputChange = (e) => {
        const currOptionsEdit = JSON.parse(JSON.stringify(this.props.optionsEdit))
        const passwords = currOptionsEdit.passwords;
        passwords[this.props.idx][e.target.name] = e.target.value

        currOptionsEdit.passwords = passwords

        this.props.modifyCustomImportSettings("optionsEdit", currOptionsEdit)
    }

    deleteUsernameAndPassword = () => {
        const currOptionsEdit = JSON.parse(JSON.stringify(this.props.optionsEdit))
        const passwords = currOptionsEdit.passwords;

        passwords.splice(this.props.idx, 1)

        currOptionsEdit.passwords = passwords

        this.props.modifyCustomImportSettings("optionsEdit", currOptionsEdit)
    }

    render() {
        const isSaved = this.props.optionsEdit.passwords[this.props.idx].pk !== -1;

        return (
            <div>
                <div className="row">
                    <div className="col-sm-11">
                        <Input
                            name="username"
                            value={this.props.optionsEdit.passwords[this.props.idx].username}
                            onChange={this.handleInputChange}
                            focus
                            size="mini"
                            fluid
                            disabled={isSaved}
                            icon="user"
                            placeholder="Username"
                        />
                        <Input
                            type={isSaved ? "password" : "text"}
                            name="password"
                            value={this.props.optionsEdit.passwords[this.props.idx].password}
                            onChange={this.handleInputChange}
                            focus
                            size="mini"
                            fluid
                            disabled={isSaved}
                            icon="lock"
                            placeholder="Password"
                        />
                    </div>
                    <div className="col-sm-1">
                        {
                            isSaved ?
                                <div style={{marginLeft: "-20px", marginTop: "10px"}}>
                                    <Button
                                        circular
                                        onClick={this.deleteUsernameAndPassword}
                                        icon="trash alternate outline"
                                        color="red"
                                        basic
                                        size="mini"
                                    />
                                </div> : null
                        }
                    </div>
                </div>
                {
                    isSaved ? <br/> : <small style={{color: 'green'}}><i>Store this password somewhere safe. You will not be able to view this password after you save</i></small>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    optionsEdit: state.mainState.customImportOptionsEdit
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(ActionsUsernamePassword)