import React, {Component} from 'react'
import RestAPI from "../../Illustrations/RestAPI.svg"
import ImporterFeature8 from "../../Illustrations/ImporterFeatures/ImporterFeature8.svg"

class GetAnAPI extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}> Access Imported Data through a REST API </div>
                <div style={this.props.subheadingStyle}>Read data imported from spreadsheets via our industry leading REST API</div>
                <div style={{textAlign: "center"}}>
                    <img src={ImporterFeature8} alt="Server Cluster"  style={{width: "50%"}}/>
                </div>
                <br/><br/>
            </div>

        )
    }
}

export default GetAnAPI