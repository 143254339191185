import React, {Component} from 'react'
import AceEditor from 'react-ace';
import 'brace/mode/json'

class JSONDisplay extends Component {
    constructor(props){
        super(props);

        this.createJSONString = this.createJSONString.bind(this);
    }

    createJSONString(data, headers, outputType, dataTypes){
        let jsonCode = "";

        if (outputType === "row" || outputType === "matrix"){
            jsonCode += '{"data": \n\t[\n'
            let startBrace; let endBrace;
            if (outputType === "row"){
                startBrace = "{"; endBrace = "}";
            }
            else{
                startBrace = "["; endBrace = "]";
            }
            for (let i=0; i<data.length; i++){
                let jsonRow = '\t\t' + startBrace
                let row = data[i];

                for (let j=0; j<row.length; j++){
                    const val = dataTypes[j] === "num" ? row[j] : '"' + row[j].toString() + '"'
                    if (outputType === "row"){
                        jsonRow += '"' + headers[j].toString() + '": '
                    }
                    jsonRow += val + ","
                }

                jsonRow = jsonRow.substring(0, jsonRow.length - 1)
                jsonCode += jsonRow + endBrace +",\n"

            }

            jsonCode = jsonCode.substring(0, jsonCode.length - 2) + "\n\t]\n}"
        }
        else if (outputType === "column" || outputType === "columnValue"){
            jsonCode += "{\n";

            if ('postRequest' in this.props){
                jsonCode += '\t"data": {\n'
            }

            for (let i=0; i<headers.length; i++){
                if ('postRequest' in this.props){
                    jsonCode += '\t'
                }

                jsonCode += '\t"' + headers[i].toString() + '": '

                if (outputType === "column"){
                    jsonCode += '['
                }

                let col = ""
                const endLength = outputType === "column" ? data.length: 1;
                for (let j=0; j<endLength; j++){
                    const val = dataTypes[i] === "num" ? data[j][i] : '"' + data[j][i].toString() + '"'
                    col += val + ","
                }

                col = col.substring(0, col.length-1)
                jsonCode += col

                if (outputType === "column"){
                    jsonCode += ']'
                }

                jsonCode += ",\n"
            }

            jsonCode = jsonCode.substring(0, jsonCode.length - 2)
            if ('postRequest' in this.props){
                jsonCode += "\n\t}"
            }

            jsonCode += "\n}"
        }

        return jsonCode

    }

    render() {
        let jsonCode = "";
        if ("jsonCode" in this.props){
            jsonCode = this.props.jsonCode;
        }
        else{
            jsonCode = this.createJSONString(this.props.tableData, this.props.tableHeaders, this.props.outputType, this.props.dataTypes);
        }
        const height = 30 + 20 * (jsonCode.match(/\n/g) || []).length;

        return (
            <div className="jsonDisplayBox">
                <p><b>{this.props.heading}</b></p>
                <AceEditor
                    mode="json"
                    theme="monokai"
                    value={jsonCode}
                    name="jsonDisplayBox"
                    editorProps={{$blockScrolling: true}}
                    height={height}
                    width="auto"
                />
            </div>
        )
    }
}

export default JSONDisplay