import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../SQL/styles";
import CustomAccordion from "../SharedComponents/CustomAccordion"
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

class ParameterRow extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ marginBottom: "12px"}}>
                <div style={{ marginBottom: "4px" }}>
                    <span><code style={{ fontWeight: "700", fontSize: "16px", color: styles.mainText}}>{this.props.parameter}</code> <span style={{ paddingLeft: "8px", color: styles.secondaryText, fontSize: "16px"}}>{this.props.type}</span></span>
                </div>
                <div style={{ fontSize: "14px", color: styles.subText}}>
                    {this.props.body}
                </div>
                {
                    'details' in this.props ?
                        <div style={{ marginTop: "8px" }}>
                            <CustomAccordion
                                body={<div style={{ marginTop: "4px", fontSize: "12px", color: gunaldiStyles.secondaryText}}>{this.props.details}</div>}
                                header="See more details"
                                accordionStyle={{
                                    borderRadius: '24px',
                                    border: "1px solid #F0F0F0",
                                    backgroundColor: 'white'
                                    // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                                }}
                                headerDivStyle={{
                                    backgroundColor: 'white',
                                    fontSize: '12px',
                                    borderRadius: '24px',
                                    fontFamily: "Lato",
                                    padding: "8px",
                                    color: gunaldiStyles.secondaryText
                                }}
                            />
                        </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ParameterRow)