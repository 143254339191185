import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

class DropboxVisual extends Component {
    render() {
        return (
            <div style={{textAlign: "center"}} className="uploadOuterSection">
                <div style={{paddingTop: "25px", textAlign: "center"}}>
                    <i className="bx bxl-dropbox" style={{color: "#D1E4CE", fontSize: "80px"}} />
                    <br/><br/>
                    <span onClick={this.choose} className="uploadLabel" style={{color: "#FFFFFF", backgroundColor: gunaldiStyles.mainGreen}}>
                        Select Your Dropbox Spreadsheets
                    </span>
                </div>
            </div>
        )
    }
}

export default DropboxVisual