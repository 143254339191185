import React, {Component} from 'react'
import {Label} from 'semantic-ui-react'

class ActionIndex extends Component {
    componentDidMount(){
        const elem = document.getElementById("fileURL")
        elem.scrollIntoView({ behavior: 'smooth', block: 'center'})
    }

    render() {
        return (
            <div className="actionIndex">
                <h4 className="thinHeading">{"To " + this.props.actionType.replace('Action', "").toUpperCase() + " rows from your file, make a "} {this.props.actionType.includes("read") || this.props.actionType.includes("delete") ? <Label color="yellow" horizontal={true}> GET </Label> : <Label color="orange" horizontal={true}> POST </Label>} {" request to your API URL"}</h4>
            </div>
        )
    }
}

export default ActionIndex