import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import PickS3File from "./PickS3File";
import API_Root from "../Constants/API_Root";
import copyArray from "../Constants/copyArray";
import {initUploadedPK} from "../Actions/InitUploadedPK";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import {toggleModal} from "../Actions/ToggleModal";
import {modifyUpgradeText} from "../Actions/ModifyUpgradeText";
import {readFiles} from "../Constants/readFile";
import {modifyIntegrationAuth} from "../Actions/ModifyIntegrationAuth";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import {push} from "react-router-redux";
import {modifyFilePKProBlock} from "../Actions/ModifyFilePKProBlock";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {modifyStatus} from "../Actions/ModifyStatus";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import {toggleToast} from "../Actions/ToggleToast";
import {modifyAPIMenuItem} from "../Actions/ModifyAPIMenuItem";

class S3FilePicker extends Component {
    constructor(props) {
        super(props);
    }

    changePrefix = (prefixName) => {
        this.props.getFiles(this.props.bucketName, prefixName)
    }

    saveS3File = (name, size) => {
        this.props.changeState({ fetching: true, fetchingText: "Creating an API from your file..." })
        const syncError = () => this.props.setErrorMsg("There was an error reading your file", "Please try again. Contact our support if this error persists")
        // Save the file here using this.state.bucketName & this.state.prefix & the account name
        fetch(API_Root + 'api/api-save-sheets-dropbox/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                documentType: "s3",
                documents: [{"name": name, "size": size}],
                s3AccountName: this.props.selectedAccountName,
                email: localStorage.getItem("email"),
                bucketName: this.props.bucketName,
                prefix: this.props.prefix
            })
        }).then(res => res.json())
            .then((data) => {
                if (!data.error){
                    // Modify the File Primary Keys
                    let filePK = JSON.parse(JSON.stringify(data.filePK));
                    let uploadedPK = JSON.parse(JSON.stringify(data.filePK));

                    if (this.props.filePK.length > 0){
                        filePK = copyArray(this.props.filePK, data.filePK);
                    }

                    if (this.props.uploadedPK.length > 0){
                        uploadedPK = data.filePK.concat(this.props.uploadedPK)
                    }

                    this.props.initUploadedPK(uploadedPK)
                    this.props.modifyFilePK(filePK);
                    // Open up WebSocket when files are saved
                    this.readS3File(data.filePK);

                }
                else{
                    if ('message' in data){
                        this.props.toggleModal(null)
                        this.props.modifyUpgradeText(data.message)
                        this.props.toggleModal("upgradeModal")
                    }
                    else{
                        syncError()
                    }
                }

            }).catch((err) => { syncError() })
            .catch(err => syncError()).catch(err => syncError())
    }

    readS3File = (filePK) => {
        const socketRequest = {
            utcOffset: new Date().getTimezoneOffset(),
            filePK: filePK,
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
            sheetRead: this.props.sheetRead
        }

        const route = "read-api-sheets-dropbox"

        readFiles(socketRequest, filePK, this.props, route, true, true)
    }

    render() {
        return (
            <div>
                {
                    this.props.prefixList !== null ?
                        this.props.prefixList.map((x, idx) => <div key={idx} onClick={e => this.changePrefix(x)} style={{backgroundColor: idx % 2 === 0 ? 'whitesmoke' : 'white'}}>
                            <p style={{fontWeight: "bold"}} className="linkStyle">{x}</p>
                        </div>) : null
                }
                {
                    this.props.fileList !== null ?
                        this.props.fileList.map((x, idx) => <PickS3File key={idx}
                                                                        idx={idx}
                                                                        isPrefixListEven={this.props.prefixList.length % 2 === 0}
                                                                        saveS3File={this.saveS3File}
                                                                        name={x.name}
                                                                        size={x.size}
                                                                        lastModified={x.lastModified}/>) : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    uploadedPK: state.mainState.uploadedPK,
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock,
    fileInformation: state.mainState.fileInformation,
    showingTutorial: state.mainState.showingTutorial,
    tutorialStep: state.mainState.tutorialStep
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    initUploadedPK: initUploadedPK,
    modifyFilePK: modifyFilePK,
    modifyUpgradeText: modifyUpgradeText,
    modifyIntegrationAuth: modifyIntegrationAuth,
    modifyFilePKProBlock: modifyFilePKProBlock,
    modifyFileInformation: modifyFileInformation,
    modifySheetRead: modifySheetRead,
    modifyStatus: modifyStatus,
    toggleToast: toggleToast,
    modifyModalInfo: modifyModalInfo,
    navigateTo: push,
    modifyAPIMenuItem: modifyAPIMenuItem
}

export default connect(mapStateToProps, mapActionsToProps)(S3FilePicker)