import React, {Component} from 'react'
import {connect} from 'react-redux'
import RereadFile from "./RereadFile";

class UploadGoogleSheets extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const imgStyle = {border:"1px solid #F0F0F0", display: "block", marginBottom: "16px"}

        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">Selecting a Google Sheet</p>
                    <p>1. Connect your Google Account</p>
                    <img
                        style={{width: "50%", ...imgStyle}}
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-GoogleSheets/ChooseGoogleSheetsAccount1.png"
                        alt="Select Google Account"/>
                    <p>2. Make sure you give <b>ALL</b> the permissions below to API Spreadsheets otherwise we will not be able to connect</p>
                    <p>
                        <img
                        style={{width: "50%", ...imgStyle}}
                        src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/GoogleSheetsPermissionCheck.png"
                        alt="GoogleSheetsPermissions"/>
                    </p>
                    <p>3. Select 1 or multiple Google Sheets</p>
                    <img
                        style={{width: "100%", ...imgStyle}}
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-GoogleSheets/SelectGoogleSheets3.png"
                        alt="GoogleSheetsPermissions"/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Error connecting with Google Sheets</p>
                    <p>If you are unable to connect to your Google Account, then you are likely using your Google Account on another API Spreadsheets account</p>
                    <p>You can <span className="linkStyle" onClick={e => this.props.setBothMenuItems("integrations-googleDrive")}>read all about how to fix issues with, and manage your Google Account</span></p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Google Sheet Read</p>
                    <RereadFile setBothMenuItems={this.props.setBothMenuItems} hasCsv={false} fileNames={<b>Google Sheets</b>}/>
                </div>

                {/*link to the article that will have managing information*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UploadGoogleSheets)