import React, {Component} from 'react'
import { Divider } from 'semantic-ui-react'

class DocsPostBodyParameters extends Component {
    render() {
        return (
            <div>
                <h5 id="parameterHeading">Request Body Parameters</h5>
                <Divider/>
                <span className="parameterName">data</span> <span className="parameterType">JSON Object</span>
                <span className="parameterSecondLine"><span className="parameterRequired">Required</span> </span>
                <span className="parameterDesc">The data that you want to post. Depending on the <code>dataFormat</code>, it needs to be in a certain format as seen on the right.</span>
                <br/>
                <span className="parameterDesc">If <code>dataFormat</code> is <code>jsonRow</code>, for each item in the array, each key in that item that is a valid column name will have its value added.</span>
                <br/>
                <span className="parameterDesc">If <code>dataFormat</code> is <code>matrix</code>, for each item in the array, if the length of the item is equal to the number of columns present in the file, the row will be added.</span>
                <br/>
                <span className="parameterDesc">If <code>dataFormat</code> is <code>jsonColumn</code>, each key that is a valid column name will have all of the values in its array added.</span>
                <Divider/>
                <span className="parameterName">dataFormat</span> <span className="parameterType">string</span>
                <span className="parameterSecondLine"><span className="parameterOptional">Optional</span> <span className="parameterDefault">default=jsonRow</span></span>
                <span className="parameterDesc">The format of the data you want to post, can be one of the following <code>jsonRow, matrix, jsonColumn</code>. If this parameter is not specified or is not in the aforementioned list, then the <code>jsonRow</code> format is used.</span>
            </div>
        )
    }
}

export default DocsPostBodyParameters