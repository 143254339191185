import React, {Component} from 'react'
import InviteRow from "./InviteRow";
import { Button, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";

class InviteRowIndex extends Component {
    constructor(props) {
        super(props);

        this.updateTeamInvites = this.updateTeamInvites.bind(this);
        this.addOrRemoveInvite = this.addOrRemoveInvite.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    updateTeamInvites(inviteIdx, key, value, clearRow){
        this.props.updateTeamInvites(inviteIdx, key, value, clearRow)
    }

    addOrRemoveInvite(inviteIdx, add){
        // Block Adding
        this.props.addOrRemoveInvite(inviteIdx, add)
    }

    openModal(e){
        this.props.toggleModal("rolesModal")
    }

    render() {
        if (this.props.teamInvites.length > 0){
            return (
                <div>
                    <div className="row">
                        <div className="col-sm-7">
                            <div className="fileIDHeading" style={{color: "#5E645E"}}>Email<span style={{color: 'red'}}>*</span></div>
                        </div>
                        <div className="col-sm-4">
                            <span className="fileIDHeading" style={{color: "#5E645E"}}>Role</span> <i className="linkStyle" onClick={this.openModal}>what is this?</i>
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    {this.props.teamInvites.map((x, idx) => <InviteRow addOrRemoveInvite={this.addOrRemoveInvite} updateTeamInvites={this.updateTeamInvites} idx={idx} value={x} key={idx.toString()} />)}

                </div>
            )
        } else {
            return (
                <Button size="tiny" style={{ backgroundColor: "#0081ff", borderRadius: "25px", color: "white", marginTop: "10px"}} onClick={(e) => this.addOrRemoveInvite(0, true)}><Icon name="plus circle" /> Add Invite</Button>
            )
        }
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(InviteRowIndex)