import React, {Component} from 'react'
import { Checkbox } from 'semantic-ui-react';

class PrivateToggle extends Component {
    constructor(props) {
        super(props);

        this.togglePrivate = this.togglePrivate.bind(this);
    }

    togglePrivate(){
        this.props.togglePrivate()
    }

    render() {
        return (
            <div style={{marginBottom: '10px'}}><span className="privateToggleTutorialSpan"><Checkbox toggle onChange={this.togglePrivate} checked={this.props.isPrivate} label="See Code for Private API" /></span></div>
        )
    }
}

export default PrivateToggle