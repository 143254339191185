import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import { Icon } from 'semantic-ui-react'
import BlockRequestsModalBody from "./BlockRequestsModalBody"

class BlockRequestsModal extends Component {
    render() {
        return (
            <ModalApp name="blockRequestsModal"
                      header={<h2><Icon name="lock"/> Block Request Types</h2>}
                      size="medium"
                      body={<BlockRequestsModalBody/>}
            />
        )
    }
}

export default BlockRequestsModal