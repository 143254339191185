import React, {Component} from 'react'
import AuthComponent from "../../SharedComponents/AuthComponent";
import UpdatedLandingPageIndex from "../../LandingPage/UpdatedLandingPageIndex";
import ImportIDDisplay from "./ImportIDDisplay"

class ImportIDIndex extends Component {
    render() {
        return (
            <div>
                <AuthComponent
                    authComponent={<ImportIDDisplay />}
                    nonAuthComponent={<UpdatedLandingPageIndex/>}
                />
            </div>
        )
    }
}

export default ImportIDIndex