import React, {Component} from 'react'
import {connect} from 'react-redux'
import HeaderDownloadFileButton from "../../FileDnD/HeaderDownloadFileButton"
import HeaderReuploadFileButton from "../../FileDnD/HeaderReuploadFileButton"
import FileIDCopy from "../FileIDCopy"
import FileTabRemoveFile from "../FileTabRemoveFile"
import { push } from 'react-router-redux'
import {toggleToast} from "../../Actions/ToggleToast";
import {isFileInLazyMode} from "../../Constants/isFileInLazyMode";
import {isUserAMacroUser} from "../../Constants/isUserAMacroUser";

class FilesIDHeaderToolbar extends Component {
    constructor(props){
        super(props);

        this.state = {
            goBackUrl: "/" + (this.props.isImporter ? 'import' : 'api') + "?menuItem=files&refresh"
        }

    }

    componentDidMount(){
        if (this.props.proBlock){
            if (this.props.fixColNames){
                if (!isUserAMacroUser(this.props.fullPricingInfo)) {
                    // this.goToFilesPage.click()
                    if (!isFileInLazyMode(this.props.fileInformation)) {
                        this.props.navigateTo(this.state.goBackUrl)
                        this.props.toggleToast({show: true, message: "Please fix your column names", type: "error"})
                    }
                }
            } else {
                // Current URL: https://my-website.com/page_a
                let nextURL = window.location.href.includes("localhost") ? 'http://localhost:5000/' : 'https://' + window.location.hostname	+ '/';
                nextURL += "api?menuItem=files&refresh"
                const nextTitle = 'API Spreadsheets - Files';
                const nextState = {};

                // This will create a new entry in the browser's history, without reloading
                window.history.pushState(nextState, nextTitle, nextURL);

                // This will replace the current entry in the browser's history, without reloading
                window.history.replaceState(nextState, nextTitle, nextURL);
            }
        }
    }

    render() {
        return (
            <div>
                <a style={{display: 'none'}}
                   ref={goToFilesPage => this.goToFilesPage = goToFilesPage}
                   href={this.state.goBackUrl}>
                   Go To Files page
                </a>
            <div style={{display: "grid", gridTemplateColumns: "auto auto auto 1fr auto 100px"}}>
                <div>
                    {
                        this.props.proBlock ? null : <HeaderDownloadFileButton
                            pk={this.props.pk}
                            pkID={this.props.pkID} />
                    }
                </div>
                {
                    this.props.canTakeAction ?
                        this.props.proBlock ? null :
                            <div style={{marginLeft: "8px"}}><HeaderReuploadFileButton pk={this.props.pk} pkID={this.props.pkID} fileHash={this.props.fileHash} updatedClassName="pageHeaderButton" color="green"/></div>
                        : null
                }
                {
                    this.props.proBlock && !isFileInLazyMode(this.props.fileInformation) ? null :
                        <div style={{marginLeft: "8px"}} className="pageHeaderSegment">
                            <FileIDCopy pk={this.props.pk}
                                fileInformation={this.props.fileInformation}
                                fileHash={this.props.fileHash}
                                previewFileHash={this.props.previewFileHash}
                            />
                        </div>
                }
                <div>

                </div>
                <div>
                    {this.props.canTakeAction ? <FileTabRemoveFile idx={this.props.idx}
                                                                   pkID={this.props.pkID}
                                                                   pk={this.props.pk}
                                                                   isExcel={this.props.isExcel}
                                                                   headerToolbar /> : null}
                </div>
                <div>

                </div>
            </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fullPricingInfo: state.mainState.fullPricingInfo
})

const mapActionsToProps = {
    navigateTo: push,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(FilesIDHeaderToolbar)