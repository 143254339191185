import React, {Component} from 'react'
import AuthUserContext from '../../Authentication/AuthUserContext';
import NavbarAuth from './NavbarAuth'
import NavbarNonAuth from './NavbarNonAuth'
import { isMobile } from 'react-device-detect'
import NavbarMobile from './NavbarMobile'
import AuthComponent from "../AuthComponent";

class NavbarIndex extends Component {
    render() {
        if (isMobile){
            return(
                <NavbarMobile />
            )
        } else {
            return (
                <div>
                    <AuthComponent
                        authComponent={<NavbarAuth/>}
                        nonAuthComponent={<NavbarNonAuth/>}
                    />
                </div>
            )
        }
    }
}

export default NavbarIndex