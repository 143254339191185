import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import { Icon } from 'semantic-ui-react'
import ReportingModalBodyIndex from "./ReportingModalBodyIndex"
import {connect} from 'react-redux'

class ReportingModal extends Component {
    render() {
        let alreadySetUpReport = false;

        if (this.props.modalInfo !== null){
            if (typeof this.props.modalInfo === "object"){
                if ("alreadySetUpReport" in this.props.modalInfo){
                    alreadySetUpReport = this.props.modalInfo.alreadySetUpReport
                }
            }
        }

        const title = alreadySetUpReport ? "View Auto Reporting Details" : "Set Up Auto Reporting"

        return (
            <ModalApp name="reportingModal"
                      header={<h2><Icon name="mail outline"/> {title}</h2>}
                      size="large"
                      body={<ReportingModalBodyIndex />}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

export default connect(mapStateToProps, null)(ReportingModal)