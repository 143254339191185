import React, {Component} from 'react'
import {connect} from 'react-redux'
import getURLParam from "../../Constants/getURLParam";
import API_Root from "../../Constants/API_Root";
import Loader from 'react-loader-spinner'
import VisualizationError from "./VisualizationError";
import {modifyVizInfo} from "../../Actions/ModifyVizInfo";
import VisualizationDisplay from "./VisualizationDisplay";

class VisualizationIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            firstTime: true,
            fetching: true
        }
    }

    componentDidMount(){
        let id;

        try{
            id = getURLParam(window.location.href)
        } catch (err) {
            id = ""
        }


        const error = () => this.setState({ error: true, fetching: false, firstTime: false })

        fetch(API_Root + "api/get-api-viz-info/" + id, {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token")
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then(res => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.props.modifyVizInfo(data.vizInfo)
                    this.setState({ error: false, fetching: false, firstTime: data.firstTime })
                }).catch(err => error())
            } else {
                error()
            }
        }).catch(err => error())
    }

    render() {
        if (this.state.fetching){
            return (
                <div>
                    <div style={{padding: '100px 0', textAlign: 'center'}}>

                    </div>
                </div>
            )
        } else {
            if (this.state.error){
                return(
                    <div>
                        <VisualizationError/>
                    </div>
                )
            } else{
                return(
                    <div>
                        <VisualizationDisplay firstTime={this.state.firstTime} />
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    modifyVizInfo: modifyVizInfo
}

export default connect(mapStateToProps, mapActionsToProps)(VisualizationIndex)