import React, {Component} from 'react'
import { Popup } from 'semantic-ui-react'
import PopupPlanBody from "./PopupPlanBody";

class PopupPlanDetails extends Component {
    render() {
        let body = null;

        if ('plan' in this.props){
            if (this.props.plan !== 4){
                // Not enterprise
                body = <PopupPlanBody plan={this.props.plan} noPrice={'noPrice' in this.props}/>
            } else {
                body = <p>This is a custom account. Please contact us at info@apispreadsheets.com if you want your plan details</p>
            }

        }
        const style = {
            borderRadius: 0,
            padding: '2em',
            backgroundColor:  backgroundColor[this.props.plan],
            color: color[this.props.plan]
        }



        return (
            <Popup
                style={style}
                trigger={this.props.trigger}
                // position="top left"
                pinned
                offset='50px, 0'
                position='bottom left'
            >
                <Popup.Content>
                    {body}
                </Popup.Content>
            </Popup>
        )
    }
}

const backgroundColor = ["white", "whitesmoke", "#203354"]
const color = ["black", "black", "white"]

export default PopupPlanDetails