import React, {Component} from 'react'
import SignUpForm from './SignUpForm'
import images from "../../../Constants/Images";
import { Image } from 'semantic-ui-react'
import AppSumoAPILogo from "../AppSumoAPILogo.svg"

class SignUpIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.initEmail(),
            password: '',
            error: null,
            loading: false,
            isAppSumo: this.initAppSumo()
        }

    }
    componentDidMount(){
        document.title = "Signup - API Spreadsheets"
    }
    initEmail = () => {
        const url = window.location.href;

        if (url.includes("&email=")){
            return url.substring(url.indexOf("&email=") + "&email=".length, url.length).trim()
        } else { return '' }
    }
    initAppSumo = () => { return (window.location.href).includes("?deal=appsumo") }

    isImporter = () => ((window.location.href).includes("?importer"))

    render() {
        return (
            <div className="row" style={{backgroundColor: 'whitesmoke', minHeight: '100vh'}}>
                <div className="col-sm-3" />
                <div className="col-sm-6">
                    <br/><br/><br/>
                    {this.state.isAppSumo ?
                        <div style={{marginLeft:'1rem'}}>
                           <Image style={{marginTop:'5px'}} src={AppSumoAPILogo} href="/" size="large"/>
                        </div>:
                        <div style={{marginLeft: '1rem'}}>
                            <Image style={{marginTop: '5px'}} src={this.isImporter() ? images.logoImporterNav : images.logoNav} href="/" size="medium"/>
                        </div> }
                    <SignUpForm importer={this.isImporter()}/>
                    <br/>
                    <p style={{textAlign: 'center'}}>Already Have An Account? <a href={"/login" + (this.isImporter() ? "?importer" : "")}>Log In</a></p>
                    <br/><br/><br/><br/>
                </div>
                <div className="col-sm-3" />
            </div>
        )
    }
}

export default SignUpIndex