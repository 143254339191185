import React, {Component} from 'react'
import DocsImage from "../../DocsImage"
import { Label } from 'semantic-ui-react'
import SpreadsheetImportWebhook from "../../../../Guides/GuidesText/SpreadsheetImportWebhook";

class Actions extends Component {
    render(){
        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">Introduction</p>
                    <p style={{marginBottom: "5px"}}>You can configure the following actions to be taken either before or after the user imports a spreadsheet</p>
                    <DocsImage
                        src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/SpreadsheetImporter/ActionsTab.png"
                        width="100%"
                        alt="Importer Appearance" />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Email Alerts</p>
                    <p>This is only available on a <Label color="green">TEAM</Label> or higher account</p>
                    <p>You can select to receive an email notification when a user imports a file. You can also select to have the file be attached to the email</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Password Protect</p>
                    <p>This is only available on a <Label color="green">PRO</Label> or higher account</p>
                    <p>You can create multiple username and password combinations to password protect your importer</p>
                    <p><b><i>NOTE:</i></b> password protection is only available on the importer if you use the URL. If you integrate the importer in your own website, then there will be no password protection</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Webhook URL</p>
                    <p>This is only available on a <Label color="green">PRO</Label> or higher account</p>
                    <p>You can add a REST API url here, such as <code>http://www.yourserver.com/your_webhook</code></p>
                    <p>In this example, a <code>POST</code> request will be made to <code>http://www.yourserver.com/your_webhook</code> after any successful spreadsheet import</p>
                    <p>The following data will be included in the request body with each POST request to the Webhook URL</p>
                    <SpreadsheetImportWebhook/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Send File Data to Webhook</p>
                    <p>You can toggle whether you wish to send the File Data with the webhook request. With this toggle off, the <code>data</code> parameter in the request body above will be a blank array <code>[]</code></p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Destinations-Save.png"
                        width="100%"
                        alt="Importer Appearance" />
                </div>
            </div>
        )
    }
}

export default Actions