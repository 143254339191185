import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {resetAllStates} from "../../Actions/ResetAllStates";
import { auth } from '../../Authentication/firebase';
import Loader from 'react-loader-spinner'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
import InfoSegment from "../../SharedComponents/InfoSegment";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

class CancelAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cancelAccountStep: 0,
            deletingAccount: false
        }

        this.cancelAccount = this.cancelAccount.bind(this);
        this.resetCancel = this.resetCancel.bind(this);
    }

    cancelAccount(e){
        const currStep = this.state.cancelAccountStep;
        const incrementStep = () => this.setState({ cancelAccountStep: currStep + 1})

        if (currStep === 2){
            this.setState({ deletingAccount: true })

            auth.deleteUser().then(() => {
                fetch(API_Root + "api/delete-free-api-customer/", {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json, text/plain, */*',
                    },
                    body: JSON.stringify({
                        email: localStorage.getItem("email"),
                        token: localStorage.getItem("token")
                    })
                }).then(res => {
                    this.setState({ deletingAccount: false })

                    if (res.status === 200){
                        alert("Account Deleted")
                        auth.doSignOut()
                        this.props.resetAllStates();
                    } else {
                        incrementStep()
                    }

                }).catch(err => { console.log(err); this.setState({ deletingAccount: false }); incrementStep() });
            }).catch(err => {
                if (err.code === "auth/requires-recent-login"){
                    this.props.modifyModalInfo({ source: "cancelButton" })
                    this.setState({ deletingAccount: false, cancelAccountStep: 0 }, () => {
                        alert("Please Login to Re-authenticate yourself and follow the steps again to cancel your account");
                        this.props.toggleModal("loginModal")
                    })
                } else {
                    console.log(err); this.setState({ deletingAccount: false }); incrementStep()
                }
            })


            // fetch(API_Root + "api/delete-free-api-customer/", {
            //     method: 'POST',
            //     headers: {
            //         Accept: 'application/json, text/plain, */*',
            //     },
            //     body: JSON.stringify({
            //         email: localStorage.getItem("email"),
            //         token: localStorage.getItem("token")
            //     })
            // }).then(res =>
            //     {
            //         this.setState({ deletingAccount: false })
            //         if (res.status === 200){
            //             console.log("account deleted from database")
            //             auth.deleteUser().then(() => {
            //                 alert("Account Deleted")
            //                 auth.doSignOut()
            //                 this.props.resetAllStates();
            //             }).catch(err => { console.log(err); this.setState({ deletingAccount: false }); incrementStep() })
            //         } else {
            //             console.log("failed delete")
            //             incrementStep()
            //         }
            //     }
            // ).catch(err => { console.log(err); this.setState({ deletingAccount: false }); incrementStep() });
        } else {
            incrementStep()
        }
    }

    resetCancel(e){
        this.setState({ cancelAccountStep: 0 })
    }

    render() {
        if (this.props.pricingPlan === "free"){
            if (this.state.deletingAccount){
                return(
                    <div>
                        <Loader
                            type="TailSpin"
                            color="black"
                            height="50"
                            width="50"
                        />
                        <h4 className="thinHeading" style={{color: 'black'}}>Canceling Your Account...</h4>
                    </div>
                )
            } else {
                if (this.state.cancelAccountStep === 0){
                    return(
                        <span>
                        <Button content="Delete My Account" color="red" icon="trash alternate outline" onClick={this.cancelAccount} />
                    </span>
                    )
                } else if (this.state.cancelAccountStep === 1){
                    return(
                        <span>
                        <h6 style={{color: 'red'}} className="thinHeading">Are you sure you want to Delete your Account?</h6>
                        <p>All your files and API URLs will be immediately deleted. There is no coming back.</p>
                        <Button content="Yes" color="red" onClick={this.cancelAccount} icon="trash alternate outline" />
                        <Button content="No" color="green" onClick={this.resetCancel} icon="user alternate outline" />
                    </span>
                    )
                } else if (this.state.cancelAccountStep === 2){
                    return(
                        <span>
                            <h6 style={{color: 'red'}} className="thinHeading">FINAL WARNING</h6>
                            <p>Your Account, Your Files and API URLs will be IMMEDIATELY deleted and stop working.</p>
                            <p>You will not be able to Log In to your account after you cancel</p>
                            <small><b>Note</b> you will need to manually remove API Spreadsheets' access from your all connected accounts</small>
                        <Button content="I Want to Keep my Account" color="green" onClick={this.resetCancel} icon="user alternate outline" />
                        <Button content="I Want to Delete my Account" color="red" onClick={this.cancelAccount} icon="trash alternate outline" />
                    </span>
                    )
                } else{
                    return(
                        <span>
                        <h5 className="thinHeading" style={{color: 'red'}}>
                            There was a problem delete your account.
                        </h5>
                        <h5 className="thinHeading">
                            Email us at <a href="mailto:cancel@apispreadsheets.com?Subject=API%20Spreadsheets%Delete%20Account" style={{color: 'red'}}> cancel@apispreadsheets.com</a> from {localStorage.getItem("email").toString()} to delete your account.
                        </h5>
                    </span>
                    )
                }
            }
        }
        else {
            return (
                <InfoSegment
                    header="Cancel Subscription"
                    info={<div style={{fontSize: "14px", color: gunaldiStyles.mainText}}>
                        Email us at <a href="mailto:cancel@apispreadsheets.com?Subject=API%20Spreadsheets%Cancel%20Account" style={{color: 'red'}}> cancel@apispreadsheets.com</a> from {localStorage.getItem("email").toString()} and we will cancel it right away. No questions asked!
                    </div>}
                />

            )
        }
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    resetAllStates: resetAllStates,
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(CancelAccount)