import React, {Component} from 'react'
import {isMobile} from "react-device-detect";
import IconLabel from "../SharedComponents/IconLabel";
import RulesRow from "./RulesRow";
import styles from "./styles";

class SQLUploadModalBody extends Component {
    getFAQDisplay = (question, answer) => {
        return <div style={{ marginBottom: "16px"}}>
            <div style={{ fontSize: "16px", color: styles.mainText, fontWeight: "700" }}>
                {question}
            </div>
            <div style={{ fontSize: "16px", color: styles.subText, marginTop: "4px"}}>
                {answer}
            </div>
        </div>
    }

    render() {
        return (
            <div>
                <IconLabel
                    icon="bx bx-question-mark"
                    label="Upload Files FAQs"
                    style={{ fontWeight: "700" }}
                />
                <div style={{ marginTop: "24px" }}>
                    {this.getFAQDisplay("What kind of files can I upload?", "You can upload .xlsx, .xls, and .csv files")}
                    {this.getFAQDisplay("How many files can I upload at a time?", "You can upload up to 3 files at a time. You can select as many as you want but only the first 3 will be read.")}
                    {this.getFAQDisplay("How are my files read?", "Excel files (.xlsx, .xls) are read for their first sheet ONLY. CSV (.csv) files are read with a comma delimiter")}
                    {this.getFAQDisplay("Can I change how my files are read?", "No, but we are working on this feature")}
                    {this.getFAQDisplay("How are column names determined?", "The first line of your file become your column names")}
                    {this.getFAQDisplay("Can I change my column names?", "No, but we are working on this feature")}
                    {this.getFAQDisplay("How are data types determined for my data?", "If an entire column is text or numerical (even with blanks) then it is read as such. If it has mixed data types (text or numerical) then it is read as text. Blank values are read as NULL but displayed as blank")}
                    <div style={{ marginBottom: "64px", border: "2px solid #F0F0F0", borderRadius: "4px", padding: "16px"}}>
                        If you have any more questions, you can email us at <b>info@apispreadsheets.com</b>
                    </div>
                </div>
            </div>
        )
    }
}

export default SQLUploadModalBody