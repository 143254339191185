import React, {Component} from 'react'
import { Segment, Image } from 'semantic-ui-react'
import images from "../../../Constants/Images";
import ReadPython from "./ReadPython";
import ReadR from "./ReadR";

class ReadSpreadsheets extends Component {
    constructor(props){
        super(props);

        this.state = {
            language: "python"
        }

        this.changeLanguage = this.changeLanguage.bind(this);
    }

    changeLanguage(e, language){
        this.setState({
            language: language
        })
    }

    render() {
        const body = this.state.language === "python" ? <ReadPython/> : <ReadR/>

        return (
            <div>
                <p>There are a few ways to read spreadsheets in both R & Python.</p>
                <br/>
                <p>This guide will walk you through the easiest way how to read spreadsheets in both R and Python using API Spreadsheets</p>
                <br/>
                <img style={{margin: '0 auto'}} src="https://miro.medium.com/max/648/1*odFqlFgAyXLZp3415PxxPg.jpeg" height="50%" width="50%" alt="ReadSpreadsheetsPythonR1" />
                <br/><br/><br/>
                <div className="row">
                    <div className="col-sm-3">
                        <Segment raised style={{cursor: 'pointer', backgroundColor: this.state.language === "python" ? "lightgrey" : "white"}} onClick={(e) => this.changeLanguage(e, "python")}>
                            <Image src={images.pythonLogo} size="mini" centered/>
                            <h3 className="thinHeading" style={{textAlign: 'center'}}>Python</h3>
                        </Segment>
                    </div>
                    <div className="col-sm-3">
                        <Segment raised style={{cursor: 'pointer', backgroundColor: this.state.language !== "python" ? "lightgrey" : "white"}} onClick={(e) => this.changeLanguage(e, "r")}>
                            <Image src={images.rLogo} size="mini" centered/>
                            <h3 className="thinHeading" style={{textAlign: 'center'}}>R</h3>
                        </Segment>
                    </div>
                    <div className="col-sm-3" />
                    <div className="col-sm-3" />
                </div>
                <br/><br/>
                {body}
            </div>
        )
    }
}

export default ReadSpreadsheets