import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Checkbox } from 'semantic-ui-react'
import Loader from 'react-loader-spinner'
import API_Root from "../../Constants/API_Root";
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyPaymentModalOrigin} from "../../Actions/ModifyPaymentModalOrigin";

class UtilitiesPrivateButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changingOnBackend: false
        }
    }

    toggle = () => {
        if (this.props.tier === "pro" || this.props.tier === "team" || this.props.tier === "business" || this.props.tier === "enterprise"){
            this.setState({
                changingOnBackend: true
            })

            const error = () => this.setState({ changingOnBackend: false })
            fetch(API_Root + "api/crud-api-utilities-info/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    mode: "isPrivate",
                    val: !this.props.isPrivate,
                    pk: this.props.pk
                })
            }).then(res => {
                if (res.status === 200){
                    this.setState({
                        changingOnBackend: false
                    }, () => this.props.changeState({ isPrivate: !this.props.isPrivate }))
                } else {
                    error()
                }
            }).catch(err => error())
        } else {
            this.props.toggleModal(null)
            this.props.modifyPaymentModalOrigin("private")
            this.props.toggleModal("paymentModal")
        }

    }

    render() {
        if (this.state.changingOnBackend){
            return <Loader
                type="TailSpin"
                color="black"
                height="20"
                width="20"
            />
        }

        return (
            <div>
                <Checkbox checked={this.props.isPrivate} onChange={this.toggle} toggle/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyPaymentModalOrigin: modifyPaymentModalOrigin
}

export default connect(mapStateToProps, mapActionsToProps)(UtilitiesPrivateButton)