import React, {Component} from 'react'
import FadeIn from 'react-fade-in';
import isObjectEmpty from "../../Constants/isObjectEmpty";
import { Input, Dropdown, Button, Icon } from 'semantic-ui-react';
import {getUploadFileForMessage} from "./getUploadFileForMessage";
import {isBrowser, isMobile } from 'react-device-detect'

class IntroUploadName extends Component {
    constructor(props) {
        super(props);


        this.state = {
            importName: ""
        }
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    dropdownChange = (event, { value }) => this.setState({ importName: value });

    proceed = () => {
        localStorage.setItem("importName", this.state.importName);
        this.props.changeImportName(this.state.importName)
    }

    getWelcomeIntroMessage = () => {
        let startMsg = "Enter your name to start uploading files for "
        return getUploadFileForMessage(startMsg, this.props.info)
    }

    render() {
        let body = null;
        const allUserOptions = this.props.info.allUsers;
        const uploadMsg = this.getWelcomeIntroMessage()

        const enterNameBody =
        <div>
            {isMobile ? <span style={{fontSize: "18px", fontWeight: "700", fontFamily: "Lato", wordBreak: "break-word"}}>{uploadMsg}</span>: <h2 className="wordwrap">{uploadMsg}</h2>}
            <br/>
            <Input name="importName"
                   value={this.state.importName}
                   placeholder="Hermione Granger"
                   fluid
                   onChange={this.handleInputChange}
            />
        </div>;

        if (isObjectEmpty(allUserOptions)){
           body = enterNameBody;
        } else {
            if ('userNames' in allUserOptions){
                let dropdownOptions = [];

                for (let i=0; i < allUserOptions.allUserNames.length; i++){
                    dropdownOptions.push({ key: i, value: allUserOptions.userNames[i], text: allUserOptions.userNames[i] })
                }

                body =
                <div>
                    <h2>Select your Name to start Uploading your Files for {this.props.info.userEmail}</h2>
                    <br/>
                    <Dropdown options={dropdownOptions}
                              onChange={this.dropdownChange}
                              selection
                              value={this.state.importName}
                              fluid />
                </div>
            } else {
                body = enterNameBody
            }
        }

        return (
            <FadeIn>
                <div style={isMobile ? {margin: "2.5% 5% 0 5%"} : {padding: "75px 100px 0 100px", textAlign: "center"}}>
                    {body}
                    <div style={{marginTop: "20px"}}>
                        <Button fluid
                                onClick={this.proceed}
                                disabled={this.state.importName.trim() === ""}
                                color="green"
                        >
                            Proceed to Uploading
                            <Icon name="arrow right" />
                        </Button>
                        {
                            this.state.importName.trim() === "" ?
                            <small style={{color: "red", display: "block"}}>Please enter a name to proceed</small> : null
                        }
                    </div>
                </div>
            </FadeIn>
        )
    }
}

export default IntroUploadName