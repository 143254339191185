import React, {Component} from 'react'

class TestFetchBtn extends Component {
    constructor(props) {
        super(props);
    }

    testFetch = (e) => {
        fetch("http://127.0.0.1:8080/ai/15af9184e5d7e1a657bf39795bedf46f7571d175d5cf804f461659d722704bad/", {
            method: "POST",
            headers: {"Accept": "application/json, text/plain, */*"},
            body: JSON.stringify({"data":[{"Title":"Guardians of the Galaxy","Genre":"Action,Adventure,Sci-Fi","Director":"James Gunn","Actors":"Chris Pratt, Vin Diesel, Bradley Cooper, Zoe Saldana","Year":2014,"Runtime (Minutes)":121},{"Title":"Prometheus","Genre":"Adventure,Mystery,Sci-Fi","Director":"Ridley Scott","Actors":"Noomi Rapace, Logan Marshall-Green, Michael Fassbender, Charlize Theron","Year":2012,"Runtime (Minutes)":124},{"Title":"Split","Genre":"Horror,Thriller","Director":"M. Night Shyamalan","Actors":"James McAvoy, Anya Taylor-Joy, Haley Lu Richardson, Jessica Sula","Year":2016,"Runtime (Minutes)":117},{"Title":"Sing","Genre":"Animation,Comedy,Family","Director":"Christophe Lourdelet","Actors":"Matthew McConaughey,Reese Witherspoon, Seth MacFarlane, Scarlett Johansson","Year":2016,"Runtime (Minutes)":108},{"Title":"Suicide Squad","Genre":"Action,Adventure,Fantasy","Director":"David Ayer","Actors":"Will Smith, Jared Leto, Margot Robbie, Viola Davis","Year":2016,"Runtime (Minutes)":123},{"Title":"The Great Wall","Genre":"Action,Adventure,Fantasy","Director":"Yimou Zhang","Actors":"Matt Damon, Tian Jing, Willem Dafoe, Andy Lau","Year":2016,"Runtime (Minutes)":103}],"query":"Show me movies where Chris Pratt was an actor"})
        }).then(res =>{
            if (res.status === 200){
                res.json().then(data => {
                    // SUCCESS
                    const respData = data;
                    console.log(respData)
                }).catch(err => console.log(err))
            }
            else{
                // ERROR
            }
        })
    }

    render() {
        return (
            <button onClick={this.testFetch}>
                Test fetch
            </button>
        )
    }
}

export default TestFetchBtn