import React, {Component} from 'react'
import {connect} from 'react-redux'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import {dateToString} from "../../Constants/dateToString";
import {stringToDate} from "../../Constants/stringToDate";


class DateFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultStartDt: stringToDate(this.props.originalFilePK[this.props.originalFilePK.length - 1].createdDt.substring(0, 10)),
            defaultEndDt: stringToDate(this.props.originalFilePK[0].createdDt.substring(0, 10))
        }


        this.changeDates = this.changeDates.bind(this);
    }

    changeDates(dates){
        if (dates !== null){
            this.props.changeState("dates", {startDt: dateToString(dates[0]).date, endDt: dateToString(dates[1]).date})
        }
        else{
            this.props.changeState("dates", {startDt: dateToString(this.state.defaultStartDt).date, endDt: dateToString(this.state.defaultEndDt).date})
        }
    }

    render() {
        const dateValues = [stringToDate(this.props.startDt.substring(0, 10)), stringToDate(this.props.endDt.substring(0, 10))];

        return (
            <div style={{zIndex: '999'}}>
                <div >
                <DateRangePicker
                    onChange={this.changeDates}
                    value={dateValues}
                    minDate={this.state.defaultStartDt}
                    maxDate={this.state.defaultEndDt}
                    className="react-calendar"
                />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    originalFilePK: state.mainState.originalFilePK
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DateFilter)