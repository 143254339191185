export const modifyColumnInfoInRedux = (info, props, idx) => {
    const allCurrInfo = JSON.parse(JSON.stringify(props.customImportColumnsAddlInfo))
    const currInfo = allCurrInfo[idx]

    for (let key in info){
        currInfo[key] = info[key]
    }

    allCurrInfo[idx] = currInfo

    props.modifyCustomImportSettings("columnsAddlInfo", allCurrInfo)

}