const importerFeatureList = [
    {key: "files", label: "Files"},
    {key: "rowMax", label: "Rows per File"},
    {key: "team", label: "Team Members"},
    {key: "addlProducts", label: "API Included"},
    {key: "webhooks", label: "Webhooks"},
    {key: "customizeImporter", label: "Customize Appearance"},
    {key: "alerts", label: "Email Alerts"},
    {key: "database", label: "Database Connection"},
    {key: "connectDomains", label: "Connect Your Domain"},
    {key: "support", label: "Support Level"},
];

export default importerFeatureList;