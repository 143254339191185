import React from 'react';
import {Button} from "semantic-ui-react";

const OutputFieldRow = ({ field, sheetNames, onFieldChange, onRemove }) => {
    const handleChange = (fieldKey) => (event) => {
        const value = event.target.value;
        onFieldChange({ ...field, [fieldKey]: value });
    };

    const labelStyle = {fontFamily: "Lato", fontWeight: '500', fontSize: "14px", color: "#5E645E", marginTop: "16px"}

    return (
        <div style={{marginBottom: "16px"}}>
            <div className="row">
                <div className="col-sm-4">
                    <div>
                        <span style={labelStyle}>Output Label</span>
                    </div>
                    <div>
                        <input type="text" value={field.outputLabel || ''}
                               onChange={handleChange('outputLabel')} className="inputGray" style={{width: '100%'}}/>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div style={{display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "32px"}}>
                        <div>
                            <div>
                                <span style={labelStyle}>Output Excel Sheet</span>
                            </div>
                            <div style={{padding: '6px 0px'}}>
                                <select value={field.outputSheet || ''} onChange={handleChange('outputSheet')}>
                                    {sheetNames.map((sheet, index) => (
                                        <option key={index} value={sheet}>{sheet}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span style={labelStyle}>Output Excel Cell</span>
                            </div>
                            <div>
                                <input type="text" value={field.outputCell || ''}
                                       onChange={handleChange('outputCell')} style={{width: '128px'}} className="inputGray"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div style={{ paddingTop: "16px"}}>
                        <Button onClick={onRemove} circular icon="trash alternate outline" basic color="red"></Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OutputFieldRow;
