import React, {Component} from 'react'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

class AIResultsTable extends Component {
    render() {
        const tableStyle = {};
        const theadStyle = {};
        const thRowStyle = {};
        const thStyle = {
            // border: "2px solid " + gunaldiStyles.mainGreen,
            border: "none",
            backgroundColor: gunaldiStyles.mainGreen,
            color: "white"
        };

        const tbodyStyle = {};
        const trStyle = {};
        const tdStyle = {
            border: "1px solid #F0F0F0",
            padding: "12px"
            // paddingLeft: "4px"
        }

        return (
            <div style={{ border: "1px solid #F0F0F0", borderRadius: "8px", padding: "12px", marginTop: "12px", marginLeft: this.props.isMobile ? "0" : "24px"}}>
                <div style={{ marginBottom: "12px"}}>
                    <div style={{ fontSize: "16px", fontWeight: "700", color: gunaldiStyles.subText }}>Get results in JSON object</div>
                </div>
                <table style={tableStyle}>
                    <thead style={theadStyle}>
                        <tr style={thRowStyle}>
                            <th style={thStyle}>
                                Title
                            </th>
                            <th style={thStyle}>
                                Director
                            </th>
                            <th style={thStyle}>
                                Year
                            </th>
                        </tr>
                    </thead>
                    <tbody style={tbodyStyle}>
                        <tr style={trStyle}>
                            <td style={tdStyle}>
                                Guardians of the Galaxy
                            </td>
                            <td style={tdStyle}>
                                James Gunn
                            </td>
                            <td style={tdStyle}>
                                2014
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default AIResultsTable