import React, {Component} from 'react'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import {getDatetimeComponentsFromISO} from "../../Constants/getDatetimeComponentsFromISO";
import {getDisplayLabelSuffix} from "../../Constants/getDisplayLabelSuffix";

class CachingLayerTimer extends Component {
    constructor(props) {
        super(props);

        const dateComponents = getDatetimeComponentsFromISO(this.props.expiring)
        const expiringDate = new Date(Date.UTC(dateComponents.year, dateComponents.month, dateComponents.date, dateComponents.hours, dateComponents.minutes, dateComponents.seconds))

        this.state = {
            expiringDate: expiringDate,
            timeLeft: this.getTimeLeft(expiringDate)
        }
    }

    getTimeLeft = (expiringDate) => {
        let timeLeft = {day: null, hour: null, minute: null, second: null};
        const currDate = new Date();

        if (currDate <= expiringDate){
            const diffDays = (expiringDate - currDate) / (1000 * 60 * 60 * 24);
            timeLeft.day = Math.floor(diffDays)
            const diffHours = (diffDays % 1) * 24;
            timeLeft.hour = Math.floor(diffHours)
            const diffMinutes = (diffHours % 1) * 60;
            timeLeft.minute = Math.floor(diffMinutes);
            const diffSeconds = (diffMinutes % 1) * 60;
            timeLeft.second = Math.floor(diffSeconds);
        }

        return timeLeft
    }

    setTimeLeft = () => this.setState({ timeLeft: this.getTimeLeft(this.state.expiringDate) })

    componentDidMount(){
        setInterval(() => this.setTimeLeft(), 1000);
    }



    render() {
        if (this.state.timeLeft.hour === null){
            return (
                <div style={{fontSize: "16px", color: gunaldiStyles.red, fontWeight: "700"}}>Your caching layer has expired</div>
            )
        } else {
            return (
                <div>
                    <div style={{fontSize: "16px", color: gunaldiStyles.mainText, fontWeight: "700"}}>Your caching layer expires at {this.state.expiringDate.toLocaleString()}</div>
                    <div style={{fontSize: "14px", color: gunaldiStyles.subText}}>
                        <b>{this.state.timeLeft.day}</b> days <b>{this.state.timeLeft.hour}</b> hour{getDisplayLabelSuffix(this.state.timeLeft.hour)} <b>{this.state.timeLeft.minute}</b> minute{getDisplayLabelSuffix(this.state.timeLeft.minute)} <b>{this.state.timeLeft.second}</b> second{getDisplayLabelSuffix(this.state.timeLeft.second)}
                    </div>
                </div>
            )
        }
    }
}

export default CachingLayerTimer