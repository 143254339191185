import React, {Component} from 'react'
import withAuthorization from '../../Authentication/withAuthorization';
import DropboxFolderConnectIndex from "./DropboxFolderConnectIndex";

class AuthDropboxFolderConnectIndex extends Component {
    render() {
        return (
            <DropboxFolderConnectIndex/>
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AuthDropboxFolderConnectIndex)