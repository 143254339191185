import React, {Component} from 'react'
import ModalApp from '../../../SharedComponents/Modal'
import CRUDModalBody from "./CRUDModalBody"

class CRUDModal extends Component {
    render() {
        return (
            <ModalApp name="crudModal"
                      header={null}
                      size="large"
                      body={<CRUDModalBody />}
            />
        )
    }
}

export default CRUDModal