import React, {Component} from 'react'
import {connect} from 'react-redux'
import BannerAlert from "../../SharedComponents/BannerAlert"
import { Button } from 'semantic-ui-react'
import API_Backend_Root from "../../Constants/API_Backend_Root";
import {toggleToast} from "../../Actions/ToggleToast";

class ToggleAPIAndMacro extends Component {
    constructor(props) {
        super(props);

        this.state = {
            switching: false
        }
    }

    toggleToAnotherMode = () => {
        this.setState({ switching: true })

        let fileInfo = this.props.fileInformation[this.props.pkID];
        if (this.props.fileMode === "api"){
            fileInfo['isFileInAPIMode'] = false
        } else {
            fileInfo['isFileInAPIMode'] = true
        }

        fetch(API_Backend_Root + "api/update-file-information/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                fileInfo: fileInfo,
                pk: this.props.pk
            })
        }).then(res => {
            if (res.status === 200){
                this.setState({ switching: false }, () => {
                    window.location.reload()
                })
            } else {
                this.setState({ switching: false }, () => {
                    this.props.toggleToast({
                        show: true,
                        type: "error",
                        message: 'There was an error switching. Try again or contact support'
                    })
                })

            }
        }).catch(err => {
            this.setState({ switching: false }, () => {
                this.props.toggleToast({
                    show: true,
                    type: "error",
                    message: 'There was an error switching. Try again or contact support'
                })
            })
        })
    }

    render() {
        return (
            <div>
                <BannerAlert type="display"
                             content={'Switch to ' + (this.props.fileMode === "api" ? "macros" : "API") + " mode by clicking the switch button"}
                             header={'This file is in ' + this.props.fileMode.toUpperCase() + " mode"}
                             icon={this.props.fileMode === "api" ? "bx bx-code-alt" : "bx bx-cog"}
                             action={<Button loading={this.state.switching} content={"Switch to " + (this.props.fileMode === "api" ? "macros" : "API") + " mode"} style={{color: "white", backgroundColor: "#9696A0"}} onClick={this.toggleToAnotherMode}/>}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(ToggleAPIAndMacro)