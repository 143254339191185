import React, {Component} from 'react'
import {bytesToDisplay} from "../Constants/bytesToDisplay";

class PickS3File extends Component {
    constructor(props) {
        super(props);
    }

    saveS3File = () => this.props.saveS3File(this.props.name, this.props.size)

    render() {
        return (
            <div style={{backgroundColor: this.props.idx % 2 === (this.props.isPrefixListEven ? 0 : 1) ? 'whitesmoke' : 'white'}}>
                <div className="row">
                    <div className="col-sm-6">
                        <p onClick={this.saveS3File} className="linkStyle">{this.props.name}</p>
                    </div>
                    <div className="col-sm-3">
                        {bytesToDisplay(this.props.size, 2).toString()}
                    </div>
                    <div className="col-sm-3">
                        {this.props.lastModified}
                    </div>
                </div>
            </div>
        )
    }
}

export default PickS3File