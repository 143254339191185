import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import { push } from 'react-router-redux'
import UseCaseCard from "./UseCaseCard";
import Forms from "./Forms.svg"
import PowerApps from "./PowerApps.svg"
import Dashboard from "./Dashboard.svg"
// import FadeIn from 'react-fade-in';
import { isMobile } from 'react-device-detect'
import IntegrateSources from './IntegrateSources.svg'
import Charts from "./Charts.svg"
import Code from "./Code.svg"

class HowDoesThisWork extends Component {
    constructor(props) {
        super(props);

        this.openModal = this.openModal.bind(this);
    }

    openModal(){
        // this.props.toggleModal("howitworksModal")
        this.props.navigateTo("features")
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    render() {
        return (
            <section className={isMobile ? null : "howDoesThisWork"} style={isMobile ? {textAlign: 'center'} : null}>
                <h1 style={{textAlign: 'center'}}>Use Cases</h1>
                <div className="row">
                    <div className="col-sm-4">
                        <UseCaseCard
                            title="Use Any and All Spreadsheet Data in Your Apps"
                            url="tutorials/react-site-with-no-backend"
                            img={PowerApps}
                            landing={this.props.landing}
                        />
                    </div>
                    {isMobile ? <span><br/><br/></span> : null}
                    <div className="col-sm-4">
                        <UseCaseCard
                            title="Save Data from Web Forms to Spreadsheets"
                            url="tutorials/save-web-form-data"
                            img={Forms}
                            landing={this.props.landing}
                        />
                    </div>
                    {isMobile ? <span><br/><br/></span> : null}
                    <div className="col-sm-4">
                        <UseCaseCard
                            title="Create a Live Dashboard with Google Sheets"
                            url="tutorials/dashboard-with-google-sheets"
                            img={Charts}
                            landing={this.props.landing}
                        />
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-4">
                        <UseCaseCard
                            title="Collaborate With Your Fellow Data Analysts"
                            url="tutorials/share-spreadsheet-data"
                            img={Dashboard}
                            landing={this.props.landing}
                        />
                    </div>
                    {isMobile ? <span><br/><br/></span> : null}
                    <div className="col-sm-4">
                        <UseCaseCard
                            title="Learn About an API by Experimenting with Your Spreadsheet Data"
                            url="tutorials/what-is-an-api"
                            img={Code}
                            landing={this.props.landing}
                        />
                    </div>
                    {isMobile ? <span><br/><br/></span> : null}
                    <div className="col-sm-4">
                        <UseCaseCard
                            title="Integrate Your Google Sheets, Dropbox and Local Spreadsheets"
                            url="tutorials/integrate-google-sheets-dropbox-local-files"
                            img={IntegrateSources}
                            landing={this.props.landing}
                        />
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(HowDoesThisWork)