import React, {Component} from 'react'
import ModalApp from "./Modal"
import LoginForm from "../Authentication/UserPages/LogIn/LoginForm"

class LoginModal extends Component {
    render() {
        return (
            <ModalApp name="loginModal"
                      header={null}
                      size="medium"
                      body={<LoginForm modal importer={false}/>}
            />
        )
    }
}

export default LoginModal