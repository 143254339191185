import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {modifyTeamInfo} from "../../Actions/ModifyTeamInfo";

// const current = new Date();
// const utcOffset = current.getTimezoneOffset();

class UpdateTeamMemberInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: false,
            popupOpen: false,
            popupText: "",
            takingAction: false
        }
    }

    updateNewTeamInfo = (newTeamInfo) => {
        const currTeamInfo = JSON.parse(JSON.stringify(this.props.teamInfo))
        currTeamInfo[this.props.teamIdx] = newTeamInfo

        this.props.modifyTeamInfo(currTeamInfo)

        this.clearEdit()
    }

    updateMemberInfo = (e) => {
        this.setState({ isEditing: true })
        this.props.updateCurrentMembers(this.props.email, this.props.role)
    }
    //
    clearEdit = (e) => {
        this.props.updateCurrentMembers(this.props.email, this.props.role, true)
        this.setState({ isEditing: false })
    }
    //
    saveOrDeleteTeamMemberClick = (e, save) => {
        let message;

        if (save){
            let warning = null;

            if (this.props.role === "manager"){
                if (this.props.updatedValues[this.props.email] === "developer"){
                    warning = <small style={{color: 'orange'}}>Heads Up! Team member will not have dashboard access anymore</small>
                }
            } else if (this.props.role === "developer"){
                if (this.props.updatedValues[this.props.email] === "manager"){
                    warning = <small style={{color: 'orange'}}>Heads Up! Team member will now have dashboard access!</small>
                }
            }

            message =
                <div>
                    <h5 className="thinHeading" style={{color: 'green'}}>Save change for {this.props.email.trim()}?</h5>
                    {warning}
                    <div style={{display: 'grid', gridTemplateColumns: "50% 50%"}}>
                        <div>
                            <Button size="small" color="red"  loading={this.state.takingAction} content="No" onClick={(e) => this.togglePopup(e, false)} fluid/>
                        </div>
                        <div>
                            <Button size="small" color="green" loading={this.state.takingAction} content="Yes" onClick={e => this.editTeamMember("editRole")} fluid/>
                        </div>
                    </div>
                </div>
        } else {
            message =
                <div>
                    <h5 className="thinHeading" style={{color: 'red'}}>Are you sure you want to remove {this.props.email.trim()} from the team?</h5>
                    <div style={{display: 'grid', gridTemplateColumns: "50% 50%"}}>
                        <div>
                            <Button size="small" color="green" content="No" onClick={(e) => this.togglePopup(e, false)} fluid/>
                        </div>
                        <div>
                            <Button size="small" color="red" content="Yes" onClick={e => this.editTeamMember("decline")} fluid/>
                        </div>
                    </div>
                </div>
        }



        this.setState({
            popupText: message,
            popupOpen: true
        })
    }

    editTeamMember = (operation) => {
        this.setState({ takingAction: true })

        const revertState = () => this.setState({ popupOpen: false, popupText: "", isEditing: false, takingAction: false});

        fetch(API_Root + "api/remove-add-or-edit-api-team-member/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                inviteEmail: this.props.email,
                teamPK: this.props.teamInfo[this.props.teamIdx].id,
                role: operation === "editRole" ? this.props.updatedValues[this.props.email] : this.props.role,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                adminAction: true,
                operation: operation
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.setState({takingAction: false, popupOpen: false, popupText: "", isEditing: false}, () => this.updateNewTeamInfo(data.teamInfo))
                }).catch(err => {revertState(); alert("There was an error " + (operation === "decline" ? "deleting" : "changing") + " " + this.props.email )})
            } else {
                revertState(); alert("There was an error " + (operation === "decline" ? "deleting" : "changing") + " " + this.props.email);
            }
        }).catch(err => {revertState(); alert("There was an error " + (operation === "decline" ? "deleting" : "changing") + " " + this.props.email )})

    }

    togglePopup = (e, toggle) => {
        this.setState({
            popupOpen: toggle
        })
    }

    render() {
        let isSaveDisabled = false;

        if (this.state.isEditing){
            if (this.props.email in this.props.updatedValues){
                if (this.props.updatedValues[this.props.email] === this.props.role){
                    isSaveDisabled = true
                }
            }
        }

        return (
            <div style={{textAlign: "right"}}>
                <Button
                    style={{color: "white", backgroundColor: this.state.isEditing ? "#9696A0" : "#6FBB98"}}
                    content={this.state.isEditing ? "Undo" : "Edit"}
                    color={this.state.isEditing ? "red" : "blue"}
                    icon={this.state.isEditing ? "undo" : "edit outline"}
                    onClick={this.state.isEditing ? this.clearEdit : this.updateMemberInfo}
                    disabled={this.props.userRole === "admin"}
                />
                <Popup
                    trigger={<Button disabled={isSaveDisabled || this.props.userRole === "admin"}
                                     loading={this.state.takingAction}
                                     style={{ color: "white", backgroundColor: this.state.isEditing ? "#6FBB98" : "#EE685F" }}
                                     icon={this.state.isEditing ? "save outline" : "trash alternate outline"}
                                     content={this.state.isEditing ? "Save" : "Remove"}
                                     onClick={(e) => this.saveOrDeleteTeamMemberClick(e, this.state.isEditing)}
                    />}
                    on="click"
                    position="top"
                    // onOpen={(e) => this.togglePopup(e, true)}
                    // onClose={(e) => this.togglePopup(e, false)}
                    open={this.state.popupOpen}
                >
                    <div style={{padding: '10px'}}>{this.state.popupText}</div>
                </Popup>
                {this.props.userRole === "admin" ? <small style={{color: 'red', display: 'block'}}>Admin role cannot be changed</small> : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    teamInfo: state.mainState.teamInfo
})

const mapActionsToProps = {
    modifyTeamInfo: modifyTeamInfo
}

export default connect(mapStateToProps, mapActionsToProps)(UpdateTeamMemberInfo)