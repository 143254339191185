import React, {Component} from 'react'
import NoTeams from "../LandingPage/Illustrations/NoTeams.svg"
import { connect } from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import {modifyPaymentModalOrigin} from "../Actions/ModifyPaymentModalOrigin";

class NoTeamsFound extends Component {
    openCreateTeamModal = () => {
        this.props.toggleModal("createTeamModal")
    }

    openUpgradeModal = () => {
        this.props.modifyPaymentModalOrigin("teams")
        this.props.toggleModal("paymentModal")
    }

    render(){
        const borderStyle = "24px solid #F0F0F0"
        const isLowerThanTeam = this.props.tier === "free" || this.props.tier === "pro";

        return (
            <div style={'style' in this.props ? this.props.style : null}>
                <img src={NoTeams} alt="No teams foumd" style={{borderTop: borderStyle, borderLeft: borderStyle, borderRight: borderStyle, display: "block", margin: "0 auto" }}/>
                <div style={{textAlign: "center", marginTop: "16px"}}>
                    <span style={{fontSize: "20px", fontWeight: "700", fontFamily: "Lato", color: "#161E16"}}>{isLowerThanTeam ? "You cannot create a new team 😭" : "You don't have any teams 😭" }</span>
                </div>
                <div style={{textAlign: "center", marginTop: "16px"}}>
                    <button className="callToActionBtn darkCTABtn" style={{width: "400px"}} onClick={isLowerThanTeam ? this.openUpgradeModal : this.openCreateTeamModal}>
                        <div>
                            <div>{isLowerThanTeam ? "Upgrade to create a new team" : "Create a new team"}</div>
                            <div><i className={isLowerThanTeam ? 'bx bx-credit-card' : 'bx bx-plus'}/></div>
                        </div>
                    </button>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyPaymentModalOrigin: modifyPaymentModalOrigin
}

export default connect(mapStateToProps, mapActionsToProps)(NoTeamsFound)