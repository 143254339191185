import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyTutorialStep} from "../../../Actions/ModifyTutorialStep";
import {Button, Icon} from 'semantic-ui-react'
import Icons from "../../../Constants/Icons";
import {toggleModal} from "../../../Actions/ToggleModal";
import {toggleShowingTutorial} from "../../../Actions/ToggleShowingTutorial";
import ActionIndex from "./ActionIndex";
import ActionParameters from "./ActionParameters";
import ActionCodeSnippet from "./ActionCodeSnippet";
import ActionResponse from "./ActionResponse";
import ActionPrivate from "./ActionPrivate";

const actionTypeInfo = {
    readAction: {
        steps: ["", "Parameters", "Private","Response", "CodeSnippet"],
        body: [<ActionIndex actionType="readAction"/>, <ActionParameters actionType="readAction"/>, <ActionPrivate actionType="readAction"/> , <ActionResponse actionType="readAction"/>, <ActionCodeSnippet actionType="readAction" />]
    },
    createAction: {
        steps: ["", "Parameters", "Response", "CodeSnippet"],
        body: [<ActionIndex actionType="createAction"/>, <ActionParameters actionType="createAction"/>, <ActionResponse actionType="createAction"/>, <ActionCodeSnippet actionType="createAction" />]
    },
    deleteAction: {
        steps: ["", "Parameters", "Private", "Response", "CodeSnippet"],
        body: [<ActionIndex actionType="deleteAction"/>, <ActionParameters actionType="deleteAction"/>, <ActionPrivate actionType="deleteAction"/>, <ActionResponse actionType="deleteAction"/>, <ActionCodeSnippet actionType="deleteAction" />,]
    },
    updateAction: {
        steps: ["", "Parameters", "Response", "CodeSnippet"],
        body: [<ActionIndex actionType="updateAction"/>, <ActionParameters actionType="updateAction"/>, <ActionResponse actionType="updateAction"/>, <ActionCodeSnippet actionType="updateAction" />,]
    },
}


class ActionModalBody extends Component {
    constructor(props) {
        super(props);

        this.changeTutorialStep = this.changeTutorialStep.bind(this);
        this.endTutorial = this.endTutorial.bind(this);
        this.undoToActions = this.undoToActions.bind(this);
        this.isPrivateDefault = this.isPrivateDefault.bind(this);
    }

    changeTutorialStep(e, stepIdx){
        let nextStep = stepIdx + 1;
        if (!this.isPrivateDefault()  && ["readAction", "deleteAction"].includes(this.props.actionType)){
            if (stepIdx === 1){
                nextStep = stepIdx + 2
            }
        }

        this.props.modifyTutorialStep(this.props.actionType + actionTypeInfo[this.props.actionType].steps[nextStep])
    }

    endTutorial(){
        this.props.toggleModal(null);
        this.props.modifyTutorialStep("userPreference");
    }

    undoToActions(e){
        this.props.modifyTutorialStep("pickAction")
    }

    isPrivateDefault(e){
        return this.props.tier !== "free"
    }

    render() {
        const strippedStep = this.props.tutorialStep.replace(this.props.actionType, "");
        const stepIdx = actionTypeInfo[this.props.actionType].steps.indexOf(strippedStep);
        const lastStep = stepIdx === actionTypeInfo[this.props.actionType].steps.length - 1;

        let nextButton = <Button color="green" onClick={(e) => this.changeTutorialStep(e, stepIdx)} size="large">
            Next <Icon name="arrow right" />
        </Button>

        if (lastStep){
            nextButton = <Button color="orange" onClick={this.endTutorial} size="large">
                End Tutorial
            </Button>
        }

        let totalStepLength = actionTypeInfo[this.props.actionType].steps.length;
        let currStep = stepIdx + 1;

        if (!this.isPrivateDefault() && ["readAction", "deleteAction"].includes(this.props.actionType)){
            totalStepLength -= 1
            currStep = stepIdx > 2 ? stepIdx : currStep
        }

        return (
            <div id="actionModalBody">
                <div id="actionModalBodyToolbar">
                    <div id="actionModalBodyToolbarHeading">
                        <h4><Icon name={Icons[this.props.actionType]} /> {this.props.actionType.replace("Action", "").toUpperCase()}</h4>
                    </div>
                    <div id="actionModalBodyToolbarButtons">
                        <span><i><b>{(currStep).toString()}</b> {"/" + totalStepLength.toString()}</i></span>
                    </div>
                </div>
                <div id="actionModalBody">
                    {actionTypeInfo[this.props.actionType].body[stepIdx]}
                </div>
                <div style={{textAlign: 'center', marginTop: '10px'}}>
                    {nextButton}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tutorialStep: state.mainState.tutorialStep,
    tier: state.mainState.tier
})

const mapActionsToProps = {
    modifyTutorialStep: modifyTutorialStep,
    toggleModal: toggleModal,
    toggleShowingTutorial: toggleShowingTutorial,
}

export default connect(mapStateToProps, mapActionsToProps)(ActionModalBody)