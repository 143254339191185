import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import API_Root from "../Constants/API_Root";
import { toggleToast } from "../Actions/ToggleToast";
import {modifyImportSheetsInfo} from "../Actions/ModifyImportSheetsInfo";
import { push } from 'react-router-redux'
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import {modifyImporterName} from "../Actions/ModifyImporterName";
import {toggleModal} from "../Actions/ToggleModal";
import ImportNameModal from "./ImportNameModal";

class ImportCreateButton extends Component {
    openImportNameModal = () => this.props.toggleModal("importNameModal")

    render() {
        if ('noImporterCreated' in this.props){
            return (
                <div>
                    <ImportNameModal/>
                    <button className="callToActionBtn lightCTABtnOrange" style={{width: "400px"}} onClick={this.openImportNameModal}>
                        <div>
                            <div>Create a Customized Importer</div>
                            <div><i className='bx bx-plus' /></div>
                        </div>
                    </button>
                </div>
            )
        } else {
            let content = "Create an Importer";
            let size = "medium"
            let fluid = false;

            if ('importerMenuItem' in this.props){
                content = "Create"
                size = "mini"
                fluid = true;
            }

            return (
                <div>
                    <ImportNameModal/>
                    <Button icon="plus"
                            content={content}
                            style={{backgroundColor: "#FFFFFF", border: "1px solid " + gunaldiStyles.orange, color: gunaldiStyles.orange}}
                            onClick={this.openImportNameModal}
                            size={size}
                            fluid={fluid}
                    />
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    importSheetsInfo: state.mainState.importSheetsInfo,
    importerName: state.mainState.importerName
})

const mapActionsToProps = {
    toggleToast: toggleToast,
    modifyImportSheetsInfo: modifyImportSheetsInfo,
    navigateTo: push,
    modifyImporterName: modifyImporterName,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ImportCreateButton)