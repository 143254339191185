import React, {Component} from 'react'
import Number1 from "../Illustrations/Number1.svg"
import Number2 from "../Illustrations/Number2.svg"
import Number3 from "../Illustrations/Number3.svg"
import HowItWorksSegment from "./HowItWorksSegment";


class HowItWorksRow extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-4">
                    <HowItWorksSegment number={Number1} info={this.props.info[0]} idx={0}/>
                </div>
                <div className="col-sm-4">
                    <HowItWorksSegment number={Number2} info={this.props.info[1]} idx={1}/>
                </div>
                <div className="col-sm-4">
                    <HowItWorksSegment number={Number3} info={this.props.info[2]} idx={2}/>
                </div>
            </div>
        )
    }
}

export default HowItWorksRow