import React, {Component} from 'react'

class PageHeaderHelp extends Component {
    render() {
        return (
            <div style={{padding: "5px 8px", background: "#FFFFFF", border: "1px solid #F0F0F0", borderRadius: "4px"}}>
                <div style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                    <div style={{color: "#9696A0", fontFamily: 'Lato', fontWeight: "400", fontSize: "12px"}}><i className='bx bxs-help-circle' style={{marginRight: "6px"}}/> {this.props.header}</div>
                    <div style={{color: "#5499F8", fontFamily: "Lato", fontSize: "12px", fontWeight: "400", textAlign: "right"}}><a href={this.props.url} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}>{this.props.desc} <i className='bx bx-chevron-right' style={{marginLeft: "6px"}}/> </a></div>
                </div>
            </div>
        )
    }
}

export default PageHeaderHelp