import React, {Component} from 'react'
import AceEditor from 'react-ace';
import 'brace/mode/python';
import 'brace/mode/javascript';
import 'brace/mode/ruby';
import 'brace/mode/php';
import 'brace/mode/r';
import 'brace/mode/html';
import 'brace/mode/powershell';
import 'brace/theme/monokai';
import {Menu, Segment} from 'semantic-ui-react'

class CodeSnippet extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: 'apiType' in this.props ? this.props.apiType === 'createAction' ? 'HTML' : 'cURL' : 'cURL'
        }

        this.handleItemClick = this.handleItemClick.bind(this);
    }

    componentDidUpdate(prevProps){
        if ('apiType' in this.props){
            if (prevProps.apiType === "createAction" && this.props.apiType !== "createAction"){
                if (this.state.activeItem === "HTML"){
                    this.setState({ activeItem: "cURL" })
                }
            }
        }
    }

    handleItemClick(e, { name }){
        this.setState({ activeItem: name })
    }

    render() {
        const activeItem = this.state.activeItem;

        let menuItems = [];

        let key = 0;

        for (let language in this.props.code){
            if (language === "HTML"){
                if (this.props.apiType === "createAction"){
                    menuItems.push(
                        <Menu.Item name={language}
                                   key={key}
                                   active={activeItem === language}
                                   onClick={this.handleItemClick} />
                    )

                    key += 1
                }
            } else {
                menuItems.push(
                    <Menu.Item name={language}
                               key={key}
                               active={activeItem === language}
                               onClick={this.handleItemClick} />
                )

                key += 1
            }
        }

        let mode;

        if (activeItem.includes("node") || activeItem.includes("javascript")){
            mode = "javascript"
        }
        else if (activeItem === "r"){
            mode = "r"
        }
        else if (activeItem === "cURL"){
            mode = "powershell"
        }
        else if (activeItem === "HTML"){
            mode = "html"
        }
        else if (activeItem === "PHP"){
            mode = "php"
        }
        else{
            mode = activeItem
        }

        return (
            <div>
                <Segment inverted>
                    <Menu inverted pointing secondary>
                        {menuItems}
                    </Menu>
                    <AceEditor
                        mode={mode}
                        theme="monokai"
                        value={this.props.code[activeItem]}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={this.props.height}
                        width="auto"
                    />
                </Segment>
            </div>
        )
    }
}

export default CodeSnippet