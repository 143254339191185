import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'

class DocsMenuItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: true,
        }
    }

    canClick = () => (this.props.info.subMenuItems.length > 0)

    getIcon = () => {
        let icon;

        if (this.canClick()){
            if (this.state.isOpen){
                icon = "angle down"
            } else {
                icon = "angle right"
            }

            icon = <span style={{paddingRight:"4px"}}><Icon name={icon}/></span>
        } else {
            icon = <span style={{paddingRight:"4px", visibility: "hidden"}}><Icon name="angle right"/></span>
        }

        return icon
    }

    toggleMenu = () => {
        if (this.canClick()){
            this.setState({
                isOpen: !this.state.isOpen
            })
            this.props.modifySubMenuItem(this.props.info.subMenuItems[0])
        } else {
            this.props.modifySubMenuItem(null)
        }
        this.props.modifyMenuItem(this.props.name)
    }

    toggleSubMenuItem = (subMenuItem) =>
    {
        this.props.modifyMenuItem(this.props.name)
        this.props.modifySubMenuItem(subMenuItem)
    }

    render() {
        return (
            <div>
                <div style={{fontWeight: "700", fontSize: "16px", marginBottom: "8px", color: "white", cursor: "pointer"}} onClick={this.toggleMenu}>
                    {this.getIcon()} {this.props.info.label}
                </div>
                {
                    this.canClick() ? this.state.isOpen ?
                        <div style={{marginBottom: "8px"}}>
                            {
                                this.props.info.subMenuItems.map((x, idx) =>
                                    <div style={{paddingLeft: "32px", cursor: "pointer", color: "white", fontWeight: this.props.subMenuItem === x && this.props.menuItem === this.props.name ? "700" : "400"}}
                                         onClick={e => this.toggleSubMenuItem(x)}>
                                        {this.props.info.subMenuItemInfo[x].label}
                                    </div>)
                            }
                        </div> : null : null


                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DocsMenuItem)