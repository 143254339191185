export const getColumnInfo = () => {
    const columnAddlInfo = getColumnAddlInfo()

    delete columnAddlInfo['saved']
    delete columnAddlInfo['dupeCheckName']
    delete columnAddlInfo['dupeCheckInternalName']
    delete columnAddlInfo['error']
    delete columnAddlInfo['internalNameEqual']

    return columnAddlInfo
}

export const getColumnAddlInfo = () => {
    return {
        name: "",
        required: false,
        dataType: "text",
        convert: false,
        cleaningFunctions: {},
        description: "",
        tags: "",
        internalName: "",
        saved: false,
        dupeCheckName: "",
        dupeCheckInternalName: "",
        error: false,
        internalNameEqual: true
    }
}

