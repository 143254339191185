import React, {Component} from 'react'
import AIResponseParams from "./AIResponseParams"
import CreateResponseParams from "./CreateResponseParams"
import DownloadResponseParams from "./DownloadResponseParams"
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

class SampleResponseParametersIndex extends Component {
    constructor(props){
        super(props);
    }

    render() {
        let body;

        switch (this.props.mode){
            case "ai":
                body = <AIResponseParams />
                break
            case "download":
                body = <DownloadResponseParams />
                break
            case "create":
                body = <CreateResponseParams />
                break
            default:
                body = null
        }

        return (
            <div>
                <div style={{ fontSize: "14px", color: gunaldiStyles.subText, marginBottom: "24px" }}>
                    A successful request will return a <code style={{ color: gunaldiStyles.mainText, fontWeight: "700"}}>200 response code</code> and the following parameters
                </div>
                {body}
            </div>
        )
    }
}

export default SampleResponseParametersIndex