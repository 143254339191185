import React, {Component} from 'react'
import {connect} from 'react-redux'
import ImportSheetsButton from './ImportSheetsButton'
import {modifySheetRead} from "../Actions/ModifySheetRead";
import DisplayHeader from "../FileDnD/FilesID/DisplayHeader";

class ImportSheetsBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            copiedSheetRead: JSON.parse(JSON.stringify(this.props.sheetRead))
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    getPKID = () => {
        if ('pkID' in this.props){
            return this.props.pkID
        } else {
            return this.props.editID
        }
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        // const copiedObject = JSON.parse(JSON.stringify(this.props.sheetRead));
        const copiedObject = JSON.parse(JSON.stringify(this.state.copiedSheetRead));
        copiedObject[this.getPKID().toString()] = value;

        // this.props.modifySheetRead(copiedObject)
        this.setState({ copiedSheetRead: copiedObject })
    }

    render() {
        const sheetNames = this.props.fileInformation[this.getPKID()]['sheetNames'];
        // const sheetReadPK = this.props.sheetRead[this.props.editID.toString()];
        const sheetReadPK = this.state.copiedSheetRead[this.getPKID().toString()];

        let sheetOptions = [];

        for (let i=0; i<sheetNames.length; i++){
            let sheetName = sheetNames[i];

            sheetOptions.push(
                <div className="importSheetOptions">
                    <input style={{fontSize: '150%'}}
                           type="radio"
                           name="sheetPicked"
                           value={sheetName}
                           id={sheetName}
                           onChange={this.handleInputChange}
                           checked={sheetReadPK === sheetName}
                           key={i.toString()}
                    />
                    <label htmlFor={sheetName}>
                        <span style={{cursor: 'pointer', padding: '12.5px 0 12.5px 5px'}}>{sheetName}</span>
                    </label>
                </div>
            )
        }

        const btnDisabled = sheetNames.length <= 1;

        return (
            <div>
                <div style={{display: "grid", gridTemplateColumns: "50% 50%", marginBottom: "22px"}}>
                    <div style={{marginTop: "4px"}}>
                        <DisplayHeader header="Change Sheet" />
                    </div>
                    <div style={{textAlign: "right"}}>
                        <ImportSheetsButton btnDisabled={btnDisabled}
                                            pkID={this.getPKID()}
                                            sheetNames={sheetNames}
                                            copiedSheetRead={this.state.copiedSheetRead} />
                    </div>
                </div>
                {sheetOptions}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    fileInformation: state.mainState.fileInformation,
    sheetRead: state.mainState.sheetRead
})

const mapActionsToProps = {
    modifySheetRead: modifySheetRead
}

export default connect(mapStateToProps, mapActionsToProps)(ImportSheetsBody)