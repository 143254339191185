import React, {Component} from 'react'
import TierRestrictionBanner from "./TierRestrictionBanner";
import DocsImage from "../DocsImage";

class ActionsWhitelistURLs extends Component {
    render() {
        return (
            <div>
                <div style={{marginTop: "-8px", marginBottom: "16px"}}>
                    <TierRestrictionBanner plan="business" payment="whitelistDomains"/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">What does Whitelist URLs do?</p>
                    <p>By default, your API is accessible from everywhere (so you do not need to put <code>*</code> in these fields)</p>
                    <p>On the Whitelist URL screen, you can enter URLs that the API can be accessed from</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/WhitelistDomain.png" alt="API Alerts Setup" width="70%"/>
                    <p>Setting up even 1 URL automatically <b>blocks</b> all other URLs</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">What happens when I request the API from a non-whitelist URL</p>
                    <p>You will receive a <code>415</code> status code in the response</p>
                </div>
            </div>
        )
    }
}

export default ActionsWhitelistURLs