import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Input, Popup, Button } from 'semantic-ui-react'
import {isURLValid} from "../../Constants/isURLValid";
import SaveWebhookURL from "./SaveWebhookURL"
import EventSelectionRow from "./EventSelectionRow"
import FooterMessage from "./FooterMessage"
import AnalyticsAccordion from "./AnalyticsAccordion";

class WebhookModalBody extends Component {
    constructor(props) {
        super(props);

        const fileInfo = JSON.parse(JSON.stringify(this.props.fileInformation[this.props.pkID]))

        this.state = {
            webhookURL: fileInfo.webhookURL
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    resetURL = (savedURL) => {
        this.setState({
            webhookURL: savedURL
        })
    }



    render() {
        const savedURL = this.props.fileInformation[this.props.pkID].webhookURL;

        return (
            <div>
                <div className="fileIDHeading" style={{marginBottom: "6px", fontWeight: "600"}}>Webhook URL</div>
                <div style={{display: "grid", gridTemplateColumns: "1fr auto"}}>
                    <div>
                        <input value={this.state.webhookURL} name="webhookURL" onChange={this.handleChange} placeholder="Enter URL" className='inputGray' style={{width: "100%"}} />
                        {isURLValid(this.state.webhookURL) ? null : <small style={{color: "#EE685F", display: "block"}}><i>Please enter a valid url that begins with http:// or https://</i></small>}
                    </div>
                    <div style={{textAlign: "right"}}>
                        {
                            savedURL !== this.state.webhookURL.trim() ?
                                <Popup
                                    content="Reset"
                                    trigger={<Button icon="redo"
                                                     basic
                                                     color="orange"
                                                     onClick={e => this.resetURL(savedURL)}
                                                     circular />}
                                /> : null
                        }
                        <SaveWebhookURL webhookURL={this.state.webhookURL}/>
                    </div>
                </div>
                <div style={{marginTop: "16px"}}>
                    <div className="fileIDHeading" style={{marginBottom: "6px", fontWeight: "600"}}>Choose events that will call Webhook</div>
                    <EventSelectionRow />
                </div>
                <FooterMessage />
                <br/>
                <AnalyticsAccordion pk={this.props.pk} pkID={this.props.pkID}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    editID: state.mainState.editID,
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(WebhookModalBody)