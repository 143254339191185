import React, {Component} from 'react'
import { Checkbox } from 'semantic-ui-react'
import InfoPopup from "../../../SharedComponents/InfoPopup"

class TogglePrivate extends Component {
    constructor(props) {
        super(props);

        this.togglePrivate = this.togglePrivate.bind(this);
    }

    togglePrivate(){
        this.props.togglePrivate(!this.props.isPrivate)
    }

    render() {
        return (
            <span style={{float:'right'}}>
                <Checkbox checked={this.props.isPrivate} toggle onChange={this.togglePrivate} label="View with Private API?" />
                <span style={{fontSize: "small"}}><InfoPopup info={<span>A Private API is secured with an <code>accessKey</code> and a <code>secretKey</code>. An API cannot be private on the free account.</span>}/></span>
            </span>
        )
    }
}

export default TogglePrivate