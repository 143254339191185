import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {toggleModal} from "../Actions/ToggleModal";
import {modifyPaymentModalOrigin} from "../Actions/ModifyPaymentModalOrigin";
import BannerAlert from "../SharedComponents/BannerAlert";

class UpgradeToTeam extends Component {
    openUpgradeModal = () => {
        this.props.toggleModal(null)
        this.props.modifyPaymentModalOrigin(this.props.higherTier)
        this.props.toggleModal("paymentModal")
    }

    render() {
        return (
            <div>
                <BannerAlert
                    icon="bx bx-credit-card"
                    header={this.props.header}
                    content="Upgrade to a Higher Tier Plan"
                    action={<Button
                        onClick={this.openUpgradeModal}
                        content={<span>Upgrade <span role="img" aria-label="smile" style={{paddingLeft: '2px'}}>😀</span></span>}
                        color="green"
                    />}
                    type="error"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyPaymentModalOrigin: modifyPaymentModalOrigin
}

export default connect(mapStateToProps, mapActionsToProps)(UpgradeToTeam)