import React, {Component} from 'react'
import AceEditor from 'react-ace';
import 'brace/mode/html';
import PrivateToggle from "./PrivateToggle";
import CommentSection from "../CommentSection";

class SaveForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            isPrivate: false
        }

        this.togglePrivate = this.togglePrivate.bind(this);
        this.getLineOfCode = this.getLineOfCode.bind(this);
    }

    togglePrivate(){
        const changedPrivate = !this.state.isPrivate;

        this.setState({ isPrivate: changedPrivate })
    }

    getLineOfCode(line){
        return this.state.isPrivate ? (line + 2).toString() : line.toString();
    }

    render() {
        let urlEnd = "'https://api.apispreadsheets.com/data/410/'";
        let keysHeader = '';

        if (this.state.isPrivate){
            urlEnd = "'https://api.apispreadsheets.com/data/881/'"
            keysHeader = "        headers: {\n        accessKey: '06faba59f4e7f03367b7fdcf7046d8aa',\n        secretKey: '52d875545e5111cda03e4aa4b6919ad8'},\n"
        }

        const url = '        url:' + urlEnd + ',\n'

        // const keyHeader = this.state.isPrivate ? "accessKey: " + generateRandomKey(32) + ",\nsecretKey: " + generateRandomKey(32) + "\n," : "secretKey"


        const formCode = '<!DOCTYPE html>\n' +
            '<html>\n' +
            '<head>\n' +
            '<title>Save Web Form Data to Spreadsheets - Contact Form</title>\n' +
            '<script\n' +
            '  src="https://code.jquery.com/jquery-3.4.1.js"\n' +
            '  integrity="sha256-WpOohJOqMqqyKL9FccASB9O0KwACQJpFTUBLTYOVvVU="\n' +
            '  crossorigin="anonymous"></script>\n' +
            '<script>\n' +
            'function SubForm (){\n' +
            '    $.ajax({\n' +
            url +
            // '        url:\'https://api.apispreadsheets.com/data/410/\',\n' +
            '        type:\'post\',\n' +
            '        data:$("#myForm").serializeArray(),\n' +
            keysHeader +
            '        success: function(){\n' +
            '          alert("Form Data Submitted :)")\n' +
            '        },\n' +
            '        error: function(){\n' +
            '          alert("There was an error :(")\n' +
            '        }\n' +
            '    });\n' +
            '}\n' +
            '</script>\n' +
            '</head>\n' +
            '<body>\n' +
            '  <form id="myForm">\n' +
            '    <label>Full Name</label>\n' +
            '    <br/>\n' +
            '    <input name="full_name" />\n' +
            '    <br/>\n' +
            '    <label>Email</label>\n' +
            '    <br/>\n' +
            '    <input name="email" />\n' +
            '    <br/>\n' +
            '    <label>Age</label>\n' +
            '    <br/>\n' +
            '    <input type="radio" id="age" name="age" value="18-35" /> 18-35<br/>\n' +
            '    <input type="radio" id="age" name="age" value="35+" /> 35+<br/>\n' +
            '    <br/>\n' +
            '    <label>Message</label>\n' +
            '    <br/>\n' +
            '    <textarea name="message">\n' +
            '    </textarea>\n' +
            '    <br/>\n' +
            '    <br/>\n' +
            '  </form>\n' +
            '  <button onclick="SubForm()">Submit</button>\n' +
            '</body>\n' +
            '</html>'

        const jqueryCode = '<script  src="https://code.jquery.com/jquery-3.4.1.js"  integrity="sha256-WpOohJOqMqqyKL9FccASB9O0KwACQJpFTUBLTYOVvVU="  crossorigin="anonymous">' +
            '\n</script>';

        const subFormCode = '<script>\n' +
            'function SubForm (){\n' +
            '    $.ajax({\n' +
            url +
            '        type:\'post\',\n' +
            '        data:$("#myForm").serializeArray(),\n' +
            keysHeader +
            '        success: function(){\n' +
            '          alert("Form Data Submitted :)")\n' +
            '        },\n' +
            '        error: function(){\n' +
            '          alert("There was an error :(")\n' +
            '        }\n' +
            '    });\n' +
            '}\n' +
            '</script>'

        return (
            <div>
                <h1>Set up a contact form in an HTML file</h1>
                <p><i>Okay, if you don’t know any HTML, then this might be a steep learning curve. The example is small so you should Google terms you don’t understand and it might make sense.</i></p>
                <hr/>
                <p>Let’s create a form in HTML. It’s a basic contact form and doesn’t contain any styling.</p>

                <p>The form will look like this:</p>
                <div className="row">
                    <div className="col-sm-4" />
                    <div className="col-sm-4" >
                        <figure>
                            <img src="https://miro.medium.com/max/197/1*UIaHUVHTPqVAKL9tSxBtDw.png" height="100%" width="90%" alt="WebFormData2" />
                            <figcaption>How the form will look</figcaption>
                        </figure>
                    </div>
                    <div className="col-sm-4" />
                </div>
                <p><b>Open up a blank file in the text editor you use for programming, copy and paste the code below and save the file with a [.html] extension. We saved ours as ContactForm.html</b></p>
                <p>We will walk through the HTML part now and the Javascript part later.</p>
                <PrivateToggle togglePrivate={this.togglePrivate} isPrivate={this.state.isPrivate} />
                <div id="formCode">
                    <AceEditor
                    mode="html"
                    theme="monokai"
                    value={formCode}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{$blockScrolling: true}}
                    height={375}
                    width="auto"
                    />
                </div>
                <br/>
                <p>The contact form has 4 fields.</p>
                <p>Pay attention to the <b><i>name attribute</i></b> of {'the <input> tags'}. These will be the column headers of our spreadsheet we save the data in and they MUST match up.</p>
                <p>We are going to <i>name</i> the four input tags as follows. The lines refer to the line of code:</p>
                <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px'}}>
                <ol>
                    <li>
                        <span style={{marginBottom: '0px'}}><b>full_name, <i>line {this.getLineOfCode(29)}</i></b></span>
                        <br/>
                        <span>This will be a text field with the full name of the person</span>
                    </li>
                    <li>
                        <span style={{marginBottom: '0px'}}><b>email, <i>line {this.getLineOfCode(33)}</i></b></span>
                        <br/>
                        <span>This will be a text field with the email of the person</span>
                    </li>
                    <li>
                        <span style={{marginBottom: '0px'}}><b>age, <i>lines {this.getLineOfCode(37)}-{this.getLineOfCode(38)}</i></b></span>
                        <br/>
                        <span>This will be a radio selection field with two age options. <b>18–35</b> and <b>35+</b>. We will denote them in the <i>value attribute</i> of the radio fields</span>
                    </li>
                    <li>
                        <span style={{marginBottom: '0px'}}><b>message, <i>line {this.getLineOfCode(42)}</i></b></span>
                        <br/>
                        <span>This will be a textarea that will contain the message the person types</span>
                    </li>
                </ol>
                </div>
                <br/>
                <p>
                    All four {'<input>'} tags will be inside a {'<form>'} tag <i>(line {this.getLineOfCode(26)})</i> that we will give an <i>id attribute</i> of <b>myForm</b>. Do not give it any other <i>id</i> as the data submission to spreadsheet function that we will write later in Javascript is dependent on it.
                </p>
                <p>
                    Finally, our main component will be a {'<button>'} <i>(line {this.getLineOfCode(47)})</i> that will be submitting the data. In its <i>onclick attribute</i> we are going to specify the function <b>SubForm()</b>.
                </p>
                <p>
                    Again, do not give the function another name as we will be writing this exact function later in Javascript to handle submitting the data.
                </p>
                <hr/>
                <br/>
                <h1>Prepare your Google Sheet or Spreadsheet that the data will be saved to</h1>
                <br/>
                <p><b>1. Create a blank Google Sheet or Spreadsheet</b></p>
                <img src="https://images2.imgbox.com/6c/f4/RE63O0Wc_o.png" height="100%" width="100%" alt="GoogleSheet" />
                <p><b>2. Write column headers equal to the <i>name(s)</i> of the {'<input>'} tags</b></p>
                <img src="https://images2.imgbox.com/e4/00/W3IRpu6h_o.png" height="100%" width="100%" alt="GoogleSheet" />
                <br/><br/>
                <p>
                    Each time someone submits the data, it will save in the appropriate column. <i>This step is super important so make sure these headers are EXACTLY the same as the names of the input tags above.</i>
                </p>
                <br/>
                <p><b>3. Save this spreadsheet with any name you want</b></p>
                <img src="https://images2.imgbox.com/51/99/MEvMyHbX_o.png" height="100%" width="100%" alt="GoogleSheet" />
                <br/><br/>
                <hr/>
                <br/>
                <h1>Get your API URL from API Spreadsheets</h1>
                <br/>
                <p><b>1. Sign up for an Account</b></p>
                <p>• Click on Sign Up in the Navbar</p>
                <img src="https://miro.medium.com/max/1277/1*OUjjkRu_Ei4ITqHYsiC3CA.png" height="100%" width="100%" alt="APISignup" />
                <br/><br/>
                <p>• Sign up with your email and password</p>
                <img src="https://miro.medium.com/max/765/1*gjWBxs_gN8O7J5-krhY6Dg.png" height="65%" width="65%" alt="API Spreadsheets" />
                <br/><br/>
                <p><b>2. Upload the file you created in Step 2</b></p>
                <img src="https://images2.imgbox.com/0b/8e/MQJEhSHt_o.png" height="100%" width="100%" alt="API Dashboard" />
                <br/><br/>
                <p><b>3. Copy the Create API URL for your file</b></p>
                <p>Copy the API URL from the <b>Create</b> tab for your file and save it somewhere handy. We are going to be using this in the Javascript below to submit the data.</p>
                <img src="https://images2.imgbox.com/25/58/k0WN6wLg_o.png" height="100%" width="100%" alt="API Dashboard" />
                <br/><br/>
                <p><i>4. [Optional] If your API is <b>Private</b> you will also need to copy the Access and Secret Key and save them somewhere handy</i></p>
                <figure>
                    <img style={{border: "1px solid lightgrey"}} src="https://images2.imgbox.com/26/d5/hUuF89bA_o.png" height="100%" width="100%" alt="WebFormData50"/>
                    <figcaption style={{textAlign: 'center'}}>You only need to do this for a Private API</figcaption>
                </figure>
                <br/><br/>
                <h1>Configure the Javascript to submit data from the form</h1>
                <p><i>We are going to use AJAX to submit the form. Again, if you are not familiar with Javascript, jQuery or AJAX, it might be a steep learning curve.</i></p>
                <br/>
                <p>We are going to include the full HTML code here again. The Javascript part is within the {'<head>'} tags <i>(lines 5–{this.getLineOfCode(23)})</i>.</p>
                <PrivateToggle togglePrivate={this.togglePrivate} isPrivate={this.state.isPrivate} />
                <div id="formCode">
                    <AceEditor
                        mode="html"
                        theme="monokai"
                        value={formCode}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={375}
                        width="auto"
                    />
                </div>
                <br/><br/>
                <p><b>1. Add jQuery from a CDN <i>(lines 5-8)</i></b></p>
                <p>
                    We need to ensure the FULL jQuery library is included in our HTML to use AJAX.
                </p>
                <div id="formCode">
                    <AceEditor
                        mode="html"
                        theme="monokai"
                        value={jqueryCode}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={50}
                        width="auto"
                    />
                </div>
                <br/><br/>
                <p><b>2. Write the SubForm() function between two script tags <i>(lines 9–{this.getLineOfCode(23)})</i></b></p>
                <p>
                    The <b>SubForm()</b> function is below. You will replace the URL below with the <b>URL</b> you obtained in <b>Step 3</b>. The rest of the function will remain the same.
                </p>
                <br/>
                <p>
                    We will go through this function in more detail at the end of this post but for now all you need to know is if the data was successfully saved an alert will pop up saying <b>Form Data Submitted :)</b> otherwise an alert will pop up saying <b>There was an error :(</b>.
                </p>
                <div id="formCode">
                    <AceEditor
                        mode="html"
                        theme="monokai"
                        value={subFormCode}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={200}
                        width="auto"
                    />
                </div>
                <br/>
                <p>
                    That’s it! We have done everything needed to save data from our web form to our spreadsheet.
                </p>
                <br/>
                <p>
                    Now let’s test it out and see how to view our data.
                </p>
                <br/>
                <hr />
                <br/>
                <h1>Testing</h1>
                <p><b>1. Save your HTML file and open it in a browser to see the contact form</b></p>
                <img src="https://miro.medium.com/max/336/1*KFBabw6U0V_mnJNaArqx9Q.png" height="50%" width="50%" alt="ContactForm" />
                <br/><br/>
                <p><b>2. Fill out your form and click <i>Submit</i></b></p>
                <p>An alert window should pop up saying <b>Form Data Submitted :)</b></p>
                <img src="https://miro.medium.com/max/861/1*_OhLo7s5SbmEgQL4t1TUAg.png" height="80%" width="80%" alt="WebFormData" />
                <br/><br/>
                <p>If the alert window says <b>There was an error :(</b> then feel free to email us at info@apispreadsheets.com and we can try to help you out.</p>
                <br/>
                <p><b>3. View your data in your spreadsheet or Google Sheet!!</b></p>
                <p>If you are using <b>Google Sheets</b>, you can open your Sheet and it will have all the contact details!</p>
                <img src="https://images2.imgbox.com/74/96/BiqGtyBJ_o.png" height="100%" width="100%" alt="GoogleSheetDetails"/>
                <br/><br/><br/>
                <p>If you are using a <b>Spreadsheet</b>, do the following:</p>
                <ul>
                    <li><i>Log in to API Spreadsheets</i></li>
                    <li>Click on the <b>Files</b> tab</li>
                    <br/>
                    <img src="https://images2.imgbox.com/87/76/QUWw2852_o.png" height="100%" width="100%" alt="API Spreadsheets Tab" />
                    <br/><br/>
                    <li>Find your spreadsheet and click on the <b>Download File button</b> to download it</li>
                    <br/>
                    <img src="https://images2.imgbox.com/81/43/tZ76sXXl_o.png" height="100%" width="100%" alt="Excel Spreadsheets" />
                    <br/><br/>
                    <li>Open it up and you should see the data we submitted above!!!</li>
                    <br/>
                    <img src="https://miro.medium.com/max/541/1*kVMOMZ_AU4Gw4bbfmtCnLg.png" height="80%" width="80%" alt="WebFormData12" />
                    <br/><br/>
                </ul>
                <hr />
                <br/>
                    <h1>Some Parting Thoughts</h1>
                <ul>
                    <li>You can keep submitting data to this spreadsheet and it will contain all the records whenever you download it.</li>
                    <li>If you need to manually change something in the spreadsheet, then you can change it and <b>replace it</b> using the <b>Replace File</b> button. This will preserve your API URL and still contain any updates you make. <i>NOTE: whatever you reupload will be the master version so use this with caution.</i></li>
                    <li>Finally, the Javascript function to submit the data is below. Let’s walk through it:</li>
                </ul>
                <br/>
                <PrivateToggle togglePrivate={this.togglePrivate} isPrivate={this.state.isPrivate} />
                <div id="formCode">
                    <AceEditor
                        mode="html"
                        theme="monokai"
                        value={subFormCode}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={200}
                        width="auto"
                    />
                </div>
                <ol>
                    <li>We use jQuery syntax to call ajax. That’s what the <b>$.ajax</b> does</li>
                    <li>The <b>url</b> is the URL we are calling the request. In this case it is a <b>type</b> post request.</li>
                    <li>The <b>data</b> is our data from <b>#myForm</b>. The <i>serialiazeArray()</i> method creates a JavaScript array of objects, ready to be encoded as a JSON string (don’t worry if you don’t understand this too deeply. As long as you name your inputs and spreadsheet headers properly this whole demo should work)</li>
                    {this.state.isPrivate ? <li>The <b>accessKey</b> and <b>secretKey</b> makes our API Private and functions like a Username & Password. It's only needed for a Private API</li> : null}
                    <li>If the request was successful then the <b>success</b> function is called.</li>
                    <li>If the request had an error then the <b>error</b> function is called.</li>
                </ol>
                <p>If you have any further questions, feel free to email us at <a href="mailto:info@lovespreadsheets.com">info@lovespreadsheets.com!</a> We can’t wait to see what you build.</p>
                <p>Checkout our other <a href="/tutorials">tutorials! </a> </p>
                <CommentSection commentDiv={<div className="fb-comments" data-href="https://www.apispreadsheets.com/tutorials/save-web-form-data-to-spreadsheets" data-numposts="5" data-width="" />}/>
            </div>
        )
    }
}

export default SaveForm