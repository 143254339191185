import React, {Component} from 'react'
import {connect} from "react-redux";
import FileList from "../LandingPage/Illustrations/FileList.svg"
import {toggleModal} from "../Actions/ToggleModal";
import {push} from "react-router-redux";
import {modifyAPIMenuItem} from "../Actions/ModifyAPIMenuItem";

class NoFilesUploaded extends Component {
    constructor(props){
        super(props);
    }

    goToUpload = (e) => {
        this.props.modifyAPIMenuItem("upload")
        // this.props.navigateTo("/api?menuItem=upload")
    }

    goToImport = () => {
        this.props.toggleModal("importNameModal")
    }

    goToFiles = () => {
        this.filesLink.click()
    }

    render(){
        const borderStyle = "24px solid #F0F0F0"
        const isTeam = 'isTeam' in this.props;
        const isImporter = 'isImporter' in this.props || 'isImporterPage' in this.props;

        let msg = <span>No files have been uploaded to <br/>your account 😭</span>

        if (isTeam){
            msg = <span>No files have been linked to this team 😭</span>
        }

        if (isImporter){
            msg = <span>No files have been imported to your account 😭</span>
        }

        return (
            <div style={'style' in this.props ? this.props.style : null}>
                <a href="/api?menuItem=upload" style={{display: 'none'}} ref={uploadLink => this.uploadLink = uploadLink}>Upload</a>
                <a href="/import" style={{display: 'none'}} ref={importLink => this.importLink = importLink}>Import</a>
                <a href="/api?menuItem=files" style={{display: 'none'}} ref={filesLink => this.filesLink = filesLink}>Files</a>
                <img src={FileList} alt="No files uploaded" style={{borderTop: borderStyle, borderLeft: borderStyle, borderRight: borderStyle, display: "block", margin: "0 auto" }}/>
                <div style={{textAlign: "center", marginTop: "16px"}}>
                    <span style={{fontSize: "20px", fontWeight: "700", fontFamily: "Lato", color: "#161E16"}}>{msg}</span>
                </div>
                {
                    isImporter ? null :
                        <div style={{textAlign: "center", marginTop: "16px"}}>
                            <button className="callToActionBtn darkCTABtn" style={{width: "400px"}} onClick={this.goToUpload}>
                                <div>
                                    <div>Upload a file to convert it to API</div>
                                    <div><i className='bx bxs-cloud-upload' /></div>
                                </div>
                            </button>
                        </div>
                }
                {
                    'isImporter' in this.props ? null :
                        'isImporterPage' in this.props || isTeam ?
                        <div style={{textAlign: "center", marginTop: "16px"}}>
                            <button className={isTeam ? "callToActionBtn lightCTABtn" : "callToActionBtn lightCTABtnOrange"} style={{width: "400px"}} onClick={isTeam ? this.goToFiles : this.goToImport}>
                                <div>
                                    <div>{isTeam ? "Link a file to this team" : "Create an importer for users to upload their files"}</div>
                                    <div><i className='bx bx-plus' /></div>
                                </div>
                            </button>
                        </div> : null
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal,
    modifyAPIMenuItem: modifyAPIMenuItem
}

export default connect(mapStateToProps, mapActionsToProps)(NoFilesUploaded)
