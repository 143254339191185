import React, {Component} from 'react'
import TutorialsDetails from "./TutorialsDetails";
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";
import {authorDetails} from "../../Constants/authorDetails";
import { Image } from 'semantic-ui-react'
import TutorialShare from "./TutorialShare";
import { BrowserView } from 'react-device-detect'

class TutorialsDisplayIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            topic: this.getTopicIndex(),
            link: this.getLink(),
        }

        this.getTopicIndex = this.getTopicIndex.bind(this);
        this.getLink = this.getLink.bind(this);
    }

    getTopicIndex(){
        const url =  window.location.href
        let param = url.substring(url.indexOf("tutorials") + 10, url.length).replace("/", "");

        for (let i=0; i<TutorialsDetails.length; i++){
            if (TutorialsDetails[i].title === param){
                return i
            }
        }

        return 0
    }

    getLink(){
        const topicIndex = this.getTopicIndex();

        return TutorialsDetails[topicIndex].title
    }

    componentDidMount(){
        document.title = "API Spreadsheets - " + capitalizeHyphenated(TutorialsDetails[this.state.topic].title);

        document.getElementsByTagName('meta')["description"].content = TutorialsDetails[this.state.topic].desc;

    }

    render() {
        const guideInfo = TutorialsDetails[this.state.topic];

        const title = guideInfo.header;
        // const desc = guideInfo.desc;
        const writtenBy = <span><Image src={authorDetails[guideInfo.tutorialInfo.writtenBy]['pic']} avatar /> <a href={authorDetails[guideInfo.tutorialInfo.writtenBy]['social']} target="_blank" rel="noopener noreferrer">{guideInfo.tutorialInfo.writtenBy}</a></span>
        const writtenDt = <span><i>{guideInfo.tutorialInfo.date}</i></span>

        return (
            <div className="guidePost">
                <BrowserView>
                    <TutorialShare url={guideInfo.title} header={title}/>
                </BrowserView>
                <div className="tutorialsDisplayIndex">
                    <h1 className="thinHeading" style={{fontSize: '350%'}}>{title}</h1>
                    {/*<h6 className="thinHeading" style={{color: 'gray'}}>{desc}</h6>*/}
                    <b>by</b> {writtenBy} <b>on</b> {writtenDt}
                    <br/><br/><br/>
                    {guideInfo.article}
                </div>
            </div>
        )
    }
}

export default TutorialsDisplayIndex