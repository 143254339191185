export const getAMPMTimeFrom24Hours = (hour24) => {
    let hourInt = parseInt(hour24.substring(0, 2))
    if (hourInt > 12){
        let hour12 = hourInt - 12;
        hour24 = (hour12 < 10 ? "0" : "") + hour12.toString() + ":00 PM"
    } else{
        if (hourInt === 0){
            hour24 = "12:00 AM"
        } else if (hourInt === 12){
            hour24 = hour24 + " PM"
        } else {
            hour24 = hour24 + " AM"
        }
    }

    return hour24
}