import React from 'react';
import { Icon } from 'semantic-ui-react';

class DeveloperOrBusinessRow extends React.Component {
    handleSelect = (isDeveloper) => {
        this.props.changeState({ isDeveloper });
    };

    render() {
        const { isDeveloper } = this.props; // Assuming `isDeveloper` is passed as a prop to indicate the current selection

        return (
            <div>
                <div>
                    <h4 style={{
                        textAlign: 'center', fontFamily: "Lato",
                        fontWeight: "700",
                        fontSize: "24px",
                        color: "#161E16",
                        paddingBottom: "24px"
                    }}>Personalize your experience by selecting your role</h4>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div style={{display: 'flex', gap: '20px', padding: '20px', justifyContent: 'right'}}>
                            {/* Programmer Button */}
                            <button
                                onClick={() => this.handleSelect(true)}
                                style={{
                                    border: `2px solid ${isDeveloper ? '#5499F8' : 'gray'}`, // Blue border if selected
                                    backgroundColor: 'white',
                                    padding: '20px 40px',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    color: `${isDeveloper ? '#5499F8' : 'gray'}`,
                                    borderRadius: '8px',
                                    transition: 'background-color 0.3s, border-color 0.3s', // Smooth transition on hover and selection change
                                }}
                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e6f0ff'}
                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                            >
                                <Icon name="code" size="large"/> {/* Icon for Developer */}
                                I am a programmer
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div style={{display: 'flex', gap: '20px', padding: '20px'}}>
                            {/* Business Person Button */}
                            <button
                                onClick={() => this.handleSelect(false)}
                                style={{
                                    border: `2px solid ${!isDeveloper ? '#5499F8' : 'gray'}`, // Blue border if selected
                                    backgroundColor: 'white',
                                    padding: '20px 40px',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: `${isDeveloper ? 'gray' : '#5499F8'}`,
                                    gap: '10px',
                                    borderRadius: '8px',
                                    transition: 'background-color 0.3s, border-color 0.3s', // Smooth transition on hover and selection change
                                }}
                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e6f0ff'}
                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                            >
                                <Icon name="briefcase" size="large"/> {/* Icon for Business Person */}
                                I am a business person
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeveloperOrBusinessRow;
