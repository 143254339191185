import React from 'react';
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

const OverallSettings = ({ settings, onSettingsChange }) => {
    const handleChange = (field) => (event) => {
        const value = field === 'saveInSpreadsheet' ? event.target.checked : event.target.value;
        onSettingsChange(field, value);
    };

    const labelStyle = {fontFamily: "Lato", fontWeight: '500', fontSize: "14px", color: "#5E645E", marginTop: "16px"}

    return (
        <div>
            <div style={{fontFamily: "Lato", fontSize: "16px", fontWeight: "700", color: gunaldiStyles.subText}}>
                Overall Settings
            </div>
            <div className="displayBox" style={{marginBottom: "16px", marginTop: "16px"}}>
                <div className="row">
                    <div className="col-sm-4">
                        <div>
                            <span style={labelStyle}>Title</span>
                        </div>
                        <div>
                            <input className="inputGray" type="text" value={settings.title}
                                   style={{width: '100%'}}
                                   onChange={handleChange('title')}/>
                        </div>
                    </div>
                    <div className="col-sm-8">
                        <div>
                            <span style={labelStyle}>Subheader</span>
                        </div>
                        <div>
                            <input className="inputGray" type="text" value={settings.subtitle}
                                   style={{width: '100%'}}
                                   onChange={handleChange('subtitle')}/>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingTop: "16px"}}>
                    <div className="col-sm-4">
                        <label><span style={{...labelStyle, ...{paddingRight: "8px"}}}>Background Color </span><input type="color" value={settings.backgroundColor}
                                                        onChange={handleChange('backgroundColor')}/></label>
                    </div>
                    <div className="col-sm-4">
                        <label><span style={{...labelStyle, ...{paddingRight: "8px"}}}>Label Color </span><input
                            type="color" value={settings.labelColor} onChange={handleChange('labelColor')}/></label>
                    </div>
                    <div className="col-sm-4" style={{ paddingTop: "8px"}}>
                        <label><span style={{...labelStyle, ...{paddingRight: "8px"}}}>Save each submission in a spreadsheet</span>
                            <input type="checkbox" checked={settings.saveInSpreadsheet}
                                   onChange={handleChange('saveInSpreadsheet')}/>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OverallSettings;
