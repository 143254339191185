import React, {Component} from 'react'
import TierRestrictionBanner from "../Actions/TierRestrictionBanner";
import DocsImage from "../DocsImage";

class DatabaseIntroduction extends Component {
    render() {
        const isImporter = 'importer' in this.props;

        return (
            <div>
                <div style={{marginTop: "-8px", marginBottom: "16px"}}>
                    <TierRestrictionBanner plan="business" payment="database"/>
                </div>
                <div className="docsSection">
                    <p>You can connect your <b>MySQL</b> or <b>Postgres</b> databases on your API Spreadsheets account</p>
                    {
                        isImporter ?
                            <p>Then, you can select tables from your database to store the data from the spreadsheet in the <span className="linkStyle" onClick={e => this.props.setBothMenuItems("creatingImporter-destinations")}>destinations section</span></p>
                            : <p>Then, you can interact with tables in your database using <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-introduction")}>the exact same API used for spreadsheets</span></p>
                    }
                    <p>The overall steps for this process are as follows. You can read about each step in more detail in its respective section </p>
                    <ol>
                        <li><span className="linkStyle" onClick={e => this.props.setBothMenuItems("database-connect")}>Connect</span> your <b>MySQL</b> or <b>Postgres</b> database</li>
                        {
                            isImporter ?
                                <li><span className="linkStyle" onClick={e => this.props.setBothMenuItems("database-selectTable")}>Select tables</span> from your database where the spreadsheet data should be stored</li>
                                : <li><span className="linkStyle" onClick={e => this.props.setBothMenuItems("database-createAPI")}>Create an API</span> from tables in your database</li>
                        }
                        {
                            isImporter ?
                                null :
                                <li><span className="linkStyle" onClick={e => this.props.setBothMenuItems("database-useAPI")}>Use the API</span> to <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-read")}>read data</span>, <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-create")}>create data</span>, <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-update")}>update data</span>, <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-delete")}>delete data</span></li>
                        }
                    </ol>
                    <p>In this guide we will give a quick overview of each step</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Connect Your Database</p>
                    {
                        isImporter ?
                        <div>
                            <p>Go to the <b>Destinations</b> section of your importer</p>
                            <p>Select the database type you wish to connect & click on <b>Add New Database</b> button</p>
                            <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterDatabase-Select.png"
                                       width="100%"
                                       alt='Database Columns' />
                            <p>Click on <b>Add New Database</b> button</p>
                            <br/>
                        </div> :
                        <div>
                            <p>Go to the database menu item and click on Connect New Database</p>
                            <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Database/DatabaseIndex.png" alt="Connect New Database" width="100%"/>
                        </div>
                    }
                    <p>Enter your database credentials and click Connect</p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/get+data+without+backend/mysql+enter+credentials.png"
                        width="100%"
                        alt="Enter database credentials"
                    />
                </div>
                {
                    isImporter ?
                        <div className="docsSection">
                            <p className="docsHeader">Select table(s) from your database</p>
                            <p>After your database is successfully connected, you can select the table(s) where you wish to store the data</p>
                            <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterDatabase-SelectTables.png"
                                       alt='Database Columns' />
                            <p>Click <b>Save Destinations</b></p>
                            <DocsImage
                                src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Destinations-Save.png"
                                width="100%"
                                alt="Save Destinations API Spreadsheets"
                            />
                            <p>Now every time someone uploads a file through your importer, the data will be saved in your database table</p>
                            <p><span className="linkStyle" onClick={e => this.props.setBothMenuItems("database-selectTable")}>Read more about this process here</span></p>
                        </div>
                        :
                        <div className="docsSection">
                            <p className="docsHeader">Create an API</p>
                            <p>If your database connection was successful, you will be navigated to your database's home page. Click on <b>Add New API</b> to create an API</p>
                            <DocsImage
                                src="https://apispreadsheets-content.s3.amazonaws.com/get+data+without+backend/add+new+API.png"
                                width="100%"
                                alt="Database Home Page"
                            />
                            <p>You will then be taken to the <b>Create API from your database</b> page</p>
                            <DocsImage
                                src="https://apispreadsheets-content.s3.amazonaws.com/get+data+without+backend/create+API+from+database+page.png"
                                width="100%"
                                alt="Create API from database"
                            />
                            <p>On the <b>Create API from your database</b> page, you can enter a query that this API will read data from</p>
                            <p>Such as <code>select * from customers;</code></p>
                            <p>Once your configuration is complete, you can click on the <b>Save API</b> button</p>
                        </div>
                }
                {
                    isImporter ? null :
                        <div className="docsSection">
                            <p className="docsHeader">Use your API</p>
                            <p>After you click the <b>Save API</b> button, you will be taken to your API File page (if your query worked)</p>
                            <p>On this page you can find your API URL</p>
                            <DocsImage
                                src="https://apispreadsheets-content.s3.amazonaws.com/get+data+without+backend/oie_6ApVEVZTxrow.png"
                                width="100%"
                                alt="Database file page"
                            />
                            <p>
                                You can now use this API URL to <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-read")}>read data</span>, <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-create")}>create data</span>, <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-update")}>update data</span>, <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-delete")}>delete data</span>
                            </p>
                            <p>Let's open the API URL in a browser to see the live data from your <code>customers</code> table</p>
                            <DocsImage
                                src="https://apispreadsheets-content.s3.amazonaws.com/get+data+without+backend/JSON+data+4+titles.png"
                                alt="Read data from API URL"
                            />
                        </div>
                }

            </div>
        )
    }
}

export default DatabaseIntroduction