import React, {Component} from 'react'
import {connect} from 'react-redux'
import CRUDBox from "../../../InfoPages/Features/FeaturesBody/CRUDBox"

class PickAction extends Component {
    render() {
        return (
            <div>
                <h4>BEFORE THE TOUR</h4>
                <p>There is only one thing we need to know</p>
                <div className="row">
                    <div className="col-sm-2" />
                    <div className="col-sm-8">
                        <div className="row">
                            <h2 className="thinHeading">What action do you want to perform with your file?</h2>
                            <div className="col-sm-6">
                                <CRUDBox actionType="create" quickStart/>
                                <br/>
                                <CRUDBox actionType="delete" quickStart/>
                            </div>
                            <div className="col-sm-6">
                                <CRUDBox actionType="read" quickStart/>
                                <br/>
                                <CRUDBox actionType="update" quickStart/>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2" />
                </div>
                <br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(PickAction)