import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import {toggleToast} from "../Actions/ToggleToast";
import {connect} from "react-redux";

class CopyBtn extends Component {
    copy = () => {
        navigator.clipboard.writeText(this.props.text)
        let message = "Copied";

        if ('message' in this.props){
            message = this.props.message
        }

        this.props.toggleToast({show: true, message: message, type: "success"})
    }

    render() {
        return (
            <Icon name="copy outline"
                  color="green"
                  style={{cursor: "pointer"}}
                  onClick={() => this.copy()} />
        )
    }
}

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(null, mapActionsToProps)(CopyBtn)
