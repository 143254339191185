import React, {Component} from 'react'
import { Message, Button, Input } from 'semantic-ui-react'
import { connect } from 'react-redux'
import API_Root from "../../Constants/API_Root";
import {toggleModal} from "../../Actions/ToggleModal";

class DeleteDatabaseModalBody extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: false,
            dbName: "",
            isDeleting: false
        }
    }

    deleteDB = (e) => {
        this.setState({ isDeleting: true })
        const error = () => this.setState({ error: true, isDeleting: false })

        const databaseHash = this.props.modalInfo['databaseHash'];


        fetch(API_Root + "api/delete-api-db-database/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                databaseHash: databaseHash,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token")
            })
        }).then((res) => {
            if (res.status === 200){
                this.goToDashboardLink.click()
                this.props.toggleModal(null)
            } else {
                error()
            }
        }).catch(err => error())
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    getOriginalDBName = () => {
        let dbName = ""
        if ("dbName" in this.props.modalInfo){
            dbName = this.props.modalInfo.dbName
        } else {
            dbName = this.props.allDBInfo['dbInfo']['name']
        }

        return dbName
    }

    render() {
        let originalDBName = this.getOriginalDBName();
        return (
            <div style={{padding: '50px'}}>
                <a style={{display: 'none'}} ref={goToDashboardLink => this.goToDashboardLink = goToDashboardLink}
                   href="/database">Dashboard</a>
                <h2 style={{color: 'red'}}><u>Reminder</u> your database and all APIs will be permanently deleted</h2>
                <br/>
                <b>Enter Database Name to Delete</b>
                <Input value={this.state.dbName} onChange={this.onChange} fluid name="dbName" />
                <br/>
                <Button fluid disabled={this.state.dbName.trim() !== originalDBName.trim()} onClick={this.deleteDB} size="large" icon="trash alternate outline" color="red" content="Delete Database" loading={this.state.isDeleting} />
                <br/>
                {this.state.error ? <Message negative header="There was a problem deleting your database" content="Try again or contact us if the error persists" /> : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo,
    allDBInfo: state.mainState.allDBInfo
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(DeleteDatabaseModalBody)