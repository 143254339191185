import React, {Component} from 'react'
import {connect} from 'react-redux'
import Celebration from './Celebration.svg'
import { Button } from 'semantic-ui-react'
import {toggleModal} from "../../../Actions/ToggleModal";
import {toggleShowingTutorial} from "../../../Actions/ToggleShowingTutorial";
import {modifyTutorialStep} from "../../../Actions/ModifyTutorialStep";

class Congratulations extends Component {
    constructor(props) {
        super(props);

        this.takeTour = this.takeTour.bind(this);
    }

    takeTour(tour){
        if (!tour){
            this.props.toggleShowingTutorial(false);
            this.props.modifyTutorialStep(null);
            this.props.toggleModal(null);
        }
        else{
            this.props.modifyTutorialStep("pickAction")
        }
    }

    render() {
        return (
            <div style={{textAlign: 'center'}}>
                <h2>Congratulations on uploading a file!</h2>
                <img src={Celebration} style={{width: '50%', height: '50%'}} alt="Congratulations" />
                <br/><br/><br/>
                <h5>Let's take a quick <b style={{color: 'green'}}>30 Second</b> tour of the tool</h5>
                <Button size="massive" content={<span>Sure</span>} color="green" onClick={(e) => this.takeTour(true)}/>
                {/*<Button size="massive" content={<span>No</span>} color="red" onClick={(e) => this.takeTour(false)}/>*/}
                <br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    toggleModal: toggleModal,
    toggleShowingTutorial: toggleShowingTutorial,
    modifyTutorialStep: modifyTutorialStep
}

export default connect(mapStateToProps, mapActionsToProps)(Congratulations)