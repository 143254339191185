import React, {Component} from 'react'
import {connect} from "react-redux";
import { RoughNotation } from "react-rough-notation"
import currencyInfo from "../../Constants/currencyInfo";

class PromoPricing extends Component {
    getPricingText = (currency, annual) => {
        const currencyInformation = currencyInfo[currency];
        const price = annual ? this.props.price.annual : this.props.price.promo;

        return currencyInformation.symbol + price.toString()
    }

    render() {
        return (
            <div className="promoPricing">
                <div className="row">
                    <div className="col-sm-3" />
                    <div className="col-sm-3">
                        <div className="promoPricingBoxes">
                            <h3 className="pricingHeader">Current Price</h3>
                            <h1 className="pricingCost"><RoughNotation show={true} type="crossed-off" color="red">{this.getPricingText(this.props.currency, true)}</RoughNotation></h1>
                            <br/>
                            <span className="subheading">Annually</span>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="promoPricingBoxes">
                            <h3 className="pricingHeader">Promo Price</h3>
                            <h1 className="pricingCost"><RoughNotation show={true} type="box" color="orange">{this.getPricingText(this.props.currency, false)}</RoughNotation></h1>
                            <br/>
                            <span className="subheading">Lifetime Access</span>
                        </div>
                    </div>
                    <div className="col-sm-3" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

export default connect(mapStateToProps, null)(PromoPricing)
