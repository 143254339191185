import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect'

class StartPlanButton extends Component {
    render() {
        return (
            <a style={{textDecoration: "none"}} href={this.props.url}>
                <div
                    style={{
                        border: "none",
                        backgroundColor: this.props.backgroundColor,
                        color: this.props.color,
                        cursor: "pointer",
                        borderRadius: "4px",
                        padding: "8px 32px", textAlign: 'center'
                    }}
                >
                    {this.props.text} <span style={{paddingLeft: "4px"}}><Icon name="chevron right" /></span>
                </div>
            </a>
        )
    }
}

export default StartPlanButton