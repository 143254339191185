import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

const statusMap = {
    'PENDING_VALIDATION': {icon: "clock outline", color: "gray", text: <span>Pending Validation</span>},
    'ISSUED': {icon: "check circle outline", color: "green", text: <span>Issued</span>},
    'INACTIVE': {icon: "clock outline", color: "gray", text: <span>Inactive</span>},
    'EXPIRED': {icon: "times circle outline", color: "red", text: <span>Expired</span>},
    'VALIDATION_TIMED_OUT': {icon: "ban", color: "gray", text: <span>Validation Timeout</span>},
    'REVOKED': {icon: "ban", color: "gray", text: <span>Revoked</span>},
    'FAILED': {icon: "times circle outline", color: "red", text: <span>Failed</span>}
}

class SSLCertificateStatus extends Component {
    render() {
        return (
            <span style={{color: statusMap[this.props.status].color}}><Icon name={statusMap[this.props.status].icon} /> {statusMap[this.props.status].text}</span>
        )
    }
}

export default SSLCertificateStatus