import React, {Component} from 'react'
import { Icon, Message } from 'semantic-ui-react'
import TableColumnsTable from "./TableColumnsTable";

class TableAccordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }

        this.toggleOpen = this.toggleOpen.bind(this);
    }

    toggleOpen(){
        const toggleState = !this.state.open;

        this.setState({
            open: toggleState
        })
    }

    render() {
        let body = null;
        let icon = <Icon name="triangle right" />

        if (this.state.open){
            if (this.props.columnInfo.length > 0){
                body =
                    <div className="customAccordionBody" style={{marginTop: "10px"}}>
                        <TableColumnsTable columnInfo={this.props.columnInfo}/>
                    </div>
            } else{
                body =
                    <div className="customAccordionBody" style={{marginTop: "10px"}}>
                        <Message
                            header={"There weren't any columns in your table " + this.props.tableName}
                            content="If you think this is in error, please contact support@dbspreadsheets.com"
                            icon="columns"
                            negative
                        />
                    </div>
            }

            icon = <Icon name="triangle down" />
        }


        return (
            <div className="customAccordion" style={{marginBottom: "15px"}}>
                <button className="customAccordionHeader" style={'headerDivStyle' in this.props ? this.props.headerDivStyle : null} onClick={this.toggleOpen}>
                    <span style={{fontSize: '20px'}}>{icon}</span>
                    <span style={{fontSize: '20px'}}>{this.props.tableName}</span>
                </button>
                {body}
            </div>
        )
    }
}

export default TableAccordion