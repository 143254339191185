import React, {Component} from 'react'
import {connect} from 'react-redux'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";

class DemoVideoSquare extends Component {
    openVideo = () => {
        this.props.modifyModalInfo({
            videoInfo: {
                header: this.props.info.title,
                id: this.props.info.id
            }
        })

        this.props.toggleModal("videoModal")
    }

    render() {
        return (
            <div style={{cursor: "pointer", border: "1px solid #F0F0F0", padding: "16px", borderRadius: "4px"}} className="hoverShadow" onClick={this.openVideo}>
                <div style={{textAlign: "center", color: gunaldiStyles.mainText, fontWeight: "700", fontSize: "16px"}}>
                    {this.props.info.title}
                </div>
                <div style={{textAlign: "center"}}>
                    <img src={"http://img.youtube.com/vi/" + this.props.info.id + "/hqdefault.jpg"} style={{width: "50%"}} alt="video thumbnail"/>
                </div>
                <div style={{textAlign: "center", color: gunaldiStyles.subText, fontSize: "14px"}}>
                    {this.props.info.time} minutes
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(DemoVideoSquare)