import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../SharedComponents/Modal"
import PendingInvitesRow from "./PendingInvites/PendingInvitesRow"

class PendingInvitesModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let body = null;

        if (this.props.pendingInvites !== null){
            body = this.props.pendingInvites.map((x, idx) => <div style={{marginBottom: "16px"}}><PendingInvitesRow info={x} key={idx} idx={idx}/></div>)
        }
        return (
            <ModalApp name="pendingInvitesModal"
                      header={null}
                      body={body}
                      size="large"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    pendingInvites: state.mainState.pendingInvites
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(PendingInvitesModal)