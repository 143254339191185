import React, {Component} from 'react'
import PromoCreditCardDetails from './PromoCreditCardDetails'
import {Elements, StripeProvider} from 'react-stripe-elements';
import { connect } from 'react-redux'
import Pricing from "../../Constants/Pricing/Pricing"
import currencyInfo from "../../Constants/currencyInfo"
import { Icon } from 'semantic-ui-react';

class PromoPaymentIndex extends Component {
    constructor(props){
        super(props);

        this.changeProcess = this.changeProcess.bind(this);
    }

    changeProcess(){
        this.goToFiles.click()
    }

    render() {
        // const currencyInformation = currencyInfo[this.props.currency];
        const amount = this.props.pricingInfo.promo;

        return (
            <div className="promoPaymentIndex">
                <a href="/profile" style={{display: 'none'}} ref={goToFiles => this.goToFiles = goToFiles}>Nothing</a>
                <StripeProvider apiKey={window.location.href.includes("localhost") ? "pk_test_1rS8QGgeDU1Ewoyjc8AjaTx0" : "pk_live_nmeaYWsNdAbSbOTRd8MgnGLL"}>
                    <div className="example">
                        <h4><Icon name="credit card" /> Enter Credit Card Info</h4>
                        <Elements>
                            <PromoCreditCardDetails changeProcess={this.changeProcess}
                                                    currency={this.props.currency}
                                                    plan={this.props.plan}
                                                    amount={amount}
                                                    currencyAmount={currencyInfo[this.props.currency].symbol + this.props.pricingInfo.promo.toString()}

                            />
                        </Elements>
                    </div>
                </StripeProvider>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(PromoPaymentIndex)
