import React, {Component} from 'react'
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";
import pricing from "../../Constants/Pricing/Pricing";
import {getPricingText} from "../../Constants/getPricingText";
import { Icon } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import {getPricingInfoObject} from "../../Constants/getPricingInfoObject";

class PricingDescriptionBox extends Component {
    goToFAQ = (e) => {
        window.scrollTo({
            top: document.getElementById("pricingFAQ").offsetTop,
            behavior: 'smooth'
        })
    }

    getLabelValue = (featureInfo) => {
        if (featureInfo.key === "requests"){
            if ('profilePage' in this.props){
                let pricingObj = getPricingInfoObject(this.props.tier, this.props.appsumoPlanName, this.props.isGrandfather);

                if (pricingObj.requestsFrequency === "daily"){
                    return "Requests per Day"
                } else if (pricingObj.requestsFrequency === "monthly"){
                    return "Requests per Month"
                } else {
                    return featureInfo.label
                }
            } else {
                return featureInfo.label
            }
        } else {
            return featureInfo.label
        }
    }

    render() {
        let featureBoxes = null;

        if (!isMobile){
            const featureLabelBoxStyle = {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "8px 0px 8px 16px",
                gap: "8px",
                // boxShadow: "inset 1px 0px 0px #F0F0F0, inset 0px -1px 0px #F2F2F2",
                boxShadow: "inset 1px 0px 0px #F0F0F0, inset 0px -1px 0px #F2F2F2",
                color: "#161E16",
                fontSize: "14px"
            }

            featureBoxes = this.props.featureList.map((x, idx) => <div style={featureLabelBoxStyle}>
                {this.getLabelValue(x)}
            </div>)
        }

        return (
            <div style={{textAlign: "left"}}>
                <div style={{borderBottom: "1px solid #F0F0F0"}}>
                </div>
                <div style={{padding: "16px",
                    boxShadow: "inset 1px 0px 0px #F0F0F0, inset 0px -1px 0px #F2F2F2",
                    borderRight: isMobile ?  "1px solid #F0F0F0" : null,
                    minHeight: this.props.headerBoxHeight, gap: "8px"}}>
                    {
                        'profilePage' in this.props ?
                        null : <div>
                                <div style={{marginBottom: "4px"}}>
                                    <Icon name="question circle" style={{color: "#9696A0"}} />
                                </div>
                                <div style={{fontWeight: "700", fontSize: "20px", color: "#161E16", marginBottom: "4px"}}>
                                    Have questions?
                                </div>
                                <div style={{color: "#9696A0", fontSize: "14px", marginBottom: "8px"}}>
                                    Not sure what these features are?<br/>Read the FAQs Below
                                </div>
                                <div style={{fontSize: "12px", color: "white", visibility: "hidden"}}>billed monthly</div>
                                <div style={{cursor: "pointer", fontSize: "14px", color: "#5499F8", fontWeight: "700"}} onClick={this.goToFAQ}>
                                    Jump to the FAQs <Icon name="angle down" />
                                </div>
                            </div>
                    }
                </div>
                {featureBoxes}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    yearlyPricing: state.mainState.yearlyPricing,
    tier: state.mainState.tier,
    appsumoPlanName: state.mainState.appsumoPlanName,
    isGrandfather: state.mainState.isGrandfather
})

export default connect(mapStateToProps, null)(PricingDescriptionBox)
