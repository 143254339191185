import copyObject from "./copyObject";
import {isBrowser} from "react-device-detect";
import {removePKFromArray} from "./removePKFromArray";
import isObjectEmpty from "./isObjectEmpty";
import API_Root from "./API_Root";
import {modifyAPIMenuItem} from "../Actions/ModifyAPIMenuItem";

export const readFiles = (socketRequest, filePK, props, socketURL, isUpload, showTutorial, editOptions=false, isImport=false) => {
    // Open up WebSockets
    const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
    const socket = new WebSocket(websocketRoute + "ws/api/" + socketURL + "/");

    // Send the PKs and if any column names
    socket.onopen = (event) => {
        socket.send(JSON.stringify(socketRequest));
    };

    socket.onmessage = (event) => {
        const reply = JSON.parse(event.data);
        const status = reply['status'];

        if (status === "read"){
            props.modifyStatus(status);

            if (!isImport){
                let filePKProBlock = reply['filePKProBlock'];

                if (typeof props.filePKProBlock !== 'undefined'){
                    if (!isObjectEmpty(props.filePKProBlock)){
                        filePKProBlock = copyObject(props.filePKProBlock, reply['filePKProBlock']);
                    }
                }

                props.modifyFilePKProBlock(filePKProBlock);
            }
        }

        if (status === "api"){
            props.modifyStatus(status);
            let fileInformation = reply['fileInformation'];
            let sheetRead = reply['sheetRead'];

            if (typeof props.fileInformation !== 'undefined'){
                if (Object.entries(props.fileInformation).length !== 0 && props.fileInformation.constructor === Object){
                    fileInformation = copyObject(props.fileInformation, reply.fileInformation);
                    sheetRead = copyObject(props.sheetRead, reply.sheetRead);
                }
            }

            props.modifyFileInformation(fileInformation);
            props.modifySheetRead(sheetRead);

            if (editOptions){
                const reduxEditOptions = JSON.parse(JSON.stringify(props.reduxEditOptions));

                if (props.editID !== "all"){
                    reduxEditOptions[props.editID] = props.editOptions;
                }
                else{
                    for (let i=0; i<props.filePK.length; i++){
                        const filePKObj = props.filePK[i];
                        const fileID = filePKObj['id'];

                        reduxEditOptions[fileID] = props.editOptions;
                    }

                    reduxEditOptions["all"] = props.editOptions;
                }

                props.modifyEditOptions(reduxEditOptions);
            }

            if (showTutorial){
                props.toggleModal(null)

                // If multiple files then go there, otherwise go to individual files page
                if (filePK.length === 1){
                    const fileHash = fileInformation[filePK[0].id].fileHash
                    props.navigateTo("/files/" + fileHash)
                } else if (filePK.length > 1){
                    props.modifyAPIMenuItem("files")
                    // props.navigateTo("/api?menuItem=files")
                }

                // if (isBrowser){
                //     if (props.showingTutorial){
                //         if (props.tutorialStep === "userPreference"){
                //             props.toggleModal("quickStartModal")
                //         }
                //     }
                //     else{
                //         props.toggleModal(null)
                //     }
                // }
            } else {
                props.toggleModal(null)
            }

            socket.close()
        }

        if (status === "error"){
            socket.close()
            props.modifyStatus("upload");
            props.toggleModal(null);

            if (isUpload){
                props.initUploadedPK(removePKFromArray(props.uploadedPK, filePK))
                props.modifyFilePK(removePKFromArray(props.filePK, filePK));
            }

            const errorMessage = 'errorMessage' in reply ? reply['errorMessage'] : "There was an error reading your file. Please try again. Contact our support if this error persists"
            props.toggleToast({show: true, message: errorMessage, type: "error"})
        }

        if (status === "columnNamesError"){
            socket.close()

            props.modifyStatus("upload");

            props.modifyModalInfo({
                infoType: "columnMap",
                columnNamesMap: reply.columnNamesMap
            })

            props.toggleModal(null)
            props.toggleModal("fixColumnNamesModal")
        }
    };
}