import React, {Component} from 'react'
import SocialMediaButtons from "./SocialMediaButtons";
import { Icon } from 'semantic-ui-react'
import Images from "../../Constants/Images";
import GuideDetails from "../../Guides/GuideDetails";
import FooterItem from "./FooterItem"
import {gunaldiStyles} from "../GunaldiDesignStyles";

class MobileFooter extends Component {
    render() {
        const headerStyle = {fontFamily: "Lato", fontWeight: "700", fontSize: "16px", color: "#161E16", marginBottom: "12px"}

        return (
            <div style={{
                borderTop: "1px solid #F0F0F0",
                margin: "0 20px 0 20px",
                padding: "50px 10px 10px 10px",
                color: "#161E16"
            }}>
                <div style={{marginBottom: "24px"}}>
                    <div style={{marginBottom: "24px"}}>
                        <img alt="API Spreadsheets Logo" src={Images.logoNav} style={{width: '80%'}} />
                    </div>
                    <div style={{fontFamily: "Lato", color: "#9696A0", fontWeight: "400", fontSize: "12px"}}>
                        <span>Developer Tools For <span style={{color: gunaldiStyles.mainGreen}}>Spreadsheets</span></span>
                    </div>
                </div>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "24px", paddingBottom: "24px"}}>
                    <div>
                        <div style={headerStyle}>Company</div>
                        <FooterItem to="https://lovespreadsheets.medium.com/" name="Blog" external/>
                        <FooterItem to="https://www.lovespreadsheets.com/" name="About" external/>
                        <FooterItem to="https://www.lovespreadsheets.com/contact/" name="Contact Us" external/>
                    </div>
                    <div>
                        <div style={headerStyle}>Product</div>
                        <FooterItem to="tutorials" name="Use Cases" />
                        <FooterItem to="https://api-apispreadsheets-com.statuspal.io/" name="API Status" external />
                        <FooterItem to={"guides/" + GuideDetails[0].title} name="Guides" />
                        <FooterItem to="FAQs" name="FAQs" />
                        <FooterItem to="docs" name="API Docs" />
                        <FooterItem to="datasets" name="Datasets" />
                    </div>
                </div>
                <div style={{paddingTop: "12px", paddingBottom: "24px", marginBottom: "12px", borderBottom: "1px solid #F0F0F0"}}>
                    <div style={headerStyle}>Support</div>
                    <div style={{fontFamily: "Lato", fontWeight: "400", marginBottom: "12px"}}>
                        <div style={{color: "#9696A0", fontSize: "12px"}}>Hours</div>
                        <p style={{color: "#161E16", fontSize: "14px"}}>Monday-Friday 6AM to 6PM GMT</p>
                    </div>
                    <div style={{fontFamily: "Lato", fontWeight: "400", marginBottom: "12px"}}>
                        <div style={{color: "#9696A0", fontSize: "12px"}}>Tech Support</div>
                        <a href="mailto:support@apispreadsheets.com" style={{color: "#161E16", fontSize: "14px"}}>support@apispreadsheets.com</a>
                    </div>
                    <div style={{fontFamily: "Lato", fontWeight: "400", marginBottom: "12px"}}>
                        <div style={{color: "#9696A0", fontSize: "12px"}}>Sales & Billing</div>
                        <a href="mailto:sales@apispreadsheets.com" style={{color: "#161E16", fontSize: "14px"}}>sales@apispreadsheets.com</a>
                    </div>
                </div>
                <div style={{paddingTop: "12px"}}>
                    <div style={{marginBottom: "12px"}}>
                        <SocialMediaButtons />
                    </div>
                    <div style={{marginBottom: "12px"}}>
                        Made with <span role="img" aria-label="heart emoji">❤️</span> by Woyera Inc <span><Icon name="copyright outline"/> {((new Date()).getFullYear()).toString()}</span>
                    </div>
                    <div style={{marginBottom: "12px"}}>
                        <span><a href="/terms" target="_blank" rel="noopener noreferrer"> Terms &
                            Conditions</a> | <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy
                            Policy</a></span>
                    </div>
                </div>
            </div>
        )
    }
}

export default MobileFooter