import React, {Component} from 'react'
import CustomerCarousel from "../../InfoPages/CustomerCarousel";
import {getHeroText} from "../getHeroText";
import HowItWorksRow from "../FeaturesTables/HowItWorksRow";
import ImportStep1 from "../Illustrations/ImporterStep1.svg"
import ImportStep2 from "../Illustrations/ImporterStep2.svg"
import ImportStep3 from "../Illustrations/ImporterStep3.svg"
import HowItWorksSegment from "../FeaturesTables/HowItWorksSegment";
import Number1 from "../Illustrations/Number1.svg";
import APIStep1 from "../Illustrations/APIStep1.svg";
import Number2 from "../Illustrations/Number2.svg";
import APIStep2 from "../Illustrations/APIStep2.svg";
import Number3 from "../Illustrations/Number3.svg";
import APIStep3 from "../Illustrations/APIStep3.svg";
import FeatureCard from "../FeatureCard";
import Encrypt from "../Illustrations/Encrypt.svg";
import gdpr from "../Illustrations/gdpr.svg";
import Prem from "../Illustrations/Prem.svg";
import WebFormIllustration from "../Illustrations/WebFormIllustration.svg";
import ImportIllustration from "../Illustrations/ImportIllustration.svg";
import CollaborateIllustration from "../Illustrations/CollaborateIllustration.svg";
import DashboardIllustration from "../Illustrations/DashboardIllustration.svg";
import AppsIllustration from "../Illustrations/AppsIllustration.svg";
import IntegrateIllustration from "../Illustrations/IntegrateIllustration.svg";
import Destinations from "../Illustrations/Destinations.svg";
import ServerCluster from "../Illustrations/ServerCluster.svg";
import EmailSample from "../FeaturesTables/CollaborateTeamTableContent/EmailSample";
import isEmailValid from "../isEmailValid";
import EmailButton from "../MobileLandingPage/EmailButton";
import MobileEmailSample from "../MobileLandingPage/MobileEmailSample";
import {isEven} from "../../Constants/isEven";
import HeroText from "../HeroText";
import {isMobile} from "react-device-detect";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import GunaldiCTA from "../GunaldiCTA";
import LandingConvertSpreadsheetsToAPI from "../Illustrations/LandingConvertSpreadsheetsToAPI.svg"
import MobileLandingConvertSpreadsheetsToAPI from "../MobileLandingPage/MobileLandingConvertSpreadsheetsToAPI.svg"
import MobileFeaturesTable from "../MobileLandingPage/MobileFeaturesTable";
import AutoReportingDesc from "../FeaturesTables/CollaborateTeamTableContent/AutoReportingDesc";
import TeamsDesc from "../FeaturesTables/CollaborateTeamTableContent/TeamsDesc";
import MultipleKeysDesc from "../FeaturesTables/CollaborateTeamTableContent/MultipleKeysDesc";
import MonitoringAccessDesc from "../FeaturesTables/CollaborateTeamTableContent/MonitoringAccessDesc";
import MultipleSources from "../FeaturesTables/APIDatabaseTableContent/MultipleSources";
import CrudActionsDesc from "../FeaturesTables/APIDatabaseTableContent/CrudActionsDesc";
import SimpleDesign from "../FeaturesTables/APIDatabaseTableContent/SimpleDesign";
import QueriesDesc from "../FeaturesTables/APIDatabaseTableContent/QueriesDesc";
import LandingSecurity from "../FeaturesTables/APIDatabaseTableContent/LandingSecurity";
import DataModel from "../FeaturesTables/ImporterTableContent/DataModel";
import ImportWebhooks from "../FeaturesTables/ImporterTableContent/ImportWebhooks";
import PreURL from "../FeaturesTables/ImporterTableContent/PreURL"
import ColMatching from "../FeaturesTables/ImporterTableContent/ColMatching";
import SheetsDestination from "../FeaturesTables/ImporterTableContent/SheetsDestination";
import CustomAppearance from "../FeaturesTables/ImporterTableContent/CustomAppearance";
import GetAnAPI from "../FeaturesTables/ImporterTableContent/GetAnAPI";
import PrewrittenCode from "../FeaturesTables/ImporterTableContent/PrewrittenCode";
import LandingApps from "../Illustrations/LandingApps.svg"
import LandingDashboard from "../Illustrations/LandingDashboard.svg"
import LandingForms from "../Illustrations/LandingForms.svg"
import LandingImport from "../Illustrations/LandingImport.svg"
import LandingReport from "../Illustrations/LandingReport.svg"
import LandingIntegrate from "../Illustrations/LandingIntegrate.svg"
import RequestDemoSegment from "../RequestDemoSegment";
import LandingPageFeatureSegment from "../LandingPageFeatureSegment";
import ImporterFeature1 from "../Illustrations/ImporterFeatures/ImporterFeature1.svg"
import ImporterFeatureIcon1 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon1.svg"
import ImporterFeature2 from "../Illustrations/ImporterFeatures/ImporterFeature2.svg"
import ImporterFeatureIcon2 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon2.svg"
import ImporterFeature3 from "../Illustrations/ImporterFeatures/ImporterFeature3.svg"
import ImporterFeatureIcon3 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon3.svg"
import ImporterFeature4 from "../Illustrations/ImporterFeatures/ImporterFeature4.svg"
import ImporterFeatureIcon4 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon4.svg"
import ImporterFeature5 from "../Illustrations/ImporterFeatures/ImporterFeature5.svg"
import ImporterFeatureIcon5 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon5.svg"
import ImporterFeature6 from "../Illustrations/ImporterFeatures/ImporterFeature6.svg"
import ImporterFeatureIcon6 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon6.svg"
import ImporterFeature7 from "../Illustrations/ImporterFeatures/ImporterFeature7.svg"
import ImporterFeatureIcon7 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon7.svg"
import ImporterFeature8 from "../Illustrations/ImporterFeatures/ImporterFeature8.svg"
import ImporterFeatureIcon8 from "../Illustrations/ImporterFeatures/ImporterFeatureIcon8.svg"
import Apps from "../Illustrations/ImporterFeatures/Apps.svg"
import ImporterUseCase from "../Illustrations/ImporterFeatures/ImporterUseCase.svg"
import integrate from "../Illustrations/ImporterFeatures/integrate.svg"

class ImporterMobileLandingPageIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEven: isEven()
        }
    }

    render() {
        const sectionHeaderStyle = {textAlign: "center", paddingBottom: "32px", color: "#161E16", fontWeight: "700", fontFamily: "Lato", fontSize: "32px"};
        const featureMenuHeaderStyle = {fontFamily: "Lato", fontWeight: "700", color: "#161E16", fontSize: "24px", textAlign: "center", marginBottom: "12px"}
        const featureMenuSubheadingStyle = {fontFamily: "Lato", fontWeight: "400", color: "#5E645E", fontSize: "14px", textAlign: "center", marginBottom: "24px"}
        const featureDivStyle = {margin: "0 16px 96px 16px"};

        return (
            <div>
                <div style={{textAlign: "center", marginLeft: '20px', marginRight: '20px', marginTop: "50px"}}>
                    <HeroText headerFontSize="250" img importer/>
                </div>
                <div style={{marginLeft: '20px', marginRight: '20px', marginTop: "24px"}}>
                    <EmailButton header="Or Email yourself the website link" importer/>
                </div>
                <div style={{padding: "120px 60px 120px 60px"}}>
                    <div className="row">
                        <div className="col-sm-2">
                            <div style={{fontFamily: "Lato", fontWeight: "700", fontSize: "20px", color: "#161E16", textAlign: "center"}}>
                                Trusted By
                            </div>
                        </div>
                        <div className="col-sm-10">
                            <CustomerCarousel/>
                        </div>
                    </div>
                </div>
                <div style={{marginLeft: '20px', marginRight: '20px', marginBottom: "120px"}}>
                    <div style={sectionHeaderStyle}>
                        Create a Spreadsheet <span style={{color: gunaldiStyles.orange}}>Importer</span> in Minutes
                    </div>
                    <HowItWorksRow
                        info={[
                            {header: "Customize your importer", body: <img src={ImportStep1} alt="Customize Importer Illustrator" width="100%"/>},
                            {header: "Users upload spreadsheets", body: <img src={ImportStep2} alt="User uploading spreadsheets" width="100%"/>},
                            {header: "Use imported data", body: <img src={ImportStep3} alt="User imported data" width="100%"/>},
                        ]}
                    />
                    {/*<div style={{paddingTop: "80px"}}>*/}
                        {/*<MobileFeaturesTable*/}
                            {/*menuDisplay={{*/}
                                {/*"dataModel": <DataModel headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle} mobile/>,*/}
                                {/*"prewrittenCode": <PrewrittenCode headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,*/}
                                {/*"prebuiltURL": <PreURL headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,*/}
                                {/*"colMatching": <ColMatching headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,*/}
                                {/*"sheetsDestination": <SheetsDestination headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,*/}
                                {/*"appearance":<CustomAppearance headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,*/}
                                {/*"api":<GetAnAPI headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,*/}
                                {/*"webhooks": <ImportWebhooks headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>*/}
                            {/*}}*/}
                            {/*menuItems={[*/}
                                {/*{name: "dataModel", displayName: "Data Model", header: "Customize your importer"},*/}
                                {/*{name: "appearance", displayName: "Customize appearance of your importer", header: "Customize your importer"},*/}
                                {/*{name: "sheetsDestination", displayName: "Save file to multiple external systems", header: "Customize your importer"},*/}
                                {/*{name: "prebuiltURL", displayName: "Send importer hosted on our website", header: "Users upload spreadsheets"},*/}
                                {/*{name: "prewrittenCode", displayName: "Build importer with pre-written code", header: "Users upload spreadsheets"},*/}
                                {/*// {name: "colMatching", displayName: "Column Mapping", header: "Users upload spreadsheets"},*/}
                                {/*{name: "webhooks", displayName:"Set up a webhook", header: "Use imported data"},*/}
                                {/*{name: "api", displayName:"Access imported data with a REST API", header: "Use imported data"}*/}
                            {/*]}*/}
                        {/*/>*/}
                    {/*</div>*/}
                </div>
                <div style={{textAlign:'center', marginBottom: "120px"}}>
                    <GunaldiCTA importer/>
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon1}
                        img={ImporterFeature1}
                        header="Create a Data Model"
                        category="customize your importer"
                        desc="Your data model will standardize the column names, and clean the values of each imported file"
                        textLocation="left"
                    />
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon2}
                        img={ImporterFeature2}
                        header="Customize Importer Appearance"
                        category="customize your importer"
                        desc="Configure importer color, success & error messages, allowed file extensions and much more with no-code"
                        textLocation="right"
                    />
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon3}
                        img={ImporterFeature3}
                        header="Save in Multiple Destinations"
                        category="customize your importer"
                        desc="Select all destinations an imported file and its data should be saved. No code needed"
                        textLocation="left"
                    />
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon5}
                        img={ImporterFeature5}
                        header="Copy & Paste Pre-Written Code"
                        category="users upload spreadsheets"
                        desc="Insert the importer on your website by copying and pasting our already built libraries"
                        textLocation="right"
                    />
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon4}
                        img={ImporterFeature4}
                        header="Share Importer URL"
                        category="users upload spreadsheets"
                        desc="You can share the importer hosted on our website with your customers and users. No code needed"
                        textLocation="left"
                    />
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon6}
                        img={ImporterFeature6}
                        header="Map Columns to Data Model"
                        category="users upload spreadsheets"
                        desc="Our algorithm automatically matches columns in an imported file to the data model"
                        textLocation="right"
                    />
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon7}
                        img={ImporterFeature7}
                        header="Connect Your Webhook"
                        category="users upload spreadsheets"
                        desc="A Webhook (your own REST API URL) can get called after each import. The world is your oyster after connecting a Webhook"
                        textLocation="left"
                    />
                </div>
                <div style={featureDivStyle}>
                    <LandingPageFeatureSegment
                        icon={ImporterFeatureIcon8}
                        img={ImporterFeature8}
                        header="Access Data with API"
                        category="users upload spreadsheets"
                        desc={<span>Interact with data imported from spreadsheets via our industry leading REST API. <a href="/">Read all about our API here</a></span>}
                        textLocation="right"
                    />
                </div>
                <div style={{textAlign:'center', margin: "120px 0 120px 0"}}>
                    <GunaldiCTA importer/>
                </div>
                <div style={{marginBottom: "120px"}}>
                    <div style={{textAlign: "center", paddingBottom: "16px", color: "#161E16", fontWeight: "700", fontFamily: "Lato", fontSize: "32px"}}>
                        Use Cases
                    </div>
                    <div style={{marginBottom: "48px"}}>
                        <a href="/tutorials/collect-client-spreadsheets-no-code.html" target="_blank" className="nostyle">
                            <FeatureCard
                                img={Apps}
                                header="no-code import"
                                desc="Collect spreadsheets from your clients with absolutely no code"
                            />
                        </a>
                    </div>
                    <div style={{marginBottom: "48px"}}>
                        <a href="/tutorials/import-spreadsheets-on-your-apps.html" target="_blank" className="nostyle">
                            <FeatureCard
                                img={ImporterUseCase}
                                header="install importer"
                                desc="Add a spreadsheet importer on your website and apps in minutes"
                            />
                        </a>
                    </div>
                    <div style={{marginBottom: "48px"}}>
                        <a href="/tutorials/insert-data-from-spreadsheets-to-postgres.html" target="_blank" className="nostyle">
                            <FeatureCard
                                img={integrate}
                                header="integrate importer"
                                desc="Save data from imported spreadsheets to your database and more"
                            />
                        </a>
                    </div>
                </div>
                <div style={{textAlign:'center', marginBottom: "120px"}}>
                    <GunaldiCTA importer/>
                </div>
                <div style={{backgroundColor: gunaldiStyles.subOrange, padding: "100px 20px 100px 20px", marginBottom: "120px"}}>
                    <div style={{textAlign: "center", paddingBottom: "32px", color: gunaldiStyles.mainText, fontWeight: "700", fontFamily: "Lato", fontSize: "32px"}}>
                        Security
                    </div>
                    <div>
                        <div style={{marginBottom: "48px"}}>
                            <FeatureCard
                                img={Encrypt}
                                header="data-encryption"
                                desc="Your data is encrypted in-transit using HTTPS/SSL and at rest with AWS"
                            />
                        </div>
                        <div style={{marginBottom: "48px"}}>
                            <FeatureCard
                                img={gdpr}
                                header="GDPR-compliant"
                                desc="We will never use your data maliciously as spelled out in our Terms of Service"
                            />
                        </div>
                        <div style={{marginBottom: "48px"}}>
                            <FeatureCard
                                img={Prem}
                                header="on-prem-install"
                                desc="We offer on-prem install and can easily execute a Data Processor agreement"
                            />
                        </div>
                    </div>
                </div>
                <div style={{textAlign:'center',  marginBottom: "120px"}}>
                    <GunaldiCTA importer/>
                </div>
                <div style={{marginLeft: '20px', marginRight: '20px', marginBottom: "48px"}}>
                    <EmailButton header="Email yourself this link to enjoy all the features!" importer/>
                </div>
                <div style={{margin: "0 20px 48px 20px"}}>
                    <RequestDemoSegment importer />
                </div>
            </div>
        )
    }
}

export default ImporterMobileLandingPageIndex