import React, {Component} from 'react'
import AnimatedNumber from 'react-animated-number';

class SpreadsheetNumber extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numSpreadsheets: 100
        }

        // this.increaseNumSpreadsheet = this.increaseNumSpreadsheet.bind(this);
        this.update = this.update.bind(this);
    }
    //
    componentWillMount(){
        this.interval = setInterval(() => this.update(), 500);
    }

    update(){
        const { numSpreadsheets } = this.state;

        if (this.state.numSpreadsheets < 1000){
            const getRandomInt = Math.floor(Math.random() * 100)

            let addedSpreadsheets = numSpreadsheets + getRandomInt;

            if (addedSpreadsheets > 1000){
                addedSpreadsheets = 1000
            }

            this.setState({
                numSpreadsheets: addedSpreadsheets
            })

            this.interval = setTimeout(() => this.update(), 500)
        }

    }


    render() {
        return (
            <AnimatedNumber
                style={{
                    transition: '0.8s ease-out',
                    transitionProperty:
                        'background-color, color, opacity',
                    fontSize: '250%',
                }}
                frameStyle={perc => (
                    perc === 100 ? {} : {opacity: 0.25}
                )}
                value={this.state.numSpreadsheets}
                formatValue={n => parseInt(n).toString() + "+"}/>
        )
    }
}

export default SpreadsheetNumber