import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../../SharedComponents/Modal"

class VideoModal extends Component {
    render() {
        let show = false;
        if (this.props.modalInfo !== null){
            try{
                if ('videoInfo' in this.props.modalInfo){
                    show = true
                }
            } catch(e){
                show = false
            }
        }

        if (show){
            const header = this.props.modalInfo.videoInfo.header;

            return (
                <ModalApp name="videoModal"
                          header={<div style={{fontSize: "24px", fontWeight: "700", textAlign: "center"}}>{header}</div>}
                          body={<div style={{textAlign:'center', marginTop: "16px"}}>
                              <iframe width="840" height="472.5" src={"https://www.youtube.com/embed/" + this.props.modalInfo.videoInfo.id}
                                      title="YouTube video player" frameBorder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen></iframe>
                          </div>}
                          size="large"
                />
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(VideoModal)