import React, {Component} from 'react'
import UploadSSLCertificate from "./UploadSSLCertificate";
import ImportSSLCertificateBtn from "./ImportSSLCertificateBtn";
import BannerAlert from "../../../../SharedComponents/BannerAlert"

class ImportSSLCertificate extends Component {
    render() {
        const bannerDivStyle = {marginTop: "16px"}
        return (
            <div>
                <UploadSSLCertificate
                    fileCertificate={this.props.fileCertificate}
                    fileKey={this.props.fileKey}
                    fileCertificateChain={this.props.fileCertificateChain}
                    changeState={this.props.changeState}
                />
                <ImportSSLCertificateBtn
                    fileCertificate={this.props.fileCertificate}
                    fileKey={this.props.fileKey}
                    fileCertificateChain={this.props.fileCertificateChain}
                    changeState={this.props.changeState}
                    currSSLInfo={this.props.currSSLInfo}
                />
                {
                    this.props.importSSLMsg.trim() !== "" ?
                        this.props.importSSLError ?
                            <div style={bannerDivStyle}>
                                <BannerAlert    type="error"
                                                header='There was an error importing your certificate'
                                                icon="bx bx-error-alt"
                                                content={this.props.importSSLMsg}
                                />
                            </div> :
                            <div style={bannerDivStyle}>
                                <BannerAlert
                                    type="success"
                                    header="Your SSL Certificate has been successfully imported"
                                    content="Check Your Certificates tab to select it"
                                />
                            </div> : null
                }
            </div>
        )
    }
}

export default ImportSSLCertificate