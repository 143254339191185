import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../Constants/API_Root";
import Loader from 'react-loader-spinner'
import {dateToString} from "../Constants/dateToString";
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import {Checkbox} from 'semantic-ui-react'
import DownloadLogs from './DownloadLogs'
import {convertServerDateToDisplayDate} from "../Constants/convertServerDateToDisplayDate";
import DisplayHeader from "../FileDnD/FilesID/DisplayHeader"

class AnalyticsBody extends Component {
    constructor(props) {
        super(props);

        const allTeamUsersForFile = this.allTeamUsersForFile()
        const userCheck = this.getUserCheck(allTeamUsersForFile)

        this.state = {
            vizInfo: null,
            fetching: false,
            getRequest: true,
            postRequest: true,
            createRequest: true,
            readRequest: true,
            updateRequest: true,
            deleteRequest: true,
            fullVizInfo: null,
            allTeamUsersMap: allTeamUsersForFile,
            userCheck: userCheck
        }

        this.reshapeData = this.reshapeData.bind(this);
        this.toggleRequest = this.toggleRequest.bind(this);
    }

    allTeamUsersForFile = () => {
        if (!('webhook' in this.props)){
            const pkID = this.props.analyticsID.pkID;

            const fileInfoPK = this.props.fileInformation[pkID];
            const teamsPKList = fileInfoPK.teams_pk_list;

            if (teamsPKList.length > 0){
                let allTeamUsersMap = {}

                const allTeamsPk = this.props.teamInfo.map(x => x.id)

                for (let i=0; i<teamsPKList.length; i++){
                    let teamPK = teamsPKList[i];
                    let teamInfo = this.props.teamInfo[allTeamsPk.indexOf(teamPK)]
                    let allUsers = teamInfo.all_users;

                    for (let email in allUsers){
                        let userInfo = allUsers[email];

                        if ("accessKey" in userInfo && "secretKey" in userInfo){
                            let combinedKey = userInfo["accessKey"] + userInfo["secretKey"]

                            if (!(combinedKey in allTeamUsersMap)){
                                allTeamUsersMap[combinedKey] = email
                            }
                        }
                    }
                }

                return allTeamUsersMap
            } else {
                return { }
            }
        } else{
            return { }
        }
    }

    getUserCheck = (allTeamUsers) => {
        if (!('webhook' in this.props)){
            if (Object.keys(allTeamUsers).length === 0){
                return null
            } else {
                let userCheck = {};

                for (let keyCombo in allTeamUsers) {
                    userCheck[allTeamUsers[keyCombo]] = true
                }

                return userCheck
            }
        } else {
            return null
        }
    }

    componentWillMount(){
        this.setState({ fetching: true });
        let pk;

        if ('pk' in this.props){
            pk = this.props.pk
        } else {
            pk = this.props.analyticsID.pk
        }

        fetch(API_Root + "api/get-api-analytics/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                pk: pk,
                webhook: 'webhook' in this.props
            })
        }).then(res => res.json())
            .then((data) => {
                if (!data.error){
                    this.setState({
                        vizInfo: this.reshapeData(data.vizInfo, this.state.getRequest, this.state.postRequest),
                        fetching: false,
                        fullVizInfo: data.vizInfo
                    })
                }
                else{
                    this.setState({ fetching: false })
                }
            }).catch((err) => {
                this.setState({ fetching: false });
            }).catch(err => this.setState({ fetching: false }));
    }

    toggleRequest(e, requestType){
        let currState = this.state[requestType];
        let getRequest = requestType === "getRequest" ? !currState : this.state.getRequest;
        let postRequest = requestType === "postRequest" ? !currState : this.state.postRequest;

        let reshapedData = this.reshapeData(this.state.fullVizInfo, getRequest, postRequest);

        this.setState({
            [requestType]: !currState,
            vizInfo: reshapedData
        })
    }

    reshapeData(vizInfo, getRequest, postRequest, currUserCheck=null){
        if (vizInfo.length > 0){
            let vizData = [];

            let dateArr = [];
            let error = [];
            let success = [];

            let today = new Date();
            let weekAgo = new Date();
            weekAgo.setDate(today.getDate() - 7)

            while (weekAgo <= today) {
                dateArr.push(dateToString(new Date(weekAgo)).date);
                error.push(0)
                success.push(0)
                weekAgo.setDate(weekAgo.getDate() + 1);
            }

            const requestTypeBool = {"GET": getRequest, "POST": postRequest};

            for (let i=0; i<vizInfo.length; i++){
                const apiCall = vizInfo[i];
                let date = convertServerDateToDisplayDate(apiCall.created_dt.replace("T", " ").substr(0, apiCall.created_dt.indexOf("."))).substr(0, 10);
                let dateIdx = dateArr.indexOf(date);

                if (currUserCheck !== null){
                    if (apiCall["access_key"] !== "" && apiCall["secret_key"] !== ""){
                        let combinedKey = apiCall["access_key"] + apiCall["secret_key"]
                        let userEmail = this.state.allTeamUsersMap[combinedKey]
                        if (currUserCheck[userEmail]){
                            let updatedArrays = this.updateSuccessErrorArrays(success, error, apiCall, requestTypeBool, dateIdx)
                            success = updatedArrays['success']
                            error = updatedArrays['error']
                        }
                    }
                } else {
                    let updatedArrays = this.updateSuccessErrorArrays(success, error, apiCall, requestTypeBool, dateIdx)
                    success = updatedArrays['success']
                    error = updatedArrays['error']
                }
            }

            for (let i=0; i<dateArr.length; i++){
                vizData.push(
                    {
                        date: dateArr[i],
                        success: success[i],
                        error: error[i]
                    }
                )
            }

            return vizData
        }
        else{
            return vizInfo
        }
    }

    updateSuccessErrorArrays = (successArray, errorArray, apiCall, requestTypeBool, dateIdx) => {
        if (requestTypeBool[apiCall.request_type]){
            let statusCode = apiCall.status_code;

            if (statusCode === 200 || statusCode === 201){
                let updatedSuccess = successArray[dateIdx] + 1
                successArray[dateIdx] = updatedSuccess
            }
            else{
                let updatedError = errorArray[dateIdx] + 1
                errorArray[dateIdx] = updatedError
            }
        }

        return { "success": successArray, "error": errorArray}
    }

    checkUser = (e, userEmail) => {
        const currUserCheck = JSON.parse(JSON.stringify(this.state.userCheck));
        const currStatus = currUserCheck[userEmail]

        currUserCheck[userEmail] = !currStatus

        let reshapedData = this.reshapeData(this.state.fullVizInfo, this.state.getRequest, this.state.postRequest, currUserCheck);

        this.setState({ userCheck: currUserCheck, vizInfo: reshapedData })
    }

    render() {
        let body = null;

        if ('webhook' in this.props){
            body = <div>
                <h5 className="thinHeading">This Webhook has not been called within the last week</h5>
            </div>
        } else {
            body = <div>
                <div style={{marginBottom: "16px"}}>
                    <DisplayHeader header="API Usage"/>
                </div>
                <h5 className="thinHeading">You haven't made any calls to this API within the last week</h5>
            </div>
        }

        if (this.state.vizInfo !== null){
            if (this.state.vizInfo.length > 0){
                let userCheckboxes = null;
                if (this.state.userCheck !== null){
                    let allUserCheckboxes = [];

                    for (let email in this.state.userCheck){
                        allUserCheckboxes.push(
                            <div key={email} style={{marginBottom: "8px"}}>
                                <Checkbox
                                    name={email}
                                    label={email}
                                    checked={this.state.userCheck[email]}
                                    onChange={(e) => this.checkUser(e, email)}
                                />
                            </div>
                        )
                    }

                    userCheckboxes = <div>
                        <DisplayHeader header="Team Members"/>
                        {allUserCheckboxes}
                    </div>
                }

                body =
                    <div>
                        <div style={{display: "grid", gridTemplateColumns: "50% 50%", marginBottom: "16px"}}>
                            <div>
                                <DisplayHeader header="API Usage"/>
                            </div>
                            <div style={{textAlign: "right"}}>
                                    <DownloadLogs webhook={'webhook' in this.props} pk={'pk' in this.props ? this.props.pk : this.props.analyticsID.pk}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div>
                                    <Checkbox
                                        name="get"
                                        label="GET"
                                        checked={this.state.getRequest}
                                        onChange={(e) => this.toggleRequest(e, "getRequest")}
                                    />
                                    <Checkbox
                                        name="post"
                                        label="POST"
                                        checked={this.state.postRequest}
                                        onChange={(e) => this.toggleRequest(e, "postRequest")}
                                        style={{marginLeft: '20px'}}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-5">
                                {userCheckboxes}
                            </div>
                            <div className="col-sm-7">
                                <LineChart
                                    width={400}
                                    height={300}
                                    data={this.state.vizInfo}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="success" stroke="#8884d8" activeDot={{ r: 8 }} />
                                        <Line type="monotone" dataKey="error" stroke="#82ca9d" />
                                </LineChart>
                            </div>
                            {/*<div className="col-sm-5">*/}

                            {/*</div>*/}
                        </div>
                    </div>
            }
        }

        if (this.state.fetching){
            return(
                <div style={{padding: '100px 0', textAlign: 'center'}}>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4 className="thinHeading" style={{color: 'black'}}>Getting your history...</h4>
                </div>
            )
        }
        else{
            return (
                body
            )
        }
    }
}

const mapStateToProps = (state) => ({
    analyticsID: state.mainState.analyticsID,
    fileInformation: state.mainState.fileInformation,
    teamInfo: state.mainState.teamInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(AnalyticsBody)