import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import ContactUsForm from "./ContactUsForm";

class ContactUsModal extends Component {
    render() {
        const contactUsBody =
            <div className="row">
                <div className="col-sm-1" />
                <div className="col-sm-10">
                    <ContactUsForm/>
                </div>
                <div className="col-sm-1" />
            </div>
        return (
            <ModalApp name="contactUsModal"
                      header="Contact Us"
                      body={contactUsBody}
                      size="medium"
            />
        )
    }
}

export default ContactUsModal