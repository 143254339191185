import React, {Component} from 'react'
import ModalApp from "../../SharedComponents/Modal"
import { Icon } from 'semantic-ui-react'
import DeleteDatabaseModalBody from "./DeleteDatabaseModalBody"

class DeleteDatabaseModal extends Component {
    render() {
        return (
            <ModalApp name="deleteDatabaseModal"
                      header={<h2 className="thinHeading"><Icon name="trash alternate outline" /> Delete Database</h2>}
                      size="small"
                      body={<DeleteDatabaseModalBody/>}
            />
        )
    }
}

export default DeleteDatabaseModal