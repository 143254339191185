import React, {Component} from 'react'
import {connect} from 'react-redux'
import {gunaldiStyles} from "../../../SharedComponents/GunaldiDesignStyles";
import UpgradeButton from "../UpgradeButton"
import { Icon } from 'semantic-ui-react'
import {toggleModal} from "../../../Actions/ToggleModal";
import PlanDetailsModal from "./PlanDetailsModal";

const planDescBgColor = ["white", "#F4FBF3", "#6FBB98", gunaldiStyles.blue, "black"]

class PlanRow extends Component {
    constructor(props) {
        super(props);
    }

    openPlanDetails = () => {
        this.props.toggleModal("planDetailsModal")
    }

    render() {
        return (
            <div style={{border: "1px solid #F0F0F0", borderRadius: "4px", padding: "16px", backgroundColor: planDescBgColor[this.props.planIdx], color: this.props.planIdx >= 2 ? "white" : "black" }}>
                <PlanDetailsModal />
                <div style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                    <div>
                        <div style={{fontWeight: "700", fontSize: "16px"}}>
                            {this.props.planName.toUpperCase()}
                        </div>
                        {
                            this.props.planIdx < 4 ?
                                <div>
                                    <span className="linkStyle"
                                          style={{color: this.props.planIdx >= 2 ? "white" : "black" }}
                                          onClick={this.openPlanDetails}>
                                        <Icon name="eye" /> View Plan Details
                                    </span>
                                </div>
                                : null
                        }
                    </div>
                    <div style={{textAlign: "right"}}>
                        {this.props.planIdx < 4 ? <UpgradeButton /> : null }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(PlanRow)