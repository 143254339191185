import React, {Component} from 'react'
import SilentFail from "../Importer/SilentFail";

class GoogleDriveImporterError extends Component {
    render() {
        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">Error connecting to Google Account</p>
                    <p>If you are unable to connect your Google Account, it is likely that your Google account is connected to <i>another</i> API Spreadsheets account</p>
                    <p>Follow these steps to fix this issue</p>
                    <ol>
                        <li>Go to your <a href="https://myaccount.google.com/" target="_blank" rel="noopener noreferrer">Google Account</a></li>
                        <li>On the left navigation panel, select <b>Security</b></li>
                        <li>On the <i>Third-party apps with account access panel</i>, select <b>Manage third-party access.</b> (you might have to scroll down a bit to see this panel)</li>
                        <li>Select <b>API Spreadsheets</b>.</li>
                        <li>Select Remove Access</li>
                        <li>Log out of <b>API Spreadsheets</b> and clear your browser <b>cache</b></li>
                        <li>Log back in to API Spreadsheets and try again</li>
                    </ol>
                    <p>If you are still not able to connect, you MAY have to remove your access to API Spreadsheets from your Google Account. And try again.</p>
                    <ol>
                        <li>Log out of API Spreadsheets</li>
                        <li>Clear your browser cache and cookies. <a href="https://www.a2hosting.com/kb/getting-started-guide/internet-and-networking/clearing-a-web-browsers-cache-and-cookies" target="_blank" rel="noopener noreferrer">Read here how to do that</a></li>
                        <li>Log back in to API Spreadsheets and try again</li>
                    </ol>
                    <br/>
                    <p>If it still doesn't connect, follow the same steps as above but on a different browser</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Imported file failed to save to Google Account</p>
                    <p>If your connection was not working when the file was imported, then it will not be saved to your Google Account</p>
                    <SilentFail destination="Google Drive"/>
                </div>
            </div>
        )
    }
}

export default GoogleDriveImporterError