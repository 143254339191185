import React, {Component} from 'react'
import { Input, Button } from 'semantic-ui-react'

class WhitelistDomainsRow extends Component {
    handleInputChange = (e) => {
        this.props.handleInputChange(e.target.value, this.props.idx)
    }

    removeDomain = () => {
        this.props.removeDomain(this.props.idx)
    }

    render() {
        return (
            <div style={{marginTop: "5px"}}>
                <h6>Domain {this.props.idx + 1}</h6>
                <div className="row">
                    <div className="col-sm-8">
                        <input className="inputGray" style={{width: "100%"}} value={this.props.domainValue} onChange={this.handleInputChange} placeholder="Enter Domain"/>
                        {this.props.domainValue.trim() === "" ? <span style={{color: "red", fontSize: "12px"}}>Please enter a value</span> : null}
                    </div>
                    <div className="col-sm-4">
                        <div style={{textAlign: "left"}}>
                            <Button onClick={this.removeDomain} basic circular icon="trash alternate icon" color="red" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WhitelistDomainsRow