import React, {Component} from 'react'

class URLBox extends Component {
    render() {
        return (
            <div>
                <div className="urlBoxHeader">
                    {this.props.urlType}
                </div>
                <div className="urlBoxLink">
                    {this.props.url}
                </div>
            </div>
        )
    }
}

export default URLBox