import React, {Component} from 'react'
import {connect} from 'react-redux'
import {capitalize} from "../Constants/capitalize";
import { Icon } from 'semantic-ui-react'
import {modifyImporterMenuItem} from "../Actions/ModifyImporterMenuItem";

class ImportIndexMenuItem extends Component {
    modifyMenuItem = () => this.props.modifyImporterMenuItem(this.props.name)

    render() {
        let cName;

        if ('common' in this.props){
            cName = "commonIndexMenuItem"
        } else {
            cName = "importIndexMenuItem"
        }

        if (this.props.name === this.props.importerMenuItem) {
            if ('common' in this.props) {
                cName += " commonIndexMenuItemSelected";
            } else {
                cName += " importIndexMenuItemSelected";
            }
        }

        return (
            <div className={cName} onClick={this.modifyMenuItem}>
                <Icon name={this.props.icon} />
                <p>{capitalize(this.props.name)}</p>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    importerMenuItem: state.mainState.importerMenuItem
})

const mapActionsToProps = {
    modifyImporterMenuItem: modifyImporterMenuItem
}

export default connect(mapStateToProps, mapActionsToProps)(ImportIndexMenuItem)