import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'
import DocsImage from "../../DocsImage"

class Appearance extends Component {
    render(){
        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">Introduction</p>
                    <p style={{marginBottom: "5px"}}>There are plenty of options you can modify that will affect the <b>Appearance</b> of the importer. We are always adding more options here</p>
                    <DocsImage
                        src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/SpreadsheetImporter/ApperanceTab.png"
                        width="100%"
                        alt="Importer Appearance" />
                </div>
                <section id="multipleFiles">
                    <div className="docsSection">
                        <p className="docsHeader">Multiple Files</p>
                        <p>If selected, the user can upload multiple files at the same time</p>
                    </div>
                </section>
                <section id="removeBranding">
                    <div className="docsSection">
                        <p className="docsHeader">Remove API Spreadsheets Branding</p>
                        <p>If selected, the API Spreadsheets logo will not appear</p>
                        <p>This is only available on a <Label color="green">PRO</Label> or higher account</p>
                    </div>
                </section>
                <div className="docsSection">
                    <p className="docsHeader">Show File Information</p>
                    <p style={{marginBottom: "5px"}}>If selected, the user will see the file information displayed below the importer</p>
                    <DocsImage
                        src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/SpreadsheetImporter/ShowFileInfo.png"
                        width="100%"
                        alt="Importer File Information" />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Success Message</p>
                    <p style={{marginBottom: "5px"}}>This message will be shown to the user after a successful spreadsheet import</p>
                    <DocsImage
                        src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/SpreadsheetImporter/FileSuccess.png"
                        width="100%"
                        alt="Importer File Information" />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Error Message</p>
                    <p>This message will be shown to the user after a failed spreadsheet import</p>
                </div>
                <section id="fileExtensions">
                    <div className="docsSection">
                        <p className="docsHeader">File Extensions</p>
                        <p>The user will only be able to upload a file with the extensions that you configure</p>
                    </div>
                </section>
                <div className="docsSection">
                    <p className="docsHeader">Background Color</p>
                    <p>This will change the color of the border and folder icon of the importer. The default is our API Spreadsheets' Importer orange but you can change it to anything you want</p>
                </div>
            </div>
        )
    }
}

export default Appearance