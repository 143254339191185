import React, {Component} from 'react'
import {connect} from 'react-redux'
import isObjectEmpty from "../Constants/isObjectEmpty";
import { Button } from 'semantic-ui-react'
import API_Root from "../Constants/API_Root";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {toggleModal} from "../Actions/ToggleModal";
import {toggleToast} from "../Actions/ToggleToast";
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

class EditQuickstartButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false
        }
    }

    areJSONOutputsEqual = () => {
        let areSame = true;

        for (let key in this.props.jsonOutput){
            if (key in this.props.originalOutput){
                if (this.props.jsonOutput[key] !== this.props.originalOutput[key]){
                    areSame = false;
                    break;
                }
            } else{
                areSame = false;
                break;
            }
        }

        return areSame
    }


    saveQuickstartAPI = () => {
        this.setState({ saving: true })

        const error = () => {
            this.setState({
                saving: false
            }, () => {
                this.props.toggleModal(null)
                this.props.toggleToast({show: true, message: "There was an error creating your quickstart API. Please try again", type: "error"})
            })

        }

        fetch(API_Root + 'api/edit-quickstart-api/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                rows: this.props.rows,
                jsonOutput: this.props.jsonOutput,
                pk: this.props.pk
            })
        }).then(res => {
            if (res.status === 201){
                const fileInformation = JSON.parse(JSON.stringify(this.props.fileInformation))
                const info = fileInformation[this.props.pkID]
                info['jsonRowSample'] = this.props.jsonOutput
                fileInformation[this.props.pkID] = info
                this.props.modifyFileInformation(fileInformation)

                this.setState({ saving: false }, () =>
                {
                    this.props.toggleModal(null);
                    this.props.toggleToast({show: true, message: "Values successfully updated!", type: "success"})
                })
            } else {
                error()
            }
        }).catch(err => error())
    }



    render() {
        return (
            <Button style={gunaldiStyles.btnBlue}
                    content="Save Your Edited Values"
                    icon="save alternate outline"
                    loading={this.state.saving}
                    onClick={this.saveQuickstartAPI}
                    disabled={isObjectEmpty(this.props.jsonOutput) || this.areJSONOutputsEqual()} />
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation,
    toggleModal: toggleModal,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(EditQuickstartButton)