import React, {Component} from 'react'
import DocsImage from "../DocsImage";

class ActionsFileValues extends Component {
    render() {
        return (
            <div>
                <div className="docsSection">
                    <p>The File Data action lets you:</p>
                    <ol>
                        <li>View current data in your file in a spreadsheet grid</li>
                        <li>Share the data with a URL</li>
                    </ol>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">View current data</p>
                    <p>Click on <b>File Data</b> to view the current data of the file</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/FileValues-Click.png" alt='File Values click' />
                    <br/>
                    <p>This data is live and reflects EXACTLY what's in your file, Google Sheet, or Database at the moment</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/FileValues-Screen.png" alt='File Values screen' width="70%"/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Share the data</p>
                    <p>You can send the file data to anyone by sending this link</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/FileValues-Link.png" alt='File Values screen' width="70%"/>
                    <br/>
                    <p>Anyone in the world can open this link, and view the data in a spreadsheet grid</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/FileValues-Browser.png" alt='File Values screen' width="70%"/>
                </div>
            </div>
        )
    }
}

export default ActionsFileValues