import React, {Component} from 'react'
import { Message } from 'semantic-ui-react'

class BetaMessage extends Component {
    render() {
        return (
            <Message positive style={{marginBottom: '20px'}}>
                <Message.Header>This is currently a BETA feature</Message.Header>
            </Message>
        )
    }
}

export default BetaMessage