import React, {Component} from 'react'
import TierRestrictionBanner from "./TierRestrictionBanner";
import DocsImage from "../DocsImage";

class ActionsBlockRequests extends Component {
    render() {
        return (
            <div>
                <div style={{marginTop: "-8px", marginBottom: "16px"}}>
                    <TierRestrictionBanner plan="team" payment="blockRequests"/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">What are Block Requests</p>
                    <p>Blocking requests blocks your selection of request types (create, read, update, delete) from accessing the API</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/BlockRequests.png" alt="API Alerts Setup" width="70%"/>
                    <p>This is a security feature that helps you secure your API</p>
                    <p>For example, if you are using the API on the front-end to store Form data, you can block that data from being read, updated, or deleted</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">What happens when I execute a blocked request type</p>
                    <p>You will receive a <code>415</code> status code in the response</p>
                </div>
            </div>
        )
    }
}

export default ActionsBlockRequests