import React from 'react'
import currencyInfo from "../../Constants/currencyInfo";
import Pricing from "../../Constants/Pricing/Pricing"

const getCurrencyString = () => {
    let currencyValues = [];

    for (let currSymbol in currencyInfo){
        currencyValues.push(currencyInfo[currSymbol].text + " (" + currencyInfo[currSymbol].symbol + ")")
    }

    return currencyValues.join(", ")
}

const currencyString = getCurrencyString();

const contactUs = <a href="mailto:sales@apispreadsheets.com">contact us at sales@apispreadsheets.com</a>

const importerPricingFAQs = [
    {
        info: {
            name: "General",
            // type: "button"
        },
        faq: [
            {
                question: "How do I pay for the plan?",
                answer: <p>We accept all Credit Cards using <a href="https://www.stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a> as the payment provider! If you wish to pay via any other payment methods such as PayPal, direct deposit, wire transfer, or Venmo, please email us at <a href="mailto:sales@apispreadsheets.com">sales@apispreadsheets.com</a></p>,
                searchAnswer: "We accept all Credit Cards using Stripe as the payment provider! If you wish to pay via any other payment methods such as PayPal, direct deposit, wire transfer, or Venmo, please email us at sales@apispreadsheets.com"
            },
            {
                question: "Are there any other fees?",
                answer: "Nope, the price you see is the final price!"
            },
            {
                question: "What does a month mean?",
                answer: "A month begins and ends on the date you signed up. For ex: if you signed up on August 13th, then your month will renew on September 13th, October 13th and so on."
            },
            {
                question: "When does my yearly subscription renew?",
                answer: "The yearly subscription renews on the same date as you sign up. So if you signed up on February 28th 2022, then your subscription will renew on February 28th 2023 "
            },
            {
                question: "Do I receive any receipt of the plan?",
                answer: "Yes, you can download your receipts from your Dashboard under Profile."
            },
            {
                question: "How long can I use the free plan for?",
                answer: "As long as you want"
            },
            {
                question: "What if I want to cancel my account?",
                answer: <p>Simply email <a href="mailto:cancel@apispreadsheets.com?Subject=API%20Spreadsheets%Cancel%20Account"> cancel@apispreadsheets.com</a> from the email you signed up with and we will cancel your account!</p>,
                searchAnswer: "Simply email cancel@apispreadsheets.com from the email you signed up with and we will cancel your account!"
            },
            {
                question: "What if I want to downgrade my account to a lower tier?",
                answer: <p>Simply email <a href="mailto:support@apispreadsheets.com?Subject=API%20Spreadsheets%Downgrade%20Account"> info@apispreadsheets.com</a> from the email you signed up with, and the tier you wish to be downgraded to and we will take care of that right away!</p>,
                searchAnswer: "Simply email info@apispreadsheets.com from the email you signed up with, and the tier you wish to be downgraded to and we will take care of that right away!"
            },
            {
                question: "Is there a free trial for the paid accounts?",
                answer: "No, there is not. Since we offer a permanent free tier, we do not offer free trials. But if you want to test out a feature(s) in certain tiers, you can email us with your use case and we can provide you a free trial to those feature(s) for a certain period of time!"
            },
            {
                question: "What currencies do you support?",
                answer: "We currently support the following: " + currencyString
            },
            {
                question: "My currency is not supported. Can I still pay?",
                answer: <span>Yes, of course! If your currency is on the list <a href="https://stripe.com/docs/currencies?presentment-currency=US#presentment-currencies" target="_blank" rel="noopener noreferrer">here</a>, then you can pay in that currency. Email us at <a href="mailto:sales@apispreadsheets.com">sales@apispreadsheets.com</a> and we can set you up</span>,
                searchAnswer: "Yes, of course! If your currency is on the list here, then you can pay in that currency. Email us at sales@apispreadsheets.com and we can set you up"
            },
            {
                question: "I only need certain features that are offered. Can I pay for a custom plan?",
                answer: <span>Yes, you can! Please reach out to <a href="mailto:sales@apispreadsheets.com">sales@apispreadsheets.com</a> and we can customize a plan just for you!</span>,
                searchAnswer: "Yes, you can! Please reach out to sales@apispreadsheets.com and we can customize a plan just for you!"
            },
            {
                question: "How is my payment information handled?",
                answer: <span>We don't store any of your payment information. It all is handled by our payment processor Stripe. You can read their <a href="https://stripe.com/privacy" target="_blank">privacy policy here</a></span>,
                searchAnswer: "We don't store any of your payment information. It all is handled by our payment processor Stripe. You can read their privacy policy here"
            },
            {
                question: "I want to use this tool, but do not have any tech knowledge or technology resources. What can I do?",
                answer: <span>You can use our hosted importer without any tech knowledge whatsoever. If you wish to install the importer on your website and are a customer, you can {contactUs} and we will walk you through the steps needed for the importer to work!</span>
            },
            {
                question: "There is a certain feature or integration that I desperately need, but it is not offered. What can I do?",
                answer: <span>First, you can check on <a href="https://trello.com/b/4s8vwYDE/api-spreadsheets-roadmap" target="_blank">our roadmap</a> if it is a feature we are already working on. If not, you can {contactUs}. Depending on the feature, we may be able to build it insanely quickly (just ask any one of our business customers).</span>
            }
        ]
    },
    {
        info: {
            name: "Security"
        },
        faq: [
            {
                question: "How is my data secured?",
                answer: <div>
                    <ol>
                        <li>We encrypt data both at-rest and in-transit</li>
                        <li>When you delete a file or an account, everything related to is deleted. However, if you would like auditing and backups for your account, please {contactUs}</li>
                    </ol>
                </div>,
                searchAnswer: "We encrypt data both at-rest and in-transit. For spreadsheets connected from external sources, such as Google Sheet, we only store the connection information and no data. When you delete a file or an account, everything related to is deleted. However, if you would like auditing and backups for your account, please contact us. We offer plenty of API security features such as access & secret keys, domain whitelisting, blocking certain request types, and alerts"
            },
            {
                question: "I need more assurances for my data safety. Do you have any additional measures?",
                answer: <span>Yes, we can execute a data processor agreement with you to guarantee compliance and uptime. This costs extra depending on your organization size and requirements. Please {contactUs}</span>,
                searchAnswer: "Yes, we can execute a data processor agreement with you to guarantee compliance and uptime. This costs extra depending on your organization size and requirements. Please contact us"
            },
            {
                question: "My data is super duper sensitive. Do you have offer on-prem services?",
                answer: <span>Yes, we do! We use docker containers to easily install our software on your company's servers. This costs extra depending on your requirements. Please {contactUs}</span>,
                searchAnswer: "Yes, we do! We use docker containers to easily install our software on your company's servers. This costs extra depending on your requirements. Please contact us"
            }
        ]
    },
    {
        info: {
            name: "Rows per File"
        },
        faq: [
            {
                question: "How do the rows count work?",
                answer: "Each row in the file besides the column names counts as a row"
            },
            {
                question: "What happens if I manually add data to the file in my Google Sheets, DropBox, or AWS S3 account?",
                answer: "That does not affect the rows per file limit. The rows per file limit is only assessed at the time the file is uploaded through the importer",
            }
        ]
    },
    {
        info: {
            name: "File Count"
        },
        faq: [
            {
                question: "What counts as 1 file?",
                answer: "Each file uploaded by your user, client, or customer counts as one file"
            },
            {
                question: "What happens when I reach my file limit?",
                answer: "Don't worry, your users will be able to upload as many files as they want without through the importer even if you reach your limit. However, you will not be able to access the files until you upgrade",
            },
        ]
    },
    {
        info: {
            name: "Team Members"
        },
        faq: [
            {
                question: "How do Teams work?",
                answer: "You can share files, edit files and much more amongst a team"
            },
            {
                question: "Can I invite more team members than my account limit?",
                answer: <p>Yes, you can! Please {contactUs} and we can help you.</p>,
                searchAnswer: "Yes, you can! Contact Us and we can help you."
            },
            {
                question: "Can I control access for my team members?",
                answer: <p>Yes, there are different roles for team members. <a href="/api-docs/?docsMenuItem=teams" target="_blank">Read all about teams</a></p>,
                searchAnswer: "Yes, there are different roles for team members. Read all about teams"
            }
        ]
    },
    {
        info: {
            name: "API Included"
        },
        faq: [
            {
                question: "What does API refer to?",
                answer: <p>Our industry leading REST API is included for FREE with all the plans. The API product lets you instantly use your spreadsheets, and Google Sheets as a REST API and Database. And offers many more features. You can <a href="/" target="_blank" rel="noopener noreferrer">read all about the API here</a></p>,
                searchAnswer: "Our industry leading REST API is included for FREE with all the plans. The API products lets you instantly use your spreadsheets, and Google Sheets as a REST API and Database. And offers many more features. You can read all about the API here"
            },
            {
                question: "Does this mean I can read data from the importer files using the API?",
                answer: "Yes! As soon as a file is imported, it can be immediately read using the REST API!"
            }
        ]
    },
    {
        info: {
            name: "Webhooks"
        },
        faq: [
            {
                question: "What are Webhooks?",
                answer: "A webhook is a REST API URL endpoint that API Spreadsheets can call after a file being imported through the importer"
            },
            {
                question: "Why should Webhooks be set up?",
                answer: "You can do anything in a Webhook. For example, you can set up a slack notification to be prompted after a file has been imported."
            },
            {
                question: "What are the specs for using Webhooks?",
                answer: <span>You can read <a href="/import-docs/?docsMenuItem=creatingImporter-actions">this guide</a> on how to set up a webhook with the spreadsheet importer</span>,
                searchAnswer: "You can read this guide on setting up a webhook with the Spreadsheet API or this guide on how to setting up a webhook with the spreadsheet importer"
            }
        ]
    },
    {
        info: {
            name: "Customize Appearance"
        },
        faq: [
            {
                question: "What does customize appearance include?",
                answer: "You can remove the API Spreadsheets branding, set up password protection, and more."
            }
        ]
    },
    {
        info: {
            name: "Email Alerts"
        },
        faq: [
            {
                question: "What are email alerts?",
                answer: "You can configure to receive emails each time a file is imported through the importer."
            },
            {
                question: "Can I customize these emails?",
                answer: "Currently, you can choose whether to receive the uploaded file attached in the email or not. And you can configure whether to receive the email if there was an error. We are always adding more customization options"
            }
        ]
    },
    {
        info: {
            name: "Database Connection"
        },
        faq: [
            {
                question: "What is a database connection?",
                answer: <span>You can connect your databases so that spreadsheet data imported from the importer can be saved to your database tables. You can <a href="/import-docs/?docsMenuItem=database-introduction" target="_blank" rel="noopener noreferrer">read all about databases here</a> </span>,
                searchAnswer: "You can connect your databases so that spreadsheet data imported from the importer can be saved to your database tables"
            },
            {
                question: "What databases are supported?",
                answer: <p>We currently support <b>Postgres</b> and <b>MySQL</b> databases</p>,
                searchAnswer: "We currently support Postgres and MySQL databases"
            }
        ]
    },
    {
        info: {
            name: "Connect Your Domain"
        },
        faq: [
            {
                question: "What does Connect Your Domain mean?",
                answer: "You can connect your own domain to use for the importer. So instead of apispreadsheets.com it will be yourdomain.com"
            },
            {
                question: "How many domains can I connect?",
                answer: <div>
                    <p>Currently, you can connect 1 domain on the Business plan. If you wish to connect more domains, you can {contactUs} to upgrade to an Enterprise plan</p>
                </div>,
                searchAnswer: "Currently, you can connect 1 domain on the Business plan. If you wish to connect more domains, you can contact us to upgrade to an Enterprise plan"
            },
            {
                question: "How does Connect Your Domain work?",
                answer: <p>You can read the <a href="/api-docs/?docsMenuItem=integrations-connectDomain">Connect Your Domain guide here</a></p>,
                searchAnswer: "You can read the Connect Your Domain guide here"
            }
        ]
    },
    {
        info: {
            name: "Multiple Keys"
        },
        faq: [
            {
                question: "What do multiple keys mean?",
                answer: "You can create multiple Access keys and Secret keys to access files for your team or organization"
            },
            {
                question: "Why would I want to create multiple keys?",
                answer: "Creating multiple keys can let you control and monitor access from different people"
            },
        ]
    },
    {
        info: {
            name: "Domain Whitelist"
        },
        faq: [
            {
                question: "What does domain whitelist mean?",
                answer: "This lets you set up a list of domains that can access the API."
            },
            {
                question: "Why should domain whitelisting be set up?",
                answer: "This is a high grade security feature. It lets you protect your API from any unauthorized access other than your domains!"
            },
        ]
    },
    {
        info: {
            name: "Support Level"
        },
        faq: [
            {
                question: "What is the FAQ support type?",
                answer: "On the free plan, you are relegated to looking up answers in our FAQs, Guides, Tutorials & Videos"
            },
            {
                question: "What is the Email support type?",
                answer: "You can reach us through email, or a support ticket from Monday to Friday, 6AM to 6PM GMT. You will have a response within 1 business day"
            },
            {
                question: "What does the Priority support type mean?",
                answer: "You can reach us from Monday to Friday, 6AM to 6PM GMT, and will receive help from us before the end of the day that you reach us"
            },
            {
                question: "What does the Dedicated support type mean?",
                answer: "You can reach us from Monday to Friday, 6AM to 6PM GMT, and will receive help from us within 1 hour."
            }
        ]
    }
]

export default importerPricingFAQs