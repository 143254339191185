import React, {Component} from 'react'
import StartRead from "./StartRead";
import StartDelete from "./StartDelete";
import StartUpdate from "./StartUpdate";
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import { Icon } from 'semantic-ui-react'
import BannerAlert from "../../../../SharedComponents/BannerAlert";

class QuickstartRules extends Component{
    constructor(props){
        super(props);

        let fileID;
        try {
            if (this.props.fileInfo[this.props.editID].useFileHash){
                fileID = this.props.fileInfo[this.props.editID].fileHash
            } else {
                fileID = this.props.filePK[0].pk
            }
        } catch (e) {
            fileID = "5CF9MkyfCi1Vjnre"
        }

        this.state = {
            fileID: fileID
        }
    }

    render() {
        const actionTypeSubheading = this.props.actionType.replace("Action", "")

        const subHeadingStyle = {fontSize: "16px", color: gunaldiStyles.mainText, fontWeight: "700"}

        const startRows = {
            readAction: <StartRead fileID={this.state.fileID} headerStyle={subHeadingStyle}/>,
            deleteAction: <StartDelete fileID={this.state.fileID} headerStyle={subHeadingStyle}/>,
            updateAction: <StartUpdate fileID={this.state.fileID} headerStyle={subHeadingStyle}/>
        }

        const select1 = <span>SELECT <code>*</code></span>
        const select2 = <span>SELECT <code>id, name</code></span>
        const deleteQuery = <span>DELETE</span>

        const queryStarts = {
            readAction: Math.random() > 0.5 ? select1 : select2,
            updateAction: select1,
            deleteAction: deleteQuery
        }

        return (
            <div className="queryQuickstartRulesDiv">
                {startRows[this.props.actionType]}
                <br/>
                <div style={subHeadingStyle}>2. <code>filterClause</code> can contain <a href="https://www.w3resource.com/sql/comparison-operators/sql-comparison-operators.php" target="_blank" rel="noopener noreferrer">any SQL comparison operator such as {"<=, <"} etc.</a>. <code>NULL</code> is NOT allowed. Instead use a blank string <code>''</code></div>
                <div className="codeExampleBox">
                    <span>{queryStarts[this.props.actionType]} FROM <code>{this.state.fileID}</code> WHERE <code>{"name='CJ Cregg'"}</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <span>{queryStarts[this.props.actionType]} FROM <code>{this.state.fileID}</code> WHERE <code>{"name<>''"}</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <span>{queryStarts[this.props.actionType]} FROM <code>{this.state.fileID}</code> WHERE <code>{"id>5"}</code></span>
                </div>
                <br/>
                <div style={subHeadingStyle}>3. <code>filterClause</code> can contain <a href="https://www.w3resource.com/sql/boolean-operator/sql-boolean-operators.php" target="_blank" rel="noopener noreferrer"> any SQL logical operator, such as <code>AND</code>, <code>OR</code> etc. </a> It can also contain <a href="https://duckdb.org/docs/sql/functions/char" target="_blank" rel="noopener noreferrer">almost any TEXT Function</a></div>
                <div className="codeExampleBox">
                    <span>{queryStarts[this.props.actionType]} FROM <code>{this.state.fileID}</code> WHERE <code>{"name='CJ Cregg' AND id>5"}</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <span>{queryStarts[this.props.actionType]} FROM <code>{this.state.fileID}</code> WHERE <code>{"name='CJ Cregg' OR id BETWEEN 1 AND 10"}</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <span>{queryStarts[this.props.actionType]} FROM <code>{this.state.fileID}</code> WHERE <code>{"name IN ('CJ Cregg', 'Talulah Riley') AND substring(movie, 1,3)='The'"}</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <span>{queryStarts[this.props.actionType]} FROM <code>{this.state.fileID}</code> WHERE <code>{"name NOT IN('Adam Sandler', 'TOBY') AND country LIKE 'United%'"}</code></span>
                </div>
                <div style={{marginTop: "16px"}}>
                    <BannerAlert
                        type="display"
                        header='Learn more about writing queries in API Spreadsheets'
                        icon="bx bx-info-circle"
                        content={<span>Read our <a href="/api-docs/?docsMenuItem=api-query">guide</a> on how to write a valid query</span>}
                    />
                </div>
            </div>
        )
    }
}

export default QuickstartRules