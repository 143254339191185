import React, {Component} from 'react'
import { Table } from 'semantic-ui-react'
import ColumnName from "./ColumnName";
// import ColumnTypeDropdown from "./ColumnTypeDropdown";
import ColumnDesc from "./ColumnDesc";
import {connect} from "react-redux";
import ColumnInfoSaveBox from './ColumnInfoSaveBox'
import { Icon } from 'semantic-ui-react'
import ColumnNames from "./ColumnNames.svg"
import ColumnNamesTutorial from "./ColumnNamesTutorial"

class ColumnNamesInfoBody extends Component {
    constructor(props){
        super(props);

        this.state = {
            originalColInfo: JSON.parse(JSON.stringify(this.props.fileInformation[this.props.editID].columnInformation)),
            colInfo: JSON.parse(JSON.stringify(this.props.fileInformation[this.props.editID].columnInformation)),
            colNames: JSON.parse(JSON.stringify(this.props.fileInformation[this.props.editID].columnInformation.names)),
            saving: false,
            showingColumnTransformPic: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.changeColumnName = this.changeColumnName.bind(this);
        this.discardChanges = this.discardChanges.bind(this);
        this.showColumnTransformPic = this.showColumnTransformPic.bind(this);
    }

    changeColumnName(idx, name){
        let colNames = JSON.parse(JSON.stringify(this.state.colNames));

        colNames[idx] = name

        this.setState({
            colNames: colNames
        })
    }

    handleInputChange(idx, changeType, changeValue){
        let colInfo = JSON.parse(JSON.stringify(this.state.colInfo))
        let colNames = colInfo.names;
        const currColName = colNames[idx];
        let colNameInfo = colInfo.info;
        let currColNameInfo = colNameInfo[currColName];

        if (changeType === "columnName"){
            if (changeValue !== currColName){
                colNames[idx] = changeValue
                colInfo.names = colNames;

                colNameInfo[changeValue] = currColNameInfo;

                delete colNameInfo[currColName];

                colInfo.info = colNameInfo;

                this.setState({
                    colInfo: colInfo
                })
            }
        } else {
            currColNameInfo[changeType] = changeValue
            colNameInfo[currColName] = currColNameInfo

            colInfo.info = colNameInfo;

            this.setState({
                colInfo: colInfo
            })
        }

    }

    discardChanges(){
        const prevInfo = this.state.originalColInfo;

        this.setState({
            colNames: prevInfo.names,
            colInfo: prevInfo
        })
    }

    showColumnTransformPic(e){
        const currState = JSON.parse(JSON.stringify(this.state.showingColumnTransformPic));

        this.setState({ showingColumnTransformPic: !currState })
    }

    render() {
        const colInfo = this.state.colInfo;

        const colNames = colInfo.names;
        const colNamesInfo = colInfo.info;

        let rows = [];

        for (let i=0; i<colNames.length; i++){
            const colName = colNames[i];
            const colNameInfo = colNamesInfo[colName];

            rows.push(
                <div className="columnNamesInfoBodyRow" style={{
                    borderRight: "1px solid #F0F0F0",
                    borderLeft: "1px solid #F0F0F0",
                    borderBottom: "1px solid #F0F0F0",
                }}>
                    <div className="ellipses" style={{color: "#161E16", fontFamily: "Lato", fontSize: "14px", fontWeight: "500"}}><ColumnName idx={i} changeColumnName={this.changeColumnName}  allColumnNames={colNames} allColumnNamesEdit={this.state.colNames} handleInputChange={this.handleInputChange} /></div>
                    {/*<Table.Cell style={{color: 'gray'}}>{colNameInfo.original}</Table.Cell>*/}
                    <div><ColumnDesc idx={i} handleInputChange={this.handleInputChange} columnDesc={colNameInfo.desc}/></div>
                </div>
            )
        }


        return (
            <div>
                <div>
                    <ColumnNamesTutorial />
                    {/*<span className="linkStyle" onClick={this.showColumnTransformPic}>How are Column Names transformed <Icon name={this.state.showingColumnTransformPic ? "minus circle" : "plus circle"}/></span>*/}
                    {this.state.showingColumnTransformPic ? <img src={ColumnNames} alt="column names transform"/> : null}
                </div>
                <div style={{marginTop: "16px"}}>
                    <ColumnInfoSaveBox
                        prevInfo={this.state.originalColInfo}
                        currInfo={this.state.colInfo}
                        discardChanges={this.discardChanges}
                    />
                </div>
                <div>
                    <div className="columnNamesInfoBodyRow" style={{backgroundColor: "#F9F9F9", fontFamily: 'Lato', fontWeight: "700", fontSize: "14px", color: "#161E16"}}>
                        <div>Column Name</div>
                        <div>Description</div>
                    </div>
                    {rows}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ColumnNamesInfoBody)