import React, {Component} from 'react'
import {connect} from 'react-redux'
import {featureList} from "../../Constants/Pricing/featureList";
import PricingDescriptionBox from "../../InfoPages/Pricing/PricingDescriptionBox";
import PlanPricingBox from "../../InfoPages/Pricing/PlanPricingBox"
import {gunaldiStyles} from "../GunaldiDesignStyles";
import {getPricingPlanIdx} from "../../Constants/getPricingPlanIdx";

const planDescBgColor = ["white", "#F4FBF3", "#6FBB98", gunaldiStyles.blue]

class PlanDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            headerBoxHeight: 162
        }
    }

    changeHeaderBoxHeight = (boxHeight) => this.setState({ headerBoxHeight: boxHeight })

    render() {
        const planIdx = getPricingPlanIdx(this.props.tier);

        if (planIdx === 4){
            return (
                <div style={{border: "1px solid lightgrey", borderRadius: "4px", padding: "10px", marginTop: "-10px", boxShadow: "9px 7px 21px -10px rgba(0,0,0,0.75)"}}>
                    <p>This is a custom account. Please contact us at info@apispreadsheets.com if you want your plan details</p>
                </div>
            )
        } else {
            return (
                <div>
                    <PlanPricingBox
                        planIdx={planIdx}
                        featureList={featureList}
                        backgroundColor={planDescBgColor[planIdx]}
                        changeHeaderBoxHeight={this.changeHeaderBoxHeight}
                        profilePage
                    />
                </div>
            )
        }

    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(PlanDisplay)