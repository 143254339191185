import React, {Component} from 'react'
import PageHeaderButton from "../SharedComponents/PageHeaderButton"
import getURLParam from "../Constants/getURLParam";
import { connect } from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import DatabasePageHeaderDescription from "./DatabasePageHeaderDescription"

class DatabasePageHeaderToolbar extends Component {
    constructor(props) {
        super(props);
    }

    openModal(e, modalName){
        const databaseHash = getURLParam(window.location.href);

        this.props.modifyModalInfo({ databaseHash: databaseHash, location: "databasePage"})
        this.props.toggleModal(modalName + "Modal")
    }

    render() {
        return (
            <div style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                    <DatabasePageHeaderDescription desc={this.props.description} />
                <div style={{textAlign: "right"}}>
                    <PageHeaderButton
                        content="Delete Database"
                        color="red"
                        icon="trash alternate outline"
                        onClick={(e) => this.openModal(e, "deleteDatabase")}
                    />
                </div>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(null, mapActionsToProps)(DatabasePageHeaderToolbar)