import React, {Component} from 'react'
import SilcaLogo from "../../Constants/SilcaLogo.png";
import CTAButtonLanding from "../../SharedComponents/CTAButtonLanding"

class Silca extends Component {
    render() {
        return (
            <div>
                <a href="http://www.silca.cc" target="_blank" rel="noopener noreferrer">
                    <img src={SilcaLogo} style={{width: '40%', margin: '0 auto'}} alt="SilcaLogo" />
                </a>
                <br/><br/>
                <h1 className="thinHeading">How Silca uses API Spreadsheets to build their Email List</h1>
                <br/>
                <hr/>
                <h6>
                    A quick background. Silca makes awesome pumps, tools and accessories for your bicycle. They combine a deep Italian heritage with modern efficiency to make high quality and beautiful products. Check out their gear <a href="http://www.silca.cc/" target="_blank" rel="noopener noreferrer">here</a>.
                </h6>
                <br/>
                <h6>
                    They, like us, live to serve their customers and a huge part of helping out your customers is personalizing their experience with your product or service. On the web, that translates to Web Forms. A well-designed form can help your customer feel like the web experience is built for them. And on the other side, you get to know your customer and their needs deeply.
                </h6>
                <br/>
                <h6>
                    Silca created 2 web forms to help their customers. Their most popular form, <a href="https://silca.cc/pages/sppc-form" target="_blank" rel="noopener noreferrer">https://silca.cc/pages/sppc-form</a>, helps their customers calculate the optimal pressure for the tires. This form also has an optional email field that customers can enter their email to receive updates.
                </h6>
                <br/>
                <h6>
                    Using API Spreadsheets, Silca has been able to collect their customer emails and update them on any product and company updates. Not to mention they can capture and analyze all the other information their customers are providing.
                </h6>
                <br/>
                <h6>
                    It is a pain to analyze information collected from forms. But with API Spreadsheets, you can use Excel to look through it. A tool everyone is familiar with. Without API Spreadsheets, collecting information from a form is a tedious task. You can do it using form builders but it’s very hard to integrate them into a bespoke website, like Silca, and many others.
                </h6>
                <br/>
                <h6>
                    Another tool that Silca built is a Cycling Power Calculator, currently in Beta, <a href="https://silca.cc/pages/power-calc" target="_blank" rel="noopener noreferrer">https://silca.cc/pages/power-calc</a>. Tools like this are amazing for User Experience because they really personalize a web experience. People enjoy feeling like they are one in a million, not one of the herd. In this case, they can use these tools to determine how Silca can help them based on their personal cycle stats.
                </h6>
                <br/>
                <h6>
                    If you are like Silca, a small business making great products, that wants to help your customers on your website, we hope you consider using API Spreadsheets instead of going through a lengthy process of setting up a database to collect your data.
                </h6>
                <br/><br/><br/>
                <CTAButtonLanding />
            </div>
        )
    }
}

export default Silca