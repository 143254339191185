import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {toggleModal} from "../Actions/ToggleModal";
import {modifyFilePKProBlock} from "../Actions/ModifyFilePKProBlock";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import {initUploadedPK} from "../Actions/InitUploadedPK";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import {toggleToast} from "../Actions/ToggleToast";
import {readFiles} from "../Constants/readFile";
import {modifyStatus} from "../Actions/ModifyStatus";
import PageHeaderButton from "../SharedComponents/PageHeaderButton";
import {modifyAPIMenuItem} from "../Actions/ModifyAPIMenuItem";

class RefreshFile extends Component {
    constructor(props) {
        super(props);

        this.refreshFile = this.refreshFile.bind(this);
    }

    refreshFile(e){
        let modalName = this.props.inputType === "googleSheets" ? "loadingModalGoogleSheetsRefresh" : "loadingModalDropboxRefresh";
        this.props.toggleModal(modalName);

        const route = "read-api-sheets-dropbox"

        // Offset to get created dt
        let filePK = [{'id': this.props.pkID, 'pk': this.props.pk}];

        // Send the PKs and if any column names
        const socketRequest = {
            utcOffset: new Date().getTimezoneOffset(),
            filePK: filePK,
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
            sheetRead: this.props.sheetRead,
            refresh: true
        }

        readFiles(socketRequest, filePK, this.props, route, false, false)

    }

    render() {
        if ("header" in this.props){
            return (
                <span>
                    <PageHeaderButton content="Refresh File" color={this.props.color} icon='refresh' onClick={this.refreshFile} updatedClassName={this.props.updatedClassName}/>
                </span>
            )
        } else {
            return (
                <span>
                    <Button content="Refresh File" basic color='green' icon='refresh' onClick={this.refreshFile} fluid />
                </span>
            )
        }

    }
}

const mapStateToProps = (state) => ({
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock,
    fileInformation: state.mainState.fileInformation,
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyFilePKProBlock: modifyFilePKProBlock,
    modifyFileInformation: modifyFileInformation,
    modifySheetRead: modifySheetRead,
    initUploadedPK: initUploadedPK,
    modifyFilePK: modifyFilePK,
    toggleToast: toggleToast,
    modifyStatus: modifyStatus,
    modifyAPIMenuItem: modifyAPIMenuItem
}

export default connect(mapStateToProps, mapActionsToProps)(RefreshFile)