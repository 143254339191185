import React, {Component} from 'react'
import JSONDisplay from "../../SharedComponents/JSONDisplay";
import {generateRandomKey} from "../../Constants/generateRandomKey";

class PostPrivateHeaders extends Component {
    render() {
        const accessKey = 'accessKeyInfo' in this.props ? this.props.accessKeyInfo :  generateRandomKey(32);
        const secretKey = 'secretKeyInfo' in this.props ? this.props.secretKeyInfo :  generateRandomKey(32);
        return (
            <div>
                <JSONDisplay
                    heading="Include your Keys in your Request Header"
                    jsonCode={'{"accessKey": "' + accessKey + '", "secretKey": "' + secretKey + '"}'}
                />
            </div>
        )
    }
}

export default PostPrivateHeaders