import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class NavigateToolbar extends Component {
    constructor(props) {
        super(props);
    }

    getPrefixSubstring = () => this.props.prefix.substring(0, this.props.prefix.length - 2)

    getBackLinkText = () => {
        switch(this.props.currentDisplay) {
            case "buckets":
                return "Go back to account selection"
            case "files":
                if (this.props.prefix === ""){
                    return "Go back to bucket selection"
                } else {
                    const prefixSubstr = this.getPrefixSubstring();
                    if (prefixSubstr.includes("/")){
                        return prefixSubstr.substring(0, prefixSubstr.lastIndexOf("/") + 1)
                    } else {
                        return "Go back to " + this.props.bucketName
                    }
                }
            default:
                return ""
        }
    }

    goBack = () => {
        switch(this.props.currentDisplay) {
            case "buckets":
                this.props.changeState({currentDisplay: "accounts"})
                break;
            case "files":
                if (this.props.prefix === ""){
                    this.props.changeState({currentDisplay: "buckets"})
                    break;
                } else {
                    let prefix;
                    const prefixSubstr = this.getPrefixSubstring();
                    if (prefixSubstr.includes("/")){
                        prefix = prefixSubstr.substring(0, prefixSubstr.lastIndexOf("/") + 1)
                    } else {
                        prefix = ""
                    }

                    this.props.getFiles(this.props.bucketName, prefix)
                    break
                }
            default:
                return ""
        }
    }

    render() {
        return (
            <div>
                {
                    this.props.currentDisplay !== "accounts" ?
                        <div style={{marginTop: "10px"}}>
                            <p className="linkStyle" onClick={this.goBack} style={{marginBottom: "10px"}}>
                                <Icon name="left arrow" /> {this.getBackLinkText()}
                            </p>
                            { this.props.currentDisplay !== "buckets" ? <h5><b>{this.props.bucketName + "/" + this.props.prefix}</b></h5> : null }
                        </div>
                        : null
                }
            </div>
        )
    }
}

export default NavigateToolbar