import React, {Component} from 'react'
import {getPricingPlanIdx} from "../../../Constants/getPricingPlanIdx";
import {planBgColor} from "../../../Constants/planBgColor";
import {gunaldiStyles} from "../../../SharedComponents/GunaldiDesignStyles";
import { connect } from 'react-redux'
import {toggleModal} from "../../../Actions/ToggleModal";
import {modifyPaymentModalOrigin} from "../../../Actions/ModifyPaymentModalOrigin";

class TierRestrictionBanner extends Component {
    openPaymentModal = () => {
        this.props.modifyPaymentModalOrigin(this.props.payment)
        this.props.toggleModal("paymentModal")
    }

    render() {
        const planIdx = getPricingPlanIdx(this.props.plan)
        let tier = this.props.tier;

        if (tier === null){
            tier = "free"
        }

        const userPlanIdx = getPricingPlanIdx(tier)

        if (userPlanIdx < planIdx){
            const planColor = planBgColor[planIdx]

            return (
                <div style={{padding: "16px",
                    color: this.props.plan === "pro" ? gunaldiStyles.mainText : planColor,
                    backgroundColor: this.props.plan === "pro" ? planColor : "white",
                    border: "1px solid " + planColor,
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: "700"}}>
                    <div className="row">
                        <div className="col-sm-8">
                            This feature is only available on the {this.props.plan.toUpperCase()} and higher tier plans
                        </div>
                        <div className="col-sm-4" style={{textAlign: "right"}}>
                            {
                                localStorage.getItem("email") === null ? null : <span className="linkStyle" onClick={this.openPaymentModal}>Upgrade</span>
                            }
                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyPaymentModalOrigin: modifyPaymentModalOrigin
}

export default connect(mapStateToProps, mapActionsToProps)(TierRestrictionBanner)