import React, {Component} from 'react'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import DemoVideoSquare from "./DemoVideoSquare";

class DemoVideosSection extends Component {
    render() {
        let demoSectionRows = [];
        let demoSectionColumns = [];

        for (let i=0; i<this.props.videos.length; i++){
            if (i % 3 === 0){
                if (i !== 0){
                    demoSectionRows.push(
                        <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", columnGap: "8px", marginBottom: "16px"}} key={i}>
                            {demoSectionColumns}
                        </div>
                    )
                }

                demoSectionColumns = []
            }

            demoSectionColumns.push(<DemoVideoSquare info={this.props.videos[i]} key={i}/>)

            if (i === this.props.videos.length - 1){
                demoSectionRows.push(
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", columnGap: "8px", marginBottom: "16px"}} key={i}>
                        {demoSectionColumns}
                    </div>
                )
            }

        }

        return (
            <div style={{marginBottom: "32px"}}>
                <div style={{fontSize: "18px", color: gunaldiStyles.mainText, fontWeight: "700"}}>
                    {this.props.header}
                </div>
                <div style={{fontSize: "12px", color: gunaldiStyles.subText, marginBottom: "16px"}}>
                    {this.props.desc}
                </div>
                {demoSectionRows}
            </div>
        )
    }
}

export default DemoVideosSection