import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Checkbox } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import API_Root from "../../../../Constants/API_Root";
import Loader from 'react-loader-spinner'

class ColumnMatchToggle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            updating: false
        }
    }

    toggleMatchColumns = () => {
        const currOptions = JSON.parse(JSON.stringify(this.props.options))
        const currOptionsEdit = JSON.parse(JSON.stringify(this.props.optionsEdit))

        this.updateColumnMatchInBackend(currOptions, currOptionsEdit)
    }

    updateColumnMatchInBackend = (currOptions, currOptionsEdit) => {
        const error = () => this.setState({ updating: false }, () => alert("There was an error updating your column settings"))

        fetch(API_Root + "api/update-import-details/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                importHash: this.props.importHash,
                changeObject: {matchColumns: !this.props.optionsEdit.matchColumns}
            })
        }).then((res) => {
            if (res.status === 200) {
                this.updateColumnMatchInRedux(currOptions, currOptionsEdit)
            }
            else {
                error()
            }
        }).catch(err => error())
    }

    updateColumnMatchInRedux = (currOptions, currOptionsEdit) => {
        const value = currOptionsEdit.matchColumns;
        currOptionsEdit.matchColumns = !value
        currOptions.matchColumns = !value;

        this.props.modifyCustomImportSettings("optionsEdit", currOptionsEdit)
        this.props.modifyCustomImportSettings("options", currOptions)

        this.setState({ updating: false })
    }

    render() {
        if (this.state.updating){
            return (
                <Loader
                    type="TailSpin"
                    color="black"
                    height="20"
                    width="20"
                />
            )
        } else {
            return (
                <div style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                    <div>
                        <span style={{fontFamily: "Lato", fontWeight: "700", fontSize: "16px"}}>Match columns after File Upload</span>
                    </div>
                    <div style={{textAlign: "right"}}>
                        <Checkbox
                            toggle
                            checked={this.props.optionsEdit.matchColumns}
                            onChange={this.toggleMatchColumns}
                        />
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    options: state.mainState.customImportOptions,
    optionsEdit: state.mainState.customImportOptionsEdit
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnMatchToggle)