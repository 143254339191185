import React, {Component} from 'react'
import Loader from 'react-loader-spinner'
import NotFound from "../InfoPages/NotFound"
import {getQueryParamValue} from "../Constants/getQueryParamValue";
import { Message } from 'semantic-ui-react'
import ResetPasswordIndex from "./UserPages/Password/ResetPasswordIndex"
import VerifyEmailIndex from "./VerifyEmailIndex"
import AuthComponent from "../SharedComponents/AuthComponent";
import LoginVerificationEmail from "./LoginVerificationEmail"

class FirebaseUserActions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: null,
            email: "",
            error: false
        }
    }

    componentDidMount(){
        const url = window.location.href;
        const modeValue = getQueryParamValue("mode", url)

        const hitError = () => this.setState({ mode: modeValue, error: true })

        if (modeValue === "resetPassword"){
            const oobCode = getQueryParamValue("oobCode", url)
            const apiKey = getQueryParamValue("apiKey", url)

            const API = "https://www.googleapis.com/identitytoolkit/v3/relyingparty/resetPassword?key=" + apiKey;

            fetch(API, {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    oobCode: oobCode
                })
            }).then(res => res.json())
                .then(data => {
                    this.setState({email: data.email, mode: modeValue, error: false})
                }).catch(err => hitError()).catch(err => hitError());
        } else {
            this.setState({
                mode: modeValue,
                error: false
            })
        }
    }

    render() {
        let body = <NotFound/>

        if (this.state.mode === null){
            body = <div style={{textAlign: "center", padding: "50px"}}>
                <Loader
                    type="TailSpin"
                    color="black"
                    height="50"
                    width="50"
                />
            </div>
        } else {
            if (this.state.error){
                body =
                <div style={{textAlign: "center", padding: "50px"}}>
                    <Message error
                             header="There was an error completing your action"
                             content="Please try again or contact us if this persists" />
                </div>
            } else {
                if (this.state.mode === "resetPassword"){
                    body = <ResetPasswordIndex email={this.state.email} />
                } else if (this.state.mode === "verifyEmail") {
                    body =
                        <AuthComponent
                            authComponent={<VerifyEmailIndex />}
                            nonAuthComponent={<LoginVerificationEmail />}
                        />
                }
            }
        }

        return (
            <div style={{ minHeight: '100vh'}}>
                {body}
            </div>
        )
    }
}

export default FirebaseUserActions