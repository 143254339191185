import React, {Component} from 'react'
import {connect} from 'react-redux'
import APIIndexMenuItem from "./APIIndexMenuItem"

class APIIndexMenu extends Component {
    render() {
        return (
            <div className="navbarAuthDiv" style={{minHeight: '100vh', padding: "16px"}}>
                <div style={{marginBottom: "16px"}}>
                    <APIIndexMenuItem
                        name="upload"
                        icon="upload"
                    />
                </div>
                <div style={{marginBottom: "16px"}}>
                    <APIIndexMenuItem
                        name="files"
                        icon="file alternate"
                    />
                </div>
                <div style={{marginBottom: "16px"}}>
                    <APIIndexMenuItem
                        name="docs"
                        icon="book"
                    />
                </div>
                <div style={{marginBottom: "16px"}}>
                    <APIIndexMenuItem
                        name="demos"
                        icon="video"
                    />
                </div>
                <div style={{marginBottom: "16px"}}>
                    <APIIndexMenuItem
                        name="database"
                        icon="database"
                        common
                    />
                </div>
                <div style={{marginBottom: "16px"}}>
                    <APIIndexMenuItem
                        name="connect domain"
                        icon="window maximize outline"
                        common
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(APIIndexMenu)