import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import { Table, Input, Button } from 'semantic-ui-react'
import {isEmailValid} from "../../../../Constants/isEmailValid";

class ActionsEmailIndex extends Component {
    componentDidMount(){
        const allAlertEmails = JSON.parse(JSON.stringify(this.props.optionsEdit.alertEmails));
        const userEmail = localStorage.getItem("email")

        if (!allAlertEmails.includes(userEmail)){
            allAlertEmails.push(userEmail)
        }

        this.props.changeStateInRedux("alertEmails", allAlertEmails)
    }

    handleInputChange = (e) => {
        const allAlertEmails = JSON.parse(JSON.stringify(this.props.optionsEdit.alertEmails));
        allAlertEmails[parseInt(e.target.name)] = e.target.value;

        this.props.changeStateInRedux("alertEmails", allAlertEmails)
    }

    addNewAlertEmail = () => {
        const allAlertEmails = JSON.parse(JSON.stringify(this.props.optionsEdit.alertEmails));
        allAlertEmails.push("")

        this.props.changeStateInRedux("alertEmails", allAlertEmails)
    }

    deleteEmail = (idx) => {
        const options = JSON.parse(JSON.stringify(this.props.optionsEdit));
        const alertEmailsCopy = options.alertEmails;

        alertEmailsCopy.splice(idx, 1)
        options.alertEmails = alertEmailsCopy

        this.props.changeStateInRedux("alertEmails", alertEmailsCopy)
    }

    render() {
        const allAlertEmails = JSON.parse(JSON.stringify(this.props.optionsEdit.alertEmails));

        const emailRows = allAlertEmails.map((x, idx) =>
        <Table.Row key={idx}>
            <Table.Cell>
                <Input focus fluid value={x} name={idx} onChange={this.handleInputChange} />
                {isEmailValid(x) ? null : <small style={{color: 'red'}}><i>Please enter a valid email</i></small>}
            </Table.Cell>
            <Table.Cell>
                <Button
                    circular
                    onClick={e => this.deleteEmail(idx)}
                    icon="trash alternate outline"
                    color="red"
                    basic
                    size="mini"
                />
            </Table.Cell>
        </Table.Row>)

        return (
            <div style={{marginTop: "5px"}}>
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={15}>Emails to send alerts</Table.HeaderCell>
                            <Table.HeaderCell width={1}> </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {emailRows}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={2}>
                                <div style={{textAlign: "right"}}>
                                    <Button style={{backgroundColor: "#0081ff", color: "white", borderRadius: "25px", cursor: "pointer"}}
                                            content="Add Email" size="mini" icon="plus" onClick={this.addNewAlertEmail}/>
                                </div>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    options: state.mainState.customImportOptions,
    optionsEdit: state.mainState.customImportOptionsEdit,
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(ActionsEmailIndex)