import React, {Component} from 'react'
import ImportCreateButton from "./ImportCreateButton";
import PageHeaderHelp from "../SharedComponents/PageHeaderHelp";

class ImportHeaderToolbar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ display: "grid", gridTemplateColumns: "50% 50%"}}>
                <div>
                    <ImportCreateButton type="custom"/>
                </div>
                <div>
                    <PageHeaderHelp
                        header="Need help getting started with Importer"
                        url="/import-docs/?docsMenuItem=quickstart"
                        desc="Read our guide on how to create an importer"
                    />
                </div>
            </div>
        )
    }
}

export default ImportHeaderToolbar