import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyEditID} from "../Actions/ModifyEditID";
import {toggleModal} from "../Actions/ToggleModal";

class ColumnNames extends Component {
    constructor(props) {
        super(props);

        this.showColumnNames = this.showColumnNames.bind(this);
    }

    showColumnNames(e){
        this.props.modifyEditID(this.props.pkID)
        this.props.toggleModal("columnNamesModal")
    }

    render() {
        // let allClassNames = "shadowDiv ";

        // if (["createActionColumns", "updateActionColumns"].includes(this.props.tutorialStep)){
        //     allClassNames += "tutorialHighlighted"
        // }
        // <div className={allClassNames} id="columnNamesDiv">

        return (
            <div><Button content="Column Names" basic color='blue' icon='columns' onClick={this.showColumnNames} fluid/></div>
        )
    }
}

const mapStateToProps = (state) => ({
    tutorialStep: state.mainState.tutorialStep
})

const mapActionsToProps = {
    modifyEditID: modifyEditID,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnNames)