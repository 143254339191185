import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import API_Root from "../../../../Constants/API_Root";
import {modifyEditOptionsForBackend} from "./modifyEditOptionsForBackend";
import {getShouldMatchColumns} from "../../getShouldMatchColumns";
import {removePKFromArray} from "../../../../Constants/removePKFromArray";
import copyObject from "../../../../Constants/copyObject";
import {modifyStatus} from "../../../../Actions/ModifyStatus";
import {modifyImportSheetsFileInformation} from "../../../../Actions/ModifyImportSheetsFileInformation";
import {modifyImportSheetRead} from "../../../../Actions/ModifyImportSheetRead";
import {toggleToast} from "../../../../Actions/ToggleToast";
import {modifyFileInfoForCallback} from "../../modifyFileInfoForCallback";

class SaveEditSettingsBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false
        }
    }

    saveEditSettings = () => {
        // Websocket and change file information and everything
        // Init columns again
        // Switch to mapping
        // Reset edit options to default again

        const isEmbed = 'embed' in this.props;

        this.setState({ saving: true })

        const editOptions = modifyEditOptionsForBackend(this.props.editOptions)

        const shouldMatchColumns = getShouldMatchColumns(this.props.info)
        const columnsMap = shouldMatchColumns ? [] : null;

        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/read-api-files/");

        const socketInfo = {
            utcOffset: new Date().getTimezoneOffset(),
            filePK: [this.props.pkInfo],
            sheetRead: this.props.importSheetRead,
            importKey: this.props.importKey,
            email: this.props.userEmail,
            importName: localStorage.getItem("importName"),
            editOptions: editOptions,
            columnsMap: columnsMap
        }

        socket.onopen = (event) => {
            socket.send(JSON.stringify(socketInfo))
        }

        socket.onmessage = (event) => {
            const endEditing = () => {
                this.props.modifyCustomImportSettings("shouldEdit", false)
                this.props.modifyCustomImportSettings("editOptions", {header: true, skipRows: 0, startRow: 0, delimiter: ",", skipRowsError: false, startRowError: false, rows: [[]], columns: [], sheetRead: null, sheetNames: []})

                if (!isEmbed){
                    this.props.initColumnMatchRows()
                }
            }

            const reply = JSON.parse(event.data);
            const status = reply['status'];

            if (status === "read") {
                this.props.modifyStatus(status);
            }

            if (status === "api") {
                this.props.modifyStatus(status);
                let fileInformation = reply['fileInformation'];
                let sheetRead = reply['sheetRead'];

                if (typeof this.props.importSheetsFileInformation !== 'undefined') {
                    if (Object.entries(this.props.importSheetsFileInformation).length !== 0 && this.props.importSheetsFileInformation.constructor === Object) {
                        fileInformation = copyObject(this.props.importSheetsFileInformation, reply.fileInformation);
                        sheetRead = copyObject(this.props.importSheetRead, reply.sheetRead);
                    }
                }

                this.props.modifyImportSheetsFileInformation(fileInformation);
                this.props.modifyImportSheetRead(sheetRead);

                this.props.modifyCustomImportSettings("shouldEdit", true)
                this.props.modifyCustomImportSettings("matchColumns", shouldMatchColumns)
                socket.close()
                this.setState({ saving: false })
                endEditing()
            }

            if (status === "error") {
                this.props.modifyStatus("upload")
                this.props.modifyImportSheetsUploadPK(removePKFromArray(this.props.importSheetsUploadPK, [this.props.pkInfo]));
                const erorrMessage = 'errorMessage' in reply ? reply['errorMessage'] : "There was an error reading your file :( Please try again"
                this.props.toggleToast({show: true, message: erorrMessage, type: "error"})

                socket.close()
                this.setState({ saving: false })
                endEditing()
            }

            if ('embed' in this.props){
                if (status === "api"){
                    const copiedFileInfo = JSON.parse(JSON.stringify(this.props.importSheetsFileInformation))
                    const sheetRead = JSON.parse(JSON.stringify(this.props.importSheetRead))
                    const pk = this.props.pkInfo.pk;
                    const id = this.props.pkInfo.id;

                    const fileInfo = modifyFileInfoForCallback(copiedFileInfo, id, pk, sheetRead)

                    this.sendMessage(true, fileInfo, this.props.fileID)
                } else if (status === "error"){
                    this.sendMessage(false, null, this.props.fileID)
                }
            }
        };
    }

    sendMessage = (success, fileInfo, editFileID) => {
        const parentWindow = window.parent;

        parentWindow.postMessage({success: success, fileInfo: fileInfo, editFileID: editFileID}, "*")
    }

    render() {
        let saveText = null;
        if ('embed' in this.props){
            saveText = <span>Save Settings</span>
        } else {
            saveText = <span>Save Edit Settings and Map Columns <Icon name="arrow right" /></span>
        }

        return (
            <Button
                size="large"
                color="blue"
                loading={this.state.saving}
                onClick={this.saveEditSettings}>
                <Icon name="save alternate outline" /> <Icon name="edit alternate outline" />
                {saveText}
            </Button>
        )
    }
}

const mapStateToProps = (state) => ({
    editOptions: state.mainState.customImportEditOptions,
    importSheetRead: state.mainState.importSheetRead,
    info: state.mainState.customImportInfo,
    importSheetsFileInformation: state.mainState.importSheetsFileInformation,
    importSheetsUploadPK: state.mainState.importSheetsUploadPK
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings,
    modifyStatus: modifyStatus,
    modifyImportSheetsFileInformation: modifyImportSheetsFileInformation,
    modifyImportSheetRead: modifyImportSheetRead,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(SaveEditSettingsBtn)