import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button, Message, Label, Checkbox, Icon, Popup} from 'semantic-ui-react';
import ImportSheets from './ImportSheets'
import RemoveFile from './RemoveFile';
import {toggleModal} from "../Actions/ToggleModal";
import {modifyEditID} from "../Actions/ModifyEditID";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import PrivateButton from './PrivateButton'
import KeysButton from './KeysModal/KeysButton'
import ShareButton from './ShareButton'
import FileSource from "./FileSource";
import TeamFileButton from "../FileDnD/TeamFile/TeamFileButton"
import ColumnNames from './ColumnNames'
import FileDescriptionButton from "./FileDescription/FileDescriptionButton";
import FileOptions from "./FileOptions"
import AnalyticsButton from './AnalyticsButton'
import AlertsButton from "./Alerts/AlertsButton"
import DownloadFileButton from './DownloadFileButton'
import WhitelistDomainsButton from "./WhitelistDomains/WhitelistDomainsButton"
import ReuploadFile from './ReuploadFile'
import BlockRequestsButton from "./BlockRequests/BlockRequestsButton"
import FileDescriptionModalBody from './FileDescription/FileDescriptionModalBody'
import FileSummaryCodeAccordion from './FileSummaryCode/FileSummaryCodeAccordion'
import {getPricingText} from "../Constants/getPricingText";
import {isBrowser, isMobile } from 'react-device-detect'
import FixColumnNames from "./FixColumnNames/FixColumnNames"
import VisualizationButton from "./Visualization/VisualizationButton"
import ReportingButton from "./Reporting/ReportingButton"
import WebhookButton from "./Webhook/WebhookButton"
import SharedLabel from "./SharedLabel"
import pricing from "../Constants/Pricing/Pricing";
// import TestQueryButton from "./TestQueryButton"
// import FileSummaryBaseURL from "./FileSummaryBaseURL";
import UpdateFileHashButton from "./UpdateFileHashButton";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import FilesTabFileSource from "./FilesTabFileSource";
import "../index.css"
import {Link} from "react-router-dom";
import FilesTabFileSourceNames from "./FilesTabFileSourceNames";
import FilesTabFileOptions from "./FilesTabFileOptions";
import FileTabRemoveFile from "./FileTabRemoveFile";
import FilesTabSharedLabel from "./FilesTabSharedLabel";
import {toggleToast} from "../Actions/ToggleToast";
import FileRowErrorContent from "./FileRowErrorContent"
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import {getFileLimitMessage} from "../Constants/getFileLimitMessage";
import { push } from 'react-router-redux'
import {isFileInLazyMode} from "../Constants/isFileInLazyMode";
import {isUserAMacroUser} from "../Constants/isUserAMacroUser";

class FilesTabFileSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            outputFormat: 'jsonRow',
            apiType: 'get',
            rows: this.props.fileInformation[this.props.pkID].rowLength,
            previewHash:false,
            popupContent: "Copy ID",
            accordionShow: false
        }

        this.openEditModal = this.openEditModal.bind(this);
        this.changeState = this.changeState.bind(this);
        this.openPaymentModal = this.openPaymentModal.bind(this);
    }

    openEditModal(){
        this.props.modifyEditID(this.props.pkID)
        this.props.toggleModal("editModal")
    }

    changeState(name, value){
        this.setState({
            [name]: value
        })
    }

    openPaymentModal(e){
        this.props.toggleModal("paymentModal");
    }

    togglePreviewHash = () => {
        this.setState({previewHash: !this.state.previewHash})
    }

    openReadMoreModal = (e, fileHash, fileID) => {
        this.props.modifyModalInfo({
            fileHash: fileHash,
            fileID: fileID
        })
        this.props.toggleModal("fileIDReadMoreModal")
    }

    copyID = (id) => {
        navigator.clipboard.writeText(id)
        this.props.toggleToast({show: true, message: "File ID Copied", type: "success"})
    }

    getCreatedDt = (dt) => {
        // if (dt.toString().length >= 10){
        //     return dt.toString().substring(0, 10)
        // } else {
        //     return dt.toString()
        // }
        return dt
    }

    goToFile = (e, disable, fileHash) => {
        let classNames;
        try {
            classNames = e.target.className
        } catch(err) {
            classNames = ""
        }

        if (classNames === null){
            classNames = ""
        }

        if (disable === null){
            if (classNames.indexOf("fileIDLabelIcon") === -1 && classNames.indexOf("fileTabRemoveFileBtn") === -1){
                if (this.props.isImporterPage){
                    this.props.navigateTo("/files/" + fileHash + "?importerFilePage")
                } else {
                    this.props.navigateTo("/files/" + fileHash)
                }
                // this.filesTabFileSummaryLink.click()
            }
        }
    }

    toggleAccordion = () => {
        const currShow = this.state.accordionShow;

        this.setState({ accordionShow: !currShow })
    }

    render() {
        const fileInformation =  this.props.fileInformation[this.props.pkID];

        let useFileHash = fileInformation["useFileHash"]

        let fileHash = fileInformation["fileHash"]

        let upgradeMessage = !useFileHash ?
            <div style={{marginBottom: "10px"}}>
                <Message positive
                         header="Upgrade to a More Secure File ID"
                         content={<div style={{marginTop: "5px"}}>
                             <Checkbox toggle label="Preview how upgrade will look"
                                       checked={this.state.previewHash}
                                       onChange={this.togglePreviewHash} />
                             {this.state.previewHash ? <UpdateFileHashButton pk={this.props.pk} pkID={this.props.pkID} />:null}
                             <small style={{display:'block',margin:'0'}}> Don't worry, this is just a preview! <span className="linkStyle" onClick={e => this.openReadMoreModal(e, fileHash, this.props.pk.toString())}>Read more about new File IDs</span> </small>
                         </div>}
                />
            </div> :null

        let fileName = fileInformation.name;
        // const size = formatBytes(fileInformation.size);

        const isExcel = fileName.split('.').pop() !== "csv" && fileName.split('.').pop() !== "txt";

        const proBlock = this.props.filePKProBlock[this.props.pkID];

        const fileDivStyle = {
            borderTop:'1px solid #F0F0F0',
            borderBottom:'1px solid #F0F0F0',
            margin: '0px 0px',
            // height:'70px',
            // marginTop: '20px',
            cursor:'pointer',
            padding: "10px"
        };
        let disable = null;
        let proMessage = null;
        // let displayFileHash = useFileHash ? fileHash: this.props.pk.toString();
        // let displayFileHash = useFileHash ? fileHash: this.props.pk.toString();
        let displayFileHash = useFileHash || this.state.previewHash ? fileHash: this.props.pk.toString();

        let fileIDLabel = <p className="fileIDLabel">
                            <Popup content={this.state.popupContent}
                                   trigger={<Icon name="copy outline"
                                                  className="fileIDLabelIcon"
                                                  color="green"
                                                  onClick={() => this.copyID(displayFileHash)}
                                   />}
                            />
                            {displayFileHash}
                        </p>;

        let allBlocks = [];

        if (proBlock){
            // disable = {pointerEvents: 'none', opacity: '0.2'}
            disable = true
            proMessage =
                <div className="row">
                    <div className="col-sm-9">
                        <Message
                            floating={true} negative={true}
                            icon='lock'
                            header={getFileLimitMessage(this.props.proBlockTooManyFiles, this.props.pkID, this.props.tier, this.props.appsumoPlanName, this.props.isGrandfather)}
                            content={<Button onClick={this.openPaymentModal} color="green">{"Upgrade to Pro for just " + getPricingText(this.props.currency, 1)}</Button>}
                        />
                    </div>
                    <div className="col-sm-3">
                        <RemoveFile idx={this.props.idx} pkID={this.props.pkID} pk={this.props.pk} isExcel={isExcel} />
                    </div>
                </div>
            fileIDLabel = null;
            upgradeMessage = null;

            const message = <span style={{color: "red"}}><Icon name="warning sign" /> <span style={{paddingLeft: "2px"}}>{getFileLimitMessage(this.props.proBlockTooManyFiles, this.props.pkID, this.props.tier, this.props.appsumoPlanName, this.props.isGrandfather)}</span></span>
            allBlocks.push({type: "recordsUpgrade", message: message, content: null, action: <Button onClick={this.openPaymentModal} style={gunaldiStyles.btnGreen}>{"Upgrade"}</Button>})
        }
        else{
            if (fileInformation.fixColumnNames && !isFileInLazyMode(fileInformation) && !isUserAMacroUser(this.props.fullPricingInfo)){
                disable = true
                // disable = {pointerEvents: 'none', opacity: '0.2'}
                proMessage = <FixColumnNames pkID={this.props.pkID} pk={this.props.pk} idx={this.props.idx} fileInformation={fileInformation}/>
                fileIDLabel = null;
                upgradeMessage = null;

                allBlocks.push({
                    type: "fixColumnNames",
                    message: <span style={{color: "red"}}><Icon name="columns" /> <span style={{paddingLeft: "2px"}}>Column Names in your file were not valid</span></span>,
                    action: null,
                    content: <FixColumnNames pkID={this.props.pkID} pk={this.props.pk} idx={this.props.idx} fileInformation={fileInformation}/>
                })
            }
        }

        const isTeam = "isTeam" in fileInformation ? fileInformation.isTeam : false;

        let canTakeAction = true;

        const sheetName = isExcel ? <div style={{color: allBlocks.length > 0 ? "red" : "gray"}}><small>{this.props.sheetRead[this.props.pkID]}</small></div> : null;

        let rowClassName;
        if (allBlocks.length > 0){
            delete fileDivStyle['cursor']
            rowClassName = "fileErrorRow"
        } else {
            rowClassName = "fileInformationTableHeader filesTabFileSummaryRow"
        }

        try {
            if (isTeam) {
                if ("teams_pk_list" in fileInformation) {
                    if (fileInformation.teams_pk_list.length > 0) {
                        const allTeamPKs = this.props.teamInfo.map(x => x.id)
                        let allFileTakeAction = [];

                        for (let i = 0; i < fileInformation.teams_pk_list.length; i++) {
                            const idx = allTeamPKs.indexOf(fileInformation.teams_pk_list[i])
                            allFileTakeAction.push(["admin", "manager"].includes(this.props.teamInfo[idx].all_users[localStorage.getItem("email")].role))
                        }

                        if (allFileTakeAction.indexOf(true) === -1){
                            canTakeAction = false
                        }
                    }
                }
            }
        } catch (error) {
            canTakeAction = true
        }

        if (disable){
            let disableMessage = null;
            let actionsButton = "";
            let content = null;

            if (allBlocks.length === 1){
                disableMessage = allBlocks[0].message
                actionsButton = allBlocks[0].action
                content = allBlocks[0].content
            } else if (allBlocks.length > 1){
                disableMessage = <span style={{color: "red"}}><Icon name="warning circle" /> <span style={{paddingLeft: "2px"}}>{allBlocks.length.toString()} file issues found</span></span>
                actionsButton = null
                content = <FileRowErrorContent allBlocks={allBlocks} />
            }

            if (actionsButton === null){
                actionsButton = <button style={{padding: "6px 12px 6px 16px", border: "1px solid #9696A0", cursor: "pointer", fontFamily: "Lato", fontWeight: "400", fontSize: "14px", borderRadius: "4px", backgroundColor: "white", color: "#9696A0"}} onClick={this.toggleAccordion}><span style={{paddingRight: "6px"}}>{this.state.accordionShow ? "Hide" : "View"} Issue{allBlocks.length > 1 ? "s" : ""}</span> <Icon name={this.state.accordionShow ? "chevron up" : "chevron down"} /> </button>
            } else if (actionsButton === ""){
                actionsButton = null
            }

            let accordionBody = null
            if (content !== null){
                if (this.state.accordionShow){
                    // content
                    accordionBody =
                    <div style={{backgroundColor: "#F9F9F9", padding: "24px 0px 24px 24px"}}>
                        {content}
                    </div>
                }
            }

            return (
                <div style={fileDivStyle}>
                    <div className={rowClassName}>
                        <div>
                            <div className="fileRowFileSource">
                                <div style={{marginLeft: "-8px"}}>
                                    <FilesTabFileSource fileInformation={fileInformation} />
                                </div>
                                <div className="ellipses">
                                    <span style={{color: "red", fontFamily:'Lato',fontWeight:'600',fontSize:'16px'}}>{fileName}</span>
                                    {sheetName}
                                </div>
                            </div>
                        </div>
                        <div style={{position:'static',height:'44px',float:'left',fontStyle:'normal',fontFamily:'Lato',fontWeight:'400',fontSize:'14px',color:'#5E645E'}}>
                            {this.getCreatedDt(fileInformation['createdDt'])}
                        </div>
                        <div>
                            <div style={{display: "grid", gridTemplateColumns: "60% 40%"}}>
                                <div>
                                    {disableMessage}
                                </div>
                                <div style={{textAlign: "right"}}>
                                    <div style={{position:'static',height:'44px',display:'inline-block', marginRight: "12px"}}>
                                        {actionsButton}
                                    </div>
                                    <div style={{position:'static',height:'44px',display:'inline-block', float: "right"}}>
                                        <FilesTabFileOptions
                                            canTakeAction={canTakeAction}
                                            removeFile={<FileTabRemoveFile idx={this.props.idx} pkID={this.props.pkID} pk={this.props.pk} isExcel={isExcel} />}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {accordionBody}
                </div>
            )
        } else{
            return (
                <div style={fileDivStyle} className={rowClassName} onClick={e => this.goToFile(e, disable, fileHash)}>
                    <a style={{display: "none"}} href={'files/'+fileHash} ref={filesTabFileSummaryLink => this.filesTabFileSummaryLink = filesTabFileSummaryLink}> </a>
                    <div>
                        <div className="fileRowFileSource">
                            <div style={{marginLeft: "-8px"}}>
                                <FilesTabFileSource fileInformation={fileInformation} />
                            </div>
                            {/*<div className="wordwrap wordwrap2">*/}
                            <div className="ellipses">
                                <span style={{color: "#161E16;", fontFamily:'Lato',fontWeight:'600',fontSize:'16px', paddingRight: "4px"}}>{fileName}</span>
                                {sheetName}
                            </div>
                        </div>
                    </div>
                    <div style={{position:'static',height:'44px',float:'left',fontStyle:'normal',fontFamily:'Lato',fontWeight:'400',fontSize:'14px',color:'#5E645E'}}>
                        {this.getCreatedDt(fileInformation['createdDt'])}
                    </div>
                    {
                        this.props.isApiPage ? <div style={{fontStyle:'normal',textAlign:'left',fontFamily:'Lato',fontWeight:'700',fontSize:'14px', paddingLeft: "2px"}}>
                            <FilesTabFileSourceNames fileInformation={fileInformation}/>
                        </div> : <div style={{position:'static',height:'44px',display:'inline-block',float:'left',fontFamily:'Lato',fontWeight:'400',fontSize:'14px',color:'#5E645E'}}>
                            <FilesTabSharedLabel fileInformation={fileInformation}/>
                        </div>
                    }
                    <div style={{position:'static',height:'44px',display:'inline-block',float:'left'}}>
                        {fileIDLabel}
                    </div>
                    <div style={{textAlign: "right"}}>
                        <div style={{position:'static',height:'44px',display:'inline-block', marginRight: "12px"}}>
                            <button style={{width:'84px',height:'36px',backgroundColor:'#FFFFFF',border:'1px solid #6FBB98',borderRadius:'4px'}}> <a style={{textDecoration:'none',color:'#6FBB98'}} href={'files/'+fileHash}> Details </a> </button>
                        </div>
                        <div style={{position:'static',height:'44px',display:'inline-block', float: "right"}}>
                            <FilesTabFileOptions
                                canTakeAction={canTakeAction}
                                removeFile={<FileTabRemoveFile idx={this.props.idx} pkID={this.props.pkID} pk={this.props.pk} isExcel={isExcel} />}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock,
    currency: state.mainState.currency,
    teamInfo: state.mainState.teamInfo,
    proBlockTooManyFiles: state.mainState.proBlockTooManyFiles,
    tier: state.mainState.tier,
    appsumoPlanName: state.mainState.appsumoPlanName,
    isGrandfather: state.mainState.isGrandfather,
    fullPricingInfo: state.mainState.fullPricingInfo
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyEditID: modifyEditID,
    modifyFilePK: modifyFilePK,
    modifyModalInfo: modifyModalInfo,
    toggleToast: toggleToast,
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(FilesTabFileSummary)