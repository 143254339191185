import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import {isURLValid} from "../../Constants/isURLValid";
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import API_Root from "../../Constants/API_Root";

class SaveWebhookURL extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }
    }

    isBtnDisabled = () => {
        const reduxWebhookURL = this.props.fileInformation[this.props.editID].webhookURL;

        return !(isURLValid(this.props.webhookURL) && reduxWebhookURL !== this.props.webhookURL.trim())
    }

    saveWebhookURL = () => {
        this.setState({ loading: true })

        const isError = () => {this.setState({ loading: false }); alert("There was an error saving your URL. Please try again")}

        fetch(API_Root + "api/save-api-webhook-info/", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                info: {"webhookURL": this.props.webhookURL.trim()},
                pk: this.props.modalInfo.pk
            })
        }).then((res) => {
            if (res.status === 201){
                this.setState({ loading: false })
                this.updateWebhookURLInRedux()
            } else {
                isError()
            }
        }).catch((err) => {
            isError()
        })

    }

    updateWebhookURLInRedux = () => {
        const fileInformation = JSON.parse(JSON.stringify(this.props.fileInformation))
        const editIDInfo = fileInformation[this.props.editID]

        editIDInfo.webhookURL = this.props.webhookURL.trim();

        fileInformation[this.props.editID] = editIDInfo

        this.props.modifyFileInformation(fileInformation)
    }

    render() {
        return (
            <Popup content="Save Webhook URL"
                   trigger={<Button icon="save"
                                    basic
                                    color="green"
                                    onClick={this.saveWebhookURL}
                                    disabled={this.isBtnDisabled()}
                                    loading={this.state.loading}
                                    circular />}
            />

        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    editID: state.mainState.editID,
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation
}

export default connect(mapStateToProps, mapActionsToProps)(SaveWebhookURL)