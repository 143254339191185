import React, {Component} from 'react'
import {connect} from 'react-redux'
import ParameterRow from "../Utilities/ParameterRow"
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

class CreateResponseParams extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        // name: '2023-06-15_08_40_39.csv',
        // size: 416,
        // colLen: 6,
        // rowLen: 6,
        // fileID: 'hrX7RtY8zZ8UXMTs',
        // createdDt: '2023-06-15 08:40:39',
        // apiGetUrl: 'http://localhost:8080/data/hrX7RtY8zZ8UXMTs/' + (isPrivate ? "?accessKey=108dca97354cc34c90e85da1b2c3d607&secretKey=79eb2d43d00851e502254b1605965b15/" : ""),
        // apiPostUrl: 'http://localhost:8080/data/hrX7RtY8zZ8UXMTs/',
        // dashboardUrl: 'http://localhost:5000/files/hrX7RtY8zZ8UXMTs',
        // columnNames: ['Title', 'Genre', 'Director', 'Actors', 'Year', 'Runtime (Minutes)'],
        // sampleData: [['Guardians of the Galaxy', 'Action,Adventure,Sci-Fi', 'James Gunn', 'Chris Pratt, Vin Diesel, Bradley Cooper, Zoe Saldana', 2014, 121], ['Prometheus', 'Adventure,Mystery,Sci-Fi', 'Ridley Scott', 'Noomi Rapace, Logan Marshall-Green, Michael Fassbender, Charlize Theron', 2012, 124], ['Split', 'Horror,Thriller', 'M. Night Shyamalan', 'James McAvoy, Anya Taylor-Joy, Haley Lu Richardson, Jessica Sula', 2016, 117], ['Sing', 'Animation,Comedy,Family', 'Christophe Lourdelet', 'Matthew McConaughey,Reese Witherspoon, Seth MacFarlane, Scarlett Johansson', 2016, 108], ['Suicide Squad', 'Action,Adventure,Fantasy', 'David Ayer', 'Will Smith, Jared Leto, Margot Robbie, Viola Davis', 2016, 123]],

        const isPrivate = ['pro', 'team', 'business', 'enterprise'].includes(this.props.tier);

        return (
            <div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="name"
                        type={"string"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                The name of the file created + .csv extension. If you did not provide a <code style={{ color: gunaldiStyles.mainText}}>fileName</code> the name will be the UTC datetime when the file was created
                            </div>
                        </div>}
                    />
                </div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="size"
                        type={"integer"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                The size of the file created in bytes
                            </div>
                        </div>}
                    />
                </div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="colLen"
                        type={"integer"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                The count of column names in the file
                            </div>
                        </div>}
                    />
                </div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="rowLen"
                        type={"integer"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                The count of rows in the file
                            </div>
                        </div>}
                    />
                </div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="fileID"
                        type={"string (16 characters)"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                The ID of the file. This is the main ID to make API requests and access your file
                            </div>
                        </div>}
                    />
                </div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="fileID"
                        type={"string (16 characters)"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                The ID of the file. This is the main ID to make API requests and access your file
                            </div>
                        </div>}
                    />
                </div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="createdDt"
                        type={"string (19 characters)"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                The date the file was created in 'YYYY-MM-DD HH:MM:SS' format
                            </div>
                        </div>}
                    />
                </div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="dashboardUrl"
                        type={"url"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                The URL of the file in the API Spreadsheets dashboard. You can go to this link in a browser to learn more about your newly created file and all its options
                            </div>
                        </div>}
                    />
                </div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="apiGetUrl"
                        type={"url"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                The API URL to read data from this newly created file. Visit the <code>dashboardUrl</code> to learn more about reading your file
                            </div>
                        </div>}
                    />
                </div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="apiPostUrl"
                        type={"url"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                The API URL to create data to this newly created file. Visit the <code>dashboardUrl</code> to learn more about creating rows to your file
                            </div>
                        </div>}
                    />
                </div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="isPrivate"
                        type={"boolean"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                {
                                    isPrivate ?
                                        <span>Since you are on the {this.props.tier} plan, this will always be <code>true</code> by default. You can change the setting later in the files tab accessed by the dashboardUrl</span> :
                                        <span>Since you are on the free plan, this will always be <code>false</code>. You can upgrade if you want private files</span>
                                }

                            </div>
                        </div>}
                    />
                </div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="columnNames"
                        type={"array"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                An array of the column names in your newly crated file
                            </div>
                        </div>}
                    />
                </div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="sampleData"
                        type={"2D array (array of arrays)"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                Up to 5 rows of your newly created file
                            </div>
                        </div>}
                    />
                </div>
                {
                    isPrivate ?
                    <div>
                        <div style={{ marginBottom: "12px" }}>
                            <ParameterRow
                                parameter="accessKey"
                                type={"string (32 characters)"}
                                body={<div>
                                    <div style={{ marginBottom: "2px"}}>
                                        Access Key to access your API since it is private
                                    </div>
                                </div>}
                            />
                        </div>
                        <div style={{ marginBottom: "12px" }}>
                            <ParameterRow
                                parameter="secretKey"
                                type={"string (32 characters)"}
                                body={<div>
                                    <div style={{ marginBottom: "2px"}}>
                                        Secret Key to access your API since it is private
                                    </div>
                                </div>}
                            />
                        </div>
                    </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CreateResponseParams)