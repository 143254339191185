import React, {Component} from 'react'
import { Segment, Button } from 'semantic-ui-react'
import {connect} from "react-redux";
import {toggleToast} from "../../Actions/ToggleToast";

class ImportHashCopy extends Component {
    copyKey = (e) => {
        // e.target.focus();
        this.importHashRef.select();
        document.execCommand("copy")
        window.getSelection().removeAllRanges();

        this.props.toggleToast({show: true, message: "Import Key Copied!", type: "success"})
    }

    render() {
        return (
            <div>
                <Segment>
                    <h5><span style={{color: "grey"}}>Key for this Import Configuration</span>
                        <Button content="Copy Key"
                                icon="copy"
                                color="olive"
                                onClick={this.copyKey}
                                size="mini"
                                style={{marginLeft: '10px'}}/>
                    </h5>
                    <input readOnly={true} className="wordwrap"
                           style={{width: '100%', border: 'none', fontSize: '110%', color: 'black'}}
                           value={this.props.importHash}
                           type="text"
                           id="importHashValue"
                           ref={(input) => {
                               this.importHashRef = input
                           }}/>
                </Segment>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(null, mapActionsToProps)(ImportHashCopy)