import React, {Component} from 'react'
import InfoPopup from "../../../../SharedComponents/InfoPopup";
import { Label } from 'semantic-ui-react'
import TierLabel from "../../../../SharedComponents/TierLabel";

class ActionsLabel extends Component {
    render() {
        const subheadingStyle = {fontFamily: "Lato", fontWeight: "500", fontSize: "14px", color: "#161E16"};

        return (
            <span style={subheadingStyle}>{this.props.labelText}
                <InfoPopup style={{fontSize: "14px", color: "#161E16"}} info={this.props.infoText}/>
                {
                    'tierRestriction' in this.props ?
                        <span style={{marginTop: "-3px", marginLeft: "5px"}}>
                            <TierLabel tier={this.props.tierRestriction} feature={this.props.feature} importer/>
                        </span> : null
                }
            </span>
        )
    }
}

export default ActionsLabel