import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../Constants/API_Root";
import { Message, Icon, Button } from 'semantic-ui-react'
import Loader from 'react-loader-spinner'
import {modifyTeamInfo} from "../Actions/ModifyTeamInfo";
import {modifyPendingInvites} from "../Actions/ModifyPendingInvites";
import getURLParam from "../Constants/getURLParam";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import TeamInfoModalBody from "./TeamInfo/TeamInfoModalBody"
import PageHeader from "../SharedComponents/PageHeader";
import BackHeaderIcon from "../SharedComponents/BackHeaderIcon";
import TeamsDescriptionDisplay from "./TeamsDescriptionDisplay";
import TeamInfoRemoveButton from "./TeamInfo/TeamInfoRemoveButton";
import BannerAlert from "../SharedComponents/BannerAlert";

class TeamsIDDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: true,
            error: null
        }
    }

    componentDidMount(){
        let pk = getURLParam(window.location.href);

        if (window.location.href.includes("?file=")){
            pk = pk.substring(0, pk.indexOf("?file="))
        }

        const isError = (error="There was an error getting your team information") => {
            this.setState({
                error: <BannerAlert type="error"
                                    header={error}
                                    icon="bx bx-error-alt"
                                    content="Try again!"
                                     />,
                fetching: false
            })
        }

        fetch(API_Root + "api/get-one-api-team-information/", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                teamPK: pk
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then(data => {
                    this.props.modifyModalInfo({ teamIdx: 0 })
                    this.props.modifyTeamInfo(data['teamInfo'])

                    this.setState({
                        error: null,
                        fetching: false
                    })

                }).catch( err => isError())
            } else if (res.status === 403){
                isError("You are not a member of this team")
            } else if (res.status === 404){
                isError("This Team could not be found")
            } else {
                isError()
            }
        }).catch((err) => {
            isError()
        })
    }

    goToTeams = () => {
        this.teamLink.click()
    }

    render() {
        let body;

        if (this.state.fetching){
            body = <div style={{padding: "50px 100px 100px 100px"}}>
                <Loader
                    type="TailSpin"
                    color="black"
                    height="50"
                    width="50"
                />
            </div>
        } else {
            const url = window.location.href
            const isSourceFile = url.includes("?file=")

            if (this.state.error === null){
                const teamInfo = this.props.teamInfo[this.props.modalInfo.teamIdx]

                body =
                <div>
                    <PageHeader
                        header={
                            <div className="filesIDIndexHeaderContainer">
                                <h3>
                                    <span style={{marginTop: "3px"}}>
                                        <BackHeaderIcon link={isSourceFile ? "/files/" + url.substring(url.indexOf("?file=") + "?file=".length, url.length).replace("/", "") : "/teams"}/>
                                        <span style={{marginTop: "3px"}}>{teamInfo.name}</span>
                                    </span>
                                </h3>
                            </div>
                        }
                        desc={<div style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                            <div>
                                <TeamsDescriptionDisplay />
                            </div>
                            <div style={{textAlign: "right"}}>
                                <TeamInfoRemoveButton />
                            </div>
                        </div>}
                    />
                    <div style={{margin: "24px 100px 250px 60px"}}>
                        <TeamInfoModalBody />
                    </div>
                </div>
            } else {
                body = <div>
                    {this.state.error}
                    <PageHeader
                        header={
                            <div className="filesIDIndexHeaderContainer">
                                <h3>
                                    <span style={{marginTop: "3px"}}>
                                        <BackHeaderIcon link={isSourceFile ? "/files/" + url.substring(url.indexOf("?file=") + "?file=".length, url.length).replace("/", "") : "/teams"}/>
                                        <span style={{marginTop: "3px", color: "red"}}>INVALID FILE KEY</span>
                                    </span>
                                </h3>
                            </div>
                        }
                        desc={null}
                    />
                </div>
            }
        }

        return (
            <div style={{minHeight: "100vh"}}>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo,
    teamInfo: state.mainState.teamInfo
})

const mapActionsToProps = {
    modifyTeamInfo: modifyTeamInfo,
    modifyPendingInvites: modifyPendingInvites,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(TeamsIDDisplay)