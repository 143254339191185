import React, {Component} from 'react'
import { Segment } from 'semantic-ui-react'
import RestAPI from "../../Illustrations/RestAPI.svg"

class CachingLayer extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Create a Caching Layer</div>
                <div style={this.props.subheadingStyle}>You can access your data blazing fast by using a caching layer created with absolutely no code</div>
                <Segment>
                    <div style={{margin: "0 auto", textAlign: "center"}}>
                        <img src={RestAPI} alt="multiple connect sources to API" style={{width: "50%"}}/>
                    </div>
                </Segment>
            </div>

        )
    }
}

export default CachingLayer