import React, {Component} from 'react'
import { Message } from 'semantic-ui-react'

class AlertsMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: true
        }
    }

    closeMessage = () => {
        this.setState({ show: false })
    }

    render() {
        if (this.state.show){
            let negative = false;
            let positive = true;

            if (this.props.error){
                negative =  true;
                positive = false;
            }

            return (
                <div>
                    <br/>
                    <Message header={this.props.header} negative={negative} positive={positive} onDismiss={this.closeMessage}/>
                </div>
            )
        } else{
            return (
                null
            )
        }


    }
}

export default AlertsMessage