import React, {Component} from 'react'
import { Dropdown } from 'semantic-ui-react'

class ColumnDataType extends Component {
    updateDataType = (event, { value }) => {
        this.props.changeState("dataType", value)
    }

    render() {
        const dataTypeOptions = [
            {key: 1, value: "text", text: "text"},
            {key: 2, value: "numerical", text: "numerical"},
            {key: 3, value: "boolean", text: "boolean"},
            {key: 4, value: "datetime", text: "datetime"},
        ]

        return (
            <Dropdown options={dataTypeOptions}
                      onChange={this.updateDataType}
                      selection
                      value={this.props.dataType}
                      fluid />
        )
    }
}

export default ColumnDataType