import React, {Component} from 'react'
import {connect} from 'react-redux'
import NumberCircle from "../NumberCircle";
import {gunaldiStyles} from "../GunaldiDesignStyles";

class StepSelectionMenu extends Component {
    constructor(props) {
        super(props);
    }

    changeStep = (step) => {
        if (step !== this.props.step){
            this.props.changeStep(step)
        }
    }

    render() {
        return (
            <div style={{display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "12px", gridTemplateRows: "auto auto auto auto"}}>
                <div onClick={e => this.changeStep(0)} style={{cursor: "pointer"}}>
                    <NumberCircle
                        number={1}
                        background={this.props.step === 0 ? "#6FBB98" : "#D1E4CE"}
                        color="#FFFFFF"
                    />
                </div>
                <div onClick={e => this.changeStep(0)} style={{ height: "36px", display: "flex", alignItems: "center", cursor: "pointer"}}>
                    <div>
                        <span style={{fontSize: "16px", fontWeight: "700", color: this.props.step === 0 ? gunaldiStyles.mainText : gunaldiStyles.subText}}>Select Plan</span>
                    </div>
                </div>
                <div style={{height: "36px", display: "grid", gridTemplateColumns: "50% 50%"}}>
                    <div style={{borderRight: "1px solid #6FBB98"}}>

                    </div>
                    <div style={{borderLeft: "1px solid #6FBB98"}}>

                    </div>
                </div>
                <div>

                </div>
                <div onClick={e => this.changeStep(1)} style={{cursor: "pointer"}}>
                    <NumberCircle
                        number={2}
                        background={this.props.step === 1 ? "#6FBB98" : "#D1E4CE"}
                        color="#FFFFFF"
                    />
                </div>
                <div onClick={e => this.changeStep(1)} style={{ height: "36px", display: "flex", alignItems: "center", cursor: "pointer"}}>
                    <div>
                        <span style={{fontSize: "16px", fontWeight: "700", color: this.props.step === 1 ? gunaldiStyles.mainText : gunaldiStyles.subText}}>Pay for Plan</span>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(StepSelectionMenu)