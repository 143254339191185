import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class ProfileDisplayHeader extends Component {
    capitalizeCamelCase = (name) => {
        let finalName = "";
        if ("titleName" in this.props){
            finalName = this.props.titleName
        } else {
            const titleName = name.charAt(0).toUpperCase() + name.substring(1, name.length)

            for (let i=0; i<titleName.length; i++){
                if (i > 0 && (titleName.charAt(i) === titleName.charAt(i).toUpperCase())){
                    finalName += " "
                }

                finalName += titleName.charAt(i)
            }
        }

        return finalName
    }

    render() {
        return (
            <span>
                <h3><Icon name={this.props.icon} /> {this.capitalizeCamelCase(this.props.name)}</h3>
            </span>
        )
    }
}

export default ProfileDisplayHeader