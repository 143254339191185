import React, {Component} from 'react'
import teams from "../../Illustrations/teams.svg"

class TeamsDesc extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Invite People to a Team</div>
                <div style={this.props.subheadingStyle}>Share files, access APIs, edit files and much more amongst a team</div>
                <img src={teams} style={{width:'70%',margin:'auto',display:'block'}}/>
                <br/>

            </div>

        )
    }
}

export default TeamsDesc