import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import {modifyFilePK} from "../Actions/ModifyFilePK";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import API_Root from "../Constants/API_Root";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import {toggleToast} from "../Actions/ToggleToast";
import {modifyImportSheetRead} from "../Actions/ModifyImportSheetRead";
import {modifyImportSheetsUploadPK} from "../Actions/ModifyImportSheetsUploadPK";
import {modifyImportSheetsInfo} from "../Actions/ModifyImportSheetsInfo";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import PageHeaderButton from "../SharedComponents/PageHeaderButton"
import { push } from 'react-router-redux'
import {initOriginalFilePK} from "../Actions/InitOriginalFilePK";
import {toggleModal} from "../Actions/ToggleModal";

class FileTabRemoveFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleting: false
        }

        this.removeFile = this.removeFile.bind(this);
        this.deleteServerFile = this.deleteServerFile.bind(this);
        this.deleteSheetsRead = this.deleteSheetsRead.bind(this);
    }

    removeFile(){
        if ("headerToolbar" in this.props){
            // this.props.navigateTo("/files")
            this.props.toggleModal("loadingModalDeletingFile")
        }

        const pkID = this.props.pkID;
        const pk = this.props.pk;

        const isExcel = this.props.isExcel;

        let deleteServerFile = true;

        const isImport = 'importFile' in this.props;

        // Figure out whether to delete the file from the server or not and delete any sheets read
        let filePK = this.props.filePK
        if (isImport){
            filePK = this.props.importSheetsUploadPK
        }

        if (isExcel){
            let countPK = 0;

            for (let i=0; i<filePK; i++){
                const currPK = filePK[i]['pk'];

                if (currPK === pk){
                    countPK += 1
                }
            }

            if (countPK > 1){
                deleteServerFile = false
            }

            // Delete from sheets read
            this.deleteSheetsRead(pk, pkID)
            // window.location.reload(false)
        }

        // Delete the file from the server
        if (deleteServerFile){
            this.deleteServerFile(pk, pkID, filePK, isImport)
            // window.location.reload(false)
        } else {
            this.removeFileFromRedux(filePK, isImport, pkID)
            // window.location.reload(false)
        }


    }

    removeFileFromRedux = (filePK, isImport, pkID) => {
        if ("headerToolbar" in this.props){
            this.props.initOriginalFilePK([])
        }

        this.setState({ deleting: false})

        // Delete the idx of filePK and update
        const removedFilePK = JSON.parse(JSON.stringify(filePK));
        removedFilePK.splice(this.props.idx, 1);

        let copiedFileInformation;
        if (isImport){
            this.props.modifyImportSheetsUploadPK(removedFilePK)
            copiedFileInformation = JSON.parse(JSON.stringify(this.props.importSheetsFileInformation));
        } else {
            this.props.modifyFilePK(removedFilePK);
            copiedFileInformation = JSON.parse(JSON.stringify(this.props.fileInformation));
        }

        delete copiedFileInformation[pkID];

        if (isImport){
            this.props.modifyImportSheetsInfo(copiedFileInformation)
        } else {
            this.props.modifyFileInformation(copiedFileInformation);
        }

        this.successDeleting()
    }

    successDeleting = () => {
        if ("headerToolbar" in this.props){
            this.props.toggleModal(null)
        }
        this.props.toggleToast({show: true, message: "File Deleted", type: "success"});
    }

    deleteServerFile(pk, pkID, filePK, isImport){
        this.setState({ deleting: true })

        const errorDeleting = () => this.setState({ deleting: false },
            () => {
                this.props.toggleModal(null);
                this.props.toggleToast({show: true, message: "There was an error deleting your file. Try again. Contact our support if this persists", type: "error"})
            })

        fetch(API_Root + "api/delete-api-file/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                pk: pk
            })
        }).then(res => {
            if (res.status === 200){
                this.removeFileFromRedux(filePK, isImport, pkID)
            } else {
                errorDeleting()
            }
        }).catch(err => errorDeleting());
    }

    deleteSheetsRead(pk, pkID){
        let allSheetRead = this.props.sheetRead;

        const isImport = 'importFile' in this.props;

        if (isImport){
            allSheetRead = this.props.importSheetRead
        }

        const removedSheets = JSON.parse(JSON.stringify(allSheetRead));
        delete removedSheets[pkID];

        if (isImport){
            this.props.modifyImportSheetRead(removedSheets)
        } else {
            this.props.modifySheetRead(removedSheets);
        }
    }

    render() {
        const isImport = 'importFile' in this.props;

        return (
            <Popup
                trigger={
                    "headerToolbar" in this.props ?
                        <PageHeaderButton
                            loading={this.state.deleting}
                            content="Delete File"
                            color="red"
                            icon="trash alternate outline"
                        /> :
                        <Icon style={{width:'6px',height:'8px',top:'10px',left:'9px'}} size="large" loading={this.state.deleting} basic color='red' name='trash alternate outline' className="fileTabRemoveFileBtn" />
                }
                content={
                    <div style={{textAlign: 'center'}}>
                        <h5 className="thinHeading">Are you sure you want to delete this file?</h5>
                        <Button loading={this.state.deleting} color='red' content='Delete File' onClick={this.removeFile} className="fileTabRemoveFileBtn" /> <br/>
                        {isImport ? null : <small><b>NOTE: </b>if file is imported from Google Sheets or Dropbox then source file will NOT be deleted</small>}
                    </div>}
                on='click'
                position='top right'
            />
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    sheetRead: state.mainState.sheetRead,
    importSheetRead: state.mainState.importSheetRead,
    importSheetsFileInformation: state.mainState.importSheetsFileInformation,
    importSheetsUploadPK: state.mainState.importSheetsUploadPK
})

const mapActionsToProps = {
    modifyFilePK: modifyFilePK,
    modifyFileInformation: modifyFileInformation,
    modifySheetRead: modifySheetRead,
    toggleToast: toggleToast,
    modifyImportSheetRead: modifyImportSheetRead,
    modifyImportSheetsUploadPK: modifyImportSheetsUploadPK,
    modifyImportSheetsInfo: modifyImportSheetsInfo,
    navigateTo: push,
    initOriginalFilePK: initOriginalFilePK,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(FileTabRemoveFile)