import React, {Component} from 'react'
import ReactJson from 'react-json-view'

const importerData = {
    "name": "world_leaders.xlsx",
    "size": 9021,
    "columnNames": [
        "id",
        "name",
        "salary_$",
        "country",
        "email"
    ],
    "createdDt": "2022-07-30 09:27:35",
    "colLen": 5,
    "rowLen": 5,
    "sampleData": [
        [
            1,
            "Justin Trudeau",
            252069.46,
            "Canada",
            "justin@canada.gov"
        ],
        [
            2,
            "Jacinda Ardern",
            339862.25,
            "New Zealand",
            "jacinda@newzealand.gov"
        ],
        [
            3,
            "Boris Johnson",
            187033.75,
            "UK",
            "boris@gmail.com"
        ],
        [
            4,
            "Joko Widodo",
            27200.8,
            "Indonesia",
            "joko@gmail.com"
        ],
        [
            5,
            "Angela Merkel",
            369727.4,
            "Germany",
            "angela@deutschland.gov"
        ]
    ],
    "duplicateColumnNames": false,
    "importKey": "d8832db655301ed2573139943ef04748",
    "fileID": "M1ub8poWMWl6f5CQ",
    "apiUrl": "https://api.apispreadsheets.com/data/27880/?accessKey=1fa941680abad5c35f6e9eef9ba9e862&secretKey=5ea99d7b2caa7a5e3c2f2d79e35d0e8e",
    "sheetNames": [
        "Sheet1", "Historical Leaders"
    ],
    "sheetRead": "Sheet1"
}

class ImporterCodeJSON extends Component {
    render() {
        return (
            <div className="getResponseBox">
                <div className="getResponseHeading">
                    <span style={{float: 'left'}}><h6 className="thinHeading">data JSON object</h6></span>
                </div>
                <ReactJson src={importerData}
                           name={null}
                           theme="ocean"
                           displayObjectSize={false}
                           displayDataTypes={false}/>
            </div>
        )
    }
}

export default ImporterCodeJSON