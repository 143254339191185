import React, {Component} from 'react'
import {Input} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import API_Root from "../../../../Constants/API_Root";
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import {connect} from "react-redux";
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";

class TestingCleanFunctions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error:false,
            testValue:'',
            cleanedValue:null
        }
    }
    changeValue = (e) =>{
        if (e.target.value.trim() !== ""){
            this.setState({[e.target.name]:e.target.value})
        } else {
            this.setState({testValue: e.target.value, cleanedValue: null})
        }
    }
    testValueButton = () =>{
        fetch(API_Root + "api/test-value-api-import-clean-function/", {
            method: "POST",
            body: JSON.stringify({
                cleaningFunctions:this.props.cleaningFunctions,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                columnType: this.props.columnType,
                value:this.state.testValue
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then(data => {
                    const cleanedValue = data['cleanedValue']
                    this.setState({
                        cleanedValue: cleanedValue
                    })
                })
            }
            else{
                if (res.status === 400){
                    res.json().then(data => {
                        this.setState({error:true})
                    })
                }

            }
        }).catch(err => this.props.toggleToast({show: true, message: "These changes could not be saved", type: "error"}))

    }
    render() {
        return (
            <div style={{borderTop: "1px solid #F0F0F0", marginTop: "8px", paddingTop: "8px"}}>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "16px"}}>
                    <div>
                        <div style={{fontSize: "14px", fontWeight: "700", color: gunaldiStyles.subText}}> Test Cleaning Functions </div>
                        <Input
                            style={{paddingRight:'10px'}}
                            placeholder="Enter Test Value"
                            name="testValue"
                            onChange={this.changeValue}
                            value={this.state.testValue} />
                        <Button color="blue" size="mini" onClick={this.testValueButton} disabled={this.state.testValue.trim()===''} content="Test Value"> Test Value </Button>
                    </div>
                    <div>
                        {
                            this.state.cleanedValue === null ? null :
                                <div>
                                    <div style={{fontSize: "14px", fontWeight: "700", color: gunaldiStyles.subText}}> Cleaned Test Value </div>
                                    <div style={{ padding:'8px',backgroundColor:gunaldiStyles.mainGreen,borderRadius:'4px', color: "white"}}>
                                        {this.state.cleanedValue}
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

        )
    }
}
const mapStateToProps = (state) => ({
    customImportColumnsAddlInfo: state.mainState.customImportColumnsAddlInfo,
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(TestingCleanFunctions)