import {getCodeSnippet} from "../../../Constants/getCodeSnippet";

export const createCodeSnippets = (fileInformation, apiType, fileURL, createFormat="row", full_data=false, update_query=null) => {
    let pkIDCode;
    for (let pkID in fileInformation){
        pkIDCode = pkID
        break
    }

    let pk = parseInt(pkIDCode.replace("_1", ""))

    return {
        cURL: getCodeSnippet(fileInformation, pk, pkIDCode, apiType, createFormat, fileURL, "cURL", full_data, update_query),
        python: getCodeSnippet(fileInformation, pk, pkIDCode, apiType, createFormat, fileURL, "python", full_data, update_query),
        javascript: getCodeSnippet(fileInformation, pk, pkIDCode, apiType, createFormat, fileURL, "javascript", full_data, update_query),
        ruby: getCodeSnippet(fileInformation, pk, pkIDCode, apiType, createFormat, fileURL, "ruby", full_data, update_query),
        PHP: getCodeSnippet(fileInformation, pk, pkIDCode, apiType, createFormat, fileURL, "php", full_data, update_query)
    }
}