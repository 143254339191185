import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../Modal"

class ImageModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalSize: 'medium'
        }

        this.onImgLoad = this.onImgLoad.bind(this);

    }

    onImgLoad({target:img}) {
        let imgWidthInPx = img.naturalWidth;

        let modalSize = "medium";

        if (imgWidthInPx < 300){
            modalSize = "small"
        }
        else if (imgWidthInPx >= 300 && imgWidthInPx < 850){
            modalSize = "medium"
        }
        else if (imgWidthInPx >= 850 && imgWidthInPx <= 1400){
            modalSize = "large"
        }
        else{
            modalSize = "full"
        }

        this.setState({
            modalSize: modalSize
        });
    }

    render() {
        let body;
        if (this.props.modalImageURL !== null){
            body = <img src={this.props.modalImageURL.url}
                        alt={this.props.modalImageURL.alt}
                        width="100%"
                        onLoad={this.onImgLoad}
            />
        }
        else{
            body = null
        }

        return (
            <ModalApp name="imageModal"
                      header={null}
                      size={this.state.modalSize}
                      body={body}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    modalImageURL: state.mainState.modalImageURL
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ImageModal)