import React, {Component} from 'react'
import {connect} from 'react-redux'
import isObjectEmpty from "../../../../Constants/isObjectEmpty";
import API_Root from "../../../../Constants/API_Root";
import loadScript from "load-script";
import { Icon } from 'semantic-ui-react'
import {toggleModal} from "../../../../Actions/ToggleModal";
import {toggleToast} from "../../../../Actions/ToggleToast";
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

let scriptLoadingStarted = false;

class GoogleDriveFolderSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clientId: '340136574451-49jb4g9qahbn3l2bsd40s2og3nuiodi1.apps.googleusercontent.com',
            developerKey:'AIzaSyB_mctRTSmKsVCAQRx1bKYtKWm1f8APl-Q',
            // scope:['https://www.googleapis.com/auth/drive'],
            scope:['https://www.googleapis.com/auth/drive.file', 'https://www.googleapis.com/auth/spreadsheets'],
            onChange: data => this.onChange(data),
            onAuthFailed: data => console.log('on auth failed:', data),
            multiselect: false,
            navHidden: false,
            authImmediate: false,
            mimeTypes: ['application/vnd.google-apps.folder'],
            query: '',
            viewId:'DOCS',
            origin: API_Root.includes("api") ? 'https://apispreadsheets.com' : 'http://localhost:5000',
            email: ''
        }
    }

    isGoogleReady = () => {
        return !!window.gapi;
    }

    componentWillMount() {
        if(this.isGoogleReady()) {
            // google api is already exists
            // init immediately
            this.onApiLoad();
        } else if (!scriptLoadingStarted) {
            // load google api and the init
            scriptLoadingStarted = true;
            loadScript(GOOGLE_SDK_URL, this.onApiLoad)
        } else {
            // is loading
        }
    }

    isGoogleAuthReady=()=>{
        return !!window.gapi.auth;
    }

    isGooglePickerReady=()=>{
        return !!window.google.picker;
    }

    onApiLoad=()=>{
        window.gapi.load('auth');
        window.gapi.load('picker');
    }

    choose = () => {
        this.props.toggleModal(null)

        if (!this.isGoogleReady() || !this.isGoogleAuthReady() || !this.isGooglePickerReady() || this.state.disabled) {
            return null;
        }

        const token = window.gapi.auth.getToken();
        const oauthToken = token && token.access_token;

        if (oauthToken) {
            this.createPicker(token.access_token);
        } else {
            const syncError = (errorMessage="There was an error with your account selection. Check your Profile to make sure all your accounts are valid") => {
                this.props.toggleModal(null)
                this.props.toggleToast({show: true, message: errorMessage, type: "error"});
            }

            fetch(API_Root + "api/get-google-account-access-token/" + localStorage.getItem("email").toString() + "/" + localStorage.getItem("token").toString() + "/" + this.props.accountName.toString() + "/")
                .then(res => {
                    if (res.status === 200){
                        res.json().then(data => {
                            this.createPicker(data.accessToken)
                        }).catch(err => syncError())
                    } else {
                        syncError()
                    }
                }).catch(err => syncError())
        }
    }

    createPicker(accessToken) {
        const view = new window.google.picker.DocsView(window.google.picker.ViewId.FOLDERS).setIncludeFolders(true)
        view.setSelectFolderEnabled(true);
        view.setMimeTypes('application/vnd.google-apps.folder')

        if (!view) {
            throw new Error('Can\'t find view by viewId');
        }

        const picker = new window.google.picker.PickerBuilder()
            .addView(view)
            .setOAuthToken(accessToken)
            .setDeveloperKey(this.state.developerKey)
            .setCallback(this.state.onChange);

        // picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)

        // if (this.state.navHidden) {
        //     picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN)
        // }
        //
        // if (this.state.multiselect) {
        //     picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
        // }


        // try {
        picker.build().setVisible(true);
        // } catch (error) {
        //     this.props.toggleModal(null)
        //     this.props.toggleModal("googleSheetsErrorModal");
        // }
    }

    onChange = (data) => {
        if (data.action === "picked") {
            if (data.docs) {
                if (data.docs.length > 0) {
                    const folderInfo = data.docs[0]

                    const destinationsEditCopied = JSON.parse(JSON.stringify(this.props.destinationsEdit))
                    const accountDetails = destinationsEditCopied["googleAccountDetails"]

                    accountDetails[this.props.accountName] = folderInfo
                    destinationsEditCopied['googleAccountDetails'] = accountDetails

                    this.props.modifyCustomImportSettings("destinationsEdit", destinationsEditCopied)
                }
            }
        }
    }

    doesAccountHaveDetails = () => {
        return this.props.accountName in this.props.destinationsEdit['googleAccountDetails']
    }

    getAccountDetails = () => {
        let detailInfo = this.props.destinationsEdit['googleAccountDetails'][this.props.accountName];
        let text = "main Google Drive"
        if (isObjectEmpty(detailInfo)){
            text = "main Google Drive"
        } else {
            if ("name" in detailInfo){
                text = detailInfo.name
            }
        }

        return <h6>Imported files will be saved in the <b>{text}</b> folder</h6>
    }

    render() {
        let text = "";
        if (this.doesAccountHaveDetails()){
            text = this.getAccountDetails()
        } else {
            text = ""
        }

        return (
            <div>
                {text}
                <h6 className="linkStyle" onClick={this.choose}><Icon name="folder open outline" /> Choose another folder from your Google Drive account</h6>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    destinations: state.mainState.customImportDestinations,
    destinationsEdit: state.mainState.customImportDestinationsEdit
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    toggleToast: toggleToast,
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(GoogleDriveFolderSelection)