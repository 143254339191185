import React, {Component} from 'react'
import { Dropdown } from 'semantic-ui-react'

class SelectOutputType extends Component{
    constructor(props){
        super(props);

        this.handleRadioChange = this.handleRadioChange.bind(this);
    }

    handleRadioChange(event, {value}){
        this.props.changeState("outputFormat", value);
    }

    render(){
        const idx = this.props.idx.toString();
        const name = "outputFormat" + idx;
        const outputFormat = this.props.outputFormat;

        const delimiterOptions = [
            {key: 1, text: 'Row', value: 'jsonRow'},
            {key: 2, text: 'Column', value: 'jsonColumn'},
            {key: 3, text: 'Matrix', value: 'matrix'},
        ];

        return(
            <div>
                <span> Choose Data Format: {' '}
                    <Dropdown
                        placeholder="Select Data Format"
                        name={name}
                        value={outputFormat}
                        options={delimiterOptions}
                        onChange={this.handleRadioChange}
                        inline
                    />
                </span>
            </div>
        )
    }

}

export default SelectOutputType