import React from 'react';
import OutputFieldRow from './OutputFieldRow';
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import {Button, Icon} from "semantic-ui-react";

class OutputDisplay extends React.Component {
    handleAddRow = () => {
        this.props.onOutputFieldsChange([...this.props.outputFields, {}]);
    };

    handleRemoveRow = (index) => {
        const updatedFields = [...this.props.outputFields];
        updatedFields.splice(index, 1);
        this.props.onOutputFieldsChange(updatedFields);
    };

    handleFieldChange = (index, updatedField) => {
        const updatedFields = [...this.props.outputFields];
        updatedFields[index] = updatedField;
        this.props.onOutputFieldsChange(updatedFields);
    };

    render() {
        return (
            <div style={{ paddingTop: "32px"}}>
                <div style={{fontFamily: "Lato", fontSize: "16px", fontWeight: "700", color: gunaldiStyles.subText, marginBottom: "16px"}}>
                    Output Display
                </div>
                <div className="displayBox" style={{marginBottom: "16px"}}>
                    {this.props.outputFields.map((field, index) => (
                        <OutputFieldRow
                            key={index}
                            field={field}
                            sheetNames={this.props.sheetNames}
                            onFieldChange={(updatedField) => this.handleFieldChange(index, updatedField)}
                            onRemove={() => this.handleRemoveRow(index)}
                        />
                    ))}
                    <div>
                        <Button type="button" onClick={this.handleAddRow} size='small'
                                style={{ backgroundColor: gunaldiStyles.mainGreen, color: "white" }}><Icon name='plus' />Add Output Field</Button>
                    </div>
                </div>

            </div>
        );
    }
}

export default OutputDisplay;
