import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import RolesModalBody from "./RolesModalBody"
import { Icon } from 'semantic-ui-react'

class RolesModal extends Component {
    render() {
        return (
            <ModalApp name="rolesModal"
                      header={<h4><Icon name="users" /> Different roles can do different things</h4>}
                      body={<RolesModalBody />}
                      size="large"
            />
        )
    }
}

export default RolesModal