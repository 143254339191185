import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyCustomImportSettings} from "../../../../../Actions/ModifyCustomImportSettings";
import {Checkbox, Dropdown, Input} from "semantic-ui-react";
import FindReplaceInputs from "./FindReplaceInputs";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import TestingCleanFunctions from "../TestingCleanFunctions";
import {gunaldiStyles} from "../../../../../SharedComponents/GunaldiDesignStyles";
import InfoPopup from "../../../../../SharedComponents/InfoPopup";

class NumericalCleaningFunctions extends Component {
    constructor(props) {
        super(props);
        this.state={
            setValue:'',
            error:this.initError()

        }
        this.handleChange = this.handleChange.bind(this);
    }
    initError=()=>{
        let error=true;
        let info = this.getColumnInfo()
        let cleaningFunctions = info.cleaningFunctions;

        if ("numericalFormula" in cleaningFunctions){
            delete cleaningFunctions['numericalFormula']
        } else {
            cleaningFunctions['numericalFormula'] = ""
        }

        return error

    }
    handleChange(event, {value}){
        this.props.handleInputChange(this.props.idx, value)
    }
    getColumnInfo = () => {
        return this.props.customImportColumnsAddlInfo[this.props.columnIdx]
    }

    changeApplyFormula=()=>{
        const allColumnInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))
        const columnInfo = allColumnInfo[this.props.columnIdx]
        const cleaningFunctions = columnInfo['cleaningFunctions'];

        if ("numericalFormula" in cleaningFunctions){
            delete cleaningFunctions['numericalFormula']
        } else {
            cleaningFunctions['numericalFormula'] = ""
        }

        columnInfo['cleaningFunctions'] = cleaningFunctions
        allColumnInfo[this.props.columnIdx] = columnInfo

        this.props.modifyCustomImportSettings("columnsAddlInfo", allColumnInfo)
    }
    changeApplyInput = (e,{value}) => {
        const allColumnInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))
        const columnInfo = allColumnInfo[this.props.columnIdx]
        const cleaningFunctions = columnInfo['cleaningFunctions'];
        let error;

        if ("numericalFormula" in cleaningFunctions){
            cleaningFunctions['numericalFormula'] = value;
            let replacedValue = value.replaceAll('[value]','10')
            if(value.trim()===''){
                error=true
            }else{
                try{
                    eval(replacedValue)
                    error = false
                }catch{
                    error = true
                }
            }
        }else{
            cleaningFunctions['numericalFormula'] = ""
            error = true
        }
        this.setState({
            error:error })


        columnInfo['cleaningFunctions'] = cleaningFunctions
        allColumnInfo[this.props.columnIdx] = columnInfo

        this.props.modifyCustomImportSettings("columnsAddlInfo", allColumnInfo)

    }

    handleChange(event, {value}){
        this.props.handleInputChange(this.props.idx, value)
    }
    getColumnInfo = () => {
        return this.props.customImportColumnsAddlInfo[this.props.columnIdx]
    }

    render() {
        let info = this.getColumnInfo()
        let cleaningFunctions = info.cleaningFunctions;
        let labelStyle = {fontSize: "14px", color: gunaldiStyles.mainText, fontWeight: "400"};

        return (
            <div>
                <Checkbox
                    name="numericalFormula"
                    label={<label style={labelStyle}>Enter Formula to Transform Values </label>}
                    onChange={this.changeApplyFormula}
                    checked={'numericalFormula' in cleaningFunctions}
                />
                {
                    'numericalFormula' in cleaningFunctions ?
                        <div>
                            <div style={{color: gunaldiStyles.subText, marginBottom: "8px"}}><small>For example: [value]*10+52. [value] will correspond to the column you need a formula for in your spreadsheet. </small></div>
                            <Input
                                id=""
                                placeholder="Enter Formula"
                                name="numericalFormula"
                                onChange={this.changeApplyInput}
                                value={cleaningFunctions['numericalFormula']}/>
                            <br/>
                            {this.state.error ? <small style={{color:'red'}}> There is an error, please enter a valid formula. </small>:null}
                        </div> : null
                }
                <br/>
                { 'numericalFormula' in cleaningFunctions && !this.state.error ? <div> <i className="thinHeading">For testing, write the number of your [value]. For example, the number 20 </i> <TestingCleanFunctions cleaningFunctions={cleaningFunctions} columnType={"numerical"}/></div>:null}

            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    customImportColumnsAddlInfo: state.mainState.customImportColumnsAddlInfo,
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(NumericalCleaningFunctions)