import React, {Component} from 'react'
import {modifyAllQueries} from "../../Actions/ModifyAllQueries";
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {toggleToast} from "../../Actions/ToggleToast";
import { push } from 'react-router-redux'

class DeleteEditAPI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleting: false
        }
    }

    deleteAPI = () => {
        const queryPK = this.props.queryPK;

        const error = () =>
        {
            this.setState({ deleting: false }, () =>
                this.props.toggleToast({show: true, message: "There was an error deleting this API. Please try again", type: "error"})
            )
        }

        if (queryPK !== -1){
            this.setState({ deleting: true })

            fetch(API_Root + "api/delete-db-api-query/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    queryPK: queryPK,
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token")
                })
            }).then((res) => {
                if (res.status === 200){
                    this.removeQueryFromAllQueries()
                    this.props.navigateTo("/database" + this.props.dbHashURL)
                    this.props.toggleToast({show: true, message: "API successfully deleted!", type: "success"})
                } else {
                    error()
                }
            }).catch(err => error())
        }
    }

    removeQueryFromAllQueries = () => {
        const queryIdx = this.props.queryIdx;

        if (queryIdx !== -1){
            const copiedAllQueries = JSON.parse(JSON.stringify(this.props.allQueries))

            copiedAllQueries.splice(queryIdx, 1)

            this.props.modifyAllQueries(copiedAllQueries)
        }
    }

    render() {
        return (
            <Button content="Delete API" icon="trash alternate outline" color="red" basic loading={this.state.deleting} onClick={this.deleteAPI}/>
        )
    }
}

const mapStateToProps = (state) => ({
    allQueries: state.mainState.allQueries
})

const mapActionsToProps = {
    modifyAllQueries: modifyAllQueries,
    toggleToast: toggleToast,
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(DeleteEditAPI)