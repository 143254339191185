import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import { Button } from 'semantic-ui-react';

class PromoLogin extends Component {
    constructor(props) {
        super(props);

        this.openLoginModal = this.openLoginModal.bind(this);
    }

    openLoginModal(){
        this.props.toggleModal("loginModal")
    }

    render() {
        return (
            <div className="promoPaymentIndex">
                <h4 style={{color: 'red'}}>You need to Log In before taking advantage of the promo</h4>
                <Button color="green" content="Log In" onClick={this.openLoginModal} size="large"/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(PromoLogin)