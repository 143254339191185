import {TOGGLE_MODAL} from "../Actions/ToggleModal";
import {MODIFY_FILE_PK} from "../Actions/ModifyFilePK";
import {MODIFY_FILE_INFORMATION} from "../Actions/ModifyFileInformation";
import {TOGGLE_PRO_BLOCK} from "../Actions/ToggleProBlock";
import {MODIFY_STATUS} from "../Actions/ModifyStatus";
import {MODIFY_EDIT_OPTIONS} from "../Actions/ModifyEditOptions";
import {MODIFY_EDIT_ID} from "../Actions/ModifyEditID";
import {MODIFY_SHEET_READ} from "../Actions/ModifySheetRead";
import {MODIFY_ADD_FILE_NAME} from "../Actions/ModifyAddFileName";
import {MODIFY_FILE_URL} from "../Actions/ModifyFileURL";
import {MODIFY_KEYS_PK} from "../Actions/ModifyKeysPK";
import {RESET_ALL_STATES} from "../Actions/ResetAllStates";
import {MODIFY_CUSTOM_CONTACT_FILES} from "../Actions/ModifyCustomContactFiles";
import {MODIFY_FILE_PK_PRO_BLOCK} from "../Actions/ModifyFilePKProBlock";
import {MODIFY_INTEGRATION_AUTH} from "../Actions/ModifyIntegrationAuth";
import {MODIFY_ANALYTICS_ID} from "../Actions/ModifyAnalyticsID";
import {MODIFY_TIER} from "../Actions/ModifyTier";
import {MODIFY_UPGRADE_TEXT} from "../Actions/ModifyUpgradeText";
import {TOGGLE_TOAST} from "../Actions/ToggleToast";
import {MODIFY_MODAL_QUERY_QUICKSTART_TYPE} from "../Actions/ModifyModalQueryQuickstartType";
import {MODIFY_PAYMENT_MODAL_ORIGIN} from "../Actions/ModifyPaymentModalOrigin";
import {INIT_ORIGINAL_FILE_PK} from "../Actions/InitOriginalFilePK";
import {MODIFY_MODAL_IMAGE_URL} from "../Actions/ModifyModalImageURL";
import {INIT_UPLOADED_PK} from "../Actions/InitUploadedPK";
import {MODIFY_CURRENCY} from "../Actions/ModifyCurrency";
import {INIT_USER_HAS_CURRENCY} from "../Actions/InitUserHasCurrency";
import {MODIFY_FILES_FETCHED_TIME} from "../Actions/ModifyFilesFetchedTime";
import {MODIFY_MODAL_INFO} from "../Actions/ModifyModalInfo";
import {TOGGLE_SHOWING_TUTORIAL} from "../Actions/ToggleShowingTutorial";
import {MODIFY_TUTORIAL_STEP} from "../Actions/ModifyTutorialStep";
import {MODIFY_NAVBAR_ITEM_CLICKED} from "../Actions/ModifyNavbarItemClicked";
import {MODIFY_SHOWING_MINOR_TUTORIALS} from "../Actions/ModifyShowingMinorTutorials";
import {TOGGLE_SHOW_PROMO_BAR} from "../Actions/ToggleShowPromoBar";
import {MODIFY_VIZ_INFO} from "../Actions/ModifyVizInfo";
import {MODIFY_TEAM_INFO} from "../Actions/ModifyTeamInfo";
import {MODIFY_PENDING_INVITES} from "../Actions/ModifyPendingInvites";
import {MODIFY_IMPORT_SHEETS_INFO} from "../Actions/ModifyImportSheetsInfo";
import {MODIFY_IMPORT_SHEETS_UPLOAD_PK} from "../Actions/ModifyImportSheetsUploadPK";
import {MODIFY_IMPORT_SHEETS_FILE_INFORMATION} from "../Actions/ModifyImportSheetsFileInformation";
import {MODIFY_IMPORT_SHEET_READ} from "../Actions/ModifyImportSheetRead";
import {MODIFY_PRO_BLOCK_TOO_MANY_FILES} from "../Actions/ModifyProBlockTooManyFiles";
import {INIT_ALL_CUSTOM_IMPORT_SETTINGS} from "../Actions/InitAllCustomImportSettings";
import {MODIFY_CUSTOM_IMPORT_SETTINGS} from "../Actions/ModifyCustomImportSettings";
import {MODIFY_IMPORT_COLUMN_INFORMATION} from "../Actions/ModifyImportColumnInformation";
import {MODIFY_APPSUMO_PLAN_NAME} from "../Actions/ModifyAppsumoPlanName";
import {MODIFY_ALL_DB_INFO} from "../Actions/ModifyAllDBInfo";
import {MODIFY_DB_INFO} from "../Actions/ModifyDBInfo";
import {MODIFY_ALL_QUERIES} from "../Actions/ModifyAllQueries";
import {MODIFY_EDIT_QUERY_INFO} from "../Actions/ModifyEditQueryInfo";
import {MODIFY_EMAIL_VERIFIED} from "../Actions/ModifyEmailVerified";
import {MODIFY_CONNECTED_DOMAINS} from "../Actions/ModifyConnectedDomains";
import {TOGGLE_YEARLY_PRICING} from "../Actions/ToggleYearlyPricing";
import {INIT_IS_GRANDFATHER} from "../Actions/InitIsGrandfather";
import {MODIFY_CURR_REQUESTS} from "../Actions/ModifyCurrRequests";
import {MODIFY_SUBSCRIPTION_INFO} from "../Actions/ModifySubscriptionInfo";
import {MODIFY_API_MENU_ITEM} from "../Actions/ModifyAPIMenuItem";
import {MODIFY_IMPORTER_MENU_ITEM} from "../Actions/ModifyImporterMenuItem";
import {MODIFY_IMPORTER_NAME} from "../Actions/ModifyImporterName";
import {TOGGLE_SHOW_DEMO} from "../Actions/ToggleShowDemo";
import {TOGGLE_IS_PRODUCT_IMPORTER} from "../Actions/ToggleIsProductImporter";
import {MODIFY_UTILITIES_MENU_ITEM} from "../Actions/ModifyUtilitiesMenuItem";
import {MODIFY_UTILITIES_INFO} from "../Actions/ModifyUtiltiesInfo";
import {MODIFY_FULL_PRICING_INFO} from "../Actions/ModifyFullPricingInfo";

function mainState(
    state = {
        filePK: [],
        fileInformation: {},
        editOptions: {},
        modalName: null,
        proBlock: false,
        status: "upload",
        editID: null,
        sheetRead: {},
        addFileName: false,
        fileURL: '',
        keysPK: -1,
        customContactFiles: [],
        filePKProBlock: {},
        integrationAuth: {
            dropbox: false,
            googleSheets: false
        },
        analyticsID: null,
        tier: null,
        upgradeText: "",
        toast: {show: false, message: "", type: ""},
        modalQueryQuickstartType: "",
        paymentModalOrigin: null,
        originalFilePK: [],
        modalImageURL: null,
        uploadedPK: [],
        currency: "usd",
        userHasCurrency: false,
        filesFetchedTime: null,
        modalInfo: null,
        showingTutorial: false,
        tutorialStep: null,
        navbarItemClicked: "",
        showingMinorTutorials: {"columnMap": true, "refreshFile": true},
        showPromoBar: true,
        vizInfo: null,
        teamInfo: null,
        pendingInvites: null,
        appsumoPlanName: '',
        importSheetsInfo: [],
        importSheetsUploadPK: [],
        importSheetsFileInformation: {},
        importSheetRead: {},
        proBlockTooManyFiles: [],
        customImportColumns: null,
        customImportAppearance: null,
        customImportDestinations: null,
        customImportOptions: null,
        customImportInfo: null,
        customImportColumnsAddlInfo: null,
        customImportDestinationsEdit: null,
        customImportAppearanceEdit: null,
        customImportOptionsEdit: null,
        customImportMatchColumns: false,
        customImportFileIdx: -1,
        customImportShouldEdit: false,
        allDBInfo: {},
        dbInfo: [],
        editQueryInfo: null,
        allQueries: [],
        emailVerified: false,
        customImportEditOptions: {header: true, skipRows: 0, startRow: 0, delimiter: ",", skipRowsError: false, startRowError: false, rows: [[]], columns: [], sheetRead: null, sheetNames: []},
        connectedDomains: null,
        yearlyPricing: true,
        isGrandfather: true,
        currRequests: {requests: 0, frequency: "daily", files: 0},
        subscriptionInfo: {},
        apiMenuItem: "upload",
        importerMenuItem: "importers",
        importerName: "",
        showDemo: true,
        isProductImporter: false,
        utilitiesMenuItem: "ai",
        utilitiesInfo: {
            isPrivate: false,
            whitelistDomains: "",
            keys: []
        },
        fullPricingInfo: null
    }, action
) {
    switch (action.type) {
        case MODIFY_UTILITIES_INFO:
            return Object.assign({}, state, {
                utilitiesInfo: action.mainState.utilitiesInfo
            });
        case MODIFY_UTILITIES_MENU_ITEM:
            return Object.assign({}, state, {
                utilitiesMenuItem: action.mainState.utilitiesMenuItem
            });
        case TOGGLE_IS_PRODUCT_IMPORTER:
            return Object.assign({}, state, {
                isProductImporter: action.mainState.isProductImporter
            });
        case TOGGLE_SHOW_DEMO:
            return Object.assign({}, state, {
                showDemo: action.mainState.showDemo
            });
        case MODIFY_IMPORTER_NAME:
            return Object.assign({}, state, {
                importerName: action.mainState.importerName
            });
        case MODIFY_IMPORTER_MENU_ITEM:
            return Object.assign({}, state, {
                importerMenuItem: action.mainState.importerMenuItem
            });
        case MODIFY_API_MENU_ITEM:
            return Object.assign({}, state, {
                apiMenuItem: action.mainState.apiMenuItem
            });
        case MODIFY_SUBSCRIPTION_INFO:
            return Object.assign({}, state, {
                subscriptionInfo: action.mainState.subscriptionInfo
            });
        case MODIFY_CURR_REQUESTS:
            return Object.assign({}, state, {
                currRequests: action.mainState.currRequests
            });
        case INIT_IS_GRANDFATHER:
            return Object.assign({}, state, {
                isGrandfather: action.mainState.isGrandfather
            });
        case TOGGLE_YEARLY_PRICING:
            return Object.assign({}, state, {
                yearlyPricing: action.mainState.yearlyPricing
            });
        case MODIFY_CONNECTED_DOMAINS:
            return Object.assign({}, state, {
                connectedDomains: action.mainState.connectedDomains
            });
        case MODIFY_EMAIL_VERIFIED:
            return Object.assign({}, state, {
                emailVerified: action.mainState.emailVerified
            });
        case MODIFY_FULL_PRICING_INFO:
            return Object.assign({}, state, {
                fullPricingInfo: action.mainState.fullPricingInfo
            });
        case MODIFY_ALL_QUERIES:
            return Object.assign({}, state, {
                allQueries: action.mainState.allQueries
            });
        case MODIFY_ALL_DB_INFO:
            return Object.assign({}, state, {
                allDBInfo: action.mainState.allDBInfo
            });
        case MODIFY_APPSUMO_PLAN_NAME:
            return Object.assign({}, state, {
                appsumoPlanName: action.mainState.appsumoPlanName
            })
        case MODIFY_IMPORT_COLUMN_INFORMATION:
            return Object.assign({}, state, {
                customImportColumns: action.mainState.customImportColumns,
                customImportColumnsAddlInfo: action.mainState.customImportColumnsAddlInfo
            })
        case MODIFY_CUSTOM_IMPORT_SETTINGS:
            return Object.assign({}, state, {
                [action.settingsName]: action.mainState[action.settingsName]
            })
        case INIT_ALL_CUSTOM_IMPORT_SETTINGS:
            return Object.assign({}, state, {
                customImportInfo: action.mainState.customImportInfo,
                customImportColumns: action.mainState.customImportColumns,
                customImportAppearance: action.mainState.customImportAppearance,
                customImportDestinations: action.mainState.customImportDestinations,
                customImportOptions: action.mainState.customImportOptions,
                customImportColumnsAddlInfo: action.mainState.customImportColumnsAddlInfo,
                customImportDestinationsEdit: action.mainState.customImportDestinations,
                customImportAppearanceEdit: action.mainState.customImportAppearance,
                customImportOptionsEdit: action.mainState.customImportOptions
            })
        case MODIFY_PRO_BLOCK_TOO_MANY_FILES:
            return Object.assign({}, state, {
                proBlockTooManyFiles: action.mainState.proBlockTooManyFiles
            })
        case MODIFY_IMPORT_SHEET_READ:
            return Object.assign({}, state, {
                importSheetRead: action.mainState.importSheetRead
            })
        case MODIFY_IMPORT_SHEETS_FILE_INFORMATION:
            return Object.assign({}, state, {
                importSheetsFileInformation: action.mainState.importSheetsFileInformation,
            })
        case MODIFY_IMPORT_SHEETS_UPLOAD_PK:
            return Object.assign({}, state, {
                importSheetsUploadPK: action.mainState.importSheetsUploadPK,
            })
        case MODIFY_IMPORT_SHEETS_INFO:
            return Object.assign({}, state, {
                importSheetsInfo: action.mainState.importSheetsInfo,
            })
        case MODIFY_TEAM_INFO:
            return Object.assign({}, state, {
                teamInfo: action.mainState.teamInfo,
            })
        case MODIFY_PENDING_INVITES:
            return Object.assign({}, state, {
                pendingInvites: action.mainState.pendingInvites
            })
        case MODIFY_VIZ_INFO:
            return Object.assign({}, state, {
                vizInfo: action.mainState.vizInfo
            })
        case TOGGLE_SHOW_PROMO_BAR:
            return Object.assign({}, state, {
                showPromoBar: action.mainState.showPromoBar
            });
        case MODIFY_FILE_PK:
            return Object.assign({}, state, {
                filePK: action.mainState.filePK
            });
        case MODIFY_FILE_INFORMATION:
            return Object.assign({}, state, {
                fileInformation: action.mainState.fileInformation
            });
        case TOGGLE_MODAL:
            return Object.assign({}, state, {
                modalName: action.mainState.modalName
            });
        case TOGGLE_PRO_BLOCK:
            return Object.assign({}, state, {
                proBlock: action.mainState.proBlock
            });
        case MODIFY_STATUS:
            return Object.assign({}, state, {
                status: action.mainState.status
            });
        case MODIFY_EDIT_OPTIONS:
            return Object.assign({}, state, {
                editOptions: action.mainState.editOptions
            });
        case MODIFY_EDIT_ID:
            return Object.assign({}, state, {
                editID: action.mainState.editID
            });
        case MODIFY_SHEET_READ:
            return Object.assign({}, state, {
                sheetRead: action.mainState.sheetRead
            });
        case MODIFY_ADD_FILE_NAME:
            return Object.assign({}, state, {
                addFileName: action.mainState.addFileName
            });
        case MODIFY_FILE_URL:
            return Object.assign({}, state, {
                fileURL: action.mainState.fileURL
            });
        case MODIFY_KEYS_PK:
            return Object.assign({}, state, {
                keysPK: action.mainState.keysPK
            });
        case MODIFY_CUSTOM_CONTACT_FILES:
            return Object.assign({}, state, {
                customContactFiles: action.mainState.customContactFiles
            });
        case MODIFY_FILE_PK_PRO_BLOCK:
            return Object.assign({}, state, {
                filePKProBlock: action.mainState.filePKProBlock
            });
        case MODIFY_INTEGRATION_AUTH:
            return Object.assign({}, state, {
                integrationAuth: action.mainState.integrationAuth
            })
        case MODIFY_ANALYTICS_ID:
            return Object.assign({}, state, {
                analyticsID: action.mainState.analyticsID
            })
        case MODIFY_TIER:
            return Object.assign({}, state, {
                tier: action.mainState.tier
            })
        case MODIFY_UPGRADE_TEXT:
            return Object.assign({}, state, {
                upgradeText: action.mainState.upgradeText
            })
        case TOGGLE_TOAST:
            return Object.assign({}, state, {
                toast: action.mainState.toast
            })
        case MODIFY_MODAL_QUERY_QUICKSTART_TYPE:
            return Object.assign({}, state, {
                modalQueryQuickstartType: action.mainState.modalQueryQuickstartType
            })
        case MODIFY_PAYMENT_MODAL_ORIGIN:
            return Object.assign({}, state, {
                paymentModalOrigin: action.mainState.paymentModalOrigin
            })
        case INIT_ORIGINAL_FILE_PK:
            return Object.assign({}, state, {
                originalFilePK: action.mainState.originalFilePK
            })
        case MODIFY_MODAL_IMAGE_URL:
            return Object.assign({}, state, {
                modalImageURL: action.mainState.modalImageURL
            })
        case INIT_UPLOADED_PK:
            return Object.assign({}, state, {
                uploadedPK: action.mainState.uploadedPK
            })
        case MODIFY_CURRENCY:
            return Object.assign({}, state, {
                currency: action.mainState.currency
            })
        case INIT_USER_HAS_CURRENCY:
            return Object.assign({}, state, {
                userHasCurrency: action.mainState.userHasCurrency
            })
        case MODIFY_FILES_FETCHED_TIME:
            return Object.assign({}, state, {
                filesFetchedTime: action.mainState.filesFetchedTime
            })
        case MODIFY_MODAL_INFO:
            return Object.assign({}, state, {
                modalInfo: action.mainState.modalInfo
            })
        case TOGGLE_SHOWING_TUTORIAL:
            return Object.assign({}, state, {
                showingTutorial: action.mainState.showingTutorial
            })
        case MODIFY_TUTORIAL_STEP:
            return Object.assign({}, state, {
                tutorialStep: action.mainState.tutorialStep
            })
        case MODIFY_NAVBAR_ITEM_CLICKED:
            return Object.assign({}, state, {
                navbarItemClicked: action.mainState.navbarItemClicked
            })
        case MODIFY_SHOWING_MINOR_TUTORIALS:
            return Object.assign({}, state, {
                showingMinorTutorials: action.mainState.showingMinorTutorials
            })
        case MODIFY_DB_INFO:
            return Object.assign({}, state, {
                dbInfo: action.mainState.dbInfo
            });
        case MODIFY_EDIT_QUERY_INFO:
            return Object.assign({}, state, {
                editQueryInfo: action.mainState.editQueryInfo
            });
        case RESET_ALL_STATES:
            return Object.assign({}, state, {
                vizInfo: action.mainState.vizInfo,
                filePK: action.mainState.filePK,
                fileInformation: action.mainState.fileInformation,
                editOptions: action.mainState.editOptions,
                modalName: action.mainState.modalName,
                proBlock: action.mainState.proBlock,
                status: action.mainState.status,
                editID: action.mainState.editID,
                sheetRead: action.mainState.sheetRead,
                addFileName: action.mainState.addFileName,
                fileURL: action.mainState.fileURL,
                keysPK: action.mainState.keysPK,
                customContactFiles: action.mainState.customContactFiles,
                filePKProBlock: action.mainState.filePKProBlock,
                integrationAuth: {
                    dropbox: false,
                    googleSheets: false
                },
                analyticsID: null,
                tier: null,
                upgradeText: "",
                toast: {show: false, message: "", type: ""},
                modalQueryQuickstartType: "",
                paymentModalOrigin: null,
                originalFilePK: [],
                modalImageURL: '',
                uploadedPK: [],
                currency: "usd",
                userHasCurrency: false,
                filesFetchedTime: null,
                modalInfo: null,
                showingTutorial: false,
                tutorialStep: null,
                navbarItemClicked: "",
                showingMinorTutorials: {"columnMap": true, "refreshFile": true},
                teamInfo: null,
                pendingInvites: null,
                importSheetsInfo: [],
                importSheetsUploadPK: [],
                appsumoPlanName: '',
                allDBInfo: {},
                dbInfo: [],
                editQueryInfo: null
            });
        default:
            return state
    }
}

export default mainState;

