import React, {Component} from 'react'
import {connect} from 'react-redux'
import constants from "../../../Constants/constants";
import { Icon } from 'semantic-ui-react'
import RereadFile from "./RereadFile";

class UploadLocalFiles extends Component {
    render() {
        const imgStyle = {border:"1px solid #F0F0F0", display: "block", marginBottom: "16px"}

        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">Selecting a File</p>
                    <p>You can select any of the following file types from your computer: {constants.extensions.join(",")}</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-LocalFiles/UploadLocalFiles.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="select dropbox from account"
                    />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">File Read</p>
                    <RereadFile setBothMenuItems={this.props.setBothMenuItems} hasCsv={true} fileNames={<b>excel files</b>}/>
                </div>
                {/*file types to select*/}
                {/*can mention multiple files*/}
                {/*copy and paste intro article from here*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UploadLocalFiles)