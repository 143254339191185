import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'
import SampleTable from "../SampleTable";
import DocsBaseURL from "../../../InfoPages/DocsPage/DocsBaseURL"
import JSONDisplay from "../../../SharedComponents/JSONDisplay";
import CodeSnippet from "../../../FileDnD/CodeSnippet"
import {createFileInformation} from "../Functions/createFileInformation";
import {createCodeSnippets} from "../Functions/createCodeSnippets";
import {createFullURLFromBase} from "../../../Constants/createFullURLFromBase";
import BetaMessage from "../../../SharedComponents/BetaMessage";

class ReadSubset extends Component {
    constructor(props){
        super(props);

        this.state = {
            sampleTableData: [
                [1, "Justin Trudeau", 252069.46, "Canada", "justin@canada.gov"],
                [2, "Jacinda Ardern", 339862.25, "New Zealand", "jacinda@newzealand.gov"],
                [3, "Boris Johnson", 187033.75, "UK", "boris@gmail.com"],
                [4, "Joko Widodo", 27200.8, "Indonesia", "joko@gmail.com"],
                [5, "Angela Merkel", 369727.4, "Germany", "angela@deutschland.gov"]
            ],
            sampleTableHeaders: ["id", "name", "salary_$", "country", "email"],
            sampleDataType: ["num", "str", "num", "str", "str"],
            dataFormat: "row"
        }

        this.changeState = this.changeState.bind(this);
    }

    changeState(name, value){
        this.setState({
            [name]: value
        })
    }

    render() {
        // POST: Orange
        // GET: Yellow
        let baseGetURL = 'data/[fileID]/?query=[query]/';
        const sampleFileInformation = createFileInformation(this.state.sampleTableData, this.state.sampleTableHeaders, this.state.sampleDataType, 1023, "Sample Data - World Leaders", false, createFullURLFromBase('data/1023'))

        return (
            <div>
                <h2>Make a GET request to the following URL</h2>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={baseGetURL} />
                <br/>
                <p>We will only cover the <code>query</code> parameter in this guide. Be sure to go through the <a href="/guides/read-all-rows" target="_blank" rel="noopener noreferrer">Read All Rows</a> guide to learn how all the other parameters are used.</p>
                <br/><br/>
                <h2>Let's look at some examples</h2>
                <p>We will use the following sample data for our examples</p>
                <SampleTable
                    tableData={this.state.sampleTableData}
                    tableHeaders={this.state.sampleTableHeaders}
                    header="Sample Data - World Leaders"
                />
                <br/>
                <p>The following are examples of queries and the response that you will get from the API. You can read all about how to write a valid query in its <a href="/guides/write-a-valid-query">guide</a>.</p>
                <br/><br/>
                <h4>Example #1</h4>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={'data/cOjdbKHx41pI2Ckb/?query=SELECT * FROM cOjdbKHx41pI2Ckb WHERE name=\'Boris Johnson\''} />
                {/*<CodeSnippet*/}
                    {/*code={createCodeSnippets(sampleFileInformation, "readAction", createFullURLFromBase('data/5CF9MkyfCi1Vjnre/?query=SELECT * FROM 5CF9MkyfCi1Vjnre WHERE name=\'Boris Johnson\''))}*/}
                    {/*height={100}*/}
                {/*/>*/}
                <SampleTable
                    tableData={[this.state.sampleTableData[2]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    header={<code>SELECT * FROM cOjdbKHx41pI2Ckb WHERE name='Boris Johnson'</code>}
                />
                <JSONDisplay
                    heading="Your JSON Response"
                    tableData={[this.state.sampleTableData[2]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    dataTypes={this.state.sampleDataType}
                    outputType="row"
                />
                <br/><br/><br/>
                <h4>Example #2</h4>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={'data/cOjdbKHx41pI2Ckb/?query=SELECT * FROM cOjdbKHx41pI2Ckb WHERE salary_$ BETWEEN 100000 AND 350000'} />
                <SampleTable
                    tableData={[this.state.sampleTableData[0], this.state.sampleTableData[1], this.state.sampleTableData[2]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    header={<code>{'SELECT * FROM cOjdbKHx41pI2Ckb WHERE salary_$ BETWEEN 100000 AND 350000'}</code>}
                />
                <JSONDisplay
                    heading="Your JSON Response"
                    tableData={[this.state.sampleTableData[0], this.state.sampleTableData[1], this.state.sampleTableData[2]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    dataTypes={this.state.sampleDataType}
                    outputType="row"
                />
                <br/><br/><br/>
                <h4>Example #3</h4>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={"data/cOjdbKHx41pI2Ckb/?query=SELECT id, name, email FROM cOjdbKHx41pI2Ckb WHERE email LIKE '%gmail%'"} />
                <SampleTable
                    tableData={[
                        [3, "Boris Johnson", "boris@gmail.com"],
                        [4, "Joko Widodo", "joko@gmail.com"],
                    ]}
                    tableHeaders={["id", "name", "email"]}
                    header={<code>{"SELECT id, name FROM cOjdbKHx41pI2Ckb WHERE email LIKE '%gmail%'"}</code>}
                />
                <JSONDisplay
                    heading="Your JSON Response"
                    tableData={[
                        [3, "Boris Johnson", "boris@gmail.com"],
                        [4, "Joko Widodo", "joko@gmail.com"],
                    ]}
                    tableHeaders={["id", "name", "email"]}
                    dataTypes={["num", "str", "str"]}
                    outputType="row"
                />
                <br/><br/><br/>
                <h4>Example #4</h4>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={"data/cOjdbKHx41pI2Ckb/?query=SELECT * FROM cOjdbKHx41pI2Ckb WHERE country NOT IN ('Canada','Indonesia')"} />
                <SampleTable
                    tableData={[this.state.sampleTableData[1], this.state.sampleTableData[2], this.state.sampleTableData[4]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    header={<code>{"SELECT * FROM cOjdbKHx41pI2Ckb WHERE country NOT IN ('Canada','Indonesia')"}</code>}
                />
                <JSONDisplay
                    heading="Your JSON Response"
                    tableData={[this.state.sampleTableData[1], this.state.sampleTableData[2], this.state.sampleTableData[4]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    dataTypes={this.state.sampleDataType}
                    outputType="row"
                />
            </div>
        )
    }
}

export default ReadSubset