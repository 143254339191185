import React, {Component} from 'react'
import { Button } from 'semantic-ui-react'
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import { capitalizeCamelCase } from "../../../../Constants/capitalizeCamelCase";

const extensions = {
    fileCertificate: ".crt",
    fileKey: ".key",
    fileCertificateChain: ".ca-bundle"
}

class UploadSSLCertificate extends Component {
    openUploader = (ref) => this[ref].click()

    upload = (e) => {
        const uploadCert = e.target.name;

        let file;

        try{
            file = e.target.files[0];
        } catch (e){
            file = null
        }

        if (file !== null){
            this.props.changeState({[uploadCert]: file})
        }
    }

    getFileUploader = (uploaderName, extensions, ref) => {
        return (
            <input
                type="file"
                name={uploaderName}
                id={uploaderName}
                className="form-control-file"
                onChange={this.upload}
                style={{
                    width: '0.1px',
                    height: '0.1px',
                    opacity: '0',
                    overflow: 'hidden',
                    position: 'absolute',
                    zIndex: '-1'
                }}
                multiple={false}
                accept={[extensions]}
                ref={ref}
                // ref={certUpload => this.certUpload = certUpload}
            />
        )
    }

    getUploadBtn = (ref) => {
        return (
            <Button
                style={gunaldiStyles.btnGreenBasic}
                icon="upload"
                circular={true}
                size="mini"
                onClick={e => this.openUploader(ref)}
            />
        )
    }

    getFileUploadRow = (label) => {
        return (
            <div style={{marginBottom: "16px"}}>
                <div style={{display: "grid", gridTemplateColumns: "160px auto"}}>
                    <div>
                        <div style={{color: gunaldiStyles.subText}}>
                            {capitalizeCamelCase(label)}{label !== "fileCertificateChain" ? <span style={{color: "red"}}>*</span> : null}
                        </div>
                        <div>
                            <small style={{color: gunaldiStyles.secondaryText}}>File extension: <b>{extensions[label]}</b></small>
                        </div>
                    </div>
                    <div>
                        {this.getUploadBtn(label)}
                    </div>
                </div>
                {this.props[label] === null
                    ? null : <div><small>{this.props[label].name} was uploaded</small></div>}
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.getFileUploader("fileCertificate", extensions.fileCertificate, fileCertificate => this.fileCertificate = fileCertificate)}
                {this.getFileUploader("fileKey", extensions.fileKey, fileKey => this.fileKey = fileKey)}
                {this.getFileUploader("fileCertificateChain", extensions.fileCertificateChain, fileCertificateChain => this.fileCertificateChain = fileCertificateChain)}
                {this.getFileUploadRow("fileCertificate")}
                {this.getFileUploadRow("fileKey")}
                {this.getFileUploadRow("fileCertificateChain")}
            </div>
        )
    }
}

export default UploadSSLCertificate