import React, {Component} from 'react'
import SlideCounter from "./SlideCounter";
import { Icon } from 'semantic-ui-react'

class Carousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slide: 0
        }

        this.scroll = this.scroll.bind(this);
        this.changeSlide = this.changeSlide.bind(this);
    }

    scroll(e, changeFunc){
        const currSlide = this.state.slide;
        let changedSlide = changeFunc(currSlide) % this.props.content.length;

        if (changedSlide < 0){
            changedSlide = this.props.content.length + changedSlide
        }

        this.setState({
            slide: changedSlide
        })
    }

    changeSlide(slide){
        this.setState({
            slide: slide
        })
    }

    render() {
        return (
            <div className="carousel">
                <div className="carouselLeft" onClick={(e) => this.scroll(e, x =>  x-1 )}>
                    <Icon name="angle left"
                          id="carouselLeftArrow"
                    />
                </div>
                <div className="carouselContent">
                    {this.props.content[this.state.slide]}
                </div>
                <div className="carouselRight" onClick={(e) => this.scroll(e, x => x+1 )}>
                        <Icon name="angle right"
                              id="carouselRightArrow"
                        />
                </div>
                <div className="carouselIndicator">
                    <SlideCounter
                        totalSlides={this.props.content.length}
                        slide={this.state.slide}
                        changeSlide={this.changeSlide}
                    />
                </div>
            </div>
        )
    }
}

export default Carousel