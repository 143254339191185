import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

class BackHeaderIcon extends Component {
    constructor(props) {
        super(props);
    }

    click = () => {
        this.props.navigateTo(this.props.link)
        // this.iconLink.click()
    }

    render() {
        return (
            <span className="backHeaderIconSpan">
                <a href={this.props.link}
                   ref={iconLink => this.iconLink = iconLink} style={{display: "none"}}> Icon Link</a>
                <Icon name="chevron left" className="backHeaderIcon" onClick={this.click}/>
            </span>
        )
    }
}

const mapActionsToProps = {
    navigateTo: push
}

export default connect(null, mapActionsToProps)(BackHeaderIcon)