import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class GoogleSheetsVisual extends Component {
    render() {
        return (
            <div className="uploadOuterSection">
                <div className="uploadInnerSection">
                    <div style={{textAlign: 'center'}}>
                        <span className="uploadIcons">
                            <Icon name="google drive" />
                        </span>
                        <h4 className="thinHeading">Google Sheets</h4>
                    </div>
                </div>
            </div>
        )
    }
}

export default GoogleSheetsVisual