import React, {Component} from 'react'
import {connect} from 'react-redux'
import CopyURLSegment from "../../SharedComponents/CopyURLSegment"
import API_Root from "../../Constants/API_Root";
import TableIndex from "../../InfoPages/Table/TableIndex";

class FilesPageTableIndex extends Component {
    constructor(props) {
        super(props);
    }

    getDisplayURL = (getURL) => {
        const dataIdx = getURL.indexOf("/data/") + "/data/".length;
        const baseURL = API_Root.includes("8000") ? "http://localhost:5000/" : "https://www.apispreadsheets.com/"

        return baseURL + "table/" + getURL.substring(dataIdx, getURL.length)
    }

    render() {
        const getURL = this.props.getURL;
        const displayURL = this.getDisplayURL(getURL)

        return (
            <div>
                <div style={{marginBottom: "16px"}}>
                    <CopyURLSegment url={displayURL} header="URL to view data table below"/>
                </div>
                <div>
                    <TableIndex files getURL={getURL}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FilesPageTableIndex)