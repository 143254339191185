import React, {Component} from 'react'
import ModalApp from "../../SharedComponents/Modal"
import QuickstartBody from "./QuickstartBody";

class QuickstartModal extends Component {
    render() {
        return (
            <ModalApp name="quickStartModal"
                      header={null}
                      size="medium"
                      body={<QuickstartBody/>}
                      noClose
            />
        )
    }
}

export default QuickstartModal