import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import { push } from 'react-router-redux'

class FooterItem extends Component {
    constructor(props){
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.goToURL = this.goToURL.bind(this);
    }

    toggleModal(e){
        this.props.toggleModal(this.props.to)
    }

    goToURL(e){
        this.props.navigateTo("/" + this.props.to)
    }

    render() {
        let leftIcon = null;
        let rightIcon = null;

        const style = {color: this.props.color};

        if ('icon' in this.props){
            if ('iconPosition' in this.props){
                if (this.props.iconPosition === "left"){
                    leftIcon = <Icon name={this.props.icon} />
                }
                else{
                    rightIcon = <Icon name={this.props.icon} />
                }
            }
            else{
                leftIcon = <Icon name={this.props.icon} />
            }
        }

        if ('modal' in this.props){
            return(
                <span className="footerItem" onClick={this.toggleModal} style={style}>{leftIcon}{this.props.name}{rightIcon}</span>
            )
        }
        else if ('external' in this.props) {
            return(
                <a href={this.props.to} style={style} target="_blank" rel="noopener noreferrer" className="footerItem">
                    {leftIcon}{this.props.name}{rightIcon}</a>
            )
        }
        else{
            return (
                <a href={"/" + this.props.to} style={style} className="footerItem">
                    {leftIcon}{this.props.name}{rightIcon}</a>
            )
        }
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    toggleModal: toggleModal,
    navigateTo: push
};

export default connect(mapStateToProps, mapActionsToProps)(FooterItem)