import React, {Component} from 'react'
import QueryHelpBody from "../../Database/Modals/QueryHelpBody";
import { Table } from 'semantic-ui-react'
import TierRestrictionBanner from "../../App/Docs/Actions/TierRestrictionBanner";

class DatabaseAPIGuide extends Component {
    render() {
        return (
            <div>
                <div style={{marginTop: "-8px", marginBottom: "16px"}}>
                    <TierRestrictionBanner plan="business" payment="database"/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">The first step is connecting to your database</p>
                    <p><span onClick={e => this.props.setBothMenuItems("database-connect")} className="linkStyle"> Read our guide on how to connect to your database</span></p>
                    <p>After you have successfully connected to your database, you can create multiple APIs to read, create, update, or delete data from your database</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Create an API</p>
                    <p style={{marginBottom: "5px"}}>On your database home page, you can create an API</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/DatabaseAPI/AddNewAPI.png" style={{width: "100%"}} alt="Add API"/>
                    <p> </p>
                    <p>Now you will need to enter a <b>name</b> for the API and a <b>query</b></p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Query</p>
                    <p style={{marginBottom: "5px"}}>The query that you enter here will become the data for your API</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/DatabaseAPI/WriteAQuery.png" style={{width: "100%"}} alt="Add Query" />
                    <p> </p>
                    <QueryHelpBody/>
                    <br/>
                    <p>You can use this API just like any other <b>Spreadsheet API</b> that you have created using API Spreadsheets with one major exception.</p>
                    <p style={{color: "red"}}>If your Query is getting data from more than 1 table, then you can <b>NOT</b> create, update or delete values</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">You can now use this API</p>
                    <p><span className="linkStyle" onClick={e => this.props.setBothMenuItems("database-useAPI")}>Read how to use this API from your database</span></p>
                </div>
            </div>
        )
    }
}

export default DatabaseAPIGuide