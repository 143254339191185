import React, {Component} from 'react'
import ModalApp from '../Modal'
import { Form, TextArea } from 'semantic-ui-react'
import FeedbackSubmitButton from './FeedbackSubmitButton'

class FeedbackModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            feedback: ''
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(e){
        this.setState({
            feedback: e.target.value
        })
    }

    render() {
        const modalBody =
            <div className="row">
                <div className="col-sm-1" />
                <div className="col-sm-10">
                    <br/><br/>
                    <Form>
                        <TextArea value={this.state.feedback} placeholder="What should I watch now that Game of Thrones is over?" onChange={this.onChange}/>
                    </Form>
                    <br/><br/>
                    <div style={{float: 'right'}}>
                        <FeedbackSubmitButton feedback={this.state.feedback}/>
                    </div>
                    <br/><br/>
                </div>
                <div className="col-sm-1" />
            </div>;

        return (
            <div>
                <ModalApp name="feedbackModal"
                          header="Provide feedback so we can help you better!"
                          body={modalBody}
                          size="small"
                />
            </div>
        )
    }
}

export default FeedbackModal