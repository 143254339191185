import React, {Component} from 'react'
import { connect } from 'react-redux'
import {toggleModal} from "../../../Actions/ToggleModal";
import {modifyEditID} from "../../../Actions/ModifyEditID";
import {modifyModalQueryQuickstartType} from "../../../Actions/ModifyModalQueryQuickstartType";

class DeleteOptionsToolbar extends Component {
    constructor(props) {
        super(props);

        this.openQueryModal = this.openQueryModal.bind(this);
    }

    openQueryModal(e){
        this.props.modifyEditID(this.props.pkID)
        this.props.modifyModalQueryQuickstartType("deleteAction")
        this.props.toggleModal("queryQuickstartModal")
    }

    render() {
        return (
            <div className="boxOutline" style={{marginTop: "16px"}}>
                <span> A Query is required to delete rows. <span className="linkStyle" onClick={this.openQueryModal}>Read how</span> </span>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyEditID: modifyEditID,
    modifyModalQueryQuickstartType: modifyModalQueryQuickstartType
}

export default connect(null, mapActionsToProps)(DeleteOptionsToolbar)