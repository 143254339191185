export const isTLDValid = (tld) => {
    let isValid = true;
    let testTLD = tld.trim();

    if (testTLD === ""){
        isValid = false
    } else{
        if (testTLD.length < 2){
            isValid = false
        } else{
            // does contain things other than letters
            if(/[^a-z]/i.test(testTLD)){
                isValid = false
            }
        }
    }

    return isValid
}