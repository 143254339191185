import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyFinalColumnPK} from "../Actions/ModifyFinalColumnPK";
import {modifyStatus} from "../Actions/ModifyStatus";
import {modifyEditOptions} from "../Actions/ModifyEditOptions";
import {modifyFinalColumnNames} from "../Actions/ModifyFinalColumnNames";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {toggleModal} from "../Actions/ToggleModal";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import {modifyFilePKProBlock} from "../Actions/ModifyFilePKProBlock";
import {toggleToast} from "../Actions/ToggleToast";
import {readFiles} from "../Constants/readFile";
import {modifyAPIMenuItem} from "../Actions/ModifyAPIMenuItem";

class ImportSheetsButton extends Component {
    constructor(props) {
        super(props);

        this.importSheets = this.importSheets.bind(this);
    }

    importSheets(){
        // Check if there is any error
        const importSheets = true;

        if (importSheets){
            let pk;
            try{
                pk = parseInt(this.props.pkID.split(" ")[0])
            } catch (e) {
                pk = -1
            }

            // let filePK = this.props.filePK.;

            // Get the PK of the file
            for (let i=0; i<this.props.filePK.length; i++){
                const filePKObj = this.props.filePK[i];
                const fileID = filePKObj['id'];

                if (fileID === this.props.pkID){
                    pk = filePKObj['pk'];
                    break
                }
            }

            const props = this.props;

            // Close the Import Sheets Modal
            props.toggleModal(null);
            // Open up the import sheets loading modal
            props.toggleModal('loadingModalImportSheets');

            // Open up WebSockets
            // const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");

            let socketURL = "read-api-files"
            if (props.fileInformation[props.pkID]['inputType'] !== "file"){
                socketURL = "read-api-sheets-dropbox"
            }

            let filePK = [{'pk': pk, 'id': props.pkID}];

            const socketRequest = {
                filePK: filePK,
                sheetRead: this.props.copiedSheetRead,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                utcOffset: new Date().getTimezoneOffset(),
                importSheets: true
            }

            readFiles(socketRequest, filePK, this.props, socketURL, false, false)

            // Send the PKs
            // socket.onopen = (event) => {
            //     socket.send(JSON.stringify());
            // };

            // socket.onmessage = (event) => {
            //     const reply = JSON.parse(event.data);
            //     const status = reply['status'];
            //
            //     props.modifyStatus(status);
            //
            //     if (status === "read"){
            //         let filePKProBlock = reply['filePKProBlock'];
            //
            //         if (typeof props.filePKProBlock !== 'undefined') {
            //             if (!isObjectEmpty(props.filePKProBlock)) {
            //                 const copiedFilePKProBlock = JSON.parse(JSON.stringify(props.filePKProBlock));
            //                 copiedFilePKProBlock[props.pkID] = filePKProBlock[props.pkID];
            //
            //                 props.modifyFilePKProBlock(copiedFilePKProBlock);
            //             }
            //         }
            //     }
            //
            //     if (status === "api"){
            //         let fileInformation = reply.fileInformation;
            //         if (typeof props.fileInformation !== 'undefined'){
            //             if (Object.entries(props.fileInformation).length !== 0 && props.fileInformation.constructor === Object){
            //
            //                 const copiedFileInformation = JSON.parse(JSON.stringify(props.fileInformation));
            //                 copiedFileInformation[props.pkID] = fileInformation[props.pkID];
            //
            //                 props.modifyFileInformation(copiedFileInformation);
            //                 props.modifySheetRead(reply['sheetRead'])
            //             }
            //         }
            //
            //         socket.close();
            //         // Close the loading modal
            //         props.toggleModal(null);
            //     }
            //
            //     if (status === "error"){
            //         socket.close()
            //         props.toggleModal(null);
            //         const errorMessage = 'errorMessage' in reply ? reply['errorMessage'] : "There was an error changing your sheet 😞. Please try again. Contact our Support if this error persists."
            //         props.toggleToast({show: true, message: errorMessage, type: "error"})
            //     }

        }
    }

    render() {
        return (
            <Button disabled={this.props.btnDisabled}
                    style={{backgroundColor: "#6FBB98", color: "white"}}
                    icon="file alternate outline" content="Change Sheet" onClick={this.importSheets}/>
        )
    }
}

const mapStateToProps = (state) => ({
    finalColumnNames: state.mainState.finalColumnNames,
    finalColumnPK: state.mainState.finalColumnPK,
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    editID: state.mainState.editID,
    reduxEditOptions: state.mainState.editOptions,
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock
})

const mapActionsToProps = {
    modifyFilePK: modifyFilePK,
    modifyStatus: modifyStatus,
    modifyFinalColumnPK: modifyFinalColumnPK,
    modifyFinalColumnNames: modifyFinalColumnNames,
    modifyFileInformation: modifyFileInformation,
    modifyEditOptions: modifyEditOptions,
    modifySheetRead: modifySheetRead,
    toggleModal: toggleModal,
    modifyFilePKProBlock: modifyFilePKProBlock,
    toggleToast: toggleToast,
    modifyAPIMenuItem: modifyAPIMenuItem
}

export default connect(mapStateToProps, mapActionsToProps)(ImportSheetsButton)