import React, {Component} from 'react'
import { Button } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom';
import {gunaldiStyles} from "../GunaldiDesignStyles";

class NavbarSignUp extends Component {
    constructor(props) {
        super(props);

        this.signUp = this.signUp.bind(this);
    }

    signUp(e){
        this.signupLink.click()

        if ('toggleOpen' in this.props){
            this.props.toggleOpen(e)
        }
    }

    render() {
        return (
            <span>
                <a
                    style={{display: 'none'}}
                    ref={signupLink => this.signupLink = signupLink}
                    href={'importer' in this.props ? "/signup?importer" : "/signup"}
                >Download hidden</a>
                <Button id="navbarSignupButton"
                        onClick={this.signUp}
                        style={{
                            marginTop: '2px',
                            backgroundColor: 'importer' in this.props ? gunaldiStyles.orange : "#52bd95",
                            color: 'white'}}>
                    Sign Up
                </Button>
            </span>
        )
    }
}

export default withRouter(NavbarSignUp);