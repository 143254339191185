import React, {Component} from 'react'
import {getQueryParamValue} from "../Constants/getQueryParamValue";
import { auth } from './firebase';
import Loader from 'react-loader-spinner';
import { Message, Button, Icon } from 'semantic-ui-react';
import API_Root from "../Constants/API_Root";
import NewUserActionButtonsRow from "../InfoPages/Profile/NewUserActionButtonsRow"

class VerifyEmailIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVerifying: true,
            error: false
        }

    }

    componentDidMount(){
        const hitError = () => this.setState({ isVerifying: false, error: true })
        const code = getQueryParamValue("code", window.location.href);

        fetch(API_Root + "api/toggle-user-email-verification/", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify(
                {
                    email: localStorage.getItem("email"),
                    code: code
                }
            )
        }).then((res) => {
            if (res.status === 200){
                this.setState({ isVerifying: false, error: false})
            } else {
                hitError()
            }
        }).catch((err) => hitError())

        // this.setState({ error: false })
    }

    render() {
        let body = null;

        if (this.state.isVerifying){
            body =
                <Loader
                    type="TailSpin"
                    color="black"
                    height="50"
                    width="50"
                />
        } else {
            if (this.state.error){
                body = <Message error
                                fluid
                                header="There was an error verifying your email"
                                content={<span>Please try again or contact our support if this error persists.<br/><br/>You can still use your API account for 7 days without verifying your email</span>} />
            } else {
                // const aNoLinkStyle = {textDecoration: "none", color: "white"}
                body = <Message positive
                                fluid
                                header={<h2 style={{paddingBottom: "15px"}}>Congrats! Your email has been verified!</h2>}
                                content={<span>
                                    <NewUserActionButtonsRow/>
                                </span>}
                />
            }
        }

        return (
            <div style={{textAlign: "center", padding: "50px", minHeight: '100vh'}}>
                {body}
            </div>
        )
    }
}

export default VerifyEmailIndex