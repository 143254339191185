import React, {Component} from 'react'
import {connect} from 'react-redux'
import DocsImage from "../DocsImage";
import GoogleSheetsGuide from "../../../Guides/GuidesText/GoogleSheetsGuide"
import GoogleDriveImporterError from "./GoogleDriveImporterError";

class GoogleDrive extends Component {
    render() {
        const isImporter = 'importer' in this.props;
        const imgStyle = {border:"1px solid #F0F0F0", display: "block", marginBottom: "16px"}

        return (
            <div>
                {
                    isImporter ? <div className="docsSection">
                        <p className="docsHeader">Connect Google Account</p>
                        <p>1. Choose your Google Account</p>
                        <img
                            style={{width: "50%", ...imgStyle}}
                            src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-GoogleSheets/ChooseGoogleSheetsAccount1.png"
                            alt="Select Google Account"/>
                        <p>2. Make sure you give <b>ALL</b> the permissions below to API Spreadsheets otherwise we will not be able to connect</p>
                        <p>
                            <img
                                style={{width: "50%", ...imgStyle}}
                                src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/GoogleSheetsPermissionCheck.png"
                                alt="GoogleSheetsPermissions"/>
                        </p>
                    </div> : null
                }
                <div className="docsSection">
                    <p className="docsHeader">View your Google Accounts</p>
                    <p>You can view your connected Google Accounts from your Profile</p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Integrations/Profile-GoogleAccounts.png"
                        alt="Google Accounts on Profile"
                        width="100%"
                    />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Remove your Google Account from API Spreadsheets</p>
                    <p>To remove our connection from any Google Account, click on that account's <b>Remove Account from API Spreadsheets</b> button </p>
                    <p>This will show you a series of prompts to follow, and once you are done API Spreadsheets will not be able to connect to that Google Account. All APIs from that Google Account will stop working</p>
                    <p>Once again, taking the steps above will promptly cut off API Spreadsheets' access to your Google Account</p>
                    <p>But if you open your Google Account, you will see that API Spreadsheets still has permissions to your Google Account. Don't worry, the connection is fully removed from our end</p>
                    <p>You can go ahead and remove API Spreadsheets from your Google Account</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Remove API Spreadsheets from your Google Account</p>
                    <ol>
                        <li>Go to your <a href="https://myaccount.google.com/" target="_blank" rel="noopener noreferrer">Google Account</a></li>
                        <li>On the left navigation panel, select <b>Security</b></li>
                        <li>On the <i>Third-party apps with account access panel</i>, select <b>Manage third-party access</b> (you might have to scroll down a bit to see this panel)</li>
                        <li>Select <b>API Spreadsheets</b></li>
                        <li>Select Remove Access</li>
                        <li>Confirm, if needed</li>
                    </ol>
                </div>
                <div className="docsSection">
                    {
                        isImporter ? <GoogleDriveImporterError/> : <GoogleSheetsGuide/>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(GoogleDrive)