import React, {Component} from 'react'
import {connect} from 'react-redux'
import Images from "../../Constants/Images"
import SocialMediaButtons from "./SocialMediaButtons"
import { Icon } from 'semantic-ui-react'
import FooterItem from "./FooterItem"
import GuideDetails from "../../Guides/GuideDetails";
import {getHeroText} from "../../LandingPage/getHeroText";
import {gunaldiStyles} from "../GunaldiDesignStyles";

class FullFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: ''
        };

        this.goToURL = this.goToURL.bind(this);
    }

    goToURL(e, url){
        this.setState({
            url: url
        }, () => this.hiddenLink.click())
    }

    render() {
        const hiddenURL = this.state.url;
        const headerStyle = {fontFamily: "Lato", fontWeight: "700", fontSize: "16px", color: "#161E16", marginBottom: "12px"}

        return (
            <div className="fullFooter">
                <div style={{paddingBottom: "72px", borderBottom: "1px solid #F0F0F0"}}>
                    <a style={{display: 'none'}} ref={hiddenLink => this.hiddenLink = hiddenLink} href={hiddenURL} target="_blank" rel="noopener noreferrer">Download hidden</a>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", columnGap: "24px"}}>
                        <div>
                            <div style={{marginBottom: "24px"}}>
                                <img alt="API Spreadsheets Logo" src={Images.logoNav} style={{width: '80%'}} />
                            </div>
                            <div style={{fontFamily: "Lato", color: "#9696A0", fontWeight: "400", fontSize: "12px", marginBottom: "24px"}}>
                                <span>Developer Tools For <span style={{color: gunaldiStyles.mainGreen}}>Spreadsheets</span></span>
                            </div>
                            <br/>
                            <SocialMediaButtons/>
                        </div>
                        <div style={{paddingLeft: "32px"}}>
                            <div style={headerStyle}>Company</div>
                            <FooterItem to="https://lovespreadsheets.medium.com/" name="Blog" external/>
                            <FooterItem to="https://www.lovespreadsheets.com/" name="About" external/>
                            <FooterItem to="https://www.lovespreadsheets.com/contact/" name="Contact Us" external/>
                        </div>
                        <div style={{paddingLeft: "32px"}}>
                            <div style={headerStyle}>Product</div>
                            <FooterItem to="https://api-apispreadsheets-com.statuspal.io/" name="API Status" external />
                            <FooterItem to="tutorials" name="Use Cases" />
                            {/*<FooterItem to={"guides/" + GuideDetails[0].title} name="Guides" />*/}
                            {/*<FooterItem to="FAQs" name="FAQs" />*/}
                            <FooterItem to="api-docs" name="API Docs" />
                            <FooterItem to="import-docs" name="Importer Docs" />
                            <FooterItem to="datasets" name="Datasets" />
                        </div>
                        <div style={{paddingLeft: "32px"}}>
                            <div style={headerStyle}>Support</div>
                            <div style={{fontFamily: "Lato", fontWeight: "400", marginBottom: "12px"}}>
                                <div style={{color: "#9696A0", fontSize: "12px"}}>Hours</div>
                                <p style={{color: "#161E16", fontSize: "14px"}}>Monday-Friday 6AM to 6PM GMT</p>
                            </div>
                            <div style={{fontFamily: "Lato", fontWeight: "400", marginBottom: "12px"}}>
                                <div style={{color: "#9696A0", fontSize: "12px"}}>Tech Support</div>
                                <a href="mailto:support@apispreadsheets.com" style={{color: "#161E16", fontSize: "14px"}}>support@apispreadsheets.com</a>
                            </div>
                            <div style={{fontFamily: "Lato", fontWeight: "400", marginBottom: "12px"}}>
                                <div style={{color: "#9696A0", fontSize: "12px"}}>Sales & Billing</div>
                                <a href="mailto:sales@apispreadsheets.com" style={{color: "#161E16", fontSize: "14px"}}>sales@apispreadsheets.com</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{paddingTop: "16px"}}>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <div>
                            Made with <span role="img" aria-label="heart emoji">❤️</span> by Woyera Inc <span><Icon name="copyright outline"/> {((new Date()).getFullYear()).toString()}</span>
                        </div>
                        <div style={{textAlign: "right"}}>
                            <span><a href="/terms" target="_blank" rel="noopener noreferrer"> Terms &
                                Conditions</a> | <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy
                                Policy</a></span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FullFooter)