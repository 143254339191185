import React, {Component} from 'react'

class ColumnInfoSaveBullet extends Component {
    render() {

        return (
            <span className="columnInfoSaveBullet">For Row {(this.props.row + 1).toString()}, changing Column <span id={this.props.changeInfo.type + "ColDescSub"}>{{name: "Name", desc: "Description", type: "Type"}[this.props.changeInfo.type]}</span> from <b><i>{this.props.changeInfo.prevValue.trim() === "" ? "[blank]" : this.props.changeInfo.prevValue}</i></b> to <b>{this.props.changeInfo.currValue}</b></span>
        )
    }
}

export default ColumnInfoSaveBullet