import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'

class AccountRowDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isImageValid: false
        }

        this.checkIfImageIsValid()
    }

    checkIfImageIsValid = () => {
        if (this.props.accountType !== "s3"){
            if (this.props.profilePic !== null){
                const image = new Image();
                image.onload = () => {
                    if (image.width > 0) {
                        this.setState({ isImageValid: true })
                    }
                }
                image.onerror = () => {
                    this.setState({ isImageValid: false })
                }
                image.src = this.props.profilePic;
            } else {
                this.setState({ isImageValid: false })
            }
        }
    }

    getAccountDisplay = () => {
        if (this.props.isDatabase){
            if ("name" in this.props.info){
                return this.props.info.name
            } else{
                return this.props.email
            }
        } else {
            return this.props.email
        }
    }

    render() {
        return (
            <span>
                { this.state.isImageValid ? <img className="avatar"
                                                 style={'imgStyle' in this.props ? this.props.imgStyle : null}
                                                 src={this.props.profilePic}
                                                 alt="googleProfile"/> : <Icon name={this.props.isDatabase || this.props.accountType === "s3" ? "user circle" : this.props.accountType} />}
                <span style={{paddingLeft: "5px"}}>{this.getAccountDisplay()}</span>
            </span>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(AccountRowDisplay)