import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'
import SampleTable from "../../../../Guides/GuidesText/SampleTable"
import DocsBaseURL from "../../../DocsPage/DocsBaseURL"
import {generateRandomKey} from "../../../../Constants/generateRandomKey";
import {createFileInformation} from "../../../../Guides/GuidesText/Functions/createFileInformation";
import {createFullURLFromBase} from "../../../../Constants/createFullURLFromBase";
import CodeSnippet from "../../../../FileDnD/CodeSnippet";
import {createCodeSnippets} from "../../../../Guides/GuidesText/Functions/createCodeSnippets";

class DeleteRows extends Component {
    constructor(props){
        super(props);

        this.state = {
            isPrivate: false,
            sampleTableData: [
                [1, "Justin Trudeau", 252069.46, "Canada"],
                [2, "Jacinda Ardern", 339862.25, "New Zealand"],
                [3, "Boris Johnson", 187033.75, "UK"],
                [4, "Joko Widodo", 27200.8, "Indonesia"],
                [5, "Angela Merkel", 369727.4, "Germany"]
            ],
            deletedData: [
                [3, "Boris Johnson", 187033.75, "UK"],
                [4, "Joko Widodo", 27200.8, "Indonesia"],
            ],
            sampleTableHeaders: ["id", "name", "salary_$", "country"],
            sampleDataType: ["num", "str", "num", "str"],
            dataFormat: "row"
        }

        this.togglePrivate = this.togglePrivate.bind(this);
        this.modifyURLIfPrivate = this.modifyURLIfPrivate.bind(this);
        this.changeState = this.changeState.bind(this);
    }

    togglePrivate(e){
        this.setState({ isPrivate: !this.state.isPrivate })
    }

    changeState(name, value){
        this.setState({
            [name]: value
        })
    }

    modifyURLIfPrivate(url){
        if (this.state.isPrivate){
            const keyText = "accessKey=" + generateRandomKey(32) + "&secretKey=" + generateRandomKey(32)
            if (url.indexOf("?") === -1){
                url += "?" + keyText
            }
            else{
                url += "&" + keyText
            }
        }

        url += "/"

        return url
    }

    render() {
        // POST: Orange
        // GET: Yellow

        const deleteSomeRowsURL = this.modifyURLIfPrivate('data/812/?query=DELETEFROM812WHEREsalary_$>=250000');
        const sampleFileInformationSomeRows = createFileInformation(this.state.deletedData, this.state.sampleTableHeaders, this.state.sampleDataType, 812, "Sample Data - World Leaders", this.state.isPrivate, createFullURLFromBase(deleteSomeRowsURL))
        const someRowsCode = createCodeSnippets(sampleFileInformationSomeRows, "deleteAction", createFullURLFromBase(deleteSomeRowsURL));

        return (
            <div>
                <SampleTable
                    tableData={this.state.sampleTableData}
                    tableHeaders={this.state.sampleTableHeaders}
                    header={<span style={{color: 'green'}}>World Leaders</span>}
                />
                <br/>
                <h2>Make a GET request to the following URL</h2>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={deleteSomeRowsURL} />
                <CodeSnippet
                    code={someRowsCode}
                    height={100}
                />
                <br/>
                <SampleTable
                    tableData={this.state.deletedData}
                    tableHeaders={this.state.sampleTableHeaders}
                    header={<span style={{color: 'red'}}>World Leaders - After Rows Being Deleted</span>}
                />
            </div>
        )
    }
}

export default DeleteRows