import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Table, Button, Label } from 'semantic-ui-react'
import RemoveColumnRow from "./RemoveColumnRow"
import ColumnName from "./ColumnName";
import ColumnInternalName from "./ColumnInternalName";
import ColumnRequired from "./ColumnRequired";
import ColumnDataType from "./ColumnDataType";
import ColumnConvert from "./ColumnConvert";
import {toggleModal} from "../../../../Actions/ToggleModal";
import {modifyModalInfo} from "../../../../Actions/ModifyModalInfo";
import ColumnAdditionalInfoModalBody from "./ColumnAdditionalInfoModalBody"
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import ColumnRowSaveBtn from "./ColumnRowSaveBtn"
import {modifyImportColumnInformation} from "../../../../Actions/ModifyImportColumnInformation";
import ColumnRowHeader from "./ColumnRowHeader";
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";

class ColumnRow extends Component {
    changeState = (key, value, changeOriginal=false, save=false) => {
        let columnsAddlInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))
        columnsAddlInfo = this.updateKeyInColumnsList(columnsAddlInfo, key, value, true)
        this.props.modifyCustomImportSettings("columnsAddlInfo", columnsAddlInfo)

        if (changeOriginal){
            let columns = JSON.parse(JSON.stringify(this.props.customImportColumns))
            columns = this.updateKeyInColumnsList(columns, key, value)

            this.props.modifyCustomImportSettings("columns", columns)
        }
    }

    updateKeyInColumnsList = (columnsList, key, value, addlInfo=false) => {
        const columnsInfo = columnsList[this.props.idx]

        columnsInfo[key] = value

        if (addlInfo){
            if (key === "name"){
                columnsInfo['dupeCheckName'] = value
            }
        }

        columnsList[this.props.idx] = columnsInfo

        return columnsList
    }

    hasInfoChanged = () => {
        let hasChanged = false;

        if (this.props.idx < this.props.customImportColumnsAddlInfo.length){
            const savedInfo = this.props.customImportColumns[this.props.idx];
            const currInfo = this.props.customImportColumnsAddlInfo[this.props.idx];

            const allKeys = ["name", "description", "internalName", "tags", "dataType", "convert", "required", "cleaningFunctions"]
            const strNames = ["name", "description", "internalName", "tags"]

            for (let key in currInfo){
                if (allKeys.includes(key)){
                    if (key !== "cleaningFunctions"){
                        if (strNames.includes(key)){
                            if (currInfo[key].trim() !== savedInfo[key].trim()){
                                hasChanged = true;
                                break
                            }
                        } else {
                            if (currInfo[key] !== savedInfo[key]){
                                hasChanged = true;
                                break;
                            }
                        }
                    }
                }
            }

            if (!hasChanged){
                if (this.haveCleaningFunctionsChanged(savedInfo.cleaningFunctions, currInfo.cleaningFunctions)){
                    hasChanged = true
                }
            }
        }

        return hasChanged
    }

    haveCleaningFunctionsChanged = (originalFunctions, newFunctions) => {
        let hasChanged = false;

        if (Object.keys(newFunctions).length !== Object.keys(originalFunctions).length){
            hasChanged = true
        } else {
            if (Object.keys(newFunctions).length > 0){
                if (JSON.stringify(newFunctions) !== JSON.stringify(originalFunctions)){
                    hasChanged = true
                }
            }
        }

        return hasChanged
    }


    undo = () => {
        const savedInfo = this.props.customImportColumns[this.props.idx];
        let columnsAddlInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))
        const currInfo = columnsAddlInfo[this.props.idx];

        for (let key in savedInfo){
            currInfo[key] = savedInfo[key]
        }

        currInfo.dupeCheckName = currInfo.name

        columnsAddlInfo[this.props.idx] = currInfo

        this.props.modifyCustomImportSettings("columnsAddlInfo", columnsAddlInfo)
    }

    removeColumnRow = () => {
        const columns = JSON.parse(JSON.stringify(this.props.customImportColumns))
        const columnsAddlInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))

        columns.splice(this.props.idx, 1)
        columnsAddlInfo.splice(this.props.idx, 1)

        this.props.modifyImportColumnInformation(columns, columnsAddlInfo)
    }

    openAdditionalModal = () => {
        this.props.modifyModalInfo({ columnIdx: this.props.idx })
        this.props.toggleModal("columnAdditionalInfoModal")
    }


    render() {
        const checkBoxStyle = {textAlign: "center", marginTop: "14px"};

        const currInfo = this.props.customImportColumnsAddlInfo[this.props.idx];

        return (
            <div className="displayBox" style={{marginBottom: "16px"}}>
                <div style={{fontSize: "14px", fontWeight: "700", color: gunaldiStyles.mainText, marginBottom: "16px"}}>Column {(this.props.idx + 1).toString()}</div>
                <div style={{display: "grid", gridTemplateColumns: "4fr 4fr 2fr 3fr 2fr", columnGap: "8px", paddingBottom: "8px"}}>
                    <div>
                        <ColumnRowHeader header="Display Name" info="Name of the column that the user will see while matching"/>
                        <ColumnName
                            idx={this.props.idx}
                            name={currInfo.name}
                            columnNames={this.props.columnNames}
                            changeState={this.changeState}
                            internalNameEqual={currInfo.internalNameEqual}
                        />
                    </div>
                    <div>
                        <ColumnRowHeader header="Internal Name" info="Name of the column in the data after matching "/>
                        <ColumnInternalName
                            idx={this.props.idx}
                            displayName={currInfo.name}
                            name={currInfo.internalName}
                            columnNames={this.props.internalColumnNames}
                            changeState={this.changeState}
                            internalNameEqual={currInfo.internalNameEqual}
                        />
                    </div>
                    <div>
                        <ColumnRowHeader header="Required" info="User will not be able to finish uploading without mapping this column" center/>
                        <ColumnRequired
                            required={currInfo.required}
                            changeState={this.changeState}
                            checkBoxStyle={checkBoxStyle}
                        />
                    </div>
                    <div>
                        <ColumnRowHeader header="Type"/>
                        <ColumnDataType
                            dataType={currInfo.dataType}
                            changeState={this.changeState}
                        />
                    </div>
                    <div>
                        <ColumnRowHeader header="Convert" info={<span>Column values will be forcibly converted to chosen type. Values that fail conversion will be <code>null</code></span>} center/>
                        <ColumnConvert
                            convert={currInfo.convert}
                            changeState={this.changeState}
                            checkBoxStyle={checkBoxStyle}
                        />
                    </div>
                </div>
                <div>
                    <ColumnAdditionalInfoModalBody idx={this.props.idx}/>
                </div>
                <div style={{paddingTop: "8px", borderTop: "1px solid #F0F0F0"}}>
                    <div style={{display: "grid", gridTemplateColumns: "30% 70%"}}>
                        <div>
                            {
                                !currInfo.saved || this.hasInfoChanged() ?
                                    <div style={{marginTop: "4px"}}><span style={{border: "1px solid " + gunaldiStyles.red, borderRadius: "4px", padding: "8px", color: gunaldiStyles.red, fontSize: "10px", fontWeight: "700"}}>Unsaved</span></div> :
                                    null
                            }
                        </div>
                        <div style={{textAlign: "right"}}>
                            <ColumnRowSaveBtn
                                hasInfoChanged={this.hasInfoChanged()}
                                idx={this.props.idx}
                                changeState={this.changeState}
                                error={currInfo.error}
                            />
                            <Button
                                // circular
                                onClick={this.undo}
                                disabled={!(currInfo.saved && this.hasInfoChanged())}
                                icon="undo"
                                content="Undo"
                                style={{backgroundColor: gunaldiStyles.subText, color: "white"}}
                                // color="orange"
                                // basic
                                size="mini"
                            />
                            <RemoveColumnRow
                                idx={this.props.idx}
                                removeColumnRow={this.removeColumnRow}
                                saved={currInfo.saved}
                            />
                        </div>
                    </div>
                </div>
                {/*<Table.Cell>*/}
                    {/*<div style={{textAlign: "center"}}>*/}
                        {/*<Button circular*/}
                                {/*onClick={this.openAdditionalModal}*/}
                                {/*icon="edit alternate outline"*/}
                                {/*color="blue"*/}
                                {/*basic*/}
                                {/*size="mini"/>*/}
                    {/*</div>*/}
                    {/*<ColumnAdditionalInfoModal />*/}
                {/*</Table.Cell>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    customImportColumnsAddlInfo: state.mainState.customImportColumnsAddlInfo,
    customImportColumns: state.mainState.customImportColumns
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo,
    modifyCustomImportSettings: modifyCustomImportSettings,
    modifyImportColumnInformation: modifyImportColumnInformation
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnRow)