import React, {Component} from 'react'
import ModalApp from '../SharedComponents/Modal'
import DatabaseConnectionErrorBody from "./DatabaseConnectionErrorBody";

class DatabaseConnectionErrorModal extends Component {
    render() {
        return (
            <div>
                <ModalApp name="databaseConnectionErrorModal"
                          header="Troubleshoot Database Connection"
                          size="medium"
                          body={<DatabaseConnectionErrorBody/>}
                />
            </div>
        )
    }
}

export default DatabaseConnectionErrorModal