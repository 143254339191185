export const sortArrayOfObjects = (arrayToSort, keyToSort, reverse=false) => {
    const arr = JSON.parse(JSON.stringify(arrayToSort))
    const n = arr.length;
    for (let i=0; i<n; i++){

        for (let j=0; j<n-i-1; j++){

            if (!reverse){
                if (arr[j][keyToSort] > arr[j+1][keyToSort]){
                    const j1 = arr[j+1]
                    const j0 = arr[j];

                    arr[j] = j1
                    arr[j+1] = j0
                }
            }
            else{
                if (arr[j][keyToSort] < arr[j+1][keyToSort]){
                    const j1 = arr[j+1]
                    const j0 = arr[j];

                    arr[j] = j1
                    arr[j+1] = j0
                }
            }

        }
    }

    return arr
}