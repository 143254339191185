import React, {Component} from 'react'
import { Divider } from 'semantic-ui-react'
import {connect} from "react-redux";
import {toggleModal} from "../../../../Actions/ToggleModal";

class DocsPostBodyParameters extends Component {
    constructor(props){
        super(props)

        this.openSQLModal = this.openSQLModal.bind(this);
    }

    openSQLModal(){
        this.props.toggleModal("queryModal")
    }

    render() {
        const dataUpdate = "{\n\tcolumn_1: value1,\n\tcolumn_2: value2\n}"
        return (
            <div>
                <h5 id="parameterHeading">Request Body Parameters</h5>
                <Divider/>
                <span className="parameterName">data</span> <span className="parameterType">JSON Object</span>
                <span className="parameterSecondLine"><span className="parameterRequired">Required</span> </span>
                <span className="parameterDesc">The data you want to update in the following format:
                    <br/>
                    <code>
                        {dataUpdate}
                    </code>
                    <br/>
                    <b>See Example to the right</b>
                </span>
                <Divider/>
                <span className="parameterName">query</span> <span className="parameterType">string</span>
                <span className="parameterSecondLine"><span className="parameterRequired">Required</span> </span>
                <span className="parameterDesc">SQL style query to update the rows that you want. <br/><span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-query")}>Read a full overview on how to write a query</span></span>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps,mapActionsToProps)(DocsPostBodyParameters)