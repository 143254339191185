import React, {Component} from 'react'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import { Icon } from 'semantic-ui-react'
import UtilitiesPrivateButton from "./UtilitiesPrivateButton"
import KeysIndex from "./KeysIndex";
import SettingsWhitelistDomains from "./SettingsWhitelistDomains"

class SettingsIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const headerStyle = { fontSize: "16px", fontWeight: "700", color: gunaldiStyles.subText, marginBottom: "24px"}
        console.log(this.props.settings)

        return (
            <div className="row">
                <div className="col-sm-6">
                    <div style={{
                        borderRadius: '4px',
                        border: "1px solid #F0F0F0",
                        backgroundColor: 'white',
                        padding: "12px"
                    }}>
                        <div style={headerStyle}>
                            <Icon name="lock" /> <span>Utility APIs Private</span>
                        </div>
                        <div style={{ marginBottom: "24px"}}>
                            <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "16px"}}>
                                <div>
                                    <span style={{fontSize: "16px", fontFamily: "Lato", fontWeight: "400", color: gunaldiStyles.subText}}>Utility APIs Private?</span>
                                </div>
                                <div style={{ textAlign: "right"}}>
                                    <UtilitiesPrivateButton
                                        pk={this.props.settings.pk}
                                        isPrivate={this.props.settings.isPrivate}
                                        changeState={this.props.changeState}/>
                                </div>
                            </div>
                        </div>
                        {
                            this.props.settings.isPrivate ?
                                <div style={{ marginBottom: "24px"}}>
                                    <KeysIndex
                                        keys={this.props.settings.keys}
                                        changeState={this.props.changeState}
                                        pk={this.props.settings.pk}
                                    />
                                </div> : null
                        }
                    </div>
                </div>
                <div className="col-sm-6">
                    <div style={{
                        borderRadius: '4px',
                        border: "1px solid #F0F0F0",
                        backgroundColor: 'white',
                        padding: "12px"
                    }}>
                        <div style={headerStyle}>
                            <Icon name="world" /> <span>Whitelist URLs</span>
                        </div>
                        <div style={{ marginBottom: "24px"}}>
                            <SettingsWhitelistDomains
                                whitelistDomains={this.props.settings.whitelistDomains}
                                changeState={this.props.changeState}
                                pk={this.props.settings.pk}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SettingsIndex