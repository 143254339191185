export const isURLComponentValid = (urlComponent) => {
    let isValid = true;
    let testStr = urlComponent.trim();

    if (testStr === ""){
        isValid = false
    } else{
        if (testStr.length === 1){
            // does contain things other than letters
            if(/[^a-z]/i.test(testStr)){
                isValid = false
            }
        } else{
            const startLetter = testStr.charAt(0)
            const endLetter = testStr.charAt(testStr.length)

            const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

            // starts or ends with special characters
            if (specialChars.test(startLetter) || specialChars.test(endLetter)){
                isValid = false
            } else {
                if (!/^[a-zA-Z0-9-]+$/.test(testStr)){
                    isValid = false
                }
            }
        }
    }

    return isValid
}