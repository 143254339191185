export const getSupportMessage = (plan) => {
    return {
        timeResponse: timeResponseMap[plan],
        supportHours: supportHoursMessage,
        utcTimeResponse: plan === "free" ? "when there is time" : getResponseTime(plan, true)
    }
}

let startTime = new Date("2022-01-01T06:00:00.000Z")
let endTime = new Date("2022-01-01T18:00:00.000Z")

const getResponseTime = (plan, utc=false) => {
    let currDate = new Date();

    const hour = currDate.getUTCHours()
    const day = currDate.getUTCDay();

    let endDay = 0;
    let timeHours = 23;

    if (plan === "pro" || plan === "team"){
        if (day === 6){
            // saturday
            endDay = 3
        } else if (day === 5 || day === 4){
            // thursday, friday
            endDay = 4
        } else {
            // sunday, monday, tuesday, wednesday
            endDay = 2
        }

        timeHours = startTime.getHours()
    } else if (plan === "business") {
        if (hour > (endTime.getHours() - 2)){
            if ([0, 1, 2, 3, 4].includes(day)){
                endDay = 1
            } else if (day === 5){
                endDay = 3
            } else if (day === 6){
                endDay = 2
            }
        } else {
            if ([1, 2, 3, 4, 5].includes(day)){
                endDay = 0
            } else if (day === 0){
                endDay = 1
            } else if (day === 6){
                endDay = 2
            }
        }

        timeHours = endTime.getHours()
    }

    currDate.setDate(currDate.getDate() + endDay)
    currDate.setHours(timeHours)
    currDate.setMinutes(0)
    currDate.setSeconds(0)

    if (utc){
        return currDate.toUTCString()
    } else {
        return currDate.toLocaleString()
    }
}

const replaceSeconds = (timeStr) => {
    try{
        if (timeStr.includes(":00:00")){
            timeStr = timeStr.replace(":00", "")
        }

        return timeStr
    } catch (e) {
        return timeStr
    }
}

let startTimeStr = replaceSeconds(startTime.toLocaleTimeString())
let endTimeStr = replaceSeconds(endTime.toLocaleTimeString())

const timeResponseMap = {
    "free": "Your plan type does not guarantee a response from us. Still, depending on our availability we often respond to tickets that are clear and actionable",
    "pro": "For your plan support type, we will respond to you within 2 business days by: " + getResponseTime("pro"),
    "team": "For your plan support type, we will respond to you within 2 business days by: " + getResponseTime("team"),
    "business": "For your plan support type, we will respond to you within 1 business day by: " + getResponseTime("business"),
    "enterprise": ""
}

const supportHoursMessage = "Our support hours are 6AM - 6PM GMT Monday to Friday. In your timezone this is " + startTimeStr + " to " + endTimeStr