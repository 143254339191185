import React, {Component} from 'react'
import {connect} from 'react-redux'
import TeamFileModalBody from "./TeamFileModalBody"
import Loader from 'react-loader-spinner'
import {modifyTeamInfo} from "../../Actions/ModifyTeamInfo";
import {modifyPendingInvites} from "../../Actions/ModifyPendingInvites";
import API_Root from "../../Constants/API_Root";
import { Message } from 'semantic-ui-react'

class TeamFileModalBodyIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: true,
            error: null
        }
    }

    componentDidMount(){
        let tableType = "all";

        if ("tableType" in this.props.modalInfo){
            tableType = this.props.modalInfo.tableType;
        }

        if (tableType === "all"){
            this.setState({ fetching: false })
        } else {
            this.getTeamInfo()
        }
    }

    getTeamInfo = () => {
        const isError = () => {
            this.setState({
                error: <Message error header="There was an error getting your team information"
                                content="Please refresh and try again. If the error persists, contact our support"
                                icon="refresh" fluid />,
                fetching: false
            })
        }

        fetch(API_Root + "api/get-api-team-information/", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then(data => {
                    this.props.modifyTeamInfo(data['teamInfo'])
                    this.props.modifyPendingInvites(data['pendingInvites'])

                    this.setState({
                        error: null,
                        fetching: false
                    })

                }).catch( err => isError())
            } else {
                isError()
            }
        }).catch((err) => {
            isError()
        })
    }

    render() {
        let body;

        if (this.state.fetching){
            body = <div>
                <Loader
                    type="TailSpin"
                    color="black"
                    height="50"
                    width="50"
                />
            </div>
        } else {
            if (this.state.error === null){
                body = <div>
                    <TeamFileModalBody pk={this.props.pk}
                                       fileHash={this.props.fileHash}
                                       pkID={this.props.pkID}/>
                </div>
            } else {
                body = this.state.error
            }
        }

        return (
            <div>
                {body}
            </div>
        )

    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {
    modifyTeamInfo: modifyTeamInfo,
    modifyPendingInvites: modifyPendingInvites
}

export default connect(mapStateToProps, mapActionsToProps)(TeamFileModalBodyIndex)