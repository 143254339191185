import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Table, Dropdown } from 'semantic-ui-react'
import {isValueDupeInArray} from "./isValueDupeInArray";

class ColumnMatchRow extends Component {
    changeColumnMap = (event, { value }) => {
        this.props.changeColumnMap(this.props.idx, value)
    }

    render() {
        return (
            <Table.Row>
                <Table.HeaderCell>{this.props.info}</Table.HeaderCell>
                <Table.Cell>
                    <Dropdown options={this.props.dropdownOptions}
                              onChange={this.changeColumnMap}
                              selection
                              search
                              value={this.props.matchedColumn}
                              fluid />
                    {
                        this.props.matchedColumn !== null && isValueDupeInArray(this.props.matchedColumn, this.props.allMatchedColumns) ?
                        <small style={{color: 'red'}}>You can only map 1 column with the name</small> : null
                    }
                </Table.Cell>
                {/*<Table.Cell>{this.props.info.dataType}</Table.Cell>*/}
                {/*<Table.Cell>{this.props.info.description}</Table.Cell>*/}
            </Table.Row>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ColumnMatchRow)