import React, {Component} from 'react'
import { Divider, Popup } from 'semantic-ui-react'

class DocsGetURLParametersOld extends Component {
    render() {
        return (
            <div>
                <div>
                    <h5 id="parameterHeading">URL Parameters</h5>
                    <Divider/>
                    <span className="parameterName">fileID</span> <span className="parameterType">integer</span>
                    <span className="parameterSecondLine"><span className="parameterRequired">Required</span> </span>
                    <span className="parameterDesc">This is the id of the spreadsheet imported through the web app. It can be found
                        <Popup content={<img src="https://images2.imgbox.com/62/6d/N2t6SsyE_o.png" alt="File ID"/>} trigger={<span className="linkStyle"> here</span>} />
                    </span>
                    <Divider/>
                    <span className="parameterName">dataFormat</span> <span className="parameterType">string</span>
                    <span className="parameterSecondLine"><span className="parameterOptional">Optional</span> <span className="parameterDefault">default=jsonRow</span></span>
                    <span className="parameterDesc">The format of the output data you want, can be one of the following <code>jsonRow, matrix, jsonColumn</code>. If this parameter is not specified or is not in the aforementioned list, then the jsonRow format is returned.</span>
                </div>
            </div>
        )
    }
}

export default DocsGetURLParametersOld