import React, {Component} from 'react'
import { Message } from 'semantic-ui-react'
import { connect } from 'react-redux'

class TestQueryTable extends Component {
    render() {
        let show = true;

        if (show){
            if (!this.props.info.testError){
                if (this.props.info.testTableColumns.length > 0){
                    const columnNames = this.props.info.testTableColumns.map((x, idx) => <th scope="col" key={idx}>{x}</th>)

                    let tableMessage = "Your table was empty"
                    let tbody = null;

                    if (this.props.info.testTableValues.length > 0){
                        tableMessage = "Showing the first " + this.props.info.testTableValues.length.toString() + " row"  + (this.props.info.testTableValues.length === 1 ? "" : "s")
                        let tableRows = [];

                        for (let i=0; i<this.props.info.testTableValues.length; i++){
                            const columnRow = this.props.info.testTableValues[i];
                            let tableCells = [];

                            for (let j=0; j<this.props.info.testTableColumns.length; j++){
                                let cellValue = columnRow[this.props.info.testTableColumns[j]];
                                tableCells.push(<td key={i.toString() + j.toString()}>{typeof cellValue === "string" ? cellValue : JSON.stringify(cellValue)}</td>)
                            }

                            tableRows.push(<tr key={i.toString()}>{tableCells}</tr>)
                        }

                        tbody = <tbody>{tableRows}</tbody>

                    }

                    return(
                        <div>
                            <h4 style={{color: 'green', fontWeight: 'bold'}}>{tableMessage}</h4>
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped table-sm">
                                    <thead className="thead-dark">
                                    <tr>
                                        {columnNames}
                                    </tr>
                                    </thead>
                                    {tbody}
                                </table>
                            </div>
                        </div>
                    )
                } else{
                    return(
                        null
                    )
                }
            } else{
                return(
                    <div>
                        <Message
                            header={this.props.info.testErrorMessage.header}
                            content={this.props.info.testErrorMessage.content}
                            icon="database"
                            negative
                        />
                    </div>
                )
            }
        } else {
            return (
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, null)(TestQueryTable)