import React, {Component} from 'react'
import {connect} from 'react-redux'
import ReactJson from 'react-json-view'
import RegenerateKeysSegmentButton from "./RegenerateKeysSegmentButton"

class KeysSegment extends Component {
    updateKeyPairs = (newKeyPair) => {
        this.props.updateKeyPairs(this.props.idx, newKeyPair)
    }

    render() {
        const keys = {
            accessKey: this.props.fileAccessKey,
            secretKey: this.props.fileSecretKey
        }

        // const keySegmentStyle = {border: "1px solid lightgrey", borderRadius: "10px", padding: "10px", marginBottom: "30px", boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)'}
        const keySegmentStyle = {border: "1px solid #F0F0F0", borderRadius: '4px', padding: "16px"}
        const headerStyle = {color: "#161E16", fontFamily: "Lato", fontWeight: "700", fontSize: "14px", marginBottom: "16px"}

        return (
            <div style={keySegmentStyle}>
                <div style={headerStyle}>Key Pair {(this.props.idx + 2).toString()}</div>
                <ReactJson src={keys} name={null} theme="bright:inverted" displayObjectSize={false}/>
                <div style={{marginTop: "16px"}}>
                    <RegenerateKeysSegmentButton pairNum={(this.props.idx + 2).toString()} keysPK={this.props.keysPK} updateKeyPairs={this.updateKeyPairs} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(KeysSegment)