import React, {Component} from 'react'
import {connect} from 'react-redux'
import UpgradeToTeam from "../UpgradeToTeam"
import FetchLoader from "../FetchLoader"
import { Checkbox, Icon, Input, Button } from 'semantic-ui-react'
import {capitalize} from "../../Constants/capitalize";
import {isEmailValid} from "../../Constants/isEmailValid";
import InfoPopup from "../../SharedComponents/InfoPopup"
import AlertsMessage from "./AlertsMessage"
import API_Root from "../../Constants/API_Root";
import {mapPkAndPkid} from "../../Constants/mapPkAndPkid";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

class AlertsModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            success: {
                onCreate: false,
                onRead: false,
                onUpdate: false,
                onDelete: false
            },
            error: {
                onCreate: false,
                onRead: false,
                onUpdate: false,
                onDelete: false
            },
            fetching: false,
            email: localStorage.getItem("email"),
            history: {
                success: {
                    onCreate: false,
                    onRead: false,
                    onUpdate: false,
                    onDelete: false
                },
                error: {
                    onCreate: false,
                    onRead: false,
                    onUpdate: false,
                    onDelete: false
                },
                email: localStorage.getItem("email"),
            },
            creatingAlerts: false,
            creatingError: null
        }
    }

    componentDidMount(){
        this.setState({ fetching: true })
        const stopFetch = () => this.setState({ fetching: false })

        fetch(API_Root + "api/set-get-alerts-api-files/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                get: true,
                filePK: mapPkAndPkid(this.props.filePK, "id", this.props.editID)
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    const copiedState = JSON.parse(JSON.stringify(this.state))

                    if ("success" in data){ copiedState["success"] = data.success; copiedState['history']['success'] = data.success;}
                    if ("error" in data){ copiedState["error"] = data.error; copiedState['history']['error'] = data.error; }
                    if ("email" in data){ copiedState["email"] = data.email; copiedState['history']['email'] = data.email; }

                    this.setState(copiedState)
                    stopFetch()
                }).catch(err => stopFetch())
            } else { stopFetch() }
        }).catch(err => stopFetch())
    }

    setUpAlerts = () => {
        this.setState({ creatingAlerts: true })
        const stopFetch = (error, header) => this.setState({ creatingAlerts: false, creatingError: <AlertsMessage error={error} header={header}/> })

        fetch(API_Root + "api/set-get-alerts-api-files/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                alertEmail: this.state.email,
                successRequests: this.state.success,
                errorRequests: this.state.error,
                filePK: mapPkAndPkid(this.props.filePK, "id", this.props.editID)
            })
        }).then((res) => {
            if (res.status === 201){
                stopFetch(false, "Your alerts were set up successfully!")
                this.setState({history: { success: this.state.success, error: this.state.error, email: this.state.email }})
            }
            else{
                stopFetch(true, "There was an error. Please try again or contact us at info@apispreadsheets.com if the error persists")
            }
        }).catch(err => stopFetch(true, "There was an error. Please try again or contact us at info@apispreadsheets.com if the error persists"))

    }

    alertRequestType = (e, requestType, alertType) => {
        const blockStateCopy = JSON.parse(JSON.stringify(this.state[alertType]))
        const blockState = blockStateCopy["on" + capitalize(requestType)]

        blockStateCopy["on" + capitalize(requestType)] = !blockState

        this.setState({
            [alertType]: blockStateCopy
        })
    }

    getDivStyle = (isTeam) => {
        let style = {marginTop: "16px"}

        if (!isTeam){
            style['pointerEvents'] = "none"
            style['opacity'] = "0.5"
            // style['cursor'] = "not-allowed"
        }

        return style
    }

    hasStateBeenChanged = () => {
        let changed = false;

        let successRequestsChanged = this.getRequestListThatHasChanged("success");
        let errorRequestsChanged = this.getRequestListThatHasChanged("error");

        if (this.state.email.trim() !== this.state.history.email.trim() || successRequestsChanged.length > 0 || errorRequestsChanged.length > 0){
            changed = true;
        }

        return changed
    }

    reset = () => {
        const copiedHistory = JSON.parse(JSON.stringify(this.state.history))
        const copiedSuccess = JSON.parse(JSON.stringify(this.state.success))
        const copiedError = JSON.parse(JSON.stringify(this.state.error))

        for (let requestType in copiedHistory['success']){
            copiedSuccess[requestType] = copiedHistory['success'][requestType]
        }

        for (let requestType in copiedHistory['error']){
            copiedError[requestType] = copiedHistory['error'][requestType]
        }

        const copiedEmail = this.state.history.email;

        this.setState({
            success: copiedSuccess,
            error: copiedError,
            email: copiedEmail
        })

    }

    handleEmailChange = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    getRequestListThatHasChanged = (alertType) => {
        let requestListThatHasChanged = [];
        for (let requestType in this.state.history[alertType]){
            if (this.state[alertType][requestType] !== this.state.history[alertType][requestType]){
                requestListThatHasChanged.push(requestType)
            }
        }

        return requestListThatHasChanged
    }


    render() {
        const isTeam = this.props.tier === "team" || this.props.tier === "business" || this.props.tier === "enterprise";

        return (
            <div>
                {isTeam ? null : <div style={{marginTop: "8px"}}><UpgradeToTeam higherTier="alerts" header="You can only set up Success/Error Alerts on a Team or Higher Tier Plan"/></div>}
                <div style={isTeam ? null : {"cursor": "not-allowed"}}>
                    <div style={this.getDivStyle(isTeam)}>
                        <div style={{display: "grid", gridTemplateColumns: "50% 50%", columnGap: "16px"}}>
                            <div style={{border: "1px solid #F0F0F0", borderRadius: "4px", padding: "16px"}}>
                                <div style={{color: "#EE685F", fontFamily: "Lato", fontSize: "14px", fontWeight: "500", marginBottom: "10px"}}>Error Alerts<span style={{fontSize: "12px", marginTop: "-5px"}}> <InfoPopup style={{cursor: 'pointer', fontSize: "12px"}} info={<span>Response codes: <code>400, 401, 402, 404, 405, 406, 500, 502</code></span>} /></span></div>
                                <div className="blockRequestCheckbox">
                                    <FetchLoader fetching={this.state.fetching}/><Checkbox toggle label="Create" onChange={e => this.alertRequestType(e, "create", "error")} checked={this.state.error.onCreate}/>
                                </div>
                                <div className="blockRequestCheckbox">
                                    <FetchLoader fetching={this.state.fetching}/><Checkbox toggle label="Read" onChange={e => this.alertRequestType(e, "read", "error")} checked={this.state.error.onRead}/>
                                </div>
                                <div className="blockRequestCheckbox">
                                    <FetchLoader fetching={this.state.fetching}/><Checkbox toggle label="Update" onChange={e => this.alertRequestType(e, "update", "error")} checked={this.state.error.onUpdate}/>
                                </div>
                                <div className="blockRequestCheckbox">
                                    <FetchLoader fetching={this.state.fetching}/><Checkbox toggle label="Delete" onChange={e => this.alertRequestType(e, "delete", "error")} checked={this.state.error.onDelete}/>
                                </div>
                            </div>
                            <div style={{border: "1px solid #F0F0F0", borderRadius: "4px", padding: "16px", marginRight: "16px"}}>
                                <div style={{color: '#6FBB98', fontFamily: "Lato", fontSize: "14px", fontWeight: "500", marginBottom: "10px"}}>Success Alerts <span style={{fontSize: "12px"}}> <InfoPopup style={{cursor: 'pointer', fontSize: "12px"}} info={<span>Response codes: <code>200, 201</code></span>} /></span></div>
                                <div className="blockRequestCheckbox">
                                    <FetchLoader fetching={this.state.fetching}/><Checkbox toggle label="Create" onChange={e => this.alertRequestType(e, "create", "success")} checked={this.state.success.onCreate}/>
                                </div>
                                <div className="blockRequestCheckbox">
                                    <FetchLoader fetching={this.state.fetching}/><Checkbox toggle label="Read" onChange={e => this.alertRequestType(e, "read", "success")} checked={this.state.success.onRead}/>
                                </div>
                                <div className="blockRequestCheckbox">
                                    <FetchLoader fetching={this.state.fetching}/><Checkbox toggle label="Update" onChange={e => this.alertRequestType(e, "update", "success")} checked={this.state.success.onUpdate}/>
                                </div>
                                <div className="blockRequestCheckbox">
                                    <FetchLoader fetching={this.state.fetching}/><Checkbox toggle label="Delete" onChange={e => this.alertRequestType(e, "delete", "success")} checked={this.state.success.onDelete}/>
                                </div>
                            </div>
                        </div>
                        {this.hasStateBeenChanged() ? <span style={{color: 'red', cursor: "pointer", fontSize: "10px", textAlign: 'right'}} onClick={this.reset}><Icon name="undo"/> Reset</span> : null}
                        <div className="row" style={{marginTop: "16px"}}>
                            <div className="col-sm-6">
                                <span style={{fontFamily: "Lato", fontSize: "16px", fontWeight: "700", color: "#161E16"}}>Enter Email to Receive Alerts</span>
                                <Input value={this.state.email} icon="at" onChange={this.handleEmailChange} fluid />
                                {isEmailValid(this.state.email.trim()) ? null : <small style={{color: 'red'}}>Enter a Valid Email</small>}
                            </div>
                            <div className="col-sm-6">
                                {/*{successReqList.length > 0 ? <span></span>}*/}
                            </div>
                        </div>
                        <div style={{marginTop: "16px"}}>
                            <Button
                                style={gunaldiStyles.btnGreen}
                                    icon="paper plane outline"
                                    content="Set Up Email Alerts"
                                    onClick={this.setUpAlerts}
                                    loading={this.state.creatingAlerts}
                                    disabled={!this.hasStateBeenChanged() || !isEmailValid(this.state.email.trim())} />
                        </div>
                        {this.state.creatingError}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier,
    filePK: state.mainState.filePK,
    editID: state.mainState.editID
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(AlertsModalBody)