import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyTeamInfo} from "../../Actions/ModifyTeamInfo";
import { Button, Icon, Message, Input, Dropdown, Popup } from 'semantic-ui-react'
import {initInvitesUsed} from "../initInvitesUsed";
import teamMemberMax from "../../Constants/teamMemberMax";
import UpgradeButton from "../../InfoPages/Profile/UpgradeButton"
import {isEmailValid} from "../../Constants/isEmailValid";
import RoleTag from "../Roles/RoleTag"
import UpdateTeamMemberInfo from "./UpdateTeamMemberInfo"
import InviteSentRow from "./InviteSentRow"
import API_Root from "../../Constants/API_Root";
import DisplayHeader from "../../FileDnD/FilesID/DisplayHeader";

const options = [{key: 1, value: "manager", text: "Manager"},  {key: 2, value: "developer", text: "Developer"}]

class PendingInvitesTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            teamMembers: this.props.teamInfo[this.props.teamIdx].all_users,
            teamInvites: [],
            isAdmin: this.props.teamInfo[this.props.teamIdx].admin_email === localStorage.getItem("email"),
            errorMessage: null,
            sendingInvite: false,
            updatedValues: {},
        }
    }

    dismissMessage = () => {
        this.setState({ errorMessage: null })
    }

    addOrRemoveInvite = (inviteIdx, add) => {
        const copiedTeamInvites = JSON.parse(JSON.stringify(this.state.teamInvites))

        let usedTeamInvites = initInvitesUsed(this.props.teamInfo, localStorage.getItem("email"));
        let tier = this.props.tier;

        if (add){
            if (this.state.teamInvites.length + usedTeamInvites >= teamMemberMax[tier]){
                this.setState({
                    errorMessage:
                        <Message error
                                 header={<span>You can only invite a maximum of {teamMemberMax[tier].toString()} people TOTAL under your account plan</span>}
                                 content={<span>Please <UpgradeButton /> if you want to invite more team members</span>}
                                 onDismiss={this.dismissMessage}
                        />
                })
            } else {
                this.setState({ errorMessage: null})
                copiedTeamInvites.push({ email: "", role: ""})
            }
        } else {
            this.setState({ errorMessage: null})
            copiedTeamInvites.splice(inviteIdx, 1)
        }

        this.setState({ teamInvites: copiedTeamInvites })
    }

    handleChange = (e) => {
        const inviteIdx = this.getInviteIdx(e.target.name);
        const copiedInvites = JSON.parse(JSON.stringify(this.state.teamInvites))
        const inviteObj = copiedInvites[inviteIdx]
        inviteObj.email = e.target.value;
        copiedInvites[inviteIdx] = inviteObj

        this.setState({ teamInvites: copiedInvites})
    }

    inputEmailError = (email) => {
        const errorStyle = {color: 'red'}
        if (!isEmailValid(email.trim())){
            return <small style={errorStyle}>Your email format is not valid</small>
        } else if (email.trim() in this.props.teamInfo[this.props.teamIdx].all_users){
            return <small style={errorStyle}>Email already exists on team</small>
        } else {
            return null
        }
    }

    roleSelectError = (role) => {
        if (role.trim() === ''){
            return <small style={{color: 'red'}}>Select a role</small>
        } else {
            return null
        }
    }

    handleInviteRoleChange = (e, data) => {
        const inviteIdx = this.getInviteIdx(data.name)
        const copiedInvites = JSON.parse(JSON.stringify(this.state.teamInvites))
        const inviteObj = copiedInvites[inviteIdx]
        inviteObj.role = data.value;
        copiedInvites[inviteIdx] = inviteObj

        this.setState({ teamInvites: copiedInvites})
    }

    getInviteIdx = (name) => {
        return parseInt(name.substring(name.indexOf("_") + 1, name.length))
    }

    handleCurrentRoleChange = (e, data) => {
        const value = data.value;
        const email = data.name;

        const currUpdatedValues = JSON.parse(JSON.stringify(this.state.updatedValues));
        currUpdatedValues[email] = value;

        this.setState({
            updatedValues: currUpdatedValues
        })
    }

    inviteNewTeamMember = (e, idx) => {
        const errorFetch = () => this.setState({ sendingInvite: false }, () => alert("There was an error sending your invite. Please try again!"))

        // const current = new Date();
        // const utcOffset = current.getTimezoneOffset();

        this.setState({ sendingInvite: true })

        fetch(API_Root + "api/invite-new-api-team-member/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                // utcOffset: utcOffset,
                teamPK: this.props.teamInfo[this.props.teamIdx].id,
                inviteInfo: [this.state.teamInvites[idx]]
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    // this.setState({ sendingInvite: false }, () => {this.addOrRemoveInvite(idx, false); this.updateNewTeamInfo(data.teamInfo)})
                    this.setState({ sendingInvite: false }, () => {this.updateNewTeamInfo(data.teamInfo); this.addOrRemoveInvite(idx, false)})
                }).catch(err => errorFetch())
            } else {
                errorFetch()
            }
        }).catch(err => errorFetch())
    }

    updateNewTeamInfo = (newTeamInfo) => {
        const currTeamInfo = JSON.parse(JSON.stringify(this.props.teamInfo))
        currTeamInfo[this.props.teamIdx] = newTeamInfo

        this.props.modifyTeamInfo(currTeamInfo)
    }

    updateCurrentMembers = (email, role, remove=false) => {
        const currUpdatedValues = JSON.parse(JSON.stringify(this.state.updatedValues));

        if (remove){
            delete currUpdatedValues[email]
        } else {
            currUpdatedValues[email] = role
        }

        this.setState({ updatedValues: currUpdatedValues })
    }

    render() {
        let newInviteRows = null;
        if (this.state.teamInvites.length > 0){
            newInviteRows = []

            for (let i=0; i<this.state.teamInvites.length; i++){
                const emailValue = this.state.teamInvites[i].email
                const roleValue = this.state.teamInvites[i].role

                newInviteRows.push(
                    <tr key={i.toString()}>
                        <td className="w-60">
                            <Input
                                name={"email_" + i.toString()}
                                fluid
                                onChange={this.handleChange}
                                value={emailValue}/>
                            {this.inputEmailError(emailValue)}
                        </td>
                        <td className="w-30">
                            <Dropdown
                                onChange={this.handleInviteRoleChange}
                                options={options}
                                placeholder='Role'
                                selection
                                name={"role_" + i.toString()}
                                value={roleValue}
                                fluid
                            />
                            {this.roleSelectError(roleValue)}
                        </td>
                        <td className="w-10">
                            <span>
                                <Popup
                                    trigger={<Button
                                        disabled={this.inputEmailError(emailValue) !== null || this.roleSelectError(roleValue) !== null}
                                        circular
                                        loading={this.state.sendingInvite}
                                        icon="paper plane"
                                        basic
                                        color="green"
                                        onClick={(e) => this.inviteNewTeamMember(e, i)}
                                    />}
                                    content="Invite" />
                            </span>
                            <span style={{paddingLeft: '10px'}}>
                                <Button
                                    circular
                                    icon="remove"
                                    basic
                                    color="red"
                                    onClick={(e) => this.addOrRemoveInvite(i, false)}/>
                            </span>
                        </td>
                    </tr>
                )
            }
        }

        let currentTeamMemberRows = [];

        let key = 0;
        for (let email in this.props.teamInfo[this.props.teamIdx].all_users){
            if (this.props.teamInfo[this.props.teamIdx].all_users[email].status === "joined"){
                const role = this.props.teamInfo[this.props.teamIdx].all_users[email].role;
                currentTeamMemberRows.push(
                    <tr key={key.toString()}>
                        <td className="w-60">
                            {email}
                        </td>
                        <td className="w-30">
                            <td>{
                                email in this.state.updatedValues ?
                                    <Dropdown
                                        onChange={this.handleCurrentRoleChange}
                                        options={options}
                                        placeholder='Role'
                                        selection
                                        name={email}
                                        value={this.state.updatedValues[email]}
                                        fluid
                                    /> :
                                    <RoleTag userRole={role}/>
                            }
                            </td>
                        </td>
                        <td className="w-10">
                            {
                                this.state.isAdmin ?
                                    <UpdateTeamMemberInfo updatedValues={this.state.updatedValues}
                                                          allUsers={this.props.teamInfo[this.props.teamIdx].all_users}
                                                          teamIdx={this.props.teamIdx}
                                                          email={email}
                                                          userRole={role}
                                                          updateCurrentMembers={this.updateCurrentMembers}/> :
                                    null
                            }
                        </td>
                    </tr>
                )

                key += 1
            }
        }

        let invitesSent = null;

        let key2 = 0;
        if (this.state.isAdmin){
            invitesSent = []

            for (let email in this.props.teamInfo[this.props.teamIdx].all_users){
                if (this.props.teamInfo[this.props.teamIdx].all_users[email].status === "invited"){
                    const role = this.props.teamInfo[this.props.teamIdx].all_users[email].role;

                    invitesSent.push(<div key={key2.toString()}>
                        <InviteSentRow
                            teamIdx={this.props.teamIdx}
                            email={email}
                            role={role}
                            sentOn={this.props.teamInfo[this.props.teamIdx].all_users[email].invited}
                            inviteHash={this.props.teamInfo[this.props.teamIdx].all_users[email].inviteHash}
                        />
                    </div>)

                    key2 += 1
                }
            }

            if (invitesSent.length > 0){
                invitesSent = <div>
                    <div className="invitesSentRow" style={{borderBottom: '1px solid lightgrey', padding: "0 0 10px 0"}}>
                        <div><b>Email</b></div>
                        <div><b>Role</b></div>
                        <div><b>Invited</b></div>
                        <div> </div>
                    </div>
                    {invitesSent}
                </div>
            } else {
                invitesSent = <div style={{color: "#E27837", fontFamily: "Lato", fontSize: "14px", fontWeight: "500"}}>There are no pending invitations for this team</div>
            }
        }

        return (
            <div>
                <div style={{marginBottom: "16px"}}>
                    <DisplayHeader header={<span><Icon name="envelope open outline"/> Pending Invitations</span>}/>
                </div>
                {invitesSent}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    teamInfo: state.mainState.teamInfo,
    tier: state.mainState.tier,
})

const mapActionsToProps = {
    modifyTeamInfo: modifyTeamInfo
}

export default connect(mapStateToProps, mapActionsToProps)(PendingInvitesTable)