import React, {Component} from 'react'
import {connect} from 'react-redux'
import JSONAccordionIndex from "../SharedComponents/JSONAccordion/JSONAccordionIndex"
import {toggleToast} from "../Actions/ToggleToast";
import CustomAccordion from "../SharedComponents/CustomAccordion"
import {getSampleResponseJson} from "./getSampleResponseJson";
import SampleResponseParametersIndex from "./SampleResponseParametersIndex";
import ErrorResponseCodesBody from "./ErrorResponseCodesBody";

class UtilitiesSampleResponse extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        // can use this to show the parameters as needed
        const responseJSON = getSampleResponseJson(this.props.mode, ['pro', 'team', 'business', 'enterprise'].includes(this.props.tier))

        return (
            <div>
                <div className="row">
                    <div className="col-sm-6">
                        <JSONAccordionIndex
                            title="Sample Response Body"
                            message=""
                            heading={""}
                            jsonObj={responseJSON}
                        />
                    </div>
                    <div className="col-sm-6">
                        <CustomAccordion
                            // body={<RequestParameterIndex
                            //     mode={this.props.mode}
                            //     dataFormat={this.state.dataFormat}
                            //     changeState={this.changeState}
                            //     isPrivate={this.props.utilitiesInfo.isPrivate}
                            // />}
                            body={<SampleResponseParametersIndex
                                mode={this.props.mode}

                            />}
                            header={"Response Body Parameters"}
                            accordionStyle={{
                                borderRadius: '4px',
                                border: "1px solid #F0F0F0",
                                backgroundColor: 'white'
                                // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                            }}
                            headerDivStyle={{
                                borderRadius: '4px',
                                backgroundColor: 'white',
                                fontSize: '16px',
                                fontWeight: '700',
                                fontFamily: "Lato",
                                padding: "16px"
                            }}
                        />
                    </div>
                </div>
                <div style={{ marginTop: "24px"}}>
                    <CustomAccordion
                        body={<ErrorResponseCodesBody
                            mode={this.props.mode}
                        />}
                        header={"Error Response Codes and Messages"}
                        accordionStyle={{
                            borderRadius: '4px',
                            border: "1px solid #F0F0F0",
                            backgroundColor: 'white'
                            // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                        }}
                        headerDivStyle={{
                            borderRadius: '4px',
                            backgroundColor: 'white',
                            fontSize: '16px',
                            fontWeight: '700',
                            fontFamily: "Lato",
                            padding: "16px"
                        }}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    utilitiesInfo: state.mainState.utilitiesInfo,
    tier: state.mainState.tier
})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(UtilitiesSampleResponse)