import React, {Component} from 'react'

class TableColumnsTable extends Component {
    render() {
        let tableRows = [];

        for (let i=0; i<this.props.columnInfo.length; i++){
            const columnInfoRow = this.props.columnInfo[i];

            tableRows.push(
                <tr>
                    <th scope="row">{columnInfoRow['Field']}</th>
                    <td>{columnInfoRow['Type']}</td>
                    <td>{columnInfoRow['NULL']}</td>
                    <td>{columnInfoRow['Default']}</td>
                    <td>{columnInfoRow['Extra']}</td>
                </tr>
            )
        }
        return (
            <div>
                <table className="table table-bordered table-striped table-sm">
                    <thead className="thead-light">
                    <tr>
                        <th scope="col">Field</th>
                        <th scope="col">Type</th>
                        <th scope="col">NULL</th>
                        <th scope="col">Default</th>
                        <th scope="col">Extra</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableRows}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default TableColumnsTable