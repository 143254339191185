import React, { Component } from 'react';
import CircleComp from './CircleComp';

class SquareLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colors: Array(12).fill('#FFFFFF'), // Initialize with a default color for each circle
        };
    }

    componentDidMount() {
        this.interval = setInterval(this.changeColors, 1000); // Change colors every second
    }

    componentWillUnmount() {
        clearInterval(this.interval); // Clear the interval when component unmounts
    }

    getRandomColor = () => {
        // Generate a random hex color
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    changeColors = () => {
        // Update the colors array with random colors
        this.setState({
            colors: this.state.colors.map(() => this.getRandomColor()),
        });
    };

    render() {
        return (
            <div style={{ display: 'grid', gridTemplateColumns: '10px 10px 10px 10px', gridTemplateRows: '10px 10px 10px', gap: '2px' }}>
                {this.state.colors.map((color, index) => (
                    <CircleComp key={index} color={color} />
                ))}
            </div>
        );
    }
}

export default SquareLoader;
