import React, {Component} from 'react'
import { Icon, Image } from 'semantic-ui-react'
import ModalApp from '../Modal'
import images from "../../Constants/Images";
import DemoVideo from "./DemoVideo";
import {gunaldiStyles} from "../GunaldiDesignStyles";


class DemoModal extends Component {
    isImporter = () => (window.location.href.includes("/import"))

    render() {
        return (
            <ModalApp name="demoModal"
                      header={
                          <div className="row">
                              <div className="col-sm-1">
                                  <Image style={{cursor: 'pointer'}} src={images.imageIcon} size="mini"/>
                              </div>
                              <div className="col-sm-11">
                                  <div style={{textAlign: "center", fontSize: "20px", fontWeight: "700", color: gunaldiStyles.mainText}}>
                                      Watch API Spreadsheets {this.isImporter() ? "Importer " : ""}in action with this {this.isImporter() ? "60" : "90"}-second demo
                                  </div>
                              </div>
                          </div>}
                      body={<div style={{textAlign:'center', marginTop: "16px"}}>
                          <DemoVideo isImporter={this.isImporter()}/>
                      </div>}
                      size="large"
            />
        )
    }
}

export default DemoModal