import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class PromoCountdown extends Component {
    render() {
        if (this.props.timeLeft.day === null){
            return(
                <div style={{color: 'white'}}>
                    <h1><Icon name="warning" /> This Promo Has Expired</h1>
                </div>
            )
        }
        else{
            return (
                <h1 style={{display: "inline"}}>{this.props.timeLeft.day} <span className="promoCountdownLabel">days</span> {this.props.timeLeft.hour} <span className="promoCountdownLabel">hours</span> {this.props.timeLeft.minute} <span className="promoCountdownLabel">minutes</span> {this.props.timeLeft.second} <span className="promoCountdownLabel">seconds</span></h1>
            )
        }
    }
}

export default PromoCountdown