import React, {Component} from 'react'
import {connect} from 'react-redux'
import RequestURLBox from "../../SharedComponents/RequestURLBox"
import UtilitiesSampleRequest from "../UtilitiesSampleRequest"
import {getFullAPIUrl} from "../getFullAPIUrl";
import UtilitiesSampleResponse from "../UtilitiesSampleResponse"

class DownloadIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const url = getFullAPIUrl(this.props.baseURL, "download")

        return (
            <div>
                <div style={{ marginBottom: "24px"}}>
                    <RequestURLBox url={url} />
                </div>
                <div style={{ marginBottom: "24px"}}>
                    <UtilitiesSampleRequest mode="download" url={url}/>
                </div>
                <div style={{ marginBottom: "24px"}}>
                    <UtilitiesSampleResponse mode="download" url={url}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DownloadIndex)