import React, {Component} from 'react'
import {connect} from 'react-redux'
import Team from "../Team.svg"
import { Icon, Message, Button, Popup } from 'semantic-ui-react'
import UpgradeButton from "../../InfoPages/Profile/UpgradeButton"
import {toggleModal} from "../../Actions/ToggleModal";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

class CreateTeam extends Component {
    createTeam = () => {
        this.props.toggleModal("createTeamModal")
    }

    render() {
        if (this.props.tier === "free" || this.props.tier === "pro"){
            return (
                <Popup content="You need to have a Team or higher account to create a team"
                       trigger={<Button icon="plus" content="Create New Team" style={{backgroundColor: "#FFFFFF", border: "1px solid #6FBB98", color: "#6FBB98"}} onClick={this.createTeam} disabled={true}/>}/>
            )
        } else {
            return (
                <Button icon="plus" content="Create New Team" style={{backgroundColor: "#FFFFFF", border: "1px solid #6FBB98", color: "#6FBB98"}} onClick={this.createTeam} />
            )
        }
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(CreateTeam)