import React, {Component} from 'react'
import {connect} from 'react-redux'

class ActionsIntroduction extends Component {
    render() {
        const imgStyle = {border:"1px solid #F0F0F0", display: "block", marginBottom: "16px"}

        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">General</p>
                    <p>There are many actions that can be taken for a particular file or API</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions-Introduction/Actions.png"
                         alt="File Actions Menu"
                         style={{...imgStyle}} />
                    <p>You can read about them in their appropriate section</p>
                    <p>Additionally, there are some more actions such as downloading or viewing a file that differ based on the upload source</p>
                    <p>In this article, we will go through the actions that differ based on the upload sources</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Google Sheets</p>
                    <p>You can <b>Open</b> your Google Sheet or <b>Refresh</b> it</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions-Introduction/GoogleSheets.png"
                         alt="Google Sheets Header"
                         style={{...imgStyle, width: '75%'}} />
                    <p><b>Open File</b></p>
                    <p>This action will open up your Google Sheet in a new tab</p>
                    <p><b>Refresh File</b></p>
                    <p>This action will update the information on the Files page from your Google Sheet</p>
                    <p>For example: sheet names, and sample data on the your File page is computed at the time of upload. Refreshing the file will update all this information with what is in the file currently</p>
                    <p><b>Note</b> The API always returns the live data in the Google Sheet. You do <b>not</b> need to refresh the file to access any data that has changed</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Local Files</p>
                    <p>You can <b>Download</b> your File or <b>Reupload</b> it</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions-Introduction/LocalFile.png"
                         alt="Local file"
                         style={{...imgStyle, width: '75%'}} />
                    <p><b>Download File</b></p>
                    <p>This action will always download your file in .xlsx format</p>
                    <p><b>Reupload File</b></p>
                    <p>This file will replace your current file with the file you are uploading</p>
                    <p>However, the File ID will remain the same so your API URL will not change</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Dropbox and AWS S3</p>
                    <p>You can <b>Download</b> your File or <b>Refresh</b> it</p>
                    <div className="row">
                        <div className="col-sm-6">
                            <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions-Introduction/Dropbox.png"
                                 alt="Dropbox header"
                                 style={{...imgStyle, width: '100%'}} />
                        </div>
                        <div className="col-sm-6">
                            <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions-Introduction/S3.png"
                                 alt="S3 Header"
                                 style={{...imgStyle, width: '100%'}} />
                        </div>
                    </div>
                    <p><b>Download File</b></p>
                    <p>This action will always download your file in .xlsx format</p>
                    <p><b>Refresh File</b></p>
                    <p>This action will update the information on the Files page from your File on Dropbox or AWS S3</p>
                    <p>For example: sheet names, and sample data on the your File page is computed at the time of upload. Refreshing the file will update all this information with what is in the file currently</p>
                    <p><b>Note</b> The API always returns the live data in the File. You do <b>not</b> need to refresh the file to access any data that has changed</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Quickstart</p>
                    <p>Since Quickstart is not a file format, all you can do is Edit Values for your JSON object</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions-Introduction/Quickstart.png"
                         alt="Quickstart"
                         style={{...imgStyle, width: '75%'}} />
                    <p><b>Edit Values</b></p>
                    <p>You will be able to edit the keys and values of your JSON object</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Database</p>
                    <p>An API created from your database requires a query to select the data</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions-Introduction/Database.png"
                         alt="Database"
                         style={{...imgStyle, width: '75%'}} />
                    <p><b>Edit Query</b></p>
                    <p>You will be able to edit the query that was used to create this API</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ActionsIntroduction)