import React, {Component} from 'react'
import pricing from "../../Constants/Pricing/Pricing";
import PlanPricingBox from "../../InfoPages/Pricing/PlanPricingBox"
import {featureList} from "../../Constants/Pricing/featureList";
import {planBgColor} from "../../Constants/planBgColor";
import YearlySavedAmount from "./YearlySavedAmount";

class PaymentPlanSelection extends Component {
    render() {
        let colWidth = 12 / (pricing.length - this.props.minPlan - 1);
        let plans = [];

        for (let i=this.props.minPlan; i<pricing.length - 1; i++){
            plans.push(
                <div className={"col-sm-" + colWidth.toString()} key={i.toString()}>
                    {
                        this.props.yearlyPricing ? <YearlySavedAmount planIdx={i} /> : null
                    }
                    <PlanPricingBox
                        planIdx={i}
                        featureList={featureList}
                        backgroundColor={planBgColor[i]}
                        selectPlan={this.props.updatePlan}
                        paymentModal
                        selectPlanModal
                    />
                </div>)
        }

        return (
            <div className="row">
                {plans}
            </div>
        )
    }
}

export default PaymentPlanSelection