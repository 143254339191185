import React, {Component} from 'react'
import pricing from '../../Constants/Pricing/Pricing'
import { List, Icon } from 'semantic-ui-react'
import AddCommaToNumbers from "../../Constants/AddCommaToNumbers";
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";
import { connect } from 'react-redux'
import {getPricingText} from "../../Constants/getPricingText";

class PopupPlanBody extends Component {
    getTeamContent = () => {
        if (this.props.plan === 0 || this.props.plan === 1){
            return <span><Icon name="remove" /> Team Members</span>
        } else {
            return <span>{AddCommaToNumbers(pricing[this.props.plan].team.toString())} Team Members</span>
        }
    }

    render() {
        const pricingInfo = pricing[this.props.plan];

        let price = null;
        let pricePeriod = null;

        if (!this.props.noPrice){
            price = <h1 className="pricingCost">{getPricingText(this.props.currency, this.props.plan)}</h1>;
            pricePeriod = <span className="subheading">per month</span>
        }

        return (
            <div>
                <h3 className="pricingHeader">{pricingInfo.name.toUpperCase()}</h3>
                <span className="subheading">{capitalizeHyphenated(pricingInfo.desc)}</span>
                {price}
                {pricePeriod}
                <List>
                    <List.Item icon='th' content={AddCommaToNumbers(pricingInfo.rowMax.toString()) + " rows per file"} />
                    <List.Item icon='file alternate outline' content={AddCommaToNumbers(pricingInfo.files.toString()) + " files"}/>
                    <List.Item icon='code' content={AddCommaToNumbers(pricingInfo.requests.toString()) + " requests per day"} />
                    <List.Item icon="group" content={this.getTeamContent()} />
                    <List.Item icon={pricingInfo.secure ? "check" : "x"} content="Private" />
                    <List.Item icon={pricingInfo.autoEmail ? "check" : "x"} content="Auto Email Spreadsheets" />
                    <List.Item icon={pricingInfo.webhooks ? "check" : "x"} content="Webhooks" />
                    <List.Item icon={pricingInfo.blockRequests ? "check" : "x"} content="Block Request Types" />
                    <List.Item icon={pricingInfo.alerts ? "check" : "x"} content="Success/Error Alerts" />
                    <List.Item icon={pricingInfo.multipleKeys ? "check" : "x"} content="Multiple Keys" />
                    <List.Item icon={pricingInfo.domainWhitelist ? "check" : "x"} content="Domain Whitelist" />
                    <List.Item icon={supportIcon[pricingInfo.support]} content={pricingInfo.support + " support"} />
                </List>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

export default connect(mapStateToProps, null)(PopupPlanBody)

const supportIcon = {
    FAQ: 'question',
    Email: 'paper plane outline',
    Priority: 'phone',
    Dedicated: 'user'
}


