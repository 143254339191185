import AddCommaToNumbers from "./AddCommaToNumbers";
import {getPricingInfoObject} from "./getPricingInfoObject";

export const getFileLimitMessage = (proBlockTooManyFiles, pkID, tier, appsumoPlanName, isGrandfather, proBlockOverRide=false) => {
    const pricingInfo = getPricingInfoObject(tier, appsumoPlanName, isGrandfather)

    if (proBlockTooManyFiles.includes(pkID) || proBlockOverRide){
        return "You can only have a maximum of " + AddCommaToNumbers(pricingInfo.files).toString() + " files with your plan"
    } else {
        return "File has more than " + AddCommaToNumbers(pricingInfo.rowMax).toString() + " records"
    }
}