import React, {Component} from 'react'

class NumberCircle extends Component {
    render() {
        return (
            <div style={{
                borderRadius: "50%",
                width: "36px",
                height: "36px",
                padding: "1px",
                background: this.props.background,
                border: "2px solid " + this.props.background,
                color: this.props.color,
                textAlign: "center",
                fontSize: "20px"
            }}>
                {this.props.number.toString()}
            </div>
        )
    }
}

export default NumberCircle