import React, {Component} from 'react'

class APICount extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-4" />
                <div className="col-sm-4">
                    <br/><br/><br/><br/><br/><br/>
                    <div className="pricingHaveMoreQuestions">
                        <div className="pricingHaveMoreQuestionsText">
                            <h2>Serving <span style={{color: 'gray', fontSize: '150%'}}>1,704</span> APIs to Developers Worldwide!</h2>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4" />
            </div>
        )
    }
}

export default APICount