import React, {Component} from 'react'
import UploadSources from "../../Illustrations/UploadSources.svg"
import { Segment } from 'semantic-ui-react'

class MultipleSources extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Connect File from Multiple Sources</div>
                <div style={this.props.subheadingStyle}>We support spreadsheets and data from any of the following sources: Excel or CSV Files, Google Sheets, Dropbox, Your Custom Importer, AWS S3, Postgres, MySQL </div>
                <Segment>
                    <img src={UploadSources} alt="multiple connect sources to API"/>
                </Segment>
            </div>

        )
    }
}

export default MultipleSources