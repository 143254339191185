import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyAnalyticsID} from "../Actions/ModifyAnalyticsID";
import {toggleModal} from "../Actions/ToggleModal";

class AnalyticsButton extends Component {
    constructor(props) {
        super(props);

        this.openAnalyticsModal = this.openAnalyticsModal.bind(this);
    }

    openAnalyticsModal(e){
        this.props.modifyAnalyticsID({pkID: this.props.pkID, pk: this.props.pk});
        this.props.toggleModal("analyticsModal")
    }

    render() {
        return (
            <div>
                <Button content="API Usage" basic color='blue' icon='line graph' onClick={this.openAnalyticsModal} fluid/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    analyticsID: state.mainState.analyticsID
})

const mapActionsToProps = {
    modifyAnalyticsID: modifyAnalyticsID,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(AnalyticsButton)