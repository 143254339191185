import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import {getCleanDomain} from "../../../../Constants/getCleanDomain";

class ValidationMethodInfo extends Component {
    getEmailDomain = (domain) => {
        let emailDomain = domain;

        if (domain.length > 2){
            if (domain.substring(0, 2) === "*."){
                emailDomain = domain.substring(2, domain.length);
            } else {
                if (domain.length > 4){
                    if (domain.substring(0, 4) === "www."){
                        emailDomain = domain.substring(4, domain.length)
                    }
                }
            }
        }

        return emailDomain
    }

    render() {
        return (
            <div>
                <div style={{marginBottom: "16px"}}>
                    <div><Icon name="caret right" /> <b>Email</b></div>
                    <div>
                        You will receive a validation email on the following email addresses:
                        <br/>{["administrator", "hostmaster", "postmaster", "webmaster", "admin"].map(x => <small style={{display: "block"}}>{x + "@" + this.getEmailDomain(getCleanDomain(this.props.domainName))}</small>)}
                        <br/>And any of the emails listed in your Domain WHOIS record for the following contacts: <small>Domain registrant, Technical contact, Administrative contact</small>
                        <br/><br/>Pick this option if you have access to to any of the emails above
                        <br/>
                        <a href="https://docs.aws.amazon.com/acm/latest/userguide/email-validation.html"
                           target="_blank"
                           rel="noopener noreferrer">Read more about Email Validation</a>
                    </div>
                </div>
                <div style={{marginBottom: "16px"}}>
                    <div><Icon name="caret right" /> <b>DNS</b></div>
                    <div>
                        This will require you to put a CNAME record such as "_xxxxyzzzz" on your domain DNS page.
                        <br/><br/>Pick this option if you have access to your domain's DNS records
                        <br/>
                        <a href="https://docs.aws.amazon.com/acm/latest/userguide/dns-validation.html"
                           target="_blank"
                           rel="noopener noreferrer">Read more about DNS Validation</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default ValidationMethodInfo