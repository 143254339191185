import React, {Component} from 'react'

class PlanName extends Component {
    render() {
        return (
            <div style={{
                fontWeight: "700",
                color: this.props.planIdx >=2 ? "white" : "#161E16",
                fontSize: "20px"}}
            >
                {this.props.pricingName.toUpperCase()}
            </div>
        )
    }
}

export default PlanName