import React, {Component} from 'react'
import { Segment } from 'semantic-ui-react';
import AceEditor from 'react-ace';
import 'brace/mode/javascript';
import 'brace/mode/html';
import 'brace/theme/monokai';
import sampleImportCode from "./sampleImportCode";
import CopyBtn from "../../SharedComponents/CopyBtn";
import { Menu } from 'semantic-ui-react'
import sampleNPMImportCode from "./sampleNPMImportCode";

class ImportCodeSnippet extends Component {
    constructor(props){
        super(props);

        this.state = {
            codeType: "html"
        }
    }

    handleItemClick = (e, { name }) => this.setState({ codeType: name })

    render() {
        let code = null;

        if (this.state.codeType === "html"){
            code = sampleImportCode(this.props.importHash, this.props.shouldMatch);
        } else if (this.state.codeType === "javascript"){
            code = sampleNPMImportCode(this.props.importHash, this.props.shouldMatch)
        }

        return (
            <div>
                <Segment inverted>
                    <div style={{fontSize: "18px", fontWeight: "700"}}>Code to create your import button <CopyBtn text={code} message="Code Copied!"/></div>
                    <Menu pointing secondary inverted>
                        <Menu.Item
                            name="html"
                            active={this.state.codeType === 'html'}
                            onClick={this.handleItemClick}>
                            HTML
                        </Menu.Item>
                        <Menu.Item
                            name="javascript"
                            active={this.state.codeType === 'javascript'}
                            onClick={this.handleItemClick}>
                            JavaScript (NPM)
                        </Menu.Item>
                    </Menu>
                    <AceEditor
                        mode={this.state.codeType}
                        theme="monokai"
                        value={code}
                        name="importCodeSnippet"
                        editorProps={{$blockScrolling: true}}
                        height={this.props.shouldMatch ? "400px" : "300px"}
                        width="auto"
                    />
                </Segment>
            </div>
        )
    }
}

export default ImportCodeSnippet