import {isEmailValid} from "../Constants/isEmailValid";

export const getInviteErrorMessage = (teamInvites, name) => {
    let errorMessage = [];
    let duplicateEmails = [];

    if (teamInvites.length > 0){
        for (let i=0; i<teamInvites.length; i++){
            let inviteInfo = teamInvites[i];
            let errorText = "";

            if (!isEmailValid(inviteInfo.email.trim())){
                errorText += inviteInfo.email.trim() + " is not a valid email"
            }

            if (inviteInfo.role === ""){
                let text = "You need to pick a role"
                if (errorText !== ""){
                    text = ". " + text
                }

                errorText += text
            }



            if (errorText.trim() !== ""){
                errorMessage.push(errorText)
            }
        }

        for (let i=0; i<teamInvites.length; i++){
            if (!duplicateEmails.includes(teamInvites[i].email.trim())){
                let count = 0;
                for (let j=0; j<teamInvites.length;j++){
                    if (teamInvites[j].email.trim() === teamInvites[i].email.trim()){
                        count += 1
                    }
                }

                if (count > 1){
                    duplicateEmails.push(teamInvites[i].email.trim())
                    errorMessage.push(teamInvites[i].email.trim() + " can only be invited once")
                }
            }
        }
    }

    if (name.trim() === ""){
        errorMessage.unshift("Team Name is required")
    }
    return errorMessage
}