import React, {Component} from 'react'
import Loader from 'react-loader-spinner'
import { Message, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux'
import {modifyImportSheetsUploadPK} from "../../Actions/ModifyImportSheetsUploadPK";
import {modifyImportSheetsFileInformation} from "../../Actions/ModifyImportSheetsFileInformation";
import {modifyImportSheetRead} from "../../Actions/ModifyImportSheetRead";
import API_Root from "../../Constants/API_Root";
import EditSettingsIndex from "../ImportUpload/ColumnMatching/Edit/EditSettingsIndex"
import SheetNameDelimiterDropdown from "../ImportUpload/ColumnMatching/Edit/SheetNameDelimiterDropdown"
import SaveEditSettingsBtn from "../ImportUpload/ColumnMatching/Edit/SaveEditSettingsBtn"
import {modifyCustomImportSettings} from "../../Actions/ModifyCustomImportSettings";

class ImportEditIndex extends Component {
    constructor(props){
        super(props);

        const getIDs = this.getIDs();
        console.log(getIDs)
        this.state = {
            importKey: getIDs.importKey,
            fileID: getIDs.fileID,
            fetching: true,
            errorHeader: "",
            errorMsg: <span> </span>,
            userEmail: ""
        }
    }

    getIDs = () => {
        const url = window.location.href;

        const startIdxImportID = url.indexOf("embed/") + "embed/".length;
        const endIdxImportID = url.indexOf("/edit")

        const importID = url.substring(startIdxImportID, endIdxImportID)

        const startIdxFileID = endIdxImportID + "/edit/".length
        const endIdxFileID = url.length;

        const fileID = url.substring(startIdxFileID, endIdxFileID)

        return {
            importKey: importID,
            fileID: fileID
        }
    }

    componentDidMount(){
        fetch(API_Root + "api/get-import-sheet-info/", {
            method: "POST",
            headers: {Accept: 'application/json, text/plain, */*',},
            body: JSON.stringify({
                importKey: this.state.importKey
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then(data => {
                    this.props.modifyCustomImportSettings("info", data)

                    this.getFileInfo()
                }).catch(err => this.isError())
            } else if (res.status === 404){
                this.isError("The import configuration key is incorrect. Please contact the creator of this web page",
                    "The incorrect key is " + this.state.importKey)
            } else {
                this.isError()
            }
        }).catch(err => this.isError())
    }

    isError = (header="There was an error setting up this import page! Please try again", msg=<span>Please refresh and try again. Contact us at <a href="mailto:support@apispreadsheets.com">support@apispreadsheets.com</a> if this error persists.</span>) => {
        this.setState({ fetching: false, errorHeader: header, errorMsg: msg })
    }

    getFileInfo = () => {
        fetch(API_Root + "api/get-one-api-file-api/", {
            method: "POST",
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                utcOffset: new Date().getTimezoneOffset(),
                email: "",
                token: "",
                filePK: this.state.fileID,
                isAPI: true
            })
        }).then(res => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.props.modifyImportSheetsUploadPK(data.filePK);
                    this.props.modifyImportSheetsFileInformation(data.fileInformation);
                    this.props.modifyImportSheetRead(data.sheetRead);

                    this.setState({ errorMessage: "", fetching: false, userEmail: data.userEmail })
                }).catch(err => this.isError())
            } else {
                this.isError()
            }
        }).catch(err => this.isError()).catch(err => this.isError())
    }

    render() {
        let body = null;

        if (this.state.fetching){
            body =
                <div style={{padding: '100px 0', textAlign: 'center'}}>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4>Setting up your edit page...</h4>
                </div>
        } else{
            if (this.state.errorHeader !== ""){
                body =
                    <div style={{padding: '100px 0', textAlign: 'center'}}>
                        <Message negative header={this.state.errorHeader}
                                 content={this.state.errorMsg}
                        />
                    </div>
            } else {
                const pkInfo = this.props.importSheetsUploadPK[0];
                const fileInfo = this.props.importSheetsFileInformation[pkInfo.id]

                let sampleLength = fileInfo.rowLength;

                if ("matrixSample" in fileInfo){
                    if ("data" in fileInfo.matrixSample){
                        sampleLength = fileInfo.matrixSample.data.length + 1;
                    }
                }

                body = <div>
                    <div className="row" style={{marginBottom: "20px"}}>
                        <div className="col-sm-1" />
                        <div className="col-sm-5">
                            <SaveEditSettingsBtn embed
                                                 userEmail={this.state.userEmail}
                                                 pk={pkInfo.pk}
                                                 fileID={this.state.fileID}
                                                 pkInfo={pkInfo}
                                                 importKey={this.state.importKey}/>
                        </div>
                        <div className="col-sm-6" />
                    </div>
                    <div className="row">
                        <div className="col-sm-1" />
                        <div className="col-sm-6">
                            <h5 className="text-muted" style={{marginBottom: "0"}}>
                                <span style={{fontWeight: "bold"}}>Verify and edit your file read settings</span>
                            </h5>
                            <Segment>
                                <h3 className="wordwrap">{fileInfo.name}</h3>
                                <small className="text-muted"><i>Showing first {sampleLength} out of {fileInfo.rowLength + 1} rows</i></small>
                            </Segment>
                        </div>
                        <div className="col-sm-4">
                            <SheetNameDelimiterDropdown pk={pkInfo.pk} pkID={pkInfo.id} />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row">
                        <div className="col-sm-1" />
                        <div className="col-sm-10">
                            <EditSettingsIndex pkID={pkInfo.id}
                                               pk={pkInfo.pk}
                                               changeState={this.changeState}
                                               fileInfo={fileInfo}
                                               embed
                            />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                </div>
            }
        }
        return (
            <div>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    importSheetsUploadPK: state.mainState.importSheetsUploadPK,
    importSheetsFileInformation: state.mainState.importSheetsFileInformation
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings,
    modifyImportSheetsUploadPK: modifyImportSheetsUploadPK,
    modifyImportSheetsFileInformation: modifyImportSheetsFileInformation,
    modifyImportSheetRead: modifyImportSheetRead
}

export default connect(mapStateToProps, mapActionsToProps)(ImportEditIndex)