import React, {Component} from 'react'
import security from "../../Illustrations/security.svg"

class SecurityDesc extends Component {
    render() {
        return (
            <div>
                <h3> Security </h3>
                <img src={security} style={{width:'50%',margin:'auto',display:'block'}}/>
                <br/>
                <p> We use HTTPS/SSL in transmitting data to our server to keep it secure. </p>
                <p>We also store the data under encryption keys only accessible to one person.</p>

            </div>

        )
    }
}

export default SecurityDesc