import React, {Component} from 'react'
import {connect} from 'react-redux'
import ImportConfigDeleteModalBodyFileNames from "./ImportConfigDeleteModalBodyFileNames";
import { Input, Button, Message } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {modifyImportSheetsInfo} from "../../Actions/ModifyImportSheetsInfo";
import {toggleModal} from "../../Actions/ToggleModal";
import { push } from 'react-router-redux'
import {toggleToast} from "../../Actions/ToggleToast";

class ImportConfigDeleteModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteFiles: false,
            importNameConfirm: '',
            deleting: false,
            error: null
        }
    }

    toggleDeleteFiles = (deleteFiles) => this.setState({ deleteFiles: deleteFiles })

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value })

    deleteImportConfig = () => {
        const errorDeleting = () => this.setState({ deleting: false, error: <Message negative header="There was an error deleting this import configuration" content="Please try again. Contact our support if this error persists" />})
        this.setState({ deleting: true })

        fetch(API_Root + "api/delete-import-details/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                deleteFiles: this.state.deleteFiles,
                importHash: this.props.importHash
            })
        }).then(res => {
            if (res.status === 200){
                this.setState({
                    deleting: false, error: null
                }, () => this.props.custom ? this.removeCustomConfig() : this.removeFromReduxAndCloseModal())
            } else {
                errorDeleting()
            }
        }).catch(err => errorDeleting());
    }

    removeCustomConfig = () =>{
        this.props.toggleModal(null)
        this.props.navigateTo("/import")

        this.props.toggleToast({show: true, message: this.props.importName + (this.state.deleteFiles ? " and all files" : "") + " successfully deleted!", type: "success"})
    }

    removeFromReduxAndCloseModal = () => {
        const importInfo = JSON.parse(JSON.stringify(this.props.importSheetsInfo));
        importInfo.splice(this.props.idx, 1)

        this.props.modifyImportSheetsInfo(importInfo)

        this.props.toggleModal(null)

        this.props.toggleToast({show: true, message: this.props.importName + (this.state.deleteFiles ? " and all files" : "") + " successfully deleted!", type: "success"})
    }

    render() {
        // Get import hash from modal info
        // Show name of the import configuration
        // Have an option to check if they want to delete files
        // If they want to delete files, show the files that will be deleted
        // Ask to type in the name of the configuration then delete
        return (
            <div>
                <ImportConfigDeleteModalBodyFileNames
                    importHash={this.props.importHash}
                    deleteFiles={this.state.deleteFiles}
                    toggleDeleteFiles={this.toggleDeleteFiles}
                />
                <div style={{marginTop: "20px"}}>
                    <label style={{fontWeight: "bold"}}>Enter the name of this import configuration to delete</label>
                    <Input fluid
                           value={this.state.importNameConfirm}
                           name="importNameConfirm"
                           onChange={this.handleChange}
                           placeholder={this.props.importName}
                    />
                    <br/>
                    <Button
                        content={"Delete " + this.props.importName + (this.state.deleteFiles ? " and All Uploaded Files": "")}
                        icon="trash alternate outline"
                        color="red"
                        fluid
                        loading={this.state.deleting}
                        onClick={this.deleteImportConfig}
                        disabled={this.props.importName !== this.state.importNameConfirm}
                    />
                    {this.state.error}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo,
    importSheetsInfo: state.mainState.importSheetsInfo
})

const mapActionsToProps = {
    modifyImportSheetsInfo: modifyImportSheetsInfo,
    toggleModal: toggleModal,
    navigateTo: push,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(ImportConfigDeleteModalBody)