import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import API_Root from "../../../../Constants/API_Root";
import getURLParam from "../../../../Constants/getURLParam";
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";

class RemoveColumnRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            removing: false
        }
    }

    removeColumnRow = () => {
        this.setState({ removing: true })
        if (this.props.saved){
            const splitParam = getURLParam(window.location.href).split("_")
            const importKey = splitParam[0];

            const error = () => this.setState({ removing: false }, () => alert("There was an error deleting your column information"))

            fetch(API_Root + "api/delete-import-column-info/", {
                method: "POST",
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    importHash: importKey,
                    idx: this.props.idx
                })
            }).then((res) => {
                if (res.status === 200) {
                    this.props.removeColumnRow()
                    this.setState({ removing: false })
                }
                else {
                    error()
                }
            }).catch(err => error())
        } else {
            this.props.removeColumnRow()
            this.setState({ removing: false })
        }
    }

    render() {
        return (
            <Popup
                trigger={<Button
                                // circular
                                 icon="trash alternate outline"
                                 content={"Delete Column " + (this.props.idx + 1).toString()}
                                 style={{backgroundColor: gunaldiStyles.red, color: "white"}}
                                 size="mini" />}
                content={<div style={{textAlign: 'center'}}>
                    <h5 className="thinHeading">Are you sure you want to delete this column?</h5>
                    <Button loading={this.state.removing} color='red' content='Delete Column' onClick={this.removeColumnRow} /> <br/>
                </div>}
                on='click'
                position='top left'
            />


        )
    }
}

const mapStateToProps = (state) => ({
    customImportColumns: state.mainState.customImportColumns
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(RemoveColumnRow)