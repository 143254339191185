import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'
import SampleTable from "../SampleTable";
import DocsBaseURL from "../../../InfoPages/DocsPage/DocsBaseURL"
import {generateRandomKey} from "../../../Constants/generateRandomKey";
import JSONDisplay from "../../../SharedComponents/JSONDisplay";
import TogglePrivate from "./TogglePrivate";
import GuideDetails from "../../GuideDetails";
import CodeSnippet from "../../../FileDnD/CodeSnippet"
import {createFileInformation} from "../Functions/createFileInformation";
import {createCodeSnippets} from "../Functions/createCodeSnippets";
import {createFullURLFromBase} from "../../../Constants/createFullURLFromBase";

class ReadRows extends Component {
    constructor(props){
        super(props);

        this.state = {
            isPrivate: false,
            sampleTableData: [
                [1, "Justin Trudeau", 252069.46, "Canada"],
                [2, "Jacinda Ardern", 339862.25, "New Zealand"],
                [3, "Boris Johnson", 187033.75, "UK"],
                [4, "Joko Widodo", 27200.8, "Indonesia"],
                [5, "Angela Merkel", 369727.4, "Germany"]
            ],
            sampleTableHeaders: ["id", "name", "salary_$", "country"],
            sampleDataType: ["num", "str", "num", "str"],
            dataFormat: "row"
        }

        this.togglePrivate = this.togglePrivate.bind(this);
        this.modifyURLIfPrivate = this.modifyURLIfPrivate.bind(this);
        this.changeState = this.changeState.bind(this);
    }

    togglePrivate(e){
        this.setState({ isPrivate: !this.state.isPrivate })
    }

    changeState(name, value){
        this.setState({
            [name]: value
        })
    }

    modifyURLIfPrivate(url){
        if (this.state.isPrivate){
            const keyText = "accessKey=" + generateRandomKey(32) + "&secretKey=" + generateRandomKey(32)
            if (url.indexOf("?") === -1){
                url += "?" + keyText
            }
            else{
                url += "&" + keyText
            }
        }

        url += "/"

        return url
    }

    render() {
        // POST: Orange
        // GET: Yellow
        let baseGetURL = 'data/[fileID]/?dataFormat=[dataFormat]&query=[query]&limit=[limit]&accessKey=[accessKey]&secretKey=[secretKey]/';
        let privateText = this.state.isPrivate ? <span>Add your <code>accessKey</code> and <code>secretKey</code>.</span> : null
        const urlBase = this.modifyURLIfPrivate("data/812");
        const urlBaseDataFormat = this.modifyURLIfPrivate('data/812/?dataFormat=' + this.state.dataFormat);
        const urlBaseLimit = this.modifyURLIfPrivate('data/812/?limit=3&dataFormat=' + this.state.dataFormat);

        const sampleFileInformation = createFileInformation(this.state.sampleTableData, this.state.sampleTableHeaders, this.state.sampleDataType, 812, "Sample Data - World Leaders", this.state.isPrivate, createFullURLFromBase(urlBaseLimit))

        const baseCode = createCodeSnippets(sampleFileInformation, "readAction", createFullURLFromBase(urlBase));
        const baseDataFormatCode = createCodeSnippets(sampleFileInformation, "readAction", createFullURLFromBase(urlBaseDataFormat));
        const baseLimitCode = createCodeSnippets(sampleFileInformation, "readAction", createFullURLFromBase(urlBaseLimit));

        return (
            <div>
                <h2>Make a GET request to the following URL</h2>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={baseGetURL} />
                <br/>
                <p>We will cover examples with all the URL parameters except the <code>query</code> parameter. The <code>query</code> parameter is covered in the <a href={GuideDetails[2].title}>Read Subset Data</a> guide. </p>
                <br/><br/>
                <h2>Let's look at some examples</h2>
                <br/>
                <h4>Getting all Rows <TogglePrivate isPrivate={this.state.isPrivate} togglePrivate={this.togglePrivate}/></h4>
                <p>Make a GET request to the URL with your <code>fileID</code>. Get your data back in a JSON object. {privateText}</p>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={urlBase} />
                <CodeSnippet
                    code={baseCode}
                    height={150}
                />
                <SampleTable
                    tableData={this.state.sampleTableData}
                    tableHeaders={this.state.sampleTableHeaders}
                    header="Sample Data - World Leaders"
                />
                <JSONDisplay
                    heading="Your JSON Response"
                    tableData={this.state.sampleTableData}
                    tableHeaders={this.state.sampleTableHeaders}
                    dataTypes={this.state.sampleDataType}
                    outputType="row"
                />
                <br/><br/><br/><br/>
                <h4>Getting all Rows in Different Formats <TogglePrivate isPrivate={this.state.isPrivate} togglePrivate={this.togglePrivate}/></h4>
                <p>You can get your rows in one of the three different formats by specifying it as a URL parameter <code>dataFormat</code>. If no format is specified, the <code>row</code> format is returned as default.</p>
                <br/>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="clickDiv" style={{backgroundColor: this.state.dataFormat === "row" ? "lightgray" : ""}} onClick={(e) => this.changeState("dataFormat", "row")}>
                            <h5>Row</h5>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="clickDiv" style={{backgroundColor: this.state.dataFormat === "column" ? "lightgray" : ""}} onClick={(e) => this.changeState("dataFormat", "column")}>
                            <h5>Column</h5>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="clickDiv" style={{backgroundColor: this.state.dataFormat === "matrix" ? "lightgray" : ""}} onClick={(e) => this.changeState("dataFormat", "matrix")}>
                            <h5>Matrix</h5>
                        </div>
                    </div>
                </div>
                <br/>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={urlBaseDataFormat} />
                <CodeSnippet
                    code={baseDataFormatCode}
                    height={150}
                />
                <br/>
                <JSONDisplay
                    heading="Your JSON Response"
                    tableData={this.state.sampleTableData}
                    tableHeaders={this.state.sampleTableHeaders}
                    dataTypes={this.state.sampleDataType}
                    outputType={this.state.dataFormat}
                />
                <br/><br/><br/><br/>
                <h4>Getting Rows with a Limit <TogglePrivate isPrivate={this.state.isPrivate} togglePrivate={this.togglePrivate}/></h4>
                <p>You can get only the first <b>n</b> rows by specifying the <code>limit</code> parameter in the URL. In this example, we will get the first <b>3</b> rows.</p>
                <br/>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="clickDiv" style={{backgroundColor: this.state.dataFormat === "row" ? "lightgray" : ""}} onClick={(e) => this.changeState("dataFormat", "row")}>
                            <h5>Row</h5>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="clickDiv" style={{backgroundColor: this.state.dataFormat === "column" ? "lightgray" : ""}} onClick={(e) => this.changeState("dataFormat", "column")}>
                            <h5>Column</h5>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="clickDiv" style={{backgroundColor: this.state.dataFormat === "matrix" ? "lightgray" : ""}} onClick={(e) => this.changeState("dataFormat", "matrix")}>
                            <h5>Matrix</h5>
                        </div>
                    </div>
                </div>
                <br/>
                <Label color="yellow">GET</Label>
                <DocsBaseURL url={urlBaseLimit} />
                <CodeSnippet
                    code={baseLimitCode}
                    height={150}
                />
                <br/>
                <JSONDisplay
                    heading="Your JSON Response"
                    tableData={this.state.sampleTableData.slice(0, 3)}
                    tableHeaders={this.state.sampleTableHeaders}
                    dataTypes={this.state.sampleDataType}
                    outputType={this.state.dataFormat}
                />
            </div>
        )
    }
}

export default ReadRows