import React, {Component} from 'react'

class CodeSnippet extends Component {
    render() {
        let code = null;

        if (Array.isArray(this.props.code)){
            code = this.props.code.map((x, idx) => <div key={idx}><code>{x}</code></div>)
        } else {
            code = <div><code>{this.props.code}</code></div>
        }

        return (
            <div style={{background: "whitesmoke", padding: "16px", borderRadius: "8px", marginBottom: "8px"}}>
                {code}
            </div>
        )
    }
}

export default CodeSnippet