import React, {Component} from 'react'
import matchingicon from "../../Illustrations/matchingicon.svg"
import { Table } from 'semantic-ui-react'
import ColumnMatchRowLanding from "../../../Import/ImportUpload/ColumnMatching/ColumnMatchRowLanding";

class ColMatching extends Component {
    constructor(props){
        super(props);

        this.state = {
            columnsMapRows: ["Sign Up Date", "Full Name", null, "Items Ordered"]
        }
    }

    changeColumnMap = (idx, value) => {
        const currColumnsMapRows = JSON.parse(JSON.stringify(this.state.columnsMapRows))
        currColumnsMapRows[idx] = value

        this.setState({ columnsMapRows: currColumnsMapRows })
    }

    render() {
        const dropdownOptions = ["Sign Up Date", "Full Name", "Is Customer?", "Items Ordered"].map((x, idx) => { return { key: idx, value: x, text: x } })
        dropdownOptions.push({ key: -1, value: null, text: "NO MATCH"})

        return (
            <div>
                <div style={this.props.headerStyle}> Map File Columns to Data Model </div>
                <div style={this.props.subheadingStyle}>We use a combination of intelligence and user input to map columns from uploaded files to your data model</div>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={4}>Your Column Name</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Map Your Column</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <ColumnMatchRowLanding idx={0}
                                               colName="Joined Dt"
                                               dropdownOptions={dropdownOptions}
                                               changeColumnMap={this.changeColumnMap}
                                               matchedColumn={this.state.columnsMapRows[0]}
                                               allMatchedColumns={this.state.columnsMapRows}
                        />
                        <ColumnMatchRowLanding idx={1}
                                               colName="Customer Name"
                                               dropdownOptions={dropdownOptions}
                                               changeColumnMap={this.changeColumnMap}
                                               matchedColumn={this.state.columnsMapRows[1]}
                                               allMatchedColumns={this.state.columnsMapRows}
                        />
                        <ColumnMatchRowLanding idx={2}
                                               colName="Free or Paid"
                                               dropdownOptions={dropdownOptions}
                                               changeColumnMap={this.changeColumnMap}
                                               matchedColumn={this.state.columnsMapRows[2]}
                                               allMatchedColumns={this.state.columnsMapRows}
                        />
                        <ColumnMatchRowLanding idx={3}
                                               colName="Num Items"
                                               dropdownOptions={dropdownOptions}
                                               changeColumnMap={this.changeColumnMap}
                                               matchedColumn={this.state.columnsMapRows[3]}
                                               allMatchedColumns={this.state.columnsMapRows}
                        />
                    </Table.Body>
                </Table>
            </div>

        )
    }
}

export default ColMatching