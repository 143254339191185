import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../../Constants/API_Root";
import isObjectEmpty from "../../Constants/isObjectEmpty";
import ProfileDisplayHeader from "./ProfileDisplayHeader";
import Loader from 'react-loader-spinner'
import S3AccountRow from "./S3AccountRow"
import AddNewS3Account from "../../Import/ImportID/CustomImport/Destinations/AddNewS3Account"
import AccountsErrorBanner from "./AccountsErrorBanner";
import AccountsNotConnectedBanner from "./AccountsNotConnectedBanner";

class S3AccountsIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accountInfo: null,
            fetching: true,
            error: false
        }
    }

    componentDidMount(){
        const hitError = () => this.setState({ fetching: false, error: true, accountInfo: null })

        fetch(API_Root + 'api/crud-s3-accounts-for-user/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                actionType: "read",

            })
        }).then(res => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.setState({ accountInfo: data.awsS3Accounts, fetching: false, error: false })
                }).catch(err => hitError())
            }
            else{
                hitError()
            }
        }).catch(err => hitError());
    }

    removeAccountInfo = (accountName) => {
        const copiedAccountInfo = JSON.parse(JSON.stringify(this.state.accountInfo))

        delete copiedAccountInfo[accountName]

        this.setState({
            accountInfo: copiedAccountInfo
        })
    }

    render() {
        if (this.state.fetching){
            return (
                <Loader
                    type="TailSpin"
                    color="black"
                    height="40"
                    width="40"
                />
            )
        } else {
            if (this.state.error) {
                return (
                    <AccountsErrorBanner
                        accountType="AWS S3"
                    />
                )

            } else {
                if (this.state.accountInfo === null) {
                    return (
                        <AccountsNotConnectedBanner
                            accountType="AWS S3"
                        />
                    )
                } else {
                    if (isObjectEmpty(this.state.accountInfo)) {
                        return (
                            <AccountsNotConnectedBanner
                                accountType="AWS S3"
                            />
                        )
                    } else {
                        let s3Rows = [];
                        const allNames = Object.keys(this.state.accountInfo)

                        for (let email in this.state.accountInfo){
                            s3Rows.push(<S3AccountRow
                                email={email}
                                allNames={allNames}
                                info={this.state.accountInfo[email]}
                                removeAccountInfo={this.removeAccountInfo}
                            />)
                        }

                        return(
                            <div>
                                <ProfileDisplayHeader name={this.props.name} icon={this.props.icon} titleName="AWS S3 Accounts"/>
                                <br/>
                                {s3Rows}
                                <br/>
                                <AddNewS3Account profile accountInfo={this.state.accountInfo} />
                            </div>
                        )
                    }
                }
            }
        }
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(S3AccountsIndex)