import React, {Component} from 'react'
import DocsImage from "../DocsImage";
import TierRestrictionBanner from "./TierRestrictionBanner";

class ActionsAPIAlerts extends Component {
    render() {
        return (
            <div>
                <div style={{marginTop: "-8px", marginBottom: "16px"}}>
                    <TierRestrictionBanner plan="team" payment="alerts"/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">What are API Alerts</p>
                    <p>You can set up to receive an email when your API request is successful or if it failed</p>
                    <p>Since API activity happens in the background, these alerts let you keep track of how the API requests are doing</p>
                    <p>You will get 1 email for every 1 API request that matches the conditions you set up the Alerts for</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Set up Alerts</p>
                    <p>Click on the <b>API Alerts</b> action under the menu of the Files page for which you wish to set up the alerts</p>
                    <p>This will bring up the following screen, where you can select when you wish to receive the Alerts</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/APIAlerts-Setup.png" alt="API Alerts Setup" width="70%"/>
                    <p>The error emails will be received if your API request returns any of the following status code: <code>400, 401, 402, 404, 405, 406, 500, 502</code></p>
                    <p>The success emails will be received if your API request returns the <code>200</code> or <code>201</code> status code</p>
                    <p>This is what an example success email looks like</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Actions/APIAlerts-Email.png" alt="API Alerts Email" width="70%"/>
                </div>
            </div>
        )
    }
}

export default ActionsAPIAlerts