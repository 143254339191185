import React, {Component} from 'react'
import {errorCodes} from "./errorCodes";
import ErrorResponseCodesRow from "./ErrorResponseCodesRow";

class ErrorResponseCodesBody extends Component {
    constructor(props) {
        super(props);
    }

    getErrorMessagesForMode = () => {
        const copiedErrorMessage = JSON.parse(JSON.stringify(errorCodes))

        const newMessages = {

        }

        for (let statusCode in copiedErrorMessage){
            const msgInfo = copiedErrorMessage[statusCode];
            const allMsgs = msgInfo['all']

            if (this.props.mode in msgInfo){
                const modeMsgs = msgInfo[this.props.mode];

                for (let i=0; i<modeMsgs.length; i++){
                    allMsgs.push(modeMsgs[i])
                }
            }

            newMessages[statusCode] = allMsgs
        }

        return newMessages
    }

    render() {
        const msgs = this.getErrorMessagesForMode();
        let codesRows = [];

        for (let statusCode in msgs){
            codesRows.push(<ErrorResponseCodesRow
                statusCode={statusCode}
                messages={msgs[statusCode]}
                key={statusCode}
            />)
        }


        return (
            <div>
                {codesRows}
            </div>
        )
    }
}

export default ErrorResponseCodesBody