import React, {Component} from 'react'

class ActionPrivate extends Component {
    componentDidMount(){
        const elem = document.getElementById("privateButtonDiv")
        elem.scrollIntoView({ behavior: 'smooth', block: 'center'})
    }

    render() {
        return (
            <div className="actionIndex">
                <h4 className="thinHeading">Since your API is private, you need to include your <code>accessKey</code> and <code>secretKey</code> as URL parameters. You can also make your API non-private if you want.</h4>
            </div>
        )
    }
}

export default ActionPrivate