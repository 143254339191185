import React, {Component} from 'react'
import ModalApp from "../SharedComponents/Modal"
import { Button, Icon } from 'semantic-ui-react'

class GoogleSheetsPermissionsModal extends Component {
    proceed = () => {
        this.props.startSelecting()
    }

    render() {
        return (
            <ModalApp name="googleSheetsPermissionsModal"
                      header={null}
                      body={
                      <div>
                          <h4>You will soon be prompted to Sign In to your Google Account</h4>
                          <h4>Make sure to give <b><u>ALL</u></b> requested Permissions (as seen in the pic below) to API Spreadsheets</h4>
                          <div style={{ marginBottom: "10px"}}>
                            <img
                                style={{border: "1px solid lightgrey", display: "block", marginLeft: "auto", marginRight: "auto", width: "50%"}}
                                src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/GoogleSheetsPermissionCheck.png"
                                alt="GoogleSheetsPermissions"/>
                          </div>
                          <div style={{textAlign: "center", marginTop: "10px"}}>
                              <Button color="green" size="massive" onClick={this.proceed}>
                                  PROCEED <Icon name="arrow right" />
                              </Button>
                          </div>
                      </div>}
                      size="medium"
                      noClose
            />
        )
    }
}

export default GoogleSheetsPermissionsModal