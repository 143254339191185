function copyArray(a, b){
    let finalArray = [];

    for (let i=0; i<a.length; i++){
        finalArray.push(a[i]);
    }

    for (let j=0; j<b.length; j++){
        finalArray.push(b[j])
    }

    return finalArray
}

export default copyArray