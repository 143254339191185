import React, {Component} from 'react'
import { Label, Icon } from 'semantic-ui-react'
import isObjectEmpty from "../Constants/isObjectEmpty";

class SharedLabel extends Component {
    render() {
        const fileInformation = this.props.fileInformation;
        let teamLabel = null;
        let importLabel = null;

        const isTeam = "isTeam" in fileInformation ? fileInformation.isTeam : false;
        const isImported = 'importSheetsFK' in fileInformation ? fileInformation['importSheetsFK'] !== -1 : false

        if (isTeam){
            teamLabel = <Label color="purple"> <Icon name="users"/> shared by team</Label>
        }

        if (isImported){
            let uploadedBy = "";

            if ('importSheetsInfo' in fileInformation){
                if (!isObjectEmpty(fileInformation.importSheetsInfo)){
                    if ("importName" in fileInformation.importSheetsInfo){
                        if (fileInformation.importSheetsInfo.importName !== null){
                            uploadedBy = "by " + fileInformation.importSheetsInfo.importName
                        }
                    }
                }
            }

            importLabel = <Label color="teal"> <Icon name="user"/> imported {uploadedBy}</Label>
        }

        return (
            <span>{teamLabel}{importLabel}</span>
        )
    }
}

export default SharedLabel