import React, {Component} from 'react'
import {connect} from 'react-redux'
import getURLParam from "../../Constants/getURLParam";
import API_Root from "../../Constants/API_Root";
import Loader from 'react-loader-spinner'
import { Message } from 'semantic-ui-react';
import ImportUploadDisplay from "./ImportUploadDisplay";
import ImportUploadFilesDisplay from "./ImportUploadFilesDisplay";
import {modifyCustomImportSettings} from "../../Actions/ModifyCustomImportSettings";
import {getValueFromInfoObject} from "./getValueFromInfoObject";
import ImportUploadNavbar from "./ImportUploadNavbar"

class ImportUploadDisplayEmbed extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: true,
            info: null,
            importName: localStorage.getItem("importName"),
            errorHeader: "",
            errorMsg: <span> </span>
        }

    }

    componentDidMount(){
        const error = (header="There was an error setting up this import page! Please try again", msg=<span>Please refresh and try again. Contact us at <a href="mailto:support@apispreadsheets.com">support@apispreadsheets.com</a> if this error persists.</span>) => {
            this.setState({ fetching: false, info: null, errorHeader: header, errorMsg: msg })
        }

        const importKey = getURLParam(window.location.href);

        fetch(API_Root + "api/get-import-sheet-info/", {
            method: "POST",
            headers: {Accept: 'application/json, text/plain, */*',},
            body: JSON.stringify({
                importKey: importKey
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then(data => {
                    this.props.modifyCustomImportSettings("info", data)

                    this.setState({
                        fetching: false,
                        info: data
                    })
                }).catch(err => error())
            } else if (res.status === 404){
                error("The import configuration key is incorrect. Please contact the creator of this web page",
                    "The incorrect key is " + importKey)
            } else {
                error()
            }
        }).catch(err => error())
    }

    changeImportName = (importName) => this.setState({ importName: importName })

    render() {
        let body = null;

        if (this.state.fetching){
            body =
                <div style={{padding: '100px 0', textAlign: 'center'}}>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4>Setting up your import page...</h4>
                </div>
        } else{
            if (this.state.info === null){
                body =
                    <div style={{padding: '100px 0', textAlign: 'center'}}>
                        <Message negative header={this.state.errorHeader}
                                 content={this.state.errorMsg}
                        />
                    </div>
            } else {
                const importKey = getURLParam(window.location.href)

                let removeBranding = getValueFromInfoObject("removeBranding", this.state.info, "appearance")

                if (removeBranding === null){
                    removeBranding = false
                }

                body =
                    <div style={{padding: "50px 0"}}>
                        {removeBranding ? null : <ImportUploadNavbar info={this.state.info}/>}
                        <ImportUploadDisplay info={this.state.info}
                                             embed={true}
                                             importKey={importKey} />
                        <div style={{marginTop: "24px"}}>
                            <ImportUploadFilesDisplay info={this.state.info}
                                                      importKey={importKey} />
                        </div>
                    </div>
            }
        }

        return (
            <div>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(ImportUploadDisplayEmbed)