import React, {Component} from 'react'
import Silca from "./Silca";

class CaseStudyIndex extends Component {
    render() {
        let customer = this.props.match.params.id;

        if (customer === undefined){
            customer = window.location.href.substr(window.location.href.lastIndexOf("/") +1, window.location.href.length)
        }

        let customerCaseStudyMap = {
            silca: <Silca/>
        }

        let body = null;

        if (customer in customerCaseStudyMap){
            body = customerCaseStudyMap[customer]
        }

        return (
            <div className="caseStudyIndex">
                {body}
            </div>
        )
    }
}

export default CaseStudyIndex