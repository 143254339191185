import React, {Component} from 'react'
import { Checkbox } from 'semantic-ui-react'
import DocsImage from "../DocsImage"
import Gist from "react-gist";
import CodeSnippet from "../CodeSnippet";
import ReactJson from 'react-json-view'
import ImporterCodeParameters from "./ImporterCodeParameters";
import ImporterCodeJSON from "./ImporterCodeJSON";
import CustomAccordion from "../../../SharedComponents/CustomAccordion"

class ImporterCode extends Component {
    constructor(props){
        super(props);

        this.state = {
            isColumnMatch: false
        }
    }

    toggle = () => {
        const changeState = !this.state.isColumnMatch;

        this.setState({ isColumnMatch: changeState })
    }

    render() {
        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">Introduction</p>
                    <p>We provide a pre-written code snippet that lets you create a spreadsheet importer element on your website or app</p>
                    <p>You can find this snippet in your importer page</p>
                    <DocsImage
                        src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/ImportSpreadsheetsOnYourApps/ImporterCode.png"
                        width='100%'
                        alt="Copy pre-written code from the importer page"
                    />
                    <p><b>NOTE:</b> if you have set up column matching, then your code snippet will look a little different. Don't worry, both code snippets will work no matter what since the column matching paramter is optional</p>
                    <div>
                        <Checkbox toggle value={this.state.isColumnMatch} onChange={this.toggle} label={<label>View Code if Column Match Set Up</label>}/>
                    </div>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Code Snippet Explanation</p>
                    <p>The code in the <code>&lt;script></code> tags of your code snippet should go in the <code>&lt;head></code> tags. The <code>&lt;button></code> should go where you want the user to click to upload the files</p>
                    <Gist id={this.state.isColumnMatch ? '2ecd8b5530aa25adfea205fcf2d2fc55':'d0ee0c33e92d6695b9c9ddac39316a26'}/>
                    <br/>
                    <p>Let's walk through the code in more detail</p>
                    <p><b>Import API Spreadsheets Library (<i>line 4</i>)</b></p>
                    <CodeSnippet code={"<script type=\"text/javascript\" src=\"https://www.apispreadsheets.com/import.js\"></script>"}/>
                    <p>This code imports the API Spreadsheets library so you can use the importer function</p>
                    <p><b>Initialize importer and setup callback function (<i>lines {this.state.isColumnMatch ? "6-23" : "6-15"}</i>)</b></p>
                    <p>This is the meat of the code. It involves 2 parts {this.state.isColumnMatch ? "and an optional 3rd part" : ""}:</p>
                    <p>1. Setting up the importer <i>(line {this.state.isColumnMatch ? "23": "15"})</i></p>
                    <CodeSnippet code={this.state.isColumnMatch ? 'let importer = new APISpreadsheetsImporter("3602d167d52a5d6914e6649a4158f672", importComplete, matchComplete);' : 'let importer = new APISpreadsheetsImporter("3602d167d52a5d6914e6649a4158f672", importComplete);  '}/>
                    <p>This line initializes the importer with {this.state.isColumnMatch ? "3" : "2"} parameters: the Importer ID, {this.state.isColumnMatch ? "" : "and"} the Importer callback function {this.state.isColumnMatch ? ", and the Column Match callback function" : ""}</p>
                    <p>The Importer ID can be found here </p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterCode-ImportID.png"
                               width="100%"
                               alt='Database Columns' />
                    <p>The Importer callback function is a function that you can customize to handle the actions of the importer and the data from the imported file</p>
                    {
                        this.state.isColumnMatch ? <p>The Column Match callback function alerts you if column match successfully completed or not. This is an <b>optional</b> parameter which means the code will work completely fine without it</p> : null
                    }
                    <p>Also, pay attention to the variable you use to create the importer, in this case it is <code>let importer</code></p>
                    <p>2. Create the Importer callback function <i>(lines {this.state.isColumnMatch ? "6-11" : "6-13"})</i></p>
                    <CodeSnippet
                        code={['function importComplete(success, data) {',
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;console.log(success);</span>,
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;if (success)&#123;</span>,
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;console.log(data);</span>,
                            this.state.isColumnMatch ? null : <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// close the importer if you wish</span>,
                            this.state.isColumnMatch ? null : <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// importer.closeImporter();</span>,
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&#125;</span>,
                            "}"]}
                        />
                    <p>The Importer callback function ALWAYS takes two parameters: <code>success</code> and <code>data</code></p>
                    <p>The <code>success</code> parameter will have a value of <b>true</b> or <b>false</b> depending on whether a file was successfully imported or not</p>
                    <p>If the <code>success</code> was <b>false</b> then the <code>data</code> parameter will be <b>null</b></p>
                    <p>If the <code>success</code> was <b>true</b>, then the data parameter will be a JSON object that contains the following parameters</p>
                    <CustomAccordion
                        body={<div className="row">
                            <div className="col-sm-6">
                                <ImporterCodeParameters/>
                            </div>
                            <div className="col-sm-6">
                                <ImporterCodeJSON/>
                            </div>
                        </div>}
                        header="Data JSON Object Parameters"
                        accordionStyle={{
                            borderRadius: '4px',
                            border: "1px solid #F0F0F0",
                            backgroundColor: 'white'
                            // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                        }}
                        headerDivStyle={{
                            borderRadius: '4px',
                            backgroundColor: 'white',
                            fontSize: '12px',
                            fontWeight: '700',
                            fontFamily: "Lato",
                            padding: "16px"
                        }}
                    />
                    <br/>
                    {
                        this.state.isColumnMatch ? <div>
                            <p><b>Optional</b> 3. Create the Column Match callback function <i>(lines 13-21)</i></p>
                            <CodeSnippet
                                code={['function matchComplete(matchRequired, matchSuccess) {',
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;if (matchRequired)&#123;</span>,
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// if first uploaded file has finished matching, do something</span>,
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if(matchSuccess[0])&#123;</span>,
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// close the importer if you wish</span>,
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;// importer.closeImporter();</span>,
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;</span>,
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&#125;</span>,
                                    "}"]}
                            />
                            <p>The Importer callback function ALWAYS takes two parameters: <code>matchRequired</code> and <code>matchSuccess</code></p>
                            <p><code>matchRequired</code> has a value of <b>true</b> or <b>false</b> depending on if column matching was set up for this importer</p>
                            <p><code>matchSuccess</code> is an array with the length of the number of files that were uploaded. Each item in the array is either <b>true</b> or <b>false</b> depending on if column matching succeeded or not for that file</p>
                            <p>For example, if the user uploaded 2 files and completed the column matching for the first file ONLY, then their <code>matchSuccess</code> value would be <code>[true, false]</code></p>
                        </div>: null
                    }
                    <p>Finally, if you wish to close the importer at any time, you can invoke the <code>importer.closeImporter()</code> code. This will close the importer popup. Be careful using this since the pre-configured success and error alerts will not show up.</p>
                    <br/>
                    <p><b>Invoke the importer function from an element <i>(line {this.state.isColumnMatch ? "28" : "19"})</i></b></p>
                    <CodeSnippet
                        code='<button class="api-spreadsheets-import-button" onclick="importer.importFiles();">Import Excel and CSV Files</button>'
                    />
                    <p>To begin the importer process, you need to specify the <code>importFiles()</code> function from the <code>importer</code> variable you created on <i>line {this.state.isColumnMatch ? "23" : "15"}</i></p>
                    <p>This function needs to be specified in the <code>onclick</code> attribute of an HTML element</p>
                    <p>In this example code, we use a <b>&lt;button></b> but you can use any HTML element as long as you specify these properties</p>
                    <p>Also in this example code, we style the <b>&lt;button></b> with the class called <code>api-spreadsheets-import-button</code> that exists in the API Spreadsheets library that you imported in <i>line 4</i></p>
                    <p>But you can style the element however you want!</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Clicking the Button</p>
                    <p>Clicking on the button will open a popup modal that will walk the user through the uploading process</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterIntroduction-CodeBegin.png"
                               width="100%"
                               alt='Database Columns' />
                    <p>You can <span className="linkStyle" onClick={e => this.props.setBothMenuItems("usingImporter-introduction")}>read all about how the uploading process works here</span></p>
                </div>
            </div>
        )
    }
}

export default ImporterCode