import React, {Component} from 'react'
import { connect } from 'react-redux'
import {toggleToast} from "../../../../Actions/ToggleToast";
import { Button } from 'semantic-ui-react'
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import API_Root from "../../../../Constants/API_Root";

class ResendValidationEmails extends Component {
    constructor(props){
        super(props);

        this.state = {
            resending: false
        }
    }
    resendValidationEmails = () => {
        const isError = () => this.setState({ resending: false }, () => this.props.toggleToast({ show: true, message: "There was an error resending your validation emails. Please contact our support", type: "error" }))

        this.setState({ resending: true })

        fetch(API_Root + "api/resend-ssl-certificate-validation/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                domain: this.props.validationInfo['DomainName'],
                validationDomain: this.props.validationInfo['ValidationDomain'],
                certificateArn: this.props.certificateArn
            })
        }).then((res) => {
            if (res.status === 200) {
                this.setState({
                    resending: false
                }, () => this.props.toggleToast({ show: true, message: "Validation emails successfully resent", type: "success" }))
            } else {
                isError()
            }
        }).catch(err => isError())


    }

    render() {
        return (
            <Button
                size="mini"
                style={{backgroundColor: gunaldiStyles.mainGreen, color: "white"}}
                icon="paper plane outline"
                content="Resend Validation Emails"
                onClick={this.resendValidationEmails}
                loading={this.state.resending}
            />
        )
    }
}

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(null, mapActionsToProps)(ResendValidationEmails)