import React, { Component } from 'react';
import { connect } from 'react-redux';
import CalculatorTab from './CalculatorTab';
import API_Backend_Root from "../Constants/API_Backend_Root";
import Loader from "react-loader-spinner";
import {Button, Icon} from "semantic-ui-react";
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

class MakeCalculatorIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            calculatorInfo: [],
            sheetNames: [],
            isSaving: false,
            saveStatus: ''
        };
    }

    componentDidMount() {
        this.fetchCalculatorInfo();
    }

    fetchCalculatorInfo = () => {
        fetch(API_Backend_Root + "api/crud-calculator-settings/", {
            method: 'POST',
            body: JSON.stringify({
                action: "get",
                filePK: this.props.pk,
                fileHash: this.props.displayFileHash
            })
        })
            .then(res => res.status === 200 ? res.json() : Promise.reject())
            .then(data => this.setState({
                calculatorInfo: data.calculatorInfo,
                sheetNames: data.sheetNames,
                isFetching: false
            }))
            .catch(() => this.setState({ isFetching: false }));
    }

    addNewCalculator = () => {
        const newCalculator = {
            title: '',
            subtitle: '',
            backgroundColor: '#ffffff',
            labelColor: '#000000',
            saveInSpreadsheet: false,
            inputFields: [],
            outputFields: []
        };
        this.setState({
            calculatorInfo: [...this.state.calculatorInfo, newCalculator]
        });
    }

    updateCalculator = (index, updatedCalculator) => {
        const updatedCalculators = [...this.state.calculatorInfo];
        updatedCalculators[index] = updatedCalculator;
        this.setState({ calculatorInfo: updatedCalculators });
    }

    saveAllCalculators = () => {
        this.setState({ isSaving: true, saveStatus: 'Saving...' });

        const dataToSave = {
            filePK: this.props.pk,
            calculatorInfo: this.state.calculatorInfo,
            action: "create",
            fileHash: this.props.displayFileHash
        };

        fetch(`${API_Backend_Root}api/crud-calculator-settings/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataToSave)
        })
            .then((res) => {
                if (res.status === 201){
                    this.setState({ isSaving: false, saveStatus: 'All calculators saved successfully!' });
                } else {
                    this.setState({ isSaving: false, saveStatus: 'Error saving. Try again.' });
                }
            }).catch(err => {
            this.setState({ isSaving: false, saveStatus: 'Error saving. Try again.' });
        })
    };

    render() {
        return (
            <div>
                {
                    this.state.calculatorInfo.length === 0 ?
                        <Button
                                style={{backgroundColor: "#6FBB98", color: "white"}}
                                icon="file alternate outline" content="Add new calculator" onClick={this.addNewCalculator}/>
                         : null
                }
                {this.state.isFetching ? (
                    <div>
                        <Loader type="TailSpin" color="black" height="50" width="50" />
                        <div style={{fontFamily: "Lato", fontSize: "16px", fontWeight: "700", color: "#161E16"}}>
                            Getting calculator information...
                        </div>
                    </div>
                ) : (
                    this.state.calculatorInfo.map((calculator, index) => (
                        <CalculatorTab
                            key={index}
                            index={index}
                            data={calculator}
                            sheetNames={this.state.sheetNames}
                            onCalculatorChange={this.updateCalculator}
                            displayFileHash={this.props.displayFileHash}
                        />
                    ))
                )}
                <div className="save-button-container" style={{paddingTop: '16px'}}>
                    <Button style={{backgroundColor: gunaldiStyles.blue, color: "white"}} onClick={this.saveAllCalculators} disabled={this.state.isSaving} size='large'>
                        <Icon name='save outline' />
                        {this.state.isSaving ? 'Saving...' : 'Save Calculator Configuration'}
                    </Button>
                    {this.state.saveStatus && <p>{this.state.saveStatus}</p>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(MakeCalculatorIndex);
