const getURLParam = (url) => {
    if (typeof url === "string"){
        if (url.charAt(url.lastIndexOf("/") - 1) === "/"){
            url = url + "/"
        } else {
            url = url.charAt(url.length - 1) === "/" ? url.substring(0, url.length - 1) : url
        }
    }

    return url.substring(url.lastIndexOf("/") + 1, url.length)
}

export default getURLParam