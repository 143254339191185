import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Table, Checkbox, Label } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";

class EditTable extends Component {
    reformatData = () => {
        let reformatData = JSON.parse(JSON.stringify(this.props.editOptions.rows));
        reformatData.unshift(this.props.editOptions.columns)

        return reformatData
    }

    getBasicHeaderNames = () => this.props.editOptions.columns.map((x, idx) => idx.toString())

    changeStartRow = (idx) => this.props.changeState("startRow", idx)

    getDedupedColumnNames = (columnNames) => {
        columnNames = columnNames.map(x => x.toString().trim())
        const nameCount = {}

        for (let i=0; i<columnNames.length; i++){
            let colName = columnNames[i];
            if (colName in nameCount){
                let countIdx = nameCount[colName]
                countIdx.push(i)
                nameCount[colName] = countIdx
            } else {
                nameCount[colName] = [i]
            }
        }

        for (let i=0; i<columnNames.length; i++) {
            let colName = columnNames[i];

            if (colName in nameCount){
                const countIndices = nameCount[colName]

                if (countIndices.length > 1){
                    if (countIndices[0] === i){
                        for (let j=0; j<countIndices.length; j++){
                            columnNames[countIndices[j]] = columnNames[countIndices[j]].toString().trim() + "_" + (j+1).toString()
                        }
                    }
                }
            }
        }

        return columnNames
    }

    changeHeader = () => this.props.changeState("header", !this.props.editOptions.header)

    render() {
        const data = this.reformatData()

        let tableRows = [];
        for (let rowNum=0; rowNum<data.length; rowNum++){
            let row = data[rowNum]
            if (rowNum === this.props.editOptions.startRow){
               row = this.getDedupedColumnNames(row)
            }

            let columnCells = [];
            for (let colNum=0; colNum<row.length; colNum++){
                const val = row[colNum].toString().trim()
                if (rowNum === this.props.editOptions.startRow){
                    if (this.props.editOptions.header){
                        columnCells.push(<Table.HeaderCell key={rowNum.toString() + colNum.toString()}>{val}</Table.HeaderCell>)
                    } else {
                        columnCells.push(<Table.Cell key={rowNum.toString() + colNum.toString()}>{val}</Table.Cell>)
                    }
                } else{
                    if (rowNum < this.props.editOptions.startRow){
                        columnCells.push(<Table.Cell disabled key={rowNum.toString() + colNum.toString()}>{val}</Table.Cell>)
                    } else {
                        columnCells.push(<Table.Cell key={rowNum.toString() + colNum.toString()}>{val}</Table.Cell>)
                    }
                }
            }

            if (rowNum === this.props.editOptions.startRow){
                columnCells.unshift(<Table.HeaderCell key={rowNum.toString() + (-1).toString()}>
                    <Checkbox toggle checked={true} onChange={e => this.changeStartRow(rowNum)} label={<label>Start here?</label>}/>
                    <Checkbox style={{display: "block"}} checked={this.props.editOptions.header} onChange={this.changeHeader} label={<label><small>Column Names?</small></label>} />
                </Table.HeaderCell>)
            } else {
                columnCells.unshift(<Table.Cell key={rowNum.toString() + (-1).toString()}>
                    <Checkbox toggle checked={false} onChange={e => this.changeStartRow(rowNum)} label={<label>Start here?</label>}/>
                </Table.Cell>)
            }

            if (!this.props.editOptions.header){
                if (rowNum === this.props.editOptions.startRow) {
                    const basicHeaderCells = this.getBasicHeaderNames().map((x, idx) => <Table.HeaderCell key={"-1" + idx.toString()}>{x}</Table.HeaderCell>)
                    basicHeaderCells.unshift(<Table.HeaderCell key="-1-1"><Label color="green">New Column Names</Label></Table.HeaderCell>)
                    tableRows.push(<Table.Row key="-1">{basicHeaderCells}</Table.Row>)
                }
            }

            tableRows.push(<Table.Row key={rowNum.toString()}>{columnCells}</Table.Row>)
        }

        return (
            <div id="importEditTableContainer">
                <div id="importEditTable">
                    <Table celled compact='very'>
                        <Table.Body>
                            {tableRows}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editOptions: state.mainState.customImportEditOptions
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(EditTable)