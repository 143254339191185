import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {gunaldiStyles} from "../../../SharedComponents/GunaldiDesignStyles";
import API_Root from "../../../Constants/API_Root";
import {toggleToast} from "../../../Actions/ToggleToast";
import {modifyConnectedDomains} from "../../../Actions/ModifyConnectedDomains";

class ConnectDomainBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            connecting: false
        }
    }

    getConnectedDomainNameFromInfo = (connectedInfo) => {
        return connectedInfo['Distribution'].DistributionConfig.Aliases.Items[0]
    }

    addConnectedDomainNameToRedux = (connectedInfo) => {
        const connectedDomainName = this.getConnectedDomainNameFromInfo(connectedInfo)

        const reduxConnectedDomainNames = this.props.connectedDomains;

        if (reduxConnectedDomainNames === null){
            this.props.modifyConnectedDomains(connectedDomainName)
        } else {
            let copiedDomainNames = JSON.parse(JSON.stringify(reduxConnectedDomainNames))
            copiedDomainNames += "," + connectedDomainName

            this.props.modifyConnectedDomains(copiedDomainNames)
        }
    }

    connectDomain = () => {
        this.setState({ connecting: true })
        const isError = () => this.setState({ connecting: false }, () => this.props.toggleToast({ show: true, type: "error", message: "There was an error connecting your domain. Please try again" }))

        fetch(API_Root + "api/connect-cname-domain/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                domainName: this.props.info.domainName,
                sslSelected: this.props.info.sslSelected,
                sslInfo: this.props.info.sslSelected !== null ? this.props.info.currSSLInfo[this.props.info.sslSelected] : null,
                useSSL: this.props.info.useSSL
            })
        }).then(res => {
            if (res.status === 201){
                res.json().then(data => {
                    this.setState({
                        connecting: false
                    }, () => this.props.toggleToast({ show: true, type: "success", message: "Domain successfully connected!" }))

                    let currDomainInfo = this.props.info.domainInfo;

                    if (currDomainInfo === null){
                        currDomainInfo = [data.domainInfo]
                    } else {
                        currDomainInfo.push(data.domainInfo)
                    }

                    this.props.changeState({ domainInfo: currDomainInfo })

                    try{
                        this.props.modifyConnectedDomains(data.connectedDomains)
                    } catch (e) {
                        console.log(e)
                    }
                }).catch(err => isError())

            } else if (res.status === 406){
                this.setState({ connecting: false }, () => this.props.toggleToast({ show: true, type: "warn", message: "This domain is already connected" }))
            } else if (res.status === 409){
                this.setState({ connecting: false }, () => this.props.toggleToast({ show: true, type: "error", message: "This domain contains an incorrectly configured DNS record that points to another CloudFront distribution. You must update the DNS record to correct the problem"}))
            }
            else {
                isError()
            }
        }).catch(err => isError())
    }

    isDisabled = () => {
        let disabled = false;
        let errorMsg = null
        if (this.props.info.domainNameError){
            disabled = true;
        } else {
            if (this.props.info.useSSL && this.props.info.sslSelected === null){
                disabled = true;
            }
        }

        return {errorMsg: errorMsg, disabled: disabled}
    }

    render() {
        const disabled = this.isDisabled();

        return (
            <div>
                <Button style={gunaldiStyles.btnGreen}
                        content="Connect Your Domain"
                        icon="window maximize outline"
                        onClick={this.connectDomain}
                        disabled={disabled.disabled}
                        loading={this.state.connecting}
                />
                {disabled.errorMsg === null ? null : <div style={{color: gunaldiStyles.red}}>{disabled.errorMsg}</div>}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    connectedDomains: state.mainState.connectedDomains
})

const mapActionsToProps = {
    toggleToast: toggleToast,
    modifyConnectedDomains: modifyConnectedDomains
}

export default connect(mapStateToProps, mapActionsToProps)(ConnectDomainBtn)