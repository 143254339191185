import React, {Component} from 'react'
import {Elements, StripeProvider} from "react-stripe-elements";
import ChangeCreditCardDetails from "../Payment/ChangeCreditCardDetails";
import { Icon, Message } from "semantic-ui-react"
import API_Root from "../../Constants/API_Root";
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux'
import {gunaldiStyles} from "../GunaldiDesignStyles";

class UpdatePaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state={
            brand: "",
            expDate: "",
            last4: "",
            fetching:true,
            error:false
        }
    }

    componentDidMount() {
        const isError = () => { this.setState({ fetching: false, error: true }) }

        setTimeout( () =>
                fetch(API_Root + "api/get-default-customer-credit-card/", {
                    method: "POST",
                    body: JSON.stringify({
                        email: localStorage.getItem("email"),
                        token: localStorage.getItem("token")
                    })
                }).then((res) => {
                    if (res.status === 200){
                        res.json().then(data => {
                            this.setState({
                                brand:data["brand"].toLowerCase(),
                                expDate:data["expDate"],
                                last4:data["last4"],
                                fetching:false
                            })
                        }).catch(err => isError())
                    }
                    else{
                        isError()
                    }
                }).catch(err => isError()) , 1500)
    }

    updateCreditCardInfo = (data) => {
        let brand = "";
        let last4 = "";
        let expDate = "";
        if ('creditCards' in data){
            if (data['creditCards'] !== null){
                for (let i=0; i<data['creditCards'].length; i++){
                    const cardInfo = data['creditCards'][i];

                    if (data['defaultCard'] === cardInfo.id){
                        brand = cardInfo['brand'].toLowerCase()
                        last4 = cardInfo['last4'].toString()
                        expDate = cardInfo['exp_month'].toString() + "/" + cardInfo['exp_year'].toString()

                        this.setState({
                            brand: brand,
                            last4: last4,
                            expDate: expDate,
                        })

                        break
                    }
                }
            }
        }
    }

    render() {
        if (this.props.tier === "free"){
            return (
                <div style={{marginTop: "25px", marginLeft: "1rem", marginBottom: "50px", padding:'15px', backgroundColor:'white', boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
                    <Message header="You are not a customer"
                             content={<span>Please visit your <a href="/profile">Profile</a> to upgrade to a paid account</span>}
                             icon="credit card outline"
                             red
                    />
                </div>
            )
        } else {
            let brandIcon = {
                amex: "cc amex",
                cartes_bancaires: "payment",
                diners_club: "cc diners club ",
                discover: "cc discover",
                jcb: "cc jcb",
                mastercard: "cc mastercard",
                visa: "cc visa",
                unionpay: "payment"
            }

            let iconName = "payment"
            if (this.state.brand in brandIcon){
                iconName = brandIcon[this.state.brand]
            }

            let currCardInfo;
            if (this.state.fetching){
                currCardInfo = <Loader
                    type="TailSpin"
                    color="black"
                    height="20"
                    width="20"
                />
            } else {
                if (this.state.error){
                    currCardInfo = <h6 style={{color: "red"}}>Unable to get your information.
                        Don't worry you can still enter a new Card. You can also refresh</h6>
                } else {
                    currCardInfo = <span>
                    <Icon name={iconName}/>
                    <span style={{padding:'10px',textTransform: 'capitalize'}}><b>{this.state.brand}</b></span>
                    <span>Ending in {this.state.last4}</span>
                    <span style={{float:'right'}}> <b> Exp Date </b>{this.state.expDate}</span>
                </span>
                }
            }

            const isOnProfilePage = 'profilePage' in this.props;

            return (
                <div style={isOnProfilePage ? null : {marginTop: "25px", marginLeft: "1rem", marginBottom: "50px", padding:'15px', backgroundColor:'white', boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
                    {isOnProfilePage ? <div style={{fontWeight: '700', fontSize: "14px", color: gunaldiStyles.subText}}>Current Card Information</div> : <h4> Current Card Information</h4> }
                    <div style={{padding:'10px', marginBottom: "30px", backgroundColor:'whitesmoke'}}>
                        {currCardInfo}
                    </div>
                    <StripeProvider apiKey={window.location.href.includes("localhost") ? "pk_test_1rS8QGgeDU1Ewoyjc8AjaTx0" : "pk_live_nmeaYWsNdAbSbOTRd8MgnGLL"}>
                        <div className="example">
                            {isOnProfilePage ? <div style={{fontWeight: '700', fontSize: "14px", color: gunaldiStyles.subText}}>Enter New Card Information</div> : <h4> Enter New Card Information</h4> }
                            <Elements>
                                <ChangeCreditCardDetails
                                    updateCreditCardInfo={this.updateCreditCardInfo}
                                    noInvoice />
                            </Elements>
                        </div>
                    </StripeProvider>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

export default connect(mapStateToProps, null)(UpdatePaymentForm)
