import React, {Component} from 'react'
import DocsImage from "../../App/Docs/DocsImage"
import TierRestrictionBanner from "../../App/Docs/Actions/TierRestrictionBanner"

class CachingLayerGuide extends Component {
    render() {
        return (
            <div>
                <div style={{marginTop: "-8px", marginBottom: "16px"}}>
                    <TierRestrictionBanner plan="team" payment="cachingLayer"/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Introduction</p>
                        <p>
                            A Caching Layer in API Spreadsheets is an API URL that you can set up for 1 to 12 hours that lets you access your data super fast.
                        </p>
                        <p>
                            This feature is helpful when you need to READ your API data very fast for a short amount of time
                        </p>
                        <p>
                            When you create the layer, your data at that moment in time, will be locked into this caching layer. This data cannot be updated in any way.
                        </p>
                        <p>
                            You can only READ from the caching layer API URL. Now, let’s create a cache layer and see this feature in action.

                        </p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Setting up the caching layer</p>
                        <p>Let’s say I have <a href="https://www.apispreadsheets.com/table/P93eUlGtyrDCuJjK/?accessKey=eae376cfa09387aae4c55339af7ff89a&secretKey=05b6978b69dbe459a769a5ed6efd86ce" target="_blank" rel="noopener noreferrer">this data</a> in a spreadsheet uploaded to my API Spreadsheets account.</p>
                        <p>This has resulted in the following regular API URL: <a href="https://api.apispreadsheets.com/data/P93eUlGtyrDCuJjK/?accessKey=eae376cfa09387aae4c55339af7ff89a&secretKey=05b6978b69dbe459a769a5ed6efd86ce" target="_blank" rel="noopener noreferrer">https://api.apispreadsheets.com/data/P93eUlGtyrDCuJjK/?accessKey=eae376cfa09387aae4c55339af7ff89a&secretKey=05b6978b69dbe459a769a5ed6efd86ce</a></p>
                        <DocsImage src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/CachingLayer/FileDashboard.png"
                             width="100%" alt="File Dashboard"/>
                        <p> </p>
                        <p>If I request data from this regular API URL, it takes me an average of 1.0586638450622559 seconds. <small>You can view the code we wrote to time the requests <a href="https://gist.github.com/asharma327/f4b310582c0c86a6cf161ea82dc6aeaa" target="_blank" rel="noopener noreferrer">here</a></small></p>
                        <p>This prompts me to create a caching layer</p>
                        <p>Click on the <b>Caching Layer</b> menu item under Actions, for <i>each</i> File you wish to set up the caching layer</p>
                        <DocsImage src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/CachingLayer/ActionsCachingLayer.png" alt="Actions Caching Layer"/>
                        <p> </p>
                        <p>This will bring up the following screen where you can customize your caching layer</p>
                        <DocsImage src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/CachingLayer/CustomizeCachingLayer.png" width="100%" alt="Customize caching Layer" />
                        <p> </p>
                </div>
                <div className="docsSection">
                        <p className="docsHeader">Hours caching layer should be active</p>
                        <p>Enter the number of hours (in whole number) for which you wish to use this layer. The number of hours cannot exceed 12.</p>
                        <DocsImage src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/CachingLayer/HoursActiveInput.png" alt="Hours Caching Layer Active"/>
                        <p> </p>
                        <p>Once you enter your hours, you will see the date and time until which the layer will be active. This date time will be in your time zone.</p>
                </div>
                <div className="docsSection">
                        <p className="docsHeader">Add Query</p>
                        <p>You can optionally add a query to filter your data before it is inserted into the layer. If you do not wish to add a query, just leave this field blank.</p>
                        <DocsImage src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/CachingLayer/AddQueryInput.png" alt="Add Query Input"/>
                        <p> </p>
                </div>
                <div className="docsSection">
                        <p className="docsHeader">View Data</p>
                        <p>Finally, you can view the data that will be in your caching layer by clicking on the <i>See data…</i> link.</p>
                        <DocsImage src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/CachingLayer/ViewDataTimer.png" alt="Caching layer timer" />
                        <p> </p>
                </div>
                <div className="docsSection">
                        <p className="docsHeader">Using the caching layer</p>
                        <p>Once again, this data can NOT be updated in the caching layer. You can only READ from the caching layer API URL.</p>
                        <p>Once you Create Caching Layer, you will get the API URL of the caching layer, and see a timer with the expiration date of the caching layer like below</p>
                        <DocsImage src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/CachingLayer/CreateCachingLayer.png" width='100%' alt="Create caching layer" />
                        <p> </p>
                </div>
                <div className="docsSection">
                        <p className="docsHeader">API URL for Caching Layer</p>
                        <p>You can make GET requests to this API URL to READ the data. Just as you would to the regular API URL</p>
                        <p>If the request is successful, the data will always be returned in the <b>Row</b> format with a <b>200</b> status code, as seen below</p>
                        <DocsImage src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/CachingLayer/JSONObj.png" alt="Caching layer JSON" />
                        <p> </p>
                        <p>If there are any errors, an appropriate error message and code will be returned as specified in the <span className="linkStyle" onClick={e => this.props.setBothMenuItems("api-introduction")}>API Docs</span></p>
                        <p>The most common error will be a <b>401 Unauthorized</b> error. </p>
                        <p>This could happen either if you fail to include your access and secret keys IF you had the API Private when you created the caching layer. Or if you have whitelisted a set of domains and make the request from outside those domains.</p>
                        <p>Finally, If I run the timer code on this API URL, I get an average time of 0.403367805480957 seconds. Which is a whopping <b>62% faster</b> than requesting from the regular API URL.</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Caching Layer Expiry</p>
                        <p>When the time limit expires you will see a message saying that the caching layer has expired and your URL will no longer be valid.</p>
                        <p>Any requests to this URL after the caching layer has expired will return a <b>404 status code</b>.</p>
                        <DocsImage src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/CachingLayer/CachingLayerExpiry.png" width='100%' alt="caching layer expiry" />
                        <p> </p>
                    <p>Finally, you can view the data in the caching layer. Or delete the caching layer all together.</p>
                </div>
            </div>
        )
    }
}

export default CachingLayerGuide