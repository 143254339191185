import React, {Component} from 'react'
import {connect} from 'react-redux'
import constants from "../../../Constants/constants";
import RereadFile from "./RereadFile"

class UploadAWSS3 extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const imgStyle = {border:"1px solid #F0F0F0", display: "block", marginBottom: "16px"}

        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">Connect AWS S3 Account</p>
                    <p>You can connect your AWS S3 account by providing:</p>
                    <div className="docsList">
                        <p>1. An Account Name</p>
                        <p>2. Access Key to your AWS Account</p>
                        <p>3. Secret Key to your AWS Account</p>
                    </div>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/EnterAccountInfo1.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="select aws s3" />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Connect AWS S3 Account - Account Name</p>
                    <p><b>Account Name</b></p>
                    <p>This is a unique identifier for API Spreadsheets so it could be anything you wish. It has nothing to do with your AWS S3 account</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Connect AWS S3 Account - Access Key & Secret Key</p>
                    <p>You need to provide both the <a href="https://aws.amazon.com/blogs/security/wheres-my-secret-access-key/" target="_blank" rel="noopener noreferrer">Access Key and Secret Key from your AWS account</a></p>
                    <p>For optimal security, you can create these keys so they <a href="https://docs.aws.amazon.com/secretsmanager/latest/userguide/auth-and-access_examples.html" target="_blank" rel="noopener noreferrer">only access S3</a> and nothing else from your AWS account</p>
                    <p>Here is a summary of how to create and obtain these keys from your AWS account</p>
                    <p>1. <b>Search</b> and go to the <b>IAM</b> page from your AWS Console</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/SearchIAM2.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="search IAM page" />
                    <p>2. Click on <b>Add Users</b> under the users tab of the IAM page</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/AddUsers3.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="add users" />
                    <p>3. Provide a username and <b>select</b> the access key option on Step 1 of the Add User signup flow</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/UsernameAPIAccess4.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="api access" />
                    <p>4. Select <b>AmazonS3FullAccess</b> policy under Attach existing policies directly on Step 2</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/AddPolicy5.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="add policy" />
                    <p>5. Keep clicking through the prompts until user is created as seen below. <b>Copy</b> the <b>Access key ID</b> and the <b>Secret access key</b></p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/CopyAccessSecretKey6.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="copy keys" />
                    <p>6. Use these to connect to your AWS S3 account on API Spreadsheets</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Selecting a File</p>
                    <p>You can select any of these files from your S3 Buckets: {constants.extensions.join(",")}</p>
                    <p>1. Click on the <b>account</b> you wish to choose the buckets from</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/SelectAWSS3Account7.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="add users" />
                    <p>2. Navigate the buckets and folders to find the file you wish to select</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/SelectAWSS3Account7.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="navigate s3 bucket" />
                    <p>If your bucket and folder is empty that means you have no files from the following extensions: {constants.extensions.join(",")}</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">File Read</p>
                    <RereadFile setBothMenuItems={this.props.setBothMenuItems} hasCsv={true} fileNames={<b>excel files</b>}/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Managing your AWS S3 Accounts</p>
                    <p>You can <span className="linkStyle" onClick={e => this.props.setBothMenuItems("integrations-awsS3")}>read all about how to manage your AWS S3 Accounts here</span></p>
                </div>
                {/*Prompts to select S3 account*/}
                {/*Get access and secret keys*/}
                {/*Account name is unique and only relevant to API Spreadsheets*/}
                {/*copy and paste intro article from here*/}
                {/*mention where to manage these accounts*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UploadAWSS3)