import React, {Component} from 'react'
import Loader from 'react-loader-spinner'

class FetchLoader extends Component {
    render() {
        if (this.props.fetching){
            return (
                <span><Loader
                    type="TailSpin"
                    color="black"
                    height="20"
                    width="20"
                /></span>
            )
        } else{
            return (
                null
            )
        }

    }
}

export default FetchLoader