import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import { push } from 'react-router-redux'
import FeedbackNavLink from "../Feedback/FeedbackNavLink"
import NavbarHelp from "./NavbarHelp";
import {modifyNavbarItemClicked} from "../../Actions/ModifyNavbarItemClicked";
import {gunaldiStyles} from "../GunaldiDesignStyles";


const isLinkSelected = (windowURL, linkTo, navbarItemClicked) => {
    if (linkTo !== "api"){
        return windowURL.indexOf(linkTo) !== -1 || navbarItemClicked === linkTo
    } else {
        if (windowURL.includes("localhost")){
            return windowURL.indexOf("/api") !== -1
        } else{
            return windowURL.indexOf(".com/api") !== -1
        }
    }

}


class NavbarItem extends Component {
    constructor(props){
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.goToURL = this.goToURL.bind(this);
    }

    toggleModal(e){
        this.props.toggleModal(this.props.to)
    }

    goToURL(e){
        this.props.modifyNavbarItemClicked(this.props.to)
        this.props.navigateTo("/" + this.props.to)

        if ('toggleOpen' in this.props){
            this.props.toggleOpen(e)
        }
    }

    render() {
        let leftIcon = null;
        let rightIcon = null;

        const style = {color: this.props.color, cursor: 'pointer', textDecoration: 'none', fontSize: '115%', marginTop: '6px'};

        if ('icon' in this.props){
            if ('iconPosition' in this.props){
                if (this.props.iconPosition === "left"){
                    leftIcon = <Icon name={this.props.icon} />
                }
                else{
                    rightIcon = <Icon name={this.props.icon} />
                }
            }
            else{
                leftIcon = <Icon name={this.props.icon} />
            }
        }

        let cname = "navLink"
        if (this.props.to === "import" || 'importer' in this.props){
            cname = "importNavLink"
        } else if (this.props.to === "utilities" || 'utilities' in this.props){
            cname = "utilitiesNavLink"
        }

        if (this.props.to === "feedbackModal"){
            return(
                <FeedbackNavLink style={style} />
            )
        }
        else if (this.props.to === "help"){
            return(
                <NavbarHelp style={style} name={this.props.name}/>
            )
        }
        else if ('modal' in this.props){
            return(
                <span onClick={this.toggleModal} className={cname} style={style}>{leftIcon}{this.props.name}{rightIcon}</span>
            )
        }
        else if ('external' in this.props) {
            return(
                <a className={cname} href={this.props.to} style={style} target="_blank" rel="noopener noreferrer">
                {leftIcon}{this.props.name}{rightIcon}</a>
            )
        }
        else{
            if (isLinkSelected(window.location.href, this.props.to, this.props.navbarItemClicked)){
                style['fontWeight'] = 'bold'
                style['marginBottom'] = "3px"

                if (this.props.to === "import" || 'importer' in this.props){
                    style['borderBottom'] = "4px solid " + gunaldiStyles.orange
                } else if (this.props.to === "utilities" || 'utilities' in this.props){
                    style['borderBottom'] = "4px solid " + gunaldiStyles.blue
                }
                else {
                    style['borderBottom'] = "4px solid #6FBB98"
                }

            }
            else{
                style['fontWeight'] = '500'
                style['borderBottom'] = "none"
                style['marginBottom'] = "0"
            }

            return (
                <span onClick={this.goToURL} style={style}><span className={cname}>{leftIcon}{this.props.name}{rightIcon}</span></span>
                // <a href={"/" + this.props.to} style={style}>
                    // {leftIcon}{this.props.name}{rightIcon}</a>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    navbarItemClicked: state.mainState.navbarItemClicked
});

const mapActionsToProps = {
    toggleModal: toggleModal,
    navigateTo: push,
    modifyNavbarItemClicked: modifyNavbarItemClicked
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarItem)