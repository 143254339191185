import React, {Component} from 'react'
import {connect} from 'react-redux'
import FileOptions from "../FileOptions"
import getURLParam from "../../Constants/getURLParam";
import ShareButton from "../ShareButton"
import {mapPkAndPkid} from "../../Constants/mapPkAndPkid";
import ColumnNames from "../ColumnNames"

class VisualizationDisplay extends Component {
    render() {
        if (this.state.vizInfo !== null ){
            const pk = getURLParam(window.location.href)
            const pkID = mapPkAndPkid(this.props.filePK, "pk", pk);

            return (
                <div>
                    <div className="row">
                        <div className="col-sm-3">

                        </div>
                        <div className="col-sm-6">
                            <div style={{float: 'right', width: '100%'}}>
                                <FileOptions
                                    visualizationButton={null}
                                    // testQuery={isBrowser ? <TestQueryButton pkID={this.props.pkID} /> : null}
                                    alertsButton={null}
                                    shareButton={<ShareButton pk={pk} outputFormat="jsonRow" />}
                                    whitelistDomainsButton={null}
                                    analyticsButton={null}
                                    downloadFileButton={null}
                                    reuploadFile={null}
                                    blockRequestsButton={null}
                                    keysButton={null}
                                    columnNames={<ColumnNames pkID={pkID} pk={pk} />}
                                    fileDescription={null}
                                    importSheets={null}
                                    editFile={null}
                                    removeFile={null}
                                />
                            </div>

                        </div>
                        <div className="col-sm-3">
                        </div>
                    </div>
                </div>
            )
        } else{
            return (
                null
            )
        }

    }
}

const mapStateToProps = (state) => ({
    vizInfo: state.mainState.vizInfo,
    filePK: state.mainState.filePK
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(VisualizationDisplay)