import React, {Component} from 'react'
import { isMobile } from 'react-device-detect'
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

class LandingPageFeatureSegment extends Component {
    render() {
        let icon = null;
        if ('icon' in this.props){
            icon = <img src={this.props.icon} style={{width: "44px", height: "44px"}} alt="Feature icon" />
        }

        let featureImg = null;

        if ('img' in this.props){
            featureImg = <div><img src={this.props.img} alt="Feature display" style={{width: "100%"}}/></div>
        } else {
            featureImg = <div style={{width: "100%"}}>{this.props.display}</div>
        }

        let header = <div style={{fontWeight: "700", fontSize: isMobile ? "28px": "32px", color: gunaldiStyles.mainText}}>{this.props.header}</div>;
        let categoryHeader = <div style={{fontWeight: "700", fontSize: "14px", color: gunaldiStyles.secondaryText}}>{this.props.category.toUpperCase()}</div>;
        let desc = <div style={{fontWeight: "400", fontSize: isMobile ? "16px" : "18px", color: gunaldiStyles.subText}}>{this.props.desc}</div>

        const divMarginStyle = {marginBottom: "16px"};
        const textBox = <div>
            <div style={divMarginStyle}>
                {icon}
            </div>
            <div style={divMarginStyle}>
                {categoryHeader}
            </div>
            <div style={divMarginStyle}>
                {header}
            </div>
            <div style={divMarginStyle}>
                {desc}
            </div>
        </div>

        if (isMobile){
            return (
                <div style={{textAlign: "center"}}>
                    {textBox}
                    <div style={divMarginStyle}>
                        {featureImg}
                    </div>
                </div>
            )
        } else {
            if (this.props.textLocation === "left"){
                return (
                    <div style={{display: "grid", gridTemplateColumns: "2fr 3fr", columnGap: "48px"}}>
                        {textBox}
                        {featureImg}
                    </div>
                )
            } else {
                return (
                    <div style={{display: "grid", gridTemplateColumns: "3fr 2fr", columnGap: "48px"}}>
                        {featureImg}
                        {textBox}
                    </div>
                )
            }
        }
    }
}

export default LandingPageFeatureSegment