import React, {Component} from 'react'
import ModalApp from "../../SharedComponents/Modal"
import { Icon } from 'semantic-ui-react'
import EditDatabaseModalBody from "./EditDatabaseModalBody"

class EditDatabaseModal extends Component {
    render() {
        return (
            <ModalApp name="editDatabaseModal"
                      header={<h2 className="thinHeading"><Icon name="edit alternate outline" /> Edit Database Info</h2>}
                      size="large"
                      body={<EditDatabaseModalBody/>}
            />
        )
    }
}

export default EditDatabaseModal