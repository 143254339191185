import React, {Component} from 'react'
import {toggleModal} from "../../../Actions/ToggleModal";
import {connect} from "react-redux";
import {modifyEditID} from "../../../Actions/ModifyEditID";
import {modifyModalQueryQuickstartType} from "../../../Actions/ModifyModalQueryQuickstartType";
// import EmptyFileWarning from "./EmptyFileWarning";

class UpdateOptionsToolbar extends Component {
    constructor(props) {
        super(props);

        this.openQueryModal = this.openQueryModal.bind(this);
    }

    openQueryModal(e){
        this.props.modifyEditID(this.props.pkID)
        this.props.modifyModalQueryQuickstartType("updateAction")
        this.props.toggleModal("queryQuickstartModal")
    }

    render() {
        return (
            <div className="fileSummaryCodeToolbar">
                <span> A Query is required to update values. <span className="linkStyle" onClick={this.openQueryModal}>Read how</span> </span>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyEditID: modifyEditID,
    modifyModalQueryQuickstartType: modifyModalQueryQuickstartType
}

export default connect(null, mapActionsToProps)(UpdateOptionsToolbar)