import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import { Icon } from 'semantic-ui-react'
import ImportConfigDeleteModalBody from "./ImportConfigDeleteModalBody"
import { connect } from 'react-redux'
import isObjectEmpty from "../../Constants/isObjectEmpty";

class ImportConfigDeleteModal extends Component {
    render() {
        let importHash = null;
        let importName = null;
        let idx = null;
        let custom = null;

        if (this.props.modalInfo !== null){
            if (!isObjectEmpty(this.props.modalInfo)){
                if ("importHash" in this.props.modalInfo){
                    importHash = this.props.modalInfo.importHash
                    importName = this.props.modalInfo.importName
                    idx = this.props.modalInfo.idx
                    custom = this.props.modalInfo.custom
                }
            }
        }

        if (idx === null){
            return null
        } else {
            return (
                <ModalApp name="importConfigDeleteModal"
                          header={<h4><Icon name="trash alternate outline" /> Delete {importName}</h4>}
                          size="medium"
                          body={<ImportConfigDeleteModalBody
                              importHash={importHash}
                              importName={importName}
                              custom={custom}
                              idx={idx}/>}
                />
            )
        }
    }
}

const mapStateToProps = (state) => ({
    importSheetsInfo: state.mainState.importSheetsInfo,
    modalInfo: state.mainState.modalInfo
})


export default connect(mapStateToProps, null)(ImportConfigDeleteModal)