export const getQuery = (fileInfo, pk, queryType) => {
    let query;
    if (queryType === "delete"){
        query = "delete from " + pk + " where " + filterClause(fileInfo)
    }
    else{
        query = "select * from " + pk + " where " + filterClause(fileInfo)
    }

    return query
}

const firstColName= (fileInformation) => {
    let colName = "col1";

    if ('columnInformation' in fileInformation){
        if ('names' in fileInformation['columnInformation']){
            if (fileInformation['columnInformation']['names'].length > 0){
                colName = fileInformation['columnInformation']['names'][0]
            }
        }
    }

    return colName
}

const firstValue = (fileInformation, colName) => {
    const colValuesSample = fileInformation["jsonColumnSample"][colName]

    let firstValue = "'value'"
    if (colValuesSample.length > 0){

        if (typeof colValuesSample[0] === "string"){
            firstValue = "'" + colValuesSample[0] + "'"
        }
        else{
            firstValue = colValuesSample[0].toString()
        }
    }

    return firstValue

}

const filterClause = (fileInformation) =>{
    const colName = firstColName(fileInformation)

    let colValue = "'value'"
    if (colName !== "col1"){
        colValue = firstValue(fileInformation, colName)
    }

    return colName + "=" + colValue
}