import React, {Component} from 'react'
import CompletePaymentForm from './CompletePaymentForm'
import images from '../../Constants/Images'
import { Image } from 'semantic-ui-react'

class CompletePaymentIndex extends Component {
    componentDidMount(){
        document.title = "Complete Payment - API Spreadsheets"
    }

    render() {
        return (
            <div>
                <div className="row" style={{backgroundColor: 'whitesmoke', minHeight: '100vh'}}>
                    <div className="col-sm-3" />
                    <div className="col-sm-6">
                        <br/><br/><br/>
                        <div style={{marginLeft: '1rem'}}>
                            <Image style={{marginTop: '5px'}} src={images.logoNav} href="/" size="medium"/>
                        </div>
                        <CompletePaymentForm/>
                        <br/>
                    </div>
                    <div className="col-sm-3" />
                </div>
            </div>
        )
    }
}

export default CompletePaymentIndex