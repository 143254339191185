import React, {Component} from 'react'
import ReactJson from 'react-json-view'
import { Dropdown } from 'semantic-ui-react'
import DocsPostResponseCode from "./DocsPostResponseCode";

class DocsPostBodyReq extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataFormat: 'jsonRow'
        }

        this.handleFormatChange = this.handleFormatChange.bind(this);
    }

    handleFormatChange(event, {value}){
        this.setState({
            dataFormat: value
        })
    }

    render() {
        const responseBody =
            {
                jsonRow: {
                    "data": [
                        {
                            "name": "Bon Jovi",
                            "address": "Cool Dr",
                            "isCustomer": true,
                            "totalSales": 45.43
                        },
                        {
                            "name": "Tom Hanks",
                            "address": "Awesome Ave",
                            "isCustomer": false,
                            "totalSales": 0
                        }
                    ]
                },
                matrix: {
                    "dataFormat": "matrix",
                    "data":
                        [
                            ["Bon Jovi", "Cool Dr", true, 45.53],
                            ["Tom Hanks", "Awesome Ave", false, 0]
                        ]
                },
                jsonColumn: {
                    "dataFormat": "jsonColumn",
                    "data": {
                        "name": ["Bon Jovi", "Tom Hanks"],
                        "address": ["Cool Dr", "Awesome Ave"],
                        "isCustomer": [true, false],
                        "totalSales": [45.53, 0]
                    }
                }
            }

        const dataFormatOptions = [
            {key: 1, text: 'jsonRow', value: 'jsonRow'},
            {key: 2, text: 'matrix', value: 'matrix'},
            {key: 3, text: 'jsonColumn', value: 'jsonColumn'},
        ];

        const dataFormat = this.state.dataFormat;

        let responseCodes = null;
        let boxClassName = "getResponseBoxLanding";

        if (!('landing' in this.props)){
            responseCodes = <DocsPostResponseCode/>;
            boxClassName = "getResponseBox";
        }

        return (
            <div className={boxClassName}>
                <div className="getResponseHeading">
                    <span style={{float: 'left'}}><h6 className="thinHeading">Request Body</h6></span>
                    <span style={{float: 'right'}}> {' '}
                        <Dropdown
                            placeholder="Select Data Format"
                            name="dataFormat"
                            value={dataFormat}
                            options={dataFormatOptions}
                            onChange={this.handleFormatChange}
                            inline
                        />
                    </span>
                </div>
                <ReactJson src={responseBody[dataFormat]} name={null} theme="ocean" displayObjectSize={false}/>
                {responseCodes}
            </div>
        )
    }
}

export default DocsPostBodyReq