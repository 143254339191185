import React, {Component} from 'react'

class UploadSourceLink extends Component {
    // changeMenuItems = () => this.goToMenuLink.click()
    changeMenuItems = () => {
        this.props.setBothMenuItems("upload-" + this.props.name)
    }

    render() {
        return (
            <div className="linkStyle" onClick={this.changeMenuItems}>
                <i className={this.props.icon} style={{ fontSize: "18px", verticalAlign: "middle", marginTop: "-2px" }} />
                <span style={{paddingLeft: "8px", fontSize: "14px"}}>{this.props.label}</span>
            </div>
        )
    }
}

export default UploadSourceLink