import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class RereadFile extends Component {
    render() {
        return (
            <div>
                <p>When you select a file, it is automatically read with the following default settings</p>
                <div className="docsList">
                    {this.props.hasCsv ? <p><Icon name="caret right"/> <b>.csv</b> or <b>.txt</b> files are default read with comma (,) delimiter</p> : null}
                    <p><Icon name="caret right"/> All {this.props.fileNames} are default read from their first sheet, e.g., <b>Sheet1</b></p>
                    <p><Icon name="caret right"/> All files are default read by assuming row 1 are the column names</p>
                </div>
                {'rereadIndex' in this.props ? null : <p>Don't worry if your file needs to be read with different settings. You can <span className="linkStyle" onClick={e => this.props.setBothMenuItems("upload-reread")}>re-read your file with different settings easily</span></p>}
            </div>
        )
    }
}

export default RereadFile