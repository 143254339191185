import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import API_Root from "../Constants/API_Root";
import {dateToString} from "../Constants/dateToString";
import {toggleToast} from "../Actions/ToggleToast";

class DownloadLogs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            downloading: false,
            downloadURL: ''
        }

        this.downloadLogs = this.downloadLogs.bind(this);
    }

    downloadLogs(e){
        this.setState({
            downloading: true
        })

        // Open up WebSockets
        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/download-api-logs/");


        // Send the PKs and if any column names
        socket.onopen = (event) => {
            const downloadDate = dateToString(new Date());

            socket.send(JSON.stringify({
                pk: this.props.pk,
                downloadTime: downloadDate.date + downloadDate.time,
                webhook: this.props.webhook
            }));
        };

        socket.onmessage = (event) => {
            const reply = JSON.parse(event.data);
            const url = reply['url'];

            if (url === null){
                this.setState({
                    downloading: false
                })
                this.props.toggleToast({show: true, message: "Sorry there was an error downloading your file :(", type: "error"})
            }
            else{
                this.setState({downloadURL: url, downloading: false});
                this.downloadLink.click();
            }

            socket.close()
        }
    }

    render() {
        const url = this.state.downloadURL;
        return (
            <div>
                <a
                    style={{display: 'none'}}
                    ref={downloadLink => this.downloadLink = downloadLink}
                    href={url}
                >Download hidden</a>
                <Button icon="download"
                        content="Download Full Logs"
                        style={{backgroundColor: "white", border: "1px solid #6FBB98", borderRadius: "4px", color: "#6FBB98"}}
                        onClick={this.downloadLogs}
                        loading={this.state.downloading}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    analyticsID: state.mainState.analyticsID
})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(DownloadLogs)