import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Message, Icon } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {modifyAllDBInfo} from "../../Actions/ModifyAllDBInfo";
import isObjectEmpty from "../../Constants/isObjectEmpty";
import {modifyAllQueries} from "../../Actions/ModifyAllQueries";
import {toggleToast} from "../../Actions/ToggleToast";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

class SaveNewQuestion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSaving: false,
            testErrorMessage: {},
            url: ''
        }

        this.saveNewQuestion = this.saveNewQuestion.bind(this);
        this.addQuestionToAllQueries = this.addQuestionToAllQueries.bind(this);
    }

    saveNewQuestion(e){
        //    Save fetch and if he does then add to the query list in the front
        this.setState({ isSaving: true })

        const errorSaving = () => this.setState({ isSaving: false }, () => this.props.toggleToast({show: true, message: "There was an error saving. Please try again. Contact support if this error persists", type: "error"}))

        fetch(API_Root + "api/save-api-db-query/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                dbHash: this.props.editQueryInfo.dbHash,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                name: this.props.info.name,
                query: this.props.info.query,
                queryPK: this.props.info.queryPK
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.addQuestionToAllQueries(data.newQuestionInfo)
                    this.setState({ isSaving: false })
                }).catch(err => errorSaving())
            } else if (res.status === 402) {
                // show the file length modal here
            } else {
                errorSaving()
            }
        }).catch(err => errorSaving())

    }

    addQuestionToAllQueries(newQuestionInfo){
        const queryInfo = JSON.parse(JSON.stringify(newQuestionInfo))

        const errorMessage = queryInfo.errorMessage;
        delete queryInfo['errorMessage']

        const fileHash = queryInfo.fileHash;
        delete queryInfo['fileHash']

        const idx = this.props.editQueryInfo.idx;
        const allQueries = JSON.parse(JSON.stringify(this.props.allQueries))

        allQueries[idx] = queryInfo

        this.props.modifyAllQueries(allQueries)

        if (!newQuestionInfo.isTested) {
            this.setState({
                testErrorMessage: errorMessage
            })
        } else {
            this.setState({
                url: "/files/" + fileHash
            }, () => this.dashLink.click())
        }

    }

    render() {
        // const newQuestionInfo = this.props.unsavedNewQuestions;
        const name = this.props.info.name;
        const query = this.props.info.query;

        return (
            <div>
                <a href={this.state.url} ref={dashLink => this.dashLink = dashLink} style={{display: "none"}}>Hidden</a>
                <Button disabled={name.trim() === "" && query.trim() === ""}
                        loading={this.state.isSaving} onClick={this.saveNewQuestion}
                        style={{...gunaldiStyles.btnGreen, ...{width: "100%"}}}
                        size="large" content="Save API" icon="save outline" />
                {name.trim() === "" && query.trim() === "" ? <span><small style={{color: 'red'}}><b><i>Please enter an API name or query before saving</i></b></small><br/></span> : null}
                {
                    !isObjectEmpty(this.state.testErrorMessage) ?
                        <div>
                            <p style={{color: "green"}}>Your API was saved! But it won't be usable and won't appear in your <span><span><Icon name="file alternate" />Files</span></span> until you fix the following errors</p>
                            <Message
                                header={this.state.testErrorMessage.header}
                                content={this.state.testErrorMessage.content}
                                icon="database"
                                negative
                            />
                        </div>: null
                }
                {/*<small style={{color: 'purple'}}><b>After the query is saved, you will be able to download your full results!</b></small>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allDBInfo: state.mainState.allDBInfo,
    modalInfo: state.mainState.modalInfo,
    allQueries: state.mainState.allQueries,
    editQueryInfo: state.mainState.editQueryInfo
})

const mapActionsToProps = {
    modifyAllDBInfo: modifyAllDBInfo,
    modifyAllQueries: modifyAllQueries,
    toggleToast: toggleToast
}

export default  connect(mapStateToProps, mapActionsToProps)(SaveNewQuestion)