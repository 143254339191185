import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyEditID} from "../../Actions/ModifyEditID";

class AlertsButton extends Component {
    openModal = e => {
        this.props.modifyEditID(this.props.pkID);
        this.props.toggleModal("alertsModal")
    }

    render() {
        return (
            <div>
                <Button content="Success/Error Alerts" basic color='blue' icon='paper plane outline' onClick={this.openModal} fluid/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyEditID: modifyEditID
}

export default connect(mapStateToProps, mapActionsToProps)(AlertsButton)