export const appSumoPlanPricing = {
    "apispreadsheets_tier1":
        {
            "rowMax": 50000,
            "requests": 50000,
            "files": 50,
            "team": 5,
            "secure": true,
            "price": 69,
            "support":'Dedicated',
            "blockRequests":true,
            "alerts":true,
            "multipleKeys":true,
            "domainWhitelist":true,
            "autoEmail":true,
            "webhooks":true,
            "database":true,
            "customizeImporter":true,
            "cachingLayer":true,
            "connectDomains":true,
            "requestsFrequency":"daily"
        },
    "apispreadsheets_tier2":
        {
            "rowMax": 100000,
            "requests": 100000,
            "files": 150,
            "team": 10,
            "secure": true,
            "price": 149,
            "support":'Dedicated',
            "blockRequests":true,
            "alerts":true,
            "multipleKeys":true,
            "domainWhitelist":true,
            "autoEmail":true,
            "webhooks":true,
            "database":true,
            "customizeImporter":true,
            "cachingLayer":true,
            "connectDomains":true,
            "requestsFrequency":"daily"
        },
    "apispreadsheets_tier3":
        {
            "rowMax": 250000,
            "requests": 250,
            "files": 250000,
            "team": 15,
            "secure": true,
            "price": 299,
            "support":'Dedicated',
            "blockRequests":true,
            "alerts":true,
            "multipleKeys":true,
            "domainWhitelist":true,
            "autoEmail":true,
            "webhooks":true,
            "database":true,
            "customizeImporter":true,
            "cachingLayer":true,
            "connectDomains":true,
            "requestsFrequency":"daily"
        },
    "apispreadsheets_tier4":
        {
            "rowMax": 750000,
            "requests": 750000,
            "files": 750,
            "team": 20,
            "secure": true,
            "price": 349,
            "support":'Dedicated',
            "blockRequests":true,
            "alerts":true,
            "multipleKeys":true,
            "domainWhitelist":true,
            "autoEmail":true,
            "webhooks":true,
            "database":true,
            "customizeImporter":true,
            "cachingLayer":true,
            "connectDomains":true,
            "requestsFrequency":"daily"
        }
}