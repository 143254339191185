import React, {Component} from 'react'
import {connect} from 'react-redux'
import ReactJson from 'react-json-view'
import isObjectEmpty from "../Constants/isObjectEmpty";
// import {getPostData} from "../Constants/getPostData";
import {getFileURL} from "../Constants/getFileURL";
import {getFileHash} from "../Constants/getFileHash";

class JSONOutputPreview extends Component {
    constructor(props) {
        super(props);

        this.makeMessage = this.makeMessage.bind(this);
    }

    makeMessage(jsonText, pkFileInfo){
        const outputFormat = this.props.outputFormat;

        const row = pkFileInfo['rowLength'];
        const col = pkFileInfo['colLength'];

        let message;

        // jsonRow, matrix, jsonColumn
        if(outputFormat === "jsonRow" || outputFormat === "matrix" || outputFormat === "row"){
            const sampleLength = jsonText['data'].length;
            message = "Showing " + sampleLength.toString() + " out of " + row.toString() + " rows"
        }
        else if (outputFormat === "jsonColumn" || outputFormat === "column") {

            let sample;

            if (!isObjectEmpty(jsonText)){
                for (let key in jsonText){
                    sample = jsonText[key].length;
                    break
                }
            }
            else{
                sample = 0;
            }

            message = "Showing all " + col.toString() + " columns. Showing " + sample.toString() + " out of " + row.toString() + " row values for each column"
        }
        else{
            message = "Showing all " + col.toString() + " columns"
        }

        return message
    }

    render() {
        const pkFileInfo = this.props.fileInformation[this.props.pkID];
        let outputFormat = this.props.outputFormat;
        let jsonText;

        let message;
        let heading;

        if (outputFormat==="lazyMode"){
            jsonText = this.props.outputJson

            heading = ""
            message = ""
        }
        else{
            if (this.props.apiType === "readAction" && this.props.count){
                heading = "Count Response: "
                message = "This is a sample. The real count of rows will be determined live depending on file rows at that moment or the query"

                jsonText = {"count": pkFileInfo['rowLength'] > this.props.rows ? this.props.rows: pkFileInfo['rowLength']}
            } else {
                if (outputFormat === "columnValue"){
                    // const jsonStr = getPostData(pkFileInfo, "columnValue", this.props.apiType, this.props.pk, false, null).replace(/[\u0000-\u001F\u007F-\u009F]/g, "").replace(/[\n\r\t\b]/g, "");
                    // console.log(jsonStr)
                    // jsonText = JSON.parse(jsonStr)
                    let startText = pkFileInfo["jsonRowSample"]

                    if ("data" in startText){
                        if (startText['data'].length > 0){
                            jsonText = {"data": startText['data'][0]}
                        } else { jsonText = {}}
                    } else {jsonText = {}}

                }
                else {
                    if (outputFormat === "row"){
                        outputFormat = "jsonRow"
                    }
                    else if (outputFormat === "column"){
                        outputFormat = "jsonColumn"
                    }

                    jsonText = pkFileInfo[outputFormat + "Sample"];
                }

                if (this.props.apiType === "updateAction"){

                    let readURL = getFileURL(this.props.fileInformation, getFileHash(this.props.fileInformation[this.props.pkID], this.props.pk), this.props.pkID, "jsonRow", "readAction", this.props.rowLen, this.props.rows, true)
                    const startIdx = readURL.indexOf("query=") + "query=".length

                    let substrURL = readURL.substring(startIdx, readURL.length)

                    let endIdx = substrURL.length;

                    if (substrURL.indexOf("&limit=") !== -1){
                        endIdx = substrURL.indexOf("&limit=")
                    }

                    const query = substrURL.substring(0, endIdx)

                    jsonText['query'] = query
                    // jsonText = jsonText.substring(0, jsonText.length - 1)
                    // jsonText += ',"query":' + query + "}"

                }

                message = this.makeMessage(jsonText, pkFileInfo);

                const outputFormatDisplay = {'jsonRow': 'Row', 'matrix': 'Matrix (2D Array)', 'jsonColumn': 'Column', 'row': 'Row', 'column': 'Column', 'columnValue': 'Column Value'}

                heading = outputFormatDisplay[this.props.outputFormat] + ": "
            }
        }


        return (
            <div style={{paddingTop: '8px'}}>
                <h6 className="thinHeading"><b>{heading}</b><i>{message}</i></h6>
                <ReactJson src={jsonText} name={null} theme="ocean" displayObjectSize={false}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(JSONOutputPreview)