import React, {Component} from 'react'
import DocsImage from "../../DocsImage"

class Columns extends Component {
    render(){
        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">Introduction</p>
                    <p>What we call <b>Columns</b> can also be thought of as a data model</p>
                    <p>When configuring your importer, you can specify many things about each column of the data model (as you will see below)</p>
                    <p>Then when anyone uploads a spreadsheet through this importer:</p>
                    <ol>
                        <li>The columns in their spreadsheet will be automatically matched with the columns you specified in the data model</li>
                        <li>The user will also have the option to manually match their columns with the columns you specified in the data model</li>
                    </ol>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Why would I need to do this?</p>
                    <p>Creating a data model helps you to </p>
                    <ol>
                        <li>Standardize each spreadsheet uploaded through the importer</li>
                        <li>Clean data from each spreadsheet uploaded through the importer</li>
                        <li>Practice excellent data governance on your imported spreadsheets</li>
                    </ol>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Configuring the Columns (data model)</p>
                    <p>Go to your importer page, and click on <b>Columns</b>. Then you can configure all their necessary details</p>
                    <DocsImage
                        src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/SpreadsheetImporter/ColumnsInfo.png"
                        width="100%"
                        alt="Importer Columns"
                    />
                    <p>Let's walk through everything you can configure about a column</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Display Name</p>
                    <p>This is the name that the user will see when prompted which column to match with</p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Columns-ColumnInfoRowDisplayName.png"
                        width="100%"
                        alt="Importer Columns"
                    />
                    <p>Here we can see how the user sees the column matching</p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Importer-UserMatchColumns.png"
                        width="100%"
                        alt="User match columns"
                    />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Internal Name</p>
                    <p>After the column name is matched the internal name will replace the column name in the spreadsheet</p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Columns-ColumnInfoRowInternalName.png"
                        width="100%"
                        alt="Internal column name"
                    />
                    <p>See example of name replacement below</p>
                    <div className="row">
                        <div className="col-sm-4">
                            <div style={{borderRight: "1px solid #F0F0F0", padding: "16px"}}>
                                <b>Original File before Import</b>
                                <br/>
                                <DocsImage
                                    src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Importer-FileOriginal.png"
                                    alt="Internal column name"
                                />
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div style={{padding: "16px"}}>
                                <b>Replace Column Name after Import</b>
                                <br/>
                                <DocsImage
                                    src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Importer-FileColMatch.png"
                                    alt="Internal column name"
                                />
                            </div>
                        </div>
                        <div className="col-sm-2" />
                    </div>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Required?</p>
                    <p>If selected, the user MUST choose a column from their spreadsheet to match</p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Importer-Required.png"
                        width="100%"
                        alt="Importer required"
                    />
                    <p>If they do not, then the user will see an error message on their column selection screen and will be unable to upload their spreadsheet</p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Importer-NotSelectedRequired.png"
                        width="100%"
                        alt="Selected Column Not Matched"
                    />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Type</p>
                    <p>The type of values that will be in the spreadsheet column. There are 4 choices: text, numerical, boolean, datetime</p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Columns-ColumnType.png"
                        width="100%"
                        alt="Importer type"
                    />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Convert?</p>
                    <p>
                        If selected, all the values in that spreadsheet column will be converted to the <i>type</i> you select.
                        <br/><br/>
                        Sometimes, the conversion will fail, for example: if you select <i>type</i> to be <b>numerical</b> and a value in your column is <b>abc</b>
                        <br/><br/>
                        In that case, the value will be converted to <code>null</code>
                    </p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Info</p>
                    <p style={{marginBottom: "5px"}}>Depending on the data type the information popup will contain different cleaning functions but these fields will exist for every column type</p>
                    <b>Description</b>
                    <p>The user will see this to help them match the column. It will also help you keep organized</p>
                    <b>Tags</b>
                    <p>You can enter tags separated by a comma that will help auto matching the user's spreadsheet columns with the ones you specify here</p>
                    <p>The tags do not appear anywhere in the importing process, they only help our matching algorithm</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Clean Functions</p>
                    <p>You can select functions to clean and transform the data in that spreadsheet column. These will change based on the <i>type</i> you select</p>
                    <div className="docsSection">
                        <p><b>Text</b></p>
                        <DocsImage
                            src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Columns-TextCleaning.png"
                            width="70%"
                            alt="Importer type"
                        />
                    </div>
                    <div className="docsSection">
                        <p><b>Numerical</b></p>
                        <DocsImage
                            src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Columns-NumericalCleaning.png"
                            width="70%"
                            alt="Importer type"
                        />
                    </div>
                    <div className="docsSection">
                        <p><b>Date</b></p>
                        <DocsImage
                            src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Customization/Columns-DateCleaning.png"
                            alt="Importer type"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Columns