import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'

class FileOptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            icon: "triangle right"
        }

        this.toggleHover = this.toggleHover.bind(this);
    }

    toggleHover(e){
        if (this.state.icon === "triangle right"){
            this.setState({
                icon: "triangle down"
            })
        }
        else{
            this.setState({
                icon: "triangle right"
            })
        }
    }

    render() {
        const canTakeAction = 'canTakeAction' in this.props ? this.props.canTakeAction : true;
        const isQuickstart = this.props.inputType === "quickstart";
        const isDatabase = this.props.inputType === "database";

        return (
            <div className="customDropdown">
                <div className="dropbtn" onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                       <Icon name={this.state.icon} /> <b>More Actions</b>
                </div>
                <div className="dropdown-content">
                    {/*{this.props.visualizationButton}*/}
                    {this.props.downloadFileButton}
                    {canTakeAction ? this.props.reuploadFile : null}
                    {!isQuickstart ? this.props.teamFileButton : null}
                    {canTakeAction ? !isQuickstart ? this.props.reportingButton : null : null}
                    {canTakeAction ? !isQuickstart ? this.props.webhookButton : null : null}
                    {canTakeAction ? !isQuickstart ? this.props.alertsButton : null : null}
                    {canTakeAction ? this.props.whitelistDomainsButton : null}
                    {!isQuickstart ? this.props.columnNames : null}
                    {/*{this.props.shareButton}*/}
                    {this.props.analyticsButton}
                    {/*{!isQuickstart && !isDatabase ? this.props.testQuery : null}*/}
                    {canTakeAction ? !isQuickstart ? this.props.importSheets : null : null}
                    {canTakeAction ? !isQuickstart ? this.props.editFile : null : null}
                    {canTakeAction ? !isQuickstart ? this.props.blockRequestsButton : null : null}
                    {this.props.keysButton}
                    {canTakeAction ? this.props.removeFile : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FileOptions)