import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import getURLParam from "../../../../Constants/getURLParam";

class AddNewDropboxAccount extends Component {
    constructor(props){
        super(props);

        this.state = {
            redirectURL: this.getRedirectURL()
        }

    }

    getRedirectURL = () => {
        let redirectURL = ""
        if (window.location.href.includes("localhost")){
            redirectURL = "http://localhost:5000"
        } else {
            redirectURL = "https://www.apispreadsheets.com"
        }

        redirectURL += "/add-dropbox-account-to-destinations"

        return redirectURL
    }

    onChoose = () => {
        this.addDropboxLink.click()
    }

    render() {
        const splitParam = getURLParam(window.location.href).split("_")
        const importKey = splitParam[0];

        return (
            <div>
                {/*<a href={"https://www.dropbox.com/1/oauth2/redirect_receiver?client_id=5wksa0jimylgr9z&response_type=code&redirect_uri=" + this.state.redirectURL}>*/}
                <a style={{display: 'none'}}
                   ref={addDropboxLink => this.addDropboxLink = addDropboxLink}
                   href={"https://www.dropbox.com/oauth2/authorize?client_id=5wksa0jimylgr9z&state=" + importKey + "&response_type=code&token_access_type=offline&redirect_uri=" + this.state.redirectURL}>
                    Dropbox link
                </a>
                <Button
                    onClick={this.onChoose}
                    color="blue">
                    <Icon name="plus" />
                    <Icon name="dropbox" />
                    <span style={{paddingLeft: "5px"}}>Add New Dropbox Account</span>
                </Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    integrationAuth: state.mainState.integrationAuth,
    destinations: state.mainState.customImportDestinations,
    destinationsEdit: state.mainState.customImportDestinationsEdit
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(AddNewDropboxAccount)