import React from 'react';
import PropTypes from 'prop-types';

const FeatureDisplay = ({ image, heading, paragraph, headingColor, paragraphColor, flip, isMobile }) => {
    if (isMobile) {
        return (
            <div style={{ paddingTop: "48px"}}>
                <div>
                    <div style={{
                        paddingTop: "36px",
                        textAlign: 'center'
                    }}>
                        <p style={{
                            color: headingColor,
                            fontWeight: 'bold',
                            margin: '0',
                            fontSize: "24px"
                        }}>{heading}</p>
                        <p style={{color: paragraphColor, marginTop: '10px', fontSize: "18px"}}>{paragraph}</p>
                    </div>
                </div>
                <div>
                    <div style={{textAlign: 'center'}}>
                        <img src={image} alt="Feature" style={{maxWidth: '75%', borderRadius: '8px'}}/>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: flip ? 'row-reverse' : 'row', // Flip columns based on the flip prop
                alignItems: 'flex-start', // Align text with the top of the image
                gap: '20px',
                padding: '20px',
            }}
        >
            {/* Image Column */}
            <div style={{flex: '1', textAlign: 'center'}}>
                <img src={image} alt="Feature" style={{maxWidth: '75%', borderRadius: '8px' }} />
            </div>

            {/* Text Column */}
            <div style={{ flex: '1', paddingTop: "36px",
                paddingRight: flip ? "0px" : "48px", paddingLeft: flip ? "120px" : "0px"}}>
                <p style={{ color: headingColor, fontWeight: 'bold', margin: '0', fontSize: "24px" }}>{heading}</p>
                <p style={{ color: paragraphColor, marginTop: '10px', fontSize: "18px" }}>{paragraph}</p>
            </div>
        </div>
    );
};

FeatureDisplay.propTypes = {
    image: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    paragraph: PropTypes.string.isRequired,
    headingColor: PropTypes.string,
    paragraphColor: PropTypes.string,
    flip: PropTypes.bool,
};

FeatureDisplay.defaultProps = {
    headingColor: '#000',
    paragraphColor: '#555',
    flip: false,
};

export default FeatureDisplay;
