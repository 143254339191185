import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon, Popup, Button } from 'semantic-ui-react'
import {convertServerDateToDisplayDate} from "../../Constants/convertServerDateToDisplayDate";
import TeamMemberTable from "./TeamMemberTable"
import API_Root from "../../Constants/API_Root";
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyTeamInfo} from "../../Actions/ModifyTeamInfo";
import { push } from 'react-router-redux'
import MenuSegment from "../../FileDnD/FilesID/FilesIDDisplayMenu/MenuSegment"
import MemberDisplayIndex from "./MemberDisplayIndex"
import TeamFilesIndex from "./TeamFilesIndex"

class TeamInfoModalBody extends Component {
    constructor(props){
        super(props);

        this.state = {
            isAdmin: this.props.teamInfo[this.props.modalInfo.teamIdx].admin_email === localStorage.getItem("email").trim(),
            popupOpen: false,
            deleting: false,
            url: "",
            menuItem: "members"
        }
    }

    deleteTeam = (e) => {
        this.setState({ deleting: true })

        fetch(API_Root + "api/delete-api-team/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                teamPK: this.props.teamInfo[this.props.modalInfo.teamIdx].id,
            })
        }).then((res) => {
            if (res.status === 200){
                this.setState({
                    deleting: false
                }, () => this.finishDeletingTeam())
            } else {
                alert("There was an error deleting your team! Try again")
            }
        }).catch(err => alert("There was an error deleting your team! Try again"))
    }

    leaveTeam = (e) => {
        this.setState({ deleting: true })

        fetch(API_Root + "api/remove-add-or-edit-api-team-member/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                inviteEmail: localStorage.getItem("email"),
                role: this.props.teamInfo[this.props.modalInfo.teamIdx].all_users[localStorage.getItem("email")].role,
                adminAction: false,
                operation: "decline",
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                teamPK: this.props.teamInfo[this.props.modalInfo.teamIdx].id,
            })
        }).then((res) => {
            if (res.status === 200){
                this.setState({
                    deleting: false
                }, () => this.finishDeletingTeam())
            } else {
                alert("There was an error deleting your team! Try again")
            }
        }).catch(err => alert("There was an error deleting your team! Try again"))
    }


    togglePopup = (e, toggle) => {
        this.setState({
            popupOpen: toggle
        })
    }

    finishDeletingTeam = () => {
        this.props.toggleModal(null)
        this.props.navigateTo("/teams")
        const currTeamInfo = JSON.parse(JSON.stringify(this.props.teamInfo))
        currTeamInfo.splice(this.props.idx, 1)

        this.props.modifyTeamInfo(currTeamInfo)
    }

    goToFile = (fileID) => {
        this.setState({
            url: this.getFileURL(fileID, true)
        }, () => {
            this.goToFileLink.click()
        })
    }

    getFileURL = (fileID, route=false) => {
        const baseURL = window.location.href.includes("localhost") ? "http://localhost:5000/" : "https://www.apispreadsheets.com/"
        return baseURL + "files/" + fileID.toString() + (route ? "?team=" + this.props.teamInfo[this.props.modalInfo.teamIdx].team_hash.toString() : "")
    }

    changeMenuItem = (e, menuItem) => {
        this.setState({ menuItem: menuItem })
    }

    render() {
        const teamInfo = this.props.teamInfo[this.props.modalInfo.teamIdx]

        const displayMap = {
            "members": <MemberDisplayIndex />,
            "files": <TeamFilesIndex apiFilePK={teamInfo.api_file_pk} teamPK={teamInfo.id} teamHash={teamInfo.team_hash}/>
        }

        let display = null;
        if (this.state.menuItem in displayMap){
            display = displayMap[this.state.menuItem]
        }

        return (
            <div className="filesIDDisplayIndex">
                <div className="filesIDDisplayMenu">
                    <div style={{margin: "16px 0px 0px 0px", fontFamily: "Lato", fontWeight: "700", fontSize: "12px"}}>
                        Menu
                    </div>
                    <MenuSegment label="Member Info" icon='group' name="members" menuItem={this.state.menuItem} onClick={this.changeMenuItem} />
                    <MenuSegment label="Files" icon='file-blank' name="files" menuItem={this.state.menuItem} onClick={this.changeMenuItem} />
                </div>
                <div className="filesIDDisplay">
                    {display}
                    {/*<a href={this.state.url} style={{display: "none"}} ref={goToFileLink => this.goToFileLink = goToFileLink}>Read more</a>*/}
                    {/*<div>*/}
                        {/*<TeamMemberTable teamIdx={this.props.modalInfo.teamIdx}/>*/}
                    {/*</div>*/}
                    {/*{*/}
                        {/*"files_pk_list" in teamInfo.api_file_pk ?*/}
                            {/*<div style={{marginTop: "40px", marginBottom: "100px"}}>*/}
                                {/*<h4><Icon name="file alternate outline" /> Files linked to this team</h4>*/}
                                {/*{teamInfo.api_file_pk["files_hash_list"].map((x, idx) =>*/}
                                {/*<div style={{paddingBottom: "10px"}}>*/}
                                    {/*<h4 className="linkStyle" onClick={e => this.goToFile(x)}>{this.getFileURL(x)}</h4>*/}
                                {/*</div>)}*/}
                            {/*</div> : null*/}
                    {/*}*/}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo,
    teamInfo: state.mainState.teamInfo
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyTeamInfo: modifyTeamInfo,
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(TeamInfoModalBody)