import React, {Component} from 'react'
import { connect } from 'react-redux'

class ActionParameters extends Component {
    componentDidMount(){
        const elem = document.getElementById("fileSummaryCodeToolbar")
        elem.scrollIntoView({ behavior: 'smooth', block: 'center'})
    }

    render() {
        const actionType = this.props.actionType;
        const privateCustomer = this.props.tier !== "free";

        const message = {
            readAction: <span>There are 3 <span id="flashingQuery">OPTIONAL</span> url parameters you can include: <code>dataFormat</code>, <code>query</code> and <code>limit</code>. Play around with them and see how the <b>API URL</b> changes</span>,
            createAction: <span>You need to include a JSON encoded BODY with your new rows. Play around with the format to see how the rows should look. {privateCustomer ? <span>If private, include your <code>accessKey</code> and <code>secretKey</code> in the request HEADER</span> : null}</span>,
            updateAction: <span>You need to include a JSON encoded BODY. It needs to be in the format specified along with a Query. The Query will dictate which rows to update. {privateCustomer ? <span>If private, include your <code>accessKey</code> and <code>secretKey</code> in the request HEADER</span> : null}</span>,
            deleteAction: <span>You need to specify a <code>Query</code> that dictates which rows will be deleted</span>
        }

        return (
            <div className="actionIndex">
                <h4 className="thinHeading">{message[actionType]}</h4>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

export default connect(mapStateToProps, null)(ActionParameters)