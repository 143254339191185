import React, {Component} from 'react'
import {connect} from 'react-redux'
import {capitalize} from "../Constants/capitalize";
import { Icon } from 'semantic-ui-react'
import {modifyUtilitiesMenuItem} from "../Actions/ModifyUtilitiesMenuItem";

class UtilitiesIndexMenuItem extends Component {
    modifyMenuItem = () => this.props.modifyUtilitiesMenuItem(this.props.name)

    render() {
        let cName;

        if ('common' in this.props){
            cName = "commonIndexMenuItem"
        } else {
            cName = "utilitiesIndexMenuItem"
        }

        if (this.props.name === this.props.utilitiesMenuItem) {
            if ('common' in this.props) {
                cName += " commonIndexMenuItemSelected";
            } else {
                cName += " utilitiesIndexMenuItemSelected";
            }
        }

        return (
            <div className={cName} onClick={this.modifyMenuItem}>
                <Icon name={this.props.icon} />
                <p>{this.props.name === "ai" ? "AI" : capitalize(this.props.name)}</p>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    utilitiesMenuItem: state.mainState.utilitiesMenuItem
})

const mapActionsToProps = {
    modifyUtilitiesMenuItem: modifyUtilitiesMenuItem
}

export default connect(mapStateToProps, mapActionsToProps)(UtilitiesIndexMenuItem)