export const getRequestBodyJSON = (mode, isPrivate, keys, dataFormat) => {
    const dataValues = {
        "row": getRowValues(),
        "column": getColumnValues(),
        "matrix": getMatrixValues(),
    }[dataFormat]

    const body = {
        "data": dataValues
    }

    if (dataFormat === "matrix"){
        body['columnNames'] = getColNames()
    }

    if (mode === "ai"){
        body['query'] = 'Show me movies where Chris Pratt was an actor'
    } else if (mode === "create" || mode === "download"){
        body['fileName'] = 'MyFile'
    }

    if (isPrivate){
        let accessKey;
        let secretKey;

        try{
            accessKey = keys[0].access_key
            secretKey = keys[0].secret_key
        } catch (e) {
            accessKey = undefined
            secretKey = undefined
        }

        if (accessKey !== null && accessKey !== undefined && secretKey !== null && secretKey !== undefined){
            body['accessKey'] = accessKey
            body['secretKey'] = secretKey
        }
    }

    return body
}

const getRowValues = () => {
    return [{'Title': 'Guardians of the Galaxy', 'Genre': 'Action,Adventure,Sci-Fi', 'Director': 'James Gunn', 'Actors': 'Chris Pratt, Vin Diesel, Bradley Cooper, Zoe Saldana', 'Year': 2014, 'Runtime (Minutes)': 121}, {'Title': 'Prometheus', 'Genre': 'Adventure,Mystery,Sci-Fi', 'Director': 'Ridley Scott', 'Actors': 'Noomi Rapace, Logan Marshall-Green, Michael Fassbender, Charlize Theron', 'Year': 2012, 'Runtime (Minutes)': 124}, {'Title': 'Split', 'Genre': 'Horror,Thriller', 'Director': 'M. Night Shyamalan', 'Actors': 'James McAvoy, Anya Taylor-Joy, Haley Lu Richardson, Jessica Sula', 'Year': 2016, 'Runtime (Minutes)': 117}, {'Title': 'Sing', 'Genre': 'Animation,Comedy,Family', 'Director': 'Christophe Lourdelet', 'Actors': 'Matthew McConaughey,Reese Witherspoon, Seth MacFarlane, Scarlett Johansson', 'Year': 2016, 'Runtime (Minutes)': 108}, {'Title': 'Suicide Squad', 'Genre': 'Action,Adventure,Fantasy', 'Director': 'David Ayer', 'Actors': 'Will Smith, Jared Leto, Margot Robbie, Viola Davis', 'Year': 2016, 'Runtime (Minutes)': 123}, {'Title': 'The Great Wall', 'Genre': 'Action,Adventure,Fantasy', 'Director': 'Yimou Zhang', 'Actors': 'Matt Damon, Tian Jing, Willem Dafoe, Andy Lau', 'Year': 2016, 'Runtime (Minutes)': 103}]
}

const getColumnValues = () => {
    return {'Title': ['Guardians of the Galaxy', 'Prometheus', 'Split', 'Sing', 'Suicide Squad', 'The Great Wall'], 'Genre': ['Action,Adventure,Sci-Fi', 'Adventure,Mystery,Sci-Fi', 'Horror,Thriller', 'Animation,Comedy,Family', 'Action,Adventure,Fantasy', 'Action,Adventure,Fantasy'], 'Director': ['James Gunn', 'Ridley Scott', 'M. Night Shyamalan', 'Christophe Lourdelet', 'David Ayer', 'Yimou Zhang'], 'Actors': ['Chris Pratt, Vin Diesel, Bradley Cooper, Zoe Saldana', 'Noomi Rapace, Logan Marshall-Green, Michael Fassbender, Charlize Theron', 'James McAvoy, Anya Taylor-Joy, Haley Lu Richardson, Jessica Sula', 'Matthew McConaughey,Reese Witherspoon, Seth MacFarlane, Scarlett Johansson', 'Will Smith, Jared Leto, Margot Robbie, Viola Davis', 'Matt Damon, Tian Jing, Willem Dafoe, Andy Lau'], 'Year': [2014, 2012, 2016, 2016, 2016, 2016], 'Runtime (Minutes)': [121, 124, 117, 108, 123, 103]}
}

const getMatrixValues = () => {
    return [['Guardians of the Galaxy', 'Action,Adventure,Sci-Fi', 'James Gunn', 'Chris Pratt, Vin Diesel, Bradley Cooper, Zoe Saldana', 2014, 121], ['Prometheus', 'Adventure,Mystery,Sci-Fi', 'Ridley Scott', 'Noomi Rapace, Logan Marshall-Green, Michael Fassbender, Charlize Theron', 2012, 124], ['Split', 'Horror,Thriller', 'M. Night Shyamalan', 'James McAvoy, Anya Taylor-Joy, Haley Lu Richardson, Jessica Sula', 2016, 117], ['Sing', 'Animation,Comedy,Family', 'Christophe Lourdelet', 'Matthew McConaughey,Reese Witherspoon, Seth MacFarlane, Scarlett Johansson', 2016, 108], ['Suicide Squad', 'Action,Adventure,Fantasy', 'David Ayer', 'Will Smith, Jared Leto, Margot Robbie, Viola Davis', 2016, 123], ['The Great Wall', 'Action,Adventure,Fantasy', 'Yimou Zhang', 'Matt Damon, Tian Jing, Willem Dafoe, Andy Lau', 2016, 103]]
}

const getColNames = () => {
    return ['Title', 'Genre', 'Director', 'Actors', 'Year', 'Runtime (Minutes)']
}