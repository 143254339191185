import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import {modifyModalImageURL} from "../Actions/ModifyModalImageURL";

class CustomImage extends Component {
    constructor(props) {
        super(props);

        this.openImageModal = this.openImageModal.bind(this);
    }

    openImageModal(e){
        if (!('width' in this.props)){
            this.props.modifyModalImageURL({url: this.props.src, alt: this.props.alt});
            this.props.toggleModal("imageModal")
        }
    }

    render() {
        let imgClassName = "customImage";
        let style = 'style' in this.props ? this.props.style : null;

        if ('width' in this.props){
            style = {cursor: 'default'}
        }

        return (
            <img src={this.props.src}
                 width={'width' in this.props ? this.props.width : '100%'}
                 alt={this.props.alt}
                 style={style}
                onClick={this.openImageModal}
                className={'className' in this.props ? imgClassName + " " + this.props.className : imgClassName}
            />
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalImageURL: modifyModalImageURL
}

export default connect(mapStateToProps, mapActionsToProps)(CustomImage)