import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Input } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import InfoPopup from "../../../../SharedComponents/InfoPopup"

class AppearanceInput extends Component {
    handleInputChange = (e) => {
        const editCopy = JSON.parse(JSON.stringify(this.props.customImportAppearanceEdit))
        editCopy[e.target.name] = e.target.value

        this.props.modifyCustomImportSettings("appearanceEdit", editCopy)
    }

    getPlaceholderText = () => {
        const placeholderMap = {
            organizationName: "Sam & Addy LLC",
            successMessage: "Thanks for your upload!",
            errorMessage: "There was an error. Please contact us"
        }

        if (this.props.name in placeholderMap){
            return placeholderMap[this.props.name]
        } else {
            return ""
        }

    }

    render() {
        let input;
        if (this.props.name === "backgroundColor"){
            input = <input name={this.props.name}
                           type="color"
                           value={this.props.customImportAppearanceEdit[this.props.name]}
                           onChange={this.handleInputChange} />
        }  else {
            input = <input
                           style={{width: "100%"}}
                           className="inputGray"
                           name={this.props.name}
                           placeholder={this.getPlaceholderText()}
                           value={this.props.customImportAppearanceEdit[this.props.name]}
                           onChange={this.handleInputChange}
            />
        }

        const subheadingStyle = {fontFamily: "Lato", fontWeight: "500", fontSize: "14px", color: "#161E16"};

        if (this.props.name === "backgroundColor"){
            return (
                <div style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                    <div style={{paddingTop: "4px"}}>
                        <div style={subheadingStyle}>{this.props.labelText} <InfoPopup style={{cursor: "pointer" }} info={this.props.helpText}/></div>
                    </div>
                    <div style={{textAlign: "right"}}>
                        {input}
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div style={subheadingStyle}>{this.props.labelText} <InfoPopup style={{cursor: "pointer" }} info={this.props.helpText}/></div>
                    {input}
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    customImportAppearanceEdit: state.mainState.customImportAppearanceEdit
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(AppearanceInput)