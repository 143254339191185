import React, {Component} from 'react'
import ModalApp from "../SharedComponents/Modal";
import QuickstartUploadModalBody from "./QuickstartUploadModalBody";
import { Icon } from 'semantic-ui-react'

class QuickstartUploadModal extends Component {
    render() {
        return (
            <ModalApp name="quickstartUploadModal"
                      header={<h4> <Icon name="code" /> Quickstart - API with 1 JSON Object</h4>}
                      body={<QuickstartUploadModalBody/>}
                      size="large"
            />
        )
    }
}

export default QuickstartUploadModal