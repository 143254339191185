import React, {Component} from 'react'
import {Elements, StripeProvider} from 'react-stripe-elements';
import Verify3DSecurePaymentBtn from "./Verify3DSecurePaymentBtn";

class Verify3DSecurePaymentBtnIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <StripeProvider apiKey={window.location.href.includes("localhost") ? "pk_test_1rS8QGgeDU1Ewoyjc8AjaTx0" : "pk_live_nmeaYWsNdAbSbOTRd8MgnGLL"}>
                <Elements>
                    <Verify3DSecurePaymentBtn toggleToast={this.props.toggleToast} stripe3DSecureInfo={this.props.stripe3DSecureInfo}/>
                </Elements>
            </StripeProvider>
        )
    }
}

export default Verify3DSecurePaymentBtnIndex