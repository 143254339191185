import React, { Component } from 'react';
import { Form, Button, Input, Radio, Dropdown, TextArea, Message } from 'semantic-ui-react';
import {toggleToast} from "../../Actions/ToggleToast";
import {connect} from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";

class MacrosContactUsModal extends Component {
    state = {
        email: localStorage.getItem("email"),
        useCase: '',
        requestsPerMonth: '',
        additionalInfo: '',
        emailError: false,
        isLoading: false,
    };

    handleEmailChange = (e) => {
        const email = e.target.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.setState({
            email,
            emailError: !emailRegex.test(email)
        });
    };

    handleUseCaseChange = (e, { value }) => this.setState({ useCase: value });

    handleRequestsChange = (e, { value }) => this.setState({ requestsPerMonth: value });

    handleAdditionalInfoChange = (e) => this.setState({ additionalInfo: e.target.value });

    handleSubmit = async () => {
        const { email, useCase, requestsPerMonth, additionalInfo, emailError } = this.state;

        this.setState({ isLoading: true });

        try {
            const response = await fetch('https://api.apispreadsheets.com/data/RPbsoeCa9zFKhlig/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "accessKey":"3efadcc7db09d647a1c1527c09e2c7db",
                    "secretKey":"dbd974e72a8246561ad83fc25efb30e1"
                },
                body: JSON.stringify({"data": {
                        "Email": email,
                        "Problem": useCase,
                        "Monthly Requests": requestsPerMonth,
                        "Description": additionalInfo }
                })
            });

            if (!response.ok) {
                this.props.toggleToast({show: true, message: "There was an error submitting your details. Please email us at support@apispreadsheets.com", type: "error"})
            } else {
                this.props.toggleToast({show: true, message: "Thank you for contacting us! We will be reaching out shortly", type: "success"})
            }

        } catch (error) {
            this.props.toggleToast({show: true, message: "There was an error submitting your details. Please email us at support@apispreadsheets.com", type: "success"})
        } finally {
            this.props.toggleModal(null)
            this.setState({ isLoading: false });
        }
    };

    render() {
        const { email, useCase, requestsPerMonth, additionalInfo, emailError, isLoading } = this.state;
        const useCaseOptions = [
            { label: "I want to execute macros in my spreadsheets using an API", value: "macros" },
            { label: "I want to run formulas in my spreadsheets using an API", value: "formulas" },
            { label: "I want to use my spreadsheets as a rules engine", value: "rules-engine" },
            { label: "Some combination of the above", value: "combination" },
            { label: "My use case is not listed", value: "other" }
        ];
        const requestsOptions = [
            { label: "1-10,000 requests per month", value: "<10,000" },
            { label: "10,001-100,000 requests per month", value: "10,001-100,000" },
            { label: "100,001-1,000,000 per month", value: "100,001-1,000,000" },
            { label: "More than 1,000,000 requests per month", value: ">1,000,000" }
        ];

        let formError = !email || !useCase || !requestsPerMonth || emailError

        return (
            <Form error={formError || emailError}>
                <Form.Field error={emailError}>
                    <label>Email *</label>
                    <Input placeholder='Enter your email' value={email} onChange={this.handleEmailChange} />
                    {emailError && <Message error content='Invalid email format' />}
                </Form.Field>
                <Form.Field>
                    <label>Please select a use case *</label>
                    {useCaseOptions.map(option => (
                        <Form.Field key={option.value}>
                            <Radio
                                label={option.label}
                                name='useCaseRadioGroup'
                                value={option.value}
                                checked={useCase === option.value}
                                onChange={this.handleUseCaseChange}
                            />
                        </Form.Field>
                    ))}
                </Form.Field>
                <Form.Field>
                    <label>How many requests do you estimate per month? *</label>
                    <Dropdown
                        placeholder='Select requests range'
                        fluid
                        selection
                        options={requestsOptions.map(option => ({ key: option.value, text: option.label, value: option.value }))}
                        value={requestsPerMonth}
                        onChange={this.handleRequestsChange}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Tell us anything more we need to know</label>
                    <TextArea placeholder='Additional information' value={additionalInfo} onChange={this.handleAdditionalInfoChange} />
                </Form.Field>
                {formError && <Message error content='Please fill out all required fields' />}
                <Button type='submit' onClick={this.handleSubmit} disabled={formError || emailError || isLoading} loading={isLoading} content="Contact Us" color="green">
                    Contact Us
                </Button>
            </Form>
        );
    }
}

const mapActionsToProps = {
    toggleToast: toggleToast,
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(MacrosContactUsModal)
