import React, {Component} from 'react'
import {connect} from 'react-redux'
import PaymentForm from './PaymentForm'
import PaymentPlanSelection from './PaymentPlanSelection'
import PopupPlanDetails from './PopupPlanDetails'
import {Icon, Image} from 'semantic-ui-react'
import CurrencyDropdown from "../../InfoPages/Pricing/CurrencyDropdown"
import CustomerCarousel from "../../InfoPages/CustomerCarousel";
import {getPricingText} from "../../Constants/getPricingText";
import PopupPlanBody from "./PopupPlanBody";
import images from "../../Constants/Images";
import {getPricingPlanIdx} from "../../Constants/getPricingPlanIdx";
import StepSelectionMenu from "./StepSelectionMenu"
import BannerAlert from "../../SharedComponents/BannerAlert"
import MonthlyYearlyToggle from "../../InfoPages/Pricing/MonthlyYearlyToggle"
import PlanPricingBox from "../../InfoPages/Pricing/PlanPricingBox"
import {featureList} from "../../Constants/Pricing/featureList";
import {planBgColor} from "../../Constants/planBgColor";
import EnterprisePricingBox from "../../InfoPages/Pricing/EnterprisePricingBox";
import InfoSegment from "../InfoSegment";

const paymentOriginPlan = {
    "private": 1,
    "blockRequests": 2,
    "alerts": 2,
    "whitelistDomains": 3,
    "reporting": 2,
    "webhooks": 1,
    "database": 3,
    "cachingLayer": 3,
    "cname": 3,
    "teams": 2,
    "branding": 1,
    "emailAlerts": 2,
    "passwordProtect": 1,
    "multipleKeys": 3,
    "ai": 2
}

class CompletePaymentForm extends Component {
    constructor(props) {
        super(props);

        const planInfo = this.getPlan();

        this.state = {
            email: localStorage.getItem("email"),
            plan: planInfo.plan,
            minPlan: planInfo.minPlan,
            step: 0

        }

        this.getPlan = this.getPlan.bind(this);
        this.updatePlan = this.updatePlan.bind(this);
    }

    getPlan(){
        const url = window.location.href;
        if (url.includes("?pricing=")){
            return {plan: url.substring(url.indexOf("?pricing=")+9, url.indexOf("?pricing=")+10), minPlan: 1}
        }
        else{
            let plan = 1

            if (this.props.paymentModalOrigin in paymentOriginPlan){
                plan = paymentOriginPlan[this.props.paymentModalOrigin]
            }
            else{
                let planIdx = getPricingPlanIdx(this.props.tier.toString())
                if (planIdx !== -1){
                    if (planIdx < 4){
                        plan = planIdx + 1
                    }
                }
            }

            return {plan: plan, minPlan: plan}
        }

    }

    updatePlan(plan){
        this.setState({
            plan: plan,
            step: 1
        })
    }

    changeStep = (step) => this.setState({ step: step })

    render() {
        if (this.state.minPlan === 4){
            return (
                <div style={{marginTop: "16px"}}>
                    <EnterprisePricingBox />
                </div>
            )
        } else {
            let paymentOriginText = null;

            if (this.props.paymentModalOrigin !== null) {
                paymentOriginText = {
                    "private": "APIs can only be Private on Pro or higher tier account",
                    "blockRequests": "Request types can only be blocked on a Team or higher tier account",
                    "alerts": "Success/Error alerts can only be set up on a Team or higher tier account",
                    "whitelistDomains": "Domains can only be whitelisted on a Business or higher tier account",
                    "reporting": "Auto reporting can only be set up on a Team or higher tier account",
                    "webhooks": "Webhooks can only be set up on a Pro or higher tier account",
                    "database": "Database can only be connected on a Business or higher tier account",
                    "cachingLayer": "Caching layer can only be set up on a Business or higher tier account",
                    "cname": "Domain can only be connected on a Business or higher tier account",
                    "teams": "Teams can only be created on a Team or higher tier account",
                    "branding": "API Spreadsheets branding can only be removed on a Pro or higher tier account",
                    "emailAlerts": "Email alerts can only be set up on the Team or higher tier account",
                    "passwordProtect": "Password protection can only be set up on the Pro or higher tier account",
                    "multipleKeys": "Multiple keys can only be set up on a Business or higher tier account",
                    "ai": "AI API can only be used on a Team or higher tier account"
                }[this.props.paymentModalOrigin]

                paymentOriginText = <div style={{marginBottom: "16px"}}>
                    <BannerAlert type="warning"
                                 header={paymentOriginText}
                                 icon="bx bx-credit-card"
                                 content="Continue below to use this feature" />
                </div>
            }

            return (
                <div>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "25% 75%"
                    }}>
                        <div style={{padding: "16px"}}>
                            <StepSelectionMenu step={this.state.step} changeStep={this.changeStep}/>
                            <div style={{marginTop: "16px", padding: "16px", borderRadius: "8px"}} className="segment-raised">
                                <div style={{textAlign: "center", fontWeight: "700", fontSize: "16px"}}>Plan Selected</div>
                                <PlanPricingBox
                                    planIdx={this.state.plan}
                                    featureList={featureList}
                                    backgroundColor={planBgColor[this.state.plan]}
                                    paymentModal
                                    planSelected
                                />
                            </div>
                        </div>
                        <div style={{backgroundColor: "white", padding: "16px"}}>
                            {
                                this.state.step === 0 ?
                                    <div>
                                        {paymentOriginText}
                                        <div style={{display: "grid", gridTemplateColumns: "50% 50%", marginBottom: "16px"}}>
                                            <div style={{fontSize: "14px", fontWeight: "400", color: "#161E16", display: "grid", gridTemplateColumns: "auto auto 1fr", columnGap: "12px", paddingTop: "8px"}}>
                                                <div>
                                                    Monthly
                                                </div>
                                                <div>
                                                    <MonthlyYearlyToggle />
                                                </div>
                                                <div>
                                                    Yearly
                                                </div>
                                            </div>
                                            <div style={{textAlign: "right"}}>
                                                <CurrencyDropdown />
                                            </div>
                                        </div>
                                        <PaymentPlanSelection plan={this.state.plan}
                                                              yearlyPricing={this.props.yearlyPricing}
                                                              minPlan={this.state.minPlan}
                                                              updatePlan={this.updatePlan}/>
                                    </div> : this.state.step === 1 ? <div>
                                        <PaymentForm plan={parseInt(this.state.plan)} segment/>
                                    </div> : null
                            }
                        </div>
                    </div>
                </div>
            )
        }

    }
}

const mapStateToProps = (state) => ({
    paymentModalOrigin: state.mainState.paymentModalOrigin,
    tier: state.mainState.tier,
    yearlyPricing: state.mainState.yearlyPricing
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CompletePaymentForm)