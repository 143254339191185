import {getQuery} from "./getQuery";
import {getFileHash} from "./getFileHash";

export const getPostData = (fileInfo, createFormat, apiType, pk, full_data, update_query) => {
    let postData;
    let columnNames = fileInfo['columnNames'];

    if ('columnInformation' in fileInfo){
        if ('names' in fileInfo['columnInformation']){
            if (fileInfo['columnInformation']['names'].length > 0){
                columnNames = fileInfo['columnInformation']['names']
            }
        }
    }

    if (apiType === "createAction"){
        if (createFormat === "row"){
            postData = createPostDataJSON(fileInfo['jsonRowSample'], columnNames, "row", full_data)
        }
        else if (createFormat === "column"){
            postData = createPostDataJSON(fileInfo['jsonColumnSample'], columnNames, "column", full_data)
        }
        else {
            postData = createPostDataJSON(fileInfo['jsonColumnSample'], columnNames, "columnValue", full_data)
        }
    }
    else {
        postData = createPostDataJSON(fileInfo['jsonColumnSample'], columnNames, "columnValue", full_data)
        postData = postData.substring(0, postData.length - 1)

        let updateQueryText;
        if (update_query !== null){
            updateQueryText = update_query
        }
        else{
            updateQueryText = getQuery(fileInfo, getFileHash(fileInfo, pk), "read")
        }
        postData += ', "query": "' + updateQueryText + '"}'
    }

    return postData
}

const createPostDataJSON = (fileSamples, columnNames, dataType, full_data) => {
    let data;

    if (dataType === "row"){

        if (fileSamples['data'].length > 0){
            if (full_data){
                data = ""
                for (let i=0; i<fileSamples['data'].length; i++){
                    data += JSON.stringify(fileSamples['data'][i]) + ","
                }
                data = data.substring(0, data.length - 1)
            }
            else{
                data = JSON.stringify(fileSamples['data'][0])
            }
        }
        else{
            data = '{'
            for (let i=0; i<columnNames.length; i++){
                data += '"' + columnNames[i] + '":"value' + (i+1).toString() + '",'
            }
            if (data.length > 1) {
                data = data.substring(0, data.length - 1);
            }

            data += '}'
        }

        data = '[' + data + ']'
    }
    else{
        data = '{';
        for (let colName in fileSamples){
            data += '"' + colName + '":'

            let value;
            if (fileSamples[colName].length > 0){
                if (full_data){
                    value = JSON.stringify(fileSamples[colName]) + ","
                }
                else{
                    value = '[' + JSON.stringify(fileSamples[colName][0]) + '],'
                }

            }
            else{
                value = '["value"],'
            }

            data += value
        }

        if (data.length > 1){
            data = data.substring(0, data.length - 1);
        }

        data += "}"

        if (dataType === "columnValue"){
            data = data.replace(/\[/g, '').replace(/]/g, '')
        }

    }

    return '{"data": ' + data + '}'
}