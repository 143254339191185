import React, {Component} from 'react'
import { Progress, Icon } from 'semantic-ui-react'

class MergeIndicator extends Component {
    render() {
        return (
            <div>
                <span className="uploadIcons">
                    <Icon name="sync" />
                </span>
                <div className="uploadProgressBar">
                    <Progress percent={this.props.percentage} indicating />
                </div>
                <h4 className="thinHeading">
                {
                    'importSegment' in this.props ? "Saving and sharing your files details..." : "Creating API URL..."
                }
                </h4>
            </div>
        )
    }
}

export default MergeIndicator