import React, {Component} from 'react'
import { connect } from 'react-redux'
import ModalApp from '../SharedComponents/Modal'

class AccountRemoveStepsModal extends Component {
    render() {
        let accountEmail = null;

        if (this.props.modalInfo !== null){
            if (typeof this.props.modalInfo === "object"){
                if ("accountEmail" in this.props.modalInfo){
                    accountEmail = "for " + this.props.modalInfo.accountEmail
                }
            }
        }

        return (
            <ModalApp name="accountRemoveStepsModal"
                      header="How to Remove Permissions for API Spreadsheets from your Google Account"
                      body={<div>
                          <ol>
                              <li>Go to your <a href="https://myaccount.google.com/" target="_blank" rel="noopener noreferrer">Google Account</a> {accountEmail}.</li>
                              <li>On the left navigation panel, select <b>Security</b>.</li>
                              <li>On the <i>Third-party apps with account access panel</i>, select <b>Manage third-party access.</b> (you might have to scroll down a bit to see this panel)</li>
                              <li>Select <b>API Spreadsheets</b>.</li>
                              <li>Select Remove Access.</li>
                              <li>Confirm, if needed</li>
                          </ol>
                      </div>}
                      size="large"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

export default connect(mapStateToProps, null)(AccountRemoveStepsModal)