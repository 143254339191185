import React, {Component} from 'react'
import { Image } from 'semantic-ui-react'
import adminLogo from "./adminLogo.svg"
import managerLogo from "./managerLogo.svg"
import developerLogo from "./developerLogo.svg"

const imageMap = {
    adminLogo: adminLogo,
    managerLogo: managerLogo,
    developerLogo: developerLogo
}

class RoleTag extends Component {
    render() {
        return (
            <span style={'style' in this.props ? this.props.style : null}>
                <Image src={imageMap[this.props.userRole + "Logo"]} alt="logo" avatar/>
                <span style={{fontWeight: '700'}}>{this.props.userRole.charAt(0).toUpperCase() + this.props.userRole.substring(1, this.props.userRole.length)}</span>
            </span>
        )
    }
}

export default RoleTag