import React, {Component} from 'react'
import {connect} from 'react-redux'
import CNAMEDomain from "./CNAMEDomain";
import { Checkbox } from 'semantic-ui-react'
import {gunaldiStyles} from "../../../SharedComponents/GunaldiDesignStyles";
import SSLIndex from "./SSLCertificate/SSLIndex";
import CNAMEDomainError from "./CNAMEDomainError";
import ConnectDomainBtn from "./ConnectDomainBtn";
import BannerAlert from "../../../SharedComponents/BannerAlert";

class CNAMEForm extends Component {
    toggle = () => this.props.changeState({ useSSL: !this.props.info.useSSL })

    render() {
        const isDomainConnected = 'domainConnected' in this.props;
        const style = {};
        let msg = null;

        if (isDomainConnected){
            if (this.props.tier !== "enterprise"){
                style['pointerEvents'] = "none"
                style['opacity'] = "0.5"
                msg = <BannerAlert
                    icon="bx bx-credit-card"
                    header="You can only connect to 1 domain on the Business Plan"
                    content={<a href="mailto:sales@apispreadsheets.com">Contact us to upgrade to an Enterprise plan</a>}
                    type="error"
                />
            }
        }

        return (
            <div style={isDomainConnected ? {border: "1px solid #F0F0F0", padding: "16px", borderRadius: "4px", marginTop: "16px"} : null}>
                {msg}
                <div style={style}>
                    <div style={{marginTop: "16px"}}>
                        <CNAMEDomain
                            name="domainName"
                            error={this.props.info.domainNameError}
                            errorMsg={this.props.info.domainNameErrorMsg}
                            value={this.props.info.domainName}
                            changeState={this.props.changeState}
                        />
                    </div>
                    <div style={{marginTop: "16px", border: "1px solid #F0F0F0", borderRadius: "4px", padding: "16px"}}>
                        <div>
                            <div style={{fontFamily: "Lato", fontSize: '14px', color: gunaldiStyles.mainText}}>Use HTTPS?</div>
                        </div>
                        <div>
                            <Checkbox toggle
                                      value={this.props.info.useSSL}
                                      onChange={this.toggle}
                            />
                            {/*<CNAMEDomainError*/}
                                {/*error={this.props.info.domainNameError}*/}
                                {/*errorMsg={this.props.info.domainNameErrorMsg}*/}
                                {/*errorOnly*/}
                            {/*/>*/}
                        </div>
                        {
                            this.props.info.useSSL ?
                                <div>
                                    <SSLIndex
                                        sslSelected={this.props.info.sslSelected}
                                        fileCertificate={this.props.info.fileCertificate}
                                        fileKey={this.props.info.fileKey}
                                        fileCertificateChain={this.props.info.fileCertificateChain}
                                        changeState={this.props.changeState}
                                        importSSLMsg={this.props.info.importSSLMsg}
                                        importSSLError={this.props.info.importSSLError}
                                        requestSSLMsg={this.props.info.requestSSLMsg}
                                        requestSSLError={this.props.info.requestSSLError}
                                        domainName={this.props.info.domainName}
                                        domainNameError={this.props.info.domainNameError}
                                        domainNameErrorMsg={this.props.info.domainNameErrorMsg}
                                        menuItem={this.props.info.sslMenuItem}
                                        domainValidationOption={this.props.info.domainValidationOption}
                                        currSSLInfo={this.props.info.currSSLInfo}
                                    />
                                </div>: null
                        }
                    </div>
                    <div style={{marginTop: "16px"}}>
                        <ConnectDomainBtn
                            info={this.props.info}
                            changeState={this.props.changeState}
                        />
                    </div>
                    {/*Connect domain name button*/}
                    {/*Messages after connecting*/}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CNAMEForm)