function pad(value) {
    return value < 10 ? '0' + value : value;
}

export const getUTCStringFromOffset = (date) => {
    let sign = (date.getTimezoneOffset() > 0) ? "-" : "+";
    let offset = Math.abs(date.getTimezoneOffset());
    let hours = pad(Math.floor(offset / 60));
    let minutes = pad(offset % 60);
    return sign + hours + ":" + minutes;
}