import React, {Component} from 'react'
import FileInformationTable from './FileInformationTable'
import Loader from 'react-loader-spinner'
import API_Root from "../Constants/API_Root";
import {connect} from "react-redux";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import { Segment, Button } from 'semantic-ui-react'
import {modifyFilePKProBlock} from "../Actions/ModifyFilePKProBlock";
import {initOriginalFilePK} from "../Actions/InitOriginalFilePK";
import {modifyFilesFetchedTime} from "../Actions/ModifyFilesFetchedTime";
import { isBrowser } from 'react-device-detect'
import { push } from 'react-router-redux'
import PromoBar from "../SharedComponents/PromoBar/PromoBar"
import {resetAllStates} from "../Actions/ResetAllStates";
import {modifyTeamInfo} from "../Actions/ModifyTeamInfo";
import EmailVerificationMessage from "../SharedComponents/EmailVerificationMessage";
import FailedPaymentMessage from "../SharedComponents/FailedPaymentMessage";
import pricing from "../Constants/Pricing/Pricing";
import {modifyProBlockTooManyFiles} from "../Actions/ModifyProBlockTooManyFiles";

import NoFilesUploaded from "./NoFilesUploaded";
import {getPricingPlanIdx} from "../Constants/getPricingPlanIdx";
import {getPricingInfoObject} from "../Constants/getPricingInfoObject";

class FilesIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            fetching: true,
            isImporterPage: 'importerIndex' in this.props,
            isApiPage: 'apiIndex' in this.props
        };

        this.goToUpload = this.goToUpload.bind(this);
    }

    shouldGetFiles = () => (this.props.originalFilePK.length === 0 || window.location.href.includes("refresh"))

    componentDidMount(){
        // Offset to get created dt
        const current = new Date();

        // if (this.shouldGetFiles()){
            fetch(API_Root + "api/get-api-files/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    utcOffset: current.getTimezoneOffset(),
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    pkList: 'pkList' in this.props ? this.props.pkList : null,
                    teamPK: 'teamPK' in this.props ? this.props.teamPK : null,
                    importer: this.state.isImporterPage,
                    api: this.state.isApiPage
                })
            }).then(res => res.json())
                .then((data)=> {
                    const dataCopy = JSON.parse(JSON.stringify(data));
                    this.props.initOriginalFilePK(dataCopy.filePK)
                    this.props.modifyFilePK(dataCopy.filePK);
                    this.props.modifyFileInformation(data.fileInformation);
                    this.props.modifySheetRead(data.sheetRead);
                    this.props.modifyFilePKProBlock(this.updateFilePKProBlock(data.filePKProBlock, dataCopy.filePK));
                    this.props.modifyTeamInfo(data.teamInfo)
                    this.props.modifyFilesFetchedTime();
                    this.setState({fetching: false});
                })
                .catch(err => this.setState({fetching: false})).catch(err => this.setState({fetching: false}));
        // }
    }

    goToUpload(e){
        this.uploadLink.click()
    }

    goToImport = () => {
        this.importLink.click()
    }

    updateFilePKProBlock = (serverPKProBlock, filePK) => {
        if (this.props.tier !== "enterprise"){
            const pricingInfo = getPricingInfoObject(this.props.tier, this.props.appsumoPlanName, this.props.isGrandfather)
            const fileNum = pricingInfo.files

            if (filePK.length > fileNum){
                const currPKProBlock = JSON.parse(JSON.stringify(serverPKProBlock))

                const cutOffIdx = filePK.length - fileNum;
                let filePKBlocks = [];

                for (let i=0; i<cutOffIdx; i++){
                    const pkID = filePK[i].id

                    currPKProBlock[pkID] = true
                    filePKBlocks.push(pkID)
                }

                this.props.modifyProBlockTooManyFiles(filePKBlocks)

                return currPKProBlock
            } else {
                return serverPKProBlock
            }
        } else {
            return serverPKProBlock
        }
    }

    render() {
        let body;

        if (this.state.fetching){
            body =
                <div style={{padding: '100px 0', textAlign: 'center'}}>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4 className="thinHeading" style={{color: 'black'}}>Getting your files...</h4>
                </div>
        }
        else{
            if (this.props.originalFilePK.length > 0){
                body = <FileInformationTable tableType="all"
                                             isImporterPage={this.state.isImporterPage}
                                             isApiPage={this.state.isApiPage}
                />
            }
            else{
                if (this.state.isImporterPage){
                    body = <NoFilesUploaded style={{paddingTop: "7%"}} isImporterPage />
                } else {
                    body = <NoFilesUploaded style={{paddingTop: "7%"}} isApiPage />
                }

            }

        }

        const isNotIndex = 'pkList' in this.props;

        return(
            <div style={ isNotIndex ? null : {minHeight: '100vh'}}>
                <div style={isNotIndex ? null : {paddingBottom: '350px'}}>
                    {body}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    originalFilePK: state.mainState.originalFilePK,
    filesFetchedTime: state.mainState.filesFetchedTime,
    tier: state.mainState.tier,
    appsumoPlanName: state.mainState.appsumoPlanName,
    isGrandfather: state.mainState.isGrandfather
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation,
    modifyFilePK: modifyFilePK,
    modifySheetRead: modifySheetRead,
    modifyFilePKProBlock: modifyFilePKProBlock,
    initOriginalFilePK: initOriginalFilePK,
    modifyFilesFetchedTime: modifyFilesFetchedTime,
    navigateTo: push,
    resetAllStates: resetAllStates,
    modifyTeamInfo: modifyTeamInfo,
    modifyProBlockTooManyFiles: modifyProBlockTooManyFiles
}

export default connect(mapStateToProps, mapActionsToProps)(FilesIndex)