import React, {Component} from 'react'
import { Menu, Image, Icon } from 'semantic-ui-react'
import images from '../../Constants/Images'
import {connect} from "react-redux";
import {push} from "react-router-redux";
import AuthComponent from "../AuthComponent";
import NavbarItem from "./NavbarItem"
import NavbarLogIn from "./NavbarLogIn"
import NavbarSignUp from "./NavbarSignUp"
import SignOutButton from '../../Authentication/UserPages/SignOut/SignOutButton'
import getURLParam from "../../Constants/getURLParam";

class NavbarMobile extends Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen: false
        }

        this.goToURL = this.goToURL.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
    }

    goToURL(e){
        e.preventDefault();

        this.props.navigateTo("/")
    }

    toggleOpen(e){
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        const navbar = <Menu secondary stackable>
            <Menu.Item>
                <AuthComponent
                    authComponent={<NavbarItem to="upload" name={<span><Icon name="upload" />Upload</span>} color="black" toggleOpen={this.toggleOpen}/>}
                    nonAuthComponent={<NavbarItem to="pricing" name="Pricing" color="black" toggleOpen={this.toggleOpen} />}
                />
            </Menu.Item>
            <Menu.Item>
                <AuthComponent
                    authComponent={<NavbarItem to="files" name={<span><Icon name="file alternate" />Files</span>} color="black" toggleOpen={this.toggleOpen} />}
                    nonAuthComponent={<NavbarItem to="tutorials" name="Use Cases" color="black" toggleOpen={this.toggleOpen} />}
                />
            </Menu.Item>
            <Menu.Item>
                <AuthComponent
                    authComponent={<NavbarItem to="profile" name={<span><Icon name="user" />Profile</span>} color="black" toggleOpen={this.toggleOpen}/>}
                    nonAuthComponent={<NavbarLogIn toggleOpen={this.toggleOpen}/>}
                />
            </Menu.Item>
            <Menu.Item>
                <AuthComponent
                    authComponent={<SignOutButton style={{marginTop: '5px'}} toggleOpen={this.toggleOpen}/>}
                    nonAuthComponent={<NavbarSignUp toggleOpen={this.toggleOpen}/>}
                />
            </Menu.Item>
        </Menu>

        const urlParam = getURLParam(window.location.href);
        if (urlParam.includes("signup") ||
            urlParam.includes("login") ||
            window.location.href.includes("update-payment-info") ||
            urlParam.includes("?team=") ||
            (window.location.href.includes("useractions") && window.location.href.toLowerCase().includes("verifyemail")) ||
            (window.location.href.includes("import") && window.location.href.includes("upload")) ||
            (window.location.href.includes("import") && window.location.href.includes("embed"))) {
            return (
                null
            )
        }
         else {
            if (window.location.href.includes("/importer")){
                return (
                    <div style={{boxShadow: "0px 2px 10px rgba(2, 34, 19, 0.06)"}}>
                        <Menu secondary>
                            <Menu.Menu position="left">
                                <Menu.Item>
                                    <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                        <Image style={{cursor: 'pointer'}} src={images.logoImporterNav} onClick={this.goToURL} size="small"/>
                                    </div>
                                </Menu.Item>
                            </Menu.Menu>
                            <Menu.Menu position="right">
                                <Menu.Item>
                                    <div className="navbarMobileButton" onClick={this.toggleOpen}>
                                        <Icon name={this.state.isOpen ? "close" : "align justify"} />
                                    </div>
                                </Menu.Item>
                            </Menu.Menu>
                        </Menu>
                        {this.state.isOpen ? navbar : null}
                    </div>
                )
            } else {
                return (
                    <div style={{boxShadow: "0px 2px 10px rgba(2, 34, 19, 0.06)"}}>
                        <Menu secondary>
                            <Menu.Menu position="left">
                                <Menu.Item>
                                    <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                        <Image style={{cursor: 'pointer'}} src={images.logoNav} onClick={this.goToURL} size="small"/>
                                    </div>
                                </Menu.Item>
                            </Menu.Menu>
                            <Menu.Menu position="right">
                                <Menu.Item>
                                    <div className="navbarMobileButton" onClick={this.toggleOpen}>
                                        <Icon name={this.state.isOpen ? "close" : "align justify"} />
                                    </div>
                                </Menu.Item>
                            </Menu.Menu>
                        </Menu>
                        {this.state.isOpen ? navbar : null}
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    navigateTo: push
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarMobile)