import {appSumoPlanPricing} from "./Pricing/AppsumoPlanPricing";
import {getPricingPlanIdx} from "./getPricingPlanIdx";
import pricing from "./Pricing/Pricing";
import oldPricing from "./Pricing/OldPricing";

export const getPricingInfoObject = (planTier, appSumoPlanName, isGrandfather) => {
    if (appSumoPlanName !== ""){
        return appSumoPlanPricing[appSumoPlanName]
    } else {
        const planIdx = getPricingPlanIdx(planTier)

        if (isGrandfather){
            return oldPricing[planIdx]
        } else {
            return pricing[planIdx]
        }
    }
}