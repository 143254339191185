import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class QueriesDesc extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Query Your Spreadsheet Data</div>
                <div style={this.props.subheadingStyle}>Use SQL-style queries to Read, Update, or Delete your data super easily </div>
                {/*<div className="featureModalSimpleAPIExampleTableRequest">*/}
                    {/*<span className="featureModalSubHeader"><Icon name="code" /> You can add queries to your API URL</span>*/}
                    {/*<br/><br/>*/}
                    <pre id="apiFeatureModalURLBox">
                            {/*https://api.apispreadsheets.com/data/100/?query=<br/><span id="flashingQuery">SELECTnameFROM100WHEREage>90</span>*/}
                        https://api.apispreadsheets.com/data/100/?query=<span style={{fontWeight: '800', color: 'lightgreen'}}>SELECT name FROM your_spreadsheet WHERE age>90</span>
                    </pre>
                {/*</div>*/}
            </div>

        )
    }
}

export default QueriesDesc