import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class StartUpdate extends Component {
    render() {
        return (
            <span>
                <div style={this.props.headerStyle}>1. Query will be in a similar format to SQL</div>
                <p><Icon name="caret right" /> Only <b>SELECT</b>, <b>FROM</b> and <b>WHERE</b> identifiers are allowed. Other SQL identifiers like <b>ORDER BY</b> and <b>GROUP BY</b> are not allowed</p>
                <p><Icon name="caret right" /> Only <code>*</code> is allowed as the selector. Columns that are to be updated are specified in the <b>Request Body</b></p>
                <div className="codeTheoryBox">
                    <span>SELECT <code>*</code> FROM <code>fileID</code> WHERE <code>filterClause</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <b>Valid</b>
                    <span>SELECT <code>*</code> FROM <code>{this.props.fileID}</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <b>Valid</b>
                    <span>SELECT <code>*</code> FROM <code>{this.props.fileID}</code> WHERE <code>{"email LIKE '%gmail%"}</code></span>
                </div>
                <br/>
                <div className="codeInvalidBox">
                    <b>Invalid</b>
                    <span>SELECT <code>name, address</code> FROM <code>{this.props.fileID}</code> WHERE <code>id>5</code></span>
                </div>
                <br/>
                <div className="codeInvalidBox">
                    <b>Invalid</b>
                    <span>SELECT <code>*</code> FROM <code>{this.props.fileID}</code> WHERE <code>name='JFK'</code> Group By <code>date</code></span>
                </div>
                <br/>
            </span>
        )
    }
}

export default StartUpdate