import React, {Component} from 'react'
import {gunaldiStyles} from "./GunaldiDesignStyles";
import Loader from 'react-loader-spinner'
import InfoPopup from "./InfoPopup";

class InfoSegment extends Component {
    getInfoRow = (info, idx) => {
        return <div style={{marginBottom: '16px', display: "grid", gridTemplateColumns: "40% 60%", columnGap: "24px"}} key={idx}>
            <div style={{fontWeight: '700', fontSize: "14px", color: gunaldiStyles.subText}}>
                {info.label} {'info' in info ? <InfoPopup info={info.info}/> : null}
            </div>
            <div>
                {
                    typeof info.value === "string" ?
                    <span style={{color: gunaldiStyles.mainText, fontSize: "14px", fontWeight: "400"}}>{info.value}</span> :
                    info.value
                }
            </div>
        </div>
    }

    getDisplay = () => {
        let isLoading = false;

        if ('loading' in this.props){
            isLoading = this.props.loading
        }

        if (isLoading){
            return <Loader
                type="TailSpin"
                color="black"
                height="25"
                width="25"
            />
        } else {
            if (Array.isArray(this.props.info)){
                return this.props.info.map((info, idx) => this.getInfoRow(info, idx))
            } else {
                return this.props.info
            }
        }
    }

    render() {
        return (
            <div style={{border: "1px solid #F0F0F0", borderRadius: "4px", padding: "16px"}}>
                <div style={{ marginBottom: "16px"}}>
                    {
                        typeof this.props.header === "string" ?
                            <span style={{fontWeight: "700", fontSize: "14px", color: "#161E16"}}>
                                {this.props.header}
                            </span> :
                            this.props.header
                    }
                </div>
                {
                    this.getDisplay()
                }
            </div>
        )
    }
}

export default InfoSegment