import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import { Icon } from 'semantic-ui-react'
import CreateTeamModalBody from "./CreateTeamModalBody"
import DisplayHeader from "../../FileDnD/FilesID/DisplayHeader";

class UpdatedPricingModal extends Component {
    render() {
        return (
            <ModalApp name="createTeamModal"
                      header={<DisplayHeader header="Create New Team"/>}
                      body={<div style={{paddingTop: "16px"}}>
                          <CreateTeamModalBody />
                      </div>}
            />
        )
    }
}

export default UpdatedPricingModal