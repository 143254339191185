import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import { Icon } from 'semantic-ui-react'
import AlertsModalBody from "./AlertsModalBody"

class AlertsModal extends Component {
    render() {
        return (
            <ModalApp name="alertsModal"
                      header={<h2><Icon name="paper plane outline"/> Success/Error Alerts</h2>}
                      size="medium"
                      body={<AlertsModalBody/>}
            />
        )
    }
}

export default AlertsModal