import React, {Component} from 'react'
import AceEditor from 'react-ace';
import 'brace/mode/html';
import CommentSection from "../CommentSection";

class ApiBlog extends Component {
    render() {
        const startCode='import requests\n' +
        'r = requests.get("https://api.apispreadsheets.com/data/581/")\n' +
        'print(r)\n' +
        'data = r.json()'
        const secondCode='import requests\n' +
            'r = requests.get("https://api.apispreadsheets.com/data/581/")'
        const thirdCode='{"data":[{"county_name":"Atlantic County","cases":"2251","deaths":"162"},{"county_name":"Bergen County","cases":"18333","deaths":"1584"},{"county_name":"Burlington County","cases":"4680","deaths":"297"},{"county_name":"Camden County","cases":"6603","deaths":"339"},{"county_name":"Cape May County","cases":"628","deaths":"50"},{"county_name":"Cumberland County","cases":"2345","deaths":"78"},{"county_name":"Essex County","cases":"17752","deaths":"1672"},{"county_name":"Gloucester County","cases":"2262","deaths":"148"},{"county_name":"Hudson County","cases":"18455","deaths":"1188"},{"county_name":"Hunterdon County","cases":"999","deaths":"60"},{"county_name":"Mercer County","cases":"7004","deaths":"477"},{"county_name":"Middlesex County","cases":"16021","deaths":"997"},{"county_name":"Monmouth County","cases":"8289","deaths":"608"},{"county_name":"Morris County","cases":"6512","deaths":"617"},{"county_name":"Ocean County","cases":"8817","deaths":"743"},{"county_name":"Passaic County","cases":"16234","deaths":"931"},{"county_name":"Salem County","cases":"645","deaths":"42"},{"county_name":"Somerset County","cases":"4605","deaths":"419"},{"county_name":"Sussex County","cases":"1123","deaths":"148"},{"county_name":"Union County","cases":"15868","deaths":"1078"},{"county_name":"Warren County","cases":"1173","deaths":"132"}]}'
        const fourthCode='data = {"data": {"county_name": "New County", "cases": "2251", "deaths": "162"}}'
        const fifthCode='r = requests.post("https://api.apispreadsheets.com/data/581/", json={"data": data})'
        return(
            <div>
                <h1>So, what the hell is an API?</h1>
                    <p> An <a href="https://en.wikipedia.org/wiki/Application_programming_interface">Application Programming Interface (API)</a> in general stands for any computing interface which defines interactions between multiple (often disparate) chunks of software.</p>

                    <p>These chunks of software could be a library, such as Pandas in Python, and your Python script. Or your front-end Javascript website communicating with your PHP backend.</p>
                    <br/>
                <figure>
                    <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/700/1*z0odoY9q80eI4Uu95GHMWQ.png" alt="apiexample"/>
                    <figcaption style={{textAlign: 'center'}}><sub>Example of a generic API</sub></figcaption>
                </figure>
                <hr/>
                <h1>Okay, but… how the heck does it help me? </h1>
                    <p>APIs are a great way to save your time! Instead of putting in hours of work writing code to do something that’s already been done, you can use an API that bridges to the code you need.</p>
                    <p>To better understand this, let’s think of a translator: instead of learning a new language, you can use a translator to talk to someone speaking that language.</p>
                    <p>APIs let us communicate across different applications without wasting any of our precious time!</p>

                <h1>Sounds interesting, so what in the world are these REST APIs I keep hearing of?</h1>
                    <p><b>RE</b>presentational <b>S</b>tate <b>T</b>ransfer (REST) APIs are the most popular type of APIs. REST APIs allow us to bridge together our code using a <a href="https://en.wikipedia.org/wiki/Representational_state_transfer">defined convention</a> that’s easy to use over the internet aka HTTP(s).</p>
                    <p>REST APIs are extremely popular these days. If you ever hear the term API being used, you can confidently assume that it is a REST API.</p>
                    <p>Due to this convention, we’re able to easily communicate between two different applications using HTTP(s), such as an Excel spreadsheet in the cloud and your Python code.</p>
                <hr/>
                <h1>Sweet, but how can I use these REST APIs?</h1>
                    <p>REST APIs can be used by making a <b>request</b> to a <b>URL</b> and receiving a <b>response.</b></p>
                    <p>The URL gets accessed using the same process as if you went to your browser and typed in <a href="www.apispreadsheets.com">www.apispreadsheets.com</a> or <a href="www.medium.com">www.medium.com</a> except it is called through your code.</p>
                <figure>
                <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/700/1*VAnCL2W_AK87g0G1omykjw.png" alt="apiworks"/>
            </figure>
                    <br/>
                    <p>Let’s take a deeper look at <b>URLs, requests</b> and <b>responses</b> using our product <a href="www.apispreadsheets.com">APISpreadsheets</a> API and Python as examples.</p>
                    <br/>
                    <hr/>
                <h1>Are we starting with Requests?</h1>
                    <p>Yup! Because to use an API you have to make a <b>request.</b> The <b>request</b> is made from the client, which could be your Python code, your browser or another application running on your computer such as <a href="https://www.postman.com/"> Postman. </a></p>
                    <p>There are many <a href="https://en.wikipedia.org/wiki/Hypertext_Transfer_Protocol#Request_methods"> <b> request types </b></a> but there are only two super common ones that you need to be aware of:</p>
                    <ol>
                        <li> <b>GET</b> is used to request data from a specified resource using a <b>URL</b></li>
                        <li><b>POST</b> is used to send data to a server to create/update a resource using a <b>URL</b></li>
                    </ol>
                    <br/>
                    <hr/>
                <h1>Okay, how do I make these Requests then?</h1>
                    <p>We will go through the full code steps for Python but just for reference this is what the final code will look like:</p>
                    <AceEditor
                        mode="python"
                        theme="monokai"
                        value={startCode}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={70}
                        width="auto"
                    />
                    <br/><br/>

                    <h4><b>Step 1: Request Library</b></h4>
                    <p>In Python, you can make <b>requests</b> by first importing the requests library at the beginning of your code</p>
                <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px'}}>
                <code>import requests</code>
                </div>
                    <br/><br/>
                <p><i>Be sure that the request library is installed already. If not you can </i> <code>$ pip install requests</code></p>
                    <h4><b>Step 2: Create your API URL</b></h4>
                    <p>You need a <b>URL</b> to make <b>requests</b> to. To make requests to your own spreadsheet using API Spreadsheets, follow our <a href="https://apispreadsheets.com/api-docs/?docsMenuItem=quickstart"> tutorial </a> to get your <b>URL</b></p>

                    <p>For now, you can use this <b>URL.</b> It accesses a COVID-19 case data spreadsheet from New Jersey from before June 3rd 2020.</p>

                <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px'}}> <a href="https://api.apispreadsheets.com/data/581/">https://api.apispreadsheets.com/data/581/</a></div>
                    <br/><br/><br/>
                    <h4><b> Step 3: Perform your request</b></h4>
                    <p>Using your <b>URL</b> that you just created, you can now perform a GET <b>request</b> on your spreadsheet and store its <b>response</b> in a variable r.</p>

                    <p><i>Remember, a GET <b>request</b> is used to get data from a specified resource</i></p>

                    <AceEditor
                        mode="python"
                        theme="monokai"
                        value={secondCode}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={50}
                        width="auto"
                    />
                    <br/><br/>
                    <h4><b>Step 4: Verify everything went smoothly </b></h4>
                    <p>No matter what happens you always get a <b>response</b> back from a <b>request.</b></p>
                    <p>Before we actually retrieve data from the <b>response,</b> let’s first test to make sure the <b>request</b> went through properly. Add in a print line to your code:</p>
                <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px'}}>
                    <code>print(r) </code>
                </div>
                    <br/>
                    <p>At this point, you want to run the program and get an output saying <b>“Response [200]”.</b> The 200 is the <b>response code</b> and indicates that your API request worked.</p>
                    <hr/>
                <h1>Ooo cool! So what happens if the request didn’t work?</h1>
                    <p>Well, I am glad you asked. If the request didn’t work you get any other <b>response</b> code besides 200.</p>

                    <p>In fact, these <b>response</b> codes being standardized is what makes using REST APIs easy.</p>

                    <p>There are a <a href="https://www.restapitutorial.com/httpstatuscodes.html">bunch of <b>response</b> codes</a> but it’s up to a particular API’s designers and developers to return the appropriate ones.</p>

                    <p>At API Spreadsheets, for a GET <b>request</b> we return any of the following codes</p>
                <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px'}}>
                    <ol>
                        <li> <b> 200 — </b>OK, <i>everything went smoothly</i></li>
                        <li><b> 401 — </b>Unauthorized, <i> don’t have permission to access a file</i></li>
                        <li><b> 404 — </b> Not Found</li>
                        <li><b>500 — </b>Server Error, <i>something wrong happened on the server</i></li>
                    </ol>
                </div>
                    <br/><br/><br/>
                <h1>That seems helpful. What happened to the data in the response?</h1>
                    <p>Don’t worry. The data is also in the <b>response</b> and can be accessed through the <b>response’s</b> JSON parameter/method.</p>
                <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px'}}>
                    <code>data = r.json()</code>
                </div>
                    <br/><br/>
                <hr/>
                    <h1>Wait, what is JSON?</h1>
                    <p><a href="https://en.wikipedia.org/wiki/JSON">Javascript Object Notation (JSON)</a> is a data format that is widely used to exchange data between applications due to its fairly straightforward format.</p>

                    <p>You can see what the data JSON looks like by opening the URL, <a href="https://api.apispreadsheets.com/data/581/">https://api.apispreadsheets.com/data/581/</a> in your browser 😀</p>
                    <AceEditor
                        mode="javascript"
                        theme="monokai"
                        value={thirdCode}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={50}
                        width="auto"
                        />
                <div style={{textAlign:"center"}}><sub>JSON Example from the Data</sub></div>
                <br/><br/>

                    <p>Keep in mind that a <b>response</b> doesn’t have to contain JSON data. It’s again up to the designers and developers of an API. As is the format of the JSON itself.</p>
                    <p>We at API Spreadsheets, only return JSON data with a GET request but a POST request doesn’t have JSON data returned.</p>
                    <p>And you can see the format of the JSON data in our <a href="https://www.apispreadsheets.com/docs">documentation.</a> Most APIs will have good documentation detailing the format of their JSON and their <b>response</b> codes.</p>
                    <hr/>
                <h1>Oh crap, does this meaning executing a POST request is different too?</h1>
                    <p>Yes, but not too much. You are still making the <b>request</b> but now you will also need to include a JSON object that contains your data.</p>

                    <p><i>Remember, a POST request is used to send data to a server to create/update a resource.</i></p>

                    <h4><b>Step 1: Request Library</b></h4>
                    <p>In Python, you can make <b>requests</b> by first importing the <b>requests</b>  library at the beginning of your code</p>
                <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px'}}>
                    <code>import requests</code>
                </div>
                <br/><br/>

                    <h4><b>Step 2: Create your API URL</b></h4>
                    <p>To make <b>requests</b>  to your own spreadsheet using API Spreadsheets, follow our <a href="https://www.apispreadsheets.com/tutorials/read-spreadsheet-excel-file-R-Python"> tutorial </a>to get your <b>URL.</b></p>

                    <p>For now, you can use this <b>URL.</b> It accesses a COVID-19 case data spreadsheet from New Jersey from before June 3rd 2020.</p>
                <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px'}}>
                    <a href="https://api.apispreadsheets.com/data/581/">https://api.apispreadsheets.com/data/581/</a>
                </div>
                    <br/><br/>
                    <h4><b>Step 3: Perform your POST request with your JSON data</b></h4>
                    <p>First, we need to prepare the data we want to add.</p>
                    <p>I know you are probably getting tired of hearing this, but the format of this data is specified by the developer and designer of the API.</p>

                    <p>For API Spreadsheets, we can add data to the spreadsheet in this format</p>

                    <AceEditor
                        mode="javascript"
                        theme="monokai"
                        value={fourthCode}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={50}
                        width="auto"
                    />
                    <br/>
                    <p>We can perform the POST <b>request</b> like this</p>

                    <AceEditor
                        mode="javascript"
                        theme="monokai"
                        value={fifthCode}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={50}
                        width="auto"
                    />
                    <br/>
                    <p><i>FYI — You might hear this JSON data be referred to as the Body</i></p>
                    <br/>
                    <h4><b>Step 4: Verify Everything went smoothly</b></h4>
                    <p>You can print the <b>response</b> or check if everything went well. You should get a <b>201</b> code, different than the 200 from GET. 201 stands for created whereas 200 is OK.</p>
                    <hr/>
                <h1>Wrapping Everything Up</h1>
                <br/>
                <p>Congratulations! You have made it to the end. Now you should know what an API is, how it can help you and how to make simple requests in Python!</p>
                <br/>
                <p>If you have any further questions, feel free to email us at <a href="mailto:info@lovespreadsheets.com">info@lovespreadsheets.com!</a> We can’t wait to see what you build.</p>
                <p>Checkout our other <a href="/tutorials">tutorials! </a> </p>

                <CommentSection commentDiv={<div className="fb-comments" data-href="https://www.apispreadsheets.com/tutorials/what-the-hell-is-an-api" data-numposts="5" data-width="" />}/>
            </div>
                )
    }
}
export default ApiBlog;