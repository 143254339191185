import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyEditID} from "../../Actions/ModifyEditID";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
import isObjectEmpty from "../../Constants/isObjectEmpty";

class ReportingButton extends Component {
    openModal = (alreadySetUpReport) => {
        this.props.modifyModalInfo({pk: this.props.pk, alreadySetUpReport: alreadySetUpReport})
        this.props.modifyEditID(this.props.pkID);
        this.props.toggleModal("reportingModal")
    }

    render() {
        let alreadySetUpReport = false;

        if ("reportInfo" in this.props.fileInformation[this.props.pkID]){
            if (!isObjectEmpty(this.props.fileInformation[this.props.pkID].reportInfo)){
                alreadySetUpReport = true;
            }
        }

        return (
            <div>
                <Button content={alreadySetUpReport ? "View Auto Reporting" : "Setup Auto Reporting"}
                        basic
                        color='blue'
                        icon='mail outline'
                        onClick={e => this.openModal(alreadySetUpReport)} fluid/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo,
    modifyEditID: modifyEditID
}

export default connect(mapStateToProps, mapActionsToProps)(ReportingButton)