import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Dropdown } from 'semantic-ui-react'

class FileTypeFilter extends Component {
    constructor(props) {
        super(props);

        this.initDropdownOptions = this.initDropdownOptions.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }

    initDropdownOptions(){
        const fileTypeMaps = {
            googleSheets: "Google Sheets",
            file: "Local File",
            dropbox: "Dropbox",
            s3: "AWS S3",
            quickstart: "Quickstart",
            database: "Database"
        }

        let options = [];

        for (let i=0; i<this.props.originalFileTypes.length; i++) {
            options.push(
                {
                    key: i, value: this.props.originalFileTypes[i], text: fileTypeMaps[this.props.originalFileTypes[i]]
                }
            )
        }

        return options
    }

    getLabelInfo = (value, key) => {
        const optionsInfo = {
            googleSheets: {label: "Google Sheets", icon: "google drive", color: "#479B5F"},
            file: {label: "Local File", icon: "folder open outline", color: "#6FBB98"},
            dropbox: {label: "Dropbox", icon: "dropbox", color: "#2F6ED9"},
            s3: {label: "AWS S3", icon: "aws", color: "#FF9900"},
            quickstart: {label: "Quickstart", icon:"code", color: "#161E16"},
            database: {label: "Database", icon: "database", color: "#E27837"}
        }

        return optionsInfo[value][key]
    }

    handleDropdownChange(e, { value }){
        this.props.changeState("fileTypes", value)
    }

    renderLabel = (label) => ({
        color: this.getLabelInfo(label.value, "color"),
        content: ``,
        icon: this.getLabelInfo(label.value, "icon"),
    })

    render() {
        return (
            <Dropdown
                style={{minHeight:'40px', top:'0px',backgroundColor:'#F0F0F0',border:'none',padding:'8px 8px 8px 12px', borderRadius: "0"}}
                fluid
                inline
                selection
                multiple
                options={this.initDropdownOptions()}
                value={this.props.fileTypes}
                placeholder='File Source'
                onChange={this.handleDropdownChange}
                renderLabel={this.renderLabel}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FileTypeFilter)