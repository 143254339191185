import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyImportSheetsInfo} from "../../Actions/ModifyImportSheetsInfo";
import { Button } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {modifyCustomImportSettings} from "../../Actions/ModifyCustomImportSettings";

class ImportConfigNameSaveBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false
        }
    }

    saveName = (e) => {
        this.setState({saving: true});
        const error = () => {
            this.setState({saving: false})
            alert("There was an error saving your new name :(")
        }

        // fetch
        fetch(API_Root + "api/update-import-details/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                importHash: this.props.importHash,
                changeObject: {name: this.props.configName}
            })
        }).then((res) => {
            if (res.status === 200) {
                this.updateNameInRedux()
            }
            else {
                error()
            }
        }).catch(err => error())
    }

    updateNameInRedux = () => {
        if (this.props.custom) {
            const info = JSON.parse(JSON.stringify(this.props.customImportInfo));
            info.name = this.props.configName
            this.props.modifyCustomImportSettings("info", info)
        } else{
            const importInfo = JSON.parse(JSON.stringify(this.props.importSheetsInfo))

            importInfo[this.props.idx].name = this.props.configName;

            this.props.modifyImportSheetsInfo(importInfo)
        }

        this.setState({
            saving: false,
        }, () => this.props.stopEditing())
    }

    render() {
        return (
            <Button content="Save"
                    style={{backgroundColor: "#6FBB98", color: "white"}}
                    icon="save outline"
                    disabled={this.props.originalName.trim() === this.props.configName.trim()}
                    onClick={this.saveName}
                    loading={this.state.saving}
                    size="mini"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    importSheetsInfo: state.mainState.importSheetsInfo,
    customImportInfo: state.mainState.customImportInfo
})

const mapActionsToProps = {
    modifyImportSheetsInfo: modifyImportSheetsInfo,
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(ImportConfigNameSaveBtn)