import React, {Component} from 'react'
import { auth } from '../../firebase';
import { Button } from 'semantic-ui-react'
import {connect} from "react-redux";
import {resetAllStates} from "../../../Actions/ResetAllStates";
import { push } from 'react-router-redux'

class SignOutButton extends Component{
    constructor(props){
        super(props);

        this.signOut = this.signOut.bind(this);
    }

    signOut(e){
        auth.doSignOut()
        if (this.props.isProductImporter){
            this.props.navigateTo("/importer")
        } else {
            this.props.navigateTo("/")
        }
        this.props.resetAllStates();
    }

    render(){
        return(
            <div>
                <Button
                    // color="orange"
                        id="signOutButton" onClick={this.signOut}>Logout</Button>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    isProductImporter: state.mainState.isProductImporter
})

const mapActionsToProps = {
    resetAllStates: resetAllStates,
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(SignOutButton)