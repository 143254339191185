import React, {Component} from 'react'
import { Message, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal"

class TestDatabaseMessage extends Component {
    openTutorialModal = (modalName) => {
        this.props.toggleModal(modalName)
    }

    render() {
        if (this.props.connectionValid !== null){

            const header = this.props.connectionValid ? "Congratulations! Your connection was successful :)" : "There was an error connecting to your database"
            const content = this.props.connectionValid ?
                !('edit' in this.props) ? <span><a href={"/database/" + this.props.connectionValidMessage}>Get started with getting and sharing your data <Icon name="arrow right" /></a></span> : null
                : <div>{this.props.connectionValidMessage}<br/><br/>Read our <span className="linkStyle" onClick={e => this.openTutorialModal("databaseConnectionErrorModal")}>Troubleshoot Database Connection</span> guide for help if you keep failing to connect</div>

            return (
                <div>
                    <Message
                        success={this.props.connectionValid}
                        negative={!this.props.connectionValid}
                        header={header}
                        content={content}
                        size="huge"
                    />
                </div>
            )
        } else {
            return (
                null
            )
        }

    }
}

const mapStateToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapStateToProps)(TestDatabaseMessage)