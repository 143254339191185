import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react';

class DatasetDownload extends Component {
    constructor(props) {
        super(props);

        this.downloadDataset = this.downloadDataset.bind(this);
    }

    downloadDataset(e){
        this.downloadDatasetLink.click()
    }

    render() {
        return (
            <div className="datasetDownloadButton" onClick={this.downloadDataset} style={'curve' in this.props ? {"borderRadius": "10px"} : null}>
                <a
                    style={{display: 'none'}}
                    ref={downloadDatasetLink => this.downloadDatasetLink = downloadDatasetLink}
                    href={this.props.fileURL}
                >Download hidden</a>
                <span><Icon name="file alternate outline" /></span>
                <p>Download</p>
            </div>
        )
    }
}

export default DatasetDownload