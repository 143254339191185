import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import ModalApp from '../Modal'
import SupportModalBody from "./SupportModalBody"

class SupportModal extends Component {
    render() {
        return (
            <ModalApp name="supportModal"
                      header={<h2><Icon name='envelope' /> Support</h2>}
                      body={<div><br/><SupportModalBody /></div>}
                      size="large"
            />
        )
    }
}

export default SupportModal