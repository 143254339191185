import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import isObjectEmpty from "../Constants/isObjectEmpty";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import API_Root from "../Constants/API_Root";
import {initUploadedPK} from "../Actions/InitUploadedPK";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import copyArray from "../Constants/copyArray";
import copyObject from "../Constants/copyObject";
import {toggleToast} from "../Actions/ToggleToast";
import {toggleModal} from "../Actions/ToggleModal";
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import {push} from "react-router-redux";
import {modifyUpgradeText} from "../Actions/ModifyUpgradeText";

class CreateQuickstartButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false
        }
    }

    saveQuickstartAPI = () => {
        this.setState({ saving: true })

        const error = (err) => {
            this.setState({
                saving: false
            }, () => {
                console.log(err.toString())
                this.props.toggleModal(null)
                this.props.toggleToast({show: true, message: "There was an error creating your quickstart API. Please try again", type: "error"})
            })

        }

        fetch(API_Root + 'api/save-quickstart-api/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                name: this.props.info.name.trim(),
                rows: this.props.info.rows,
                desc: this.props.info.desc.trim(),
                jsonOutput: this.props.jsonOutput,

            })
        }).then(res => {
            if (res.status === 201){
                res.json().then(data => {
                    let filePK = JSON.parse(JSON.stringify([data.filePK]));
                    let uploadedPK = JSON.parse(JSON.stringify([data.filePK]));

                    if (this.props.filePK.length > 0){
                        filePK = copyArray(this.props.filePK, data.filePK);
                    }

                    if (this.props.uploadedPK.length > 0){
                        uploadedPK = data.filePK.concat(this.props.uploadedPK)
                    }

                    this.props.initUploadedPK(uploadedPK)
                    this.props.modifyFilePK(filePK);

                    let fileInformation = {[data.filePK.id]: data.fileInformation}
                    if (typeof this.props.fileInformation !== 'undefined'){
                        if (Object.entries(this.props.fileInformation).length !== 0 && this.props.fileInformation.constructor === Object){
                            fileInformation = copyObject(this.props.fileInformation, {[data.filePK.id]: data.fileInformation});
                        }
                    }

                    this.props.modifyFileInformation(fileInformation)

                    this.setState({ saving: false },
                        () => {
                            const fileHash = fileInformation[data.filePK.id].fileHash
                            this.props.navigateTo("/files/" + fileHash)

                            this.props.toggleModal(null)
                        })
                }).catch(err => error(err))
            } else {
                if (res.status === 402){
                    res.json().then(data => {
                        this.setState({
                            saving: false
                        }, () => {
                            this.props.modifyUpgradeText(data.message)
                            this.props.toggleModal("upgradeModal")
                        })
                    }).catch(err => error(err))
                } else{
                    error(res.status)
                }
            }
        }).catch(err => error(err))
    }

    render() {
        return (
            <Button style={gunaldiStyles.btnGreen}
                    content="Create Your Quickstart API" icon="code"
                    loading={this.state.saving}
                    onClick={this.saveQuickstartAPI}
                    disabled={isObjectEmpty(this.props.jsonOutput) || this.props.info.name.trim() === ""} />
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    uploadedPK: state.mainState.uploadedPK
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation,
    initUploadedPK: initUploadedPK,
    modifyFilePK: modifyFilePK,
    toggleToast: toggleToast,
    toggleModal: toggleModal,
    navigateTo: push,
    modifyUpgradeText: modifyUpgradeText,
}

export default connect(mapStateToProps, mapActionsToProps)(CreateQuickstartButton)