import React, {Component} from 'react'
import {connect} from 'react-redux'
import URLBox from "../URLBox"
import API_Backend_Root from "../../../Constants/API_Backend_Root";
import { Popup } from 'semantic-ui-react'
import RequestLabel from "./RequestLabel";

class APIIntroduction extends Component {
    render() {
        const imgStyle = {border:"1px solid #F0F0F0", display: "block", marginBottom: "16px"}
        const requestBox = {border:"1px solid #F0F0F0", borderRadius: "8px", padding: "16px"}

        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">General</p>
                    <p>The API is based around <a href="https://en.wikipedia.org/wiki/Representational_state_transfer" >REST</a> principles.</p>
                    <p>The API takes either a GET or a POST Request depending on the action.</p>
                    <p>The Response from each endpoint is a predictable response code. Depending on the action, a JSON encoded response will also be returned.</p>
                    <p>The API assumes beginner level proficiency in programming (able to run a piece of code on your computer). You can get started with our code samples with little to no programming knowledge as long as you know how to run code.</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Base URL</p>
                    <p>The Base URL for all requests is as follows</p>
                    <URLBox urlType="BASE URL" url={API_Backend_Root + "data/[fileID]"}/>
                    <br/>
                    <p>It takes 1 parameter which is the ID of the File/API</p>
                    <div>
                        <span className="parameterName">fileID</span> <span className="parameterType">string</span>
                        <span className="parameterSecondLine"><span className="parameterRequired">Required</span> </span>
                        <span className="parameterDesc">This is the id of the file you are making requests to. It can be found on your file page
                            <Popup content={<img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/API-Introduction/FileID.png" alt="File ID"/>}
                                    position='top center'
                                   trigger={<span className="linkStyle"> here</span>} />
                        </span>
                    </div>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Requests</p>
                    <p>Depending on the action you need to take for the API, you will make either a <RequestLabel type="get"/> or a <RequestLabel type="post"/> request</p>
                    <div className="row">
                        <div className="col-sm-6">
                            <div style={requestBox}>
                                <div>
                                    <RequestLabel type="get"/> Request Actions
                                </div>
                                <div style={{marginTop: "16px"}}>
                                    <ol>
                                        <li onClick={e => this.props.setBothMenuItems("api-read")}> <span className="linkStyle">Read Rows</span></li>
                                        <li onClick={e => this.props.setBothMenuItems("api-delete")}> <span className="linkStyle">Delete Rows</span></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div style={requestBox}>
                                <div>
                                    <RequestLabel type="post"/> Request Actions
                                </div>
                                <div style={{marginTop: "16px"}}>
                                    <ol>
                                        <li onClick={e => this.props.setBothMenuItems("api-create")}> <span className="linkStyle">Create Rows</span></li>
                                        <li onClick={e => this.props.setBothMenuItems("api-update")}> <span className="linkStyle">Update Rows</span></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Response</p>
                    <p>Depending on the action, the response from the API will be different</p>
                    <p>However, the following error response codes are common to all API requests</p>
                    <hr/>
                    <div className="responseCodeLine">
                        <code className="responseCodeStatus">401</code> <span className="responseCodeParam codeFail">Unauthorized</span>
                        <span className="responseCodeDesc">The data is private and incorrect access and secret keys were provided</span>
                    </div>
                    <hr/>
                    <div className="responseCodeLine">
                        <code className="responseCodeStatus">402</code> <span className="responseCodeParam codeFail">Payment Required</span>
                        <span className="responseCodeDesc">You are over the row limit per file or request limit for your plan</span>
                    </div>
                    <hr/>
                    <div className="responseCodeLine">
                        <code className="responseCodeStatus">404</code> <span className="responseCodeParam codeFail">Not Found</span>
                        <span className="responseCodeDesc">The fileID does not exist</span>
                    </div>
                    <hr/>
                    <div className="responseCodeLine">
                        <code className="responseCodeStatus">500</code> <span className="responseCodeParam codeFail">Server Error</span>
                        <span className="responseCodeDesc">There was something wrong with our server OR the server of the connected platform (like Google Sheets)</span>
                    </div>
                    <hr />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(APIIntroduction)