import React, {Component} from 'react'
import {connect} from 'react-redux'
import FileURL from "../../FileDnD/FileURL"
import ImportConfigDeleteBtn from "../ImportConfigDelete/ImportConfigDeleteBtn"

class ImportPageHeaderToolbar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{display: "grid", gridTemplateColumns: "60% 40%"}}>
                <div>
                    <FileURL import importHash={this.props.importHash} isImportSegment message={<span style={{fontFamily: 'Lato', fontStyle: "normal", fontWeight: "700",  fontSize: "12px", paddingLeft: "2px", marginRight: "10x" }}>Import URL</span>} />
                </div>
                <div style={{textAlign: "right"}}>
                    <ImportConfigDeleteBtn importHash={this.props.importHash}
                                           idx={this.props.idx}
                                           custom={true}
                                           importName={this.props.importName}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ImportPageHeaderToolbar)