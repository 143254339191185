import React, {Component} from 'react'
import { connect } from 'react-redux'
import {modifyModalQueryQuickstartType} from "../../../Actions/ModifyModalQueryQuickstartType";
import { Dropdown, Form, TextArea, Button, Message } from 'semantic-ui-react'
import {testQueryValidity} from "../../../Constants/testQueryValidity";
import QuickstartRules from "./QuickstartRules/QuickstartRules";
import {mapPkAndPkid} from "../../../Constants/mapPkAndPkid";
import CustomAccordion from "../../../SharedComponents/CustomAccordion"
import API_Backend_Root from "../../../Constants/API_Backend_Root";

class QueryBody extends Component {
    constructor(props){
        super(props);

        this.state = {
            query: '',
            message: null,
            testing: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
        this.testQueryValidity = this.testQueryValidity.bind(this);
        this.toggleQueryType = this.toggleQueryType.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.editID !== prevProps.editID) {
            this.setState({
                query: '',
                message: null
            })
        }
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    dismissMessage(e){
        this.setState({ message: null })
    }

    testQueryValidity(e){
        this.setState({ testing: true })
        // const pk = mapPkAndPkid(this.props.filePK, "id", this.props.editID);
        // const queryValidity = testQueryValidity(this.state.query, this.props.modalQueryQuickstartType, this.props.fileInformation[this.props.editID], pk)

        const successAction = () => this.setState({
            message: <div>
                <br/>
                <Message
                    header="Your Query is Valid!"
                    content={<span role="img" aria-label="smile" style={{fontSize: '110%'}}>😀🎉❤️🍻🎊</span>}
                    onDismiss={this.dismissMessage}
                    positive
                />
            </div>,
            testing: false
        });

        const invalidAction = (invalidMessage) => this.setState({
            message: <div>
                <br/>
                <Message
                    header="Your Query is Invalid"
                    content={invalidMessage}
                    onDismiss={this.dismissMessage}
                    negative
                />
            </div>,
            testing: false
        })

        // if (queryValidity.isQueryValid){
        //     if (queryValidity.queryMessage === ""){
        //         successAction()
        //     }
        //     else{
                fetch(API_Backend_Root + "api/test-sql-filter-clause/", {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                    },
                    body: JSON.stringify({
                        email: localStorage.getItem("email"),
                        token: localStorage.getItem("token"),
                        data: this.props.fileInformation[this.props.editID].jsonRowSample,
                        query: this.state.query,
                        dataFormat: "jsonRow",
                        columnInfo: this.props.fileInformation[this.props.editID].columnInformation
                    })
                }).then((res) => {
                    if (res.status === 200){
                        successAction()
                    }
                    else{
                        invalidAction(<span>Your <b>filterClause</b> is not valid. Read the rules to make sure you are writing it correctly.</span>)
                    }
                }).catch(err => invalidAction("There was a problem on our server validating the Query. Try again or read the rules!"))
            // }
        // }
        // else{
        //     invalidAction(queryValidity.queryMessage)
        // }

    }

    toggleQueryType(e, { value }){
        this.props.modifyModalQueryQuickstartType(value)
    }


    render() {
        const queryTypeOptions = [
            {key: 1, text: 'Read', value: 'readAction'},
            {key: 2, text: 'Update', value: 'updateAction'},
            {key: 3, text: 'Delete', value: 'deleteAction'},
        ];


        return (
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <div style={{float: 'left', fontSize: '16px', fontWeight: '700'}}>Enter your query and test if it's valid</div>
                        <span style={{float: 'right'}}>
                            <Dropdown
                                placeholder="Choose Action Type"
                                name={"queryQuickstartType" + this.props.editID.toString()}
                                value={this.props.modalQueryQuickstartType}
                                options={queryTypeOptions}
                                onChange={this.toggleQueryType}
                            />
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Form>
                            <TextArea
                                value={this.state.query}
                                placeholder="select*from814wherecol_name=123"
                                onChange={this.handleInputChange}
                                name="query"
                            />
                            <br/><br/>
                            <Button color="blue"
                                    content="Test Query Validity"
                                    icon="question circle outline"
                                    onClick={this.testQueryValidity}
                                    loading={this.state.testing}/>
                            {this.state.message}
                            <br/>
                        </Form>
                    </div>
                </div>
                <div className="row" style={{marginTop: "16px"}}>
                    <div className="col-sm-12">
                        <CustomAccordion
                            body={<QuickstartRules actionType={this.props.modalQueryQuickstartType}
                                                   filePK={this.props.filePK}
                                                   fileInfo={this.props.fileInformation}
                                                   editID={this.props.editID}
                            />}
                            header={<span>How do I write a valid {this.props.modalQueryQuickstartType.replace("Action", "")} Query?</span>}
                            accordionStyle={{
                                borderRadius: '4px',
                                backgroundColor: 'white',
                                border: "1px solid #F0F0F0"
                            }}
                            headerDivStyle={{
                                borderRadius: '4px',
                                backgroundColor: 'white',
                                fontSize: '16px',
                                fontWeight: '700',
                                paddingTop: '7px',
                                paddingBottom: '7px'
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    modalQueryQuickstartType: state.mainState.modalQueryQuickstartType,
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK
})

const mapActionsToProps = {
    modifyModalQueryQuickstartType: modifyModalQueryQuickstartType
}

export default connect(mapStateToProps, mapActionsToProps)(QueryBody)