import React, {Component} from 'react'
import {connect} from 'react-redux'
import AuthComponent from "../../SharedComponents/AuthComponent";
import APIIndex from "./APIIndex"
import UpdatedLandingPageIndex from "../../LandingPage/UpdatedLandingPageIndex";

class APIAuthIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <AuthComponent
                    authComponent={<APIIndex />}
                    nonAuthComponent={<UpdatedLandingPageIndex/>}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(APIAuthIndex)