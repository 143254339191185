import React, {Component} from 'react'
import ModalApp from '../SharedComponents/Modal'
import GoogleSheetsGuide from "../Guides/GuidesText/GoogleSheetsGuide";

class GoogleSheetsErrorModal extends Component {
    render() {
        return (
            <ModalApp name="googleSheetsErrorModal"
                      header={<h1 style={{textAlign: 'center', color: "red"}} className="thinHeading">Error Connecting to Your Google Sheets<span role="img" aria-label="sad-emoji">🙁</span></h1>}
                      size="medium"
                      body={<GoogleSheetsGuide upload={true}/>}
            />
        )
    }
}

export default GoogleSheetsErrorModal