import Icons from "../Constants/Icons";
import React from 'react'
import APIQuickstart from "./GuidesText/APIQuickstart";
import ReadRows from "./GuidesText/Read/ReadRows";
import CreateRows from "./GuidesText/CreateRows";
import UpdateRows from "./GuidesText/UpdateRows";
import DeleteRows from "./GuidesText/DeleteRows";
import QueryBody from "../InfoPages/Query/FullTutorial/QueryBody";
import SaveForm from "../InfoPages/Tutorials/TutorialsText/SaveForm";
import LiveDashboard from "../InfoPages/Tutorials/TutorialsText/LiveDashboard";
import ReadSpreadsheets from "../InfoPages/Tutorials/TutorialsText/ReadSpreadsheets";
import ReadSubset from "./GuidesText/Read/ReadSubset";
import UpdateSubsetRows from "./GuidesText/UpdateSubsetRows";
import ColumnNamesValid from "./ColumnNamesValid";
import GoogleSheetsGuide from "./GuidesText/GoogleSheetsGuide"
import TeamsGuide from "../InfoPages/Tutorials/TutorialsText/TeamsGuide";
import APISecurity from "./GuidesText/APISecurity";
import Webhooks from "./GuidesText/Webhooks/Webhooks";
import SpreadsheetImport from "./GuidesText/SpreadsheetImport";
import SpreadsheetImportCode from "./SpreadsheetImportCode";
import DatabaseConnectionErrorBody from "../Database/DatabaseConnectionErrorBody";
import DatabaseAPIGuide from "./GuidesText/DatabaseAPIGuide";
import ConnectDomain from "./ConnectDomain";
import CachingLayerGuide from "./GuidesText/CachingLayerGuide";

const GuideDetails = [
    {
        icon: 'laptop',
        header: 'Use Spreadsheets as API',
        desc: 'Quick start guide on using spreadsheets as an API',
        title: 'get-started-with-spreadsheets-api',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <APIQuickstart/>
    },
    {
        icon: 'upload',
        header: 'Spreadsheet Importer',
        desc: 'Create a Spreadsheet Importer for your Website',
        title: 'spreadsheet-importer',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <SpreadsheetImport/>
    },
    {
        icon: 'code',
        header: 'Importer Code',
        desc: 'Learn more about the code for setting up the Importer',
        title: 'spreadsheet-importer-code',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <SpreadsheetImportCode/>
    },
    {
        icon: 'google drive',
        header: 'Guide to Google Sheets',
        desc: 'Learn about using Google Sheets and Common Problems',
        title: 'guide-to-google-sheets',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <GoogleSheetsGuide/>
    },
    {
        icon: 'columns',
        header: 'How Do Column Names Work',
        desc: 'Learn what makes your Column Names valid',
        title: 'column-names',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <ColumnNamesValid/>
    },
    {
        icon: Icons.readAction,
        header: 'Read All Rows',
        desc: 'Read everything in your file',
        title: 'read-all-rows',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <ReadRows readType="all"/>
    },
    {
        icon: Icons.readAction,
        header: 'Read Subset of Data',
        desc: 'Read only subset of rows with a specified criteria',
        title: 'read-subset-data',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <ReadSubset/>
    },
    {
        icon: Icons.createAction,
        header: 'Create New Row(s)',
        desc: 'Create one or more new rows in your file',
        title: 'create-new-rows',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <CreateRows/>
    },
    {
        icon: Icons.updateAction,
        header: 'Update Data in All Rows',
        desc: 'Update data in column(s) for all your rows',
        title: 'update-data-all-rows',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <UpdateRows updateType="all"/>
    },
    {
        icon: Icons.updateAction,
        header: 'Update Data in Subset of Row(s)',
        desc: 'Update data in column(s) for a rows with a specified criteria',
        title: 'update-data-subset-rows',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <UpdateSubsetRows/>
    },
    {
        icon: Icons.deleteAction,
        header: 'Delete Row(s)',
        desc: 'Delete rows in your file based on a specified criteria',
        title: 'delete-rows',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <DeleteRows/>
    },
    {
        icon: 'code',
        header: 'Write a Valid Query',
        desc: 'Learn how to write a valid query on API Spreadsheets. Compare and contrast with SQL.',
        title: 'write-a-valid-query',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <QueryBody/>
    },
    {
        icon: 'wordpress forms',
        header: 'Save Web Form Data to Spreadsheets',
        desc: 'Learn how to save web form data directly to spreadsheets',
        title: 'save-web-form-data-to-spreadsheets',
        menuType: 'tutorial',
        iconType: 'semantic',
        article: <SaveForm/>
    },
    {
        icon: 'dashboard',
        header: 'Create Live Dashboard with Google Sheets',
        desc: 'Learn how to create a live dashboard with Google Sheets',
        title: 'create-live-dashboard-with-google-sheets',
        menuType: 'tutorial',
        iconType: 'semantic',
        article: <LiveDashboard/>
    },
    {
        icon: 'code',
        header: 'Read a Spreadsheet with R & Python',
        desc: 'Learn the easiest way to read a spreadsheet with R & Python',
        title: 'read-spreadsheet-excel-file-R-Python',
        menuType: 'tutorial',
        iconType: 'semantic',
        article: <ReadSpreadsheets/>
    },
    {
        icon: 'users',
        header: 'Teams and Roles',
        desc: 'Everything to know about Teams and Roles',
        title: 'teams',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <TeamsGuide/>
    },
    {
        icon: 'lock',
        header: 'API & Data Security',
        desc: 'Everything to know about the security of your API and Data',
        title: 'api-data-security',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <APISecurity/>
    },
    {
        icon: 'fork',
        header: 'Webhooks',
        desc: 'Everything to know about webhooks',
        title: 'webhooks',
        menuType: 'quickStart',
        iconType: 'semantic',
        article: <Webhooks/>
    },
    {
        icon: "database",
        header: "Database Connection",
        desc: "Learn how to connect to your database",
        title: "database-connection",
        menuType: "quickStart",
        iconType: "semantic",
        article: <div>
            <p>This guide will walk you through what is needed to connect to your databases</p>
            <DatabaseConnectionErrorBody/>
            <hr/>
            <br/>
            <h2>Next steps</h2>
            <p>Now that you are connected to the database, you can make API requests to this database</p>
            <p>Read the <a href="/guides/database-api/">Database API guide</a> to learn how to do that</p>
        </div>
    },
    {
        icon: "database",
        header: "Database API",
        desc: "Learn how to use your database as an API",
        title: "database-api",
        menuType: "quickStart",
        iconType: "semantic",
        article: <DatabaseAPIGuide/>
    },
    {
        icon: "window maximize outline",
        header: "Connect Your Domain",
        desc: "Learn how to connect your own domain to use instead of apispreadsheets.com",
        title: "connect-your-domain",
        menuType: "quickStart",
        iconType: "semantic",
        article: <ConnectDomain/>
    },
    // {
    //     icon: "wifi",
    //     header: "Caching Layer",
    //     desc: "Learn how to create a cache layer to access your data super fast",
    //     title: "caching-layer",
    //     menuType: "quickStart",
    //     iconType: "semantic",
    //     article: <CachingLayerGuide/>
    // }
]

export default GuideDetails