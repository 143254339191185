import React, {Component} from 'react'
import EmailVerificationMessage from "../../SharedComponents/EmailVerificationMessage";
import FailedPaymentMessage from "../../SharedComponents/FailedPaymentMessage";
import UserMacroMessage from "./UserMacroMessage"

class BannerMessages extends Component {
    render() {
        return (
            <div>
                <EmailVerificationMessage/>
                <FailedPaymentMessage/>
                <UserMacroMessage />
            </div>
        )
    }
}

export default BannerMessages