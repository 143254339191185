import React, {Component} from 'react'
import {isMobile} from "react-device-detect";
import AutoReportingDesc from "./AutoReportingDesc";
import TeamsDesc from "./TeamsDesc";
import MultipleKeysDesc from "./MultipleKeysDesc";
import MonitoringAccessDesc from "./MonitoringAccessDesc";


class CollaborateTeamTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeFunction: "autoReporting"
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e, name){
        this.setState({activeFunction: name})
    }

    render() {
        const functionInfo = {
            "autoReporting": { desc: <AutoReportingDesc/>},
            "teams": {desc: <TeamsDesc/>},
            "multipleKeys": {desc:<MultipleKeysDesc/>},
            "monitoringAcess":{desc:<MonitoringAccessDesc/>},
        };
        const generalStyle = { color: "white", cursor: "pointer", padding: "15px 0 15px 15px", fontSize: "14px" }
        const selectedStyle = JSON.parse(JSON.stringify(generalStyle))
        selectedStyle['backgroundColor'] = 'whitesmoke'
        selectedStyle['color'] = 'black'

        const menuItemInfo = [
            {name: "autoReporting", displayName: "Auto Reporting"},
            {name: "teams", displayName: "Invite Teams"},
            {name:"multipleKeys",displayName: "Multiple Keys"},
            {name:"monitoringAcess", displayName: "Monitoring Access"},
        ];
        const menuItems = menuItemInfo.map((x, idx) =>
            <div style={this.state.activeFunction === x.name ? selectedStyle : generalStyle} key={idx} onClick={e => this.handleClick(e, x.name)}>
                {x.displayName}
            </div>);

        const cleanWidth = isMobile ? "31.25%" : "25%";
        const exampleWidth = isMobile ? "68.75%" : "75%";

        return (
            <div style={{display: "grid",
                boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
                gridTemplateColumns: cleanWidth + " " + exampleWidth, backgroundColor: "black"}}>
                <div style={{padding: "10px 0 10px 0"}}>
                    {menuItems}
                </div>
                <div style={{backgroundColor: "whitesmoke", padding: "12px 25px 25px 25px"}}>
                    {functionInfo[this.state.activeFunction].desc}
                </div>
            </div>
        )
    }
}

export default CollaborateTeamTable