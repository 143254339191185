import React, {Component} from 'react'

class ReadPython extends Component {
    render() {
        return (
            <div>
                <h2>Step 0: Install the API Spreadsheets Python Library</h2>
                <p>In the python environment on your computer, install the API Spreadsheets library using pip</p>
                <blockquote>
                    <code>$ pip install apispreadsheets</code>
                </blockquote>
                <br/>
                <h2>Step 1: Create an Account on API Spreadsheets</h2>
                <br/>
                <p>• Click on Sign Up in the Navbar</p>
                <img src="https://miro.medium.com/max/1277/1*OUjjkRu_Ei4ITqHYsiC3CA.png" height="100%" width="100%" alt="ReadSpreadsheetsPython2" />
                <br/><br/><br/>
                <p>• Sign up with your email and password</p>
                <img src="https://miro.medium.com/max/765/1*gjWBxs_gN8O7J5-krhY6Dg.png" height="65%" width="65%" alt="ReadSpreadsheetsPython3" />
                <br/><br/><br/><br/>
                <h2>Step 2: Upload your spreadsheet</h2>
                <p>Click on <b>Upload or Drop Files</b> and select your spreadsheet from your computer</p>
                <br/>
                <img src="https://miro.medium.com/max/1251/0*vpJxbF0vulFCGrF9.png" height="100%" width="100%" alt='ReadSpreadsheetsPython4' />
                <br/><br/><br/><br/>
                <h2>Step 3: Copy the Python Code from the dashboard</h2>
                <p>Copy and run the python code in your script</p>
                <br/>
                <img src="https://miro.medium.com/max/1050/1*JDzBXpAD626KuQHZu8HHDA.png" height="100%" width="100%" alt='ReadSpreadsheetsPython5' />
                <br/><br/><br/>
                <p>This will read the file in an array that is the length==rows of the file.</p>
                <br/>
                <p>Each item in the array will be a dictionary with the column names as the keys.</p>
                <br/>
                <p>You can read the file in a different format be changing the <b>Choose Data Format</b>.</p>
                <br/><br/><br/>
                <h2>Step 4: Enjoy!</h2>
                <br/>
                <img src="https://miro.medium.com/max/5000/0*rF1z7JW-JPDiyoeW" height="100%" width="100%" alt='ReadSpreadsheetsPython5' />
                <br/><br/><br/>
                <p>Relax with all the time you have saved or get working on your models and data cleaning!</p>
            </div>
        )
    }
}

export default ReadPython