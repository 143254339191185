import React, {Component} from 'react'
import images from '../../Constants/Images'
import { Image } from 'semantic-ui-react'
import AuthComponent from "../AuthComponent";
import UpdatePaymentForm from "./UpdatePaymentForm";
import UpdatePaymentLogin from "./UpdatePaymentLogin";

class UpdatePaymentIndex extends Component {
    componentDidMount(){
        document.title = "Update Payment"
    }

    render() {
        return (
            <div>
                <div className="row" style={{backgroundColor: 'whitesmoke', minHeight: '100vh'}}>
                    <div className="col-sm-3" />
                    <div className="col-sm-6">
                        <br/><br/><br/>
                        <div style={{marginLeft: '1rem'}}>
                            <Image style={{marginTop: '5px'}} src={images.logoNav} href="/" size="medium"/>
                        </div>
                            <AuthComponent
                                authComponent={<UpdatePaymentForm/>}
                                nonAuthComponent={<UpdatePaymentLogin/>}
                            />
                        <br/><br/><br/><br/><br/><br/>
                    </div>
                    <div className="col-sm-3" />
                </div>
            </div>
        )
    }
}

export default UpdatePaymentIndex