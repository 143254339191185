import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import getURLParam from "../Constants/getURLParam";
import {modifyAllQueries} from "../Actions/ModifyAllQueries";
import API_Root from "../Constants/API_Root";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import {toggleModal} from "../Actions/ToggleModal";
import {toggleToast} from "../Actions/ToggleToast";
import { push } from 'react-router-redux';
import {modifyEditQueryInfo} from "../Actions/ModifyEditQueryInfo";
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

// import {modifyUnsavedNewQuestions} from "../../Actions/ModifyUnsavedNewQuestions";
// import {newUnsavedQuestionObject} from "./newUnsavedQuestionObject";
// import {toggleModal} from "../../Actions/ToggleModal";
// import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
// import pricing from "../../Constants/Pricing";
// import { push } from "react-router-redux"
// import {getDatabasePKFromURL} from "../getDatabasePKFromURL";

class AddDBAPIButton extends Component {
    constructor(props){
        super(props);

        this.state = {
            creatingQuery: false
        }
    }

    addNewAPI = (e) => {
        this.setState({ creatingQuery: true })
        const error = () => this.props.toggleToast({show: true, message: "There was an error. Please try again or contact support if this persists", type: "error"})

        fetch(API_Root + "api/save-new-api-db-query/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                dbHash: getURLParam(window.location.href)
            })
        }).then(res => {
            if (res.status === 201){
                res.json().then(data => {
                    this.setState({ creatingQuery: false }, () =>
                    {
                        const updateAllQueries = [...this.props.allQueries, data.newQuestionInfo]
                        this.props.modifyAllQueries(updateAllQueries)
                        this.props.modifyEditQueryInfo({queryPK: data.queryPK, isNew: true, isInRedux: true, dbHash: getURLParam(window.location.href), idx: this.props.allQueries.length})
                        this.props.modifyModalInfo({queryPK: data.queryPK, isNew: true, isInRedux: true, dbHash: getURLParam(window.location.href), idx: this.props.allQueries.length})
                        this.props.navigateTo("/database-edit-api/")
                    })
                }).catch(err => error())
            } else {
                error()
            }
        }).catch(err => error())

    }

    render() {
        return (
            <Button
                    icon="plus"
                    content="Add a New API"
                    style={gunaldiStyles.btnBlue}
                    loading={this.state.creatingQuery}
                    onClick={this.addNewAPI} />
        )
    }
}

const mapStateToProps = (state) => ({
    allQueries: state.mainState.allQueries
})

const mapActionsToProps = {
    modifyModalInfo: modifyModalInfo,
    toggleModal: toggleModal,
    modifyAllQueries: modifyAllQueries,
    toggleToast: toggleToast,
    navigateTo: push,
    modifyEditQueryInfo: modifyEditQueryInfo
}

export default connect(mapStateToProps, mapActionsToProps)(AddDBAPIButton)