import React, {Component} from 'react'
import ReactJson from 'react-json-view'
import { Dropdown } from 'semantic-ui-react'

class DocsPostBodyReq extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataFormat: 'row'
        }

        this.handleFormatChange = this.handleFormatChange.bind(this);
    }

    handleFormatChange(event, {value}){
        this.setState({
            dataFormat: value
        })
    }

    render() {
        const responseBody =
            {
                row: {
                    "data": [
                        {
                            "id": 4,
                            "name": "AT&T",
                            "paid": "Yes",
                        },
                        {
                            "id": 5,
                            "name": "Apple",
                            "paid": "No",
                        }
                    ]
                },
                columnValue: {
                    "data": {
                            "id": 4,
                            "name": "AT&T",
                            "paid": "Yes"
                        }
                },
                column: {
                    "data": {
                        "id": [4, 5],
                        "name": ["AT&T", "Apple"],
                        "paid": ["Yes", "No"]
                    }
                }
            }

        const dataFormatOptions = [
            {key: 1, text: 'row', value: 'row'},
            {key: 2, text: 'column', value: 'column'},
            {key: 3, text: 'columnValue', value: 'columnValue'},

        ];

        const dataFormat = this.state.dataFormat;

        const boxClassName = "getResponseBox";

        return (
            <div className={boxClassName}>
                <div className="getResponseHeading">
                    <span style={{float: 'left'}}><h6 className="thinHeading">Request Body</h6></span>
                    <span style={{float: 'right'}}> {' '}
                        <Dropdown
                            placeholder="Select Data Format"
                            name="dataFormat"
                            value={dataFormat}
                            options={dataFormatOptions}
                            onChange={this.handleFormatChange}
                            inline
                        />
                    </span>
                </div>
                <ReactJson src={responseBody[dataFormat]} name={null} theme="ocean" displayObjectSize={false} displayDataTypes={false}/>
            </div>
        )
    }
}

export default DocsPostBodyReq