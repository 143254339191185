import React, {Component} from 'react'
import {connect} from 'react-redux'
import {mapPkAndPkid} from "../../Constants/mapPkAndPkid";
import ReactJson from 'react-json-view'
import RegenerateKeysButton from './RegenerateKeysButton'
import BannerAlert from "../../SharedComponents/BannerAlert";

class KeysModalBody extends Component {
    render() {
        const pk = mapPkAndPkid(this.props.filePK, "id", this.props.editID)
        const keys = {
            accessKey: this.props.fileInformation[this.props.editID]['accessKey'],
            secretKey: this.props.fileInformation[this.props.editID]['secretKey']
        }

        const isPrivate = this.props.fileInformation[this.props.editID].private;

        let canTakeAction = true;
        if (typeof this.props.modalInfo === "object" && this.props.modalInfo !== null){
            if ('canTakeAction' in this.props.modalInfo){
                canTakeAction = this.props.modalInfo.canTakeAction
            }
        }

        return (
            <div>
                {
                    !isPrivate ? <div style={{marginBottom: "16px"}}><BannerAlert type="warning"
                                              header='Your API is NOT private'
                                              content={<span>You need to make your <b>API Private?</b> to use the API Keys</span>}
                                              icon="bx bx-lock-open-alt"
                    /></div> : null
                }
                <ReactJson src={keys} name={null} theme="bright:inverted" displayObjectSize={false}/>
                <div style={{marginTop: "16px"}}>
                    <RegenerateKeysButton canTakeAction={canTakeAction} isCustom={'custom' in this.props} pk={pk} pkID={this.props.editID} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation,
    modalInfo: state.mainState.modalInfo

})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(KeysModalBody)