import React, {Component} from 'react'
import {connect} from 'react-redux'
import UpgradeButton from "../../InfoPages/Profile/UpgradeButton"
import { Icon, Checkbox, Button, Message } from 'semantic-ui-react'
import CreateTeamModalBody from "../../Teams/CreateTeam/CreateTeamModalBody"
import TeamSegmentRow from "../../Teams/TeamSegmentRow"
import API_Root from "../../Constants/API_Root";
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import {modifyTeamInfo} from "../../Actions/ModifyTeamInfo";
import UpgradeToTeam from "../UpgradeToTeam"

class AddFileToTeam extends Component {
    constructor(props) {
        super(props);

        const teamIndicesUserCanAddTo = this.getTeamIndices()

        this.state = {
            teamSelectedToAdd: teamIndicesUserCanAddTo.map(x => false),
            addingFiles: false,
            errorMsg: null
        }
    }

    areAnyTeamsSelected = () => {return this.state.teamSelectedToAdd.indexOf(true) !== -1}

    dismissMessage = () => this.setState({ errorMsg: null })

    getTeamIndices = () => {
        const userEmail = localStorage.getItem("email")
        let teamIndicesUserCanAddTo = [];

        for (let i=0; i<this.props.teamInfo.length; i++){
            if (["admin", "manager"].includes(this.props.teamInfo[i].all_users[userEmail].role)){
                if (!this.props.allTeamInfoPKs.includes(this.props.teamInfo[i].id)){
                    teamIndicesUserCanAddTo.push(i)
                }
            }
        }

        return teamIndicesUserCanAddTo
    }

    toggleSelect = (e, idx) => {
        const copiedTeamSelectedToAdd = JSON.parse(JSON.stringify(this.state.teamSelectedToAdd))
        const currState = copiedTeamSelectedToAdd[idx]

        copiedTeamSelectedToAdd[idx] = !currState

        this.setState({ teamSelectedToAdd: copiedTeamSelectedToAdd })
    }

    addFileToTeams = () => {
        this.setState({ addingFiles: true })

        const errorMsgMessage = <Message error header="There was an error adding this file to the teams you selected" content="Try again! Contact our support if the error persists" onDismiss={this.dismissMessage}/>
        const hitError = () => this.setState({ addingFiles: false, errorMsg: errorMsgMessage })

        const teamsPKToSend = []
        const teamIndices = this.getTeamIndices();

        for (let i=0; i<teamIndices.length; i++){
            if (this.state.teamSelectedToAdd[i]){
                teamsPKToSend.push(this.props.teamInfo[teamIndices[i]].id)
            }
        }

        fetch(API_Root + "api/link-api-file-with-team/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                filePK: this.props.filePK,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                teamsPK: teamsPKToSend
            })
        }).then((res) => {
            if (res.status === 201){
                res.json().then((data) =>
                {
                    // Update team info, and file info and then reset the states
                    const fileInfo = JSON.parse(JSON.stringify(this.props.fileInformation))
                    const fileInfoPK = fileInfo[this.props.editID]

                    const teamsPKList = fileInfoPK.teams_pk_list;
                    const allTeamPKs = this.props.teamInfo.map(x => x.id)

                    const currTeamInfo = JSON.parse(JSON.stringify(this.props.teamInfo))

                    for (let i=0; i<teamsPKToSend.length; i++){
                        if (!teamsPKList.includes(teamsPKToSend[i])){
                            teamsPKList.push(teamsPKToSend[i])
                        }

                        currTeamInfo[allTeamPKs.indexOf(teamsPKToSend[i])] = data['updatedTeamInfo'][i]
                    }

                    fileInfoPK.teams_pk_list = teamsPKList
                    fileInfo[this.props.editID] = fileInfoPK

                    this.props.modifyFileInformation(fileInfo)
                    this.props.modifyTeamInfo(currTeamInfo)

                    const teamIndicesUserCanAddTo = this.getTeamIndices()

                    this.setState({ addingFiles: false, errorMsg: null, teamIndices: teamIndicesUserCanAddTo,
                        teamSelectedToAdd: teamIndicesUserCanAddTo.map(x => false)})

                }).catch(err => hitError())
            } else {
                hitError()
            }
        }).catch(err => hitError())
    }

    render() {
        const teamIndices = this.getTeamIndices();
        if (teamIndices.length > 0){
            return (
                <div style={{border: "1px solid #F0F0F0", borderRadius: "4px", padding: "16px", marginBottom: "16px"}}>
                    <div style={{fontFamily: "Lato", fontWeight: "700", fontSize: "14px", color: "#161E16", marginBottom: "16px"}}> Add this file to any of your existing teams</div>
                        {
                            teamIndices.map((x, idx) =>
                                <div key={idx.toString()} style={{paddingBottom: "10px", paddingTop: "10px", borderBottom: "1px solid #F0F0F0"}}>
                                    <div className="row">
                                        <div className="col-sm-1">
                                            <div style={{paddingTop: "5px"}}>
                                                <Checkbox
                                                    style={{verticalAlign: "middle"}}
                                                    checked={this.state.teamSelectedToAdd[idx]}
                                                    onChange={e => this.toggleSelect(e, idx)} />
                                            </div>
                                        </div>
                                        {/*<div style={this.state.teamSelectedToAdd[idx] ? selectBgStyle : selectStyle}>*/}
                                        {/*</div>*/}
                                        <div className="col-sm-11">
                                        {/*<div style={this.state.teamSelectedToAdd[idx] ? teamInfoBgStyle : teamInfoStyle}>*/}
                                            <TeamSegmentRow fileHash={this.props.fileHash} info={this.props.teamInfo[x]} idx={x} teamPage={false} />
                                        </div>
                                    </div>
                                </div>)
                        }
                        <div style={{marginTop: "16px"}}>
                            <Button
                                    color="blue"
                                    outline
                                    icon="plus"
                                    style={{backgroundColor: "#5499F8", color: "white"}}
                                    content="Add File to Selected Teams"
                                    disabled={!this.areAnyTeamsSelected()}
                                    onClick={this.addFileToTeams}
                                    loading={this.state.addingFiles}/>
                            {this.state.errorMsg}
                        </div>
                </div>
            )
        } else {
            if (this.props.tier === "team" || this.props.tier === "business" || this.props.tier === "enterprise"){
                if (this.props.isNoTeamForFile){
                    return (
                        <div>
                            <div style={{fontFamily: "Lato", fontWeight: "700", fontSize: "16px", color: "#161E16"}}>Create Team to Share This File</div>
                            <CreateTeamModalBody filePK={this.props.filePK} />
                        </div>
                    )
                } else {
                    return null
                }
            } else {
                if (this.props.isNoTeamForFile){
                    return (
                        <div>
                            <UpgradeToTeam higherTier="teams" header="You need to have a Team, Business or Enterprise account to create a Team"/>
                        </div>
                    )
                } else {
                    return null
                }
            }
        }
    }
}

const mapStateToProps = (state) => ({
    teamInfo: state.mainState.teamInfo,
    fileInformation: state.mainState.fileInformation,
    tier: state.mainState.tier,
    editID: state.mainState.editID
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation,
    modifyTeamInfo: modifyTeamInfo
}

export default connect(mapStateToProps, mapActionsToProps)(AddFileToTeam)