import React, {Component} from 'react'
import NewDatabaseConnection from "./NewDatabaseConnection";
import { connect } from 'react-redux'
import Loader from 'react-loader-spinner'
import API_Root from "../Constants/API_Root";
import {modifyDBInfo} from "../Actions/ModifyDBInfo";
import ViewDatabaseButton from "./ViewDatabaseButton"
import { Icon, Message, Button } from 'semantic-ui-react'
import {toggleModal} from "../Actions/ToggleModal";
import {modifyPaymentModalOrigin} from "../Actions/ModifyPaymentModalOrigin";
import NoDatabaseCreated from "./NoDatabaseCreated";
import DatabasePageHeader from "./DatabasePageHeader"
import BannerAlert from "../SharedComponents/BannerAlert";
import EmailVerificationMessage from "../SharedComponents/EmailVerificationMessage"
import FailedPaymentMessage from "../SharedComponents/FailedPaymentMessage"

class DatabaseIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            isFetching: true,
            message: <Message info
                              icon="question circle outline"
                              header="Need help connecting to a database"
                              content={<a href="/api-docs/?docsMenuItem=database-connect" target="_blank" rel="noopener noreferrer">Read our guide on database connections and common problems</a>}
                              onDismiss={this.dismissMessage}
            />
        }
    }

    dismissMessage = () => this.setState({ message: null })

    componentDidMount(){
        const current = new Date();

        if (localStorage.getItem("email") !== null && localStorage.getItem("token") !== null){
            fetch(API_Root + 'api/get-api-db-user-info/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    utcOffset: current.getTimezoneOffset()
                })
            }).then((res) =>
            {
                if (res.status === 200){
                    res.json().then((data) =>
                        {
                            this.props.modifyDBInfo(data.dbInfo);
                            this.setState({ isFetching: false })
                        }
                    ).catch((err) => this.setState({ isFetching: false }))
                } else {
                    this.setState({ isFetching: false })
                }
            }).catch(err => this.setState({ isFetching: false }))
        }
        else{
            this.setState({ isFetching: false })
        }
    }

    openPaymentModal = (e) => {
        this.props.modifyPaymentModalOrigin("database");
        this.props.toggleModal("paymentModal");
    }

    render() {
        const indexStyle = {margin: '32px 60px 150px 60px'};
        const pageStyle = {minHeight: '100vh', paddingBottom: "240px"}
        let body = null;

        if (this.props.tier !== "business" && this.props.tier !== "enterprise"){
            body = <div>
                <DatabasePageHeader noCreate/>
                <div style={{indexStyle}}>
                    <NoDatabaseCreated isUpgrade />
                </div>
            </div>
        } else{
            if (this.state.isFetching){
                indexStyle['padding'] = '100px 0'
                indexStyle['textAlign'] = 'center'

                body = <div>
                    <DatabasePageHeader/>
                    <div style={indexStyle}>
                        <Loader
                            type="TailSpin"
                            color="black"
                            height="50"
                            width="50"
                        />
                        <h4 className="thinHeading" style={{color: 'black'}}>Getting your Information...</h4>
                    </div>
                </div>
            } else{
                if (this.props.dbInfo.length > 0){

                    let message = null;
                    let header = <DatabasePageHeader/>

                    if (this.props.tier !== "business" && this.props.tier !== "enterprise"){
                        indexStyle['pointerEvents'] = 'none'
                        indexStyle['opacity'] = '0.2'

                        message = <BannerAlert
                            type="error"
                            header="You need a Business or higher account to access databases"
                            icon="bx bx-error-alt"
                            content={null}
                            action={<Button icon="credit card outline" content="Upgrade" style={{backgroundColor: "#FFFFFF", border: "1px solid #6FBB98", color: "#6FBB98"}} onClick={this.openPaymentModal} />}
                        />

                        header = <DatabasePageHeader noCreate/>
                    }

                    const tableHeaderStyle = {fontFamily: 'Lato', fontWeight: '700', fontSize: '14px', color: '#161E16'}

                    body = <div>
                        {header}
                        {message}
                        <div style={indexStyle}>
                            <div className="databaseSegmentRow" style={{borderBottom: "1px solid #F0F0F0"}}>
                                <div style={tableHeaderStyle}>Database Name</div>
                                <div style={tableHeaderStyle}>Date Connected</div>
                                <div style={tableHeaderStyle}>Type</div>
                                <div style={tableHeaderStyle}>Description</div>
                                <div> </div>
                                <div> </div>
                            </div>
                            <div>
                                {this.props.dbInfo.map((x, idx) => <ViewDatabaseButton info={x} key={idx} />)}
                            </div>
                        </div>
                    </div>
                } else {
                    body = <div>
                        <DatabasePageHeader/>
                        <div style={indexStyle}>
                            <NoDatabaseCreated/>
                        </div>
                    </div>
                }
            }
        }

        return (
            <div style={pageStyle}>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    dbInfo: state.mainState.dbInfo,
    tier: state.mainState.tier
})

const mapActionsToProps = {
    modifyDBInfo: modifyDBInfo,
    toggleModal: toggleModal,
    modifyPaymentModalOrigin: modifyPaymentModalOrigin
}

export default connect(mapStateToProps, mapActionsToProps)(DatabaseIndex)