import React, {Component} from 'react'
import {Button, Image} from "semantic-ui-react";
import AppSumoAPILogo from "./Authentication/UserPages/AppSumoAPILogo.svg";
import duplicateProfile from "./duplicateProfile.svg"
import getURLParam from "./Constants/getURLParam";

class AppsumoDuplicateAccount extends Component {
    render() {
        // if (window.location.href.indexOf("profile?/deal=appsumo"){
        return (
            <div style={{minHeight:'100vh'}}>
                <div style={{margin:'40px'}}>
                <div className="segment-margin segment-raised" style={{backgroundColor:'whitesmoke',textAlign:'center',margin:'0 auto',width:'50%'}}>
                    {/*<div style={{textAlign:'center',padding:'10px',borderRadius:'5px', width:'50%',marginTop:'10%'}}>*/}
                    <div style={{marginLeft:'1rem',paddingBottom:'30px'}}>
                        <Image style={{margin:'5px'}} src={AppSumoAPILogo} size="large"/>
                    </div>
                    <img src={duplicateProfile} style={{width:'50%',padding:'10px'}}/>
                    <h3 className="thinHeading"> You have already registered for an account with this email <b>{getURLParam(window.location.href)}</b> </h3>
                    <p style={{padding:'15px'}}> Please email us at <a style={{textDecoration:'none'}} href={'mailto:admin@lovespreadsheets.com'}> admin@lovespreadsheets</a> if you have any questions </p>
                        </div>
            </div>
            </div>
        )
    }
}

export default AppsumoDuplicateAccount