import React, {Component} from 'react'
import ModalApp from "../../SharedComponents/Modal"
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'
import EditS3ModalBody from "./EditS3ModalBody"

class EditS3Modal extends Component {
    render() {
        return (
            <ModalApp name="editS3Modal"
                      header={<h4><Icon name="edit alternate outline" /> Edit AWS S3 Account</h4>}
                      body={<EditS3ModalBody/>}
                      size="medium"
            />
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(EditS3Modal)