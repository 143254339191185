import React, { Component } from 'react';
import OverallSettings from './OverallSettings';
import InputFields from './InputFields';
import OutputDisplay from './OutputDisplay';
import CalculatorDisplayURL from "./CalculatorDisplayURL";

class CalculatorTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.data.title || '',
            subtitle: props.data.subtitle || '',
            backgroundColor: props.data.backgroundColor || '#ffffff',
            labelColor: props.data.labelColor || '#000000',
            saveInSpreadsheet: props.data.saveInSpreadsheet || false,
            inputFields: props.data.inputFields || [],
            outputFields: props.data.outputFields || []
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.props.onCalculatorChange(this.props.index, this.state);
        }
    }

    handleOverallSettingsChange = (field, value) => {
        this.setState({ [field]: value });
    };

    handleInputFieldsChange = (inputFields) => {
        this.setState({ inputFields });
    };

    handleOutputFieldsChange = (outputFields) => {
        this.setState({ outputFields });
    };

    render() {
        return (
            <div className="calculator-tab">
                <CalculatorDisplayURL
                    displayFileHash={this.props.displayFileHash}
                />
                <div style={{ paddingTop: '24px' }}>
                    <OverallSettings
                        settings={this.state}
                        onSettingsChange={this.handleOverallSettingsChange}
                    />
                </div>
                <div style={{ paddingTop: '24px' }}>
                    <InputFields
                        inputFields={this.state.inputFields}
                        sheetNames={this.props.sheetNames}
                        onInputFieldsChange={this.handleInputFieldsChange}
                    />
                </div>
                <div style={{ paddingTop: '24px' }}>
                    <OutputDisplay
                        outputFields={this.state.outputFields}
                        sheetNames={this.props.sheetNames}
                        onOutputFieldsChange={this.handleOutputFieldsChange}
                    />
                </div>
            </div>
        );
    }
}

export default CalculatorTab;
