import React, {Component} from 'react'

class MenuButton extends Component {
    toggleQuickstart = (e) => {
        this.props.onClick(e, this.props.name)
    }
    render() {
        let id = ""
        if ("menuItem" in this.props){
            if (this.props.menuItem === this.props.name){
                id = "menuButtonSelected"
            }
        }
        return (
            <div className="menuButton"
                 onClick={this.toggleQuickstart}
                 id={id}
            >
                <div>
                    <i className={'bx bx-' + this.props.icon} style={{fontSize: "20px", verticalAlign: "middle"}}/>
                    <span style={{fontSize: "16px", fontFamily: "Lato", fontWeight: "400", paddingLeft: "12px"}}>{this.props.label}</span>
                </div>
                <div>

                </div>
                <div>
                    <i className="bx bx-chevron-right" style={{fontSize: "20px", verticalAlign: "middle", textAlign: "right", paddingTop: "3px"}} />
                </div>
            </div>
        )
    }
}

export default MenuButton