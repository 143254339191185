import React, {Component} from 'react'
import { Popup, Button } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {toggleToast} from "../../Actions/ToggleToast";
import {connect} from "react-redux";

class RemoveDropboxAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupOpen: false,
            deleting: false
        }
    }

    togglePopup = (e, toggle) => {
        this.setState({
            popupOpen: toggle
        })
    }

    removeAccount = () => {
        const errorFetch = () =>
        {
            this.setState({
                popupOpen: false,
                deleting: false
            }, () => {
                this.props.toggleToast({show: true, message: "There was an error removing your account. Please try again or contact our support if this persists", type: "error"})
            })
        }

        fetch(API_Root + 'api/remove-dropbox-account-from-api/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                accountID: this.props.accountID
            })
        }).then(res => {
            if (res.status === 200){
                this.setState({
                    popupOpen: false,
                    deleting: false
                }, () => {
                    this.props.removeAccountInfo(this.props.email)
                })
            }
            else{
                errorFetch()
            }
        }).catch(err => errorFetch());
    }

    render() {
        return (
            <Popup
                trigger={
                    <Button
                        style={!('list' in this.props) ? {float: "right"} : null}
                        color="red"
                        loading={this.state.deleting}
                        content="Remove Account from API Spreadsheets"
                        icon="sign-out"
                        onClick={(e) => this.togglePopup(e, true)}/>
                }
                on="click"
                position="bottom"
                // onOpen={(e) => this.togglePopup(e, true)}
                onClose={(e) => this.togglePopup(e, false)}
                open={this.state.popupOpen}>
                <div style={{padding: '10px'}}>
                    <h4 style={{color: 'red'}}>
                        Are you sure you want to remove this account? ALL your APIs and Importers dependent on this account will <u>STOP</u> working.
                    </h4>
                    <div className="row">
                        <div className="col-sm-6">
                            <Button color="green" content="No" onClick={(e) => this.togglePopup(e, false)} fluid />
                        </div>
                        <div className="col-sm-6">
                            <Button color="red" loading={this.state.deleting} content="Yes" onClick={this.removeAccount} fluid />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(RemoveDropboxAccount)