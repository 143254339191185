import React, {Component} from 'react'
import FeatureTable from "./FeatureTable";
import { Icon } from 'semantic-ui-react';

class CRUD extends Component {
    render() {
        const cell1Read = '{"data":['
        const cell2Read = ''
        const cell3Read = '{"name": "Queen Liz",';
        const cell4Read = '"age": "93"},'
        const cell5Read = '{"name": "Don Draper",';
        const cell6Read = '"age": "90"}]}';

        return (
            <div id="crudModalGrid">
                <div id="crudModalGridLeftColumn">
                    <div id="crudModalCreateDiv">
                        <span className="featureModalHeader" style={{textAlign: 'left'}}>CREATE</span>
                        <FeatureTable
                            headers={["Name", "Age"]}
                            data={[["Queen Liz", "93"], [{value: "Don Draper", style: {backgroundColor: 'green', color: 'white'}}, {value: "90", style: {backgroundColor: 'green', color: 'white'}}]]}
                        />
                    </div>
                    <div id="crudModalDeleteDiv">
                        <span className="featureModalHeader" style={{textAlign: 'left'}}>DELETE</span>
                        <FeatureTable
                            headers={["Name", "Age"]}
                            data={[["Queen Liz", "93"], [{value: "Don Draper", style: {backgroundColor: '#e06666ff', color: 'white'}}, {value: "103", style: {backgroundColor: '#e06666ff', color: 'white'}}]]}
                        />
                    </div>
                </div>
                <div id="crudModalGridMiddleColumn">
                    <div id="crudCenter">
                        <span className="crudCenterIcon"><Icon name="arrow right"/></span>
                        <div id="crudCenterTextAndArrows">
                            <div className="crudCenterSideArrows"><span className="crudCenterSideArrowsIcon crudCenterIcon"><Icon name="arrow up"/></span></div>
                            <div className="crudCenterText"><h1>Execute CRUD Actions</h1></div>
                            <div className="crudCenterSideArrows"><span className="crudCenterSideArrowsIcon crudCenterIcon"><Icon name="arrow down"/></span></div>
                        </div>
                        <span className="crudCenterIcon"><Icon name="arrow left"/></span>
                    </div>
                </div>
                <div id="crudModalGridRightColumn">
                    <div id="crudModalReadDiv">
                        <span className="featureModalHeader" style={{textAlign: 'right'}}>READ</span>
                        <table className="featureModalReadJSONTable">
                            <tr>
                                <td className="featureModalReadJSONTableTopRowCell featureModalReadJSONTableLeftCell">
                                    {cell1Read}
                                </td>
                                <td className="featureModalReadJSONTableTopRowCell featureModalReadJSONTableRightCell">
                                    {cell2Read}
                                </td>
                            </tr>
                            <tr>
                                <td className="featureModalReadJSONTableLeftCell">
                                    {cell3Read}
                                </td>
                                <td className="featureModalReadJSONTableRightCell">
                                    {cell4Read}
                                </td>
                            </tr>
                            <tr style={{paddingBottom: '15px'}}>
                                <td className="featureModalReadJSONTableBottomRowCell featureModalReadJSONTableLeftCell">
                                    {cell5Read}
                                </td>
                                <td className="featureModalReadJSONTableBottomRowCell featureModalReadJSONTableRightCell">
                                    {cell6Read}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div id="crudModalUpdateDiv">
                        <span className="featureModalHeader" style={{textAlign: 'right'}}>UPDATE</span>
                        <FeatureTable
                            headers={["Name", "Age"]}
                            data={[["Queen Liz", "93"], ["Don Draper", {value: "103", style: {backgroundColor: 'green', color: 'white'}}]]}
                            extraClass='rightTable'
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default CRUD