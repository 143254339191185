import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Input, Button, Icon, Popup } from 'semantic-ui-react'
import AceEditor from 'react-ace';
import 'brace/mode/sql';
import 'brace/theme/monokai';
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
import {toggleModal} from "../../Actions/ToggleModal";
import API_Root from "../../Constants/API_Root";
import TestQueryTable from "./TestQueryTable";
import SaveNewQuestion from "./SaveNewQuestion"
import DeleteEditAPI from "./DeleteEditAPI";
import isObjectEmpty from "../../Constants/isObjectEmpty";
import PageHeader from "../../SharedComponents/PageHeader";
import BackHeaderIcon from "../../SharedComponents/BackHeaderIcon";
import PageHeaderHelp from "../../SharedComponents/PageHeaderHelp";
import PageHeaderButton from "../../SharedComponents/PageHeaderButton";
import DisplayHeader from "../../FileDnD/FilesID/DisplayHeader";

class EditAPIDisplay extends Component {
    constructor(props) {
        super(props);

        const nameAndQuery = this.getQueryInfo()

        this.state = {
            testingSQLQuery: false,
            testTableValues: [],
            testTableColumns: [],
            testError: false,
            testErrorMessage: {},
            estRows: 0,
            queryPK: this.getQueryPK(),
            name: nameAndQuery.name,
            query: nameAndQuery.query
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.changeSQLQuery = this.changeSQLQuery.bind(this);
        this.viewTablesAndColumnInfo = this.viewTablesAndColumnInfo.bind(this);
        this.testSQLQuery = this.testSQLQuery.bind(this);
    }

    componentDidMount(){
        if (this.state.queryPK === -1){
            this.goToDatabase.click()
        }
    }

    getQueryPK = () => {
        let queryPK = -1;

        // if (this.props.modalInfo !== null){
        //     if (this.props.modalInfo.constructor === Object){
        //         if ("queryPK" in this.props.modalInfo){
        //             queryPK = this.props.modalInfo.queryPK
        //         }
        //     }
        // }

        if (this.props.editQueryInfo !== null){
            if (this.props.editQueryInfo.constructor === Object){
                if ("queryPK" in this.props.editQueryInfo){
                    queryPK = this.props.editQueryInfo.queryPK
                }
            }
        }

        return queryPK
    }

    testSQLQuery(e){
        this.setState({ testingSQLQuery: true })

        const errorFetch = (errorMessage={header: "There was an error testing your query", content: "Please try again or contact support@apispreadsheets.com if this error persists"}) => this.setState({ testingSQLQuery: false, testTableValues: [], testTableColumns: [], testError: true, testErrorMessage: errorMessage })
        //    send query stripped
        fetch(API_Root + "api/test-api-db-query/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                // dbHash: this.props.modalInfo.dbHash,
                dbHash: this.props.editQueryInfo.dbHash,
                query: this.state.query
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.setState({ testingSQLQuery: false, testTableValues: data.testRecords, testTableColumns: data.testColumns, testError: false})
                }).catch(err => errorFetch())
            }
            else if (res.status === 415){
                errorFetch({ header: "You do not have the right permissions to access this database", content: "If you think this is an error, please contact our support at support@apispreadsheets.com"})
            }
            else if (res.status === 404){
                res.json().then((data) => {
                    errorFetch(data.errorMessage)
                }).catch(err => errorFetch())
            } else{
                errorFetch()
            }
        }).catch(err => errorFetch())
    }

    handleInputChange(e){
        this.setState({ [e.target.name]: e.target.value })
    }

    changeSQLQuery(sqlCode){
        this.setState({ query: sqlCode })
    }

    viewTablesAndColumnInfo(e){
        this.props.modifyModalInfo({'tablesAndColumnInfo': this.props.tablesAndColumnInfo})
        this.props.toggleModal("dbTablesAndColumnInfoModal")
    }

    updateState = (key, value) => {
        this.setState({ [key]: value })
    }

    openModal = (e, modalName) => {
        this.props.toggleModal(modalName)
    }

    getQueryIdx = () => {
        let queryIdx = -1;

        if (this.props.editQueryInfo !== null){
            if (this.props.editQueryInfo.constructor === Object){
                if ("idx" in this.props.editQueryInfo){
                    queryIdx = this.props.editQueryInfo.idx
                }
            }
        }

        return queryIdx
    }

    getQueryInfo = () => {
        let queryIdx = this.getQueryIdx()

        if (queryIdx !== -1){
            return this.props.allQueries[queryIdx]
        } else {
            return {name: "", query: ""}
        }

    }


    render() {
        const boxStyle = {
            borderRadius: '5px',
            padding: '3%',
            boxShadow: '0 1px 4px rgba(0,0,0,0.19), 0 1px 1px rgba(0,0,0,0.23)',
            marginTop: '25px',
            backgroundColor: "#E6E6E6"
        }

        const name = this.state.name;
        const query = this.state.query;

        const queryInfo = this.getQueryInfo()

        let dbHashURL;
        try {
            dbHashURL = "/" + this.props.editQueryInfo.dbHash
        } catch (e){
            dbHashURL = ""
        }

        let allTablesAndColumns = null;

        if (this.props.tablesAndColumnInfo !== null){
            if (!isObjectEmpty(this.props.tablesAndColumnInfo)){
                allTablesAndColumns = <span>| <span className="linkStyle" onClick={this.viewTablesAndColumnInfo}><Icon name="columns"/> View DB Tables & Columns </span></span>
            }
        }

        return (
            <div>
                <PageHeader
                    header={<div className="filesIDIndexHeaderContainer">
                        <h3>
                            <span style={{marginTop: "3px"}}>
                                <BackHeaderIcon link={"/database" + dbHashURL}/>
                                <span style={{marginTop: "3px", color: "green"}}><Icon name="plus square outline"/> Create API from your Database</span>
                            </span>
                        </h3>
                    </div>}
                    desc={ <div style={{ display: "grid", gridTemplateColumns: "50% 50%"}}>
                        <div>
                            <PageHeaderHelp
                                header="Need help creating an API"
                                url="/api-docs/?docsMenuItem=database-createAPI"
                                desc="Read about how to create an API from a database"
                            />
                        </div>
                        <div style={{textAlign: "right"}}>
                            <Popup
                                trigger={
                                <PageHeaderButton
                                    content="Delete API"
                                    icon="trash alternate outline"
                                    color="red"
                                />  }
                                on='click'
                                content={<div>
                                    <p>Are you sure you want to delete this API?</p>
                                    <p>This action cannot be undone</p>
                                    <DeleteEditAPI dbHashURL={dbHashURL} queryPK={this.getQueryPK()} queryIdx={this.getQueryIdx()}/>
                                </div>}
                            />
                        </div>
                    </div>}
                />
                <div className="row">
                    <div className="col-sm-6">
                        <a href="/database" ref={goToDatabase => this.goToDatabase = goToDatabase} style={{display: "none"}}> Database none</a>
                        {/*<a href={"/database" + dbHashURL}><Icon name="left arrow" /> Go to your database</a>*/}
                    </div>
                    <div className="col-sm-6">
                    </div>
                </div>
                <div style={{padding: "32px 60px 240px 60px"}}>
                    <div style={{marginBottom: "32px"}}>
                        <DisplayHeader header={<span>Enter a Name for your API {name.trim() === "" ? <span style={{color: 'red'}} className="thinHeading"><small>required</small></span> : null}</span>}/>
                        <input className="inputGray" type="text" onChange={this.handleInputChange} name="name" value={name} style={{width: '100%'}} placeholder="User Emails"/>
                    </div>
                    <div>
                        <DisplayHeader header="Write your query"/>
                        <span className="linkStyle" onClick={e => this.openModal(e, "queryHelpModal")}><Icon name="question circle outline"/> Read query rules </span> {allTablesAndColumns}
                        <div style={{backgroundColor: "#F0F0F0", borderRadius: "4px", padding: "16px"}}>
                            <AceEditor
                                mode="sql"
                                theme="monokai"
                                onChange={this.changeSQLQuery}
                                value={query}
                                name="UNIQUE_ID_OF_DIV"
                                editorProps={{$blockScrolling: true}}
                                height={200}
                                width="auto"
                            />
                            <div style={{ paddingTop: "16px", paddingBottom: "16px"}}>
                                <Button style={{backgroundColor: "#FFFFFF", border: "1px solid #6FBB98", color: "#6FBB98"}}
                                        disabled={query.trim() === "" || query.trim().toLowerCase().substring(0 ,6) !== "select"}
                                        icon="table"
                                        onClick={this.testSQLQuery}
                                        content="Test SQL Query"
                                        loading={this.state.testingSQLQuery}/>
                                {
                                    query.trim().toLowerCase().substring(0 ,6) !== "select" ?
                                        <div style={{color: 'red'}}><small>Query must start with select</small></div> : null
                                }
                            </div>
                            <div>
                                <TestQueryTable info={this.state} idx={this.props.idx}/>
                            </div>
                        </div>
                        <div style={{textAlign: 'center', paddingTop: "32px" }}>
                            <SaveNewQuestion updateState={this.updateState} queryInfo={queryInfo}
                                             info={this.state} />
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

const mapStateToProps = (state) => ({
    unsavedNewQuestions: state.mainState.unsavedNewQuestions,
    modalInfo: state.mainState.modalInfo,
    allQueries: state.mainState.allQueries,
    editQueryInfo: state.mainState.editQueryInfo
})

const mapActionsToProps = {
    modifyModalInfo: modifyModalInfo,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(EditAPIDisplay)