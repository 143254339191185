import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../Constants/API_Root";
import { Message } from 'semantic-ui-react'
import Loader from 'react-loader-spinner'
import TeamDisplayIndex from "./TeamDisplayIndex"
import {modifyTeamInfo} from "../Actions/ModifyTeamInfo";
import {modifyPendingInvites} from "../Actions/ModifyPendingInvites";
import EmailVerificationMessage from "../SharedComponents/EmailVerificationMessage";
import FailedPaymentMessage from "../SharedComponents/FailedPaymentMessage";
import PageHeader from "../SharedComponents/PageHeader";
import TeamHeaderToolbar from "./TeamHeaderToolbar"

class TeamsDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: false,
            error: null
        }
    }

    componentDidMount(){
        const isError = () => {
            this.setState({
                error: <Message error header="There was an error getting your team information"
                        content="Please refresh and try again. If the error persists, contact our support"
                        icon="refresh" fluid />,
                fetching: false
            })
        }

        this.setState({ fetching: true })

        fetch(API_Root + "api/get-api-team-information/", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then(data => {
                    this.props.modifyTeamInfo(data['teamInfo'])
                    this.props.modifyPendingInvites(data['pendingInvites'])

                    this.setState({
                        error: null,
                        fetching: false
                    })

                }).catch( err => isError())
            } else {
                isError()
            }
        }).catch((err) => {
            isError()
        })
    }

    render() {
        let body;

        if (this.state.fetching){
            body = <div style={{padding: "50px 100px 100px 100px"}}>
                <Loader
                    type="TailSpin"
                    color="black"
                    height="50"
                    width="50"
                />
            </div>
        } else {
            if (this.state.error === null){
                body = <div style={{ padding: "32px 60px 100px 60px"}}>
                    <TeamDisplayIndex />
                </div>
            } else {
                body = this.state.error
            }
        }

        return (
            <div style={{minHeight: "100vh"}}>
                <EmailVerificationMessage/>
                <FailedPaymentMessage/>
                <div>
                    <PageHeader
                        header="Teams"
                        desc={<TeamHeaderToolbar />}
                    />
                    {body}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    modifyTeamInfo: modifyTeamInfo,
    modifyPendingInvites: modifyPendingInvites
}

export default connect(mapStateToProps, mapActionsToProps)(TeamsDisplay)