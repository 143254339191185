import React, {Component} from 'react'
import DatabaseTypeTag from "./DatabaseTypeTag";
import getURLParam from "../Constants/getURLParam";
import { Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";

class ViewDatabaseButton extends Component {
    constructor(props){
        super(props);

        this.viewDatabaseOrTeam = this.viewDatabaseOrTeam.bind(this);
    }

    viewDatabaseOrTeam(e){
        let classNames;
        try {
            classNames = e.target.className
        } catch(err) {
            classNames = ""
        }

        if (classNames === null){
            classNames = ""
        }

        if (classNames.indexOf("deleteDatabaseBtnRow") === -1){
            this.viewLink.click()
        }
    }

    openDeleteModal = () => {
        this.props.modifyModalInfo({ databaseHash: this.props.info.dbHash, location: "databasePage", dbName: this.props.info.dbName })
        this.props.toggleModal("deleteDatabaseModal")
    }

    render() {
        const name = <span style={{fontFamily: "Lato", color: "#161E16", fontWeight: "600", fontSize: "16px", paddingLeft: "16px"}}>{this.props.info.dbName}</span>
        const desc = this.props.info.dbDesc

        return (
            <div>
                <a style={{display: 'none'}} ref={viewLink => this.viewLink = viewLink}
                   href={"/database/" + this.props.info.dbHash}>Download hidden</a>
                <div className="databaseSegmentRow filesTabFileSummaryRow" style={{borderBottom: "1px solid #F0F0F0", padding: "10px 0 10px 0"}} onClick={this.viewDatabaseOrTeam}>
                    <div>
                        <DatabaseTypeTag dbType={this.props.info.dbType} />
                        {name}
                    </div>
                    <div>
                        {this.props.info.createdDt}
                    </div>
                    <div>
                        {this.props.info.dbType}
                    </div>
                    <div>
                        <div className="ellipses">
                            {desc.trim()}
                        </div>
                    </div>
                    <div style={{textAlign: "right", marginRight: "16px"}}>
                        <button onClick={this.viewDatabaseOrTeam}
                                style={{width:'84px',height:'36px',backgroundColor:'#FFFFFF',border:'1px solid #6FBB98',borderRadius:'4px', color:"#6FBB98", cursor: "pointer"}}> Details</button>
                    </div>
                    <div>
                        <span className="deleteDatabaseBtnRow">
                            <Icon name="trash alternate outline"  style={{width:'6px',height:'8px', cursor: "pointer"}} size="large" basic color='red' onClick={this.openDeleteModal} className="deleteDatabaseBtnRow"/>
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(null, mapActionsToProps)(ViewDatabaseButton)