import React, {Component} from 'react'
import List from "semantic-ui-react/dist/commonjs/elements/List";

class AppsumoPopupPlanBody extends Component {
    render() {

        return (
            <div>
                {this.props.appsumoPlanName==='apispreadsheets_tier1'?
                    <div>
                        <h3 className="pricingHeader"> AppSumo Lifetime Tier 1</h3>
                        <List>
                            <List.Item icon="th" content={"50,000 rows per file"}/>
                            <List.Item icon='file alternate outline' content={'50 files'}/>
                            <List.Item icon='code' content={'50,000 requests per day'}/>
                            <List.Item icon='group' content={'5 team members'}/>
                            <List.Item icon='check' content={"Private"} />
                            <List.Item icon="check"  content={"Auto Email Spreadsheets"} />
                            <List.Item icon="check"  content={"Webhooks"} />
                            <List.Item icon="check"  content={"Block Request Types"} />
                            <List.Item icon="check"  content={"Success/Error Alerts"} />
                            <List.Item icon="check"  content={"Multiple Keys"} />
                            <List.Item icon="check" content={"Domain Whitelist"} />
                            <List.Item icon='phone' content={"Priority"} />
                        </List>
                    </div> :null}
                {this.props.appsumoPlanName==='apispreadsheets_tier2'?
                    <div>
                        <h3 className="pricingHeader"> AppSumo Lifetime Tier 2</h3>
                        <List>
                            <List.Item icon="th" content={"100,000 rows per file"}/>
                            <List.Item icon='file alternate outline' content={'150 files'}/>
                            <List.Item icon='code' content={'100,000 requests per day'}/>
                            <List.Item icon='group' content={'10 team members'}/>
                            <List.Item icon='check' content={"Private"} />
                            <List.Item icon="check"  content={"Auto Email Spreadsheets"} />
                            <List.Item icon="check"  content={"Webhooks"} />
                            <List.Item icon="check"  content={"Block Request Types"} />
                            <List.Item icon="check"  content={"Success/Error Alerts"} />
                            <List.Item icon="check"  content={"Multiple Keys"} />
                            <List.Item icon="check" content={"Domain Whitelist"} />
                            <List.Item icon='phone' content={"Priority"} />
                        </List>
                    </div>:null}
                {this.props.appsumoPlanName==='apispreadsheets_tier3'?
                    <div>
                        <h3 className="pricingHeader"> AppSumo Lifetime Tier 3</h3>
                        <List>
                            <List.Item icon="th" content={"250,000 rows per file"}/>
                            <List.Item icon='file alternate outline' content={'250 files'}/>
                            <List.Item icon='code' content={'250,000 requests per day'}/>
                            <List.Item icon='group' content={'15 team members'}/>
                            <List.Item icon='check' content={"Private"} />
                            <List.Item icon="check"  content={"Auto Email Spreadsheets"} />
                            <List.Item icon="check"  content={"Webhooks"} />
                            <List.Item icon="check"  content={"Block Request Types"} />
                            <List.Item icon="check"  content={"Success/Error Alerts"} />
                            <List.Item icon="check"  content={"Multiple Keys"} />
                            <List.Item icon="check" content={"Domain Whitelist"} />
                            <List.Item icon='phone' content={"Priority"} />
                        </List>
                    </div>:null}
                {this.props.appsumoPlanName==='apispreadsheets_tier4'?
                    <div>
                        <h3 className="pricingHeader"> AppSumo Lifetime Tier 4</h3>
                        <List>
                            <List.Item icon="th" content={"750,000 rows per file"}/>
                            <List.Item icon='file alternate outline' content={'750 files'}/>
                            <List.Item icon='code' content={'750,000 requests per day'}/>
                            <List.Item icon='group' content={'20 team members'}/>
                            <List.Item icon='check' content={"Private"} />
                            <List.Item icon="check"  content={"Auto Email Spreadsheets"} />
                            <List.Item icon="check"  content={"Webhooks"} />
                            <List.Item icon="check"  content={"Block Request Types"} />
                            <List.Item icon="check"  content={"Success/Error Alerts"} />
                            <List.Item icon="check"  content={"Multiple Keys"} />
                            <List.Item icon="check" content={"Domain Whitelist"} />
                            <List.Item icon='phone' content={"Priority"} />
                        </List>
                    </div>:null}

            </div>


        )
    }
}

export default AppsumoPopupPlanBody
