import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyImportSheetsInfo} from "../Actions/ModifyImportSheetsInfo";
import Loader from 'react-loader-spinner'
import ImportSheetsSegment from "./ImportSheetsSegment/ImportSheetsSegment"
import API_Root from "../Constants/API_Root";
import {toggleToast} from "../Actions/ToggleToast";
import NoImporterCreated from "./NoImporterCreated";

class ImportDisplayBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: this.shouldGetImporters()
        }
    }

    shouldGetImporters = () => (this.props.importSheetsInfo.length === 0 || window.location.href.includes("refresh"))

    componentDidMount(){
        if (this.shouldGetImporters()){
            const error = () => this.setState({ fetching: false }, () =>
                this.props.toggleToast({
                    show: true,
                    message: "There was an error getting your import configurations. Please refresh. Contact us if this error persists.",
                    type: "error"
                })
            )

            fetch(API_Root + "api/get-all-import-sheets/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                })
            }).then(res => res.json())
                .then((data)=> {
                    this.setState({ fetching: false }, () => this.props.modifyImportSheetsInfo(data.importSheets))
                }).catch(err => error()).catch(err => error())
        }
    }

    render() {
        if (this.state.fetching){
            return (
                <div style={{textAlign: 'center'}}>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4 className="thinHeading" style={{color: 'black'}}>Getting your Import Sheets Configuration...</h4>
                </div>
            )
        } else {
            if (this.props.importSheetsInfo.length === 0){
                return <div style={{margin: "32px 30px 180px 30px"}}>
                    <NoImporterCreated />
                </div>
            } else {
                const headingStyle = {fontFamily: "Lato", fontWeight: "700", fontSize: "14px", color: "#161E16"}

                return (
                    <div style={{margin: "32px 30px 180px 30px"}}>
                        <div className="importSegmentRow" style={{paddingBottom: "0"}}>
                            <div style={headingStyle}><span style={{paddingLeft: "4px"}}>Importer Name</span></div>
                            <div style={headingStyle}>Date Created</div>
                            <div style={headingStyle}>URL</div>
                            <div> </div>
                        </div>
                        {this.props.importSheetsInfo.map((x, idx) => <ImportSheetsSegment info={x}
                                                                                          type={x.importType}
                                                                                          key={idx}
                                                                                          idx={idx} />)}
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({
    importSheetsInfo: state.mainState.importSheetsInfo
})

const mapActionsToProps = {
    modifyImportSheetsInfo: modifyImportSheetsInfo,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(ImportDisplayBody)