// API for testing or Development

const browserDomain = window.location.origin;

let API_Root;

if (browserDomain.includes("localhost")){
    API_Root = "http://127.0.0.1:8000/";
}
else{
    API_Root = "https://api-woyera.com/";
}

export default API_Root