import React, { Component } from 'react';
import { connect } from 'react-redux';
import API_Backend_Root from "../Constants/API_Backend_Root";
import Loader from "react-loader-spinner";
import {Button} from "semantic-ui-react";

class DisplayCalculatorIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            calculatorInfo: {},
            inputValues: {},
            outputValues: {},  // Stores the label: value for each output
            isExecuting: false,
            executeStatus: ''
        };
    }

    componentDidMount() {
        this.fetchCalculatorInfo();
    }

    fetchCalculatorInfo = () => {
        const urlPath = window.location.pathname;
        const hash = urlPath.split('/')[2];

        fetch(API_Backend_Root + "api/crud-calculator-settings/", {
            method: 'POST',
            body: JSON.stringify({
                action: "get",
                fileHash: hash,
                getSingleObject: true
            })
        })
            .then(res => res.status === 200 ? res.json() : Promise.reject())
            .then(data => this.setState({
                calculatorInfo: data.calculatorInfo,
                isFetching: false
            }))
            .catch(() => this.setState({ isFetching: false }));
    }

    handleInputChange = (field, value) => {
        this.setState(prevState => ({
            inputValues: {
                ...prevState.inputValues,
                [field.cell]: value
            }
        }));
    };

    executeCalculation = () => {
        const { inputValues, calculatorInfo } = this.state;
        console.log(calculatorInfo)
        const hash = window.location.pathname.split('/')[2];

        this.setState({ isExecuting: true, executeStatus: 'Executing...' });

        fetch(`https://api.apispreadsheets.com/run-macros/${hash}/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                accessKey: "19dda6c76821d5bcd187ae96e9d19fdf",
                secretKey: "f4ee8b7af363c22b8a21eea6e3b5f01c",
                input_cells: inputValues,
                sheet_name: "Basic Savings Calculator",  // Use dynamic sheet name if needed
                output_cells: calculatorInfo.outputFields.map(field => field.outputCell),
                save_file_post_call: calculatorInfo.saveInSpreadsheet
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                // Map the response data to output field labels based on calculatorInfo.outputFields
                const outputValues = calculatorInfo.outputFields.reduce((acc, field) => {
                    acc[field.outputLabel] = data.data[field.outputCell] || "N/A";  // Assign returned value or "N/A"
                    return acc;
                }, {});

                this.setState({
                    outputValues,
                    isExecuting: false,
                    executeStatus: 'Calculation complete!'
                });
            })
            .catch(() => this.setState({ isExecuting: false, executeStatus: 'Error executing calculation' }));
    };

    renderInputField = (field) => {
        const { labelColor } = this.state.calculatorInfo;

        const labelStyle = {fontFamily: "Lato", fontWeight: '500', fontSize: "14px", color: labelColor}

        return (
            <div key={field.cell} style={{ marginBottom: '32px' }}>
                <div style={labelStyle}>
                    <label style={{color: labelColor}}>{field.name}</label>
                </div>
                <div>
                    {field.fieldType === 'dropdown' ? (
                        <select onChange={(e) => this.handleInputChange(field, e.target.value)}>
                            {field.additionalOptions.dropdownValues.map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </select>
                    ) : (
                        <input
                            type={field.fieldType === 'number' ? 'number' : 'text'}
                            onChange={(e) => this.handleInputChange(field, e.target.value)}
                        />
                    )}
                </div>
            </div>
        );
    };

    renderOutputField = () => {
        return Object.entries(this.state.outputValues).map(([label, value], index) => (
            <div key={index} style={{ marginTop: '1em' }}>
                <strong>{label}:</strong> <span>{value}</span>
            </div>
        ));
    };

    render() {
        const { isFetching, calculatorInfo, isExecuting, executeStatus } = this.state;

        let labelColor;

        if (isFetching) {
            labelColor = 'black'
        } else {
            labelColor = this.state.calculatorInfo.labelColor;
        }

        return (
            <div style={{ minHeight: '100vh', backgroundColor: calculatorInfo.backgroundColor }}>
                {isFetching ? (
                    <div>
                        <Loader type="TailSpin" color="black" height="50" width="50" />
                        <h4 className="thinHeading">Getting information...</h4>
                    </div>
                ) : (
                    <div style={{ padding: "48px 60px"}}>
                        <h1 style={{color: labelColor}}>{calculatorInfo.title}</h1>
                        <p style={{color: labelColor}}>{calculatorInfo.subtitle}</p>

                        <div style={{ paddingTop: "16px" }}>
                            <form style={{display: 'flex', flexDirection: 'column', maxWidth: '400px'}}>
                                {calculatorInfo.inputFields.map(this.renderInputField)}
                            </form>
                        </div>


                        <Button onClick={this.executeCalculation} disabled={isExecuting} color="black">
                            {isExecuting ? 'Executing...' : 'Calculate'}
                        </Button>
                        <p>{executeStatus}</p>

                        {Object.keys(this.state.outputValues).length > 0 && (
                            <div style={{ color: labelColor }}>
                                <h3>Results</h3>
                                {this.renderOutputField()}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(DisplayCalculatorIndex);
