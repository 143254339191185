import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import { Input, Checkbox } from 'semantic-ui-react'
import {isURLValid} from "../../../../Constants/isURLValid";
import ActionsLabel from "./ActionsLabel";
import ActionsEmailIndex from "./ActionsEmailIndex"
import ActionsPasswordIndex from "./ActionsPasswordIndex"
import {areArraysTheSame} from "../../../../Constants/areArraysTheSame";
import { Button } from 'semantic-ui-react'
import SaveActionsBtn from "./SaveActionsBtn"
import DisplayHeader from "../../../../FileDnD/FilesID/DisplayHeader";

class ActionsIndex extends Component {
    handleInputChange = (e) => this.changeStateInRedux(e.target.name, e.target.value)

    toggleCheckbox = (checkboxName) => this.changeStateInRedux(checkboxName, !this.props.optionsEdit[checkboxName])

    changeStateInRedux = (key, value) => {
        const optionsEditCopy = JSON.parse(JSON.stringify(this.props.optionsEdit))
        optionsEditCopy[key] = value

        this.props.modifyCustomImportSettings("optionsEdit", optionsEditCopy)
    }

    hasInfoChanged = () => {
        let hasChanged = false;

        for (let key in this.props.optionsEdit){
            let newValue = this.props.optionsEdit[key];
            let originalValue = this.props.options[key];

            if (key === "webhookURL") {
                if (newValue.trim() !== originalValue.trim()) {
                    hasChanged = true;
                    break
                }
            }
            else if (key === "webhookOptions"){
                if (JSON.stringify(newValue) !== JSON.stringify(originalValue)){
                    hasChanged = true;
                    break;
                }
            } else if (key === "successAlert"){
                if (originalValue !== newValue){
                    hasChanged = true;
                    break;
                } else {
                    if (newValue === true){
                        if (this.props.optionsEdit['attachFilesToAlert'] !== this.props.options['attachFilesToAlert']){
                            hasChanged = true;
                            break
                        } else {
                            let cleanedAlertEmails = [];
                            for (let i=0; i<this.props.optionsEdit['alertEmails'].length; i++){
                                if (this.props.optionsEdit['alertEmails'][i].trim() !== ""){
                                    cleanedAlertEmails.push(this.props.optionsEdit['alertEmails'][i].trim())
                                }
                            }

                            if (!areArraysTheSame(this.props.options['alertEmails'], cleanedAlertEmails)){
                                hasChanged = true;
                                break
                            }
                        }
                    }

                }
            } else if (key === "private"){
                if (originalValue !== newValue){
                    hasChanged = true;
                    break;
                } else {
                    newValue = this.props.optionsEdit['passwords']
                    originalValue = this.props.options['passwords']

                    let cleanedNewValues = [];
                    for (let j=0; j<newValue.length; j++){
                        if (newValue[j].username.trim() !== "" && newValue[j].password.trim() !== ""){
                            cleanedNewValues.push(newValue[j])
                        }
                    }

                    if (cleanedNewValues.length !== originalValue.length){
                        hasChanged = true;
                        break;
                    } else{
                        for (let i=0; i<cleanedNewValues.length; i++){
                            const originalPasswordInfo = originalValue[i]
                            const newPasswordInfo = cleanedNewValues[i]

                            if (newPasswordInfo.username.trim() !== "" && newPasswordInfo.password.trim() !== ""){
                                if ("username" in originalPasswordInfo
                                    && "password" in originalPasswordInfo
                                    && "username" in newPasswordInfo
                                    && "password" in newPasswordInfo){
                                    if ((originalPasswordInfo.username !== newPasswordInfo.username)
                                        && (originalPasswordInfo.password !== newPasswordInfo.password)){
                                        hasChanged = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return hasChanged
    }

    undo = () => {
        const optionsEditCopy = JSON.parse(JSON.stringify(this.props.optionsEdit))

        for (let key in this.props.options){
            optionsEditCopy[key] = this.props.options[key]
        }

        this.props.modifyCustomImportSettings("optionsEdit", optionsEditCopy)
    }

    getWebhookOptionsValue = (optionKey) => {
        let value = null;
        let defaultValueMap = {sendData: true}

        if (optionKey in defaultValueMap){
            value = defaultValueMap[optionKey]
        }

        if ("webhookOptions" in this.props.optionsEdit){
            if (optionKey in this.props.optionsEdit.webhookOptions){
                value = this.props.optionsEdit.webhookOptions[optionKey]
            }
        }

        return value
    }

    modifyWebhookOptionsValue = (e, optionKey) => {
        let webhookOptions = {};

        if ("webhookOptions" in this.props.optionsEdit){
            webhookOptions = JSON.parse(JSON.stringify(this.props.optionsEdit.webhookOptions))
        }

        if (optionKey === "sendData"){
            webhookOptions['sendData'] = !this.getWebhookOptionsValue("sendData")
        }

        this.changeStateInRedux("webhookOptions", webhookOptions)
    }

    render() {
        const labelStyle = {marginBottom: "16px"}
        const subheadingStyle = {fontFamily: "Lato", fontWeight: "500", fontSize: "14px", color: "#161E16"};

        return (
            <div>
                <div style={{marginBottom: "16px"}}>
                    <DisplayHeader header="Actions"/>
                </div>
                <div className="row">
                    <div className="col-sm-9">
                        <div style={labelStyle} className="displayBox">
                            <ActionsLabel labelText="Webhook URL"
                                          infoText="A REST API URL endpoint that gets called after a successful file upload"
                                          tierRestriction="pro" feature="webhooks"/>
                            <input value={this.props.optionsEdit.webhookURL}
                                   name="webhookURL"
                                   onChange={this.handleInputChange}
                                   style={{width: '100%'}}
                                   placeholder="https://your.domain.Ext/rest-api-endpoint/"
                                   className="inputGray"
                                   disabled={this.props.tier === "free"}
                            />
                            {this.props.optionsEdit.webhookURL.trim() !== "" && !isURLValid(this.props.optionsEdit.webhookURL) ? <small style={{color: "red"}}><i>Please enter a valid url that begins with http:// or https://</i></small> : null }
                            <div style={{display: "grid", gridTemplateColumns: "70% 30%", marginTop: "16px"}}>
                                <div>
                                    <ActionsLabel labelText="Send file data to webhook"
                                                  infoText="If checked, ALL the data from the file will be sent in request to webhook" />
                                </div>
                                <div style={{textAlign: "right"}}>
                                    <Checkbox toggle
                                              disabled={this.props.tier === "free" || this.props.optionsEdit.webhookURL.trim() === "" || !isURLValid(this.props.optionsEdit.webhookURL)}
                                              checked={this.getWebhookOptionsValue("sendData")}
                                              onChange={e => this.modifyWebhookOptionsValue(e, "sendData")}/>
                                </div>
                            </div>
                        </div>
                        <div style={labelStyle} className="displayBox">
                            <div style={{marginBottom: "16px"}}>
                                <ActionsLabel labelText="Email Alerts"
                                              infoText="Receive an email when someone uploads a file"
                                              tierRestriction="team" feature="emailAlerts"/>
                            </div>
                            <div style={{display: "grid", gridTemplateColumns: "70% 30%"}}>
                                <div>
                                    <span style={subheadingStyle}>Receive Email after File Upload</span>
                                </div>
                                <div style={{textAlign: "right"}}>
                                    <Checkbox toggle
                                              disabled={this.props.tier === "free" || this.props.tier === "pro"}
                                              checked={this.props.optionsEdit.successAlert}
                                              onChange={e => this.toggleCheckbox("successAlert")}/>
                                </div>
                            </div>
                            {
                                this.props.optionsEdit.successAlert ?
                                    <div style={{marginTop: "16px"}}>
                                        <Checkbox toggle
                                              label={<label>Attach File to Alert Email</label>}
                                              checked={this.props.optionsEdit.attachFilesToAlert}
                                              onChange={e => this.toggleCheckbox("attachFilesToAlert")}/>
                                        <ActionsEmailIndex
                                            changeStateInRedux={this.changeStateInRedux}
                                        />
                                    </div>
                                    : null
                            }
                        </div>
                        <div style={labelStyle} className="displayBox">
                            <div style={{marginBottom: "16px"}}>
                                <ActionsLabel labelText="Password Protect"
                                              infoText="Create passwords to access file upload URL"
                                              tierRestriction="pro" feature="passwordProtect"/>
                            </div>
                            <div style={{display: "grid", gridTemplateColumns: "70% 30%"}}>
                                <div>
                                    <span style={subheadingStyle}>Require password for hosted link</span>
                                </div>
                                <div style={{textAlign: "right"}}>
                                    <Checkbox toggle
                                              disabled={this.props.tier === "free"}
                                              checked={this.props.optionsEdit.private}
                                              onChange={e => this.toggleCheckbox("private")}/>
                                </div>
                            </div>
                                {
                                    this.props.optionsEdit.private ?
                                        <div style={{marginTop: "16px"}}>
                                            <ActionsPasswordIndex
                                                changeStateInRedux={this.changeStateInRedux}
                                            />
                                        </div>
                                        : null
                                }
                        </div>
                    </div>
                    <div className="col-sm-3 ">
                        <Button content="Undo"
                                basic
                                icon="undo"
                                color="orange"
                                onClick={this.undo}
                                disabled={!this.hasInfoChanged()}
                                fluid />
                        <br/>
                        <SaveActionsBtn hasInfoChanged={this.hasInfoChanged()}/>
                    </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    options: state.mainState.customImportOptions,
    optionsEdit: state.mainState.customImportOptionsEdit,
    tier: state.mainState.tier
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(ActionsIndex)