export const modifyEditOptionsForBackend = (currEditOptions) => {
    const backendOptions = {}

    let backendStartRow;
    if (currEditOptions.startRow === 0){
        backendStartRow = ""
    } else {
        backendStartRow = (currEditOptions.startRow + 1).toString()
    }

    let backendSkipRows = ""
    backendOptions['header'] = currEditOptions.header
    backendOptions['startRow'] = backendStartRow
    backendOptions['skipRows'] = backendSkipRows
    backendOptions['delimiter'] = currEditOptions.delimiter
    backendOptions['skipRowsError'] = currEditOptions.skipRowsError
    backendOptions['startRowError'] = currEditOptions.startRowError

    return backendOptions
}