import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Input, Icon, Message, Button } from 'semantic-ui-react'
import teamMemberMax from "../../Constants/teamMemberMax";
import UpgradeButton from "../../InfoPages/Profile/UpgradeButton"
import InviteRowIndex from "./InviteRowIndex"
import {toggleModal} from "../../Actions/ToggleModal";
import API_Root from "../../Constants/API_Root";
import { getInviteErrorMessage } from "../getInviteErrorMessage";
import {modifyTeamInfo} from "../../Actions/ModifyTeamInfo";
import {initInvitesUsed} from "../initInvitesUsed";
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

class CreateTeamModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            desc: '',
            teamInvites: [],
            saving: false,
            error: false,
            errorMessage: [],
            teamPK: -1
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    addOrRemoveInvite = (inviteIdx, add) => {
        const copiedTeamInvites = JSON.parse(JSON.stringify(this.state.teamInvites))

        let usedTeamInvites = initInvitesUsed(this.props.teamInfo, localStorage.getItem("email"));
        let tier = this.props.tier;

        if (add){
            if (this.state.teamInvites.length + usedTeamInvites >= teamMemberMax[tier]){
                this.setState({
                    errorMessage: [<span>You can only invite a maximum of {teamMemberMax[tier].toString()} people TOTAL under your account plan. Please <UpgradeButton /> if you want to invite more team members</span>]
                })
            } else {
                this.setState({ errorMessage: []})
                copiedTeamInvites.push({ email: "", role: ""})
            }
        } else {
            this.setState({ errorMessage: []})
            copiedTeamInvites.splice(inviteIdx, 1)
        }

        this.setState({ teamInvites: copiedTeamInvites })
    }

    updateTeamInvites = (inviteIdx, key, value, clearRow) => {
        const copiedTeamInvites = JSON.parse(JSON.stringify(this.state.teamInvites))

        if (!clearRow){
            copiedTeamInvites[inviteIdx][key] = value
        } else {
            copiedTeamInvites[inviteIdx] = {email: "", role: ""}
        }

        this.setState({ teamInvites: copiedTeamInvites })
    }

    saveTeam = (e) => {
        const errorMessage = getInviteErrorMessage(this.state.teamInvites, this.state.name)

        if (errorMessage.length > 0){
            this.setState({ error: true, errorMessage: errorMessage})
        } else {
            this.setState({ saving: true })
            const hitError = (errorMessage=["Try again or contact our support if this error persists!"]) => this.setState({ saving: false, error: true, errorMessage: errorMessage })
            const filePK = 'filePK' in this.props ? this.props.filePK : -1;

            fetch(API_Root + "api/create-new-api-team/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    name: this.state.name,
                    desc: this.state.desc,
                    teamInvites: this.state.teamInvites,
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    filePK: filePK
                })
            }).then((res) => {
                if (res.status === 201){
                    res.json().then((data) =>
                    {
                        this.setState({ saving: false, teamPK: data.teamPK })
                        let addedTeamInfo;
                        if (this.props.teamInfo === null){
                            addedTeamInfo = [data['teamInfo']]
                        } else {
                            let currTeamInfo = JSON.parse(JSON.stringify(this.props.teamInfo))
                            currTeamInfo.push(data['teamInfo'])
                            addedTeamInfo = currTeamInfo
                        }

                        this.props.modifyTeamInfo(addedTeamInfo)

                        // This means we are linking a file simultaneously
                        if (filePK !== -1){
                            const fileInfo = JSON.parse(JSON.stringify(this.props.fileInformation))
                            const fileInfoPK = fileInfo[this.props.editID]

                            const teamsPKList = fileInfoPK.teams_pk_list;
                            teamsPKList.push(data['teamInfo'].id)
                            fileInfoPK.teams_pk_list = teamsPKList
                            fileInfo[this.props.editID] = fileInfoPK

                            this.props.modifyFileInformation(fileInfo)
                        }

                    }).catch(err => hitError())
                } else {
                    hitError()
                }
            }).catch(err => hitError())
        }
    }

    closeTeam = () => {
        this.props.toggleModal(null)
    }

    render() {
        let buttonText = "Create Team"
        let firstIcon = <Icon name="group"/>
        let secondIcon = null;

        if (this.state.teamInvites.length > 0){
            buttonText += " and Invite People"
            secondIcon = <Icon name="paper plane"/>
        }

        return (
            <div>
                <div className="create-team-form-fields">
                    <div className="fileIDHeading" style={{color: "#5E645E"}}>Team Name<span style={{color: 'red'}}>*</span></div>
                    <input style={{width: '100%'}} className="inputGray" type='text' placeholder='API Spreadsheets Web Dev Squad' name='name' onChange={this.onChange}/>
                </div>
                <div className="create-team-form-fields">
                    <div className="fileIDHeading" style={{color: "#5E645E"}}>Description</div>
                    <input style={{width: '100%'}} className="inputGray" type='text' placeholder='Best Team Ever!!!' name='desc' onChange={this.onChange}/>
                </div>
                <div style={{borderRadius: "4px", border: "1px solid #F0F0F0", padding: "16px", marginTop: "16px"}}>
                    <div style={{fontWeight: "700", fontFamily: "Lato", fontSize: "16px", color: "#161E16", marginBottom: "8px"}}><Icon name="add user"/> Invite People</div>
                    <p style={{marginBottom: "16px"}}>{"Your email, "} <span style={{color: "cornflowerblue"}}>{localStorage.getItem("email")}</span>{", will be added as an "} <b>Admin</b></p>
                    <InviteRowIndex addOrRemoveInvite={this.addOrRemoveInvite} updateTeamInvites={this.updateTeamInvites} teamInvites={this.state.teamInvites}/>
                </div>
                {this.state.teamPK === -1 ? <div style={{marginTop: "16px"}}><Button loading={this.state.saving} onClick={this.saveTeam} content={buttonText} style={gunaldiStyles.btnGreen}>{firstIcon} {buttonText} {secondIcon}</Button></div> : null}
                {
                    this.state.errorMessage.length > 0 && this.state.teamPK === -1?
                        <Message negative style={{marginTop: "10px"}}>
                            <Message.Header>{this.state.error ? "There was an error creating your team" : "Please fix these errors and try again"}</Message.Header>
                            <Message.List>
                                {this.state.errorMessage.map((x, idx)=> <Message.Item key={idx.toString()}>{x}</Message.Item>)}
                            </Message.List>
                        </Message> : null
                }
                {
                    this.state.teamPK !== -1 ?
                        <Message positive style={{marginTop: "10px"}}>
                            <Message.Header>Congratulations! Your team was created!</Message.Header>
                            <Message.Content>
                                <span className="linkStyle" onClick={this.closeTeam}>View your team <Icon name="arrow right" /></span>
                            </Message.Content>
                        </Message> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    teamInfo: state.mainState.teamInfo,
    tier: state.mainState.tier,
    editID: state.mainState.editID,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyTeamInfo: modifyTeamInfo,
    modifyFileInformation: modifyFileInformation
}

export default connect(mapStateToProps, mapActionsToProps)(CreateTeamModalBody)