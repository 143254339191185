import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import TeamFileModalBodyIndex from "./TeamFileModalBodyIndex"
import { Icon } from 'semantic-ui-react'

class TeamFileModal extends Component {
    render() {
        return (
            <ModalApp name="teamFileModal"
                      header={<h2><Icon name="users"/> Team Info</h2>}
                      body={<TeamFileModalBodyIndex/>}
                      size="large"
            />
        )
    }
}

export default TeamFileModal