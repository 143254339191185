import React, {Component} from 'react'
import DocsBaseURL from "../DocsBaseURL";
import { Menu, Segment } from 'semantic-ui-react';
import CreateIndex from "./Create/CreateIndex";
import ReadIndex from "./Get/ReadIndex";
import UpdateIndex from "./Update/UpdateIndex";
import DeleteIndex from "./Delete/DeleteIndex";
import Icons from "../../../Constants/Icons";

class Docs20200311Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayType: 'Create'
        }

        this.handleItemClick = this.handleItemClick.bind(this);
    }

    handleItemClick(e, { name }){
        this.setState({
            displayType: name
        })
    }

    render() {
        const activeItem = this.state.displayType;

        const display = {
            Create: <CreateIndex/>,
            Read: <ReadIndex/>,
            Update: <UpdateIndex/>,
            Delete: <DeleteIndex/>
        }

        return (
            <div>
                <h3>API Version: <span style={{color: 'green'}}>{this.props.apiVersion} <i style={{color: 'gray'}}>current</i></span></h3>
                <br/>
                <h3>Base URL </h3>
                <DocsBaseURL url="data/"/>
                <br/>
                <h3>The API Supports all 4 CRUD Operations</h3>
                <br/>
                <Menu attached='top' tabular>
                    <Menu.Item
                        icon={Icons.createAction}
                        name='Create'
                        active={activeItem === 'Create'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        icon={Icons.readAction}
                        name='Read'
                        active={activeItem === 'Read'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        icon={Icons.updateAction}
                        name='Update'
                        active={activeItem === 'Update'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        icon={Icons.deleteAction}
                        name='Delete'
                        active={activeItem === 'Delete'}
                        onClick={this.handleItemClick}
                    />
                </Menu>
                <Segment attached='bottom'>
                    {display[this.state.displayType]}
                </Segment>
                <br/><br/><br/><br/>
            </div>
        )
    }
}

export default Docs20200311Index