import React, {Component} from 'react'

class Hero extends Component {
    render() {
        let desc = null;
        if ('desc' in this.props){
            desc = <h3 style={{paddingTop: '20px'}} className="thinHeading"> {this.props.desc}</h3>;
        }

        return (
            <div className="heroDiv">
                <div style={{color: this.props.textColor}}>
                    <h1> {this.props.header}</h1>
                    {desc}
                </div>
            </div>
        )
    }
}

export default Hero