import React, {Component} from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import {getQueryParamValue} from "../../Constants/getQueryParamValue";
import DocsIndex from "../../App/Docs/DocsIndex"

class ImporterDocsIndex extends Component {
    componentDidMount(){
        if (localStorage.getItem("email") !== null) {
            let docsMenuItem = getQueryParamValue("docsMenuItem", window.location.href)
            this.props.navigateTo("/import?menuItem=docs&docsMenuItem=" + docsMenuItem)
        }
    }

    render() {
        return (
            <div>
                <DocsIndex page="importer"/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(ImporterDocsIndex)