import React, {Component} from 'react'
import {connect} from 'react-redux'
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import CopyBtn from "../../../../SharedComponents/CopyBtn";
import InfoPopup from "../../../../SharedComponents/InfoPopup"
import { Icon } from 'semantic-ui-react'
import DeleteDomainConnectionBtn from "./DeleteDomainConnectionBtn"

class DomainConnectionRow extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const textStyle = {fontSize: "14px", color: gunaldiStyles.mainText}
        const cnameValue = this.props.info.DomainName;
        let sslCertificate = false;

        if ("ViewerCertificate" in this.props.info["DistributionConfig"]){
            if ("Certificate" in this.props.info["DistributionConfig"]["ViewerCertificate"]){
                sslCertificate = true;
            }
        }

        const domainName = this.props.info.DistributionConfig.Aliases.Items[0]

        return (
            <div style={{padding: "8px", borderBottom: "1px solid #F0F0F0"}}>
                <div style={this.props.gridStyle}>
                    <div style={textStyle} className="ellipses">
                        {domainName}
                    </div>
                    <div>
                        <CopyBtn text={cnameValue}/> {cnameValue} <InfoPopup info="Create a CNAME record for your domain with this value. This may take up to 24 hours to work"/>
                    </div>
                    <div style={{textAlign: "center"}}>
                        { sslCertificate ? <Icon style={{color: "green"}} name="check circle outline" /> : <Icon style={{color: "red"}} name="ban" /> }
                    </div>
                    <div style={{textAlign: "right", paddingRight: "4px"}}>
                        <DeleteDomainConnectionBtn
                            deleteDomainConnection={this.props.deleteDomainConnection}
                            idx={this.props.idx}
                            domainId={this.props.info.Id}
                            domainName={domainName}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DomainConnectionRow)