import React, {Component} from 'react'
import {getPricingText} from "../../../Constants/getPricingText";
import currencyInfo from "../../../Constants/currencyInfo";

class PlanPricing extends Component {
    render() {
        const yearlyPricingStyle = {fontSize: "12px", color: "#6FBB98"}

        if (this.props.planIdx >= 2){
            yearlyPricingStyle.color = "white"
        }

        if (this.props.isNotPricingPage){
            yearlyPricingStyle.fontSize = '10px'
            yearlyPricingStyle.marginTop = '4px'
        }

        return (
            <div>
                <div>
                    <span style={{
                        fontWeight: "700",
                        color: this.props.planIdx >= 2 ? "white" : "#161E16",
                        fontSize: this.props.isNotPricingPage ? "20px" : "32px",
                        textAlign: "right"}}>{this.props.pricingText}</span>
                    <span style={{
                        color: this.props.planIdx >= 2 ? "white" : "#9696A0",
                        fontWeight: "400",
                        fontSize: "14px"}}>/month</span>
                </div>
                {
                    this.props.yearlyPricing ?
                        <div style={yearlyPricingStyle}>Billed yearly</div> :
                        <div style={{...yearlyPricingStyle, visibility: "hidden"}}>billed monthly</div>
                }
            </div>
        )
    }
}

export default PlanPricing