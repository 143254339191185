import React, {Component} from 'react'
import logoPictureURLs from "../logoPictureURLs";
import LandingSecuritySegment from "./LandingSecuritySegment";


class LandingSecurity extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Secure your API</div>
                <div style={this.props.subheadingStyle}>Use one of our many options to secure your REST API</div>
                <div style={{padding: "5px 40px 0 40px"}}>
                    <div className="row">
                        <div className="col-sm-6">
                            <LandingSecuritySegment header="Access Keys" icon="key" color="rgba(71, 189, 153)"/>
                        </div>
                        <div className="col-sm-6">
                            <LandingSecuritySegment header="Block Requests" icon="code" color="rgba(168, 209, 207)"/>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-6">
                            <LandingSecuritySegment header="Whitelist Domains" icon="lock" color="rgba(173, 185, 213)"/>
                        </div>
                        <div className="col-sm-6">
                            <LandingSecuritySegment header="Data Contract" icon="legal" color="#B0ABDE"/>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default LandingSecurity