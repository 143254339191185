export const MODIFY_IMPORT_COLUMN_INFORMATION = 'MODIFY_IMPORT_COLUMN_INFORMATION';

export function modifyImportColumnInformation(columns, columnsAddlInfo){
    return{
        type: MODIFY_IMPORT_COLUMN_INFORMATION,
        mainState: {
            customImportColumns: columns,
            customImportColumnsAddlInfo: columnsAddlInfo
        }
    }
}