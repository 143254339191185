import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Checkbox, Icon } from 'semantic-ui-react'
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import API_Root from "../Constants/API_Root";
import {toggleModal} from "../Actions/ToggleModal";
import Loader from 'react-loader-spinner'
import {modifyKeysPK} from "../Actions/ModifyKeysPK";
import {toggleToast} from "../Actions/ToggleToast";
import InfoPopup from '../SharedComponents/InfoPopup'
import {modifyEditID} from "../Actions/ModifyEditID";
import {modifyPaymentModalOrigin} from "../Actions/ModifyPaymentModalOrigin";

class PrivateButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: false
        }

        this.toggle = this.toggle.bind(this);
        this.openModal = this.openModal.bind(this);

    }

    openModal(e, modalName){
        if ("changeMenuItem" in this.props){
            if (modalName === "keys"){
                this.props.changeMenuItem(e, "apiKeys")
            } else {
                this.props.modifyEditID(this.props.pkID)
                this.props.modifyPaymentModalOrigin("private");
                this.props.toggleModal(modalName + "Modal")
            }
        } else {
            this.props.modifyEditID(this.props.pkID)
            this.props.modifyPaymentModalOrigin("private");
            this.props.toggleModal(modalName + "Modal")
        }
    }

    toggle(e){
        this.setState({ fetching: true });

        let copiedObject = JSON.parse(JSON.stringify(this.props.fileInformation));

        const currValue = copiedObject[this.props.pkID]['private'];

        const getKeys = !('accessKey' in copiedObject[this.props.pkID]);
        fetch(API_Root + "api/toggle-api-private-status/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                pk: this.props.pk,
                privateStatus: !currValue,
                getKeys: getKeys
            })
        }).then(res => res.json())
            .then(data => {
                copiedObject[this.props.pkID]['private'] = !currValue;

                if (getKeys){
                    copiedObject[this.props.pkID]['accessKey'] = data.accessKey;
                    copiedObject[this.props.pkID]['secretKey'] = data.secretKey;
                }

                this.props.modifyFileInformation(copiedObject);

                this.setState({ fetching: false });
        }).catch(err => {
            this.setState({ fetching: false });
            this.props.toggleToast({show: true, message: "There was a server error making the API private. Please refresh and try again", type: "error"})
        }).catch(err => {
            this.setState({fetching: false});
            this.props.toggleToast({
                show: true,
                message: "There was a server error making the API private. Please refresh and try again",
                type: "error"
            })
        })
    }

    render(){
        const checked = this.props.fileInformation[this.props.pkID]['private'];

        let privateButton;
        // let classNames = "menuButton ";
        let classNames = "";

        if (this.state.fetching){
            privateButton = <Loader
                type="TailSpin"
                color="black"
                height="20"
                width="20"
            />
        }
        else{
            {/*<Icon name="key" id="privateKeysModalButton" style={{color: 'red', cursor: 'pointer', paddingTop: "2px", paddingLeft: "8px"}} onClick={(e) => this.openModal(e, "keys")}/>*/}

            const authIcon = checked ?
                <i className="bx bx-key" style={{cursor: 'pointer', paddingTop: "2px", paddingLeft: "8px", fontSize: "20px", verticalAlign: "middle", color: "green"}} id="privateKeysModalButton" onClick={(e) => this.openModal(e, "keys")} />
                // <Icon name="key" id="privateKeysModalButton" style={{cursor: 'pointer', paddingTop: "2px", paddingLeft: "8px"}} onClick={(e) => this.openModal(e, "keys")}/>
                :
                <span style={{float: 'right'}}><InfoPopup style={{cursor: 'pointer', paddingTop: "2px", paddingLeft: "8px"}} iconName="question circle outline" info={<span>Making your file private will give you Access and Secret Keys</span>} /></span>;

            const canMakePrivateButton = <div className="privateButton">
                <div>
                    <span style={{fontSize: "16px", fontFamily: "Lato", fontWeight: "400"}}>API Private?</span> {authIcon}
                </div>
                <div> </div>
                <div>
                    <Checkbox checked={checked} toggle onChange={this.toggle} />
                </div>
            </div>

            const noMakePrivateButton =
                <div className="privateButton" onClick={(e) => this.openModal(e, "payment")}>
                    <div>
                        <span className="privateButtonUnlock"><Icon name="unlock alternate" /></span>
                        <span style={{fontSize: "16px", fontFamily: "Lato", fontWeight: "400"}}>API Private?</span>
                    </div>
                    <div>

                    </div>
                    <div>
                        <Checkbox className="privateButtonOpaque" readOnly={true} checked={checked} toggle />
                    </div>
                </div>

            if (this.props.tier !== "free"){
                privateButton = this.props.canTakeAction ? canMakePrivateButton : noMakePrivateButton

            }
            else{
                privateButton = noMakePrivateButton
                if (this.props.isTeam && this.props.canTakeAction){
                    privateButton = canMakePrivateButton
                }
            }

        }

        if (["readActionPrivate", "deleteActionPrivate"].includes(this.props.tutorialStep)){
            classNames += "tutorialHighlighted"
        }

        return (
            <div className={classNames} id='privateButtonDiv'>
                {privateButton}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    tier: state.mainState.tier,
    tutorialStep: state.mainState.tutorialStep
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation,
    toggleModal: toggleModal,
    modifyKeysPK: modifyKeysPK,
    toggleToast: toggleToast,
    modifyEditID: modifyEditID,
    modifyPaymentModalOrigin: modifyPaymentModalOrigin
}

export default connect(mapStateToProps, mapActionsToProps)(PrivateButton)