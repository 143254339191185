import {getPostData} from "../Constants/getPostData";
import {jsonToPhpHashMap} from "../Constants/jsonToPhpHashMap";

export const getRequestCodeSnippet = (language, url, body, mode) => {
    let codeSnippet;

    switch (language) {
        case "cURL":
            codeSnippet = getCURLCode(url, body, mode);
            break;
        case "python":
            codeSnippet = getPythonCode(url, body, mode);
            break;
        case "javascript":
            codeSnippet = getJavascriptCode(url, body, mode);
            break;
        case "ruby":
            codeSnippet = getRubyCode(url, body, mode);
            break;
        case "php":
            codeSnippet = getPHPCode(url, body, mode);
            break;
        default:
            codeSnippet = ""
    }

    return codeSnippet
}

const getCURLCode = (url, body, mode) => {
    let postData = formatPostDataForCodeSnippet(body, "cURL");
    postData = postData.replace(/'/g, "'\\''");
    let postBody = "'" + postData + "'";

    return "$ curl -X POST '" +  url + "' \\\n-d " + postBody

}

const getPythonCode = (url, body, mode) => {
    let code = "# pip install requests\n\nimport requests\n\n"

    let postBody = formatPostDataForCodeSnippet(body, "python");

    code += 'r = requests.post("' + url + "\", json=" + postBody + ")\n\n"
    code += "if r.status_code == 200:\n\t# SUCCESS \n\tresp_data = r.json()\nelse:\n\t# ERROR\n\tpass"

    return code
}

const getJavascriptCode = (url, body, mode) => {
    let code = "fetch(";

    // else if (apiType === "readAction"){
    //     code += '"' + fileURL + '").then(res=>{\n'
    //     code += '\tif (res.status === 200){\n\t\t// SUCCESS\n'
    //     code += '\t\tres.json().then(data=>{\n'
    //     code += '\t\t\tconst yourData = data\n'
    //     code += '\t\t}).catch(err => console.log(err))\n'
    //     code += "\t}\n\telse{\n\t\t// ERROR\n\t}\n})"
    // }
    // else{
    let postBody = formatPostDataForCodeSnippet(body, "javascript");

    code += '"' + url + '", {\n'
    code += '\tmethod: "POST",\n'
    code += '\theaders: {"Accept": "application/json, text/plain, */*"},\n'
    code += "\tbody: JSON.stringify(" + postBody + ")\n"
    code += '}).then(res =>{\n'
    code += '\tif (res.status === 200){\n\t\t'
    code += 'res.json().then(data => {\n\t\t\t'
    code += '// SUCCESS\n\t\t\t'
    code += 'const respData = data;\n\t\t'
    code += '}).catch(err => console.log(err))'
    code += "\n\t}\n\telse{\n\t\t// ERROR\n\t}\n})"

    // }

    return code
}

const getRubyCode = (url, body, mode) => {
    let code = "require 'net/http'\nrequire 'json'\n\n";

    code += 'url = "' + url + '"\n'
    code += 'uri = URI.parse(url)\n\n'

    // if (apiType === "readAction" || apiType === "deleteAction"){
    //     code += "response = Net::HTTP.get_response(uri)\n\n"
    //     code += 'if response.code == "200"\n'
    //     code += '\t# SUCCESS\n'
    //     code += apiType === 'readAction' ? '\tdata = JSON.parse(response.body)\n' : ""
    // }
    // else{
    let postBody = formatPostDataForCodeSnippet(body, "ruby");

    code += "http = Net::HTTP.new(uri.host, uri.port)\n"
    code += "request = Net::HTTP::Post.new(uri.request_uri)\n"
    code += "request.body = (" + postBody + ").to_json\n\n"
    code += "response = http.request(request)\n"
    code += "if response.code == '200'\n"
    code += "\t# SUCCESS\n"
    code += "\tresp_data = JSON.parse(response.body)\n"
    code += 'else\n'
    code += '\t# ERROR\n'
    code += 'end'

    return code
}

const getPHPCode = (url, body, mode) => {
    let postBody = formatPostDataForCodeSnippet(body, "php");

    return "<?php\n" +
        `$url = "${url}";\n` +
        // `$data = array(\"a\" => \"b\");\n` +
        // `$data = array(${postBody});\n` +
        `$data = ${postBody};\n` +
        "$content = json_encode($data);\n" +
        "\n" +
        "$curl = curl_init($url);\n" +
        "curl_setopt($curl, CURLOPT_HEADER, false);\n" +
        "curl_setopt($curl, CURLOPT_RETURNTRANSFER, true);\n" +
        "curl_setopt($curl, CURLOPT_HTTPHEADER,\n" +
        "        array(\"Content-type: application/json\"));\n" +
        "curl_setopt($curl, CURLOPT_POST, true);\n" +
        "curl_setopt($curl, CURLOPT_POSTFIELDS, $content);\n" +
        "\n" +
        "$json_response = curl_exec($curl);\n" +
        "\n" +
        "$status = curl_getinfo($curl, CURLINFO_HTTP_CODE);\n" +
        "\n" +
        "if ($status == 200) {\n" +
        "    // SUCCESS\n" +
        "    $resp_data = json_decode($json_response, true);\n" +
        "} else {\n" +
        "    // ERROR\n" +
        "}\n" +
        "\n" +
        "curl_close($curl);\n" +
        "?>\n"
}

const formatPostDataForCodeSnippet = (postJSON, language) => {
    if (language === "php"){
        return jsonToPhpHashMap(postJSON)
    }

    return JSON.stringify(postJSON)
}