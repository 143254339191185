import React, {Component} from 'react'
import CustomImage from "../../../SharedComponents/CustomImage"
import CommentSection from "../CommentSection";

class ShareData extends Component {
    render() {
        return (
            <div>
                <p> Sharing spreadsheet data with other people can be inconvenient and messy. However, API Spreadsheets makes it easy for you to collaborate with your co-workers or friends.  </p>
                <hr/>
                <h1>What Can API Spreadsheets Do?</h1>
                <p>
                    Sharing spreadsheet data can get tricky. With API Spreadsheets, even if that data changes, your code would never have to change. And it will also read in the most up to date Spreadsheet data.
                </p>
                <p>
                    Even if the Spreadsheet is on Dropbox, Google Sheet or your Local Computer.
                    <br/>
                    Our tool, API Spreadsheets, lets you do that.
                </p>
                <figure>
                <img src="https://miro.medium.com/max/700/1*lfcJVt3esgKnIm_hrHun4Q.png" style={{width: '100%', margin: '0 auto', display: 'block'}} alt="APISpreadsheets" />
                </figure>
                <br/>
                <h1>How?</h1>
                <p>
                    We use the power of APIs to create a URL to your spreadsheet data, whether it is on Google Sheets, Dropbox or Locally. Then that URL stays current no matter what.
                </p>
                <p>
                    So you, John and Koya can all have the same URL in your Python code. And when the data changes, you won’t have to do anything :)
                </p>
                <p>
                    <i>If you don’t know what an API is, feel free to check out our article describing <a href="/tutorials/what-the-hell-is-an-api">APIs</a>.</i>
                </p>
                <br/>
                <h1>Okay, that sounds interesting. But how does it actually work?</h1>
                <p>
                    Let’s walk through a scenario.
                </p>
                <p>
                    You are the team leader of a Data Analytics team. John and Koya are your direct reports and all three of you do experimental modeling in Python to figure out why your customers might be leaving.
                </p>
                <p>
                    You get a <b>Customers.xlsx</b> file every week from your sales team. And your team maintains a <b>Regions</b> file on Google Sheets. Both are essential to your modeling.
                </p>
                <p>
                    So instead of re-downloading and re-emailing the file to everyone every time the data changes, you can use API Spreadsheets to get a URL for each of your file.
                </p>
                <p>
                    You pick one person in charge of creating the URLs. It is important to have just one leader for Data Governance.
                </p>
                <p>
                    This person creates an account on API Spreadsheets and then uploads the <b>Customers.xlsx</b> file and connects to the <b>Regions</b> file on Google Sheets.
                </p>
                <figure>
                <img src="https://miro.medium.com/max/700/1*K3vTZMdP9YWVDGP4MUFtoA.png" style={{width: '100%', margin: '0 auto', display: 'block'}} alt="APISpreadsheets"/>
                    <figcaption style={{textAlign: 'center'}}><sub>You Click on Google Sheets and Upload or Drop Files to connect to both your files</sub></figcaption>
                </figure>
                <p>
                    After a file has been uploaded and connected, you will get an API URL. And a Python code snippet on how to read your data.
                </p>
                <br/>
                <figure>
                <img src="https://miro.medium.com/max/700/1*k5WKm4ldt_ef6D_pczA9Nw.png" style={{margin: '0 auto', display: 'block',width: '100%'}} alt="API_Spreadsheets_Code" />
                    <figcaption style={{textAlign: 'center'}}><sub>Code Samples on APISpreadsheets</sub></figcaption>
                </figure>
                <p>
                    You can play around with the data format and see how you would like to read your data. You can see how each Data Format looks in the <b>Sample Response</b>.
                </p>
                <br/>
                <figure>
                <img src="https://miro.medium.com/max/700/1*zWS9GuvkLN09TAgbKblc2w.png" style={{margin: '0 auto', display: 'block',width: '100%'}} alt="API_Spreadsheets_sampleResponse" />
                <figcaption style={{textAlign: 'center'}}><sub>Sample Response</sub></figcaption>
            </figure>
                <p>
                    Now, all three of you can use the same Python code to read in the data!
                </p>
                <br/>
                <hr/>
                <h1>Wait, so how will the Data update automatically?</h1>
                <p>For Google Sheets, the data will always just update automatically as you add, delete or update any rows.</p>
                <p>For the Excel file, in this case <b>Customers.xlsx</b>, the Data Governer will need to replace the current version with the new version in their Dashboard on API Spreadsheets.</p>
                <p>This is very simple AND it maintains your API URL. That means your code in Python will not need to change.</p>
                <p>1. Go To The <b>Files</b> Tab</p>
                <img src="https://miro.medium.com/max/700/1*LssUlMP6CqRUrA2_Lp4BSQ.png" style={{width: '100%'}} alt="fileTab" />
                <br/><br/>
                <p>2. Go to your <b>Customers.xlsx</b> File, Click <b>More Options</b>, Click <b>Replace File</b> and Replace with your current version</p>
                <CustomImage src="https://miro.medium.com/max/700/1*rulDVG8EgSQvBdpvUn2Z3w.png" alt="replaceButton" />
                <div style={{textAlign: 'center'}}>
                    <sub>This will preserve the URL but update the data with the new file!</sub>
                </div>
                <br/><br/>
                <p>That’s it! Now your <b>Customers.xlsx</b> file is up to date and your URL will give you the most recent data.</p>
                <br/><br/>
                <div>
                    <p>If you have any further questions, feel free to email us at <a href="mailto:info@lovespreadsheets.com">info@lovespreadsheets.com!</a> We can’t wait to see what you build.</p>
                    <p>Checkout our other <a href="/tutorials">tutorials! </a> </p>
                <CommentSection commentDiv={<div className="fb-comments" data-href="https://www.apispreadsheets.com/tutorials/share-spreadsheet-data" data-numposts="5" data-width="" />}/>
                </div>
            </div>
        )
    }
}

export default ShareData