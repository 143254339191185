import React, {Component} from 'react'
import {connect} from 'react-redux'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import {isDomainNameValid} from "../../Constants/isDomainNameValid";
import { Button } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {toggleToast} from "../../Actions/ToggleToast";

class WhitelistDomainsBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleting: false,
            saving: false
        }
    }

    handleChange = (e) => {
        this.props.changeDomain(this.props.idx, e.target.value)
    }

    deleteDomain = () => {
        const idx = this.props.idx
        const val = this.props.domainName

        if (val.trim() === ""){
            this.props.deleteDomainFromState(idx)
        } else {
            // delete from backend
            this.addOrDeleteDomain("delete")
        }
    }

    saveDomain = () => {
        this.addOrDeleteDomain("add")
    }

    addOrDeleteDomain = (addOrSave) => {
        const stateKey = addOrSave === "add" ? "saving" : "deleting";

        this.setState({ [stateKey]: true })

        const error = () => {
            this.setState({
                [stateKey]: false
            }, () => this.props.toggleToast({
                show: true,
                type: "error",
                message: "There was an error " + stateKey + " your domain. Please try again. Contact support if error persists"}))
        }

        fetch(API_Root + "api/crud-api-utilities-info/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                mode: addOrSave === "add" ? "addDomain" : "deleteDomain",
                pk: this.props.pk,
                domainName: this.props.domainName
            })
        }).then(res => {
            if (res.status === 200){
                res.json().then(data => {
                    this.setState({
                        [stateKey]: false
                    }, () => {
                        this.props.changeState({ whitelistDomains: data.whitelistDomains})
                    })
                }).catch(err => error())
            } else {
                error()
            }
        }).catch(err => error())
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-9">
                    <input className="inputGray"
                        value={this.props.domainName}
                        onChange={this.handleChange}
                        placeholder="yourdomain.com"
                           style={{ width: '100%'}}
                    />
                    <div>
                        {
                            this.props.isDuplicate ?
                                <span style={{ color: gunaldiStyles.red}}>Domain is duplicate.</span> : null
                        }
                        {
                            !isDomainNameValid(this.props.domainName) ?
                                <span style={{ color: gunaldiStyles.red}}>Domain name is not valid</span> : null
                        }
                    </div>
                </div>
                <div className="col-sm-3">
                    <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "4px"}}>
                        <div>
                            {
                                this.props.isEdited ?
                                    <Button circular onClick={e => this.props.undoDomain(this.props.idx)} basic color="blue" icon="undo" /> :
                                    <Button circular onClick={this.deleteDomain} loading={this.state.deleting} basic color="red" icon="trash alternate outline" />
                            }
                        </div>
                        <div>
                            {
                                this.props.isEdited && isDomainNameValid(this.props.domainName) && !this.props.isDuplicate ?
                                    <Button circular onClick={this.saveDomain} loading={this.state.saving} basic color="green" icon="save" /> :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(WhitelistDomainsBox)