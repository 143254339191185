import React, {Component} from 'react'
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import { Icon } from 'semantic-ui-react'

class UtilitiesHeader extends Component {
    render() {
        return (
            <div style={{ fontSize: "20px", fontColor: gunaldiStyles.subText, fontWeight: "700"}}>
                <Icon name={this.props.icon} /> <span style={{ paddingLeft: "4px" }}>{this.props.header}</span>
            </div>
        )
    }
}

export default UtilitiesHeader