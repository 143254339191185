import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Message } from 'semantic-ui-react'
import {push} from "react-router-redux";
import {toggleModal} from "../../../Actions/ToggleModal";

class ResetPasswordMessage extends Component {
    constructor(props) {
        super(props);

        this.success = this.success.bind(this);
        this.failure = this.failure.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
    }

    dismissMessage(e){
        this.props.dismissMessage()
    }

    success(e){
        this.props.navigateTo("/login");
    }

    failure(e){
        window.location.reload();
    }

    render() {
        const success = this.props.success;

        let header;
        let content;

        if (success){
            header = "Your password was successfully reset :)";
            content = <p className="linkStyle" onClick={this.success}>Log in with your new password</p>
        }
        else{
            header = "There was an error resetting your password :("
            content = <p className="linkStyle" onClick={this.failure}>Try again</p>
        }


        return (
            <Message positive={success}
                     negative={!success}
                     onDismiss={this.dismissMessage}
                     header={header}
                     content={content}
            />
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ResetPasswordMessage)