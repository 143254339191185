import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Checkbox } from 'semantic-ui-react'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import {modifyEditID} from "../../Actions/ModifyEditID";
import {modifyModalQueryQuickstartType} from "../../Actions/ModifyModalQueryQuickstartType";
import {toggleModal} from "../../Actions/ToggleModal";
import API_Root from "../../Constants/API_Root";
import CachingLayerCreateBtn from "./CachingLayerCreateBtn";
import {convertDateToUnixTimestamp} from "../../Constants/convertDateToUnixTimestamp";

class CachingLayerForm extends Component {
    constructor(props){
        super(props);

        const hoursActiveInfo = this.getHoursActiveMessage(this.props.info.expiring)

        this.state = {
            expiringError: hoursActiveInfo.error,
            hoursActiveMessage: hoursActiveInfo.message,
            expiringDate: null
        }
    }

    openQueryModal = (e) => {
        this.props.modifyEditID(this.props.pkID)
        this.props.modifyModalQueryQuickstartType("readAction")
        this.props.toggleModal("queryQuickstartModal")
    }

    handleInputChange = (e) => {
        this.props.changeState(e.target.name, e.target.value)

        if (e.target.name === "expiring"){
            const hoursActiveInfo = this.getHoursActiveMessage(e.target.value)
            this.setState({
                expiringError: hoursActiveInfo.error,
                hoursActiveMessage: hoursActiveInfo.message,
                expiringDate: hoursActiveInfo.expiringDate
            })
        }
    }

    getHoursActiveMessage = (expiring) => {
        let message = "";
        let color = "#5E645E";
        let error;
        const expiringDate = this.getExpiringDate(expiring)

        if (expiring === null || expiring === ""){
            message = "Enter a whole number between 1-12"
            color = "orange"
            error = true
            // this.setState({ expiringError: true })
        } else {
            if(isNaN(expiring)){
                if (expiring !== "inf"){
                    message = "Input needs to be a number"
                    color = "red"
                    error = true
                    // this.setState({ expiringError: true })
                } else {
                    message = "Your caching layer will never expire"
                    error = false
                    // this.setState({ expiringError: false })
                }
            } else {
                const numHours = parseFloat(expiring)
                if (numHours < 1 || numHours > 12){
                    message = "Number must be between 1 and 12"
                    color = "red"
                    error = true
                    // this.setState({ expiringError: true })
                } else {
                    message = <span>Your caching layer will expire at:  <b>{expiringDate.toLocaleString()}</b></span>
                    error = false
                    // this.setState({ expiringError: false })
                }
            }
        }

        return {"message": <small style={{color: color}}>{message}</small>, "error": error, "expiringDate": expiringDate}
    }

    getExpiringDate = (expiring) => {
        if (typeof expiring === "string"){
            if (expiring.toLowerCase() === "inf"){
                return new Date(2030, 1, 1, 0, 0, 0, 0)
            } else {
                try {
                    const numHours = parseFloat(expiring)
                    const date = new Date()
                    date.setTime(date.getTime() + numHours * 60 * 60 * 1000)

                    return date
                } catch (e){
                    return ""
                }
            }
        } else {
            return ""
        }
    }

    getViewURL = () => {
        const getURL = this.props.getURL;
        const dataIdx = getURL.indexOf("/data/") + "/data/".length;
        const baseURL = API_Root.includes("8000") ? "http://localhost:5000/" : "https://www.apispreadsheets.com/"

        return this.addQueryToUrl(baseURL + "table/" + getURL.substring(dataIdx, getURL.length))
    }

    addQueryToUrl = (url) => {
        if (this.props.info.query !== ""){
            if (url.charAt(url.length - 1) === "/"){
                url += "?query=" + this.props.info.query
            } else {
                url += "&query=" + this.props.info.query
            }
        }

        return url
    }

    render() {
        return (
            <div style={{marginTop: "16px"}}>
                <div style={{marginBottom: "16px"}}>
                    <div style={{fontFamily: "Lato", fontSize: '16px', fontWeight: "700", color: gunaldiStyles.mainText, margin: "0 0 6px 0"}}>Hours caching layer should be active<span style={{color: "red"}}>*</span></div>
                    <input className="inputGray"
                           onChange={this.handleInputChange}
                           name="expiring"
                           placeholder="6"
                           value={this.props.info.expiring} />
                    <br/>
                    <div>{this.state.hoursActiveMessage}</div>
                </div>
                <div style={{marginBottom: "16px"}}>
                    <div style={{fontFamily: "Lato", fontSize: '16px', fontWeight: "700", color: gunaldiStyles.mainText, margin: "0 0 6px 0"}}>Add Query <small><i>optional</i></small></div>
                    <input className="inputGray"
                           onChange={this.handleInputChange}
                           name="query"
                           placeholder={"select * from " + this.props.fileInformation[this.props.pkID].fileHash + " where ..."}
                           style={{width: '100%'}}
                           value={this.props.info.query} />
                    <br/>
                    <span className="linkStyle" onClick={this.openQueryModal}><small>Read how to write a valid query</small></span>
                </div>
                <div style={{marginBottom: "16px"}}>
                    <div style={{fontFamily: "Lato", fontSize: '16px', fontWeight: "700", color: gunaldiStyles.mainText, margin: "0 0 6px 0"}}>View Data</div>
                    <div style={{fontSize: "16px", fontWeight: "700"}}><a href={this.getViewURL()} target="_blank" rel="noopener noreferrer">See data that will be stored in your caching layer {this.state.expiringError ? "" : "till " + this.state.expiringDate.toLocaleString()}</a></div>
                    <div style={{color: gunaldiStyles.subText}}>
                        <small>The data at the link above will be stored in the caching layer
                            {
                                this.props.info.query !== "" ? <span>
                                    <br/>If you entered a valid query, the data at the link was already queried.
                                    <br/>If the data is not what you expected or is blank, adjust your query
                                </span> : null
                            }
                        </small>
                    </div>
                </div>
                <div style={{marginBottom: "16px"}}>
                    <CachingLayerCreateBtn error={this.state.expiringError}
                                           info={this.props.info}
                                           pk={this.props.pk}
                                           expiring={this.state.expiringDate}
                                           getURL={this.addQueryToUrl(this.props.getURL)}
                                           changeState={this.props.changeState}
                                           changeStateWhenCreated={this.props.changeStateWhenCreated}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
})

const mapActionsToProps = {
    modifyEditID: modifyEditID,
    modifyModalQueryQuickstartType: modifyModalQueryQuickstartType,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(CachingLayerForm)