import React, {Component} from 'react'
import { Input } from 'semantic-ui-react'
import {connect} from 'react-redux'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import {modifyColumnInfoInRedux} from "./modifyColumnInfoInRedux";
import {getNameErrorMsg} from "./getNameErrorMsg";

class ColumnInternalName extends Component {
    componentDidMount(){
        if (getNameErrorMsg(this.props.name, this.props.idx, this.props.columnNames).trim() !== ""){
            this.props.changeState("error", true)
        }
    }

    handleChange = (e) => {
        const errorMsg = getNameErrorMsg(JSON.parse(JSON.stringify(e.target.value)), this.props.idx, this.props.columnNames, true)

        let error;
        if (errorMsg.trim() !== ""){
            error = true
        } else{
            error = false
        }

        modifyColumnInfoInRedux({error: error, internalName: e.target.value, dupeCheckInternalName: e.target.value},
            this.props,
            this.props.idx)
    }

    toggleInternalNameEqual = () => {
        if (!this.props.internalNameEqual){
            modifyColumnInfoInRedux({internalName: this.props.displayName, dupeCheckInternalName: this.props.displayName, internalNameEqual: !this.props.internalNameEqual},
                this.props,
                this.props.idx)
        } else {
            this.props.changeState("internalNameEqual", !this.props.internalNameEqual)
        }
    }

    render() {
        const errorMsg = getNameErrorMsg(this.props.name, this.props.idx, this.props.columnNames, true)
        return (
            <div>
                <input
                       disabled={this.props.internalNameEqual}
                       style={{width: "100%"}}
                       placeholder="Enter internal column name"
                       className="inputGray"
                       value={this.props.name}
                       onChange={this.handleChange}/>
                <input type="checkbox"
                       style={{width: "10px", height: "10px", margin: '0', padding: "0"}}
                       id={"internalNameEqualCheckbox" + this.props.idx.toString()}
                       name={"internalNameEqualCheckbox" + this.props.idx.toString()}
                       onChange={this.toggleInternalNameEqual}
                       checked={this.props.internalNameEqual} />
                <label htmlFor={"internalNameEqualCheckbox" + this.props.idx.toString()}
                       style={{cursor: "pointer", margin: "0", padding: "5px 0 0 5px", whiteSpace: "none"}}>
                    <small>Internal Name = Display Name?</small>
                </label>
                {errorMsg === "" ? null : <small style={{color: "red", display: "block"}}>{errorMsg}</small>}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    customImportColumnsAddlInfo: state.mainState.customImportColumnsAddlInfo,
    customImportColumns: state.mainState.customImportColumns
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnInternalName)