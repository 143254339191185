import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyPaymentModalOrigin} from "../../Actions/ModifyPaymentModalOrigin";
import { Button } from 'semantic-ui-react'
import {isMobile} from "react-device-detect";

class UpgradeButton extends Component {
    openPaymentModal = () => {
        this.props.modifyPaymentModalOrigin(null)
        this.props.toggleModal("paymentModal")
    }

    render() {
        return (
            <Button color="green"
                    onClick={this.openPaymentModal}
                    content={<h5>Upgrade <span role="img" aria-label="smile" style={{paddingLeft: '2px'}}>😀</span> </h5>}
                    style={isMobile ? {marginTop: '10px'} : null}
            />
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyPaymentModalOrigin: modifyPaymentModalOrigin
}

export default connect(mapStateToProps, mapActionsToProps)(UpgradeButton)