import React, {Component} from 'react'
import CopyBtn from "../../../../SharedComponents/CopyBtn";

class DNSValues extends Component {
    render() {
        let cnameName;
        let cnameValue;
        let cnameNameInfo = null;

        if ("ResourceRecord" in this.props.validationInfo){
            let cnameNameText = this.props.validationInfo['ResourceRecord']['Name']
            cnameName = <span>{cnameNameText} <CopyBtn text={cnameNameText}/></span>
            cnameNameInfo = "If the value provided does not work. Try the following: " + cnameNameText.substring(0, cnameNameText.indexOf("."))

            let cnameValueText = this.props.validationInfo['ResourceRecord']['Value']
            cnameValue = <span>{cnameValueText} <CopyBtn text={cnameValueText}/></span>
        } else {
            cnameName = <span style={{color: "orange"}}>We are still determining the values<br/>Please check again in 10 minutes</span>
            cnameValue = ""
        }

        return (
            <div>
                {this.props.getDisplayRow("CNAME Name", cnameName, cnameNameInfo)}
                {this.props.getDisplayRow("CNAME Value", cnameValue)}
            </div>
        )
    }
}

export default DNSValues