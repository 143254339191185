import React, {Component} from 'react'
import {connect} from 'react-redux'

class SelectPlanBtn extends Component {
    selectPlan = () => {
        this.props.selectPlan(this.props.planIdx)
    }

    render() {
        return (
            <button
                style={{border: "none",
                    backgroundColor: this.props.backgroundColor,
                    color: this.props.color,
                    cursor: "pointer",
                    width: "100%",
                    borderRadius: "4px",
                    padding: "8px 32px", textAlign: 'center'}}
                onClick={this.selectPlan}
            >
                Select Plan
            </button>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(SelectPlanBtn)