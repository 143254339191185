import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Checkbox, Image, Icon, Segment, Button, Label, Menu } from 'semantic-ui-react'
import images from "../../../../Constants/Images";
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import DestinationsSelection from "./DestinationsSelection"
import SaveDestinationsBtn from "./SaveDestinationsBtn"
import {areArraysTheSame} from "../../../../Constants/areArraysTheSame";
import DatabaseDestinationsErrorBanner from "./DatabaseDestinationsErrorBanner"
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import TierLabel from "../../../../SharedComponents/TierLabel"

class DestinationsIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            menuItem: "apiSpreadsheets"
        }
    }

    hasInfoChanged = () => {
        let hasChanged = false;

        const allDestinations = ["google", "dropbox", "s3", "postgres", "mysql"]
        let selected = [];

        for (let i=0; i<allDestinations.length; i++){
            const destination = allDestinations[i];
            selected.push(destination)
            selected.push(destination + "AccountSelected")
            selected.push(destination + "AccountDetails")
        }

        for (let i=0; i<selected.length; i++){
            const key = selected[i];

            if (this.props.destinationsEdit[key].constructor === Array){
                if (!areArraysTheSame(this.props.destinations[key], this.props.destinationsEdit[key])){
                    hasChanged = true
                    break
                }
            } else {
                if (JSON.stringify(this.props.destinationsEdit[key]) !== JSON.stringify(this.props.destinations[key])){
                    hasChanged = true
                    break
                }
            }
        }

        return hasChanged
    }

    handleCheckedChange = (key) => {
        const destinationsEdit = JSON.parse(JSON.stringify(this.props.destinationsEdit))

        const currChecked = destinationsEdit[key]
        destinationsEdit[key] = !currChecked

        this.props.modifyCustomImportSettings("destinationsEdit", destinationsEdit)
    }

    undo = () => {
        const currDestinationsEdit = JSON.parse(JSON.stringify(this.props.destinationsEdit));

        for (let key in this.props.destinations){
            currDestinationsEdit[key] = this.props.destinations[key]
        }

        this.props.modifyCustomImportSettings("destinationsEdit", currDestinationsEdit)
    }

    changeMenuItem = (e, { name }) => this.setState({ menuItem: name })

    getDisplayItem = () => {
        const displayMap = {
            "apiSpreadsheets": <div>
                <Checkbox
                    style={{marginTop: "20px"}}
                    toggle
                    checked={true}
                    readOnly={true}
                    label={<label><Image style={{ marginTop: "-15px"}} src={images.logoNav} size="small"/></label>}
                />
                <br/>
                <small><i>All files will be accessible through your API Spreadsheets account. You can't turn this off</i></small>
            </div>,
            "googleDrive": <div>
                <Checkbox
                    style={{marginTop: "10px"}}
                    toggle
                    onChange={e => this.handleCheckedChange("google")}
                    checked={this.props.destinationsEdit.google}
                    label={<label style={{fontSize: "150%", marginTop: "-5px"}}>
                        <Icon name="google drive" /> Google Drive
                    </label>}
                />
                <br/>
                <DestinationsSelection connectType="google" />
            </div>,
            "dropbox": <div>
                    <Checkbox
                        style={{marginTop: "10px"}}
                        toggle
                        onChange={e => this.handleCheckedChange("dropbox")}
                        checked={this.props.destinationsEdit.dropbox}
                        label={<label style={{fontSize: "150%", marginTop: "-5px"}}>
                            <Icon name="dropbox" /> Dropbox
                        </label>}
                    />
                    <br/>
                    <DestinationsSelection connectType="dropbox" changeMenuItem={this.props.changeMenuItem} />
            </div>,
            "awsS3": <div>
                <Checkbox
                    style={{marginTop: "10px"}}
                    toggle
                    onChange={e => this.handleCheckedChange("s3")}
                    checked={this.props.destinationsEdit.s3}
                    label={<label style={{fontSize: "150%", marginTop: "-5px"}}>
                        <Icon name="aws" /> AWS S3
                    </label>}
                />
                <br/>
                <DestinationsSelection connectType="s3" changeMenuItem={this.props.changeMenuItem} />
            </div>,
            "database": <div>
                <div style={{marginBottom: "16px"}}>
                    <DatabaseDestinationsErrorBanner />
                </div>
                <div>
                    <div style={{display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "8px"}}>
                        <div>
                            <Checkbox
                                style={{marginTop: "10px"}}
                                disabled={this.props.tier !== "business" && this.props.tier !== "enterprise"}
                                toggle
                                onChange={e => this.handleCheckedChange("postgres")}
                                checked={this.props.destinationsEdit.postgres}
                                label={<label style={{fontSize: "150%", marginTop: "-5px"}}>
                                    <Icon name="database" /> Postgres
                                </label>}
                            />
                        </div>
                        <div style={{ paddingTop: "8px" }}>
                            <TierLabel tier="business" feature="database" importer />
                        </div>
                    </div>
                    <br/>
                    <DestinationsSelection connectType="postgres" changeMenuItem={this.props.changeMenuItem} />
                </div>
                <div>
                    <div style={{display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "8px"}}>
                        <div>
                            <Checkbox
                                style={{marginTop: "10px"}}
                                disabled={this.props.tier !== "business" && this.props.tier !== "enterprise"}
                                toggle
                                onChange={e => this.handleCheckedChange("mysql")}
                                checked={this.props.destinationsEdit.mysql}
                                label={<label style={{fontSize: "150%", marginTop: "-5px"}}>
                                    <Icon name="database" /> MySQL
                                </label>}
                            />
                        </div>
                        <div style={{ paddingTop: "8px" }}>
                            <TierLabel tier="business" feature="database" importer />
                        </div>
                    </div>
                    <br/>
                    <DestinationsSelection connectType="mysql" changeMenuItem={this.props.changeMenuItem} />
                </div>
            </div>
        }

        if (this.state.menuItem in displayMap){
            return displayMap[this.state.menuItem]
        } else {
            return null
        }
    }

    render() {
        const toolbarSelectedStyle = {color: gunaldiStyles.orange, fontSize: "14px"}
        const originalToolbarStyle = {color: "#9696A0", fontSize: "14px"}

        return (
            <div>
                <Menu pointing secondary>
                    <Menu.Item
                        name="apiSpreadsheets"
                        icon="code"
                        active={this.state.menuItem === "apiSpreadsheets"}
                        style={this.state.menuItem === "apiSpreadsheets" ? toolbarSelectedStyle : originalToolbarStyle}
                        onClick={this.changeMenuItem}>
                        <Icon name="code" /> API Spreadsheets
                    </Menu.Item>
                    <Menu.Item
                        name="googleDrive"
                        icon="google"
                        active={this.state.menuItem === "googleDrive"}
                        style={this.state.menuItem === "googleDrive" ? toolbarSelectedStyle : originalToolbarStyle}
                        onClick={this.changeMenuItem} />
                    <Menu.Item
                        name="dropbox"
                        icon="dropbox"
                        active={this.state.menuItem === "dropbox"}
                        style={this.state.menuItem === "dropbox" ? toolbarSelectedStyle : originalToolbarStyle}
                        onClick={this.changeMenuItem} />
                    <Menu.Item
                        name="awsS3"
                        icon="aws"
                        active={this.state.menuItem === "awsS3"}
                        style={this.state.menuItem === "awsS3" ? toolbarSelectedStyle : originalToolbarStyle}
                        onClick={this.changeMenuItem}>
                        <Icon name="aws"/> AWS S3
                    </Menu.Item>
                    <Menu.Item
                        name="database"
                        icon="database"
                        active={this.state.menuItem === "database"}
                        style={this.state.menuItem === "database" ? toolbarSelectedStyle : originalToolbarStyle}
                        onClick={this.changeMenuItem} />
                </Menu>
                <div className="row">
                    <div className="col-sm-9">
                        <div className="displayBox">
                            {this.getDisplayItem()}
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <Button content="Undo"
                                basic
                                icon="undo"
                                color="orange"
                                onClick={this.undo}
                                disabled={!this.hasInfoChanged()}
                                fluid />
                        <br/>
                        <SaveDestinationsBtn hasInfoChanged={this.hasInfoChanged()} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    destinations: state.mainState.customImportDestinations,
    destinationsEdit: state.mainState.customImportDestinationsEdit,
    tier: state.mainState.tier
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(DestinationsIndex)