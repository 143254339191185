import React, {Component} from 'react'
import {connect} from 'react-redux'
import ParameterRow from "../Utilities/ParameterRow"

class AIResponseParams extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="results"
                        type={"array (of hashmaps)"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                The resulting data from asking your data the question in natural language
                            </div>
                            <div style={{ marginBottom: "2px"}}>
                                The results data format is an array of objects (hashmaps), where each object represents a row in the data. The keys in the object are the column names, and values are the values of that column in that row.
                            </div>
                        </div>}
                    />
                </div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="query"
                        type={"string"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                This is the same query that you sent in the request. It is returned for reference
                            </div>
                        </div>}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(AIResponseParams)