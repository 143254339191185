import React, {Component} from 'react'
import {connect} from 'react-redux'

class FileRowErrorContent extends Component {
    constructor(props) {
        super(props);


    }

    render() {
        // If more than one blocks, then show multiple accordions
        return (
            <div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FileRowErrorContent)