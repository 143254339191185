import React, {Component} from 'react'
import {Button, Icon, Segment} from 'semantic-ui-react'
import ConnectDomainIllustration from "../../Illustrations/ConnectDomainIllustration.svg"
import AceEditor from 'react-ace';
import RulesEnginePic from './RulesEnginePic.png'
import ReactJson from 'react-json-view'
import {gunaldiStyles} from "../../../SharedComponents/GunaldiDesignStyles";

class RulesEngine extends Component {
    generateCurlCommand = (jsonparams, url) => {
        // Parse the JSON parameters
        let params = JSON.parse(jsonparams);

        const jsonString = JSON.stringify(params, null, 2); // 2 spaces indentation
        const formattedJsonString = jsonString.replace(/(?:\r\n|\r|\n)/g, '\n  '); // Add extra spaces for indentation

        return `curl -X POST ${url}\n-d '${formattedJsonString}'`;

    }

    render() {
        const jsonparams = '{' +
            '\n\t"input_cells":{"B2": 33, "B3": "Male"},' +
            '\n\t"sheet_name":"Rates",' +
            '\n\t"macro_name":"GetPremium",' +
            '\n\t"output_cells":["B4", "B3"]' +
            '\n}'

        const url = "https://api.apispreadsheets.com/run-macros/{file_id}"

        const labelHeaders = {
            fontWeight: "700",
            fontSize: "16px",
            paddingBottom: "4px"
        }

        return (
            <div>
                <div style={this.props.headerStyle}>Use Spreadsheets as Rules Engine</div>
                <div style={this.props.subheadingStyle}>Execute formulas & macros in your spreadsheets using our API</div>
                <Segment>
                    <div style={{margin: "0 auto", textAlign: "center"}}>
                        <div className="row">
                            <div className="col-sm-4">
                                <div style={labelHeaders}>Make request to API</div>
                                <div style={this.props.subheadingStyle}>Specify input, output, macros etc.</div>
                                <AceEditor
                                    mode="powershell"
                                    theme="monokai"
                                    value={this.generateCurlCommand(jsonparams, url)}
                                    name="UNIQUE_ID_OF_DIV"
                                    editorProps={{$blockScrolling: true}}
                                    height="240px"
                                    width="auto"
                                />
                            </div>
                            <div className="col-sm-4">
                                <div style={labelHeaders}>Your formulas get executed</div>
                                <div style={this.props.subheadingStyle}>Each request is a fresh execution</div>
                                <div>
                                    <img src={RulesEnginePic} alt="Rules Engine" style={{ width: "85%" }} />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div style={labelHeaders}>Get results back</div>
                                <div style={this.props.subheadingStyle}>Receive execution answers in JSON</div>
                                <div style={{ textAlign: "left" }}>
                                    <ReactJson src={{ "data": { "B4": 66, "B3": "Male" } }} name={null} theme="ocean" displayObjectSize={false}/>
                                </div>
                                <div style={{ textAlign: "left", paddingTop: "16px" }}>
                                    <div style={labelHeaders}>Rules engine feature is an enterprise-add on</div>
                                    <Button onClick={() => {window.open("https://calendly.com/api-spreadsheets/25min", "_blank");}}
                                            style={{backgroundColor:gunaldiStyles.orange,width:'200px',height:'50px',color:'white',fontSize:'120%'}}>
                                        <Icon name="phone" /> See Demo
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Segment>
            </div>

        )
    }
}

export default RulesEngine