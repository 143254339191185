import React, {Component} from 'react'
import { Table } from 'semantic-ui-react'

class ColumnOnlyNamesBody extends Component {

    render() {
        const fileInfo = this.props.fileInfo;
        // OLD WAY TO FIND COL Names
        // const sample = fileInfo.jsonColumnSample;
        //
        // let columnNames = [];
        //
        // for (let colName in sample){
        //     columnNames.push(colName)
        // }
        let columnNames = fileInfo.columnNames;

        const fileName = fileInfo.name;

        const columnNamesRows = columnNames.map((x, idx) => <Table.Row key={idx}><Table.Cell>{x}</Table.Cell></Table.Row>)

        return (
            <div>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{"Column Names for " + fileName}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {columnNamesRows}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default ColumnOnlyNamesBody