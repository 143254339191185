import React, {Component} from 'react'
import {Button, Icon} from "semantic-ui-react";
import QuickstartTableDropdown from "./QuickstartTableDropdown";
import BooleanChangeDropdown from "./BooleanChangeDropdown";

class QuickstartTableInput extends Component {
    handleChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...this.props.rows];
        const rowInfo = rows[idx]
        rowInfo[name] = value

        let error = false
        let duplicate = false

        if (name === "key"){
            if (value.trim() === ""){
                error = true
            } else if (this.props.allKeys.includes(value.trim())){
                error = true
                duplicate = true
            }
        } else if (name === "value"){
            if (rowInfo.type === "number"){
                if (!this.isValueANumber(value)){
                    error = true
                }
            }
        }

        rowInfo.error = error
        rowInfo.duplicate = duplicate

        rows[idx]=rowInfo

        this.props.changeState({rows})
        // this.setState({
        //     rows
        // });
    };

    isValueANumber = (value) => !isNaN(value)

    handleAddRow = () => {
        if (this.props.rows.length === 100){
            alert("100 rows is the maximum")
        } else {
            const item = {
                key: "",
                type: "string",
                value: "",
                error: true
            };

            this.props.changeState({
                rows: [...this.props.rows, item]
            });
        }

    };

    handleDropdownChange = (idx, value) => {
        const rows = [...this.props.rows];
        const rowInfo = rows[idx]
        rowInfo['type'] = value
        rows[idx]=rowInfo
        this.props.changeState({
            rows
        });
    };

    handleRemoveSpecificRow = (idx) => () => {
        const rows = [...this.props.rows]
        rows.splice(idx, 1)

        this.props.changeState({ rows })
        // this.setState({ rows })
    }

    handleBooleanChange = (idx, value) => {
        const rows = [...this.props.rows];
        const rowInfo = rows[idx]
        rowInfo['value'] = value
        rows[idx]=rowInfo

        this.props.changeState({
            rows
        });
    };


    render() {
        // console.log(this.state.rows)
        const booleanOptions=[
            {key: 0, value: true, text: "True"},
            {key: 1, value: false, text: "False"},
        ]

        return (
            <div>
                <table
                    className="table table-bordered"
                    id="tab_logic">
                    <thead>
                    <tr>
                        <th className="col-sm-2"> Type </th>
                        <th className="col-sm-4"> Key </th>
                        <th className="col-sm-4"> Value </th>
                        <th className="col-sm-2" />
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.rows.map((item, idx) => (
                        <tr id="addr0" key={idx}>
                            <td>
                                <QuickstartTableDropdown
                                    value={this.props.rows[idx].type}
                                    idx={idx}
                                    handleDropdownChange={this.handleDropdownChange}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    name="key"
                                    value={this.props.rows[idx].key}
                                    onChange={this.handleChange(idx)}
                                    className="form-control"
                                />
                                {this.props.rows[idx].key.trim() === "" ? <small style={{color: "red", display: "block"}}>Please enter a Key </small> : null}
                                {this.props.rows[idx].duplicate ? <small style={{color: "red", display: "block"}}>Duplicate keys are not valid </small> : null}
                            </td>
                            <td>
                                {this.props.rows[idx].type === 'boolean'?
                                    <BooleanChangeDropdown value={this.props.rows[idx].value} booleanOptions={booleanOptions} handleBooleanChange={this.handleBooleanChange} idx={idx} />:
                                    <input
                                        type="text"
                                        name="value"
                                        value={this.props.rows[idx].value}
                                        onChange={this.handleChange(idx)}
                                        className="form-control"
                                        disabled={this.props.rows[idx].type === 'null'}

                                />}
                                {this.props.rows[idx].type === "number" ? !this.isValueANumber(this.props.rows[idx].value) ?  <small style={{color: "red"}}>Please enter a Number </small> : null : null}
                            </td>
                            <td>
                                <Button icon="trash alternate outline" color="red"
                                        basic={true} onClick={this.handleRemoveSpecificRow(idx)}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <span style={{backgroundColor: "#5499F8", padding: "6px 2px 6px 6px", borderRadius: "4px", cursor: 'pointer', textAlign: "center"}} onClick={this.handleAddRow}>
                    <Icon name="plus" style={{color: 'white', fontSize: "14px", textAlign: "center"}} />
                </span>
            </div>
        );
    }
}

export default QuickstartTableInput