import React, {Component} from 'react'

class DemoVideo extends Component {
    render() {
        return (
            <div>
                <iframe width="840" height="472.5" src={this.props.isImporter ? "https://www.youtube.com/embed/kqAlzo8liX0" : "https://www.youtube.com/embed/XrY19kHV14U"}
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
        )
    }
}

export default DemoVideo