import React, {Component} from 'react'
import teamMemberMax from "../../../Constants/teamMemberMax";
import RoleTag from "../../../Teams/Roles/RoleTag";
import RolesModalBody from "../../../Teams/Roles/RolesModalBody";
import TierRestrictionBanner from "../../../App/Docs/Actions/TierRestrictionBanner"

class TeamsGuide extends Component {
    render() {
        return (
            <div>
                <div style={{marginTop: "-8px", marginBottom: "16px"}}>
                    <TierRestrictionBanner plan="team" payment="teams"/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Introduction</p>
                    <p>On the <b>Team</b>, <b>Business</b> and <b>Enterprise </b>plans, you can create a Team to share files and APIs</p>
                    <p>You can create as many teams as you want, but the total number of members invited to teams cannot exceed
                        <br/>
                        <b>&bull; {teamMemberMax.team.toString()} members</b> for the <b>Team</b> plan
                        <br/>
                        <b>&bull; {teamMemberMax.business.toString()} members</b> for the <b>Business</b> plan
                        <br/>
                        <b>&bull; Unlimited members</b> for the <b>Enterprise</b> plan
                    </p>
                    <p>The total number <u>DOES NOT</u> include the person who is subscribed to the <b>Team</b>, <b>Business</b> or <b>Enterprise</b> account</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Example Teams for a <u><b>Business</b></u> account created by <u><b>Ajit</b></u></p>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="team-guide-box-style">
                                <h5>Team 1</h5>
                                <h6 className="text-muted" style={{textAlign: "center"}}>Dev squad</h6>
                                <div style={{paddingLeft: "10%"}}>
                                    <small><RoleTag userRole="admin"/> Ajit</small>
                                    <br/>
                                    <small><RoleTag userRole="manager"/> Adam</small>
                                    <br/>
                                    <small><RoleTag userRole="manager"/> José</small>
                                    <br/>
                                    <small><RoleTag userRole="developer"/> Svetlana</small>
                                    <br/>
                                    <small><RoleTag userRole="developer"/> Marie</small>
                                    <br/>
                                </div>
                                <h6 style={{textAlign: "center"}}><u>4 invited members</u></h6>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="team-guide-box-style">
                                <h5>Team 2</h5>
                                <h6 className="text-muted" style={{textAlign: "center"}}>Marketing ninjas</h6>
                                <div style={{paddingLeft: "10%"}}>
                                    <small><RoleTag userRole="admin"/> Ajit</small>
                                    <br/>
                                    <small><RoleTag userRole="manager"/> Yong-sook</small>
                                    <br/>
                                    <small><RoleTag userRole="manager"/> Nelson</small>
                                    <br/>
                                    <small><RoleTag userRole="developer"/> Karla</small>
                                    <br/>
                                </div>
                                <h6 style={{textAlign: "center"}}><u>3 invited members</u></h6>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="team-guide-box-style">
                                <h5>Team 3</h5>
                                <h6 className="text-muted" style={{textAlign: "center"}}>Data sleuths</h6>
                                <div style={{paddingLeft: "10%"}}>
                                    <small><RoleTag userRole="admin"/> Ajit</small>
                                    <br/>
                                    <small><RoleTag userRole="manager"/> Sherona</small>
                                    <br/>
                                    <small><RoleTag userRole="developer"/> Arpeet</small>
                                    <br/>
                                    <small><RoleTag userRole="developer"/> Maru</small>
                                    <br/>
                                </div>
                                <h6 style={{textAlign: "center"}}><u>3 invited members</u></h6>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <p>Ajit has used up 10 of his {teamMemberMax.business} invites under the Business account so he can invite {teamMemberMax.business - 10} more team members</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Roles</p>
                    <p>There are 3 possible roles a team member can have
                        <br/>&bull; <RoleTag userRole="admin"/>
                        <br/>&bull; <RoleTag userRole="manager"/>
                        <br/>&bull; <RoleTag userRole="developer"/>
                    </p>
                    <p>These roles are hierarchical. See a quick breakdown below</p>
                    <RolesModalBody/>
                    <br/><br/>
                    <p style={{marginBottom: "0"}}>Some more details about these Roles</p>
                    <ul>
                        <li>The person with the <b>Team</b> or <b>Business</b> account is automatically the <RoleTag userRole="admin"/></li>
                        <li>There can only be 1 <RoleTag userRole="admin"/> per team</li>
                        <li><RoleTag userRole="developer"/> can view shared files on their dashboard but can <b>NOT</b> share files with team members</li>
                        <li><RoleTag userRole="developer"/> can <b>ONLY</b> download files and view their keys on the Dashboard. They can <b>NOT</b> perform any other action</li>
                    </ul>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">API Requests</p>
                    <p>All files and APIs that belong to the team follow the <b>Admin's</b> account limitations</p>
                    <p>For example, let's say a user with a <b>Business</b> account created a team of 4 members</p>
                    <p>There is <span className="linkStyle" onClick={e => this.props.setBothMenuItems("actions-teamInfo")}>1 file that is shared amongst this team</span></p>
                    <p>Anyone on this team can make API requests to this file. The number of requests will be deducted from the <b>Admin's</b> request quota</p>
                </div>
            </div>
        )
    }
}

export default TeamsGuide