import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Accordion, Icon } from 'semantic-ui-react'
import JSONObjectDisplay from './JSONObjectDisplay'
import CustomAccordion from "../CustomAccordion"

class JSONAccordionIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 'active' in this.props ? 0 : -1 ,
        }

        this.handleAccordionClick = this.handleAccordionClick.bind(this);
    }

    handleAccordionClick(e, idxClicked){
        const { index } = idxClicked;
        const activeIndex = this.state.activeIndex;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex })
    }

    render() {
        const activeIndex = this.state.activeIndex;

        return (
            <CustomAccordion
                body={<JSONObjectDisplay
                    heading={this.props.heading}
                    message={this.props.message}
                    jsonObj={this.props.jsonObj}
                />}
                header={this.props.title}
                accordionStyle={{
                    borderRadius: '4px',
                    border: "1px solid #F0F0F0",
                    backgroundColor: 'white'
                    // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                }}
                headerDivStyle={{
                    borderRadius: '4px',
                    backgroundColor: 'white',
                    fontSize: '16px',
                    fontWeight: '700',
                    fontFamily: "Lato",
                    padding: "16px"
                }}
            />
        )
    }
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(JSONAccordionIndex)