import React, {Component} from 'react'
import { Segment, Button, Icon } from 'semantic-ui-react'
import AccountRowDisplay from "../../SharedComponents/GoogleAccounts/AccountRowDisplay"
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import RemoveDropboxAccount from "./RemoveDropboxAccount"

class DropboxAccountRow extends Component {
    render() {
        return (
            <div>
                <Segment style={{padding: "10px", marginBottom: "10px"}}>
                    <div className="row">
                        <div className="col-sm-8">
                            {
                                this.props.hasDropboxPermissions ?
                                    <div>
                                        <h5>
                                            <AccountRowDisplay
                                                profilePic={this.props.profilePic}
                                                email={this.props.email}
                                                accountType="dropbox"
                                            />
                                        </h5>
                                        {this.props.message !== "" ? <p style={{color: gunaldiStyles.orange}}>{this.props.message}</p> : ""}
                                    </div> :
                                    <div>
                                        <h5 style={{color: "red"}}>Your Account Cannot be Connected</h5>
                                        <p>Please follow the steps below to reconnect your account</p>
                                    </div>
                            }
                        </div>
                        <div className="col-sm-4">
                            {
                                this.props.hasDropboxPermissions ?
                                    <RemoveDropboxAccount
                                        accountID={this.props.accountID}
                                        removeAccountInfo={this.props.removeAccountInfo}
                                        email={this.props.email}
                                    /> :
                                    null
                            }
                        </div>
                    </div>
                    {
                        !this.props.hasDropboxPermissions ?
                            <div>
                                <div style={{marginBottom: "16px"}}>
                                    <p style={{fontSize: "16px"}}>1. Remove API Spreadsheets Permissions from your Dropbox account</p>
                                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/RemoveAPIFromDropbox.png"
                                         alt="Remove API Account from Dropbox"
                                         style={{width: "100%"}}
                                    />
                                </div>
                                <div style={{marginBottom: "16px"}}>
                                    <p style={{fontSize: "16px"}}>2. Remove account from API Spreadsheets</p>
                                    <div>
                                        <RemoveDropboxAccount
                                            accountID={this.props.accountID}
                                            removeAccountInfo={this.props.removeAccountInfo}
                                            email={this.props.email}
                                            list
                                        />
                                    </div>
                                </div>
                                <div style={{marginBottom: "16px"}}>
                                    <p style={{fontSize: "16px"}}>3. Upload a Dropbox file from the account you wish to reconnect</p>
                                    <div>
                                        <Button style={{backgroundColor: "navy"}}><a href="/upload" style={{textDecoration: "none", color: "white"}}><Icon name="upload" /> Upload a File</a></Button>
                                    </div>
                                </div>
                            </div> : null
                    }
                </Segment>
            </div>
        )
    }
}


export default DropboxAccountRow