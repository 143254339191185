import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Input, Segment, Message } from "semantic-ui-react";
import QuickstartTableInput from "./QuickstartTableInput";
import ReactJson from 'react-json-view'
import CreateQuickstartButton from "./CreateQuickstartButton"
import Loader from 'react-loader-spinner'
import API_Root from "../Constants/API_Root";
import EditQuickstartButton from "./EditQuickstartButton"
import BannerAlert from "../SharedComponents/BannerAlert"
import DisplayHeader from "../FileDnD/FilesID/DisplayHeader";
import {modifyShowingMinorTutorials} from "../Actions/ModifyShowingMinorTutorials";

class QuickstartUploadModalBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            desc:'',
            rows:[{key:'',type:'string',value:'', error: true, duplicate: false}],
            fetching: this.getFetchingState(),
            isEdit: false,
            originalRows: null,
            message: this.initMessage()
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initMessage = () => {
        let message = <BannerAlert
            icon="bx bx-question-mark"
            header="You can only READ 1 JSON object from a Quickstart API"
            content="For all other actions, upload a spreadsheet"
            action="close"
            type="display"
            close={this.dismissMessage}
        />

        if ("quickstartUpload" in this.props.showingMinorTutorials){
            if (!this.props.showingMinorTutorials.quickstartUpload){
                message = null
            }
        }

        return message
    }

    dismissMessage = () =>
    {
        this.setState({ message: null });

        let updatedShowingMinorTutorials = JSON.parse(JSON.stringify(this.props.showingMinorTutorials));
        updatedShowingMinorTutorials.quickstartUpload = false;
        this.props.modifyShowingMinorTutorials(updatedShowingMinorTutorials);

        fetch(API_Root + 'api/unsubscribe-from-tutorial/', {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                minorTutorial: 'quickstartUpload'
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then().catch()

    }

    getFetchingState = () => {
        let fetching = false;

        if (this.props.modalInfo !== null){
            if (this.props.modalInfo.constructor === Object){
                if ("editQuickstart" in this.props.modalInfo){
                    fetching = this.props.modalInfo.editQuickstart
                }
            }
        }

        return fetching
    }

    componentDidMount(){
        if (this.state.fetching){
            const error = () => this.setState({
                fetching: false
            }, () => alert("There was a problem getting your values. Please try again!"))

            fetch(API_Root + "api/get-quickstart-api-rows/" + this.props.modalInfo.fileHash + "/")
                .then(res => {
                    if (res.status === 200){
                        res.json().then(data => {
                            this.setState({
                                name: data.name,
                                desc: data.desc,
                                rows: data.rows,
                                isEdit: true,
                                fetching: false,
                                originalRows: JSON.parse(JSON.stringify(data.rows))
                            })
                        }).catch(err=> error())
                    } else { error() }
                }).catch(err => error())
        }
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    changeState=(newState)=> this.setState(newState)

    getJSONOutput = (rows) => {
        let jsonOutput = {};

        for (let i=0; i<rows.length; i++){
            const rowInfo = rows[i];

            if (!rowInfo.error){
                let value = rowInfo.value;
                if (rowInfo.type === "number"){
                    value = Number(value)
                } else if (rowInfo.type === "null"){
                    value = null
                }

                jsonOutput[rowInfo.key] = value
            }
        }

        return jsonOutput
    }

    render() {
        if (this.state.fetching){
            return (
                <div style={{padding: '100px 0', textAlign: 'center'}}>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4>Setting up your quickstart values...</h4>
                </div>
            )
        } else {
            const jsonOutput = this.getJSONOutput(this.state.rows);

            return (
                <div className="filesIDDisplayBox">
                    {this.state.message}
                    {
                        this.state.isEdit ? null :
                            <div className="row">
                                <div className="col-sm-7">
                                    <DisplayHeader header="Create a Quickstart API"/>
                                    <div style={{marginBottom: "16px"}}>
                                        <div style={{fontFamily: "Lato", fontSize: '14px', color: "#5E645E", margin: "0 0 6px 0"}}>API Name</div>
                                        <input value={this.state.name}
                                               placeholder="Blog Details API"
                                               name="name"
                                               onChange={this.handleInputChange}
                                               style={{width: "100%"}}
                                               className="inputGray"
                                        />
                                    </div>
                                    <div style={{marginBottom: "16px"}}>
                                        <div style={{fontFamily: "Lato", fontSize: '14px', color: "#5E645E", margin: "0 0 6px 0"}}>API Description</div>
                                        <input value={this.state.desc}
                                               placeholder="Information about my recent blog"
                                               name="desc"
                                               onChange={this.handleInputChange}
                                               style={{width: "100%"}}
                                               className="inputGray"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                </div>
                            </div>
                    }
                    <div className="filesIDDisplayBox">
                        <div className="row">
                            <div className="col-sm-7">
                                <DisplayHeader header={this.state.isEdit ? "Edit values for your Quickstart API" : "Enter values for your Quickstart API"}/>
                                <QuickstartTableInput changeState={this.changeState}
                                                      allKeys={this.state.rows.map(x => x.key)}
                                                      rows={this.state.rows}/>
                                <div style={{marginTop: "24px", marginBottom: "24px"}}>
                                    {this.state.isEdit ?
                                        <EditQuickstartButton pk={this.props.modalInfo.pk}
                                                                rows={this.state.rows}
                                                              jsonOutput={jsonOutput}
                                                              originalOutput={this.getJSONOutput(this.state.originalRows)}
                                                              pkID={this.props.modalInfo.pkID} /> :
                                        <CreateQuickstartButton info={this.state} jsonOutput={jsonOutput}/>}
                                    {this.state.name.trim() === "" ? <small style={{color: "red", display: "block", marginBottom: "10px"}}>Please enter an API Name </small> : null}
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className="jsonDisplayBoxHeader">
                                    <DisplayHeader header="JSON Response" />
                                </div>
                                <ReactJson src={jsonOutput} name={null} theme="ocean" displayObjectSize={false}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo,
    showingMinorTutorials: state.mainState.showingMinorTutorials
})

const mapActionsToProps = {
    modifyShowingMinorTutorials: modifyShowingMinorTutorials
}

export default connect(mapStateToProps, mapActionsToProps)(QuickstartUploadModalBody)