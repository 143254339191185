import React, {Component} from 'react'
import {connect} from 'react-redux'
import {capitalize} from "../../Constants/capitalize";
import { Icon } from 'semantic-ui-react'
import {modifyAPIMenuItem} from "../../Actions/ModifyAPIMenuItem";

class APIIndexMenuItem extends Component {
    constructor(props) {
        super(props);
    }

    modifyMenuItem = () => this.props.modifyAPIMenuItem(this.props.name)

    render() {
        let cName;

        if ('common' in this.props){
            cName = "commonIndexMenuItem"
        } else {
            cName = "apiIndexMenuItem"
        }

        if (this.props.name === this.props.apiMenuItem){
            if ('common' in this.props){
                cName += " commonIndexMenuItemSelected";
            } else {
                cName += " apiIndexMenuItemSelected";
            }
        }

        return (
            <div className={cName} onClick={this.modifyMenuItem}>
                <Icon name={this.props.icon} />
                <p>{capitalize(this.props.name)}</p>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    apiMenuItem: state.mainState.apiMenuItem
})

const mapActionsToProps = {
    modifyAPIMenuItem: modifyAPIMenuItem
}

export default connect(mapStateToProps, mapActionsToProps)(APIIndexMenuItem)