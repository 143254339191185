import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Table } from 'semantic-ui-react'
import ColumnRow from "./ColumnRow"
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import {getColumnAddlInfo, getColumnInfo} from "./getColumnInfo";
import InfoPopup from "../../../../SharedComponents/InfoPopup"

class ColumnTable extends Component {
    addColumnRow = () => {
        const columns = JSON.parse(JSON.stringify(this.props.customImportColumns))
        const columnsAddlInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))

        columns.push(getColumnInfo())
        columnsAddlInfo.push(getColumnAddlInfo())

        this.props.modifyCustomImportSettings("columns", columns)
        this.props.modifyCustomImportSettings("columnsAddlInfo", columnsAddlInfo)
    }

    render() {
        const columnNames = this.props.customImportColumnsAddlInfo.map((x) => x.dupeCheckName.trim())
        const internalColumnNames = this.props.customImportColumnsAddlInfo.map((x) => x.dupeCheckInternalName.trim())

        return (
            <div>
                {/*<Table singleline compact celled>*/}
                    {/*<Table.Header>*/}
                        {/*<Table.Row>*/}
                            {/*<Table.HeaderCell width={4}>Display Name <InfoPopup style={{cursor: "pointer" }} info="Name of the column that the user will see while matching"/></Table.HeaderCell>*/}
                            {/*<Table.HeaderCell width={5}>Internal Name <InfoPopup style={{cursor: "pointer" }} info="Name of the column in the data after matching "/></Table.HeaderCell>*/}
                            {/*<Table.HeaderCell width={1}>Required?</Table.HeaderCell>*/}
                            {/*<Table.HeaderCell width={2}>Type <InfoPopup style={{cursor: "pointer" }} info={<span>Type of the data. If you <b>Convert?</b> the data, and the data conversion fails, the value becomes <code>null</code></span>}/></Table.HeaderCell>*/}
                            {/*<Table.HeaderCell width={1}>Convert? </Table.HeaderCell>*/}
                            {/*<Table.HeaderCell width={1}>Info</Table.HeaderCell>*/}
                            {/*<Table.HeaderCell width={2}> </Table.HeaderCell>*/}
                        {/*</Table.Row>*/}
                    {/*</Table.Header>*/}
                    {/*<Table.Body>*/}
                        {
                            this.props.customImportColumnsAddlInfo.length > 0 ?
                                this.props.customImportColumnsAddlInfo.map((x, idx) =>
                                    <ColumnRow
                                        key={idx}
                                        columnNames={columnNames}
                                        internalColumnNames={internalColumnNames}
                                        idx={idx}
                                        info={x} />) : null
                        }
                    {/*</Table.Body>*/}
                    {/*<Table.Footer>*/}
                        {/*<Table.Row>*/}
                            {/*<Table.HeaderCell colSpan={16}>*/}
                                <div>
                                    <Button style={{backgroundColor: "#0081ff", color: "white", borderRadius: "25px", cursor: "pointer"}}
                                            content="Add Column" size="mini" icon="plus" onClick={this.addColumnRow}/>
                                </div>
                            {/*</Table.HeaderCell>*/}
                        {/*</Table.Row>*/}
                    {/*</Table.Footer>*/}
                {/*</Table>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    customImportColumns: state.mainState.customImportColumns,
    customImportColumnsAddlInfo: state.mainState.customImportColumnsAddlInfo
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnTable)