import React, {Component} from 'react'
import ReactJson from 'react-json-view'

class ColumnNamesValid extends Component {
    render() {
        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">We automatically read the first row in your File as Column Names</p>
                    <img src="https://images2.imgbox.com/d6/3f/CWvO821o_o.png" alt="Column Names in File" style={{marginBottom: '50px'}}/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">The Column Names must be Unique</p>
                    <img src="https://images2.imgbox.com/37/04/tmiAYZoM_o.png" alt="Column Names Unique vs. Duplicate" style={{marginBottom: '50px'}}/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">If you have duplicate Column Names, you will get an error when you upload your File</p>
                    <img  style={{width:'100%',marginBottom: '50px'}} src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ColumnNamesInvalid.png" alt="Error dashboard" />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">This error will remain until you fix your Column Names</p>
                    <ol style={{marginBottom: '50px'}}>
                        <li>You can fix in your file then Replace or Refresh</li>
                        <li>You can <span className="linkStyle" onClick={e => this.props.setBothMenuItems("upload-reread")}>specify if there are no Column Names OR they are on another line</span></li>
                    </ol>
                </div>
                <hr/>
                <div className="docsSection">
                    <p className="docsHeader">If you change your Column Names in your Google Sheets or DropBox file AFTER successfully uploading</p>
                    <p>AND the change turns out to create duplicate Column Names</p>
                    <p style={{marginBottom: '25px'}}>You will get a <code>400</code> Error Code on any subsequent API Requests until you fix the duplicate names</p>
                    <ReactJson src={{
                        "error": "Your column names are not valid. Read our column name guide: https://www.apispreadsheets.com/api-docs/?docsMenuItem=upload-columnNames. Fix and try again."}} name={null} theme="ocean" displayObjectSize={false}/>
                </div>
            </div>
        )
    }
}

export default ColumnNamesValid