function copyObject(a, b){
    let copiedObject = JSON.parse(JSON.stringify(a));

    for (let key in b){
        copiedObject[key] = b[key]
    }

    return copiedObject
}

export default copyObject