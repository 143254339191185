import React, {Component} from 'react'

class DocsImage extends Component {
    render() {
        const imgStyle = {border:"1px solid #F0F0F0", display: "block", marginBottom: "16px"}

        if ('width' in this.props){
            imgStyle['width'] = this.props.width
        }

        return (
            <img src={this.props.src} alt={this.props.alt} style={imgStyle} />
        )
    }
}

export default DocsImage