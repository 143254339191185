export const getFullAPIUrl = (baseUrl, mode, token=null) => {
    let param = ""
    if (mode === "ai"){
         param = "ai"
    } else if (mode === "download"){
        param = "download-file"
    } else if (mode === "create"){
        param = "create-file"
    }

    return baseUrl + param + "/" + (token === null ? localStorage.getItem("token") : token) + "/"
}