import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'

class FileOptions extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const canTakeAction = 'canTakeAction' in this.props ? this.props.canTakeAction : true;

        return (
            <div>
                <div>
                    {canTakeAction ? this.props.removeFile : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FileOptions)