import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import {toggleToast} from "../../Actions/ToggleToast";
import API_Root from "../../Constants/API_Root";

class DeleteCachingLayerBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupOpen: false,
            deleting: false
        }
    }

    togglePopup = (e, toggle) => {
        this.setState({
            popupOpen: toggle
        })
    }

    deleteLayer = (e) => {
        this.togglePopup(e, false);

        const isError = () => this.setState({ deleting: false}, () => this.props.toggleToast({show: true, message: "There was an error deleting your caching layer. Try again or contact our support if this error persists", type: "error"}));

        fetch(API_Root + "api/delete-caching-layer/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                cacheId: this.props.cacheId
            })
        }).then(res => {
            if (res.status === 200){
                this.props.resetState()
                this.setState({ deleting: false })
                this.props.toggleToast({show: true, message: "Caching layer successfully deleted!", type: "success"});
            } else {
                isError()
            }
        }).catch(err => isError())
    }

    render() {
        return (
            <div>
                <Popup
                    trigger={
                        <Button
                            style={{backgroundColor: gunaldiStyles.red, color: "white"}}
                            loading={this.state.deleting}
                            content="Delete Caching Layer"
                            icon="trash alternate outline"
                            onClick={(e) => this.togglePopup(e, true)}/>}
                    on="click"
                    size="small"
                    position="bottom"
                    // onOpen={(e) => this.togglePopup(e, true)}
                    onClose={(e) => this.togglePopup(e, false)}
                    open={this.state.popupOpen}>
                    <div style={{padding: '16px'}}>
                        <div style={{color: gunaldiStyles.red, fontSize: "14px", marginBottom: "8px"}}>
                            Are you sure you want to delete this caching layer? It will immediately stop working.
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <Button color="green" content="No" onClick={(e) => this.togglePopup(e, false)} fluid />
                            </div>
                            <div className="col-sm-6">
                                <Button color="red" loading={this.state.deleting} content="Yes" onClick={this.deleteLayer} fluid />
                            </div>
                        </div>
                    </div>
                </Popup>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(DeleteCachingLayerBtn)