import React, {Component} from 'react'
import ModalApp from "../SharedComponents/Modal";
import { Icon } from 'semantic-ui-react'
import AddNewS3ModalBody from "./AddNewS3ModalBody"

class AddNewS3Modal extends Component {
    render() {
        return (
            <ModalApp name="addNewS3Modal"
                      header={<h4><Icon name="plus" /> <Icon name="aws" /> Add New AWS S3 Account</h4>}
                      body={<AddNewS3ModalBody />}
                      size="large"
            />
        )
    }
}

export default AddNewS3Modal