import React, {Component} from 'react'
import {connect} from 'react-redux'
import Loader from 'react-loader-spinner';
import API_Root from "../../Constants/API_Root";
import UploadHistoryTable from "./UploadHistoryTable"
import { Table } from 'semantic-ui-react'

class UploadHistoryIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: true,
            data: null
        }
    }

    componentDidMount(){
        const error = (e) => {
            console.log(e);

            this.setState({
                fetching: false
            })
        }

        fetch(API_Root + "api/get-upload-history-for-api-file/" + this.props.pk.toString() +"/")
            .then(res => {
                if (res.status === 200){
                    res.json().then(data => {
                        this.setState({
                            data: data.uploadHistory,
                            fetching: false
                        })
                    }).catch(err => error(err))
                } else {
                    error(res.status)
                }
            }).catch(err => error(err))
    }

    render() {
        if (this.state.fetching){
            return (
                <div>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4>Getting your upload history...</h4>
                </div>
            )
        } else if (this.state.data === null){
            return (
                <div>
                    You do not have any history of uploads
                </div>
            )
        } else {
            if (this.state.data.length === 0){
                return(
                    <div>
                        You do not have any history of uploads
                    </div>
                )

            } else {
                return (
                    <div>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Previous File Name</Table.HeaderCell>
                                    <Table.HeaderCell>Uploaded Date</Table.HeaderCell>
                                    <Table.HeaderCell>Click to replace file</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    this.state.data.map((x, idx) => <UploadHistoryTable info={x} key={idx} pk={this.props.pk} pkID={this.props.pkID}/>)
                                }
                            </Table.Body>
                        </Table>
                    </div>
                )
            }
        }

    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UploadHistoryIndex)