import React, {Component} from 'react'
import { Segment, Icon } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import images from "../../../Constants/Images";

class EmailSample extends Component {
    render() {
        return (
            <Segment>
                <div className="row">
                    <div className="col-sm-10">
                        <span style={{marginLeft: "30px"}}>
                            <span style={{fontWeight: "300", fontSize: "24px", marginTop: "10px"}}>Daily Report of Orders</span>
                            {/*<span style={{marginLeft: "10px"}}>*/}
                                {/*<small style={{backgroundColor: "rgba(245, 179, 62)", fontWeight: "bold", borderRadius: "24px", padding: "5px 10px 5px 10px"}}>External</small>*/}
                            {/*</span>*/}
                        </span>
                    </div>
                    <div className="col-sm-2">
                        <div style={{textAlign: "right", marginTop: "5px"}}>
                            <span style={{marginRight: "10px"}}><Icon name="print" /></span>
                            <span><Icon name="expand arrows alternate" /></span>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-7">
                        <Image src={images.imageIcon} avatar/>
                        <span><b>Rosa Diaz</b> info@apispreadsheets.com</span>
                    </div>
                    <div className="col-sm-5">
                        <div style={{textAlign: "right"}}>
                            <small><Icon name="paperclip" /> Oct 26, 2021, 11:48 AM <Icon name="star outline" /> <Icon name="reply" /></small>
                        </div>
                    </div>
                </div>
                <div style={{marginLeft: "30px"}}>
                    <span className="text-muted">to Your Email Account <b><Icon name="dropdown" /></b></span>
                </div>
                <div style={{marginLeft: "30px", marginTop: "30px"}}>
                    <p>Hi Team,</p>
                    <br/>
                    <p>See the daily spreadsheet of Orders attached. Let me know if you have any questions.</p>
                    <br/>
                    <p>Thanks,</p>
                    <p>Rosa Diaz</p>
                    <br/><br/>
                    <Icon name="file excel outline" style={{color: "green", fontSize: "30px"}} />
                    <b>Orders.xlsx</b>
                </div>
                <br/><br/><br/>
            </Segment>
        )
    }
}

export default EmailSample