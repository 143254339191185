import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import getURLParam from "../../../../Constants/getURLParam";
import API_Root from "../../../../Constants/API_Root";
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import {toggleToast} from "../../../../Actions/ToggleToast";

class ColumnRowSaveBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false
        }
    }

    saveColumnRow = () => {
        this.setState({ saving: true })

        const columnRowInfo = this.getColumnRowInfo()

        const splitParam = getURLParam(window.location.href).split("_")
        const importKey = splitParam[0];

        const error = () => this.setState({ saving: false }, () => this.props.toggleToast({show: true, message: "There was an error saving your column information", type: "error"}))
        fetch(API_Root + "api/update-import-column-info/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                importHash: importKey,
                columnInfo: columnRowInfo,
                idx: this.props.idx
            })
        }).then((res) => {
            if (res.status === 200) {
                this.updateColumnInfoInRedux(columnRowInfo)
                this.setState({ saving: false }, () => this.props.toggleToast({show: true, message: "Column Information Saved!", type: "success"}))
            }
            else {
                error()
            }
        }).catch(err => error())

    }

    getColumnRowInfo = () => {
        const columnRowInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo[this.props.idx]))

        const deleteKeys = ["saved" , "dupeCheckName", "dupeCheckInternalName", "error"]

        for (let i=0; i<deleteKeys.length; i++){
            delete columnRowInfo[deleteKeys[i]]
        }


        return columnRowInfo
    }

    updateColumnInfoInRedux = (columnInfo) => {
        let allCustomImportColumns = JSON.parse(JSON.stringify(this.props.customImportColumns))
        allCustomImportColumns[this.props.idx] = columnInfo

        this.props.modifyCustomImportSettings("columns", allCustomImportColumns)

        const columnRowInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))
        const colAddlInfo = columnRowInfo[this.props.idx]
        colAddlInfo.saved = true

        columnRowInfo[this.props.idx] = colAddlInfo

        this.props.modifyCustomImportSettings("columnsAddlInfo", columnRowInfo)
    }

    render() {
        return (
            <Button
                    loading={this.state.saving}
                    disabled={!this.props.hasInfoChanged || this.props.error}
                    onClick={this.saveColumnRow}
                    icon="save alternate outline"
                    content={"Save Column " + (this.props.idx + 1).toString()}
                    style={{backgroundColor: gunaldiStyles.mainGreen, color: "white"}}
                    // color="green"
                    // basic
                    size="mini"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    customImportColumnsAddlInfo: state.mainState.customImportColumnsAddlInfo,
    customImportColumns: state.mainState.customImportColumns
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnRowSaveBtn)