import React, {Component} from 'react'
import {connect} from 'react-redux'
import TeamMemberTable from "./TeamMemberTable"
import { Menu } from 'semantic-ui-react'
import Icons from "../../Constants/Icons";
import PendingInvitesTable from "../TeamInfo/PendingInvitesTable"

class MemberDisplayIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuItem: "memberList"
        }
    }

    changeMenuItem = (e, { name }) => this.setState({ menuItem: name })

    goToPending = () => this.setState({ menuItem: "pendingInvitations" })

    render() {
        const toolbarSelectedStyle = {color: "#6FBB98", fontSize: "14px"}
        const originalToolbarStyle = {color: "#9696A0", fontSize: "14px"}

        const displayMap = {
            "memberList": <TeamMemberTable teamIdx={this.props.modalInfo.teamIdx} goToPending={this.goToPending}/>,
            "pendingInvitations": <PendingInvitesTable teamIdx={this.props.modalInfo.teamIdx} />
        }

        let display = null;
        if (this.state.menuItem in displayMap){
            display = displayMap[this.state.menuItem]
        }

        return (
            <div>
                <Menu pointing secondary>
                    <Menu.Item
                        name="memberList"
                        icon="users"
                        active={this.state.menuItem === "memberList"}
                        style={this.state.menuItem === "memberList" ? toolbarSelectedStyle : originalToolbarStyle}
                        onClick={this.changeMenuItem} />
                    <Menu.Item
                        name="pendingInvitations"
                        icon="envelope open outline"
                        active={this.state.menuItem === "pendingInvitations"}
                        style={this.state.menuItem === "pendingInvitations" ? toolbarSelectedStyle : originalToolbarStyle}
                        onClick={this.changeMenuItem} />
                </Menu>
                <div className="filesIDDisplayBox">
                    {display}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(MemberDisplayIndex)