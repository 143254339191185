export const INIT_ALL_CUSTOM_IMPORT_SETTINGS = 'INIT_ALL_CUSTOM_IMPORT_SETTINGS';

export function initAllCustomImportSettings(importSettings){
    return{
        type: INIT_ALL_CUSTOM_IMPORT_SETTINGS,
        mainState: {
            customImportInfo: importSettings.info,
            customImportColumns: importSettings.columns,
            customImportAppearance: importSettings.appearance,
            customImportDestinations: importSettings.destinations,
            customImportOptions: importSettings.importOptions,
            customImportColumnsAddlInfo: importSettings.customImportColumnsAddlInfo
        }
    }
}