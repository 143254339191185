import React, {Component} from 'react'
import ModalApp from "../../SharedComponents/Modal"
import KeysModalBody from "./KeysModalBody"
import CustomKeysModal from "./CustomKeysModal"
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'

class KeysModal extends Component {
    render() {
        let body = null;

        if (localStorage.getItem("email") !== null){
            if (
                // this.props.tier === "team" ||
                this.props.tier === "business" || this.props.tier === "enterprise"

            ){
                body = <CustomKeysModal />
            } else{
                body = <KeysModalBody/>
            }
        }

        return (
            <ModalApp name="keysModal"
                      header={<h2><Icon name="key" /> Your Access & Secret Keys </h2>}
                      size="medium"
                      body={body}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

export default connect(mapStateToProps, null)(KeysModal)