import React, {Component} from 'react'
import {connect} from 'react-redux'
import BannerMessages from "./BannerMessages";
import APIIndexMenu from "./APIIndexMenu";
import FilesIndex from "../../FileDnD/FilesIndex"
import UpdatedAppIndex from "../UpdatedAppIndex"
import {getQueryParamValue} from "../../Constants/getQueryParamValue";
import {modifyAPIMenuItem} from "../../Actions/ModifyAPIMenuItem";
import CNAMEIndex from "../../InfoPages/Profile/CNAME/CNAMEIndex"
import PageHeader from "../../SharedComponents/PageHeader";
import DocsIndex from "../Docs/DocsIndex"
import { apiDocsInfo } from "../Docs/apiDocsInfo";
import DatabaseIndex from "../../Database/DatabaseIndex"
import DemoVideoIndex from "../../InfoPages/DemoVideo/DemoVideoIndex";
import APIDemoVideoLinks from "./APIDemoVideoLinks";

class APIIndex extends Component {
    componentDidMount(){
        const menuItem = getQueryParamValue("menuItem", window.location.href)

        if (menuItem !== ""){
            this.props.modifyAPIMenuItem(menuItem)
        }
    }

    getDisplay = () => {
        return {
            "upload": <UpdatedAppIndex />,
            "files": <FilesIndex apiIndex />,
            "connect domain": <div>
                <PageHeader
                    header="Connect Your Domain"
                    desc="Use your own domain for API requests"
                    common
                    tiny
                />
                <div style={{padding: "30px 100px 160px 30px"}}>
                    <CNAMEIndex />
                </div>
            </div>,
            "demos": <DemoVideoIndex
                videos={APIDemoVideoLinks}
            />,
            "docs": <DocsIndex info={apiDocsInfo} page="api"/>,
            "database": <DatabaseIndex />
        }[this.props.apiMenuItem]
    }

    render() {
        return (
            <div style={{minHeight: '100vh'}}>
                <div style={{display: "grid", gridTemplateColumns: "100px 1fr"}}>
                    <div>
                        <APIIndexMenu />
                    </div>
                    <div>
                        <BannerMessages/>
                        <div>
                            {this.getDisplay()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    apiMenuItem: state.mainState.apiMenuItem
})

const mapActionsToProps = {
    modifyAPIMenuItem: modifyAPIMenuItem
}

export default connect(mapStateToProps, mapActionsToProps)(APIIndex)