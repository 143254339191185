import React, {Component} from 'react'
import API_Root from "../../Constants/API_Root";
import { Checkbox } from 'semantic-ui-react';
import Loader from 'react-loader-spinner'
import CustomAccordion from "../../SharedComponents/CustomAccordion"
import NoFilesUploaded from "../../FileDnD/NoFilesUploaded";
import TeamFilesRow from "../../Teams/TeamInfo/TeamFilesRow";
import DisplayHeader from "../../FileDnD/FilesID/DisplayHeader";

class ImportConfigDeleteModalBodyFileNames extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileNames: [],
            fileInfoList: [],
            fetching: true
        }
    }

    toggleDeleteFiles = () => this.props.toggleDeleteFiles(!this.props.deleteFiles)

    componentDidMount(){
        const stopFetch = () => this.setState({ fetching: false })

        fetch(API_Root + "api/get-all-files-for-import-details/", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify(
                {
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    importHash: this.props.importHash,
                    utcOffset: new Date().getTimezoneOffset()
                }
            )
        }).then((res) => {
            if (res.status === 200){
                res.json().then(data => {
                    this.setState({
                        fetching: false,
                        fileInfoList: data.fileInfoList
                    })
                }).catch(err => stopFetch())
            } else {
                stopFetch()
            }
        }).catch((err) => stopFetch())
    }

    render() {
        if (this.state.fetching){
            return (
                <Loader
                    type="TailSpin"
                    color="black"
                    height="20"
                    width="20"
                />
            )
        } else {
            const isCustom = 'custom' in this.props;

            if (this.state.fileInfoList.length > 0){
                return (
                    <div className="displayBox" style={{marginBottom: "16px"}}>
                        {/*{*/}
                            {/*isCustom ? null :*/}
                                {/*<Checkbox toggle label={<label style={{fontWeight: "bold"}}>Delete all files imported with these import settings?</label>}*/}
                                          {/*checked={this.props.deleteFiles}*/}
                                          {/*onChange={this.toggleDeleteFiles}*/}
                                {/*/>*/}
                        {/*}*/}
                        <div style={{marginBottom: "16px"}}>
                            <DisplayHeader header="Files Imported"/>
                        </div>
                        {this.state.fileInfoList.map((x, idx) =>
                                <TeamFilesRow hash={this.props.importHash} prevPage="import" fileInformation={x} key={idx}/>
                            )}
                    </div>
                )
            } else {
                if (isCustom){
                    return (
                        <div className="displayBox" style={{marginBottom: "16px"}}>
                            <NoFilesUploaded style={{paddingTop: "2%"}} isImporter/>
                        </div>
                    )
                } else {
                    return null
                }
            }
        }
    }
}

export default ImportConfigDeleteModalBodyFileNames