import React, {Component} from 'react'
import {connect} from 'react-redux'

class ChooseS3Bucket extends Component {
    modifyBucket = (bucketName) => {
        this.props.getFiles(bucketName, "")
    }

    getBucketOptions = () => this.props.bucketNames.map((x, idx) =>
        <div className="s3BucketSelectionRow" onClick={e => this.modifyBucket(x)}>
            <span>{x}</span>
        </div>
    )

    render() {
        return (
            <div>
                <h4>Select Your Bucket</h4>
                {this.getBucketOptions()}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ChooseS3Bucket)