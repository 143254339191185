import React, {Component} from 'react'
import Icons from "../Constants/Icons";
import { Menu, Segment} from 'semantic-ui-react';

class CRUDExampleTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayType: 'Create'
        }
    }

    handleItemClick = (e, { name }) => {
        this.setState({
            displayType: name
        })
    }

    render() {
        const activeItem = this.state.displayType;

        const display = {
            Create: this.props.createDisplay,
            Read: this.props.readDisplay,
            Update: this.props.updateDisplay,
            Delete: this.props.deleteDisplay
        }


        return (
            <div>
                <Menu attached='top' tabular>
                    <Menu.Item
                        icon={Icons.createAction}
                        name='Create'
                        active={activeItem === 'Create'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        icon={Icons.readAction}
                        name='Read'
                        active={activeItem === 'Read'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        icon={Icons.updateAction}
                        name='Update'
                        active={activeItem === 'Update'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        icon={Icons.deleteAction}
                        name='Delete'
                        active={activeItem === 'Delete'}
                        onClick={this.handleItemClick}
                    />
                </Menu>
                <Segment attached='bottom'>
                    {display[this.state.displayType]}
                </Segment>
            </div>
        )
    }
}

export default CRUDExampleTable