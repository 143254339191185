import React, {Component} from 'react'
import { Grid, Menu, Icon } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import GoogleAccountsIndex from "../../SharedComponents/GoogleAccounts/GoogleAccountsIndex";
import PlanIndex from "./PlanIndex"
import GeneralIndex from "./GeneralIndex"
import FailedPaymentMessage from "../../SharedComponents/FailedPaymentMessage";
import EmailVerificationMessage from "../../SharedComponents/EmailVerificationMessage";
import S3AccountsIndex from "./S3AccountsIndex"
import DropboxAccountsIndex from "./DropboxAccountsIndex";
import PageHeader from "../../SharedComponents/PageHeader";
import MenuSegment from "../../FileDnD/FilesID/FilesIDDisplayMenu/MenuSegment"
import CNAMEIndex from "../Profile/CNAME/CNAMEIndex"

const menuItems = [
    {name: "plan", icon: "bx bx-credit-card", label: "Plan"},
    {name: "general", icon: "bx bx-cog", label: "General"},
    {name: "googleAccounts", icon: "bx bxl-google", label: "Google Accounts"},
    {name: "awsS3Accounts", icon: "bx bxl-aws", label: "AWS Accounts"},
    {name: "dropboxAccounts", icon: "bx bxl-dropbox", label: "Dropbox Accounts"},
    {name: "cnameIntegration", icon: "bx bx-window-alt", label: "Connect Your Domain"}
]

class ProfileIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: menuItems[0].name,
            dateJoined: "",
            datePro: "",
            pricingPlan: "",
            appsumoPlanName: ""
        }
    }

    componentDidMount(){
        const current = new Date();
        const utcOffset = current.getTimezoneOffset();

        const errorSetState = () => this.setState({
            dateJoined: '2020-01-01 00:00:00',
            datePro: '',
            pricingPlan: 'free'
        })

        fetch(API_Root + "api/get-profile-information/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                utcOffset: utcOffset,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token")
            })
        }).then(res =>
        {
            if (res.status === 200){
                res.json().then((data) => {
                    this.setState({
                        dateJoined: data.dateJoined,
                        datePro: data.datePro,
                        pricingPlan: data.pricingPlan,
                        appsumoPlanName: data.appsumoPlanName
                    })
                })
                    .catch(err => console.log(err))
            }
            else{
                errorSetState()
            }
        }).catch(err => errorSetState())
    }

    handleItemClick = (e, name) => {
        this.setState({
            activeItem: name
        })
    }

    render() {
        const display = {
            general: <GeneralIndex name={menuItems[1].name}
                                   icon={menuItems[1].icon}
                                   pricingPlan={this.state.pricingPlan}
                                   dateJoined={this.state.dateJoined} />,
            plan: <PlanIndex name={menuItems[0].name} icon={menuItems[0].icon}
                             appsumoPlanName={this.state.appsumoPlanName}
                titleInfo={menuItems[0]} pricingPlan={this.state.pricingPlan} datePro={this.state.datePro} />,
            googleAccounts: <GoogleAccountsIndex name={menuItems[2].name}
                                                 icon={menuItems[2].icon}
                                                 titleInfo={menuItems[2]} />,
            awsS3Accounts: <S3AccountsIndex name={menuItems[3].name}
                                            icon={menuItems[3].icon} />,
            dropboxAccounts: <DropboxAccountsIndex name={menuItems[4].name}
                                                   icon={menuItems[4].icon} />,
            cnameIntegration: <CNAMEIndex />
        }[this.state.activeItem];

        return (
            <div style={{minHeight: '100vh'}}>
                <EmailVerificationMessage/>
                <FailedPaymentMessage/>
                <PageHeader
                    header={"Profile - " + localStorage.getItem("email").toString()}
                    desc="Manage your payment plan, and your account settings"
                />
                <div style={{margin: "24px 100px 250px 60px"}}>
                    <div className="filesIDDisplayIndex">
                        <div className="filesIDDisplayMenu">
                            <MenuSegment label={menuItems[0].label}
                                         icon={menuItems[0].icon}
                                         name={menuItems[0].name}
                                         menuItem={this.state.activeItem}
                                         onClick={this.handleItemClick} />
                            <MenuSegment label={menuItems[1].label}
                                         icon={menuItems[1].icon}
                                         name={menuItems[1].name}
                                         menuItem={this.state.activeItem}
                                         onClick={this.handleItemClick} />
                            <MenuSegment label={menuItems[2].label}
                                         icon={menuItems[2].icon}
                                         name={menuItems[2].name}
                                         menuItem={this.state.activeItem}
                                         onClick={this.handleItemClick} />
                            <MenuSegment label={menuItems[3].label}
                                         icon={menuItems[3].icon}
                                         name={menuItems[3].name}
                                         menuItem={this.state.activeItem}
                                         onClick={this.handleItemClick} />
                            <MenuSegment label={menuItems[4].label}
                                         icon={menuItems[4].icon}
                                         name={menuItems[4].name}
                                         menuItem={this.state.activeItem}
                                         onClick={this.handleItemClick} />
                            <MenuSegment label={menuItems[5].label}
                                         icon={menuItems[5].icon}
                                         name={menuItems[5].name}
                                         menuItem={this.state.activeItem}
                                         onClick={this.handleItemClick} isNew />

                        </div>
                        <div className="filesIDDisplay">
                            {display}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileIndex