import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Checkbox, Button, Popup } from 'semantic-ui-react'
import {capitalize} from "../../Constants/capitalize";
import SaveWebhookEvents from "./SaveWebhookEvents"

const eventTypes = ["create", "read", "update", "delete"]

class EventSelectionRow extends Component {
    constructor(props) {
        super(props);

        const webhookInfo = this.getWebhookInfo()

        let checkedValues = {
            createChecked: false,
            readChecked: false,
            updateChecked: false,
            deleteChecked: false
        }

        for (let i=0; i<eventTypes.length; i++){
            const checkedKey = eventTypes[i] + "Checked"

            if (checkedKey in webhookInfo){
                checkedValues[checkedKey] = webhookInfo[checkedKey]
            }
        }


        this.state = {
            createChecked: checkedValues.createChecked,
            readChecked: checkedValues.readChecked,
            updateChecked: checkedValues.updateChecked,
            deleteChecked: checkedValues.deleteChecked
        }
    }

    toggle = (eventType) => {
        const currChecked = this.state[eventType + "Checked"];

        this.setState({ [eventType + "Checked"]: !currChecked })
    }

    areStatesDifferent = () => {
        let different = false;

        const webhookInfo = this.getWebhookInfo()

        for (let key in this.state){
            if (key in webhookInfo){
                if (webhookInfo[key] !== this.state[key]){
                    different = true;
                    break
                }
            } else {
                if (this.state[key] === true){
                    different = true;
                    break
                }
            }
        }

        return different
    }

    getWebhookInfo = () => {
        return JSON.parse(JSON.stringify(this.props.fileInformation[this.props.editID].webhookInfo))
    }

    reset = () => {
        const webhookInfo = this.getWebhookInfo()

        for (let i=0; i<eventTypes.length; i++){
            const checkedKey = eventTypes[i] + "Checked"

            let checkedValue = false;
            if (checkedKey in webhookInfo){
                checkedValue = webhookInfo[checkedKey]
            }

            this.setState({ [checkedKey]: checkedValue })
        }
    }

    render() {
        let checkBoxes = [];

        for (let i=0; i<eventTypes.length; i++){
            checkBoxes.push(
                <div key={i} style={{marginTop: "8px"}}>
                    <Checkbox toggle label={<label className="fileIDHeading">{capitalize(eventTypes[i])}</label>}
                              onChange={e => this.toggle(eventTypes[i])}
                              checked={this.state[eventTypes[i] + "Checked"]}/>
                </div>
            )
        }

        return (
            <div>
                {checkBoxes}
                <div style={{marginTop: "16px"}}>
                    {
                        this.areStatesDifferent() ?
                            <Button
                                style={{backgroundColor: "#9696A0", color: "white"}}
                                icon="undo" content="Undo"
                                onClick={this.reset} />  : null
                    }
                    <SaveWebhookEvents events={this.state} areStatesDifferent={this.areStatesDifferent()}/>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    editID: state.mainState.editID
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(EventSelectionRow)