import React, {Component} from 'react'
import {connect} from 'react-redux'
import SSLCertificateRow from "./SSLCertificateRow";
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";

class YourSSLCertificates extends Component {
    deleteSSLInfo = (idx) => {
        console.log(idx)
        const currSSLInfoCopied = JSON.parse(JSON.stringify(this.props.currSSLInfo))

        currSSLInfoCopied.splice(idx, 1)
        console.log(currSSLInfoCopied)

        if (currSSLInfoCopied.length > 0){
            this.props.changeState({ currSSLInfo: currSSLInfoCopied })
        } else {
            this.props.changeState({ currSSLInfo: currSSLInfoCopied, sslMenuItem: "requestCertificate" })
        }
    }

    render() {
        const headingStyle = {fontSize: "14px", fontWeight: "700", color: gunaldiStyles.subText}
        const gridStyle = {display: "grid", gridTemplateColumns: "10% 40% 15% 30% 5%"}

        return (
            <div>
                <div style={{padding: "8px", borderTop: "1px solid #F0F0F0", borderBottom: "1px solid #F0F0F0"}}>
                    <div style={gridStyle}>
                        <div style={headingStyle}>
                            Select
                        </div>
                        <div style={headingStyle}>
                            Domain Name
                        </div>
                        <div style={headingStyle}>
                            Type
                        </div>
                        <div style={headingStyle}>
                            Status
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
                {
                    this.props.currSSLInfo.map((x, idx) => <SSLCertificateRow
                        domainName={this.props.domainName}
                        domainNameError={this.props.domainNameError}
                        gridStyle={gridStyle}
                        changeState={this.props.changeState}
                        sslSelected={this.props.sslSelected}
                        deleteSSLInfo={this.deleteSSLInfo}
                        info={x["Certificate"]}
                        idx={idx}
                        key={idx}/>)
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(YourSSLCertificates)