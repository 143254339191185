import React, {Component} from 'react'
import DropboxVisual from "./DropboxVisual";
import { connect } from 'react-redux'
import {push} from "react-router-redux";
import {modifyIntegrationAuth} from "../Actions/ModifyIntegrationAuth";
import API_Root from "../Constants/API_Root";
import {toggleModal} from "../Actions/ToggleModal";
import {toggleToast} from "../Actions/ToggleToast";

class DropboxConnect extends Component {
    constructor(props){
        super(props);

        this.state = {
            redirectURL: this.getRedirectURL()
        }

    }

    getRedirectURL = () => {
        let redirectURL = ""
        if (window.location.href.includes("localhost")){
            redirectURL = "http://localhost:5000/upload"

            if (window.location.href.includes("dropbox-folder-connect")){
                redirectURL += "/dropbox-folder-connect"
            }
        } else {
            redirectURL = "https://www.apispreadsheets.com/upload"

            if (window.location.href.includes("dropbox-folder-connect")){
                redirectURL += "/dropbox-folder-connect"
            }
        }

        return redirectURL
    }

    componentDidMount(){
        const url = window.location.href;

        const syncError = (err) => {
            this.props.toggleModal(null);
            this.props.toggleToast({show: true, message: "There was an error syncing your DropBox file! Try again or ask for Support if the error persists.", type: "error"});
        }

        if (url.includes("code")) {
            const code = url.substr(url.indexOf("code=") + 5, url.length)

            fetch('https://api.dropbox.com/oauth2/token', {
                method: 'POST',
                headers: {
                    Authorization: "Basic NXdrc2EwamlteWxncjl6OmdzeGx1NThoZm5nczZmeQ==",
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                    // "5wksa0jimylgr9z": "gsxlu58hfngs6fy",
                    // "client_id": "5wksa0jimylgr9z",
                    // "client_secret": "gsxlu58hfngs6fy",
                // })
                body: "code=" + code + "&grant_type=authorization_code&redirect_uri=" + this.state.redirectURL
            }).then((res) => {
                    res.json().then(
                        (data) => {
                            fetch(API_Root + "api/api-store-dropbox-auth/",
                                {
                                    method: 'POST',
                                    headers: {
                                        Accept: 'application/json, text/plain, */*',
                                    },
                                    body: JSON.stringify({
                                        email: localStorage.getItem("email"),
                                        token: localStorage.getItem("token"),
                                        dropbox: data,
                                    })
                                }).then((res) =>
                                    {
                                        if (res.status === 200){
                                            const currIntegration = JSON.parse(JSON.stringify(this.props.integrationAuth));

                                            currIntegration.dropbox = true;

                                            this.props.modifyIntegrationAuth(currIntegration);

                                            this.props.navigateTo(this.state.redirectURL.includes("dropbox-folder-connect") ? "/dropbox-folder-connect" : "/")
                                        } else{
                                            syncError(res.status.toString())
                                        }
                                    }).catch((err) => {syncError(err)})
                    }).catch((err) => {syncError(err)})
                }
            ).catch((err) => {syncError(err)})
        }
    }

    render() {
        return (
            <div>
                <a href={"https://www.dropbox.com/oauth2/authorize?client_id=5wksa0jimylgr9z&response_type=code&token_access_type=offline&redirect_uri=" + this.state.redirectURL}>
                    <DropboxVisual/>
                </a>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    integrationAuth: state.mainState.integrationAuth
});

const mapActionsToProps = {
    modifyIntegrationAuth: modifyIntegrationAuth,
    navigateTo: push,
    toggleModal: toggleModal,
    toggleToast: toggleToast
};

export default connect(mapStateToProps, mapActionsToProps)(DropboxConnect)