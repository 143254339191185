import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {toggleToast} from "../../../../Actions/ToggleToast";
import API_Root from "../../../../Constants/API_Root";
import {toggleModal} from "../../../../Actions/ToggleModal";

class DeleteCertificateBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleting: false
        }
    }

    deleteCertificate = () => {
        const isError = (msg="Problem deleting your certificate") => this.setState({
            deleting: false
        }, () => this.props.toggleToast({ show: true, message: msg, type: "error" }))

        this.setState({ deleting: true })

        fetch(API_Root + "api/delete-ssl-certificate/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                certificateArn: this.props.certificateArn
            })
        }).then(res => {
            if (res.status === 200){
                this.setState({ deleting: false }, () => {
                    this.props.deleteSSLInfo(this.props.idx)
                    this.props.toggleModal(null)
                    this.props.toggleToast({ show: true, message: "Certificate deleted", type: "success" })
                })
            } else {
                res.json().then(data => {
                    isError(data['msg'])
                }).catch(err => isError())
            }
        }).catch(err => isError())
    }

    render() {
        return (
            <div>
                <Button color="red"
                        loading={this.state.deleting}
                        icon="trash alternate icon"
                        onClick={this.deleteCertificate}
                        content="Delete Certificate"/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    toggleToast: toggleToast,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(DeleteCertificateBtn)