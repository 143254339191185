import React, {Component} from 'react'
import ModalApp from '../SharedComponents/Modal'
import AnalyticsBody from './AnalyticsBody'
import { Icon } from 'semantic-ui-react'

class AnalyticsModal extends Component {
    render() {
        return (
            <ModalApp name="analyticsModal"
                      header={null}
                      body={<AnalyticsBody />}
                      size="large"
            />
        )
    }
}

export default AnalyticsModal