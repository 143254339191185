import React, {Component} from 'react'
import FileNameSearch from "./FileNameSearch"
import FileTypeFilter from "./FileTypeFilter"
import DateFilter from "./DateFilter"
import DateSort from "./DateSort"
import { connect } from 'react-redux'
import {modifyFilePK} from "../../Actions/ModifyFilePK";
import {Button, Icon, Popup} from 'semantic-ui-react'
import { isMobile } from 'react-device-detect'
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import {sortArrayOfObjects} from "../../Constants/sortArrayOfObject";

class UpdatedFilesFilterSortIndex extends Component {
    constructor(props) {
        super(props);

        const originalFileTypes = this.initFileTypes();

        this.state = {
            isFiltering: false,
            searchName: '',
            originalFileTypes: originalFileTypes,
            fileTypes: originalFileTypes,
            startDt: this.props.originalFilePK[this.props.originalFilePK.length - 1].createdDt.substring(0, 10),
            endDt: this.props.originalFilePK[0].createdDt.substring(0, 10),
            uploadType: "all",
            sortDropdown: "createdDt_descending",
            sortType: 'createdDt'
        }

        this.initFileTypes = this.initFileTypes.bind(this);
        this.changeState = this.changeState.bind(this);
        this.filter = this.filter.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.canClearFilterAppear = this.canClearFilterAppear.bind(this);
    }

    initFileTypes(){
        let fileTypes = [];

        for (let pkID in this.props.fileInformation){
            if (fileTypes.length === 6){
                break
            }
            else{
                if (!fileTypes.includes(this.props.fileInformation[pkID]['inputType'])){
                    fileTypes.push(this.props.fileInformation[pkID]['inputType'])
                }
            }
        }

        return fileTypes
    }

    changeState(name, value){
        if (name === "dates"){
            this.setState({
                startDt: value.startDt,
                endDt: value.endDt,
                isFiltering: true
            }, () => this.filter())
        } else if (name === "sort"){
            const split = value.split("_")

            this.setState({
                sortType: split[0],
                sortDropdown: value
            }, () => this.sortFiles(split[1]))
        } else{
            this.setState({
                [name]: value,
                isFiltering: true
            }, () => this.filter())
        }
    }

    sortFiles = (order) => {
        let sortedFilePK = sortArrayOfObjects(JSON.parse(JSON.stringify(this.props.filePK)), this.state.sortType)

        if (order === "descending"){
            sortedFilePK = sortedFilePK.reverse()
        }

        this.props.modifyFilePK(sortedFilePK)
    }

    filter(){
        let filteredFilePK = filterPK(this.props.originalFilePK, this.props.fileInformation,
            {
                searchName: this.state.searchName,
                fileTypes: this.state.fileTypes,
                startDt: this.state.startDt,
                endDt: this.state.endDt,
                uploadType: this.state.uploadType
            }
        );

        this.props.modifyFilePK(filteredFilePK)

        this.setState({
            isFiltering: false
        })

    }

    clearFilter(e){
        this.setState({
            isFiltering: false,
            searchName: '',
            fileTypes: this.initFileTypes(),
            startDt: this.props.originalFilePK[this.props.originalFilePK.length - 1].createdDt.substring(0, 10),
            endDt: this.props.originalFilePK[0].createdDt.substring(0, 10),
            sortDropdown: "createdDt_descending",
            sortType: 'createdDt',
            uploadType: "all",
        });

        this.props.modifyFilePK(this.props.originalFilePK)
    }

    canClearFilterAppear(){
        let canAppear = false;
        if (this.props.originalFilePK.length !== this.props.filePK.length){
            canAppear = true
        }
        else{
            for (let i=0; i<this.props.originalFilePK.length; i++){
                if (this.props.originalFilePK[i].id !== this.props.filePK[i].id){
                    canAppear = true
                    break
                }
            }
        }

        return canAppear

    }

    // handleChange(e, { value }){
    //     this.changeState(e.target.name, e.target.value)
    //     this.setState({
    //         uploadType: value
    //     })
    // }

    handleChange = (event, { value })  => {
        this.changeState("uploadType", value)
    }

    handleDropdownChange = (event, { value }) =>{
        this.changeState("fileTypes", value)
    }



    render() {
        const radioLabelStyle = {paddingLeft: "5px", cursor: "pointer"}
        return (
            <div style={{marginBottom: '5px'}}>
                <div className="row">
                    <div className="col-sm-4">
                        <FileNameSearch
                            searchName={this.state.searchName}
                            isFiltering={this.state.isFiltering}
                            changeState={this.changeState}

                        />
                        {isMobile ? <br/> : null}
                    </div>
                    <div className="col-sm-3">
                        <FileTypeFilter
                            originalFileTypes={this.state.originalFileTypes}
                             fileTypes={this.state.fileTypes}
                             changeState={this.changeState}
                         />
                    </div>
                    <div className="col-sm-2">
                        <div>
                            <Dropdown
                                style={{height:'40px', top:'0px',backgroundColor:'#F0F0F0',border:'none',padding:'12px 8px 8px 12px', borderRadius: "0"}}
                                options={[
                                    {key: 0, value: 'all', text: 'All Files'},
                                    {key: 1, value: 'uploaded', text: 'Uploaded by you'},
                                    {key: 2, value: 'shared', text: 'Shared with you'},
                                ]}
                                onChange={this.handleChange}
                                value={this.state.uploadType}
                                fluid
                                selection
                            />
                        </div>

                    </div>
                    <div className="col-sm-2">
                        <DateSort sortType={this.state.sortType} sortDropdown={this.state.sortDropdown} changeState={this.changeState}/>
                    </div>
                    {/*<div className="col-sm-1">*/}
                            {/*<DateFilter*/}
                                {/*startDt={this.state.startDt}*/}
                                {/*endDt={this.state.endDt}*/}
                                {/*changeState={this.changeState}*/}
                            {/*/>*/}
                            {/*{isMobile ? <br/> : null}*/}
                    {/*</div>*/}
                    <div className="col-sm-1">
                        {this.canClearFilterAppear() ? <Popup trigger={<Button onClick={this.clearFilter} icon="remove" color='red' basic circular style={{float: 'right'}} />} content="Clear All Filters" />  : null}
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation,
    originalFilePK: state.mainState.originalFilePK
})

const mapActionsToProps = {
    modifyFilePK: modifyFilePK
}

export default connect(mapStateToProps, mapActionsToProps)(UpdatedFilesFilterSortIndex)

const filterPK = (originalFilePK, fileInformation, filterValues) => {
    let filteredPK = [];

    for (let i=0; i<originalFilePK.length; i++){
        const pkElement = originalFilePK[i];
        const pkID = pkElement.id;

        const filteredFileInfo = getRelevantFileInfo(fileInformation, pkID);

        if (isSearchNameInFileName(filteredFileInfo.fileName, filteredFileInfo.fileHash, filterValues.searchName) &&
            isDateBetweenFilters(filteredFileInfo.createdDt, filterValues.startDt, filterValues.endDt) &&
            isFileTypeInFilters(filteredFileInfo.fileType, filterValues.fileTypes) &&
            doesFileUploadTypeMatchSelection(filteredFileInfo.isTeam, filterValues.uploadType, filteredFileInfo.isFileImport))
        {
            filteredPK.push(pkElement)
        }
    }

    return filteredPK
}

const getRelevantFileInfo = (fileInformation, pkID) => {
    const fileInfo = fileInformation[pkID];

    let fileHash = '';
    if ('useFileHash' in fileInfo){
        fileHash = fileInfo['useFileHash'] ? fileInfo['fileHash'] : ""
    }

    return {
        fileType: fileInfo['inputType'],
        fileName: fileInfo['name'],
        createdDt: fileInfo['createdDt'].substring(0, 10),
        isTeam: 'isTeam' in fileInfo ? fileInfo['isTeam'] : false,
        isFileImport: 'importSheetsFK' in fileInfo ? fileInfo['importSheetsFK'] !== -1 : false,
        fileHash: fileHash
    }
}

const isSearchNameInFileName = (fileName, fileHash, searchName) => {
    return fileName.toLowerCase().trim().indexOf(searchName.toLowerCase().trim()) !== -1 || fileHash.trim().indexOf(searchName.trim()) !== -1

}

const isDateBetweenFilters = (createdDt, startDt, endDt) => {
    return createdDt >= startDt && createdDt <= endDt
}

const isFileTypeInFilters = (fileType, fileTypes) => {
    return fileTypes.includes(fileType)
}

const doesFileUploadTypeMatchSelection = (isTeam, uploadType, isFileImport) => {
    if (uploadType === "all"){
        return true
    } else if (uploadType === "uploaded"){
        return !isTeam && !isFileImport
    } else if (uploadType === "shared"){
        return isTeam || isFileImport
    } else {
        return true
    }
}