import {Component} from 'react'

class ShowTawkTo extends Component {
    constructor(props){
        super(props);

        this.state = {
            nullCount: 0
        }
    }

    createTawk = () => {
        if ((window.location.href.includes("import") && window.location.href.includes("embed")) ||
            (window.location.href.includes("/table/"))
        ){

        } else {
            const s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/5e56ff65a89cda5a188820c9/default';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s1.id = "tawk-to-chat-box"
            s0.parentNode.insertBefore(s1,s0);
        }
    }

    componentDidMount(){
        const tawkToDisplay = setInterval(() => {
            if (this.props.tier === null){
                if (this.state.nullCount === 0){
                    this.setState({ nullCount: 1 })
                } else {
                    this.createTawk()
                    clearInterval(tawkToDisplay)
                }
            } else {
                if (this.props.tier === "pro" || this.props.tier === "team" || this.props.tier === "business" || this.props.tier === "enterprise"){
                    this.createTawk()
                    clearInterval(tawkToDisplay)
                } else{
                    clearInterval(tawkToDisplay)
                }
            }
        },1000)
    }

    render() {
        return (
            null
        )
    }
}

export default ShowTawkTo