import React, {Component} from 'react'
import AuthComponent from "../../../../SharedComponents/AuthComponent";
import UpdatedLandingPageIndex from "../../../../LandingPage/UpdatedLandingPageIndex";
import AddDropboxAccountToDestinations from "./AddDropboxAccountToDestinations";

class AuthAddDropboxAccountToDestinations extends Component {
    render() {
        console.log(window.location.href)
        return (
            <div>
                <AuthComponent
                    authComponent={<AddDropboxAccountToDestinations />}
                    nonAuthComponent={<UpdatedLandingPageIndex/>}
                />
            </div>
        )
    }
}

export default AuthAddDropboxAccountToDestinations