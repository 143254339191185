import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class DisplayHeader extends Component {
    render() {
        let icon = null;

        if ('icon' in this.props){
            icon = <Icon name={this.props.icon} />
        }
        return (
            <div style={{fontFamily: "Lato", fontSize: "16px", fontWeight: "700", color: "#161E16"}}>
                {icon} {this.props.header}
            </div>
        )
    }
}

export default DisplayHeader