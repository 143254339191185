import React, {Component} from 'react';
import withAuthorization from '../Authentication/withAuthorization';
import DatabaseDisplayIndex from "./DatabaseDisplayIndex";

class AuthDatabaseDisplay extends Component {
    render() {
        return(
            <DatabaseDisplayIndex />
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AuthDatabaseDisplay);
