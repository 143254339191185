import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Label, Message, Button, Icon } from 'semantic-ui-react'
import {isMobile} from "react-device-detect";
import FileDescriptionModalBody from "../../FileDnD/FileDescription/FileDescriptionModalBody"
import RemoveFile from "../../FileDnD/RemoveFile"
import {modifyCustomImportSettings} from "../../Actions/ModifyCustomImportSettings";
import FilesTabFileSource from "../../FileDnD/FilesTabFileSource";
import {toggleToast} from "../../Actions/ToggleToast";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import BannerAlert from "../../SharedComponents/BannerAlert";

class ImportUploadFilesSegment extends Component {
    getMapColumnsButton = (needToMap) => {
        return <Button style={!needToMap ? gunaldiStyles.btnGreenBasic : gunaldiStyles.btnGreen}
                       onClick={needToMap ? this.mapColumns : this.edit} size="small">
            {!needToMap ? <Icon name="columns" /> : null }
            {needToMap ? "Map Columns" : "Edit Column Map"}
            {needToMap ? <Icon name="right arrow" /> : null}
        </Button>
    }

    edit = () => {
        this.props.modifyCustomImportSettings("fileIdx", this.props.idx)
        this.props.modifyCustomImportSettings("shouldEdit", true)
        this.props.modifyCustomImportSettings("matchColumns", true)
    }

    mapColumns = () => {
        this.props.modifyCustomImportSettings("fileIdx", this.props.idx)
        this.props.modifyCustomImportSettings("matchColumns", true)
    }

    copyID = (id) => {
        navigator.clipboard.writeText(id)
        this.props.toggleToast({show: true, message: "File ID Copied", type: "success"})
    }

    render() {
        const fileInformation =  this.props.importSheetsFileInformation[this.props.pkID];
        let fileName = fileInformation.name;

        const isExcel = fileName.split('.').pop() !== "csv" && fileName.split('.').pop() !== "txt";

        const fileDivStyle = {
            // backgroundColor: '#f3f6fb',
            // border: '1px solid #F0F0F0',
            padding: '10px 0 10px 0',
            borderBottom: "1px solid #F0F0F0",
            margin: "0 60px 0 60px"
        };
        const displayFileHash = fileInformation.fileHash.toString()
        let fileIDLabel = <p className="fileIDLabel">
            <Icon name="copy outline"
                  className="fileIDLabelIcon"
                  color="green"
                  onClick={() => this.copyID(displayFileHash)} />
            {displayFileHash}
        </p>

        let columnsMap = null;
        if ("columnsMap" in fileInformation.importSheetsInfo){
            columnsMap = fileInformation.importSheetsInfo.columnsMap
        }

        let columnsMapMessage = null;
        let showColumnEditing = false;
        if (columnsMap !== null){
            if (columnsMap.length === 0){
                columnsMapMessage = <div style={{marginBottom: "16px"}}>
                <BannerAlert
                    type="error"
                    header='Columns in file need to be mapped'
                    icon="bx bxs-error"
                    content={isMobile ? this.getMapColumnsButton(true) : null}
                    action={isMobile ? null : this.getMapColumnsButton(true)}
                /></div>
            } else {
                showColumnEditing = true;
            }
        }

        if (isMobile){
            return (
                <div style={fileDivStyle}>
                    {columnsMapMessage}
                    <div style={{display: "grid", gridTemplateColumns: "95% 5%"}}>
                        <div>
                            {/*<div className="fileRowFileSource">*/}
                                {/*<div>*/}
                                    {/*<FilesTabFileSource fileInformation={{inputType: "file"}} />*/}
                                {/*</div>*/}
                                {/*<div className="wordwrap wordwrap2">*/}
                            <div className="ellipses" style={{marginBottom: "8px"}}>
                                <span style={{color: "#161E16", fontFamily:'Lato',fontWeight:'600',fontSize:'16px', paddingRight: "4px"}}>{fileName}</span>
                            </div>
                            {/*</div>*/}
                            <div style={{marginBottom: "8px"}}>
                                {fileIDLabel}
                            </div>
                            <div style={{width: '100%', marginBottom: "8px"}}>
                                {/*<FileSummaryBaseURL url={"data/" + this.props.pk.toString() + "/"} />*/}
                                {/*<br/>*/}
                                <FileDescriptionModalBody
                                    importEmail={this.props.importInfo.userEmail}
                                    pkID={this.props.pkID}
                                    pk={this.props.pk}
                                    importSegment />
                            </div>
                            <div style={{marginBottom: "8px"}}>{showColumnEditing ? this.getMapColumnsButton(false) : null}</div>
                        </div>
                        <div style={{textAlign: "right"}}>
                            <RemoveFile pkID={this.props.pkID}
                                        pk={this.props.pk}
                                        idx={this.props.idx}
                                        isExcel={isExcel}
                                        importFile/>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={fileDivStyle}>
                    {columnsMapMessage}
                    <div className="importUploadFilesSegmentDiv">
                        <div>
                            <div className="fileRowFileSource">
                                <div>
                                    <FilesTabFileSource fileInformation={{inputType: "file"}} />
                                </div>
                                {/*<div className="wordwrap wordwrap2">*/}
                                <div className="ellipses">
                                    <span style={{color: "#161E16;", fontFamily:'Lato',fontWeight:'600',fontSize:'16px', paddingRight: "4px"}}>{fileName}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            {fileIDLabel}
                        </div>
                        <div style={{width: '100%'}}>
                            {/*<FileSummaryBaseURL url={"data/" + this.props.pk.toString() + "/"} />*/}
                            {/*<br/>*/}
                            <FileDescriptionModalBody
                                importEmail={this.props.importInfo.userEmail}
                                pkID={this.props.pkID}
                                pk={this.props.pk}
                                importSegment />
                            {isMobile ? <br/> : null}
                        </div>
                        <div>{showColumnEditing ? this.getMapColumnsButton(false) : null}</div>
                        <div style={{textAlign: "right"}}>
                            <RemoveFile pkID={this.props.pkID}
                                        pk={this.props.pk}
                                        idx={this.props.idx}
                                        isExcel={isExcel}
                                        importFile/>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    importSheetsFileInformation: state.mainState.importSheetsFileInformation
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(ImportUploadFilesSegment)