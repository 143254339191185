import React, {Component} from 'react'
import styles from "../SQL/styles";

class ErrorResponseCodesRow extends Component {
    render() {
        return (
            <div>
                <div style={{ marginBottom: "12px"}}>
                    <div style={{ marginBottom: "4px" }}>
                        <span><code style={{ fontWeight: "700", fontSize: "16px", color: styles.mainText}}>{this.props.statusCode}</code></span>
                    </div>
                    {
                        this.props.messages.map((x, idx) =>
                            <div style={{ fontSize: "14px", color: styles.subText, marginBottom: "4px"}} key={idx}>
                                • {x}
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default ErrorResponseCodesRow