import React, {Component} from 'react'
import {isMobile} from "react-device-detect";
import {gunaldiStyles} from "../../../SharedComponents/GunaldiDesignStyles";


class ImporterTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeFunction: this.props.menuItems[0].name
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e, name){
        this.setState({activeFunction: name})
    }

    isImporter = () => 'importer' in this.props;

    isUtility = () => ('utility' in this.props)

    render() {
        const isHorizontal = 'horizontal' in this.props;

        const generalStyle = { cursor: "pointer", padding: "12px 16px 12px 16px"}

        if (isHorizontal){
            generalStyle['textAlign'] = "center"
        }

        const selectedStyle = JSON.parse(JSON.stringify(generalStyle))
        selectedStyle['backgroundColor'] = this.isImporter() ? gunaldiStyles.orange : this.isUtility() ? gunaldiStyles.blue : '#6FBB98'
        selectedStyle['color'] = 'white'
        selectedStyle['borderRadius'] = "6px"

        let menuItems = [];
        let italicHeader = null;

        for (let idx=0; idx<this.props.menuItems.length; idx++){
            const x = this.props.menuItems[idx];
            const header = "header" in x ? <div style={{fontFamily: "Lato", fontStyle: "italic", fontWeight: "400", fontSize: "12px"}}>{x.header}</div> : null

            menuItems.push(
            <div style={this.state.activeFunction === x.name ? selectedStyle : generalStyle} key={idx} onClick={e => this.handleClick(e, x.name)}>
                {header}
                <div style={{fontFamily: "Lato", fontSize: "16px", fontWeight: "700"}}>{x.displayName}</div>
            </div>)

            if (this.state.activeFunction === x.name){
                italicHeader = <div style={{textAlign: "center", fontWeight: "400", fontSize: "14px", color: "#9696A0", fontFamily: "Lato", marginBottom: "12px"}}>{x.header}</div>
            }
        }

        const cleanWidth = isMobile ? "31.25%" : "30%";
        const exampleWidth = isMobile ? "68.75%" : "70%";

        return (
            <div style={isHorizontal ? null : {backgroundColor: this.isImporter() ? gunaldiStyles.subOrange : this.isUtility() ? gunaldiStyles.subBlue : "#F4FBF3", border: "1px solid #F0F0F0", borderRadius: "10px", padding: "30px"}}>
                <div style={isHorizontal ? null : {display: "grid", gridTemplateColumns: cleanWidth + " " + exampleWidth, columnGap: "34px"}}>
                    <div style={isHorizontal ? {display: "grid", gridTemplateColumns: menuItems.map(x => "1fr").join(" "), marginBottom: "24px"} : null}>
                        {menuItems}
                    </div>
                    <div style={{backgroundColor: "white", borderRadius: "6px", padding: "40px", marginRight: "30px"}}>
                        {italicHeader}
                        {this.props.menuDisplay[this.state.activeFunction]}
                    </div>
                </div>
            </div>
        )
    }
}

export default ImporterTable