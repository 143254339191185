const capitalizeHyphenated = (hyphenated, sepChar="-") => {
    const removeHyphen = hyphenated;

    let capitalizedString = removeHyphen.charAt(0).toUpperCase();

    for (let i=1; i<removeHyphen.length; i++){
        if (removeHyphen.charAt(i) === sepChar){
            capitalizedString += " "
        }
        else if (removeHyphen.charAt(i-1) === "-"){
            capitalizedString += removeHyphen.charAt(i).toUpperCase()
        }
        else{
            capitalizedString += removeHyphen.charAt(i)
        }
    }

    return capitalizedString

}

export default capitalizeHyphenated