import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'
import SimpleInterfaceBox from "./FeaturesBody/SimpleInterfaceBox";

class API extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-4">
                    <SimpleInterfaceBox
                        header={<span>GET or POST Request</span>}
                        icon="cloud"
                        body={<span>Execute <b>CRUD</b> actions with a simple <Label color="orange">POST</Label> or <Label color="yellow">GET</Label> Request</span>}
                        color="#ff5349"
                    />
                    <br/>
                </div>
                <div className="col-sm-4">
                    <SimpleInterfaceBox
                        header={<span>SQL Style Queries</span>}
                        icon="database"
                        body={<span>Use simple queries like <code>SELECT*FROM84WHEREage>30</code></span>}
                        color="#0081ff"
                    />
                    <br/>
                </div>
                <div className="col-sm-4">
                    <SimpleInterfaceBox
                        header={<span>Multi-Language Support</span>}
                        icon="code"
                        body={<span>Written and Tested Code for multiple languages. <code>Python, PHP, Javascript, cURL, Ruby</code></span>}
                        color="#008000"
                    />
                    <br/>
                </div>
            </div>
        )
    }
}

export default API