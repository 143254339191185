import React, {Component} from 'react'
import {capitalize} from "../../Constants/capitalize";
import { Divider } from 'semantic-ui-react'
import ReactJson from 'react-json-view'

class SpreadsheetImportWebhook extends Component {
    render() {
        const sampleRequest = {
            "data": [{"name": "Jake Peralta", "age": 38}, {"name": "Rosa Diaz", "age": 41}],
            "file_name": "Brooklyn99.xlsx",
            "created_dt": "2022-02-11 01:17:52",
            "file_id": "dS0bXsCIjB5gBJ16",
            "private": true,
            "access_key": "ab4a7a9f3e1e4d8d6a842578801e5a04",
            "secret_key": "100c4e196c7cf426a0c9c1f85220a2bc",
            "error": false
        }

        return (
            <div className="row">
                <div className="col-sm-6">
                    <h5 id="parameterHeading">Request Body Parameters</h5>
                    <Divider/>
                    <span className="parameterName">data</span> <span className="parameterType">Array</span>
                    <span className="parameterSecondLine"><span className="parameterDefault">default={"[]"}</span></span>
                    <span className="parameterDesc">The data from the spreadsheet. This will be an array where each element is a JSON object that corresponds to a row in the spreadsheet. Each element will have the column names as the keys with their values </span>
                    <Divider/>
                    <span className="parameterName">file_name</span> <span className="parameterType">string</span>
                    <span className="parameterSecondLine"><span className="parameterDefault">default=""</span></span>
                    <span className="parameterDesc">Name of the file</span>
                    <Divider/>
                    <span className="parameterName">created_dt</span> <span className="parameterType">string</span>
                    <span className="parameterSecondLine"><span className="parameterDefault">default=""</span></span>
                    <span className="parameterDesc">Datetime of the file upload. It will be in the format "YYYY-MM-DD HH:MM:SS" and will be in UTC time</span>
                    <Divider/>
                    <span className="parameterName">file_id</span> <span className="parameterType">string</span>
                    <span className="parameterSecondLine"><span className="parameterDefault">default=""</span></span>
                    <span className="parameterDesc">This will correspond to the file_id within API Spreadsheets. You can use this to make create, read, update or delete API requests to the file. Read the <a href="/docs" target="_blank" rel="noopener norefereer">API Docs</a> or go to your files dashboard to learn how to make API requests</span>
                    <Divider/>
                    <span className="parameterName">private</span> <span className="parameterType">boolean</span>
                    <span className="parameterSecondLine"><span className="parameterDefault">default=false</span></span>
                    <span className="parameterDesc">This will correspond to the private status of the file within API Spreadsheets. If <code>true</code> you will need to use access and secret keys when making API requests</span>
                    <Divider/>
                    <span className="parameterName">access_key</span> <span className="parameterType">string</span>
                    <span className="parameterSecondLine"><span className="parameterDefault">default=null</span></span>
                    <span className="parameterDesc">This will only be a string if the <code>private</code> parameter is <code>true</code>. This will correspond to the access key of the file within API Spreadsheets</span>
                    <Divider/>
                    <span className="parameterName">secret_key</span> <span className="parameterType">string</span>
                    <span className="parameterSecondLine"><span className="parameterDefault">default=null</span></span>
                    <span className="parameterDesc">This will only be a string if the <code>private</code> parameter is <code>true</code>. This will correspond to the secret key of the file within API Spreadsheets</span>
                    <Divider/>
                    <span className="parameterName">error</span> <span className="parameterType">boolean</span>
                    <span className="parameterSecondLine"> </span>
                    <span className="parameterDesc"><code>true</code> or <code>false</code> depending on if there was an error processing the file on our backend or not</span>
                    <Divider/>
                </div>
                <div className="col-sm-6">
                    <div className="getResponseBox">
                        <div className="getResponseHeading">
                            <span style={{float: 'left'}}><h6 className="thinHeading">Request Body Example</h6></span>
                        </div>
                        <ReactJson src={sampleRequest}
                                   name={null}
                                   theme="ocean"
                                   displayObjectSize={false}
                                   displayDataTypes={false}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default SpreadsheetImportWebhook