import React, {Component} from 'react'
import { Icon, Image } from 'semantic-ui-react'
import ModalApp from '../Modal'
import images from "../../Constants/Images";
import DemoVideo from "./DemoVideo";
import {gunaldiStyles} from "../GunaldiDesignStyles";


class SignUpDemoModal extends Component {
    isImporter = () => (window.location.href.includes("/import"))

    render() {
        return (
            <ModalApp name="signupDemoModal"
                      header={<div style={{textAlign: "center", fontSize: "20px", fontWeight: "700", color: gunaldiStyles.mainText}}>
                          Welcome to API Spreadsheets!
                          <div style={{fontSize: "14px", color: gunaldiStyles.subText, fontWeight: "400", marginBottom: "16px"}}>
                              Watch our {this.isImporter() ? "60" : "90"}-second demo to quickly get started
                          </div>
                      </div>}
                      body={<div style={{textAlign:'center'}}>
                          <DemoVideo isImporter={this.isImporter()}/>
                      </div>}
                      size="large"
            />
        )
    }
}

export default SignUpDemoModal