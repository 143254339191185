import React, {Component} from 'react'
import { connect } from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import { Button, Segment, Icon } from 'semantic-ui-react'
import DeleteS3Account from "./DeleteS3Account";
import API_Root from "../Constants/API_Root";

class SelectAWSAccountRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: false
        }
    }

    toggleEdit = () => this.setState({ isEditing: !this.state.isEditing })

    selectAccount = () => {
        this.props.changeState({
            fetching: true,
            fetchingText: "Getting your account buckets...",
            selectedAccountName: this.props.accountName,
            selectedAccountInfo: this.props.accountInfo
        })

        fetch(API_Root + "api/get-all-s3-buckets-for-api-user/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                accessKey:this.props.accountInfo.accessKey,
                secretKey:this.props.accountInfo.secretKey,
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.props.changeState({
                        fetching: false,
                        fetchingText: "",
                        bucketNames: data.bucketNames,
                        currentDisplay: "buckets"
                    })
                }).catch(err => this.props.setErrorMsg("There was an error getting your bucket names", "Please try again"))
            } else if (res.status === 404){
                this.props.setErrorMsg("There was a problem connecting to this account", "Please check your access and secret keys")
            } else{
                this.props.setErrorMsg("There was an error getting your bucket names", "Please try again")
            }
        }).catch(err => this.props.setErrorMsg("There was an error getting your bucket names", "Please try again"))
    }

    render() {
        if (this.state.isEditing){
            return null
        } else {
            return (
                <div className="row">
                    <div className="col-sm-9">
                        <Segment className="awsS3AccountRow" onClick={this.selectAccount}>
                            <h4 className="thinHeading"><Icon name="aws" /> {this.props.accountName}</h4>
                        </Segment>
                    </div>
                    <div className="col-sm-3">
                        {/*<div style={{marginTop: "8px"}}>*/}
                            {/*<Button circular*/}
                                    {/*onClick={this.toggleEdit}*/}
                                    {/*icon="edit alternate outline"*/}
                                    {/*color="blue"*/}
                                    {/*basic />*/}
                            {/*<DeleteS3Account/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            )
        }
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(SelectAWSAccountRow)