import React from 'react';
import PropTypes from 'prop-types';

const ProcessFlow = ({ steps, isMobile }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row', // Vertical on mobile, horizontal on browser
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {steps.map((step, index) => (
                <React.Fragment key={index}>
                    {/* Step Text */}
                    <div style={{ margin: isMobile ? '0px' : '20px', textAlign: 'center' }}>
                        <span style={{ fontSize: "24px"}}>{step}</span>
                    </div>

                    {/* Curved Arrow */}
                    {index < steps.length - 1 && (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: isMobile ? '10px 0' : '0 10px',
                            }}
                        >
                            <svg
                                width={isMobile ? 50 : 100}
                                height={isMobile ? 100 : 50}
                                viewBox="0 0 100 100"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                    transform: isMobile ? 'rotate(90deg)' : 'none', // Rotate arrow for vertical layout
                                }}
                            >
                                <path
                                    d="M10,50 C40,10 60,10 90,50"
                                    fill="transparent"
                                    stroke="#5499F8"
                                    strokeWidth="4"
                                    markerEnd="url(#arrowhead)"
                                />
                                <defs>
                                    <marker
                                        id="arrowhead"
                                        markerWidth="10"
                                        markerHeight="10"
                                        refX="8"
                                        refY="5"
                                        orient="auto"
                                    >
                                        <path d="M0,0 L0,10 L10,5 Z" fill="#5499F8" />
                                    </marker>
                                </defs>
                            </svg>
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

ProcessFlow.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.string).isRequired, // Array of step text
};

export default ProcessFlow;
