import {removePunctuation} from "../removePunctuation";

export const reshapeFAQs = (helpInfo) => {
    const faqTexts = [];
    const faqLocations = [];

    for (let i=0; i<helpInfo.length; i++){
        const helpFAQs = helpInfo[i].faq;

        for (let j=0; j<helpFAQs.length; j++){
            faqLocations.push([i, j])
            faqTexts.push(getFAQTextFromFAQObject(helpFAQs[j]))
        }
    }

    return {"texts": faqTexts, "locations": faqLocations}
}

const getFAQTextFromFAQObject = (faqObject) => {
    let answer = 'searchAnswer' in faqObject ? faqObject.searchAnswer : faqObject.answer.toString()
    let question = faqObject.question;

    return cleanText(question) + " " + cleanText(answer)
}

const cleanText = (text) => {
    return takeoutStopGapWords(removePunctuation(text.toString().trim().toLowerCase()))
}

const stopGapWords = ['i','me','my','myself','we','our','ours','ourselves','you','your','yours','yourself','yourselves','he','him','his','himself','she','her','hers','herself','it','its','itself','they','them','their','theirs','themselves','what','which','who','whom','this','that','these','those','am','is','are','was','were','be','been','being','have','has','had','having','do','does','did','doing','a','an','the','and','but','if','or','because','as','until','while','of','at','by','for','with','about','against','between','into','through','during','before','after','above','below','to','from','up','down','in','out','on','off','over','under','again','further','then','once','here','there','when','where','why','how','all','any','both','each','few','more','most','other','some','such','no','nor','not','only','own','same','so','than','too','very','s','t','can','will','just','don','should','now']

const takeoutStopGapWords = (text) => {
    let res = []
    let words = text.split(' ')
    for(let i=0; i<words.length;i++) {
        let word = words[i]

        if(!stopGapWords.includes(word)) {
            res.push(word)
        }
    }

    return(res.join(' '))
}