export const removePKFromArray = (pkArrayParam, pkToRemove) => {
    let pkArray = JSON.parse(JSON.stringify(pkArrayParam))
    if (pkArray.length > 0){
        let pkIndex = -1;

        for (let i=0; i<pkArray.length; i++){
            if (pkArray[i].pk === pkToRemove){
                pkIndex = i
                break
            }
        }

        if (pkIndex !== -1){
            pkArray.splice(pkIndex, 1)
        }

    }

    return pkArray
}