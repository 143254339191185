import React, {Component} from 'react'
import ModalApp from "../SharedComponents/Modal";
import AWSModalBodyIndex from "./AWSModalBodyIndex";
import { Icon } from 'semantic-ui-react'


class AWSModal extends Component {
    render() {
        return (
            <ModalApp name="awsModal"
                      header={<h4><Icon name="aws" /> AWS S3 File Selection</h4>}
                      body={<AWSModalBodyIndex/>}
                      size="large"
            />
        )
    }
}

export default AWSModal 