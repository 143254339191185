import React, {Component} from 'react'
import CRUDExampleTable from "../../../SharedComponents/CRUDExampleTable";
import WebhookBodyIndex from "./WebhookBodyIndex";
import TierRestrictionBanner from "../../../App/Docs/Actions/TierRestrictionBanner"

class Webhooks extends Component {
    render() {
        return (
            <div>
                <div style={{marginTop: "-8px", marginBottom: "16px"}}>
                    <TierRestrictionBanner plan="pro" payment="webhooks"/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">What is a Webhook?</p>
                    <p>A webhook is a REST API URL that you host on your own server. It is called after particular events that you choose.</p>
                    <p>For example, if you choose to call your Webhook after a <b>create</b> request, you can do more with that data in your create request. <br/><br/>Like store the data in your database after it has already been stored in your spreadsheet.</p>
                    <p>You can specify a Webhook URL for each file</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Webhook Setup on API Spreadsheets</p>
                    <p>You specify your Webhook URL, and the events after which you want the Webhook to be called</p>
                    <img style={{border: "1px solid lightgrey", width:'105%'}}
                         src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/Webhook.png" alt="Webhook Form"/>
                    <br/><br/>
                    <p>In this set up above, a <code>POST</code> request will be made to <code>http://www.yourserver.com/your_webhook</code> after any <b>create</b> or <b>read</b> requests that you make to the API Spreadsheets API</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Webhook Specifications</p>
                    <p>Your Webhook must meet the following conditions:</p>
                    <ol>
                        <li>Be a proper URL format such as <code>https://www.myserver.com/mywebhook/</code></li>
                        <li>Be able to accept <code>POST</code> requests</li>
                        <li>Return a <code>200</code> or <code>201</code> code if successful</li>
                    </ol>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Webhook <code>POST</code> request body</p>
                    <p>The following JSON data will be included in the request body with each <code>POST</code> request to the Webhook URL</p>
                    <p>There will be slight differences in the Request Body depending on the event type</p>
                    <CRUDExampleTable
                        createDisplay={<WebhookBodyIndex eventType="create" setBothMenuItems={this.props.setBothMenuItems}/>}
                        readDisplay={<WebhookBodyIndex eventType="read" setBothMenuItems={this.props.setBothMenuItems}/>}
                        updateDisplay={<WebhookBodyIndex eventType="update" setBothMenuItems={this.props.setBothMenuItems}/>}
                        deleteDisplay={<WebhookBodyIndex eventType="delete" setBothMenuItems={this.props.setBothMenuItems}/>}
                    />
                </div>

            </div>
        )
    }
}

export default Webhooks