import React, {Component} from 'react'
import {connect} from 'react-redux'
import {isUserAMacroUser} from "../../Constants/isUserAMacroUser";
import BannerAlert from "../../SharedComponents/BannerAlert"

class UserMacroMessage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (isUserAMacroUser(this.props.fullPricingInfo)){
            return (
                <div>
                    <BannerAlert icon="bx bx-cog" header="You are on the macros plan. Any files you upload will default to macro usage"
                                 content={<span>You can change this setting for each file uploaded within that file's page. Contact us to learn more</span>}
                                 action={null}
                                 type="display"
                    />
                </div>
            )
        } else {
            return null
        }

    }
}

const mapStateToProps = (state) => ({
    fullPricingInfo: state.mainState.fullPricingInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UserMacroMessage)