import React, {Component} from 'react'
import {connect} from 'react-redux'
import JSONAccordionIndex from "../SharedComponents/JSONAccordion/JSONAccordionIndex"
import {toggleToast} from "../Actions/ToggleToast";
import RequestParameterIndex from "./RequestParameterIndex";
import CustomAccordion from "../SharedComponents/CustomAccordion"
import {getRequestBodyJSON} from "./getRequestBodyJSON";
import {capitalize} from "../Constants/capitalize";
import {getFullAPIUrl} from "./getFullAPIUrl";
import UtilitiesCodeAccordion from './UtilitiesCodeAccordion'

class UtilitiesSampleRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataFormat: "row"
        }
    }


    changeState = (newState) => this.setState(newState)

    render() {
        // can use this to show the parameters as needed
        const requestJSON = getRequestBodyJSON(this.props.mode, this.props.utilitiesInfo.isPrivate, this.props.utilitiesInfo.keys, this.state.dataFormat)
        const url = this.props.url;

        return (
            <div>
                <div className="row">
                    <div className="col-sm-6">
                        <JSONAccordionIndex
                            title="Sample Request Body"
                            message=""
                            heading={<span>Showing the {capitalize(this.state.dataFormat)} data format</span>}
                            jsonObj={requestJSON}
                        />
                    </div>
                    <div className="col-sm-6">
                        <CustomAccordion
                            body={<RequestParameterIndex
                                mode={this.props.mode}
                                dataFormat={this.state.dataFormat}
                                changeState={this.changeState}
                                isPrivate={this.props.utilitiesInfo.isPrivate}
                            />}
                            header={"Request Body Parameters"}
                            accordionStyle={{
                                borderRadius: '4px',
                                border: "1px solid #F0F0F0",
                                backgroundColor: 'white'
                                // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                            }}
                            headerDivStyle={{
                                borderRadius: '4px',
                                backgroundColor: 'white',
                                fontSize: '16px',
                                fontWeight: '700',
                                fontFamily: "Lato",
                                padding: "16px"
                            }}
                        />
                    </div>
                </div>
                <div style={{ marginTop: "24px"}}>
                    <UtilitiesCodeAccordion url={url} body={requestJSON} mode={this.props.mode}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    utilitiesInfo: state.mainState.utilitiesInfo
})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(UtilitiesSampleRequest)