import {generateRandomKey} from "../../../Constants/generateRandomKey";

export const createFileInformation = (sampleData, sampleColumnNames, sampleColumnTypes, samplePK, sampleFileName, samplePrivate, sampleFileURL=null) => {
    const samplePKID = samplePK.toString() + "_1";

    let accessKey, secretKey;
    if (sampleFileURL !== null && sampleFileURL.includes("accessKey")){
        const keys = getAccessAndSecretKey(sampleFileURL)
        accessKey = keys.accessKey;
        secretKey = keys.secretKey;

    }
    else{
        accessKey = generateRandomKey(32)
        secretKey = generateRandomKey(32)
    }

    return {
        [samplePKID]: {
            name: sampleFileName,
            pkID: samplePKID,
            size: 100,
            fileURL: "",
            'private': samplePrivate,
            accessKey: accessKey,
            secretKey: secretKey,
            colLength: sampleColumnNames.length,
            rowLength: sampleData.length,
            inputType: 'file',
            createdDt: '2020-04-10 06:25:14',
            columnNames: sampleColumnNames,
            description: '',
            sheetRead: 'Sheet 1',
            sheetNames: ['Sheet 1'],
            matrixSample: {data: sampleData},
            jsonRowSample: createSample(sampleData, sampleColumnNames, "row"),
            jsonColumnSample: createSample(sampleData, sampleColumnNames, "column"),
            columnInformation: createColumnInfo(sampleColumnNames, sampleColumnTypes)
        }
    }
}

const getAccessAndSecretKey = (fileURL) => {
    const accessKeyStartIdx = fileURL.indexOf("accessKey") + 10;
    const secretKeyStartIdx = fileURL.indexOf("secretKey") + 10;

    return {
        accessKey: fileURL.substring(accessKeyStartIdx, accessKeyStartIdx + 32),
        secretKey: fileURL.substring(secretKeyStartIdx, secretKeyStartIdx + 32)
    }
}

const createSample = (data, columnNames, sampleType) => {
    let sample;

    if (sampleType === "row"){
        let rows = [];

        for (let i=0; i<data.length; i++){
            let rowObject = {};
            for (let j=0; j<data[i].length; j++){
                rowObject[columnNames[j]] = data[i][j]
            }

            rows.push(rowObject)
        }

        sample = {data: rows}
    }
    else{
        sample = {}
        for (let i=0; i<columnNames.length; i++){
            let row = [];

            for (let j=0; j<data.length; j++){
                row.push(data[j][i])
            }

            sample[columnNames[i]] = row
        }
    }

    return sample
}

const createColumnInfo = (columnNames, columnTypes) => {
    let columnInfo = {};

    columnInfo['names'] = columnNames

    let info = {}

    for (let i=0; i<columnNames.length; i++){
        info[columnNames[i]] = {
            desc: "",
            type: columnTypes[i],
            original: columnNames[i]
        }
    }

    columnInfo['info'] = info

    return columnInfo
}
