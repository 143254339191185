import React, {Component} from 'react'
import {connect} from 'react-redux'
import DatabaseTypeTag from "./DatabaseTypeTag";
// import componentStyles from "../Constants/componentStyles";
// import QueryIndex from "./Queries/QueryIndex"
import ActionsDropdown from "../Database/ActionsDropdown"
import { Button, Message, Icon, Popup } from 'semantic-ui-react'
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import {toggleModal} from "../Actions/ToggleModal";
import {modifyAllDBInfo} from "../Actions/ModifyAllDBInfo";
import AddDBAPIButton from "./AddDBAPIButton"
import QueryStatusTag from "./QueryStatusTag";
import { push } from 'react-router-redux';
import getURLParam from "../Constants/getURLParam";
import {modifyEditQueryInfo} from "../Actions/ModifyEditQueryInfo";
import MenuSegment from "../FileDnD/FilesID/FilesIDDisplayMenu/MenuSegment"
import DatabaseDisplayInfo from "./DatabaseDisplayInfo";
import DisplayHeader from "../FileDnD/FilesID/DisplayHeader";
import DeleteEditAPI from "./Query/DeleteEditAPI";
import EditDatabaseModalBody from "./Modals/EditDatabaseModalBody";

class DatabaseDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creating: false,
            url: "",
            menuItem: "api"
        }

        this.openModal = this.openModal.bind(this);
        this.goToQueryPage = this.goToQueryPage.bind(this);
    }

    openModal(e, modalName){
        const databaseHash = getURLParam(window.location.href);

        this.props.modifyModalInfo({ databaseHash: databaseHash, location: "databasePage"})
        this.props.toggleModal(modalName + "Modal")
    }

    openTutorialModal = (modalName) => {
        this.props.toggleModal(modalName)
    }

    goToQueryPage = (e, queryInfo, idx) => {
        let classNames;
        try {
            classNames = e.target.className
        } catch(err) {
            classNames = ""
        }

        if (classNames === null){
            classNames = ""
        }

        if (classNames.indexOf("databaseAPIRemoveBtn") === -1){
            if (queryInfo.isTested && 'fileHash' in queryInfo){
                this.props.navigateTo("/files/" + queryInfo['fileHash'])
            } else {
                this.props.modifyEditQueryInfo({queryPK: queryInfo.queryPK, isNew: false, isInRedux: true, dbHash: getURLParam(window.location.href), idx: idx})
                this.props.modifyModalInfo({queryPK: queryInfo.queryPK, isNew: false, isInRedux: true, dbHash: getURLParam(window.location.href), idx: idx})
                this.props.navigateTo("/database-edit-api/")
            }
        }
    }

    changeMenuItem = (e, menuItem) => {
        if (menuItem === "databaseInfo"){
            const databaseHash = getURLParam(window.location.href);

            this.props.modifyModalInfo({ databaseHash: databaseHash, location: "databasePage"})
        }
        this.setState({ menuItem: menuItem })
    }

    render() {
        const dbInfo = this.props.allDBInfo['dbInfo'];
        const btnStyle = {margin: "10px 0 10px 0"};

        let error = null;

        if (!dbInfo.canConnect){
            error = <Message
                negative
                icon='warning sign'
                header='We Were Unable to Connect to Your Database'
                content={<div>
                    <p>This could be happening if the connection settings were changed and not updated. You can try updating them in the Edit Database section under <b>Actions</b>.</p>
                    <p>Or read our <span className="linkStyle" onClick={e => this.openTutorialModal("databaseConnectionErrorModal")}>Troubleshoot Database Connection</span> guide for help</p></div>}
            />
        }

        const allQueries = this.props.allQueries

        let queryRows;

        if (allQueries.length === 0){
            queryRows =
                <div style={{marginTop: "16px"}}>
                    <div style={{fontFamily: 'Lato', color: "#E27837", fontWeight: "700", fontSize: "14px"}}>You don't have any APIs yet</div>
                </div>
        } else{
            let dbHashURL;
            try {
                dbHashURL = "/" + getURLParam(window.location.href)
            } catch (e){
                dbHashURL = ""
            }

            let segmentQueryRows = allQueries.map((x, idx) =>
                <div className="databaseAPIRows filesTabFileSummaryRow" onClick={e => this.goToQueryPage(e, x, idx)}>
                    <div style={{paddingLeft: "4px"}}>
                        <div style={{borderRadius: "40px", padding: "10px", background: "#F0F0F0", width: "40px", height: "40px", textAlign: "center"}}>
                            <i className={x.isTested ? 'bx bxs-data' : "bx bx-code-alt"} style={{color: "#5499F8", fontSize: "130%"}}/>
                        </div>
                    </div>
                    <div>
                        <span key={idx} style={{fontFamily: "Lato", fontWeight: "500", color: "#161E16", fontSize: "14px", paddingLeft: "16px"}}>{x.name.trim() === "" ? <span style={{color: "red"}}>No name given</span> : x.name.trim()}</span>
                    </div>
                    <div>
                        <QueryStatusTag isSaved={x.isTested}/>
                    </div>
                    <div>
                        <p>{x.updatedDt}</p>
                    </div>
                    <div style={{paddingLeft: "-4px",}}>
                        <button style={{width:'84px',height:'36px',backgroundColor:'#FFFFFF',border:'1px solid #6FBB98',borderRadius:'4px', color:"#6FBB98"}} onClick={e => this.goToQueryPage(e, x, idx)}>Details</button>
                    </div>
                    <div style={{textAlign: "center"}} className="databaseAPIRemoveBtn">
                        <Popup
                            trigger={<Icon style={{width:'6px',height:'8px', cursor: "pointer"}} size="large" basic color='red' name='trash alternate outline' className="databaseAPIRemoveBtn" />}
                            on='click'
                            content={<div>
                                <p>Are you sure you want to delete this API?</p>
                                <p>This action cannot be undone</p>
                                <DeleteEditAPI dbHashURL={dbHashURL} queryPK={x.queryPK} queryIdx={idx}/>
                            </div>}
                        />
                    </div>
                </div>
            )

            const headerStyle = {fontFamily: 'Lato', fontWeight: '700', fontSize: '14px', color: '#161E16'}

            queryRows = <div>
                <div className="databaseAPIRows" style={{paddingBottom: "0"}}>
                    <div>
                        <div style={{borderRadius: "40px", padding: "10px", background: "#F0F0F0", width: "40px", height: "40px", textAlign: "center", visibility: "hidden"}}>
                            <i className="bx bx-group" style={{color: "#5499F8", fontSize: "130%"}}/>
                        </div>
                    </div>
                    <div style={headerStyle}>API Name</div>
                    <div style={headerStyle}>Status</div>
                    <div style={headerStyle}>Last Updated</div>
                    <div> </div>
                    <div> </div>
                </div>
                {segmentQueryRows}
            </div>
        }

        let actionButtons = [
            <div style={btnStyle} key={0}><Button fluid content="Edit" icon="edit alternate icon" basic color="blue" onClick={(e) => this.openModal(e, "editDatabase")}/></div>,
            <div style={btnStyle} key={2}><Button fluid content="Delete" icon="trash alternate icon" basic color="red" onClick={(e) => this.openModal(e, "deleteDatabase")}/></div>
        ]

        const displayMap = {
            api: <div>
                <div style={{marginBottom: "16px"}}>
                    <DisplayHeader header="APIs"/>
                </div>
                <div style={{marginBottom: "16px"}}>
                    <AddDBAPIButton/>
                </div>
                {queryRows}
            </div>,
            databaseInfo: <div>
                <EditDatabaseModalBody />
            </div>
        }

        return (
            <div>
                {error}
                <a href={this.state.url} style={{display: "none"}} ref={reportLink => this.reportLink = reportLink} />
                <div className="filesIDDisplayIndex">
                    <div className="filesIDDisplayMenu">
                        <div style={{margin: "16px 0px 0px 0px", fontFamily: "Lato", fontWeight: "700", fontSize: "12px"}}>
                            Menu
                        </div>
                        <MenuSegment label="APIs" icon='code-alt' name="api" menuItem={this.state.menuItem} onClick={this.changeMenuItem} />
                        <MenuSegment label="Connection Settings" icon='cog' name="databaseInfo" menuItem={this.state.menuItem} onClick={this.changeMenuItem} />
                    </div>
                    <div className="filesIDDisplay">
                        <div className="displayBox">
                            {displayMap[this.state.menuItem]}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allDBInfo: state.mainState.allDBInfo,
    allQueries:state.mainState.allQueries
})

const mapActionsToProps = {
    modifyModalInfo: modifyModalInfo,
    modifyAllDBInfo: modifyAllDBInfo,
    toggleModal: toggleModal,
    modifyEditQueryInfo: modifyEditQueryInfo,
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(DatabaseDisplay)