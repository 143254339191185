import React, {Component} from 'react'
import TierRestrictionBanner from "../Actions/TierRestrictionBanner";
import DocsImage from "../DocsImage";
import SilentFail from "./SilentFail";

class DatabaseSelectTables extends Component {
    getLink = (link, text) => {
        return <span className="linkStyle" onClick={e => this.props.setBothMenuItems(link)}>{text}</span>
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "-8px", marginBottom: "16px"}}>
                    <TierRestrictionBanner plan="business" payment="database"/>
                </div>
                <div className="docsSection">
                    <p>After you have <span className="linkStyle" onClick={e => this.props.setBothMenuItems("database-connect")}>connected your database</span> then you can select table(s) to store the imported sheets data</p>
                    <p>In order for data from the imported spreadsheets to be stored in the database, <span className="linkStyle" onClick={e => this.props.setBothMenuItems("creatingImporter-columns")}>columns need to be setup</span> first</p>
                    <p>All the columns that you set up MUST be in the database table(s) you select. Any columns in the database table(s) that are not in the columns setup will be stored as <b>NULL</b> from the imported spreadsheet</p>
                    <p>To illustrate the column setup to save data to a table, let's walk through a few scenarios</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Text, Number, Boolean, and Datetime data types</p>
                    <p>Let's say I wish to save the imported spreadsheet data to the following MySQL table (Users) which has five columns: </p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterDatabase-DescribeTable.png"
                               alt='Database table info' />
                    <p>I only wish to save data to the <b>user_name</b>, <b>age</b>, and <b>sign_up_date</b> columns</p>
                    <p>I can then set up my columns with these rules</p>
                    <ol>
                        <li><b>Internal Name</b> must match the column name in the table identically</li>
                        <li><b>Type</b> must match the general column type of the database table. If a value violates the column type then that row will not be inserted</li>
                    </ol>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterDatabase-Columns.png"
                               width="100%"
                               alt='Database Columns' />
                    <p>Now, when a spreadsheet such as the following is imported, and the columns are matched in the import process, the data will be stored in the database table</p>
                    <div className="row">
                        <div className="col-sm-4">
                            <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterDatabase-Spreadsheet.png"
                                       alt='Database Columns' />
                        </div>
                        <div className="col-sm-6">
                            <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterDatabase-TableData.png"
                                       alt='Database Columns' />
                        </div>
                    </div>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Auto-increment ID column</p>
                    <p>Many tables have an auto-incremented numerical ID column</p>
                    <p>You <b>DO NOT</b> need to set up this column for your importer. Our data saving algorithm automatically takes care of creating the ID when inserting the data</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Silent Failing</p>
                    <SilentFail destination="database" />
                </div>
            </div>
        )
    }
}

export default DatabaseSelectTables