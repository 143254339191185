import React, {Component} from 'react'

class StartDelete extends Component {
    render() {
        return (
            <span>
                <div style={this.props.headerStyle}>1. Query will be in a similar format to SQL with some exceptions</div>
                <p>Only <b>SELECT</b>, <b>FROM</b> and <b>WHERE</b> identifiers are allowed. Other SQL identifiers like <b>ORDER BY</b> and <b>GROUP BY</b> are not allowed</p>
                <div className="codeTheoryBox">
                    <span>DELETE FROM <code>fileID</code> WHERE <code>filterClause</code></span>
                </div>
                <br/>
                <div className="codeExampleBox">
                    <b>Valid</b>
                    <span>DELETE FROM <code>{this.props.fileID}</code> WHERE <code>{"country LIKE 'United%' OR country='Australia' OR country='Indonesia'"}</code></span>
                </div>
                <br/>
                <div className="codeInvalidBox">
                    <b>Invalid</b>
                    <span>DELETE FROM <code>{this.props.fileID}</code> WHERE <code>id>5</code> GROUP BY <code>date desc</code></span>
                </div>
            </span>
        )
    }
}

export default StartDelete