import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import {toggleModal} from "../Actions/ToggleModal";
import {connect} from "react-redux";

class UploadFilesNonAuth extends Component {
    constructor(props) {
        super(props);

        this.authUser = this.authUser.bind(this);
    }

    authUser(){
        this.props.toggleModal("needAuthModal")
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-1" />
                <div className="col-sm-10">
                    <div className="uploadOuterSection" onClick={this.authUser}>
                        <div className="uploadInnerSection">
                            <div style={{textAlign: 'center'}}>
                                <span className="uploadIcons">
                                    <Icon name="file excel outline" />
                                    <Icon name="file alternate outline" />
                                </span>
                                <h4 className="thinHeading">Upload or Drop Files</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1" />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(UploadFilesNonAuth)