import React, {Component} from 'react'

class InputDataTable extends Component {
    render() {
        const tableStyle = {};
        const theadStyle = {};
        const thRowStyle = {};
        const thStyle = {
            border: "2px solid #F0F0F0"
        };

        const tbodyStyle = {};
        const trStyle = {};
        const tdStyle = {
            border: "1px solid #F0F0F0",
            paddingLeft: "4px"
        }

        if (this.props.isMobile){
            return (
                <div>
                    <table style={tableStyle}>
                        <thead style={theadStyle}>
                        <tr style={thRowStyle}>
                            <th style={thStyle}>
                                Title
                            </th>
                            <th style={thStyle}>
                                Actors
                            </th>
                            <th style={thStyle}>
                                ...
                            </th>
                        </tr>
                        </thead>
                        <tbody style={tbodyStyle}>
                        <tr style={trStyle}>
                            <td style={tdStyle}>
                                Guardians of the Galaxy
                            </td>
                            <td style={tdStyle}>
                                Chris Pratt, Bradley Cooper, Zoe Saldana
                            </td>
                            <td style={tdStyle}>
                                ...
                            </td>
                        </tr>
                        <tr style={trStyle}>
                            <td style={tdStyle}>
                                Prometheus
                            </td>
                            <td style={tdStyle}>
                                Noomi Rapace, Michael Fassbender
                            </td>
                            <td style={tdStyle}>
                                ...
                            </td>
                        </tr>
                        <tr style={trStyle}>
                            <td style={tdStyle}>
                                Split
                            </td>
                            <td style={tdStyle}>
                                James McAvoy, Anya Taylor-Joy
                            </td>
                            <td style={tdStyle}>
                                ...
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            )
        }

        return (
            <div>
                <table style={tableStyle}>
                    <thead style={theadStyle}>
                        <tr style={thRowStyle}>
                            <th style={thStyle}>
                                Title
                            </th>
                            <th style={thStyle}>
                                Director
                            </th>
                            <th style={thStyle}>
                                Year
                            </th>
                            <th style={thStyle}>
                                Actors
                            </th>
                            <th style={thStyle}>
                                ...
                            </th>
                        </tr>
                    </thead>
                    <tbody style={tbodyStyle}>
                        <tr style={trStyle}>
                            <td style={tdStyle}>
                                Guardians of the Galaxy
                            </td>
                            <td style={tdStyle}>
                                James Gunn
                            </td>
                            <td style={tdStyle}>
                                2014
                            </td>
                            <td style={tdStyle}>
                                Chris Pratt, Bradley Cooper, Zoe Saldana
                            </td>
                            <td style={tdStyle}>
                                ...
                            </td>
                        </tr>
                        <tr style={trStyle}>
                            <td style={tdStyle}>
                                Prometheus
                            </td>
                            <td style={tdStyle}>
                                Ridley Scott
                            </td>
                            <td style={tdStyle}>
                                2012
                            </td>
                            <td style={tdStyle}>
                                Noomi Rapace, Michael Fassbender, Charlize Theron
                            </td>
                            <td style={tdStyle}>
                                ...
                            </td>
                        </tr>
                        <tr style={trStyle}>
                            <td style={tdStyle}>
                                Split
                            </td>
                            <td style={tdStyle}>
                                M. Night Shyamalan
                            </td>
                            <td style={tdStyle}>
                                2016
                            </td>
                            <td style={tdStyle}>
                                James McAvoy, Anya Taylor-Joy, Haley Lu Richardson
                            </td>
                            <td style={tdStyle}>
                                ...
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default InputDataTable