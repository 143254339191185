import React, {Component} from 'react'
import {connect} from 'react-redux'
import RoleTag from "../Roles/RoleTag";
import { Button, Label } from 'semantic-ui-react';
import API_Root from "../../Constants/API_Root";
import {modifyPendingInvites} from "../../Actions/ModifyPendingInvites";
import {modifyTeamInfo} from "../../Actions/ModifyTeamInfo";
import {convertServerDateToDisplayDate} from "../../Constants/convertServerDateToDisplayDate";
import BannerAlert from "../../SharedComponents/BannerAlert";

class PendingTeamInvitesRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPerformingAction: false,
            error: false,
            errorMessage: ""
        }

        this.acceptOrDeclineInvite = this.acceptOrDeclineInvite.bind(this);
        this.updateTeamInfo = this.updateTeamInfo.bind(this);
    }

    updateTeamInfo(teamInfo, accept){
        // Remove from pending invites. If they accept add new team info if they cancel don't do anything
        if (accept){
            let newTeamInfo;

            if (this.props.teamInfo === null){
                newTeamInfo = [teamInfo]
            } else {
                newTeamInfo = JSON.parse(JSON.stringify(this.props.teamInfo));

                let teamIdx = -1;
                for (let i=0; i<newTeamInfo.length; i++){
                    if (newTeamInfo[i].id === teamInfo.id){
                        teamIdx = i;
                        break
                    }
                }

                if (teamIdx !== -1){
                    newTeamInfo[teamIdx] = teamInfo
                } else {
                    newTeamInfo.push(teamInfo)
                }
            }

            this.props.modifyTeamInfo(newTeamInfo)
        }

        const copiedPendingTeamInvites = JSON.parse(JSON.stringify(this.props.pendingInvites));
        copiedPendingTeamInvites.splice(this.props.idx, 1)

        this.props.modifyPendingInvites(copiedPendingTeamInvites);
    }

    acceptOrDeclineInvite(e, accept){
        this.setState({ isPerformingAction: true })
        const hitError = (errorMessage="There was an error! Please try again") => {this.setState({ isPerformingAction: false, error: true, errorMessage: errorMessage })}
        const url = API_Root + "api/remove-add-or-edit-api-team-member/";

        fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                inviteEmail: localStorage.getItem("email"),
                teamPK: this.props.info.team_id,
                role: this.props.info.role,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                adminAction: false,
                operation: accept ? "accept" : "decline"
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    let errorMessage = "";
                    if (accept){
                        errorMessage = "Congrats! You can check out your new team below!"
                    } else {
                        errorMessage = "Sorry to hear you did not want to be part of this team!"
                    }
                    this.setState({ isPerformingAction: false, error: false, errorMessage: errorMessage }, () => this.updateTeamInfo(data.teamInfo, accept))
                }).catch(err => hitError("There was an error. Please refresh!"))
            } else {
                hitError("There was an error. Please refresh. Contact us if error persists.")
            }
        }).catch(err => hitError("There was an error. Please refresh. Contact us if error persists."))

    }

    render() {
        const textStyle = {marginBottom: "4px"}

        return (
            <BannerAlert
                type="success"
                header="You have been invited to join a Team"
                content={<div>
                    <div style={{display: "grid", gridTemplateColumns: "105px 1fr"}}>
                        <div style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#5E645E"}}>
                            <div style={textStyle}>Invited by</div>
                            <div style={textStyle}>Invited at</div>
                            <div style={textStyle}>Team Name</div>
                            <div style={textStyle}>Role</div>
                        </div>
                        <div style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#161E16"}}>
                            <div style={textStyle}>{this.props.info.admin_email}</div>
                            <div style={textStyle}>{convertServerDateToDisplayDate(this.props.info.created_dt)}</div>
                            <div style={textStyle}>{this.props.info.team_name}</div>
                            <div style={textStyle}><RoleTag userRole={this.props.info.role}/></div>
                        </div>
                    </div>
                </div>}
                action={<div style={{paddingTop: "5%"}}>
                    <Button content="Accept Invite" icon="check" style={{color: "white", backgroundColor: "#6FBB98"}} loading={this.state.isPerformingAction} onClick={(e) => this.acceptOrDeclineInvite(e, true)} />
                    <Button content="Decline Invite" icon="remove" style={{color: "white", backgroundColor: "#EE685F"}} loading={this.state.isPerformingAction} onClick={(e) => this.acceptOrDeclineInvite(e, false)} />
                </div>}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    pendingInvites: state.mainState.pendingInvites,
    teamInfo: state.mainState.teamInfo,
})

const mapActionsToProps = {
    modifyPendingInvites: modifyPendingInvites,
    modifyTeamInfo: modifyTeamInfo,
}

export default connect(mapStateToProps, mapActionsToProps)(PendingTeamInvitesRow)