import React, {Component} from 'react'
import HeroImg from "./Illustrations/HeroImg.svg";
import ImporterTable from "./FeaturesTables/ImporterTableContent/ImporterTable";
// import SilcaLogo from "../Constants/SilcaLogo.png";
import { Icon } from 'semantic-ui-react'
import UpdatedCTA from "./UpdatedCTA";
import SignUp2Wave from "./Illustrations/SignUp2Wave.svg";
import SignUp3Wave from "./Illustrations/SignUp3Wave.svg";
import SignUp4Wave from "./Illustrations/SignUp4Wave.svg";
import SignUp5Wave from "./Illustrations/SignUp5Wave.svg";
import FeatureCard from "./FeatureCard";
import SaveTime from "./Illustrations/SaveTime.svg"
import Editing from "./Illustrations/Editing.svg"
import MinimalCoding from "./Illustrations/MinimalCoding.svg"
import APIQuickstartCode from "../InfoPages/HowItWorks/Features/APIQuickstartCode.js"
import CustomerCarousel from "../InfoPages/CustomerCarousel";
import APIDatabaseTable from "./FeaturesTables/APIDatabaseTableContent/APIDatabaseTable";
import CollaborateTeamTable from "./FeaturesTables/CollaborateTeamTableContent/CollaborateTeamTable";
import ImporterIcon from "./Illustrations/ImporterIcon.svg"
import ImportStep1 from "./Illustrations/ImporterStep1.svg"
import ImportStep2 from "./Illustrations/ImporterStep2.svg"
import ImportStep3 from "./Illustrations/ImporterStep3.svg"
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import CollaborateIllustration from "./Illustrations/CollaborateIllustration.svg";
import ImportIllustration from "./Illustrations/ImportIllustration.svg"
import IntegrateIllustration from "./Illustrations/IntegrateIllustration.svg"
import {getHeroText} from "./getHeroText";
import PreURL from "./FeaturesTables/ImporterTableContent/PreURL"
import ColMatching from "./FeaturesTables/ImporterTableContent/ColMatching";
import SheetsDestination from "./FeaturesTables/ImporterTableContent/SheetsDestination";
import CustomAppearance from "./FeaturesTables/ImporterTableContent/CustomAppearance";
import GetAnAPI from "./FeaturesTables/ImporterTableContent/GetAnAPI";
import PrewrittenCode from "./FeaturesTables/ImporterTableContent/PrewrittenCode";
import HowItWorksRow from "./FeaturesTables/HowItWorksRow";
import MultipleSources from "./FeaturesTables/APIDatabaseTableContent/MultipleSources";
import CrudActionsDesc from "./FeaturesTables/APIDatabaseTableContent/CrudActionsDesc";
import SimpleDesign from "./FeaturesTables/APIDatabaseTableContent/SimpleDesign";
import QueriesDesc from "./FeaturesTables/APIDatabaseTableContent/QueriesDesc";
import CachingLayer from "./FeaturesTables/APIDatabaseTableContent/CachingLayer";
import LandingSecurity from "./FeaturesTables/APIDatabaseTableContent/LandingSecurity";
import SecurityDesc from "./FeaturesTables/APIDatabaseTableContent/SecurityDesc";
import APIStep1 from "./Illustrations/APIStep1.svg"
import APIStep2 from "./Illustrations/APIStep2.svg"
import APIStep3 from "./Illustrations/APIStep3.svg"
import Number1 from "./Illustrations/Number1.svg"
import Number2 from "./Illustrations/Number2.svg"
import Number3 from "./Illustrations/Number3.svg"
import HowItWorksSegment from "./FeaturesTables/HowItWorksSegment"
import AutoReportingDesc from "./FeaturesTables/CollaborateTeamTableContent/AutoReportingDesc";
import TeamsDesc from "./FeaturesTables/CollaborateTeamTableContent/TeamsDesc";
import MultipleKeysDesc from "./FeaturesTables/CollaborateTeamTableContent/MultipleKeysDesc";
import MonitoringAccessDesc from "./FeaturesTables/CollaborateTeamTableContent/MonitoringAccessDesc";
import Encrypt from "./Illustrations/Encrypt.svg"
import gdpr from "./Illustrations/gdpr.svg";
import Prem from "./Illustrations/Prem.svg"
import DataModel from "./FeaturesTables/ImporterTableContent/DataModel";
import ImportWebhooks from "./FeaturesTables/ImporterTableContent/ImportWebhooks";
import APISideBanner from "./Illustrations/APISideBanner.svg"
import {isEven} from "../Constants/isEven";
import GunaldiHeroImage from "./Illustrations/GunaldiHeroImage.svg"
import GunaldiHeroFinal from "./Illustrations/GunaldiHeroFinal.svg"
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import GunaldiCTA from "./GunaldiCTA";
import GunaldiCreateImporter from "./Illustrations/GunaldiCreateImporter.svg"
import ConvertSpreadsheetsToAPI from "./Illustrations/ConvertSpreadsheetsToAPI.svg"
import RequestDemoSegment from "./RequestDemoSegment";
import LandingConvertSpreadsheetsToAPI from "./Illustrations/LandingConvertSpreadsheetsToAPI.svg"
import LandingApps from "./Illustrations/LandingApps.svg"
import LandingDashboard from "./Illustrations/LandingDashboard.svg"
import LandingForms from "./Illustrations/LandingForms.svg"
import LandingImport from "./Illustrations/LandingImport.svg"
import LandingReport from "./Illustrations/LandingReport.svg"
import LandingIntegrate from "./Illustrations/LandingIntegrate.svg"
import HeroText from "./HeroText";
import LandingPageFeatureSegment from "./LandingPageFeatureSegment";
import UploadSources from "./Illustrations/UploadSources.svg"
import GunaldiCreateImporterSegment from "./Illustrations/GunaldiCreateImporterSegment.svg";
import ConnectDomain from "./FeaturesTables/APIDatabaseTableContent/ConnectDomain";
import UtilitiesDisplayIndex from "./UtilitiesDisplay/UtilitiesDisplayIndex";
import RulesEngine from "./FeaturesTables/APIDatabaseTableContent/RulesEngine";
import {isURLCalculator} from "../Constants/isURLCalculator";
import DeveloperOrBusinessRow from "./DeveloperOrBusinessRow";
import SquareLoader from "./SquareLoader";
import HowItWorks from "./Calculator/HowItWorks/HowItWorks";

class UpdatedLandingPageIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            buttonMargin: this.getResponsiveStyle("buttonMargin"),
            heroClassName: this.getResponsiveStyle("heroClassName"),
            headerFontSize: this.getResponsiveStyle("headerFontSize"),
            showHero: true,
            apiCount: "200+",
            isEven: isEven(),
            isCalculator: isURLCalculator(window.location.href, true),
            isDeveloper: true
        }

        this.updateDimensions = this.updateDimensions.bind(this);
        this.getResponsiveStyle = this.getResponsiveStyle.bind(this);
    }

    changeState = (newState) => this.setState(newState);

    getResponsiveStyle(style, updateState=false){
        let styleAttr;

        if (window.innerWidth >= 1280){
            if (style === "buttonMargin"){
                styleAttr = Math.floor((window.innerWidth - 1280) * 0.2) + 50
            } else if (style === "heroClassName"){
                styleAttr = "updatedLandingHero"
            } else if (style === "headerFontSize"){
                styleAttr = Math.floor((window.innerWidth - 1280) * 0.2) + 300
            } else if (style === "showHero"){
                styleAttr = true
            }
        } else {
            if (window.innerWidth < 1100){
                if (style === "showHero"){
                    styleAttr = false
                }
            } else {
                if (style === "showHero"){
                    styleAttr = true
                }
            }

            if (style === "buttonMargin"){
                styleAttr = 50
            } else if (style === "heroClassName"){
                styleAttr = "updatedLandingHero"
            } else if (style === "headerFontSize"){
                styleAttr = 300
            }
        }

        if (updateState){
            this.setState({ [style]: styleAttr })
        } else {
            return styleAttr
        }
    }

    updateDimensions = () => {
        this.getResponsiveStyle("showHero", true)
        this.getResponsiveStyle("buttonMargin", true)
        this.getResponsiveStyle("heroClassName", true)
        this.getResponsiveStyle("headerFontSize", true)
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render(){
        console.log(this.state.isCalculator)
        const featureMenuHeaderStyle = {fontFamily: "Lato", fontWeight: "700", color: "#161E16", fontSize: "24px", textAlign: "center", marginBottom: "12px"}
        const featureMenuSubheadingStyle = {fontFamily: "Lato", fontWeight: "400", color: "#5E645E", fontSize: "14px", textAlign: "center", marginBottom: "24px"}

        const sectionHeaderStyle = {textAlign: "center", paddingBottom: "80px", color: "#161E16", fontWeight: "700", fontFamily: "Lato", fontSize: "32px"};
        const featureDivStyle = {margin: "160px 200px 160px 200px"};

        return (
            <div style={{minHeight: '100vh'}}>
                {
                    this.state.showHero ?
                        <div className={this.state.heroClassName}>
                            <div>
                                <div style={{margin: '60px 0 0 100px'}}>
                                    <HeroText headerFontSize={this.state.headerFontSize} isCalculator={this.state.isCalculator} />
                                </div>
                                {/*<UpdatedCTA style={{marginTop: this.state.buttonMargin.toString() + "px", marginLeft: '10%'}}/>*/}
                            </div>
                            <div>
                                <img src={GunaldiHeroFinal} style={{width: '100%', height: 'auto', paddingRight: '5%'}} alt="Hero Spreadsheet Flow"/>
                            </div>
                        </div> :
                        <div style={{margin: '60px', textAlign: 'center'}}>
                            <HeroText headerFontSize={this.state.headerFontSize} isCalculator={this.state.isCalculator}/>
                        </div>
                }
                <div className="updatedLandingSocialProof">
                    <div style={{display: "grid", gridTemplateColumns: "auto 1fr", columnGap: this.state.isCalculator ? "0" : "100px"}}>
                        {
                            this.state.isCalculator ?
                                <div></div> :
                                <div style={{
                                    fontFamily: "Lato",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    color: "#161E16",
                                    paddingTop: "20px"
                                }}>
                                    Trusted By
                                </div>
                        }
                        <div>
                            <CustomerCarousel isCalculator={this.state.isCalculator} />
                        </div>
                    </div>
                </div>
                {
                    this.state.isCalculator ?
                        <div style={{padding: "240px 0 48px 0"}}>
                            <DeveloperOrBusinessRow isDeveloper={this.state.isDeveloper} changeState={this.changeState} />
                            <div style={{padding: "120px 0 0 0"}}>
                                <HowItWorks isDeveloper={this.state.isDeveloper} changeState={this.changeState} />
                            </div>

                        </div> :
                        <div>
                            <div style={{padding: "100px 0 160px 0", backgroundColor: '#F4FBF3', marginTop: "160px"}}>
                                <div style={{padding: "0 200px 0 200px"}}>
                                    <div style={sectionHeaderStyle}>
                                        Instantly Use Spreadsheets as an <span
                                        style={{color: gunaldiStyles.mainGreen}}>API</span> or Database
                                    </div>
                                    <img src={LandingConvertSpreadsheetsToAPI} style={{width: "100%"}}
                                         alt="Convert File to API"/>
                                </div>
                                <div style={{padding: "120px 100px 0px 100px"}}>
                                    <ImporterTable
                                        menuDisplay={{
                                            "multipleSources": <MultipleSources headerStyle={featureMenuHeaderStyle}
                                                                                subheadingStyle={featureMenuSubheadingStyle}/>,
                                            "crudActions": <CrudActionsDesc headerStyle={featureMenuHeaderStyle}
                                                                            subheadingStyle={featureMenuSubheadingStyle}/>,
                                            "simpleDesign": <SimpleDesign headerStyle={featureMenuHeaderStyle}
                                                                          subheadingStyle={featureMenuSubheadingStyle}/>,
                                            "queriesDesc": <QueriesDesc headerStyle={featureMenuHeaderStyle}
                                                                        subheadingStyle={featureMenuSubheadingStyle}/>,
                                            // "security": <LandingSecurity headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,
                                            // "cachingLayer": <CachingLayer headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,
                                            "connectDomain": <ConnectDomain headerStyle={featureMenuHeaderStyle}
                                                                            subheadingStyle={featureMenuSubheadingStyle}/>,
                                            "runMacros": <RulesEngine headerStyle={featureMenuHeaderStyle}
                                                                      subheadingStyle={featureMenuSubheadingStyle}/>
                                        }}
                                        menuItems={[
                                            {
                                                name: "multipleSources",
                                                displayName: "Multiple Sources",
                                                header: "Spreadsheets as an API"
                                            },
                                            {
                                                name: "crudActions",
                                                displayName: "CRUD Actions",
                                                header: "Spreadsheets as an API"
                                            },
                                            {
                                                name: "simpleDesign",
                                                displayName: "Simple API Design",
                                                header: "Spreadsheets as an API"
                                            },
                                            {
                                                name: "queriesDesc",
                                                displayName: "SQL Queries",
                                                header: "Spreadsheets as an API"
                                            },
                                            // {name:"security",displayName: "Security", header: "Spreadsheets as an API"},
                                            // {name:"cachingLayer",displayName: "Caching Layer", header: "Spreadsheets as an API"},
                                            {
                                                name: "runMacros",
                                                displayName: "Rules Engine",
                                                header: "Spreadsheets as an API"
                                            },
                                        ]}
                                        horizontal
                                    />
                                </div>
                            </div>
                            <div style={{textAlign: 'center', paddingTop: "120px"}}>
                                <GunaldiCTA/>
                            </div>
                            <div style={{padding: "160px 120px 0px 100px"}}>
                                <div style={sectionHeaderStyle}>
                                    Create a Spreadsheet <span style={{color: gunaldiStyles.orange}}>Importer</span> in
                                    Minutes
                                </div>
                                <div>
                                    {
                                        this.state.showHero ?
                                            <img src={GunaldiCreateImporterSegment} alt="Create Importer Full"
                                                 style={{width: "100%"}}/> :
                                            <HowItWorksRow
                                                info={[
                                                    {
                                                        header: "Customize your importer",
                                                        body: <img src={ImportStep1}
                                                                   alt="Customize Importer Illustrator" width="100%"/>
                                                    },
                                                    {
                                                        header: "Users upload spreadsheets",
                                                        body: <img src={ImportStep2} alt="User uploading spreadsheets"
                                                                   width="100%"/>
                                                    },
                                                    {
                                                        header: "Use imported data",
                                                        body: <img src={ImportStep3} alt="User imported data"
                                                                   width="100%"/>
                                                    },
                                                ]}
                                            />
                                    }
                                </div>
                                <div style={{paddingTop: "160px", paddingBottom: "160px"}}>
                                    <ImporterTable
                                        menuDisplay={{
                                            "dataModel": <DataModel headerStyle={featureMenuHeaderStyle}
                                                                    subheadingStyle={featureMenuSubheadingStyle}/>,
                                            "prewrittenCode": <PrewrittenCode headerStyle={featureMenuHeaderStyle}
                                                                              subheadingStyle={featureMenuSubheadingStyle}/>,
                                            "prebuiltURL": <PreURL headerStyle={featureMenuHeaderStyle}
                                                                   subheadingStyle={featureMenuSubheadingStyle}/>,
                                            "colMatching": <ColMatching headerStyle={featureMenuHeaderStyle}
                                                                        subheadingStyle={featureMenuSubheadingStyle}/>,
                                            "sheetsDestination": <SheetsDestination headerStyle={featureMenuHeaderStyle}
                                                                                    subheadingStyle={featureMenuSubheadingStyle}/>,
                                            "appearance": <CustomAppearance headerStyle={featureMenuHeaderStyle}
                                                                            subheadingStyle={featureMenuSubheadingStyle}/>,
                                            "api": <GetAnAPI headerStyle={featureMenuHeaderStyle}
                                                             subheadingStyle={featureMenuSubheadingStyle}/>,
                                            "webhooks": <ImportWebhooks headerStyle={featureMenuHeaderStyle}
                                                                        subheadingStyle={featureMenuSubheadingStyle}/>
                                        }}
                                        menuItems={[
                                            {
                                                name: "dataModel",
                                                displayName: "Data Model",
                                                header: "Customize your importer"
                                            },
                                            {
                                                name: "appearance",
                                                displayName: "Custom Appearance",
                                                header: "Customize your importer"
                                            },
                                            {
                                                name: "sheetsDestination",
                                                displayName: "Multiple Destinations",
                                                header: "Customize your importer"
                                            },
                                            {
                                                name: "prebuiltURL",
                                                displayName: "Personalized URL",
                                                header: "Users upload spreadsheets"
                                            },
                                            {
                                                name: "prewrittenCode",
                                                displayName: "Your Applications",
                                                header: "Users upload spreadsheets"
                                            },
                                            {
                                                name: "colMatching",
                                                displayName: "Column Mapping",
                                                header: "Users upload spreadsheets"
                                            },
                                            {name: "webhooks", displayName: "Webhooks", header: "Use imported data"},
                                            {name: "api", displayName: "REST API", header: "Use imported data"}
                                        ]}
                                        importer
                                    />
                                </div>
                            </div>
                            <div style={{textAlign: 'center', paddingBottom: "160px"}}>
                                <GunaldiCTA/>
                            </div>
                            <div style={{padding: "160px 120px 0px 100px"}}>
                                <div style={sectionHeaderStyle}>
                                    Query your data in natural language, and much more, with our <span
                                    style={{color: gunaldiStyles.blue}}>Utility</span> APIs
                                </div>
                                {/*<div>*/}
                                {/*{*/}
                                {/*this.state.showHero ?*/}
                                {/*<img src={GunaldiCreateImporterSegment} alt="Create Importer Full" style={{width: "100%"}}/> :*/}
                                {/*<HowItWorksRow*/}
                                {/*info={[*/}
                                {/*{header: "Customize your importer", body: <img src={ImportStep1} alt="Customize Importer Illustrator" width="100%"/>},*/}
                                {/*{header: "Users upload spreadsheets", body: <img src={ImportStep2} alt="User uploading spreadsheets" width="100%"/>},*/}
                                {/*{header: "Use imported data", body: <img src={ImportStep3} alt="User imported data" width="100%"/>},*/}
                                {/*]}*/}
                                {/*/>*/}
                                {/*}*/}
                                {/*</div>*/}
                                <div style={{paddingTop: "20px", paddingBottom: "160px"}}>
                                    <ImporterTable
                                        menuDisplay={{
                                            "ai": <UtilitiesDisplayIndex headerStyle={featureMenuHeaderStyle}
                                                                         subheadingStyle={featureMenuSubheadingStyle}
                                                                         mode="ai"/>,
                                            "create": <UtilitiesDisplayIndex headerStyle={featureMenuHeaderStyle}
                                                                             subheadingStyle={featureMenuSubheadingStyle}
                                                                             mode="create"/>,
                                            "download": <UtilitiesDisplayIndex headerStyle={featureMenuHeaderStyle}
                                                                               subheadingStyle={featureMenuSubheadingStyle}
                                                                               mode="download"/>
                                        }}
                                        menuItems={[
                                            {
                                                name: "ai",
                                                displayName: "Query with AI",
                                                header: "Query your data in natural language"
                                            },
                                            {
                                                name: "create",
                                                displayName: "Create File",
                                                header: "Create a spreadsheet with a simple API call"
                                            },
                                            {
                                                name: "download",
                                                displayName: "Download File",
                                                header: "Download .xlsx file with a simple API call"
                                            },
                                        ]}
                                        utility
                                    />
                                </div>
                            </div>
                            <div style={{textAlign: 'center', paddingBottom: "160px"}}>
                                <GunaldiCTA/>
                            </div>
                            <div>
                                <div style={{
                                    textAlign: "center",
                                    paddingBottom: "16px",
                                    color: "#161E16",
                                    fontWeight: "700",
                                    fontFamily: "Lato",
                                    fontSize: "32px"
                                }}>
                                    Use Cases
                                </div>
                                <div style={{
                                    fontFamily: "Lato",
                                    fontWeight: "400",
                                    color: "#5E645E",
                                    fontSize: "14px",
                                    textAlign: "center",
                                    marginBottom: "32px"
                                }}>
                                    Some of our most popular use cases. You can view more use cases <a href="/tutorials"
                                                                                                       target="_blank">here</a>
                                </div>
                                <div className="row" style={{marginBottom: "32px"}}>
                                    <div className="col-sm-4">
                                        <a href="/tutorials/save-web-form-data.html" target="_blank"
                                           className="nostyle">
                                            <FeatureCard
                                                img={LandingForms}
                                                header="web-forms"
                                                desc="Save data from web forms to spreadsheets"
                                            />
                                        </a>
                                    </div>
                                    <div className="col-sm-4">
                                        <a href="/tutorials/import-spreadsheets-on-your-apps.html" target="_blank"
                                           className="nostyle">
                                            <FeatureCard
                                                img={LandingImport}
                                                header="import"
                                                desc="Add a spreadsheet importer on your website"
                                            />
                                        </a>
                                    </div>
                                    <div className="col-sm-4">
                                        <a href="/tutorials/automatically-email-spreadsheets-google-sheets.html"
                                           target="_blank" className="nostyle">
                                            <FeatureCard
                                                img={LandingReport}
                                                header="report"
                                                desc="Automatically send your Google Sheet to your team daily"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <a href="/tutorials/dashboard-with-google-sheets.html" target="_blank"
                                           className="nostyle">
                                            <FeatureCard
                                                img={LandingDashboard}
                                                header="dashboards"
                                                desc="Create a live dashboard with Google Sheets"
                                            />
                                        </a>
                                    </div>
                                    <div className="col-sm-4">
                                        <a href="/tutorials/react-site-with-no-backend.html" target="_blank"
                                           className="nostyle">
                                            <FeatureCard
                                                img={LandingApps}
                                                header="apps"
                                                desc="Use spreadsheet data to power your apps"
                                            />
                                        </a>
                                    </div>
                                    <div className="col-sm-4">
                                        <a href="/tutorials/react-site-with-no-backend.html" target="_blank"
                                           className="nostyle">
                                            <FeatureCard
                                                img={LandingIntegrate}
                                                header="integrate"
                                                desc="Combine spreadsheet data from different sources"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div style={{
                                    fontFamily: "Lato",
                                    fontWeight: "400",
                                    color: "#5E645E",
                                    fontSize: "16px",
                                    textAlign: "center",
                                    marginTop: "40px"
                                }}>
                                    If your use case is not listed and you want to find out if we can support it, email
                                    us at <a href="mailto:sales@apispreadsheets.com">sales@apispreadsheets.com</a>
                                </div>
                            </div>
                        </div>
                }
                <div style={{
                    marginTop: "160px",
                    padding: "80px 100px 80px 100px",
                    backgroundColor: "#6FBB98",
                    color: "white"
                }}>
                    <div style={{
                        textAlign: "center",
                        paddingBottom: "32px",
                        color: "white",
                        fontWeight: "700",
                        fontFamily: "Lato",
                        fontSize: "32px"
                    }}>
                        Security
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <FeatureCard
                                img={Encrypt}
                                header="data-encryption"
                                desc="Your data is encrypted in-transit using HTTPS/SSL and at rest with AWS"
                                white
                            />
                        </div>
                        <div className="col-sm-4">
                            <FeatureCard
                                img={gdpr}
                                header="GDPR-compliant"
                                desc="We will never use your data maliciously as spelled out in our Terms of Service"
                                white
                            />
                        </div>
                        <div className="col-sm-4">
                            <FeatureCard
                                img={Prem}
                                header="on-prem-install"
                                desc="We offer on-prem install and can easily execute a Data Processor agreement"
                                white
                            />
                        </div>
                    </div>
                    <div style={{textAlign: "center", padding: "40px 0 40px 0"}}>
                        <a href="/api-docs/?docsMenuItem=api-security" target="_blank" rel="noopener noreferrer"
                           style={{color: "white", textDecoration: "none", fontSize: "12px", fontFamily: "Lato"}}>Read
                            our full guide on API security <Icon name="arrow right"/></a>
                    </div>
                </div>
                <div style={{textAlign: 'center', margin: "80px 0 80px 0"}}>
                    <GunaldiCTA/>
                </div>
                <div style={{margin: "80px 120px 80px 100px"}}>
                    <RequestDemoSegment/>
                </div>
            </div>
        )
    }
}

export default UpdatedLandingPageIndex