import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Checkbox } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";

class DatabaseTableCheckbox extends Component {
    constructor(props) {
        super(props);
    }

    isCheckboxChecked = () => {
        const accountDetails = this.props.destinationsEdit[this.props.databaseType + "AccountDetails"]
        let isChecked = false

        if (this.props.databasePK in accountDetails){
            const accountInfo = accountDetails[this.props.databasePK]

            if ("databaseTables" in accountInfo){
                const databaseTables = accountInfo['databaseTables']
                if (databaseTables.includes(this.props.tableName)){
                    isChecked = true
                }
            }

        }

        return isChecked
    }

    toggle = () => {
        const destinationsEdit = JSON.parse(JSON.stringify(this.props.destinationsEdit))
        const accountDetails = JSON.parse(JSON.stringify(destinationsEdit[this.props.databaseType + "AccountDetails"]))

        if (this.props.databasePK in accountDetails){
            const accountInfo = accountDetails[this.props.databasePK]

            if ("databaseTables" in accountInfo){
                const databaseTables = accountInfo['databaseTables'];
                if (databaseTables.includes(this.props.tableName)){
                    databaseTables.splice(databaseTables.indexOf(this.props.tableName), 1)

                    if (databaseTables.length === 0){
                        delete accountInfo["databaseTables"]
                    } else{
                        accountInfo['databaseTables'] = databaseTables
                    }
                } else {
                    databaseTables.push(this.props.tableName)
                    accountInfo['databaseTables'] = databaseTables
                }
            } else {
                accountInfo['databaseTables'] = [this.props.tableName]
            }

            accountDetails[this.props.databasePK] = accountInfo
        } else {
            accountDetails[this.props.databasePK] = {"databaseTables": [this.props.tableName]}
        }

        destinationsEdit[this.props.databaseType + "AccountDetails"] = accountDetails

        this.props.modifyCustomImportSettings("destinationsEdit", destinationsEdit)
    }

    render() {
        return (
            <Checkbox toggle checked={this.isCheckboxChecked()} onChange={this.toggle}/>
        )
    }
}

const mapStateToProps = (state) => ({
    destinationsEdit: state.mainState.customImportDestinationsEdit
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(DatabaseTableCheckbox)