import React, {Component} from 'react'
import ModalApp from '../../../SharedComponents/Modal'
import QueryBody from "./QueryBody";

class QueryModal extends Component {
    render() {
        return (
            <div>
                <ModalApp name="queryModal"
                          header="Write a Valid Query"
                          size="large"
                          body={<div><br/><QueryBody/></div>}
                />
            </div>
        )
    }
}

export default QueryModal