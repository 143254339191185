import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
import PageHeaderButton from "../../SharedComponents/PageHeaderButton"

class ImportConfigDeleteBtn extends Component {
    startDeleting = () => {
        this.props.modifyModalInfo({ importHash: this.props.importHash, importName: this.props.importName, idx: this.props.idx, custom: this.props.custom})
        this.props.toggleModal("importConfigDeleteModal")
    }

    render() {
        if ("isImportSegment" in this.props){
            return (
                <Icon style={{width:'6px',height:'8px', cursor: "pointer"}} size="large" onClick={this.startDeleting} basic color='red' name='trash alternate outline' className="importSegmentRowDeleteBtn" />
            )
        } else {
            return (
                <PageHeaderButton
                    content="Delete Importer"
                    color="red"
                    icon="trash alternate outline"
                    onClick={this.startDeleting}
                />
            )
        }
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(ImportConfigDeleteBtn)