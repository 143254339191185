import React, {Component} from 'react'

class ImportInfoRow extends Component {
    render() {
        return (
            <div style={{marginBottom: "20px"}}>
                <div className="row">
                    <div className="col-sm-3">
                        <h4 className="thinHeading text-muted">{this.props.label}</h4>
                    </div>
                    <div className="col-sm-9">
                        {this.props.body}
                    </div>
                </div>
            </div>
        )
    }
}


export default ImportInfoRow