import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import {toggleModal} from "../Actions/ToggleModal";
import {connect} from "react-redux";

class AWSAuth extends Component {
    openAWSModal=()=>{
        this.props.toggleModal('awsModal')
    }
    render() {
        return (
            <div className="row">
                <div className="col-sm-1" />
                <div className="col-sm-10">
                    <div className="uploadOuterSection" onClick={this.openAWSModal}>
                        <div className="uploadInnerSection">
                            <div style={{textAlign: 'center'}}>
                                <span className="uploadIcons">
                                    <Icon name="aws" />
                                </span>
                                <h4 className="thinHeading">AWS S3</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1" />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(AWSAuth)