export const mapPkAndPkid = (filePK, mapType, mapValue) => {
    let returnValue;
    const returnType = mapType === "pk" ? "id" : "pk";

    for (let i=0; i<filePK.length; i++){
        const filePKObject = filePK[i];

        if (filePKObject[mapType] === mapValue){
            returnValue = filePKObject[returnType]
            break
        }
    }

    return returnValue
}