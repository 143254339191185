import React, {Component} from 'react'
import { Dropdown } from 'semantic-ui-react'

class QuickstartTableDropdown extends Component {
    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, {value}){
        this.props.handleDropdownChange(this.props.idx,value);
    }

    render() {
        const typeOptions = [
            {key: 0, value: "string", text: "String"},
            {key: 1, value: "number", text: "Number"},
            {key: 2, value: "boolean", text: "Boolean"},
            {key: 3, value: "null", text: "NULL"},
        ]

        return (
            <div style={{zIndex: '999'}}>
                <Dropdown
                    placeholder="Dropdown"
                    value={this.props.value}
                    options={typeOptions}
                    onChange={this.handleChange}
                    inline
                />

            </div>
        )
    }
}

export default QuickstartTableDropdown