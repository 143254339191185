import React, {Component} from 'react'
import {connect} from 'react-redux'
import ParameterRow from "../Utilities/ParameterRow"

class DownloadResponseParams extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="downloadUrl"
                        type={"url"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                The url to download the file. If clicked, the .xlsx file will automatically begin downloading. The URL is only valid for <b>5 minutes</b>
                            </div>
                        </div>}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DownloadResponseParams)