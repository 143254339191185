import React, {Component} from 'react'
import pricing from "../../Constants/Pricing/Pricing";
import { Icon } from 'semantic-ui-react'
import AddCommaToNumbers from "../../Constants/AddCommaToNumbers";
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";
import CurrencyDropdown from "./CurrencyDropdown"
import {getPricingText} from "../../Constants/getPricingText";
import { connect } from 'react-redux'
import CustomerCarousel from "../CustomerCarousel";

class PricingPage extends Component {
    constructor(props){
        super(props);

        this.goToFAQ = this.goToFAQ.bind(this);
    }

    goToFAQ(e, faq){
        window.scrollTo({
            top: document.getElementById(faq + "FAQ").offsetTop,
            behavior: 'smooth'
        })
    }

    render() {
        const mobileId = (mobileId) => {return <span className="faqSummary summaryMobileID">{mobileId}</span>}
        const delStyle = {textDecorationStyle: "double"}

        return (
            <div style={{minHeight: '100vh'}}>
                {/*<br/><br/>*/}
                {/*<div style={{margin: "0 150px 0 150px"}}>*/}
                {/*<FreeTierRestrictions/>*/}
                {/*</div>*/}
                <br/><br/>
                <div className="pricingGrid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h1 style={{color: "red", fontFamily: "museo sans rounded, sans-serif", textAlign: "left"}}>Pricing Increase & Tiers Update Starting April 12th</h1>
                        </div>
                        <div className="col-sm-6">
                            <div style={{float: 'right'}}>
                                <div id="pricingOptionsGrid">
                                    <div id="pricingOptionsText">
                                        Currency
                                    </div>
                                    <div id="pricingOptionsDropdown">
                                        <CurrencyDropdown />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-12" style={{backgroundColor: "#cce5cc", color: "green", padding: "20px", borderRadius: "10px"}}>
                            <h1>Upgrade before April 12th and <u>lock-in</u> current rates <u>FOREVER</u></h1>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="pricingBox pricingBoxDisplay">
                                <div className="pricingPageImageOuter">
                                    <div className="pricingPageImage">
                                        <h3 className="pricingHeader">P</h3>
                                        <span className="subheading">S</span>
                                        <h1 className="pricingCost">$0</h1>
                                        <span className="subheading">m</span>
                                        <p className="pricingCTA">G</p>
                                    </div>
                                </div>
                                <p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "rows")}>Rows per File</span></p>
                                <p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "files")}>Files</span></p>
                                <p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "requests")}>Requests per Day</span></p>
                                <p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "private")}>Private</span></p>
                                <p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "alerts")}>Success/Error Alerts</span></p>
                                <p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "blockRequests")}>Block Request Types</span></p>
                                <p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "multipleKeys")}>Multiple Keys</span></p>
                                <p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "domainWhitelist")}>Domain Whitelist</span></p>
                                {/*<p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "autoEmail")}>Auto Email Spreadsheets</span></p>*/}
                                <p className="featureItem"><span className="faqSummary" onClick={(e) => this.goToFAQ(e, "support")}>Support</span></p>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="pricingBox">
                                <h3 className="pricingHeader">{pricing[0].name.toUpperCase()}</h3>
                                <span className="subheading">{capitalizeHyphenated(pricing[0].desc)}</span>
                                <h1 className="pricingCost">{getPricingText(this.props.currency, 0)}</h1>
                                <span className="subheading">per month</span>
                                <p className="pricingCTA"><a className="linkStyle" href="/signup">Get Started</a></p>
                                <p className="featureItem">{AddCommaToNumbers(pricing[0].rowMax.toString())} {mobileId("Rows per File")}</p>
                                <p className="featureItem"><del style={delStyle}> {AddCommaToNumbers(pricing[0].files.toString())}</del> 3 {mobileId("Files")}</p>
                                <p className="featureItem"><del style={delStyle}> {AddCommaToNumbers(pricing[0].requests.toString())}</del> 50 {mobileId("Requests per Day")}</p>
                                <p className="featureItem">{mobileId("Private")} <Icon name={pricing[0].secure ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Success/Error Alerts")} <Icon name={pricing[0].alerts ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Block Request Types")} <Icon name={pricing[0].blockRequests ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Multiple Keys")} <Icon name={pricing[0].multipleKeys ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Domain Whitelist")} <Icon name={pricing[0].domainWhitelist ? "check" : "remove"} /></p>
                                {/*<p className="featureItem">{mobileId("Auto Email Spreadsheets")} <Icon name={pricing[0].autoEmail ? "check" : "remove"} /></p>*/}
                                <p className="featureItem">{pricing[0].support} {mobileId("Support")}</p>
                                <p className="pricingCTA"><a className="linkStyle" href="/signup">Get Started</a></p>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="pricingBox proPricingBox">
                                <h3 className="pricingHeader">{pricing[1].name.toUpperCase()}</h3>
                                <span className="subheading">{capitalizeHyphenated(pricing[1].desc)}</span>
                                <h1 className="pricingCost">{getPricingText(this.props.currency, 1)}</h1>
                                <span className="subheading">per month</span>
                                <p className="pricingCTA"><a className="linkStyle" href="/signup?pricing=1">Get Started</a></p>
                                <p className="featureItem">{AddCommaToNumbers(pricing[1].rowMax.toString())} {mobileId("Rows per File")}</p>
                                <p className="featureItem"><del style={delStyle}> {AddCommaToNumbers(pricing[1].files.toString())}</del> 25 {mobileId("Files")}</p>
                                <p className="featureItem"><del style={delStyle}> {AddCommaToNumbers(pricing[1].requests.toString())}</del> 2,500{mobileId("Requests per Day")}</p>
                                <p className="featureItem">{mobileId("Private")} <Icon name={pricing[1].secure ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Success/Error Alerts")} <Icon name={pricing[1].alerts ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Block Request Types")} <Icon name={pricing[1].blockRequests ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Multiple Keys")} <Icon name={pricing[1].multipleKeys ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Domain Whitelist")} <Icon name={pricing[1].domainWhitelist ? "check" : "remove"} /></p>
                                {/*<p className="featureItem">{mobileId("Auto Email Spreadsheets")} <Icon name={pricing[1].autoEmail ? "check" : "remove"} /></p>*/}
                                <p className="featureItem">{pricing[1].support} {mobileId("Support")}</p>
                                <p className="pricingCTA"><a className="linkStyle" href="/signup?pricing=1">Get Started</a></p>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="pricingBox teamPricingBox">
                                <div className="pricingBestValueTag">
                                    Best Value!
                                </div>
                                <h3 className="pricingHeader">{pricing[2].name.toUpperCase()}</h3>
                                <span className="subheading">{capitalizeHyphenated(pricing[2].desc)}</span>
                                <h1 className="pricingCost"><del style={{textDecorationStyle: "double"}}>{getPricingText(this.props.currency, 2)}</del> {getPricingText(this.props.currency, 2, 1, 25)}</h1>
                                <span className="subheading">per month</span>
                                <p className="pricingCTA"><a className="linkStyle" href="/signup?pricing=2">Get Started</a></p>
                                <p className="featureItem">{AddCommaToNumbers(pricing[2].rowMax.toString())} {mobileId("Rows per File")}</p>
                                <p className="featureItem">{AddCommaToNumbers(pricing[2].files.toString())} {mobileId("Files")}</p>
                                <p className="featureItem">{AddCommaToNumbers(pricing[2].requests.toString())} {mobileId("Requests per Day")}</p>
                                <p className="featureItem">{mobileId("Private")} <Icon name={pricing[2].secure ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Success/Error Alerts")} <Icon name={pricing[2].alerts ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Block Request Types")} <Icon name={pricing[2].blockRequests ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Multiple Keys")} <Icon name={pricing[2].multipleKeys ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Domain Whitelist")} <Icon name={pricing[2].domainWhitelist ? "check" : "remove"} /></p>
                                {/*<p className="featureItem">{mobileId("Auto Email Spreadsheets")} <Icon name={pricing[2].autoEmail ? "check" : "remove"} /></p>*/}
                                <p className="featureItem">{pricing[2].support} {mobileId("Support")}</p>
                                <p className="pricingCTA"><a className="linkStyle" href="/signup?pricing=2">Get Started</a></p>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="pricingBox customPricingBox">
                                <h3 className="pricingHeader">{pricing[3].name.toUpperCase()}</h3>
                                <span className="subheading">Agency</span>
                                <h1 className="pricingCost">{getPricingText(this.props.currency, 3)}</h1>
                                <span className="subheading">per month</span>
                                <p className="pricingCTA"><a className="linkStyle" href="/signup?pricing=3">Get Started</a></p>
                                <p className="featureItem">{AddCommaToNumbers(pricing[3].rowMax.toString())} {mobileId("Rows per File")}</p>
                                <p className="featureItem">{AddCommaToNumbers(pricing[3].files.toString())} {mobileId("Files")}</p>
                                <p className="featureItem">{AddCommaToNumbers(pricing[3].requests.toString())} {mobileId("Requests per Day")}</p>
                                <p className="featureItem">{mobileId("Private")} <Icon name={pricing[3].secure ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Success/Error Alerts")} <Icon name={pricing[3].alerts ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Block Request Types")} <Icon name={pricing[3].blockRequests ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Multiple Keys")} <Icon name={pricing[3].multipleKeys ? "check" : "remove"} /></p>
                                <p className="featureItem">{mobileId("Domain Whitelist")} <Icon name={pricing[3].domainWhitelist ? "check" : "remove"} /></p>
                                {/*<p className="featureItem">{mobileId("Auto Email Spreadsheets")} <Icon name={pricing[3].autoEmail ? "check" : "remove"} /></p>*/}
                                <p className="featureItem">{pricing[3].support} {mobileId("Support")}</p>
                                <p className="pricingCTA"><a className="linkStyle" href="/signup?pricing=3">Get Started</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: '50px', marginBottom: '50px'}}>
                    <CustomerCarousel/>
                </div>
                <div className="pricingFAQs">
                    <div className="row">
                        <div className="col-sm-4" />
                        <div className="col-sm-4">
                            <h6 style={{color: 'gray', textAlign: 'center'}} id="pricingFAQHeader">FREQUENTLY ASKED QUESTIONS</h6>
                        </div>
                        <div className="col-sm-4" />
                    </div>
                    <br/>
                    <section id="rowsFAQ">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="pricingFAQDiv">
                                    <h5>Why the price increases?</h5>
                                    <p>We are a small bootstrapped startup and our goal is to serve our customers exceptionally, with both unparalleled support and innovation.</p>
                                    <p>At our current prices and services levels, it is becoming hard for us to do that. We hope you understand and thank you for using API Spreadsheets! </p>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="pricingFAQDiv">
                                    <h5>So, my rates will never change if I upgrade now?</h5>
                                    <p>That's right! If you upgrade before April 12th, your price AND service levels will stay the same</p>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="pricingFAQDiv">
                                    <h5>How does canceling work?</h5>
                                    <p>You can cancel any time for any reason!</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <br/><br/><br/>
                    <br/><br/><br/><br/><br/><br/>
                    <div className="row">
                        <div className="col-sm-4" />
                        <div className="col-sm-4">
                            <div className="pricingHaveMoreQuestions">
                                <div className="pricingHaveMoreQuestionsText">
                                    <h2>Have more questions?</h2>
                                    <h4 style={{color: 'gray'}}>Email us at <a href="mailto:info@apispreadsheets.com?Subject=Pricing%20And%20Billing%20Questions">info@apispreadsheets.com</a></h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4" />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

export default connect(mapStateToProps, null)(PricingPage)