export const initInvitesUsed = (teamInfo, userEmail) => {
    let invitesUsed = 0;

    if (teamInfo !== null){
        if (teamInfo.length > 0){
            // const userEmail = localStorage.getItem("email")

            for (let i=0; i<teamInfo.length; i++){
                const info = teamInfo[i]

                if (info.admin_email === userEmail){
                    for (let email in info.all_users){
                        if (info.all_users[email]['role'] !== "admin"){
                            if (info.all_users[email]['status'] === "invited" || info.all_users[email]['status'] === "joined"){
                                invitesUsed += 1
                            }
                        }
                    }
                }
            }
        }
    }

    return invitesUsed

}