import React, {Component} from 'react'
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import SSLCertificateStatus from "./SSLCertificateStatus";
import OpenSSLModalInfoBtn from "./OpenSSLModalInfoBtn"
import SelectSSLCertificate from "./SelectSSLCertificate"

class SSLCertificateRow extends Component {
    render() {
        const textStyle = {fontSize: "14px", color: gunaldiStyles.mainText}

        return (
            <div style={{padding: "8px", borderBottom: "1px solid #F0F0F0"}}>
                <div style={this.props.gridStyle}>
                    <div>
                        <SelectSSLCertificate
                            domainName={this.props.domainName}
                            domainNameError={this.props.domainNameError}
                            status={this.props.info.Status}
                            sslSelected={this.props.sslSelected}
                            changeState={this.props.changeState}
                            idx={this.props.idx}
                            certDomainName={this.props.info.DomainName}
                        />
                    </div>
                    <div style={textStyle} className="ellipses">
                        {this.props.info.DomainName}
                    </div>
                    <div style={textStyle}>
                        {this.props.info.Type === "IMPORTED" ? "Imported" : "Requested"}
                    </div>
                    <div style={textStyle}>
                        <SSLCertificateStatus status={this.props.info.Status}/>
                    </div>
                    <div style={textStyle}>
                        <OpenSSLModalInfoBtn sslInfo={this.props.info}
                                             deleteSSLInfo={this.props.deleteSSLInfo}
                                             idx={this.props.idx}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default SSLCertificateRow