import React, {Component} from 'react'
import DownloadAttributes from "./DownloadAttributes";

const reformatData = (attributeInfo) => {
    let attrNames = [];
    let attrTypes = [];
    let attrDesc = [];

    for (let i=0; i<14; i++){
        attrNames.push(attributeInfo.attribute_names[i]);
        attrTypes.push(attributeInfo.attribute_type[i]);
        attrDesc.push(attributeInfo.attribute_descriptions[i]);
    }

    attrNames.push(attributeInfo.attribute_names[attributeInfo.attribute_names.length - 1]);
    attrTypes.push(attributeInfo.attribute_type[attributeInfo.attribute_type.length - 1]);
    attrDesc.push(attributeInfo.attribute_descriptions[attributeInfo.attribute_descriptions.length - 1]);

    return {"attribute_names": attrNames, "attribute_type": attrTypes, "attribute_descriptions": attrDesc}
}

const reshapeCSVData = (attributeInfo) => {
    let data = [["name", "type", "description"]];

    for (let i=0; i<attributeInfo.attribute_names.length; i++){
        data.push([attributeInfo.attribute_names[i], attributeInfo.attribute_type[i], attributeInfo.attribute_descriptions[i]]);
    }

    return data
}

class AttributeDetails extends Component {
    render() {
        let dataRows = []
        let attributeInfo = this.props.attributeInfo;
        let asterisk = null;

        if (attributeInfo.attribute_type.length > 15){
            attributeInfo = reformatData(attributeInfo)
            asterisk = <span className="text-muted"><i>{"Showing 15 out of " + this.props.attributeInfo.attribute_type.length.toString() + " columns"}</i></span>
        }

        for (let i=0; i<attributeInfo.attribute_type.length; i++){
            dataRows.push(<tr key={i.toString()}>{[
                <td key={i.toString() + "1"} className={i === attributeInfo.attribute_type.length - 1 ? "predictorCell" : null}>{attributeInfo.attribute_names[i]}</td>,
                <td key={i.toString() + "0"} className={i === attributeInfo.attribute_type.length - 1 ? "predictorCell" : null}>{attributeInfo.attribute_type[i]}</td>,
                <td key={i.toString() + "2"} className={i === attributeInfo.attribute_type.length - 1 ? "predictorCell" : null}>{attributeInfo.attribute_descriptions[i]}</td>,
            ]}</tr>);
        }

        return (
            <div>
                <h2>Attribute Details</h2>
                <DownloadAttributes attributeInfo={reshapeCSVData(this.props.attributeInfo)} name={this.props.name}/>
                {asterisk}
                <div style={{width: '100%'}}>
                    <table className="table table-bordered table-striped table-hover table-sm">
                        <thead>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Description</th>
                        </thead>
                        <tbody>
                            {dataRows}
                        </tbody>
                    </table>
                </div>

            </div>
        )
    }
}

export default AttributeDetails