import React, {Component} from 'react'
import MenuSegment from "../../../../FileDnD/FilesID/FilesIDDisplayMenu/MenuSegment"

class CustomImportToolbar extends Component {
    changeMenuItem = (e, name) => this.props.changeMenuItem(name)

    render() {
        const menuItemStyle = { cursor: 'pointer'}

        return (
            <div>
                <MenuSegment label="Columns"
                             icon='columns'
                             name="columns"
                             menuItem={this.props.activeItem}
                             onClick={this.changeMenuItem} importer />
                <MenuSegment label="Destinations"
                             icon='folder-plus'
                             name="destinations"
                             menuItem={this.props.activeItem}
                             onClick={this.changeMenuItem} importer />
                <MenuSegment label="Appearance"
                             icon='palette'
                             name="appearance"
                             menuItem={this.props.activeItem}
                             onClick={this.changeMenuItem} importer />
                <MenuSegment label="Actions"
                             icon='play'
                             name="actions"
                             menuItem={this.props.activeItem}
                             onClick={this.changeMenuItem} importer />
                <MenuSegment label="Code"
                             icon='code-alt'
                             name="code"
                             menuItem={this.props.activeItem}
                             onClick={this.changeMenuItem} importer />
                <MenuSegment label="Info"
                             icon='info-circle'
                             name="info"
                             menuItem={this.props.activeItem}
                             onClick={this.changeMenuItem} importer />
                <MenuSegment label="Imported Files"
                             icon='file'
                             name="files"
                             menuItem={this.props.activeItem}
                             onClick={this.changeMenuItem} importer />
            </div>
        )
    }
}

export default CustomImportToolbar