export const getSampleResponseJson = (mode, isPrivate) => {
    switch (mode){
        case "ai":
            return {
                results:  [{'Title': 'Guardians of the Galaxy', 'Genre': 'Action,Adventure,Sci-Fi', 'Director': 'James Gunn', 'Year': 2014, 'Runtime (Minutes)': 121}],
                query: "Show me all the movies Chris Pratt acted in"
            }
        case "download":
            return {
                downloadUrl: "https://woyera-production-files.s3.amazonaws.com/2023-06-14_16_56_44.xlsx?AWSAccessKeyId=AKIAVP4GW4B4W5QL5IHQ&Signature=jAQ4HjmEHrzZ7OcFo%2BU8EvOU5fc%3D&Expires=1686762107"
            }
        case "create":
            let data = {
                name: '2023-06-15_08_40_39.csv',
                size: 416,
                colLen: 6,
                rowLen: 6,
                fileID: 'hrX7RtY8zZ8UXMTs',
                createdDt: '2023-06-15 08:40:39',
                dashboardUrl: 'http://localhost:5000/files/hrX7RtY8zZ8UXMTs',
                apiGetUrl: 'http://localhost:8080/data/hrX7RtY8zZ8UXMTs/' + (isPrivate ? "?accessKey=108dca97354cc34c90e85da1b2c3d607&secretKey=79eb2d43d00851e502254b1605965b15/" : ""),
                apiPostUrl: 'http://localhost:8080/data/hrX7RtY8zZ8UXMTs/',
                isPrivate: isPrivate,
                columnNames: ['Title', 'Genre', 'Director', 'Actors', 'Year', 'Runtime (Minutes)'],
                sampleData: [['Guardians of the Galaxy', 'Action,Adventure,Sci-Fi', 'James Gunn', 'Chris Pratt, Vin Diesel, Bradley Cooper, Zoe Saldana', 2014, 121], ['Prometheus', 'Adventure,Mystery,Sci-Fi', 'Ridley Scott', 'Noomi Rapace, Logan Marshall-Green, Michael Fassbender, Charlize Theron', 2012, 124], ['Split', 'Horror,Thriller', 'M. Night Shyamalan', 'James McAvoy, Anya Taylor-Joy, Haley Lu Richardson, Jessica Sula', 2016, 117], ['Sing', 'Animation,Comedy,Family', 'Christophe Lourdelet', 'Matthew McConaughey,Reese Witherspoon, Seth MacFarlane, Scarlett Johansson', 2016, 108], ['Suicide Squad', 'Action,Adventure,Fantasy', 'David Ayer', 'Will Smith, Jared Leto, Margot Robbie, Viola Davis', 2016, 123]],
            }

            if (isPrivate){
                data['accessKey'] = "108dca97354cc34c90e85da1b2c3d607"
                data['secretKey'] = "79eb2d43d00851e502254b1605965b15"
            }

            return data

        default:
            return { }
    }
}