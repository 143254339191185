import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";

class FAQHeader extends Component {
    capitalizeName = (name) => {
        return name === "api" ? name.toUpperCase() : capitalizeHyphenated(name)
    }
    render() {
        const helpInfo = this.props.helpInfoObj;

        return (
            <div style={{fontSize: "20px", color: "#161E16", fontWeight: "700", marginBottom: "24px"}} id="faqDisplayHeader">
                {'icon' in helpInfo ? helpInfo['icon'] !== null ? <Icon name={helpInfo['icon']} /> : null : null } {this.capitalizeName(helpInfo['name'])}
            </div>
        )
    }
}

export default FAQHeader