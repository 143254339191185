import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'
import Loader from 'react-loader-spinner'

class PageHeaderButton extends Component {
    constructor(props) {
        super(props);
    }

    click = (e) => {
        if ('params' in this.props){
            this.props.onClick(...this.props.params)
        } else {
            this.props.onClick(e)
        }
    }

    render() {
        let content = null;
        // if (loading){
        //     content = <span className="pageHeaderButtonLoader"><Loader type="TailSpin"
        //                       color="black"
        //                       height="20"
        //                       width="25" /></span>
        // } else {
            if (typeof this.props.content === "string"){
                let color = "black";
                let icon = null;

                if ("color" in this.props){
                    color = this.props.color
                }

                if ("icon" in this.props){
                    icon = <Icon name={this.props.icon} style={{paddingLeft: "5px"}}/>
                }

                content = <span style={{color: color}}>{this.props.content} {icon}</span>
            } else{
                content = this.props.content
            }
        // }

        let loading = false;

        if ("loading" in this.props){
            loading = this.props.loading
        }

        if (loading){
            content = <span className="pageHeaderButtonLoader">
                        <Loader type="TailSpin" color="black" height="20" />
                        <span style={{visibility: "hidden"}}>{content}</span>
                    </span>
        }
        // else {
        //     cotn
        // }

        return (
            <button className={"updatedClassName" in this.props ? this.props.updatedClassName : "pageHeaderButton"} onClick={this.click}>
                {content}
            </button>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(PageHeaderButton)