import Pricing from "./Pricing/Pricing"
import currencyInfo from "./currencyInfo"
import { store } from '../index.js'

export const getPricingText = (currency, planType, int=false, pricingInfo=null, yearlyPricing=null, fullYearPrice=false) => {
    const currencyInformation = currencyInfo[currency];

    let priceNum;

    if (pricingInfo===null){
        priceNum = Pricing[planType].price;
    } else{
        priceNum = pricingInfo.price;
    }

    if (yearlyPricing !== null){
        if (yearlyPricing === true){
            priceNum *= .9
        }
    } else {
        if (store.getState().mainState.yearlyPricing){
            priceNum *= .9
        }
    }

    priceNum *= currencyInformation.multiplier

    if (planType === 4){
        priceNum = Math.floor(priceNum)

        let lastDigit = Number(priceNum.toString().slice(-1))
        let sumToAdd = 10 - lastDigit;

        priceNum = (priceNum + sumToAdd) -1
    } else {
        priceNum = Math.ceil(priceNum)
    }

    if (fullYearPrice){
        priceNum *= 12
    }

    if (int){
        return priceNum
    } else {
        return currencyInformation.symbol + priceNum.toString()
    }
}