import React, {Component} from 'react'
import images from "../../Constants/Images";
import { Menu, Image } from 'semantic-ui-react';
import {getUploadFileForMessage} from "./getUploadFileForMessage";

class ImportUploadNavbar extends Component {
    render() {
        return (
            <div>
                <Menu secondary stackable>
                    <Menu.Menu position="left">
                        <Menu.Item>
                            <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                <Image style={{cursor: 'pointer'}} src={images.logoNav} href="/" size="small"/>
                            </div>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>
        )
    }
}

export default ImportUploadNavbar