import React, {Component} from 'react'
import CustomImage from "../../../SharedComponents/CustomImage"
import BetaMessage from "../../../SharedComponents/BetaMessage";
import ValidInvalidRow from "./ValidInvalidRow";

class QueryBody extends Component {
    render() {
        return (
            <div>
                <div className="docsSection">
                    <p>A query is only needed when you want to specify operations on a subset of rows and/or columns that meet a specified criteria. Such as when,</p>
                        <ol>
                            <li>
                                {/*<a href="/guides/read-subset-data">*/}
                                Reading a subset of row(s)
                                {/*</a>*/}
                            </li>
                            <li>Both
                                {/*<a href="/guides/update-data-all-rows">*/}
                                    Updating data in all rows
                                {/*</a> */}
                                or
                                {/*<a href="/guides/update-data-subset-rows">*/}
                                    Updating data in subset of row(s)
                                {/*</a>*/}
                            </li>
                            <li>
                                {/*<a href="/guides/delete-rows">*/}
                                    Deleting row(s)
                                {/*</a>*/}
                            </li>
                        </ol>
                    <p>For other operations, such as Reading all rows or Creating new rows, you do not need a query.</p>
                </div>
                <hr/>
                <div className="docsSection">
                    <p className="docsHeader">Query Format</p>
                    <p>A query for <b>READ</b> and <b>UPDATE</b> follows the same general syntax as SQL</p>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="codeTheoryBox">
                                <span>SELECT <code>*</code> FROM <code>fileID</code> WHERE <code>filterClause</code></span>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="codeTheoryBox">
                                <span>SELECT <code>col1, col2</code> FROM <code>fileID</code> WHERE <code>filterClause</code></span>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <p>Some example queries would look like this</p>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="codeExampleBox">
                                <span>SELECT <code>*</code> FROM <code>5CF9MkyfCi1Vjnre</code><br/>WHERE <code>email LIKE '%gmail%'</code></span>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="codeExampleBox">
                                <span>SELECT <code>id, name</code> FROM <code>5CF9MkyfCi1Vjnre</code><br/>WHERE <code>id>5 AND name in ('jack', 'john')</code></span>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <p>A query for <b>DELETE</b> also follows the same general syntax as SQL</p>
                    <div className="codeTheoryBox">
                        <span>DELETE FROM <code>fileID</code> WHERE <code>filterClause</code></span>
                    </div>
                    <br/>
                    <p>An example query would look something like this</p>
                    <div className="codeExampleBox">
                        <span>DELETE FROM <code>5CF9MkyfCi1Vjnre</code> where <code>country='USA' OR country='Australia'</code></span>
                    </div>
                    <br/><br/>
                    <p><b>How The Query Format Differs from SQL</b></p>
                    <ul>
                        <li><b>SELECT</b>, <b>DELETE</b>, <b>FROM</b> and <b>WHERE</b> are the only SQL identifiers you can use. That means identifiers like <b>ORDER BY</b> or <b>GROUP BY</b> and others are not valid</li>
                        <ValidInvalidRow
                            validCode={<span>SELECT <code>*</code> FROM <code>5CF9MkyfCi1Vjnre</code> WHERE <code>name='john'</code></span>}
                            invalidCode={<span>SELECT <code>*</code> FROM <code>5CF9MkyfCi1Vjnre</code> WHERE <code>name='john'</code> ORDER BY created_dt desc</span>}
                        />
                        <p> </p>
                        <li>You can only specify <code>*</code> or column names like <code>col_1,col_2,...</code> after the <b>SELECT</b> and before the <b>FROM</b> identifiers. No functions are allowed</li>
                        <ValidInvalidRow
                            validCode={<span>SELECT <code>id, name</code> FROM <code>5CF9MkyfCi1Vjnre</code> WHERE <code>substring(name, 1, 4)='john' AND email LIKE '%gmail%'</code></span>}
                            invalidCode={<span>SELECT <code>id, substring(name, 1, 4) AS first_name</code> FROM <code>5CF9MkyfCi1Vjnre</code> WHERE <code>name LIKE 'john%'</code></span>}
                        />
                        <p> </p>
                        <li>The <code>filterClause</code> only supports a subset of SQL functions. You can read more about it in the <a href="#filterClause"><b>Filter Clause</b></a> section below</li>
                    </ul>
                    <br/>
                </div>
                <br/>
                <hr/>
                <br/>
                <div className="docsSection">
                    <p className="docsHeader">File ID</p>
                    <p style={{marginBottom: "0"}}>You need to specify the <b>fileID</b> in the query</p>
                    <div className="codeTheoryBox">
                        <span>SELECT <code>*</code> FROM <code>fileID</code> WHERE <code>filterClause</code></span>
                    </div>
                    <p> </p>
                    <p>You can find this on your File Information box</p>
                    <img style={{width:'100%'}} src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ValidQuery/FileID.png" alt="File ID Discover"  />
                </div>
                <br/><br/>
                <hr/>
                <br/>
                <div className="docsSection">
                    <p className="docsHeader">Column Names</p>
                    <p>Column Names are used in the <code>filterClause</code> and after the <b>SELECT</b> identifier if you are <b><a href="/guides/read-only-rows-specified-criteria">reading a subset of row(s)</a></b>.</p>
                    <p>Column Names must be identical (including uppercase or lowercase) to the column names for your file. These can be found as shown below.</p>
                    <img style={{width:'40%'}} src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ValidQuery/ColumnNames.png" alt="Column Name Button"/>
                </div>
                <br/><br/>
                <hr/>
                <br/>
                <section id="filterClause">
                    <div className="docsSection">
                        <p className="docsHeader">Filter Clause</p>
                        <p>The Filter Clause is specified after the <b>WHERE</b> identifier and is a subset of what you are allowed to do in SQL.</p>
                        <p>Let's look at a few examples of valid API Spreadsheets queries before we move on to the rules.</p>
                        <div className="codeExampleBox">
                            <span>SELECT <code>*</code> FROM <code>5CF9MkyfCi1Vjnre</code> WHERE <code>{'id>5 AND salary>=50000'}</code></span>
                        </div>
                        <br/>
                        <div className="codeExampleBox">
                            <span>SELECT <code>*</code> FROM <code>5CF9MkyfCi1Vjnre</code> WHERE <code>{"country NOT IN ('USA', 'Austria', 'Indonesia')"}</code></span>
                        </div>
                        <br/>
                        <div className="codeExampleBox">
                            <span>SELECT <code>name</code> FROM <code>5CF9MkyfCi1Vjnre</code> WHERE <code>{"name <> 'CJ Cregg'"}</code></span>
                        </div>
                        <br/>
                        <div className="codeExampleBox">
                            <span>DELETE FROM <code>5CF9MkyfCi1Vjnre</code> WHERE <code> {"email LIKE '%gmail%'"}</code></span>
                        </div>
                        <br/>
                        <p>We support <a href="https://www.w3resource.com/sql/comparison-operators/sql-comparison-operators.php" target="_blank" rel="noopener noreferrer">all SQL Comparison Operators</a>,
                            <a href="https://www.w3resource.com/sql/boolean-operator/sql-boolean-operators.php" target="_blank" rel="noopener noreferrer"> all SQL Logical Operators</a>, and <a href="https://duckdb.org/docs/sql/functions/char" target="_blank" rel="noopener noreferrer">almost all SQL Text Functions</a>
                        </p>
                        <p style={{marginBottom: "5px"}}><b>We support all 6 comparison operators</b></p>
                        <ol>
                            <li> <code>=</code> equal to</li>
                            <li> <code>{'<>'}</code> or <code>!=</code> not equal to</li>
                            <li> <code>{'>'}</code> greater than</li>
                            <li> <code>{'<'}</code> less than</li>
                            <li> <code>{'>='}</code> greater than or equal to</li>
                            <li> <code>{'<='}</code> less than or equal to</li>
                        </ol>
                        <p style={{marginBottom: "5px"}}><b>We support all Logical Operators</b></p>
                        <ol>
                            <li><code>AND</code></li>
                            <li><code>OR</code></li>
                            <li><code>IN</code></li>
                            <li><code>BETWEEN</code></li>
                            <li><a href="https://www.w3resource.com/sql/boolean-operator/sql-boolean-operators.php" target="_blank" rel="noopener noreferrer"> and all of the ones found here</a></li>
                        </ol>
                        <p style={{marginBottom: "5px"}}><b>We support almost all Text Functions</b></p>
                        <ol>
                            <li><code>LIKE</code></li>
                            <li><code>CONTAINS</code></li>
                            <li><code>SUBSTRING</code></li>
                            <li><a href="https://duckdb.org/docs/sql/functions/char" target="_blank" rel="noopener noreferrer"> and all of the ones found here</a></li>
                        </ol>
                    </div>
                </section>
                <p></p>
                <div className="docsSection">
                    <p className="docsHeader">A Final Thing to Note in the Query Format</p>
                    <ol>
                        <li>There is no <code>NULL</code> identifier. Use the empty string <code>''</code> instead.</li>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="codeExampleBox">
                                    <b style={{color: 'green', display: 'block'}}>VALID</b>
                                    <code>name = ''</code>
                                    <br/>
                                    <code>{"name <> ''"}</code>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="codeInvalidBox">
                                    <b style={{color: 'red', display: 'block'}}>INVALID</b>
                                    <code>name=NULL</code>
                                    <br/>
                                    <code>name IS NULL</code>
                                </div>
                            </div>
                        </div>
                    </ol>
                </div>
                <br/>
                <hr/>
                <br/>
            </div>
        )
    }
}

export default QueryBody