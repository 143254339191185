import React, {Component} from 'react'
import CancelAccount from "./CancelAccount"
import DownloadReceiptsAccordion from "./DownloadReceiptsAccordion";
import PopupPlanBody from "../../SharedComponents/Payment/PopupPlanBody";
import { Segment } from 'semantic-ui-react'
import UpgradeButton from "./UpgradeButton"
import ProfileDisplayHeader from "./ProfileDisplayHeader"
import AppsumoPricingPlan from "./AppsumoPricingPlan";
import GeneralIndex from "./GeneralIndex";
import PlanDisplay from "../../SharedComponents/Payment/PlanDisplay"
import PlanRow from "../Profile/Plan/PlanRow"
import {getPricingPlanIdx} from "../../Constants/getPricingPlanIdx";
import InfoSegment from "../../SharedComponents/InfoSegment";
import { connect } from "react-redux"
import isObjectEmpty from "../../Constants/isObjectEmpty";
import {isUndefined} from "../../Constants/isUndefined";
import {getPricingInfoObject} from "../../Constants/getPricingInfoObject";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import {capitalize} from "../../Constants/capitalize";
import {getDatetimeComponentsFromISO} from "../../Constants/getDatetimeComponentsFromISO";
import AddCommaToNumbers from "../../Constants/AddCommaToNumbers";
import UpdatePaymentForm from "../../SharedComponents/UpdatePayment/UpdatePaymentForm"
import currencyInfo from "../../Constants/currencyInfo";

const plans = {
    "free": 0,
    "pro": 1,
    "team": 2,
    "business": 3,
    "enterprise": 4,
    "": -1
}


class PlanIndex extends Component {
    getRequestsInfoMsg = (frequency) => {
        let currUTCDateComponents = getDatetimeComponentsFromISO(new Date().toISOString())

        let msg;

        if (frequency === "daily"){
            msg = <span>Request count will reset on {new Date(Date.UTC(currUTCDateComponents.year, currUTCDateComponents.month, currUTCDateComponents.date, 23, 59, 0)).toLocaleString()}</span>;
        } else if (frequency === "monthly"){
            msg = <span>Request count will reset on {new Date(Date.UTC(currUTCDateComponents.year, currUTCDateComponents.month + 1, 0, 23, 59, 0)).toLocaleString()}</span>;
        } else {
            msg = null
        }

        if (this.props.pricingPlan !== "free"){
            msg = <span>{msg} <br/><br/>Requests are computed daily at 00:00 UTC so displayed count might be less than actual count</span>
        }

        return msg
    }

    render() {
        const subscriptionDetails = [{label: "Your Plan", value: this.props.pricingPlan.toUpperCase()}];

        if (!isObjectEmpty(this.props.currRequests)){
            const pricingInfo = getPricingInfoObject(this.props.tier, this.props.appsumoPlanName, this.props.isGrandfather)

            if (typeof pricingInfo !== "undefined"){
                let allowedRequests = pricingInfo.requests;

                if (this.props.fullPricingInfo !== null){
                    try{
                        if ("enterpriseInfo" in this.props.fullPricingInfo){
                            if ("requests" in this.props.fullPricingInfo['enterpriseInfo']){
                                allowedRequests = this.props.fullPricingInfo['enterpriseInfo']['requests']
                            }
                        }
                    } catch (e) { }
                }

                let requestsValue = AddCommaToNumbers(this.props.currRequests.requests).toString() + "/" + AddCommaToNumbers(allowedRequests).toString();

                if (this.props.currRequests.requests > allowedRequests){
                    requestsValue = <span style={{color: gunaldiStyles.red}}>{requestsValue}</span>
                }

                let allowedFiles = pricingInfo.files;

                if (this.props.fullPricingInfo !== null){
                    try{
                        if ("enterpriseInfo" in this.props.fullPricingInfo){
                            if ("files" in this.props.fullPricingInfo['enterpriseInfo']){
                                allowedFiles = this.props.fullPricingInfo['enterpriseInfo']['files']
                            }
                        }
                    } catch (e) { }
                }

                let filesValue = AddCommaToNumbers(this.props.currRequests.files) + "/" + AddCommaToNumbers(allowedFiles).toString()

                if (this.props.currRequests.files > allowedFiles){
                    filesValue = <span style={{color: gunaldiStyles.red}}>{filesValue}</span>
                }

                subscriptionDetails.push({
                    label: capitalize(this.props.currRequests.frequency) + " Requests Used",
                    value: requestsValue,
                    info: this.getRequestsInfoMsg(this.props.currRequests.frequency)
                })

                subscriptionDetails.push({
                    label: "Files Uploaded",
                    value: filesValue
                })
            }
        }

        if (!isObjectEmpty(this.props.subscriptionInfo)){
            let price;
            let currency;

            try{
                price = this.props.subscriptionInfo['items']['data'][0]['plan']['tiers'][0]['flat_amount']
                currency = this.props.subscriptionInfo['items']['data'][0]['plan']['currency']
            } catch(e) {
                try{
                    price = this.props.subscriptionInfo['items']['data'][0]['plan']['amount']
                    currency = this.props.subscriptionInfo['items']['data'][0]['plan']['currency']
                } catch (e){
                    price = null;
                    currency = this.props.currency;
                }
            }

            if (price !== null){
                subscriptionDetails.push({ label: "Price", value: currencyInfo[currency].symbol + (price/100).toString() })
            }

            let createdDt = this.props.subscriptionInfo['created'];

            if (!isUndefined(createdDt)){
                 subscriptionDetails.push({label: "Plan Created", value: new Date(createdDt * 1000).toLocaleDateString()})
            }

            let currStart = this.props.subscriptionInfo['current_period_start'];

            if (!isUndefined(currStart)){
                subscriptionDetails.push({label: "Current Billing Cycle Start", value: new Date(currStart * 1000).toLocaleDateString()})
            }

            let currEnd = this.props.subscriptionInfo['current_period_end'];

            if (!isUndefined(currEnd)){
                subscriptionDetails.push({label: "Current Billing Cycle End", value: new Date(currEnd * 1000).toLocaleDateString()})
            }
        }

        //Check if appsumoPlanName is blank. If it is, return stuff below otherwise return a brand new component
        if (this.props.appsumoPlanName===''){
            return (
                <div>
                    <div style={{marginBottom: "16px"}}>
                        <PlanRow
                            planName={this.props.pricingPlan}
                            planIdx={getPricingPlanIdx(this.props.pricingPlan)}
                        />
                    </div>
                    <div style={{marginBottom: "16px"}}>
                        <InfoSegment
                            header="Subscription Details"
                            info={subscriptionDetails}
                        />
                    </div>
                    <div>
                        {
                            this.props.pricingPlan === "free" || this.props.pricingPlan === '' ? null :
                             <div>
                                 <div style={{marginBottom: "16px"}}>
                                     <InfoSegment
                                        header="Payment Details"
                                        info={<UpdatePaymentForm profilePage/>}
                                     />
                                 </div>
                                 <div style={{marginBottom: "16px"}}>
                                    <DownloadReceiptsAccordion/>
                                 </div>
                                 <div style={{marginBottom: "16px"}}>
                                     <CancelAccount pricingPlan={this.props.pricingPlan}/>
                                 </div>
                            </div>
                        }
                    </div>
                </div>
            )
        } else {
            return(
                <div>
                    <AppsumoPricingPlan
                        appsumoPlanName={this.props.appsumoPlanName}
                        pricingPlan={this.props.pricingPlan}
                        name={this.props.name}
                        icon={this.props.icon}/>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    subscriptionInfo: state.mainState.subscriptionInfo,
    currRequests: state.mainState.currRequests,
    tier: state.mainState.tier,
    appsumoPlanName: state.mainState.appsumoPlanName,
    isGrandfather: state.mainState.isGrandfather,
    currency: state.mainState.currency,
    yearlyPricing: state.mainState.yearlyPricing,
    fullPricingInfo: state.mainState.fullPricingInfo
})

export default connect(mapStateToProps, null)(PlanIndex)