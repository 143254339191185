import React, {Component} from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect'
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

class RequestDemoSegment extends Component {
    render() {
        return (
            <div style={{backgroundColor: 'importer' in this.props ? gunaldiStyles.subOrange : '#F4FBF3',padding:'30px 50px 30px 50px',borderRadius:'10px'}}>
                <div style={ isMobile ? null : {display: "grid", gridTemplateColumns: "1fr auto", columnGap: "30px"}}>
                    <div style={isMobile ? {marginBottom: "24px"} : null}>
                        <div style={{color: "#161E16", fontWeight: "700", fontSize: "24px", fontFamily: "Lato", marginBottom: "8px"}}>
                            {isMobile ? null : <span>Still not sure if API Spreadsheets is right for you?<br/></span>}
                            Book a free call with us for a product tour
                        </div>
                        <div style={{color: "#5E645E", fontWeight: "400", fontSize: "14px", fontFamily: "Lato"}}>Let us show you all the wonderful features of API Spreadsheets</div>
                    </div>
                    <div style={{verticalAlign: "middle"}}>
                        <Button onClick={() => {window.open("https://calendly.com/api-spreadsheets/25min", "_blank");}}
                                style={{backgroundColor:'black',width:'200px',height:'50px',color:'white',fontSize:'120%'}}>
                            <Icon name="phone" /> See Demo
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default RequestDemoSegment