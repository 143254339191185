import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import ActionsUsernamePassword from "./ActionsUsernamePassword"

class ActionsPasswordIndex extends Component {
    addNewUsernameAndPassword = () => {
        const optionsEditCopy = JSON.parse(JSON.stringify(this.props.optionsEdit))
        const allPasswords = optionsEditCopy.passwords;

        allPasswords.push({pk: -1, username: "", password: "", created_dt: "", updated_dt: ""})

        optionsEditCopy.passwords = allPasswords;

        this.props.modifyCustomImportSettings("optionsEdit", optionsEditCopy)
    }

    render() {
        const usernamePasswordBlocks = this.props.optionsEdit.passwords.map((x, idx) => <ActionsUsernamePassword idx={idx} key={idx}/>)

        return (
            <div>
                {usernamePasswordBlocks}
                <Button style={{backgroundColor: "#0081ff", color: "white", borderRadius: "25px", cursor: "pointer"}}
                        content="Add New Username & Password" size="mini" icon="plus" onClick={this.addNewUsernameAndPassword}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    optionsEdit: state.mainState.customImportOptionsEdit
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(ActionsPasswordIndex)