import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../Constants/API_Root";
import Loader from 'react-loader-spinner'

class UploadFilesVizSpreadsheetsIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isUserAuth: false,
            currentlyAuthenticating: true
        }
    }

    componentDidMount(){
        const url = window.location.href;
        const email = url.substring(url.indexOf("?email=") + 7, url.indexOf("&accessToken"))
        const accessToken = url.substring(url.indexOf("&accessToken=") + 13, url.length)

        fetch(API_Root + "api/is-user-token-valid/", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: email,
                token: accessToken,
            })
        }).then((res) => {
            if (res.status === 200){
                this.setState({ isUserAuth: true, currentlyAuthenticating: false})
            } else {
                this.setState({ isUserAuth: false, currentlyAuthenticating: false})
            }
        }).catch((err) => {
            this.setState({ isUserAuth: false, currentlyAuthenticating: false})
        })
    }

    render() {
        if (this.state.currentlyAuthenticating){
            return(
                <div style={{minHeight: '100vh'}}>
                    <div style={{padding: '100px 0', textAlign: 'center'}}>
                        <Loader
                            type="TailSpin"
                            color="white"
                            height="50"
                            width="50"
                        />
                    </div>
                </div>
            )
        } else {
            if (this.state.isUserAuth){
                return(
                    <div style={{minHeight: '100vh'}}>
                        <p>User is Auth</p>
                    </div>
                )
            } else {
                return (
                    <div style={{minHeight: '100vh'}}>
                        <p>User is non auth</p>
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UploadFilesVizSpreadsheetsIndex)