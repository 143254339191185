import React, {Component} from 'react'
import TableIndex from "../../InfoPages/Table/TableIndex"
import {isMobile} from "react-device-detect";

class CacheTableIndex extends Component {
    render() {
        const getURL = window.location.href.substring(window.location.href.indexOf("/cache-table/cache?url=") + "/cache-table/cache?url=".length, window.location.href.length)

        return (
            <div style={{padding: isMobile ? '16px 8px' : '40px 100px'}}>
                <TableIndex files getURL={getURL}/>
            </div>
        )
    }
}

export default CacheTableIndex