import React, {Component} from 'react'
import {connect} from 'react-redux'
import EmailVerificationMessage from "../SharedComponents/EmailVerificationMessage";
import FailedPaymentMessage from "../SharedComponents/FailedPaymentMessage";
import ImportDisplayBody from "./ImportDisplayBody";
import { Message } from 'semantic-ui-react'
import PageHeader from "../SharedComponents/PageHeader"
import ImportHeaderToolbar from "./ImportHeaderToolbar"

class ImportDisplay extends Component {
    constructor(props){
        super(props);

        this.state = {
            message: <Message info
                              icon="question circle outline"
                              header="Need help getting started with Creating an Importer"
                              content={<a href="/import-docs/?docsMenuItem=quickstart" target="_blank" rel="noopener noreferrer">Read our guide on Importer Customization</a>}
                              onDismiss={this.dismissMessage}
            />
        }
    }

    dismissMessage = () => this.setState({ message: null })

    render() {
        let body = <ImportDisplayBody />;

        let message = this.state.message;

        if (Array.isArray(this.props.importSheetsInfo)){
            if (this.props.importSheetsInfo.length > 2){
                message = null
            }
        }

        return (
            <div style={{minHeight: '100vh'}}>
                <PageHeader
                    header="Your Importers"
                    desc={<ImportHeaderToolbar/>}
                    importer
                    tiny
                />
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    importSheetsInfo: state.mainState.importSheetsInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ImportDisplay)