export const isValueDupeInArray = (value, array) => {
    let count = 0;

    for (let i=0; i<array.length; i++){
        if (array[i] === value){
            count += 1

            if (count >= 2){
                break
            }
        }
    }

    return count >= 2
}