import React, {Component} from 'react'
import PromoCountdown from "./PromoCountdown";

let utc3 = new Date(Date.UTC(2022, 0, 4, 12, 18, 0, 0));

class PromoTimer extends Component {
    constructor(props){
        super(props);

        this.state = {
            timeLeft: this.initTimeLeft(false)
        }

        this.initTimeLeft = this.initTimeLeft.bind(this);
    }

    initTimeLeft(setState){
        let timeLeft = {day: null, hour: null, minute: null, second: null};
        const currDate = new Date();

        if (currDate <= utc3){
            const diffDays = (utc3 - currDate) / (1000 * 60 * 60 * 24);
            timeLeft.day = Math.floor(diffDays)
            const diffHours = (diffDays % 1) * 24;
            timeLeft.hour = Math.floor(diffHours)
            const diffMinutes = (diffHours % 1) * 60;
            timeLeft.minute = Math.floor(diffMinutes);
            const diffSeconds = (diffMinutes % 1) * 60;
            timeLeft.second = Math.floor(diffSeconds);
        }

        if (setState){
            this.setState({ timeLeft: timeLeft })
        }
        else{
            return timeLeft
        }
    }

    componentWillMount(){
        setInterval(() => this.initTimeLeft(true), 1000);
    }

    render() {
        return (
            <div className='row'>
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <div className="promoTimerCountdown">
                        <h4>Promo Ends at <b><u>January 4, 2022 6:00PM UTC</u></b></h4>
                        {/*<h1>{launchDate}</h1>*/}
                        <div style={{fontSize: '250%'}}>
                            <PromoCountdown timeLeft={this.state.timeLeft}/>
                        </div>
                    </div>
                </div>
                <div className="col-sm-2" />
            </div>
        )
    }
}

export default PromoTimer