const sampleNPMImportCode = (importHash, shouldMatch=false) => {
    let code = "// USE THE COMMAND BELOW TO INSTALL THE LIBRARY\n// npm install apispreadsheets --save\n\n" +
        "import { APISpreadsheetsImporter } from 'apispreadsheets'\n\n"

    code += "const importer = new APISpreadsheetsImporter(\"" + importHash + "\", importComplete"

    if (shouldMatch){
        code += ", matchComplete"
    }

    code += ");\n\n"

    code += "function importComplete(success, data) {\n" +
        "   console.log(success)\n" +
        "   if (success){\n" +
        "      console.log(data);\n" +
        (shouldMatch ? "" : "      // close the importer if you wish\n      // importer.closeImporter();\n") +
        "    }\n" +
        "}\n"

    if (shouldMatch){
        code += "\n\nfunction matchComplete(matchRequired, matchSuccess){\n" +
            "   if (matchRequired){\n" +
            "      // if first uploaded file has finished matching, do something\n" +
            "      if(matchSuccess[0]){\n" +
            "            // close the importer if you wish \n" +
            "            // importer.closeImporter()\n" +
            "      }\n" +
            "   }\n" +
            "}\n\n"
    }

    code += "// USE THE ONCLICK FORMAT BELOW TO START THE IMPORT PROCESS\n//<button onclick=\"importer.importFiles();\">Import Excel and CSV Files</button>"

    return code
}



export default sampleNPMImportCode;