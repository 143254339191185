import React, {Component} from 'react'
import { Divider } from 'semantic-ui-react'

class DocsPostHeaderParameters extends Component {
    render() {
        return (
            <div>
                <h5 id="parameterHeading">Header Parameters <small><i>only required if private</i></small></h5>
                <Divider/>
                <span className="parameterName">accessKey</span> <span className="parameterType">string, 32 characters</span>
                <span className="parameterSecondLine"><span className="parameterRequired">Required, if private</span> </span>
                <span className="parameterDesc">The accessKey of the file, if the API is private. If file is not private, then there is no need for the accessKey parameter</span>
                <Divider/>
                <span className="parameterName">secretKey</span> <span className="parameterType">string, 32 characters</span>
                <span className="parameterSecondLine"><span className="parameterRequired">Required, if private</span> </span>
                <span className="parameterDesc">The secretKey of the file, if the API is private. If file is not private, then there is no need for the secretKey parameter</span>
            </div>
        )
    }
}

export default DocsPostHeaderParameters