import React, {Component} from 'react'
import { Image } from "semantic-ui-react"
import {authorDetails} from "../../Constants/authorDetails";

class UseCaseCard extends Component {
    constructor(props){
        super(props);

        this.goToUseCase = this.goToUseCase.bind(this);
    }

    goToUseCase(e){
        this.useCaseLink.click()
    }

    render() {
        let header = null;

        if (!this.props.landing){
            header = <div style={{fontWeight: '700', fontSize: "18px", color: "#161E16"}} onClick={this.goToUseCase}>{this.props.tutorialInfo.heading}</div>
        }

        return (
            <div className="useCaseCard">
                <a href={"/" + this.props.url + ".html"} style={{display: "none"}} ref={useCaseLink => this.useCaseLink = useCaseLink}>Read more</a>
                <div onClick={this.goToUseCase} style={{cursor: 'pointer'}}>
                    {header}
                    <img src={this.props.img} alt="Use Case" style={{color: 'orange', height: "150px"}} onClick={this.goToUseCase}/>
                    <span onClick={this.goToUseCase}><br/><br/></span>
                    <div style={{fontWeight: '400', fontSize: "16px", color: "#161E16"}} onClick={this.goToUseCase}>{this.props.title}</div>
                </div>
                {/*<div style={{paddingTop: '10px'}}>{writtenBy} {writtenDt}</div>*/}
            </div>
        )
    }
}

export default UseCaseCard