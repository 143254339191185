import React, {Component} from 'react'
import DocsDisplay from "./DocsDisplay";
import DocsDisplayPrevious from './DocsDisplayPrevious'
import Docs20200311Index from "./20200311/Docs20200311Index";

class DocsDisplayIndex extends Component {
    render() {
        if (this.props.apiVersion === "2019-10-14"){
            return (
                <div className="docsDisplay">
                    <DocsDisplay apiVersion={this.props.apiVersion}/>
                </div>
            )
        }
        else if (this.props.apiVersion === "2020-03-11"){
            return (
                <div className="docsDisplay">
                    <Docs20200311Index apiVersion={this.props.apiVersion}/>
                </div>
            )
        }
        else{
            return(
                <div className="docsDisplay">
                    <DocsDisplayPrevious apiVersion={this.props.apiVersion} />
                </div>
            )
        }

    }
}

export default DocsDisplayIndex