import React, {Component} from 'react'

class ActionCodeSnippet extends Component {
    componentDidMount(){
        const elem = document.getElementById("codeSnippetAccordion")
        elem.scrollIntoView({ behavior: 'smooth', block: 'end'})
    }

    render() {
        return (
            <div className="actionIndex">
                <h4 className="thinHeading">You can get started right away with some of our pre-written code samples <span role="img" aria-label="emoji">😀🎉❤🍻🎊</span></h4>
            </div>
        )
    }
}

export default ActionCodeSnippet