import React, {Component} from 'react'
import {connect} from 'react-redux'
import ConnectAWSAccount from "./ConnectAWSAccount";
import {modifyCustomImportSettings} from "../Actions/ModifyCustomImportSettings";
import {toggleModal} from "../Actions/ToggleModal";

class AddNewS3ModalBody extends Component {
    constructor(props) {
        super(props);
    }

    changeState = (changedState) => {
        if (!this.isAddFromProfile()){
            const updatedS3Accounts = changedState.awsS3Accounts;

            const copiedDestinations = JSON.parse(JSON.stringify(this.props.customImportDestinations))
            copiedDestinations['s3AccountInfo'] = updatedS3Accounts

            this.props.modifyCustomImportSettings("destinations", copiedDestinations)
            this.props.toggleModal(null)
        } else {
            this.props.toggleModal(null)
            window.location.reload()
        }


    }

    isAddFromProfile = () => {
        let fromProfile = false;
        if (this.props.modalInfo !== null){
            if (this.props.modalInfo.constructor === Object){
                if ("profile" in this.props.modalInfo){
                    fromProfile = this.props.modalInfo.profile;
                }
            }
        }

        return fromProfile
    }

    render() {
        let awsS3Accounts;

        if (this.isAddFromProfile()){
            awsS3Accounts = this.props.modalInfo.accountInfo;
        } else {
            awsS3Accounts = this.props.customImportDestinations.s3AccountInfo;
        }

        return (
            <div>
                <ConnectAWSAccount changeState={this.changeState} awsS3Accounts={awsS3Accounts}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    customImportDestinations: state.mainState.customImportDestinations,
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings,
    toggleModal: toggleModal,
}

export default connect(mapStateToProps, mapActionsToProps)(AddNewS3ModalBody)