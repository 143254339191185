export const getUploadFileForMessage = (firstPartMsg, info) => {
    let endMsg = ""

    if ("appearance" in info){
        if ("organizationName" in info.appearance){
            endMsg = info.appearance['organizationName']
        }
    }

    if (endMsg === ""){
        endMsg = info.userEmail
    }

    return firstPartMsg + endMsg
}