import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import API_Root from "../Constants/API_Root";
import { toggleToast } from "../Actions/ToggleToast";
import {modifyImportSheetsInfo} from "../Actions/ModifyImportSheetsInfo";
import { push } from 'react-router-redux'
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import {modifyImporterName} from "../Actions/ModifyImporterName";
import {isImporterNameDuplicate} from "./isImporterNameDuplicate";
import {toggleModal} from "../Actions/ToggleModal";

class ImportCreateButton extends Component {
    constructor(props){
        super(props);

        this.state = {
            creating: false
        }
    }

    createImportSheet = () => {
        this.setState({ creating: true })

        const error = () => {
            this.setState({
                creating: false
            }, () => {
                this.props.toggleToast({
                    show: true,
                    message: "There was an error creating your import configuration. Please try again. Contact us if this error persists.",
                    type: "error"
                })
            })
        }

        fetch(API_Root + "api/create-import-details/", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                importType: "custom",
                importerName: this.props.importerName
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.setState({
                        creating: false
                    }, () => this.addImportSheetsConfigToRedux(data))
                }).catch(err => error())
            } else {
                error()
            }
        }).catch((err) => {
            error()
        })
    }

    addImportSheetsConfigToRedux = (importSheetsInfo) => {
        this.props.toggleModal(null)
        this.props.modifyImporterName("")

        const currImportSheets = JSON.parse(JSON.stringify(this.props.importSheetsInfo))
        currImportSheets.unshift(importSheetsInfo)

        this.props.modifyImportSheetsInfo(currImportSheets)

        this.props.navigateTo("/import/" + importSheetsInfo['importHash'])
    }

    isImporterNameValid = () => {
        if (this.props.importerName.trim() === ""){
            return false
        } else {
            return !isImporterNameDuplicate(this.props.importerName.trim(), this.props.importSheetsInfo)
        }
    }

    render() {
        if ('noImporterCreated' in this.props){
            return (
                <button className="callToActionBtn lightCTABtnOrange"
                        style={{width: "400px"}}
                        disabled={this.isImporterNameValid()}
                        onClick={this.createImportSheet}>
                    <div>
                        <div>Continue with Customizing Your Importer</div>
                        <div><i className='bx bx-plus' /></div>
                    </div>
                </button>
            )
        } else {
            let content = "Continue with Customizing Your Importer";
            let size = "medium"
            let fluid = false;

            if ('importerMenuItem' in this.props){
                content = "Continue with Customizing Your Importer"
                size = "mini"
                fluid = true;
            }

            return (
                <Button icon="plus"
                        loading={this.state.creating}
                        content={content}
                        style={{backgroundColor: "#FFFFFF", border: "1px solid " + gunaldiStyles.orange, color: gunaldiStyles.orange}}
                        onClick={this.createImportSheet}
                        size={size}
                        fluid={fluid}
                        disabled={!this.isImporterNameValid()}
                />
            )
        }
    }
}

const mapStateToProps = (state) => ({
    importSheetsInfo: state.mainState.importSheetsInfo,
    importerName: state.mainState.importerName
})

const mapActionsToProps = {
    toggleToast: toggleToast,
    modifyImportSheetsInfo: modifyImportSheetsInfo,
    navigateTo: push,
    modifyImporterName: modifyImporterName,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ImportCreateButton)