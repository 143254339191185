import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import ImportConfigName from "../../../ImportSheetsSegment/ImportConfigName"
import ImportConfigDeleteBtn from "../../../ImportConfigDelete/ImportConfigDeleteBtn"
import { Segment } from 'semantic-ui-react'
import CustomImportToolbar from "./CustomImportToolbar";
import FileURL from "../../../../FileDnD/FileURL"
import ColumnsIndex from "../Columns/ColumnsIndex"
import DestinationsIndex from "../Destinations/DestinationsIndex"
import AppearanceIndex from "../Appearance/AppearanceIndex"
import ActionsIndex from "../Actions/ActionsIndex"
import ImportCodeSnippet from "../../../ImportSheetsSegment/ImportCodeSnippet";
import ImportInfoIndex from "./ImportInfoIndex"
import ImportConfigDeleteModalBodyFileNames from "../../../ImportConfigDelete/ImportConfigDeleteModalBodyFileNames";

class CustomImportIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: this.props.activeItem
        }
    }

    changeMenuItem = (activeItem) => this.setState({ activeItem: activeItem })

    render() {
        let shouldMatch = false;

        if (this.props.customImportOptionsEdit !== null){
            if ("matchColumns" in this.props.customImportOptionsEdit){
                if (this.props.customImportOptionsEdit.matchColumns){
                    if (this.props.customImportColumns !== null){
                        if (this.props.customImportColumns.length > 0){
                            shouldMatch = true
                        }
                    }
                }
            }
        }

        const importDisplays = {
            columns: <ColumnsIndex importHash={this.props.customImportInfo.importHash} />,
            destinations: <DestinationsIndex changeMenuItem={this.changeMenuItem} />,
            appearance: <AppearanceIndex />,
            actions: <ActionsIndex />,
            code: <ImportCodeSnippet importHash={this.props.customImportInfo.importHash} shouldMatch={shouldMatch}/>,
            info: <ImportInfoIndex importHash={this.props.customImportInfo.importHash} />,
            files: <ImportConfigDeleteModalBodyFileNames custom importHash={this.props.customImportInfo.importHash}/>
        }

        return (
            <div>
                <div className="filesIDDisplayIndex">
                    <div className="filesIDDisplayMenu">
                        <div style={{margin: "16px 0px 0px 0px", fontFamily: "Lato", fontWeight: "700", fontSize: "12px"}}>
                            Menu
                        </div>
                        <CustomImportToolbar
                            activeItem={this.state.activeItem}
                            changeMenuItem={this.changeMenuItem}
                        />
                    </div>
                    <div className="filesIDDisplay">
                        {importDisplays[this.state.activeItem]}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    customImportInfo: state.mainState.customImportInfo,
    customImportColumns: state.mainState.customImportColumns,
    customImportAppearance: state.mainState.customImportAppearance,
    customImportDestinations: state.mainState.customImportDestinations,
    customImportOptions: state.mainState.customImportOptions,
    customImportOptionsEdit: state.mainState.customImportOptionsEdit
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(CustomImportIndex)