import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import {toggleModal} from "../../../../Actions/ToggleModal";
import {modifyModalInfo} from "../../../../Actions/ModifyModalInfo";

class AddNewDatabase extends Component {
    constructor(props) {
        super(props);
    }

    addNewDatabase = () => {
        this.props.modifyModalInfo({
            type: this.props.type,
            isFromImport: true
        })
        this.props.toggleModal("addNewDatabaseModal")
    }

    render() {
        return (
            <div>
                <Button
                    onClick={this.addNewDatabase}
                    color="blue">
                    <Icon name="plus" />
                    <Icon name="database" />
                    <span style={{paddingLeft: "5px"}}>Add New Database</span>
                </Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(AddNewDatabase)