import React, {Component} from 'react'
import ReactJson from 'react-json-view'
import isObjectEmpty from "../../Constants/isObjectEmpty";

class JSONOutputPreview extends Component {
    render() {
        return (
            <div style={{paddingTop: '10px'}}>
                <h6 className="thinHeading"><b>{this.props.heading}</b><i>{this.props.message}</i></h6>
                <ReactJson src={this.props.jsonObj} name={null} theme="ocean" displayObjectSize={false}/>
            </div>
        )
    }
}

export default JSONOutputPreview