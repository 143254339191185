import React, {Component} from 'react'
import CRUDBox from "../FeaturesBody/CRUDBox"

class CRUDActions extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <div className="row">
                        <div className="col-sm-6">
                            <CRUDBox actionType="create" quickStart/>
                            <br/>
                            <CRUDBox actionType="delete" quickStart/>
                            <br/>
                        </div>
                        <div className="col-sm-6">
                            <CRUDBox actionType="read" quickStart/>
                            <br/>
                            <CRUDBox actionType="update" quickStart />
                        </div>
                    </div>
                </div>
                <div className="col-sm-2" />
            </div>
        )
    }
}

export default CRUDActions