import React, {Component} from 'react'
import {connect} from 'react-redux'
import NoFilesUploaded from "../../FileDnD/NoFilesUploaded";
import Loader from 'react-loader-spinner'
import API_Root from "../../Constants/API_Root";
import TeamFilesRow from "./TeamFilesRow";
import DisplayHeader from "../../FileDnD/FilesID/DisplayHeader";

class TeamFilesIndex extends Component {
    constructor(props) {
        super(props);

        const shouldDisplay = 'files_pk_list' in this.props.apiFilePK;

        this.state = {
            shouldDisplay: shouldDisplay,
            fetching: true,
            pkList: shouldDisplay ? this.props.apiFilePK['files_pk_list'] : [],
            fileInfoList: []
        }
    }

    componentDidMount(){
        const error = () => {
            this.setState({
                fetching: false
            }, () => this.props.toggleToast({ show: true, message: "There was an error getting your file information. Please try again!", type: "error"}))
        }

        if (this.state.pkList.length > 0){
            fetch(API_Root + "api/get-files-for-api-teams/", {
                method: "POST",
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    pkList: this.state.pkList,
                    utcOffset: new Date().getTimezoneOffset()
                })
            }).then(res => {
                if (res.status === 200){
                    res.json().then(data => {
                        this.setState({
                            fetching: false,
                            fileInfoList: data.fileInfoList
                        })
                    }).catch(err => error())
                } else {
                    error()
                }
            }).catch(err => error())
        } else {
            this.setState({ fetching: false })
        }
    }

    render() {
        if (this.state.shouldDisplay){
            if (this.state.fetching){
                return (
                    <div className="filesIDDisplayBox">
                        <Loader
                            type="TailSpin"
                            color="black"
                            height="50"
                            width="50"
                        />
                        <h4>Getting your files...</h4>
                    </div>
                )
            } else {
                if (this.state.fileInfoList.length > 0){
                    return (
                        <div className="filesIDDisplayBox">
                            <div style={{marginBottom: "16px"}}>
                                <DisplayHeader header="Files linked to this team"/>
                            </div>
                            {this.state.fileInfoList.map((x, idx) =>
                                <TeamFilesRow hash={this.props.teamHash} prevPage="teams" fileInformation={x} key={idx}/>
                            )}
                        </div>
                    )
                } else {
                    return (
                        <div className="filesIDDisplayBox">
                            <NoFilesUploaded style={{paddingTop: "2%"}} isTeam/>
                        </div>
                    )
                }
            }
        } else {
            return (
                <div className="filesIDDisplayBox">
                    <NoFilesUploaded style={{paddingTop: "2%"}} isTeam/>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(TeamFilesIndex)