import capitalizeHyphenated from "../Constants/capitalizeHyphenated";

export const MODIFY_CUSTOM_IMPORT_SETTINGS = 'MODIFY_CUSTOM_IMPORT_SETTINGS';

export function modifyCustomImportSettings(settingsName, importSettings){
    settingsName = "customImport" + capitalizeHyphenated(settingsName)
    return{
        settingsName: settingsName,
        type: MODIFY_CUSTOM_IMPORT_SETTINGS,
        mainState: {
            [settingsName]: importSettings
        }
    }
}