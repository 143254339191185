import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../Modal"
import SupportConfirmationModalBody from "./SupportConfirmationModalBody"

class SupportConfirmationModal extends Component {
    constructor(props) {
        super(props);
    }

    canDisplay = () => {
        let display;
        if (this.props.modalInfo !== null){
            try{
                if ("supportInfo" in this.props.modalInfo){
                    display = true
                } else {
                    display = false
                }
            } catch (e){
                display = false
            }
        } else { display = false }

        return display
    }

    render() {
        if (this.canDisplay()){
            return (
                <ModalApp name="supportConfirmationModal"
                          header={null}
                          body={<SupportConfirmationModalBody />}
                          size="medium"
                />
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(SupportConfirmationModal)