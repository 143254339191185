import React, {Component} from 'react'
import {connect} from 'react-redux'
import ColumnTable from "./ColumnTable"
import ColumnMatchToggle from "./ColumnMatchToggle"

class ColumnsIndex extends Component {
    render() {
        let columnTable = null;

        if (this.props.optionsEdit.matchColumns){
            columnTable = <ColumnTable />
        }

        return (
           <div>
               <div className="displayBox" style={{marginBottom: "16px"}}>
                    <ColumnMatchToggle importHash={this.props.importHash}/>
               </div>
               {columnTable}
           </div>
        )
    }
}

const mapStateToProps = (state) => ({
    optionsEdit: state.mainState.customImportOptionsEdit
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(ColumnsIndex)