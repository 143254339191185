import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import API_Root from "../../../../Constants/API_Root";

class ImportSSLCertificateBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            importing: false
        }
    }

    isDisabled = () => this.props.fileCertificate === null || this.props.fileKey === null

    importSSLCertificate = (e) => {
        this.setState({ importing: true })

        const isError =
            (errMsg="There was an error importing your certificate. Read the guides and if you still cannot import your certificate, then contact our support") =>
                this.setState({ importing: false }, () => this.props.changeState({ importSSLError: true, importSSLMsg: errMsg }))

        const formData = new FormData();

        formData.append("fileCertificate", this.props.fileCertificate);
        formData.append("fileKey", this.props.fileKey);
        formData.append("fileCertificateChain", this.props.fileCertificateChain);

        formData.append("email", localStorage.getItem("email"));
        formData.append("token", localStorage.getItem("token"));

        const currSSLinfo = JSON.parse(JSON.stringify(this.props.currSSLInfo))

        fetch(API_Root + "api/import-ssl-certificate/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: formData
        }).then(res => {
            res.json().then(data => {
                if (res.status === 200) {
                    currSSLinfo.push(data)

                    this.setState({ importing: false },
                        () => this.props.changeState({
                            importSSLError: false,
                            importSSLMsg: "Successful import",
                            currSSLInfo: currSSLinfo
                        }))
                } else {
                    isError(data.errorMsg)
                }
            }).catch(err => isError())
        }).catch(err => isError())
    }

    render() {
        return (
            <Button size="small"
                    style={gunaldiStyles.btnBlue}
                    icon="upload"
                    content="Import SSL Certificate"
                    disabled={this.isDisabled()}
                    onClick={this.importSSLCertificate}
                    loading={this.state.importing}
            />
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ImportSSLCertificateBtn)