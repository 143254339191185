import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Input, Icon, Button, Dropdown} from 'semantic-ui-react';
import API_Root from "../Constants/API_Root"
import TestDatabaseMessage from "./TestDatabaseMessage";
import InfoPopup from "../SharedComponents/InfoPopup";
import {canUserCode} from "./canUserCode";
import DatabaseTypeTag from "./DatabaseTypeTag";
import images from "../Constants/Images";
import {modifyCustomImportSettings} from "../Actions/ModifyCustomImportSettings";
import {toggleModal} from "../Actions/ToggleModal";
import isObjectEmpty from "../Constants/isObjectEmpty";
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import { push } from 'react-router-redux'

class NewDatabaseConnection extends Component {
    constructor(props) {
        super(props)

        if ('edit' in this.props){
            this.state = {
                host: this.props.dbInfo['host'],
                user: this.props.dbInfo['user'],
                password: '',
                database: this.props.dbInfo['database'],
                port: this.props.dbInfo['port'],
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                connectionValid: null,
                connectionValidMessage: "",
                fetching: false
            }
        } else {
            const dbTypeAndPort = this.getDBTypeAndPort()

            this.state = {
                host:'',
                user:'',
                password:'',
                database: '',
                port: dbTypeAndPort.port,
                databasetype: dbTypeAndPort.type,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                connectionValid: null,
                connectionValidMessage: "",
                fetching: false,
                description: "",

            };
        }

        this.onChange = this.onChange.bind(this);
        this.changeDBType = this.changeDBType.bind(this);

    }

    getDBTypeAndPort = () => {
        let port = "3306";
        let type = "MYSQL"
        if ('type' in this.props){
            type = this.props.type
            port = type === "MYSQL" ? "3306" : "5432"
        }

        return {"type": type, "port": port}
    }

    changeDBType(e, dbType){
        this.setState({ databasetype: dbType, port: dbType === "MYSQL" ? "3306" : "5432" })
    }

    isFromImport = () => {
        let isFromImport = false;

        if ('isFromImport' in this.props){
            isFromImport = this.props.isFromImport
        }

        return isFromImport
    }


    onConnect = (event) => {
        if ('edit' in this.props){
            this.props.testDBConnection(this.state)
        } else {
            this.setState({ fetching: true })
            const unknownError = () => this.setState({ fetching: false, connectionValid: false, connectionErrorMessage: "Try again! If this problem persists contact us at our support"})

            fetch(API_Root + "api/connect-to-api-db/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify(this.state)
            }).then((res) => {

                if (res.status === 200) {
                    res.json().then((data) => {
                        this.setState({
                            connectionValid: data.connectionValid,
                            connectionValidMessage: data.connectionValidMessage,
                            fetching: false
                        })

                        if (data.connectionValid) {
                            if (this.isFromImport()) {
                                //    add the database details to the destinations
                                const copiedDestinations = JSON.parse(JSON.stringify(this.props.customImportDestinations))

                                let accountInfo = {}
                                let dbType = this.state.databasetype.toLowerCase();

                                if (dbType === "mysql" || dbType === "postgres") {
                                    accountInfo = copiedDestinations[dbType + 'AccountInfo']
                                    if (!isObjectEmpty(accountInfo)) {
                                        accountInfo[data.dbPK.toString()] = data.dbImportDetails;
                                        copiedDestinations[dbType + 'AccountInfo'] = accountInfo
                                        this.props.modifyCustomImportSettings("destinations", copiedDestinations)
                                    }

                                } else {
                                    if ('toggleShowHeading' in this.props) {
                                        this.props.toggleShowHeading(false);
                                    }
                                    window.scrollTo({
                                        top: 100,
                                        behavior: 'smooth'
                                    })
                                }
                            } else{
                                this.props.toggleModal(null)
                                this.props.navigateTo("/database/" + data.connectionValidMessage)
                                window.scrollTo({
                                    top: 100,
                                    behavior: 'smooth'
                                })
                                this.props.toggleToast({show: true, message: "Database successfully connected!", type: "success"})
                            }
                        }
                    }).catch(err => unknownError())
                } else {
                    unknownError()
                }
            }).catch(err => unknownError())
        }

        event.preventDefault();
    };

    onChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    // handleTeamSelection(e, { value }){
    //     this.setState({ teamPK: value })
    // }

    inputErrorText = () => {
        let inputError = "";

        if (!('edit' in this.props)){
            if (this.state.host.trim() === ""){
                inputError += "Enter a Hostname. "
            }

            if (this.state.database.trim() === ""){
                inputError += "Enter a Database Name. "
            }

            if (this.state.description.trim() === ""){
                inputError += "Enter a Description. "
            }

            if (isNaN(this.state.port) || this.state.port.trim() === ""){
                inputError += "Enter a valid numerical value for port. "
            }
        }

        return inputError
    }

    render() {
        let mySQLSelectionStyle = {borderRadius: '4px', color: 'black', border: '1px solid #F0F0F0', cursor: 'pointer', padding: '8px 8px 16px 8px'}
        let postgresSelectionStyle = {borderRadius: '4px', color:'black', border: '1px solid #F0F0F0', cursor: 'pointer', padding: '8px 8px 16px 8px'}


        if (this.state.databasetype === "MYSQL"){
            mySQLSelectionStyle['border'] = '2px solid #5499F8'
        } else {
            postgresSelectionStyle['border'] = '2px solid #5499F8'
        }

        const databaseTypeHeader = {fontFamily: "Lato", fontSize: "14px", fontWeight: "700", color: "#161E16"}

        if (!('edit' in this.props)){
            let disabled = false;
        }

        let btnDisabled = false;
        let errorText = null;

        let inputErrorText = this.inputErrorText();

        if (inputErrorText !== ""){
            btnDisabled = true;
            errorText = <h6 style={{color: 'red', marginTop: "1"}}>{inputErrorText}</h6>
        }

        const displayHeader = {fontFamily: "Lato", fontSize: "14px", fontWeight: "400", color: "#5E645E", marginBottom: "8px"}

        let body = <div>
            {
                'edit' in this.props ?
                    null :
                    <div className="row">
                        <div className="col-sm-3" />
                        <div className="col-sm-3">
                            <div style={mySQLSelectionStyle} onClick={(e) => this.changeDBType(e, "MYSQL")}>
                                <div>
                                    {this.state.databasetype === "MYSQL" ? <input type="radio" checked={true}/> : <input type="radio" checked={false}/>}
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <img src={images.mysqlLogo} style={{height: '100px'}} alt="MySQL Icon"/>
                                    <div style={databaseTypeHeader}>MySQL</div>
                                </div>
                            </div>
                            <br/>
                        </div>
                        <div className="col-sm-3">
                            <div style={postgresSelectionStyle} onClick={(e) => this.changeDBType(e, "POSTGRES")}>
                                <div>
                                    {this.state.databasetype === "POSTGRES" ? <input type="radio" checked={true}/> : <input type="radio" checked={false}/>}
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <img src={images.postgresLogo} style={{height: '100px'}} alt="Postgres Icon"/>
                                    <div style={databaseTypeHeader}>Postgres</div>
                                </div>
                            </div>
                            <br/>
                        </div>
                        <div className="col-sm-3" />
                    </div>
            }
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "16px", marginBottom: "16px"}}>
                <div>
                    <div style={displayHeader}>Database Name<span style={{color: 'red'}}>*</span> <InfoPopup info={<span>The name of your database. Ex: <b>prod_database_01</b></span>}/></div>
                    <input style={{width: '100%'}} className="inputGray"
                           value={this.state.database} type='text' placeholder='organization_db_1' name='database' onChange={this.onChange}/>
                </div>
                <div>
                    <div style={displayHeader}>Port<span style={{color: 'red'}}>*</span> <InfoPopup info={<span>Usual defaults are <b>3306</b> for MySQL or <b>5432</b> for Postgres. Must be a number</span>}/></div>
                    <input
                        style={{width: '100%'}} className="inputGray"
                        type='text' placeholder='3306' name='port' value={this.state.port} onChange={this.onChange}/>
                </div>
            </div>
            <div style={{marginBottom: "16px"}}>
                <div style={displayHeader}>Hostname<span style={{color: 'red'}}>*</span> <InfoPopup info={<span>The hostname is a url of your database. Ex: <b>testrds.hostname.amazonaws.com</b></span>}/></div>
                <input style={{width: '100%'}}
                       className="inputGray"
                       type='text'
                       value={this.state.host}
                       placeholder='testrds.hostname.x1x' name='host' onChange={this.onChange}/>
            </div>
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "16px", marginBottom: "16px"}}>
                <div>
                    <div style={displayHeader}>Username <span style={{color: 'orange'}}><i>required, if it exists</i></span> <InfoPopup info={<span>Depending on your setup you may or may not need a username</span>}/></div>
                    <input
                        style={{width: '100%'}}
                        className="inputGray"
                        type='text' value={this.state.user} placeholder='John_username_for_db_provider' name='user' onChange={this.onChange}/>
                </div>
                <div>
                    <div style={displayHeader}>Password <span style={{color: 'orange'}}><i>required, if it exists</i></span> <InfoPopup info={<span>Depending on your setup you may or may not need a password</span>}/></div>
                    <input
                        style={{width: '100%'}}
                        className="inputGray"
                        type='password' value={this.state.password} placeholder='********' name='password' onChange={this.onChange}/>
                </div>
            </div>
            {
                'edit' in this.props ?
                    null :
                    <div style={{marginBottom: "16px"}}>
                        <div style={displayHeader}>Short Description of Database<span style={{color: 'red'}}><i>*</i></span></div>
                        <input style={{width: '100%'}}
                               className="inputGray"
                               type='text' placeholder='Production Database for Finance App' name='description' onChange={this.onChange}/>
                    </div>
            }
            <div id='dbfields'>
                {
                    'edit' in this.props ?
                        <Button disabled={btnDisabled} style={gunaldiStyles.btnGreen} input onClick={this.onConnect} type='submit' loading={this.props.saving}><Icon name="plug" /> Test Connection</Button> :
                        <Button disabled={btnDisabled} style={gunaldiStyles.btnGreen} input onClick={this.onConnect} type='submit' loading={this.state.fetching}><Icon name="plug" /> Connect</Button>
                }
            </div>
            {errorText}
            {
                'edit' in this.props ? null :
                    <TestDatabaseMessage
                        connectionValid={this.state.connectionValid}
                        connectionValidMessage={this.state.connectionValidMessage}
                    />
            }
        </div>

        if (!('edit' in this.props)){
            if (this.state.connectionValid !== null){
                if (this.state.connectionValid){
                    if (this.isFromImport()){
                        body = <div style={{borderRadius: '10px', padding: '20px', boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)', backgroundColor: 'whitesmoke'}}>
                            <h2 style={{color: "green"}}>Congratulations! Your database was successfully connected</h2>
                            <p>Close this popup to select the database and tables to add your spreadsheet data</p>
                        </div>
                    } else {
                        body =
                            <div style={{borderRadius: '10px', padding: '20px', boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)', backgroundColor: 'whitesmoke'}}>
                                <h2 style={{color: "green"}}>Congratulations! Your database was successfully connected</h2>
                                <br/>
                                <DatabaseTypeTag dbType={this.state.databasetype} />
                                <h2>{this.state.database}</h2>
                                <p><i>{this.state.description.trim().length > 25 ? this.state.description.trim().substring(0, 22) + "..." : this.state.description.trim()}</i></p>
                                <br/>
                                <h2 style={{color: 'green'}}><a href={"/database/" + this.state.connectionValidMessage}>Create queries to start using your database as an API <Icon name="arrow right" /></a></h2>
                            </div>
                    }
                }
            }
        }

        return (
            body
        )


    }
}

const mapStateToProps = (state) => ({
    customImportDestinations: state.mainState.customImportDestinations
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings,
    toggleModal: toggleModal,
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(NewDatabaseConnection)