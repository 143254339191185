import React, {Component} from 'react'
import DocsImage from "../DocsImage";
import RereadFile from "../Upload/RereadFile";

class ActionsChangeSheet extends Component {
    render() {
        return (
            <div>
                <div className="docsSection">
                    <RereadFile rereadIndex
                                setBothMenuItems={this.props.setBothMenuItems}
                                hasCsv={false} fileNames={<span><b>excel files</b> and Google Sheets</span>}/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Change Sheet</p>
                    <p>You can change the sheet that is read from the file</p>
                    <p>Go to the <b>Change Sheet</b> action in your File and change the sheet</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-ReadSettings/ChangeSheet.png"
                         width="75%"
                         alt="change sheet " />
                    <p><b>NOTE:</b> this changes the sheet that this file is read from. If you want to read from multiple sheets in a file, then you need to upload that file multiple times, and in each instance select a different sheet</p>
                </div>
            </div>
        )
    }
}

export default ActionsChangeSheet