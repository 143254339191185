import React, {Component} from 'react'
import {connect} from "react-redux";
import {toggleModal} from "../../Actions/ToggleModal";
import {push} from "react-router-redux";
import CTAButtonLanding from "../CTAButtonLanding";
import { Icon } from 'semantic-ui-react'
import SilcaLogo from "../../Constants/SilcaLogo.png";

class FeaturesButton extends Component {
    constructor(props) {
        super(props);

        this.goToFeatures = this.goToFeatures.bind(this);
    }

    goToFeatures(){
        this.props.navigateTo("features")
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    render() {
        return (
            <div className="featuresButtonDiv">
                <CTAButtonLanding />
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <div className="learnFeaturesLandingBtn">
                    <div className="row">
                        <div className="col-sm-2" />
                        <div className="col-sm-8">
                            <div className="segment-raised segment" style={{borderRadius: '100px'}}>
                                <h4 className="thinHeading">Learn how Silca uses API Spreadsheets to build their email list</h4>
                                <a href="http://www.silca.cc" target="_blank" rel="noopener noreferrer">
                                    <img src={SilcaLogo} style={{width: '25%', margin: '0 auto', marginTop: '10px'}} alt="SilcaLogo" />
                                </a>
                                <a href="/case-study/silca"><h4 className="thinHeading linkStyle" style={{marginTop: '10px'}}>Read the post <Icon name="arrow right" /></h4></a>
                            </div>
                        </div>
                        <div className="col-sm-2" />
                    </div>
                </div>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(FeaturesButton)