import React, {Component} from 'react'
import {isMobile} from "react-device-detect";
import MultipleSources from "./MultipleSources";
import CrudActionsDesc from "./CrudActionsDesc";
import SimpleDesign from "./SimpleDesign";
import QueriesDesc from "./QueriesDesc";
import WebhooksDesc from "./LandingSecurity";
import SecurityDesc from "./SecurityDesc";

class APIDatabaseTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeFunction: "multipleSources"
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e, name){
        this.setState({activeFunction: name})
    }

    render() {
        const functionInfo = {
            "multipleSources": { desc: <MultipleSources/>},
            "crudActions": {desc: <CrudActionsDesc/>},
            "simpleDesign": {desc:<SimpleDesign/>},
            // "queriesDesc":{desc:<QueriesDesc/>},
            "webhooksDesc":{desc:<WebhooksDesc/>},
            "securityDesc":{desc:<SecurityDesc/>}

        };
        const generalStyle = { color: "white", cursor: "pointer", padding: "15px 0 15px 15px", fontSize: "14px" }
        const selectedStyle = JSON.parse(JSON.stringify(generalStyle))
        selectedStyle['backgroundColor'] = 'whitesmoke'
        selectedStyle['color'] = 'black'

        const menuItemInfo = [{name: "multipleSources", displayName: "Multiple Sources"},
            {name: "crudActions", displayName: "CRUD Actions"},
            {name:"simpleDesign",displayName: "Simple API Design"},
            /*{name:"queriesDesc", displayName: "Queries"},*/
            {name:"webhooksDesc",displayName: "Webhooks"},
            {name:"securityDesc",displayName:"Security"}
        ];
        const menuItems = menuItemInfo.map((x, idx) =>
            <div style={this.state.activeFunction === x.name ? selectedStyle : generalStyle} key={idx} onClick={e => this.handleClick(e, x.name)}>
                {x.displayName}
            </div>);

        const cleanWidth = isMobile ? "31.25%" : "25%";
        const exampleWidth = isMobile ? "68.75%" : "75%";

        return (
            <div style={{display: "grid",
                boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
                gridTemplateColumns: cleanWidth + " " + exampleWidth, backgroundColor: "black"}}>
                <div style={{padding: "10px 0 10px 0"}}>
                    {menuItems}
                </div>
                <div style={{backgroundColor: "whitesmoke", padding: "12px 25px 25px 25px"}}>
                    {functionInfo[this.state.activeFunction].desc}
                </div>
            </div>
        )
    }
}

export default APIDatabaseTable