import React, {Component} from 'react'
import { Table } from 'semantic-ui-react'

class SampleTable extends Component {
    render() {
        const tableData = this.props.tableData;

        let rows = tableData.map((x, idx) =>
            <Table.Row key={idx.toString}>
                {x.map((y, idx2) => typeof y === 'object' && y !== null ? <Table.Cell key={idx.toString() + idx2.toString()} style={y.style}>{y.value}</Table.Cell> :
                    <Table.Cell key={idx.toString() + idx2.toString()}>{y}</Table.Cell>
                )}
            </Table.Row>);
        let headerCells = this.props.tableHeaders.map((x, idx) => <Table.HeaderCell key={idx.toString()}>{x}</Table.HeaderCell>)
        return (
            <Table celled compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colspan={5} style={{fontWeight: '800', color: '#192841'}}>{this.props.header}</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        {headerCells}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>
            </Table>
        )
    }
}

export default SampleTable