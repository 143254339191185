import React, {Component} from 'react'
import {CardElement, injectStripe} from 'react-stripe-elements';
import API_Root from "../../Constants/API_Root";
import { Message, Button, Icon } from 'semantic-ui-react'

class ChangeCreditCardDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cardSuccess: null,
            loading: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.submit = this.submit.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);

    }

    dismissMessage(){
        this.setState({ cardSuccess: null })
    }

    handleInputChange(e){
        this.setState({
            name: e.target.value
        })
    }

    changeCardDetailsInRedux = (data) => {
        if ('noInvoice' in this.props){
            this.props.updateCreditCardInfo(data)
        } else {
            const paymentInfo = JSON.parse(JSON.stringify(this.props.paymentInfo))
            paymentInfo['allCreditCards'] = data
            this.props.modifyPaymentInfo(paymentInfo)
        }
    }

    successSetState = (callback, msg="") => {
        this.setState({
            loading: false,
            cardSuccess: <Message positive={true}
                                  onDismiss={this.dismissMessage}
                                  header="Your Credit Card was Successfully Updated!"
                                  content={msg}
            />
        }, callback)
    }

    async submit(e) {
        this.setState({
            loading: true
        })

        const failedMessage = "Your Credit Card could not be updated!";

        const isError = (failMsg) => {
            this.setState({
                loading: false,
                cardSuccess: <Message error
                                      onDismiss={this.dismissMessage}
                                      header={failMsg}
                                      content="Please try again or contact us at payment@apispreadsheets.com if the error persists!"
                />
            })
        }

        let {token} = await this.props.stripe.createToken({name: "Name"});

        if (token !== undefined){
            let response = await fetch(API_Root + "api/change-user-credit-card/", {
                method: "POST",
                headers: {"Content-Type": "text/plain"},
                body: JSON.stringify({
                    stripeToken: token.id,
                    stripeFullToken: token,
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                })
            }).then().catch(err =>  isError(failedMessage));

            if (response !== undefined){
                if (response.ok){

                    response.json().then((data) => {
                        this.props.stripe.confirmCardSetup(data['clientSecret'])
                            .then(result => {
                                if ("error" in result){
                                    isError(failedMessage)
                                } else {
                                    fetch(API_Root + "api/confirm-user-card-update/", {
                                        method: "POST",
                                        headers: {"Content-Type": "text/plain"},
                                        body: JSON.stringify({
                                            email: localStorage.getItem("email"),
                                            token: localStorage.getItem("token"),
                                            cardID: data.cardID
                                        })
                                    }).then(res => {
                                        if (res.status === 200){
                                            res.json().then(data => {
                                                this.successSetState(() => {
                                                    this.changeCardDetailsInRedux(data['cards'])
                                                }, data["msg"])
                                            }).catch(err => isError(failedMessage))
                                        } else {
                                            isError(failedMessage)
                                        }
                                    })
                                }
                            })
                    }).catch(err => isError(failedMessage))
                }
                else{
                    isError(failedMessage)
                }
            } else {
                isError(failedMessage)
            }
        }
        else{
            isError(failedMessage)
        }
    }

    render() {
        return (
            <div className="checkout">
                <div className="semanticBorder">
                    <CardElement />
                </div>
                <br/>
                <div>
                    <Button icon="payment"
                            loading={this.state.loading}
                            onClick={this.submit}
                            color="orange"
                            style={{textAlign: 'center'}}
                            content="Update Your Credit Card" />
                </div>
                <br/>
                {this.state.cardSuccess}
            </div>
        )
    }
}

export default injectStripe(ChangeCreditCardDetails)