import React from 'react'
import currencyInfo from "../../Constants/currencyInfo";
import Pricing from "../../Constants/Pricing/Pricing"

const getCurrencyString = () => {
    let currencyValues = [];

    for (let currSymbol in currencyInfo){
        currencyValues.push(currencyInfo[currSymbol].text + " (" + currencyInfo[currSymbol].symbol + ")")
    }

    return currencyValues.join(", ")
}

const currencyString = getCurrencyString();

const contactUs = <a href="mailto:sales@apispreadsheets.com">contact us at sales@apispreadsheets.com</a>

export const pricingFAQs = [
    {
        info: {
            name: "General",
            // type: "button"
        },
        faq: [
            {
                question: "How do I pay for the plan?",
                answer: <p>We accept all Credit Cards using <a href="https://www.stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a> as the payment provider! If you wish to pay via any other payment methods such as PayPal, direct deposit, wire transfer, or Venmo, please email us at <a href="mailto:sales@apispreadsheets.com">sales@apispreadsheets.com</a></p>,
                searchAnswer: "We accept all Credit Cards using Stripe as the payment provider! If you wish to pay via any other payment methods such as PayPal, direct deposit, wire transfer, or Venmo, please email us at sales@apispreadsheets.com"
            },
            {
                question: "Are there any other fees?",
                answer: "Nope, the price you see is the final price!"
            },
            {
                question: "What does a month mean?",
                answer: "A month begins and ends on the date you signed up. For ex: if you signed up on August 13th, then your month will renew on September 13th, October 13th and so on."
            },
            {
                question: "When does my yearly subscription renew?",
                answer: "The yearly subscription renews on the same date as you sign up. So if you signed up on February 28th 2022, then your subscription will renew on February 28th 2023 "
            },
            {
                question: "Do I receive any receipt of the plan?",
                answer: "Yes, you can download your receipts from your Dashboard under Profile."
            },
            {
                question: "How long can I use the free plan for?",
                answer: "As long as you want"
            },
            {
                question: "What if I want to cancel my account?",
                answer: <p>Simply email <a href="mailto:cancel@apispreadsheets.com?Subject=API%20Spreadsheets%Cancel%20Account"> cancel@apispreadsheets.com</a> from the email you signed up with and we will cancel your account!</p>,
                searchAnswer: "Simply email cancel@apispreadsheets.com from the email you signed up with and we will cancel your account!"
            },
            {
                question: "What if I want to downgrade my account to a lower tier?",
                answer: <p>Simply email <a href="mailto:support@apispreadsheets.com?Subject=API%20Spreadsheets%Downgrade%20Account"> info@apispreadsheets.com</a> from the email you signed up with, and the tier you wish to be downgraded to and we will take care of that right away!</p>,
                searchAnswer: "Simply email info@apispreadsheets.com from the email you signed up with, and the tier you wish to be downgraded to and we will take care of that right away!"
            },
            {
                question: "Is there a free trial for the paid accounts?",
                answer: "No, there is not. Since we offer a permanent free tier, we do not offer free trials. But if you want to test out a feature(s) in certain tiers, you can email us with your use case and we can provide you a free trial to those feature(s) for a certain period of time!"
            },
            {
                question: "What currencies do you support?",
                answer: "We currently support the following: " + currencyString
            },
            {
                question: "My currency is not supported. Can I still pay?",
                answer: <span>Yes, of course! If your currency is on the list <a href="https://stripe.com/docs/currencies?presentment-currency=US#presentment-currencies" target="_blank" rel="noopener noreferrer">here</a>, then you can pay in that currency. Email us at <a href="mailto:sales@apispreadsheets.com">sales@apispreadsheets.com</a> and we can set you up</span>,
                searchAnswer: "Yes, of course! If your currency is on the list here, then you can pay in that currency. Email us at sales@apispreadsheets.com and we can set you up"
            },
            {
                question: "I only need certain features that are offered. Can I pay for a custom plan?",
                answer: <span>Yes, you can! Please reach out to <a href="mailto:sales@apispreadsheets.com">sales@apispreadsheets.com</a> and we can customize a plan just for you!</span>,
                searchAnswer: "Yes, you can! Please reach out to sales@apispreadsheets.com and we can customize a plan just for you!"
            },
            {
                question: "How is my payment information handled?",
                answer: <span>We don't store any of your payment information. It all is handled by our payment processor Stripe. You can read their <a href="https://stripe.com/privacy" target="_blank">privacy policy here</a></span>,
                searchAnswer: "We don't store any of your payment information. It all is handled by our payment processor Stripe. You can read their privacy policy here"
            },
            {
                question: "I want to use this tool, but do not have any tech knowledge or technology resources. What can I do?",
                answer: <span>Worry not! We have excellent connections to programmers, data engineers, and other technology peoples. You can {contactUs} and we will get you connected!</span>
            },
            {
                question: "There is a certain feature I desperately need, but it is not offered. What can I do?",
                answer: <span>First, you can check on <a href="https://trello.com/b/4s8vwYDE/api-spreadsheets-roadmap" target="_blank">our roadmap</a> if it is a feature we are already working on. If not, you can {contactUs}. Depending on the feature, we may be able to build it insanely quickly (just ask any one of our business customers).</span>
            }
        ]
    },
    {
        info: {
            name: "Security"
        },
        faq: [
            {
                question: "How is my data secured?",
                answer: <div>
                    <ol>
                        <li>We encrypt data both at-rest and in-transit</li>
                        <li>For spreadsheets connected from external sources, such as Google Sheet, we only store the connection information and no data</li>
                        <li>When you delete a file or an account, everything related to is deleted. However, if you would like auditing and backups for your account, please {contactUs}</li>
                        <li>We offer plenty of API security features such as access & secret keys, domain whitelisting, blocking certain request types, and alerts. You can read all about them <a href="/api-docs/?docsMenuItem=api-security" target="_blank">here</a></li>
                    </ol>
                </div>,
                searchAnswer: "We encrypt data both at-rest and in-transit. For spreadsheets connected from external sources, such as Google Sheet, we only store the connection information and no data. When you delete a file or an account, everything related to is deleted. However, if you would like auditing and backups for your account, please contact us. We offer plenty of API security features such as access & secret keys, domain whitelisting, blocking certain request types, and alerts"
            },
            {
                question: "I need more assurances for my data safety. Do you have any additional measures?",
                answer: <span>Yes, we can execute a data processor agreement with you to guarantee compliance and uptime. This costs extra depending on your organization size and requirements. Please {contactUs}</span>,
                searchAnswer: "Yes, we can execute a data processor agreement with you to guarantee compliance and uptime. This costs extra depending on your organization size and requirements. Please contact us"
            },
            {
                question: "My data is super duper sensitive. Do you have offer on-prem services?",
                answer: <span>Yes, we do! We use docker containers to easily install our software on your company's servers. This costs extra depending on your requirements. Please {contactUs}</span>,
                searchAnswer: "Yes, we do! We use docker containers to easily install our software on your company's servers. This costs extra depending on your requirements. Please contact us"
            }
        ]
    },
    {
        info: {
            name: "Rows per File"
        },
        faq: [
            {
                question: "How do the rows count work?",
                answer: "Each row in the file besides the column names counts as a row"
            },
            {
                question: "What happens to the row count when I CREATE rows?",
                answer: <div>
                    <span>On the free plan, if you CREATE rows that make the file exceed the free limit then you will get a <b>402</b> error and the row won't be added.</span>
                    <span>As a paying customer, our limits are actually soft limits. We won't prohibit you if you go over but if you consistently display this behavior then we will return a <b>402</b> error code.</span>
                </div>,
                searchAnswer: "On the free plan, if you CREATE rows that make the file exceed the free limit then you will get a 402 error and the row won't be added. As a paying customer, our limits are actually soft limits. We won't prohibit you if you go over but if you consistently display this behavior then we will return a 402 error code."
            },
            {
                question: "What happens if I manually add data to my Google Sheets, DropBox, or AWS S3 File?",
                answer: <div>
                    <span>On the free plan, if manually adding the rows has exceeded the limit, then the next time you make an API request, you will get a <b>402</b> error.</span>
                    <span>As a paying customer, our limits are soft limits. Even if your manually adding has exceeded the limit, you will still be able to make requests.</span>
                </div>,
                searchAnswer: "On the free plan, if manually adding the rows has exceeded the limit, then the next time you make an API request, you will get a 402 error. As a paying customer, our limits are soft limits. Even if your manually adding has exceeded the limit, you will still be able to make requests."
            }
        ]
    },
    {
        info: {
            name: "File Count"
        },
        faq: [
            {
                question: "What counts as 1 file?",
                answer: "Each file uploaded by you, each file imported by your user, each Google Sheet connected, each DropBox File selected, each AWS S3 File selected, counts as 1 file each"
            },
            {
                question: "What happens when I reach my file limit?",
                answer: <div>
                    <p>You won't be able to upload any more files unless you upgrade.</p>
                </div>,
                searchAnswer: "You won't be able to upload any more files unless you upgrade. HOWEVER your users will be able to import as many files as they want without any restrictions. You just won't be able to access them until you upgrade"
            },
        ]
    },
    {
        info: {
            name: "Requests per Month"
        },
        faq: [
            {
                question: "What are requests per month?",
                answer: "It refers to the number of API requests that you can make within 1 calendar month"
            },
            {
                question: "What counts as 1 month?",
                answer: "Requests are totaled per calendar month. Let's say you sign up on July 12th with a FREE, then you have " + Pricing[0].requests.toString() + " requests to use till July 31st. If you upgrade to PRO on July 20th, then you have " + Pricing[1].requests.toString() + " requests to use till July 31st. On August 1st, your request count will reset to " + Pricing[1].requests.toString()
            },
            {
                question: "What counts as 1 request?",
                answer: "Any call to the API, GET or POST counts as 1 request. Even if you are creating or getting more than 1 row. So if in 1 API Call you read 300 rows that still counts as 1 request"
            },

            {
                question: "What happens if I exceed my monthly limit?",
                answer: <div>
                    <span>On the free plan, you will get a <b>402</b> error if you exceed your monthly limit.</span>
                    <span>As a paying customer, our limits are soft limits. You can go over (within reason) without fear. However if you constantly abuse this, we will start returning a <b>402</b> error code.</span>
                </div>,
                searchAnswer: "On the free plan, you will get a 402 error if you exceed your daily limit. As a paying customer, our limits are soft limits. You can go over (within reason ~10% threshold) without fear. However if you constantly abuse this, we will start returning a 402 error code."
            }
        ]
    },
    {
        info: {
            name: "Team Members"
        },
        faq: [
            {
                question: "How do Teams work?",
                answer: "You can share files, access APIs, edit files and much more amongst a team"
            },
            {
                question: "Can I invite more team members than my account limit?",
                answer: <p>Yes, you can! Please {contactUs} and we can help you.</p>,
                searchAnswer: "Yes, you can! Contact Us and we can help you."
            },
            {
                question: "Can I control access for my team members?",
                answer: <p>Yes, there are different roles for team members. <a href="/api-docs/?docsMenuItem=teams" target="_blank">Read all about teams</a></p>,
                searchAnswer: "Yes, there are different roles for team members. Read all about teams"
            }
        ]
    },
    {
        info: {
            name: "Importer Included"
        },
        faq: [
            {
                question: "What does Importer refer to?",
                answer: <p>Our Importer product is included for FREE with all the plans. The Importer allows you to build a spreadsheet importer on your website or apps in minutes with no-code! You can even set up data cleaning, and pipelines for the spreadsheet data. <a href="/importer" target="_blank" rel="noopener noreferrer">Read all about the importer here</a></p>,
                searchAnswer: "Our Importer product is included for FREE with all the plans. The Importer allows you to build a spreadsheet importer on your website or apps in minutes with no-code! You can even set up data cleaning, and pipelines for the spreadsheet data. Read all about the importer here"
            },
            {
                question: "Does this mean I can use these importer files as an API?",
                answer: "Yes! As soon as a file is imported, it can be immediately used as an API just as a spreadsheet from any other upload source!"
            }
        ]
    },
    {
        info: {
            name: "Private"
        },
        faq: [
            {
                question: "What does private mean?",
                answer: "Private gives you the ability to secure your API URL with keys so your data can't be accessed by anyone else."
            },
            {
                question: "What if my data is not private?",
                answer: "Don't worry, we don't share or use your data and API in any way. The API URL can only be accessed by anyone who has it."
            }
        ]
    },
    {
        info: {
            name: "Webhooks"
        },
        faq: [
            {
                question: "What are Webhooks?",
                answer: "A webhook is a REST API URL endpoint that API Spreadsheets can call following certain events you select. Such as data being created in a file. Or rows being deleted from a file"
            },
            {
                question: "Why should Webhooks be set up?",
                answer: "You can do anything in a Webhook. For example, you can send an email with the data that was created in your spreadsheet."
            },
            {
                question: "What are the specs for using Webhooks?",
                answer: <span>You can read <a href="/api-docs/?docsMenuItem=actions-webhook" target="_blank">this guide</a> on setting up a webhook with the Spreadsheet API</span>,
                searchAnswer: "You can read this guide on setting up a webhook with the Spreadsheet API or this guide on how to setting up a webhook with the spreadsheet importer"
            }
        ]
    },
    {
        info: {
            name: "Auto Reporting"
        },
        faq: [
            {
                question: "What does Auto Email mean?",
                answer: "You can set up auto email reporting of the spreadsheet or Google Sheet."
            },
            {
                question: "How does Auto Email reporting work?",
                answer: "You can set up times and list of emails that receive your spreadsheet automatically."
            },
        ]
    },
    // {
    //     info: {
    //         name: "API Alerts"
    //     },
    //     faq: [
    //         {
    //             question: "What are success/error alerts?",
    //             answer: "You can set up to receive emails depending on if your API requests were successful or not."
    //         },
    //         {
    //             question: "Why should alerts be set up?",
    //             answer: "Since a lot of API activity happens in the background, alerts help let you know if anything bad is happening. Or if everything is running fine."
    //         }
    //     ]
    // },
    {
        info: {
            name: "Block Requests"
        },
        faq: [
            {
                question: "What does blocking request types do?",
                answer: "It allows you to control what kind of request types you want to allow for your API. For example, you can block any of the following: Read, Update, Create and/or Delete."
            },
            {
                question: "Why would I want to block request types?",
                answer: "This is a security measure you can take to allow/disallow certain request types. For example. some users use it to block access to reading their data and while allowing creating of data."
            }
        ]
    },
    {
        info: {
            name: "Database Connection"
        },
        faq: [
            {
                question: "What is a database connection?",
                answer: "You can connect your database to use as an API"
            },
            {
                question: "What else can I do with my database?",
                answer: <div>
                    <p>You can do anything else that you can do with files. Set up reporting, CRUD values, and share with your team</p>
                    <p><a href="/api-docs/?docsMenuItem=database-introduction" target="_blank" rel="noopener noreferrer">Read more about Databases</a></p>
                </div>,
                searchAnswer: "You can do anything else that you can do with files. Set up reporting, CRUD values, and share with your team"
            },
            {
                question: "What databases are supported?",
                answer: <p>We currently support <b>Postgres</b> and <b>MySQL</b> databases</p>,
                searchAnswer: "We currently support Postgres and MySQL databases"
            }
        ]
    },
    {
        info: {
            name: "Connect Your Domain"
        },
        faq: [
            {
                question: "What does Connect Your Domain mean?",
                answer: "You can connect your own domain to use for making API requests. So instead of apispreadsheets.com it will be yourdomain.com"
            },
            {
                question: "How many domains can I connect?",
                answer: <div>
                    <p>Currently, you can connect 1 domain on the Business plan. If you wish to connect more domains, you can {contactUs} to upgrade to an Enterprise plan</p>
                </div>,
                searchAnswer: "Currently, you can connect 1 domain on the Business plan. If you wish to connect more domains, you can contact us to upgrade to an Enterprise plan"
            },
            {
                question: "How does Connect Your Domain work?",
                answer: <p>You can read the <a href="/api-docs/?docsMenuItem=integrations-connectDomain">Connect Your Domain guide here</a></p>,
                searchAnswer: "You can read the Connect Your Domain guide here"
            }
        ]
    },
    {
        info: {
            name: "Multiple Keys"
        },
        faq: [
            {
                question: "What do multiple keys mean?",
                answer: "You can create multiple Access keys and Secret keys to access files for your team or organization"
            },
            {
                question: "Why would I want to create multiple keys?",
                answer: "Creating multiple keys can let you control and monitor access from different people"
            },
        ]
    },
    {
        info: {
            name: "Domain Whitelist"
        },
        faq: [
            {
                question: "What does domain whitelist mean?",
                answer: "This lets you set up a list of domains that can access the API."
            },
            {
                question: "Why should domain whitelisting be set up?",
                answer: "This is a high grade security feature. It lets you protect your API from any unauthorized access other than your domains!"
            },
        ]
    },
    // {
    //     info: {
    //         name: "Caching Layer"
    //     },
    //     faq: [
    //         {
    //             question: "What is a Caching Layer?",
    //             answer: "A Caching Layer in API Spreadsheets is an API URL that you can set up for 1 to 12 hours that lets you access your data super fast."
    //         },
    //         {
    //             question: "Why would I need to create a Caching Layer?",
    //             answer: "This feature is super helpful when you need to READ your API data very fast for a short amount of time"
    //         },
    //         {
    //             question: "How can I set up the caching layer?",
    //             answer: <p>You can read the <a href="/api-docs/?docsMenuItem=actions-cachingLayer">Caching Layer guide here</a></p>,
    //             searchAnswer: "You can read the Caching Layer guide here"
    //         }
    //     ]
    // },
    {
        info: {
            name: "Support Level"
        },
        faq: [
            {
                question: "What is the FAQ support type?",
                answer: "On the free plan, you are relegated to looking up answers in our FAQs, Guides, Tutorials & Videos"
            },
            {
                question: "What is the Email support type?",
                answer: "You can reach us through email, or a support ticket from Monday to Friday, 6AM to 6PM GMT. You will have a response within 1 business day"
            },
            {
                question: "What does the Priority support type mean?",
                answer: "You can reach us from Monday to Friday, 6AM to 6PM GMT, and will receive help from us before the end of the day that you reach us"
            },
            {
                question: "What does the Dedicated support type mean?",
                answer: "You can reach us from Monday to Friday, 6AM to 6PM GMT, and will receive help from us within 1 hour."
            }
        ]
    }
]