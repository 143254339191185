import React, {Component} from 'react'
import API_Root from "../../Constants/API_Root";
import API_Backend_Root from "../../Constants/API_Backend_Root";

class DocsBaseURL extends Component {
    render() {
        let API;
        if ('old' in this.props){
            API = API_Backend_Root
        }
        else{
            API = API_Backend_Root;
        }

        let identifier = null;
        if ('identifier' in this.props){
            identifier = this.props.identifier
        }

        return (
            <pre className="urlBox wordwrap wordwrap2">
                {identifier}{API + this.props.url}
            </pre>
        )
    }
}

export default DocsBaseURL