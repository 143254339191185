import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Input, Button, Icon } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {modifyAllDBInfo} from "../../Actions/ModifyAllDBInfo";
import NewDatabaseConnection from "../NewDatabaseConnection";
import TestDatabaseMessage from "../TestDatabaseMessage";
import getURLParam from "../../Constants/getURLParam";
import DisplayHeader from "../../FileDnD/FilesID/DisplayHeader";

class EditDatabaseModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editDesc: "",
            isEditingDesc: false,
            saving: false,
            errorTest: null,
            dbAuthInfo: null
        }
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    toggleState = (e, key, value) => {
        if (key === "isEditingDesc"){
            if (value){
                this.setState({ editDesc: this.props.allDBInfo['dbInfo']['description'], isEditingDesc: value })
            }
        } else {
            this.setState({ [key]: value })
        }
    }

    saveDesc = (e) => {
        this.setState({ saving: true });

        const databaseHash = getURLParam(window.location.href);
        const urlEnd = "api/edit-api-db-info/" + databaseHash.toString() + "/"

        const error = () => this.setState({ isEditingDesc: false, editDesc: "", saving: false }, () => alert("There was an error saving. Please try again!"))

        fetch(API_Root + urlEnd, {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                editType: "description",
                value: { description: this.state.editDesc }
            })
        }).then((res) => {
            if (res.status === 200){
                const allDBInfo = JSON.parse(JSON.stringify(this.props.allDBInfo))
                allDBInfo['dbInfo']['description'] = this.state.editDesc
                this.props.modifyAllDBInfo(allDBInfo)
                this.setState({ isEditingDesc: false, editDesc: "", saving: false })
            } else {
                error()
            }
        }).catch(err => error())
    }

    undoDesc = (e) => {
        this.setState({ editDesc: this.props.allDBInfo['dbInfo']['description'], isEditingDesc: false })
    }

    testDBConnection = (dbAuthInfo) => {
        const databaseHash = getURLParam(window.location.href);
        dbAuthInfo['databasetype'] = this.props.allDBInfo['dbInfo']['authInfo']['type']
        dbAuthInfo['databaseHash'] = databaseHash

        this.setState({ saving: true, dbAuthInfo: dbAuthInfo });

        const error = (errorObject={connectionValid: false, connectionValidMessage: "There was an error connecting to your database. Please try again!"}) => this.setState({ saving: false, errorTest: errorObject })

        fetch(API_Root + "api/test-api-db-connection/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify(dbAuthInfo)
        }).then((res) => res.json()).then((data) =>
            {
                error({connectionValid: data.connectionValid, connectionValidMessage: data.connectionValidMessage})
            }
        ).catch(err => error())
    }

    saveDBAuthInfo = (e) => {
        this.setState({ saving: true });

        const databaseHash = getURLParam(window.location.href);
        const urlEnd = "api/edit-api-db-info/" + databaseHash.toString() + "/"

        const error = () => this.setState({ saving: false }, () => alert("There was an error saving. Please try again!"))

        fetch(API_Root + urlEnd, {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                editType: "authInfo",
                value: this.state.dbAuthInfo
            })
        }).then((res) => {
            if (res.status === 200){
                const allDBInfo = JSON.parse(JSON.stringify(this.props.allDBInfo))
                allDBInfo['dbInfo']['authInfo'] = {user: this.state.dbAuthInfo['user'], host: this.state.dbAuthInfo['host'], database: this.state.dbAuthInfo['database'], port: this.state.dbAuthInfo['port'], type: this.state.dbAuthInfo['databasetype']}
                this.props.modifyAllDBInfo(allDBInfo)
                this.setState({ saving: false })
            } else {
                error()
            }
        }).catch(err => error())


    }

    render() {
        const dbInfo = this.props.allDBInfo['dbInfo'];
        const descValue = this.state.isEditingDesc ? this.state.editDesc : dbInfo['description'];

        return (
            <div>
                <div style={{marginBottom: "16px"}}>
                    <DisplayHeader header="Connection Settings"/>
                </div>
                <NewDatabaseConnection edit
                                       saving={this.state.saving}
                                       testDBConnection={this.testDBConnection}
                                       dbInfo={dbInfo['authInfo']}
                                       databaseHash={getURLParam(window.location.href)} />
                {
                    this.state.errorTest !== null ?
                        <div>
                            <TestDatabaseMessage
                                connectionValid={this.state.errorTest.connectionValid}
                                connectionValidMessage={this.state.errorTest.connectionValidMessage}
                                edit
                            />
                            <Button style={{marginTop: '10px'}} fluid size="huge" color="orange" onClick={this.saveDBAuthInfo} loading={this.state.saving}><Icon name="cogs" /> Save Updated Connection Settings</Button>
                            {
                                this.state.errorTest.connectionValid ? null
                                    : null
                            }
                        </div>: null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allDBInfo: state.mainState.allDBInfo
})

const mapActionsToProps = {
    modifyAllDBInfo: modifyAllDBInfo
}

export default connect(mapStateToProps, mapActionsToProps)(EditDatabaseModalBody)