import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import { Checkbox } from 'semantic-ui-react'
import API_Root from "../../../../Constants/API_Root";
import S3BucketPrefix from "./S3BucketPrefix"
import Loader from 'react-loader-spinner'

class S3BucketRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: false,
            bucketPrefixes: null
        }
    }

    componentDidMount(){
        if (this.isBucketChecked()){
            this.getBucketPrefixes()
        }
    }

    getBucketPrefixes = () => {
        if (this.state.bucketPrefixes === null){
            this.setState({ fetching: true })

            const error = () => this.setState({ fetching: false, bucketPrefixes: null})

            fetch(API_Root + "api/get-all-prefixes-for-s3-bucket/", {
                method: "POST",
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    accountName: this.props.accountName,
                    bucketName: this.props.bucketName
                })
            }).then((res) => {
                if (res.status === 200){
                    res.json().then((data) => {
                        this.setState({
                            fetching: false,
                            bucketPrefixes: data.bucketPrefixes
                        })
                    }).catch(err => error())
                } else{
                    error()
                }
            }).catch(err => error())
        }
    }

    isBucketChecked = () => {
        let bucketChecked = false;

        if (this.props.accountName in this.props.destinationsEdit["s3AccountDetails"]){
            let accountInfo = this.props.destinationsEdit['s3AccountDetails'][this.props.accountName]

            if ("bucketNames" in accountInfo){
                if (this.getIdxOfBucketName(accountInfo['bucketNames'], this.props.bucketName) !== -1){
                    bucketChecked = true;
                }
            }
        }

        return bucketChecked
    }

    getIdxOfBucketName = (allBuckets, bucketName) => {
        let idx = -1;
        for (let i=0; i<allBuckets.length; i++){
            if (allBuckets[i]['bucketName'] === bucketName){
                idx = i;
                break
            }
        }

        return idx
    }

    toggleBucketChecked = () => {
        const destinationsEdit = JSON.parse(JSON.stringify(this.props.destinationsEdit))
        const s3AccountDetails = destinationsEdit['s3AccountDetails']

        if (this.props.accountName in s3AccountDetails){
            let accountInfo = s3AccountDetails[this.props.accountName]

            let bucketNames;
            if ("bucketNames" in accountInfo){
                bucketNames = accountInfo['bucketNames']

                if (this.getIdxOfBucketName(accountInfo['bucketNames'], this.props.bucketName) !== -1){
                    bucketNames.splice(this.getIdxOfBucketName(accountInfo['bucketNames'], this.props.bucketName), 1)
                } else {
                    bucketNames.push({bucketName: this.props.bucketName, prefix: ""})
                    this.getBucketPrefixes()
                }
            } else {
                bucketNames = [{bucketName: this.props.bucketName, prefix: ""}]
                this.getBucketPrefixes()
            }

            accountInfo['bucketNames'] = bucketNames

            s3AccountDetails[this.props.accountName] = accountInfo
            destinationsEdit['s3AccountDetails'] = s3AccountDetails

            this.props.modifyCustomImportSettings("destinationsEdit", destinationsEdit)
        }
    }

    getAllBucketNames = () => {
        return this.props.destinationsEdit['s3AccountDetails'][this.props.accountName]['bucketNames']
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-1">
                        <div style={{marginTop: "3px", marginLeft: "10px" }}>
                            <Checkbox
                                checked={this.isBucketChecked()}
                                onChange={this.toggleBucketChecked}
                            />
                        </div>
                    </div>
                    <div className="col-sm-11">
                        <span>{this.props.bucketName}</span>
                    </div>
                </div>
                {
                    this.state.fetching ?
                        <div>
                            <Loader
                                type="TailSpin"
                                color="black"
                                height="10"
                                width="10"
                            />
                            <h6>Getting bucket folders...</h6>
                        </div> : this.state.bucketPrefixes !== null ?
                        <S3BucketPrefix
                            accountName={this.props.accountName}
                            bucketChecked={this.isBucketChecked()}
                            bucketIdx={this.getIdxOfBucketName(this.getAllBucketNames(), this.props.bucketName)}
                            bucketName={this.props.bucketName}
                            bucketPrefixes={this.state.bucketPrefixes}/> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    destinations: state.mainState.customImportDestinations,
    destinationsEdit: state.mainState.customImportDestinationsEdit
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(S3BucketRow)