import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Segment, Button, Icon } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../Actions/ModifyCustomImportSettings";
import {getValueFromInfoObject} from "../getValueFromInfoObject";
import Loader from 'react-loader-spinner'
import API_Root from "../../../Constants/API_Root";
import {modifyImportSheetsFileInformation} from "../../../Actions/ModifyImportSheetsFileInformation";
import { Table } from 'semantic-ui-react'
import ColumnMatchRow from "./ColumnMatchRow"
import {toggleToast} from "../../../Actions/ToggleToast";
import SaveEditSettingsBtn from "./Edit/SaveEditSettingsBtn"
import EditSettingsIndex from "./Edit/EditSettingsIndex"
import SheetNameDelimiterDropdown from "./Edit/SheetNameDelimiterDropdown"
import getColumnMatchedValues from "../../getColumnMatchedValues";

class ColumnMatchingIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            currFileIdx: this.props.fileIdx !== -1 ? this.props.fileIdx : 0,
            columnsMapRows: [],
            gettingMapRows: false,
            saving: false
        }
    }

    componentDidMount(){
        this.initColumnMatchRows()
    }

    returnToUpload = () => {
        this.props.modifyCustomImportSettings("matchColumns", false)
    }

    doMatchesContainDuplicates = () => {
        const removedNull = this.state.columnsMapRows.filter(x => x !== null)
        return (new Set(removedNull)).size !== removedNull.length
    }

    getRequiredColumnNamesNotSelected = () => {
        const matchColumns = this.getColumns(this.getFileInfo()).matchColumns
        let columnNamesNotSelected = []
        for (let i=0; i<matchColumns.length; i++){
            if (matchColumns[i].required){
                if (!(this.state.columnsMapRows.includes(matchColumns[i].name))){
                    columnNamesNotSelected.push(matchColumns[i].name)
                }
            }
        }

        return columnNamesNotSelected
    }

    saveColumnMapping = (e) => {
        // Change the currFileIdx and save column info both to database and redux
        let successMessage = getValueFromInfoObject("successMessage", this.props.info, "appearance")
        if (successMessage === null){
            successMessage = "File Upload Successful!"
        } else if (successMessage.constructor === String){
            if (successMessage.trim() === ""){
                successMessage = "File Upload Successful!"
            }
        }

        let errorMessage = getValueFromInfoObject("errorMessage", this.props.info, "appearance")
        if (errorMessage === null){
            errorMessage = "There was an error saving your column mapping! Please try again"
        } else if (errorMessage.constructor === String){
            if (errorMessage.trim() === ""){
                errorMessage = "There was an error saving your column mapping! Please try again"
            }
        }

        const filePKInfo = this.getFilePkInfo()
        const error = () => this.setState({ saving: false }, () => { this.props.modifyCustomImportSettings("matchColumns", false); this.props.toggleToast({show: true, message: errorMessage, type: "error"}) })

        fetch(API_Root + "api/update-import-column-map/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                pk: filePKInfo.pk,
                columnsMap: this.state.columnsMapRows,
                importColumns: getValueFromInfoObject("columns", this.props.info, "columns")
            })
        }).then((res) => {
            if (res.status === 200) {
                this.updateColumnMap()

                let currIdx = this.state.currFileIdx;

                if (this.shouldContinue()){
                    currIdx += 1
                    this.setState({ currFileIdx: currIdx, saving: false }, () => this.initColumnMatchRows(true))
                } else {
                    this.setState({ saving: false }, () => {
                        this.props.toggleToast({show: true, message: successMessage, type: "success"});
                        this.props.modifyCustomImportSettings("matchColumns", false)
                    })

                }

                this.sendMatchedMessage()

            }
            else {
                error()
            }
        }).catch(err => {error(); console.log(err)})
    }

    sendMatchedMessage = () => {
        try{
            let importSheetsUploadPK = this.props.importSheetsUploadPK;
            let importSheetsFileInformation = this.props.importSheetsFileInformation;

            const matchedArray = getColumnMatchedValues(importSheetsUploadPK, importSheetsFileInformation)

            const parentWindow = window.parent;
            parentWindow.postMessage({match: {required: true, completed: matchedArray}}, "*")
        } catch (e){

        }
    }

    initColumnMatchRows = (goToEdit=false) => {
        this.setState({ gettingMapRows: true })

        const fileInfo = this.getFileInfo()

        const mapRows = this.getColumnMapRows(fileInfo)

        if (mapRows.length > 0){
            this.setState({ columnsMapRows: mapRows, gettingMapRows: false })
        } else {
            const columns = this.getColumns(fileInfo)

            const error = () => {
                let mapRows = [];

                for (let i=0; i<columns.fileColumns.length; i++){
                    if (i < columns.matchColumns.length){
                        mapRows.push(columns.matchColumns[i])
                    } else {
                        mapRows.push(null)
                    }
                }

                this.setState({ columnsMapRows: mapRows, gettingMapRows: false })
            }

            fetch(API_Root + "api/fuzzy-match-import-columns/", {
                method: "POST",
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    fileColumns: columns.fileColumns,
                    matchColumns: columns.matchColumns.map(x => x.name)
                })
            }).then((res) => {
                if (res.status === 200) {
                    res.json().then((data) => {
                        this.setState({ gettingMapRows: false, columnsMapRows: data['columnsMap'] })
                        if (goToEdit){
                            this.props.modifyCustomImportSettings("shouldEdit", false)
                        }
                    }).catch(err => error())
                }
                else {
                    error()
                }
            }).catch(err => error())
        }
    }

    getFilePkInfo = () => { return this.props.importSheetsUploadPK[this.state.currFileIdx] }

    getFileInfo = () => {
        const allPKInfo = this.getFilePkInfo()
        const pkID = allPKInfo.id;

        return this.props.importSheetsFileInformation[pkID]
    }

    getColumns = (fileInfo) => {
        const fileColumns = fileInfo.columnInformation.names;
        const matchColumns = getValueFromInfoObject("columns", this.props.info, "columns")

        return {fileColumns: fileColumns, matchColumns: matchColumns}
    }

    getColumnMapRows = (fileInfo) => {
        let mapRows = []
        if ('importOptions' in fileInfo){
            if ("columnsMap" in fileInfo.importOptions){
                mapRows = fileInfo.importOptions.columnsMap
            }
        }
        return mapRows
    }

    updateColumnMap = () => {
        const fileInfo = this.getFileInfo()
        const importSheetsInfo = fileInfo.importSheetsInfo;
        importSheetsInfo['columnsMap'] = this.state.columnsMapRows
        fileInfo.importSheetsInfo = importSheetsInfo

        const allPKInfo = this.getFilePkInfo()
        const allFileInfo = JSON.parse(JSON.stringify(this.props.importSheetsFileInformation))

        allFileInfo[allPKInfo.id] = fileInfo

        this.props.modifyImportSheetsFileInformation(allFileInfo)
    }

    changeColumnMap = (idx, value) => {
        const currColumnsMapRows = JSON.parse(JSON.stringify(this.state.columnsMapRows))
        currColumnsMapRows[idx] = value

        this.setState({ columnsMapRows: currColumnsMapRows })
    }

    shouldContinue = () => {
        let shouldContinue = false;

        if (this.props.importSheetsUploadPK.length > 1){
            if (this.props.fileIdx === -1){
                // if it is not the last file
                if (this.state.currFileIdx < this.props.importSheetsUploadPK.length - 1){
                    shouldContinue = true
                }
            } else {
                // if it is not the last file
                if (this.props.fileIdx < this.props.importSheetsUploadPK.length - 1){
                    // if all the files after need their columns matched
                    let filesWithColumnMatched = 0;
                    for (let i=this.props.fileIdx; i<this.props.importSheetsUploadPK.length; i++){
                        const pkID = this.props.importSheetsUploadPK[i].id;
                        const fileInfo = this.props.importSheetsFileInformation[pkID];

                        if ("importSheetsInfo" in fileInfo){
                            if ("columnsMap" in fileInfo.importSheetsInfo){
                                if (fileInfo.importSheetsInfo.columnsMap.constructor === Array){
                                    if (fileInfo.importSheetsInfo.columnsMap.length === 0){
                                        filesWithColumnMatched += 1
                                    }
                                }
                            }
                        }
                    }

                    if (filesWithColumnMatched === (this.props.importSheetsUploadPK.length - this.props.fileIdx)){
                        shouldContinue = true
                    }
                }
            }
        }

        return shouldContinue
    }

    changeState = (key, value) => this.setState({ [key]: value })

    render() {
        const fileInfo = this.getFileInfo()
        const allColumnsInfo = this.getColumns(fileInfo)

        const dropdownOptions = allColumnsInfo.matchColumns.map((x, idx) => { return { key: idx, value: x.name, text: x.name } })
        dropdownOptions.push({ key: -1, value: null, text: "NO MATCH"})

        const requiredColumnNamesNotSelected = this.getRequiredColumnNamesNotSelected();

        const pkInfo = this.getFilePkInfo()

        let sampleLength = fileInfo.rowLength;

        if ("matrixSample" in fileInfo){
            if ("data" in fileInfo.matrixSample){
                sampleLength = fileInfo.matrixSample.data.length + 1;
            }
        }
        return (
            <div style={{paddingBottom: "150px"}}>
                <div className="row" style={{marginBottom: "20px"}}>
                    <div className="col-sm-1" />
                    <div className="col-sm-5">
                        <Button basic color="red" onClick={this.returnToUpload} size="mini" style={{marginBottom: "20px"}}>
                            <Icon name="left arrow" /> Go back to Upload
                        </Button>
                    </div>
                    <div className="col-sm-5">
                        <div style={{textAlign: "right"}}>
                            {
                                this.props.shouldEdit ?
                                    <SaveEditSettingsBtn initColumnMatchRows={this.initColumnMatchRows} userEmail={this.props.userEmail} pk={pkInfo.pk} pkInfo={pkInfo} importKey={this.props.importKey}/> :
                                    <span>
                                        <Button color="green"
                                                size="large"
                                                loading={this.state.saving}
                                                onClick={this.saveColumnMapping}
                                                disabled={requiredColumnNamesNotSelected.length > 0 || this.doMatchesContainDuplicates()}>
                                            <Icon name="save alternate outline" /> <Icon name="columns" /> Save Column Mapping
                                            { this.shouldContinue() ? <span> & Continue <Icon name="arrow right"/></span> : null}
                                        </Button>
                                        {requiredColumnNamesNotSelected.length > 0 ? <small style={{color: "red", display: "block"}}>These mapped column names need to be selected: {requiredColumnNamesNotSelected.join(",")}</small> : null}
                                    </span>
                            }
                        </div>
                    </div>
                    <div className="col-sm-1" />
                </div>
                <div className="row">
                    <div className="col-sm-1" />
                    <div className="col-sm-6">
                        <h5 className="text-muted" style={{marginBottom: "0"}}>
                            Step {this.props.shouldEdit ? "1" : "2"} out of 2:
                            <span style={{fontWeight: "bold"}}>
                                {this.props.shouldEdit ? " Verify your file structure" : " Map your columns"}
                            </span>
                        </h5>
                        <Segment>
                            <h3 className="wordwrap">{fileInfo.name}</h3>
                            {this.props.shouldEdit ? <small className="text-muted"><i>Showing first {sampleLength} out of {fileInfo.rowLength + 1} rows</i></small> : null}
                        </Segment>
                    </div>
                    <div className="col-sm-4">
                        {this.props.shouldEdit ? <SheetNameDelimiterDropdown pk={pkInfo.pk} pkID={pkInfo.id} /> : null }
                    </div>
                    <div className="col-sm-1" />
                </div>
                <div className="row">
                    <div className="col-sm-1" />
                    <div className="col-sm-10">
                        {
                            this.state.gettingMapRows ?
                            <div>
                                <Loader
                                    type="TailSpin"
                                    color="black"
                                    height="50"
                                    width="50"
                                />
                                <h4>Smartly mapping your columns...</h4>
                            </div> :
                                this.props.shouldEdit ? <EditSettingsIndex pkID={pkInfo.id}
                                                                           pk={pkInfo.pk}
                                                                           changeState={this.changeState}
                                                                           fileInfo={fileInfo} /> :
                                <div>
                                    <Table>
                                        <Table.Header>
                                            <Table.HeaderCell width={4}>Your Column Name</Table.HeaderCell>
                                            <Table.HeaderCell width={4}>Map Your Column</Table.HeaderCell>
                                            {/*<Table.HeaderCell width={3}>Type</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell width={5}>Description</Table.HeaderCell>*/}
                                        </Table.Header>
                                        <Table.Body>
                                            {
                                                allColumnsInfo.fileColumns.map((x, idx) =>
                                                <ColumnMatchRow
                                                    key={idx}
                                                    idx={idx}
                                                    info={x}
                                                    changeColumnMap={this.changeColumnMap}
                                                    dropdownOptions={dropdownOptions}
                                                    allMatchedColumns={this.state.columnsMapRows}
                                                    matchedColumn={this.state.columnsMapRows[idx]}
                                                />)
                                            }
                                        </Table.Body>
                                    </Table>
                                </div>
                        }
                    </div>
                    <div className="col-sm-1" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    info: state.mainState.customImportInfo,
    importSheetsUploadPK: state.mainState.importSheetsUploadPK,
    importSheetsFileInformation: state.mainState.importSheetsFileInformation,
    matchColumns: state.mainState.customImportMatchColumns,
    fileIdx: state.mainState.customImportFileIdx,
    shouldEdit: state.mainState.customImportShouldEdit
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings,
    modifyImportSheetsFileInformation: modifyImportSheetsFileInformation,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnMatchingIndex)