import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Backend_Root from "../../Constants/API_Backend_Root";
import RequestURLBox from "../../SharedComponents/RequestURLBox"
import UtilitiesSampleRequest from "../UtilitiesSampleRequest"
import UpgradeToTeam from "../../FileDnD/UpgradeToTeam"
import {getFullAPIUrl} from "../getFullAPIUrl";
import UtilitiesSampleResponse from "../UtilitiesSampleResponse"

class AIIndex extends Component {
    constructor(props) {
        super(props);
    }

    getDivStyle = (isTeam) => {
        let style = {marginTop: "16px"}

        if (!isTeam){
            style['pointerEvents'] = "none"
            style['opacity'] = "0.5"
            // style['cursor'] = "not-allowed"
        }

        return style
    }

    render() {
        const isTeam = this.props.tier === "team" || this.props.tier === "business" || this.props.tier === "enterprise";

        const url = getFullAPIUrl(this.props.baseURL, "ai")

        return (
            <div>
                {isTeam ? null :
                    <div style={{marginTop: "8px"}}>
                        <UpgradeToTeam higherTier="ai" header="You can only use the AI API on the Team or Higher Tier"/>
                    </div>}
                <div style={isTeam ? null : {"cursor": "not-allowed"}}>
                    <div style={this.getDivStyle(isTeam)}>
                        <div style={{ marginBottom: "24px"}}>
                            <RequestURLBox url={url} />
                        </div>
                        <div style={{ marginBottom: "24px"}}>
                            <UtilitiesSampleRequest mode="ai" url={url}/>
                        </div>
                        <div style={{ marginBottom: "24px"}}>
                            <UtilitiesSampleResponse mode="ai" url={url} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(AIIndex)