import React, {Component} from 'react'
import AuthComponent from "../../../SharedComponents/AuthComponent";
import LoginPage from "./LoginPage";
import ProfileIndex from "../../../InfoPages/Profile/ProfileIndex";

class LoginIndex extends Component {
    componentDidMount(){
        document.title = "Login - API Spreadsheets"
    }

    render() {
            return (
                <div>
                    <LoginPage/>
                </div>
            )

    }
}

export default LoginIndex