import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'
import DocsBaseURL from "../../InfoPages/DocsPage/DocsBaseURL"
import SampleTable from "./SampleTable"
import JSONDisplay from "../../SharedComponents/JSONDisplay"
import TogglePrivate from "./Read/TogglePrivate"
import PostPrivateHeaders from "./PostPrivateHeaders";
import {createFileInformation} from "./Functions/createFileInformation";
import {createFullURLFromBase} from "../../Constants/createFullURLFromBase";
import CodeSnippet from "../../FileDnD/CodeSnippet";
import {createCodeSnippets} from "./Functions/createCodeSnippets";

class CreateRows extends Component {
    constructor(props){
        super(props);

        this.state = {
            sampleTableData: [
                [1, "Justin Trudeau", 252069.46, "Canada"],
                [2, "Jacinda Ardern", 339862.25, "New Zealand"],
                [3, "Boris Johnson", 187033.75, "UK"],
                [4, "Joko Widodo", 27200.8, "Indonesia"],
                [5, "Angela Merkel", 369727.4, "Germany"]
            ],
            sampleTableHeaders: ["id", "name", "salary_$", "country"],
            sampleDataType: ["num", "str", "num", "str"],
            isPrivate: false,
            dataFormat: "row"
        }

        this.togglePrivate = this.togglePrivate.bind(this);
        this.changeState = this.changeState.bind(this);
    }

    togglePrivate(e){
        this.setState({ isPrivate: !this.state.isPrivate })
    }

    changeState(name, value){
        this.setState({
            [name]: value
        })
    }

    render() {
        let baseGetURL = 'data/1023/'
        const tableAddRows = this.state.sampleTableData.concat([[6, "Emmanuel Macron", 204315.56, "France"], [7, "Lee Hsien Loong", 1540832.04, "Singapore"], [8, "Donald Trump", 400000, "USA"]])

        const sampleFileInformation1Row = createFileInformation([[6, "Emmanuel Macron", 204315.56, "France"]], this.state.sampleTableHeaders, this.state.sampleDataType, 1023, "World Leaders", this.state.isPrivate, createFullURLFromBase('data/1023'))
        const sampleFileInformation2Row = createFileInformation([[6, "Emmanuel Macron", 204315.56, "France"], [7, "Lee Hsien Loong", 1540832.04, "Singapore"], [8, "Donald Trump", 400000, "USA"]], this.state.sampleTableHeaders, this.state.sampleDataType, 1023, "World Leaders", this.state.isPrivate, createFullURLFromBase('data/1023'))

        const info1AccessKey = sampleFileInformation1Row["1023_1"].accessKey;
        const info2AccessKey = sampleFileInformation2Row["1023_1"].accessKey;

        const info1SecretKey = sampleFileInformation1Row["1023_1"].secretKey;
        const info2SecretKey = sampleFileInformation1Row["1023_1"].secretKey;

        return (
            <div>
                <p>We are going to walk through an example of creating rows to an existing file</p>
                <br/>
                <h2>Create a new row for <b style={{color: 'orange'}}>World Leaders</b> data</h2>
                <SampleTable
                    tableData={this.state.sampleTableData}
                    tableHeaders={this.state.sampleTableHeaders}
                    header={<span style={{color: 'orange'}}>World Leaders</span>}
                />
                <SampleTable
                    tableData={[[6, "Emmanuel Macron", 204315.56, "France"]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    header="Row to create"
                />
                <br/><br/>
                <h2>Make a POST request to this URL <TogglePrivate isPrivate={this.state.isPrivate} togglePrivate={this.togglePrivate}/></h2>
                <Label color="orange">POST</Label>
                <DocsBaseURL url={baseGetURL} />
                <p>We are using an example <code>fileID</code> of <b>1023</b> in the examples below. Substitute this with your own <code>fileID</code></p>
                <JSONDisplay
                    heading="Include a JSON Encoded Body in your Request"
                    tableData={[[6, "Emmanuel Macron", 204315.56, "France"]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    dataTypes={this.state.sampleDataType}
                    outputType="columnValue"
                    postRequest
                />
                <br/>
                {
                    this.state.isPrivate ?
                        <div>
                            <PostPrivateHeaders accessKeyInfo={info1AccessKey} secretKeyInfo={info1SecretKey}/>
                            <br/>
                        </div> : null
                }
                <CodeSnippet
                    code={createCodeSnippets(sampleFileInformation1Row, "createAction", createFullURLFromBase(baseGetURL), "columnValue")}
                    height={150}
                />
                <SampleTable
                    tableData={tableAddRows.slice(0, tableAddRows.length - 2)}
                    tableHeaders={this.state.sampleTableHeaders}
                    header="World Leaders - After Creating a Row"
                />
                <br/><br/><br/>
                <h2>Request Body Format is different to create more than one row    <TogglePrivate isPrivate={this.state.isPrivate} togglePrivate={this.togglePrivate}/></h2>
                <Label color="orange">POST</Label>
                <DocsBaseURL url={baseGetURL} />
                <p>You can create multiple rows in 2 different formats</p>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="clickDiv" style={{backgroundColor: this.state.dataFormat === "row" ? "lightgray" : ""}} onClick={(e) => this.changeState("dataFormat", "row")}>
                            <h5>Row</h5>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="clickDiv" style={{backgroundColor: this.state.dataFormat === "column" ? "lightgray" : ""}} onClick={(e) => this.changeState("dataFormat", "column")}>
                            <h5>Column</h5>
                        </div>
                    </div>
                </div>
                <br/>
                <JSONDisplay
                    heading="Include a JSON Encoded Body in your Request"
                    tableData={[[6, "Emmanuel Macron", 204315.56, "France"], [7, "Lee Hsien Loong", 1540832.04, "Singapore"], [8, "Donald Trump", 400000, "USA"]]}
                    tableHeaders={this.state.sampleTableHeaders}
                    dataTypes={this.state.sampleDataType}
                    outputType={this.state.dataFormat}
                    postRequest
                />
                <br/>
                {
                    this.state.isPrivate ?
                        <div>
                            <PostPrivateHeaders accessKeyInfo={info2AccessKey} secretKeyInfo={info2SecretKey}/>
                            <br/>
                        </div> : null
                }
                <CodeSnippet
                    code={createCodeSnippets(sampleFileInformation2Row, "createAction", createFullURLFromBase(baseGetURL), this.state.dataFormat, true)}
                    height={150}
                />
                <SampleTable
                    tableData={tableAddRows}
                    tableHeaders={this.state.sampleTableHeaders}
                    header="World Leaders - After Creating Multiple Rows"
                />
            </div>
        )
    }
}

export default CreateRows