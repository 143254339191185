import React, {Component} from 'react'
import {isMobile} from "react-device-detect";

class ContactSegment extends Component {
    render() {
        return (
            <div style={{marginTop: isMobile ? "20px" : "164px", backgroundColor: "#F4FBF3", borderRadius: "10px", padding: isMobile ? "24px" : "50px"}}>
                <div className="row">
                    <div className="col-sm-6">
                        <div style={{fontWeight: "700", fontSize: "24px", color: "#161E16", marginBottom: "8px"}}>{this.props.header}</div>
                        <div style={{fontWeight: "400", fontSize: "14px", color: "#5E645E", }}>{this.props.desc}</div>
                    </div>
                    <div className="col-sm-6">
                        <div style={{fontWeight: "500",
                            fontSize: "16px",
                            lineHeight: "150%",
                            color: "#161E16",
                            paddingTop: isMobile ? "8px" : "24px",
                            textAlign: isMobile ? "left": "right"}}>
                            Email us at <a href="mailto:info@apispreadsheets.com">info@apispreadsheets.com</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactSegment