import React, {Component} from 'react'
import {connect} from 'react-redux'

class UploadQuickstart extends Component {
    render() {
        const imgStyle = {border:"1px solid #F0F0F0", display: "block", marginBottom: "16px"}

        return (
            <div>
                <div className="docsSection">
                    <p>A Quickstart API is <b>VERY</b> different than the other upload types</p>
                    <p>A Quickstart API:</p>
                    <div className="docsList">
                        <p>1. holds only <b>1 JSON object</b></p>
                        <p>2. can only be <b>READ</b> from</p>
                    </div>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Creating a Quickstart API</p>
                    <p>Creating a Quickstart API is extremely straightforward</p>
                    <div className="docsList">
                        <p>1. Provide a unique name for the API</p>
                        <p>2. Specify the <b>keys</b> and <b>values</b> for the JSON object you wish to receive from this API</p>
                    </div>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-Quickstart/CreateQuickstartAPI1.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="create quickstart api" />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Editing Values</p>
                    <p>Once you have created a Quickstart API, you can edit the <b>keys</b> and <b>values</b> at any time</p>
                    <p>1. Select the Quickstart API from your <b>Files</b> page</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-Quickstart/SelectQuickstartAPI2.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="select quickstart api" />
                    <p>2. Click on the <b>Edit Values</b> button from the Quickstart API's page</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-Quickstart/EditValuesBtnAPI3.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="edit values" />
                    <p>3. Enter the new values you wish to read from the API</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-Quickstart/EditValuesModalAPI4.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="edit values modal" />
                </div>
                {/*What is quickstart*/}
                {/*purpose*/}
                {/*only get*/}
                {/*entering the values*/}
                {/*editing the values*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UploadQuickstart)