import React, {Component} from 'react'
import isObjectEmpty from "../Constants/isObjectEmpty";
import SelectAWSAccountRow from "./SelectAWSAccountRow";
import DisplayHeader from "../FileDnD/FilesID/DisplayHeader";

class SelectAWSAccount extends Component {
    render() {
        if (isObjectEmpty(this.props.awsS3Accounts)){
            return null
        } else {
            let awsAccountRows = [];

            for (let accountName in this.props.awsS3Accounts){
                awsAccountRows.push(<SelectAWSAccountRow accountName={accountName}
                                                         setErrorMsg={this.props.setErrorMsg}
                                                         changeState={this.props.changeState}
                                                         accountInfo={this.props.awsS3Accounts[accountName]}

                />)
            }

            return (
                <div style={{marginBottom: "16px", paddingBottom: "16px", borderBottom: "1px solid #F0F0F0"}}>
                    <div style={{marginBottom: "16px"}}>
                        <DisplayHeader header="Select an already connected AWS S3 Account"/>
                    </div>
                    {awsAccountRows}
                </div>
            )
        }
    }
}

export default SelectAWSAccount