import React, {Component} from 'react'
import SampleStartTable from '../SampleStartTable'
import DeleteTable from './DeleteTable'
import DocsBaseURL from '../../DocsBaseURL'
import DocsGetURLParameters from "./DocsGetURLParameters";
import DocsGetResponseCode from "./DocsGetResponseCode"
class DeleteIndex extends Component {
    render() {
        let baseGetURL = 'data/[fileID]/?query=[query]&accessKey=[accessKey]&secretKey=[secretKey]/';

        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader"><b>Delete</b> refers to deleting entire rows</p>
                    <br/>
                    <div className="row">
                        <div className="col-sm-6">
                            <SampleStartTable/>
                        </div>
                        <div className="col-sm-6">
                            <DeleteTable/>
                        </div>
                    </div>
                </div>
                <div className="docsSection">
                    <p className="docsHeader" style={{marginBottom: "0", paddingBottom: "0"}}>Request</p>
                    <hr/>
                    <p className="docsHeader">Make a <b>GET</b> Request to the following URL</p>
                    <DocsBaseURL url={baseGetURL} />
                    <i>Example URL</i>
                    <DocsBaseURL url="data/184/?query=delete from aBcDe123xy0z where col_name='abc'&accessKey=12345678901234567890123456789012&secretKey=12345678901234567890123456789012/" />
                    <DocsGetURLParameters setBothMenuItems={this.props.setBothMenuItems}/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader" style={{marginBottom: "0", paddingBottom: "0"}}>Response</p>
                    <hr/>
                    <span>The following response codes can be returned</span>
                    <div style={{fontSize: '115%'}}>
                        <DocsGetResponseCode/>
                    </div>
                </div>
            </div>
        )
    }
}

export default DeleteIndex