import React, {Component} from 'react'
import {connect} from 'react-redux'
import SampleRequestDataType from "./SampleRequestDataType";
import ParameterRow from "../Utilities/ParameterRow"
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

class RequestParameterIndex extends Component {
    constructor(props) {
        super(props);
    }

    inputDataMsg = () => {
        switch (this.props.mode){
            case "ai":
                return "ask questions in natural language"
            case "create":
                return "create a file from"
            case "download":
                return "download in a spreadsheet"
            default:
                return "transform"
        }
    }

    dataLengthWarning = () => {
        switch (this.props.dataFormat){
            case "row":
                return "Row format is an array of objects (hashmaps), where each object represents a row in the data. The keys in the object are the column names, and values are the values of that column in that row. Make sure the keys in each object in the array are the same."
            case "column":
                return "Column format is a hashmap where the keys are the column names and the values are arrays where each item contains the value of a row for that column. Make sure the value arrays for each key are the same length"
            case "matrix":
                return "Matrix format is a 2D array (array of arrays) where each array represents a row in the data. Make sure each array inside the primary array is of the same length. The columnNames parameter must be used with this dataFormat"
            default:
                return ""
        }
    }

    getParameterType = () => {
        switch (this.props.dataFormat){
            case "row":
                return "array (of hashmaps)"
            case "column":
                return "hashmap"
            case "matrix":
                return "array (of arrays)"
            default:
                return ""
        }
    }

    render() {
        const fileExtension = this.props.mode === "create" ? '.csv' : this.props.mode === "download" ? ".xlsx" : ""
        return (
            <div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter="data"
                        type={this.getParameterType()}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                The input data that you want to {this.inputDataMsg()}
                            </div>
                            <div style={{ marginBottom: "2px"}}>
                                Choose Data Format to see example format in the sample request:
                                <SampleRequestDataType
                                    idx={0}
                                    dataFormat={this.props.dataFormat}
                                    changeState={this.props.changeState}
                                />
                            </div>
                        </div>}
                        details={
                            <div>
                                {this.dataLengthWarning()}
                            </div>
                        }
                    />
                </div>
                <div style={{ marginBottom: "12px" }}>
                    <ParameterRow
                        parameter={this.props.mode === "ai" ? "query" : "fileName"}
                        type={"string"}
                        body={<div>
                            <div style={{ marginBottom: "2px"}}>
                                {
                                    this.props.mode === "ai" ?
                                        "The natural language prompt that you wish to ask your dataset. For e.g., show me all movies that Chris Pratt acted in"
                                        : `Name of the file that you wish to ${this.props.mode}. You DO NOT need the file extension. For e.g., 'Report_123' is sufficient as the file name. This file will be automatically created as a ${fileExtension}.`
                                }
                            </div>
                        </div>}
                    />
                </div>
                {
                    this.props.isPrivate ?
                    <div>
                        <div style={{ marginBottom: "12px" }}>
                            <ParameterRow
                                parameter="accessKey"
                                type={"string (32 characters)"}
                                body={<div>
                                    <div style={{ marginBottom: "2px"}}>
                                        Access Key required if your API is private. You can switch this off in the settings
                                    </div>
                                </div>}
                            />
                        </div>
                        <div style={{ marginBottom: "12px" }}>
                            <ParameterRow
                                parameter="secretKey"
                                type={"string (32 characters)"}
                                body={<div>
                                    <div style={{ marginBottom: "2px"}}>
                                        Secret Key required if your API is private. You can switch this off in the settings
                                    </div>
                                </div>}
                            />
                        </div>
                    </div> : null
                }
                    {/* data:  choose data format */}
                    {/* if private: {accessKey: xxx, secretKey: xxx}:  choose data format */}
                    {/* if api: query */}
                    {/* if download/create: then fileName*/}

            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(RequestParameterIndex)