import React, {Component} from 'react'
import DocsPostHeaderParameters from "./DocsPostHeaderParameters";
import DocsPostBodyParameters from "./DocsPostBodyParameters";
import { Divider, Popup } from 'semantic-ui-react'
import DocsPostBodyReq from "./DocsPostBodyReq";

class DocsPostRequest extends Component {
    render() {
        if (this.props.type === "headers"){
            return(
                <div className="row">
                    <div className="col-sm-6">
                        <DocsPostHeaderParameters/>
                    </div>
                    <div className="col-sm-6">

                    </div>
                </div>
            )
        }
        else if (this.props.type === "url"){
            return(
                <div className="row">
                    <div className="col-sm-6">
                        <h5 id="parameterHeading">URL Parameter</h5>
                        <Divider/>
                        <span className="parameterName">fileID</span> <span className="parameterType">integer</span>
                        <span className="parameterSecondLine"><span className="parameterRequired">Required</span> </span>
                        <span className="parameterDesc">This is the id of the file you are making requests to. It can be found on your file page
                            <Popup content={<img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/API-Introduction/FileID.png" alt="File ID"/>} trigger={<span className="linkStyle"> here</span>} />
                        </span>
                    </div>
                    <div className="col-sm-6">

                    </div>
                </div>
            )
        }
        else{
            return (
                <div className="row">
                    <div className="col-sm-6">
                        <DocsPostBodyParameters/>
                        <br/>
                        <DocsPostHeaderParameters/>
                    </div>
                    <div className="col-sm-6">
                        <DocsPostBodyReq/>
                    </div>
                </div>
            )
        }
    }
}

export default DocsPostRequest