import React, {Component} from 'react'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import RequestURLBox from "../../SharedComponents/RequestURLBox"
import API_Backend_Root from "../../Constants/API_Backend_Root";

class CreateResults extends Component {
    render() {
        return (
            <div style={{ marginTop: "8px"}}>
                <div style={{ border: "1px solid #F0F0F0", borderRadius: "8px", padding: "12px"}}>
                    <div style={{ fontSize: "16px", fontWeight: "700", color: gunaldiStyles.subText }}>CSV file created in your account</div>
                    <div style={{ fontSize: "12px", color: gunaldiStyles.subText }}>Read & Write to your file with an API</div>
                    <div style={{ marginTop: "12px"}}>
                        <RequestURLBox url={API_Backend_Root + "data/{file_id}/"} noRequestType noBorder={this.props.isMobile}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateResults