import React, {Component} from 'react'
import { Divider } from 'semantic-ui-react'
import ReactJson from 'react-json-view'

class SpreadsheetImportCodeJSON extends Component {
    render() {
        const sampleRequest = {
            "apiUrl": "https://api.apispreadsheets.com/data/dS0bXsCIjB5gBJ16/",
            "colLen": 3,
            "rowLen": 2522,
            "columnNames": ["name", "email", "age"],
            "createdDt": "2022-02-11 01:17:52",
            "duplicateColumnNames": false,
            "fileID": "dS0bXsCIjB5gBJ16",
            "importKey": "d8832db655301ed2573139943ef04748",
            "name": "Customers.xlsx",
            "sheetNames": ["Year 2021", "Year 2022"],
            "sheetRead": "Year 2021",
            "size": 2012,
            "sampleData": [{
                name: "Jake Peralta",
                email: "jake@nypd.org",
                age: 38
            }, {
                name: "Khaleesi",
                email: "khaleesi@dragons.com",
                age: 22
            }, {
                name: "Beyonce",
                email: "beyonce@singing.net",
                age: 34
            }, {
                name: "Chandler Bing",
                email: "chandler@data.edu",
                age: 37
            }, {
                name: "Phil Dunphy",
                email: "phil@dunphy.com",
                age: 51
            }],

        }

        return (
            <div className="row">
                <div className="col-sm-6">
                    <h5 id="parameterHeading">Data JSON Object Keys</h5>
                    <Divider/>
                    <span className="parameterName">apiUrl</span> <span className="parameterType">string</span>
                    <span className="parameterSecondLine"> </span>
                    <span className="parameterDesc">The API URL for this file. You can make a <b>GET</b> request to this URL to read all the data in the file. Read the <a href="/docs" target="_blank" rel="noopener norefereer">API Docs</a> or go to your files dashboard to learn how to make API requests</span>
                    <Divider/>
                    <span className="parameterName">colLen</span> <span className="parameterType">integer</span>
                    <span className="parameterSecondLine"> </span>
                    <span className="parameterDesc">Number of columns in file</span>
                    <Divider/>
                    <span className="parameterName">rowLen</span> <span className="parameterType">integer</span>
                    <span className="parameterSecondLine"> </span>
                    <span className="parameterDesc">Number of rows in file</span>
                    <Divider/>
                    <span className="parameterName">columnNames</span> <span className="parameterType">Array of Strings</span>
                    <span className="parameterSecondLine"> </span>
                    <span className="parameterDesc">Array containing all the names of the columns</span>
                    <Divider/>
                    <span className="parameterName">createdDt</span> <span className="parameterType">string</span>
                    <span className="parameterSecondLine"> </span>
                    <span className="parameterDesc">Datetime of the file upload. It will be in the format "YYYY-MM-DD HH:MM:SS" and will be in UTC time</span>
                    <Divider/>
                    <span className="parameterName">duplicateColumnNames</span> <span className="parameterType">boolean</span>
                    <span className="parameterSecondLine"> </span>
                    <span className="parameterDesc"><code>true</code> or <code>false</code> if any of the column names are duplicate or not</span>
                    <Divider/>
                    <span className="parameterName">fileID</span> <span className="parameterType">string</span>
                    <span className="parameterSecondLine"> </span>
                    <span className="parameterDesc">This will correspond to the File ID within API Spreadsheets. You can use this to make create, read, update or delete API requests to the file</span>
                    <Divider/>
                    <span className="parameterName">importKey</span> <span className="parameterType">string</span>
                    <span className="parameterSecondLine"> </span>
                    <span className="parameterDesc">This is the key of your import configuration. It is the key you will pass to the importer in <b>line 10</b> of the code above</span>
                    <Divider/>
                    <span className="parameterName">name</span> <span className="parameterType">string</span>
                    <span className="parameterSecondLine"> </span>
                    <span className="parameterDesc">Name of the file</span>
                    <Divider/>
                    <span className="parameterName">sheetNames</span> <span className="parameterType">Array of Strings</span>
                    <span className="parameterSecondLine"> <span className="parameterDefault">default=[]</span></span>
                    <span className="parameterDesc">ONLY for Excel files. This will be an array containing the names of all the sheets in the file. For CSV and Text files this will be a blank array, i.e. <code>[]</code></span>
                    <Divider/>
                    <span className="parameterName">sheetRead</span> <span className="parameterType">string</span>
                    <span className="parameterSecondLine"> <span className="parameterDefault">default=null</span></span>
                    <span className="parameterDesc">ONLY for Excel files. This will be the name of the sheet that was read when importing. For CSV and Text files this will be a null value, i.e. <code>null</code></span>
                    <Divider/>
                    <span className="parameterName">size</span> <span className="parameterType">integer</span>
                    <span className="parameterSecondLine"> </span>
                    <span className="parameterDesc">Size of the file in bytes</span>
                    <Divider/>
                    <span className="parameterName">sampleData</span> <span className="parameterType">Array of JSON Objects</span>
                    <span className="parameterSecondLine"><span className="parameterDefault">default=[]</span></span>
                    <span className="parameterDesc">The data from the first 5 rows of the spreadsheet. This will be an array where each element is a JSON object that corresponds to a row in the spreadsheet. Each element will have the column names as the keys with their values. </span>
                    <Divider/>
                </div>
                <div className="col-sm-6">
                    <div className="getResponseBox">
                        <div className="getResponseHeading">
                            <span style={{float: 'left'}}><h6 className="thinHeading">Sample Data JSON Object</h6></span>
                        </div>
                        <ReactJson src={sampleRequest}
                                   name={null}
                                   theme="ocean"
                                   displayObjectSize={false}
                                   displayDataTypes={false}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default SpreadsheetImportCodeJSON