import React, {Component} from 'react'
import {connect} from 'react-redux'
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import InfoPopup from "../../../../SharedComponents/InfoPopup"
import {getCleanDomain} from "../../../../Constants/getCleanDomain";
import ValidationMethodInfo from "./ValidationMethodInfo";
import DomainValidationOption from "./DomainValidationOption";
import { Button } from 'semantic-ui-react'
import API_Root from "../../../../Constants/API_Root";
import BannerAlert from "../../../../SharedComponents/BannerAlert"
import CNAMEDomainError from "../CNAMEDomainError";

class RequestSSLCertificate extends Component {
    constructor(props){
        super(props);

        this.state = {
            requesting: false
        }
    }

    requestSSLCertificate = (e) => {
        this.setState({ requesting: true })

        const isError =
            (errMsg="There was an error importing your certificate. Read the guides and if you still cannot request your certificate, then contact our support") =>
                this.setState({ requesting: false }, () => this.props.changeState({ requestSSLError: true, requestSSLMsg: errMsg }))

        const currSSLinfo = JSON.parse(JSON.stringify(this.props.currSSLInfo))

        fetch(API_Root + "api/request-ssl-certificate/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                "email": localStorage.getItem("email"),
                "token": localStorage.getItem("token"),
                "domainName": getCleanDomain(this.props.domainName),
                "domainValidationOption": this.props.domainValidationOption.toUpperCase()
            })
        }).then(res => {
            res.json().then(data => {
                if (res.status === 200){
                    currSSLinfo.push(data)

                    this.setState({ requesting: false },
                        () => this.props.changeState({
                            requestSSLError: false,
                            requestSSLMsg: "Successfully requested certificate",
                            currSSLInfo: currSSLinfo
                        }))
                } else {
                    isError(data.errorMsg)
                }
            }).catch(err => isError())
        }).catch(err => isError())

    }

    getLabelRow = (label, value, info) => (
        <div style={{display: "grid", gridTemplateColumns: "240px auto", marginBottom: "16px"}}>
            <div>
                <div style={{color: gunaldiStyles.subText}}>
                    {label} <InfoPopup info={info}/>
                </div>
            </div>
            <div>
                {value}
            </div>
        </div>
    )

    render() {
        const bannerDivStyle = {marginTop: "16px"}

        return (
            <div>
                {
                    this.getLabelRow("Certificate Domain Name",
                    <div>{getCleanDomain(this.props.domainName)}
                        <CNAMEDomainError
                            error={this.props.domainNameError}
                            errorMsg={this.props.domainNameErrorMsg}
                            errorOnly
                        />
                    </div>, "The certificate will be issued for only this domain")
                }
                {
                    this.getLabelRow("Validation Method",
                        <DomainValidationOption
                            option={this.props.domainValidationOption}
                            changeState={this.props.changeState}
                        />,
                        <ValidationMethodInfo domainName={this.props.domainName}/>)
                }
                <div style={{marginTop: "16px"}}>
                    <Button size="small"
                            style={gunaldiStyles.btnBlue}
                            icon="pencil square"
                            content="Request SSL Certificate"
                            disabled={this.props.domainNameError}
                            onClick={this.requestSSLCertificate}
                            loading={this.state.requesting}
                    />
                </div>
                {
                    this.props.requestSSLMsg.trim() !== "" ?
                        this.props.requestSSLError ?
                            <div style={bannerDivStyle}>
                                <BannerAlert    type="error"
                                                header='There was an error requesting your certificate'
                                                icon="bx bx-error-alt"
                                                content={this.props.requestSSLMsg}
                                />
                            </div> :
                            <div style={bannerDivStyle}>
                                <BannerAlert
                                    type="success"
                                    header="Your SSL Certificate has been successfully requested"
                                    content="Check Your Certificates tab to view its status"
                                />
                            </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(RequestSSLCertificate)