import React, {Component} from 'react'
import withAuthorization from '../../Authentication/withAuthorization';
import VisualizationIndex from '../Visualization/VisualizationIndex';

class AuthVisualizationIndex extends Component {
    render() {
        return (
            <VisualizationIndex />
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AuthVisualizationIndex);