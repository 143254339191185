import React, {Component} from 'react'
import ModalApp from '../../../SharedComponents/Modal'
import QueryBody from "./QueryBody";
import { Icon } from 'semantic-ui-react'

class QueryQuickstartModal extends Component {
    render() {
        return (
            <div>
                <ModalApp name="queryQuickstartModal"
                          header={<span><h3><Icon name="question circle outline" /> Test Your Query</h3></span>}
                          size="large"
                          body={<div><br/><QueryBody/></div>}
                />
            </div>
        )
    }
}

export default QueryQuickstartModal