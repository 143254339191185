import React, {Component} from 'react'
// import LandingPageHero from "./LandingPageHero";
import { isBrowser } from 'react-device-detect'
import AppIndex from '../App/AppIndex'
import UpdatedAppIndex from "../App/UpdatedAppIndex"
import MobileLandingPage from './MobileLandingPage';
import AuthComponent from "../SharedComponents/AuthComponent";
// import WhatIsAnAPIButton from "./WhatIsAnAPIButton";
import UpdatedLandingPageIndex from "./UpdatedLandingPageIndex";
import APIIndex from "../App/API/APIIndex"

class LandingPageIndex extends Component {
    render() {
        const app = isBrowser ?
        <AuthComponent
            // authComponent={<AppIndex/>}
            authComponent={<APIIndex/>}
            nonAuthComponent={<UpdatedLandingPageIndex/>}
        /> : <AuthComponent
                // authComponent={<AppIndex/>}
                authComponent={<UpdatedAppIndex/>}
                nonAuthComponent={<MobileLandingPage/>}
            />;

        return (
            <div>
                {app}
            </div>
        )
    }
}

export default LandingPageIndex