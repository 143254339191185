export const getQueryParamValue = (queryParam, url) => {
    const param = queryParam + "="

    if (url.includes(param)){
        const paramIdx = url.indexOf(param)

        const paramLength = param.length;

        const startIdx = paramIdx + paramLength;

        let andIdx = -1;

        for (let i=startIdx; i<url.length; i++){
            if (url.charAt(i) === "&"){
                andIdx = i;
                break;
            }
        }

        let endIdx;
        if (andIdx === -1){
            endIdx = url.length;
        } else {
            endIdx = andIdx
        }

        const value = url.substring(startIdx, endIdx)

        return value

    } else {
        return ""
    }
}



