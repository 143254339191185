import React, {Component} from 'react'

const paramInfo = [
    {
        name: "name",
        required: true,
        type: "string",
        "default": null,
        desc: "Name of the file that was imported. Contains extension of the file"
    },
    {
        name: 'size',
        required: true,
        type: "integer",
        "default": null,
        desc: "Size of the file in Bytes"
    },
    {
        name: 'columnNames',
        required: true,
        type: 'array',
        "default": null,
        desc: "An array containing all the names of the original column names in the file. These are NOT the mapped column names",
    },
    {
        name: 'createdDt',
        required: true,
        type: 'string',
        "default": null,
        desc: "The date the file was uploaded. The format will always be YYYY-MM-DD HH:MM:SS. This time is from the local timezone of the person who uploaded the file",
    },
    {
        name: "colLen",
        required: true,
        type: 'integer',
        "default": null,
        desc: "Number of columns. This will be equivalent to the length of the columnNames parameter"
    },
    {
        name: "rowLen",
        required: true,
        type: 'integer',
        "default": null,
        desc: "Number of rows in the file"
    },
    {
        name: "sampleData",
        required: true,
        type: 'array',
        "default": null,
        desc: "A 2D array (matrix) of up to 5 rows. Each array corresponds to a row in the file. The array contains values from that row in the same order they appear"
    },
    {
        name: "duplicateColumnNames",
        required: true,
        type: 'boolean',
        "default": null,
        desc: "true or false depending on if even 1 of the column name is a duplicate. For example, this list of column names would return true ['name', 'age', 'sign_up_date', 'age']"
    },
    {
        name: "importKey",
        required: true,
        type: "string",
        "default": null,
        desc: "Key/ID of the importer this file was imported through"
    },
    {
        name: "fileID",
        required: true,
        type: "string",
        "default": null,
        desc: "Unique ID of this file in API Spreadsheets"
    },
    {
        name: "apiUrl",
        required: true,
        type: "string",
        "default": null,
        desc: "URL that you can use to interact with all of the data in the file. This is the API Spreadsheets' API"
    },
    {
        name: 'sheetNames',
        required: false,
        type: 'array',
        "default": null,
        desc: "An array containing all the names of the Excel sheets. This parameter does not exist for .csv or .txt files",
    },
    {
        name: 'sheetRead',
        required: false,
        type: 'string',
        "default": null,
        desc: "The name of the sheet read from an Excel file. This parameter does not exist for .csv or .txt files",
    }
]

class ImporterCodeParameters extends Component {
    render() {
        return (
            <div>
                <h5 id="parameterHeading">Imported File Data Parameters</h5>
                <hr/>
                {
                    paramInfo.map((x, idx) => <div key={idx}>
                        <span className="parameterName">{x.name}{x.required ? <span style={{color: "red"}}>*</span> : null}</span> <span className="parameterType">{x.type}</span>
                        <span className="parameterSecondLine">{x.default === null ? null : <span className="parameterDefault">default={x.default}</span>}</span>
                        <span className="parameterDesc">{x.desc}</span>
                        <hr/>
                    </div>)
                }
            </div>
        )
    }
}

export default ImporterCodeParameters