import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Accordion, Icon } from 'semantic-ui-react'
import JSONOutputPreview from './JSONOutputPreview'
import CustomAccordion from "../SharedComponents/CustomAccordion"

class FileAccordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: this.props.tableType === "all" ? -1 : 0,
        }

        this.handleAccordionClick = this.handleAccordionClick.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }

    handleAccordionClick(e, idxClicked){
        const { index } = idxClicked;
        const activeIndex = this.state.activeIndex;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex })
    }

    handleRadioChange(e){
        this.props.changeState("outputFormat", e.target.value);
    }

    render() {
        if (this.props.apiType === "deleteAction"){
            return(
                null
            )
        }
        else{
            const activeIndex = this.state.activeIndex;
            const outputFormat = this.props.dataFormat;
            const title = this.props.apiType === "readAction" ? "Sample Response" : "Sample Request";

            return (
                <CustomAccordion
                    body={<JSONOutputPreview
                        pkID={this.props.pkID}
                        outputFormat={outputFormat}
                        rowLen={this.props.rowLen}
                        rows={this.props.rows}
                        apiType={this.props.apiType}
                        pk={this.props.pk}
                        count={this.props.count}
                    />}
                    header={title}
                    accordionStyle={{
                        borderRadius: '4px',
                        border: "1px solid #F0F0F0",
                        backgroundColor: 'white'
                        // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                    }}
                    headerDivStyle={{
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: "Lato",
                        padding: "16px"
                    }}
                />
            )
            // return (
            //     <Accordion styled fluid>
            //         <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleAccordionClick}>
            //             <Icon name='dropdown' />
            //             <b>{title}</b>
            //         </Accordion.Title>
            //         <Accordion.Content active={activeIndex === 0}>
            //
            //         </Accordion.Content>
            //     </Accordion>
            // )
        }
    }
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(FileAccordion)