import React, {Component} from 'react'
import API_Root from "../Constants/API_Root";
import { Message } from 'semantic-ui-react'
import ConnectAWSAccount from "./ConnectAWSAccount";
import Loader from 'react-loader-spinner';
import ChooseS3Bucket from "./ChooseS3Bucket";
import S3FilePicker from "./S3FilePicker";
import NavigateToolbar from "./NavigateToolbar";
import SelectAWSAccount from "./SelectAWSAccount";
import constants from "../Constants/constants";

class AWSModalBodyIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            awsS3Accounts: null,
            fetching: true,
            fetchingText: "Getting your AWS S3 Accounts...",
            // currentDisplay: [accounts, buckets, files]
            currentDisplay: "accounts",
            prefix: "",
            bucketNames: null,
            bucketName: null,
            fileList: null,
            prefixList: null,
            selectedAccountInfo: null,
            selectedAccountName: ""
        }
    }

    componentDidMount(){
        fetch(API_Root + "api/crud-s3-accounts-for-user/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                actionType: "read"
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.setState({
                        awsS3Accounts: data.awsS3Accounts,
                        fetching: false,
                        fetchingText: "",
                        error: null
                    })
                }).catch(err => this.setErrorMsg("There was an error getting your AWS S3 accounts", "Please refresh & try again"))
            } else{
                this.setErrorMsg("There was an error getting your AWS S3 accounts", "Please refresh & try again")
            }
        }).catch(err => this.setErrorMsg("There was an error getting your AWS S3 accounts", "Please refresh & try again"))
    }

    getFiles = (bucketName, prefix) => {
        this.setState({
            fetching: true,
            fetchingText: "Getting your files and folders..." })

        fetch(API_Root + "api/get-files-for-s3-bucket/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                accessKey: this.state.selectedAccountInfo.accessKey,
                secretKey: this.state.selectedAccountInfo.secretKey,
                bucketName: bucketName,
                prefix: prefix,
                fileExtensions: constants.extensions
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.setState({
                        fileList: data.fileList,
                        prefixList: data.prefixList,
                        fetching: false,
                        error: null,
                        bucketName: bucketName,
                        prefix: prefix,
                        currentDisplay: "files"
                    })
                }).catch(err => this.setErrorMsg("There was an error getting your files", "Please try again"))
            } else if (res.status === 404){
                this.setErrorMsg("There was an error connecting to your AWS S3 Account", "Check your keys and try again")
            } else {
                this.setErrorMsg("There was an error getting your files", "Please try again")
            }
        }).catch(err => this.setErrorMsg("There was an error getting your files", "Please try again"))
    }

    setErrorMsg = (header="There was an error connecting to your account", msg= "Try again") => {
        this.setState({
            error: <div style={{marginTop: "10px"}}>
                <Message negative={true}
                         icon="warning sign"
                         header={header}
                         content={msg}
                />
            </div>,
            fetching: false,
            fetchingText: ""
        })
    }

    changeState = (keyValueObject) => this.setState(keyValueObject)

    render() {
        let body = null;

        if (this.state.fetching){
            body = <div style={{paddingLeft: '50px' }}>
                <Loader
                    type="TailSpin"
                    color="black"
                    height="25"
                    width="25"
                />
                <h4>{this.state.fetchingText}</h4>
            </div>
        } else {
            if (this.state.awsS3Accounts !== null){
                let display = null;

                if (this.state.currentDisplay === "accounts"){
                    display = <div>
                        <SelectAWSAccount awsS3Accounts={this.state.awsS3Accounts}
                                          setErrorMsg={this.setErrorMsg}
                                          changeState={this.changeState} />
                        <ConnectAWSAccount awsS3Accounts={this.state.awsS3Accounts}
                                           changeState={this.changeState} />
                    </div>
                } else if (this.state.currentDisplay === "buckets"){
                    display = <ChooseS3Bucket changeState={this.changeState}
                                              getFiles={this.getFiles}
                                              bucketNames={this.state.bucketNames} />
                } else if (this.state.currentDisplay === "files"){
                    display = <S3FilePicker changeState={this.changeState}
                                            bucketName={this.state.bucketName}
                                            setErrorMsg={this.setErrorMsg}
                                            prefix={this.state.prefix}
                                            fileList={this.state.fileList}
                                            selectedAccountName={this.state.selectedAccountName}
                                            prefixList={this.state.prefixList}
                                            getFiles={this.getFiles} />
                }

                body = <div>
                    <NavigateToolbar currentDisplay={this.state.currentDisplay}
                                     bucketName={this.state.bucketName}
                                     prefix={this.state.prefix}
                                     getFiles={this.getFiles}
                                     accountName={this.state.selectedAccountName}
                                     changeState={this.changeState}/>
                    {display}
                </div>
            }
        }

        return (
            <div className="filesIDDisplayBox">
                {this.state.error}
                {body}
            </div>

        )
    }
}


export default AWSModalBodyIndex