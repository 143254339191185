import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyEditID} from "../../Actions/ModifyEditID";

class FileDescriptionButton extends Component {
    constructor(props) {
        super(props);

        this.showDescription = this.showDescription.bind(this);
    }

    showDescription(e){
        this.props.modifyEditID(this.props.pkID)
        this.props.toggleModal("fileDescriptionModal")
    }

    render() {
        return (
            <div>
                <Button content="Description" basic color='blue' icon='sticky note outline' onClick={this.showDescription} fluid/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyEditID: modifyEditID,
}

export default connect(mapStateToProps, mapActionsToProps)(FileDescriptionButton)