import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Message, Label, Checkbox } from 'semantic-ui-react';
import ImportSheets from './ImportSheets'
import RemoveFile from './RemoveFile';
import {toggleModal} from "../Actions/ToggleModal";
import {modifyEditID} from "../Actions/ModifyEditID";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import PrivateButton from './PrivateButton'
import KeysButton from './KeysModal/KeysButton'
import ShareButton from './ShareButton'
import FileSource from "./FileSource";
import TeamFileButton from "../FileDnD/TeamFile/TeamFileButton"
import ColumnNames from './ColumnNames'
import FileDescriptionButton from "./FileDescription/FileDescriptionButton";
import FileOptions from "./FileOptions"
import AnalyticsButton from './AnalyticsButton'
import AlertsButton from "./Alerts/AlertsButton"
import DownloadFileButton from './DownloadFileButton'
import WhitelistDomainsButton from "./WhitelistDomains/WhitelistDomainsButton"
import ReuploadFile from './ReuploadFile'
import BlockRequestsButton from "./BlockRequests/BlockRequestsButton"
import FileDescriptionModalBody from './FileDescription/FileDescriptionModalBody'
import FileSummaryCodeAccordion from './FileSummaryCode/FileSummaryCodeAccordion'
import {getPricingText} from "../Constants/getPricingText";
import {isBrowser, isMobile } from 'react-device-detect'
import FixColumnNames from "./FixColumnNames/FixColumnNames"
import VisualizationButton from "./Visualization/VisualizationButton"
import ReportingButton from "./Reporting/ReportingButton"
import WebhookButton from "./Webhook/WebhookButton"
import SharedLabel from "./SharedLabel"
import pricing from "../Constants/Pricing/Pricing";
// import TestQueryButton from "./TestQueryButton"
// import FileSummaryBaseURL from "./FileSummaryBaseURL";
import UpdateFileHashButton from "./UpdateFileHashButton";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import {getFileLimitMessage} from "../Constants/getFileLimitMessage";
import {isFileInLazyMode} from "../Constants/isFileInLazyMode";
import {isUserAMacroUser} from "../Constants/isUserAMacroUser";
import {isFileInAPIMode} from "../Constants/isFileInAPIMode";

class FileSummary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            outputFormat: 'jsonRow',
            apiType: 'get',
            rows: this.props.fileInformation[this.props.pkID].rowLength,
            previewHash:false
        }

        this.openEditModal = this.openEditModal.bind(this);
        this.changeState = this.changeState.bind(this);
        this.openPaymentModal = this.openPaymentModal.bind(this);
    }

    openEditModal(){
        this.props.modifyEditID(this.props.pkID)
        this.props.toggleModal("editModal")
    }

    changeState(name, value){
        this.setState({
            [name]: value
        })
    }

    openPaymentModal(e){
        this.props.toggleModal("paymentModal");
    }

    togglePreviewHash = () => {
        this.setState({previewHash: !this.state.previewHash})
    }

    openReadMoreModal = (e, fileHash, fileID) => {
        this.props.modifyModalInfo({
            fileHash: fileHash,
            fileID: fileID
        })
        this.props.toggleModal("fileIDReadMoreModal")
    }

    render() {
        const fileInformation =  this.props.fileInformation[this.props.pkID];

        let useFileHash = fileInformation["useFileHash"]

        let fileHash = fileInformation["fileHash"]

        let upgradeMessage = !useFileHash ?
            <div style={{marginBottom: "10px"}}>
                <Message positive
                         header="Upgrade to a More Secure File ID"
                         content={<div style={{marginTop: "5px"}}>
                             <Checkbox toggle label="Preview how upgrade will look"
                                       checked={this.state.previewHash}
                                       onChange={this.togglePreviewHash} />
                             {this.state.previewHash ? <UpdateFileHashButton pk={this.props.pk} pkID={this.props.pkID} />:null}
                             <small style={{display:'block',margin:'0'}}> Don't worry, this is just a preview! <span className="linkStyle" onClick={e => this.openReadMoreModal(e, fileHash, this.props.pk.toString())}>Read more about new File IDs</span> </small>
                         </div>}
                />
            </div> :null

        let fileName = fileInformation.name;
        // const size = formatBytes(fileInformation.size);

        const isExcel = fileName.split('.').pop() !== "csv" && fileName.split('.').pop() !== "txt";

        const sheetName = isExcel ? <span className="text-muted"><small>{this.props.sheetRead[this.props.pkID]}</small></span> : null;
        const importSheets = isExcel ? <ImportSheets idx={this.props.idx} pkID={this.props.pkID} pk={this.props.pk} /> : null;

        const proBlock = this.props.filePKProBlock[this.props.pkID];

        const fileDivStyle = {
            // backgroundColor: '#F5F5F5',
            backgroundColor: '#f3f6fb',
            border: '1px solid #F0F0F0',
            // borderRadius: '5px',
            padding: '10px',
            // marginTop: '20px',
            marginBottom: '50px'
        };

        if(!useFileHash){
            fileDivStyle.padding = "0 10px 10px 10px"
        }

        let disable = null;
        let proMessage = null;
        // let displayFileHash = useFileHash ? fileHash: this.props.pk.toString();
        let displayFileHash = useFileHash || this.state.previewHash ? fileHash: this.props.pk.toString();

        let fileIDLabel = <Label color="orange"><b>File ID: </b> {displayFileHash} </Label>;
        let fileSummaryAccordion = <FileSummaryCodeAccordion
            pk={this.props.pk}
            pkID={this.props.pkID}
            textPadding={this.props.textPadding}
            idx={this.props.idx}
            tableType={this.props.tableType}
            displayFileHash={displayFileHash}
            inputType={fileInformation.inputType}
        />

        if (proBlock){
            disable = {pointerEvents: 'none', opacity: '0.2'}
            proMessage =
                <div className="row">
                    <div className="col-sm-9">
                        <Message
                            floating={true} negative={true}
                            icon='lock'
                            header={getFileLimitMessage(this.props.proBlockTooManyFiles, this.props.pkID, this.props.tier, this.props.appsumoPlanName, this.props.isGrandfather)}
                            content={<Button onClick={this.openPaymentModal} color="green">{"Upgrade to Pro for just " + getPricingText(this.props.currency, 1)}</Button>}
                        />
                    </div>
                    <div className="col-sm-3">
                        <RemoveFile idx={this.props.idx} pkID={this.props.pkID} pk={this.props.pk} isExcel={isExcel} />
                    </div>
                </div>
            fileIDLabel = null;
            fileSummaryAccordion = null;
            upgradeMessage = null;
        }
        else{
            if (fileInformation.fixColumnNames && !isFileInLazyMode(fileInformation)){
                if (isUserAMacroUser(this.props.fullPricingInfo)){
                    if (isFileInAPIMode(fileInformation)){
                        console.log('file is api')
                        disable = {pointerEvents: 'none', opacity: '0.2'}
                        proMessage = <FixColumnNames pkID={this.props.pkID} pk={this.props.pk} idx={this.props.idx} fileInformation={fileInformation}/>
                        fileIDLabel = null;
                        fileSummaryAccordion = null;
                        upgradeMessage = null;
                    }
                } else {
                    disable = {pointerEvents: 'none', opacity: '0.2'}
                    proMessage = <FixColumnNames pkID={this.props.pkID} pk={this.props.pk} idx={this.props.idx} fileInformation={fileInformation}/>
                    fileIDLabel = null;
                    fileSummaryAccordion = null;
                    upgradeMessage = null;
                }
            }
        }

        const isTeam = "isTeam" in fileInformation ? fileInformation.isTeam : false;

        let canTakeAction = true;

        try {
            if (isTeam) {
                if ("teams_pk_list" in fileInformation) {
                    if (fileInformation.teams_pk_list.length > 0) {
                        const allTeamPKs = this.props.teamInfo.map(x => x.id)
                        let allFileTakeAction = [];

                        for (let i = 0; i < fileInformation.teams_pk_list.length; i++) {
                            const idx = allTeamPKs.indexOf(fileInformation.teams_pk_list[i])
                            allFileTakeAction.push(["admin", "manager"].includes(this.props.teamInfo[idx].all_users[localStorage.getItem("email")].role))
                        }

                        if (allFileTakeAction.indexOf(true) === -1){
                            canTakeAction = false
                        }
                    }
                }
            }
        } catch (error) {
            canTakeAction = true
        }

        return (
            <div style={fileDivStyle}>
                <div style={{marginBottom: '10px'}} className="row">
                    <div className="col-sm-12">
                        {proMessage}
                    </div>
                </div>
                {upgradeMessage}
                <div style={disable}>
                    <div className="row">
                        <div className={"col-sm-9 wordwrap"}>
                            <div className="fileNameBox">
                                <h3 style={{color: '#303030'}}>{fileName} {sheetName}</h3>
                                <FileSource fileInformation={fileInformation}/>
                                {fileIDLabel}
                                <Label>{fileInformation['createdDt']}</Label>
                                <SharedLabel fileInformation={fileInformation}/>
                                <br/>
                                <div style={{width: isBrowser ? '60%' : '100%', marginTop: '5px'}}>
                                    {/*<FileSummaryBaseURL url={"data/" + this.props.pk.toString() + "/"} />*/}
                                    {/*<br/>*/}
                                    <FileDescriptionModalBody pkID={this.props.pkID} pk={this.props.pk} />
                                    {isMobile ? <br/> : null}
                                </div>
                            </div>
                        </div>
                        <div className={"col-sm-3"}>
                            <PrivateButton canTakeAction={canTakeAction} isTeam={isTeam} textPadding={this.props.textPadding} pk={this.props.pk} idx={this.props.idx} pkID={this.props.pkID} />
                            <br/>
                            <div style={{float: 'right', width: '100%'}}>
                                <FileOptions
                                    inputType={fileInformation.inputType}
                                    canTakeAction={canTakeAction}
                                    visualizationButton={<VisualizationButton pk={this.props.pk} pkID={this.props.pkID} />}
                                    webhookButton={<WebhookButton pk={this.props.pk} pkID={this.props.pkID}/>}
                                    // testQuery={isBrowser ? <TestQueryButton pkID={this.props.pkID} /> : null}
                                    alertsButton={<AlertsButton pk={this.props.pk} pkID={this.props.pkID} />}
                                    reportingButton={<ReportingButton pk={this.props.pk} pkID={this.props.pkID} />}
                                    shareButton={<ShareButton pk={this.props.pk} outputFormat={this.props.outputFormat} />}
                                    whitelistDomainsButton={<WhitelistDomainsButton pk={this.props.pk} pkID={this.props.pkID}/>}
                                    analyticsButton={<AnalyticsButton pk={this.props.pk} pkID={this.props.pkID}/>}
                                    downloadFileButton={<DownloadFileButton pk={this.props.pk} pkID={this.props.pkID}/>}
                                    reuploadFile={<ReuploadFile pk={this.props.pk} pkID={this.props.pkID} fileHash={fileInformation['fileHash']}/>}
                                    blockRequestsButton={<BlockRequestsButton pk={this.props.pk} pkID={this.props.pkID} />}
                                    keysButton={<KeysButton canTakeAction={canTakeAction} pkID={this.props.pkID} pk={this.props.pk} />}
                                    columnNames={<ColumnNames pkID={this.props.pkID} pk={this.props.pk} />}
                                    teamFileButton={<TeamFileButton fileHash={fileInformation['fileHash']} tableType={this.props.tableType} pkID={this.props.pkID} pk={this.props.pk}/>}
                                    fileDescription={<FileDescriptionButton pkID={this.props.pkID} pk={this.props.pk} />}
                                    importSheets={isBrowser ? importSheets : null }
                                    editFile={isBrowser ? <div><Button content="File Options" basic color='purple' icon='edit outline' onClick={this.openEditModal} fluid/></div> : null}
                                    removeFile={<RemoveFile idx={this.props.idx} pkID={this.props.pkID} pk={this.props.pk} isExcel={isExcel} />}
                                />
                            </div>
                        </div>
                    </div>
                    <br/>
                    {
                        isBrowser ? fileSummaryAccordion : null
                    }
                    <br/>
                </div>
                {/*<div style={{marginTop: '10px'}} className="row">*/}
                    {/*<div className="col-sm-12">*/}
                        {/*{proMessage}*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock,
    currency: state.mainState.currency,
    teamInfo: state.mainState.teamInfo,
    proBlockTooManyFiles: state.mainState.proBlockTooManyFiles,
    tier: state.mainState.tier,
    appsumoPlanName: state.mainState.appsumoPlanName,
    isGrandfather: state.mainState.isGrandfather,
    fullPricingInfo: state.mainState.fullPricingInfo
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyEditID: modifyEditID,
    modifyFilePK: modifyFilePK,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(FileSummary)