import React, {Component} from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import AnalyticsBody from "../../Analytics/AnalyticsBody"
import CustomAccordion from "../../SharedComponents/CustomAccordion"
class AnalyticsAccordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: -1
        }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }


    render() {
        const { activeIndex } = this.state

        return (
            <div>
                <CustomAccordion
                    body={<AnalyticsBody pk={this.props.pk} pkID={this.props.pkID} webhook/>}
                    header="Webhook Analytics"
                    accordionStyle={{
                        borderRadius: '4px',
                        border: "1px solid #F0F0F0",
                        backgroundColor: 'white'
                        // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                    }}
                    headerDivStyle={{
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        fontSize: '14px',
                        fontWeight: '700',
                        fontFamily: "Lato",
                        padding: "16px"
                    }}
                />
            </div>
        )
    }
}

export default AnalyticsAccordion