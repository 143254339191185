import React, {Component} from 'react'
import PasswordForget from '../../Authentication/UserPages/Password/PasswordForget'
import CancelAccount from "./CancelAccount"
import { Segment } from 'semantic-ui-react'
import ProfileDisplayHeader from "./ProfileDisplayHeader";
import InfoSegment from "../../SharedComponents/InfoSegment";

class GeneralIndex extends Component {
    render() {
        return (
            <div>
                <div style={{marginBottom: "16px"}}>
                    <InfoSegment
                        header="Profile"
                        info={[{label: "Email", value: localStorage.getItem("email").toString()},
                            {label: "Account Created", value: this.props.dateJoined.toString()}]}
                    />
                </div>
                <div style={{marginBottom: "16px"}}>
                    <InfoSegment
                        header="Reset Password"
                        info={<PasswordForget profilePage/>}
                    />
                </div>
                {
                    this.props.pricingPlan === 'free' || this.props.pricingPlan === "" ?
                        <div style={{marginBottom: "16px"}}>
                            <InfoSegment
                                header="Delete Account"
                                info={<CancelAccount pricingPlan={this.props.pricingPlan}/>}
                            />
                        </div> : null
                }
            </div>
        )
    }
}


export default GeneralIndex