import React, {Component} from 'react'
import pricing from "../../Constants/Pricing/Pricing";
import { Icon } from 'semantic-ui-react'
import AddCommaToNumbers from "../../Constants/AddCommaToNumbers";
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";
import CurrencyDropdown from "./CurrencyDropdown"
import {getPricingText} from "../../Constants/getPricingText";
import { connect } from 'react-redux'
import CustomerCarousel from "../CustomerCarousel";
import PromoBar from "../../SharedComponents/PromoBar/PromoBar"
import { isMobile } from 'react-device-detect'
import PlanPricingBox from "./PlanPricingBox";
import PricingDescriptionBox from "./PricingDescriptionBox";
import FAQDisplayGrid from "../../SharedComponents/FAQ/FAQDisplayGrid";
import pricingFAQs from "./importerPricingFAQs";
import MobileFAQDisplay from "../../SharedComponents/FAQ/MobileFAQDisplay";
import EnterprisePricingBox from "./EnterprisePricingBox";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import MonthlyYearlyToggle from "./MonthlyYearlyToggle"
// import { featureList } from "../../Constants/Pricing/featureList";
import featureList from "../../Constants/Pricing/importerFeatureList";
import {planBgColor} from "../../Constants/planBgColor";

class PricingPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            headerBoxHeight: 162
        }

        this.goToFAQ = this.goToFAQ.bind(this);
    }

    changeHeaderBoxHeight = (boxHeight) => this.setState({ headerBoxHeight: boxHeight })

    goToFAQ(e, faq){
        window.scrollTo({
            top: document.getElementById(faq + "FAQ").offsetTop,
            behavior: 'smooth'
        })
    }

    render() {
        const currencyDropdown = <div style={{float: 'right'}}>
            <div id="pricingOptionsDropdown">
                <CurrencyDropdown />
            </div>
        </div>

        return (
            <div style={{minHeight: '100vh'}}>
                <PromoBar />
                <div className={isMobile ? "mobilePricingGrid" : "pricingGrid" }>
                    <div className="row" style={{marginBottom: "24px"}}>
                        <div className="col-sm-1" />
                        <div className="col-sm-10">
                            <div style={{fontWeight: "700", fontSize: "32px", color: "#161E16", textAlign: "center"}}>
                                Simple Pricing For Individuals & Businesses Of All Sizes
                            </div>
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row">
                        <div className="col-sm-3">

                        </div>
                        <div className="col-sm-6">
                            <div style={{fontSize: "14px", fontWeight: "400", color: "#161E16", textAlign: "center", display: "grid", gridTemplateColumns: "1fr auto 1fr", columnGap: "12px"}}>
                                <div style={{textAlign: "right"}}>
                                    Monthly
                                </div>
                                <div>
                                    <MonthlyYearlyToggle />
                                </div>
                                <div style={{textAlign: "left"}}>
                                    Yearly
                                </div>
                            </div>
                            <div style={{fontSize: "12px", color: "#6FBB98", textAlign: "center"}}>
                                Save up to 10% with Yearly Billing
                            </div>
                        </div>
                        <div className="col-sm-3">
                            {
                                isMobile ? <div style={{display: "grid", gridTemplateColumns: "65% 35%", marginTop: "24px"}}>
                                    <div style={{fontSize: "14px", fontWeight: "700", paddingTop: "5px",    color: "#161E16", textAlign: "left"}}>Currency</div>
                                    <div>{currencyDropdown}</div>
                                </div> : currencyDropdown
                            }

                        </div>
                    </div>
                    <br/>
                    <div className={isMobile ? "row" : "pricingTableDiv"}>
                        <div className={isMobile ? "col-sm-3" : "pricingTableDescriptionBoxDiv"}>
                            <PricingDescriptionBox
                                headerBoxHeight={this.state.headerBoxHeight}
                                featureList={featureList}
                            />
                        </div>
                        <div className={isMobile ? "col-sm-2" : "pricingTableBox0Div"}>
                            <PlanPricingBox
                                planIdx={0}
                                featureList={featureList}
                                backgroundColor={planBgColor[0]}
                                changeHeaderBoxHeight={this.changeHeaderBoxHeight}
                            />
                        </div>
                        <div className={isMobile ? "col-sm-2" : "pricingTableBox1Div"}>
                            <PlanPricingBox
                                planIdx={1}
                                featureList={featureList}
                                backgroundColor={planBgColor[1]}
                                changeHeaderBoxHeight={this.changeHeaderBoxHeight}
                            />
                        </div>
                        <div className={isMobile ? "col-sm-2" : "pricingTableBox2Div"}>
                            <PlanPricingBox
                                planIdx={2}
                                featureList={featureList}
                                backgroundColor={planBgColor[2]}
                                changeHeaderBoxHeight={this.changeHeaderBoxHeight}
                            />
                        </div>
                        <div className={isMobile ? "col-sm-2" : "pricingTableBox3Div"}>
                            <PlanPricingBox
                                planIdx={3}
                                featureList={featureList}
                                backgroundColor={planBgColor[3]}
                                changeHeaderBoxHeight={this.changeHeaderBoxHeight}
                            />
                        </div>
                        {isMobile ? <div className="col-sm-1" /> : null}
                    </div>
                </div>
                <div style={{marginTop: "80px", marginLeft: isMobile ? "20px" : "100px", marginRight: isMobile ? "20px" : "100px"}}>
                    <EnterprisePricingBox/>
                </div>
                <div style={{padding: "80px 100px 80px 100px"}}>
                    <div className="row">
                        <div className="col-sm-2">
                            <div style={{fontFamily: "Lato", fontWeight: "700", fontSize: "20px", color: "#161E16", paddingTop: "20px",
                                textAlign: isMobile ? "center" : "left"}}>
                                Trusted By
                            </div>
                        </div>
                        <div className="col-sm-10">
                            <CustomerCarousel/>
                        </div>
                    </div>
                </div>
                <section id="pricingFAQ" style={{padding: isMobile ? "0 24px 0 24px" : "0 100px 0 100px"}}>
                    <div className="pricingFAQs">
                        <div style={{textAlign: 'center', fontWeight: "700", fontSize: "24px", color: "#161E16", marginBottom: "24px"}} id="pricingFAQHeader">Frequently asked questions about Pricing</div>
                        {
                            isMobile ?
                                <MobileFAQDisplay
                                    helpInfo={pricingFAQs}
                                /> :
                                <FAQDisplayGrid
                                    helpInfo={pricingFAQs}
                                />
                        }
                        <div style={{marginTop: isMobile ? "20px" : "164px", backgroundColor: gunaldiStyles.subOrange, borderRadius: "10px", padding: isMobile ? "24px" : "50px"}}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div style={{fontWeight: "700", fontSize: "24px", color: "#161E16", marginBottom: "8px"}}>Have More Questions?</div>
                                    <div style={{fontWeight: "400", fontSize: "14px", color: "#5E645E", }}>We are here to help with any questions you have about plans, {isMobile ? null : <br/>}pricing, and features</div>
                                </div>
                                <div className="col-sm-6">
                                    <div style={{fontWeight: "500",
                                        fontSize: "16px",
                                        lineHeight: "150%",
                                        color: "#161E16",
                                        paddingTop: isMobile ? "8px" : "24px",
                                        textAlign: isMobile ? "left": "right"}}>
                                        Email us at <a href="mailto:info@apispreadsheets.com">info@apispreadsheets.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

export default connect(mapStateToProps, null)(PricingPage)