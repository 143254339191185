import React, {Component} from 'react'
import { connect } from 'react-redux';

class GoogleSheetsTokenError extends Component {
    render() {
        return (
            <div>
                <br/>
                <p>There was an error connecting to your <span className="googleEmail">{this.props.modalInfo.gmailEmail}</span> Google account.</p>
                <p>This is likely due to your <span className="googleEmail">{this.props.modalInfo.gmailEmail}</span> account being used on two different <span className="apiSpreadsheetsText">API Spreadsheets</span> accounts.</p>
                <br/>
                <h4>You can follow these steps to fix this problem.</h4>
                <small style={{fontStyle: 'italic'}}>But beware if you follow these steps: if another <span className="apiSpreadsheetsText">API Spreadsheets</span> account is using this Google account: <span className="googleEmail">{this.props.modalInfo.gmailEmail}</span>, then APIs on the other <span className="apiSpreadsheetsText">API Spreadsheets</span> account from this Google Account will stop working.</small>
                <br/><br/>
                <ol>
                    <li>Go to your <a href="https://myaccount.google.com/" target="_blank" rel="noopener noreferrer">Google Account</a>.</li>
                    <li>On the left navigation panel, select <b>Security</b>.</li>
                    <li>On the <i>Third-party apps with account access panel</i>, select <b>Manage third-party access.</b></li>
                    <li>Select <b>API Spreadsheets</b>.</li>
                    <li>Select Remove Access.</li>
                    <li>Log out of <b>API Spreadsheets</b> and clear your browser <b>cache</b></li>
                </ol>
                <h4 className="thinHeading">When you Log back in, you should be able to connect</h4>
                <br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(GoogleSheetsTokenError)