import React, {Component} from 'react'
import ColumnNameEdit from "./ColumnNameEdit";
// import { Button, Popup } from 'semantic-ui-react'

class ColumnName extends Component {
    constructor(props) {
        super(props);

        this.state = {
            edit: false
        }

        this.toggle = this.toggle.bind(this);
        this.changeColumnName = this.changeColumnName.bind(this);
        this.changeEditColumnName = this.changeEditColumnName.bind(this);
    }

    toggle(e){
        this.setState({
            edit: !this.state.edit
        })
    }

    changeColumnName(value){
        this.props.handleInputChange(this.props.idx, "columnName", value)
    }

    changeEditColumnName(value){
        this.props.changeColumnName(this.props.idx, value)
    }

    render() {
        const currUser = localStorage.getItem("email");
        let canEdit = false;


        if (currUser !== null){
            if (currUser === "testcolumnchange1@apispreadsheets.com" || currUser === "chaim@chaim.com"){
                canEdit = true;
            }
        }

        if (!this.state.edit){
            return (
                <span onClick={canEdit ? this.toggle : null}>
                    {this.props.allColumnNames[this.props.idx]}
                    {/*<Popup trigger={<Button style={{float: 'right'}} circular icon="edit" color="blue" basic onClick={this.toggle} />}*/}
                           {/*content="Change Name"*/}
                    {/*/>*/}
                </span>
            )
        }
        else{
            return(
                <ColumnNameEdit allColumnNames={this.props.allColumnNames}
                                allColumnNamesEdit={this.props.allColumnNamesEdit}
                                toggle={this.toggle}
                                changeColumnName={this.changeColumnName}
                                changeEditColumnName={this.changeEditColumnName}
                                idx={this.props.idx}
                />
            )
        }
    }
}

export default ColumnName