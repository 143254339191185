import React, {Component} from 'react'
import {connect} from 'react-redux'
import Celebration from '../../InfoPages/QuickstartModal/Body/Celebration.svg'
import { Button } from 'semantic-ui-react'
import ModalApp from "../Modal"
import {toggleModal} from "../../Actions/ToggleModal";

class CompletedCelebration extends Component {
    goToProfile = () => {
        this.props.toggleModal(null)
        this.profileLink.click()
    }

    render() {
        const modalBody = <div style={{textAlign: 'center'}}>
            <a style={{display: 'none'}}
               ref={profileLink => this.profileLink = profileLink}
               href="/profile"
            >Contact Us Email</a>
            <h2>Congratulations on upgrading to the {String(this.props.tier).toUpperCase()} tier!</h2>
            <img src={Celebration} style={{width: '50%', height: '50%'}} alt="Congratulations" />
            <br/><br/><br/>
            <Button size="massive" content="Experience your new benefits" color="green" onClick={this.goToProfile}/>
            <br/><br/><br/>
        </div>

        return (
            <ModalApp name="completedCelebrationModal"
                      header={null}
                      body={modalBody}
                      size="medium"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(CompletedCelebration)