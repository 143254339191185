import React, {Component} from 'react'
import {connect} from 'react-redux'
import CopyURLSegment from "../../SharedComponents/CopyURLSegment";
import styles from "../../SQL/styles";
import AceEditor from 'react-ace';
import 'brace/theme/monokai';
import 'brace/mode/json';
import getURLParam from "../../Constants/getURLParam";
import {getQueryParamValue} from "../../Constants/getQueryParamValue";
import BannerAlert from "../../SharedComponents/BannerAlert";
import {isMobile} from "react-device-detect";
import { Button } from 'semantic-ui-react'
import {toggleToast} from "../../Actions/ToggleToast";
import API_Backend_Root from "../../Constants/API_Backend_Root";
import {toggleModal} from "../../Actions/ToggleModal";
import {Dropdown} from "semantic-ui-react";
import { Menu } from 'semantic-ui-react'
import Icons from "../../Constants/Icons";

class RunMacrosIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            receiveInfo: false,
            executeType: 'formulas',
            displayType: 'documentation'
        }

        this.handleFormatChange = this.handleFormatChange.bind(this);
    }

    handleFormatChange(event, {value}){
        this.setState({
            executeType: value
        })
    }

    componentDidMount(){
        fetch(API_Backend_Root + "store-analytics-from-ui/", {
            method: "POST",
            body: JSON.stringify({"data": {"Email":localStorage.getItem("email"),
                    "Notes":"User clicked on Excel Macro tab","Use Case":"Excel Macros","Date Saved":(new Date()).toLocaleDateString(),"Usage Metrics":""},
                "headers": {"accessKey": "2863a49b9ea04e9847cb90f0e0aa93d5", "secretKey": "9eca419e34ff1e929f05d00598dfb679"},
                "apiUrl": "https://api.apispreadsheets.com/data/Zh4Ob0LXhmZN3goL/", "sendEmailAlert": false}),
        }).then(res =>{
            if (res.status === 201){
                console.log("lead saved")
            }
            else{
                console.log("lead not saved")
            }
        }).catch(err => console.log(err))
    }

    receiveInformation = () => {
        // this.setState({
        //     receiveInfo: true
        // })

        // const finalMessage = () => {
        //     this.setState({
        //         receiveInfo: false
        //     }, () => this.props.toggleToast({show: true, message: "Thank you for your interest. We will be reaching out with next steps shortly!", type: "success"}))
        // }

        this.props.toggleModal("runMacrosReceiveInformationalModal")

        fetch(API_Backend_Root + "store-analytics-from-ui/", {
            method: "POST",
            body: JSON.stringify({"data": {"Email":localStorage.getItem("email"),
                    "Notes":"User clicked Receive Information button on Excel Macro tab","Use Case":"Excel Macros","Date Saved":(new Date()).toLocaleDateString(),"Usage Metrics":""},
                "headers": {"accessKey":"2863a49b9ea04e9847cb90f0e0aa93d5", "secretKey":"9eca419e34ff1e929f05d00598dfb679"},
                "apiUrl": "https://api.apispreadsheets.com/data/Zh4Ob0LXhmZN3goL/", "sendEmailAlert": true})
        }).then(res =>{
            // finalMessage()
        }).catch(err => {
            console.log(err)
        })
    }

    generateCurlCommand = (jsonparams, url) => {
        // Parse the JSON parameters
        let params = JSON.parse(jsonparams);

        // Construct the cURL command
        let curlCommand = `curl -X POST ${url}\n-H "Content-Type: application/json"\n-d '${JSON.stringify(params)}'`;

        return curlCommand;
    }

    handleActionTypeChange = (e, { name }) => {
        this.setState({ displayType: name })
    }

    render() {
        let url = this.props.getURL.replace("/data/", "/run-macros/");

        let accessKey = getQueryParamValue("accessKey", url);
        let secretKey = getQueryParamValue("secretKey", url)

        let accessKeyReq = accessKey !== "" ? '\n\t"accessKey":"' + accessKey + '",' : "";
        let secretKeyReq = secretKey !== "" ? '\n\t"secretKey":"' + secretKey + '",' : ""

        let jsonparams = '{}';
        if (this.state.executeType === "macros"){
            jsonparams = '{' +
                accessKeyReq + secretKeyReq +
                '\n\t"macro_name":"GetPremium",' +
                '\n\t"macro_parameters":["Male", 30],' +
                '\n\t"sheet_name":"Rates",' +
                '\n\t"output_cells":["C8", "C9"],' +
                '\n\t"save_file_post_call": true' +
                '\n}'
        } else if (this.state.executeType === "formulas"){
            jsonparams = '{' +
                accessKeyReq + secretKeyReq +
                '\n\t"input_cells":{"A10": 150, "C15": "Male"},' +
                '\n\t"sheet_name":"Rates",' +
                '\n\t"output_cells":["C8", "C9"],' +
                '\n\t"save_file_post_call": true' +
                '\n}'
        }

        if (url.includes("?")){
            url = url.substring(0, url.indexOf("?"))
        }

        const options = [
            {key: 1, text: 'macros', value: 'macros'},
            {key: 2, text: 'formulas', value: 'formulas'}
        ];

        const toolbarSelectedStyle = {color: "#6FBB98", fontSize: "14px"}
        const originalToolbarStyle = {color: "#9696A0", fontSize: "14px"}

        return (
            <div>
                <div style={{ marginTop: "12px"}}>
                    {
                        this.props.fileMode === "api" ?
                            <BannerAlert
                                type="warning"
                                header='The Macro API allows MAX 20 requests per month on ANY account type'
                                icon="bx bxs-error"
                                content="This is an ENTERPRISE add-on feature. Contact us to activate this feature"
                                action={<Button loading={this.state.receiveInfo} color='orange' content='Contact Us' onClick={this.receiveInformation} />}
                            /> : null
                    }
                </div>
                <CopyURLSegment
                    url={url}
                    header={<span>API URL to Run Macros & Formulas
                        <span style={{ backgroundColor: "orange", color: "white", fontSize: "85%", padding: "0 4px 0 4px", fontWeight: "700", marginLeft: "6px"}}>
                            POST
                        </span></span>}
                />
                <br/>
                <div>
                    <Menu pointing secondary>
                        <Menu.Item
                            name="documentation"
                            active={this.state.displayType === 'documentation'}
                            style={this.state.displayType === 'documentation' ? toolbarSelectedStyle : originalToolbarStyle}
                            onClick={this.handleActionTypeChange}>
                            Documentation
                        </Menu.Item>
                        <Menu.Item
                            name="curl"
                            active={this.state.displayType === 'curl'}
                            style={this.state.displayType === 'curl' ? toolbarSelectedStyle : originalToolbarStyle}
                            onClick={this.handleActionTypeChange}>
                            cURL
                        </Menu.Item>
                    </Menu>
                </div>
                <div style={{ paddingTop: "32px" }}>
                    {
                        this.state.displayType === "documentation" ?
                            <div>
                                <div style={{ fontSize: "16px", color: styles.mainText, fontWeight: "700", marginBottom: "16px"}}>
                                    How to execute macros & formulas in your spreadsheet
                                </div>
                                <div>
                                    <div style={{ fontSize: "14px", color: styles.subText, border: "1px solid #F0F0F0",
                                        borderRadius: "4px", padding: "16px" }}>
                                        <div style={{ fontWeight: "700"}}>
                                            Execute
                                            <span> {' '}
                                                <Dropdown
                                                    placeholder="Select Data Format"
                                                    name="executeType"
                                                    value={this.state.executeType}
                                                    options={options}
                                                    onChange={this.handleFormatChange}
                                                    inline
                                                />
                                            </span>
                                            in your spreadsheet
                                        </div>
                                        <div>
                                            Make a POST request to the URL above with the following request body
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <AceEditor
                                                mode="json"
                                                theme="monokai"
                                                value={jsonparams}
                                                name="UNIQUE_ID_OF_DIV"
                                                editorProps={{$blockScrolling: true}}
                                                height="500px"
                                                width="auto"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            {
                                                this.state.executeType === "macros" ?
                                                    <div>
                                                        <span className="parameterName">macro_name</span> <span className="parameterType">string</span>
                                                        <span className="parameterSecondLine"><span className="parameterRequired">Required</span> </span>
                                                        <span className="parameterDesc">The name of the macro you want to execute. This is usually in Excel as <code>Sub GetPremium()</code>. The <code>macro_name</code> in this case would be <code>GetPremium</code></span>
                                                        <br/><br/>
                                                        <span className="parameterName">macro_parameters</span> <span className="parameterType">array</span>
                                                        <span className="parameterSecondLine"><span className="parameterRequired" style={{ color: styles.orange}}>Required if macro takes parameters</span> </span>
                                                        <span className="parameterDesc">An array containing values for all parameters that the macro function takes. For example, for a macro function <code>Sub GetPremium(gender As String, age As Integer)</code> you will pass <code>["Male", 30]</code> as the <code>macro_parameters</code></span>
                                                        <br/><br/>
                                                    </div> :
                                                    <div>
                                                        <span className="parameterName">input_cells</span> <span className="parameterType">JSON Object</span>
                                                        <span className="parameterSecondLine"><span className="parameterRequired">Required</span> </span>
                                                        <span className="parameterDesc">The cell names and their values you wish to input into your spreadsheet</span>
                                                        <br/><br/>
                                                    </div>
                                            }
                                            <span className="parameterName">sheet_name</span> <span className="parameterType">string</span>
                                            <span className="parameterSecondLine"><span className="parameterRequired" style={{ color: styles.orange}}>Optional</span> </span>
                                            <span className="parameterDesc">The first sheet in the spreadsheet will be used IF the <code>sheet_name</code> parameter is not included</span>
                                            <br/><br/>
                                            <span className="parameterName">output_cells</span> <span className="parameterType">array</span>
                                            <span className="parameterSecondLine"><span className="parameterRequired" style={{ color: styles.orange}}>Optional</span> </span>
                                            <span className="parameterDesc">List of Cell Names whose values should be returned in the response</span>
                                            <br/><br/>
                                            <span className="parameterName">save_file_post_call</span> <span className="parameterType">boolean</span>
                                            <span className="parameterSecondLine"><span className="parameterRequired" style={{ color: styles.orange}}>Optional</span> </span>
                                            <span className="parameterDesc">If <code>true</code> then the file at the time will be saved after each call</span>
                                            <br/><br/>
                                            {
                                                accessKeyReq !== "" ?
                                                    <div>
                                                        <span className="parameterName">accessKey</span> <span className="parameterType">string</span>
                                                        <span className="parameterSecondLine"><span className="parameterRequired">Required</span> </span>
                                                        <span className="parameterDesc">32 digit access key for this file</span>
                                                        <br/><br/>
                                                        <span className="parameterName">secretKey</span> <span className="parameterType">string</span>
                                                        <span className="parameterSecondLine"><span className="parameterRequired">Required</span> </span>
                                                        <span className="parameterDesc">32 digit secret key for this file</span>
                                                        <br/><br/>
                                                    </div> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div>
                                <div style={{ fontSize: "16px", color: styles.mainText, fontWeight: "700", marginBottom: "16px"}}>
                                    cURL code to execute API
                                </div>
                                <div>
                                    <AceEditor
                                        mode="powershell"
                                        theme="monokai"
                                        value={this.generateCurlCommand(jsonparams, url)}
                                        name="UNIQUE_ID_OF_DIV"
                                        editorProps={{$blockScrolling: true}}
                                        height="240px"
                                        width="auto"
                                    />
                                </div>
                            </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleToast: toggleToast,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(RunMacrosIndex)