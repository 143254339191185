import React, {Component} from 'react'
import { CSVLink } from "react-csv";
import { Icon } from 'semantic-ui-react'

class DownloadAttributes extends Component {
    render() {
        return (
            <div>
                <CSVLink
                    data={this.props.attributeInfo}
                    filename={"attributes_" + this.props.name + ".csv"}
                    target="_blank"
                >
                    <Icon name="download" /> Download Attribute File
                </CSVLink>
            </div>
        )
    }
}

export default DownloadAttributes