import React from 'react'
import DocsUploadIntroduction from "./Upload/DocsUploadIntroduction";
import APISecurity from "../../Guides/GuidesText/APISecurity";
import APIQuickstart from "../../Guides/GuidesText/APIQuickstart";
import ReadRows from "../../Guides/GuidesText/Read/ReadRows";
import CreateRows from "../../Guides/GuidesText/CreateRows";
import UpdateRows from "../../Guides/GuidesText/UpdateRows";
import DeleteRows from "../../Guides/GuidesText/DeleteRows";
import QueryBody from "../../InfoPages/Query/FullTutorial/QueryBody";
import UploadLocalFiles from "./Upload/UploadLocalFiles"
import UploadGoogleSheets from "./Upload/UploadGoogleSheets"
import UploadDropbox from "./Upload/UploadDropbox"
import UploadAWSS3 from "./Upload/UploadAWSS3"
import UploadQuickstart from "./Upload/UploadQuickstart"
import ColumnNamesValid from "../../Guides/ColumnNamesValid";
import UploadReadSettings from "./Upload/UploadReadSettings"

export const apiDocsInfo = {
    docsMenuItems: ["quickstart", "upload", "api", "actions", "database"],
    itemsInfo: {
        "quickstart": {
            label: "Getting started",
            body: <APIQuickstart/>,
            subMenuItems: [],
        },
        "upload": {
            label: "Uploading a file",
            body: <DocsUploadIntroduction/>,
            subMenuItems: ["introduction", "localFiles", "googleSheets", "dropbox", "awsS3", "quickstart", "columnNames", "readSettings"],
            subMenuItemInfo: {
                "introduction": {
                    label: "Introduction",
                    body: <DocsUploadIntroduction/>
                },
                "localFiles": {
                    label: "Local Files",
                    body: <UploadLocalFiles />
                },
                "googleSheets": {
                    label: "Google Sheets",
                    body: <UploadGoogleSheets />
                },
                "dropbox": {
                    label: "Dropbox",
                    body: <UploadDropbox />
                },
                "awsS3": {
                    label: "AWS S3",
                    body: <UploadAWSS3 />
                },
                "quickstart": {
                    label: "Quickstart",
                    body: <UploadQuickstart/>
                },
                "columnNames": {
                    label: "Valid Column Names",
                    body: <ColumnNamesValid/>
                },
                "readSettings": {
                    label: "Settings to Read File",
                    body: <UploadReadSettings />
                }
            }
        },
        "api": {
            label: "Using the API",
            body: <div>Using the API</div>,
            subMenuItems: ["introduction", "create", "read", "update", "delete", "query", "security"],
            subMenuItemInfo: {
                "introduction": {
                    label: "Introduction",
                    body: <div>Using the API</div>
                },
                "create": {
                    label: "Create Rows",
                    body: <CreateRows/>
                },
                "read": {
                    label: "Read Rows",
                    body: <ReadRows/>
                },
                "update": {
                    label: "Update Rows",
                    body: <UpdateRows/>
                },
                "delete": {
                    label: "Delete Rows",
                    body: <DeleteRows/>
                },
                "query": {
                    label: "SQL-like Queries",
                    body: <QueryBody/>
                },
                "security": {
                    label: "Security",
                    body: <APISecurity/>
                }
            }
        },
        "actions": {
            label: "Actions",
            body: <p>intro</p>,
            subMenuItems: ["introduction", "apiPrivate", "fileValues", "changeSheet", "fileOptions", "apiUsage", "columnNames", "webhook", "teamInfo", "autoReporting", "apiAlerts", "blockRequests", "whitelistURLs", "cachingLayer"],
            subMenuItemInfo: {
                "introduction": {
                    label: "Introduction",
                    body: <p>intro</p>
                },
                "apiPrivate": {
                    label: "API Private",
                    body: <p>API private</p>
                },
                "fileValues": {
                    label: "File Values",
                    body: <p>File values</p>
                },
                "changeSheet": {
                    label: "Change Sheet",
                    body: <p>Change sheet for file</p>
                },
                "fileOptions": {
                    label: "File Options",
                    body: <p>Change sheet for file</p>
                },
                "apiUsage": {
                    label: "API Usage",
                    body: <p>Change sheet for file</p>
                },
                "columnNames": {
                    label: "Column Names",
                    body: <p>Change sheet for file</p>
                },
                "webhook": {
                    label: "Webhook",
                    body: <p>Change sheet for file</p>
                },
                "teamInfo": {
                    label: "Team Info",
                    body: <p>Change sheet for file</p>
                },
                "autoReporting": {
                    label: "Auto Reporting",
                    body: <p>Change sheet for file</p>
                },
                "apiAlerts": {
                    label: "API Alerts",
                    body: <p>Change sheet for file</p>
                },
                "blockRequests": {
                    label: "Block Requests",
                    body: <p>Change sheet for file</p>
                },
                "whitelistURLs": {
                    label: "Whitelist URLs",
                    body: <p>Change sheet for file</p>
                },
                "cachingLayer": {
                    label: "Caching Layer",
                    body: <p>Change sheet for file</p>
                }
            }
        },
        "database": {
            label: "Database",
            body: <p>intro</p>,
            subMenuItems: ["introduction", "connect", "readData", "createData", "updateData"],
            subMenuItemInfo: {
                "introduction": {
                    label: "Introduction",
                    body: <p>intro</p>
                },
                "connect": {
                    label: "Connect Your Database",
                    body: <p>connect</p>
                },
                "readData": {
                    label: "Read Data",
                    body: <p>connect</p>
                },
                "createData": {
                    label: "Create Data",
                    body: <p>connect</p>
                },
                "updateData": {
                    label: "Update Data",
                    body: <p>connect</p>
                }
            }
        },
        "integrations": {
            label: "Integrations",
            body: <p>intro</p>,
            subMenuItems: ["introduction", "googleDrive", "dropbox", "awsS3"],
            subMenuItemInfo: {
                "introduction": {
                    label: "Introduction",
                    body: <p>intro</p>
                },
                "googleDrive": {
                    label: "Connect Your Database",
                    body: <p>connect</p>
                },
                "dropbox": {
                    label: "Read Data",
                    body: <p>connect</p>
                },
                "awsS3": {
                    label: "Create Data",
                    body: <p>connect</p>
                }
            }
        }
    }
}