import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import isObjectEmpty from "../../Constants/isObjectEmpty";
import {toggleModal} from "../../Actions/ToggleModal";
import {toggleShowPromoBar} from "../../Actions/ToggleShowPromoBar";

let utc3 = new Date(Date.UTC(2021, 3, 12, 12, 0, 0, 0));

class PromoBar extends Component {
    componentDidMount(){
        if (new Date() > utc3){
            this.props.toggleShowPromoBar(false)
        }
    }

    goToPromo = e => {
        this.props.toggleModal("updatedPricingModal")
        // this.promoLink.click()
    }

    hideBar = () => {
        this.props.toggleShowPromoBar(false)
    }

    render() {
        if (this.props.showPromoBar){
            let show = this.props.showPromoBar;

            if (this.props.tier !== null){
                if (!isObjectEmpty(this.props.tier)){
                    if (this.props.tier !== "free"){
                            show = false
                    }
                }
            }

            if (show){
                return (
                    <div style={'close' in this.props ? {marginLeft: "100px", marginRight: "100px", marginBottom: "25px"} : null}>
                        <div style={{padding: "1%", backgroundColor: "#ff6666", color: "white", textAlign: "center"}}>
                            <h4><span style={{cursor: "pointer"}} onClick={this.goToPromo}><b>Pricing Increase on April 12th. See what's changing</b></span> {'close' in this.props ? <span style={{float: 'right'}} onClick={this.hideBar}><Icon name="close" /></span> : null}</h4>
                        </div>
                    </div>
                )
            } else {
                return (
                    null
                )
            }
        } else {
            return (
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier,
    showPromoBar: state.mainState.showPromoBar
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    toggleShowPromoBar: toggleShowPromoBar
}

export default connect(mapStateToProps, mapActionsToProps)(PromoBar)