export const jsonToPhpHashMap = (jsonObj) => {
    if (Array.isArray(jsonObj)) {
        let phpArrayItems = jsonObj.map(value => {
            if (typeof value === 'object' && value !== null) {
                return jsonToPhpHashMap(value);
            } else if (typeof value === 'string') {
                return `'${value}'`;
            } else {
                return String(value);
            }
        });
        return `array(${phpArrayItems.join(", ")})`;
    } else {
        let phpArrayString = "array(";
        let keyValueStrings = Object.entries(jsonObj).map(([key, value]) => {
            let phpKey = `'${key}'`;
            let phpValue;
            if (typeof value === 'object' && value !== null) {
                phpValue = jsonToPhpHashMap(value);
            } else if (typeof value === 'string') {
                phpValue = `'${value}'`;
            } else {
                phpValue = String(value);
            }
            return `${phpKey} => ${phpValue}`;
        });
        phpArrayString += keyValueStrings.join(", ") + ")";
        return phpArrayString;
    }
}

// let jsonObj = {
//     "name": "John",
//     "age": 30,
//     "city": "New York",
//     "details": {
//         "profession": "Engineer",
//         "hobby": "Reading"
//     },
//     "friends": ["Mike", "Anna", "Tim"]
// };
//
// console.log(jsonToPhpHashMap(jsonObj));
