import React, {Component} from 'react'
import {connect} from 'react-redux'
import RereadFile from "./RereadFile";

class UploadReadSettings extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const imgStyle = {border:"1px solid #F0F0F0", display: "block", marginBottom: "16px"}

        return (
            <div>
                <div className="docsSection">
                    <RereadFile rereadIndex setBothMenuItems={this.props.setBothMenuItems} hasCsv={true} fileNames={<span><b>excel files</b> and Google Sheets</span>}/>
                    <p>However, you may wish to do any of the following:</p>
                    <div className="docsList">
                        <p>– Read another sheet of the excel file or Google Sheet</p>
                        <p>– Read your .csv file with another delimiter</p>
                        <p>– Skip empty or useless lines at the beginning of the file</p>
                        <p>– Specify whether your file has column names or not</p>
                    </div>
                    <p>This guide will show how to re-read your file with different settings. The same process applies to all files no matter which source they were uploaded from</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Read another sheet of Excel File or Google Sheet</p>
                    <p>In order to change the sheet, you go to the <b>Change Sheet</b> action in your File and change the sheet</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-ReadSettings/ChangeSheet.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="change excel file sheet" />
                    <p><b>NOTE:</b> this changes the sheet that this file is read from. If you want to read from multiple sheets in a file, then you need to upload that file multiple times, and in each instance select a different sheet</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Read your .csv file with another delimiter</p>
                    <p>Go to the <b>File Options</b> action in your File and you can change the delimiter from there</p>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-ReadSettings/CSVDelimiter.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="read csv file with another delimiter" />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Read your file from different row</p>
                    <p>Go to the <b>File Options</b> action in your File and you can change 2 things</p>
                    <div className="docsList">
                        <p>1. Which row to start reading the file from</p>
                        <p>2. If your file has column names or not</p>
                    </div>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-ReadSettings/StartRowFileHeaderOptions.png"
                         style={{width: "75%", ...imgStyle}}
                         alt="read file with different options" />
                    <p>If you select that your file does <b>not</b> have column names, then the API will use generic column names such as: "0", "1", "2"</p>
                    <p>If you select that your file <b>does</b> have column names, then the row that you start reading the file will become your column names</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UploadReadSettings)