import React, {Component} from 'react'
import AuthComponent from "../SharedComponents/AuthComponent";
import UpdatedLandingPageIndex from "../LandingPage/UpdatedLandingPageIndex";
import TeamsDisplay from "./TeamsDisplay"

class TeamsIndex extends Component {
    render() {
        return (
            <div>
                <AuthComponent
                    authComponent={<TeamsDisplay />}
                    nonAuthComponent={<UpdatedLandingPageIndex/>}
                />
            </div>
        )
    }
}

export default TeamsIndex