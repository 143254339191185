import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class SlideCounter extends Component {
    constructor(props) {
        super(props);

        this.changeSlide = this.changeSlide.bind(this);
    }

    changeSlide(e, slide){
        this.props.changeSlide(slide)
    }

    render() {
        let circles = [];

        for (let i=0; i<this.props.totalSlides; i++){
            if (i === this.props.slide){
                circles.push(<Icon name="circle" style={{cursor: 'pointer'}} key={i} onClick={(e) => this.changeSlide(e, i)}/>)
            }
            else{
                circles.push(<Icon name="circle outline" style={{cursor: 'pointer'}} key={i} onClick={(e) => this.changeSlide(e, i)}/>)
            }
        }

        return (
            <div style={{fontSize: '50%', paddingTop: '15px', paddingBottom: '5px'}}>
                {circles}
            </div>
        )
    }
}

export default SlideCounter