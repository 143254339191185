import React, {Component} from 'react'
import {connect} from "react-redux";
import {Accordion, Icon } from 'semantic-ui-react'
import CodeSnippet from "./CodeSnippet"
import {getFileURL} from "../Constants/getFileURL";
import {getCodeSnippet} from "../Constants/getCodeSnippet"
import CustomAccordion from "../SharedComponents/CustomAccordion"

class CodeAccordionUpdated extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0
        }

        this.handleAccordionClick = this.handleAccordionClick.bind(this);
    }

    handleAccordionClick(e, idxClicked){
        const { index } = idxClicked;
        const activeIndex = this.state.activeIndex;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex })
    }

    render() {
        let code;

        if ('quickstart' in this.props){
            code = this.props.code
        } else{
            let fileURL = getFileURL(this.props.fileInformation, this.props.displayFileHash, this.props.pkID, this.props.outputFormat, this.props.apiType, this.props.rowLen, this.props.rows, this.props.readQuery, this.props.count);

            code = {
                HTML: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.apiType, this.props.createFormat, fileURL, "html"),
                cURL: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.apiType, this.props.createFormat, fileURL, "cURL"),
                python: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.apiType, this.props.createFormat, fileURL, "python"),
                javascript: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.apiType, this.props.createFormat, fileURL, "javascript"),
                ruby: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.apiType, this.props.createFormat, fileURL, "ruby"),
                // PHP: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.apiType, this.props.createFormat, fileURL, "php")
                PHP: getCodeSnippet(this.props.fileInformation, this.props.pk, this.props.pkID, this.props.apiType, this.props.createFormat, fileURL, "php")
            }
        }

        const activeIndex = this.state.activeIndex;

        return (
            <CustomAccordion
                body={<div>
                    <CodeSnippet code={code} height={150} apiType={this.props.apiType}/>
                </div>}
                header="Code Samples"
                accordionStyle={{
                    borderRadius: '4px',
                    border: "1px solid #F0F0F0",
                    backgroundColor: 'white'
                    // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                }}
                headerDivStyle={{
                    borderRadius: '4px',
                    backgroundColor: 'white',
                    fontSize: '16px',
                    fontWeight: '700',
                    fontFamily: "Lato",
                    padding: "16px"
                }}
                open
            />
        )

    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(CodeAccordionUpdated)