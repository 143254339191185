import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'
import {modifyFilePK} from "../Actions/ModifyFilePK";
import {toggleModal} from "../Actions/ToggleModal";
import {modifyEditID} from "../Actions/ModifyEditID";
import isObjectEmpty from "../Constants/isObjectEmpty";
import FileSummary from './FileSummary'
import FilesFilterSortIndex from "./FilesFilterSort/FilesFilterSortIndex";
import { isBrowser } from 'react-device-detect'
import FilesTabFileSummary from "./FilesTabFileSummary";
import UpdatedFilesFilterSortIndex from "./FilesFilterSort/UpdatedFilesFilterSortIndex";
import HeaderIndex from "../SharedComponents/HeaderIndex";
import PageHeader from "../SharedComponents/PageHeader";
import getURLParam from "../Constants/getURLParam";

class FileInformationTable extends Component {
    constructor(props){
        super(props);

        this.editAll = this.editAll.bind(this);
        // Change this with excel sheet
        // this.shouldDisplayTable = this.shouldDisplayTable.bind(this);
    }

    editAll(e){
        this.props.toggleModal("editModal");
        this.props.modifyEditID("all");
    }

    render() {
        let table = null;

        if (!isObjectEmpty(this.props.fileInformation)){
            if ((this.props.tableType !== "all" && this.props.uploadedPK.length > 0) || this.props.tableType === "all"){
                let fileDropSources = [];
                // Get file names to check whether all extensions are the same
                let fileNames = [];

                // Grid column length
                const colLengths = ["6","3", "1", "2"];

                const initPKList = this.props.tableType === 'all' ? this.props.filePK : this.props.uploadedPK

                for (let i=0; i<initPKList.length; i++) {
                    const pkID = initPKList[i]['id'];
                    const pk = initPKList[i]['pk'];
                    if ((window.location.href).includes("/upload")){
                        if (pk in this.props.fileInformation || pkID in this.props.fileInformation) {
                            fileDropSources.push(
                                <FileSummary idx={i} pkID={pkID}
                                             pk={pk}
                                             key={pk.toString() + i.toString()}
                                             colLengths={colLengths}
                                             textPadding="10"
                                             tableType={this.props.tableType}
                                />
                            );
                            fileNames.push(this.props.fileInformation[pkID].name);
                        }
                    }
                    else {
                        if ((window.location.href).includes("/files") || window.location.href.includes("/teams") || window.location.href.includes("/api") || window.location.href.includes("/import") || getURLParam(window.location.href).trim() === "" || getURLParam(window.location.href) === window.location.host) {
                            if (pk in this.props.fileInformation || pkID in this.props.fileInformation) {
                                fileDropSources.push(
                                    <FilesTabFileSummary idx={i} pkID={pkID}
                                                         isImporterPage={this.props.isImporterPage}
                                                         isApiPage={this.props.isApiPage}
                                                        pk={pk}
                                                        key={pk.toString() + i.toString()}
                                                        colLengths={colLengths}
                                                        textPadding="10"
                                                        tableType={this.props.tableType}/>
                                )
                            }
                        }
                    }
                }

                let header = null;
                let fileTable;

                let fileFilterIndex = null;
                let fileNumDisplay = null;
                let fileHeaderDisplay = null;
                let margin = isBrowser ? "30px" : "10px";
                if (this.props.tableType === "all" && ((window.location.href).includes("/files") || window.location.href.includes("/teams") || window.location.href.includes("/api") || window.location.href.includes("/import") || window.location.href.includes("/"))){
                    margin = '0'

                    if (this.props.isApiPage){
                        header =
                            <PageHeader
                                header="Your Files & APIs"
                                desc="Manage, filter, or search through your APIs & Files"
                                tiny
                            />
                    } else{
                        if (this.props.isImporterPage){
                            header =
                                <PageHeader
                                    header="Your Imported Files"
                                    desc="View, manage, and use your importer files as an API"
                                    importer
                                    tiny
                                />
                        }
                    }

                    fileFilterIndex = <UpdatedFilesFilterSortIndex/>

                    fileHeaderDisplay =
                        <div className="fileInformationTableHeader">
                            <div>
                                <b>File Name</b>
                            </div>
                            <div>
                                <b>Created</b>
                            </div>
                            <div>
                                {this.props.isApiPage ? <b>Source</b> : <b>By</b>}
                            </div>
                            <div>
                                <b>File ID</b>
                            </div>
                            <div>
                            </div>
                        </div>

                    // const totalNumber = this.props.filePK.length > this.props.originalFilePK.length ? this.props.filePK.length : this.props.originalFilePK.length
                    // fileNumDisplay = <small><i>Showing {this.props.filePK.length} of {totalNumber} files</i></small>
                }
                else{
                    header = <h2 style={{textAlign: "center"}}>Files Selected</h2>
                }
                fileTable = fileDropSources;

                table =
                <div style={{
                        marginLeft: margin,
                        marginRight: margin,
                        minHeight: '100vh'
                }}>
                        {header}
                        {/*<HeaderIndex title={this.props.title}/>*/}
                        <div style={{padding:'10px 30px 10px 30px'}}>
                        {fileFilterIndex}
                        </div>
                        <div style={{padding:'10px 30px 10px 30px'}}>
                        {fileHeaderDisplay}
                        {fileTable}
                        </div>
                </div>
            }
        }

        return (
            <div>
                {table}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation,
    uploadedPK: state.mainState.uploadedPK,
    originalFilePK: state.mainState.originalFilePK
});

const mapActionsToProps = {
    modifyFilePK: modifyFilePK,
    toggleModal: toggleModal,
    modifyEditID: modifyEditID
};

export default connect(mapStateToProps, mapActionsToProps)(FileInformationTable)