import React, {Component} from 'react'
import ResendValidationEmails from "./ResendValidationEmails";
import DNSValues from "./DNSValues"

class SSLPendingValidationStatus extends Component {
    getEmailValidationInfo = (validationEmails, resend=false) => {
        if (resend){
            return (
                <div>
                    {"Validation emails will be sent to:\n\n" + validationEmails.join("\n")}
                    <br/>
                    <a href="https://docs.aws.amazon.com/acm/latest/userguide/email-validation.html"
                       target="_blank"
                       rel="noopener noreferrer">Read more about Email Validation</a>
                </div>
            )
        } else {
            return (
                <div>
                    {"Check any of the following emails to validate your certificate:\n" + validationEmails.join("\n")}
                    <br/>
                    <a href="https://docs.aws.amazon.com/acm/latest/userguide/email-validation.html"
                       target="_blank"
                       rel="noopener noreferrer">Read more about Email Validation</a>
                </div>
            )
        }
    }

    render() {
        const validationInfo = this.props.domainValidationOptions.length > 0 ? this.props.domainValidationOptions[0] : null

        if (validationInfo !== null){
            const validationMethod = "ValidationMethod" in validationInfo ? validationInfo.ValidationMethod : null;
            let info = null;

            if (validationMethod === "EMAIL"){
                if ("ValidationEmails" in validationInfo){
                    info = this.getEmailValidationInfo(validationInfo.ValidationEmails)
                }
            } else if (validationMethod === "DNS"){
                if ("ResourceRecord" in validationInfo){
                    info = <div>Create a CNAME record with the <b>CNAME Name</b> and <b>CNAME Value</b> below on your domain's DNS page
                        <br/>
                        <a href="https://docs.aws.amazon.com/acm/latest/userguide/dns-validation.html"
                    target="_blank"
                    rel="noopener noreferrer">Read more about DNS Validation</a>
                    </div>
                }
            }

            return (
                <div>
                    {
                        validationMethod !== null ? this.props.getDisplayRow("Validation Method", validationMethod, info) : null
                    }
                    {
                        validationMethod === "EMAIL" ?
                            this.props.getDisplayRow("Resend Validation Emails", <ResendValidationEmails certificateArn={this.props.certificateArn} validationInfo={validationInfo}/>, this.getEmailValidationInfo(validationInfo.ValidationEmails, true)) :
                            validationMethod === "DNS" ?
                            <DNSValues
                                getDisplayRow={this.props.getDisplayRow}
                                validationInfo={validationInfo}
                            /> : null
                    }
                </div>
            )
        } else {
            return null
        }
    }
}

export default SSLPendingValidationStatus