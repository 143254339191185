import React, {Component} from 'react'
import {connect} from 'react-redux'
import UpgradeToTeam from "../UpgradeToTeam"
import Loader from 'react-loader-spinner'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import BannerAlert from "../../SharedComponents/BannerAlert"
import CachingLayerForm from "./CachingLayerForm"
import API_Root from "../../Constants/API_Root";
import CachingLayerDisplay from "./CachingLayerDisplay";

class CachingLayerIndex extends Component {
    constructor(props) {
        super(props);

        // view data in this
        this.state = {
            error: null,
            fetching: false,
            expiring: null,
            authInfo: {},
            cacheId: null,
            query: "",
            createdDt: null
        }
    }

    componentDidMount(){
        if (this.isBusiness()){
            const isError = (err) => this.setState({ error: err, fetching: false })

            fetch(API_Root + "api/get-api-caching-layer-info/", {
                method: "POST",
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    pk: this.props.pk
                })
            }).then(res => {
                if (res.status === 200){
                    res.json().then(data => {
                        this.setState({
                            error: null,
                            fetching: false,
                            expiring: data.expiring,
                            authInfo: data.authInfo,
                            cacheId: data.cacheId,
                            createdDt: data.createdDt
                        })
                    }).catch(err => isError(err))
                } else {
                    isError("There was a problem: " + res.status.toString())
                }
            }).catch(err => isError(err))
        }
    }

    isBusiness = () => (this.props.tier === "business" || this.props.tier === "enterprise")

    changeState = (key, value) => this.setState({ [key]: value })

    changeStateWhenCreated = (cacheId, expiring, authInfo, createdDt) => this.setState({ expiring: expiring, authInfo: authInfo, cacheId: cacheId, createdDt: createdDt })

    resetState = () => this.setState({ error: null, fetching: false, expiring: null, authInfo: {}, cacheId: null, query: "", createdDt: null })

    render() {
        const isBusiness = this.isBusiness();

        let style = {marginTop: "16px"}

        if (!isBusiness){
            style['pointerEvents'] = "none"
            style['opacity'] = "0.5"
        }

        let body = null;

        if (this.state.fetching){
            body =
            <div>
                <Loader
                    type="TailSpin"
                    color="black"
                    height="20"
                    width="20"
                />
                <div style={{fontSize: "18px", fontWeight: "700"}}>Getting information about your caching layer...</div>
            </div>
        } else {
            if (this.state.error !== null){
                let errorMsg;
                if (typeof this.state.error === "string"){
                    errorMsg = this.state.error
                } else {
                    errorMsg = <span>
                        There was an error getting your caching layer information<br/><br/>
                        Please contact support if this error persists
                    </span>
                }

                body = <div>
                    <div style={{fontSize: "20px", fontWeight: "700", color: gunaldiStyles.red}}>
                        {errorMsg}
                    </div>
                </div>
            } else {
                if (this.state.cacheId === null){
                    body = <div>
                        <BannerAlert
                            icon="bx bxs-info-circle"
                            type="info"
                            header="You currently do not have an active caching layer"
                            content={<span>Create one below to access your CURRENT data super fast. <a href="/api-docs/?docsMenuItem=actions-cachingLayer" target="_blank" rel="noopener noreferrer">Read our guide about caching layer</a></span>}
                        />
                        <CachingLayerForm info={this.state}
                                          pk={this.props.pk}
                                          changeState={this.changeState}
                                          pkID={this.props.pkID}
                                          getURL={this.props.getURL}
                                          changeStateWhenCreated={this.changeStateWhenCreated}
                        />
                    </div>
                } else {
                    body = <div>
                        <CachingLayerDisplay
                            getURL={this.props.getURL}
                            resetState={this.resetState}
                            info={this.state}
                            pkID={this.props.pkID}
                        />
                    </div>
                }
            }
        }

        return (
            <div>
                {isBusiness ? null : <div style={{marginTop: "8px"}}>
                    <UpgradeToTeam higherTier="cachingLayer"
                                   header="You can only use a Caching Layer on the Business or Higher Tier"/>
                </div>}
                <div style={isBusiness ? null : {"cursor": "not-allowed"}}>
                    <div style={style}>
                        {body}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CachingLayerIndex)