import React, {Component} from 'react'
import { Message } from 'semantic-ui-react'
import ReuploadFile from "../../../FileDnD/ReuploadFile"

class EmptyFileWarning extends Component {
    render() {
        return (
            <div>
                <Message negative>
                    <Message.Header>You can't {this.props.actionType} rows on an empty file</Message.Header>
                    <p>Add at least 1 row of Column Names AND</p>
                    <ReuploadFile pk={this.props.pk} pkID={this.props.pkID} />
                </Message>
            </div>
        )
    }
}

export default EmptyFileWarning