import React, {Component} from 'react'
import { Input, Button } from 'semantic-ui-react'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

class ImportUploadName extends Component {
    constructor(props) {
        super(props);

        const importName = localStorage.getItem("importName");

        this.state = {
            importName: importName === null ? "" : importName,
            loading: false
        }
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    isNameEdited = () => {
        let isNameEdited = false;

        if (localStorage.getItem("importName") !== null){
            isNameEdited = localStorage.getItem("importName").trim() !== this.state.importName.trim()
        } else {
            isNameEdited = this.state.importName.trim() !== ""
        }

        return isNameEdited
    }

    saveImportName = () => {
        this.setState({ loading: true })

        const error = () => this.setState({ loading: false })

        localStorage.setItem("importName", this.state.importName)
        error()
    }

    undo = () => {
        const importName = localStorage.getItem("importName");

        let prevName = importName === null ? "" : importName;
        this.setState({ importName: prevName})
    }

    render() {
        return (
            <div style={{margin: "0 60px 24px 60px"}}>
                <div className="row" style={{marginBottom: "8px"}}>
                    <div className="col-sm-12">
                        <span style={{fontFamily: "Lato", fontSize: "14px", fontWeight: "500", color: "#161E16"}}>Your Name</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-8">
                        <input onChange={this.handleInputChange}
                               value={this.state.importName}
                               style={{width: "100%"}}
                               className="inputGray"
                               name="importName"
                               placeholder="Hermione Granger"
                        />
                        {this.state.importName.trim() === "" ? <small style={{display: "block", color: "red"}}>Please enter your name so {this.props.info.userEmail} can see who uploaded the files</small> : null}
                        {this.isNameEdited() ? <small style={{display: "block", color: "red"}}>Please save your name</small> : null}
                    </div>
                    <div className="col-sm-4">
                        <div style={{display: 'grid', gridTemplateColumns: "50% 50%"}}>
                            {
                                this.isNameEdited() ?
                                    <div style={{textAlign: "right"}}>
                                        <Button
                                            icon="undo"
                                            content="Reset Name"
                                            style={gunaldiStyles.btnGray}
                                            onClick={this.undo}
                                        />
                                    </div>: <div> </div>
                            }
                            <div style={{textAlign: "right"}}>
                                <Button
                                    disabled={!this.isNameEdited()}
                                    icon="save alternate outline"
                                    content="Save Name"
                                    loading={this.state.loading}
                                    style={gunaldiStyles.btnGreen}
                                    onClick={this.saveImportName}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ImportUploadName