export const getDatetimeComponentsFromISO = (isoDate) => {
    return {
        // '2014-01-01 09:23:23'
        "year": parseInt(isoDate.substring(0, 4)),
        "month": parseInt(isoDate.substring(5, 7))-1,
        "date": parseInt(isoDate.substring(8, 10)),
        "hours": getComponent(isoDate, 11, 13),
        "minutes": getComponent(isoDate, 14, 16),
        "seconds": getComponent(isoDate, 17, 19)
    }
}

const getComponent = (isoDate, startIdx, endIdx) => {
    try{
        return parseInt(isoDate.substring(startIdx, endIdx))
    } catch (e) {
        return 0
    }
}