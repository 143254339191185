import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class ConnectDomain extends Component {
    render() {
        const imgBorderStyle = {border: "1px solid #F0F0F0"};

        return (
            <div>
                <div className="docsSection">
                    <p>
                        You can connect your own domain to use as one or both of the following
                        <br/><br/>
                        1. Use your own domain to make API requests<br/>
                        <small>make CRUD requests to your spreadsheet using <code>api.yourdomain.com</code></small>
                        <br/><br/>
                        2. Host the spreadsheet importer on your own domain<br/>
                        <small>collect spreadsheets from users at www.yourdomain.com/import/data/</small>
                    </p>
                    <p>In this guide, we will walk through how to connect your own domain</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Click on Connect Your Domain in your Profile</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ConnectYourDomain/ConnectYourDomain.png" style={{width: '80%', border: "1px solid #F0F0F0"}} />
                    <p> </p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Enter domain you wish to use</p>
                    <p>You can enter 1 domain in the box below</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ConnectYourDomain/EnterDomainName.png" style={{border: "1px solid #F0F0F0"}}/>
                    <p> </p>
                    <p>This domain must be exact and ONLY this domain will work</p>
                    <p>For example, if you enter www.yourdomain.com, then yourdomain.com will not work unless you configure it on your end</p>
                    <p>If you wish to use all the subdomains for your domain, then enter a wildcard: <b>*.yourdomain.com</b></p>
                </div>
                <hr/>
                <div className="docsSection">
                    <p className="docsHeader">(Optional) Get SSL Certificate</p>
                    <p>SSL lets you access your domain over <code>https</code>. It is more secure than regular <code>http</code>. You can read all about it <a href="https://www.digicert.com/what-is-an-ssl-certificate" target="_blank" rel="noopener noreferrer">here</a></p>
                    <p>If you wish to use SSL with your domain, then you need a SSL Certificate</p>
                    <p>We offer 2 ways to link an SSL certificate with your domain</p>
                    <ol>
                        <li>We request a certificate on your behalf from AWS</li>
                        <li>You upload your own certificate</li>
                    </ol>
                    <p> </p>
                    <p><b>Request Certificate</b></p>
                    <p>We can request a certificate for you from AWS at no extra cost to you</p>
                    <p>After you enter your domain name to connect<br/>
                        1. Toggle the <b>Use HTTPS</b> button<br/>
                        2. Choose the <b>Request Certificate</b> tab<br/>
                        3. Choose a validation method <b>Email</b> or <b>DNS</b><br/>
                        4. Click on the <b>Request SSL Certificate</b> Button<br/>
                    </p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ConnectYourDomain/RequestCertificate.png" style={{width: '80%', border: "1px solid #F0F0F0"}} alt="Request Certificate"/>
                    <p> </p>
                    <p>If your certificate request is successful, your certificate will appear in the <b>Your Certificates</b> tab</p>
                    <p>You can click on the <b>View More Information</b> icon to see detailed validation information about the certificate</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ConnectYourDomain/ClickOnDomain.png" style={imgBorderStyle} />
                    <p></p>
                    <p><Icon name="caret right"/> For <b>Email</b> validation, you will receive emails on all the email addresses listed in the SSL Certificate box</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ConnectYourDomain/EmailValidationEmails.png" style={imgBorderStyle} />
                    <p>You can <a href="https://docs.aws.amazon.com/acm/latest/userguide/email-validation.html" target="_blank" rel="noopener noreferrer"> read more about Email Validation here</a></p>
                    <p><Icon name="caret right"/> For <b>DNS</b> validation, you will need to create a CNAME record in your Domain with the name and value listed in the SSL Certificate box</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ConnectYourDomain/DNSValidation.png" style={imgBorderStyle} />
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ConnectYourDomain/DNSValidationNameCheap.png" style={{...imgBorderStyle, width: '100%'}} />
                    <p> </p>
                    <p>DNS validation can take up to 24 hours to validate your domain</p>
                    <p>Also, some domain providers do not accept the full value of your <b>CNAME Name</b> such as this <code>_3639ac514e785e898d2646601fa951d5.example.com.</code> so you might have to try shortening it to just this <code>_3639ac514e785e898d2646601fa951d5</code></p>
                    <p>You can <a href="https://docs.aws.amazon.com/acm/latest/userguide/dns-validation.html" target="_blank" rel="noopener noreferrer"> read more about DNS Validation here</a></p>
                    <p>Once your validation is complete, your certificate will be ready to connect to your domain!</p>
                    <p><b>Import Certificate</b></p>
                    <p>If you already have your own certificate, then you can upload it on API Spreadsheets and use it to connect with your domain</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ConnectYourDomain/ImportCertificateUploadedFiles.png" style={imgBorderStyle} />
                    <p></p>
                    <p>The certificates must be in particular file formats as seen above</p>
                    <p>You can <a href="https://docs.aws.amazon.com/acm/latest/userguide/import-certificate-format.html" target="_blank" rel="noopener noreferrer">read this guide to understand what the certificate files actually look like</a></p>
                    <p>Please contact us if you have any questions importing your own certificate. Since it is hard to give a full overview of all the different certificate types</p>
                    <p>Once successfully imported, you can select the certificate to connect with your domain</p>
                </div>
                <hr/>
                <div className="docsSection">
                    <p className="docsHeader">Get CNAME Record to Connect Your Domain</p>
                    <p>You can finally click <b>Connect Your Domain</b> once your domain name is entered</p> <p>Chose a SSL certificate if you have selected that option</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ConnectYourDomain/ConnectDomainButtonClick.png" style={imgBorderStyle} />
                    <p> </p>
                    <p> Then copy the <b>CNAME</b> value for your domain connection</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ConnectYourDomain/DomainConnectedCNAMEValue.png" style={imgBorderStyle} />
                    <p> </p>
                    <p>Create a CNAME record in your DNS provider for the domain/subdomain name you just entered</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ConnectYourDomain/CNAMEOnNameCheap.png" style={{...imgBorderStyle, width: '100%'}} />
                    <p> </p>
                    <p>The connection can take up to 24 hours to be available</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Use your newly connected domain</p>
                    <p>You can now use your newly connected domain to make API requests</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ConnectYourDomain/OwnDomainAPIRequest.png" style={imgBorderStyle} />
                    <p> </p>
                    <p>Or use it to access the spreadsheet importer</p>
                    <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/ConnectYourDomain/OwnDomainImporter.png" style={{...imgBorderStyle, width: '100%'}} />
                    <p> </p>
                    <p>As always, feel free to contact our support if you have any problems with this feature!</p>
                </div>
            </div>
        )
    }
}

export default ConnectDomain