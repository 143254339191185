import React, {Component} from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import DownloadReceipts from "./DownloadReceipts"
import InfoSegment from "../../SharedComponents/InfoSegment";

class DownloadReceiptsAccordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: -1
        }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps

        const newIndex = this.state.activeIndex === index ? -1 : index

        this.setState({
            activeIndex: newIndex
        })
    }

    render() {
        return (
            <InfoSegment
                header="Receipts"
                info={<DownloadReceipts />}
            />
        )
    }
}

export default DownloadReceiptsAccordion