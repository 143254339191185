import React, {Component} from 'react'

class SilentFail extends Component {
    render() {
        return (
            <div>
                <p>We currently do not provide any errors when a {this.props.destination} save fails</p>
                <p>We do this for 2 reasons</p>
                <ol>
                    <li>Smoother importing experience for the user</li>
                    <li>File is always saved on API Spreadsheets for you to access</li>
                </ol>
                <p>We are in the process of adding a feature of sending email alerts when an error happens. Stay tuned!</p>
            </div>
        )
    }
}

export default SilentFail