import React, {Component} from 'react'
import CustomImage from "../../SharedComponents/CustomImage"

class FAQ extends Component{
    render() {
        const answer = this.props.answer;
        let answerBlock;

        if (answer.constructor === Array){
            const answerList = answer.map((x, idx) => <li key={idx}>{x}</li>);
            answerBlock = <ul>{answerList}</ul>
        }
        else{
            answerBlock = <span>{answer}</span>
        }

        let image = null;

        if (this.props.image !== null){
            if ('width' in this.props.image){
                image = <div><CustomImage src={this.props.image.src} alt={this.props.image.alt} width={this.props.image.width}/></div>
            }
            else{
                image = <div><CustomImage src={this.props.image.src} alt={this.props.image.alt} /></div>
            }
        }

        return (
            <div style={{marginBottom: "24px", fontFamily: "Lato"}}>
                <div style={{fontWeight: "700", fontSize: "16px", color: "#161E16"}}>{this.props.question}</div>
                {answerBlock}
                {image}
            </div>
        )
    }
}

export default FAQ