import React, {Component} from 'react'
import {CardElement, injectStripe} from 'react-stripe-elements';
import API_Root from "../../Constants/API_Root";
import { Input, Message, Button } from 'semantic-ui-react'
import {getPricingText} from "../../Constants/getPricingText";
import {useStripe} from '@stripe/react-stripe-js';
import InfoSegment from "../InfoSegment";
import pricing from "../../Constants/Pricing/Pricing";
import BannerAlert from "../BannerAlert";
import {addSuffixToNumbers} from "../../Constants/addSuffixToNumbers";
import CreditCardSelection from "./CreditCardSelection";
import {getCardInfoFromCardIdx} from "../../Constants/getCardInfoFromCardIdx";

class CreditCardDetails extends Component {
    constructor(props) {
        super(props);

        let cardIdx;
        try{
            if (this.userHasPaymentInformation()){
                if (this.props.paymentInfo.creditCards.length > 0){
                    cardIdx = 0
                } else {
                    cardIdx = -1
                }
            } else {
                cardIdx = null
            }
        } catch (e){
            cardIdx = null
        }

        this.state = {
            cardSuccess: <BannerAlert
                type="display"
                header={"Your card will be charged " + getPricingText(this.props.currency, this.props.plan, false, null, null, this.props.yearlyPricing) + " right now. Your subscription will renew every " + this.getRenewMessage()}
                content={"Don't worry, you cancel at any time. No questions asked!"}
            />,
            name: '',
            submitDisabled: true,
            loading: false,
            cardIdx: cardIdx
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.submit = this.submit.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
    }

    getRenewMessage = () => {
        const currDate = new Date();

        if (this.props.yearlyPricing){
            return " year on " + (currDate.toLocaleDateString())
        } else {
            try{
                let isEndOfMonth = false;

                if (currDate.getMonth() === 1){
                    if (currDate.getDate() >= 28){
                        isEndOfMonth = true
                    }
                } else {
                    if (currDate.getDate() >= 30) {
                        isEndOfMonth = true
                    }
                }

                if (isEndOfMonth){
                    return " month on the last day of each month"
                } else {
                    return " month on the " + addSuffixToNumbers(currDate.getDate())
                }
            } catch (e) {
                return " month on this date"
            }

        }
    }

    dismissMessage(){
        this.setState({ cardSuccess: null })
    }

    handleInputChange(e){
        this.setState({
            name: e.target.value
        })
    }

    async submit(e) {
        this.setState({
            loading: true
        })

        const failedMessage = "Your Payment Failed!";

        let token;
        let cardInfo = null;

        if (this.state.cardIdx === null || this.state.cardIdx === -1){
            let result = await this.props.stripe.createToken({name: "Name"});

            if (result !== undefined){
                token = result.token
            } else {
                token = undefined
            }

        } else {
            token = {id: null}
            cardInfo = getCardInfoFromCardIdx(this.props.paymentInfo, this.state.cardIdx)
        }

        if (token !== undefined){
            let response = await fetch(API_Root + "api/charge-api-spreadsheets/", {
                method: "POST",
                headers: {"Content-Type": "text/plain"},
                body: JSON.stringify({
                    stripeToken: token.id,
                    email: localStorage.getItem("email"),
                    name: this.state.name,
                    token: localStorage.getItem("token"),
                    plan: this.props.plan,
                    currency: this.props.currency,
                    cardInfo: cardInfo,
                    yearlyPricing: this.props.yearlyPricing
                })
            }).then().catch(err =>
                this.setState({
                    loading: false,
                    cardSuccess: <Message error
                                          onDismiss={this.dismissMessage}
                                          header={failedMessage}
                                          content={"Please try again. If it keeps failing, email us at payment@apispreadsheets.com"}
                    />
                })
            );
            const emojiStyle = {fontSize: '90%'};
            if (response !== undefined){
                if (response.ok){
                    this.setState({
                        loading: false,
                        cardSuccess: <Message positive={true}
                                              color={'blue'}
                                              onDismiss={this.dismissMessage}
                                              header="Congrats! Your payment was a success!"
                                              content={<p style={{color:'black'}}>Check out your new benefits. Click <a style={{textDecoration:'none',color:'blue'}} href="/upload"> here</a>  to upload a file <span role="img" aria-label="smile" style={emojiStyle}>😀</span></p>}
                        />
                    })

                    this.props.changeProcess("completed")
                }
                else{
                    if (response.status === 402){
                        response.json().then(data => {
                            this.props.stripe.confirmCardPayment(data.paymentIntentClientSecret, {
                                setup_future_usage: "off_session"
                            }).then((result) => {
                                if ("error" in result){
                                    this.setState({
                                        loading: false,
                                        cardSuccess: <Message error
                                                              onDismiss={this.dismissMessage}
                                                              header="There was a problem with your additional authentication"
                                                              content="Please try again!"
                                        />
                                    })
                                } else {
                                    fetch(API_Root + "api/confirm-3d-secure-payment/", {
                                        method: "POST",
                                        headers: {"Content-Type": "text/plain"},
                                        body: JSON.stringify({
                                            email: localStorage.getItem("email"),
                                            token: localStorage.getItem("token"),
                                        })
                                    }).then(res => {
                                        if (res.status === 201){
                                            this.setState({
                                                loading: false,
                                                cardSuccess: <Message positive={true}
                                                                      color={'blue'}
                                                                      onDismiss={this.dismissMessage}
                                                                      header="Congrats! Your payment was a success!"
                                                                      content={<p style={{color:'black'}}>Check out your new benefits. Click <a style={{textDecoration:'none',color:'blue'}} href="/upload"> here</a>  to upload a file <span role="img" aria-label="smile" style={emojiStyle}>😀</span></p>}
                                                />
                                            })

                                            this.props.changeProcess("completed")
                                        } else {
                                            const errorMsg = "errorMsg" in data ? data['errorMsg'] : "Please try again!"

                                            this.setState({
                                                loading: false,
                                                cardSuccess: <Message error
                                                                      onDismiss={this.dismissMessage}
                                                                      header={failedMessage}
                                                                      content={errorMsg}
                                                />
                                            })
                                        }
                                    }).catch(err =>
                                        this.setState({
                                            loading: false,
                                            cardSuccess: <Message error
                                                                  onDismiss={this.dismissMessage}
                                                                  header="There was a problem with your additional authentication"
                                                                  content="Please try again!"
                                            />
                                        })
                                    )
                                }
                            }).catch(err =>
                                this.setState({
                                    loading: false,
                                    cardSuccess: <Message error
                                                          onDismiss={this.dismissMessage}
                                                          header={failedMessage}
                                                          content="Please try again!"
                                    />
                                })
                            )
                        })

                    }
                    else {
                        response.json().then(data => {
                            const errorMsg = "errorMsg" in data ? data['errorMsg'] : "Please try again!"

                            this.setState({
                                loading: false,
                                cardSuccess: <Message error
                                                      onDismiss={this.dismissMessage}
                                                      header={failedMessage}
                                                      content={errorMsg}
                                />
                            })
                        }).catch(err =>
                            this.setState({
                                loading: false,
                                cardSuccess: <Message error
                                                      onDismiss={this.dismissMessage}
                                                      header={failedMessage}
                                                      content="Please try again!"
                                />
                            })
                        )
                    }
                }
            }

        }
        else{
            this.setState({
                loading: false,
                cardSuccess: <Message error
                                      onDismiss={this.dismissMessage}
                                      header={failedMessage}
                                      content="Please enter all details and try again!"
                />
            })
        }
    }

    userHasPaymentInformation = () => this.props.paymentInfo !== null

    changeCardIdx = (idx) => this.setState({ cardIdx: idx })

    render() {
        const ccDetails = <div className="checkout">
            <Input icon="user"
                   id="paymentName"
                   iconPosition='left'
                   placeholder="Name on Card"
                   onChange={this.handleInputChange} value={this.state.name} fluid />
            <br/>
            <div className="semanticBorder">
                <CardElement />
            </div>
        </div>


        return (
            <div>
                {
                    this.userHasPaymentInformation() ?
                    <div style={{marginBottom: "16px"}}>
                        <InfoSegment
                            header="Select Credit Card"
                            info={<CreditCardSelection paymentInfo={this.props.paymentInfo}
                                                       cardIdx={this.state.cardIdx}
                                                       changeCardIdx={this.changeCardIdx}
                            />}
                        />
                    </div> : null
                }
                <div style={this.state.cardIdx === null || this.state.cardIdx === -1 ? null : {pointerEvents: 'none', opacity: '0.2'}}>
                    {
                        this.props.segment ?
                            <InfoSegment
                                header={this.userHasPaymentInformation() ? "Enter New Credit Card" : "Enter Credit Card Details"}
                                info={ccDetails}
                            /> : ccDetails
                    }
                </div>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button loading={this.state.loading}
                            id="upgradeButton"
                            onClick={this.submit}
                            color="black"
                            style={{textAlign: 'center',color:'white',fontSize:'120%'}} fluid>
                        {"Upgrade to " + pricing[this.props.plan].name.toString().toUpperCase()}
                    </Button>
                </div>
                <div style={{marginTop: "8px"}}>
                    {this.state.cardSuccess}
                </div>
            </div>
        )
    }
}

export default injectStripe(CreditCardDetails)