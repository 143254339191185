import React, {Component} from 'react'
import { Segment } from 'semantic-ui-react'

class HowItWorksSegment extends Component {
    render() {
        return (
            <div>
                <div style={{textAlign: "center", margin: "0 10px 0 10px"}}>
                    <img src={this.props.number}
                         style={{width: "40px", height: "40px"}}
                         alt={"Number" + (this.props.idx + 1).toString()} />
                    <div style={{fontFamily: "Lato", fontWeight: "700", fontSize: "20px", color: "#161E16", paddingTop: "20px"}}>
                        {this.props.info.header}
                    </div>
                </div>
                <div style={{paddingTop: "16px"}}>
                    {this.props.info.body}
                </div>
            </div>
        )
    }
}

export default HowItWorksSegment