import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../Constants/API_Root";
import Loader from 'react-loader-spinner'
import { Message } from 'semantic-ui-react'
import DatabaseDisplay from "./DatabaseDisplay"
import {modifyAllDBInfo} from "../Actions/ModifyAllDBInfo";
import isObjectEmpty from "../Constants/isObjectEmpty";
import getURLParam from "../Constants/getURLParam";
import {modifyAllQueries} from "../Actions/ModifyAllQueries";
import PageHeader from "../SharedComponents/PageHeader";
import BackHeaderIcon from "../SharedComponents/BackHeaderIcon";
import DatabasePageHeaderToolbar from "./DatabasePageHeaderToolbar"


class DatabaseDisplayIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            error: false
        }
    }

    getValueFromDBInfo = (key) => {
        let value = "";

        if ("dbInfo" in this.props.allDBInfo){
            if (key in this.props.allDBInfo['dbInfo']){
                value = this.props.allDBInfo['dbInfo'][key]
            }
        }

        return value
    }

    componentDidMount(){
        let getDBInfo = isObjectEmpty(this.props.allDBInfo);
        if (getDBInfo){
            const id = getURLParam(window.location.href);
            const errorFetch = () => this.setState({ info: {}, error: true, isFetching: false })

            const current = new Date();
            const utcOffset = current.getTimezoneOffset();

            fetch(API_Root + "api/get-api-db-info/" + id + "/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    utcOffset: utcOffset
                })
            }).then((res) => {
                if (res.status === 200){
                    res.json().then((data) => {
                        this.props.modifyAllQueries(data.dbInfo.allQueries)
                        delete data.dbInfo['allQueries']
                        this.props.modifyAllDBInfo(data);
                        this.setState({ error: false, isFetching: false })
                    }).catch(err => errorFetch())
                } else {
                    errorFetch()
                }
            }).catch(err => errorFetch())
        } else {
            this.setState({ info: {}, error: false, isFetching: false })
        }
    }

    render() {
        let body = null;
        let name = "";
        const pagePadding = "16px 60px 240px 60px"

        if (this.state.isFetching){
            body = <div style={{ textAlign: 'center', padding: pagePadding}}>
                <Loader
                    type="TailSpin"
                    color="black"
                    height="50"
                    width="50"
                />
                <h4 className="thinHeading" style={{color: 'black'}}>Getting your Database Information...</h4>
            </div>
        } else {
            if (this.state.error){
                body = <div style={{ padding: pagePadding }}>
                    <Message
                        header="There was an error getting your database information"
                        icon="refresh"
                        content="Please refresh. Contact our support if this error persists"
                        negative
                        size="huge"
                        fluid/>
                </div>
            } else {
                body = <div>
                    <PageHeader
                        header={
                            <div>
                                <h3>
                                    <span style={{marginTop: "3px"}}>
                                        <BackHeaderIcon link="/database"/>
                                        <span style={{marginTop: "3px"}}>{this.getValueFromDBInfo('name')}</span>
                                    </span>
                                </h3>
                            </div>
                        }
                        desc={<DatabasePageHeaderToolbar description={this.getValueFromDBInfo('description')}/>}
                    />
                    <div style={{ padding: pagePadding }}>
                        <DatabaseDisplay  />
                    </div>
                </div>
            }
        }

        return (
            <div style={{minHeight: '100vh'}}>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allDBInfo: state.mainState.allDBInfo
})

const mapActionsToProps = {
    modifyAllDBInfo: modifyAllDBInfo,
    modifyAllQueries: modifyAllQueries
}

export default connect(mapStateToProps, mapActionsToProps)(DatabaseDisplayIndex)