import React, {Component} from 'react'
import ModalApp from '../SharedComponents/Modal'
import GoogleSheetsTokenError from './GoogleSheetsTokenError'

class GoogleSheetsTokenErrorModal extends Component {
    render() {
        return (
            <ModalApp name="googleSheetsTokenErrorModal"
                      header={<h1 style={{textAlign: 'center'}} className="thinHeading">Error Connecting to Your Google Account <span role="img" aria-label="sad-emoji">🙁</span></h1>}
                      size="medium"
                      body={<GoogleSheetsTokenError/>}
            />
        )
    }
}

export default GoogleSheetsTokenErrorModal