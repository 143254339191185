import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import {toggleModal} from "../../../../Actions/ToggleModal";
import {modifyModalInfo} from "../../../../Actions/ModifyModalInfo";

class AddNewS3Account extends Component {
    constructor(props) {
        super(props);
    }

    addNewS3Account = () => {
        if ('profile' in this.props){
            this.props.modifyModalInfo({
                accountInfo: this.props.accountInfo,
                profile: true
            })
        }

        this.props.toggleModal("addNewS3Modal")
    }

    render() {
        return (
            <div>
                <Button
                    onClick={this.addNewS3Account}
                    color="yellow">
                    <Icon name="plus" />
                    <Icon name="aws" />
                    <span style={{paddingLeft: "5px"}}>Add New AWS S3 Account</span>
                </Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(AddNewS3Account)