import React, {Component} from 'react'
import {connect} from 'react-redux'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import { Button } from 'semantic-ui-react'
import WhitelistDomainsBox from "./WhitelistDomainsBox"
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyPaymentModalOrigin} from "../../Actions/ModifyPaymentModalOrigin";

class SettingsWhitelistDomains extends Component {
    constructor(props) {
        super(props);

        this.state = {
            whitelistDomains: this.getWhitelistDomainsArray(this.props.whitelistDomains)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.props.whitelistDomains !== prevProps.whitelistDomains){
            this.setState({
                whitelistDomains: this.getWhitelistDomainsArray(this.props.whitelistDomains)
            })
        }
    }

    getWhitelistDomainsArray = (whitelistDomains) => {
        return whitelistDomains.trim() !== "" ? whitelistDomains.split(",") : []
    }

    originalWhitelistDomains = () => {
        return this.getWhitelistDomainsArray(this.props.whitelistDomains)
    }

    changeDomain = (idx, value) => {
        const currDomains = JSON.parse(JSON.stringify(this.state.whitelistDomains));
        currDomains[idx] = value

        this.setState({
            whitelistDomains: currDomains
        })
    }


    addBlankWhitelistDomains = () => {
        const isBusiness = this.props.tier === "business" || this.props.tier === "enterprise";

        if (!isBusiness){
            this.props.toggleModal(null)
            this.props.modifyPaymentModalOrigin("whitelistDomains")
            this.props.toggleModal("paymentModal")
        } else {
            const currDomains = JSON.parse(JSON.stringify(this.state.whitelistDomains));

            currDomains.push("")

            this.setState({
                whitelistDomains: currDomains
            })
        }
    }

    isDomainDuplicate = (idx) => {
        const copiedWhitelistDomains = JSON.parse(JSON.stringify(this.state.whitelistDomains));
        const domainName = copiedWhitelistDomains[idx];
        copiedWhitelistDomains.splice(idx, 1)

        return copiedWhitelistDomains.includes(domainName)
    }

    getOriginalValueOfDomain = (idx, editedValue=false) => {
        try{
            return this.originalWhitelistDomains()[idx]
        } catch (e) {
            if (editedValue){
                return null
            } else{
                return ""
            }

        }
    }

    isDomainEdited = (idx, val) => {
        const originalValue  = this.getOriginalValueOfDomain(idx, true);

        if (val.trim() === "" && originalValue === undefined){
            return false
        } else {
            if (originalValue === undefined){
                return true
            } else {
                return val !== originalValue
            }
        }

    }

    undoDomain = (idx) => {
        let originalValue  = this.getOriginalValueOfDomain(idx);

        if (originalValue === undefined){
            originalValue = ""
        }

        this.changeDomain(idx, originalValue)
    }

    deleteDomainFromState = (idx) => {
        const currDomains = JSON.parse(JSON.stringify(this.state.whitelistDomains));
        currDomains.splice(idx, 1)

        this.setState({
            whitelistDomains: currDomains
        })
    }

    render() {
        return (
            <div>
                {
                    this.state.whitelistDomains.length > 0 ?
                        this.state.whitelistDomains.map((x, idx) =>
                            <div style={{marginBottom: "4px"}}>
                                <WhitelistDomainsBox domainName={x}
                                                     idx={idx}
                                                     pk={this.props.pk}
                                                     changeState={this.props.changeState}
                                                     deleteDomainFromState={this.deleteDomainFromState}
                                                     isEdited={this.isDomainEdited(idx, x)}
                                                     undoDomain={this.undoDomain}
                                                     changeDomain={this.changeDomain}
                                                     isDuplicate={this.isDomainDuplicate(idx)}
                                                     key={idx}/>
                            </div>) : null

                }
                {
                    (this.originalWhitelistDomains().length === 0 && this.state.whitelistDomains.length < 1) ||
                        this.state.whitelistDomains.length === this.originalWhitelistDomains().length  ?

                        <Button
                            // circular
                            onClick={this.addBlankWhitelistDomains}
                            icon="plus"
                            content="Add domain"
                            style={{border: "1px solid " + gunaldiStyles.blue, color: gunaldiStyles.blue, backgroundColor: "white"}}
                            // color="orange"
                            // basic
                            size="mini"
                        /> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyPaymentModalOrigin: modifyPaymentModalOrigin
}

export default connect(mapStateToProps, mapActionsToProps)(SettingsWhitelistDomains)