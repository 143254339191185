import React, {Component} from 'react'
import ModalApp from './Modal'
import Loader from 'react-loader-spinner'

class LoadingModal extends Component {
    render() {
        const modalBody =
            <div style={{padding: '100px 0', textAlign: 'center'}}>
                <Loader
                    type="TailSpin"
                    color="white"
                    height="50"
                    width="50"
                />
                <h4 className="thinHeading" style={{color: 'white'}}>{this.props.message}</h4>
            </div>;

        return (
            <div>
                <ModalApp name={this.props.name}
                          header={null}
                          body={modalBody}
                          noClose
                />
            </div>
        )
    }
}

export default LoadingModal