import React, {Component} from 'react'
import ModalApp from "../SharedComponents/Modal"
import { Icon, Label } from 'semantic-ui-react'
import {connect} from 'react-redux'

class FileIDReadMore extends Component {
    render() {
        let fileID = "1451"
        let fileHash = "EHncQevtAEYwQlsq"

        if (this.props.modalInfo !== null){
            if ("fileID" in this.props.modalInfo){
                fileID = this.props.modalInfo.fileID
            }

            if ("fileHash" in this.props.modalInfo){
                fileHash = this.props.modalInfo.fileHash
            }
        }


        return (
            <ModalApp name="fileIDReadMoreModal"
                      header={<h4><Icon name="lock" /> More Secure File ID</h4>}
                      body={<div style={{marginTop: "10px", marginBottom: "25px"}}>
                          <p style={{marginBottom: "0"}}>For files uploaded before <b>December 8, 9:30 AM UTC</b>, the File ID is a number, such as </p>
                          <Label color="orange"><b>File ID: </b>{fileID}</Label>
                          <br/><br/>
                          <p style={{marginBottom: "0"}}>For files uploaded after, the File ID is a random 16 digit alphanumeric code, such as </p>
                          <Label color="orange"><b>File ID: </b>{fileHash}</Label>
                          <br/><br/>
                          <p style={{marginBottom: "0"}}>Older files can still upgrade to this new random 16 digit code</p>
                          <p style={{marginBottom: "0"}}>If you upgrade, the older numerical ID will <b>NOT</b> work, and you <b>MUST</b> use the new 16 digit code</p>
                          <span><Icon name="cancel" style={{color: "red"}}/> <Label color="orange">{fileID}</Label></span>
                          <span style={{marginLeft: "10px"}}><Icon name="check" style={{color: "green"}}/> <Label color="orange">{fileHash}</Label></span>
                      </div>}
                      size="medium"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

export default connect(mapStateToProps, null)(FileIDReadMore)