import React, {Component} from 'react'
import CustomError from "../../Illustrations/CustomError.svg"
import {hexToRgbA} from "../../../Constants/hexToRgbA";
import FileDrop from 'react-file-drop';
import UploadIndicator from "../../../UploadFiles/Indicators/UploadIndicator";
import constants from "../../../Constants/constants";
import convertHexToRGB from "../../../Constants/convertHexToRGB";

class CustomAppearance extends Component {
    constructor(props){
        super(props);

        this.state = {
            color: "#E27837"
        }
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    render() {
        let buttonColor = "#FFFFFF"
        const rgbComponent = convertHexToRGB(this.state.color);
        if (rgbComponent !== null){
            if ((rgbComponent.r * 0.299) + (rgbComponent.g * 0.587) + (rgbComponent.b * 0.114) > 186){
                buttonColor = "#000000"
            }
        }

        return (
            <div>
                <div style={this.props.headerStyle}> Customize Importer Appearance </div>
                <div style={this.props.subheadingStyle}>Configure color, success/error messages, allowed file extensions and much more</div>
                <div style={{display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "16px", marginBottom: "12px"}}>
                    <div style={{paddingTop: "8px"}}>
                        <p className="inputLabel">Try Out Choosing Your Uploader Color</p>
                    </div>
                    <div style={{textAlign: "right"}}>
                        <input name="color"
                               type="color"
                               value={this.state.color}
                               onChange={this.handleInputChange} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="uploadOuterSection" style={{border: "2px dashed " + hexToRgbA(this.state.color), marginBottom: "0"}}>
                            <label htmlFor="inputFileUpload" style={{width: "100%"}}>
                                <FileDrop draggingOverTargetClassName="lightgray" onDrop={this.handleFileUpload}>
                                    <div style={{paddingTop: "25px", textAlign: "center"}}>
                                        <i className="bx bxs-folder-open" style={{color: hexToRgbA(this.state.color), fontSize: "80px"}}/>
                                        <div style={{fontFamily: "Lato", fontWeight: "400", color: "#9696A0", marginTop: "8px", marginBottom: "16px", fontSize: "14px"}}>
                                            Drag & Drop Files here or
                                        </div>
                                        <label htmlFor="inputFileUpload" style={{backgroundColor: hexToRgbA(this.state.color)}}>
                                            <div className="uploadLabel" style={{color: buttonColor, backgroundColor: hexToRgbA(this.state.color)}}>
                                                Browse Files
                                            </div>
                                        </label>
                                        <div style={{color: "#9696A0", textAlign: "center"}}>
                                            <small>You can upload: {constants.extensions.join(",")}</small>
                                        </div>
                                    </div>
                                </FileDrop>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default CustomAppearance