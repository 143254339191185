import React, {Component} from 'react'
import {connect} from "react-redux";
import UtilitiesCodeSnippet from "./UtilitiesCodeSnippet"
import CustomAccordion from "../SharedComponents/CustomAccordion"
import {getRequestCodeSnippet} from "./getRequestCodeSnippet";

class UtilitiesCodeAccordion extends Component {
    render() {
        let code;

        let fileURL = this.props.url

        code = {
            cURL: getRequestCodeSnippet("cURL", fileURL, this.props.body, this.props.mode),
            python: getRequestCodeSnippet("python", fileURL, this.props.body, this.props.mode),
            javascript: getRequestCodeSnippet("javascript", fileURL, this.props.body, this.props.mode),
            ruby: getRequestCodeSnippet("ruby", fileURL, this.props.body, this.props.mode),
            PHP: getRequestCodeSnippet("php", fileURL, this.props.body, this.props.mode),
        }

        return (
            <CustomAccordion
                body={<div>
                    <UtilitiesCodeSnippet code={code} height={150} />
                </div>}
                header="Code Samples"
                accordionStyle={{
                    borderRadius: '4px',
                    border: "1px solid #F0F0F0",
                    backgroundColor: 'white'
                    // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                }}
                headerDivStyle={{
                    borderRadius: '4px',
                    backgroundColor: 'white',
                    fontSize: '16px',
                    fontWeight: '700',
                    fontFamily: "Lato",
                    padding: "16px"
                }}
                open
            />
        )

    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(UtilitiesCodeAccordion)