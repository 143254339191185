import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import { Radio } from 'semantic-ui-react'

class S3BucketPrefix extends Component {
    constructor(props) {
        super(props);
    }

    isRadioChecked = (value) => {
        const destinationsEdit = JSON.parse(JSON.stringify(this.props.destinationsEdit))
        const s3AccountDetails = destinationsEdit['s3AccountDetails']
        let accountInfo = s3AccountDetails[this.props.accountName]
        let bucketNames = accountInfo['bucketNames']
        let bucketInfo = bucketNames[this.props.bucketIdx]

        return bucketInfo.prefix === value
    }

    handleChange = (e, {value}) => {
        const destinationsEdit = JSON.parse(JSON.stringify(this.props.destinationsEdit))
        const s3AccountDetails = destinationsEdit['s3AccountDetails']
        let accountInfo = s3AccountDetails[this.props.accountName]
        let bucketNames = accountInfo['bucketNames']

        let bucketInfo = bucketNames[this.props.bucketIdx]

        bucketInfo.prefix = value
        bucketNames[this.props.bucketIdx] = bucketInfo

        accountInfo['bucketNames'] = bucketNames
        s3AccountDetails[this.props.accountName] = accountInfo
        destinationsEdit['s3AccountDetails'] = s3AccountDetails

        this.props.modifyCustomImportSettings("destinationsEdit", destinationsEdit)
    }


    render() {
        if (this.props.bucketPrefixes !== null){
            const allPrefixSelections = this.props.bucketPrefixes.map((x, idx) =>
                this.props.bucketIdx === -1 ? null :
                    <div className="row">
                        <div className="col-sm-1" />
                        <div className="col-sm-11">
                            <div key={idx} style={{paddingLeft: "10px"}}>
                                <Radio label={x}
                                       value={x}
                                       checked={this.isRadioChecked(x)}
                                       onChange={this.handleChange}
                                       name={this.props.bucketName}/>
                            </div>
                        </div>
                    </div>
            )

            if (this.props.bucketIdx !== -1){
                allPrefixSelections.unshift(
                    <div className="row">
                        <div className="col-sm-1" />
                        <div className="col-sm-11">
                            <div style={{paddingLeft: "10px"}}>
                                <Radio label="/"
                                       value=""
                                       checked={this.isRadioChecked("")}
                                       onChange={this.handleChange}
                                       name={this.props.bucketName}/>
                            </div>
                        </div>
                    </div>
                )
            }

            return (
                <div>
                    {allPrefixSelections}
                </div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({
    destinations: state.mainState.customImportDestinations,
    destinationsEdit: state.mainState.customImportDestinationsEdit
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(S3BucketPrefix)