import React, {Component} from 'react'
import emailreporting from "../../Illustrations/emailreporting.svg"
import EmailSample from "./EmailSample";
import MobileEmailSample from "../../MobileLandingPage/MobileEmailSample"
import { isMobile } from 'react-device-detect'

class AutoReportingDesc extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Set up Auto Reporting</div>
                <div style={this.props.subheadingStyle}>Choose frequency to email spreadsheets or Google Sheets to your team and other stakeholders</div>
                {isMobile ? <MobileEmailSample/> : <EmailSample/>}
            </div>

        )
    }
}

export default  AutoReportingDesc