import React, {Component} from 'react'
import {Dropdown} from "semantic-ui-react";

const dataTypeOptions = [
    {key: 1, value: "api", text: "API", icon: "code"},
    {key: 2, value: "importer", text: "Importer", icon: "table"},
    {key: 3, value: "billing", text: "Billing", icon: "credit card"},
    {key: 4, value: "teams", text: "Teams", icon: "users"},
    {key: 5, value: "database", text: "Database", icon: "database"},
    {key: 6, value: "utilities", text: "Utilities", icon: "wrench"},
    {key: 7, value: "general", text: "General", icon: "cogs"},
]

class SupportTopicSelection extends Component {
    updateSupportTopic = (event, { value }) => {
        this.props.changeState({ supportTopic: value })
    }

    render() {

        return (
            <Dropdown options={dataTypeOptions}
                      onChange={this.updateSupportTopic}
                      selection
                      placeholder="Please Select Category"
                      value={this.props.supportTopic}
            />
        )
    }
}

export default SupportTopicSelection