import React, {Component} from 'react'
import CreateTeam from "./CreateTeam/CreateTeam"
import PageHeaderHelp from "../SharedComponents/PageHeaderHelp";

class TeamHeaderToolbar extends Component {
    render() {
        return (
            <div style={{ display: "grid", gridTemplateColumns: "50% 50%"}}>
                <div>
                    <CreateTeam />
                </div>
                <div>
                    <PageHeaderHelp
                        header="Need help getting started with Teams"
                        url="/api-docs/?docsMenuItem=teams"
                        desc="Read all about Teams and Roles"
                    />
                </div>
            </div>
        )
    }
}

export default TeamHeaderToolbar