import React, {Component} from 'react'
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";

class BooleanChangeDropdown extends Component {
    constructor(props) {
        super(props);
    }
    handleChange=(event, {value}) =>{
        this.props.handleBooleanChange(this.props.idx,value)
    }


    render() {
        return (
            <Dropdown
                placeholder="True or False"
                value={this.props.value}
                options={this.props.booleanOptions}
                onChange={this.handleChange}
                inline
            />

        )
    }
}

export default BooleanChangeDropdown