import React, {Component} from 'react'
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";
import MenuItem from "../../SharedComponents/Menu/MenuItem"
import { Input } from 'semantic-ui-react';

class FAQMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            faqStartPosition: 200
        }

        this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    }

    componentDidMount(){
        this.setState({
            faqStartPosition: this.getFAQStartPosition()
        })
    }

    getFAQStartPosition = () => {
        let e = document.getElementById("faqDisplayHeader");
        let top = 0;

        do {
            top += e.offsetTop;
        } while (e = e.offsetParent);

        return top;
    }

    changeSearchText = (e) => this.props.modifySearchText(e.target.value)

    isElementInViewport = () => {
        const element = document.getElementById("faqDisplayHeader");

        let rect = element.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }

    handleMenuItemClick(e, name, idx){
        this.props.changeMenuItem(name, idx)
        this.props.modifySearchText("")

        if (!this.isElementInViewport()){
            window.scrollTo({
                top: this.state.faqStartPosition,
                behavior: 'smooth'
            })
        }
        console.log(this.isElementInViewport())
    }

    render() {
        const menuItems = this.props.helpInfo.map((x, idx) =>
            <MenuItem
                idx={idx}
                key={idx.toString()}
                icon={'icon' in x.info ? x.info.icon : null}
                name={x.info.name}
                menuItem={this.props.menuItem}
                label={x.info.name === "api" ? x.info.name.toUpperCase() : capitalizeHyphenated(x.info.name)}
                onClick={this.handleMenuItemClick}
            />
        )

        return (
            <div className="menuDiv">
                <Input icon='search'
                       placeholder='Search...'
                       fluid
                       value={this.props.searchText}
                       onChange={this.changeSearchText}
                />
                {menuItems}
            </div>
        )
    }
}

export default FAQMenu