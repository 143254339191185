import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import FileDescriptionModalBody from './FileDescriptionModalBody'

class FileDescriptionModal extends Component {
    render() {
        return (
            <ModalApp name="fileDescriptionModal"
                      header={<p>File Description</p>}
                      size="small"
                      body={<FileDescriptionModalBody/>}
            />
        )
    }
}

export default FileDescriptionModal