import React, {Component} from 'react'
import {connect} from "react-redux";
import Pricing from "../../Constants/Pricing/Pricing";
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";
import {getPricingText} from "../../Constants/getPricingText";
import { Icon } from 'semantic-ui-react'
import PlanPricingBoxFeature from "./PlanPricingBoxFeature";
import StartPlanButton from "./StartPlanButton";
import {getPricingInfoObject} from "../../Constants/getPricingInfoObject";
import PlanName from "./PricingBoxHeader/PlanName";
import PlanDesc from "./PricingBoxHeader/PlanDesc";
import PlanPricing from "./PricingBoxHeader/PlanPricing";
import PlanCTA from "./PricingBoxHeader/PlanCTA";
import { isMobile } from 'react-device-detect'
import { featureList } from "../../Constants/Pricing/featureList";
import SelectPlanBtn from "./SelectPlanBtn"

const boxShadowValues = [
    "inset 1px 0px 0px #F0F0F0, inset 0px -1px 0px #F2F2F2",
    "inset 1px 0px 0px #F0F0F0, inset 0px -1px 0px #F2F2F2",
    "inset 1px 0px 0px rgba(240, 240, 240, 0.2), inset 0px -1px 0px rgba(242, 242, 242, 0.2)",
    "inset 1px 0px 0px rgba(240, 240, 240, 0.2), inset 0px -1px 0px rgba(242, 242, 242, 0.2)"
]

class PlanPricingBox extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        if ('changeHeaderBoxHeight' in this.props){
            const height = this.planPricingBoxHeaderDivRef.clientHeight;
            this.props.changeHeaderBoxHeight(height)
        }
        // this.setState({ height });
    }

    getSignUpLink = (planIdx) => {
        let baseURL = "/signup"

        if (planIdx > 0){
            baseURL += "?pricing=1"
        }

        return baseURL
    }

    getSignUpText = (planIdx) => {
        if (planIdx === 0){ return "Sign Up Free"} else { return "Get Started"}
    }

    render() {
        const planIdx = this.props.planIdx;

        const isNotPricingPage = 'profilePage' in this.props || isMobile || 'paymentModal' in this.props;

        let pricing;
        if ('profilePage' in this.props){
            pricing = getPricingInfoObject(this.props.tier, this.props.appsumoPlanName, this.props.isGrandfather)
            console.log(pricing)
        } else {
            pricing = Pricing[this.props.planIdx]
        }

        const featureSegments = !('planSelected' in this.props) ? this.props.featureList.map((x, idx) => <PlanPricingBoxFeature pricing={pricing}
                                                                                   info={x}
                                                                                   key={idx}
                                                                                   lastItem={idx === this.props.featureList.length - 1}
                                                                                   planIdx={planIdx}
                                                                                   isProfilePage={'profilePage' in this.props}
                                                                                   isNotPricingPage={isNotPricingPage}/>) : null

        return (
            <div style={{backgroundColor: this.props.backgroundColor}}>
                <div style={{borderBottom: "1px solid #F0F0F0"}}> </div>
                <div style={{padding: "16px", boxShadow: boxShadowValues[planIdx], borderRight: isNotPricingPage ? "1px solid #F0F0F0" : null}}
                     id="planPricingBoxHeaderDiv"
                     ref={ (planPricingBoxHeaderDivRef) => { this.planPricingBoxHeaderDivRef = planPricingBoxHeaderDivRef } }>
                    {
                        isNotPricingPage ?
                            <div style={{ display: "grid", gridTemplateColumns: "50% 50%"}}>
                                <div style={{textAlign: "left"}}>
                                    <div style={{marginBottom: '4px'}}>
                                        <PlanName
                                            isNotPricingPage={isNotPricingPage}
                                            planIdx={planIdx}
                                            pricingName={pricing.name}
                                        />
                                    </div>
                                    <div>
                                        <PlanDesc
                                            isNotPricingPage={isNotPricingPage}
                                            desc={pricing.desc}
                                            planIdx={planIdx}
                                        />
                                    </div>
                                </div>
                                <div style={{textAlign: "right"}}>
                                    {'profilePage' in this.props ? null :
                                        <PlanPricing
                                            isNotPricingPage={isNotPricingPage}
                                            pricingText={getPricingText(this.props.currency, planIdx, false, pricing)}
                                            currency={this.props.currency}
                                            planIdx={planIdx}
                                            yearlyPricing={this.props.yearlyPricing}
                                        />
                                    }
                                </div>
                            </div> :
                            <div>
                                <div style={{marginBottom: '4px'}}>
                                    <PlanName
                                        isNotPricingPage={isNotPricingPage}
                                        planIdx={planIdx}
                                        pricingName={pricing.name}
                                    />
                                </div>
                                <div style={{marginBottom: '4px'}}>
                                    <PlanDesc
                                        isNotPricingPage={isNotPricingPage}
                                        desc={pricing.desc}
                                        planIdx={planIdx}
                                    />
                                </div>
                                <div style={{marginBottom: "8px"}}>
                                    <PlanPricing
                                        isNotPricingPage={isNotPricingPage}
                                        pricingText={getPricingText(this.props.currency, planIdx, false, pricing)}
                                        planIdx={planIdx}
                                        yearlyPricing={this.props.yearlyPricing}
                                    />
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <PlanCTA
                                        planIdx={planIdx}
                                        getSignUpLink={this.getSignUpLink}
                                        getSignUpText={this.getSignUpText}
                                    />
                                </div>
                            </div>
                    }
                </div>
                {featureSegments}
                {
                    'profilePage' in this.props || 'planSelected' in this.props ? null :
                        <PlanPricingBoxFeature planIdx={planIdx}
                                               isNotPricingPage={isNotPricingPage}
                                               pricing={pricing}
                                               content={'selectPlanModal' in this.props ?
                                                   <SelectPlanBtn
                                                        selectPlan={this.props.selectPlan}
                                                        backgroundColor={planIdx === 2 || planIdx === 3 ? "white" : "#5499F8"}
                                                        color={planIdx === 2 ? "#6FBB98" : planIdx === 3 ? "#5499F8" : "white"}
                                                        planIdx={planIdx}
                                                   /> :
                                                   <StartPlanButton
                                                       url={this.getSignUpLink(planIdx)}
                                                       backgroundColor={planIdx === 2 || planIdx === 3 ? "white" : "#5499F8"}
                                                       color={planIdx === 2 ? "#6FBB98" : planIdx === 3 ? "#5499F8" : "white"}
                                                       text={planIdx === 0 ? "Sign Up Free" : "Get Started"}
                                                   />}
                        />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency,
    yearlyPricing: state.mainState.yearlyPricing,
    tier: state.mainState.tier,
    appsumoPlanName: state.mainState.appsumoPlanName,
    isGrandfather: state.mainState.isGrandfather
})

export default connect(mapStateToProps, null)(PlanPricingBox)