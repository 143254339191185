import React, {Component} from 'react'
import {gunaldiStyles} from "../../../SharedComponents/GunaldiDesignStyles";
import CNAMEDomainError from "./CNAMEDomainError";

class CNAMEDomain extends Component {
    onChange = (e) => {
        this.props.changeState({ [e.target.name]: e.target.value }, e.target.name)
    }

    render() {
        return (
            <div>
                <div style={{margin: "0 0 6px 0"}}>
                    <div style={{fontFamily: "Lato", fontSize: '14px', color: gunaldiStyles.mainText}}>Domain Name</div>
                    <div style={{color: gunaldiStyles.subText}}><small>Enter domain in these formats: <b>www.example.io</b>, <b>example.com</b>, <b>*.cool.co.in</b></small></div>
                </div>
                <input className="inputGray"
                       onChange={this.onChange}
                       name={this.props.name}
                       style={{minWidth: "50%"}}
                       value={this.props.domainName}
                />
                <CNAMEDomainError
                    error={this.props.error}
                    errorMsg={this.props.errorMsg}
                />
            </div>
        )
    }
}

export default CNAMEDomain