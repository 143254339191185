const oldPricing = [
    {
        rowMax: 1500,
        requests: 50,
        files: 3,
        team: 0,
        secure: false,
        price: 0,
        name: 'free',
        desc: 'hobbyists',
        support: 'FAQ',
        blockRequests: false,
        alerts: false,
        multipleKeys: false,
        domainWhitelist: false,
        autoEmail: false,
        webhooks: false,
        database: false,
        customizeImporter: false,
        cachingLayer: false,
        connectDomains: false,
        addlProducts: true,
        requestsFrequency:"daily",
        sqlQuery: true
    },
    {
        rowMax: 10000,
        requests: 5000,
        files: 25,
        team: 0,
        secure: true,
        price: 10,
        name: 'pro',
        desc: 'professionals',
        support: 'Email',
        blockRequests: false,
        alerts: false,
        multipleKeys: false,
        domainWhitelist: false,
        autoEmail: false,
        webhooks: true,
        database: false,
        customizeImporter: true,
        cachingLayer: false,
        connectDomains: false,
        addlProducts: true,
        requestsFrequency:"daily",
        sqlQuery: true
    },
    {
        rowMax: 50000,
        requests: 50000,
        files: 100,
        team: 5,
        secure: true,
        price: 25,
        name: 'team',
        desc: 'dev-teams',
        support: 'Priority',
        blockRequests: true,
        alerts: true,
        multipleKeys: false,
        domainWhitelist: false,
        autoEmail: true,
        webhooks: true,
        database: false,
        customizeImporter: true,
        cachingLayer: false,
        connectDomains: false,
        addlProducts: true,
        requestsFrequency:"daily",
        sqlQuery: true
    },
    {
        rowMax: 500000,
        requests: 150000,
        files: 500,
        team: 10,
        secure: true,
        price: 50,
        name: 'business',
        desc: 'agency-&-business',
        support: 'Dedicated',
        blockRequests: true,
        alerts: true,
        multipleKeys: true,
        domainWhitelist: true,
        autoEmail: true,
        webhooks: true,
        database: true,
        customizeImporter: true,
        cachingLayer: true,
        connectDomains: true,
        addlProducts: true,
        requestsFrequency:"daily",
        sqlQuery: true
    },
    {
        rowMax: 500000,
        requests: 150000,
        files: 500,
        team: 10,
        secure: true,
        price: 999,
        name: 'business',
        desc: 'agency-&-business',
        support: 'Dedicated',
        blockRequests: true,
        alerts: true,
        multipleKeys: true,
        domainWhitelist: true,
        autoEmail: true,
        webhooks: true,
        database: true,
        customizeImporter: true,
        cachingLayer: true,
        connectDomains: true,
        addlProducts: true,
        requestsFrequency:"daily",
        sqlQuery: true
    }
];

export default oldPricing