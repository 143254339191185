import React, {Component} from 'react'

class UploadMenuItem extends Component {
    constructor(props) {
        super(props);
    }

    changeMenuItem = () => {
        this.props.changeMenuItem(this.props.name)
    }

    render() {
        const isSelected = this.props.menuItem === this.props.name;

        return (
            <div style={{padding: "13px 24px 13px 16px",
                width: "180px",
                height: "50px",
                cursor: "pointer",
                textAlign: "center",
                backgroundColor: isSelected ? "#FEFFFE" : "#F4FBF3",
                color: isSelected ? "#161E16" : "#9696A0"}}
                 onClick={this.changeMenuItem}
            >
                <i className={this.props.icon} style={{ color: isSelected ? "#6FBB98" : "#9696A0", fontSize: "18px", verticalAlign: "middle", marginTop: "-2px" }} />
                <span style={{paddingLeft: "8px", fontWeight: "600", fontFamily: "Lato", fontSize: "14px"}}>{this.props.label}</span>
            </div>
        )
    }
}

export default UploadMenuItem