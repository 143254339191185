import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../../../../Constants/API_Root";
import Loader from 'react-loader-spinner'
import isObjectEmpty from "../../../../Constants/isObjectEmpty";
import TableAccordion from "../../../../Database/Modals/TableAccordion";
import { Message, Icon, Table } from 'semantic-ui-react'
import DatabaseTableCheckbox from "./DatabaseTableCheckbox"

class DatabaseTablesSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: false,
            tablesAndColumnInfo: {}
        }
    }

    componentDidMount(){
        this.setState({ fetching: true })

        const error = () => this.setState({ fetching: false, tablesAndColumnInfo: null })

        fetch(API_Root + "api/get-api-database-tables-and-column-info/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                pk: parseInt(this.props.databasePK)
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.setState({
                        fetching: false,
                        tablesAndColumnInfo: data.tablesAndColumnInfo
                    })
                }).catch(err => error())
            } else{
                error()
            }
        }).catch(err => error())

    }


    render() {
        if (this.state.fetching){
            return (
                <div>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="25"
                        width="25"
                    />
                    <h4>Getting your database tables...</h4>
                </div>
            )
        } else{
            if (this.state.tablesAndColumnInfo === null){
                return (
                    <div>
                        <p style={{color: "red"}}>There was an error getting your tables. Please try again</p>
                    </div>
                )
            } else {
                let tableBody = null;

                if(!isObjectEmpty(this.state.tablesAndColumnInfo)){
                    let tableAccordion = [];
                    for (let tableName in this.state.tablesAndColumnInfo){
                        tableAccordion.push(
                            <Table.Row key={tableName}>
                                <Table.Cell>
                                    <div style={{textAlign: "center"}}>
                                    <DatabaseTableCheckbox databasePK={this.props.databasePK}
                                                           databaseType={this.props.databaseType}
                                                           tableName={tableName}/>
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    <TableAccordion headerDivStyle={{fontSize: "12px"}}
                                                    tableName={tableName}
                                                    columnInfo={this.state.tablesAndColumnInfo[tableName]}/>
                                </Table.Cell>
                            </Table.Row>)
                    }

                    tableBody =
                    <div>
                        <div style={{ padding: "10px" }}>
                            <p className="thinHeading" style={{marginBottom: "10px"}}><Icon name="columns" /> Select Table(s) to Add Spreadsheet Data</p>
                            <Table basic='very' compact celled definition>
                                <Table.Header>
                                    <Table.HeaderCell width={2}/>
                                    <Table.HeaderCell> Table Name </Table.HeaderCell>
                                </Table.Header>
                                <Table.Body>
                                    {tableAccordion}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                } else{
                    tableBody = <div>
                        <Message
                            header="There weren't any tables in your database"
                            content="If you think this is in error, please contact support@dbspreadsheets.com"
                            icon="database"
                            negative
                        />
                    </div>

                }

                return (
                    <div style={{border: "1px solid lightgrey", borderRadius: "5px"}}>
                        {tableBody}
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({
    destinations: state.mainState.customImportDestinations
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DatabaseTablesSelection)