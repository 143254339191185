import React, {Component} from 'react'
import {connect} from 'react-redux'
import Loader from 'react-loader-spinner'
import API_Root from "../../../../Constants/API_Root";
import S3BucketRow from "./S3BucketRow"

class S3BucketsSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: true,
            bucketNames: null
        }
    }

    componentDidMount(){
        const error = () => this.setState({ fetching: false, bucketNames: null})

        fetch(API_Root + "api/get-all-s3-buckets-for-api-user/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                accountName: this.props.accountName
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.setState({
                        fetching: false,
                        bucketNames: data.bucketNames
                    })
                }).catch(err => error())
            } else{
                error()
            }
        }).catch(err => error())
    }

    render() {
        if (this.state.fetching){
            return (
                <div>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="25"
                        width="25"
                    />
                    <h4>Getting buckets for your account...</h4>
                </div>
            )
        } else {
            if (this.state.bucketNames === null){
                return (
                    <div>
                        <p style={{color: "red"}}>There was an error getting your buckets. Please try again</p>
                    </div>
                )
            } else{
                return (
                    <div style={{paddingTop: "10px"}}>
                        <b>Select buckets to upload files to</b>
                        {this.state.bucketNames.map((x, idx) => <S3BucketRow key={idx}
                                                                             idx={idx}
                                                                             accountName={this.props.accountName}
                                                                             bucketName={x} />)}
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(S3BucketsSelection)