import React, {Component} from 'react'
import ModalApp from '../Modal'
import CompletePaymentForm from './CompletePaymentForm';
import {gunaldiStyles} from "../GunaldiDesignStyles";

class PaymentModal extends Component {
    render() {
        let modalBody = null;

        if (localStorage.getItem("email") !== null && localStorage.getItem("token") !== null){
            modalBody = <CompletePaymentForm/>;
        }

        return (
            <div>
                <ModalApp name="paymentModal"
                          header={<span style={{fontSize: '20px', color: gunaldiStyles.mainText, fontWeight: "700", paddingBottom: "16px"}}>Upgrade your plan</span>}
                          body={modalBody}
                          size="full"
                          bodyColor="#F9F9F9"
                />
            </div>
        )
    }
}


export default PaymentModal