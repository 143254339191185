import React, {Component} from 'react'
import { Input, Dropdown, Icon } from 'semantic-ui-react'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

const options = [{key: 1, value: "manager", text: "Manager"},  {key: 2, value: "developer", text: "Developer"}]

class InviteRow extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.addOrRemoveInvite = this.addOrRemoveInvite.bind(this)
    }

    onChange(e){
        this.props.updateTeamInvites(this.props.idx, e.target.name, e.target.value, false)
    }

    handleChange(e, { value }){
        this.props.updateTeamInvites(this.props.idx, "role", value, false)
    }

    clearSelection(e){
        this.props.updateTeamInvites(this.props.idx, "email", "", true)
    }

    addOrRemoveInvite(e, add){
        this.props.addOrRemoveInvite(this.props.idx, add)
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-7">
                    <input className="inputGray" style={{width: "100%"}} onChange={this.onChange} name="email" value={this.props.value.email} />
                </div>
                <div className="col-sm-3">
                    <Dropdown
                        style={gunaldiStyles.dropdown}
                        onChange={this.handleChange}
                        options={options}
                        placeholder='Role'
                        selection
                        value={this.props.value.role}
                        fluid
                    />
                </div>
                <div className="col-sm-2">
                    <div style={{display: 'grid', gridTemplateColumns: "33% 33% 34%"}}>
                        {this.props.value.email.trim() !== "" || this.props.value.role.trim() !== "" ? <div><Icon name="undo" style={{color: 'orange', cursor: 'pointer', padding: '5px'}} onClick={this.clearSelection} /></div> : null}
                        <div><Icon name="remove" style={{color: 'red', cursor: 'pointer', padding: '5px'}} onClick={(e) => this.addOrRemoveInvite(e, false)} /></div>
                        <div><Icon name="plus circle" style={{color: 'blue', cursor: 'pointer', padding: '5px'}} onClick={(e) => this.addOrRemoveInvite(e, true)} /></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InviteRow