import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
// import { Image } from 'semantic-ui-react';
import SignUpForm from "../../Authentication/UserPages/SignUp/SignUpForm"
// import images from "../../Constants/Images";

class NeedAuthModal extends Component {
    render() {
        const body =
            <div className="needAuthModalBody">
                <br/>
                <div className="row">
                    <div className="col-sm-1" />
                    <div className="col-sm-10">
                        {/*<div style={{marginLeft: '1rem'}} className='segment-margin segment-raised'>*/}
                            {/*<Image style={{marginTop: '5px'}} src={images.logoNav} href="/" size="medium"/>*/}
                        {/*</div>*/}
                        <SignUpForm modalForm importer={false}/>
                        <div className="segment-margin segment-raised">
                            <p style={{textAlign: 'center', fontSize: '120%'}}><b>Already Have An Account?</b> <a href="/login">Log In</a></p>
                        </div>
                        {/*<Segment>*/}
                            {/*<div className="startModalRow">*/}
                                {/*<NavbarLogIn/>*/}
                                {/*<span style={{marginLeft: '10px', marginRight: '10px'}}>OR</span>*/}
                                {/*<NavbarSignUp/>*/}
                                {/*<span>to get started <span className="emoji" role="img" aria-label="smiley-emoji">😊</span></span>*/}
                            {/*</div>*/}
                        {/*</Segment>*/}
                    </div>
                    <div className="col-sm-1" />
                </div>
                <br/>
                <br/>
            </div>

        return (
            <ModalApp
                name="needAuthModal"
                header={null}
                body={body}
                size="medium"
            />
        )
    }
}


export default NeedAuthModal