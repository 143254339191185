import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import { Button } from 'semantic-ui-react'
import {isURLValid} from "../../../../Constants/isURLValid";
import getURLParam from "../../../../Constants/getURLParam";
import {isEmailValid} from "../../../../Constants/isEmailValid";
import API_Root from "../../../../Constants/API_Root";

class SaveActionsBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false
        }
    }

    anyInputErrors = () => {
        let anyErrors = false;
        if (this.props.optionsEdit.webhookURL.trim() !== ""){
            if (!isURLValid(this.props.optionsEdit.webhookURL.trim())){
                anyErrors = true
            }
        }



        return anyErrors
    }

    save = () => {
        this.setState({saving: true});

        const error = () => {
            this.setState({saving: false})
            alert("There was an error saving your actions. Please try again. Contact our support if this persists")
        }

        const splitParam = getURLParam(window.location.href).split("_")
        const importKey = splitParam[0];

        const options = this.cleanOptions(JSON.parse(JSON.stringify(this.props.optionsEdit)))

        fetch(API_Root + "api/update-import-details/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                importHash: importKey,
                changeObject: {importOptions: options}
            })
        }).then((res) => {
            if (res.status === 200) {
                this.updateActionsInRedux()
            }
            else {
                error()
            }
        }).catch(err => error())

    }

    cleanOptions = (optionsCopyObj) => {
        let cleanedOptions = {};

        for (let key in optionsCopyObj){
            const value = optionsCopyObj[key]
            if (key === "webhookURL"){
                cleanedOptions[key] = value.trim()
            } else if (key === "alertEmails") {
                if (value.length > 0){
                    let cleanedEmails = []
                    for (let i=0; i<value.length; i++){
                        if (isEmailValid(value[i])){
                            cleanedEmails.push(value[i].trim())
                        }
                    }

                    cleanedOptions[key] = cleanedEmails
                }
            } else if (key === "passwords"){
                if (value.length > 0){
                    let cleanedPasswords = [];
                    for (let j=0; j<value.length; j++){
                        const passwordInfo = value[j];

                        let username = ""
                        if ("username" in passwordInfo){
                            username = passwordInfo.username.trim()
                        }

                        let password = ""
                        if ("password" in passwordInfo){
                            password = passwordInfo.password.trim()
                        }

                        if (username !== "" && password !== ""){
                            passwordInfo.username = passwordInfo.username.trim()
                            passwordInfo.password = passwordInfo.password.trim()
                        }

                        cleanedPasswords.push(passwordInfo)
                    }

                    cleanedOptions[key] = cleanedPasswords
                }
            } else {
                cleanedOptions[key] = value
            }
        }

        return cleanedOptions
    }

    updateActionsInRedux = () => {
        const options = JSON.parse(JSON.stringify(this.props.options))

        for (let key in this.props.optionsEdit){
            options[key] = this.props.optionsEdit[key]
        }

        this.props.modifyCustomImportSettings("options", options)

        this.setState({ saving: false })
    }

    render() {
        const canSave = this.props.hasInfoChanged  && !this.anyInputErrors()

        return (
            <div>
                <Button
                    loading={this.state.saving}
                    content="Save Actions"
                    icon="save alternate outline"
                    color="green"
                    onClick={this.save}
                    disabled={!canSave}
                    fluid
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    options: state.mainState.customImportOptions,
    optionsEdit: state.mainState.customImportOptionsEdit
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(SaveActionsBtn)