import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Checkbox } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";

class AppearanceToggle extends Component {
    toggleAppearanceSettings = () => {
        const editCopy = JSON.parse(JSON.stringify(this.props.customImportAppearanceEdit))
        const currValue = editCopy[this.props.name]

        editCopy[this.props.name] = !currValue

        this.props.modifyCustomImportSettings("appearanceEdit", editCopy)
    }

    render() {
        const name = this.props.name;
        let disabled = false;

        if (this.props.tier === "free" && name === "removeBranding"){
            disabled = true;
        }

        const labelID = JSON.stringify(this.props.name).replace(" ", "").toLowerCase() + "AppearanceToggleLabel"

        return (
            <div style={{display: "grid", gridTemplateColumns: "65% 35%"}}>
                <div>
                    <label htmlFor={labelID} style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", fontColor: "#161E16"}}>{this.props.labelText}</label> {'tierLabel' in this.props ? <span style={{marginLeft: "8px"}}>{this.props.tierLabel}</span> : null }
                </div>
                <div style={{textAlign: "right"}}>
                    <Checkbox toggle
                              id={labelID}
                              disabled={disabled}
                              checked={this.props.customImportAppearanceEdit[name]}
                              onChange={this.toggleAppearanceSettings}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier,
    customImportAppearanceEdit: state.mainState.customImportAppearanceEdit
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(AppearanceToggle)