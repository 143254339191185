import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon, Input } from 'semantic-ui-react'
import {isEmailValid} from "../../Constants/isEmailValid";
import DisplayHeader from "../FilesID/DisplayHeader"

class ReportingEmails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addEmail: ''
        }
    }

    removeEmail = (email) => {
        const allEmails = JSON.parse(JSON.stringify(this.props.emails));
        allEmails.splice(allEmails.indexOf(email), 1)

        this.props.changeState("emails", allEmails)
    }

    addEmail = (e, email) => {
        if (isEmailValid(email)){
            const allEmails = JSON.parse(JSON.stringify(this.props.emails));
            allEmails.push(email)

            this.props.changeState("emails", allEmails)

            this.setState({ addEmail: ""})
        }
    }

    handleInputChange = (e) => {
        this.setState({ addEmail: e.target.value })
    }

    render() {
        return (
            <div>
                <div style={{marginBottom: "8px"}}>
                    <DisplayHeader header="Send To"/>
                </div>
                {this.props.emails.length > 0 ?
                    this.props.emails.map((x, idx) =>
                        <div key={idx} style={{display: "grid", gridTemplateColumns: "1fr auto", columnGap: "16px"}}>
                            <div>
                                <span style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#5E645E"}}>{x}</span>
                            </div>
                            <div>
                                <Icon name="trash alternate outline" style={{color: 'red', cursor: 'pointer'}} onClick={(e) => this.removeEmail(e, x)}/>
                            </div>
                        </div>) :
                    <p style={{color: "red", marginBottom: "0px"}}>You haven't added any emails yet</p>
                }
                <br/>
                <div>
                    <div style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#5E645E"}}>Add Email</div>
                    <div>
                        <input value={this.state.addEmail}
                               className="inputGray"
                               style={{width: "100%"}}
                               placeholder="Enter Email"
                               onChange={this.handleInputChange} />
                        {this.state.addEmail.trim() !== "" && !isEmailValid(this.state.addEmail) ? <small style={{color: 'red', display: "block"}}>Enter a valid email</small> : null}
                    </div>
                    <div style={{marginTop: "16px"}}>
                        <span style={{backgroundColor: "#5499F8", padding: "6px 2px 6px 6px", borderRadius: "4px", cursor: 'pointer', textAlign: "center"}} onClick={(e) => this.addEmail(e, this.state.addEmail)}>
                            <Icon name="plus" style={{color: 'white', fontSize: "14px", textAlign: "center"}} />
                        </span>
                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ReportingEmails)