export const errorCodes = {
    "405": {
        "all": ["Only POST requests are allowed for this API"]
    },
    "404": {
        "all": ["Your URL is invalid. Check your token (aka the long alphanumeric string) and make sure it matches what is in your www.apispreadsheets.com/utilities dashboard"]
    },
    "402": {
        "ai": ["Your plan does not allow access to this API. Please upgrade to a higher payment plan"],
        "all": ["You are over your API requests limit. Please upgrade to a higher payment plan", "Your data is 2000 rows. Your rows per file limit is 1200. Please upgrade to a higher payment plan"]
    },
    "401": {
        "all": ["Either your 'accessKey' parameter and/or 'secretKey' parameter is missing. Or the keys are invalid. Or you have set up domain whitelist and you are requesting from another domain."]
    },
    "400": {
        "all": ["Missing 'data' parameter in request", "Your 'data' is not in a valid format. Please visit www.apispreadsheets.com/utilities to see examples of proper data formats", "For the 'matrix' data format, you need to include 'columnNames' parameter. Please visit www.apispreadsheets.com/utilities to see examples of proper data formats", "Your 'data' is empty. Please visit www.apispreadsheets.com/utilities to see examples of proper data formats"],
        "ai": ["Missing 'query' parameter in request. Query is the natural language string you wish to ask the data", "Query must be in a string format"]
    },
    "422": {
        'all': [],
        "ai": ["There was an error asking your question. This is most likely due to your query or your data not having identifiable column names. Try formatting the query in a different way."]
    },
    "500": {
        "create": ["There was an error creating your file. This maybe due to your data being corrupted or maybe on our end. Try again, and contact support through your dashboard if the error persists."],
        "download": ['There was an error creating the download link for your file. This maybe due to your data being corrupted or maybe on our end. Try again, and contact support through your dashboard if the error persists.'],
        "all": ["An unknown error occurred on our end. Please try again. Contact support from your dashboard if this error persists"]
    },
}