import React, {Component} from 'react'
import urlIcon from "../../Illustrations/urlIcon.svg"
import { Segment } from 'semantic-ui-react'

class PreURL extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Send Personalized URL </div>
                <div style={this.props.subheadingStyle}>Users can upload their files on a URL that is personalized to your importer</div>
                <div style={this.props.subheadingStyle}>You can even password protect this on paid plans!</div>
                <img src={urlIcon} alt="Personalized URL for Importing"/>
                <br/><br/>
                <Segment>
                    <p style={{fontSize: "20px"}} className="inputLabel"><u>Try out a live upload URL!</u></p>
                    <p style={{marginBottom: "0px"}}><b>username:</b> api_spreadsheets_test</p>
                    <p><b>password:</b> api_spreadsheets_test</p>
                    <a href="https://apispreadsheets.com/import/upload/3d7612f8aa463f8c15279beb5c46ae65" target="_blank" rel="noopener noreferrer">
                        https://apispreadsheets.com/import/upload/3d7612f8aa463f8c15279beb5c46ae65
                    </a>
                </Segment>
            </div>
        )
    }
}

export default PreURL