export const featureList = [
    {key: "rowMax", label: "Rows per File"},
    {key: "files", label: "Files"},
    {key: "requests", label: "Requests per Month"},
    {key: "team", label: "Team Members"},
    {key: "ai", label: "AI Query API"},
    {key: "addlProducts", label: "Importer Included"},
    {key: "secure", label: "Private"},
    {key: "webhooks", label: "Webhooks"},
    // {key: "customizeImporter", label: "Customize Importer"},
    {key: "autoEmail", label: "Auto Reporting"},
    // {key: "alerts", label: "API Alerts"},
    {key: "blockRequests", label: "Block Requests"},
    {key: "database", label: "Database Connection"},
    {key: "connectDomains", label: "Connect Your Domain"},
    {key: "multipleKeys", label: "Multiple Keys"},
    {key: "domainWhitelist", label: "Domain Whitelist"},
    // {key: "cachingLayer", label: "Caching Layer"},
    {key: "support", label: "Support Level"},
];