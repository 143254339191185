import React, { Component } from 'react';
import { connect } from 'react-redux';
import CopyURLSegment from "../SharedComponents/CopyURLSegment";
import API_Root from "../Constants/API_Root";

class CalculatorDisplayURL extends Component {
    constructor(props) {
        super(props);

    }

    render(){
        let url = window.location.href.includes("localhost") ?
            "http://localhost:5001/" : "https://www.apispreadsheets.com/"

        url += "calculator/" + this.props.displayFileHash + "/"

        return (
            <div>
                <CopyURLSegment
                    url={url}
                    header={<span>Calculator URL</span>}
                />
            </div>
        )
    }
}

export default CalculatorDisplayURL;