export const isFileInLazyMode = (fileInfo) => {
    let inputType;

    try{
        inputType = fileInfo['inputType']
    } catch (e) { inputType = null}

    if (inputType === "googleSheets"){
        try{
            const key = 'isLazyMode'
            if (key in fileInfo){
                console.log(fileInfo)
                return fileInfo[key]
            } else {
                console.log("not here")
                return false
            }
        } catch (e){
            return false
        }
    } else {
        return false
    }


}