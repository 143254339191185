import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import ModalApp from '../SharedComponents/Modal'


class DatabaseModal extends Component {
    render() {
        return (
            <ModalApp name="database"
                      header={<h2> <Icon name='video'/>Demo Video</h2>}
                      body={<div >
                          <br/> <br/>
                         <p> Test </p>

                      </div>}
                      size="large"
            />
        )
    }
}

export default DatabaseModal