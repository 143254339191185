import React, { Component } from 'react';
import AceEditor from 'react-ace';
import 'brace/mode/javascript';
import CommentSection from "../CommentSection";

class ReactPost extends Component {
    render() {
        const startCode = 'import React, { Component } from \'react\';\n' +
            'import \'./App.css\';\n' +
            'import FilmCard from \'./FilmCard\';\n' +
            '\n' +
            'class App extends Component {\n' +
            '  constructor(props) {\n' +
            '    super(props);\n' +
            '\n' +
            '    this.state = {\n' +
            '      filmInfo: [],\n' +
            '      fetching: false,\n' +
            '      error: false\n' +
            '    }\n' +
            '  }\n' +
            '\n' +
            '  componentDidMount() {\n' +
            '    this.setState({ fetching: true })\n' +
            '\n' +
            '    fetch("https://api.apispreadsheets.com/data/568/").then(res=>{\n' +
            '      if (res.status === 200){\n' +
            '        // SUCCESS\n' +
            '        res.json().then(data => {\n' +
            '          const yourData = data.data\n' +
            '          this.setState(\n' +
            '              {\n' +
            '                filmInfo: yourData,\n' +
            '                fetching: false\n' +
            '              }\n' +
            '          )\n' +
            '        }).catch(err => this.setState({ error: true, fetching: false }))\n' +
            '      }\n' +
            '      else{\n' +
            '        this.setState({ error: true, fetching: false })\n' +
            '        // ERROR\n' +
            '      }\n' +
            '    }).catch(err => this.setState({ error: true, fetching: false }))\n' +
            '  }\n' +
            '\n' +
            '  render() {\n' +
            '    let body;\n' +
            '    if(this.state.filmInfo.length===0 && !this.state.fetching && !this.state.error){\n' +
            '            body = <h1> ... </h1>\n' +
            '    } else {\n' +
            '        if(this.state.fetching){\n' +
            '            body = <h1> ... </h1>\n' +
            '        }\n' +
            '        else{\n' +
            '            if(this.state.error){\n' +
            '                body = <h1> There was an error getting your request</h1>\n' +
            '            }\n' +
            '            else{\n' +
            '                body = this.state.filmInfo.map(x => <FilmCard title={x.title}\n' +
            '                                                                          awards_won={x.awards_won}\n' +
            '                                                                          rotten_tomato_score={x.rotten_tomato_score}\n' +
            '                                                                          imdb_score={x.imdb_score}\n' +
            '                                                                          revenue={x.revenue}\n' +
            '                                                                          genre={x.genre}\n' +
            '                                                                          picture={x.picture}\n' +
            '                                                                          film_url={x.film_url}\n' +
            '                                                                          year={x.year}/>\n' +
            '                                                                          )\n' +
            '\n' +
            '            }\n' +
            '        }\n' +
            '\n' +
            '    }\n' +
            '\n' +
            '    return (\n' +
            '       <div>\n' +
            '           <h1> Award Winning Movies </h1>\n' +
            '           {body}\n' +
            '       </div>\n' +
            '    )\n' +
            '  }\n' +
            '}\n' +
            '\n' +
            'export default App;'

            const secondCode='import React, { Component } from "react";\n' +
            'import "./App.css";\n' +
            'import FilmCard from "./FilmCard";\n\n'
            const thirdCode='class App extends Component {'
            const forthCode='  constructor(props) {\n' +
                '    super(props);\n' +
                '\n' +
                '    this.state = {\n' +
                '      filmInfo: [],\n' +
                '      fetching: false,\n' +
                '      error: false\n' +
                '    }\n' +
                '  }'
            const fifthCode='\tcomponentDidMount() {\n' +
                '\t\tthis.setState({ fetching: true })'
            const sixthCode='\t\tfetch("https://api.apispreadsheets.com/data/568/").then(res => {\n' +
                ' \t\t\tif (res.status === 200){\n' +
                ' \t\t\t\t// SUCCESS\n' +
                ' \t\t\t\tres.json().then(data => {\n' +
                ' \t\t\t\t\tconst yourData = data\n' +
                ' \t\t\t\t}).catch(err => console.log(err))\n' +
                ' \t\t\t}\n' +
                '\t\t\telse{\n' +
                '\t\t\t\t// ERROR\n' +
                '\t\t\t}\n' +
                '})'
            const seventhCode='{"data":[{"title":"Parasite","awards_won":"Best Picture","rotten_tomato_score":"99%","imdb_score":"8.6","revenue":"$266.9 million","genre":"Comedy, Drama, Thriller","year":"2019","picture":"https://m.media-amazon.com/images/M/MV5BYWZjMjk3ZTItODQ2ZC00NTY5LWE0ZDYtZTI3MjcwN2Q5NTVkXkEyXkFqcGdeQXVyODk4OTc3MTY@._V1_UY1200_CR90,0,630,1200_AL_.jpg","film_url":"https://en.wikipedia.org/wiki/Parasite_(2019_film)"}'
            const eigthCode='\tcomponentDidMount() {\n' +
                '\t\tthis.setState({ fetching: true })\n'+
                '\n'+
                    '    \tfetch("https://api.apispreadsheets.com/data/568/").then(res=>{\n' +
                    '      \t\tif (res.status === 200){\n' +
                    '        \t\t// SUCCESS\n' +
                    '        \t\tres.json().then(data => {\n' +
                    '          \t\tconst yourData = data.data\n' +
                    '          \t\tthis.setState(\n' +
                    '              \t\t{\n' +
                    '                \t\tfilmInfo: yourData,\n' +
                    '                \t\tfetching: false\n' +
                    '              \t\t}\n' +
                    '          \t\t)\n' +
                    '        \t\t}).catch(err => this.setState({ error: true, fetching: false }))\n' +
                    '      \t\t}\n' +
                    '      \t\telse{\n' +
                    '        \t\tthis.setState({ error: true, fetching: false })\n' +
                    '        \t\t// ERROR\n' +
                    '      \t\t}\n' +
                    '    \t\t}).catch(err => this.setState({ error: true, fetching: false }))\n' +
                '  \t\t}'
            const ninthCode='import React, { Component } from \'react\';\n' +
                '\n' +
                'class FilmCard extends Component {\n' +
                '    constructor(props) {\n' +
                '        super(props);\n' +
                '    }\n' +
                '\n' +
                '    render() {\n' +
                '        return (\n' +
                '            <div>\n' +
                '                <div>\n' +
                '                    {this.props.title}\n' +
                '                </div>\n' +
                '                <div>\n' +
                '                    {this.props.year}\n' +
                '                </div>\n' +
                '                <div>\n' +
                '                    {this.props.film_url}\n' +
                '                </div>\n' +
                '                <div>\n' +
                '                    {this.props.picture}\n' +
                '                </div>\n' +
                '                <div>\n' +
                '                    {this.props.rotten_tomato_score}\n' +
                '                </div>\n' +
                '                <div>\n' +
                '                    {this.props.imdb_score}\n' +
                '                </div>\n' +
                '                <div>\n' +
                '                    {this.props.revenue}\n' +
                '                </div>\n' +
                '                <div>\n' +
                '                    {this.props.awards_won}\n' +
                '                </div>\n' +
                '                <div>\n' +
                '                    {this.props.genre}\n' +
                '                </div>\n' +
                '                <div>\n' +
                '                    {this.props.film_url}\n' +
                '                </div>\n' +
                '            </div>\n' +
                '        )\n' +
                '    }\n' +
                '}\n' +
                '\n' +
                'export default FilmCard;'
            const tenthCode='render() {\n' +
                '    let body;\n' +
                '    if(this.state.filmInfo.length===0 && !this.state.fetching && !this.state.error){\n' +
                '            body = <h1> ... </h1>\n' +
                '    } else {\n' +
                '        if(this.state.fetching){\n' +
                '            body = <h1> ... </h1>\n' +
                '        }\n' +
                '        else{\n' +
                '            if(this.state.error){\n' +
                '                body = <h1> There was an error getting your request</h1>\n' +
                '            }\n' +
                '            else{\n' +
                '                body = this.state.filmInfo.map(x => <FilmCard title={x.title}\n' +
                '                                                              awards_won={x.awards_won}\n' +
                '                                                              rotten_tomato_score={x.rotten_tomato_score}\n' +
                '                                                              imdb_score={x.imdb_score}\n' +
                '                                                              revenue={x.revenue}\n' +
                '                                                              genre={x.genre}\n' +
                '                                                              picture={x.picture}\n' +
                '                                                              film_url={x.film_url}\n' +
                '                                                              year={x.year}/>\n' +
                '                                                )\n' +
                '\n' +
                '            }\n' +
                '        }\n' +
                '\n' +
                '    }\n' +
                '\n' +
                '    return (\n' +
                '       <div>\n' +
                '           <h1> Award Winning Movies </h1>\n' +
                '           {body}\n' +
                '       </div>\n' +
                '    )\n' +
                '  }\n' +
                '}\n' +
                '\n' +
                'export default App;'
            // const eleventhCode='return (\n' +
            //     '        <div>\n' +
            //     '          <h1> Award Winning Movies</h1>\n' +
            //     '          {body}\n' +
            //     '        </div>\n' +
            //     '    )\n' +
            //     '  }\n' +
            //     '}\n' +
            //     '\n' +
            //     'export default App;'
            return(
            <div>
                <h1>Quick Notes About React</h1>
                        <p>If you are the kind of person who learns best by doing, go through this tutorial first THEN go through the <a href="https://reactjs.org/tutorial/tutorial.html">React docs</a>.</p>
                        <p>Otherwise, if you are a theoretical person, read the <a href="https://reactjs.org/tutorial/tutorial.html">React docs</a> first.</p>
                <hr/>
                <br/>
                <h1>Start by Creating Your Spreadsheet </h1>
                        <p>Create a spreadsheet that contains the data you want to show up on your website.</p>
                        <p>You can make it on Google Sheets, upload it to Dropbox, or make it on Excel. I created mine on Google Sheets with information on <a href="https://docs.google.com/spreadsheets/d/1So0Rg_UnwBMzGGRju_aqmaDRh7XwxUvdbwWAhx1-IOQ/edit?usp=sharing">Academy Award Winning Films in 2019</a>. </p>
                        <br/>
                        <figure>
                            <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/700/1*Shd79AbJ7h_EstZcMsfOUQ.png" alt="spreadsheet example"/>
                            <figcaption style={{textAlign: 'center'}}><sub>With APISpreadsheets, you don’t need to worry about the format of your sheet because it’ll all get converted the same way.</sub></figcaption>
                        </figure>
                        <br/>
                        <p> Our data will power our front-end which will ultimately look like this</p>
                        <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/242/1*F3__lv2_1Ey82mYEAwee2A.png" alt="final card "/>
                        <br/><br/><br/>
                <hr/>
                <br/>
                <h1>Upload or Connect your Spreadsheet on APISpreadsheets.com</h1>
                        <br/>
                        <p>Make an account if you don’t have one already (it’s free for up to 10 files!) and choose the file you want. Once you have uploaded the file, checkout the quick tour to get a deeper understanding of all the features!</p>
                        <br/><br/>
                        <figure>
                            <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/700/1*hfUM3LoGJ-peB4HamOpV3Q.png" alt="api spreadsheets"/>
                            <figcaption style={{textAlign:"center"}}><sub>You can upload a Google Sheet, Local File, or a File on Dropbox.</sub></figcaption>
                        </figure>
                        <br/><br/>
                        <h4>For this tutorial, we will only be reading our spreadsheet so click on the “Read” tab and Javascript Code Sample</h4>
                        <br/>
                        <figure>
                        <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/700/1*rrhD1g5i_AnUlTsxNWt-BQ.png" alt="quickstart code"/>
                            <figcaption style={{textAlign:"center"}}><sub>ApiSpreadsheets Quickstart menu</sub></figcaption>
                        </figure>
                        <p>We will explain what to do with all this in a bit, but for now let’s start up our React App.</p>
                        <br/>
                <hr/>
                <br/>
                <h1>Open up your Terminal on Mac or Command Prompt on Windows</h1>
                        <br/>
                        <p>Before installing React we have to make sure we have node.js downloaded which you can do from here: <a href="https://nodejs.org/en/download/">nodejs.org</a></p>
                        <p>Now, to create a project run the following commands on your Terminal or Command Prompt:</p>
                        <ol>
                            <li> <code>$ npx create-react-app award-winning-movies </code> this creates a folder with all the files you need for your react app. You can change <b>award-winning-movies</b> to any other name.</li>
                            <li><code>$ cd award-winning-movies</code> this puts you in the folder you made so you can work on your app</li>
                            <li><code>$ npm start</code> you should see your app open up on your localhost and it looks like this</li>
                        </ol>
                        <br/>
                        <figure>
                            <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/700/1*XJPybVUUJJXVLHLk0gsUkg.png" alt="react page"/>
                            <figcaption style={{textAlign:"center"}}><sub>How your app should first look</sub></figcaption>
                        </figure>
                        <br/>
                <hr/>
                <br/>
                <h1>Open the React App you created in your favorite IDE or Text Editor</h1>
                        <p>An IDE stands for Integrated Development Environment where you can edit the React app we just created. I’m using <a href="https://www.jetbrains.com/webstorm/">WebStorm. </a> You can also use a simple text editor like <a href="https://atom.io/"> Atom.</a></p>
                        <br/>
                        <figure>
                        <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/665/1*_R7LR-KBQtBzffBkQdSBqQ.png" alt="react src"/>
                            <figcaption style={{textAlign:"center"}}><sub>The left shows the files under src imported from React</sub></figcaption>
                        </figure>
                        <br/><br/>
                        <p>On the left you can see all the files we get from React but we will mainly be focusing on our <b>App.js</b> component in the folder “src”.</p>
                        <p>When we are done with this tutorial, the <b>App.js </b>component will have the following code.</p>
                        <br/>
                        <div id="formCode">
                            <AceEditor
                                mode="javascript"
                                theme="monokai"
                                value={startCode}
                                name="UNIQUE_ID_OF_DIV"
                                editorProps={{$blockScrolling: true}}
                                height={650}
                                width="auto"
                                setOptions={{
                                    useWorker: false // <<----- USE THIS OPTION TO DISABLE THE SYNTAX CHECKER
                                }}
                            />
                        </div>
                        <br/>
                        <p>Don’t worry if all this looks confusing. We are going to walk you through it step by step below.</p>

                <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px'}}>
                    <h4>What is a Component?</h4>
                    <p>A component is a block of code that renders your given HTML elements or other components. Components are the building blocks of React and you can read more about them in the <a href="https://reactjs.org/docs/getting-started.html">React documentation.</a></p>
                    <p style={{marginBottom:'0px'}}>For now all you need to know is components display the visuals for our app.</p>
                </div>
                <br/><br/>
                <h1>Our App.js Component</h1>
                        <p>Our <b>App.js</b> component is the entry way to our React app.</p>
                        <h4>Import Statements</h4>
                        <p>We are going to import React and the Component class from ‘react’. We will also be importing the <b>App.css</b> file and another Component: FilmCard. We will be walking through these individually later on.</p>
                        <div id="formCode">
                            <AceEditor
                                mode="javascript"
                                theme="monokai"
                                value={secondCode}
                                name="UNIQUE_ID_OF_DIV"
                                editorProps={{$blockScrolling: true}}
                                height={50}
                                width="auto"
                                setOptions={{
                                    useWorker: false // <<----- USE THIS OPTION TO DISABLE THE SYNTAX CHECKER
                                }}
                            />
                        </div>
                        <h4>Initialize Our Component</h4>
                        <p>We begin by declaring that our component is a class that extends the component class</p>
                        <div>
                                <AceEditor
                                    mode="javascript"
                                    theme="monokai"
                                    value={thirdCode}
                                    name="UNIQUE_ID_OF_DIV"
                                    editorProps={{$blockScrolling: true}}
                                    height={15}
                                    width="auto"
                                    setOptions={{
                                        useWorker: false // <<----- USE THIS OPTION TO DISABLE THE SYNTAX CHECKER
                                    }}
                                />
                        </div>
                <figcaption><sub><i>Don't forget to close this bracket at the end :)</i></sub></figcaption>
            <br/>
                        <p>We initialize the component by setting the component’s <b>state</b> and inheriting props.</p>
                        <div>
                                <AceEditor
                                    mode="javascript"
                                    theme="monokai"
                                    value={forthCode}
                                    name="UNIQUE_ID_OF_DIV"
                                    editorProps={{$blockScrolling: true}}
                                    height={100}
                                    width="auto"
                                    setOptions={{
                                        useWorker: false // <<----- USE THIS OPTION TO DISABLE THE SYNTAX CHECKER
                                    }}
                                />
                        </div>
                        <br/>
                        <p><b>this.state = {} </b>initializes the local state of the component. This is where we will be including "filmInfo", an empty array right now. Fetching and error are set to false because nothing is happening for them. </p>
                <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px'}}>
                    <i>State is the component’s brain. Any changes in the state render the component again so we always think carefully on what the state should contain</i>
                </div>
                        <br/>
                           <h4>Add componentDidMount Method and immediately change fetching state to be true</h4>

                            <AceEditor
                                mode="javascript"
                                theme="monokai"
                                value={fifthCode}
                                name="UNIQUE_ID_OF_DIV"
                                editorProps={{$blockScrolling: true}}
                                height={50}
                                width="auto"
                                setOptions={{
                                    useWorker: false // <<----- USE THIS OPTION TO DISABLE THE SYNTAX CHECKER
                                }}
                            />
                        <br/>
                        <p><b>componentDidMount</b> is a method that is part of React’s component lifecycle. It signals that the component and it’s sub-components have rendered properly and it’s where we will be making our API call.</p>

            <br/>
                            <h4>Go back to APISpreadsheets to copy our Javascript Code from earlier and paste in the componentDidMount() method</h4>
                            <AceEditor
                                mode="javascript"
                                theme="monokai"
                                value={sixthCode}
                                name="UNIQUE_ID_OF_DIV"
                                editorProps={{$blockScrolling: true}}
                                height={150}
                                width="auto"
                                setOptions={{
                                    useWorker: false // <<----- USE THIS OPTION TO DISABLE THE SYNTAX CHECKER
                                }}
                            />
                <div style={{textAlign:"center"}}><i>By the way, this API URL is public so feel free to use it yourself</i></div>
                <br/>
                        <p>We are going to modify this code a bit so it handles all the different scenarios. Depending on the status of the <b>fetch</b> request, we will be changing the state using the <b>this.setState</b> function.</p>
                        <p>For example, if the fetch is successful (i.e. we have obtained our spreadsheet data), then we will set the <b>filmInfo</b> state to contain all our film data. Our film data will look like the following.</p>
                            <AceEditor
                                mode="javascript"
                                theme="monokai"
                                value={seventhCode}
                                name="UNIQUE_ID_OF_DIV"
                                editorProps={{$blockScrolling: true}}
                                height={50}
                                width="auto"
                                setOptions={{
                                    useWorker: false // <<----- USE THIS OPTION TO DISABLE THE SYNTAX CHECKER
                                }}
                            />
                            <br/>
                        <p>We will also be changing <b>states</b> for when the page is loading or if there is an error. Our final <b>fetch</b> and <b>componentDidMount</b>code looks like the following:</p>
                            <AceEditor
                                mode="javascript"
                                theme="monokai"
                                value={eigthCode}
                                name="UNIQUE_ID_OF_DIV"
                                editorProps={{$blockScrolling: true}}
                                height={200}
                                width="auto"
                            />
                        <br/>
                        <p>If successful, our <b>filmInfo</b> state will contain an array of all the films and their attributes. The ones we put into our spreadsheet.</p>

                        <p>Now we will need another Component that can take each element of that array (aka the info for a particular film) and create a Card to display.</p>
                <hr/>
                <h1>Create New Component FilmCard.js</h1>
                        <p>Create a new file and name it <b>FilmCard.js</b> and put in the same directory as <b>App.js.</b></p>
                        {/*TODO: ADD SCREENSHOT OF SRC*/}
                <figure>
                    <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/153/1*dL_Xt4GStei4DjD-pJho8w.png" alt="img7"/>
                    <figcaption style={{textAlign: 'center'}}><sub> FilmCard.js </sub></figcaption>
                </figure>

                        <p>This is where we will be displaying the info about the film. We will be passing <b>props</b> to this component that are equal to the film’s info.</p>

                        <p>If you go back to APISpreadsheets and click on <b>Sample Response</b> you can see what we would be naming each prop.</p>
                      <figure>
                          <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/700/1*ug4FVVEdYLs0irm7qcnoqQ.png" alt="img7"/>
                          <figcaption style={{textAlign: 'center'}}><sub> ApiSpreadsheets Sample Response</sub></figcaption>
                      </figure>
                <p> I gave each <b>prop</b> its own <i>{"<div>"}</i> and it looks like this: </p>

                            <AceEditor
                                mode="javascript"
                                theme="monokai"
                                value={ninthCode}
                                name="UNIQUE_ID_OF_DIV"
                                editorProps={{$blockScrolling: true}}
                                height={500}
                                width="auto"
                                setOptions={{
                                    useWorker: false // <<----- USE THIS OPTION TO DISABLE THE SYNTAX CHECKER
                                }}
                            />
                            <br/>

                <h1>We’re almost done! Now it’s time to work on our render() function on App.js</h1>

                <p>Your code will first run its <b>constructor(props)</b> method, then <b>render()</b>, then <b>componentDidMount()</b> and then <b>render()</b> anytime the state is changed, for example when we call the <b>this.setState</b> function in <b>componentDidMount</b> and <b>fetch</b>.</p>

                <p style={{marginBottom:"5px"}}>In the render we have if else statements that determine what should be displayed given a particular scenario. In our case we have 3 scenarios.</p>
                        <ol>
                            <li>if the component is fetching </li>
                            <li>if there is an error</li>
                            <li>if the data was successfully obtained.</li>
                        </ol>
                <br/>
                    <p>The first and second if else blocks are for when the screen is loading and the third is if there are errors.</p>

                    <p>The last else is for getting your film info mapped to your FilmCard component to display the card. This is where we pass the film’s attributes as props to the FilmCard component. Our final <b>render()</b> code will look like this:</p>

                    <AceEditor
                        mode="javascript"
                        theme="monokai"
                        value={tenthCode}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={300}
                        width="auto"
                        setOptions={{
                            useWorker: false // <<----- USE THIS OPTION TO DISABLE THE SYNTAX CHECKER
                        }}
                    />
                    <br/>
                <div style={{textAlign:"center"}}><sub>Very unformatted and non-abstract code. Feel free to simplify but we have found this is better for learning</sub></div>
                        <br/><br/>
                <p>We have to have a return() something from the render() method because this is what React shows to our browser. Since we are setting what should be displayed to the <b>body</b> variable, we will be returning, aka telling React to display it, in the return().</p>

                <p>Finally, we also need to export each component.</p>


                    <p>When you run your code, it will display like this on your localhost.</p>
                <figure>
                    <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/700/1*h_hcnPQzie6n7Z9X9LeiGg.png" alt="img7"/>
                    <figcaption style={{textAlign: 'center'}}><sub> How Your Browser Should Look</sub></figcaption>
                </figure>

                <br/><br/>
                <p>Here you have all your data and now you can add some CSS to make it look nice! You can reference my full code on <a href="https://github.com/AsthaSharma1/award-winning-movies"> GitHub</a> and use guides on <a href="https://www.w3schools.com/cssref/default.asp">W3Schools</a> to see what you can do!</p>

                    <h1>Congratulations! You have now made a React Web app powered by Spreadsheets!</h1>
                <figure>
                    <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/700/0*wPDKRJlp_lOOftQI" alt="img123"/>
                </figure>
                    <br/><br/>
                <h2>Time to Deploy Your Code</h2>
                <p>After you created a nicer looking front-end you can use Heroku to host your site for free.</p>
                <p>I followed this <a href="https://blog.heroku.com/deploying-react-with-zero-configuration">tutorial</a> if you want to go more in depth but you want to get started right away, copy and run each step in your terminal. </p>
                <p>First, make an account on <a href="heroku.com."> heroku </a></p>
                <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px'}}>
                <code>$ npm install -g create-react-app </code>
                <br/>
                <code> $ create-react-app my-app </code>
                <br/>
                <code> $ cd my-app </code>
                <br/>
                <code>$ git init</code>
                <br/>
                <code>$ heroku create -b https://github.com/mars/create-react-app-buildpack.git </code>
                <br/>
                <code>$ git add . </code>
                <br/>
                <code>$ git commit -m "react-create-app on Heroku" </code>
                <br/>
                <code>$ git push heroku master </code>
                <br/>
                <code>$ heroku open </code>
                </div>

                    <br/>
                <p>This gave me a randomly worded name but now my site can be shared and seen to anyone!<i> Note, that free sites sometimes take a while to boot up on Heroku.</i></p>

                <h4> <a href="https://sheltered-eyrie-23738.herokuapp.com/">https://sheltered-eyrie-23738.herokuapp.com/</a></h4>
               <hr/>
                <h1>And there you have it, a simple React web app powered by Spreadsheets.</h1>

                <p>The best part is, if you add or modify any data to your Google Sheets, it will automatically show up on your web app! Even if you are working on an Excel file you can upload that in the same section you originally did on APISpreadsheets and your app will update! No additional code would be required.</p>
                <p>If you have any further questions, feel free to email us at <a href="mailto:info@lovespreadsheets.com">info@lovespreadsheets.com!</a> We can’t wait to see what you build.</p>
                <p>Checkout our other <a href="/tutorials">tutorials! </a> </p>
                <CommentSection commentDiv={<div className="fb-comments" data-href="https://www.apispreadsheets.com/tutorials/how-to-make-a-react-site-with-no-backend" data-numposts="5" data-width="" />}/>
            </div>

        )
    }
}

export default ReactPost;

