import React, {Component} from 'react'
import {connect} from 'react-redux'
import Loader from 'react-loader-spinner'
import { Message } from 'semantic-ui-react'
import isObjectEmpty from "../../Constants/isObjectEmpty";
import EditAPIDisplay from "./EditAPIDisplay"
import API_Root from "../../Constants/API_Root";
import {modifyAllQueries} from "../../Actions/ModifyAllQueries";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
import {modifyEditQueryInfo} from "../../Actions/ModifyEditQueryInfo";

class DatabaseEditAPIIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: this.getIsFetching(),
            error: false,
            tablesAndColumnInfo: this.getTablesAndColumnInfo()
        }
    }

    getValueFromModalInfo = (key) => {
        let value = null;

        // if (this.props.modalInfo !== null){
        //     if (this.props.modalInfo.constructor === Object){
        //         if (key in this.props.modalInfo){
        //             value = this.props.modalInfo[key]
        //         }
        //     }
        // }

        if (this.props.editQueryInfo !== null){
            if (this.props.editQueryInfo.constructor === Object){
                if (key in this.props.editQueryInfo){
                    value = this.props.editQueryInfo[key]
                }
            }
        }

        return value
    }

    getIsFetching = () => {
        let isFetching = false;

        let isFetchingVal = this.getValueFromModalInfo("isInRedux")

        if (isFetchingVal !== null){
            isFetching = !isFetchingVal
        }

        return isFetching
    }

    getQueryPK = () => {
        let queryPK = -1;

        let queryPKVal = this.getValueFromModalInfo("queryPK")

        if (queryPK !== null){
            queryPK = queryPKVal
        }

        return queryPK
    }

    getTablesAndColumnInfo = () => {
        let tablesAndColumnInfo = [];
        if (!isObjectEmpty(this.props.allDBInfo)){
            if ("dbInfo" in this.props.allDBInfo){
                if ("tablesAndColumnInfo" in this.props.allDBInfo.dbInfo){
                    tablesAndColumnInfo = this.props.allDBInfo.dbInfo.tablesAndColumnInfo
                }
            }
        }

        return tablesAndColumnInfo
    }

    componentDidMount(){
        if (this.state.isFetching){
            const queryPK = this.getQueryPK()

            if (queryPK !== -1){
                // Get query information and store it in the allQueries and store it in modal info
                // If dbInfo is blank, also get the db tables and columns
                const getTablesAndColumnInfo = this.state.tablesAndColumnInfo.length === 0;
                const error = () => this.setState({ isFetching: false, error: true })

                fetch(API_Root + "api/get-api-query-info/", {
                    method: "POST",
                    body: JSON.stringify({
                        email: localStorage.getItem("email"),
                        token: localStorage.getItem("token"),
                        queryPK: queryPK,
                        getTablesAndColumnInfo: getTablesAndColumnInfo
                    })
                }).then(res => {
                    if (res.status === 200){
                        res.json().then(data => {
                            this.props.modifyModalInfo({queryPK: queryPK, isNew: false, isInRedux: true, dbHash: data.dbHash, idx: this.props.allQueries.length})
                            this.props.modifyEditQueryInfo({queryPK: queryPK, isNew: false, isInRedux: true, dbHash: data.dbHash, idx: this.props.allQueries.length})

                            const updateAllQueries = [...this.props.allQueries, data.queryInfo]
                            this.props.modifyAllQueries(updateAllQueries)

                            if (getTablesAndColumnInfo){
                                this.setState({ tablesAndColumnInfo: data.tablesAndColumnInfo, isFetching: false, error: false })
                            } else {
                                this.setState({ isFetching: false, error: false })
                            }
                        }).catch(err => {error(); console.log(err)})
                    } else {
                        error()
                    }
                }).catch(err => {error(); console.log(err)})
            }
        }
    }

    render() {
        let body = null;

        if (this.state.isFetching){
            body = <div style={{ textAlign: 'center'}}>
                <Loader
                    type="TailSpin"
                    color="black"
                    height="50"
                    width="50"
                />
                <h4 className="thinHeading" style={{color: 'black'}}>Setting up your report...</h4>
            </div>
        } else {
            if (this.state.error){
                body = <Message
                    header="There was an error getting your file query information"
                    icon="refresh"
                    content="Please refresh. Contact our support if this error persists"
                    negative
                    size="huge"
                    fluid/>
            } else {
                body =
                    <div>
                        <EditAPIDisplay tablesAndColumnInfo={this.state.tablesAndColumnInfo} />
                    </div>
            }
        }

        return (
            <div style={{minHeight: '100vh'}}>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allDBInfo: state.mainState.allDBInfo,
    modalInfo: state.mainState.modalInfo,
    allQueries: state.mainState.allQueries,
    editQueryInfo: state.mainState.editQueryInfo
})

const mapActionsToProps = {
    modifyAllQueries: modifyAllQueries,
    modifyModalInfo: modifyModalInfo,
    modifyEditQueryInfo: modifyEditQueryInfo
}

export default connect(mapStateToProps, mapActionsToProps)(DatabaseEditAPIIndex)