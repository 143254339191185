import React, {Component} from 'react'

class PageHeader extends Component {
    render() {
        let header = null;
        let desc = null;

        if (typeof this.props.header === "string"){
            header = <h3>{this.props.header}</h3>
        } else {
            header = this.props.header
        }

        if (typeof this.props.desc === "string"){
            desc = <h5>{this.props.desc}</h5>
        } else {
            desc = this.props.desc
        }

        let style = {backgroundColor: "#F4FBF3"}

        if ('importer' in this.props){
            style.backgroundColor = "#FFF7F3"
        } else if ('common' in this.props){
            style.backgroundColor = "#F4F4F5"
        } else if ('isImporter' in this.props){
            if (this.props.isImporter){
                style.backgroundColor = "#FFF7F3"
            }
        }

        return (
            <div className={'tiny' in this.props ? "tinyPageHeader" : "pageHeader"} style={style}>
                <div>{header}</div>
                <div>{desc}</div>
            </div>
        )
    }
}

export default PageHeader