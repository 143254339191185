import React, {Component} from 'react'
import { connect } from 'react-redux'
import Congratulations from "./Body/Congratulations"
import PickAction from "./Body/PickAction"
import ExitModalFooter from "./Body/ExitModalFooter"
import ActionModalBody from "../QuickstartModal/Body/ActionModalBody"

class QuickstartBody extends Component {
    render() {
        let body = null;
        let footerOptions = <ExitModalFooter actionType/>;

        if (this.props.tutorialStep === "userPreference"){
            body = <Congratulations />
            footerOptions = <ExitModalFooter />;
        }
        else if (this.props.tutorialStep === "pickAction" ){
            body = <PickAction />
            footerOptions = <ExitModalFooter />;
        }
        else if (this.props.tutorialStep.includes("readAction")){
            body = <ActionModalBody actionType="readAction"/>
        }
        else if (this.props.tutorialStep.includes("createAction")){
            body = <ActionModalBody actionType="createAction"/>
        }
        else if (this.props.tutorialStep.includes("updateAction")){
            body = <ActionModalBody actionType="updateAction"/>
        }
        else if (this.props.tutorialStep.includes("deleteAction")){
            body = <ActionModalBody actionType="deleteAction"/>
        }
        else{
            body = null;
            footerOptions = null;
        }


        return (
            <div>
                {footerOptions}
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tutorialStep: state.mainState.tutorialStep
})

export default connect(mapStateToProps, null)(QuickstartBody)