import React, {Component} from 'react'
import {connect} from 'react-redux'
import {gunaldiStyles} from "../../../../SharedComponents/GunaldiDesignStyles";
import DomainConnectionRow from "./DomainConnectionRow"

class DomainConnectionsIndex extends Component {
    deleteDomainConnection = (idx) => {
        const currDomainInfo = JSON.parse(JSON.stringify(this.props.domainInfo));

        currDomainInfo.splice(idx, 1)

        if (currDomainInfo.length > 0){
            this.props.changeState({ domainInfo: currDomainInfo })
        } else {
            this.props.changeState({ domainInfo: null })
        }
    }

    render() {
        if (this.props.domainInfo.length > 0){
            const headingStyle = {fontSize: "14px", fontWeight: "700", color: gunaldiStyles.subText}
            const gridStyle = {display: "grid", gridTemplateColumns: "40% 45% 10% 5%"}

            return (
                <div>
                    <div style={{padding: "8px", borderTop: "1px solid #F0F0F0", borderBottom: "1px solid #F0F0F0"}}>
                        <div style={gridStyle}>
                            <div style={headingStyle}>
                                Domain Name
                            </div>
                            <div style={headingStyle}>
                                CNAME Value
                            </div>
                            <div style={{...headingStyle, textAlign: "center"}}>
                                Use SSL?
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                    {
                        this.props.domainInfo.map((x, idx) => <DomainConnectionRow
                            info={x['Distribution']}
                            idx={idx}
                            deleteDomainConnection={this.deleteDomainConnection}
                            gridStyle={gridStyle}
                            changeState={this.props.changeState}
                        />)
                    }
                </div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DomainConnectionsIndex)