import React, {Component} from 'react'
import DocsBaseURL from "../DocsBaseURL";
import DocsGetResponse from '../DocsGetResponse'
import DocsGetURLParametersOld from './DocsGetURLParametersOld'
import DocsPostHeaderParameters from '../DocsPostHeaderParameters'

class DocsOldGetIndex extends Component {
    render() {
        const baseGetURL = "api/data/[fileID]/[dataFormat]/"
        return (
            <div>
                <p>If your file is <b>NOT PRIVATE</b> then make a <code>GET</code> request to the following URL</p>
                <DocsBaseURL url={baseGetURL} />
                <div className="row">
                    <div className="col-sm-6 getParams">
                        <DocsGetURLParametersOld />
                        <br/><br/><br/><br/><br/>
                        <p>If your file is <b>PRIVATE</b> then make a <code>POST</code> request to the same URL as above with an empty body and the following in your <b>request header</b></p>
                        <br/>
                        <DocsPostHeaderParameters/>
                    </div>
                    <div className="col-sm-6 getResponse">
                        <DocsGetResponse/>
                    </div>
                </div>
            </div>
        )
    }
}

export default DocsOldGetIndex