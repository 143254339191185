import React, {Component} from 'react'
import Modal from '../SharedComponents/Modal'
import SQLUploadModalBody from "./SQLUploadModalBody";

class SQLUploadModal extends Component {
    render() {
        return (
            <Modal name="sqlUploadModal"
                   header={null}
                   body={<SQLUploadModalBody/>}
                   size="medium"
            />
        )
    }
}


export default SQLUploadModal