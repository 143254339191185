import React, {Component} from 'react'

class ActionResponse extends Component {
    componentDidMount(){
        if (this.props.actionType === "readAction"){
            const elem = document.getElementById("fileResponseSampleDiv")
            elem.scrollIntoView({ behavior: 'smooth', block: 'center'})
        }
    }

    render() {
        const actionType = this.props.actionType;
        const successStyle = {color: 'green'};

        const message = {
            readAction: <span>You will get your data as a JSON response and a status code of <code>200</code> if your request is <span style={successStyle}>successful</span></span>,
            createAction: <span>You will get a status code of <code>201</code> if your rows were <span style={successStyle}>successfully</span> created</span>,
            updateAction: <span>You will get a status code of <code>201</code> if your rows were <span style={successStyle}>successfully</span> updated</span>,
            deleteAction: <span>You will get a status code of <code>200</code>if your rows were <span style={successStyle}>successfully</span> deleted</span>
        }

        return (
            <div className="actionIndex">
                <h4 className="thinHeading">{message[actionType]}</h4>
            </div>
        )
    }
}

export default ActionResponse