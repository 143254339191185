export const isURLCalculator = (url, remove=false) => {

    if (url.includes("isCalculator")){
        localStorage.setItem("isCalculator", "true");
        return true;
    } else {
        let isCalculator = localStorage.getItem("isCalculator");

        if (isCalculator === "true"){
            return true
        } else {
            return false
        }
    }
}