import React, {Component} from 'react'
import { Button, Icon } from 'semantic-ui-react'
import API_Root from "../Constants/API_Root";
import {injectStripe} from 'react-stripe-elements';

class Verify3DSecurePaymentBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }
    }

    // onSuccess = (successMsg) => {
    //     this.props.toggleToast({show: true, message: successMsg, type: "success"})
    // }
    //
    // onError = (errorMsg) => {
    //     this.props.toggleToast({show: true, message: errorMsg, type: "error"})
    // }

    getErrorMsg = () => {
        return "There was a problem with your additional authentication. Please try again!"
    }

    stopSpinner = (msg, isError) => {
        this.setState({ loading: false}, () => this.props.toggleToast({ show: true, message: msg, type: isError ? "error" : "success"}))
    }

    takeAddlAction = (e) => {
        if (typeof this.props.stripe3DSecureInfo === "object"){
            // either key will be "paymentIntentClientSecret" or "setupIntentClientSecret":
            if ("paymentIntentClientSecret" in this.props.stripe3DSecureInfo){

                this.setState({ loading: true })

                this.props.stripe.confirmCardPayment(this.props.stripe3DSecureInfo.paymentIntentClientSecret, {
                    payment_method: this.props.stripe3DSecureInfo.defaultPayment,
                    setup_future_usage: "off_session"
                }).then((result) => {
                    if ("error" in result){
                        this.stopSpinner(this.getErrorMsg(), true)
                    } else {
                        fetch(API_Root + "api/confirm-3d-secure-payment/", {
                            method: "POST",
                            headers: {"Content-Type": "text/plain"},
                            body: JSON.stringify({
                                email: localStorage.getItem("email"),
                                token: localStorage.getItem("token"),
                            })
                        }).then(res => {
                            if (res.status === 201){
                                this.stopSpinner("Congrats! Your payment was successfully verified!", false)
                                window.location.reload()
                                // window.location.href = '/';
                            } else {
                                res.json().then(data => {
                                    const errorMsg = "errorMsg" in data ? data['errorMsg'] : this.getErrorMsg()

                                    this.stopSpinner(errorMsg, true)
                                }).catch(err => this.stopSpinner(this.getErrorMsg(), true))
                            }
                        }).catch(err => this.stopSpinner(this.getErrorMsg(), true))
                    }
                }).catch(err => this.stopSpinner(this.getErrorMsg(), true))
            }
        }
    }

    render() {
        return (
            <Button size="large" style={{backgroundColor: "#6FBB98"}} onClick={this.takeAddlAction} loading={this.state.loading}>
                <span style={{color: "white"}}><Icon name="thumbs up outline" /> Complete verification</span>
            </Button>
        )
    }
}

export default injectStripe(Verify3DSecurePaymentBtn)