import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import {modifyCustomImportSettings} from "../../../../../Actions/ModifyCustomImportSettings";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";

class FindReplaceInputs extends Component {

    constructor(props) {
        super(props);
    }

    changeInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        const allColumnInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))
        const columnInfo = allColumnInfo[this.props.columnIdx]
        const cleaningFunctions = columnInfo['cleaningFunctions'];

        if ("findReplace" in cleaningFunctions){
            const findReplace = cleaningFunctions['findReplace'];
            const currRowFindReplaceValues = findReplace[this.props.idx]

            currRowFindReplaceValues[name] = value

            findReplace[this.props.idx] = currRowFindReplaceValues
            cleaningFunctions['findReplace'] = findReplace
            columnInfo['cleaningFunctions'] = cleaningFunctions
            allColumnInfo[this.props.columnIdx] = columnInfo

            this.props.modifyCustomImportSettings("columnsAddlInfo", allColumnInfo)
        }
    }

    deleteFindReplaceRow = () => {
        const allColumnInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))
        const columnInfo = allColumnInfo[this.props.columnIdx]
        const cleaningFunctions = columnInfo['cleaningFunctions'];

        if ("findReplace" in cleaningFunctions){
            const findReplaceArray = cleaningFunctions['findReplace']
            findReplaceArray.splice(this.props.idx, 1)

            columnInfo['cleaningFunctions'] = cleaningFunctions
            allColumnInfo[this.props.columnIdx] = columnInfo

            this.props.modifyCustomImportSettings("columnsAddlInfo", allColumnInfo)
        }
    }
    addFindReplaceInputs = () => {
        const allColumnInfo = JSON.parse(JSON.stringify(this.props.customImportColumnsAddlInfo))
        const columnInfo = JSON.parse(JSON.stringify(allColumnInfo[this.props.columnIdx]))
        const cleaningFunctions = JSON.parse(JSON.stringify(columnInfo['cleaningFunctions']));
        let currFindReplaceArray = JSON.parse(JSON.stringify(cleaningFunctions['findReplace']))
        currFindReplaceArray.push({find:"",replace:""})
        const addedArray = JSON.parse(JSON.stringify(currFindReplaceArray))

        cleaningFunctions['findReplace'] = addedArray
        columnInfo['cleaningFunctions'] = cleaningFunctions
        allColumnInfo[this.props.columnIdx] = columnInfo

        this.props.modifyCustomImportSettings("columnsAddlInfo", allColumnInfo)
    }

    render() {
        const currRowFindReplaceValues = this.props.customImportColumnsAddlInfo[this.props.columnIdx]['cleaningFunctions']['findReplace'][this.props.idx]

        let find = currRowFindReplaceValues['find'];
        let replace = currRowFindReplaceValues['replace'];

        return (
            <div>
                <Input style={{paddingRight:'5px',paddingBottom:'5px'}} size="small" placeholder="Find Value" name="find" onChange={this.changeInput} value={find}/>
                <Input style={{paddingRight:'5px'}} size="small" placeholder="Replace Value" name="replace" onChange={this.changeInput} value={replace} />
                <Button icon="add" color="green"
                        basic={true}
                        onClick={this.addFindReplaceInputs}/>
                <Button icon="trash alternate outline" color="red"
                        basic={true} onClick={this.deleteFindReplaceRow}/>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    customImportColumnsAddlInfo: state.mainState.customImportColumnsAddlInfo
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(FindReplaceInputs)