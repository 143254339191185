import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Message, Icon, Segment } from 'semantic-ui-react'
import RemoveGoogleAccount from "./RemoveGoogleAccount";
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
import AccountRowDisplay from "./AccountRowDisplay"

class GoogleAccountRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: null,
        }
    }

    changeState = (name, value) => this.setState({ [name]: value })

    componentDidMount(){
        if (!this.props.hasGooglePermissions) {
            this.setState({
                message: <Message
                            style={{marginTop: "10px"}}
                            negative
                            header={"API Spreadsheets does not have permissions to access Google Sheets for this account. If you wish to re-grant permissions, follow these steps in order. Otherwise, feel free to Remove this Account"}
                            content={<span>
                                        <ol>
                                            <li>Remove API Spreadsheets permissions from your Google Account. <span className="linkStyle" onClick={this.openAccountRemoveSteps}>Click here to see steps on how to do that</span></li>
                                            <br/>
                                            <li>
                                                <RemoveGoogleAccount
                                                    list
                                                    emailToRemove={this.props.email}
                                                    changeState={this.changeState}
                                                    removeAccount={this.removeAccount}
                                                    removeType="grantPermissions"/>
                                            </li>
                                            <br/><br/>
                                            <li><Button style={{backgroundColor: "navy"}}><a href="/upload" style={{textDecoration: "none", color: "white"}}><Icon name="upload" /> Upload a Google Sheet from {this.props.email}</a></Button></li>
                                        </ol></span>}
                            />
            })
        }
    }

    openAccountRemoveSteps = () => {
        this.props.modifyModalInfo({
            accountEmail: this.props.email
        })
        this.props.toggleModal("accountRemoveStepsModal")
    }


    removeAccount = (removeType) => {
        // this.props.removeAccount(this.props.email)

        if (removeType === "nonStep"){
            this.setState({
                message: <Message positive header="Your account was successfully removed from API Spreadsheets (our database)! Google Sheets related to this account cannot be accessed anymore"
                                  content={<span>To completely revoke permissions, remove API Spreadsheets permissions from your Google Account as well, if you haven't already. <span className="linkStyle" onClick={this.openAccountRemoveSteps}>Click here to see steps</span></span>}
                />
            })
        } else {
            this.setState({
                message: <Message positive header="Your account was successfully removed from API Spreadsheets"
                            content={<span>If you wish to relink the account, <Button style={{backgroundColor: "navy"}}><a href="/upload" style={{textDecoration: "none", color: "white"}}><Icon name="upload" /> Upload a Google Sheet from {this.props.email}</a></Button></span>}
                />

            })
        }
    }

    render() {
        return (
            <Segment style={{padding: "10px", marginBottom: "10px"}}>
                <div className="row">
                    <div className="col-sm-8">
                        <h5>
                            <AccountRowDisplay
                                profilePic={this.props.profilePic}
                                email={this.props.email}
                                accountType="google"
                            />
                        </h5>
                    </div>
                    <div className="col-sm-4">
                        <RemoveGoogleAccount
                            emailToRemove={this.props.email}
                            changeState={this.changeState}
                            removeAccount={this.removeAccount}
                            removeType="nonStep"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        {this.state.message}
                    </div>
                </div>
            </Segment>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(GoogleAccountRow)