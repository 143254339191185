import React, {Component} from 'react'
import API_Root from "../../Constants/API_Root";
import Hero from "../../SharedComponents/Hero"
import Loader from 'react-loader-spinner'
import DatasetRow from './DatasetRow'
import isObjectEmpty from "../../Constants/isObjectEmpty";
import { Message } from 'semantic-ui-react'

class DatasetsIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            fetching: false,
            data: {}
        }
    }

    componentDidMount(){
        this.setState({ fetching: true });

        const stopFetching = () => this.setState({ fetching: false })
        fetch(API_Root + "api/get-api-datasets/all/").then(res => {
            if (res.status === 200){
                res.json().then(data => this.setState({ fetching: false, data: data})).catch(err => stopFetching())
            }
            else {
                stopFetching()
            }
        }).catch(err => stopFetching())
    }

    render() {
        let body = null;
        if (this.state.fetching){
            body = <div style={{marginTop: '50px', textAlign: 'center'}}> <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4 className="thinHeading" style={{color: 'black'}}>Getting Datasets...</h4>
                </div>
        } else {
            if (!isObjectEmpty(this.state.data)){
                body = <div>
                    <div style={{paddingTop: '50px', width: '50%', margin: '0 auto'}}>
                        <Message header="Missing values are filled in with '?' for nominal and -100000 for numerical attributes" warning/>
                    </div>
                    <div className="datasetRowBody"> {this.state.data["datasets"].map((x, idx) => <DatasetRow info={x} key={idx}/>)}</div>
                </div>
            }
        }

        return (
            <div className="datasetIndex">
                <Hero
                    header="Datasets"
                    desc="Clean Data to Help you Practice Machine Learning"
                    textColor="white"
                    color1="rgb(124,252,0)"
                    color2="rgb(0,128,0)"
                />
                {body}
            </div>
        )
    }
}

export default DatasetsIndex