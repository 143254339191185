import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup, Icon } from 'semantic-ui-react'
import API_Root from "../Constants/API_Root";
import {modifyTeamInfo} from "../Actions/ModifyTeamInfo";

class TeamsDescriptionDisplay extends Component {
    constructor(props) {
        super(props);


        this.state = {
            originalDesc: JSON.parse(JSON.stringify(this.props.teamInfo[this.props.modalInfo.teamIdx].description)),
            desc: JSON.parse(JSON.stringify(this.props.teamInfo[this.props.modalInfo.teamIdx].description)),
            saving: false,
            editMode: false
        }

        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.saveDescription = this.saveDescription.bind(this);
        this.undoDesc = this.undoDesc.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
    }

    undoDesc(e){
        if (this.state.originalFileDesc === ""){
            this.setState({
                desc: this.state.originalDesc,
                editMode: true
            })
        }
        else{
            this.setState({
                desc: this.state.originalDesc,
                editMode: false
            })
        }
    }

    handleDescriptionChange(e){
        this.setState({
            desc: e.target.value
        })
    }

    toggleEdit(e){
        const currEdit = this.state.editMode;

        this.setState({
            editMode: !currEdit
        })
    }

    saveDescription(e){
        this.setState({saving: true});

        let copiedTeamInfo = JSON.parse(JSON.stringify(this.props.teamInfo))
        copiedTeamInfo[this.props.modalInfo.teamIdx].description = this.state.desc;

        this.props.modifyTeamInfo(copiedTeamInfo);

        let teamHash = this.props.teamInfo[this.props.modalInfo.teamIdx].team_hash;

        fetch(API_Root + 'api/change-api-team-description/', {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                teamHash: teamHash,
                description: this.state.desc,
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then(
            (res) => {
                if (res.status === 200){
                    this.setState({originalDesc: this.state.desc, saving: false, editMode: false});
                }
                else{
                    this.setState({originalDesc: this.state.desc, saving: false, editMode: false});
                }
            }).catch(err => this.setState({originalDesc: this.state.desc, saving: false, editMode: false}))

    }

    startEditMode = () => {
        this.setState({ editMode: true })
    }

    render() {
        const fileDesc = this.state.desc;

        let alert = null;
        let saveBtnDisabled = true;

        if (this.state.originalDesc.trim() !== fileDesc.trim()){
            alert = <span style={{color: 'red', paddingLeft: '5px', marginBottom: '5px'}}>Description not saved<br/></span>
            saveBtnDisabled = false
        }

        if (this.state.editMode){
            return (
                <div>
                    <div className="fileDescDiv">
                        <div className="fileDescTextArea">
                            <input
                                className="inputGray"
                                style={{width: "100%"}}
                                value={fileDesc}
                                onChange={this.handleDescriptionChange}
                                placeholder="Enter description for Team"
                            />
                        </div>
                        <div style={{display: "grid", gridTemplateColumns: "50% 50%", marginTop: "4px"}}>
                            <div>
                                {alert}
                            </div>
                            <div>
                                <div className="fileDescButtons">
                                    <Button content="Undo"
                                            icon="undo"
                                            onClick={this.undoDesc}
                                            size="mini"
                                            style={{backgroundColor: "#9696A0", color: "white"}}
                                    />
                                    <Button content="Save"
                                            style={{backgroundColor: "#6FBB98", color: "white"}}
                                            icon="save outline"
                                            disabled={saveBtnDisabled}
                                            onClick={this.saveDescription}
                                            loading={this.state.saving}
                                            size="mini"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            if (this.state.originalDesc.trim() === ""){
                return (
                    <p style={{color: "#5499F8", fontFamily: "Lato", fontSize: "14px", cursor: "pointer"}} onClick={this.startEditMode}>Add Description</p>
                )
            } else {
                return (
                    <div>
                        <p>{fileDesc} <Popup
                            trigger={<button onClick={this.toggleEdit} style={{marginLeft: "8px"}} className="iconBtn">
                                {/*<span><Icon name="edit outline" /></span>*/}
                                <span><i className="bx bxs-edit-alt" /> </span>
                            </button>}
                            content="Edit Description"
                        /></p>
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo,
    teamInfo: state.mainState.teamInfo
})

const mapActionsToProps = {
    modifyTeamInfo: modifyTeamInfo
}

export default connect(mapStateToProps, mapActionsToProps)(TeamsDescriptionDisplay)