import React, {Component} from 'react'
import capitalizeHyphenated from "../../../Constants/capitalizeHyphenated";

class PlanDesc extends Component {
    render() {
        return (
            <div style={{
                fontWeight: "400",
                color:  this.props.planIdx >=2 ? "white" : "#9696A0",
                fontSize: "10px"}}>{capitalizeHyphenated(this.props.desc)}</div>
        )
    }
}

export default PlanDesc