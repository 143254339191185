import React, {Component} from 'react'
import {connect} from 'react-redux'
import DocsImage from "../DocsImage";
import SilentFail from "../Importer/SilentFail";

class AWSS3 extends Component {
    render() {
        const isImporter = 'importer' in this.props;
        const imgStyle = {border:"1px solid #F0F0F0", display: "block", marginBottom: "16px"}

        return (
            <div>
                {
                    isImporter ? <div>
                        <div className="docsSection">
                            <p className="docsHeader">Connect AWS S3 Account</p>
                            <p>You can connect your AWS S3 account by providing:</p>
                            <div className="docsList">
                                <p>1. An Account Name</p>
                                <p>2. Access Key to your AWS Account</p>
                                <p>3. Secret Key to your AWS Account</p>
                            </div>
                            <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/EnterAccountInfo1.png"
                                 style={{width: "75%", ...imgStyle}}
                                 alt="select aws s3" />
                        </div>
                        <div className="docsSection">
                            <p className="docsHeader">Connect AWS S3 Account - Account Name</p>
                            <p><b>Account Name</b></p>
                            <p>This is a unique identifier for API Spreadsheets so it could be anything you wish. It has nothing to do with your AWS S3 account</p>
                        </div>
                        <div className="docsSection">
                            <p className="docsHeader">Connect AWS S3 Account - Access Key & Secret Key</p>
                            <p>You need to provide both the <a href="https://aws.amazon.com/blogs/security/wheres-my-secret-access-key/" target="_blank" rel="noopener noreferrer">Access Key and Secret Key from your AWS account</a></p>
                            <p>For optimal security, you can create these keys so they <a href="https://docs.aws.amazon.com/secretsmanager/latest/userguide/auth-and-access_examples.html" target="_blank" rel="noopener noreferrer">only access S3</a> and nothing else from your AWS account</p>
                            <p>Here is a summary of how to create and obtain these keys from your AWS account</p>
                            <p>1. <b>Search</b> and go to the <b>IAM</b> page from your AWS Console</p>
                            <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/SearchIAM2.png"
                                 style={{width: "75%", ...imgStyle}}
                                 alt="search IAM page" />
                            <p>2. Click on <b>Add Users</b> under the users tab of the IAM page</p>
                            <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/AddUsers3.png"
                                 style={{width: "75%", ...imgStyle}}
                                 alt="add users" />
                            <p>3. Provide a username and <b>select</b> the access key option on Step 1 of the Add User signup flow</p>
                            <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/UsernameAPIAccess4.png"
                                 style={{width: "75%", ...imgStyle}}
                                 alt="api access" />
                            <p>4. Select <b>AmazonS3FullAccess</b> policy under Attach existing policies directly on Step 2</p>
                            <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/AddPolicy5.png"
                                 style={{width: "75%", ...imgStyle}}
                                 alt="add policy" />
                            <p>5. Keep clicking through the prompts until user is created as seen below. <b>Copy</b> the <b>Access key ID</b> and the <b>Secret access key</b></p>
                            <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/CopyAccessSecretKey6.png"
                                 style={{width: "75%", ...imgStyle}}
                                 alt="copy keys" />
                            <p>6. Use these to connect to your AWS S3 account on API Spreadsheets</p>
                        </div>
                    </div> : null
                }
                <div className="docsSection">
                    <p className="docsHeader">View your AWS S3 Accounts</p>
                    <p>You can view your connected AWS S3 Accounts from your Profile</p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Integrations/Profile-AllAWSAccount.png"
                        alt="AWS S3 accounts"
                        width="100%"
                    />
                    <p>You can edit the account names and account keys by clicking on the Edit icon of the account</p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Integrations/Profile-EditAWSAccount.png"
                        alt="AWS S3 accounts"
                        width="100%"
                    />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Add your AWS S3 Account</p>
                    <p>Unlike Google Drive and Dropbox, you can add a new AWS S3 account directly from your profile</p>
                    <p>Click the Add New AWS S3 Account</p>
                    <DocsImage
                        src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Integrations/Profile-AddNewAWSAccount.png"
                        alt="AWS S3 accounts"
                        width="100%"
                    />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Remove your AWS S3 Account from API Spreadsheets</p>
                    <p>To remove our connection from your AWS S3 Account, click on that account's <b>garbage can</b> button </p>
                    <p>This will show you a series of prompts to follow, and once you are done API Spreadsheets will not be able to connect to that AWS S3 Account. All APIs from that AWS S3 Account will stop working</p>
                    <p>Once again, taking the steps above will promptly cut off API Spreadsheets' access to your AWS S3 Account</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Re-issue new keys</p>
                    <p>The keys you provide us will be removed from our database after you have Removed your AWS S3 account following the steps above</p>
                    <p>For further safety, you should also remove these keys from circulation on your AWS S3 account by re-issuing new keys</p>
                    <p>1. <b>Search</b> and go to the <b>IAM</b> page from your AWS Console</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/SearchIAM2.png"
                         width="75%"
                         alt="search IAM page" />
                    <p>2. Find the User whose Access and Secret Keys you are using on API Spreadsheets</p>
                    <p>3. Click on <b>Security Credentials</b> and then click on <b>Make Inactive</b> for your old key and <b>Create Access Key</b> to get a new key </p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Integrations/Profile-AWSAccountNewKeys.png"
                               width="75%"
                               alt="remove AWS account key" />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Not being able to connect to AWS S3</p>
                    <p>If you are unable to connect to your S3 account, the only issue is with your keys and their permissions</p>
                    <p>Make sure you are providing permission to the full <b>AmazonS3FullAccess</b> policy to the user whose keys you are using on API Spreadsheets</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Upload-AWSS3/AddPolicy5.png"
                         width="75%"
                         alt="add policy" />
                </div>
                <div className="docsSection">
                    {
                        isImporter ? <div>
                                <p className="docsHeader">Imported file failed to save to AWS S3 Account</p>
                                <p>If your connection was not working when the file was imported, then it will not be saved to your AWS S3 Account</p>
                                <SilentFail destination="AWS S3"/>
                        </div> :
                        <div>
                            <p className="docsHeader">Your File Deleted on AWS S3</p>
                            <p>If you delete your File from AWS S3 and NOT from API Spreadsheets, you will get an error when you try to do anything with that file</p>
                            <p>Your API URL will be valid but since your file does not exist in AWS S3, you will receive a <code>500</code> or <code>502</code> error code when making any API requests</p>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(AWSS3)