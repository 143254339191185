import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import QueryHelpBody from "./QueryHelpBody"

class QueryHelpModal extends Component {
    render() {
        return (
            <div>
                <ModalApp name="queryHelpModal"
                          header={null}
                          size="medium"
                          body={<QueryHelpBody modal/>}
                />
            </div>
        )
    }
}

export default QueryHelpModal