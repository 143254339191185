import React, {Component} from 'react'

class CustomWebsite extends Component {
    render() {
        return (
            <h1> Make a Custom Website Using Data from Google Sheets </h1>

        )
    }
}

export default  CustomWebsite