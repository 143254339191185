import React, {Component} from 'react'
import API_Root from "../../Constants/API_Root";
import Loader from 'react-loader-spinner'
import isObjectEmpty from "../../Constants/isObjectEmpty";
import { Button, Icon } from 'semantic-ui-react'
// import GoogleAccountRow from "./GoogleAccountRow";
import ProfileDisplayHeader from "../../InfoPages/Profile/ProfileDisplayHeader"
import DropboxAccountRow from "./DropboxAccountRow"
import AccountsErrorBanner from "./AccountsErrorBanner";
import AccountsNotConnectedBanner from "./AccountsNotConnectedBanner";

class DropboxAccountsIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accountInfo: null,
            fetching: true,
            error: false
        }
    }

    removeAccountInfo = (accountName) => {
        const copiedAccountInfo = JSON.parse(JSON.stringify(this.state.accountInfo))

        delete copiedAccountInfo[accountName]

        this.setState({
            accountInfo: copiedAccountInfo
        })
    }

    componentDidMount(){
        const hitError = () => this.setState({ fetching: false, error: true, accountInfo: null })

        fetch(API_Root + 'api/get-dropbox-accounts-info/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token")
            })
        }).then(res => {
            if (res.status === 200){
                res.json().then((data) => {
                    this.setState({ accountInfo: data, fetching: false, error: false })
                }).catch(err => hitError())
            }
            else{
                hitError()
            }
        }).catch(err => hitError());
    }

    render() {
        if (this.state.fetching){
            return (
                <Loader
                    type="TailSpin"
                    color="black"
                    height="40"
                    width="40"
                />
            )
        } else {
            if (this.state.error){
                return (
                    <AccountsErrorBanner
                        accountType="Dropbox"
                    />
                )

            } else {
                if (this.state.accountInfo === null){
                    return (
                        <AccountsNotConnectedBanner
                            accountType="Dropbox"
                        />
                    )
                } else {
                    if (isObjectEmpty(this.state.accountInfo)){
                        return (
                            <AccountsNotConnectedBanner
                                accountType="Dropbox"
                            />
                        )
                    } else {
                        let dropboxRows = [];

                        for (let email in this.state.accountInfo){
                            const info = this.state.accountInfo[email];

                            dropboxRows.push(<DropboxAccountRow
                                email={email}
                                message={info.message}
                                profilePic={info.profilePic}
                                name={info.name}
                                accountID={info.accountID}
                                hasDropboxPermissions={info.hasDropboxPermissions}
                                removeAccountInfo={this.removeAccountInfo}
                            />)
                        }

                        return (
                            <div>
                                <ProfileDisplayHeader name={this.props.name} icon={this.props.icon}/>
                                <br/>
                                {dropboxRows}
                            </div>
                        )
                    }
                }
            }

        }
    }
}

export default DropboxAccountsIndex