import React from 'react';
import InputFieldRow from './InputFieldRow';
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import {Button, Icon} from "semantic-ui-react";

class InputFields extends React.Component {
    handleAddRow = () => {
        this.props.onInputFieldsChange([...this.props.inputFields, {}]);
    };

    handleRemoveRow = (index) => {
        const updatedFields = [...this.props.inputFields];
        updatedFields.splice(index, 1);
        this.props.onInputFieldsChange(updatedFields);
    };

    handleFieldChange = (index, updatedField) => {
        const updatedFields = [...this.props.inputFields];
        updatedFields[index] = updatedField;
        this.props.onInputFieldsChange(updatedFields);
    };

    render() {
        return (
            <div>
                <div style={{fontFamily: "Lato", fontSize: "16px", fontWeight: "700", color: gunaldiStyles.subText, marginBottom: "16px"}}>
                    Input Fields
                </div>
                {this.props.inputFields.map((field, index) => (
                    <InputFieldRow
                        key={index}
                        rowIdx={index}
                        field={field}
                        sheetNames={this.props.sheetNames}
                        onFieldChange={(updatedField) => this.handleFieldChange(index, updatedField)}
                        onRemove={() => this.handleRemoveRow(index)}
                    />
                ))}
                <Button type="button" onClick={this.handleAddRow} size='small'
                        style={{ backgroundColor: gunaldiStyles.mainGreen, color: "white" }}><Icon name='plus' />Add Input Field</Button>
            </div>
        );
    }
}

export default InputFields;
