import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Backend_Root from "../../Constants/API_Backend_Root";
import Loader from 'react-loader-spinner';
import { ReactGrid, Column, Row } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import images from "../../Constants/Images";
import { Image } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect'

class TableIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: true,
            data: null,
            columnInfo: null
        }
    }

    componentDidMount(){
        let getURL;

        if ('getURL' in this.props){
            getURL = this.props.getURL
        } else {
            const url = window.location.href
            getURL = this.replaceDataFormatInGetURL(API_Backend_Root + "data/" + url.substring(url.indexOf("/table/") + "/table/".length, url.length))
        }

        const isError = () => this.setState({ fetching: false, data: null })

        fetch(getURL).then(res => {
            if (res.status === 200){
                res.json().then(data => {
                    this.setState({
                        data: data['data'],
                        fetching: false,
                        columnInfo: this.getColumns(data['data'])
                    })
                }).catch(err => isError())
            } else {
                if (res.status === 402){
                    this.setState({ data: "Error getting the data. The file owner has run out of requests for the day", fetching: false })
                } else { isError()  }
            }
        }).catch(err => isError())
    }

    replaceDataFormatInGetURL = (getURL) => {
        if (getURL.substring(getURL.length - 1, getURL.length) === "/"){
            getURL = getURL.substring(0, getURL.length)
        }

        if (getURL.includes("dataFormat=")){
            const url = new URL(getURL);
            const dataFormatValue = url.searchParams.get("dataFormat")

            getURL = url.includes("?dataFormat=" + dataFormatValue) ? getURL.replace("?dataFormat=" + dataFormatValue, "") : getURL.replace("&dataFormat=" + dataFormatValue, "")
        }

        return getURL
    }

    getRows = (data) => {
        const columnNames = this.getColumnNames(data)

        let rows = [{rowId: "header", cells: columnNames.map(colName => ({ type: "header", text: colName }))}]

        for (let i=0; i<data.length; i++){
            let cells = [];
            for (let j=0; j < columnNames.length; j++){
                cells.push({type: "text", text: data[i][columnNames[j]].toString()})
            }

            rows.push({rowId: i, cells: cells})
        }

        return rows
    }

    getColumnNames = (data) => {
        let columnNames;

        if (data.length === 0){
            columnNames = ["Column 1"]
        } else{
            columnNames = Object.keys(data[0])
        }

        return columnNames
    }

    getColumns = (data) => (this.getColumnNames(data).map(colName => ({ columnId: colName, width: 150, resizable: true})))

    handleColumnResize = (id, widthNumber) => {
        const allColumnInfo = [...this.state.columnInfo];

        for (let i=0; i<allColumnInfo.length; i++){
            const colInfo = allColumnInfo[i];

            if (colInfo.columnId === id){
                colInfo.width = widthNumber
                allColumnInfo[i] = colInfo
            }
        }

        this.setState({ columnInfo: allColumnInfo })
    }

    render() {
        let body = null;
        const isFilesPage = 'files' in this.props;

        if (this.state.fetching){
            body = <div>
                <Loader
                    type="TailSpin"
                    color="black"
                    height="50"
                    width="50"
                />
                <h4>Getting your data...</h4>
            </div>
        } else {
            if (this.state.data !== null && typeof(this.state.data) !== "string"){
                body = <div style={{overflowX: "scroll", width: "100%", display: "block"}}>
                        <ReactGrid rows={this.getRows(this.state.data)}
                                   columns={this.state.columnInfo}
                                   onColumnResized={this.handleColumnResize}
                        />
                </div>
            } else {
                let msg;
                if (typeof(this.state.data) === "string"){
                    msg = this.state.data
                } else { msg = "Error getting your data" }

                body = <div>
                    <div style={{fontSize: "20px", fontWeight: "700", color: gunaldiStyles.red}}>{msg}</div>
                </div>
            }
        }
        return (
            <div>
                {
                    isMobile || isFilesPage ? null :
                        <div style={{paddingTop: "12px", paddingLeft: isMobile ? "8px" : "100px"}}>
                            <Image style={{cursor: 'pointer'}} src={images.logoNav} href="/" size="small"/>
                        </div>
                }
                <div style={!isFilesPage ? {padding: isMobile ? '16px 8px' : '40px 100px'} : null}>
                    {body}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(TableIndex)