import React, {Component} from 'react'
import TogglePrivate from "../../../../Guides/GuidesText/Read/TogglePrivate"
import { Label } from 'semantic-ui-react'
import DocsBaseURL from "../../../DocsPage/DocsBaseURL"
import SampleTable from "../../../../Guides/GuidesText/SampleTable"
import JSONDisplay from "../../../../SharedComponents/JSONDisplay"
import PostPrivateHeaders from "../../../../Guides/GuidesText/PostPrivateHeaders";
import {createFileInformation} from "../../../../Guides/GuidesText/Functions/createFileInformation";
import {createFullURLFromBase} from "../../../../Constants/createFullURLFromBase";
import CodeSnippet from "../../../../FileDnD/CodeSnippet";
import {createCodeSnippets} from "../../../../Guides/GuidesText/Functions/createCodeSnippets";

class UpdateRows extends Component {
    constructor(props){
        super(props);

        this.state = {
            sampleTableData: [
                [1, "Justin Trudeau", 252069.46, "Canada"],
                [2, "Jacinda Ardern", 339862.25, "New Zealand"],
                [3, "Boris Johnson", 187033.75, "UK"],
                [4, "Joko Widodo", 27200.8, "Indonesia"],
                [5, "Angela Merkel", 369727.4, "Germany"]
            ],
            updatedTableData: [
                [1, "Justin Trudeau", {style: {backgroundColor: "lightgreen", color: 'green'}, value: 0}, "Canada"],
                [2, "Jacinda Ardern", {style: {backgroundColor: "lightgreen", color: 'green'}, value: 0}, "New Zealand"],
                [3, "Boris Johnson", {style: {backgroundColor: "lightgreen", color: 'green'}, value: 0}, "UK"],
                [4, "Joko Widodo", {style: {backgroundColor: "lightgreen", color: 'green'}, value: 0}, "Indonesia"],
                [5, "Angela Merkel", {style: {backgroundColor: "lightgreen", color: 'green'}, value: 0}, "Germany"]
            ],
            updatedTableData2Col: [
                [1, "Justin Trudeau", {style: {backgroundColor: "lightgreen", color: 'green'}, value: 0}, {style: {backgroundColor: "lightgreen", color: 'green'}, value: "NA"}],
                [2, "Jacinda Ardern", {style: {backgroundColor: "lightgreen", color: 'green'}, value: 0}, {style: {backgroundColor: "lightgreen", color: 'green'}, value: "NA"}],
                [3, "Boris Johnson", {style: {backgroundColor: "lightgreen", color: 'green'}, value: 0}, {style: {backgroundColor: "lightgreen", color: 'green'}, value: "NA"}],
                [4, "Joko Widodo", {style: {backgroundColor: "lightgreen", color: 'green'}, value: 0}, {style: {backgroundColor: "lightgreen", color: 'green'}, value: "NA"}],
                [5, "Angela Merkel", {style: {backgroundColor: "lightgreen", color: 'green'}, value: 0}, {style: {backgroundColor: "lightgreen", color: 'green'}, value: "NA"}]
            ],
            sampleTableHeaders: ["id", "name", "salary_$", "country"],
            sampleDataType: ["num", "str", "num", "str"],
            isPrivate: false,
            dataFormat: "row"
        }

        this.togglePrivate = this.togglePrivate.bind(this);
        this.changeState = this.changeState.bind(this);
    }

    togglePrivate(e){
        this.setState({ isPrivate: !this.state.isPrivate })
    }

    changeState(name, value){
        this.setState({
            [name]: value
        })
    }

    render() {
        let baseGetURL = 'data/1023/'
        const sampleFileInformation1 = createFileInformation([[0]], ["salary_$"], ["num"], 1023, "World Leaders - Updated Table", this.state.isPrivate, createFullURLFromBase('data/1023'))
        const info1AccessKey = sampleFileInformation1["1023_1"].accessKey;
        const info1SecretKey = sampleFileInformation1["1023_1"].secretKey;

        return (
            <div>
                <div className="row">
                    <div className="col-sm-6">
                        <SampleTable
                            tableData={this.state.sampleTableData}
                            tableHeaders={this.state.sampleTableHeaders}
                            header={<span style={{color: 'orange'}}>World Leaders - Current Table</span>}
                        />
                    </div>
                    <div className="col-sm-6">
                        <SampleTable
                            tableData={this.state.updatedTableData}
                            tableHeaders={this.state.sampleTableHeaders}
                            header="World Leaders - Updated Table"
                        />
                    </div>
                </div>
                <br/><br/>
                <h2>Make a POST request to this URL <TogglePrivate isPrivate={this.state.isPrivate} togglePrivate={this.togglePrivate}/></h2>
                <Label color="orange">POST</Label>
                <DocsBaseURL url={baseGetURL} />
                <br/>
                <JSONDisplay
                    heading="Include a JSON Encoded Body in your Request"
                    jsonCode={'{\n\t"data": {\n\t\t"salary_$": 0\n\t}, \n\t"query": "SELECT*FROM1023"\n}'}
                />
                <br/>
                {
                    this.state.isPrivate ?
                        <div>
                            <PostPrivateHeaders
                                accessKeyInfo={info1AccessKey}
                                secretKeyInfo={info1SecretKey}
                            />
                            <br/>
                        </div> : null
                }
                <CodeSnippet
                    code={createCodeSnippets(sampleFileInformation1, "updateAction", createFullURLFromBase(baseGetURL), "columnValue", true, "SELECT*FROM1023")}
                    height={100}
                />
                <br/><br/><br/>
            </div>
        )
    }
}

export default UpdateRows