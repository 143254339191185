import React, {Component} from 'react'
import { Label, Icon } from 'semantic-ui-react'
import isObjectEmpty from "../Constants/isObjectEmpty";

class FilesTabSharedLabel extends Component {
    render() {
        const fileInformation = this.props.fileInformation;
        let teamLabel = null;
        let importLabel = null;

        const isTeam = "isTeam" in fileInformation ? fileInformation.isTeam : false;
        const isImported = 'importSheetsFK' in fileInformation ? fileInformation['importSheetsFK'] !== -1 : false
        const isImportedByMe = 'importSheetsFK' in fileInformation ? fileInformation['importSheetsFK'] === -1 : false

        let uploadedBy = "Me"
        importLabel = <p> {uploadedBy}</p>

        if (isTeam){
            importLabel = <p> <Icon name="users"/> team</p>
        }

        if (isImported){
            let uploadedBy = "";

            if ('importSheetsInfo' in fileInformation){
                if (!isObjectEmpty(fileInformation.importSheetsInfo)){
                    if ("importName" in fileInformation.importSheetsInfo){
                        if (fileInformation.importSheetsInfo.importName !== null){
                            uploadedBy = fileInformation.importSheetsInfo.importName
                        }
                    }
                }
            }

            importLabel = <p> {uploadedBy}</p>
        }

        if (isImportedByMe){

        }

        return (
            <p style={{color:'#5E645E',lineHeight:'17px',width:'auto',fontWeight:'400',fontSize:'14px'}}>{importLabel}</p>
        )
    }
}

export default FilesTabSharedLabel