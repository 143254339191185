import React, {Component} from 'react'
import {connect} from 'react-redux'
import KeysModalBody from "./KeysModalBody"
import { Button } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {mapPkAndPkid} from "../../Constants/mapPkAndPkid";
import KeysSegment from "./KeysSegment";
import Loader from 'react-loader-spinner'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

class CustomKeysModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newKeyPairs: [],
            adding: false,
            addingError: false,
            gettingAddlPairs: false,
            gettingAddlPairsError: false
        }
    }

    componentDidMount(){
        this.setState({ gettingAddlPairs: true })
        const error = () => this.setState({ gettingAddlPairs: false, gettingAddlPairsError: true })

        fetch(API_Root + "api/get-additional-key-pairs-for-api-spreadsheets/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                filePK: mapPkAndPkid(this.props.filePK, "id", this.props.editID)
            })
        }).then((res) =>
        {
            if (res.status === 200){
                res.json().then(data => {
                    this.setState({
                        newKeyPairs: data["allKeys"],
                        gettingAddlPairs: false,
                        gettingAddlPairsError: false
                    })
                }).catch(err => error())
            } else {
                error()
            }
        }).catch(err => error());
    }

    updateKeyPairs = (idx, newKeyPair) => {
        const currKeyPairs = JSON.parse(JSON.stringify(this.state.newKeyPairs))
        currKeyPairs[idx] = newKeyPair

        this.setState({
            newKeyPairs: currKeyPairs
        })
    }

    addKeyPair = () => {
        this.setState({ adding: true })
        const error = () => this.setState({ adding: false, addingError: true })

        fetch(API_Root + "api/create-additional-key-pairs-for-api-spreadsheets/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                filePK: mapPkAndPkid(this.props.filePK, "id", this.props.editID)
            })
        }).then((res) =>
            {
                if (res.status === 201){
                    res.json().then(data => {
                        const currNewKeyPairs = JSON.parse(JSON.stringify(this.state.newKeyPairs))
                        currNewKeyPairs.push(data)
                        this.setState({
                            newKeyPairs: currNewKeyPairs,
                            adding: false,
                            addingError: false
                        })
                    }).catch(err => error())
                } else {
                    error()
                }
        }).catch(err => error());
    }

    render() {
        // const keySegmentStyle = {border: "1px solid lightgrey", borderRadius: "10px", padding: "10px", marginBottom: "30px", boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)',}
        const keySegmentStyle = {border: "1px solid #F0F0F0", borderRadius: '4px', padding: "16px"}

        let newKeys = null;
        if (this.state.newKeyPairs.length > 0){
            newKeys = this.state.newKeyPairs.map((x, idx) => <div style={{marginTop: '16px'}}><KeysSegment updateKeyPairs={this.updateKeyPairs} idx={idx} key={idx.toString()} fileAccessKey={x.accessKey} fileSecretKey={x.secretKey} keysPK={x.keysPK}/></div>)
        }

        let error = null;
        if (this.state.addingError){
            error = <h4 style={{color: "red", border: "1px solid lightgrey", borderRadius: "10px", padding: "10px", marginBottom: "30px"}}>There was an error creating another key pair. Please try again or email us at info@apispreadsheets.com if the error persists</h4>
        }

        let gettingAddlPairs = null;
        if (this.state.gettingAddlPairs){
            gettingAddlPairs = <div style={{padding: '100px 0', textAlign: 'center'}}>
                <Loader
                    type="TailSpin"
                    color="black"
                    height="50"
                    width="50"
                />
            </div>
        }

        const headerStyle = {color: "#161E16", fontFamily: "Lato", fontWeight: "700", fontSize: "14px", marginBottom: "16px"}

        return (
            <div>
                <br/>
                <div style={keySegmentStyle}>
                    <div style={headerStyle}>Primary Key Pair</div>
                    <KeysModalBody custom />
                </div>
                {gettingAddlPairs}
                {newKeys}
                <div style={{marginTop: "24px"}}>
                    <Button style={gunaldiStyles.btnBlue} loading={this.state.adding} color="green" icon="plus" content="Add Another Key Pair" onClick={this.addKeyPair}/>
                </div>
                {error}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    filePK: state.mainState.filePK,
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CustomKeysModal)