import React, {Component} from 'react'
import { Icon, Dropdown } from 'semantic-ui-react'

class QueryHelpBody extends Component {
    constructor(props){
        super(props);

        this.state = {
            queryType: "readAction"
        }
    }

    toggleQueryType = (e, { value }) => this.setState({ queryType: value })

    render() {
        // TODO: Insert examples for all the different scenarios
        const isRead = this.state.queryType === "readAction";

        return (
            <div>
                {'modal' in this.props ? <h2><Icon name="code" /> How to Write Queries</h2> : null}
                <div>
                    Choose Action:
                    <span style={{paddingLeft: "12px", fontWeight: "bold"}}>
                        <Dropdown
                            placeholder="Choose Action Type"
                            name={"databaseQueryRules"}
                            value={this.state.queryType}
                            options={[{key: 1, text: 'Read', value: 'readAction'}, {key: 2, text: 'Create', value: 'createAction'}]}
                            onChange={this.toggleQueryType}
                        />
                    </span>
                </div>
                <br/>
                <p>You can write queries like you normally do with Postgres or MySQL with {isRead ? "one": "two"} exception</p>
                <p style={{marginBottom: "0px"}}>1. All Queries MUST begin with <b>SELECT</b></p>
                {!isRead ? <p style={{marginBottom: "0px"}}>2. Only <b>ONE</b> table name is allowed in the query</p> : null}
                {!isRead ? <div style={{border: "1px solid #F0F0F0", padding: "8px", borderRadius: "4px", fontSize: "12px"}}>
                    <b>NOTE:</b> the where clauses, and column names you specify in your query <b>DO NOT</b> affect data rows being added. You can add rows for any columns in the <b>ONE</b> table in your query. This format exists so you can use the same API for reading, and creating data.
                </div> : null}
                <div className="row">
                    <div className="col-sm-6">
                        <div className="codeExampleBox">
                            <b style={{color: 'green', display: 'block'}}>VALID QUERY</b>
                            <code><b>SELECT</b> * <b>FROM</b> Customers <b>WHERE</b> Country='Mexico';</code>
                            {!isRead ? <div><i>You will be able to add row(s) of data at the end of <b>Customers</b> table. Even if Country!='Mexico'</i></div> : null}
                        </div>
                        <br/>
                        <div className="codeExampleBox">
                            <b style={{color: 'green', display: 'block'}}>VALID QUERY</b>
                            <code><b>SELECT</b> CustomerName, Email <b>FROM</b> Customers <b>WHERE</b> Country='Mexico';</code>
                            {!isRead ? <div><i>You will be able to add row(s) of data at the end of <b>Customers</b> table for <b>ANY</b> columns you wish in the table. Not just <i>CustomerName</i> or <i>Email</i></i></div> : null}
                        </div>
                        <br/>
                        <div className="codeExampleBox">
                            <b style={{color: 'green', display: 'block'}}>VALID QUERY</b>
                            <code>
                                <b>SELECT</b> CustomerName, City, Country
                                <br/>
                                <b>FROM</b> Customers
                                <br/>
                                <b>ORDER BY</b>
                                <br/>
                                (<b>CASE</b>
                                <br/>
                                <b>WHEN</b> City IS NULL <b>THEN</b> Country <b>ELSE</b> City <br/><b>END</b>);
                            </code>
                            {!isRead ? <div><i>You will be able to add row(s) of data at the end of <b>Customers</b> table for <b>ANY</b> columns you wish in the table</i></div> : null}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="codeInvalidBox">
                            <b style={{color: 'red', display: 'block'}}>INVALID QUERY</b>
                            <code>
                                <b>SELECT * FROM</b> customers, emails <b>WHERE</b> customers.id=emails.customer_id;
                            </code>
                            {!isRead ? <div><i>Multiple table names make this invalid</i></div> : null}
                        </div>
                        <br/>
                        <div className="codeInvalidBox">
                            <b style={{color: 'red', display: 'block'}}>INVALID QUERY</b>
                            <code>
                                <b>INSERT INTO</b> Customers (City, Country)
                                <br/>
                                <b>VALUES</b> ('Stavanger', 'Norway');
                            </code>
                            {!isRead ? <div><i>Query must begin with select</i></div> : null}
                        </div>
                        <br/>
                        <div className="codeInvalidBox">
                            <b style={{color: 'red', display: 'block'}}>INVALID QUERY</b>
                            <code>
                                <b>UPDATE</b> Customers
                                <br/>
                                <b>SET</b> ContactName='Juan'
                                <br/>
                                <b>WHERE</b> Country='Mexico';
                            </code>
                            {!isRead ? <div><i>Query must begin with select</i></div> : null}
                        </div>
                    </div>
                </div>
                <p> </p>
                <p>The values obtained from this query will then become your <b>Spreadsheet API</b></p>
                <p>The <b>Spreadsheet API</b> will be live connected to your database so any CRUD operation using the API will be based on the data obtained by the query at the moment of the API request</p>
            </div>
        )
    }
}

export default QueryHelpBody