import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import SocialMediaButtons from "./SocialMediaButtons";

class ThinFooterIndex extends Component {
    render() {
            return (
                <div className="footerIndex" style={{color: 'black', backgroundColor: 'whitesmoke'}}>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <div style={{paddingLeft: '5%'}}>
                                <p><span><Icon name="copyright outline"/> {((new Date()).getFullYear()).toString()} Woyera Inc</span>
                                    | <a href="/terms" target="_blank" rel="noopener noreferrer"> Terms &
                                        Conditions</a> | <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy
                                        Policy</a></p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div style={{textAlign: 'center'}}>
                                <a
                                    href="http://www.lovespreadsheets.com/" target="_blank" rel="noopener noreferrer">
                                    Part of <span role="img" aria-label="heart">❤️</span> spreadsheets!
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div style={{paddingRight: '5%', float: 'right'}}>
                                <SocialMediaButtons/>
                            </div>
                        </div>
                    </div>
                    <br/>
                </div>
            )

    }
}

export default ThinFooterIndex