import React, {Component} from 'react'
import { Table } from 'semantic-ui-react'

class SampleStartTable extends Component {
    render() {
        return (
            <div>
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='3'>Original Data</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>
                                id
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                name
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                paid
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                1
                            </Table.Cell>
                            <Table.Cell>
                                ABC Inc.
                            </Table.Cell>
                            <Table.Cell>
                                Yes
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                2
                            </Table.Cell>
                            <Table.Cell>
                                Mom and Pop
                            </Table.Cell>
                            <Table.Cell>
                                Yes
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                3
                            </Table.Cell>
                            <Table.Cell>
                                Ceylon LLC
                            </Table.Cell>
                            <Table.Cell>
                                No
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default SampleStartTable