import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Popup, Button, Icon } from 'semantic-ui-react'
import PageHeaderButton from "../../SharedComponents/PageHeaderButton"
import API_Root from "../../Constants/API_Root";
import {toggleModal} from "../../Actions/ToggleModal";
import {push} from "react-router-redux";
import {modifyTeamInfo} from "../../Actions/ModifyTeamInfo";
import {toggleToast} from "../../Actions/ToggleToast";

class TeamInfoRemoveButton extends Component {
    constructor(props) {
        super(props);

        const teamIdx = 'teamIdx' in this.props ? this.props.teamIdx : this.props.modalInfo.teamIdx

        this.state = {
            isAdmin: this.props.teamInfo[teamIdx].admin_email === localStorage.getItem("email").trim(),
            popupOpen: false,
            deleting: false,
            url: "",
            teamIdx: teamIdx
        }
    }

    deleteTeam = (e) => {
        this.setState({ deleting: true })

        fetch(API_Root + "api/delete-api-team/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                teamPK: this.props.teamInfo[this.state.teamIdx].id,
            })
        }).then((res) => {
            if (res.status === 200){
                this.setState({
                    deleting: false
                }, () => this.finishDeletingTeam())
            } else {
                this.props.toggleToast({show: true, type: "error", message: "There was an error deleting your team! Try again"})
            }
        }).catch(err => this.props.toggleToast({show: true, type: "error", message: "There was an error deleting your team! Try again"}))
    }

    leaveTeam = (e) => {
        this.setState({ deleting: true })

        fetch(API_Root + "api/remove-add-or-edit-api-team-member/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                inviteEmail: localStorage.getItem("email"),
                role: this.props.teamInfo[this.state.teamIdx].all_users[localStorage.getItem("email")].role,
                adminAction: false,
                operation: "decline",
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                teamPK: this.props.teamInfo[this.state.teamIdx].id,
            })
        }).then((res) => {
            if (res.status === 200){
                this.setState({
                    deleting: false
                }, () => this.finishDeletingTeam())
            } else {
                this.props.toggleToast({show: true, type: "error", message: "There was an error deleting your team! Try again"})
            }
        }).catch(err => this.props.toggleToast({show: true, type: "error", message: "There was an error deleting your team! Try again"}))
    }


    togglePopup = (e, toggle) => {
        this.setState({
            popupOpen: toggle
        })
    }

    finishDeletingTeam = () => {
        this.props.toggleModal(null)
        // this.props.navigateTo("/teams")
        const currTeamInfo = JSON.parse(JSON.stringify(this.props.teamInfo))
        currTeamInfo.splice(this.props.idx, 1)

        this.props.modifyTeamInfo(currTeamInfo)
    }

    render() {
        return (
            <Popup
                trigger={
                    "displayTeamIndex" in this.props ?
                    <Icon style={{width:'6px',height:'8px', cursor: "pointer"}} size="large" onClick={(e) => this.togglePopup(e, true)} basic color='red' name='trash alternate outline' className="teamTabRemoveFileBtn" /> :
                    <PageHeaderButton
                        loading={this.state.deleting}
                        content={this.state.isAdmin ? "Delete Team" : "Leave Team"}
                        color="red"
                        icon={this.state.isAdmin ? "trash alternate outline" : "sign-out"}
                        onClick={(e) => this.togglePopup(e, true)}
                    />}
                on="click"
                position="bottom"
                // onOpen={(e) => this.togglePopup(e, true)}
                onClose={(e) => this.togglePopup(e, false)}
                open={this.state.popupOpen}>
                <div style={{padding: '10px'}}>
                    <h4 style={{color: 'red'}}>
                        {this.state.isAdmin ? "Are you sure you want to delete this Team?" : "Are you sure you want to leave this Team?"}
                    </h4>
                    <p style={{color: 'red'}}>YOU CANNOT UNDO THIS ACTION!</p>
                    <div className="row">
                        <div className="col-sm-6 teamTabRemoveFileBtn">
                            <Button color="green" content="No" onClick={(e) => this.togglePopup(e, false)} fluid />
                        </div>
                        <div className="col-sm-6 teamTabRemoveFileBtn">
                            <Button color="red" loading={this.state.deleting} content="Yes" onClick={this.state.isAdmin ? this.deleteTeam : this.leaveTeam} fluid />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo,
    teamInfo: state.mainState.teamInfo
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyTeamInfo: modifyTeamInfo,
    navigateTo: push,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(TeamInfoRemoveButton)