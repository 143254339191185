import React, {Component} from 'react'
import { Image } from 'semantic-ui-react'
import localFileIcon from './localFileIcon.svg'
import database from "./database.svg"
import dropbox from "./dropbox.png"
import quickstart from "./quickstart.svg"
import sheets from "./sheets.svg"
import aws from "./aws.svg"

class FilesTabFileSource extends Component {
    render() {
        const inputType = 'inputType' in this.props.fileInformation ? this.props.fileInformation['inputType'] : null;

        if (inputType === null){
            return (
                null
            )
        }
        else{
            let iconName = null;
            let color = null;

            if (inputType.includes("file")){
                iconName = localFileIcon

            }
            else if (inputType.includes("dropbox")){
                iconName = dropbox
                color = "blue"

            }
            else if (inputType.includes("google")){
                iconName = sheets
                color = "green"

            }
            else if (inputType.includes("s3")) {
                iconName = aws
                color = "yellow"

            }
            else if (inputType.includes("quickstart")){
                iconName = quickstart
                color = "black"
            }
            else if (inputType.includes("database")){
                iconName = database
                color = "purple"
            }

            if ("filesIndex" in this.props){
                return (
                        <Image src={iconName} alt={iconName} avatar />
                )
            } else {
                return(
                    <span style={{marignLeft: "-5px", height:'44px',width:'44px',backgroundColor:'#F0F0F0',borderRadius:'40px',display:'inline-block',alignItems:'center'}}>
                        <img style={{height:'80%',width:'80%', paddingLeft:'10px',paddingTop:'10px'}} src={iconName}/>
                   </span>
                )
            }
        }
    }
}

export default FilesTabFileSource