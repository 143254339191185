import React, {Component} from 'react'
import ServerCluster from "../../Illustrations/ServerCluster.svg"
import ImporterFeature7 from "../../Illustrations/ImporterFeatures/ImporterFeature7.svg"

class ImportWebhooks extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}> Connect a Webhook </div>
                <div style={this.props.subheadingStyle}>A Webhook (your own REST API URL) will get called after each import. The world is your oyster after connecting a webhook</div>
                <div style={{textAlign: "center"}}>
                    <img src={ImporterFeature7} alt="Server Cluster"  style={{width: "50%"}}/>
                </div>
            </div>
        )
    }
}

export default ImportWebhooks