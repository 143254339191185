import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import API_Root from "../../../../Constants/API_Root";
import getURLParam from "../../../../Constants/getURLParam";
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";

class SaveDestinationsBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false
        }
    }

    save = () => {
        this.setState({saving: true});
        const error = () => {
            this.setState({saving: false})
            alert("There was an error saving your destinations. Please try again. Contact our support if this persists")
        }

        const splitParam = getURLParam(window.location.href).split("_")
        const importKey = splitParam[0];

        const destinationsEditCopy = JSON.parse(JSON.stringify(this.props.destinationsEdit))
        delete destinationsEditCopy['googleAccountInfo']
        delete destinationsEditCopy['dropboxAccountInfo']
        delete destinationsEditCopy['s3AccountInfo']

        fetch(API_Root + "api/update-import-details/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                importHash: importKey,
                changeObject: {destinations: destinationsEditCopy}
            })
        }).then((res) => {
            if (res.status === 200) {
                this.updateDestinationsInRedux()
            }
            else {
                error()
            }
        }).catch(err => error())
    }

    updateDestinationsInRedux = () => {
        const destinations = JSON.parse(JSON.stringify(this.props.destinations))

        for (let key in this.props.destinationsEdit){
            destinations[key] = this.props.destinationsEdit[key]
        }

        this.props.modifyCustomImportSettings("destinations", destinations)

        this.setState({ saving: false })
    }

    render() {
        return (
            <div>
                <Button
                    loading={this.state.saving}
                    content="Save Destinations"
                    icon="save alternate outline"
                    color="green"
                    onClick={this.save}
                    disabled={!this.props.hasInfoChanged}
                    fluid
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    destinationsEdit: state.mainState.customImportDestinationsEdit,
    destinations: state.mainState.customImportDestinations
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(SaveDestinationsBtn)