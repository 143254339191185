import React, {Component} from 'react'
import {Dropdown} from "semantic-ui-react";
import InfoPopup from "../../../../SharedComponents/InfoPopup";
import DatetimeCleaningFunctions from "./CleaningFunctionsBody/DatetimeCleaningFunctions";
import NumericalCleaningFunctions from "./CleaningFunctionsBody/NumericalCleaningFunctions";
import TextCleaningFunctions from "./CleaningFunctionsBody/TextCleaningFunctions";

class ColumnAdditionalInfoModalBodyCleaningFunctionsIndex extends Component {
    render() {
        if(this.props.dataType === "datetime" || this.props.dataType === "numerical" || this.props.dataType === "text"){
            let body = null;
            if (this.props.dataType === "datetime"){
                body = <DatetimeCleaningFunctions columnIdx={this.props.columnIdx}/>
            } else if (this.props.dataType === "numerical"){
                body = <NumericalCleaningFunctions columnIdx={this.props.columnIdx}/>
            } else if (this.props.dataType === "text"){
                body = <TextCleaningFunctions columnIdx={this.props.columnIdx} />
            }

            return (
                <div>
                    {body}
                </div>
            )

        }else{
            return null
        }
    }
}


export default (ColumnAdditionalInfoModalBodyCleaningFunctionsIndex)