import React, {Component} from 'react'
// import { Accordion, Icon } from 'semantic-ui-react'

class DocsMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: -1
        }

        this.changeAPIVersion = this.changeAPIVersion.bind(this);
        this.handleAccordionClick = this.handleAccordionClick.bind(this);
    }

    changeAPIVersion(e, apiVersion){
        this.props.changeAPIVersion(apiVersion);
    }

    handleAccordionClick(e, titleProps){
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render() {
        // const activeIndex = this.state.activeIndex;
        // const previousIcon = activeIndex === 0 ? "angle down" : "angle right";

        return (
            <div className="docsMenu" style={{textAlign: 'center'}}>
                <h5 className="thinHeading">Current API Version</h5>
                <p style={{color: 'green', fontWeight: '900', fontSize: '200%', cursor: 'pointer'}}
                   onClick={(e) => this.changeAPIVersion(e, "2020-03-11")}>2020-03-11</p>
                <br/>
                {/*<Accordion>*/}
                    {/*<Accordion.Title*/}
                        {/*active={activeIndex === 0}*/}
                        {/*index={0}*/}
                        {/*onClick={this.handleAccordionClick}*/}
                    {/*>*/}
                        {/*<h5 className="thinHeading"> <Icon name={previousIcon} /> Previous API Versions </h5>*/}
                    {/*</Accordion.Title>*/}
                    {/*<Accordion.Content active={activeIndex === 0}>*/}
                        {/*<p className="thinHeading"*/}
                           {/*style={{textAlign: 'center', cursor: 'pointer'}}*/}
                           {/*onClick={(e) => this.changeAPIVersion(e, "2019-10-14")}>2019-10-14</p>*/}
                        {/*<p className="thinHeading"*/}
                           {/*style={{textAlign: 'center', cursor: 'pointer', marginTop: '-20px'}}*/}
                           {/*onClick={(e) => this.changeAPIVersion(e, "2019-07-12")}>2019-07-12</p>*/}
                    {/*</Accordion.Content>*/}
                {/*</Accordion>*/}

            </div>
        )
    }
}

export default DocsMenu