import React, {Component} from 'react'
import GuideDetails from "../GuideDetails";

class GuidesDisplayIndex extends Component {
    render() {
        const guideInfo = GuideDetails[this.props.topic];

        const title = guideInfo.header;
        const desc = guideInfo.desc;

        return (
            <div className="guidePost">
                <h1 className="thinHeading">{title}</h1>
                <h6 className="thinHeading" style={{color: 'gray'}}>{desc}</h6>
                <br/>
                {guideInfo.article}
            </div>
        )
    }
}

export default GuidesDisplayIndex