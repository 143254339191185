import React, {Component} from 'react'
import SelectOutputType from "../../SelectOutputType"
import ChangeRowLength from "../../ChangeRowLength"
import { Checkbox } from 'semantic-ui-react'
import {connect} from "react-redux";
import {toggleModal} from "../../../Actions/ToggleModal";
import {modifyEditID} from "../../../Actions/ModifyEditID";
import {modifyModalQueryQuickstartType} from "../../../Actions/ModifyModalQueryQuickstartType";

class ReadOptionsToolbar extends Component {
    constructor(props) {
        super(props);

        this.changeState = this.changeState.bind(this);
        this.toggle = this.toggle.bind(this);
        this.openQueryModal = this.openQueryModal.bind(this);
    }

    changeState(name, value){
        this.props.changeState(name, value)
    }

    toggle(e, stateName){
        this.props.changeState(stateName, stateName === "readQuery" ? !this.props.readQuery : !this.props.count)
    }

    openQueryModal(e){
        this.props.modifyEditID(this.props.pkID)
        this.props.modifyModalQueryQuickstartType("readAction")
        this.props.toggleModal("queryQuickstartModal")
    }

    render() {
        return (
            <div>
                <div className="fileSummaryCodeToolbar">
                    <SelectOutputType idx={this.props.idx} outputFormat={this.props.outputFormat} changeState={this.changeState} />
                </div>
                <div style={{display: "grid", gridTemplateColumns: "auto auto auto", columnGap: "16px"}}>
                    <div className="boxOutline">
                        <span>
                            <Checkbox checked={this.props.readQuery}
                                      toggle
                                      onChange={e => this.toggle(e, "readQuery")}
                                      label="Query data" />
                            <span className="linkStyle" onClick={this.openQueryModal}>  Read how </span>
                        </span>
                    </div>
                    <div className="boxOutline">
                        <Checkbox checked={this.props.count}
                                  toggle
                                  onChange={e => this.toggle(e, "count")}
                                  label="Count Rows" />
                    </div>
                    <div className="boxOutline">
                        <ChangeRowLength pkID={this.props.pkID} rowLen={this.props.rowLen} rows={this.props.rows} changeState={this.changeState} apiType={this.props.apiType} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyEditID: modifyEditID,
    modifyModalQueryQuickstartType: modifyModalQueryQuickstartType
}

export default connect(null, mapActionsToProps)(ReadOptionsToolbar)