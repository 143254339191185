import React, {Component} from 'react'
import { Button } from 'semantic-ui-react'

class ColumnNameEdit extends Component {
    constructor(props){
        super(props);

        this.state = {
            saveDisabled: false
        }

        this.modifyColumnName = this.modifyColumnName.bind(this);
        this.changeColumnName = this.changeColumnName.bind(this);
        this.undo = this.undo.bind(this);
    }

    modifyColumnName(e){

        this.props.changeEditColumnName(e.target.value);
        const saveDisabled = isColumnNameDuplicate(this.props.allColumnNamesEdit, e.target.value, this.props.idx)

        this.setState({
            saveDisabled: saveDisabled
        })
    }

    changeColumnName(){
        this.props.changeColumnName(this.props.allColumnNamesEdit[this.props.idx].trim())
        this.props.toggle(null)
    }

    undo(){
        this.props.changeEditColumnName(this.props.allColumnNames[this.props.idx]);
        this.props.toggle(null)
    }


    render() {
        const duplicateWarning = this.state.saveDisabled ? <small style={{color: 'red'}}><br/>Duplicate name not allowed</small> : null;
        const colName = this.props.allColumnNamesEdit[this.props.idx];

        return (
            <span>
                <input onChange={this.modifyColumnName} value={colName} type="text" />
                <span style={{float: 'right'}}>
                    <Button  circular icon="check" color="green" basic onClick={this.changeColumnName} disabled={this.state.saveDisabled}/>
                    <Button  circular icon="times" color="red" basic onClick={this.undo} />
                </span>
                {duplicateWarning}
            </span>
        )
    }
}

const isColumnNameDuplicate = (allColumnNames, currColumnName, idx) => {
    let count = 0;
    for (let i=0; i<allColumnNames.length; i++){
        if (i === idx){
            count += 1
        }
        else if (currColumnName.toLowerCase().trim() === allColumnNames[i].toLowerCase().trim()){
            count += 1
        }

        if (count > 1){
            return true
        }
    }

    return false
}

export default ColumnNameEdit