import React, {Component} from 'react'
import { Checkbox } from 'semantic-ui-react'

class ColumnConvert extends Component {
    toggle = () => {
        this.props.changeState("convert", !this.props.convert)
    }

    render() {
        return (
            <div style={this.props.checkBoxStyle}>
                <Checkbox checked={this.props.convert} onChange={this.toggle}/>
            </div>
        )
    }
}

export default ColumnConvert