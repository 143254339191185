import React, {Component} from 'react'
import DocsGetIndex from "./DocsGetIndex";
import DocsBaseURL from "./DocsBaseURL";
import { Menu, Segment } from 'semantic-ui-react';
import DocsPostIndex from "./DocsPostIndex";

class DocsDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayType: 'GET Data'
        }

        this.handleItemClick = this.handleItemClick.bind(this);
    }

    handleItemClick(e, { name }){
        this.setState({
            displayType: name
        })
    }

    render() {
        const activeItem = this.state.displayType;

        return (
            <div>
                <h3>API Version: <span style={{color: 'green'}}>{this.props.apiVersion} <i style={{color: 'gray'}}>backwards compatible</i></span></h3>
                <br/>
                <h3>Base URL </h3>
                <DocsBaseURL url="data/"/>
                <br/>
                <Menu attached='top' tabular>
                    <Menu.Item
                        name='GET Data'
                        active={activeItem === 'GET Data'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        name='POST Data'
                        active={activeItem === 'POST Data'}
                        onClick={this.handleItemClick}
                    />
                </Menu>
                <Segment attached='bottom'>
                    {activeItem === "GET Data" ? <DocsGetIndex/> : <DocsPostIndex/>}
                </Segment>
                <br/><br/><br/><br/>
            </div>
        )
    }
}

export default DocsDisplay