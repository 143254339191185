import React, {Component} from 'react'
import {connect} from 'react-redux'
import Icons from "../../../Constants/Icons"
import { Icon } from "semantic-ui-react"
import {toggleModal} from "../../../Actions/ToggleModal";
import {modifyModalInfo} from "../../../Actions/ModifyModalInfo";
import {modifyTutorialStep} from "../../../Actions/ModifyTutorialStep";

class CRUDBox extends Component {
    constructor(props) {
        super(props);

        this.openCRUDModal = this.openCRUDModal.bind(this);
    }

    openCRUDModal(e){
        if ('quickStart' in this.props){
            this.props.modifyTutorialStep(this.props.actionType + "Action")
        }
        else{
            this.props.modifyModalInfo({actionType: this.props.actionType})
            this.props.toggleModal("crudModal")
        }

    }

    render() {
        return (
          <div className="segment rounded-div-border click-hover-div featuresPageCrudBox" onClick={this.openCRUDModal}>
              <h4 className='thinHeading'> {this.props.actionType.toUpperCase()}</h4>
              <h1><Icon name={Icons[this.props.actionType + "Action"]} /></h1>
              {'quickStart' in this.props ? null : <span className="linkStyle"><i>Learn More</i></span>}
          </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo,
    modifyTutorialStep: modifyTutorialStep,

}

export default connect(mapStateToProps, mapActionsToProps)(CRUDBox)