import React, {Component} from 'react'

class QueryStatusTag extends Component {
    render() {
        const tagStyle = {
            borderRadius: "25px",
            padding: "2px 10px 2px 10px",
            textTransform: "uppercase",
            // margin: "1% 3% 1% 0"
        }

        let text;
        if (this.props.isSaved){
            tagStyle['color'] = "white"
            tagStyle['backgroundColor'] = "green"
            text = "Ready"
        } else {
            tagStyle['color'] = "white"
            tagStyle['backgroundColor'] = "orange"
            text = "In Progress"
        }

        return (
            <span style={tagStyle}>
                {text}
            </span>
        )
    }
}

export default QueryStatusTag