import React, {Component} from 'react'
import ColumnTransformTable from "./ColumnTransformTable";
import CustomImage from "../../SharedComponents/CustomImage"

class APIQuickstart extends Component {
    render() {
        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">Getting started using Spreadsheets as APIs consists of three major steps</p>
                    <ol>
                        <li>Upload your spreadsheet</li>
                        <li>Fix your Column Names (if needed)</li>
                        <li>Follow example code guides</li>
                    </ol>
                </div>
                <hr/>
                <div className="docsSection">
                    <p className="docsHeader">Upload your spreadsheet</p>
                    <p><span className="bold">Click on the appropriate dialog box and follow instructions to select a file</span><br/><span>You can select multiple files at a time as well</span></p>
                    <CustomImage src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/UploadFile.png" alt="Upload Connect Files" />
                    <p className='bold'>After the file is read, you will be shown your File page</p>
                    <p>If you uploaded multiple files, then you will be shown the list of all files. You can click on your file to go to your File page</p>
                    <img style={{width:'95%'}} src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/FileInfoPage.png" alt="File Information Box"  />
                </div>
                <hr/>
                <div className="docsSection">
                    <p className="docsHeader">Fix your Column Names (if needed)</p>
                    <p>Column Names are the crux of the tool and need to be used verbatim to perform any of the CRUD operations.</p>
                    <br/><br/>
                    <p className="bold">COLUMN NAMES MUST BE UNIQUE</p>
                    <ColumnTransformTable
                        transform="INVALID Column Names vs. VALID Column Names"
                        columnNames={[["ID", "First Name", "Revenue $", "First Name", "description"],
                            ["ID","First Name_1","Revenue $","First Name_2","description"]]}
                        changedCells={[1, 3]}
                    />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">What if my file doesn't have column names?</p>
                    <p>Click on <b>File Options</b> in the <b>Actions</b> menu</p>
                    <img style={{width:'35%'}} src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/MoreActions.png" alt="File Options Dropdown" width="auto"/>
                    <p>Deselect the <b>Does File Have Header Option?</b> and click on the <b>Read File</b> button</p>
                    <img style={{width:'35%'}} src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/FileHeader.png" alt="File Header Option" width="auto"/>
                    <p className="docsHeader">What if my column names are not on the first line?</p>
                    <p>You can enter the line of your column name in the <b>Start Row</b> field of the <b>File Options</b> and click on the <b>Read File</b> button</p>
                    <img  style={{width:'35%'}} src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/NumOfCols.png" alt="Start at Row" width="auto"/>
                </div>
                <hr/>
                <div className="docsSection">
                    <p className="docsHeader">Follow example code guides</p>
                    <p>You can get started easily from the File Information box itself.</p>
                        <ol>
                            <li>Select which operation you want to perform</li>
                            <li>Customize your operation selection</li>
                            <li>Pick your preferred language or get started with <b>cURL</b></li>
                        </ol>
                    <CustomImage src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Guides/QuickstartCode.png" alt="image host"/>
                </div>
            </div>
        )
    }
}

export default APIQuickstart