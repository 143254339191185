import React, {Component} from 'react'
import BannerAlert from "../../SharedComponents/BannerAlert";

const icons = {
    Google: "bx bxl-google",
    "AWS S3": 'bx bxl-aws',
    Dropbox: "bx bxl-dropbox"
}

class AccountsNotConnectedBanner extends Component {
    render() {
        return (
            <BannerAlert type="warning"
                         header={'You have no ' + this.props.accountType + ' accounts connected to API Spreadsheets'}
                         icon={icons[this.props.accountType]}
                         content={<span>You can connect your {this.props.accountType} account by uploading a spreadsheet from the <a href="/upload">upload</a> tab</span>}
            />
        )
    }
}

export default AccountsNotConnectedBanner