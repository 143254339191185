import React, {Component} from 'react'
import {connect} from 'react-redux'
import AuthComponent from "../SharedComponents/AuthComponent";
import UpdatedLandingPageIndex from "../LandingPage/UpdatedLandingPageIndex";
import ImportIndex from "./ImportIndex"
import {isBrowser} from "react-device-detect";
import ImporterBrowserLandingPageIndex from "../LandingPage/ImporterLandingPage/ImporterBrowserLandingPageIndex";
import ImporterMobileLandingPageIndex from "../LandingPage/ImporterLandingPage/ImporterMobileLandingPageIndex";

class ImportAuthIndex extends Component {
    render() {
        if (isBrowser){
            return (
                <div>
                    <AuthComponent
                        authComponent={<ImportIndex/>}
                        nonAuthComponent={<ImporterBrowserLandingPageIndex/>}
                    />
                </div>
            )
        } else {
            return (
                <div>
                    <AuthComponent
                        authComponent={<ImportIndex/>}
                        nonAuthComponent={<ImporterMobileLandingPageIndex/>}
                    />
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ImportAuthIndex)