export const isFileInAPIMode = (fileInfo) => {
    let isInAPIMode = false

    try{
        if (fileInfo['inputType'] === "file"){
            if ("isFileInAPIMode" in fileInfo){
                isInAPIMode = fileInfo['isFileInAPIMode']
                console.log(isInAPIMode)
            }
        } else {
            isInAPIMode = true
        }

    } catch (e) {

    }

    return isInAPIMode
}