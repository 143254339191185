import React, {Component} from 'react'
import ReactJson from 'react-json-view'

class DocsPostResponse extends Component {
    render() {
        const responseBody =
            {
                "data" : {
                    "paid": "Yes"
                },
                "query": "select*from1whereid=3",
            }

        return (
            <div className="getResponseBox">
                <div className="getResponseHeading">
                    <span style={{float: 'left'}}><h6 className="thinHeading">Request Body</h6></span>
                </div>
                <ReactJson src={responseBody} name={null} theme="ocean" displayObjectSize={false}/>
            </div>
        )
    }
}

export default DocsPostResponse