import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../../SharedComponents/Modal"
import MacrosContactUsModal from "./MacrosContactUsModal";

class RunMacrosReceiveInformationModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <ModalApp name="runMacrosReceiveInformationalModal"
                          header="Run Macros Receive Information"
                          body={<MacrosContactUsModal/>}
                          size="medium"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(RunMacrosReceiveInformationModal)