import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import UploadSourceLink from "./UploadSourceLink";
import constants from "../../../Constants/constants";
import RereadFile from "./RereadFile";

class DocsUploadIntroduction extends Component {
    goToSection = (sectionName) => {
        if (sectionName === "rereadFile"){
            this.rereadFileSection.scrollIntoView({ behavior: 'smooth'})
        }
    }

    setBothMenuItems = (menuItems) => this.props.setBothMenuItems(menuItems)

    render() {
        const imgStyle = {border:"1px solid #F0F0F0", display: "block", marginBottom: "16px"}

        return (
            <div>
                <div className="docsSection">
                    <p>You can upload your spreadsheet from a variety of sources by following these general steps</p>
                    <div className="docsList">
                        <p>1. Select your source</p>
                        <p>2. Select your spreadsheet or csv file</p>
                        <p>3. View your uploaded file</p>
                        <p>[optional] 4. Re-read file with different settings</p>
                    </div>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Sources</p>
                    <p>Pick which source you want to upload your spreadsheet from. Each source has different prompts/authentication to follow to be able to connect</p>
                    <p>Currently we support the sources below. You can read more about connecting to each source in their individual articles</p>
                    <div className="docsList">
                        <UploadSourceLink name="localFiles"
                                          setBothMenuItems={this.props.setBothMenuItems}
                                          label="Local Files"
                                          icon="bx bx-folder-open"/>
                        <UploadSourceLink name="googleSheets"
                                          setBothMenuItems={this.props.setBothMenuItems}
                                          label="Google Sheets"
                                          icon="bx bxl-google"/>
                        <UploadSourceLink name="dropbox"
                                          setBothMenuItems={this.props.setBothMenuItems}
                                          label="Dropbox"
                                          icon="bx bxl-dropbox"/>
                        <UploadSourceLink name="awsS3"
                                          setBothMenuItems={this.props.setBothMenuItems}
                                          label="AWS S3"
                                          icon="bx bxl-aws" />
                        <UploadSourceLink name="quickstart"
                                          setBothMenuItems={this.props.setBothMenuItems}
                                          label="Quickstart"
                                          icon="bx bx-code-block" />
                    </div>
                    <img src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/UploadSources.png"
                         style={{width: "100%", ...imgStyle}}
                         alt="upload sources"
                    />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Selecting a File</p>
                    <p>You can select the following extensions from general file storage sources like <b>Your Computer</b>, <b>Dropbox</b>, <b>AWS S3</b></p>
                    <div className="docsList">
                        {constants.extensions.map((x, idx) => <p key={idx}>{x}</p>)}
                    </div>
                    <p>For <b>Google Sheets</b> you can select any Google Sheet that you own, or you have permissions to edit</p>
                    <p>You can select multiple files at a time!</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">View File</p>
                    <p>After your file is uploaded, you can start using the API right away with our pre-written code samples</p>
                    <p>If you had duplicate or blank column names, you will <span className="linkStyle" onClick={e => this.setBothMenuItems("upload-columnNames")}>need to fix your columns names</span></p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Re-read File</p>
                    <RereadFile setBothMenuItems={this.props.setBothMenuItems} hasCsv={true} fileNames={<span><b>excel files</b> and Google Sheets</span>}/>
                </div>
                {/*Mention  change sheets, read from different row, change delimiter Link to settings read file*/}
            </div>
        )
    }
}

export default DocsUploadIntroduction