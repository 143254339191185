import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../../../../Actions/ToggleModal";
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import {toggleToast} from "../../../../Actions/ToggleToast";
import isObjectEmpty from "../../../../Constants/isObjectEmpty";
import { Icon } from 'semantic-ui-react'
import loadScript from "load-script";
import constants from "../../../../Constants/constants";
import { push } from 'react-router-redux'

const DROPBOX_SDK_URL = 'https://www.dropbox.com/static/api/2/dropins.js';
const SCRIPT_ID = 'dropboxjs';

let scriptLoadingStarted = false;

const documentType = "dropbox";

class DropboxFolderSelection extends Component {
    constructor(props) {
        super(props);
    }

    isDropboxReady = () => {
        return !!window.Dropbox;
    }

    componentDidMount(){
        if (!this.isDropboxReady() && !scriptLoadingStarted) {
            scriptLoadingStarted = true;
            loadScript(DROPBOX_SDK_URL, {
                attrs : {
                    id: SCRIPT_ID,
                    'data-app-key': "5wksa0jimylgr9z"
                }
            });
        }
    }

    onClick = (e) => {
        if (!this.isDropboxReady()) {
            return null;
        }

        const options = {
            success: (files) => this.onSuccess(files),
            cancel: () => this.cancel(),
            multiselect: false,
            extensions: constants.extensions,
            folderselect: true
        };

        window.Dropbox.choose(options);
    }


    onSuccess = (files) => {
        if (files.length > 0){
            const folderInfo = files[0]

            if (folderInfo.isDir){
                const destinationsEditCopied = JSON.parse(JSON.stringify(this.props.destinationsEdit))
                const accountDetails = destinationsEditCopied["dropboxAccountDetails"]

                accountDetails[this.props.accountName] = folderInfo
                destinationsEditCopied['dropboxAccountDetails'] = accountDetails

                this.props.modifyCustomImportSettings("destinationsEdit", destinationsEditCopied)
            } else {
                this.props.toggleToast({show: true, message: "You need to select a folder, not a file", type: "error"})
            }
        }
    }

    cancel = () => {
        // this.props.navigateTo(window.location.href)
    }

    doesAccountHaveDetails = () => {
        return this.props.accountName in this.props.destinationsEdit['dropboxAccountDetails']
    }

    getAccountDetails = () => {
        let detailInfo = this.props.destinationsEdit['dropboxAccountDetails'][this.props.accountName];
        let text = "main Dropbox"
        if (isObjectEmpty(detailInfo)){
            text = "main Dropbox"
        } else {
            if ("name" in detailInfo){
                text = detailInfo.name
            }
        }

        return <h6>Imported files will be saved in the <b>{text}</b> folder</h6>
    }

    render() {
        let text = "";
        if (this.doesAccountHaveDetails()){
            text = this.getAccountDetails()
        } else {
            text = ""
        }

        return (
            <div>
                {text}
                <h6 className="linkStyle" onClick={this.onClick}><Icon name="folder open outline" /> Choose another folder from your Dropbox account</h6>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    destinations: state.mainState.customImportDestinations,
    destinationsEdit: state.mainState.customImportDestinationsEdit
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    toggleToast: toggleToast,
    modifyCustomImportSettings: modifyCustomImportSettings,
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(DropboxFolderSelection)