import React, {Component} from 'react'
import CreateTeam from "./CreateTeam/CreateTeam"
import TeamSegments from "./TeamSegments"
import { connect } from 'react-redux'
import PendingInvitesRow from "./PendingInvites/PendingInvitesRow"
import { Message, Button } from 'semantic-ui-react'
import BannerAlert from "../SharedComponents/BannerAlert";
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import {toggleModal} from "../Actions/ToggleModal";

class TeamDisplayIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            message: <Message info
                              icon="question circle outline"
                              header="Need help getting started with Teams"
                              content={<a href="/api-docs/?docsMenuItem=teams" target="_blank" rel="noopener noreferrer">Read all about Teams and Roles</a>}
                              onDismiss={this.dismissMessage}
                    />
        }
    }

    dismissMessage = () => this.setState({ message: null })

    openPendingInvitesModal = () => {
        this.props.toggleModal("pendingInvitesModal")
    }

    render() {
        // Check if user is team to give option to create a team
        // Show Pending Invites
        // Show team details (this will be a modal)
        let pendingInvites = null;

        if (this.props.pendingInvites !== null){
            if (this.props.pendingInvites.length > 0){
                if (this.props.pendingInvites.length === 1){
                    pendingInvites = <div>
                        <PendingInvitesRow info={this.props.pendingInvites[0]} idx={0}/>
                    </div>
                } else {
                   pendingInvites = <div>
                       <BannerAlert
                            icon="bx bxs-info-circle"
                            type="info"
                            header={this.props.pendingInvites.length.toString() + " team invitations are waiting your action"}
                            content=""
                            action={<Button content="View All Invitations" icon="envelope open outline" style={{color: "white", backgroundColor: "#9696A0"}} onClick={this.openPendingInvitesModal}/>}
                       />
                   </div>
                }
            }
        }

        let message = null;

        if (this.props.teamInfo === null){
            message = this.state.message
        } else {
            if (this.props.teamInfo.length < 2){
                message = this.state.message
            }
        }

        return (
            <div>
                {pendingInvites}
                {/*{message}*/}
                <div>
                    <TeamSegments />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    pendingInvites: state.mainState.pendingInvites,
    teamInfo: state.mainState.teamInfo
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(TeamDisplayIndex)