import React, {Component} from 'react'
import { connect } from 'react-redux'
import {push} from "react-router-redux";
import API_Root from "../../../../Constants/API_Root";
import {modifyIntegrationAuth} from "../../../../Actions/ModifyIntegrationAuth";
import Loader from 'react-loader-spinner'

class AddDropboxAccountToDestinations extends Component {
    constructor(props){
        super(props);

        this.state = {
            redirectURL: this.getRedirectURL()
        }

    }

    getRedirectURL = () => {
        let redirectURL = ""
        if (window.location.href.includes("localhost")){
            redirectURL = "http://localhost:5000"
        } else {
            redirectURL = "https://www.apispreadsheets.com"
        }

        redirectURL += "/add-dropbox-account-to-destinations"

        return redirectURL
    }

    componentDidMount(){
        const url = window.location.href;

        const syncError = () => {
            this.props.navigateTo("/import")
            this.props.toggleModal(null);
            this.props.toggleToast({show: true, message: "There was an error syncing your DropBox account! Try again or ask for Support if the error persists.", type: "error"});
        }

        if (url.includes("code")) {
            const codeIdx = url.indexOf("code=")
            const stateIdx = url.indexOf("state=")

            const codeEndIdx = codeIdx < stateIdx ? stateIdx - 1 : url.length;
            const stateEndIdx = stateIdx < codeIdx ? codeIdx - 1 : url.length;

            const code = url.substr(url.indexOf("code=") + 5, codeEndIdx - codeIdx - 5)
            const state = url.substr(url.indexOf("state=") + 6, stateEndIdx)

            fetch('https://api.dropbox.com/oauth2/token', {
                method: 'POST',
                headers: {
                    Authorization: "Basic NXdrc2EwamlteWxncjl6OmdzeGx1NThoZm5nczZmeQ==",
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                // "5wksa0jimylgr9z": "gsxlu58hfngs6fy",
                // "client_id": "5wksa0jimylgr9z",
                // "client_secret": "gsxlu58hfngs6fy",
                // })
                body: "code=" + code + "&grant_type=authorization_code&redirect_uri=" + this.state.redirectURL
            }).then((res) => {
                    res.json().then(
                        (data) => {
                            fetch(API_Root + "api/api-store-dropbox-auth/",
                                {
                                    method: 'POST',
                                    headers: {
                                        Accept: 'application/json, text/plain, */*',
                                    },
                                    body: JSON.stringify({
                                        email: localStorage.getItem("email"),
                                        token: localStorage.getItem("token"),
                                        dropbox: data,
                                    })
                                }).then((res) =>
                            {
                                if (res.status === 200){
                                    const currIntegration = JSON.parse(JSON.stringify(this.props.integrationAuth));
                                    currIntegration.dropbox = true;
                                    this.props.modifyIntegrationAuth(currIntegration);

                                    this.props.navigateTo("/import/" + state + "_0_destinations")
                                } else{
                                    syncError()
                                }
                            }).catch((err) => {syncError(); console.log(err)})
                        }).catch((err) => {syncError(); console.log(err)})
                }
            ).catch((err) => {syncError(); console.log(err)})
        }
    }


    render() {
        return (
            <div style={{minHeight: '100vh'}}>
                <div style={{padding: '100px 0', textAlign: 'center'}}>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4>Linking your Dropbox Account...</h4>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    integrationAuth: state.mainState.integrationAuth,
    destinations: state.mainState.customImportDestinations,
    destinationsEdit: state.mainState.customImportDestinationsEdit
});

const mapActionsToProps = {
    navigateTo: push,
    modifyIntegrationAuth: modifyIntegrationAuth
};

export default connect(mapStateToProps, mapActionsToProps)(AddDropboxAccountToDestinations)