import React, {Component} from 'react'
import { Menu, Image } from 'semantic-ui-react'
import images from '../../Constants/Images'
import NavbarItem from './NavbarItem'
import NavbarLogIn from './NavbarLogIn'
import NavbarSignUp from './NavbarSignUp'
import getURLParam from '../../Constants/getURLParam'
import {connect} from "react-redux";
import {push} from "react-router-redux";

class NavbarNonAuth extends Component {
    render() {
        const urlParam = getURLParam(window.location.href);
        if (urlParam.includes("signup") ||
            urlParam.includes("login") ||
            window.location.href.includes("update-payment-info") ||
            urlParam.includes("?team=") ||
            (window.location.href.includes("useractions") && window.location.href.toLowerCase().includes("verifyemail")) ||
            (window.location.href.includes("import") && window.location.href.includes("upload")) ||
            (window.location.href.includes("import") && window.location.href.includes("embed")) ||
            (window.location.href.includes("/calculator")) ||
            (window.location.href.includes("/table/"))
        ){
            return(
                null
            )
        }
        else{
            if (window.location.href.includes("/import")){
                return(
                    <div>
                        <Menu secondary stackable>
                            <Menu.Menu position="left">
                                <Menu.Item>
                                    <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                        <Image style={{cursor: 'pointer'}} src={images.logoImporterNav} href="/importer" size="small"/>
                                    </div>
                                </Menu.Item>
                            </Menu.Menu>
                            <Menu.Menu position="right">
                                <Menu.Item>
                                    <NavbarItem to="importer/pricing" name="Pricing" color="black" importer />
                                </Menu.Item>
                                {/*<Menu.Item>*/}
                                {/*<NavbarItem to="datasets" name="Datasets" color="black" />*/}
                                {/*</Menu.Item>*/}
                                <Menu.Item>
                                    <NavbarItem to="tutorials" name="Use Cases" color="black" importer />
                                </Menu.Item>
                                {/*<Menu.Item>*/}
                                {/*<NavbarItem to="https://trello.com/b/4s8vwYDE/api-spreadsheets-roadmap" name="Roadmap" color="black" external/>*/}
                                {/*</Menu.Item>*/}
                                <Menu.Item>
                                    <NavbarItem to="demoModal" name="60-second Demo Video" color="black" modal importer />
                                </Menu.Item>
                                <Menu.Item>
                                    <NavbarItem to="https://calendly.com/api-spreadsheets/25min" name="See Demo" color="black" external />
                                </Menu.Item>
                                {/*<Menu.Item>*/}
                                {/*<NavbarItem to="feedbackModal" name="Feedback" color="gray" modal/>*/}
                                {/*</Menu.Item>*/}
                                <Menu.Item>
                                    <NavbarLogIn importer />
                                </Menu.Item>
                                <Menu.Item>
                                    <NavbarSignUp importer />
                                </Menu.Item>
                            </Menu.Menu>
                        </Menu>
                    </div>
                )
            } else {
                return (
                    <div>
                        <Menu secondary stackable>
                            <Menu.Menu position="left">
                                <Menu.Item>
                                    <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                        <Image style={{cursor: 'pointer'}} src={images.logoNav} href="/" size="small"/>
                                    </div>
                                </Menu.Item>
                            </Menu.Menu>
                            <Menu.Menu position="right">
                                <Menu.Item>
                                    <NavbarItem to="pricing" name="Pricing" color="black" />
                                </Menu.Item>
                                {/*<Menu.Item>*/}
                                {/*<NavbarItem to="datasets" name="Datasets" color="black" />*/}
                                {/*</Menu.Item>*/}
                                <Menu.Item>
                                    <NavbarItem to="tutorials" name="Use Cases" color="black" />
                                </Menu.Item>
                                {/*<Menu.Item>*/}
                                {/*<NavbarItem to="https://trello.com/b/4s8vwYDE/api-spreadsheets-roadmap" name="Roadmap" color="black" external/>*/}
                                {/*</Menu.Item>*/}
                                <Menu.Item>
                                    <NavbarItem to="demoModal" name="90-second Demo Video" color="black" modal />
                                </Menu.Item>
                                <Menu.Item>
                                    <NavbarItem to="https://calendly.com/api-spreadsheets/25min" name="See Demo" color="black" external />
                                </Menu.Item>
                                {/*<Menu.Item>*/}
                                {/*<NavbarItem to="feedbackModal" name="Feedback" color="gray" modal/>*/}
                                {/*</Menu.Item>*/}
                                <Menu.Item>
                                    <NavbarLogIn/>
                                </Menu.Item>
                                <Menu.Item>
                                    <NavbarSignUp />
                                </Menu.Item>
                            </Menu.Menu>
                        </Menu>
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state, ownProps) => ({
    id: ownProps
});

const mapActionsToProps = {
    navigateTo: push
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarNonAuth)

