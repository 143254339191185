import React, {Component} from 'react'
import VerifyEmailIndex from "./VerifyEmailIndex"
import AuthComponent from "../SharedComponents/AuthComponent";
import LoginVerificationEmail from "./LoginVerificationEmail"

class AuthVerifyEmailIndex extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <AuthComponent
                authComponent={<VerifyEmailIndex />}
                nonAuthComponent={<LoginVerificationEmail />}
            />
        )
    }
}

export default AuthVerifyEmailIndex