import React, {Component} from 'react'

class FeatureTable extends Component {
    render() {
        const headerRow = this.props.headers.map((x, idx) => <th key={idx}>{x}</th>)

        let tableRows = [];

        for (let i=0; i<this.props.data.length ; i++){
            let row = [];

            for (let j=0; j<this.props.data[i].length; j++){

                if (typeof this.props.data[i][j] === "object"){
                    row.push(
                        <td key={i.toString() + j.toString()} style={this.props.data[i][j].style}>
                            {this.props.data[i][j].value}
                        </td>
                    )
                }
                else{
                    row.push(
                        <td key={i.toString() + j.toString()}>
                            {this.props.data[i][j]}
                        </td>
                    )
                }
            }

            tableRows.push(<tr>{row}</tr>)
        }

        return (
            <div>
                <table id="featureTable" className={'extraClass' in this.props ? this.props.extraClass : null}>
                    <thead>
                    <tr>
                        {headerRow}
                    </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default FeatureTable