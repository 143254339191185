import React, {Component} from 'react'
import { auth } from '../Authentication/firebase/firebase'
import { Message } from 'semantic-ui-react'
import ResendVerificationEmail from "./ResendVerificationEmail";
import API_Root from "../Constants/API_Root";
import {getQueryParamValue} from "../Constants/getQueryParamValue";
import { connect } from 'react-redux'
import {modifyEmailVerified} from "../Actions/ModifyEmailVerified";
import BannerAlert from "./BannerAlert";

class EmailVerificationMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checking: true
        }
    }

    componentDidMount() {
        const stopChecking = () => this.setState({ checking: false })

        if (this.state.checking && !this.props.emailVerified){
            if (localStorage.getItem("email") !== null){
                fetch(API_Root + "api/get-user-email-verification/", {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                    },
                    body: JSON.stringify({
                        email: localStorage.getItem("email"),
                        token: localStorage.getItem("token"),
                    })
                }).then((res) => {
                    if (res.status === 200){
                        res.json().then(data => {
                            this.props.modifyEmailVerified(data.emailVerified)
                            stopChecking()
                        }).catch(err => stopChecking())
                    } else{
                        stopChecking()
                    }
                }).catch((err) => stopChecking())
            }
        } else {
            stopChecking()
        }
    }

    render() {
        if (this.state.checking){
            return null
        } else {
            if (this.props.emailVerified){
                return null
            } else {
                return (
                    <div>
                        <BannerAlert icon="bx bxs-error" header="Please Verify Your Email"
                                     content="Check your email to verify your account! Please contact our support if you did not receive an email."
                                     action={<ResendVerificationEmail/>}
                                     type="warning"
                        />
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({
    emailVerified: state.mainState.emailVerified
})

const mapActionsToProps = {
    modifyEmailVerified: modifyEmailVerified
}

export default connect(mapStateToProps, mapActionsToProps)(EmailVerificationMessage)