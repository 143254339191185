import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'
import DocsImage from "../DocsImage"
import Gist from "react-gist";
import CodeSnippet from "../CodeSnippet";

class UsingImporterIntroduction extends Component {
    render() {
        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">Introduction</p>
                    <p>There are two ways to start using an importer</p>
                    <p>You can either</p>
                    <p>1. <span className="linkStyle" onClick={e => this.props.setBothMenuItems("usingImporter-code")}>Insert the importer code on your own website or apps</span></p>
                    <p>2. <span className="linkStyle" onClick={e => this.props.setBothMenuItems("usingImporter-noCode")}>Share the hosted importer URL with people</span></p>
                    <p>No matter, how you use the importer, the import process is the same. We will walk through the process in this guide</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Begin Import Screen</p>
                    <p>The import process begins with this screen if you are using the importer code on your own website or app</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterIntroduction-CodeBegin.png"
                               width="100%"
                               alt='Database Columns' />
                    <p>If you are using the hosted importer URL, then the importer screen has an additional banner at the top containing the name you provided in the customization</p>
                    <DocsImage
                        src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/ImportSpreadsheetsOnYourApps/TestCode.png"
                        width='100%'
                        alt="Test Import Button"
                    />
                    <p>If you are on the <b>PRO</b> tier or higher, then you can <span className="linkStyle" onClick={e => this.props.setBothMenuItems("creatingImporter-appearance#removeBranding")}>remove the API Spreadsheets Logo</span> from the import screen</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Upload Box</p>
                    <p>Users can either drag and drop their files on to the box, or click the <b>Browse</b> button to select files to upload</p>
                    <p>The types of files someone can upload will be written right underneath the Browse button. You can <span className="linkStyle" onClick={e => this.props.setBothMenuItems("creatingImporter-appearance#fileExtensions")}>customize these for your importer</span></p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterIntroduction-FileType.png"
                               alt='Database Columns' />
                    <p>If you have <span className="linkStyle" onClick={e => this.props.setBothMenuItems("creatingImporter-appearance#multipleFiles")}>customized the setting for multiple files</span>, then the user will be able to upload multiple files at a time</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Column Mapping</p>
                    <p>If you have <span className="linkStyle" onClick={e => this.props.setBothMenuItems("creatingImporter-columns")}>column mapping</span> set up, then the users wil be prompted to map the columns from their spreadsheet to the columns you set up</p>
                    <p><b>Step 1</b> User verifies how to read the spreadsheet</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterIntroduction-FileVerification.png"
                               width="100%"
                               alt='Database Columns' />
                    <p>For both excel files and csv files, the user can select the row they want to read the file from, and whether the file has column names or not</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterIntroduction-StartRowColumnNames.png"
                               width="100%"
                               alt='Database Columns' />
                    <p>For just excel files, users can select the sheet they want to be read</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterIntroduction-ExcelSheet.png"
                               width="100%"
                               alt='Database Columns' />
                    <p>For just csv files, users can select which delimiter to read the files</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterIntroduction-Delimiter.png"
                               width="100%"
                               alt='Database Columns' />
                    <p><b>Step 2</b> User maps columns</p>
                    <p>Our matching algorithm provides the closest match of each required column</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterIntroduction-ColumnMapStart.png"
                               width="100%"
                               alt='Database Columns' />
                    <p>The user who uploaded the file can also do the column mapping themselves</p>
                    <p>After the columns are mapped, the user confirms and is brought back to the import screen</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterIntroduction-SaveColumnMap.png"
                               width="100%"
                               alt='Database Columns' />
                    <p>If the user fails to map a column that is required then they will be shown an error</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterIntroduction-RequiredColFail.png"
                               width="100%"
                               alt='Database Columns' />
                    <p>If the user exits this process at any time, they are taken back to the main Import Screen</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Imported File Information</p>
                    <p>When a file is successfully imported, the user will be able to see information about it on the main Import Screen</p>
                    <p>Here they will be able to:</p>
                    <ol>
                        <li>Copy the file ID</li>
                        <li>Insert a description for the file</li>
                        <li>Delete the file</li>
                        <li><i>optional</i> Edit their column mapping (if it was set up)</li>
                    </ol>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterIntroduction-FileInformationBox.png"
                               width="100%"
                               alt='Database Columns' />
                    <p>If the user deletes the file from this screen, then you will not see it on your API Spreadsheets dashboard</p>
                    <p>If the user has arrived from prematurely exiting the column mapping process, then they will see a button that will take them back to the column mapping screen</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterIntroduction-ColumnsNeedToBeMapped.png"
                               width="100%"
                               alt='Database Columns' />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">File saved to destinations</p>
                    <p>After a successful import, the file will be cleaned and saved to your <span className="linkStyle" onClick={e => this.props.setBothMenuItems("creatingImporter-destinations")}>destinations</span> as you configured</p>
                    <p>This happens in the background and the user is not notified that this process is occurring</p>
                </div>
            </div>
        )
    }
}

export default UsingImporterIntroduction