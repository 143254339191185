import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Label } from 'semantic-ui-react'
class VisualizationButton extends Component {
    goToLiveDashboard = () => {
        this.vizLink.click()
    }

    render() {
        const pk = this.props.pk;
        return (
            <div>
                <a href={"dashboard/" + pk + "/"} ref={vizLink => this.vizLink = vizLink} style={{display: "none"}}> Live Dashboard</a>
                <Label as='a' color='green' ribbon>
                    New!
                </Label>
                <Button content="Live Dashboard" basic color="orange" icon="dashboard" fluid onClick={this.goToLiveDashboard}/>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(VisualizationButton)