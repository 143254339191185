import React, {Component} from 'react'

class TestAPIWhitelistIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {}
        }
    }

    componentDidMount(){
        fetch("http://127.0.0.1:8080/data/doJL95p1jnH5XQE7/?accessKey=0a52950dbad57309b7cafd38731c5191&secretKey=517affe91a522bb097431650a537308f")
            .then(res => {
                if (res.status === 200){
                    res.json().then(data => {
                        this.setState({data: data })
                    }).catch(err => this.setState({ data: err }))
                } else{
                    this.setState({ data: res.status.toString()})
                }
            }).catch(err => this.setState({ data: err }))
    }

    render() {
        return (
            <div>
                <p>Test Whitelist</p>
                {JSON.stringify(this.state.data)}
            </div>
        )
    }
}

export default TestAPIWhitelistIndex