import React, {Component} from 'react'
import {connect} from 'react-redux'
import TeamSegmentRow from "../../Teams/TeamSegmentRow"
import { Icon, Message, Button } from 'semantic-ui-react'
import BannerAlert from "../../SharedComponents/BannerAlert"
import AddFileToTeam from "./AddFileToTeam"
import RemoveTeamFromFile from "./RemoveTeamFromFile"

class TeamFileModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warningMessage: <div style={{marginBottom: "16px"}}><BannerAlert icon="bx bx-message-error"
                                     header="This File is not part of any Teams linked to your Account"
                                     type="info"
                                     content="You can link the file to a team below"
                                 /></div>,
            url: "/teams"
        }
    }

    dismissMessage = () => {
        this.setState({ warningMessage: null })
    }

    seeTeamDetails = (teamHash, fileHash) => {
        this.setState({
            url: this.getFileURL(teamHash, fileHash)
        }, () => this.goToTeamsLink.click())
    }

    getFileURL = (teamHash, fileHash) => {
        const baseURL = window.location.href.includes("localhost") ? "http://localhost:5000/" : "https://www.apispreadsheets.com/"
        return baseURL + "teams/" + teamHash.toString() + "?file=" + fileHash.toString()
    }

    render() {
        const teamsPKList = this.props.fileInformation[this.props.pkID].teams_pk_list;
        const teamInfo = this.props.teamInfo;
        const filePK = this.props.pk;
        const fileHash = this.props.fileHash;

        let allTeamInfoForList = [];

        const allTeamInfoPKs = teamInfo.map(x => x.id)

        if (teamsPKList.length > 0){
            for (let i=0; i<teamsPKList.length; i++){
                if (allTeamInfoPKs.includes(teamsPKList[i])){
                    allTeamInfoForList.push(teamInfo[allTeamInfoPKs.indexOf(teamsPKList[i])])
                }
            }
        }

        let currTeams;

        if (allTeamInfoForList.length > 0){
            currTeams =
                <div style={{border: "1px solid #F0F0F0", borderRadius: "4px", padding: "16px", marginBottom: "16px"}}>
                    <div style={{fontFamily: "Lato", fontWeight: "700", fontSize: "14px", color: "#161E16", marginBottom: "16px"}}>This file is part of the following teams</div>
                    {allTeamInfoForList.map((x, idx) =>
                        <div key={idx} style={{paddingBottom: "10px", paddingTop: "10px", borderBottom: "1px solid #F0F0F0"}}>
                            <div className="row">
                                <div className="col-sm-11">
                                    <a href={this.state.url}  ref={goToTeamsLink => this.goToTeamsLink = goToTeamsLink} style={{display: "none"}}> Live Dashboard</a>
                                    <TeamSegmentRow fileHash={fileHash} info={x} idx={allTeamInfoPKs.indexOf(x.id)} teamPage={false} />
                                </div>
                                <div className="col-sm-1">
                                    <RemoveTeamFromFile filePK={filePK} teamPK={x.id} teamIdx={allTeamInfoPKs.indexOf(x.id)} />
                                </div>
                            </div>
                        </div>)}
                </div>
        } else {
            currTeams = this.state.warningMessage
        }

        return (
            <div>
                {currTeams}
                <AddFileToTeam isNoTeamForFile={allTeamInfoForList.length === 0}
                               fileHash={fileHash}
                               allTeamInfoPKs={allTeamInfoForList.map(x => x.id)}
                               filePK={filePK} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    teamInfo: state.mainState.teamInfo,
    fileInformation: state.mainState.fileInformation,
    editID: state.mainState.editID,
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(TeamFileModalBody)