import React, {Component} from 'react'
import {connect} from 'react-redux'
import isObjectEmpty from "../../Constants/isObjectEmpty";
import {getUTCStringFromOffset} from "../../Constants/getUTCStringFromOffset";
import { Dropdown, Checkbox } from 'semantic-ui-react'
import offsetOptionList from "../../Constants/offsetOptionList";
import { getAMPMTimeFrom24Hours } from "../../Constants/getAMPMTimeFrom24Hours";
import ReportingSaveButton from "./ReportingSaveButton"
import ReportingEmails from "./ReportingEmails"
import ReportingEmailDetails from "./ReportingEmailDetails"
import ReportingTestEmail from "./ReportingTestEmail";
import DisplayHeader from "../FilesID/DisplayHeader";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

const daysList = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
let timesList = [];

for (let i=0; i<=23; i++){
    timesList.push((i < 10 ? "0" : "") + i.toString() + ":00")
}

const timesOptions = timesList.map((x, idx) => { return { key: idx, value: x, text: getAMPMTimeFrom24Hours(x) }})

class ReportingModalBody extends Component {
    constructor(props) {
        super(props);

        const fileName = this.props.fileInformation[this.props.pkID].name;

        if (!("reportInfo" in this.props.fileInformation[this.props.pkID])){
            this.state = {
                isEditing: true,
                days: ["monday", "tuesday", "wednesday", "thursday", "friday"],
                times: ["09:00"],
                utcOffset: getUTCStringFromOffset(new Date()),
                emails: [],
                subject: this.initGenericSubject(fileName),
                from: "Khaleesi mother of dragons",
                msg: this.initGenericMsg(fileName, localStorage.getItem("email")),
                query: ""

            }
        } else {
            if (isObjectEmpty(this.props.fileInformation[this.props.pkID].reportInfo)){
                this.state = {
                    isEditing: true,
                    days: ["monday", "tuesday", "wednesday", "thursday", "friday"],
                    times: ["09:00"],
                    utcOffset: getUTCStringFromOffset(new Date()),
                    emails: [],
                    subject: this.initGenericSubject(fileName),
                    from: "Khaleesi mother of dragons",
                    msg: this.initGenericMsg(fileName, localStorage.getItem("email")),
                    query: ""
                }
            } else {
                const reportInfo = this.props.fileInformation[this.props.pkID].reportInfo
                this.state = {
                    isEditing: false,
                    days: reportInfo.days,
                    times: reportInfo.times,
                    utcOffset: reportInfo.utcOffset,
                    emails: reportInfo.emails,
                    subject: reportInfo.subject,
                    from: reportInfo.from,
                    msg: reportInfo.msg,
                    query: ""
                }
            }
        }
    }

    initGenericSubject = (fileName) => { return fileName + " auto report"}


    initGenericMsg = (fileName, fromEmail) => {
        return "Hello,\n\n" + fromEmail + " has included you on an automated report for file: " + fileName + ".\n\nPlease find file attached.\n\nBest,\n\nAPI Spreadsheets Team\n\n"
    }


    handleUTCDropdownChange = (e, { value }) => {
        this.setState({ utcOffset: value })
    }

    handleTimesDropdownChange = (e, { value }) => {
        this.setState({ times: value })
    }

    handleAllTimesCheckboxChange = (e) => {
        if (this.state.times.length === 24){
            this.setState({ times: ["09:00"]})
        } else {
            this.setState({ times: timesList })
        }
    }

    handleDaysCheckboxChange = (e) => {
        let currDays = JSON.parse(JSON.stringify(this.state.days))
        if (currDays.includes(e.target.name)){
            currDays.splice(currDays.indexOf(e.target.name), 1)
        } else {
            currDays.push(e.target.name)
        }

        this.setState({ days: currDays })
    }

    getDaysCheckbox = (day) => { return <span style={{paddingRight: "26px"}}>
        <input type="checkbox" style={{cursor: "pointer"}} name={day} id={day} onChange={this.handleDaysCheckboxChange} checked={this.state.days.includes(day)} />
        <label style={{cursor: "pointer", paddingLeft: "5px"}} htmlFor={day}>{day.charAt(0).toUpperCase() + day.substring(1, 3)}</label>
    </span>}

    toggleAllDaysCheckbox = () => {
        if (this.state.days.length === 7){
            this.setState({ days: ["monday", "tuesday", "wednesday", "thursday", "friday"] })
        } else {
            this.setState({ days: daysList })
        }
    }

    changeState = (key, value) => {
        this.setState({ [key]: value })
    }

    renderLabel = (label) => ({
        color: "white",
        fontFamily: "Lato",
        backgroundColor: "#9696A0",
        style: {backgroundColor: "#9696A0", color: "white", fontFamily: "Lato", borderRadius: "3px", padding: "5px 5px 5px 9px", fontSize: "12px", fontWeight: "400"},
        content: <span style={{color: "white", backgroundColor: "#9696A0",}}>{label.value}</span>,
    })

    render() {
        const utcOptions = offsetOptionList.map((x, idx) => { return { key: idx, value: x, text: x } });

        return (
            <div>
                {/*{this.state.isEditing ? <p>Select frequency of having this spreadsheet emailed to people you want</p> :*/}
                    {/*<p>These are your current auto report settings</p>}*/}
                <div style={{border: "1px solid #F0F0F0", borderRadius: "4px", padding: "16px"}}>
                    <DisplayHeader header="Date & Time"/>
                    <div style={{marginTop: "16px"}}>
                        <span style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#5E645E"}}>Send every</span>
                            <div style={{display: "grid", gridTemplateColumns: "auto 1fr"}}>
                                <div>
                                    {this.getDaysCheckbox("monday")}
                                    {this.getDaysCheckbox("tuesday")}
                                    {this.getDaysCheckbox("wednesday")}
                                    {this.getDaysCheckbox("thursday")}
                                    {this.getDaysCheckbox("friday")}
                                    {this.getDaysCheckbox("saturday")}
                                    {this.getDaysCheckbox("sunday")}
                                </div>
                                <div style={{textAlign: "right"}}>
                                    <Checkbox toggle label="Everyday" name="allDays" onChange={this.toggleAllDaysCheckbox} checked={this.state.days.length === 7} />
                                </div>
                            </div>
                    </div>
                    <div style={{marginTop: "12px"}}>
                        <div style={{display: "grid", gridTemplateColumns: "60% 40%", columnGap: "8px"}}>
                            <div>
                                <div style={{display: "grid", gridTemplateColumns: "1fr auto"}}>
                                    <div>
                                        <span style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#5E645E"}}>At time</span>
                                    </div>
                                    <div style={{textAlign: "right"}}>
                                        <Checkbox toggle label={<label style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#5E645E"}}>Every Hour of the Day</label>} onChange={this.handleAllTimesCheckboxChange} checked={this.state.times.length === 24}/>
                                    </div>
                                </div>
                                <Dropdown
                                    style={gunaldiStyles.dropdown}
                                    onChange={this.handleTimesDropdownChange}
                                    options={timesOptions}
                                    placeholder='Select Times'
                                    selection
                                    multiple
                                    value={this.state.times}
                                    fluid
                                    disabled={this.state.times.length === 24}
                                    renderLabel={this.renderLabel}
                                />
                            </div>
                            <div>
                                <span style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#5E645E"}}>UTC Timezone <span style={{visibility: "hidden"}}><Checkbox toggle label="Everyday" name="allDays" onChange={this.toggleAllDaysCheckbox} checked={this.state.days.length === 7} /></span></span>
                                <Dropdown
                                    onChange={this.handleUTCDropdownChange}
                                    style={gunaldiStyles.dropdown}
                                    options={utcOptions}
                                    placeholder='UTC Timezone'
                                    selection
                                    value={this.state.utcOffset}
                                    fluid
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: "16px"}}>
                    <div style={{display: "grid", gridTemplateColumns: "55% 45%", columnGap: "8px"}}>
                        <div style={{border: "1px solid #F0F0F0", borderRadius: "4px", padding: "16px"}}>
                            <ReportingEmailDetails
                                subject={this.state.subject}
                                from={this.state.from}
                                msg={this.state.msg}
                                changeState={this.changeState}
                                fileInfo={this.props.fileInformation[this.props.pkID]}
                            />
                        </div>
                        <div style={{border: "1px solid #F0F0F0", borderRadius: "4px", padding: "16px"}}>
                            <ReportingEmails
                                changeState={this.changeState}
                                emails={this.state.emails}
                            />
                            <br/><br/><br/>
                            <ReportingTestEmail
                                reportInfo={this.state}
                                pk={this.props.pk}
                            />
                        </div>
                    </div>
                </div>
                <div style={{marginTop: "16px", textAlign: "right"}}>
                    <ReportingSaveButton
                        changeState={this.changeState}
                        pk={this.props.pk}
                        editID={this.props.pkID}
                        reportInfo={this.state}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    modalInfo: state.mainState.modalInfo,
    fileInformation: state.mainState.fileInformation,
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ReportingModalBody)