import React, {Component} from 'react'
import API_Root from '../../../Constants/API_Root'
import { auth } from '../../firebase';
import { Header, Input, Button, Message } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import {toggleModal} from "../../../Actions/ToggleModal";
import constants from "../../../Constants/constants";
import {modifyIntegrationAuth} from "../../../Actions/ModifyIntegrationAuth";
import {toggleShowingTutorial} from "../../../Actions/ToggleShowingTutorial";
import {modifyTutorialStep} from "../../../Actions/ModifyTutorialStep";


class SignUpForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: this.initEmail(),
            passwordOne:'',
            passwordTwo:'',
            tandc: false,
            error: null,
            loading: false,
            plan: "0",
            isAppSumo: this.initAppSumo()
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.signUp = this.signUp.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
    }

    initEmail = () => {
        const url = window.location.href;

        if (url.includes("&email=")){
            return url.substring(url.indexOf("&email=") + "&email=".length, url.length).trim()
        } else { return '' }
    }

    initAppSumo = () => { return (window.location.href).includes("?deal=appsumo") }

    getTeamCode = () => {
        const url = window.location.href;

        if (url.includes("?team=")){
            return url.substring(url.indexOf("?team=") + "?team=".length, url.indexOf("&email=")).trim()
        } else { return '' }
    }

    handleKeyPress(e){
        const isInvalid = this.state.passwordOne !== this.state.passwordTwo || this.state.passwordOne === '' || this.state.passwordOne.length < 8 || this.state.email === ''|| this.state.tandc === false;

        if (e.charCode === 13){
            if (!isInvalid){
                e.preventDefault()
                this.signUp(e)
            }
        }
    }

    dismissMessage(){
        this.setState({
            error: null
        })
    }

    handleInputChange(e){
        if (e.target.name === "tandc"){
            this.setState({
                tandc: !this.state.tandc
            })
        }
        else{
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    signUp(e){
        this.setState({
            loading: true
        })

        const email = this.state.email;
        const passwordOne = this.state.passwordOne;

        const API = API_Root + 'api/' + (this.state.isAppSumo ? 'save-user-appsumo/' : 'save-user/'); /*save user email to our db*/
        const formData = {
            "email": email,
            "username": email,
            "password": passwordOne,
            "teamCode": this.getTeamCode(),
            "source": localStorage.getItem("source"),
            "importer": this.props.importer
        };

        let plan = "0";
        const url = window.location.href;

        if (url.includes("?pricing=")){
            plan = url.substring(url.indexOf("?pricing=")+9, url.indexOf("?pricing=")+10)
        }

        const errorMessage = {message: <p>There was an error on our server creating the
                account. Please <a
                    href={"mailto:support@apispreadsheets.com?Subject=Create%20Account%20Error%20for%20" + email}>Email
                    Us</a></p>};

        auth.doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                this.setState(() => ({
                    username: '',
                    email: '',
                    passwordOne: '',
                    passwordTwo: '',
                    tandc: false,
                    error: null,
                }));

                fetch(API, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                })
                .then((res) => {
                        if (res.status === 200){
                            res.json().then((data) => {
                                    localStorage.setItem("email", email.toLowerCase().trim());
                                    localStorage.setItem("token", data.token);

                                    // try{
                                    //     auth.sendCurrentUserVerifyEmail(authUser.user).then(() => {
                                    //         console.log("function worked")
                                    //         // Function worked
                                    //     }).catch(err => console.log(err))
                                    // } catch (err){
                                    //     console.log(err)
                                    // }

                                    const integrationAuth = {
                                        dropbox: false,
                                        googleSheets: false
                                    };

                                    this.props.modifyIntegrationAuth(integrationAuth);

                                    this.setState({
                                        loading: false
                                    }, () => {
                                        this.props.modifyTutorialStep("userPreference")
                                        this.props.toggleShowingTutorial(true)
                                        if (plan !== "0"){
                                            this.setState({
                                                plan: plan
                                            }, () => {
                                                this.paymentLink.click()
                                            })
                                            // this.props.navigateTo('/complete-payment?pricing=' + plan)
                                        }
                                        else{
                                            if (window.location.href.includes("?team=")){
                                                this.teamsLink.click()
                                            } else {
                                                if (this.state.isAppSumo){
                                                    this.profileLink.click()
                                                } else {
                                                    this.uploadLink.click()
                                                }
                                            }
                                        }
                                    })
                                }
                            ).catch(error => {
                                this.setState({
                                    error: errorMessage ,
                                    loading: false
                                });
                        })
                    }
                    else{
                        this.setState({
                            error: errorMessage ,
                            loading: false
                        })
                    }
                }).catch(error => {
                    this.setState({error:  errorMessage, loading: false});
                });
            }).catch(error => {
                this.setState({error: error, loading: false});
            });
    }

    render() {
        const {
            email,
            passwordOne,
            passwordTwo,
            tandc,
            error
        } = this.state;

        const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || passwordOne.length < 8 || passwordOne.length > 40 || email === ''|| tandc === false;

        const passwordLengthError = passwordOne.length < 8 ? <div><small style={{color: 'red'}}>Password must be at least 8 characters</small></div> : null;
        const passwordMaxLengthError = passwordOne.length > 40 ? <div><small style={{color: 'red'}}>Password cannot be more than 40 characters</small></div> : null;
        const passwordMatchError = passwordOne !== passwordTwo ? <div><small style={{color: 'red'}}>Passwords Do Not Match</small></div> : null;

        let errorMessage = null;

        if (error !== null){
            if ("message" in error){
                errorMessage = <Message
                    onDismiss={this.dismissMessage}
                    content={error.message}
                    negative
                />
            }
        }

        const paymentURL = "/complete-payment?pricing=" + this.state.plan;

        return (
            <div className="segment-margin segment-raised" style={'modalForm' in this.props ? {backgroundColor: 'whitesmoke'} : null} onKeyPress={this.handleKeyPress}>
                <a
                    style={{display: 'none'}}
                    ref={uploadLink => this.uploadLink = uploadLink}
                    href={this.props.importer ? "/import?signup" : "/api?signup&menuItem=upload"}
                >Download hidden</a>
                <a
                    style={{display: 'none'}}
                    ref={teamsLink => this.teamsLink = teamsLink}
                    href="/teams"
                >Download hidden</a>
                <a
                    style={{display: 'none'}}
                    ref={profileLink => this.profileLink = profileLink}
                    href="/profile?deal=appsumo"
                >Download hidden</a>
                <a
                    style={{display: 'none'}}
                    ref={paymentLink => this.paymentLink = paymentLink}
                    href={paymentURL}
                >Download hidden</a>
                <Header as='h1'>
                    Sign Up to Get Started
                </Header>
                {
                    window.location.href.includes("?team=") ? <Header size='small'>You must sign up with the email below to be added to your team</Header> : null
                }
                {
                    this.state.isAppSumo ? <div> <Header size='medium'>You Must Sign Up with your App Sumo Email</Header> <small>Don't worry you can change your email later :) </small> </div>: null
                }
                <br/>
                <Input icon="at"
                       iconPosition='left'
                       id="signupEmail"
                       placeholder="Email" type="email" name="email"
                       onChange={this.handleInputChange}
                       disabled={this.state.isAppSumo}
                       value={email} fluid/>
                <Input  icon="lock"
                        iconPosition="left"
                        id="signupPassword1"
                        placeholder="Password (at least 8 characters)" type="password" name="passwordOne"
                        onChange={this.handleInputChange}
                        value={passwordOne} error={passwordOne.length < 8} fluid/>
                <Input  icon="lock"
                        iconPosition="left"
                        id="signupPassword2"
                        placeholder="Confirm Password" type="password" name="passwordTwo"
                        onChange={this.handleInputChange}
                        value={passwordTwo} error={passwordTwo.length < 8} fluid/>
                <br/>
                <input type="checkbox" name="tandc" id="tandc" onChange={this.handleInputChange} checked={tandc} />
                <label htmlFor="tandc">
                    <span style={{paddingLeft: '5px'}}>{"I agree with " + constants.productName + "'"}</span>
                    <a href="/terms" target="_blank"> Terms and Conditions</a>
                </label>
                {passwordLengthError}
                {passwordMaxLengthError}
                {passwordMatchError}
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button loading={this.state.loading}
                            color={this.props.importer ? "orange" : "green"}
                            id="signupButton"
                            disabled={isInvalid} onClick={this.signUp} onKeyPress={this.handleKeyPress} fluid>
                        <h4 className="thinHeading"> Sign Up</h4>
                    </Button>
                </div>
                <br/>
                {errorMessage}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal,
    modifyIntegrationAuth: modifyIntegrationAuth,
    toggleShowingTutorial: toggleShowingTutorial,
    modifyTutorialStep: modifyTutorialStep
}

export default connect(mapStateToProps,mapActionsToProps)(SignUpForm);
