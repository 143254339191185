import React, {Component} from 'react'

class VisualizationError extends Component {
    render() {
        return (
            <div>
                <h2>There was an error getting your visualization components</h2>
            </div>
        )
    }
}

export default VisualizationError