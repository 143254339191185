import React, { Component } from 'react';
import ProcessFlow from "./ProcessFlow";
import FeatureDisplay from "./FeatureDisplay";
import APIStep1 from "../../Illustrations/APIStep1.svg"
import APIStep2 from "../../Illustrations/APIStep2.svg"
import APIStep3 from "../../Illustrations/APIStep3.svg"
import {gunaldiStyles} from "../../../SharedComponents/GunaldiDesignStyles";
import api_url from "../../Illustrations/api_url.svg"
import UploadSpreadsheets from "../../Illustrations/UploadSpreadsheets.svg"
import LandingApps from "../../Illustrations/LandingApps.svg"
import LandingForms from "../../Illustrations/LandingForms.svg"
class HowItWorks extends Component {
    render() {
        const isMobile = 'isMobile' in this.props;

        let uploadSpreadsheetParagraph = <span>Upload your Spreadsheets & Google Sheets on the API Spreadsheets dashboard.<br/>Manage file versions, user access, and much more</span>


        let processSteps = ["Upload a spreadsheet", "View your API", "Build amazing apps"]
        let allParagraphs = [
            uploadSpreadsheetParagraph,
            <span>Your spreadsheets are automatically converted into an API.<br/>Use it to execute your formulas & macros within your spreadsheets</span>,
            <span>Build applications that use these calculations within minutes, not days</span>
        ]

        if (!this.props.isDeveloper){
            processSteps = ["Upload a spreadsheet", "Configure your calculator", "Show it to the world"]
            allParagraphs = [
                uploadSpreadsheetParagraph,
                <span>Set up your calculator to execute your formulas & macros in your spreadsheets</span>,
                <span>Publish your calculator for the world to see</span>
            ]
        }

        return (
            <div style={isMobile ? {paddingTop: "48px"} : null}>
                <div style={{
                    textAlign: "center",
                    paddingBottom: "32px",
                    color: gunaldiStyles.mainText,
                    fontWeight: "700",
                    fontFamily: "Lato",
                    fontSize: "32px"
                }}>
                    How it works
                </div>
                <ProcessFlow steps={processSteps} isMobile={isMobile}/>
                <FeatureDisplay image={UploadSpreadsheets} paragraph={allParagraphs[0]} heading={processSteps[0]} isMobile={isMobile}/>
                <FeatureDisplay image={this.props.isDeveloper ? api_url : LandingForms } paragraph={allParagraphs[1]} heading={processSteps[1]} flip={true} isMobile={isMobile}/>
                <FeatureDisplay image={LandingApps} paragraph={allParagraphs[2]} heading={processSteps[2]} flip={false} isMobile={isMobile}/>
            </div>
        )
    }
}

export default HowItWorks