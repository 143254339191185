const getColumnMatchedValues = (importSheetsUploadPK, importSheetsFileInformation) => {
    let matchedArray = [];

    for (let i=0; i<importSheetsUploadPK.length; i++){
        const id = importSheetsUploadPK[i].id;
        const fileInformation = importSheetsFileInformation[id];
        let matched = false;

        if ("columnsMap" in fileInformation.importSheetsInfo){
            let columnsMap = fileInformation.importSheetsInfo.columnsMap
            if (columnsMap !== null) {
                if (columnsMap.length > 0) {
                    matched = true
                }
            }
        }

        matchedArray.push(matched)
    }

    return matchedArray
}

export default getColumnMatchedValues