import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'
import {modifyShowingMinorTutorials} from "../../Actions/ModifyShowingMinorTutorials";
import API_Root from "../../Constants/API_Root";
import BannerAlert from "../../SharedComponents/BannerAlert";

class ColumnNamesTutorial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: true
        }

        this.handleChecked = this.handleChecked.bind(this);
    }

    handleChecked(e){
        let updatedShowingMinorTutorials = JSON.parse(JSON.stringify(this.props.showingMinorTutorials));
        updatedShowingMinorTutorials.columnMap = false;
        this.props.modifyShowingMinorTutorials(updatedShowingMinorTutorials);

        fetch(API_Root + 'api/unsubscribe-from-tutorial/', {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                minorTutorial: 'columnMap'
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then().catch()
    }

    hideBanner = () => this.setState({ display: false })

    render() {
        if (this.props.showingMinorTutorials.columnMap){
            if (this.state.display){
                return (
                    <div style={{marginTop: "16px"}}>
                        <BannerAlert
                            header="Essential Column Names Information"
                            content={<div>
                                <ul style={{margin: "0", paddingLeft: "20px"}}>
                                    <li style={{margin: "0", padding: "0"}}>The Column Names here may NOT reflect what is currently in your File</li>
                                    <li style={{margin: "0", padding: "0"}}>Column Names are updated whenever you make a API Request or if you <span style={{color: "green"}}>Reupload/Refresh <Icon name='upload'/> </span> the File</li>
                                </ul>
                                <p className="linkStyle" onClick={this.handleChecked} style={{marginTop: "4px"}}>Don't show me this again</p>
                            </div>}
                            action={<span style={{float: 'right'}} onClick={this.hideBanner}><Icon name="window close outline" /></span>}
                            type="display"
                        />
                    </div>
                )
            }
            else{
                return (
                    null
                )
            }
        } else {
            return (
                null
            )
        }

    }
}

const mapStateToProps = (state) => ({
    showingMinorTutorials: state.mainState.showingMinorTutorials
})

const mapActionsToProps = {
    modifyShowingMinorTutorials: modifyShowingMinorTutorials
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnNamesTutorial)