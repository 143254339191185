import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleShowingTutorial} from "../../../Actions/ToggleShowingTutorial";
import {modifyTutorialStep} from "../../../Actions/ModifyTutorialStep";
import {toggleModal} from "../../../Actions/ToggleModal";
import API_Root from "../../../Constants/API_Root";
import { Icon } from 'semantic-ui-react'

class ExitModalFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            unsubscribeFromTutorial: false
        }

        this.leaveTutorial = this.leaveTutorial.bind(this)
        this.undoToActions = this.undoToActions.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    leaveTutorial(e){
        const leave = () => {
            this.props.toggleModal(null);
            this.props.toggleShowingTutorial(false);
            this.props.modifyTutorialStep(null);
        };

        if (this.state.unsubscribeFromTutorial){
            fetch(API_Root + "api/unsubscribe-from-tutorial/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email")
                })
            }).then(res => leave()).catch(err => leave());
        }
        else{
            this.props.toggleModal(null);
            this.props.modifyTutorialStep("userPreference");
        }

    }

    undoToActions(e){
        this.props.modifyTutorialStep("pickAction")
    }

    handleInputChange(e){
        const changedInput = !JSON.parse(JSON.stringify(this.state.unsubscribeFromTutorial));

        this.setState({
            unsubscribeFromTutorial: changedInput
        })
    }

    render() {
        return (
            <div id="exitModalFooterDiv">
                <div id="exitModalFooterUndoToAction">
                    {'actionType' in this.props ? <span className="linkStyle" onClick={this.undoToActions}><Icon name="undo"/> I want to pick another action</span> : null }
                </div>
                <div id="exitModalFooterLeaveTour">
                    <input type="checkbox" name="unsubscribeFromTutorial" id="unsubscribeFromTutorial" onChange={this.handleInputChange} checked={this.state.unsubscribeFromTutorial} />
                    <label htmlFor="unsubscribeFromTutorial">
                        <span style={{paddingLeft: '7px'}}>Don't show me this tour ever again</span>
                    </label>
                    <span className="linkStyle" id="leaveTourLink" onClick={this.leaveTutorial} style={{color: 'red', paddingLeft: '7px'}}><Icon name="dont"/> I want to leave the tour</span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    toggleShowingTutorial: toggleShowingTutorial,
    modifyTutorialStep: modifyTutorialStep
}

export default connect(mapStateToProps, mapActionsToProps)(ExitModalFooter)