import React, {Component} from 'react'
import {Button, Input, Message, Segment} from "semantic-ui-react";
import isEmailValid from "../isEmailValid";
import API_Root from "../../Constants/API_Root";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

class EmailButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            message: null
        }

        this.sendEmail = this.sendEmail.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event){
        this.setState({
            email: event.target.value
        })
    }
    sendEmail(e){
        fetch(API_Root + "api/send-email/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: this.state.email,
                fromEmail: 'info@apispreadsheets.com'
            })
        }).then(res =>
            this.setState({
                message: <div style={{marginTop: "24px"}}>
                    <Message positive={true}
                             header="Email sent successfully!"
                             content="It is possible the email is sent to the spam or junk folder" />
                </div>,
                email: ''
            })
        ).catch(err => this.setState({
            message: <div style={{marginTop: "24px"}}>
                <Message negative
                         header="There was a problem sending your email"
                         content="Please try again" />
            </div>,
            email: ''
        }));
    }
    render() {
        return (
            <div style={{backgroundColor:'importer' in this.props ? gunaldiStyles.subOrange : '#F4FBF3',padding:'24px',borderRadius:'10px'}}>
                <h5 className="thinHeading" style={{textAlign: 'center'}}>
                    {this.props.header}
                </h5>
                <Input onChange={this.handleInputChange} value={this.state.email} placeholder="Enter Email" fluid />
                {!isEmailValid(this.state.email.trim()) ? <small style={{color: 'red'}}>Please enter a valid email</small> :null}
                <div style={{textAlign: 'center',paddingTop:'12px'}}>
                    <Button disabled={!isEmailValid(this.state.email.trim())}
                            onClick={this.sendEmail}
                            color="orange"
                            basic
                            icon="paper plane"
                            content="Send Email"
                    />
                </div>
                {this.state.message}
            </div>


        )
    }
}

export default  EmailButton