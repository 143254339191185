import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup, Icon } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import {modifyTeamInfo} from "../../Actions/ModifyTeamInfo";

class RemoveTeamFromFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupOpen: false,
            deleting: false
        }
    }

    togglePopup = (e, toggle) => {
        this.setState({
            popupOpen: toggle
        })
    }

    removeTeam = () => {
        this.setState({ deleting: true })

        fetch(API_Root + "api/remove-api-team-from-file/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                teamPK: this.props.teamPK,
                filePK: this.props.filePK
            })
        }).then((res) => {
            if (res.status === 200){
                this.setState({
                    deleting: false, popupOpen: false
                }, () => this.finishRemovingTeam())
            } else {
                this.setState({ deleting: false, popupOpen: false })
                alert("There was an error removing your team! Try again")
            }
        }).catch(err => {
            this.setState({ deleting: false, popupOpen: false });
            alert("There was an error removing your team! Try again")
        })
    }

    finishRemovingTeam(){
        const currTeamInfo = JSON.parse(JSON.stringify(this.props.teamInfo))
        const teamInfo = currTeamInfo[this.props.teamIdx]
        const teamAPIFilePK = teamInfo.api_file_pk['files_pk_list'];
        teamAPIFilePK.splice(teamAPIFilePK.indexOf(this.props.filePK), 1)

        if (teamAPIFilePK.length > 0){
            teamInfo.api_file_pk = {"files_pk_list": teamAPIFilePK}
        } else {
            teamInfo.api_file_pk = {}
        }

        currTeamInfo[this.props.teamIdx] = teamInfo
        this.props.modifyTeamInfo(currTeamInfo)

        const currFileInformation = JSON.parse(JSON.stringify(this.props.fileInformation))
        const fileInfoPK = currFileInformation[this.props.editID]
        const teamsPKList = fileInfoPK.teams_pk_list;
        teamsPKList.splice(teamsPKList.splice(this.props.teamPK), 1)

        fileInfoPK.teams_pk_list = teamsPKList
        currFileInformation[this.props.editID] = fileInfoPK

        this.props.modifyFileInformation(currFileInformation)
    }

    render() {
        let canRemove = false;

        if (["admin", "manager"].includes(this.props.teamInfo[this.props.teamIdx].all_users[localStorage.getItem("email")].role)){
            canRemove = true
        }

        if (canRemove){
            return (
                <Popup
                    trigger={
                        <Icon   name="trash alternate outline"
                                style={{color: "red", fontSize: "20px", cursor: "pointer"}}
                                onClick={(e) => this.togglePopup(e, true)}/>
                    }
                    on="click"
                    position="right"
                    onClose={(e) => this.togglePopup(e, false)}
                    open={this.state.popupOpen}>
                    <div style={{padding: '10px'}}>
                        <h4 style={{color: 'red'}}>Are you sure you want to remove this Team from this File?</h4>
                        <p style={{color: 'red'}}>YOU CANNOT UNDO THIS ACTION!</p>
                        <div className="row">
                            <div className="col-sm-6">
                                <Button color="green" content="No" onClick={(e) => this.togglePopup(e, false)} fluid />
                            </div>
                            <div className="col-sm-6">
                                <Button color="red" loading={this.state.deleting} content="Yes" onClick={this.removeTeam} fluid />
                            </div>
                        </div>
                    </div>
                </Popup>
            )
        } else {
            return (
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    fileInformation: state.mainState.fileInformation,
    teamInfo: state.mainState.teamInfo
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation,
    modifyTeamInfo: modifyTeamInfo
}

export default connect(mapStateToProps, mapActionsToProps)(RemoveTeamFromFile)