import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyCustomImportSettings} from "../../../../Actions/ModifyCustomImportSettings";
import { Checkbox } from 'semantic-ui-react'

class AppearanceFileExtensions extends Component {
    toggle = (fileExtension) => {
        const editCopy = JSON.parse(JSON.stringify(this.props.customImportAppearanceEdit))
        const fileTypes = editCopy.fileTypes;

        if (fileTypes.includes(fileExtension)){
            fileTypes.splice(fileTypes.indexOf(fileExtension), 1)
        } else {
            fileTypes.push(fileExtension)
        }

        editCopy.fileTypes = fileTypes

        this.props.modifyCustomImportSettings("appearanceEdit", editCopy)
    }

    render() {
        const fileTypes = this.props.customImportAppearanceEdit.fileTypes;

        const allCheckboxes = [".xlsx", ".xls", ".csv", ".txt"].map((x, idx) =>
            <span key={idx} style={{paddingRight: "16px"}}>
                <Checkbox label={x} checked={fileTypes.includes(x)} onChange={e => this.toggle(x)}/>
            </span>)

        return (
            <div style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                <div>
                    <span style={this.props.subheadingStyle}>File Extensions Allowed to be Uploaded</span>
                </div>
                <div style={{textAlign: "right"}}>
                    {allCheckboxes}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    customImportAppearanceEdit: state.mainState.customImportAppearanceEdit
})

const mapActionsToProps = {
    modifyCustomImportSettings: modifyCustomImportSettings
}

export default connect(mapStateToProps, mapActionsToProps)(AppearanceFileExtensions)