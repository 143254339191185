import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Icon, Label } from 'semantic-ui-react';
import {getFileURL} from "../Constants/getFileURL";
import {toggleToast} from "../Actions/ToggleToast";

class CopyURLSegment extends Component {
    constructor(props){
        super(props);

        this.copyURL = this.copyURL.bind(this);
    }

    copyURL(e){
        // e.target.focus();
        navigator.clipboard.writeText(this.props.url)
        document.execCommand("copy")
        window.getSelection().removeAllRanges();

        this.props.toggleToast({show: true, message: "URL Copied!", type: "success"})
    }

    render() {
        let isDisabled = 'disabled' in this.props;

        return (
            <div className="codeFileURL" id="fileURL">
                <div style={{marginBottom: "6px"}}>
                    <span>
                        <span style={{verticalAlign: "middle", fontSize: "16px", color: "#161E16", fontWeight: "700", fontFamily: "Lato"}}>
                            <i className='bx bx-window-alt' style={{color: "#9696A0", fontSize: "20px", verticalAlign: "middle"}} />
                            <span style={{paddingLeft: "4px", marginTop: "5px"}}>{this.props.header}</span>
                        </span>
                    </span>
                </div>
                <p className="apiURLText" id="apiURLInput">
                    <Icon name="copy outline"
                          className="fileIDLabelIcon"
                          color="green"
                          style={{cursor: "pointer"}}
                          onClick={isDisabled ? null : this.copyURL}
                    />
                    {this.props.url}
                </p>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(CopyURLSegment)