import React, {Component} from 'react'
import { Divider } from 'semantic-ui-react'

class DocsPostResponseCode extends Component {
    render() {
        return (
            <div className="getResponseCodes">
                <h5 id="getResponseCodesHeading">Response Codes</h5>
                <Divider/>
                <div className="responseCodeLine">
                    <code className="responseCodeStatus">201</code> <span className="responseCodeParam codeSuccess">CREATED</span>
                    <span className="responseCodeDesc">Post request was successful</span>
                </div>
                <Divider/>
                <div className="responseCodeLine">
                    <code className="responseCodeStatus">400</code> <span className="responseCodeParam codeFail">Bad Request</span>
                    <span className="responseCodeDesc">The required parameter <code>data</code> was not present OR the column names did not match what's in the file</span>
                </div>
                <Divider/>
                <div className="responseCodeLine">
                    <code className="responseCodeStatus">401</code> <span className="responseCodeParam codeFail">Unauthorized</span>
                    <span className="responseCodeDesc">The data is private and incorrect access and secret keys were provided</span>
                </div>
                <Divider/>
                <div className="responseCodeLine">
                    <code className="responseCodeStatus">402</code> <span className="responseCodeParam codeFail">Payment Required</span>
                    <span className="responseCodeDesc">You are over the row limit per file for your plan</span>
                </div>
                <Divider/>
                <div className="responseCodeLine">
                    <code className="responseCodeStatus">404</code> <span className="responseCodeParam codeFail">Not Found</span>
                    <span className="responseCodeDesc">The fileID does not exist</span>
                </div>
                <Divider/>
                <div className="responseCodeLine">
                    <code className="responseCodeStatus">405</code> <span className="responseCodeParam codeFail">Method Not Allowed</span>
                    <span className="responseCodeDesc">You configured a security feature such as blocking reading requests or whitelisting domains and you are breaking the rules</span>
                </div>
                <Divider/>
                <div className="responseCodeLine">
                    <code className="responseCodeStatus">406</code> <span className="responseCodeParam codeFail">Not Acceptable</span>
                    <span className="responseCodeDesc">The format of data is not correct</span>
                </div>
                <Divider/>
                <div className="responseCodeLine">
                    <code className="responseCodeStatus">500</code> <span className="responseCodeParam codeFail">Server Error</span>
                    <span className="responseCodeDesc">There was something wrong with our server</span>
                </div>
                <Divider/>
                <div className="responseCodeLine">
                    <code className="responseCodeStatus">502</code> <span className="responseCodeParam codeFail">Bad Gateway</span>
                    <span className="responseCodeDesc">Something went wrong with the Google Sheets or DropBox Servers</span>
                </div>
                <br/>
            </div>
        )
    }
}

export default DocsPostResponseCode