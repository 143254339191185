import React, {Component} from 'react'
import {connect} from 'react-redux'
import BannerAlert from "../../../../SharedComponents/BannerAlert"
import { Icon } from 'semantic-ui-react'

class DatabaseDestinationsErrorBanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: true
        }
    }

    close = () => this.setState({ display: false })

    render() {
        if (this.state.display){
            return (
                <div>
                    <BannerAlert
                        type="display"
                        header='Columns must be set up if data is going to a database'
                        icon="bx bx-error-alt"
                        content={<span>The <b>internal column name(s)</b> in the <Icon name="columns" /> Columns tab must be present in the tables you select in your databases</span>}
                        action='close'
                        close={this.close}
                    />
                </div>
            )
        } else {
            return null
        }

    }
}

const mapStateToProps = (state) => ({
    destinations: state.mainState.customImportDestinations,
    destinationsEdit: state.mainState.customImportDestinationsEdit,
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DatabaseDestinationsErrorBanner)