import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Dropdown, Button } from 'semantic-ui-react'
import {initOriginalFilePK} from "../../Actions/InitOriginalFilePK";
import {modifyFilePK} from "../../Actions/ModifyFilePK";
import {sortArrayOfObjects} from "../../Constants/sortArrayOfObject";

// 0 is ascending, 1 is descending
const iconNames = {
    fileName: ["sort alphabet up", "sort alphabet down"],
    createdDt: ["sort numeric up", "sort numeric down"]
}

const isListInOrder = (arr, keyType) => {
    let isInOrder = true;

    for (let i=0; i<arr.length - 1; i++){
        if (arr[i][keyType] > arr[i + 1][keyType]){
            isInOrder = false
            break
        }
    }

    return isInOrder
}

class DateSort extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sortType: this.props.sortType,
            sortDropdown: this.props.sortDropdown
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.sortFiles = this.sortFiles.bind(this);
        this.findSortedOrder = this.findSortedOrder.bind(this);
    }

    handleInputChange(e, { value }){
        // const split = value.split("_")
        this.props.changeState("sort", value)

        // this.setState({
        //     sortType: split[0],
        //     dropdownValue: value
        // }, () =>
        // this.sortFiles(e, split[1])
    }

    sortFiles(e, order){
        let sortedFilePK = sortArrayOfObjects(JSON.parse(JSON.stringify(this.props.filePK)), this.state.sortType)

        if (order === "descending"){
            sortedFilePK = sortedFilePK.reverse()
        }

        this.props.modifyFilePK(sortedFilePK)
    }

    findSortedOrder(){
        let sortedOrder = "unsorted";
        const filePK = JSON.parse(JSON.stringify(this.props.filePK))
        const filePKReverse = JSON.parse(JSON.stringify(this.props.filePK));
        filePKReverse.reverse()

        if (filePK.length > 1){
            const firstVal = filePK[0][this.state.sortType];
            const secondVal = filePK[1][this.state.sortType];
            if (firstVal < secondVal){
                if (isListInOrder(filePK, this.state.sortType)){
                    sortedOrder = "ascending"
                }
            }
            else if (firstVal > secondVal){
                if (isListInOrder(filePKReverse, this.state.sortType)){
                    sortedOrder = "descending"
                }
            }
            else{
                if (isListInOrder(filePK, this.state.sortType)){
                    sortedOrder = "ascending"
                }
                else if (isListInOrder(filePKReverse, this.state.sortType)){
                    sortedOrder = "descending"
                }
            }
        }

        return sortedOrder
    }

    render() {
        const sortedOrder = this.findSortedOrder()
        return (
            <div style={{height:'40px', top:'0px',backgroundColor:'#F0F0F0',border:'none',padding:'8px 8px 8px 12px'}}>
                <span>
                    Sort By: {' '}
                    <Dropdown
                        inline
                        options={[
                            {key: 0, value: 'createdDt_descending', text: 'Date Desc', icon: "sort numeric down"},
                            {key: 1, value: 'createdDt_ascending', text: 'Date Asc', icon: "sort numeric up"},
                            {key: 2, value: 'fileName_descending', text: 'Name Desc', icon: "sort alphabet down"},
                            {key: 3, value: 'fileName_ascending', text: 'Name Asc', icon: "sort alphabet up"},
                        ]}
                        value={this.props.sortDropdown}
                        onChange={this.handleInputChange}
                    />
                </span>

                {/*<span style={{paddingLeft:'100px'}}>*/}
                    {/*<Button.Group>*/}
                    {/*    <Button icon={iconNames[this.state.sortType][0]} basic={sortedOrder !== "ascending"} color="green" onClick={(e) => this.sortFiles(e, "ascending")}/>*/}
                    {/*    <Button icon={iconNames[this.state.sortType][1]} basic={sortedOrder !== "descending"} color="green" onClick={(e) => this.sortFiles(e, "descending")}/>*/}
                    {/*</Button.Group>*/}
                {/*</span>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    originalFilePK: state.mainState.originalFilePK,
    filePK: state.mainState.filePK
})

const mapActionsToProps = {
    initOriginalFilePK: initOriginalFilePK,
    modifyFilePK: modifyFilePK
}

export default connect(mapStateToProps, mapActionsToProps)(DateSort)