import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../../Constants/API_Root";
import Loader from 'react-loader-spinner'
import { Message, Icon } from 'semantic-ui-react'
import currencyInfo from "../../Constants/currencyInfo";

const monthAbbrev = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]

class DownloadReceipts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            receipts: [],
            error: null,
            fetching: false
        }
    }

    dismissMessage = () => this.setState({ error: null })

    formatTimeStamp = (timeStamp) => {
        const dateObj = new Date(timeStamp * 1000)
        return monthAbbrev[dateObj.getMonth()] + " " + dateObj.getDate() + ", " + dateObj.getFullYear() + " " + dateObj.toLocaleTimeString()
    }

    componentDidMount(){
        this.setState({ fetching: true })

        const genericMsg = <span>There was an error on our server getting your receipts. Please email us at <a href="mailto:admin@lovespreadsheets.com">admin@lovespreadsheets.com</a> and we will email you the receipts</span>;
        const errorMsg = (msg) => this.setState({ error: msg, fetching: false})

        fetch(API_Root + "api/get-receipts-to-download/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
            })
        }).then(res => res.json()).then(data => {
            if ("errorMsg" in data){
                errorMsg(data['errorMsg'])
            } else {
                this.setState({
                    error: null,
                    receipts: data['receipts'],
                    fetching: false
                })
            }
        }).catch(err => errorMsg(genericMsg)).catch(err => errorMsg(genericMsg))
    }

    render() {
        let body = null;
        if (this.state.fetching){
            body =
                <div>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="25"
                        width="25"
                    />
                    <p><b>Getting your receipts...</b></p>
                </div>
        } else {
            if (this.state.error !== null){
                body = <Message
                    error
                    icon="ban"
                    header='There was an error getting your receipts'
                    content={this.state.error}
                />
            } else {
                if (this.state.receipts.length > 0){
                    // body = <p>Receipts Table</p>
                    let tableRows = [];
                    let tableColumns = ["created", "amount", "description", "receipt_url"];

                    for (let i=0; i < this.state.receipts.length; i++){
                        const receiptObj = this.state.receipts[i];

                        if (receiptObj.status === "succeeded"){
                            let row = []
                            for (let j=0; j < tableColumns.length; j++){
                                const tableKey = tableColumns[j];

                                let value = "";
                                if (tableKey === "amount"){
                                    const currencySymbol = currencyInfo[receiptObj['currency']]['symbol']
                                    const amount =  (currencySymbol === '¥' ? receiptObj["amount"] : (receiptObj["amount"] / 100))

                                    value = currencySymbol + amount
                                } else if (tableKey === "created"){
                                    value = this.formatTimeStamp(receiptObj["created"])

                                } else if (tableKey === "receipt_url"){
                                    value = <a href={receiptObj[tableKey]} target="_blank" rel="noopener noreferrer"><Icon name="download" /> Download your receipt</a>
                                } else{
                                    value = receiptObj[tableKey]
                                }

                                row.push(<td key={i.toString() + j.toString()}>{value}</td>)
                            }

                            tableRows.push(<tr key={i.toString()}>{row}</tr>)
                        }
                    }

                    if (tableRows.length === 0){
                        body = <Message
                            warning
                            icon="warning sign"
                            header="We could not find any receipts for you"
                            content={<span>If you think this is an error, please email us at <a href="mailto:admin@lovespreadsheets.com">admin@lovespreadsheets.com</a> from your account email</span>}
                        />
                    } else{
                        body =
                            <table className="table table-hover table-sm">
                                <thead className="thead-light">
                                <tr>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Description</th>
                                    <th>Receipt</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tableRows}
                                </tbody>
                            </table>
                    }


                } else {
                    body = <Message
                        warning
                        icon="warning sign"
                        header="We could not find any receipts for you"
                        content={<span>If you think this is an error, please email us at <a href="mailto:admin@lovespreadsheets.com">admin@lovespreadsheets.com</a> from your account email</span>}
                    />
                }
            }
        }

        return (
            <div>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DownloadReceipts)