import React, {Component} from 'react'
import {connect} from 'react-redux'
import AuthComponent from "../SharedComponents/AuthComponent";
import UpdatedLandingPageIndex from "../LandingPage/UpdatedLandingPageIndex";
import {isBrowser} from "react-device-detect";
import MobileLandingPage from '../LandingPage/MobileLandingPage';
import UtilitiesIndex from "./UtilitiesIndex"

class UtilitiesAuthIndex extends Component {
    render() {
        if (isBrowser){
            return (
                <div>
                    <AuthComponent
                        authComponent={<UtilitiesIndex/>}
                        nonAuthComponent={<UpdatedLandingPageIndex/>}
                    />
                </div>
            )
        } else {
            return (
                <div>
                    <AuthComponent
                        authComponent={<UtilitiesIndex/>}
                        nonAuthComponent={<MobileLandingPage/>}
                    />
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UtilitiesAuthIndex)