import React, {Component} from 'react'
import { Table, Input, Dropdown, Checkbox } from 'semantic-ui-react'

class DataModelRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnName: this.props.columnName,
            dataType: this.props.dataType,
            required: this.props.required
        }
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    updateDataType = (event, { value }) => this.setState({"dataType": value})

    toggleRequired = () => {
        const oppositeRequired = !this.state.required

        this.setState({ required: oppositeRequired })
    }

    render() {
        const dataTypeOptions = [
            {key: 1, value: "text", text: "text"},
            {key: 2, value: "numerical", text: "numerical"},
            {key: 3, value: "boolean", text: "boolean"},
            {key: 4, value: "datetime", text: "datetime"},
        ]

        return (
            <Table.Row>
                <Table.Cell>
                    <Input fluid value={this.state.columnName} onChange={this.handleInputChange} name="columnName" focus />
                </Table.Cell>
                <Table.Cell>
                    <Dropdown options={dataTypeOptions}
                              onChange={this.updateDataType}
                              selection
                              value={this.state.dataType}
                              fluid />
                </Table.Cell>
                <Table.Cell>
                    <div style={{textAlign: "center", marginTop: "10px"}}>
                        <Checkbox checked={this.state.required} onChange={this.toggleRequired} />
                    </div>
                </Table.Cell>
            </Table.Row>
        )
    }
}

export default DataModelRow