import React, {Component} from 'react'
import Hero from "../../SharedComponents/Hero";

class PromoBanner extends Component {
    render() {
        // <div style={{textAlign: 'center', paddingTop: '100px', paddingBottom: '50px'}}>
        // <h1>Lifetime Access Promotion</h1>
        // <p>Limited Time Offer</p>
        // </div>
        return (
            <Hero
                textColor="white"
                header="Lifetime Access Promotion"
                desc="Limited Time Offer"
            />
        )
    }
}

export default PromoBanner