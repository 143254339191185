import React, {Component} from 'react'
import {isMobile} from "react-device-detect";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

class MobileFeaturesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeFunction: this.props.menuItems[0].name
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e, name){
        this.setState({activeFunction: name})
    }

    isImporter = () => 'importer' in this.props;

    isUtility = () => 'utility' in this.props;

    render() {
        const generalStyle = {cursor: "pointer", padding: "12px 16px 12px 16px", borderBottom: "1px solid #F0F0F0"}
        const selectedStyle = JSON.parse(JSON.stringify(generalStyle))
        selectedStyle['backgroundColor'] = this.isImporter() ? gunaldiStyles.orange : this.isUtility() ? gunaldiStyles.blue : '#6FBB98'
        selectedStyle['color'] = 'white'
        selectedStyle['borderRadius'] = "6px"

        let menuItems = [];
        let italicHeader = null;

        for (let idx=0; idx<this.props.menuItems.length; idx++){
            const x = this.props.menuItems[idx];
            const header = "header" in x ? <div style={{fontFamily: "Lato", fontStyle: "italic", fontWeight: "400", fontSize: "12px"}}>{x.header}</div> : null

            if (this.state.activeFunction === x.name){
                italicHeader = <div style={{textAlign: "center", fontWeight: "400", fontSize: "14px", color: "#9696A0", fontFamily: "Lato", marginBottom: "12px"}}>{x.header}</div>
            }

            if (this.state.activeFunction === x.name){
                menuItems.push(
                    <div style={{border: "1px solid " + ( this.isImporter() ? gunaldiStyles.orange : this.isUtility() ? gunaldiStyles.blue : "#6FBB98"), borderRadius: "6px"}}>
                        <div style={{padding: "12px 16px 12px 16px", backgroundColor: this.isImporter() ? gunaldiStyles.orange : this.isUtility() ? gunaldiStyles.blue : "#6FBB98", color: "white", borderRadius: "6px 6px 0px 0px"}}>
                            <div style={{fontFamily: "Lato", fontStyle: "italic", fontWeight: "400", fontSize: "12px"}}>{header}</div>
                            <div style={{fontFamily: "Lato", fontSize: "16px", fontWeight: "700"}}>{x.displayName}</div>
                        </div>
                        <div style={{backgroundColor: "white", padding: "12px" }}>
                            {italicHeader}
                            {this.props.menuDisplay[this.state.activeFunction]}
                        </div>
                    </div>
                )
            } else {
                menuItems.push(
                <div style={generalStyle} key={idx} onClick={e => this.handleClick(e, x.name)}>
                    {header}
                    <div style={{fontFamily: "Lato", fontSize: "16px", fontWeight: "700"}}>{x.displayName}</div>
                </div>)
            }
        }

        const cleanWidth = isMobile ? "31.25%" : "30%";
        const exampleWidth = isMobile ? "68.75%" : "70%";

        const firstMenuItem =  this.props.menuItems[0]
        return (
            <div>
                {menuItems}
            </div>
        )
    }
}

export default MobileFeaturesTable