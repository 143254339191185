import React, {Component} from 'react'
import SampleStartTable from "../SampleStartTable";
import UpdateTable from "./UpdateTable";
import DocsBaseURL from '../../DocsBaseURL'
import DocsPostRequest from '../../DocsPostRequest';
import DocsPostBodyParameters from "./DocsPostBodyParameters";
import DocsPostResponse from "./DocsPostResponse";
import DocsPostHeaderParameters from "../../DocsPostHeaderParameters";
import PostHeaderReq from "../Create/PostHeaderReq";
import DocsPostResponseCode from "../../DocsPostResponseCode";

class UpdateIndex extends Component {
    render() {
        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader"><b>Update</b> refers to updating values in columns</p>
                    <br/>
                    <div className="row">
                        <div className="col-sm-6">
                            <SampleStartTable/>
                        </div>
                        <div className="col-sm-6">
                            <UpdateTable/>
                        </div>
                    </div>
                </div>
                <div className="docsSection">
                    <p className="docsHeader" style={{marginBottom: "0", paddingBottom: "0"}}>Request</p>
                    <hr/>
                    <p className="docsHeader">1. Make a <b>POST</b> Request to the following URL</p>
                    <DocsBaseURL url="data/[fileID]/"/>
                    <i>Example URL</i>
                    <DocsBaseURL url="data/aBcDe123xy0z/" />
                    <DocsPostRequest type="url" />
                    <hr/>
                    <br/>
                    <p className="docsHeader">2. Include a JSON Encoded Body with Update Data AND Query</p>
                    <br/>
                    <div className="row">
                        <div className="col-sm-6">
                            <DocsPostBodyParameters setBothMenuItems={this.props.setBothMenuItems}/>
                        </div>
                        <div className="col-sm-6">
                            <DocsPostResponse/>
                        </div>
                    </div>
                    <hr/>
                    <br/>
                    <p className="docsHeader" style={{color: 'gray'}}>3. Add Keys in the Request Header <i>if private</i></p>
                    <div className="row">
                        <div className="col-sm-6">
                            <DocsPostHeaderParameters />
                        </div>
                        <div className="col-sm-6">
                            <PostHeaderReq/>
                        </div>
                    </div>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Response</p>
                    <span>The following response codes can be returned</span>
                    <div style={{fontSize: '115%'}}>
                        <DocsPostResponseCode/>
                    </div>
                </div>
            </div>
        )
    }
}

export default UpdateIndex