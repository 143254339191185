import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import API_Root from "../../Constants/API_Root";

class SaveWebhookEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }
    }

    saveWebhookEvents = () => {
        this.setState({ loading: true })

        const isError = () => {this.setState({ loading: false }); alert("There was an error saving your events. Please try again")}

        fetch(API_Root + "api/save-api-webhook-info/", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                info: {"events": this.props.events},
                pk: this.props.modalInfo.pk
            })
        }).then((res) => {
            if (res.status === 201){
                this.setState({ loading: false })
                this.updateWebhookInfoInRedux()
            } else {
                isError()
            }
        }).catch((err) => {
            isError()
        })

    }

    updateWebhookInfoInRedux = () => {
        const fileInformation = JSON.parse(JSON.stringify(this.props.fileInformation))
        const editIDInfo = fileInformation[this.props.editID]

        editIDInfo.webhookInfo = this.props.events;

        fileInformation[this.props.editID] = editIDInfo

        this.props.modifyFileInformation(fileInformation)
    }

    render() {
        return (
           <Button
               style={{backgroundColor: "#6FBB98", color: "white"}}
               content="Save Webhook Events"
               icon="save"
               onClick={this.saveWebhookEvents}
               disabled={!this.props.areStatesDifferent}
               loading={this.state.loading}
           />

        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    editID: state.mainState.editID,
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation
}

export default connect(mapStateToProps, mapActionsToProps)(SaveWebhookEvents)