import React, {Component} from 'react'

class HeaderIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let title = null;
        let style = <div style={{backgroundColor:'#f5fbf4',padding:'10px',position: 'absolute'}}> </div>
        let desc = null;
        return (
            <div>
                <div style={style}>
                    <h2> {this.props.title}</h2>
                    <p> {desc}</p>
                </div>
            </div>

        )
    }
}

export default HeaderIndex
