import {getCleanDomain} from "./getCleanDomain";
import {isTLDValid} from "../SharedComponents/isTLDValid";
import {isURLComponentValid} from "../SharedComponents/isURLComponentValid";

export const isDomainNameValid = (domainName) => {
    const testDomainName = domainName.toString().trim().toLowerCase().replace("http://", "").replace("https://", "").replace("*.", "jm6wi72hnbk0ar2l.")

    let isValid = true;

    const domainComponents = testDomainName.split(".")

    if (domainComponents.length > 1){
        const tld = domainComponents[domainComponents.length-1];

        if (!isTLDValid(tld)){
            isValid = false
        } else {
            if (domainComponents.length === 2){
                if (domainComponents[0] === "jm6wi72hnbk0ar2l" || !isURLComponentValid(domainComponents[0])){
                    isValid = false
                }
            } else {
                for (let i=0; i<domainComponents.length-1;i++){
                    if (!isURLComponentValid(domainComponents[i])){
                        isValid = false
                        break
                    }
                }
            }
        }
    } else {
        isValid = false
    }

    return isValid
}