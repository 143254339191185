import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CircleComp extends Component {
    render() {
        const { color } = this.props;

        return (
            <div
                style={{
                    backgroundColor: color,
                    borderRadius: '50%', // Makes the div circular
                    width: '10px', // Adjust size as needed
                    height: '10px',
                }}
            ></div>
        );
    }
}

CircleComp.propTypes = {
    color: PropTypes.string.isRequired,
};

export default CircleComp;
