import React, {Component} from 'react'
import { Icon, Label } from 'semantic-ui-react'

class FilesTabFileSourceNames extends Component {
    render() {
        const inputType = 'inputType' in this.props.fileInformation ? this.props.fileInformation['inputType'] : null;

        if (inputType === null){
            return (
                null
            )
        }
        else{
            let fileType = null;
            let color = null;

            if (inputType.includes("file")){
                fileType = "Local"
                color = "#6FBB98";

            }
            else if (inputType.includes("dropbox")){
                fileType = "Dropbox"
                color = "#2F6ED9"

            }
            else if (inputType.includes("google")){
                fileType = "Google Drive"
                color = "#479B5F"

            }
            else if (inputType.includes("s3")) {
                fileType = "AWS S3"
                color = "#FF9900"

            }
            else if (inputType.includes("quickstart")){
                fileType = "Quickstart"
                color = "black"
            }
            else if (inputType.includes("database")){
                fileType = "Database"
                color = "#EE685F"
            }

            return(
                <div>
                   <p style={{color:color, fontFamily:'Lato'}}> {fileType}</p>
                </div>
            )

        }
    }
}

export default FilesTabFileSourceNames