import React, {Component} from 'react'
import InputDataTable from "./InputDataTable";
import RequestURLBox from "../../SharedComponents/RequestURLBox"
import {getFullAPIUrl} from "../../Utilities/getFullAPIUrl";
import API_Root from "../../Constants/API_Root";
import API_Backend_Root from "../../Constants/API_Backend_Root";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import ArrowRight from "../Illustrations/arrow-right.svg"
import ArrowDown from "../Illustrations/arrow-down.svg"
import images from "../../Constants/Images";
import AIResultsTable from "./AIResultsTable";
import CreateResults from "./CreateResults";
import DownloadResults from "./DownloadResults";

class UtilitiesDisplayIndex extends Component {
    getHeaderText = (mode) => {
        switch (mode) {
            case "ai":
                return "Call API  with your data and your prompt"
            case "create":
                return "Create a file in your account with your data"
            case "download":
                return "Get a link to download your data in .xlsx"
            default:
                return ""
        }
    }

    getResultsDisplay = (mode) => {
        switch (mode) {
            case "ai":
                return <AIResultsTable isMobile={this.isMobile()}/>
            case "create":
                return <CreateResults headerStyle={this.props.headerStyle} isMobile={this.isMobile()}/>
            case "download":
                return <DownloadResults/>
            default:
                return null
        }
    }

    isMobile = () => ('mobile' in this.props);

    render() {
        const mode = this.props.mode;
        const apiUrl = getFullAPIUrl(API_Backend_Root, mode, "abcd1234")
        return (
            <div>
                <div style={this.props.headerStyle}>
                    {this.getHeaderText(mode)}
                </div>
                {
                    mode === "ai" ? <div>
                        <pre id="apiFeatureModalURLBox">
                            <div style={{ fontWeight: "700"}}>prompt</div>
                            <div>show me movies where {this.isMobile() ? <br/> : ""}Chris Pratt acted</div>
                        </pre>
                    </div> : null
                }
                <InputDataTable isMobile={this.isMobile()}/>
                {
                    this.isMobile() ?
                        <div>
                            <div style={{ gridArea: "down-arrow"}}>
                                <div style={{ textAlign: "center", paddingTop: "12px", paddingBottom: "12px"}}>
                                    <img src={ArrowDown} style={{ maxHeight: "40px" }} alt="Down arrow"/>
                                </div>
                            </div>
                            <div style={{ gridArea: "api-logo"}}>
                                <div style={{ textAlign: "center"}}>
                                    <img src={images.logoNav} style={{ maxWidth: "200px", border: "1px solid #F0F0F0"}} alt="product-logo-small"/>
                                </div>
                            </div>
                            <div style={{ gridArea: "down-arrow"}}>
                                <div style={{ textAlign: "center", paddingTop: "12px", paddingBottom: "12px"}}>
                                    <img src={ArrowDown} style={{ maxHeight: "40px" }} alt="Down arrow"/>
                                </div>
                            </div>
                            <div style={{ gridArea: "results"}}>
                                <div style={{ paddingBottom: "12px"}}>
                                    {this.getResultsDisplay(this.props.mode)}
                                </div>
                            </div>
                        </div> :
                        <div style={{ display: 'grid', gridTemplateColumns: "1fr 60px 3fr", gridTemplateRows: "auto", gridTemplateAreas: '"down-arrow . results"\n"api-logo right-arrow results"'}}>
                            <div style={{ gridArea: "down-arrow"}}>
                                <div style={{ textAlign: "center", paddingTop: "12px", paddingBottom: "12px"}}>
                                    <img src={ArrowDown} style={{ maxHeight: "40px" }} alt="Down arrow"/>
                                </div>
                            </div>
                            <div style={{ gridArea: "api-logo"}}>
                                <div style={{ textAlign: "center"}}>
                                    <img src={images.imageIcon} style={{ maxWidth: "50%"}} alt="product-logo-small"/>
                                </div>
                            </div>
                            <div style={{ gridArea: "results"}}>
                                <div style={{ paddingLeft: "16px", paddingTop: "12px", paddingBottom: "12px"}}>
                                    {this.getResultsDisplay(this.props.mode)}
                                </div>
                            </div>
                            <div style={{ gridArea: "right-arrow"}}>
                                <div style={{ textAlign: "left", paddingTop: "8px" }}>
                                    <img src={ArrowRight} style={{ maxWidth: "60px"}} alt="Right arrow"/>
                                </div>
                                <span style={{ color: "white" }}>right-arrow</span>
                            </div>
                        </div>
                }
                {/*<RequestURLBox url={apiUrl}/>*/}
            </div>
        )
    }
}

export default UtilitiesDisplayIndex