import React, {Component} from 'react'
import {connect} from 'react-redux'
import UpgradeToTeam from "../UpgradeToTeam"
import ReportingModalBody from "./ReportingModalBody"

class ReportingModalBodyIndex extends Component {
    getDivStyle = (isTeam) => {
        let style = {marginTop: "16px"}

        if (!isTeam){
            style['pointerEvents'] = "none"
            style['opacity'] = "0.5"
            // style['cursor'] = "not-allowed"
        }

        return style
    }

    render() {
        const isTeam = this.props.tier === "team" || this.props.tier === "business" || this.props.tier === "enterprise";

        return (
            <div>
                {isTeam ? null :  <div style={{marginTop: "8px"}}><UpgradeToTeam higherTier="reporting" header="Auto reporting can only be set up on a Team or higher account"/></div> }
                <div style={isTeam ? null : {"cursor": "not-allowed"}}>
                    <div style={this.getDivStyle(isTeam)}>
                        <ReportingModalBody pk={this.props.pk} pkID={this.props.pkID} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ReportingModalBodyIndex)