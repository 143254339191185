export const getFileHash = (fileInfo, pk) => {
    let fileHash;

    try{
        if (fileInfo.useFileHash){
            fileHash = fileInfo.fileHash
        } else {
            fileHash = pk.toString()
        }
    } catch (e){
        fileHash = pk.toString()
    }

    return fileHash
}