import React, {Component} from 'react'
import { Label } from 'semantic-ui-react'
import DocsImage from "../DocsImage"
import Gist from "react-gist";
import CodeSnippet from "../CodeSnippet";

class ImporterURL extends Component {
    render() {
        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">Introduction</p>
                    <p>We provide an already configured URL hosted on apispreadsheets.com that you can share for users, customers, clients, to upload their spreadsheets to you</p>
                    <p>You can find this URL on the Importer page, and each importer's page as seen below</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterNoCode-MainPage.png"
                               width="100%"
                               alt='Database Columns' />
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterNoCode-Index.png"
                               width="100%"
                               alt='Database Columns' />
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Copy the URL</p>
                    <p>You can copy the URL and share it right away with anyone. There is no additional set up needed</p>
                    <p>If you wish to use your own domain instead of apispreadsheets.com, then you can <span className="linkStyle" onClick={e => this.props.setBothMenuItems("integrations-connectDomain")}>connect your own domain</span></p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Uploading a file</p>
                    <p>When a unique user visits the URL they are asked from their name</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Importer-Use/ImporterNoCode-Name.png"
                               width="100%"
                               alt='Database Columns' />
                    <p>The import process starts then begins for user to upload their file</p>
                    <DocsImage
                        src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/ImportSpreadsheetsOnYourApps/TestCode.png"
                        width='100%'
                        alt="Test Import Button"
                    />
                    <p>You can <span className="linkStyle" onClick={e => this.props.setBothMenuItems("usingImporter-introduction")}>read all about how the uploading process works here</span></p>
                </div>
            </div>
        )
    }
}

export default ImporterURL