import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import { isBrowser } from "react-device-detect";
import {modifyEditID} from "../../Actions/ModifyEditID";
import {toggleModal} from "../../Actions/ToggleModal";
import ImportSheets from '../ImportSheets'
import FixColumnNamesTable from "./FixColumnNamesTable";
import ReuploadFile from "../ReuploadFile";
import RemoveFile from "../RemoveFile"
import HeaderReuploadFileButton from "../HeaderReuploadFileButton"
import PageHeaderButton from "../../SharedComponents/PageHeaderButton"
import ToggleLazyMode from "../FilesID/LazyMode/ToggleLazyMode"
import {getFileInputType} from "../../Constants/getFileInputType";

class FixColumnNames extends Component {
    constructor(props){
        super(props);

        this.openEditModal = this.openEditModal.bind(this);
    }

    openEditModal(){
        this.props.modifyEditID(this.props.pkID)
        this.props.toggleModal("editModal")
    }

    openImportSheetsModal = () => {
        this.props.modifyEditID(this.props.pkID);
        this.props.toggleModal("importSheets");
    }

    render() {
        let fileInformation = this.props.fileInformation[this.props.pkID]
        const colInfo = JSON.parse(JSON.stringify(fileInformation.columnInformation));
        const fileType = fileInformation.inputType;

        let fileName = fileInformation.name;
        // const size = formatBytes(fileInformation.size);

        const isExcel = fileName.split('.').pop() !== "csv" && fileName.split('.').pop() !== "txt";
        const importSheets = isExcel ?
            <PageHeaderButton content="Change Sheet" color="#6FBB98" icon='file alternate outline' onClick={this.openImportSheetsModal} updatedClassName="fixColumnNamesEditBtn"/> :
            null;

        let fileNamesMap = {}

        if (colInfo.names.length > 0){
            for (let i=0; i<colInfo.names.length; i++){
                fileNamesMap[colInfo.names[i]] = {"fileColumn": colInfo["info"][colInfo.names[i]]["original"], "idx": i}
            }

            return (
                <div className="fixColumnNames">
                    <div>
                        <FixColumnNamesTable columnNamesMap={fileNamesMap}/>
                    </div>
                    <div>
                        <h3 style={{fontWeight: "700", fontSize: "20px", fontFamily: "Lato", color: "#161E16"}}>Column Names in your file were not valid</h3>
                        <p className="descGenericText" style={{marginTop: "16px"}}>Change the Column Names in your file and {fileType === "file" ? "Reupload" : "Refresh"}</p>
                        <div className="fixColumnNamesBtnRow" style={{marginTop: "2px"}}>
                            <div>
                                <HeaderReuploadFileButton pk={this.props.pk} pkID={this.props.pkID} fileHash={this.props.fileHash} updatedClassName="fixColumnNamesReuploadBtn" color="white" fixColumnNames />
                            </div>
                            <div>
                            </div>
                            <div>
                            </div>
                        </div>
                        <p className="descGenericText" style={{marginTop: "18px"}}>Or you can re-read your file with different options</p>
                        <div className="fixColumnNamesBtnRow" style={{marginTop: "2px"}}>
                            <div>
                            {isBrowser ?
                                <PageHeaderButton content="File Options" color="#6FBB98" icon='edit' onClick={this.openEditModal} updatedClassName="fixColumnNamesEditBtn"/>
                             : null}
                            </div>
                            <div>
                                {importSheets}
                            </div>
                            <div>
                            </div>
                        </div>
                        {/*{*/}
                            {/*getFileInputType(fileInformation) === 'googleSheets' ?*/}
                                {/*<div style={{ marginTop: '16px' }}>*/}
                                    {/*<ToggleLazyMode pk={this.props.pk}*/}
                                                    {/*display={<span className="linkStyle">{"Switch to non-strict mode"}</span>}*/}
                                                    {/*pkID={this.props.pkID}*/}
                                                    {/*isLazyMode={false}*/}
                                                    {/*fileInformation={fileInformation} />*/}
                                {/*</div>*/}
                            {/*: null*/}
                        {/*}*/}
                    </div>
                </div>
            )
        }
        else{
            return(
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,

})

const mapActionsToProps = {
    modifyEditID: modifyEditID,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(FixColumnNames)