import {getPostData} from "./getPostData";

export const getCodeSnippet = (fileInformation, pk, pkID, apiType, createFormat, fileURL, language, full_data=false, update_query=null) => {
    let codeSnippet;
    const fileInfo = fileInformation[pkID];

    switch (language) {
        case "cURL":
            codeSnippet = getCURLCode(apiType, createFormat, fileURL, fileInfo, pk, full_data, update_query);
            break;
        case "python":
            codeSnippet = getPythonCode(apiType, createFormat, fileURL, fileInfo, pk, full_data, update_query);
            break;
        case "javascript":
            codeSnippet = getJavascriptCode(apiType, createFormat, fileURL, fileInfo, pk, full_data, update_query);
            break;
        case "ruby":
            codeSnippet = getRubyCode(apiType, createFormat, fileURL, fileInfo, pk, full_data, update_query);
            break;
        case "php":
            codeSnippet = getPHPCode(apiType, createFormat, fileURL, fileInfo, pk, full_data, update_query);
            break;
        case "html":
            codeSnippet = getHTMLCode(apiType, fileInfo, fileURL, pk)
            break;
        default:
            codeSnippet = ""
    }

    return codeSnippet
}

const getHTMLCode = (apiType, fileInfo, fileURL, pk) => {
    if (apiType === "createAction"){
        let columnNames = fileInfo['columnNames'];

        if ('columnInformation' in fileInfo){
            if ('names' in fileInfo['columnInformation']){
                if (fileInfo['columnInformation']['names'].length > 0){
                    columnNames = fileInfo['columnInformation']['names']
                }
            }
        }

        let formHTML = "";

        for (let i=0; i<columnNames.length; i++){
            formHTML += "\t\t<label>" + columnNames[i] + "</label>\n\t\t<input name=\"" + columnNames[i] + "\" />\n\t\t<br/>\n"
        }

        return "<!DOCTYPE html>\n" +
            "<html>\n" +
            "<head>\n" +
            "\t<title>Save Web Form Data to Spreadsheets</title>\n" +
            "\t<script\n" +
        "\t\tsrc=\"https://code.jquery.com/jquery-3.4.1.js\"\n" +
        "\t\tintegrity=\"sha256-WpOohJOqMqqyKL9FccASB9O0KwACQJpFTUBLTYOVvVU=\"\n" +
        "\t\tcrossorigin=\"anonymous\"></script>\n" +
        "\t<script>\n" +
            "\t\tfunction SubForm (){\n" +
                "\t\t\t$.ajax({\n" +
                    "\t\t\t\turl:\"" + fileURL + "\",\n" +
                    "\t\t\t\ttype:\"post\",\n" +
                    "\t\t\t\tdata:$(\"#myForm\").serializeArray(),\n" +
                    getPostHeader(fileInfo, "html") +
                    "\t\t\t\tsuccess: function(){\n" +
                    "\t\t\t\t\talert(\"Form Data Submitted :)\")\n" +
                    "\t\t\t\t},\n" +
                    "\t\t\t\terror: function(){\n" +
                    "\t\t\t\t\talert(\"There was an error :(\")\n" +
                    "\t\t\t\t}\n" +
                "\t\t\t});\n" +
            "\t\t}\n" +
        "\t</script>\n" +
            "</head>\n" +
            "<body>\n" +
            "\t<form id=\"myForm\">\n" +
            formHTML +
            "\t</form>\n" +
            "\t<button onclick='SubForm()'>Submit</button>\n" +
            "</body>\n" +
            "</html>"
    } else {
        return ""
    }
}

const getCURLCode = (apiType, createFormat, fileURL, fileInfo, pk, full_data, update_query) => {
    if (apiType === "readAction" || apiType === "deleteAction"){
        return '$ curl "' + fileURL + '"'
    }
    else{
        let postData = getPostData(fileInfo, createFormat, apiType, pk, full_data, update_query);
        postData = postData.replace(/'/g, "'\\''");
        let postBody = "'" + postData + "'";

        return "$ curl -X POST '" +  fileURL + "' \\\n" + getPostHeader(fileInfo, "curl") + "\n-d " + postBody
    }

}

const getPythonCode = (apiType, createFormat, fileURL, fileInfo, pk, full_data, update_query) => {
    let code = "# pip install requests\n\nimport requests\n\n"
    if (apiType === "readAction" || apiType === "deleteAction"){
        code += 'r = requests.get("' + fileURL + '")\n\n'
        code += "if r.status_code == 200:\n\t# SUCCESS \n\t"
        code += apiType === "readAction" ? "data = r.json()" : "pass"
        code += "\nelse:\n\t# ERROR\n\t"
        code += apiType === "readAction" ? "data=None" : "pass"
    }
    else{
        let postHeader = getPostHeader(fileInfo, "python")
        let postBody = getPostData(fileInfo, createFormat, apiType, pk, full_data, update_query)

        code += 'r = requests.post("' + fileURL + '", headers=' + postHeader + ", json=" + postBody + ")\n\n"
        code += "if r.status_code == 201:\n\t# SUCCESS \n\tpass\nelse:\n\t# ERROR\n\tpass"
    }
    return code
}

const getJavascriptCode = (apiType, createFormat, fileURL, fileInfo, pk, full_data, update_query) => {
    let code = "fetch(";

    if (apiType === "deleteAction"){
        code += '"' + fileURL + '").then(res=>{\n'
        code += '\tif (res.status === 200){\n\t\t// SUCCESS\n\t'
        code += "}\n\telse{\n\t\t// ERROR\n\t}\n})"
    }
    else if (apiType === "readAction"){
        code += '"' + fileURL + '").then(res=>{\n'
        code += '\tif (res.status === 200){\n\t\t// SUCCESS\n'
        code += '\t\tres.json().then(data=>{\n'
        code += '\t\t\tconst yourData = data\n'
        code += '\t\t}).catch(err => console.log(err))\n'
        code += "\t}\n\telse{\n\t\t// ERROR\n\t}\n})"
    }
    else{
        let postHeader = getPostHeader(fileInfo, "javascript")
        let postBody = getPostData(fileInfo, createFormat, apiType, pk, full_data, update_query)

        code += '"' + fileURL + '", {\n'
        code += '\tmethod: "POST",\n'
        if (postHeader !== ""){
            code += "\t" + postHeader + ",\n"
        }

        code += "\tbody: JSON.stringify(" + postBody + "),\n"
        code += '}).then(res =>{\n'
        code += '\tif (res.status === 201){\n\t\t// SUCCESS\n\t'
        code += "}\n\telse{\n\t\t// ERROR\n\t}\n})"

    }

    return code
}

const getRubyCode = (apiType, createFormat, fileURL, fileInfo, pk, full_data, update_query) => {
    let code = "require 'net/http'\nrequire 'json'\n\n";

    code += 'url = "' + fileURL + '"\n'
    code += 'uri = URI.parse(url)\n\n'

    if (apiType === "readAction" || apiType === "deleteAction"){
        code += "response = Net::HTTP.get_response(uri)\n\n"
        code += 'if response.code == "200"\n'
        code += '\t# SUCCESS\n'
        code += apiType === 'readAction' ? '\tdata = JSON.parse(response.body)\n' : ""
    }
    else{
        let postHeader = getPostHeader(fileInfo, "ruby")
        let postBody = getPostData(fileInfo, createFormat, apiType, pk, full_data, update_query)

        code += "http = Net::HTTP.new(uri.host, uri.port)\n"
        code += "request = Net::HTTP::Post.new(uri.request_uri, " + postHeader + ")\n"
        code += "request.body = (" + postBody + ").to_json\n\n"
        code += "response = http.request(request)\n"
        code += "if response.code == '201'\n"
        code += "\t# SUCCESS\n"

    }
    code += 'else\n'
    code += '\t# ERROR\n'
    code += 'end'

    return code
}

const doesParamExistInURL = (param, url) => {
    return url.toString().includes("?" + param) || url.toString().includes("&" + param)
}

const getParamValue = (param, url) => {
    const urlStr = url.toString();

    let paramStartIdx;

    try{
        paramStartIdx = urlStr.indexOf(param + "=")
    } catch (e) {
        paramStartIdx = -1
    }

    if (paramStartIdx !== -1){
        let paramSubstring = urlStr.substring(paramStartIdx + (param + "=").length, urlStr.length)

        let paramEndIdx;

        if (paramSubstring.includes("&")){
            paramEndIdx = paramSubstring.indexOf("&")
        } else {
            paramEndIdx = paramSubstring.length
        }

        return paramSubstring.substring(0, paramEndIdx)
    } else {
        return ""
    }

}


const getVarToPHPCodeIfParam = (param, url) => {
    let varCode = null
    if (doesParamExistInURL(param, url)){
        let paramVal = ""
        if (param === "count"){
            paramVal = "true"
        } else {
            let rawParamVal = getParamValue(param, url)

            if (param === "dataFormat" || param === "query"){
                paramVal = "'" + rawParamVal + "'"
            } else {
                paramVal = rawParamVal
            }
        }

        varCode = "\t'" + param + "' => " + paramVal
    }

    return varCode
}

const getFileIDFromURL = (fileURL) => {
    let fileID = "";

    if (fileURL.indexOf("data/") !== -1){
        const fileIDSubstr = fileURL.substring(fileURL.indexOf("data/") + "data/".length, fileURL.length)

        if (fileIDSubstr.indexOf("/") !== -1){
            fileID = fileIDSubstr.substring(0, fileIDSubstr.indexOf("/"))
        } else {
            fileID = fileIDSubstr
        }
    }

    return fileID
}

const addAccessOrSecretKeysToPHPCodeIfNeeded = (phpCode, fileURL) => {
    let accessKey = null;
    if (doesParamExistInURL("accessKey", fileURL)){
        accessKey = getParamValue("accessKey", fileURL)
    }

    let secretKey = null;
    if (doesParamExistInURL("secretKey", fileURL)){
        secretKey = getParamValue("secretKey", fileURL)
    }

    if (accessKey !== null && secretKey !== null){
        phpCode += ",'" + accessKey + "', '" + secretKey + "'"
    }

    phpCode += ");"

    return phpCode
}

const getPHPCode = (apiType, createFormat, fileURL, fileInfo, pk, full_data, update_query) => {
    // let code = '<?php\n\n$url= "' + fileURL + '";\n$curl = curl_init($url);\ncurl_setopt($curl, CURLOPT_RETURNTRANSFER, true);\n\n'
    let code = "// Install our library by running the following composer command\n" +
        "// composer require asharma327/apispreadsheets-php\n// Read library specs in more detail here: https://github.com/asharma327/apispreadsheets-php\n\n" +
        "<?php\n\nrequire 'vendor/autoload.php';\n\nuse ApiSpreadsheets\\Spreadsheet;\n\n"
    let fileID = getFileIDFromURL(fileURL)

    if (apiType === "readAction"){
        let paramValues = [];
        let params = ["count", "query", "dataFormat", "limit"]
        for (let i=0; i<params.length; i++){
            let varCode = getVarToPHPCodeIfParam(params[i], fileURL)

            if (varCode !== null){
                paramValues.push(varCode)
            }
        }

        code += "$params = [\n" + paramValues.join(",\n") + "\n];\n\n"

        const readResponseCode =  "$response = Spreadsheet::read('" + fileID + "', $params"
        code += addAccessOrSecretKeysToPHPCodeIfNeeded(readResponseCode, fileURL)
    } else if (apiType === "deleteAction"){
        let query = "$query = "

        if (doesParamExistInURL("query", fileURL)){
            query += '"' + getParamValue("query", fileURL) + '";';
        } else {
            query += '"";'
        }

        code += query + "\n\n"

        const deleteResponseCode = "$response = Spreadsheet::delete('" + fileID + "', $query"
        code += addAccessOrSecretKeysToPHPCodeIfNeeded(deleteResponseCode, fileURL)
    } else if (apiType === "createAction" || apiType === "updateAction"){
        let postBody = getPostData(fileInfo, createFormat, apiType, pk, full_data, update_query)

        postBody = postBody.replace(/{/g, '[').replace(/}/g, ']').replace(/:/g, '=>')

        let data;
        const startIdx = postBody.indexOf('"data"=>') + '"data"=> '.length
        let endIdx;

        if (apiType === "createAction"){
            endIdx = postBody.length - 1;
        } else if (apiType === "updateAction"){
            endIdx = postBody.indexOf('"query"=>') - 2
        }

        data = postBody.substring(startIdx, endIdx)
        code += "$data = " + data + ";"

        let responseCode;
        if (apiType === "updateAction"){
            const queryStartIdx = postBody.indexOf('"query"=>') + '"query"=> '.length
            code += "\n\n$query = " + postBody.substring(queryStartIdx, postBody.length -1) + ";\n\n"

            responseCode =  "$response = Spreadsheet::update('" + fileID + "', $data, $query"
        } else if (apiType === "createAction"){
            code += "\n\n"
            responseCode =  "$response = Spreadsheet::create('" + fileID + "', $data"
        }

        let postHeader = getPostHeader(fileInfo, "php")

        if (postHeader.accessKey !== null && postHeader.secretKey !== null){
            responseCode += ",'" + postHeader.accessKey + "', '" + postHeader.secretKey + "'"
        }

        responseCode += ");"

        code += responseCode
    }

    code += "\n\n?>"
    // code += "else{\n"
    // code += "\t// ERROR CODE\n"
    // code += "}\n"
    // code += "curl_close($curl)\n"
    // code += "?>"

    return code
}

const isPrivate = (fileInfo) => {
    return fileInfo['private']
}

const getPostHeader = (fileInfo, language) => {
    let postHeader;

    switch (language){
        case "curl":
            postHeader = '-H "Content-Type: application/json" '
            if (isPrivate(fileInfo)){
                const accessKey = fileInfo['accessKey'];
                const secretKey = fileInfo['secretKey'];

                postHeader += ' -H "accessKey: ' +  accessKey + '" -H "secretKey: ' + secretKey + '" '
            }
            postHeader += "\\";
            break;
        case "python":
            postHeader = "{}"
            if (isPrivate(fileInfo)){
                const accessKey = fileInfo['accessKey'];
                const secretKey = fileInfo['secretKey'];

                postHeader = '{"accessKey": "' +  accessKey + '", "secretKey": "' + secretKey + '"}'
            }
            break;
        case "javascript":
            postHeader = ""
            if (isPrivate(fileInfo)){
                const accessKey = fileInfo['accessKey'];
                const secretKey = fileInfo['secretKey'];

                postHeader = 'headers: {"accessKey":"'  + accessKey + '", "secretKey":"' + secretKey + '"}'
            }
            break;
        case "ruby":
            postHeader = "{'Content-Type': 'text/json'"
            if (isPrivate(fileInfo)){
                const accessKey = fileInfo['accessKey'];
                const secretKey = fileInfo['secretKey'];

                postHeader += ", 'accessKey': '" + accessKey + "', 'secretKey': '" + secretKey + "'"
            }

            postHeader += "}"
            break
        case "php":
            postHeader = {accessKey: null, secretKey: null}

            if (isPrivate(fileInfo)){
                postHeader.accessKey = fileInfo['accessKey'];
                postHeader.secretKey = fileInfo['secretKey'];
            }
            break
        case "html":
            postHeader = ""

            if (isPrivate(fileInfo)){
                postHeader += '\t\t\t\theaders:{\n\t\t\t\t\taccessKey: "' + fileInfo['accessKey'] + '",\n\t\t\t\t\tsecretKey: "' + fileInfo['secretKey'] + '"},\n'
            }
            break
        default:
            postHeader = ""
    }

    return postHeader
}



