import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import { Button } from 'semantic-ui-react';

class LoginVerificationEmail extends Component {
    constructor(props) {
        super(props);

        this.openLoginModal = this.openLoginModal.bind(this);
    }

    openLoginModal(){
        this.props.toggleModal("loginModal")
    }

    render() {
        return (
            <div style={{minHeight: '100vh', padding: '10% 15% 15% 15%', textAlign: "center"}}>
                <h4 style={{color: 'red'}}>You need to Log In before you can Verify Your Email</h4>
                <Button color="green" content="Log In" onClick={this.openLoginModal} size="large"/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
}

export default connect(mapStateToProps, mapActionsToProps)(LoginVerificationEmail)