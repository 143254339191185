import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import TeamInfoModalBody from "./TeamInfoModalBody"

class TeamInfoModal extends Component {
    render() {
        return (
            <ModalApp name="teamInfoModal"
                      header={null}
                      body={<TeamInfoModalBody />}
                      size="large"
            />
        )
    }
}

export default TeamInfoModal