import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../SharedComponents/Modal"
import {modifyImporterName} from "../Actions/ModifyImporterName";
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import { Icon } from 'semantic-ui-react'
import ImportStartCreateButton from "./ImportStartCreateButton";
import {isImporterNameDuplicate} from "./isImporterNameDuplicate";

class ImportNameModal extends Component {
    constructor(props) {
        super(props);
    }

    onChange = (e) => {
        this.props.modifyImporterName(e.target.value);
    }

    render() {
        const modalApp = <div style={{textAlign: "left"}}>
            <div style={{color: gunaldiStyles.mainText, fontSize: "18px", fontWeight: "700", marginBottom: "16px"}}>
                <Icon name="table" /> Enter a name for your Importer
            </div>
            <div>
                <input className="inputGray"
                       style={{width: "100%"}}
                       onChange={this.onChange}
                       placeholder="Daily List of Attendance"
                       name="importerName"
                       value={this.props.importerName} />
            </div>
            <div style={{marginTop: "32px"}}>
                <ImportStartCreateButton />
            </div>
            <div style={{marginBottom: "32px"}}>
                {this.props.importerName.trim() === "" ? <div><small style={{color: "red"}}>Please enter a Name</small></div> : null}
                {isImporterNameDuplicate(this.props.importerName.trim(), this.props.importSheetsInfo) ? <div><small style={{color: "red"}}>Importer name already exists</small></div> : null}
            </div>
        </div>

        return (
            <div>
                <ModalApp name="importNameModal"
                          header={null}
                          body={modalApp}
                          size="small"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    importerName: state.mainState.importerName,
    importSheetsInfo: state.mainState.importSheetsInfo
})

const mapActionsToProps = {
    modifyImporterName: modifyImporterName
}

export default connect(mapStateToProps, mapActionsToProps)(ImportNameModal)