import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyTier} from "../Actions/ModifyTier";
import API_Root from "../Constants/API_Root";
import {ToastContainer, toast} from 'react-toastify'
import { toggleToast } from "../Actions/ToggleToast";
import {modifyCurrency} from "../Actions/ModifyCurrency";
import {initUserHasCurrency} from "../Actions/InitUserHasCurrency";
import {toggleShowingTutorial} from "../Actions/ToggleShowingTutorial";
import {modifyTutorialStep} from "../Actions/ModifyTutorialStep";
import {modifyShowingMinorTutorials} from "../Actions/ModifyShowingMinorTutorials";
import isObjectEmpty from "../Constants/isObjectEmpty";
import {resetAllStates} from "../Actions/ResetAllStates";
import { push } from 'react-router-redux'
import {modifyAppsumoPlanName} from "../Actions/ModifyAppsumoPlanName";
import {modifyConnectedDomains} from "../Actions/ModifyConnectedDomains";
import {initIsGrandfather} from "../Actions/InitIsGrandfather";
import {toggleYearlyPricing} from "../Actions/ToggleYearlyPricing";
import {modifyCurrRequests} from "../Actions/ModifyCurrRequests";
import {modifySubscriptionInfo} from "../Actions/ModifySubscriptionInfo";
import {toggleIsProductImporter} from "../Actions/ToggleIsProductImporter";
import {modifyFullPricingInfo} from "../Actions/ModifyFullPricingInfo";
import API_Backend_Root from "../Constants/API_Backend_Root";

class CustomToastContainer extends Component {
    componentDidMount(){
        if (localStorage.getItem("email") !== null && localStorage.getItem("token") !== null){
            const current = new Date();
            const utcOffset = current.getTimezoneOffset();

            fetch(API_Backend_Root + "api/get-full-pricing-info/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token")
                })
            }).then((res) => {
                if (res.status === 200){
                    res.json().then((data) => {
                        this.props.modifyFullPricingInfo(data.fullPricingInfo)
                    })
                }
            }).catch(err => console.log(err))
            // this.props.modifyFullPricingInfo(data.fullPricingInfo)

            fetch(API_Root + "api/get-profile-information/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    utcOffset: utcOffset,
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token")
                })
            }).then((res) =>
            {
                if (res.status === 200){
                    res.json().then((data) => {
                        this.props.toggleIsProductImporter("isProductImporter" in data ? data.isProductImporter : false)
                        this.props.toggleYearlyPricing("yearlyPricing" in data ? data.yearlyPricing : false)
                        this.props.initIsGrandfather(data.isGrandfather)
                        this.props.modifyConnectedDomains(data.connectedDomains)
                        this.props.modifyAppsumoPlanName(data.appsumoPlanName)
                        this.props.modifyTier(data.pricingPlan)
                        this.props.modifyCurrency(data.currency)
                        this.props.initUserHasCurrency(data.userHasCurrency)
                        this.props.modifySubscriptionInfo(data.subscriptionInfo)
                        this.props.modifyCurrRequests(data.currRequests)
                        this.props.toggleShowingTutorial(data.showTutorial)
                        if (!isObjectEmpty(data.minorTutorials)){
                            this.props.modifyShowingMinorTutorials(data.minorTutorials)
                        }
                        if(data.showTutorial){
                            this.props.modifyTutorialStep("userPreference")
                        }
                    }).catch(err => this.props.modifyTier("team"));
                }
                else{
                    this.props.modifyTier("team")
                }

            }).catch(err => this.props.modifyTier("team"))

        }
        // else {
        //     this.props.navigateTo("/")
        //     auth.doSignOut()
        //     this.props.resetAllStates();
        // }
    }

    render() {
        if (this.props.toast.show){
            const message = this.props.toast.message;
            const toastType = this.props.toast.type;

            if (toastType === "success"){
                toast.success(message)
            }
            else if (toastType === "info"){
                toast.info(message)
            }
            else if (toastType === "warn"){
                toast.warn(message)
            }
            else if (toastType === "error"){
                toast.error(message)
            }

            this.props.toggleToast({show: false, message: "", type: ""})
        }


        return (
            <ToastContainer
                position="top-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover/>
        )
    }
}

const mapStateToProps = (state) => ({
    toast: state.mainState.toast
})

const mapActionsToProps = {
    modifyTier: modifyTier,
    toggleToast: toggleToast,
    modifyCurrency: modifyCurrency,
    initUserHasCurrency: initUserHasCurrency,
    toggleShowingTutorial: toggleShowingTutorial,
    modifyTutorialStep: modifyTutorialStep,
    modifyShowingMinorTutorials: modifyShowingMinorTutorials,
    resetAllStates: resetAllStates,
    navigateTo: push,
    modifyAppsumoPlanName: modifyAppsumoPlanName,
    modifyConnectedDomains: modifyConnectedDomains,
    initIsGrandfather: initIsGrandfather,
    toggleYearlyPricing: toggleYearlyPricing,
    modifyCurrRequests: modifyCurrRequests,
    modifySubscriptionInfo: modifySubscriptionInfo,
    toggleIsProductImporter: toggleIsProductImporter,
    modifyFullPricingInfo: modifyFullPricingInfo
}

export default connect(mapStateToProps, mapActionsToProps)(CustomToastContainer)