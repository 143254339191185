const ImporterDemoVideoLinks = [
    {
        topic: "Full Importer Demo",
        desc: "Videos on how to create and use an importer",
        videos: [{
            title: "Quickstart on using the Importer",
            id: "kqAlzo8liX0",
            time: "1:07"
        }, {
            title: "Full Demo on using the Importer",
            id: "lsqvBGG0fdU",
            time: "6:34"
        }]
    }
]

export default ImporterDemoVideoLinks