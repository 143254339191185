import React, {Component} from 'react'

class UpdatedCTA extends Component {
    constructor(props) {
        super(props);

        this.signUp = this.signUp.bind(this);
    }

    signUp(e){
        this.signupLink.click()
    }

    render() {
        return (
            <div>
                <a
                    style={{display: 'none'}}
                    ref={signupLink => this.signupLink = signupLink}
                    href="/signup"
                >Download hidden</a>
                <button style={'style' in this.props ? this.props.style : null} className="signUpHeroButton" onClick={this.signUp}>Sign Up Free</button>
            </div>
        )
    }
}

export default UpdatedCTA