import React, {Component} from 'react'
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import { Icon } from 'semantic-ui-react'
import KeyBox from "./KeyBox";
import { Button } from 'semantic-ui-react';
import API_Root from "../../Constants/API_Root";
import {toggleToast} from "../../Actions/ToggleToast";
import {connect} from "react-redux";
import {modifyPaymentModalOrigin} from "../../Actions/ModifyPaymentModalOrigin";
import {toggleModal} from "../../Actions/ToggleModal";

class KeysIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addingKeys: false
        }
    }

    addKeys = () => {

        // multipleKeys
        const isTeam = this.props.tier === "business" || this.props.tier === "enterprise" || this.props.tier === "team";

        if (!isTeam){
            this.props.toggleModal(null)
            this.props.modifyPaymentModalOrigin("multipleKeys")
            this.props.toggleModal("paymentModal")
        } else {
            this.setState({
                addingKeys: true
            })

            const error = () => {
                this.setState({ addingKeys: false }, () => {
                    this.props.toggleToast({ show: true, type: "error", message: "There was an error adding new keys. Please try again. Contact support if error persists."})
                })
            }

            fetch(API_Root + "api/crud-api-utilities-info/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    mode: "addKeys",
                    pk: this.props.pk
                })
            }).then(res => {
                if (res.status === 200){
                    res.json().then(data => {
                        this.setState({
                            addingKeys: false
                        }, () => {
                            const currKeys = JSON.parse(JSON.stringify(this.props.keys));
                            currKeys.push(data)

                            this.props.changeState({ keys: currKeys })
                        })
                    }).catch(err => error())
                } else {
                    error()
                }
            }).catch(err => error())
        }
    }

    deleteKeysFromState = (idx) => {
        const currKeys = JSON.parse(JSON.stringify(this.props.keys));
        console.log(currKeys)
        currKeys.splice(idx, 1)

        this.props.changeState({ keys: currKeys })
    }

    render() {
        return (
            <div>
                <div style={{fontSize: "16px", fontFamily: "Lato", fontWeight: "700", color: gunaldiStyles.subText, marginBottom: "12px"}}>
                    <Icon name="key" /> <span style={{ paddingLeft: "8px"}}>API Keys</span>
                </div>
                {this.props.keys.map((x, idx) =>
                <div style={{ marginBottom: "12px"}} key={idx}>
                    <KeyBox
                        keys={x}
                        idx={idx}
                        changeState={this.props.changeState}
                        deleteKeysFromState={this.deleteKeysFromState}
                        canDelete={this.props.keys.length > 1}
                        pk={this.props.pk}
                    />
                </div>)}
                <Button
                    // circular
                    onClick={this.addKeys}
                    icon="plus"
                    content="Add more key pairs"
                    style={{border: "1px solid " + gunaldiStyles.mainGreen, color: gunaldiStyles.mainGreen, backgroundColor: "white"}}
                    loading={this.state.addingKeys}
                    // color="orange"
                    // basic
                    size="mini"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {
    toggleToast: toggleToast,
    toggleModal: toggleModal,
    modifyPaymentModalOrigin: modifyPaymentModalOrigin
}

export default connect(mapStateToProps, mapActionsToProps)(KeysIndex)