import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {toggleModal} from "../../Actions/ToggleModal";
import API_Root from "../../Constants/API_Root";
import {toggleToast} from "../../Actions/ToggleToast";
import {convertDateToUnixTimestamp} from "../../Constants/convertDateToUnixTimestamp";

class CachingLayerCreateBtn extends Component {
    createCachingLayer = () => {
        this.props.toggleModal("loadingModalCachingLayer")

        const isError = (err) => {
            this.props.toggleModal(null)
            this.props.toggleToast({show: true, message: err, type: "error"})
        }

        fetch(API_Root + "api/create-caching-layer-for-api/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                getURL: this.props.getURL,
                expiringUnixTimestamp: convertDateToUnixTimestamp(this.props.expiring),
                pk: this.props.pk
            })
        }).then(res => {
            if (res.status === 201){
                res.json().then(data => {
                    this.props.changeStateWhenCreated(data.cacheId, data.expiring, data.authInfo, data.createdDt)
                    this.props.toggleModal(null)
                }).catch(err => isError(err))
            } else if (res.status === 404){
                isError("There was a problem accessing your spreadsheet data. Try again or contact support if the error persists")
            } else if (res.status === 400){
                isError("There was a problem creating your cache layer. Try again or contact support if the error persists")
            } else {
                isError(res.status)
            }
        }).catch(err => isError(err))
    }

    render() {
        return (
            <Button
                style={{backgroundColor: "#6FBB98", color: "white"}}
                icon="edit outline"
                disabled={this.props.error}
                content="Create Caching Layer & Get Cache Layer API URL"
                onClick={this.createCachingLayer}
            />
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(CachingLayerCreateBtn)