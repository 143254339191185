import React, {Component} from 'react'
import monitoring from "../../Illustrations/monitoring.svg"
import TeamAccess from "../../Illustrations/TeamAccess.svg"

class MonitoringAccessDesc extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Monitor Team Access</div>
                <div style={this.props.subheadingStyle}>You can monitor the API usage of all your team members because of their unique keys</div>
                <br/>
                <div style={{textAlign: "center"}}>
                    <img src={TeamAccess} alt="Team Access" style={{width: "50%"}}/>
                </div>
            </div>

        )
    }
}

export default MonitoringAccessDesc 