import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../SQL/styles";
import ReactJson from "react-json-view";

class QuickstartParameters extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <span className="parameterName">input_cells</span> <span className="parameterType">JSON object</span>
                <span className="parameterSecondLine"><span className="parameterRequired"
                                                            style={{color: styles.orange}}>Required if inserting data</span> </span>
                <span
                    className="parameterDesc">A JSON object of the style below. You specify as many of your sheet (tab) names as keys and the values are JSON objects containing the cell reference and value to be inserted</span>
                <div style={{paddingTop: "8px"}}>
                    <ReactJson src={{
                        "sheet_name_1": {"A3": "value", "B6": "value"},
                        "sheet_name_2": {"C4": "value", "C5": "value"}
                    }} name={null} theme="ocean" displayObjectSize={false}/>
                </div>

                <br/><br/>
                <span className="parameterName">output_cells</span> <span className="parameterType">JSON object</span>
                <span className="parameterSecondLine"><span className="parameterRequired"
                                                            style={{color: styles.orange}}>Required if returning data</span> </span>
                <span className="parameterDesc">A JSON object of the style below. You specify as many of your sheet (tab) names as keys and the values are arrays containing the cell reference whose values should be returned</span>
                <div style={{paddingTop: "8px"}}>
                    <ReactJson src={{
                        "sheet_name_1": ["A4", "B80"],
                        "sheet_name_2": ["A9"]
                    }} name={null} theme="ocean" displayObjectSize={false}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(QuickstartParameters)