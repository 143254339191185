import React, {Component} from 'react'
import FAQHeader from "./FAQHeader"
import FAQBlock from "./FAQBlock";

class MobileFAQDisplay extends Component {
    render() {
        return (
            <div>
                {this.props.helpInfo.map((x, idx) =>
                <div key={idx} style={{marginBottom: "36px"}}>
                    <FAQHeader helpInfoObj={x.info}/>
                    <FAQBlock
                        FAQs={x.faq}
                    />
                </div>)}
            </div>
        )
    }
}

export default MobileFAQDisplay