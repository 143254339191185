import React, {Component} from 'react'
import { connect } from 'react-redux'
import API_Root from "../Constants/API_Root";
import { toggleModal } from "../Actions/ToggleModal";
import constants from "../Constants/constants";
import {toggleProBlock} from "../Actions/ToggleProBlock";
import FileDrop from 'react-file-drop';
import UploadIndicator from "./Indicators/UploadIndicator";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import copyArray from "../Constants/copyArray";
import {modifyStatus} from "../Actions/ModifyStatus";
import ReadingIndicator from './Indicators/ReadingIndicator'
import MergeIndicator from './Indicators/MergeIndicator'
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {modifyFinalColumnNames} from "../Actions/ModifyFinalColumnNames";
import {modifyFinalColumnPK} from "../Actions/ModifyFinalColumnPK";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import bytesToMB from "../Constants/bytesToMB";
import {modifyFilePKProBlock} from "../Actions/ModifyFilePKProBlock";
import {modifyCustomContactFiles} from "../Actions/ModifyCustomContactFiles";
import {modifyUpgradeText} from "../Actions/ModifyUpgradeText";
import { toggleToast } from "../Actions/ToggleToast";
import {initUploadedPK} from "../Actions/InitUploadedPK";
import {readFiles} from "../Constants/readFile";
import { push } from 'react-router-redux'
import {modifyAPIMenuItem} from "../Actions/ModifyAPIMenuItem";
import {hexToRgbA} from "../Constants/hexToRgbA";
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

class UploadFilesIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null
        };

        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.getStatusAndResults = this.getStatusAndResults.bind(this);
    }


    handleFileUpload(droppedFiles, e){
        // If files are being dropped vs. uploaded
        const files = droppedFiles === null ? e.target.files : droppedFiles;

        const formData = new FormData();

        let customContactFiles  = [];

        for (let i=0; i<files.length; i++){
            const fileObj = files[i];
            
            if (bytesToMB(fileObj.size) > 20){
                if (this.props.tier !== "business" && this.props.tier !== "enterprise"){
                    customContactFiles.push(fileObj.name)
                } else {
                    formData.append("file" + i.toString(), fileObj);
                }
            }
            else {
                formData.append("file" + i.toString(), fileObj);
            }
        }

        this.props.modifyCustomContactFiles(customContactFiles);

        formData.append("email", localStorage.getItem("email"));
        formData.append("token", localStorage.getItem("token"));

        this.props.modifyStatus("reading");

        this.props.toggleModal("loadingModalFile")

        fetch(API_Root + 'api/save-api-files/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: formData
        }).then(res => res.json())
            .then(data => {
            if (!data.error){
                // this.getFilePKs(data.taskID)

                // Modify the File Primary Keys
                let filePK = JSON.parse(JSON.stringify(data.filePK));
                let uploadedPK = JSON.parse(JSON.stringify(data.filePK));

                if (this.props.filePK.length > 0){
                    filePK = copyArray(this.props.filePK, data.filePK);
                }

                if (this.props.uploadedPK.length > 0){
                    uploadedPK = data.filePK.concat(this.props.uploadedPK)
                }

                this.props.modifyStatus("saved");
                this.props.initUploadedPK(uploadedPK)
                this.props.modifyFilePK(filePK);

                // Open up WebSocket when files are saved
                this.getStatusAndResults(data.filePK);
            }
            else{
                this.props.modifyStatus("upload");
                this.props.toggleModal(null)
                if ('message' in data){
                    this.props.modifyUpgradeText(data.message)
                    this.props.toggleModal("upgradeModal")
                }
                else{
                    this.props.toggleToast({show: true, message: "There was an error uploading your file! Please try again!", type: "error"})
                }
            }
        }).catch((err) => {
            this.props.toggleModal(null)
            this.props.toggleToast({show: true, message: "There was an error uploading your file! Please try again!", type: "error"})
            this.props.modifyStatus("upload");
        }).catch((err => {
            this.props.toggleModal(null)
            this.props.toggleToast({show: true, message: "There was an error uploading your file! Please try again!", type: "error"})
            this.props.modifyStatus("upload");
        }));
    }

    getStatusAndResults(filePK){
        // Offset to get created dt
        const current = new Date();
        const utcOffset = current.getTimezoneOffset();

        // Send the PKs and if any column names
        const socketRequest = {
            utcOffset: utcOffset,
            filePK: filePK,
            sheetRead: this.props.sheetRead,
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token")
        }

        readFiles(socketRequest, filePK, this.props, "read-api-files", true, true)
    }

    render() {
        // Hides the Input Button
        const inputFileStyle = {
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1'
        };

        // Valid extensions for the file upload
        const acceptExtensions = constants.extensions.join(",");

        // Components for what status the job is in
        const indicators = {
            upload: <UploadIndicator status="upload"/>,
            reading: <ReadingIndicator percentage={0} />,
            saved: <ReadingIndicator percentage={45} />,
            read: <MergeIndicator percentage={90}/>,
            api: <UploadIndicator status="merged"/>
        };

        return (
            <div>
                <input
                    type="file"
                    name="inputFileUpload"
                    id="inputFileUpload"
                    className="form-control-file"
                    onChange={(e) => this.handleFileUpload(null, e)}
                    style={inputFileStyle}
                    multiple={true}
                    accept={acceptExtensions}
                />
                <div className="uploadOuterSection">
                    <label htmlFor="inputFileUpload" style={{width: "100%"}}>
                        <FileDrop draggingOverTargetClassName="lightgray" onDrop={this.handleFileUpload}>
                            <div style={{paddingTop: "25px", textAlign: "center"}}>
                                <i className="bx bxs-folder-open" style={{color: "#D1E4CE", fontSize: "80px"}}/>
                                <div style={{fontFamily: "Lato", fontWeight: "400", color: "#9696A0", marginTop: "8px", marginBottom: "16px", fontSize: "14px"}}>
                                    Drag & Drop Files here or
                                </div>
                                <label htmlFor="inputFileUpload">
                                    <div className="uploadLabel" style={{color: "#FFFFFF", backgroundColor: gunaldiStyles.mainGreen}}>
                                        Browse Files
                                    </div>
                                </label>
                                <div style={{color: "#9696A0", textAlign: "center"}}>
                                    <small>{acceptExtensions}</small>
                                </div>
                            </div>
                        </FileDrop>
                    </label>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    finalColumnNames: state.mainState.finalColumnNames,
    fileInformation: state.mainState.fileInformation,
    finalColumnPK: state.mainState.finalColumnPK,
    status: state.mainState.status,
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock,
    uploadedPK: state.mainState.uploadedPK,
    showingTutorial: state.mainState.showingTutorial,
    tutorialStep: state.mainState.tutorialStep,
    tier: state.mainState.tier
});

const mapActionsToProps = {
    toggleModal: toggleModal,
    toggleProBlock: toggleProBlock,
    modifyFilePK: modifyFilePK,
    modifyStatus: modifyStatus,
    modifyFileInformation: modifyFileInformation,
    modifyFinalColumnNames: modifyFinalColumnNames,
    modifyFinalColumnPK: modifyFinalColumnPK,
    modifySheetRead: modifySheetRead,
    modifyFilePKProBlock: modifyFilePKProBlock,
    modifyCustomContactFiles: modifyCustomContactFiles,
    modifyUpgradeText: modifyUpgradeText,
    toggleToast: toggleToast,
    initUploadedPK: initUploadedPK,
    navigateTo: push,
    modifyAPIMenuItem: modifyAPIMenuItem
};

export default connect(mapStateToProps, mapActionsToProps)(UploadFilesIndex)