import React, {Component} from 'react';
import withAuthorization from '../../Authentication/withAuthorization';
import DatabaseEditAPIIndex from "./DatabaseEditAPIIndex"

class AuthDatabaseEditAPIIndex extends Component {
    render() {
        return(
            <DatabaseEditAPIIndex/>
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AuthDatabaseEditAPIIndex);
