import React, {Component} from 'react'
import {connect} from 'react-redux'
import DocsDisplay from "./DocsDisplay"
import DocsMenu from "./DocsMenu";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import {getQueryParamValue} from "../../Constants/getQueryParamValue";
import DocsUploadIntroduction from "./Upload/DocsUploadIntroduction";
import APISecurity from "../../Guides/GuidesText/APISecurity";
import APIQuickstart from "../../Guides/GuidesText/APIQuickstart";
import ReadRows from "../../Guides/GuidesText/Read/ReadRows";
import CreateRows from "../../Guides/GuidesText/CreateRows";
import UpdateRows from "../../Guides/GuidesText/UpdateRows";
import DeleteRows from "../../Guides/GuidesText/DeleteRows";
import QueryBody from "../../InfoPages/Query/FullTutorial/QueryBody";
import UploadLocalFiles from "./Upload/UploadLocalFiles"
import UploadGoogleSheets from "./Upload/UploadGoogleSheets"
import UploadDropbox from "./Upload/UploadDropbox"
import UploadAWSS3 from "./Upload/UploadAWSS3"
import UploadQuickstart from "./Upload/UploadQuickstart"
import ColumnNamesValid from "../../Guides/ColumnNamesValid";
import UploadReadSettings from "./Upload/UploadReadSettings"
import APIIntroduction from "./API/APIIntroduction";
import CreateIndex from "../../InfoPages/DocsPage/20200311/Create/CreateIndex"
import ReadIndex from "../../InfoPages/DocsPage/20200311/Get/ReadIndex";
import UpdateIndex from "../../InfoPages/DocsPage/20200311/Update/UpdateIndex";
import DeleteIndex from "../../InfoPages/DocsPage/20200311/Delete/DeleteIndex";
import ActionsIntroduction from "./Actions/ActionsIntroduction";
import ActionsAPIPrivate from "./Actions/ActionsAPIPrivate";
import ActionsFileValues from "./Actions/ActionsFileValues"
import ActionsChangeSheet from "./Actions/ActionsChangeSheet";
import ActionsFileOptions from "./Actions/ActionsFileOptions";
import ActionsColumnNames from "./Actions/ActionsColumnNames";
import Webhooks from "../../Guides/GuidesText/Webhooks/Webhooks";
import TeamsGuide from "../../InfoPages/Tutorials/TutorialsText/TeamsGuide";
import ActionsTeamInfo from "./Actions/ActionsTeamInfo";
import CachingLayerGuide from "../../Guides/GuidesText/CachingLayerGuide";
import ActionsAPIAlerts from "./Actions/ActionsAPIAlerts";
import ActionsBlockRequests from "./Actions/ActionsBlockRequests";
import ActionsWhitelistURLs from "./Actions/ActionsWhitelistURLs";
import ActionsAutoReporting from "./Actions/ActionsAutoReporting";
import DatabaseIntroduction from "./Database/DatabaseIntroduction";
import DatabaseConnectionErrorBody from "../../Database/DatabaseConnectionErrorBody";
import DatabaseAPIGuide from "../../Guides/GuidesText/DatabaseAPIGuide";
import DatabaseUseAPI from "./Database/DatabaseUseAPI";
import ConnectDomain from "../../Guides/ConnectDomain";
import GoogleDrive from "./Integrations/GoogleDrive"
import Dropbox from "./Integrations/Dropbox"
import AWSS3 from "./Integrations/AWSS3"
import SpreadsheetImport from "../../Guides/GuidesText/SpreadsheetImport";
import Columns from "./Importer/CustomizeImporter/Columns";
import Destinations from "./Importer/CustomizeImporter/Destinations";
import Appearance from "./Importer/CustomizeImporter/Appearance";
import Actions from "./Importer/CustomizeImporter/Actions";
import ImporterCode from "./Importer/ImporterCode";
import UsingImporterIntroduction from "./Importer/UsingImporterIntroduction";
import ImporterURL from "./Importer/ImporterURL";
import DatabaseSelectTables from "./Importer/DatabaseSelectTables"
import { Popup } from 'semantic-ui-react'
import CopyBtn from "../../SharedComponents/CopyBtn"

class DocsIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuItem: this.getInfo().docsMenuItems[0],
            subMenuItem: null
        }
    }

    componentDidMount(){
        let docsMenuItem = getQueryParamValue("docsMenuItem", window.location.href)

        if (docsMenuItem !== ""){
            try{
                if (docsMenuItem.includes("#")){
                    docsMenuItem = docsMenuItem.substring(0, docsMenuItem.indexOf("#"))
                }

                this.setBothMenuItems(docsMenuItem)
            } catch (e){}
        }
    }

    getInfo = () => {
        if (this.props.page === "api"){ return this.getApiDocsInfo() }
        else { return this.getImporterDocsInfo() }
    }

    setBothMenuItems = (menuItemStr) =>  {
        const docsInfo = this.getInfo()

        const menuSplit = menuItemStr.split("-")
        let menuItem = menuSplit[0]

        if (!docsInfo.docsMenuItems.includes(menuItem)){
            menuItem = docsInfo.docsMenuItems[0]
        }

        let subMenuItem = null;
        if (menuSplit.length === 2){
            subMenuItem = menuSplit[1]

            try{
                const obj = docsInfo['itemsInfo'][menuItem]['subMenuItemInfo'][subMenuItem].body
            } catch (e){
                subMenuItem = null
            }
        }

        this.setState({ menuItem: menuItem, subMenuItem: subMenuItem }, () => this.scrollToTop())
    }

    modifyMenuItem = (menuItem) => {
        this.setState({ menuItem: menuItem })
        this.scrollToTop()
    }

    modifySubMenuItem = (subMenuItem) => {
        this.setState({ subMenuItem: subMenuItem })
        this.scrollToTop()
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    getDisplay = () => {
        let obj;
        try{
            if (this.state.subMenuItem === null ){
                obj = this.getInfo()['itemsInfo'][this.state.menuItem].body
            } else {
                obj = this.getInfo()['itemsInfo'][this.state.menuItem]['subMenuItemInfo'][this.state.subMenuItem].body
            }
        } catch (e){
            obj = null
        }


        return obj
    }

    getDocsLink = () => {
        const baseURL = window.location.origin
        let pageQuery = this.props.page === "importer" ? "import" : "api"
        return <Popup
            content="Copy Link"
            on="hover"
            trigger={<div style={{float: 'right'}}>
                <CopyBtn message="Doc URL Copied"
                              text={baseURL + "/" + pageQuery + "-docs/?docsMenuItem=" + this.state.menuItem + (this.state.subMenuItem === null ? "" : "-" + this.state.subMenuItem)}/>
            </div>}
        />
    }

    getHeader = () => {
        let header;

        // menuItem={this.state.menuItem}
        // subMenuItem={this.state.subMenuItem}
        // page=this.props.page

        try{
            const menuLabel = this.getInfo()['itemsInfo'][this.state.menuItem].label;

            if (this.state.subMenuItem === null ){
                header = <h2 style={{fontWeight: "700"}}>{menuLabel} {this.getDocsLink()}</h2>
            } else {
                const subHeading = this.getInfo()['itemsInfo'][this.state.menuItem]['subMenuItemInfo'][this.state.subMenuItem].label;

                header = <div>
                    <h2 style={{fontWeight: "700"}}>{subHeading} {this.getDocsLink()}</h2>
                    <p style={{color: gunaldiStyles.subText, fontWeight: "700"}}>{menuLabel}</p>
                </div>
            }
        } catch (e){
            header = null;
        }

        return header
    }

    render() {
        return (
            <div style={{display: "grid", gridTemplateColumns: "240px 1fr"}}>
                <DocsMenu info={this.getInfo()}
                          page={this.props.page}
                          subMenuItem={this.state.subMenuItem}
                          menuItem={this.state.menuItem}
                          modifyMenuItem={this.modifyMenuItem}
                          modifySubMenuItem={this.modifySubMenuItem}
                />
                <DocsDisplay
                    page={this.props.page}
                    header={this.getHeader()}
                    display={this.getDisplay()}
                />
            </div>
        )
    }

    getImporterDocsInfo = () => ({
        docsMenuItems: ["quickstart", "creatingImporter", "usingImporter", "database", "integrations"],
        itemsInfo: {
            "quickstart": {
                label: "Getting Started",
                body: <SpreadsheetImport setBothMenuItems={this.setBothMenuItems}/>,
                subMenuItems: []
            },
            "creatingImporter": {
                label: "Customizing Importer",
                body: <Columns/>,
                subMenuItems: ["columns", "destinations", "appearance", "actions"],
                subMenuItemInfo: {
                    "columns": {
                        label: "Columns",
                        body: <Columns/>
                    },
                    "destinations": {
                        label: "Destinations",
                        body: <Destinations setBothMenuItems={this.setBothMenuItems}/>
                    },
                    "appearance": {
                        label: "Appearance",
                        body: <Appearance setBothMenuItems={this.setBothMenuItems}/>
                    },
                    "actions": {
                        label: "Actions",
                        body: <Actions setBothMenuItems={this.setBothMenuItems}/>
                    }
                }
            },
            "usingImporter": {
                label: "Using the Importer",
                body: <UsingImporterIntroduction setBothMenuItems={this.setBothMenuItems}/>,
                subMenuItems: ["introduction", "code", "noCode"],
                subMenuItemInfo: {
                    "introduction": {
                        label: "Introduction",
                        body: <UsingImporterIntroduction setBothMenuItems={this.setBothMenuItems}/>
                    },
                    "code": {
                        label: "Insert Importer Code",
                        body: <ImporterCode setBothMenuItems={this.setBothMenuItems}/>
                    },
                    "noCode": {
                        label: "Share Importer URL",
                        body: <ImporterURL setBothMenuItems={this.setBothMenuItems}/>
                    }
                }
            },
            "database": {
                label: "Database",
                body: <DatabaseIntroduction setBothMenuItems={this.setBothMenuItems} importer/>,
                subMenuItems: ["introduction", "connect", "selectTable"],
                subMenuItemInfo: {
                    "introduction": {
                        label: "Introduction",
                        body: <DatabaseIntroduction setBothMenuItems={this.setBothMenuItems} importer/>
                    },
                    "connect": {
                        label: "Connect your Database",
                        body: <DatabaseConnectionErrorBody setBothMenuItems={this.setBothMenuItems} importer/>
                    },
                    "selectTable": {
                        label: "Select Table",
                        body: <DatabaseSelectTables setBothMenuItems={this.setBothMenuItems} importer />
                    },
                }
            },
            "integrations": {
                label: "Integrations",
                body: <GoogleDrive setBothMenuItems={this.setBothMenuItems} importer/>,
                subMenuItems: ["googleDrive", "dropbox", "awsS3", "connectDomain"],
                subMenuItemInfo: {
                    "googleDrive": {
                        label: "Google Drive",
                        body: <GoogleDrive setBothMenuItems={this.setBothMenuItems} importer/>
                    },
                    "dropbox": {
                        label: "Dropbox",
                        body: <Dropbox setBothMenuItems={this.setBothMenuItems} importer/>
                    },
                    "awsS3": {
                        label: "AWS S3",
                        body: <AWSS3 setBothMenuItems={this.setBothMenuItems} importer/>
                    },
                    "connectDomain": {
                        label: "Connect Your Domain",
                        body: <ConnectDomain/>
                    }
                }
            }
        }
    })

    getApiDocsInfo = () => ({
            docsMenuItems: ["quickstart", "upload", "api", "actions", "database", "integrations", "teams"],
            itemsInfo: {
                "quickstart": {
                    label: "Getting started",
                    body: <APIQuickstart/>,
                    subMenuItems: [],
                },
                "teams": {
                    label: "Teams",
                    body: <TeamsGuide setBothMenuItems={this.setBothMenuItems}/>,
                    subMenuItems: [],
                },
                "upload": {
                    label: "Uploading a file",
                    body: <DocsUploadIntroduction setBothMenuItems={this.setBothMenuItems}/>,
                    subMenuItems: ["introduction", "localFiles", "googleSheets", "dropbox", "awsS3", "quickstart", "columnNames", "readSettings"],
                    subMenuItemInfo: {
                        "introduction": {
                            label: "Introduction",
                            body: <DocsUploadIntroduction setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "localFiles": {
                            label: "Local Files",
                            body: <UploadLocalFiles setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "googleSheets": {
                            label: "Google Sheets",
                            body: <UploadGoogleSheets setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "dropbox": {
                            label: "Dropbox",
                            body: <UploadDropbox setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "awsS3": {
                            label: "AWS S3",
                            body: <UploadAWSS3 setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "quickstart": {
                            label: "Quickstart",
                            body: <UploadQuickstart setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "columnNames": {
                            label: "Valid Column Names",
                            body: <ColumnNamesValid setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "readSettings": {
                            label: "Settings to Read File",
                            body: <UploadReadSettings setBothMenuItems={this.setBothMenuItems}/>
                        }
                    }
                },
                "api": {
                    label: "Using the API",
                    body: <APIIntroduction />,
                    subMenuItems: ["introduction", "create", "read", "update", "delete", "query", "security"],
                    subMenuItemInfo: {
                        "introduction": {
                            label: "Introduction",
                            body: <APIIntroduction setBothMenuItems={this.setBothMenuItems} />
                        },
                        "create": {
                            label: "Create Rows",
                            body: <CreateIndex setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "read": {
                            label: "Read Rows",
                            body: <ReadIndex setBothMenuItems={this.setBothMenuItems} />
                        },
                        "update": {
                            label: "Update Rows",
                            body: <UpdateIndex setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "delete": {
                            label: "Delete Rows",
                            body: <DeleteIndex setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "query": {
                            label: "SQL-like Queries",
                            body: <QueryBody/>
                        },
                        "security": {
                            label: "Security",
                            body: <APISecurity/>
                        }
                    }
                },
                "actions": {
                    label: "Actions",
                    body: <ActionsIntroduction />,
                    subMenuItems: ["introduction", "apiPrivate", "fileValues", "changeSheet", "fileOptions", "columnNames", "webhook", "teamInfo", "autoReporting", "apiAlerts", "blockRequests", "whitelistURLs",
                        // "cachingLayer"
                    ],
                    subMenuItemInfo: {
                        "introduction": {
                            label: "Introduction",
                            body: <ActionsIntroduction />
                        },
                        "apiPrivate": {
                            label: "API Private",
                            body: <ActionsAPIPrivate setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "fileValues": {
                            label: "File Data",
                            body: <ActionsFileValues />
                        },
                        "changeSheet": {
                            label: "Change Sheet",
                            body: <ActionsChangeSheet setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "fileOptions": {
                            label: "File Options",
                            body: <ActionsFileOptions setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "columnNames": {
                            label: "Column Names",
                            body: <ActionsColumnNames setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "webhook": {
                            label: "Webhook",
                            body: <Webhooks setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "teamInfo": {
                            label: "Team Info",
                            body: <ActionsTeamInfo setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "autoReporting": {
                            label: "Auto Reporting",
                            body: <ActionsAutoReporting/>
                        },
                        "apiAlerts": {
                            label: "API Alerts",
                            body: <ActionsAPIAlerts/>
                        },
                        "blockRequests": {
                            label: "Block Requests",
                            body: <ActionsBlockRequests/>
                        },
                        "whitelistURLs": {
                            label: "Whitelist URLs",
                            body: <ActionsWhitelistURLs/>
                        }
                        // "cachingLayer": {
                        //     label: "Caching Layer",
                        //     body: <CachingLayerGuide setBothMenuItems={this.setBothMenuItems}/>
                        // }
                    }
                },
                "database": {
                    label: "Database",
                    body: <p>intro</p>,
                    subMenuItems: ["introduction", "connect", "createAPI", "useAPI"],
                    subMenuItemInfo: {
                        "introduction": {
                            label: "Introduction",
                            body: <DatabaseIntroduction setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "connect": {
                            label: "Connect Your Database",
                            body: <DatabaseConnectionErrorBody setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "createAPI": {
                            label: "Create Database API",
                            body: <DatabaseAPIGuide setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "useAPI": {
                            label: "Use Database API",
                            body: <DatabaseUseAPI setBothMenuItems={this.setBothMenuItems}/>
                        },
                    }
                },
                "integrations": {
                    label: "Integrations",
                    body: <p>intro</p>,
                    subMenuItems: ["googleDrive", "dropbox", "awsS3", "connectDomain"],
                    subMenuItemInfo: {
                        "googleDrive": {
                            label: "Google Drive",
                            body: <GoogleDrive setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "dropbox": {
                            label: "Dropbox",
                            body: <Dropbox setBothMenuItems={this.setBothMenuItems}/>
                        },
                        "awsS3": {
                            label: "AWS S3",
                            body: <AWSS3 setBothMenuItems={this.setBothMenuItems} />
                        },
                        "connectDomain": {
                            label: "Connect Your Domain",
                            body: <ConnectDomain/>
                        }
                    }
                }
            }
        }
    )
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DocsIndex)