import React, {Component} from 'react'
import {gunaldiStyles} from "./GunaldiDesignStyles";
import pricing from "../Constants/Pricing/Pricing";
import {connect} from 'react-redux'
import {planBgColor} from "../Constants/planBgColor";
import {modifyPaymentModalOrigin} from "../Actions/ModifyPaymentModalOrigin";
import {toggleModal} from "../Actions/ToggleModal";

class TierLabel extends Component {
    shouldDisplayTier = () => {
        let allTiers = [];
        try{
            allTiers = pricing.map(x => x.name)
        } catch (e) {
            allTiers = []
        }

        let display = true;
        if (this.props.reduxTier === "enterprise"){
            display = false
        } else {
            if (allTiers.includes(this.props.reduxTier) && allTiers.includes(this.props.tier)){
                const userTierIdx = allTiers.indexOf(this.props.reduxTier)
                const labelTierIdx = allTiers.indexOf(this.props.tier)

                display = userTierIdx < labelTierIdx
            }
        }

        return display
    }

    openUpgradeModal = () => {
        this.props.modifyPaymentModalOrigin(this.props.feature)
        this.props.toggleModal("paymentModal")
    }

    render() {
        if (this.shouldDisplayTier()){
            let bgColor = "green";
            let color = "white";

            const labelBg = {
                pro: "green",
                team: planBgColor[2],
                business: planBgColor[3],
                "new": gunaldiStyles.red
            }

            const labelColor = {
                pro: "white",
                team: "white",
                business: "white",
                "new": "white"
            }

            if (this.props.tier in labelBg){
                bgColor = labelBg[this.props.tier]
                color = labelColor[this.props.tier]
            }

            let labelStyle = {backgroundColor: bgColor, padding: "2px 6px 2px 6px", borderRadius: "2px", fontSize: "12px", color: color, fontWeight: "700", fontFamily: "Lato"};

            if ('importer' in this.props){
                labelStyle['cursor'] = 'pointer'
            }

            return (
                <span style={labelStyle} onClick={"feature" in this.props ? this.openUpgradeModal : null}>
                    {this.props.tier.toUpperCase()}
                </span>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({
    reduxTier: state.mainState.tier
})

const mapActionsToProps = {
    modifyPaymentModalOrigin: modifyPaymentModalOrigin,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(TierLabel)