import React, {Component} from 'react'
import {gunaldiStyles} from "../../../SharedComponents/GunaldiDesignStyles";

class CNAMEDomainError extends Component {
    render() {
        if (this.props.error){
            return <div style={{color: gunaldiStyles.red}}>{this.props.errorMsg} {'selectSSL' in this.props ? " to select certificate" : null}</div>
        } else {
            if ('errorOnly' in this.props){
                return null
            } else {
                if (this.props.errorMsg.trim() !== ""){
                    return <div style={{color: gunaldiStyles.mainGreen}}><b>{this.props.errorMsg}</b></div>
                } else {
                    return null
                }
            }
        }
    }
}

export default CNAMEDomainError