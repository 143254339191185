import {isNameDuplicate} from "./isNameDuplicate";

export const getNameErrorMsg = (name, idx, columnList, internal=false) => {
    let errorMsg = "";

    if (name.trim() === ""){
        errorMsg += "Please enter a name. "
    }

    if (isNameDuplicate(name, idx, columnList)){
        if (internal){
            errorMsg += "Internal "
        }
        errorMsg += "Column names can't be duplicate."
    }

    return errorMsg
}