import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Table } from 'semantic-ui-react'
import ReuploadLink from "./ReuploadLink"

class UploadHistoryTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Table.Row>
                <Table.Cell>{this.props.info.name}</Table.Cell>
                <Table.Cell>{this.props.info.created_dt}</Table.Cell>
                <Table.Cell><ReuploadLink pk={this.props.pk}
                                          replaceFilePK={this.props.info.pk}
                                          pkID={this.props.pkID} /></Table.Cell>
            </Table.Row>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UploadHistoryTable)