import React, {Component} from 'react'
import DocsBaseURL from "./DocsBaseURL";
import DocsPostRequest from "./DocsPostRequest";

class DocsPostIndex extends Component {
    render() {
        return (
            <div>
                <p>To get add data to your file make a POST request to the following URL</p>
                <DocsBaseURL url="data/[fileID]/"/>
                <i>Example URL</i>
                <DocsBaseURL url="data/1/" />
                <br/>
                <DocsPostRequest type="url" />
                <br/>
                <DocsPostRequest type="body" />
                <br/>
                {/*<DocsPostRequest type="headers" />*/}
            </div>
        )
    }
}

export default DocsPostIndex