import React, {Component} from 'react'
import { Table } from 'semantic-ui-react'
import DataModelRow from "./DataModelRow";
import { isMobile } from 'react-device-detect'

class DataModel extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Create a Data Model</div>
                <div style={this.props.subheadingStyle}>A data model standardizes the column names and values of the imported files</div>
                {
                    isMobile ? <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/DataModelImage.png"
                                    style={{width: "100%"}} alt="Data Model"/> :
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={8}>Column Name</Table.HeaderCell>
                                    <Table.HeaderCell width={5}>Data Type</Table.HeaderCell>
                                    <Table.HeaderCell width={3}>Required?</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <DataModelRow columnName="Sign Up Date" dataType="datetime" required={true}/>
                                <DataModelRow columnName="Full Name" dataType="text" required={false}/>
                                <DataModelRow columnName="Is Customer?" dataType="boolean" required={true}/>
                                <DataModelRow columnName="Items Ordered" dataType="numerical" required={true}/>
                            </Table.Body>
                        </Table>
                }
            </div>
        )
    }
}

export default DataModel