import React, {Component} from 'react'
import { Radio } from 'semantic-ui-react'

class DomainValidationOption extends Component {
    handleChange = (e, { value }) => this.props.changeState({ domainValidationOption: value })


    render() {
        return (
            <div style={{display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "16px"}}>
                <div>
                    <Radio
                        label="Email"
                        name="validationOptions"
                        value="email"
                        checked={this.props.option === "email"}
                        onChange={this.handleChange}
                    />
                </div>
                <div>
                    <Radio
                        label="DNS"
                        name="validationOptions"
                        value="dns"
                        checked={this.props.option === "dns"}
                        onChange={this.handleChange}
                    />
                </div>
            </div>
        )
    }
}

export default DomainValidationOption