export const isNameDuplicate = (name, idx, nameList) => {
    nameList = JSON.parse(JSON.stringify(nameList))
    let isDuplicate = false;

    if (nameList.length > 1){
        nameList[idx] = name

        let count = 0;

        for (let i=0; i<nameList.length; i++){
            if (nameList[i].trim() === name.trim()){
                count += 1

                if (count >= 2){
                    isDuplicate = true;
                    break;
                }
            }
        }
    }

    return isDuplicate
}