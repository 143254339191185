import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import {toggleModal} from "../Actions/ToggleModal";
import TeamSegmentRow from "./TeamSegmentRow";
import NoTeamsFound from "./NoTeamsFound";

class TeamSegments extends Component {
    openTeamModal = (idx) => {
        this.props.modifyModalInfo({
            teamIdx: idx
        })
        this.props.toggleModal("teamInfoModal")
    }


    render() {
        if (this.props.teamInfo === null){
            return null
        } else{
            if (this.props.teamInfo.length === 0){
                return (
                    <div style={{paddingTop: "16px"}}><NoTeamsFound/></div>
                )
            } else {
                const headingStyle = {fontFamily: "Lato", fontWeight: "700", fontSize: "14px", color: "#161E16"}

                return (
                    <div>
                        <div className="teamPageSegmentRow" style={{paddingBottom: "0"}}>
                            <div>
                                <div style={{borderRadius: "40px", padding: "10px", background: "#F0F0F0", width: "40px", height: "40px", textAlign: "center", visibility: "hidden"}}>
                                    <i className="bx bx-group" style={{color: "#5499F8", fontSize: "130%"}}/>
                                </div>
                            </div>
                            <div style={headingStyle}>Team Name</div>
                            <div style={headingStyle}>Created</div>
                            <div style={headingStyle}>Admin</div>
                            <div style={headingStyle}>Role</div>
                            <div style={headingStyle}>Members</div>
                            <div> </div>
                            <div> </div>
                        </div>
                        {
                            this.props.teamInfo.map((x, idx) =>
                            <div>
                                <TeamSegmentRow fileHash={null} info={x} idx={idx} teamPage={true} />
                            </div>)
                        }
                    </div>
                )
            }
        }

    }
}

const mapStateToProps = (state) => ({
    teamInfo: state.mainState.teamInfo
})

const mapActionsToProps = {
    modifyModalInfo: modifyModalInfo,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(TeamSegments)