import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

class GunaldiCTA extends Component {
    constructor(props) {
        super(props);

        this.signUp = this.signUp.bind(this);
    }

    signUp(e){
        this.signupLink.click()
    }

    render() {
        const isImporter = 'importer' in this.props;
        const includeContactUs = 'contact' in this.props;

        const cta = <span>
            <a
                style={{display: 'none'}}
                ref={signupLink => this.signupLink = signupLink}
                href="/signup"
            >Download hidden</a>
            <button style={{
                padding: "16px 24px",
                background: isImporter ? gunaldiStyles.orange : "#5499F8",
                borderRadius: "4px",
                boxShadow: "none",
                border: "0"
            }} className="btnHover" onClick={this.signUp}>
                <span
                    style={{fontFamily: "Lato", fontWeight: "700", fontSize: "20px", color: "white"}}>Sign Up Free <Icon
                    name="angle right"/></span>
            </button>
        </span>

        const contactUsBtn = <div>
            <a href="https://calendly.com/api-spreadsheets/25min" target="_blank" rel="noopener noreferrer">
                <button style={{
                    padding: "16px 24px",
                    background: "white",
                    borderRadius: "4px",
                    boxShadow: "none",
                    border: "1px solid #5499F8"
                }} className="btnHover">
                <span
                    style={{fontFamily: "Lato", fontWeight: "700", fontSize: "20px", color: "#5499F8"}}>See Demo <Icon
                    name="angle right"/></span>
                </button>
            </a>
        </div>

        if (includeContactUs) {
            return (
                <div style={{display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "16px"}}>
                    <div>
                        {cta}
                    </div>
                    <div>
                        {contactUsBtn}
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    {cta}
                </div>
            )
        }
    }
}

export default GunaldiCTA