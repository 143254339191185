import React, {Component} from 'react'
import AceEditor from 'react-ace';
import 'brace/mode/python';
import CommentSection from "../CommentSection";

class IntegrateFiles extends Component {
    render() {
        const fullCode = "import requests\n" +
            "import pandas as pd\n" +
            "\n" +
            "google_sheet_request = requests.get(\"https://api.apispreadsheets.com/data/581/\")\n" +
            "dropbox_request = requests.get(\"https://api.apispreadsheets.com/data/582/\")\n" +
            "local_request = requests.get(\"https://api.apispreadsheets.com/data/583/\")\n" +
            "\n" +
            "if google_sheet_request.status_code == 200:\n" +
            "\tgoogle_sheet_data = pd.DataFrame(google_sheet_request.json()[\"data\"])\n" +
            "else:\n" +
            "\tgoogle_sheet_data = None\n" +
            "\t\n" +
            "if dropbox_request.status_code == 200:\n" +
            "\tdropbox_data = pd.DataFrame(dropbox_request.json()[\"data\"])\n" +
            "else:\n" +
            "\tdropbox_data = None\n" +
            "\n" +
            "if local_request.status_code == 200:\n" +
            "\tlocal_data = pd.DataFrame(local_request.json()[\"data\"])\n" +
            "else:\n" +
            "\tlocal_data = None\n" +
            "\t\n" +
            "### DO SOMETHING WITH ALL YOUR 3 DATA FRAMES\n" +
            "print(google_sheet_data)\n" +
            "print(dropbox_data)\n" +
            "print(local_data)";

        const startCode = "import requests\n\n" +
            "r = requests.get(\"https://api.apispreadsheets.com/data/581/\")\n\n" +
            "if r.status_code == 200:\n" +
            "\t# SUCCESS \n" +
            " \tdata = r.json()\n" +
            "else:\n" +
            "\t# ERROR\n" +
            "\tdata=None"

        const cleanedCode = "import requests\n" +
            "import pandas as pd\n" +
            "r = requests.get(\"https://api.apispreadsheets.com/data/581/\")\n" +
            "if r.status_code == 200:\n" +
            "\tdata = pd.DataFrame(r.json()[\"data\"])\n" +
            "else:\n" +
            "\tdata = None\n"

        const middleCode = "import requests\n" +
            "import pandas as pd\n\n" +
            "google_sheet_request = requests.get(\"https://api.apispreadsheets.com/data/581/\")\n" +
            "dropbox_request = requests.get(\"https://api.apispreadsheets.com/data/582/\")\n\n" +
            "if google_sheet_request.status_code == 200:\n" +
            "\tgoogle_sheet_data = pd.DataFrame(google_sheet_request.json()[\"data\"])\n" +
            "else:\n" +
            "\tgoogle_sheet_data = None\n\n" +
            "if dropbox_request.status_code == 200:\n" +
            "\tdropbox_data = pd.DataFrame(dropbox_request.json()[\"data\"])\n" +
            "else:\n" +
            "\tdropbox_data = None"

        return (
            <div>
                <p>
                    I am going to show you how to easily bring data together from all these sources using API Spreadsheets and Python. And keep it updated and in sync. So you can keep your cool and be happy!
                </p>
                <p>
                    We will use 1 Google Sheet, 1 Local File and 1 Dropbox File. Each file will contain Covid data from 1 unique US State: TX, NJ or CA. The data will be broken up on each row by county and look like the following:
                </p>
                <figure>
                    <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/260/0*f_1DJZOEEKWGoZtS.png"  alt="Google_Sheets" />
                    <figcaption style={{textAlign: 'center'}}><sub>Sample Data From the NJ File. The Headers will be the same in each file</sub>
                    </figcaption>
                </figure>
                <br/>
                <p>
                    Let’s get started!
                </p>
                <br/>
                <hr/>
                <br/>
                <h1>Step 0: Create an Empty Python File</h1>
                <p>
                    Create an empty Python file named <b>spreadsheet_integration.py</b> and save it somewhere.
                </p>
                <p>
                    The final code for the file will be as follows. We will walk through how to get there in this post.
                </p>
                <AceEditor
                    mode="python"
                    theme="monokai"
                    value={fullCode}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{$blockScrolling: true}}
                    height={500}
                    width="auto"
                />
                <br/><br/>
                <h1>Step 1: Create an API Spreadsheets Free Account</h1>
                <p>
                    Create a free account by clicking on <a href={"/signup"} target={"_blank"} rel={"noopener noreferrer"}> Sign Up</a>
                </p>
                <br/>
                <h1>Step 2: Connect to Your Google Sheet</h1>
                <p>
                    Click on the Google Sheet Upload Box
                </p>
                <figure>
                    <img src="https://miro.medium.com/max/1400/1*mgsOGPyFzkN-umHpPkr3NA.png" style={{width: '100%', margin: '0 auto', display: 'block'}} alt="API Spreadsheets" />
                    <figcaption style={{textAlign: 'center'}}><sub>Click on the Google Sheets Upload box to start connecting</sub>
                    </figcaption>
                </figure>
                <br/><br/>
                <p>
                    Select the Google Sheet you want to connect to
                </p>
                <br/><br/>
                <figure>
                    <img style={{margin: '0 auto', display: 'block'}} src="https://miro.medium.com/max/250/1*wqzpDTHCh3Ds59aq5WOAzg.png"  alt="Google_Drive" />
                    <figcaption style={{textAlign: 'center'}}><sub>Select the file you want to connect to</sub>
                    </figcaption>
                </figure>
                <br/><br/>
                <p>
                    Copy the Python Code from the Read Tab and paste it in your Python file
                </p>
                <figure>
                    <img src="https://miro.medium.com/max/1400/1*9vcTBQ6Nh4lvnWbhiWfUMw.png" style={{width: '100%', margin: '0 auto', display: 'block'}} alt="API Spreadsheet" />
                </figure>
                    <br/><br/>
                <p>
                    Your Python File will now have the following code
                </p>
                <AceEditor
                    mode="python"
                    theme="monokai"
                    value={startCode}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{$blockScrolling: true}}
                    height={200}
                    width="auto"
                />
                <br/>
                <p>
                    We are now going to change two things in the code to make the data easier to deal with
                </p>
                <ol>
                    <li>We will access the data from the <b>r.json()</b> object’s data key</li>
                    <li>We will import pandas and convert the data to a Pandas Dataframe</li>
                </ol>
                <br/>
                <p>
                    Our code will now look like the following
                </p>
                <AceEditor
                    mode="python"
                    theme="monokai"
                    value={cleanedCode}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{$blockScrolling: true}}
                    height={175}
                    width="auto"
                />
                <br/><br/>
                <p style={{borderLeft: '5px solid black', height: '150%', fontStyle: 'italic', paddingLeft: '25px'}}>All API links in this post are public so feel free to use this to test!</p>
                <br/>
                <h1>Step 3: Connect to Your Dropbox File</h1>
                <p>
                    Click on the Dropbox Upload box. The first click will either ask you to authenticate OR if your account is already open in the tab, it will just refresh the page. This is expected behavior from Dropbox authentication.
                </p>
                <br/>
                <figure>
                    <img src="https://miro.medium.com/max/1400/1*D2l_tSXYHbTkF1nIwBCcQQ.png" style={{width: '100%', margin: '0 auto', display: 'block'}} alt="Dropbox"/>
                    <figcaption style={{textAlign: 'center'}}><sub>Click on the Dropbox Upload Box to Start the Process</sub>
                    </figcaption>
                </figure>
                <br/><br/>
                <p>
                    Now click on the Dropbox Upload box again and you will see a File Chooser. Select your file.
                </p>
                <br/>
                <figure>
                    <img src="https://miro.medium.com/max/1400/1*pCJtKc3QT3FUTAWsK8ekNQ.png" style={{width: '100%', margin: '0 auto', display: 'block'}} alt="uploadDropbox"/>
                </figure>
                    <br/><br/>
                <p>
                    Follow the same steps as above for copying the Python code in your file.
                </p>
                <p>
                    However, we are now going to change the name of the requests and the data to be more identifying
                </p>
                <AceEditor
                    mode="python"
                    theme="monokai"
                    value={middleCode}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{$blockScrolling: true}}
                    height={260}
                    width="auto"
                />
                <br/><br/>
                <h1>Step 4: Upload File From Your Desktop</h1>
                <p>
                    Now we are going to upload the file from your desktop.
                </p>
                <p>
                    We are not going to bore you with this process. Simply click on the <b>Upload or Drop Files</b> box and upload your file.
                </p>
                <p>
                    Follow the same steps as above to get the python code and insert it into your Python file. Then change the variable names so it all makes sense.
                </p>
                <p>
                    This will lead us to our final code
                </p>
                <AceEditor
                    mode="python"
                    theme="monokai"
                    value={fullCode}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{$blockScrolling: true}}
                    height={500}
                    width="auto"
                />
                <br/><br/>
                <h1>Step 5: Do Something with your Data</h1>
                <p>
                    That’s it! That’s how easy it is to integrate spreadsheet data from various sources.
                </p>
                <p>
                    The best part is that if the underlying Google Sheet or Dropbox File data changes then your data is automatically updated.
                </p>
                <p>
                    And if you want to share your data with someone you simply need to send them the API link.
                </p>
                <p>
                    Good luck with your integration journey and feel free to drop us a note at info@lovespreadsheets.com if you run into any trouble or have any questions.
                </p>
                <p>If you have any further questions, feel free to email us at <a href="mailto:info@lovespreadsheets.com">info@lovespreadsheets.com!</a> We can’t wait to see what you build.</p>
                <p>Checkout our other <a href="/tutorials">tutorials! </a> </p>
                <CommentSection commentDiv={<div className="fb-comments" data-href="https://www.apispreadsheets.com/tutorials/integrate-google-sheets-dropbox-local-files" data-numposts="5" data-width="" />}/>
            </div>
        )
    }
}

export default IntegrateFiles