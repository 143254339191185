import React, {Component} from 'react'
import CreditCardDetails from './CreditCardDetails'
import {Elements, StripeProvider} from 'react-stripe-elements';
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyFilePKProBlock} from "../../Actions/ModifyFilePKProBlock";
import {modifyTier} from "../../Actions/ModifyTier";

class PaymentForm extends Component {
    constructor(props){
        super(props);

        this.changeProcess = this.changeProcess.bind(this);
    }

    changeProcess(process){
        if ('changeProcess' in this.props){
            this.props.changeProcess(process);
        }

        if (process === "completed"){
            let updatedFilePKProBlock = {};

            for (let pkID in this.props.filePKProBlock){
                updatedFilePKProBlock[pkID] = false
            }

            this.props.modifyFilePKProBlock(updatedFilePKProBlock);

            const planNames = ["pro", "team", "business"]
            this.props.modifyTier(planNames[this.props.plan-1])

            this.props.toggleModal(null)
            this.props.toggleModal("completedCelebrationModal")
        }
    }

    getPaymentInformation = () => {
        let paymentInfo = null;

        if (this.props.subscriptionInfo !== null){
            if ('paymentInfo' in this.props.subscriptionInfo){
                if ('creditCards' in this.props.subscriptionInfo.paymentInfo && 'defaultCard' in this.props.subscriptionInfo.paymentInfo){
                    paymentInfo = this.props.subscriptionInfo.paymentInfo
                }
            }
        }

        return paymentInfo
    }

    render() {
        return (
            <div>
                <StripeProvider apiKey={window.location.href.includes("localhost") ? "pk_test_1rS8QGgeDU1Ewoyjc8AjaTx0" : "pk_live_nmeaYWsNdAbSbOTRd8MgnGLL"}>
                {/*<StripeProvider apiKey="pk_test_1rS8QGgeDU1Ewoyjc8AjaTx0">*/}
                    <div className="example">
                        <Elements>
                            <CreditCardDetails
                                paymentInfo={this.getPaymentInformation()}
                                changeProcess={this.changeProcess}
                                toggleModal={this.props.toggleModal}
                                plan={this.props.plan}
                                currency={this.props.currency}
                                yearlyPricing={this.props.yearlyPricing}
                                segment={'segment' in this.props}
                            />
                        </Elements>
                    </div>
                </StripeProvider>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    subscriptionInfo: state.mainState.subscriptionInfo,
    filePKProBlock: state.mainState.filePKProBlock,
    currency: state.mainState.currency,
    yearlyPricing: state.mainState.yearlyPricing
});

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyFilePKProBlock: modifyFilePKProBlock,
    modifyTier: modifyTier
};

export default connect(mapStateToProps, mapActionsToProps)(PaymentForm)
