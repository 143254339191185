import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import {toggleModal} from "../Actions/ToggleModal";
import {connect} from "react-redux";

class QuickstartUploadAuth extends Component {
    openQuickstartUploadModal=()=>{
        this.props.toggleModal('quickstartUploadModal')
    }
    render() {
        return (
            <div className="row">
                <div className="col-sm-1" />
                <div className="col-sm-10">
                    <div className="uploadOuterSection" onClick={this.openQuickstartUploadModal}>
                        <div className="uploadInnerSection">
                            <div style={{textAlign: 'center'}}>
                                <span className="uploadIcons">
                                    <Icon name="code" />
                                </span>
                                <h4 className="thinHeading">Quickstart</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1" />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(QuickstartUploadAuth)