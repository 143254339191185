import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Message} from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

class ReportingSaveButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSaving: false,
            error: null
        }
    }

    saveReportingInfo = () => {
        this.setState({ isSaving: true })
        const isError = () => this.setState({ isSaving: false, error: true });

        fetch(API_Root + "api/save-api-auto-reporting/", {
            method: "POST",
            headers: { Accept: 'application/json, text/plain, */*'},
            body: JSON.stringify({
                pk: this.props.pk,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                reportInfo: this.props.reportInfo
            })
        }).then((res) => {
            if (res.status === 200){
                this.updateReduxInfo(this.props.reportInfo)
                this.setState({ isSaving: false, error: false})
            } else {
                isError()
            }
        }).catch((err) => isError())
    }

    updateReduxInfo = (reportInfo) => {
        const currFileInfo = JSON.parse(JSON.stringify(this.props.fileInformation))
        const fileInfo = currFileInfo[this.props.editID]
        fileInfo['reportInfo'] = reportInfo

        currFileInfo[this.props.editID] = fileInfo

        this.props.modifyFileInformation(currFileInfo)
    }

    render() {
        let message = null;

        if (this.state.error !== null){
            if (this.state.error){
                message = <Message negative header="There was an error saving your settings" content="Please try again or contact our support if the error persists" />
            } else {
                message = <Message positive header="Your auto report settings were saved" content="The spreadsheet will now be emailed to the people you selected" />
            }
        }
        return (
            <div style={{marginBottom: '20px'}}>
                <Button disabled={this.props.reportInfo.days.length === 0 || this.props.reportInfo.times.length === 0 || this.props.reportInfo.emails.length === 0}
                        style={gunaldiStyles.btnGreen}
                        loading={this.state.isSaving}
                        content={this.props.reportInfo.isEditing ? "Set up Auto Reporting" : "Change Auto Reporting Details"}
                        icon="mail outline" onClick={this.saveReportingInfo}/>
                {this.props.reportInfo.days.length === 0 ? <small style={{display: 'block', color: 'red'}}>Please select at least 1 day</small> : null}
                {this.props.reportInfo.times.length === 0 ? <small style={{display: 'block', color: 'red'}}>Please select at least 1 time</small> : null}
                {this.props.reportInfo.emails.length === 0 ? <small style={{display: 'block', color: 'red'}}>Please add at least 1 email to receive reports</small> : null}
                {message}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation
}

export default connect(mapStateToProps, mapActionsToProps)(ReportingSaveButton)