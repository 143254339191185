import React, {Component} from 'react'
import TierRestrictionBanner from "./TierRestrictionBanner";
import DocsImage from "../DocsImage";

class ActionsAutoReporting extends Component {
    render() {
        return (
            <div>
                <div style={{marginTop: "-8px", marginBottom: "16px"}}>
                    <TierRestrictionBanner plan="team" payment="reporting"/>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">What does Auto Reporting do?</p>
                    <p>Auto reporting emails your file, Google Sheet, or database table as a spreadsheet on the frequency you configure and people you choose to receive the spreadsheet</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">How do I set this up?</p>
                    <p>Click on <b>Auto Reporting</b> under the actions menu of your Files page</p>
                    <DocsImage src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/EmailSpreadsheetsDaily/AutoReporting.png" width="40%" alt="Auto reporting menu click"/>
                    <p>Then you can pick which days the spreadsheet should send, which times, the message, the subject, and who the spreadsheet should go to!</p>
                    <DocsImage src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/Tutorials+Pics/EmailSpreadsheetsDaily/AutoReportingSettings.png" width="100%" alt="Auto Reporting Menu" />
                    <p>Once you click on <b>Set up Auto Reporting</b>, that's it! Your file data as a spreadsheet will be emailed at your chosen frequency to the emails you have listed </p>
                    <p>You can edit this configuration at any time from this screen</p>
                </div>
            </div>
        )
    }
}

export default ActionsAutoReporting