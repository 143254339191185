import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Checkbox } from 'semantic-ui-react'
import {toggleYearlyPricing} from "../../Actions/ToggleYearlyPricing";

class MonthlyYearlyToggle extends Component {
    toggle = () => {
        const toggleVal = !JSON.parse(JSON.stringify(this.props.yearlyPricing))

        this.props.toggleYearlyPricing(toggleVal)
    }

    render() {
        return (
            <span>
                <Checkbox toggle checked={this.props.yearlyPricing} onChange={this.toggle}/>
            </span>
        )
    }
}

const mapStateToProps = (state) => ({
    yearlyPricing: state.mainState.yearlyPricing
})

const mapActionsToProps = {
    toggleYearlyPricing: toggleYearlyPricing
}

export default connect(mapStateToProps, mapActionsToProps)(MonthlyYearlyToggle)