import React, {Component} from 'react'
import { Icon, Popup } from 'semantic-ui-react'

class InfoPopup extends Component {
    render() {
        let style = {fontSize: '110%', cursor: 'pointer', paddingLeft: '6px', color: '#9696A0'}

        if ("style" in this.props){
            style = this.props.style
        }

        let iconName = "info circle"

        if ("iconName" in this.props){
            iconName = this.props.iconName
        }

        return (
            <Popup content={this.props.info} trigger={<Icon name={iconName} style={style} />} on="click"/>
        )
    }
}

export default InfoPopup