import React, {Component} from 'react'
import { Button, Popup, Message } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";

class RemoveGoogleAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupOpen: false,
            deleting: false
        }
    }

    togglePopup = (e, toggle) => {
        this.setState({
            popupOpen: toggle
        })
    }

    removeAccount = () => {
        const errorFetch = () =>
        {
            this.setState({
                popupOpen: false,
                deleting: false
            }, () => {
                this.props.changeState("message",
                    <Message error header="There was an error removing your account" content="Please try again or contact our support if this persists"/> );
            })
        }

        fetch(API_Root + 'api/remove-google-account-from-api/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                emailToRemove: this.props.emailToRemove
            })
        }).then(res => {
            if (res.status === 200){
                this.setState({
                    popupOpen: false,
                    deleting: false
                }, () => {
                    this.props.removeAccount(this.props.removeType)
                })
            }
            else{
                errorFetch()
            }
        }).catch(err => errorFetch());
    }

    render() {
        return (
            <span>
                <Popup
                    trigger={
                        <Button
                            style={{float: 'list' in this.props ? 'left' : "right"}}
                            color="red"
                            loading={this.state.deleting}
                            content="Remove Account from API Spreadsheets"
                            icon="sign-out"
                            onClick={(e) => this.togglePopup(e, true)}/>}
                    on="click"
                    position="bottom"
                    // onOpen={(e) => this.togglePopup(e, true)}
                    onClose={(e) => this.togglePopup(e, false)}
                    open={this.state.popupOpen}>
                            <div style={{padding: '10px'}}>
                                <h4 style={{color: 'red'}}>
                                   Are you sure you want to remove this account? ALL your APIs dependent on this account will <u>STOP</u> working.
                                </h4>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Button color="green" content="No" onClick={(e) => this.togglePopup(e, false)} fluid />
                                    </div>
                                    <div className="col-sm-6">
                                        <Button color="red" loading={this.state.deleting} content="Yes" onClick={this.removeAccount} fluid />
                                    </div>
                                </div>
                            </div>
                    </Popup>
            </span>
        )
    }
}

export default RemoveGoogleAccount