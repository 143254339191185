import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class PlanCTA extends Component {
    render() {
        return (
            <span className="pricingCTA"><a className="linkStyle" style={this.props.planIdx === 2 || this.props.planIdx === 3 ? {color: "white"} : null} href={this.props.getSignUpLink(this.props.planIdx)}>{this.props.getSignUpText(this.props.planIdx)} <span style={{paddingLeft: "2px", paddingTop: "2px"}}><Icon name="angle right" /></span></a></span>
        )
    }
}

export default PlanCTA