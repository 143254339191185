import React, {Component} from 'react'
import {connect} from 'react-redux'
import Loader from 'react-loader-spinner'
import {gunaldiStyles} from "../../../SharedComponents/GunaldiDesignStyles";
import UpgradeToTeam from "../../../FileDnD/UpgradeToTeam"
import BannerAlert from "../../../SharedComponents/BannerAlert"
import CNAMEForm from "../CNAME/CNAMEForm"
import {isTLDValid} from "../../../SharedComponents/isTLDValid";
import {isURLComponentValid} from "../../../SharedComponents/isURLComponentValid";
import {getCleanDomain} from "../../../Constants/getCleanDomain";
import API_Root from "../../../Constants/API_Root";
import ProfileDisplayHeader from "../ProfileDisplayHeader"
import DomainConnectionsIndex from "./DomainConnections/DomainConnectionsIndex"

class CNAMEIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            fetching: false,
            domainInfo: null,
            domainName: "",
            domainNameError: true,
            domainNameErrorMsg: "Please enter a domain name",
            fileCertificate: null,
            fileKey: null,
            fileCertificateChain: null,
            useForAPIRequests: true,
            useForImporter: true,
            isDomainHealthy: false,
            useSSL: false,
            importSSLError: false,
            importSSLMsg: "",
            requestSSLError: false,
            requestSSLMsg: "",
            domainValidationOption: "email",
            sslMenuItem: "requestCertificate",
            currSSLInfo: [],
            sslSelected: null
        }
    }

    componentDidMount(){
        // get data in here, and if there is any SSL data then update sslMenuItem to yourCertificate
        this.setState({ fetching: true })
        const isError = () => this.setState({ fetching: false, error: true })

        fetch(API_Root + "api/get-cname-integration-info/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
            })
        }).then(res => {
            if (res.status === 200){
                res.json().then(data => {
                    let currSSLInfo = []
                    if ("certificateInfo" in data){
                        currSSLInfo = data["certificateInfo"]
                    }

                    this.setState({
                        domainInfo: 'distributionInfo' in data ? data['distributionInfo'] : null,
                        currSSLInfo: currSSLInfo,
                        fetching: false,
                        error: null,
                        sslMenuItem: currSSLInfo.length > 0 ? "yourCertificates" : "requestCertificate"
                    })
                }).catch(err => isError())
            } else {
                isError()
            }
        }).catch(err => isError())
    }

    isBusiness = () => (this.props.tier === "business" || this.props.tier === "enterprise")

    changeState = (newStateObj, checkError="") => {
        if (checkError === "domainName"){
            this.isDomainNameValid(newStateObj['domainName'])
        } else {
            this.setState(newStateObj)
        }
    }

    isDomainNameValid = (domainName) => {
        const noMatch = () => this.setState({ domainName: domainName, domainNameError: true, domainNameErrorMsg: "Please enter a valid domain name" })

        const testDomainName = domainName.toString().trim().toLowerCase().replace("http://", "").replace("https://", "").replace("*.", "jm6wi72hnbk0ar2l.")

        let isValid = true;

        const domainComponents = testDomainName.split(".")

        if (domainComponents.length > 1){
            const tld = domainComponents[domainComponents.length-1];

            if (!isTLDValid(tld)){
                isValid = false
            } else {
                if (domainComponents.length === 2){
                    if (domainComponents[0] === "jm6wi72hnbk0ar2l" || !isURLComponentValid(domainComponents[0])){
                        isValid = false
                    }
                } else {
                    for (let i=0; i<domainComponents.length-1;i++){
                        if (!isURLComponentValid(domainComponents[i])){
                            isValid = false
                            break
                        }
                    }
                }
            }
        } else {
            isValid = false
        }

        if (!isValid){
            noMatch()
        } else {
            this.setState({
                domainName: domainName,
                domainNameError: false,
                domainNameErrorMsg: "You will be connecting this domain: " + getCleanDomain(domainName)})
        }
    }

    render() {
        const isBusiness = this.isBusiness();

        let style = {marginTop: "16px"}

        if (!isBusiness){
            style['pointerEvents'] = "none"
            style['opacity'] = "0.5"
        }

        let body = null;

        if (this.state.fetching){
            body =
                <div>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="20"
                        width="20"
                    />
                    <div style={{fontSize: "18px", fontWeight: "700"}}>Getting information about your connected domains...</div>
                </div>
        } else {
            if (this.state.error !== null){
                let errorMsg;
                if (typeof this.state.error === "string"){
                    errorMsg = this.state.error
                } else {
                    errorMsg = <span>
                        There was an error getting your connected domain information<br/><br/>
                        Please contact support if this error persists
                    </span>
                }

                body = <div>
                    <div style={{fontSize: "20px", fontWeight: "700", color: gunaldiStyles.red}}>
                        {errorMsg}
                    </div>
                </div>
            } else {
                if (this.state.domainInfo !== null){
                    body = <div>
                        <div style={{marginTop: "16px"}}>
                            <DomainConnectionsIndex
                                domainInfo={this.state.domainInfo}
                                changeState={this.changeState}
                            />
                        </div>
                        <CNAMEForm
                            info={this.state}
                            changeState={this.changeState}
                            domainConnected
                        />
                    </div>
                } else{
                    body = <div>
                        <BannerAlert
                            icon="bx bxs-info-circle"
                            type="info"
                            header="You currently do not have any domains connected"
                            content={<span>Connect your domain below. Read more about <a href="/api-docs/?docsMenuItem=integrations-connectDomain">connecting your domain here</a></span>}
                        />
                        <CNAMEForm
                            info={this.state}
                            changeState={this.changeState}
                        />
                    </div>
                }
            }
        }

        return (
            <div>
                {isBusiness ? null : <div style={{marginTop: "8px"}}>
                    <UpgradeToTeam higherTier="cname"
                                   header="You can only Connect your Domain on the Business or higher tier account"/>
                </div>}
                <div style={isBusiness ? null : {"cursor": "not-allowed"}}>
                    <div style={style}>
                        {body}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CNAMEIndex)