import React, {Component} from 'react'
import Destinations from "../../Illustrations/Destinations.svg"
import { Segment } from 'semantic-ui-react'
import ImporterFeature3 from "../../Illustrations/ImporterFeatures/ImporterFeature3.svg"

class SheetsDestination extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}> Select Multiple Destinations </div>
                <div style={this.props.subheadingStyle}>Select all the destinations the imported file and data should be saved to</div>
                <Segment>
                    <div style={{textAlign: "center"}}>
                        <img src={ImporterFeature3} alt="all destinations for import files" style={{width: "75%"}}/>
                    </div>
                </Segment>
            </div>

        )
    }
}

export default SheetsDestination