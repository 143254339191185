import React, {Component} from 'react'

class ValidInvalidRow extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="codeExampleBox">
                        <b style={{color: 'green', display: 'block'}}>VALID</b>
                        {this.props.validCode}
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="codeInvalidBox">
                        <b style={{color: 'red', display: 'block'}}>INVALID</b>
                        {this.props.invalidCode}
                    </div>
                </div>
            </div>
        )
    }
}

export default ValidInvalidRow