import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class APISecurity extends Component {
    render() {
        const notePStyle = {paddingBottom: "0", marginBottom: "5px"}

        return (
            <div>
                <p>We understand security is extremely important when developing applications so we offer various options to secure your API & Data</p>
                <div style={{border: '1px solid lightgrey', borderRadius: "8px", padding: "10px"}}>
                    <p style={{marginBottom: "0px"}}>Before we go through the options, here's a quick summary of our <a href="/privacy">Privacy Policy</a> and <a href="/terms">Terms</a></p>
                    <ol>
                        <li>You own all your data completely</li>
                        <li>For Google Drive and Dropbox we don't store any of your data except auth tokens</li>
                        <li>We delete all instances related to your uploaded data and account when you press delete</li>
                        <li>We never share or use your data under any circumstance</li>
                        <li>Your data is stored with encryption keys that only 1 person holds</li>
                        <li>We use HTTPS/SSL for security in transmitting data</li>
                    </ol>
                </div>
                <br/><br/><br/>
                <p className="docsHeader"><Icon name='key' /> Access & Secret Keys</p>
                <p className="text-muted"><b>PRO</b> account or higher</p>
                <p>We provide you with an Access Key and a Secret Key (both 32 digit alphanumeric strings). You must use these when making requests to your API</p>
                <p>If the correct keys are not provided, then your requests will be denied. This is the easiest way to secure your API</p>
                <div style={{border: "1px solid lightgrey", borderRadius: "8px", padding: "10px"}}>
                    <p style={{marginBottom: "0px", paddingBottom: "0", fontSize: "20px"}}><b><u>Note for using keys on the front-end</u></b></p>
                    <p style={notePStyle}>Using any kind of credentials on the front-end is a well-known security risk. This applies to every API not just API Spreadsheets</p>
                    <p style={notePStyle}>If you are not using any of our security features below, then the safest course of action is to swap out the Keys frequently</p>
                    <p style={notePStyle}>However, that still exposes your keys for certain time periods so if security is paramount, please upgrade your account</p>
                </div>
                <br/><br/><br/><br/>
                <p className="docsHeader"><Icon name='key' /> Block Request Type</p>
                <p className="text-muted"><b>TEAM</b> account or higher</p>
                <p>You can block any of the 4 Request types (create, read, update, delete) from your API</p>
                <p><b>Example 1</b> if you are saving data to a spreadsheet from a form, you may choose to block anyone from reading, updating or deleting data from the spreadsheet</p>
                <p><b>Example 2</b> if you are displaying elements on your website from the data in your spreadsheet, you may choose to block anyone from creating, updating or deleting the data</p>
                <br/><br/><br/><br/>
                <p className="docsHeader"><Icon name='lock' /> Whitelist Domains</p>
                <p className="text-muted"><b>BUSINESS</b> or <b>ENTERPRISE</b> accounts only</p>
                <p>You can specify as many domains as you want and requests made ONLY from those domains will be accepted. Anything else will be blocked</p>
                <p>This is the most surefire way to secure your data and API</p>
                <br/><br/><br/><br/>
                <p className="docsHeader"> Data Processor Agreement</p>
                <p className="text-muted"><b>ENTERPRISE</b> accounts only</p>
                <p>We are able to sign and execute Data Processor Agreements for your organization</p>
                <p>The specifics of the agreement differ from organization to organization but we are willing to take responsibility and liability to keep your data secure</p>
                <br/><br/><br/><br/>
                <p>If you have any further questions about security, feel free to <a href="mailto:info@apispreadsheets.com">Email Us</a></p>
            </div>
        )
    }
}

export default APISecurity