import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Icon, Input} from 'semantic-ui-react'

class FileNameSearch extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e){
        this.props.changeState("searchName", e.target.value)
    }

    render() {

        return (
            <div style={{width:'100%',height:'40px',backgroundColor:'#F0F0F0',left:'0px', top:'0px',border:'none',padding:'8px 8px 8px 12px',display:'flex',align:'center',position:'static'}}>
                <img src="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/FilesUploadPageIcons/searchIcon.svg"
                    // style={{width:'18.71px',height:'18.71px',left:'68.33%',top:'9.33%',bottom:'13.72%',position:'absolute'}}
                    // style={{width:'18.71px',height:'18.71px',top:'9.33%',bottom:'13.72%',position:'absolute', float: "right"}}
                />
                <input
                    style={{border: "none", width: '100%', padding:'8px 8px 8px 12px', backgroundColor:'#F0F0F0', outline: "none"}}
                    placeholder="Search File Name or File ID"
                    // loading={this.props.isFiltering}
                    onChange={this.handleInputChange}
                    value={this.props.searchName}
                    />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(FileNameSearch)