import firebase from 'firebase/app';
import 'firebase/auth';

// Initialize Firebase
const config = {
    apiKey: "AIzaSyAqYl2OmjDXEdk0xw8MGG6b44hsTrRtnrA",
    authDomain: "spreadsheet-api-f43dc.firebaseapp.com",
    databaseURL: "https://spreadsheet-api-f43dc.firebaseio.com",
    projectId: "spreadsheet-api-f43dc",
    storageBucket: "spreadsheet-api-f43dc.appspot.com",
    messagingSenderId: "834181032648",
    appId: "1:834181032648:web:1a0863401450006e"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

const auth = firebase.auth();

export {
    auth,
};
