import React, {Component} from 'react'
import { Input, Button, Icon, Form, TextArea} from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import { connect } from 'react-redux'
import {toggleToast} from "../../Actions/ToggleToast";

class ContactUsForm extends Component {
    constructor(props) {
        super(props);

        this.submitContactForm = this.submitContactForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.state = {
            email: '',
            message: ''
        }
    }

    submitContactForm(){
        if ('modal' in this.props){
            this.props.toggleModal(false, '')
        }

        fetch(API_Root + 'api/contact-us/', {
            method: "POST",
            body: JSON.stringify({
                email: this.state.email,
                message: this.state.message,
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then(
            (res) => {
                if (res.status === 200){
                    this.props.toggleToast({show: true, message: "Thank your for contacting us! We will be reaching out shortly", type: "success"})
                }
                else{
                    this.props.toggleToast({show: true, message: "There was an error submitting the contact form. Email us at info@apispreadsheets.com instead!", type: "error"})
                }
            }).catch(err => this.props.toggleToast({show: true, message: "There was an error submitting the contact form. Email us at info@apispreadsheets.com instead!", type: "error"}))
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    render() {
        return (
            <div>
                <Form>
                    <br/><br/>
                    <Input iconPosition="left" icon="at" type="email" name="email" value={this.state.email} placeholder="Your Email" onChange={this.handleInputChange} fluid/>
                    <br/>
                    <TextArea rows={4} placeholder="Let us know what you are thinking :)" value={this.state.message} name="message" onChange={this.handleInputChange}/>
                    <br/><br/>
                    <div className="text-center">
                        <Button color="orange" className="roundedButton" id="contactUsButton" onClick={this.submitContactForm}>
                            <Icon name="paper plane outline"/>Contact Us</Button>
                    </div>
                    <br/>
                </Form>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(null, mapActionsToProps)(ContactUsForm)