import React, {Component} from 'react'
import {Button} from "semantic-ui-react";
import API_Root from "../Constants/API_Root";
import { connect } from 'react-redux'
import {toggleToast} from "../Actions/ToggleToast";

class ResendVerificationEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resend: false
        }
    }

    resendEmail = () => {
        const error = () => this.setState({ resend: false }, () => this.props.toggleToast({show: true, message:"There was an error re-sending your verification email. Please try again. Contact our support if this error persists", type: "error"}))
        this.setState({ resend: true })

        fetch(API_Root + "api/resend-api-user-email-verification/", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
            })
        }).then((res) => {
            if (res.status === 200){
                this.setState({
                    resend: false
                }, () => this.props.toggleToast({ show: true, message: "Verification email successfully resent to " + localStorage.getItem("email"), type: "success" }))
            } else{
                error()
            }
        }).catch((err) => error())
    }


    render() {
        return (
            <span onClick={this.resendEmail}>
                <Button style={{backgroundColor: "#E27837", color: "white"}} loading={this.state.resend}>
                    Resend the verification email
                </Button>
            </span>
        )
    }
}

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(null, mapActionsToProps)(ResendVerificationEmail)