import {dateToString} from "./dateToString";

export const convertServerDateToDisplayDate = (serverDate) => {
    const utcDate = new Date(serverDate.replace(" ", "T") + ".000Z")
    const dateObj = dateToString(utcDate)

    const hour = parseInt(dateObj['time'].substring(0, 2))
    const minutes = dateObj['time'].substring(3, 5)
    const am_pm = hour >= 12 ? "PM" : "AM"

    return dateObj['date'] + " " + (hour > 12 ? hour - 12 : hour).toString() + ":" + minutes + am_pm
}