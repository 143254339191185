import {isBrowser} from "react-device-detect";
import React, {Component} from 'react'
import AuthComponent from "../../SharedComponents/AuthComponent";
import ImportAuthIndex from "../../Import/ImportAuthIndex";
import ImporterBrowserLandingPageIndex from "./ImporterBrowserLandingPageIndex";
import ImporterMobileLandingPageIndex from "./ImporterMobileLandingPageIndex";

class ImporterLandingPageIndex extends Component {
    render() {
        const app = isBrowser ?
            <AuthComponent
                // authComponent={<AppIndex/>}
                authComponent={<ImportAuthIndex />}
                nonAuthComponent={<ImporterBrowserLandingPageIndex/>}
            /> : <AuthComponent
                // authComponent={<AppIndex/>}
                authComponent={<ImportAuthIndex/>}
                nonAuthComponent={<ImporterMobileLandingPageIndex/>}
            />;

        return (
            <div>
                {app}
            </div>
        )
    }
}

export default ImporterLandingPageIndex