import React, {Component} from 'react'
import {HelpInfo} from "./HelpInfo";
import Hero from "../../SharedComponents/Hero"
import FAQDisplayGrid from "../../SharedComponents/FAQ/FAQDisplayGrid";

class HelpIndex extends Component {
    render() {
        return (
            <div className="helpIndex">
                <Hero
                    header="FAQs"
                    desc={<span>Our most frequently asked questions. </span>}
                    textColor="white"
                    color1="rgb(124,252,0)"
                    color2="rgb(0,128,0)"
                />
                <div style={{padding: "40px 100px 240px 100px"}}>
                    <FAQDisplayGrid helpInfo={HelpInfo}/>
                </div>
                <div className="faqEmailUs">
                    <div className="row">
                        <div className="col-sm-4" />
                        <div className="col-sm-4">
                            <div style={{textAlign: 'center'}}>
                                <h2>Have more questions?</h2>
                                <h4 style={{color: 'gray'}}>Email us at <a href="mailto:info@apispreadsheets.com?Subject=Pricing%20And%20Billing%20Questions">info@apispreadsheets.com</a></h4>
                            </div>
                        </div>
                        <div className="col-sm-4" />
                    </div>
                </div>
            </div>
        )
    }
}

export default HelpIndex