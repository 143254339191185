import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyEditID} from "../Actions/ModifyEditID";
import {toggleModal} from "../Actions/ToggleModal";
import { Button } from 'semantic-ui-react'

class ShareButton extends Component {
    constructor(props) {
        super(props);

        this.openShareModal = this.openShareModal.bind(this);
    }


    openShareModal(e){
        // this.props.modifyEditID(this.props.pkID);
        this.props.toggleModal("shareModal_" + this.props.pk + "_" + this.props.outputFormat)
    }

    render() {
        return (
            <div>
                <Button content="Share URL" basic color='blue' icon='share' onClick={this.openShareModal} fluid/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    modifyEditID: modifyEditID,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ShareButton)