const images = {
    logoNav: 'https://s3.amazonaws.com/project-static-assets/APISpreadsheets/Logo.png',
    logoImporterNav: "https://project-static-assets.s3.amazonaws.com/APISpreadsheets/LogoImporter.png",
    image404: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Longhaired_Dachshund_portrait.jpg/1006px-Longhaired_Dachshund_portrait.jpg',
    imageIcon: 'https://project-static-assets.s3.amazonaws.com/APISpreadsheets/LogoIcon.png',
    pythonLogo: 'https://woyera-static-files.s3.amazonaws.com/LanguageLogo/PythonLogo.png',
    rLogo: 'https://woyera-static-files.s3.amazonaws.com/LanguageLogo/R_Logo.png',
    loveLogo: 'https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/Logo.png',
    mysqlLogo: 'https://upload.wikimedia.org/wikipedia/commons/b/b2/Database-mysql.svg',
    postgresLogo: 'https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg',
};

export default images