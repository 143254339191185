import React, {Component} from 'react'
import {connect} from 'react-redux'
import KeysModalBody from "./KeysModalBody"
import CustomKeysModal from "./CustomKeysModal"

class KeysModalBodyIndex extends Component {
    render() {
        let body = null;

        if (localStorage.getItem("email") !== null){
            if (
                // this.props.tier === "team" ||
            this.props.tier === "business" || this.props.tier === "enterprise"

            ){
                body = <CustomKeysModal />
            } else{
                body = <div style={{marginTop: "16px"}}><KeysModalBody/></div>
            }
        }

        return (
            body
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(KeysModalBodyIndex)