import React, {Component} from 'react'
import {connect} from 'react-redux'
import {convertServerDateToDisplayDate} from "../../Constants/convertServerDateToDisplayDate";
import { Label, Button, Icon } from 'semantic-ui-react';
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";
import FileURL from "../../FileDnD/FileURL"
import ImportCodeSnippet from "./ImportCodeSnippet";
import ImportHashCopy from "./ImportHashCopy";
import ImportConfigName from "./ImportConfigName";
import ImportConfigDeleteBtn from "../ImportConfigDelete/ImportConfigDeleteBtn"
import { push } from 'react-router-redux'

class ImportSheetsSegment extends Component {
    getDisplayDate = (dt) => { return dt.toString().substring(0, 10)}

    goToImportPage = (e) => {
        //
        //
        let classNames;

        try {
            classNames = e.target.className
        } catch(err) {
            classNames = ""
        }

        if (classNames === null){
            classNames = ""
        }

        if (classNames.indexOf("importSegmentRowFileURLDiv") === -1 && classNames.indexOf("importSegmentRowDeleteBtn") === -1){
            // this.importSheetsSegmentLink.click()
            this.props.navigateTo("/import/" + this.props.info.importHash + "_" + this.props.idx.toString())
        }
    }

    render() {
        let segmentStyle = {
            backgroundColor: '#f3f6fb',
            border: '1px solid #F0F0F0',
            padding: '10px',
            marginBottom: '50px'
        };

        const uploadTypeColors = {
            "quickstart": "olive",
            "custom": "green"
        }

        const displayStyle = {
            paddingTop: "5px"
        }

        return (
            <div>
                <a href={"/import/" + this.props.info.importHash + "_" + this.props.idx.toString()} style={{display: "none"}} ref={importSheetsSegmentLink => this.importSheetsSegmentLink = importSheetsSegmentLink}> </a>
                <div className="importSegmentRow filesTabFileSummaryRow" onClick={this.goToImportPage}>
                    <div style={displayStyle}>
                        <span style={{paddingLeft: "4px"}}>
                            <ImportConfigName idx={this.props.idx}
                                              importHash={this.props.info.importHash}
                                              custom={false}
                            />
                        </span>
                    </div>
                    <div style={displayStyle}>
                        {this.getDisplayDate(convertServerDateToDisplayDate(this.props.info.createdDt))}
                    </div>
                    <div>
                        <FileURL import importHash={this.props.info.importHash} isImportSegment />
                    </div>
                    <div>
                        <div style={{textAlign: "right" }}>
                            <div style={{position:'static',height:'44px',display:'inline-block', marginRight: "15px", marginLeft: "5px"}}>
                                <a href={"/import/" + this.props.info.importHash + "_" + this.props.idx.toString()}>
                                    <button style={{width:'84px',height:'36px',backgroundColor:'#FFFFFF',border:'1px solid #6FBB98',borderRadius:'4px', color:"#6FBB98", cursor: "pointer"}}> Details</button>
                                </a>
                            </div>
                            <div style={{position:'static',height:'44px',display:'inline-block', float: "right"}}>
                                <ImportConfigDeleteBtn importHash={this.props.info.importHash}
                                                       custom={false}
                                                       idx={this.props.idx}
                                                       importName={this.props.importSheetsInfo[this.props.idx].name}
                                                       isImportSegment
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    importSheetsInfo: state.mainState.importSheetsInfo
})

const mapActionsToProps = {
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(ImportSheetsSegment)