import React, { useState } from 'react';
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import {Button, Icon} from "semantic-ui-react";

const InputFieldRow = ({ field, sheetNames, onFieldChange, onRemove, rowIdx }) => {
    const [additionalOptions, setAdditionalOptions] = useState(field.additionalOptions || {});

    const handleChange = (fieldKey) => (event) => {
        const value = event.target.value;
        onFieldChange({ ...field, [fieldKey]: value });
    };

    const handleTypeChange = (event) => {
        const value = event.target.value;
        onFieldChange({ ...field, fieldType: value });
        setAdditionalOptions({});
    };

    const handleAdditionalOptionsChange = (optionKey) => (event) => {
        const value = event.target.value;
        const updatedOptions = { ...additionalOptions, [optionKey]: value };
        setAdditionalOptions(updatedOptions);
        onFieldChange({ ...field, additionalOptions: updatedOptions });
    };

    const handleDropdownValuesChange = (index, newValue) => {
        const updatedValues = [...(additionalOptions.dropdownValues || [])];
        updatedValues[index] = newValue;
        setAdditionalOptions({ ...additionalOptions, dropdownValues: updatedValues });
        onFieldChange({ ...field, additionalOptions: { ...additionalOptions, dropdownValues: updatedValues } });
    };

    const addDropdownValue = () => {
        const updatedValues = [...(additionalOptions.dropdownValues || []), ''];
        setAdditionalOptions({ ...additionalOptions, dropdownValues: updatedValues });
    };

    const removeDropdownValue = (index) => {
        const updatedValues = additionalOptions.dropdownValues.filter((_, i) => i !== index);
        setAdditionalOptions({ ...additionalOptions, dropdownValues: updatedValues });
    };

    const fieldName = "Field " + (rowIdx + 1).toString();
    const labelStyle = {fontFamily: "Lato", fontWeight: '500', fontSize: "14px", color: "#5E645E", marginTop: "16px"}

    return (
        <div className="displayBox" style={{marginBottom: "16px"}}>
            <div style={{fontFamily: "Lato", fontSize: "16px", color: gunaldiStyles.subText, marginBottom: "16px"}}>
                {fieldName}
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <div>
                        <span style={labelStyle}>Field Name</span>
                    </div>
                    <div>
                        <input type="text" value={field.name || ''} onChange={handleChange('name')}
                               className="inputGray" style={{width: '100%'}}/>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div style={{display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "32px"}}>
                        <div>
                            <div>
                                <span style={labelStyle}>Excel Sheet</span>
                            </div>
                            <div style={{ padding: '6px 0px'}}>
                                <select value={field.sheetName || ''} onChange={handleChange('sheetName')}>
                                    {sheetNames.map((sheet, index) => (
                                        <option key={index} value={sheet}>{sheet}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span style={labelStyle}>Excel Cell</span>
                            </div>
                            <div>
                                <input type="text" value={field.cell || ''} onChange={handleChange('cell')}
                                       style={{width: '128px'}} className="inputGray"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div style={{ paddingTop: "16px"}}>
                        <Button onClick={onRemove} circular icon="trash alternate outline" basic color="red"></Button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <div style={labelStyle}>
                        <span>Field Type</span>
                    </div>
                    <div style={{ padding: '6px 0px'}}>
                        <select value={field.fieldType || ''} onChange={handleTypeChange}>
                            <option value="number">Number</option>
                            <option value="text">Text</option>
                            <option value="date">Date</option>
                            <option value="dropdown">Dropdown</option>
                        </select>
                    </div>
                </div>
                <div className="col-sm-8">
                    {/* Additional options based on Field Type */}
                    {field.fieldType === 'number' && (
                        <div>
                            <div style={labelStyle}>Number Type</div>
                            <div>
                                <span>
                                    <input type="radio" value="integer" checked={additionalOptions.numberType === 'integer'}
                                           onChange={handleAdditionalOptionsChange('numberType')}/> Integer
                                </span>
                                <span style={{ paddingLeft: "8px"}}>
                                    <input type="radio" value="float" checked={additionalOptions.numberType === 'float'}
                                           onChange={handleAdditionalOptionsChange('numberType')}/> Decimal
                                </span>

                            </div>
                        </div>
                    )}
                    {field.fieldType === 'text' && (
                        <div>
                            <div style={labelStyle}>
                                Max Length
                            </div>
                            <div>
                                <input type="number" value={additionalOptions.maxLength || ''}
                                       onChange={handleAdditionalOptionsChange('maxLength')}/>
                            </div>
                        </div>
                        )}
                    {field.fieldType === 'date' && (
                        <div style={{display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "16px"}}>
                            <div>
                                <div style={labelStyle}>
                                    Min Date
                                </div>
                                <div>
                                    <input type="date" value={additionalOptions.minDate || ''}
                                           onChange={handleAdditionalOptionsChange('minDate')}/>
                                </div>
                            </div>
                            <div>
                                <div style={labelStyle}>
                                    Max Date
                                </div>
                                <div>
                                    <input type="date" value={additionalOptions.maxDate || ''}
                                           onChange={handleAdditionalOptionsChange('maxDate')}/>
                                </div>
                            </div>
                        </div>
                    )}
                    {field.fieldType === 'dropdown' && (
                        <div>
                            <div style={labelStyle}>
                                Dropdown Values
                            </div>
                            {additionalOptions.dropdownValues && additionalOptions.dropdownValues.map((value, index) => (
                                <div key={index} style={{ paddingBottom: "4px"}}>
                                    <input type="text" className="inputGray" value={value}
                                           onChange={(e) => handleDropdownValuesChange(index, e.target.value)}/>
                                    <button type="button" onClick={() => removeDropdownValue(index)} style={{
                                        color: 'red',
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        marginLeft: '5px'
                                    }}>x</button>
                                </div>
                            ))}
                            <div style={{ paddingTop: "8px" }}>
                                <Button type="button" onClick={addDropdownValue} size='mini'
                                        style={{ backgroundColor: gunaldiStyles.mainGreen, color: "white" }}><Icon name='plus' />Add Value</Button>
                            </div>

                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InputFieldRow;
