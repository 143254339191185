import React, {Component} from 'react'
import ImportDisplay from "./ImportDisplay"
import {connect} from 'react-redux'
import BannerMessages from "../App/API/BannerMessages";
import ImportIndexMenu from "./ImportIndexMenu";
import {getQueryParamValue} from "../Constants/getQueryParamValue";
import {modifyImporterMenuItem} from "../Actions/ModifyImporterMenuItem";
import FilesIndex from "../FileDnD/FilesIndex"
import CNAMEIndex from "../InfoPages/Profile/CNAME/CNAMEIndex"
import PageHeader from "../SharedComponents/PageHeader";
import DocsIndex from "../App/Docs/DocsIndex"
import DatabaseIndex from "../Database/DatabaseIndex"
import {toggleModal} from "../Actions/ToggleModal";
import {modifyIntegrationAuth} from "../Actions/ModifyIntegrationAuth";
import {toggleShowDemo} from "../Actions/ToggleShowDemo";
import APIDemoVideoLinks from "../App/API/APIDemoVideoLinks";
import DemoVideoIndex from "../InfoPages/DemoVideo/DemoVideoIndex";
import ImporterDemoVideoLinks from "./ImportDemoVideoLinks";

class ImportIndex extends Component {
    componentDidMount(){
        if (localStorage.getItem("email") !== null && localStorage.getItem("token") !== null) {
            if (window.location.href.includes("?signup")) {
                if (localStorage.getItem("showImporterDemo") === null) {
                    this.props.toggleModal("signupDemoModal")
                    localStorage.setItem("showImporterDemo", false)
                }
            }
        }
        const menuItem = getQueryParamValue("menuItem", window.location.href)

        if (menuItem !== ""){
            this.props.modifyImporterMenuItem(menuItem)
        }
    }

    getDisplay = () => {
        return {
            "importers": <ImportDisplay/>,
            "files": <FilesIndex importerIndex />,
            "docs": <DocsIndex page="importer"/>,
            "database": <DatabaseIndex />,
            "demos": <DemoVideoIndex
                videos={ImporterDemoVideoLinks}
                importer
            />,
            "connect domain":
                <div>
                    <PageHeader
                        header="Connect Your Domain"
                        desc="Host importer on your own domain"
                        common
                        tiny
                    />
                    <div style={{padding: "30px 100px 160px 30px"}}>
                        <CNAMEIndex />
                    </div>
                </div>
        }[this.props.importerMenuItem]
    }

    render() {
        return (
            <div style={{minHeight: '100vh'}}>
                <div style={{display: "grid", gridTemplateColumns: "100px 1fr"}}>
                    <div>
                        <ImportIndexMenu/>
                    </div>
                    <div>
                        <BannerMessages/>
                        <div>
                            {this.getDisplay()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    importerMenuItem: state.mainState.importerMenuItem,
    showDemo: state.mainState.showDemo
})

const mapActionsToProps = {
    modifyImporterMenuItem: modifyImporterMenuItem,
    toggleModal: toggleModal,
    toggleShowDemo: toggleShowDemo
}

export default connect(mapStateToProps, mapActionsToProps)(ImportIndex)