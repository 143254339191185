import React, {Component} from 'react'
import PromoBanner from "./PromoBanner";
import PromoTimer from "./PromoTimer";
import PromoPricing from "./PromoPricing";
import CurrencyDropdown from "../Pricing/CurrencyDropdown"
import PopupPlanDetails from '../../SharedComponents/Payment/PopupPlanDetails'
import { Icon, Dropdown, Segment } from 'semantic-ui-react';
import Confetti from 'react-confetti'
import PromoPaymentIndex from "./PromoPaymentIndex";
import AuthComponent from "../../SharedComponents/AuthComponent";
import PromoLogin from "./PromoLogin";
import { connect } from 'react-redux';
import Pricing from "../../Constants/Pricing/Pricing";
import currencyInfo from "../../Constants/currencyInfo";

class PromoIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            plan: 2
        }
    }

    getPricing = (multiplier) => {
        const currencyInformation = currencyInfo[this.props.currency];
        let price = Pricing[this.state.plan].price * currencyInformation.multiplier * multiplier

        // if (multiplier === 6){
        //     if (this.props.currency === "inr" || this.props.currency === "php"){
        //         price -= 1
        //     } else {
        //         price += (9 - (price % 10))
        //     }
        // }

        return price
    }

    getPricingInfo = () => { return { annual: this.getPricing(12), promo: this.getPricing(6) }}

    changePlan = (event, { value }) => this.setState({ plan: value })

    render() {
        const pricingInfo = this.getPricingInfo()

        const congrats = <div className="promoPaymentIndex">
            <h2>You are already a Customer! Enjoy
                <span role="img" aria-label="smile">😀</span>
                <span role="img" aria-label="confetti">🎉</span>
                <span role="img" aria-label="heart">❤️</span>
                <span role="img" aria-label="beer">🍻</span>
                <span role="img" aria-label="poppers">🎊</span>
            </h2>
        </div>

        let paymentArea = <AuthComponent
            authComponent={this.props.tier === "free" || this.props.tier === null ?
                <PromoPaymentIndex pricingInfo={pricingInfo} plan={this.state.plan} /> : congrats}
            nonAuthComponent={<PromoLogin />}
        />

        const planOptions = [
            {key: 1, value: 1, text: <h3>PRO</h3>},
            {key: 2, value: 2, text: <h3>TEAM</h3>}
        ]

        return (
            <div className="promoIndex">
                <Confetti
                    numberOfPieces={500}
                    recycle={false}
                />
                <PromoBanner/>
                <div style={{textAlign: 'center', paddingBottom: '10px', paddingTop: '50px'}}>
                    <div className="row">
                        <div className="col-sm-4" />
                        <div className="col-sm-4">
                            <Segment>
                                <h4>Choose Plan</h4>
                                <Dropdown
                                    onChange={this.changePlan}
                                    value={this.state.plan}
                                    selection
                                    options={planOptions}
                                />
                                <div>
                                    <PopupPlanDetails
                                        trigger={<span className="linkStyle" style={{fontSize: '115%'}}>
                                                    <small><Icon name="eye" /> View Plan Details</small>
                                                </span>}
                                        plan={this.state.plan}
                                        noPrice
                                    />
                                </div>
                            </Segment>
                        </div>
                        <div className="col-sm-4" />
                    </div>
                    <br/>
                </div>
                <div className="promoCurrency">
                    <b style={{marginRight: "10px"}}>Currency</b>
                    <CurrencyDropdown/>
                </div>
                <PromoPricing plan={this.state.plan} price={pricingInfo}/>
                <PromoTimer/>
                <br/>
                {paymentArea}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier,
    currency: state.mainState.currency
})

export default connect(mapStateToProps, null)(PromoIndex)