import React, {Component} from 'react'
import TierRestrictionBanner from "../Actions/TierRestrictionBanner";
import DocsImage from "../DocsImage";

class DatabaseUseAPI extends Component {
    getLink = (link, text) => {
        return <span className="linkStyle" onClick={e => this.props.setBothMenuItems(link)}>{text}</span>
    }

    render() {
        return (
            <div>
                <div style={{marginTop: "-8px", marginBottom: "16px"}}>
                    <TierRestrictionBanner plan="business" payment="database"/>
                </div>
                <div className="docsSection">
                    <p>After you have <span className="linkStyle" onClick={e => this.props.setBothMenuItems("database-connect")}>connected your databases</span> and <span className="linkStyle" onClick={e => this.props.setBothMenuItems("database-createAPI")}>created an API</span> from your database, then you can use that API</p>
                    <p>The API use and API <b>actions</b> are the EXACT same as it would be for any other Upload source</p>
                    <p>In this guide, we will touch upon any differences in the API use or actions that is specific to the database. Otherwise we will provide a link to our other guides</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Edit Query</p>
                    <p>You can edit the query you used to create the API at any time from that API's page</p>
                    <DocsImage src="https://apispreadsheets-content.s3.amazonaws.com/DocsPics/Database/Database-EditQuery.png" alt="Connect New Database" width="100%"/>
                    <p>Now the data you read, create, update, or delete from your database will be updated to the query</p>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Use API for CRUD</p>
                    <p>Since the API use for CRUD actions is the EXACT same as for other Upload sources, you can read the relevant docs to understand how to use the API</p>
                    <ol>
                        <li>{this.getLink("api-create", "Create Data")}</li>
                        <li>{this.getLink("api-read", "Read Data")}</li>
                        <li>{this.getLink("api-update", "Update Data")}</li>
                        <li>{this.getLink("api-delete", "Delete Data")}</li>
                    </ol>
                </div>
                <div className="docsSection">
                    <p className="docsHeader">Perform actions on API data</p>
                    <p>All the actions you can take on your database data are the EXACT same as for other Upload sources</p>
                    <p>There are many actions you can take, and things you can do (such as set up auto emailing of your database data as a spreadsheet)</p>
                    <p>You can start by {this.getLink("actions-introduction", "checking out the actions introductions")} and then read about each of them individually</p>
                </div>
            </div>
        )
    }
}

export default DatabaseUseAPI